import React, { useEffect, useState } from 'react';
import { Tab, Tabs, Box, Grid } from '@material-ui/core'
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";
import MentorCard from './mentorCard'
import { useSelector } from 'react-redux'
import PrivateChat from './privateChat';

const StyledTabs = withStyles({
    indicator: {
        display: "flex",
        justifyContent: "center",
        backgroundColor: "#09A9BB",
        "& > span": {
            maxWidth: 30,
            width: "100%",
            backgroundColor: "#09A9BB"
        }
    }
})(props => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

const StyledTab = withStyles(theme => ({
    root: {
        minWidth: "60px !important",
        textTransform: "none",
        color: "#080808e3",
        fontWeight: theme.typography.fontWeightRegular,
        fontSize: theme.typography.pxToRem(15),
        marginRight: theme.spacing(1),
        flexGrow: 1,

        fontFamily: theme.fontFamily.Roboto,
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "16px",
        lineHeight: "14px",
        color: " #FFF",
        "&:focus": {
            opacity: 1
        }
    },
    selected: {
        color: "#09A9BB"
    }
}))(props => <Tab disableRipple {...props} />);
function TabPanel(props) {
    const { children, value, index, ...other } = props;


    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box pt={3}>
                    <>{children}</>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
};

function ChatAndMentor(props) {
    const classes = useStyles();
    const intl = useIntl();
    const user = useSelector(state => state?.auth?.user);
    const course = useSelector(state => state?.retreatCourse?.trainingCourseDetails);
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    return (
        <Grid container>
            <Grid item xs={12}>
                <StyledTabs
                    value={value}
                    onChange={handleChange}
                    aria-label="styled tabs example"
                    style={{ borderBottom: '1px solid #e8e8e8' }}
                >
                    <StyledTab
                        label={intl.formatMessage({ id: "mentors" })}
                    />

                    <StyledTab
                        label={intl.formatMessage({ id: "privateChat" })}
                    />
                </StyledTabs>

            </Grid>

            <Grid item xs={12}>
                <TabPanel value={value} index={0}  >
                    {course?.mentors?.length > 0 ? course?.mentors?.map(mentor => <div key={mentor?.id} style={{ cursor: 'pointer' }} onClick={() => window.open(`${window.location.origin}/view-profile/${mentor?.id}`)}><MentorCard mentor={mentor} /></div>) : 'No mentor found'}
                </TabPanel>
                <TabPanel value={value} index={1}  >

                    {(course?.isUserTaking || course?.user?.id === user?.id || course?.isMentor) ? <PrivateChat /> : 'Will be available after join'}
                </TabPanel>




            </Grid>

        </Grid>
    );
}

export default ChatAndMentor;

const useStyles = makeStyles(theme => ({
    container: {
        height: '100vh',
        background: '#FBFBFB',
        padding: '30px 15px 50px 15px'
    },
    card: {
        width: '100%',
        background: '#FFFFFF',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.05)',
        borderRadius: '10px',
    },

}));