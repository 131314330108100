import React, { useEffect, useState } from "react";
import config from "./configs";
import Application from "./containers/App";

import AddToHomescreen from "react-add-to-homescreen";
import InstallDailog from "./components/Common/InstallDialog";
import RelateIcon from "./assets/Icons/RelateIcon.png";
const App = () => {
  const [open, setOpen] = useState(false);
  const [addTo, setAddTo] = useState(false);

  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      const isIos = () => {
        const userAgent = window.navigator.userAgent.toLowerCase();
        return /iphone|ipad|ipod/.test(userAgent);
      };
      const isInStandaloneMode = () =>
        "standalone" in window.navigator && window.navigator.standalone;
      if (isIos() && !isInStandaloneMode()) {
        console.log("test pwa install");
        setOpen(true);
      }
    }
  }, []);
  console.log(open,"open")
  return (
    <>
      {" "}
      <Application config={config} style={{ margin: 0 }} />
      {addTo ? (
        <>
          {" "}
          <div style={{ zIndex: 9999 }}>
            <AddToHomescreen
              style={{ backgroundColor: "red", color: "blue", zIndex: "9999" }}
              title="Add Omide.life to Home screen"
              icon={RelateIcon}
              onAddToHomescreenClick={() => {
                setOpen(true);
                setAddTo(false);
              }}
            />
          </div>
        </>
      ) : null
      }
      {open ? <InstallDailog open={open} setOpen={setOpen} /> : null}
    </>
  );
};

export default App;
