import React from "react";
import ForgotPassword from '../../../containers/Common/ForgotPassword'


const ChooseIdentity = () => {




  return (
    <React.Fragment>
      <ForgotPassword />
    </React.Fragment>
  );
}


export default ChooseIdentity;