// import axios from "axios";
import API from "../utils/API";
// types
import {
  UPDATE_PROFILE,
  SHOW_MESSAGE,
  UPLOADED_IMAGE,
  GET_USER_FRIENDS,
  SET_CURRENT_LOGGED_IN_USER,
  LOADER
} from "./types";



export const updateUserProfile = (data, token) => async dispatch => {

  // set header
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    }
  };
  // set body
  const body = JSON.stringify(data);

  try {
    const res = await API.patch(`/api/v1/user/updateUserProfile`, body, config);

    dispatch({
      type: UPDATE_PROFILE,
      payload: res.data.data
    });

    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        title: `success`,
        message: res.data.message,
        severity: "success",
      },
    });
     dispatch(getCurrentLoggedInUser({ token: token }));
     return 'success'
  } catch (error) {
    console.log(error.response.data.message);

    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        title: `Failed`,
        message:
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : `update profile failed`,
        severity: "error",
      },
    });
    return 'fail'

  }
};

export const uploadPic = (data, url, token) => async dispatch => {

  // set header
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    }
  };


  try {
    const res = await API.post(`/api/v1/user/${url}`, data, config);
    dispatch({
      type: UPLOADED_IMAGE,
      payload: {
        ...res.data.data,
        pictureUrl: `${res.data.data.pictureUrl}?updated_at=${new Date()}`,
        picturePath: `${res.data.data.picturePath}?updated_at=${new Date()}`,
        coverUrl:  `${res.data.data.coverUrl}?updated_at=${new Date()}`
      }
    });
    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        title: `success`,
        message: res.data.message,
        severity: "success"
      }
    });
  } catch (error) {
    console.log(error);

    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        title: `Failed`,
        message: `${url} Failed`,
        severity: "error"
      }
    });
  }
};
export const getUsersFriends = token => async dispatch => {
  dispatch({
    type: LOADER,
    payload: true
  });
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    }
  };

  try {
    const res = await API.get(`/api/v1/userRelation/getUserFriends`, config);
    dispatch({
      type: GET_USER_FRIENDS,
      payload: res.data.data
    });
    dispatch({
      type: LOADER,
      payload: false
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: LOADER,
      payload: false
    });
  }
};

export const getCurrentLoggedInUser = ({ token }) => async dispatch => {
  // set header
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    }
  };

  try {
    const res = await API.get(`/api/v1/user/getLoggedInUserProfile`, config);
    dispatch({
      type: SET_CURRENT_LOGGED_IN_USER,
      payload: res.data.data
    });
    return res
  } catch (error) {
    return error
  }
};
