import React, { useState, useEffect } from 'react'
import { Grid, Box, Typography } from '@material-ui/core'
import { DoneAll } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import {useSelector} from 'react-redux'

function SenderMessage(props) {
    const classes = useStyles();
    const [readStatus, setReadStatus] = useState(true);
    const chatMessages = useSelector(state => state?.chat?.chatMessages);

    return (
        <Box>
            <Grid container >
                <Grid xs={11} />
                <Grid xs={1}>
                    <div className={classes.chatUserPic} style={{ backgroundImage: `url(${props?.message?.sender?.pictureUrl})` }}>
                    </div>
                </Grid>

                <Grid item xs={12} container className={classes.container}>
                    <Grid item xs={12}>
                        <Typography className={classes.message}>{props?.message?.message}</Typography>
                    </Grid>
                    {(chatMessages?.length > 0 && chatMessages[chatMessages?.length - 1]?.id === props?.message?.id ? chatMessages[chatMessages?.length - 1]?.read : props?.message?.read) && <Grid item xs={12} style={{ color: '#09A9BB', textAlign: 'right', paddingRight: "3px" }}>
                        <DoneAll />
                    </Grid>}
                </Grid>
            </Grid>
        </Box>
    )
}

export default SenderMessage
const useStyles = makeStyles(theme => ({
    container: {
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.05)',
        background: '#FFFFFF',
        borderRadius: '9px',
        minHeight: '68px',
        width: '100%',
        marginRight: '14px',
        margin: '-18px 0px 0px 0'
    },
    message: {
        color: '#525252',
        fontFamily: theme.fontFamily.Roboto,
        fontStyle: "normal",
        fontWeight: 'normal',
        fontSize: "14px",
        lineHeight: "19px",
        padding: '12px 10px 10px 17px'
    },
    chatUserPic: {
        height: '34px',
        width: '34px',
        borderRadius: '11px',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        zIndex: 100,
        position: 'relative',
       
    }
}))