import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Fab from "@material-ui/core/Fab";

const useStyles = makeStyles(theme => ({
  styles: {
    margin: theme.spacing(4),
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    background: 'none',
    border: "2px solid #FFFFFF",
    "&:hover": {
      background: "#09A9BB",
      border: 'none'
    }
  }
}));

const LetStartButton = ({
  text,
  onClick,
  fontFamily,
  fontStyle,
  fontSize,
  fontWeight,
  lineHeight,
  letterSpacing,
  width,
  key
}) => {
  const classes = useStyles();

  return (
    <div key={key}>
      <Fab
        variant="extended"
        aria-label="add"
        className={classes.styles}
        style={{
          color: "white",
          fontFamily,
          fontSize,
          fontStyle,
          fontWeight: fontWeight,
          lineHeight,
          letterSpacing,
          width
        }}
        onClick={onClick}
      >
        {text}
      </Fab>
    </div>
  );
};

export default LetStartButton;
