import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Grid,
  AppBar,
  Tab,
  Box,
  useMediaQuery,
  Tabs,
  Button,
  Typography,
} from "@material-ui/core";
import { makeStyles, useTheme, withStyles } from "@material-ui/styles";

import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import CardWithThumbnail from "../../Common/Session/InviteFriendCard";
import CheckIcon from "@material-ui/icons/Check";
import AddIcon from "@material-ui/icons/Add";
import Invitations from "./SessionInvitations";
import { useHistory } from "react-router-dom";
import InviteFriends from "../../Common/InviteFriends/Index";
function Invite(props) {
  const classes = useStyles();
  let history = useHistory();

  const [invited, setInvited] = useState(
    props.sessionData && props.sessionData.userIds
      ? props.sessionData.userIds
      : []
  );
  const [sessionUsers] = useState(
    props?.sessionData?.sessionUsers ? props?.sessionData?.sessionUsers : []
  );

  const intl = useIntl();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleInvite = (id) => {
    if (sessionUsers.indexOf(id) === -1) {
      let found = invited.indexOf(id);
      if (found === -1) {
        setInvited([...invited, id]);
      } else {
        let invite = [...invited];
        invite.splice(found, 1);
        setInvited([...invite]);
      }
    }
  };
  const chcekInvited = (id) => {
    if (invited.indexOf(id) === -1 && sessionUsers.indexOf(id) === -1) {
      return false;
    } else {
      return true;
    }
  };
  const handleCancle = () => {
    history.push("/more");
  };
  const handleGoNext = () => {
    props.handleNext({
      userIds: [...invited],
    });
  };

  return (
    <Grid container>
      <Grid item lg={6} xs={12} md={7} sm={7} xl={6} style={{marginLeft:'30px'}}>
        <InviteFriends
          handleInvite={handleInvite}
          chcekInvited={chcekInvited}
          invited={invited}
        />
        <Grid
          item
          xs={12}
          style={{
            marginTop: "45px",
            paddingLeft: isMobile ? "0" : "0",
            padding: isMobile ? "10px" : "0px",
          }}
        >
          <Button
            className={classes.nextBtn}
            style={{
              width: isMobile ? "100%" : "100%",
            }}
            onClick={() => handleGoNext()}
          >
            {props.screen === "live"
              ? intl.formatMessage({ id: "goLive" })
              : intl.formatMessage({ id: "finish" })}
          </Button>
        </Grid>

        {isMobile && (
          <Grid
            style={{
              paddingLeft: "10px",
              paddingRight: "10px",
            }}
            xs={12}
            sm={12}
            md={12}
            lg={12}
          >
            <Button
              onClick={() => handleCancle()}
              className={classes.btnCancle}

            >
              {intl.formatMessage({ id: "cancel" })}
            </Button>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}

export default Invite;

const useStyles = makeStyles((theme) => ({
  btnCancle: {
    width: "100%",
    height: "2.750em",
    borderRadius: "11px",
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: 500,
    textTransform: "none",
    fontSize: "20px",
    lineHeight: "23px",
    color: "#D95959",
    marginBottom: "70px",
  },
  nextBtn: {
    height: "62px",
    background: "linear-gradient(180deg, #FFC000 -13.24%, #F28705 100%)",
    borderRadius: "11px",
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "20px",
    lineHeight: "23px",
    textTransform: "none",
    color: "#FFFFFF",
  },
  appBar: {
    backgroundColor: "rgb(251, 250, 249)",
    width: "100%",
    boxShadow: "none",
    display: "flex",
    justifyContent: "space-between",
  },
  noContent: {
    height: "35vh",
    color: "#7d7b79",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "19px",
    padding: "12px 10px 10px 17px",
    textAlign: "center",
  },
  headerGrid: {
    background: "#FBF1E0",
    height: "2.75em",
    fontSize: "20px",
    boxShadow: `inset 2px 2px 4px rgba(0, 65, 72, 0.05)`,
    borderRadius: `12px`,
  },
  selectedBtn: {
    background: "linear-gradient(180deg, #FFC000 -13.24%, #F28705 100%)",
    width: "100%",
    height: "2.750em",
    borderRadius: "11px",
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: 500,
    textTransform: "none",
    fontSize: "20px",
    lineHeight: "23px",
    color: "#fff",
    marginBottom: "70px",
  },
  unselectedBtn: {
    width: "100%",
    height: "2.750em",
    borderRadius: "11px",
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: 500,
    textTransform: "none",
    fontSize: "20px",
    lineHeight: "23px",
    color: "#F8A303",
    marginBottom: "70px",
  },
  tabLabel: {
    color: "#DFDFDF",
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "19px",
    minWidth: "27%",
    textTransform: "none",
  },
  tabSelected: {
    color: "#F28705",
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "19px",
  },
  indicatorStyling: {
    backgroundColor: "#F28705",
    height: "5px",
    maxWidth: "60px",
  },
  indicatorStylingMobile: {
    backgroundColor: "#F28705",
    height: "5px",
  },
  tabsContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
}));
const useStyles1 = makeStyles((theme) => ({
  title: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "italic",
    fontWeight: "bold",
    fontSize: "18px",
    lineHeight: "21px",
    color: "#3C4A6B",
  },
  body: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "21px",
    color: "#3C4A6B",
  },
  root: {
    width: "100%",
    height: "5rem",
    transform: "rotate(360deg)",
    borderBottom: "1px solid rgba(168, 182, 200, 0.3)",
  },
  container: {
    marginTop: "20px",
  },

  actionBtnStyle: {
    minWidth: "57px",
  },
  headerTitle: {
    fontFamily: theme.fontFamily.Reef,
    color: "#FFFFFF",
    fontStyle: "normal",
    fontWeight: 900,
    fontSize: "26px",
    lineHeight: "35px",
    textAlign: "center",
  },

  closeIcon: {
    color: "#C7C7C7",
    fontSize: "25px",
    padding: "10px",
    cursor: "pointer",
  },
  modelBtnSugestionModel: {
    background: `linear-gradient(#727272 1%,#727272 100%)`,
    height: "73px",
    width: "100%",
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "18px",
    lineHeight: "21px",
    marginTop: "8px",
    color: theme.palette.background.paper,
  },
  modalDescSugestionModel: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "24px",
    lineHeight: "28px",
    color: "#3C4A6B",
    textAlign: "center",
    padding: theme.spacing(2, 4, 2),
  },
  availabilityMsgSugestionModel: {
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "18px",
    textAlign: "center",
    lineHeight: "21px",
    padding: "10px",
  },
  inputBoxSugestionModel: {
    textAlign: "center",
    paddingTop: "20px",
    paddingBottom: "20px",
  },
  paperRateUsModel: {
    position: "absolute",
    height: 400,
    maxWidth: 315,
    minWidth: 300,
    justifyContent: "center",
    backgroundColor: theme.palette.background.paper,
  },
  modelBtnRateUs: {
    background: `linear-gradient(#FBAE02 1%,#FBAE02 100%)`,
    height: "73px",
    width: "100%",
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "18px",
    lineHeight: "21px",
    marginTop: "8px",
    color: theme.palette.background.paper,
  },
  rateusImage: {
    height: "13em",
    width: "100%",
    justifyContent: "center",
    display: "flex",
  },
}));
