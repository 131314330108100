import locales from './locales'
import routes from './routes'
import parseLanguages from '../utils/locale'
import Loading from '../components/Loading'

const config = {
  locale: {
    defaultLocale: parseLanguages(['en', 'de', 'ru'], 'en'),
    locales,
    persistKey: 'base-shell:locale',
    onError: (e) => {
      return
    },
  },
  routes,
  pages: {
    PageNotFound: import('../pages/PageNotFound'),
  },
  components: {
    Loading,
  },
  containers: {
    AppContainer: ({ children }) => (
      <div>{children}</div>
    ),

    LayoutContainer: ({ children }) => (
      <div>{children}</div>
    ),
  },
}

export default config
