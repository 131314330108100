import React, { useReducer, createContext, useEffect, useState } from "react";
import SocketIOClient from "socket.io-client";
import {
  getUserChats,
  getRoomMessages,
  getGroupMessages
} from "../actions/chat";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
export const SocketContext = createContext();

const initialState = {
  contacts: [
    {
      id: "098",
      name: "Diana Prince",
      email: "diana@us.army.mil"
    },
    {
      id: "099",
      name: "Bruce Wayne",
      email: "bruce@batmail.com"
    },
    {
      id: "100",
      name: "Clark Kent",
      email: "clark@metropolitan.com"
    }
  ],
  loading: false,
  error: null
};

const reducer = (state, action) => {
  switch (action.type) {
    case "ADD_CONTACT":
      return {
        contacts: [...state.contacts, action.payload]
      };
    case "DEL_CONTACT":
      return {
        contacts: state.contacts.filter(
          contact => contact.id !== action.payload
        )
      };
    case "START":
      return {
        loading: true
      };
    case "COMPLETE":
      return {
        loading: false
      };
    default:
      throw new Error();
  }
};

export const SocketContextProvider = props => {
  const dispatch = useDispatch();
  const [socketIo, setSocketIo] = useState();
  const [refreshRoom, setRefreshRoom] = useState(null);
  const authReducer = useSelector(state => state.auth);

  useEffect(() => {
    let socket;
    socket = SocketIOClient(process.env.REACT_APP_SOCKET_URL, {
      reconnection: true,
      reconnectionDelay: 5000,
      reconnectionDelayMax: 5000,
      reconnectionAttempts: 2,
      rememberUpgrade: true,
      transports: ["polling", "websocket"]
    });
    socket.emit("connection", () => {
      console.log("socket connected");
    });

    setSocketIo(socket);
    socket.on("message", async message => {
      if (message?.senderId !== authReducer?.user?.id) {
        if (/challenge/.test(message?.roomId)) {
          dispatch(
            getGroupMessages({
              token: authReducer?.token,
              roomId: message?.roomId
            })
          );
        } else {
          setRefreshRoom({
            updatedAt: new Date(),
            roomId: message?.roomId
          })

          dispatch(getUserChats(authReducer?.token));
        }
      }
    });



    return () => {
      socket.emit("disconnect", async function () {
        console.log("socket disconnected...");
      });
    };
  }, []);



  return (
    <SocketContext.Provider value={[socketIo, setSocketIo, refreshRoom, setRefreshRoom]}>
      {props.children}
    </SocketContext.Provider>
  );
};
