import React, { useState, useEffect, forwardRef } from "react";
import {
  Grid,
  TextField,
  Card,
  Button,
  useMediaQuery,
  CircularProgress,
  Typography,
  Box,
  Tooltip,
  Hidden,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  CardContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  IconButton,
  FormControlLabel,
  Switch,
  createMuiTheme,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardTimePicker,
} from "@material-ui/pickers";
import { Add, Done, AccessTime } from "@material-ui/icons";
import Truncate from "react-truncate";
import { useTheme } from "@material-ui/core/styles";
import { makeStyles, withStyles, ThemeProvider } from "@material-ui/styles";
import { ReactSortable, Swap } from "react-sortablejs";
import {
  addEpisode,
  updateEpisodeOrderApi,
  changeSectionStatus,
  updateEpisode,
  deleteEpisode,
  changeEpisodeStatus,
  addSections,
  updateSections,
  deleteSection,
} from "../../../actions/trainingCourses";
import {
  addSectionFile,
  addAssignmentFile,
  deleteSectionOrAssignmentFile,
} from "../../../actions/retreatCourse";
import AddImage from "../../../components/AddCourse/AddImage/Index";
import EpisodeEditCard from "../../../components/AddCourse/EpisodeEditCard";
import DropZoneArea from "../../../components/AddCourse/DropZone/DropZoneArea";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import Editor from "../../../components/Editor";
import SupportedFileCard from "../../../components/AddEditRetreat/FileCard";
import AssignmentCard from "../../../components/AddEditRetreat/AssignmentCard";
import AddAssignmentCard from "../../../components/AddEditRetreat/AddAssignmentCard";
import AddSupportedFileCard from "../../../components/AddEditRetreat/AddSupportedFileCard";
import {
  convertToRaw,
  EditorState,
  ContentState,
  convertFromHTML,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import moment from "moment";

const defaultMaterialTheme = createMuiTheme({
  palette: {},
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        color: { 100: "#fff" },
      },
    },

    MuiPickersModal: {
      dialogAction: {
        color: { 500: "#FBAE02" },
      },
    },
  },
});

const CustomSwitch = withStyles({
  switchBase: {
    "&$checked": {
      color: "#29cc10",
    },
    "&$checked + $track": {
      backgroundColor: "#29cc10",
    },
  },
  checked: {},
  track: {},
})(Switch);

function is_url(str) {
  let regexp =
    /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
  if (regexp.test(str)) {
    return true;
  } else {
    return false;
  }
}

const CustomComponent = forwardRef((props, ref) => {
  return (
    <Grid container spacing={2} ref={ref}>
      {props.children}
    </Grid>
  );
});

const EpisodeAddContainer = (props) => {
  const classes = useStyles();
  let dispatch = useDispatch();
  const [image, setImage] = useState(null);
  const [sections, setSections] = useState([]);
  const [selectedSection, setSelectedSection] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [videoFile, setVideoFile] = useState(null);
  const [episodeUrl, setEpisodeUrl] = useState(null);
  const [episodeName, setEpisodeName] = useState(null);
  const [startTime, setStartTime] = useState(moment("10:00 AM", "LT").format());
  const [endTime, setEndTime] = useState(moment("11:00 AM", "LT").format());
  const [sectionName, setSectionName] = useState("");
  const [lessonLimit, setLessonLimit] = useState("0");
  const [lessonType, setLessonType] = useState("recorded");
  const [liveType, setLiveType] = useState("zoom");
  const [episodeApproach, setEpisodeApproach] = useState("upload");
  const [timeZone, setTimeZone] = useState("");
  const [zoomMeetingUrl, setZoomMeetingUrl] = useState("");
  const [sessionDate, setSessionDate] = useState(moment().format("YYYY/MM/DD"));
  const [selectedEpisodeEdit, setSelectedEpisodeEdit] = useState(null);
  const [selectedEpisode, setSelectedEpisode] = useState(null);
  const [episodeDrag, setEpisodeDrag] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [loader, setLoader] = useState(false);
  const [deleteSectionLoader, setDeleteSectionLoader] = useState(false);
  const [sectionFileLoader, setSectionFileLoader] = React.useState(false);
  const [addAssignmentFileLoader, setAddAssignmentFileLoader] =
    React.useState(false);
  const [isPublished, setIsPublished] = useState(false);
  const [episodeDescription, setEpisodeDescription] = useState(
    EditorState.createEmpty()
  );
  const [openDeleteSection, setOpenDeleteSection] = useState(false);

  const [errors, setErrors] = useState({});
  const [errorsSection, setErrorsSection] = useState({});
  const intl = useIntl();
  const [episodes, setEpisodes] = useState(
    props.courseData &&
      props.courseData.episodes &&
      props.courseData.episodes.length > 0
      ? props.courseData.episodes
      : []
  );
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  let coursesReducerState = useSelector((state) => state.trainingCourse);
  let authReducerState = useSelector((state) => state.auth);

  const timeZones = moment?.tz
    ?.names()
    ?.filter((tz) =>
      tz.match(
        /^(((Africa|America|Antarctica|Asia|Australia|Europe|Arctic|Atlantic|Indian|Pacific)\/.+)|(UTC))$/
      )
    );

  const selectedFile = (file) => {
    if (file) {
      setErrors({ ...errors, image: null });
      setImage(URL.createObjectURL(file));
      setImageFile(file);
    }
  };
  const setSelectedVideoFile = (files) => {
    if (files?.length) {
      setErrors({ ...errors, video: null });
      setVideoFile(files[0]);
    } else {
      setVideoFile(null);
    }
  };

  useEffect(() => {
    if (coursesReducerState?.courseData?.sections?.length > 0) {
      setSections(coursesReducerState.courseData.sections);
    } else {
      setSections([]);
    }
  }, [coursesReducerState]);

  useEffect(() => {
    if (selectedSection) {
      if (selectedSection?.lessons?.length > 0) {
        setEpisodes(selectedSection.lessons);
      } else {
        setEpisodes([]);
      }
      setSectionName(selectedSection?.name);
      setLessonLimit(`${selectedSection?.limit}`);
      setIsPublished(selectedSection?.isPublished);
    } else {
      setSectionName(null);
      setLessonLimit("0");
      setIsPublished(false);
    }
  }, [selectedSection]);

  useEffect(() => {
    if (
      !coursesReducerState?.courseLoader &&
      (selectedEpisode || selectedEpisodeEdit)
    ) {
      setSelectedEpisode(null);
      setSelectedEpisodeEdit(null);
    }
    if (!coursesReducerState.courseLoader) {
      setImage(null);
      setVideoFile("");
      setEpisodeName("");
      setImageFile("");
      setEpisodeUrl("");
      setEpisodeApproach("upload");
      setZoomMeetingUrl("");
      setLiveType("zoom");
      setEpisodeDescription(EditorState.createEmpty());
      setLessonType("recorded");
      setTimeZone("");
      setSessionDate(moment().format("YYYY/MM/DD"));
      setStartTime(moment("10:00 AM", "LT").format());
      setEndTime(moment("11:00 AM", "LT").format());
    }
  }, [coursesReducerState.courseLoader]);

  const handleChangeStatus = async (episode) => {
    if (episode.status === "Active") {
      const res = await dispatch(
        changeEpisodeStatus({
          episodeId: episode.id,
          status: "InActive",
          token: authReducerState.token,
          showMessage: true,
          courseId: coursesReducerState?.courseData?.id,
        })
      );
      if (res.id) {
        const sectionLocal = selectedSection?.lessons.map((item) =>
          item.id === res.id ? { ...item, status: "InActive" } : item
        );
        setSelectedSection({ ...selectedSection, lessons: sectionLocal });
      }
    } else {
      const res = await dispatch(
        changeEpisodeStatus({
          episodeId: episode.id,
          status: "Active",
          token: authReducerState.token,
          showMessage: true,
          courseId: coursesReducerState?.courseData?.id,
        })
      );
      if (res.id) {
        const sectionLocal = selectedSection?.lessons.map((item) =>
          item.id === res.id ? { ...item, status: "Active" } : item
        );
        setSelectedSection({ ...selectedSection, lessons: sectionLocal });
      }
    }
  };

  const handleSectionChangeStatus = async () => {
    const activeSec = coursesReducerState?.courseData?.sections?.filter(
      (item) => item.status === "Active"
    )?.length;
    if (selectedSection.status === "Active") {
      const res = await dispatch(
        changeSectionStatus({
          sectionId: selectedSection.id,
          trainingCourseId: props?.courseData?.id,
          status: "InActive",
          token: authReducerState.token,
          showMessage: true,
        })
      );
      if (res?.status) {
        if (activeSec <= 1) {
          dispatch({
            type: "IN_ACTIVE_LAST_SECTION",
            payload: "InComplete",
          });
        }
        setSelectedSection({ ...selectedSection, status: "InActive" });
      }
    } else {
      const res = await dispatch(
        changeSectionStatus({
          sectionId: selectedSection.id,

          trainingCourseId: coursesReducerState?.courseData?.id,
          status: "Active",
          token: authReducerState.token,
          showMessage: true,
        })
      );
      if (res?.status) {
        setSelectedSection({ ...selectedSection, status: "Active" });
      }
    }
  };

  const handleAddFile = async ({ fileName, file, size, type, sectionId }) => {
    setSectionFileLoader(true);
    const res = await dispatch(
      addSectionFile({
        fileName,
        file,
        size,
        type,
        sectionId,
        token: authReducerState.token,
        courseId: coursesReducerState?.courseData?.id,
      })
    );
    if (res?.id) {
      setSelectedSection({
        ...selectedSection,
        supportedFiles:
          selectedSection?.supportedFiles?.length > 0
            ? [...selectedSection?.supportedFiles, res]
            : [res],
      });
      setSectionFileLoader(false);
    } else {
      setSectionFileLoader(false);
    }
  };

  const handleAddAssignment = async ({
    file,
    name,
    size,
    type,
    sectionId,
    timeZone,
    date,
    time,
    scheduleType,
  }) => {
    setAddAssignmentFileLoader(true);
    const res = await dispatch(
      addAssignmentFile({
        name,
        file,
        size,
        type,
        sectionId,
        timeZone,
        scheduleType,
        date,
        time,
        token: authReducerState.token,
        courseId: coursesReducerState?.courseData?.id,
      })
    );
    if (res?.id) {
      setSelectedSection({
        ...selectedSection,
        assignmentFiles:
          selectedSection?.assignmentFiles?.length > 0
            ? [...selectedSection?.assignmentFiles, res]
            : [res],
      });
      setAddAssignmentFileLoader(false);
    } else {
      setAddAssignmentFileLoader(false);
    }
  };

  useEffect(() => {
    setSections(coursesReducerState?.courseData?.sections);
    if (coursesReducerState?.courseData?.sections?.length > 0) {
      const index = coursesReducerState?.courseData?.sections?.findIndex(
        (item) => item?.id === selectedSection?.id
      );
      index > -1 &&
        setSelectedSection(coursesReducerState?.courseData?.sections[index]);
    }
  }, [coursesReducerState?.courseData?.sections]);

  const handleNextStep = async () => {
    if (sections?.length > 0) {
      setLoader(true);
      props.handleNext();
    }
  };

  const addOrUpdateSection = async (e) => {
    e.preventDefault();
    const errs = validateSectionField();
    setErrors({});
    if (Object.keys(errs).length === 0) {
      if (!selectedSection?.id) {
        const res = await dispatch(
          addSections({
            name: sectionName,
            limit: lessonLimit || 0,
            trainingCourseId: coursesReducerState?.courseData?.id,
            token: authReducerState.token,
          })
        );
      } else {
        const res = await dispatch(
          updateEpisodeOrderApi({
            episodes: episodes,
            token: authReducerState.token,
            trainingCourseId: coursesReducerState?.courseData?.id,
          })
        );
        if (res.success) {
          let response = await dispatch(
            updateSections({
              name: sectionName,
              limit: lessonLimit || 0,
              sectionId: selectedSection?.id,
              trainingCourseId: coursesReducerState?.courseData?.id,
              token: authReducerState.token,
            })
          );
          if (response?.status) {
            setSelectedSection(res.section);
          }
        }
      }
    } else {
      setErrorsSection(errs);
    }
  };

  const validate = () => {
    let errs = {};
    if (!episodeName) {
      errs.episodeName =
        lessonType === "recorded"
          ? "Lesson Name is required"
          : "Session Name is required";
    }
    if (!videoFile && !episodeUrl && lessonType === "recorded") {
      errs.video = "Either lesson url or Video file is required";
    }
    if (episodeUrl && !is_url(episodeUrl) && lessonType === "recorded") {
      errs.video = "Lesson url is not a valid url";
    }
    if (!imageFile && !image) {
      errs.image = "image is required";
    }
    if (!episodeApproach && lessonType === "recorded") {
      errs.episodeApproach = "Lesson approach is required";
    }
    if (!timeZone && lessonType === "live") {
      errs.timeZone = "Timezone is required";
    }
    if (!zoomMeetingUrl && lessonType === "live" && liveType === "zoom") {
      errs.zoomMeetingUrl = "Zoom meeting url is required";
    }
    if (
      zoomMeetingUrl &&
      lessonType === "live" &&
      liveType === "zoom" &&
      !is_url(zoomMeetingUrl)
    ) {
      errs.zoomMeetingUrl = "Zoom meeting url is not valid";
    }
    if (!episodeDescription.getCurrentContent().hasText()) {
      errs.description =
        lessonType === "recorded"
          ? "Lesson description is required"
          : "Session description is required";
    }

    return errs;
  };

  const validateSectionField = () => {
    let errs = {};
    if (!sectionName) {
      errs.sectionName = "Section Name is required";
    }

    return errs;
  };

  const handleSetSelectedSection = (section) => {
    setSelectedSection(section);
    setErrorsSection(null);
  };

  const handleLessonsAddEdit = async (e) => {
    const errs = validate();
    e.preventDefault();
    if (Object.keys(errs).length === 0) {
      const response = selectedEpisodeEdit?.id
        ? await dispatch(
            updateEpisode({
              episodeData: {
                lessonId: selectedEpisodeEdit.id,
                name: episodeName,
                uploadApproach: episodeApproach,
                multimediaUrl:
                  lessonType === "live" && liveType === "zoom"
                    ? zoomMeetingUrl
                    : episodeUrl,
                multimediaType: "video",
                type: lessonType === "live" ? "live" : "published",
                liveType,
                date: moment(sessionDate).format("YYYY-MM-DD"),
                timeZone,
                startTime: moment(startTime).format("HH:mm"),
                endTime: moment(endTime).format("HH:mm"),
                courseId: coursesReducerState?.courseData?.id,
                sectionId: selectedSection.id,
                description: escape(
                  draftToHtml(
                    convertToRaw(episodeDescription.getCurrentContent())
                  )
                ),
              },
              episodeImage: imageFile,
              episodeVideo: videoFile,
              token: authReducerState.token,
            })
          )
        : await dispatch(
            addEpisode({
              episodeData: {
                name: episodeName,
                isFree: true,
                uploadApproach: episodeApproach,
                multimediaUrl:
                  lessonType === "live" && liveType === "zoom"
                    ? zoomMeetingUrl
                    : episodeUrl,
                multimediaType: "video",
                type: lessonType === "live" ? "live" : "published",
                date: moment(sessionDate).format("YYYY-MM-DD"),
                timeZone,
                liveType,
                startTime: moment(startTime).format("HH:mm"),
                endTime: moment(endTime).format("HH:mm"),
                courseId: coursesReducerState?.courseData?.id,
                sectionId: selectedSection.id,
                description: escape(
                  draftToHtml(
                    convertToRaw(episodeDescription.getCurrentContent())
                  )
                ),
              },
              episodeImage: imageFile,
              episodeVideo: videoFile,
              token: authReducerState.token,
            })
          );

      if (response.status) {
        setEpisodes(response.lessons);
        setErrors({});
      }
    } else {
      setErrors(errs);
    }
  };

  useEffect(() => {
    if (coursesReducerState?.lesson?.sectionId === selectedSection?.id) {
      const localEpisodes = episodes.map((episode) => {
        if (episode?.id === coursesReducerState?.lesson?.id) {
          return { ...episode, ...coursesReducerState?.lesson };
        }
        return episode;
      });
      setEpisodes(localEpisodes);
    }
  }, [coursesReducerState?.lesson]);

  const handleDeleteEpisode = async () => {
    const response = await dispatch(
      deleteEpisode({
        episodeData: {
          sectionId: selectedEpisode?.sectionId,
          lessonId: selectedEpisode?.id,
        },
        courseId: coursesReducerState?.courseData?.id,
        token: authReducerState.token,
      })
    );
    if (response.status) {
      setEpisodes(response.lessons);
    }
  };

  const clickCrossHandler = async (file, type) => {
    const res = await dispatch(
      deleteSectionOrAssignmentFile({
        fileId: file?.id,
        type: type,
        courseId: coursesReducerState?.courseData?.id,
        token: authReducerState.token,
      })
    );

    if (res?.id) {
      if (type === "assignment") {
        const assignmentsLocal = selectedSection?.assignmentFiles?.filter(
          (item) => item?.id !== res?.id
        );
        setSelectedSection({
          ...selectedSection,
          assignmentFiles: assignmentsLocal,
        });
      } else if (type === "supportedFile") {
        const supportedFileLocal = selectedSection?.supportedFiles?.filter(
          (item) => item?.id !== res?.id
        );
        setSelectedSection({
          ...selectedSection,
          supportedFiles: supportedFileLocal,
        });
      }
    }
  };

  const handleDeleteSection = async () => {
    setDeleteSectionLoader(true);
    const res = await dispatch(
      deleteSection({
        trainingCourseId: coursesReducerState?.courseData?.id,
        token: authReducerState.token,
        sectionId: selectedSection?.id,
      })
    );
    if (res.status) {
      const localSection = sections.filter(
        (item) => item.id !== selectedSection?.id
      );
      setDeleteSectionLoader(false);
      setSelectedSection(null);
      setOpenDeleteSection(false);
      dispatch({
        type: "SET_TRAINING_COURSE_SECTIONS",
        payload: {
          sections: localSection,
        },
      });
    }
  };

  return (
    <div style={{ background: "#FBFBFB" }}>
      <Dialog
        open={openDeleteSection}
        onClose={() => setOpenDeleteSection(false)}
      >
        <DialogContent>
          <Typography component="h4" variant="h6">
            {intl.formatMessage({ id: "areYouSure" })} {selectedSection?.name}
          </Typography>
          {selectedSection?.status === "Active" &&
            sections.filter(
              (item) =>
                item.status === "Active" && item.id !== selectedSection?.id
            ).length === 0 && (
              <Typography component="p" variant="caption" color="secondary">
                This is last active Section of retreat. If you delete this
                section your retreat status will be turn to incomplete and then
                only be active after you active it by your self. After adding at
                least one active retreat
              </Typography>
            )}
        </DialogContent>
        <DialogActions>
          {deleteSectionLoader ? (
            <CircularProgress size={15} style={{ color: "#F28705" }} />
          ) : (
            <Button onClick={handleDeleteSection} style={{ color: "#F28705" }}>
              {intl.formatMessage({ id: "yes" })}
            </Button>
          )}

          <Button
            onClick={() => {
              setOpenDeleteSection(false);
              setDeleteSectionLoader(false);
            }}
          >
            {intl.formatMessage({ id: "no" })}
          </Button>
        </DialogActions>
      </Dialog>
      <Grid
        container
        style={{
          paddingLeft: isMobile && !isDesktop ? "0px" : "2rem",
          padding: isMobile && !isDesktop ? "0px" : "0px",
        }}
      >
        <Grid
          container
          spacing={2}
          style={{
            marginTop: "2rem",
            marginBottom: "1rem",
            padding: isMobile && !isDesktop ? "10px 20px" : "0px",
          }}
        >
          <Grid item xs={12} md={6} lg={7} container spacing={1}>
            {sections?.map((section) => (
              <Grid key={section?.id} item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    selectedSection?.id !== section.id &&
                      handleSetSelectedSection(section);
                    setErrors({});
                  }}
                  className={
                    selectedSection?.id === section.id
                      ? classes.sessionNumber
                      : section?.status === "Incomplete"
                      ? classes.sessionNumberInProgress
                      : section?.status === "Active"
                      ? classes.sessionActive
                      : classes.sessionInActive
                  }
                  endIcon={section?.status === "Active" ? <Done /> : <></>}
                >
                  {section.name}
                </Button>
              </Grid>
            ))}
          </Grid>
          <Grid item xs={12} md={6} lg={5} container>
            {selectedSection && (
              <Grid
                item
                xs={12}
                container
                justify={isMobile ? "flex-start" : "flex-end"}
              >
                <Grid item>
                  <FormControlLabel
                    style={{ marginRight: "10px" }}
                    control={
                      <CustomSwitch
                        onChange={handleSectionChangeStatus}
                        checked={
                          selectedSection?.status === "Active" ? true : false
                        }
                      />
                    }
                    label={
                      <span className={classes.startTimeTxt}>
                        {selectedSection?.status}
                      </span>
                    }
                  />
                </Grid>

                <Grid item>
                  <Button
                    style={{
                      background: "#F28705",
                      color: "#FFFFFF",
                    }}
                    onClick={() => setOpenDeleteSection(true)}
                  >
                    {" "}
                    Delete Section
                  </Button>
                </Grid>
              </Grid>
            )}
          </Grid>

          <Grid item xs={12} md={10} lg={10}>
            <TextField
              value={sectionName || ""}
              onChange={(e) => {
                setSectionName(e.target.value);
                setErrorsSection({ ...errorsSection, sectionName: null });
              }}
              error={errorsSection && errorsSection.sectionName}
              helperText={errorsSection && errorsSection.sectionName}
              label={intl.formatMessage({ id: "sectionName" })}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={2} lg={2}>
            <FormControl
              className={classes.formControl}
              error={errorsSection && errorsSection.lessonLimit}
            >
              <InputLabel id="demo-simple-select-error-label">
                Section Limit
              </InputLabel>
              <Select
                labelId="demo-simple-select-error-label"
                id="demo-simple-select-error"
                value={lessonLimit}
                onChange={(e) => {
                  setLessonLimit(`${e.target.value}`);
                  setErrors({ ...errorsSection, lessonLimit: null });
                }}
              >
                <MenuItem value={"0"}>No Limit</MenuItem>
                <MenuItem value={"7"}>7</MenuItem>
                <MenuItem value={"14"}>14</MenuItem>
                <MenuItem value={"21"}>21</MenuItem>
                <MenuItem value={"28"}>28</MenuItem>
              </Select>
              <FormHelperText>
                {errorsSection && errorsSection.lessonLimit}
              </FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
        {Boolean(selectedSection) && (
          <>
            <Grid
              container
              spacing={2}
              style={{
                marginTop: "2rem",
                marginBottom: "1rem",
                padding: isMobile && !isDesktop ? "10px 20px" : "0px",
              }}
            >
              <Grid item xs={12} sm={12} md={10}>
                <Typography className={classes.lessonHeading}>
                  {lessonType === "recorded" ? "Lesson" : "Session"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={2}>
                <FormControl
                  className={classes.formControl}
                  error={errors && errors.episodeApproach}
                >
                  <InputLabel id="demo-simple-select-error-label">
                    Lesson Type
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-error-label"
                    id="demo-simple-select-error"
                    value={lessonType}
                    disabled={selectedEpisodeEdit && selectedEpisodeEdit?.id}
                    onChange={(e) => {
                      setLessonType(e.target.value);
                    }}
                  >
                    <MenuItem value={"recorded"}>Pre Recorded</MenuItem>
                    <MenuItem value={"live"}>Live</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              {lessonType === "recorded" && (
                <Grid item xs={12} md={5} lg={5}>
                  <TextField
                    value={episodeUrl || ""}
                    disabled={episodeApproach === "upload"}
                    onChange={(e) => {
                      setEpisodeUrl(e.target.value);
                      setErrors({ ...errors, video: null });
                    }}
                    error={errors && errors.video}
                    helperText={errors && errors.video}
                    label={"Lesson url"}
                    fullWidth
                  />
                </Grid>
              )}
              {lessonType === "recorded" && (
                <Grid item xs={12} md={5} lg={5}>
                  <TextField
                    value={episodeName || ""}
                    onChange={(e) => {
                      setEpisodeName(e.target.value);
                      setErrors({ ...errors, episodeName: null });
                    }}
                    error={errors && errors.episodeName}
                    helperText={errors && errors.episodeName}
                    label={
                      lessonType === "recorded" ? "Lesson name" : "Session Name"
                    }
                    fullWidth
                  />
                </Grid>
              )}

              {lessonType === "recorded" && (
                <Grid item xs={12} md={2} lg={2}>
                  <FormControl
                    className={classes.formControl}
                    error={errors && errors.episodeApproach}
                  >
                    <InputLabel id="demo-simple-select-error-label">
                      Lesson Approach
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-error-label"
                      id="demo-simple-select-error"
                      value={episodeApproach || ""}
                      onChange={(e) => {
                        setEpisodeApproach(e.target.value);
                        setErrors({ ...errors, episodeApproach: null });
                        if (e.target.value === "external") {
                          !selectedEpisodeEdit ||
                          selectedEpisodeEdit?.uploadApproach === "upload"
                            ? setEpisodeUrl("")
                            : setEpisodeUrl(selectedEpisodeEdit.multimediaUrl);
                          setVideoFile("");
                        } else {
                          !selectedEpisodeEdit ||
                          selectedEpisodeEdit?.uploadApproach === "external"
                            ? setEpisodeUrl("")
                            : setEpisodeUrl(selectedEpisodeEdit.multimediaUrl);
                        }
                      }}
                    >
                      <MenuItem value={"upload"}>Video File</MenuItem>
                      <MenuItem value={"external"}>Video Url</MenuItem>
                    </Select>
                    <FormHelperText>
                      {errors && errors.episodeApproach}
                    </FormHelperText>
                  </FormControl>
                </Grid>
              )}
            </Grid>
            <Grid
              container
              spacing={2}
              style={{
                padding: isMobile && !isDesktop ? "10px 20px" : "0px",
              }}
            >
              <Grid item xs={12} md={6} lg={6}>
                <Card elevation={0} style={{ borderRadius: "20px" }}>
                  <AddImage
                    btnText={intl.formatMessage({ id: "addThumbnail" })}
                    image={image}
                    aspect={"1 / 1"}
                    selectedFile={selectedFile}
                  />
                </Card>

                <Grid container>
                  <p className={classes.errorMessage}>
                    {errors && errors.image}
                  </p>
                </Grid>
              </Grid>
              {lessonType === "recorded" && (
                <Grid item xs={12} md={6} lg={6}>
                  <Card elevation={0} style={{ borderRadius: "20px" }}>
                    <DropZoneArea
                      dropzoneText={intl.formatMessage({ id: "drapndrop" })}
                      btnText={intl.formatMessage({ id: "browse" })}
                      files={videoFile}
                      disabled={episodeApproach === "external"}
                      setFiles={setSelectedVideoFile}
                    />
                  </Card>
                  <Grid container>
                    <p className={classes.errorMessage}>
                      {!episodeUrl && errors && errors.video}
                    </p>
                  </Grid>
                </Grid>
              )}

              {lessonType === "live" && (
                <Grid item xs={12} md={6} lg={6} container>
                  <Grid item xs={12}>
                    <TextField
                      value={episodeName || ""}
                      onChange={(e) => {
                        setEpisodeName(e.target.value);
                        setErrors({ ...errors, episodeName: null });
                      }}
                      error={errors && errors.episodeName}
                      helperText={errors && errors.episodeName}
                      label={
                        lessonType === "recorded"
                          ? "Lesson name"
                          : "Session Name"
                      }
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl
                      className={classes.formControl}
                      error={errors && errors.liveType}
                    >
                      <InputLabel id="demo-simple-select-error-label">
                        Live with
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-error-label"
                        id="demo-simple-select-error"
                        value={liveType}
                        onChange={(e) => {
                          setLiveType(e.target.value);
                        }}
                      >
                        <MenuItem value={"zoom"}>Zoom</MenuItem>
                        <MenuItem value={"vimeo"}>Vimeo</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  {lessonType === "live" && liveType === "zoom" && (
                    <Grid item xs={12}>
                      <TextField
                        value={zoomMeetingUrl || ""}
                        onChange={(e) => {
                          setZoomMeetingUrl(e.target.value);
                          setErrors({ ...errors, zoomMeetingUrl: null });
                        }}
                        error={errors && errors.zoomMeetingUrl}
                        helperText={errors && errors.zoomMeetingUrl}
                        label={"Zoom Meeting Url"}
                        fullWidth
                      />
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <Grid container className={classes.date}>
                        <ThemeProvider theme={defaultMaterialTheme}>
                          <KeyboardDatePicker
                            minDate={new Date()}
                            className={classes.date}
                            margin="normal"
                            id="date-picker-dialog"
                            open={isOpen}
                            onOpen={() => {
                              setErrors({ ...errors, sessionDate: null });
                              setIsOpen(true);
                            }}
                            onClick={() => setIsOpen(true)}
                            InputProps={{ readOnly: true }}
                            onClose={() => setIsOpen(false)}
                            format="yyyy/MM/dd"
                            placeholder="yyyy/MM/dd"
                            value={sessionDate}
                            fullWidth
                            onChange={(date) => setSessionDate(date)}
                            KeyboardButtonProps={{
                              "aria-label": "change date",
                            }}
                            label="Session Date"
                          />
                        </ThemeProvider>
                      </Grid>
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid item xs={12}>
                    <Autocomplete
                      id="combo-box-demo"
                      options={timeZones}
                      fullWidth
                      error={errors && errors.timeZone}
                      helperText={errors && errors.timeZone}
                      onChange={(e, value) => {
                        setErrors({ ...errors, timeZone: null });
                        setTimeZone(value);
                      }}
                      value={timeZone || null}
                      getOptionLabel={(zone) =>
                        `(UTC ${moment.tz(zone).format("Z")})  ${zone}`
                      }
                      renderInput={(params) => (
                        <TextField
                          classes={{ root: classes.timeTextField }}
                          {...params}
                          placeholder="Select time zone"
                        />
                      )}
                      label={"Timezone"}
                    />
                    <Grid container>
                      <p className={classes.errorMessage}>
                        {errors && errors.timeZone}
                      </p>
                    </Grid>
                  </Grid>
                </Grid>
              )}

              <Grid
                style={{
                  paddingLeft: isMobile && !isDesktop ? "0px" : "2rem",
                  padding: isMobile && !isDesktop ? "10px 20px" : "0px",
                }}
                item
                xs={12}
                md={6}
                lg={6}
              >
                <Card elevation={0} />
              </Grid>
            </Grid>
            <Grid
              container
              spacing={2}
              style={{
                marginTop: "2rem",
                marginBottom: "1rem",
                padding: isMobile && !isDesktop ? "10px 20px" : "0px",
              }}
            >
              {lessonType === "live" && (
                <Grid item xs={12} sm={12} md={6}>
                  <MuiPickersUtilsProvider
                    utils={DateFnsUtils}
                    style={{ width: "100%" }}
                  >
                    <Grid container className={classes.date}>
                      <ThemeProvider theme={defaultMaterialTheme}>
                        <KeyboardTimePicker
                          className={classes.date}
                          classes={{ root: classes.timeTextField }}
                          margin="normal"
                          id="time-picker"
                          minutesStep={15}
                          value={startTime}
                          onChange={(newValue) => setStartTime(newValue)}
                          KeyboardButtonProps={{
                            "aria-label": "change time",
                          }}
                          InputProps={{ readOnly: true }}
                          keyboardIcon={<AccessTime />}
                          fullWidth
                          label={"Start Time"}
                        />
                      </ThemeProvider>
                    </Grid>
                  </MuiPickersUtilsProvider>
                </Grid>
              )}
              {lessonType === "live" && (
                <Grid item xs={12} sm={12} md={6}>
                  <MuiPickersUtilsProvider
                    utils={DateFnsUtils}
                    style={{ width: "100%" }}
                  >
                    <Grid container className={classes.date}>
                      <ThemeProvider theme={defaultMaterialTheme}>
                        <KeyboardTimePicker
                          className={classes.date}
                          classes={{ root: classes.timeTextField }}
                          margin="normal"
                          id="time-picker"
                          minutesStep={15}
                          value={endTime}
                          fullWidth
                          onChange={(newValue) => setEndTime(newValue)}
                          KeyboardButtonProps={{
                            "aria-label": "change time",
                          }}
                          InputProps={{ readOnly: true }}
                          keyboardIcon={<AccessTime />}
                          label={"End Time"}
                        />
                      </ThemeProvider>
                    </Grid>
                  </MuiPickersUtilsProvider>
                </Grid>
              )}
              <Grid item xs={12}>
                <Typography className={classes.descriptionTitle}>
                  Description
                </Typography>
                <>
                  <Editor
                    editorState={episodeDescription}
                    placeholder={"Lesson Description..."}
                    changeDescription={(des) => {
                      setErrors({ ...errors, description: null });
                      setEpisodeDescription(des);
                    }}
                  />
                </>
              </Grid>
              <Grid container>
                <p className={classes.errorMessage}>
                  {errors && errors.description}
                </p>
              </Grid>
              <Grid
                container
                style={{
                  marginTop: "2rem",
                  marginBottom: "1rem",
                  padding: isMobile && !isDesktop ? "0px" : "0px",
                }}
                spacing={1}
              >
                <Grid item xs={12} md={5} lg={5}>
                  <Button
                    style={{
                      background: "transparent",
                      border: "3px solid #F28705",
                      textAlign: "center",
                      padding: "1.5rem",
                      fontFamily: theme.fontFamily.Roboto,
                      fontStyle: "normal",
                      fontWeight: "normal",
                      fontSize: "19px",
                      lineHeight: "22px",
                      color: "#0F0F0F",
                      width: "100%",
                    }}
                    onClick={handleLessonsAddEdit}
                  >
                    {coursesReducerState.courseLoader ? (
                      <CircularProgress size={20} style={{ color: "#000" }} />
                    ) : selectedEpisodeEdit && selectedEpisodeEdit.id ? (
                      intl.formatMessage({ id: "updateLesson" })
                    ) : (
                      intl.formatMessage({ id: "addLesson" })
                    )}
                  </Button>
                  {!Object.values(errors).every((o) => o === null) && (
                    <Grid container>
                      <p className={classes.errorMessage}>
                        {"fix above errors"}
                      </p>
                    </Grid>
                  )}
                </Grid>

                {selectedEpisodeEdit && (
                  <Grid item xs={12} md={4} lg={2}>
                    <Button
                      disabled={coursesReducerState.courseLoader}
                      style={{
                        background: coursesReducerState.courseLoader
                          ? "#C2C2C2"
                          : "#F28705",
                        border: "3px solid #F28705",
                        textAlign: "center",
                        padding: "1.5rem",
                        fontFamily: theme.fontFamily.Roboto,
                        fontStyle: "normal",
                        fontWeight: "normal",
                        fontSize: "19px",
                        lineHeight: "22px",
                        color: "#fff",
                        width: "100%",
                      }}
                      onClick={(e) => {
                        setSelectedEpisode(null);
                        setSelectedEpisodeEdit(null);
                        setLessonType("recorded");
                        setTimeZone("");
                        setSessionDate(moment().format("YYYY/MM/DD"));
                        setStartTime(moment("10:00 AM", "LT").format());
                        setEndTime(moment("11:00 AM", "LT").format());
                        setZoomMeetingUrl("");
                        setLiveType("zoom");
                        setImage(null);
                        setVideoFile("");
                        setEpisodeName("");
                        setImageFile("");
                        setEpisodeUrl("");
                        setEpisodeApproach(null);
                        setEpisodeDescription(null);
                        setEpisodeDescription(EditorState.createEmpty());
                      }}
                    >
                      {intl.formatMessage({ id: "cancel" })}
                    </Button>
                  </Grid>
                )}
              </Grid>
              <Grid xs={12}>
                <Typography style={{ color: "#40B58A", marginBottom: "5px" }}>
                  Don't leave page if episode status is in progress!
                </Typography>
              </Grid>
              <Hidden smDown>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    {episodes?.length > 1 && (
                      <Typography>
                        <span style={{ color: "red" }}>*</span>
                        {intl.formatMessage({ id: "dragTextRetreat" })}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <ReactSortable
                      list={episodes}
                      onChange={(e) => {
                        console.log("event", e);
                      }}
                      setList={(newList) => {
                        console.log(newList);
                        setEpisodes(newList);
                      }}
                      direction="horizontal"
                      tag={CustomComponent}
                    >
                      {episodes &&
                        episodes.length > 0 &&
                        episodes.map((ep) => (
                          <Grid
                            key={ep.id}
                            id={ep.id}
                            item
                            xs={12}
                            md={6}
                            lg={4}
                          >
                            <EpisodeEditCard
                              title={ep.name}
                              imageUrl={ep.thumbnail}
                              id={ep.id}
                              type={ep.type}
                              multimediaUrl={ep.multimediaUrl}
                              episodeVideoProgress={
                                coursesReducerState?.episodeVideoProgress?.filter(
                                  (item) => item.episode_id === ep.id
                                )[0]?.percentage
                                  ? coursesReducerState?.episodeVideoProgress?.filter(
                                      (item) => item.episode_id === ep.id
                                    )[0].percentage
                                  : 0
                              }
                              showWarning={Boolean(
                                episodes.filter(
                                  (item) => item.status === "Active"
                                ).length <= 1
                              )}
                              episodeVideoUploadLoader={
                                coursesReducerState?.videoUploadLoaders?.includes(
                                  ep.id
                                )
                                  ? true
                                  : false
                              }
                              handleChangeStatus={() => handleChangeStatus(ep)}
                              status={ep.status}
                              setSelectedEpisode={setSelectedEpisode}
                              selectedEpisode={selectedEpisode}
                              deleteClick={() => setSelectedEpisode(ep)}
                              handleDeleteEpisode={handleDeleteEpisode}
                              editClick={() => {
                                setImage(ep?.thumbnail);
                                setSelectedEpisodeEdit(ep);
                                setSessionDate(
                                  moment(ep?.date).format("YYYY/MM/DD")
                                );
                                setStartTime(
                                  moment.utc(ep?.startDateTime).format()
                                );
                                setEndTime(
                                  moment.utc(ep?.endDateTime).local().format()
                                );
                                setTimeZone(ep?.timeZone);
                                setLessonType(
                                  ep?.type === "live" ? "live" : "recorded"
                                );
                                setZoomMeetingUrl(ep?.multimediaUrl);
                                setLiveType(ep?.liveType);
                                setVideoFile(ep?.videoFile);
                                setEpisodeName(ep?.name);
                                setImageFile(ep?.imageFile);
                                setEpisodeUrl(ep?.multimediaUrl);
                                setEpisodeApproach(ep.uploadApproach);
                                const blocksFromHtml = convertFromHTML(
                                  unescape(ep?.description)
                                );
                                const { contentBlocks, entityMap } =
                                  blocksFromHtml;
                                const contentState =
                                  ContentState.createFromBlockArray(
                                    contentBlocks,
                                    entityMap
                                  );
                                const editorState =
                                  EditorState.createWithContent(contentState);
                                ep?.description
                                  ? setEpisodeDescription(editorState)
                                  : setEpisodeDescription(
                                      EditorState.createEmpty()
                                    );
                              }}
                            />
                          </Grid>
                        ))}
                    </ReactSortable>
                  </Grid>
                </Grid>
              </Hidden>
              <Hidden mdUp>
                <Dialog
                  fullScreen
                  style={{
                    paddingTop: "1rem",
                    paddingBottom: "1rem",
                  }}
                  open={episodeDrag}
                >
                  <DialogTitle>
                    <Typography component="h4" variant="h5">
                      {intl.formatMessage({ id: "dragTextRetreat" })}
                    </Typography>
                  </DialogTitle>
                  <DialogContent>
                    <ReactSortable
                      list={episodes}
                      onChange={(e) => {
                        console.log("event", e);
                      }}
                      setList={(newList) => {
                        console.log(newList);
                        setEpisodes(newList);
                      }}
                      direction="vertical"
                      tag={CustomComponent}
                    >
                      {episodes &&
                        episodes.length > 0 &&
                        episodes.map((ep) => (
                          <Grid key={ep.id} id={ep.id} spacing={1} item xs={11}>
                            <Card>
                              <CardContent style={{ padding: ".8rem" }}>
                                <Typography component="p" variant="p">
                                  <Truncate
                                    lines={1}
                                    ellipsis={<span>...</span>}
                                  >
                                    {ep.name}
                                  </Truncate>
                                </Typography>
                              </CardContent>
                            </Card>
                          </Grid>
                        ))}
                    </ReactSortable>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      style={{
                        background: "#F28705",
                        border: "3px solid #F28705",
                        textAlign: "center",
                        paddingLeft: "1.5rem",
                        paddingRight: "1.5rem",
                        fontFamily: theme.fontFamily.Roboto,
                        fontStyle: "normal",
                        fontWeight: "normal",
                        fontSize: "19px",
                        lineHeight: "22px",
                        color: "#fff",
                      }}
                      onClick={() => setEpisodeDrag(false)}
                    >
                      Ok
                    </Button>
                  </DialogActions>
                </Dialog>

                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    {episodes?.length > 1 && (
                      <Typography>
                        <span style={{ color: "red" }}>*</span>
                        {intl.formatMessage({ id: "dragTextRetreat" })}
                      </Typography>
                    )}
                  </Grid>

                  <Grid item xs={12}>
                    {episodes &&
                      episodes.length > 0 &&
                      episodes.map((ep) => (
                        <Grid key={ep.id} id={ep.id} item xs={12} md={6} lg={4}>
                          <EpisodeEditCard
                            title={ep.name}
                            imageUrl={ep.thumbnail}
                            id={ep.id}
                            multimediaUrl={ep.multimediaUrl}
                            episodeVideoProgress={
                              coursesReducerState?.episodeVideoProgress?.filter(
                                (item) => item.episode_id === ep.id
                              )[0]?.percentage
                                ? coursesReducerState?.episodeVideoProgress?.filter(
                                    (item) => item.episode_id === ep.id
                                  )[0].percentage
                                : 0
                            }
                            showWarning={Boolean(
                              episodes.filter(
                                (item) => item.status === "Active"
                              ).length <= 1
                            )}
                            episodeVideoUploadLoader={
                              coursesReducerState?.videoUploadLoaders?.includes(
                                ep.id
                              )
                                ? true
                                : false
                            }
                            setSelectedEpisode={setSelectedEpisode}
                            selectedEpisode={selectedEpisode}
                            handleChangeStatus={() => handleChangeStatus(ep)}
                            deleteClick={() => setSelectedEpisode(ep)}
                            handleDeleteEpisode={handleDeleteEpisode}
                            status={ep.status}
                            editClick={() => {
                              setImage(ep.thumbnail);
                              setSelectedEpisodeEdit(ep);

                              setSessionDate(
                                moment(ep?.date).format("YYYY/MM/DD")
                              );
                              setStartTime(
                                moment.utc(ep?.startDateTime).format()
                              );
                              setEndTime(
                                moment.utc(ep?.endDateTime).local().format()
                              );
                              setTimeZone(ep?.timeZone);
                              setLessonType(
                                ep?.type === "live" ? "live" : "recorded"
                              );
                              setLiveType(ep?.liveType);
                              setVideoFile(ep.videoFile);
                              setEpisodeName(ep.name);
                              setLiveType(ep.liveType);
                              setZoomMeetingUrl(ep.multimediaUrl);
                              setImageFile(ep.imageFile);
                              setEpisodeUrl(ep.multimediaUrl);
                              setEpisodeApproach(ep.uploadApproach);
                              const blocksFromHtml = convertFromHTML(
                                unescape(ep?.description)
                              );
                              const { contentBlocks, entityMap } =
                                blocksFromHtml;
                              const contentState =
                                ContentState.createFromBlockArray(
                                  contentBlocks,
                                  entityMap
                                );
                              const editorState =
                                EditorState.createWithContent(contentState);
                              setEpisodeDescription(editorState);
                            }}
                          />
                        </Grid>
                      ))}
                  </Grid>
                </Grid>
                <Grid container justify="center">
                  <Grid item xs={11}>
                    {episodes?.length > 1 && (
                      <Button
                        disabled={coursesReducerState.courseLoader}
                        style={{
                          background: coursesReducerState.courseLoader
                            ? "#C2C2C2"
                            : "#F28705",
                          border: "3px solid #F28705",
                          textAlign: "center",
                          padding: "1.5rem",
                          fontFamily: theme.fontFamily.Roboto,
                          fontStyle: "normal",
                          fontWeight: "normal",
                          fontSize: "19px",
                          lineHeight: "22px",
                          color: "#fff",
                          width: "100%",
                        }}
                        onClick={() => setEpisodeDrag(true)}
                      >
                        Change Lesson order
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </Hidden>

              <Grid item xs={12}>
                <Typography className={classes.heading}>
                  <>
                    Add Supported Files{" "}
                    <AddSupportedFileCard
                      section={selectedSection}
                      handleAddFile={handleAddFile}
                      loader={sectionFileLoader}
                    />
                    {/* <IconButton
                
                  className={classes.button}
                >
                  <Add />
                </IconButton> */}
                  </>
                </Typography>
              </Grid>

              <Grid item xs={12} container spacing={1}>
                {selectedSection?.supportedFiles?.length > 0 ? (
                  selectedSection?.supportedFiles?.map((file) => (
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={6}
                      lg={4}
                      xl={4}
                      key={file?.id}
                    >
                      {" "}
                      <SupportedFileCard
                        file={file}
                        type={file?.type}
                        fileName={file?.name}
                        clickCrossHandler={() =>
                          clickCrossHandler(file, "supportedFile")
                        }
                      />{" "}
                    </Grid>
                  ))
                ) : (
                  <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                    <Typography>No file uploaded yet</Typography>
                  </Grid>
                )}
              </Grid>

              <Grid item xs={12} container>
                <Typography className={classes.heading}>
                  <>
                    Add Assignment{" "}
                    <AddAssignmentCard
                      loader={addAssignmentFileLoader}
                      section={selectedSection}
                      handleAddAssignment={handleAddAssignment}
                    />
                  </>
                </Typography>
              </Grid>
              <Grid item xs={12} container spacing={1}>
                {selectedSection?.assignmentFiles?.length > 0 ? (
                  selectedSection?.assignmentFiles?.map((file) => (
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={6}
                      lg={4}
                      xl={4}
                      key={file?.id}
                    >
                      {" "}
                      <AssignmentCard
                        file={file}
                        type={file?.type}
                        fileName={file?.name}
                        clickCrossHandler={() =>
                          clickCrossHandler(file, "assignment")
                        }
                      />{" "}
                    </Grid>
                  ))
                ) : (
                  <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                    <Typography>No file uploaded yet</Typography>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </>
        )}
      </Grid>

      <Grid
        item
        xs={12}
        container
        justify="space-between"
        style={{
          paddingLeft: isMobile && !isDesktop ? "0" : "2rem",
          padding: isMobile && !isDesktop ? "10px" : "0px",
        }}
      >
        <Grid item xs={12} md={5} lg={5}>
          <Button
            style={{
              background: "transparent",
              border: "3px solid #F28705",
              textAlign: "center",
              padding: "1.1rem",
              fontFamily: theme.fontFamily.Roboto,
              fontStyle: "normal",
              fontWeight: "normal",
              fontSize: "19px",
              lineHeight: "18px",
              color: "#0F0F0F",
              width: "100%",
              marginTop: "10px",
            }}
            onClick={addOrUpdateSection}
          >
            {coursesReducerState.courseLoader ? (
              <CircularProgress size={20} style={{ color: "#000" }} />
            ) : selectedSection ? (
              intl.formatMessage({ id: "updateSection" })
            ) : (
              intl.formatMessage({ id: "addNextSection" })
            )}
          </Button>
        </Grid>
        {selectedSection && (
          <Grid item xs={12} md={5} lg={5} justify="flex-end">
            <Button
              style={{
                background: "transparent",
                border: "3px solid #F28705",
                textAlign: "center",
                padding: "1.1rem",
                fontFamily: theme.fontFamily.Roboto,
                fontStyle: "normal",
                fontWeight: "normal",
                fontSize: "19px",
                lineHeight: "18px",
                color: "#0F0F0F",
                width: "100%",
                marginTop: "10px",
              }}
              onClick={() => {
                setErrors({});
                setSelectedSection(null);
              }}
            >
              Add next section
            </Button>
          </Grid>
        )}
      </Grid>

      <Grid
        style={{
          paddingLeft: isMobile && !isDesktop ? "0" : "2rem",
          padding: isMobile && !isDesktop ? "10px" : "0px",
        }}
        item
        xs={12}
        md={6}
        lg={6}
      >
        <Tooltip title={episodes.length === 0 ? "Add at-least one lesson" : ""}>
          <div>
            <Button
              disabled={
                loader ||
                sections?.length === 0 ||
                coursesReducerState?.courseLoader ||
                (selectedEpisodeEdit && selectedEpisodeEdit.id) ||
                sections?.length <= 0
              }
              style={{
                width: "100%",
                height: "2.750em",
                background:
                  loader ||
                  sections?.length === 0 ||
                  coursesReducerState?.courseLoader ||
                  (selectedEpisodeEdit && selectedEpisodeEdit?.id)
                    ? "#C2C2C2"
                    : "linear-gradient(180deg, #FFC000 -13.24%, #F28705 100%)",
                borderRadius: isMobile && !isDesktop ? "50px" : "11px",
                fontFamily: theme.fontFamily.Roboto,
                fontStyle: "normal",
                fontWeight: 500,
                fontSize: "20px",
                lineHeight: "23px",
                color: "#FFFFFF",
                marginBottom: "30px",
                marginTop: "50px",
              }}
              onClick={() => {
                setErrors({});
                handleNextStep();
              }}
            >
              {loader ? (
                <CircularProgress size={20} style={{ color: "#fff" }} />
              ) : (
                intl.formatMessage({ id: "next" })
              )}
            </Button>
          </div>
        </Tooltip>
      </Grid>
    </div>
  );
};

export default EpisodeAddContainer;

const useStyles = makeStyles((theme) => ({
  rootDropZone: {
    width: "100%",
    height: "92%",
    opacity: "0.2",
    border: "2px dashed #000000",
    boxSizing: "border-box",
    borderRadius: "20px",
    marginTop: "10px",
  },
  lessonHeading: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "bold",
    fontWeight: "500",
    fontSize: "18px",
    lineHeight: "16px",
  },
  button: {
    background: "#FBAE02 !important",
    boxShadow: "0px 2px 2px rgba(216, 118, 0, 0.18)",
    color: "#FFF",
    height: "40px",
    width: "40px",
  },
  errorMessage: {
    color: "#f44336",
    margin: 0,
    fontSize: "0.75rem",
    marginTop: " 3px",
    textAlign: "left",
    fontFamily: "Reef",
    fontWeight: 400,
    lineHeight: 1.66,
  },
  courseDescriptionImageCard: {
    height: "15.438em",
    backgroundColor: "#FEEFCC",
    borderRadius: "20px",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "top center",
  },
  cardButton: {
    width: "18em",
    height: "2.488em",
    borderRadius: "20px",
    backgroundColor: "#FBAE02",
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: 300,
    fontSize: "10px",
    lineHeight: "10px",
    color: "#FFFFFF",
    marginTop: "20px",
    margin: "auto",
  },
  cardText: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "16px",
    color: "#363636",
  },
  profile_image: {
    padding: "10px",
    cursor: "pointer",
  },
  progressCard: {
    width: "100%",
    height: "7em",
    borderRadius: "11px",
    transform: "rotate(360deg)",
    marginBottom: "50px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  formControl: {
    width: "100%",
  },
  descriptionTitle: {
    paddingTop: "5px",
    paddingBottom: "5px",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "11px",
    lineHeight: "16px",
    color: "#505050",
    opacity: "0.78",
  },
  sessionNumber: {
    background: "#2A4A67",
    color: "#fff",
    "&:hover": {
      background: "#2A4A67",
      color: "#fff",
    },
  },
  sessionActive: {
    background: "#52b202",
    color: "#fff",
    "&:hover": {
      background: "#52b202",
      color: "#fff",
    },
  },
  sessionInActive: {
    background: "#C2C2C2",
    color: "#FFF",
    "&:hover": {
      background: "#C2C2C2",
      color: "#FFF",
    },
  },
  sessionNumberInProgress: {
    background: "#FBAE02",
    color: "#fff",
    "&:hover": {
      background: "#FBAE02",
      color: "#fff",
    },
  },
  heading: {
    marginBottom: "5px",
  },
  startTimeTxt: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: "normal",

    fontSize: "24px",
    lineHeight: "10px",
    color: "#ff9100",
  },
}));
