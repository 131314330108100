import React, { useEffect, useState } from 'react';
import { TextField, Grid, Divider, CircularProgress, Typography } from '@material-ui/core'
import { Send, CameraAlt } from '@material-ui/icons'
import { makeStyles, withStyles } from "@material-ui/core/styles";
import MentorComment from '../../common/MentorComment'
import UserComment from './UserComment';
import { addTextComment, getTrainingCourseComments } from '../../../../actions/retreatCourse'
import { useSelector, useDispatch } from 'react-redux';

function Comments(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [comment, setComment] = useState('');
    const [loader, setLoader] = useState(false);
    const [sendCommentLoader, setSendCommentLoader] = useState(false);

    const user = useSelector(state => state?.auth);
    const course = useSelector(state => state?.retreatCourse?.trainingCourseDetails);
    const comments = useSelector(state => state?.retreatCourse?.trainingCourseComments);


    const getData = async () => {
        setLoader(true)
        const res = await dispatch(getTrainingCourseComments({ trainingCourseId: course.id, token: user?.token, comment }));
        if (res) {
            setLoader(false)
        }
    }



    useEffect(() => {
        getData();
        return () => {
            dispatch({
                type: 'TRAINING_COURSE_SECTION_SUPPORTED_FILES',
                payload: []
            });
        }
    }, []);


    const sendTextComment = async () => {
        if (comment && !sendCommentLoader) {
            setSendCommentLoader(true);
            const res = await dispatch(addTextComment({ trainingCourseId: course.id, token: user?.token, comment }));
            if (res) {
                setSendCommentLoader(false);
                setComment('');
            }
        }
    }

    return (
        <Grid container justify='flex-end' >

            {(course?.isUserTaking || course?.user?.id === user?.user?.id) && <Grid item xs={12} md={10} lg={11} >
                <TextField
                    id="outlined-textarea"
                    placeholder='Type here...'
                    multiline
                    variant="outlined"
                    classes={{ root: classes.messageInputRoot, focused: classes.focused }}
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                    rowsMax={4}
                    onKeyPress={e => {
                        (e.key === 'enter') && sendTextComment();
                    }}
                    InputProps={{
                        endAdornment: (
                            < Send className={classes.iconStyle} style={{ marginLeft: '15px', cursor: 'pointer' }} onClick={sendTextComment} />
                        )
                    }}
                />


            </Grid>}

            {(course?.isUserTaking || course?.user?.id === user?.user?.id) && <Grid item xs={12} md={10} lg={11} className={classes.divider}><Divider /></Grid>}

            {loader ? <Grid item xs={12} className={classes.noContent}>
                <CircularProgress size={20} style={{ color: '#09a9bb' }} />
            </Grid>
                :
                <Grid item container xs={12} className={classes.container}>
                    {
                        comments?.length > 0 ? (
                            comments?.map(comment => (
                                comment?.isMentor ?
                                    (<Grid item xs={12} className={classes.bottomSpace} key={comment?.id}><MentorComment type='comment' comment={comment} /></Grid>) :
                                    (<Grid item xs={12} className={classes.bottomSpace} key={comment?.id}><UserComment comment={comment} /></Grid>)
                            ))
                        ) : <Grid item xs={12} className={classes.noContent} >
                            <Typography className={classes.noContentText}>No comment yet</Typography>
                        </Grid>
                    }
                </Grid>}

        </Grid>
    );
}

export default Comments;

const useStyles = makeStyles(theme => ({
    container: {
        height: '400px',
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
            width: '0px',
            background: 'transparent'
        }
    },

    card: {
        width: '100%',
        background: '#FFFFFF',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.05)',
        borderRadius: '10px',
    },
    bottomSpace: {
        paddingBottom: '15px'
    },
    divider: {
        height: '2px',
        color: '#E2E2E2',
        marginTop: "15px",
        textAlign: 'right'
    },
    messageInputRoot: {
        marginTop: "15px",
        color: '#78849E',
        width: '100%',
        background: '#EBEBEB',
        '& focused': {
            border: '1px solid #E3E3E3 !important',
        }

    },
    focused: {
        border: '1px solid #E3E3E3 !important',
    },
    iconStyle: {
        color: '#3D3D3D'
    },
    noContent: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: "100px"
    },
    noContentText: {
        fontFamily: theme.fontFamily.Roboto,
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "14px",
        color: "#666666",

    }
}));