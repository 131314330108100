import React from "react";
import ResetPassword from '../../../containers/Common/ResetPassword'


const ChooseIdentity = () => {
  return (
    <React.Fragment>
      <ResetPassword />
    </React.Fragment>
  );
}


export default ChooseIdentity;