import React from 'react';
import { Grid } from '@material-ui/core'
import CourseDetail from '../../../../components/AddEditRetreat/CoursePreview/CourseDetail'

function PreviewCourse(props) {
    return (
        <Grid container>
            <Grid item xs={12} md={10} lg={8}>
                <CourseDetail handleNext={props?.handleNext}/>
            </Grid>
           

        </Grid>
    );
}

export default PreviewCourse;