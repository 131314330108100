import React from 'react';
import { Grid, Typography, useMediaQuery, useTheme } from '@material-ui/core'
import { makeStyles } from "@material-ui/core/styles";
import MentorComment from '../../../common/MentorComment';
import AssignmentFile from '../../../common/supportedFileCard'
import moment from 'moment'

function AssignmentDetailCard(props) {
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const { assignment } = props

    return (
        <Grid container style={{ marginBottom: '10px' }}>
            {/* <Grid item xs={12} container style={{marginBottom:'15px'}}>
                <Grid item xs={3} sm={3} md={3} lg={3} xl={2} className={classes.assignmentTitleContainer}>
                    <Typography className={classes.assignmentTitle}>Assignment # 01</Typography>
                    <Typography className={classes.assignmentTime}>Today 12:15</Typography>
                </Grid>
                <Grid item xs={9} sm={9} md={9} lg={9} xl={10} container className={classes.announcementTxtContainer} justify='flex-end'>
                    <Grid item xs={12}>
                        < MentorComment data={{ content: props?.data?.content }} />
                    </Grid>
                    <Grid item xs={10} sm={10} md={11} container style={{ paddingTop: '10px' }}>
                        <Grid item xs={12} sm={12} md={8} lg={5}>
                            <AssignmentFile type='doc' />
                        </Grid>
                    </Grid>

                </Grid>
            </Grid> */}

            {isMobile ? <Grid item xs={12} container className={classes.mobileContainer}>
                <Grid item xs={12} container className={classes.duDateContainer}>
                    <Grid item xs={6}>
                        <Typography className={classes.assignmentTitle}  style={{ textAlign: 'left' }}>Assignment # {props?.ind + 1}</Typography>
                    </Grid>
                    <Grid item xs={6} >
                        <Typography className={classes.assignmentTime} style={{ textAlign: 'right' }}>{moment.utc(assignment?.dateTimeUtc).local().format("MMM DD HH:MM")}</Typography>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <AssignmentFile file={assignment} />
                </Grid>
            </Grid> :

                <Grid item xs={12} container>
                    <Grid item xs={3} sm={3} md={3} lg={3} xl={2} container className={classes.assignmentTitleContainer}>
                        <Grid item xs={12}>
                        <Typography className={classes.assignmentTitle}>Assignment # {props?.ind + 1}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                        <Typography className={classes.assignmentTime}>{moment.utc(assignment?.dateTimeUtc).local().format("MMM DD HH:MM")}</Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={9} sm={9} md={9} lg={9} xl={10} container className={classes.announcementTxtContainer} justify='flex-end'>

                        <Grid item xs={10} sm={10} md={11} container >
                            <Grid item xs={12} sm={12} md={8} lg={5}>
                                <AssignmentFile file={assignment} />
                            </Grid>
                        </Grid>

                    </Grid>
                </Grid>}
        </Grid>
    );
}

export default AssignmentDetailCard;

const useStyles = makeStyles(theme => ({
    cardContainer: {
        minHeight: '40px',
        width: '100%',
        boxShadow: 'none'
    },
    mobileContainer: {
        borderBottom: '2px solid #E2E2E2',
        paddingBottom: '20px'
    },
    duDateContainer: {
        paddingBottom: '15px'
    },
    assignmentTitle: {
        fontFamily: theme.fontFamily.Roboto,
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "16px",
        lineHeight: "100%",
        color: "#B6B6B6",
        paddingBottom: '5px',
        textAlign: 'center',


    },
    assignmentTime: {
        fontFamily: theme.fontFamily.Roboto,
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "14px",
        lineHeight: "100%",
        color: "#B6B6B6",
        textAlign: 'center',


    },
    announcementTxtContainer: {
        paddingLeft: '15px'
    },

    announcementHeading: {
        fontFamily: theme.fontFamily.Roboto,
        fontStyle: "bold",
        fontWeight: 'normal',
        fontSize: "18px",
        lineHeight: "100%",
        color: "#353B35",
        textAlign: 'center',

    },
    assignmentTitleContainer: {
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
        borderRight: '1px solid #3D3D3D',

    }

}));