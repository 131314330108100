import * as React from "react";
import { useAddToHomescreenPrompt } from "./InstallPromptHook.ts";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  Grid,
  useMediaQuery
} from "@material-ui/core";
import logo from "../assets/img/logo512.png";
import {  useTheme } from "@material-ui/core/styles";

function ExampleComponent() {
  const [prompt, promptToInstall] = useAddToHomescreenPrompt();
  const [isVisible, setVisibleState] = React.useState(false);
  const theme = useTheme();
  let isMobile = useMediaQuery(theme.breakpoints.down("sm"))
  const hide = () => setVisibleState(false);

  React.useEffect(() => {
    if (prompt) {
      setVisibleState(true);
    } else if (isVisible) {
      setVisibleState(false);
    }
  }, [prompt]);

  //   if (!isVisible) {
  //     return <div />;
  //   }

  return (
    <Dialog open={isVisible}
      maxWidth={isMobile ? 'md' : 'sm'}
    >
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item >
            <img src={logo} height='25px' />
          </Grid>
          <Grid item >
            Install tantraschooloflove.com?
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="default" onClick={hide}>
          Close
        </Button>
        <Button color="primary" variant="contained" onClick={promptToInstall}>
          Install
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ExampleComponent;
