import React, { useEffect, useState, useRef } from 'react';
import { Grid, CircularProgress, Typography, useMediaQuery, Dialog, DialogContent, DialogTitle, Chip } from '@material-ui/core';
import { Favorite, PlayCircleOutline, CancelRounded, InsertInvitation } from '@material-ui/icons';
import { makeStyles, useTheme } from "@material-ui/core/styles";
import ReactPlayer from 'react-player/lazy'
import clsx from 'clsx';
import MentorCard from './MentorCard'
import onCallStudentIcon from '../../assets/img/onCallStudentIcon.svg'
import absentStudentIcon from '../../assets/img/absentStudentIcon.svg'
import liveIcon from '../../assets/Icons/liveIcon.svg'
import ShareIcon from '../../assets/Icons/RetreatShareIcon.svg'
import { useSelector, useDispatch } from 'react-redux'
import JoinTrainingCourseComponent from './joinTrainingCourse'
import { lessonStartOrCompleteStatus, deleteTrainingCourseFavorite, addFavoriteCourse } from '../../actions/retreatCourse';
import InviteFriends from "../../containers/Common/RetreatContainer/CoursePreview/InviteFriends";
import SocialMediaShare from "../../components/More/Common/SocialMediaShare";
import { useHistory } from 'react-router-dom'
import DetailSection from './detailSectionMobile'
import moment from 'moment'
import { useIntl } from "react-intl";
import IntroVideo from '../../assets/videos/OmidIntro.mp4'
import ExpireSubscriptionPopup from '../../components/Common/ExpireSubscriptionPopup';
import SubscribeNowBtn from '../Common/SubscribeNowBtn';


function AttendanceComponent(props) {
    const classes = useStyles();


    return (
        <Grid container spacing={1} style={{ height: '32px', alignItems: 'center' }}>
            <Grid item >
                <img src={props?.onCall ? onCallStudentIcon : absentStudentIcon} />
            </Grid>
            <Grid item >
                <Typography className={classes.attendanceContent}> {props?.onCall ? 'on-call participants' : 'Absent participants'}</Typography>
            </Grid>
            <Grid item className={classes.noOfStudent} style={{ backgroundColor: props?.onCall ? 'rgba(20, 217, 217, 0.22' : 'rgba(229, 77, 64, 0.34)' }}>
                <Typography >10</Typography>
            </Grid>
        </Grid>
    )
}

function PlayTrainingVideo(props) {
    const classes = useStyles();
    const theme = useTheme()
    const history = useHistory()
    const scrollAble = useRef(null);
    const intl = useIntl();
    const dispatch = useDispatch()
    const [error, setError] = useState(null);
    const [isTaking, setIsTaking] = useState(false);
    // const [videoUrl, setVideoUrl] = useState('');
    const [openInvite, setOpenInvite] = useState(false);
    const [open, setOpen] = useState(false);
    const [preview, setPreview] = useState(false);
    const [stopLoader, setStopLoader] = useState(false);
    const [introVideo, setIntroVideo] = useState(true);
    const [playing, setPlaying] = useState(false);
    const [subscriptionExpire, setSubscriptionExpire] = useState(false);

    const user = useSelector(state => state?.auth?.user);
    const auth = useSelector(state => state?.auth);
    const authUser = useSelector(state => state?.user?.user);
    const course = useSelector(state => state?.retreatCourse?.trainingCourseDetails);
    const lesson = useSelector(state => state?.retreatCourse?.toBePlayedLesson);
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const courseContent = useSelector(state => state?.retreatCourse?.courseContent);
    const reducerUser = useSelector(state => state?.user?.user);
    const configs = useSelector(state => state?.setting?.siteConfig);

    // video URL code 


    // debugger


  const IPFS_BASE_URL = process.env.REACT_APP_IPFS_BASE_URL;
  const videoUrl = introVideo ? IntroVideo : course?.ipfsCid
  ? `${IPFS_BASE_URL}/${course?.ipfsCid}`
  : course.previewUrl;



    useEffect(() => {
        if (course && courseContent && !lesson?.id && (course?.isUserTaking || (course?.user?.id === user?.id))) {
            setIsTaking(course?.isUserTaking || (course?.user?.id === user?.id));
            const index = courseContent?.length > 0 && courseContent.findIndex(item => (!item?.sectionCompleted));
            const sectionIndex = index > -1 ? index : 0;
            const index1 = courseContent[sectionIndex]?.lessons?.findIndex(item => item?.watchStatus !== 'Completed');
            const lessonIndex = index1 > -1 ? index1 : 0;
            dispatch({
                type: "TO_BE_PLAY_TRAINING_COURSE_EPISODE",
                payload: courseContent && courseContent[sectionIndex] && courseContent[sectionIndex]?.lessons && courseContent[sectionIndex]?.lessons[lessonIndex]
            });



        }
    }, [course, courseContent]);


    useEffect(() => {
        setIntroVideo(true)
        setPlaying(false);
        setPreview(false);
        if (lesson && lesson?.type === 'live') {
            if (moment(moment.utc(lesson?.endDateTime).local().format()).isBefore(moment().format())) {
                dispatch(lessonStartOrCompleteStatus({ status: 'Completed', lessonId: lesson?.id, token: auth?.token, trainingCourseId: course?.id }));
            }

            if ((moment(moment.utc(lesson?.startDateTime).local().format()).isBefore(moment().format())) && (moment(moment.utc(lesson?.endDateTime).local().format()).isAfter(moment().format()))) {
                dispatch(lessonStartOrCompleteStatus({ status: 'Started', lessonId: lesson?.id, token: auth?.token, trainingCourseId: course?.id }));
            } else {
                setStopLoader(true)
            }

        }

    }, [lesson?.id])
    useEffect(() => {
        return () => {
            dispatch({
                type: "TO_BE_PLAY_TRAINING_COURSE_EPISODE",
                payload: {}
            });
        }
    }, [])


    const handleStart = () => {

        dispatch(lessonStartOrCompleteStatus({ status: 'Started', lessonId: lesson?.id, token: auth?.token, trainingCourseId: course?.id }));
    }

    const handleEnded = () => {
        setIntroVideo(!introVideo);
        if (!introVideo) {
            setPlaying(false)
        }
        dispatch(lessonStartOrCompleteStatus({ status: 'Completed', lessonId: lesson?.id, token: auth?.token, trainingCourseId: course?.id }));
    }

    const handleFavoriteCourse = () => {
        if (course?.isUserFan) {
            dispatch(deleteTrainingCourseFavorite({ courseId: course?.id, token: auth?.token }));
        } else if (!course?.isUserFan) {
            dispatch(addFavoriteCourse({ courseId: course?.id, token: auth?.token }));
        }

    }

    const isIos = () => {
        const userAgent = window.navigator.userAgent.toLowerCase();
        return /iphone|ipad|ipod/.test(userAgent);
    };

    useEffect(() => {
        scrollAble && scrollAble.current && scrollAble.current.scrollTo(0, 0)
    });

    

    return (
        <Grid container>
            {subscriptionExpire && <ExpireSubscriptionPopup subscriptionExpire={subscriptionExpire} setPopUp={() => setSubscriptionExpire(false)} />}
            {(isMobile && props?.detail) ? <Grid item xs={12} style={{ paddingBottom: "70px" }}>
                <DetailSection switch={props?.switch} />
            </Grid> : <>
                {(1 === 2) && <Grid item xs={12} container className={classes.attendanceContainer}>
                    <Grid item xs={12} sm={12} md={8} lg={8} xl={6} container className={classes.attendanceContainer}>
                        <Grid item xs={12} sm={12} md={6}>
                            <AttendanceComponent onCall={true} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} >
                            <AttendanceComponent />
                        </Grid>
                    </Grid>
                    <div ref={scrollAble} style={{ height: '10px' }}></div>

                    <Grid item xs={12} sm={12} md={4} lg={4} xl={6} className={classes.attendanceContainer}>
                        {'live' === 'live' ? <Typography className={classes.live}><img src={liveIcon} className={classes.liveIcon} /> LIVE <span className={classes.availableDate} style={{ fontStyle: 'normal' }}>15:20</span></Typography> : <Typography className={classes.availableHeading}>Available on <span className={classes.availableDate} >6/2/2021</span></Typography>}
                    </Grid>
                </Grid>}
                {(isTaking && lesson?.type === 'live' && lesson?.liveType === 'zoom' && !introVideo) ? <Grid item xs={12}>
                    <div className={classes.playerWrapper}>
                        <div className={clsx(classes.reactPlayer, classes.preLoadBody)} style={{ background: '#2A2B2C' }}>
                            {(moment(moment.utc(lesson?.startDateTime).local().format()).isAfter(moment().format())) ? <Grid container>
                                <Grid item xs={12}>
                                    <Typography className={classes.startEventHeading}>This event is scheduled for</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography className={classes.startEventDate}>{moment.utc(lesson?.startDateTime).local().format('MMMM DD')}</Typography>
                                </Grid>

                                <Grid item xs={12}>
                                    <Typography className={classes.startEventHeading}>at {moment.utc(lesson?.startDateTime).local().format('HH:mm A')}</Typography>
                                </Grid>

                            </Grid> : (moment(moment.utc(lesson?.endDateTime).local().format()).isBefore(moment().format())) ? <Typography className={classes.startEventHeading}>This Session has been delivered</Typography> : ((moment(moment.utc(lesson?.startDateTime).local().format()).isBefore(moment().format())) && (moment(moment.utc(lesson?.endDateTime).local().format()).isAfter(moment().format()))) ? <Typography className={classes.startEventHeading} style={{ cursor: 'pointer' }} onClick={() => {
                                if (reducerUser?.isExpiredSubscription && course?.plan?.name === 'Subscription' && reducerUser?.id !== course?.user?.id) {
                                    setSubscriptionExpire(true)
                                } else {
                                    window.open(/(http(s?)):\/\//i.test(lesson?.multimediaUrl) ? lesson?.multimediaUrl : `http://${lesson?.multimediaUrl}`, "_blank")
                                }
                            }}>Click to join live session</Typography> : <img src={lesson?.thumbnailUrl} />}

                        </div>
                    </div>
                </Grid> :
                    <Grid item xs={12}>
                        <div className={classes.playerWrapper}>
                            {!stopLoader && <div className={clsx(classes.reactPlayer, classes.preLoadBody)} >
                                <CircularProgress style={{ color: '#09A9BB' }} />
                            </div>}

                            {/* {console.log("conditions   " )}

                               {console.log(isTaking)}
                               {console.log(lesson)}
                               {console.log(playing)}
                               {console.log(preview)} */}
                          


                            {((isTaking && lesson?.multimediaUrl && playing) || (playing && preview)) ? <ReactPlayer
                                controls="true"
                                height="100%"
                                width="100%"
                                className={classes.reactPlayer}
                                style={{ background: (lesson?.type === 'live' && moment(moment.utc(lesson?.startDateTime).local().format()).isAfter(moment().format())) && '#2A2B2C' }}

                                onEnded={handleEnded}
                                onStart={handleStart}
                                playing={playing}
                                onError={() => {
                                    preview && setPreview(false)
                                }}
                                light={''}
                                // url={introVideo ? IntroVideo : preview ? course?.previewUrl : lesson?.multimediaUrl}
                                url={videoUrl}
                                config={{
                                    youtube: {
                                        playerVars: { showinfo: 1 }
                                    },
                                    file: { attributes: { controlsList: 'nodownload' } },



                                }}
                            /> : <>
                                {(course?.previewUrl || introVideo) && <div className={clsx(classes.reactPlayer, classes.preLoadBody)} >
                                    <PlayCircleOutline variant="contained"
                                        color="default"
                                        className={classes.button}
                                        onClick={() => {
                                            if (reducerUser?.isExpiredSubscription && course?.plan?.name === 'Subscription' && reducerUser?.id !== course?.user?.id) {
                                                setSubscriptionExpire(true)
                                            } else {
                                                // !isTaking && setPreview(true)
                                                isTaking && setPreview(true)

                                                setPlaying(true)
                                                setIntroVideo(true)
                                            }

                                        }}
                                    />
                                </div>}
                                <img src={course?.image} height="100%"
                                    width="100%"
                                    className={classes.reactPlayer} />
                            </>
                            }
                        </div>
                    </Grid>}

                <Grid item xs={12} container className={classes.attendanceContainer} style={{ borderBottom: '2px solid #E2E2E2', padding: isMobile && '0px 5px' }}>
                    {user?.id !== course?.user?.id && <Grid item xs={3} md={2} container >
                        <Grid item className={classes.center} onClick={handleFavoriteCourse}>
                            <Favorite style={{ paddingRight: '8px', color: course?.isUserFan ? '#E54D40' : '#C6C6C6', cursor: 'pointer' }} />
                        </Grid>
                        <Grid item className={classes.center}>
                            <Typography>Favorite</Typography>
                        </Grid>
                    </Grid>}
                    {(user?.id === course?.user?.id || course?.isUserTaking) && (course?.isPrivate) && <Grid item xs={4} md={2} container onClick={() => course?.status === 'Active' && setOpenInvite(true)} style={{ paddingTop: '5px', paddingRight: '3px', cursor: 'pointer' }}>
                        <Grid item className={classes.center} >
                            <img src={ShareIcon} style={{ paddingTop: '5px', paddingRight: '3px' }} />
                        </Grid>
                        <Grid item className={classes.center}>
                            <Typography > Invite Friends</Typography>
                        </Grid>
                    </Grid>}
                    {(user?.id === course?.user?.id || course?.isUserTaking) && (!course?.isPrivate) && <Grid item xs={5} sm={5} md={4} lg={3} container>
                        <Grid item xs={6} container onClick={() => course?.status === 'Active' && setOpenInvite(true)} style={{ paddingRight: '3px', cursor: 'pointer' }}>

                            <Grid item className={classes.center} >
                                <InsertInvitation style={{ color: '#C6C6C6' }} />
                            </Grid>
                            <Grid item className={classes.center}>
                                <Typography > Invite </Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={6} container onClick={() => course?.status === 'Active' && setOpen(true)} style={{ paddingRight: '3px', cursor: 'pointer' }}>

                            <Grid item className={classes.center} >
                                <img src={ShareIcon} style={{ paddingTop: '5px', paddingRight: '3px' }} />
                            </Grid>
                            <Grid item className={classes.center}>
                                <Typography > Share</Typography>
                            </Grid>
                        </Grid>
                    </Grid>}


                    <Grid item xs={(user?.id === course?.user?.id || course?.isUserTaking) ? 4 : 8} md={8} className={classes.center} style={{ justifyContent: 'flex-end' }}>

                        {isMobile && user?.id !== course?.user?.id && !course?.isMentor && <JoinTrainingCourseComponent />}
                    </Grid>

                    <Grid item xs={12} container justify='center'>
                        <Grid item xs={12} md={6}>
                            <SubscribeNowBtn />
                        </Grid>
                    </Grid>

                </Grid>



                <Grid item xs={12} container className={classes.mentorDetailContainer}>
                    <Grid item xs={12} container className={clsx(classes.mentorContainer, classes.scroll)} spacing={2} >
                        {course?.mentors?.map(mentor => <Grid item key={mentor?.id} style={{ cursor: 'pointer' }} onClick={() => window.open(`${window.location.origin}/view-profile/${mentor?.id}`)}><MentorCard mentor={mentor} /></Grid>)}
                    </Grid>

                    <Grid item xs={12} >
                        <Typography className={classes.mentorDetailHeading}>DETAIL:</Typography>
                    </Grid>

                    <Grid item xs={12}  >

                        {course?.description ? <Typography className={classes.mentorDetailContent} dangerouslySetInnerHTML={{ __html: unescape(course?.description) }} ></Typography> :
                            <Typography className={classes.mentorDetailContent} style={{ textAlign: 'center', paddingTop: '50px' }}>No description found</Typography>}
                    </Grid>

                    <Grid item xs={12} >
                        <Typography className={classes.mentorDetailHeading}>{course?.retreatType === 'retreat' ? 'Retreat' : 'Teacher Training'} Price:</Typography>
                    </Grid>

                    <Grid item xs={12}  >
                        <Typography className={classes.mentorDetailContent} > {course?.plan?.name === 'Subscription' ? 'Under Subscription' : (course?.plan?.name === 'Free') ? 'Free' : `${configs?.currency} ${course?.price}`} </Typography>
                    </Grid>

                    <Grid item xs={12} >
                        <Typography className={classes.mentorDetailHeading}>{course?.retreatType === 'retreat' ? 'Retreat' : 'Teacher Training'} Type:</Typography>
                    </Grid>

                    <Grid item xs={12}  >
                        <Typography className={classes.mentorDetailContent} > {course?.user?.isPlatform ? `Platform ${course?.retreatType === 'retreat' ? 'Retreat' : 'Teacher Training'}` : `Teacher's ${course?.retreatType === 'retreat' ? 'Retreat' : 'Teacher Training'}`} </Typography>
                    </Grid>

                    {(user?.id !== course?.user?.id && course?.plan?.name === 'Subscription' && course?.isUserTaking) && <>
                        <Grid item xs={12} >
                            <Typography className={classes.mentorDetailHeading}>Availability:</Typography>
                        </Grid>

                        <Grid item xs={12}  >
                            <Typography className={classes.mentorDetailContent} > This is under subscription {course?.retreatType === 'retreat' ? 'Retreat' : 'Teacher Training'} and will be available from {authUser?.purchasedSubscription && moment(authUser?.purchasedSubscription[0]?.startDate).format('DD-MM-YY')} to
                                {authUser?.purchasedSubscription && moment(authUser?.purchasedSubscription[0]?.endDate).format('DD-MM-YY')}</Typography>
                        </Grid>
                    </>}


                </Grid>

                <Dialog open={open} onClose={() => setOpen(false)}>
                    <DialogContent style={{ padding: isIos() && "0px" }}>
                        <SocialMediaShare
                            url={`${window.location.origin}/share/${course?.retreatType}/${course?.slug}`}
                            imageUrl={course?.image}
                            sumaryText={"Here is new retreat"}
                            title={"Hey there,  Check this out, I think you will love it"}
                            tags={
                                course?.courseTags
                                    ? course?.courseTags.map(item => item.name)
                                    : []
                            }
                            header={intl.formatMessage({ id: "retreatShareText" })}
                        />
                    </DialogContent>
                </Dialog>

                {openInvite && (
                    <Dialog open={openInvite} onClose={() => setOpenInvite(false)}>
                        <DialogTitle>
                            <CancelRounded
                                className={classes.cancelBtn}
                                onClick={() => setOpenInvite(false)}
                            />
                        </DialogTitle>
                        <DialogContent>
                            <InviteFriends
                                setOpenInvite={setOpenInvite}
                                course={course}
                            />
                        </DialogContent>
                    </Dialog>
                )}
            </>}
        </Grid>
    );
}

export default PlayTrainingVideo;

const useStyles = makeStyles(theme => ({
    container: {
        height: '100vh',
        background: '#FBFBFB',
        padding: '30px 15px 50px 15px'
    },
    card: {
        width: '100%',
        background: '#FFFFFF',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.05)',
        borderRadius: '10px',
    },
    startEventHeading: {
        fontSize: '1.2em',
        fontWeight: 400,
        textAlign: 'center',
        color: '#FFF',
        fontFamily: "Helvetica"
    },
    startEventDate: {
        fontSize: '2.2em',
        fontWeight: 400,
        textAlign: 'center',
        color: '#FFF',
        fontFamily: "Helvetica"

    },
    cancelBtn: {
        color: "#D0CFCF",
        fontSize: "30px",
        cursor: "pointer",
        float: "right"
    },
    playerWrapper: {
        position: "relative",
        paddingTop: "56.25%",
        marginTop: '15px'
    },
    reactPlayer: {
        position: "absolute",
        top: 0,
        left: 0
    },
    preLoadBody: {
        display: 'flex',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#a9a9a9',
        width: '100%'
    },
    attendanceContainer: {
        minHeight: '80px',
        alignItems: 'center',

    },
    noOfStudent: {
        height: '32px',
        minWidth: '32px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '5px'
    },
    button: {
        textTransform: 'none',
        color: '#09B1D3',
        cursor: 'pointer',
        fontSize: '70px',
        zIndex: 1,
        justifyContent: 'center', display: 'flex', textAlign: 'center',

    },
    attendanceContent: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '16px',
        lineHeight: '100%',
        color: '#353B35',
    },
    availableHeading: {
        fontFamily: 'Roboto',
        fontStyle: 'italic',
        fontWeight: 500,
        fontSize: '20px',
        lineHeight: '100%',
        color: ' #8F8F8F',
        alignItems: 'center',
        display: 'flex',
        height: '80px',
        justifyContent: 'flex-end'
    },
    availableDate: {
        fontFamily: 'Roboto',
        fontStyle: 'italic',
        fontWeight: 500,
        fontSize: '20px',
        lineHeight: '100%',
        color: '#353B35',
        paddingLeft: '7px'
    },
    live: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '20px',
        lineHeight: '100%',
        alignItems: 'center',
        display: 'flex',
        height: '80px',
        justifyContent: 'flex-end',
        color: '#353B35',
    },
    center: {
        display: 'flex',
        alignItems: 'center'
    },
    finishLessonButton: {
        background: '#09A9BB',
        color: '#FFF',
        textTransform: 'none',
        '&:hover': {
            background: '#09A9BB',
        }
    },
    mentorContainer: {
        minHeight: '100px',
        display: 'flex',
        alignItems: 'center',
    },
    mentorDetailContainer: {
        padding: '0px 25px'
    },
    mentorDetailHeading: {
        fontFamily: 'Reef',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '16px',
        paddingTop: '10px',
        color: '#353B35',
    },
    mentorDetailContent: {
        fontFamily: 'Reef',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '16px',
        lineHeight: '25px',
        color: '#434343'
    },
    liveIcon: {
        paddingRight: '10px'
    },
    scroll: {

        paddingTop: '7px',
        flexWrap: "unset",
        overflowX: "auto",
        "&::-webkit-scrollbar": {
            display: "none",
        },
    },
    ratio: {
        position: "relative",
        width: "100%",
        height: 0,

        paddingTop: `56.25%`,
    },

}));