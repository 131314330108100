import React from "react";
import PackageEpisodeCard from "../PackageEpCard";
import { Grid } from "@material-ui/core";
const FreemiumPackageComponent = props => {
  const { episodes, handleChangeLock } = props;
  return (
    <Grid container direction="row" style={{ marginTop: 20 }} spacing={3}>
      {episodes &&
        episodes.map((episode, i) => {
          return (
            <Grid key={episode.id} item xs={12} md={8} lg={5}>
              <PackageEpisodeCard
                imageUrl={episode.thumbnail}
                lock={episode.isFree}
                setLock={handleChangeLock}
                index={i}
                status={episode?.status}
                title={episode.name}
              />
            </Grid>
          );
        })}
      
    </Grid>
  );
};

export default FreemiumPackageComponent;
