import React, { useState } from "react";
import ratingIcon from "../../../assets/Icons/ratingIcon.svg";
import {
  Grid,
  Typography,
  Button,
  TextField,
  useMediaQuery,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import {
  createCourseReviewRating,
} from "../../../actions/courses";
import Rating from '@material-ui/lab/Rating'

import "./courseReview.scss";

function PostReviewCard(props) {
  const classes = useStyles();
  const theme = useTheme();
  let dispatch = useDispatch();

  const [star, setStar] = useState(0);
  const [review, setReview] = useState("");
  let authReducerState = useSelector((state) => state.auth);
  let isMobile = useMediaQuery(theme.breakpoints.down("sm")) ? true : false;
  let courseDetail = useSelector(state => state?.courses?.courseDetail);

 

  const postReview = () => {
    dispatch(
      createCourseReviewRating({
        token: authReducerState.token,
        courseId : courseDetail?.id,
        review,
        rating: star
      })
    );
    setStar(0);
    setReview("");
  };

  return (
    <Grid container direction="row" style={{ paddingBottom: "15px" }}>
      <Grid item xs={12} style={{ paddingBottom: "15px" }}>
        <TextField
          onChange={(e) => setReview(e.target.value)}
          className="postReviewTextField"
          multiline
          rows={12}
          style={{ width: "100%" }}
          placeholder="Write your review..."
          value={review}
        />
      </Grid>
      <Grid item xs={4} sm={3} md={3} lg={2} xl={1}>
        <Button style={{ background: "#0ABB58", borderRadius: "8px" }} disabled={((!review || review.trim().length === 0) && star===0) ? true : false}>
          <Typography
            className={classes.instructorIntroText}
            
            style={{ fontSize: "12px", lineHeight: "16px", color: "#FFFFFF" }}
            onClick={postReview}
          >
            Post Review
          </Typography>
        </Button>
      </Grid>
      {
        courseDetail?.user?.id !== authReducerState?.user?.id &&
        <>
       
          <Grid item xs={5} sm={5} md={5} lg={4}>
          <Rating
          name="simple-controlled"
          size={isMobile  ? 'small' : 'medium'}
          value={star}
          onChange={(event, newValue) => {
            setStar(newValue);
          }}
        />
          
          </Grid>
          <Grid item xs={2} sm={2} md={2}>
            <Typography
              style={{
                color: " #9ACF0E",
                fontSize: isMobile ? "12px" : "16px",
                lineHeight: "25px",
                textAlign: "right",
              }}
            >
              {star === 2
                ? "Average"
                : star > 2 && star <= 4
                  ? "Good"
                  : star === 5
                    ? "Excellent"
                    : ""}
            </Typography>
          </Grid>
        </>
      }
      <Grid item xs={1} sm={1} md={1}>
        {star > 1 && (
          <img
            height="18px"
            width="18px"
            style={{
              textAlign: "left",
              margin: isMobile ? "3px 0px 0px 0px " : "5px 0px -4px 10px ",
            }}
            src={ratingIcon}
            alt=""
          />
        )}
      </Grid>
    </Grid>
  );
}

export default PostReviewCard;

const useStyles = makeStyles((theme) => ({
  instructorIntroText: {
    fontFamily: theme.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: 900,
  },
  ratingStarMobileView: {
    width: "16px",
    height: "16px",
  },
  ratingStarWebView: {
    width: "24px",
    height: "24px",
  },
}));
