import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import ChallengeDetail from '../../../components/ViewChallengeDetail/ChallengeDetail'
import GroupChat from '../../../components/ViewChallengeDetail/GroupChat'
import { useIntl } from "react-intl";
import { Link, useHistory } from 'react-router-dom'
import { ArrowBack } from '@material-ui/icons'
import { makeStyles } from "@material-ui/styles";
import { useSelector } from 'react-redux'


function ViewChallengeDetails() {
    const intl = useIntl()
    const classes = useStyles();
    const history = useHistory()

    const challengeDetail = useSelector(state => state?.challenges?.challengeDetail)
    const auth = useSelector(state => state?.auth)

    const checkInviteHandler = () => {
        let challengeParticipant = challengeDetail?.challengeParticipants?.find(participant => (participant?.id === auth?.user?.id))
        if (challengeParticipant && challengeDetail?.type === 'Group') {
            return true
        }
        return false
    }

    return (
        <Grid container >

            <Grid item xs={8} style={{ padding: '0px 40px 0px 40px' }} className={classes.hideScroll}>
                <Grid item xs={12} container style={{ paddingTop: '40px' }}>
                    <Grid item container sm={1}>
                        <Grid item xs={6} onClick={() => history.goBack()}>
                            {/* <Link to='/more/relate-with-friends' style={{ textDecoration: 'none' }}> */}
                            <ArrowBack style={{ cursor: 'pointer', color: '#3C4A6B' }} />
                            {/* </Link> */}
                        </Grid>
                        <Grid item xs={6}>
                            <Link to='/more/relate-with-friends' style={{ textDecoration: 'none' }}>
                                <Typography className={classes.BackTxt}>{intl.formatMessage({ id: "Back" })}</Typography>
                            </Link>
                        </Grid>


                    </Grid>
                </Grid>

                <ChallengeDetail />
            </Grid>
            <Grid item xs={4} className={classes.hideScroll}>
                {checkInviteHandler() === true && <GroupChat />}
            </Grid>
        </Grid >
    )
}

export default ViewChallengeDetails


const useStyles = makeStyles(theme => ({
    BackTxt: {
        fontFamily: theme.fontFamily.Roboto,
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '20px',
        lineHeight: '23px',
        color: '#3C4A6B',
        textAlign: 'left'
    },
    hideScroll: {
        height: '100vh', overflowY: 'auto',
        "&::-webkit-scrollbar": {
            width: "0px",
            background: "transparent" /* make scrollbar transparent */
        }
    }
}))