import React, { useEffect } from "react";

import ViewProfileWebContainer from "../../containers/Web/ViewProfile";
import { getUserById } from "../../actions/publicUser";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
const ViewProfile = () => {
  const params = useParams();
  const dispatch = useDispatch();

  const authReducer = useSelector((state) => state?.auth);

  useEffect(() => {
    dispatch(
      getUserById({ token: authReducer?.token, userId: params?.userId })
    );
  }, []);

  return (
    <>
      <ViewProfileWebContainer />
    </>
  );
};

export default ViewProfile;
