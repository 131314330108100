import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Avatar } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  imageContainer: {
    width: "5.229em",
    height: "5.229em",
    borderRadius: "9px",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  icon: {
    textAlign: "left",
    cursor: "pointer",
    marginRight: "20px",
  },
  actionText: {
    color: "#C2C2C2",
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "18px",
    lineHeight: "21px",
    textAlign: "left",
    marginRight: "20px",
  },
  header: {
    fontFamily: theme.fontFamily.Reef,
    fontStyle: "normal",
    textAlign: "left",
    fontWeight: 900,
    fontSize: "12px",
    color: "rgba(0, 0, 0, 0.8)",
  },
  control: {
    padding: theme.spacing(2),
  },
  bodyIcon: {
    fontSize: "10px",
    marginTop: "10px",
  },
  marginLeft: {
    paddingLeft: "7px",
  },
  fullHeight: {
    height: "100%",
  },
  root: {
    width: "100%",
    paddingTop: "5px",
    height: "6rem",
    transform: "rotate(360deg)",
    boxShadow: "0px 0px 0px rgba(0, 0, 0, 0.00)",
  },
  bodyStyle: {
    fontSize: "10px",
    color: "#2A4A67",
  },
  menuIconStyle: {
    borderRadius: "26px",
    height: "66px",
    width: "66px",
    justifyContent: "center",
    lineHeight: "78px",
    alignItems: "center",
    display: "flex",
    color: "#fff",
  },
  rootPadding: {
    paddingTop: "5px",
    paddingBottom: "5px",
  },
  container: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    borderBottom: "1px solid rgba(168, 182, 200, 0.3)",
    height: "6.5rem",
    transform: "rotate(360deg)",
    boxShadow: "0px 0px 0px rgba(0, 0, 0, 0.00)",
  },
  image: {
    height: "70px",
    width: "70px",
  },
  username: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "italic",
    fontWeight: "bold",
    fontSize: "18px",
    lineHeight: "21px",
    color: "#3C4A6B",
    marginLeft: "5px",
  },
  name: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "21px",
    color: "#3C4A6B",
    marginTop: "5px",
    marginLeft: "5px",
  },
  friend: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "21px",
    color: '#A9A9A9',
    marginTop: '5px',
    marginLeft: '5px'

},
}));

CardWithThumbNail.propTypes = {
  // thumbnail url
  imageUrl: PropTypes.string,
  // icon component
  icon: PropTypes.string,
  // style object for icon
  iconStyle: PropTypes.object,
  //Selected Icon if change icon on select
  selectedIcon: PropTypes.string,
  //style object for selected icon
  selectedIconStyle: PropTypes.object,
  // shows current selected icon . true for icon and false for selectedIcon
  currentIcon: PropTypes.bool,
  // function to handle icon click .input =currentSelectedIcon :true/false
  handleChange: PropTypes.func,
  // title text to display in card body
  title: PropTypes.string,
  // title text style object
  titleStyle: PropTypes.object,
  // text to diplay under title in card body
  body: PropTypes.string,
  // style object for text displayed under title in card body
  bodyStyle: PropTypes.object,
  //text to display over title in card body
  header: PropTypes.string,
  // true for show icon
  showIconButton: PropTypes.bool,
  // true for show thumbnail
  showImage: PropTypes.bool,
  // style object for card
  rootCard: PropTypes.object,
};
CardWithThumbNail.defaultProps = {
  currentIcon: true,
  showIconButton: false,
  showImage: false,
};
function CardWithThumbNail(props) {
  const classes = useStyles();

  const { title, body } = props;
  return (
    <div>
      <Grid container className={classes.container} justify="space-between">
        <Grid item xs={4} md={3} lg={3}>
          <Avatar src={props?.friend?.pictureUrl} className={classes.image} />
        </Grid>
        <Grid item xs={6} md={7} lg={7}>
          <Typography className={classes.username}>{title && title}</Typography>
          <Typography className={classes.name}>{body && body}</Typography>
          {props?.friend?.isFriend && <Typography className={classes.friend}>Friend</Typography>}
        </Grid>
        <Grid item xs={2}>
          <div
            onClick={() => props.handleInvite(props.id)}
            className={classes.menuIconStyle}
            style={{
              cursor: "pointer",
              alignItems: "baseline",
              background:
                props?.selected === "true"
                  ? "#3abac9"
                  : props?.IconContainerBackground,
            }}
          >
            {props?.Icon}
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default CardWithThumbNail;
