import React from 'react';
import { CardContent, Grid, Card } from '@material-ui/core'
import { makeStyles, withStyles } from "@material-ui/core/styles";
import TopBar from "../../../components/TrainingRetreat/TopBarWeb";
import PlayTrainingVideoCard from '../../../components/TrainingRetreat/PlayTrainingVideo'
import ContentSection from '../../../components/TrainingRetreat/ContentSection'
import ContentProgress from '../../../components/TrainingRetreat/CourseProgessAndParticipants'
import ChatAndMentor from '../../../components/TrainingRetreat/ChatAndMentor'
import { useSelector } from 'react-redux';

function Retreat(props) {
    const classes = useStyles();
    const user = useSelector(state => state?.auth?.user);
    const course = useSelector(state => state?.retreatCourse?.trainingCourseDetails);
    
    return (
        <Grid container className={classes.container}>
            {/* <div ref={scrollAble} style={{ height: '10px' }}></div> */}
            <Grid item xs={12} container spacing={2}>
                <Grid item xs={8} container className={classes.scroll}>
                    <Grid item xs={12} >
                        <Card className={classes.card}>
                            <CardContent style={{ paddingTop: '0px' }}>
                                <Grid container >
                                    <Grid item xs={12} style={{ borderBottom: '2px solid #E2E2E2' }}>
                                        <TopBar />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <PlayTrainingVideoCard />
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>

                    {(course?.isUserTaking || course?.user?.id === user?.id) && <Grid item xs={12} style={{ marginTop: '15px' }}>
                        <Card className={classes.card}>
                            <CardContent style={{ paddingTop: '0px' }}>
                                <ContentSection />
                            </CardContent>
                        </Card>
                    </Grid>}
                </Grid>

                <Grid item xs={4} container style={{ height: '100vh' }} className={classes.scroll}>
                    <Grid item xs={12}>
                        <Card className={classes.card}>
                            <CardContent style={{ paddingTop: '0px' }}>
                                <ContentProgress />
                            </CardContent>
                        </Card>
                    </Grid>

                    <Grid item xs={12}>
                        <Card className={classes.card} style={{ marginTop: '30px' }}>
                            <CardContent style={{ paddingTop: '0px' }}>
                                <ChatAndMentor />
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Grid>

        </Grid>
    );
}

export default Retreat;


const useStyles = makeStyles(theme => ({
    container: {
        MinHeight: '100vh',
        background: '#FBFBFB',
        padding: '30px 15px 50px 15px'
    },

    card: {
        width: '100%',
        background: '#FFFFFF',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.05)',
        borderRadius: '10px',
    },
    scroll:{
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
            width: '0px',
            background: 'transparent', /* make scrollbar transparent */
        }
    }

}));