import React, { useState, useEffect } from "react";
import {
  Card,
  Grid,
  Dialog,
  DialogContent,
  Typography,
  useMediaQuery,
  CircularProgress,
  IconButton,
  Button,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { Close } from "@material-ui/icons";
import ReactPlayer from "react-player/lazy";
import quoteIcon from "../../assets/Icons/quoteIcon.svg";
import clsx from "clsx";
import moment from "moment";

function QuoteOfTheDay(props) {
  const classes = useStyles();
  const theme = useTheme();

  const [image, setImage] = useState();
  const [quoteDialog, setQuoteDialog] = useState(null);
  const [thumbnail, setThumbnail] = useState(null);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const quote = useSelector((state) => state?.courses?.quoteOfTheDay);

  const handleClosePreviewDialog = () => {
    localStorage.setItem("omidQuoteOfTheDay", moment().format("DD-MM-YYYY"));
    setQuoteDialog(null);
    setThumbnail(null);
  };

  useEffect(() => {
    const isSeen = localStorage.getItem("omidQuoteOfTheDay");
    if ((!isSeen || isSeen !== moment().format("DD-MM-YYYY")) && quote?.id) {
      quote && setQuoteDialog(quote);
      toDataURL(quote.authorPicture).then((dataUrl) => {

        setImage(dataUrl);
      });
      // quote && preloadImage(quote.authorPicture);
    }
  }, [quote]);

  function preloadImage(src) {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = function () {
        resolve(img);
      };
      img.onerror = img.onabort = function () {
        reject(src);
      };
      img.src = src;
      setImage(src);
    });
  }
  const toDataURL = (url) =>
    fetch(url)
      .then((response) => response.blob())
      .then(
        (blob) =>
          new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(blob);
          })
    );
  const handleOpenWindow = () => {
    localStorage.setItem("omidQuoteOfTheDay", moment().format("DD-MM-YYYY"));
    setQuoteDialog(null);
    setThumbnail(null);
     window.open(
      /(http(s?)):\/\//i.test(quoteDialog?.referenceUrl)
        ? quoteDialog?.referenceUrl
        : `http://${quoteDialog?.referenceUrl}`,
      "_blank"
    )

  }
  return (
    <Grid
      container
      direction="column"
      justify="center"
      style={{ marginLeft: "112px" }}
    >
      <Grid item xs={12}>
        {quoteDialog?.type === "image" ? (
          image && (
            <Dialog
              fullWidth={true}
              minWidth={"sm"}
              open={quoteDialog}
              onClose={handleClosePreviewDialog}
              aria-labelledby="responsive-dialog-title"
              classes={{ root: classes.dialogBody }}
            >
              <DialogContent
                style={{
                  padding: "0px",
                  justifyContent: "space-between",
                }}
              >
                <div
                  onClick={handleClosePreviewDialog}
                  style={{
                    textAlign: "end",
                    paddingTop: "10px",
                    marginLeft: "1px",
                  }}
                >
                  <IconButton
                    className={""}
                    style={{
                      cursor: "pointer",
                      background: "#FFF",
                      padding: "1px",
                    }}
                  >
                    <Close />
                  </IconButton>
                </div>
                {quoteDialog?.type === "image" ? (
                  <Card
                    elevation={0}
                    className={classes.playerWrapper}
                    style={{ borderRadius: "0px" }}
                  >
                    <img
                      // src={`${quoteDialog?.authorPicture}`}
                      src={image}
                      className={classes.imageCardRoot}
                      style={{
                        borderRadius: "0px",
                      }}
                      alt=""
                    />
                  </Card>
                ) : quoteDialog?.type === "video" ? (
                  <Grid container>
                    <Grid item xs={12}>
                      <div className={classes.playerWrapper}>
                        <div
                          className={clsx(
                            classes.reactPlayer,
                            classes.preLoadBody
                          )}
                        >
                          <CircularProgress style={{ color: "#09A9BB" }} />
                        </div>
                        <ReactPlayer
                          controls="true"
                          height="100%"
                          width="100%"
                          className={classes.reactPlayer}
                          playing={true}
                          onError={() => {
                            quoteDialog &&
                              quoteDialog?.url &&
                              setQuoteDialog(null);
                          }}
                          url={quoteDialog?.url}
                          config={{
                            youtube: {
                              playerVars: { showinfo: 1 },
                            },
                          }}
                        />
                      </div>
                    </Grid>
                  </Grid>
                ) : quoteDialog?.type === "text" ? (
                  <Grid container>
                    <Grid item xs={12}>
                      <div className={classes.quoteCard}>
                        <Grid container direction="row">
                          <Grid
                            item
                            xs={1}
                            md={1}
                            style={{ marginTop: "20px", textAlign: "right" }}
                          >
                            <img src={quoteIcon} alt="quote" />
                          </Grid>
                          <Grid item xs={11} style={{ marginTop: "20px" }}>
                            <Typography className={classes.quoteText}>
                              {quoteDialog?.quote}
                            </Typography>
                            <Typography className={classes.cite}>
                              <cite>{quoteDialog?.author}</cite>
                            </Typography>
                          </Grid>
                        </Grid>
                      </div>
                    </Grid>
                  </Grid>
                ) : (
                  ""
                )}
              </DialogContent>

              {quoteDialog?.referenceUrl && (
                <div

                  style={{
                    textAlign: "center",
                    paddingTop: isMobile ? 5 : 10,
                  }}
                >
                  <IconButton
                    className={""}
                    style={{
                      cursor: "pointer",
                      background: "#FFF",
                      padding: "1px",
                      height: isMobile ? "15px" : "20px",
                      marginRight: 20,
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Button  onClick={handleOpenWindow}> Detail</Button>
                  </IconButton>
                </div>
              )}
            </Dialog>
          )
        ) : (
          <Dialog
            fullWidth={true}
            minWidth={"sm"}
            open={quoteDialog}
            onClose={handleClosePreviewDialog}
            aria-labelledby="responsive-dialog-title"
            classes={{ root: classes.dialogBody }}
          >
            <DialogContent
              style={{
                padding: "0px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div
                onClick={handleClosePreviewDialog}
                style={{
                  textAlign: "end",
                  paddingTop: "10px",
                  marginLeft: "1px",
                }}
              >
                <IconButton
                  className={""}
                  style={{
                    cursor: "pointer",
                    background: "#FFF",
                    padding: "1px",
                  }}
                >
                  <Close />
                </IconButton>
              </div>
              {quoteDialog?.type === "image" ? (
                <Card
                  elevation={0}
                  className={classes.playerWrapper}
                  style={{ borderRadius: "0px" }}
                >
                  <img
                    // src={`${quoteDialog?.authorPicture}`}
                    src={image}
                    className={classes.imageCardRoot}
                    style={{
                      borderRadius: "0px",
                    }}
                    alt=""
                  />
                </Card>
              ) : quoteDialog?.type === "video" ? (
                <Grid container>
                  <Grid item xs={12}>
                    <div className={classes.playerWrapper}>
                      <div
                        className={clsx(
                          classes.reactPlayer,
                          classes.preLoadBody
                        )}
                      >
                        <CircularProgress style={{ color: "#09A9BB" }} />
                      </div>
                      <ReactPlayer
                        controls="true"
                        height="100%"
                        width="100%"
                        className={classes.reactPlayer}
                        playing={true}
                        onError={() => {
                          quoteDialog &&
                            quoteDialog?.url &&
                            setQuoteDialog(null);
                        }}
                        url={quoteDialog?.url}
                        config={{
                          youtube: {
                            playerVars: { showinfo: 1 },
                          },
                        }}
                      />
                    </div>
                  </Grid>
                </Grid>
              ) : quoteDialog?.type === "text" ? (
                <Grid container>
                  <Grid item xs={12}>
                    <div className={classes.quoteCard}>
                      <Grid container direction="row">
                        <Grid
                          item
                          xs={1}
                          md={1}
                          style={{ marginTop: "20px", textAlign: "right" }}
                        >
                          <img src={quoteIcon} alt="quote" />
                        </Grid>
                        <Grid item xs={11} style={{ marginTop: "20px" }}>
                          <Typography className={classes.quoteText}>
                            {quoteDialog?.quote}
                          </Typography>
                          <Typography className={classes.cite}>
                            <cite>{quoteDialog?.author}</cite>
                          </Typography>
                        </Grid>
                      </Grid>
                    </div>
                  </Grid>
                </Grid>
              ) : (
                ""
              )}
            </DialogContent>

            {quoteDialog?.referenceUrl && (
              <div

                style={{
                  textAlign: "center",
                  paddingTop: isMobile ? 5 : 10,
                }}
              >
                <IconButton
                  className={""}
                  style={{
                    cursor: "pointer",
                    background: "#FFF",
                    padding: "1px",
                    height: isMobile ? "15px" : "20px",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Button  onClick={handleOpenWindow}> Detail</Button>
                </IconButton>
              </div>
            )}
          </Dialog>
        )}{" "}
      </Grid>
    </Grid>
  );
}

export default QuoteOfTheDay;

const useStyles = makeStyles((theme) => ({
  playerWrapper: {
    position: "relative",
    paddingTop: "56.25%",
    height: 0,
  },

  reactPlayer: {
    position: "absolute",
    top: 0,
    left: 0,
  },
  imageCardRoot: {
    position: "absolute",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    backgroundColor: "#a9a9a9",
    backgroundPosition: "center",
  },
  imageWrapper: {
    position: "relative",
    width: "100%",
    height: 0,

    paddingTop: `100%`,
  },
  button: {
    textTransform: "none",
    color: "#09B1D3",
    cursor: "pointer",
  },
  button1: {
    textTransform: "none",
    color: "#fff",
    cursor: "pointer",
    backgroundColor: "#09B1D3",
    "&:hover": {
      backgroundColor: "#09B1D3",
    },
  },
  dialogBody: {
    backgroundColor: "transparent",
    "& .MuiPaper-root": {
      backgroundColor: "transparent",
      boxShadow: "none",
      width: "100% !important",
      overflow: "visible",
    },
    "& .MuiDialog-paper": {
      margin: "12px",
    },
  },
  preLoadBody: {
    display: "flex",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#a9a9a9",
    width: "100%",
  },
  quoteCard: {
    background: "#F7EFF0",
    borderRadius: "17px",
    minHeight: "135px",
    width: "100%",
    marginTop: "25px",
    marginBottom: "5px",
  },
  quoteText: {
    fontFamily: theme.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: 900,
    fontSize: "16px",
    lineHeight: "25px",
    color: "#3F5B73",
    padding: "2px",
  },
  cite: {
    fontFamily: theme.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: 900,
    fontSize: "12px",
    lineHeight: "16px",
    color: "#9F9F9F",
    margin: "auto",
    textAlign: "center",
    marginTop: "10px",
    marginBottom: "5px",
  },
}));
