import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";

import Grid from "@material-ui/core/Grid";
import { Button, Modal, CircularProgress } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { useIntl } from "react-intl";
import moment from "moment";
import {
  getAllJoinedAndCreatedSessions,
  selectedSession
} from "../../../actions/Session";
import SessionCard from "../../../components/Common/Session/SessionCard/index";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
function Sessions(props) {
  const classes = useStyles();
  const intl = useIntl();
  const theme = useTheme();
  let dispatch = useDispatch();
  let history = useHistory();

  const authReducerState = useSelector(state => state.auth);
  const sessionsList = useSelector(
    state => state?.session?.allCreatedAndJoinedSessionsList
  );
  const sessionsReduerState = useSelector(state => state.session);
  console.log("state", authReducerState);
  useEffect(() => {
    dispatch(
      getAllJoinedAndCreatedSessions({
        token: authReducerState && authReducerState.token
      })
    );
  }, []);

  const getFomatatedTime = value => {
    let hours =
      parseInt((value * 15) / 60) > 12
        ? parseInt((value * 15) / 60) - 12
        : parseInt((value * 15) / 60);
    let min = ((value * 15) / 60 - parseInt((value * 15) / 60)) * 60;
    let ampm = parseInt((value * 15) / 60) > 12 ? "PM" : "AM";
    if (hours === 0) {
      hours = 12;
    }

    return `${hours}: ${min} ${ampm}`;
  };
  const [modalStyle] = useState(getModalStyle);
  function getModalStyle() {
    const top = 50;
    const left = 50;
    return {
      borderColor: theme.colors.white,
      borderRadius: "4px",
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top - 4}%, -${left}%)`
    };
  }
  const [openModel, setOpenModel] = useState(false);
  const handleCloseModal = () => {
    setOpenModel(false);
  };
  const handleOpenModal = () => {
    setOpenModel(true);
  };

  const goDetails = (page, session, e) => {
    history.push({ pathname: `/more/session/edit/${session.id}` });
  };


  const modalBody = (
    <Grid
      container
      direction="row"
      spacing={3}
      style={modalStyle}
      className={classes.paper}
    >
      <Grid item className={classes.msgBody}>
        <Typography className={classes.modelBodyText}>
          {intl.formatMessage({
            id: "sessionNotStartedYet"
          })}
        </Typography>
      </Grid>
      <Grid item className={classes.modalBtnGrid}>
        <Button onClick={() => handleCloseModal()} className={classes.modelBtn}>
          {intl.formatMessage({ id: "okay" })}
        </Button>
      </Grid>
    </Grid>
  );
  return (
    <>
      <Modal
        disableEnforceFocus={true}
        open={openModel}
        onClose={handleCloseModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {modalBody}
      </Modal>
      <Grid
        container
        style={{
          paddingBottom: "10px"
        }}

        spacing={2}
        className={classes.hideScrollBar}
      >
        {sessionsReduerState?.showLoader ? (
          <Grid
            container
            justify="center"
            alignItems="center"
            style={{ minHeight: "190px" }}
          >
            <CircularProgress color="primary" size={20} />
          </Grid>
        ) : sessionsList.length === 0 ? (
          <Grid item xs={12}>
            <Typography className={classes.noContent}>
              No Record found
            </Typography>
          </Grid>
        ) : (
          sessionsList?.slice(0, 4)?.map((n, i) => {

            return (
              <Grid
                item

                key={i}
              >
                <SessionCard
                  image={n && n.imageUrl}
                  edit={false}
                  name={n && n.name}
                  goDetails={goDetails}
                  session={n}
                  friends={
                    n &&
                      n.sessionParticipants &&
                      n.sessionParticipants.length > 1
                      ? `${n &&
                      n.sessionParticipants &&
                      n.sessionParticipants.length} friends`
                      : `${n &&
                      n.sessionParticipants &&
                      n.sessionParticipants.length} friend`
                  }
                  time={moment(n?.sessionDates && n?.sessionDates[0]?.date)}
                  startDate={n?.sessionDates}
                />
              </Grid>
            );
          })
        )}
      </Grid>
    </>
  );
}

export default Sessions;

const useStyles = makeStyles(theme => ({
  hideScrollBar: {
    flexWrap: "unset",
    overflowX: "auto",
    "&::-webkit-scrollbar": {
      display: "none"
    }
  },
  noContent: {
    color: "#7d7b79",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "19px",
    padding: "12px 10px 10px 17px",
    textAlign: "center"
  },
  paper: {
    position: "absolute",
    height: 330,
    maxWidth: 315,
    minWidth: 300,
    justifyContent: "center",
    backgroundColor: theme.palette.background.paper,
    outline: "none"
  },

  modelBodyText: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "17px",
    lineHeight: "27px",
    textAlign: "center",
    letterSpacing: "0.02em",
    color: "#192247",
    overflow: "auto"
  },

  modelBtn: {
    height: "73px",
    width: "100%",
    fontFamily: theme.fontFamily.Roboto,
    cursor: "pointer",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "21px",
    color: theme.colors.white
  },

  modalBtnGrid: {
    padding: "0px",
    alignSelf: "flex-end",
    color: "#fff",
    cursor: "pointer",
    background: `linear-gradient(#09A9BB 1%,#09A9BB 100%)`,
    height: "73px",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    display: "flex"
  },
  msgBody: {
    minHeight: "240px",
    alignItems: "center",
    display: "flex"
  }
}));
