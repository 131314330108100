// types
import { LOADER} from "../actions/types";

// initial state
const initialState = {
  loader: false
};

// handle actions
export default function(state = initialState, action) {
    const { type, payload } = action;
  
    switch (type) {
      case LOADER:
        return {
          ...state,
          loader:payload
        };
      
      default:
        return state;
    }
  }