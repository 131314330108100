import React, { useState, useRef, useEffect } from 'react';
import { Grid, TextField, CircularProgress, Typography, Avatar } from '@material-ui/core'
import { makeStyles } from "@material-ui/core/styles";
import SocketIOClient from "socket.io-client";
import { useSelector, useDispatch } from 'react-redux'
import { getChatMessages } from '../../../actions/retreatCourse'
import { sendChatMedia } from '../../../actions/chat'
import { Send, AttachFile, CameraAlt } from '@material-ui/icons'
import ChatCard from './chatCard'
import MentorChatCard from './MentorChatCard'
import clsx from 'clsx'

function PrivateChat(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const scrollAble = useRef(null);
    const [message, setMessage] = useState('');
    const [messages, setMessages] = useState([]);
    const [socketIo, setSocketIo] = useState();
    const [loader, setLoader] = useState(false);
    const [selectedUser, setSelectedUser] = useState('');
    const authReducer = useSelector(state => state.auth);
    const lesson = useSelector(state => state?.retreatCourse?.toBePlayedLesson);
    const course = useSelector(state => state?.retreatCourse?.trainingCourseDetails);
    const participants = useSelector(state => state?.retreatCourse?.trainingCourseParticipants);


    const getMessages = async ({ loading }) => {
        loading && setLoader(true)
        let res = await dispatch(
            getChatMessages({
                token: authReducer?.token,
                roomId: `enrolledTrainingCourse-${course?.id}-${course?.isMentor ? selectedUser?.user?.id : authReducer?.user?.id}`,
                type: 'Retreat'

            })
        );
        if (res) {
            setMessages(res)
            setLoader(false)
        } else {
            setLoader(false)
        }
    }

    useEffect(() => {
        if (lesson) {
            getMessages({ loading: true })
            let socket;
            socket = SocketIOClient(process.env.REACT_APP_SOCKET_URL, {
                reconnection: true,
                reconnectionDelay: 5000,
                reconnectionDelayMax: 5000,
                reconnectionAttempts: 2,
                rememberUpgrade: true,
                transports: ["polling", "websocket"]

            });
            socket.emit("connection", () => {
                console.log("socket connected");
            });

            setSocketIo(socket);
            socket && socket.emit("subscribe", `enrolledTrainingCourse-${course?.id}-${course?.isMentor ? selectedUser?.user?.id : authReducer?.user?.id}`)
            socket && socket.on("message", async message => {
                if (message?.senderId !== authReducer?.user?.id) {
                    getMessages({ loading: false })
                }
            });

            return () => {
                socket && socket.emit("unsubscribe", `enrolledTrainingCourse-${course?.id}-${course?.isMentor ? selectedUser?.user?.id : authReducer?.user?.id}`);
                socket.emit("disconnect", async function () {
                    console.log("socket disconnected...");
                });
            };
        }
    }, [lesson, selectedUser]);

    useEffect(() => {
        if (participants?.length > 0) {
            setSelectedUser(participants[0]);
        }

    }, [participants])

    useEffect(() => {
        scrollAble && scrollAble.current && scrollAble.current.scrollIntoView();
    });


    const sendMessage = () => {
        if (message.trim().length === 0 || participants?.length <= 0) {
            setMessage('')
        } else {
            const data = {
                roomId: `enrolledTrainingCourse-${course?.id}-${course?.isMentor ? selectedUser?.user?.id : authReducer?.user?.id}`,
                message: {
                    message,
                    messageType: "Text",
                    read: false,
                    senderId: authReducer?.user?.id,
                    groupType: "Retreat"
                }
            };
            socketIo && socketIo.emit("message", data);
            getMessages({ loading: false })
            setMessage("");

        }
    };


    const sendMultimediaMessage = async (e) => {
        if (participants?.length > 0) {

            const formData = new FormData();
            formData.append("chatMedia", e.target.files[0]);
            const resUrl = await dispatch(
                sendChatMedia({
                    token: authReducer?.token,
                    chatMedia: formData,
                    groupType: 'Retreat',
                    roomId: `enrolledTrainingCourse-${course?.id}-${course?.isMentor ? selectedUser?.user?.id : authReducer?.user?.id}`,

                })
            );
            if (resUrl) {
                const data = {
                    roomId: `enrolledTrainingCourse-${course?.id}-${course?.isMentor ? selectedUser?.user?.id : authReducer?.user?.id}`,
                    message: {
                        message: resUrl?.chatMediaUrl,
                        messageType: "Image",
                        read: false,
                        groupType: 'Retreat',
                        senderId: authReducer?.user?.id,

                    }
                };
                socketIo && socketIo.emit("message", data);
                getMessages({ loading: false })
                setMessage("");
            }
        }

    };

    return (
        <Grid container>

            {course?.isMentor && <Grid item container xs={12} spacing={1} style={{ borderBottom: course?.isMentor && '2px solid #E2E2E2', marginBottom: '10px' }}>
                {
                    participants?.length > 0 ? participants?.map(participant => <Grid item onClick={() => setSelectedUser(participant)} key={participant?.id} style={{ cursor: 'pointer' }}>
                        <Avatar src={participant?.user?.pictureUrl} style={{ height: participant?.id === selectedUser?.id && '45px', width: participant?.id === selectedUser?.id && '45px' }} >{participant && participant?.user?.name[0]}</Avatar>
                        <Typography>{participant?.user?.username}</Typography>
                    </Grid>) : <Grid item xs={12} className={classes.noContent}>
                        <Typography className={classes.noContentText}>No user found</Typography>
                    </Grid>
                }

            </Grid>}

            {loader ? <Grid item xs={12} className={classes.noContent}>
                <CircularProgress size={20} style={{ color: '#09a9bb' }} />
            </Grid> : messages?.length > 0 ?
                <Grid item xs={12} className={classes.container}>
                    {messages.map(message => message?.sender?.isMentor ? <MentorChatCard message={message} /> : <ChatCard message={message} />)}
                    <div ref={scrollAble} style={{ height: '10px' }}></div>
                </Grid> : <Grid item xs={12} className={classes.noContent}>
                    <Typography className={classes.noContentText}>No message found</Typography>
                </Grid>}

            <Grid item xs={12} >
                <TextField
                    id="outlined-textarea"
                    placeholder='Type message...'
                    multiline
                    variant="outlined"
                    classes={{ root: classes.messageInputRoot, focused: classes.focused }}
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    rowsMax={4}
                    disabled={participants?.length <= 0}

                    onKeyPress={e => {
                        (e.charCode === 13) && message && sendMessage();
                    }}
                    InputProps={{
                        startAdornment: (
                            <label className={clsx(classes.inputFile, classes.iconStyle)}>
                                < CameraAlt className={classes.iconStyle} style={{ marginRight: '15px', paddingTop: '5px' }} />
                                <input
                                    type="file"
                                    accept="image/*"
                                    disabled={participants?.length <= 0}
                                    onChange={sendMultimediaMessage}
                                />
                            </label>

                        ),
                        endAdornment: (
                            < Send className={classes.iconStyle} style={{ marginLeft: '15px' }} onClick={() => message && sendMessage()} />
                        )
                    }}
                />
            </Grid>
            {/* <Grid item xs={12} container className={classes.attachmentContainer}>
                <Grid item>< AttachFile className={classes.iconStyle} style={{ paddingLeft: '10px' }} /></Grid>
                <Grid item>< CameraAlt className={classes.iconStyle} style={{ paddingLeft: '10px' }} /></Grid>
            </Grid> */}
        </Grid>
    );
}

export default PrivateChat;

const useStyles = makeStyles(theme => ({
    container: {
        maxHeight: '400px',
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
            width: '0px',
            background: 'transparent', /* make scrollbar transparent */
        }
    },

    card: {
        width: '100%',
        background: '#FFFFFF',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.05)',
        borderRadius: '10px',
    },

    messageInputRoot: {
        marginTop: "15px",
        color: '#78849E',
        width: '100%',
        background: '#EBEBEB',
        '& focused': {
            border: '1px solid #E3E3E3 !important',
        }

    },
    focused: {
        border: '1px solid #E3E3E3 !important',
    },
    iconStyle: {
        color: '#3D3D3D',
        cursor: 'pointer',

    },
    attachmentContainer: {
        background: '#EBEBEB',
        display: 'flex',
        alignItems: 'center',
        height: '40px'

    },

    noContent: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: "100px"
    },
    noContentText: {
        fontFamily: theme.fontFamily.Roboto,
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "14px",
        color: "#666666",

    },
    inputFile: {
        cursor: 'pointer',
        '& input[type="file"]': {
            display: 'none'
        }
    },

}));