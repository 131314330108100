// types
import {
  SET_SETTING,
  SET_LANGUAGES,
  UPDATE_SETTING,
  SET_SITE_SETTINGS
} from "../actions/types";

// initial state
const initialState = {
  settings: {},
  allLanguages: [],
  siteConfig: null
};

// handle actions
export default function(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SET_SETTING:
      console.log("payload SET_SETTING:", payload);
      return {
        ...state,
        settings: payload
      };
    case UPDATE_SETTING:
      console.log("payload UPDATE_SETTING:", payload);

      return {
        ...state,
        settings: { ...state.settings, ...payload }
      };
    case SET_LANGUAGES:
      return {
        ...state,
        allLanguages: payload
      };
    case SET_SITE_SETTINGS:
      return {
        ...state,
        siteConfig: payload
      };
    default:
      return state;
  }
}
