import React, { useEffect, useState } from 'react';
import { Grid, Typography, Button, Dialog, DialogTitle, DialogContent, DialogActions, IconButton, Checkbox, CircularProgress } from '@material-ui/core'
import { Close } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { unjoinUnderSubscription } from '../../../actions/SubscriptionPlan'

function ExpireSubscriptionPopup({ open, handleClose, plan, continueActivation }) {
    const history = useHistory();
    const dispatch = useDispatch();
    const classes = useStyles();

    const allJoined = useSelector(state => state?.subscriptionPlan?.userUnderSubscriptionJoined);
    const auth = useSelector(state => state?.auth);

    const [loader, setLoader] = useState(false);
    const [error, setError] = useState([]);
    const [unjoin, setUnjoinList] = useState({ courses: [], sessions: [], retreats: [] });

    const handleChange = (e, item, name) => {
        let unJoinedLocal = unjoin;
        if (e.target.checked) {
            unJoinedLocal[name].push(item?.id);
            setUnjoinList({ ...unJoinedLocal })
        } else {
            let popArr = unJoinedLocal[name].filter(i => i !== item?.id);
            setUnjoinList({ ...unjoin, [name]: popArr });
        }
    }

    const isActivateAble = () => {
        let arr = []
        if (allJoined?.omidCourses?.length > Number(plan?.availableCourses)+Number(plan?.availableTeacherCourses)) {
            arr.push('omidCourses')
        } else if (allJoined?.teacherCourses?.length >  Number(plan?.availableCourses)+Number(plan?.availableTeacherCourses)) {
            arr.push('teacherCourses')
        } else if (allJoined?.omidRetreats?.length > Number(plan?.availableRetreats) + Number(plan?.availableTeacherRetreats)) {
            arr.push('omidRetreats')
        } else if (allJoined?.teacherRetreats?.length > Number(plan?.availableTeacherRetreats) + Number(plan?.availableRetreats)) {
            arr.push('teacherRetreats')
        } else if (allJoined?.omidSessions?.length > Number(plan?.availableSessions) + Number(plan?.availableTeacherSessions)) {
            arr.push('omidSessions')
        } else if (allJoined?.teacherSessions?.length > Number(plan?.availableTeacherSessions) + Number(plan?.availableSessions)) {
            arr.push('teacherSessions')
        } else if (allJoined?.omidTeacherTrainings?.length > Number(plan?.availableTeacherTrainings) + Number(plan?.availableTeachersTeacherTrainings)) {
            arr.push('omidTeacherTrainings')
        } else if (allJoined?.teachersTeacherTrainings?.length > Number(plan?.availableTeachersTeacherTrainings) + Number(plan?.availableTeacherTrainings)) {
            arr.push('teachersTeacherTrainings')
        }

        return setError(arr)



    }
    // debugger

    const handleContinue = () => {
        return continueActivation()
    }

    const handleLeave = async () => {
        setLoader(true);
        await dispatch(unjoinUnderSubscription({ token: auth.token, data: { courseIds: unjoin?.courses, 
            sessionIds: unjoin?.sessions, retreatIds: unjoin?.retreats } }));
        setUnjoinList({ courses: [], sessions: [], retreats: [] });
        setLoader(false);

    }

    useEffect(() => {
        setError([]);
        isActivateAble()
    }, [allJoined]);

    return (
        <Dialog open={open}>
            <DialogTitle>
                <Grid container justify="space-between">
                    <Grid item>
                        <Typography component="h4" variant="h5" >
                        Leave or continue
                        </Typography>
                    </Grid>
                    <Grid item>
                        <IconButton onClick={handleClose}>
                            <Close />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent style={{ display: 'flex', justifyContent: 'center' }}>
                <Grid container>
                    <Grid item xs={12}>
                        <Typography >
                        Your monthly courses you joined have ended at the end of the month.
                        Please cancel any course you do not wish to continue in order to open space for new courses you wish to join in the coming month.
                        </Typography>
                    </Grid>

                    {allJoined?.omidCourses?.length > 0 && <><Grid item xs={12}>
                       {/*  <Typography className={error.includes('omidCourses') ? classes.labelError : classes.label}>Delete:</Typography> */}
                    </Grid>
                        {allJoined?.omidCourses.map(item => (<Grid item xs={12} key={item?.id} container style={{ alignItems: 'center' }}>
                            <Grid item xs={2}><Checkbox color='primary' classes={{ colorPrimary: classes.checkbox }} onChange={(e) => handleChange(e, item, 'courses')} /></Grid>
                            <Grid item xs={10}><Typography>{item?.name}</Typography></Grid>
                        </Grid>))
                        }
                    </>
                    }
                    {allJoined?.teacherCourses?.length > 0 && <> <Grid item xs={12}>
                        {/* <Typography className={error.includes('teacherCourses') ? classes.labelError : classes.label}>Teacher's Courses:</Typography> */}
                    </Grid>

                        {
                            allJoined?.teacherCourses.map(item => (<Grid item xs={12} key={item?.id} container style={{ alignItems: 'center' }}>
                                <Grid item xs={2}><Checkbox color='primary' onChange={(e) => handleChange(e, item, 'courses')} classes={{ colorPrimary: classes.checkbox }} /></Grid>
                                <Grid item xs={10}><Typography>{item?.name}</Typography></Grid>
                            </Grid>))
                        }
                    </>

                    }
                    {allJoined?.omidSessions?.length > 0 && <> <Grid item xs={12}>
                        <Typography className={error.includes('omidSessions') ? classes.labelError : classes.label}>Omid's Sessions:</Typography>
                    </Grid>

                        {
                            allJoined?.omidSessions.map(item => (<Grid item xs={12} key={item?.id} container style={{ alignItems: 'center' }}>
                                <Grid item xs={2}><Checkbox color='primary' onChange={(e) => handleChange(e, item, 'sessions')} classes={{ colorPrimary: classes.checkbox }} /></Grid>
                                <Grid item xs={10}><Typography>{item?.name}</Typography></Grid>
                            </Grid>))
                        }
                    </>

                    }
                    {allJoined?.teacherSessions?.length > 0 && <> <Grid item xs={12}>
                      {/*   <Typography className={error.includes('teacherSessions') ? classes.labelError : classes.label}>Teacher's Sessions:</Typography> */}
                    </Grid>

                        {
                            allJoined?.teacherSessions.map(item => (<Grid item xs={12} key={item?.id} container style={{ alignItems: 'center' }}>
                                <Grid item xs={2}><Checkbox color='primary' onChange={(e) => handleChange(e, item, 'sessions')} classes={{ colorPrimary: classes.checkbox }} /></Grid>
                                <Grid item xs={10}><Typography>{item?.name}</Typography></Grid>
                            </Grid>))
                        }
                    </>

                    }
                    {allJoined?.omidRetreats?.length > 0 && <> <Grid item xs={12}>
                        <Typography className={error.includes('omidRetreats') ? classes.labelError : classes.label}>Omid's Retreats:</Typography>
                    </Grid>

                        {
                            allJoined?.omidRetreats.map(item => (<Grid item xs={12} key={item?.id} container style={{ alignItems: 'center' }}>
                                <Grid item xs={2}><Checkbox color='primary' onChange={(e) => handleChange(e, item, 'retreats')} classes={{ colorPrimary: classes.checkbox }} /></Grid>
                                <Grid item xs={10}><Typography>{item?.name}</Typography></Grid>
                            </Grid>))
                        }
                    </>

                    }
                    {allJoined?.teacherRetreats?.length > 0 && <> <Grid item xs={12}>
                       {/*  <Typography className={error.includes('teacherRetreats') ? classes.labelError : classes.label}>Teacher's Retreats:</Typography> */}
                    </Grid>

                        {
                            allJoined?.teacherRetreats.map(item => (<Grid item xs={12} key={item?.id} container style={{ alignItems: 'center' }}>
                                <Grid item xs={2}><Checkbox color='primary' onChange={(e) => handleChange(e, item, 'retreats')} classes={{ colorPrimary: classes.checkbox }} /></Grid>
                                <Grid item xs={10}><Typography>{item?.name}</Typography></Grid>
                            </Grid>))
                        }
                    </>

                    }
                    {allJoined?.omidTeacherTrainings?.length > 0 && <> <Grid item xs={12}>
                        <Typography className={error.includes('omidTeacherTrainings') ? classes.labelError : classes.label}>Omid's Teacher Trainings:</Typography>
                    </Grid>

                        {
                            allJoined?.omidTeacherTrainings.map(item => (<Grid item xs={12} key={item?.id} container style={{ alignItems: 'center' }}>
                                <Grid item xs={2}><Checkbox color='primary' onChange={(e) => handleChange(e, item, 'retreats')} classes={{ colorPrimary: classes.checkbox }} /></Grid>
                                <Grid item xs={10}><Typography>{item?.name}</Typography></Grid>
                            </Grid>))
                        }
                    </>

                    }
                    {allJoined?.teachersTeacherTrainings?.length > 0 && <> <Grid item xs={12}>
                       {/*  <Typography className={error.includes('teachersTeacherTrainings') ? classes.labelError : classes.label}>Teacher's Teacher Trainings:</Typography> */}
                    </Grid>

                        {
                            allJoined?.teachersTeacherTrainings.map(item => (<Grid item xs={12} key={item?.id} container style={{ alignItems: 'center' }}>
                                <Grid item xs={2}><Checkbox color='primary' onChange={(e) => handleChange(e, item, 'retreats')} classes={{ colorPrimary: classes.checkbox }} /></Grid>
                                <Grid item xs={10}><Typography>{item?.name}</Typography></Grid>
                            </Grid>))
                        }
                    </>

                    }
                </Grid>
            </DialogContent>
            <DialogActions>
                {loader ? <Button className={classes.modelBtn} >
                    <CircularProgress className={classes.loader} size={20} />
                </Button> :
                    <>
                        <Button onClick={handleLeave} className={classes.modelBtn} disabled={unjoin?.courses?.length <= 0 && unjoin?.sessions?.length <= 0 && unjoin?.retreats?.length <= 0}>
                            Cancel
                        </Button>
                        <Button onClick={handleContinue} className={classes.modelBtn} disabled={error.length > 0}>
                            Continue
                        </Button>
                    </>}
            </DialogActions>
            {error.length > 0 && <Typography className={classes.errorTxt}>Please fix above errors to continue!</Typography>}
        </Dialog>
    );
}

export default ExpireSubscriptionPopup;

const useStyles = makeStyles((theme) => ({

    modelBtn: {
        fontFamily: theme.fontFamily.Roboto,
        cursor: "pointer",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "21px",
        textTransform: 'none',
        color: theme.colors.white,
        background: `linear-gradient(#09A9BB 1%,#09A9BB 100%)`
    },
    checkbox: {
        color: '#09A9BB',
        '&.Mui-checked': {
            color: '#09A9BB',
        }
    },
    label: {
        fontWeight: 'bold',
        fontSize: '16px'
    },
    labelError: {
        fontWeight: 'bold',
        color: '#ff0000'

    },
    errorTxt: {
        color: '#ff0000',
        textAlign: 'center'
    },
    loader: {
        color: '#fff',
    }
}));