import React, { useState } from 'react'
import { Grid, Typography } from '@material-ui/core'
import { makeStyles } from "@material-ui/core/styles";
import { ArrowBack } from "@material-ui/icons";
import AllNotifications from '../../../components/Notifications/AllNotificationsCard'

import { Link } from 'react-router-dom'
import { useIntl } from "react-intl";
function Notifications() {
    const classes = useStyles();
    const intl = useIntl();

    const [currentScreen, setCurrentScreen] = useState('notifications');
    const handleRenderScreen = (screen) => {
        setCurrentScreen(screen)
    }
    return (
        <Grid container style={{ paddingBottom: "70px" }}>
            <Grid item container xs={12} style={{ background: '#FFF', minHeight: '100vh' }}>
                <Grid item xs={12} >

                    <><div className={classes.header}>
                        <Grid container>
                            <Grid item xs={1} />
                            <Grid item xs={2}>
                                <Link to='/dashboard'>
                                    <ArrowBack style={{ color: '#FFFFFF' }} />
                                </Link>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography className={classes.headerTitle} >

                                    {intl.formatMessage({ id: "notifications" })}
                                </Typography>
                            </Grid>
                            <Grid item xs={3} />
                        </Grid>

                    </div>
                        <AllNotifications onClick={handleRenderScreen} /></>

                </Grid>



            </Grid>
        </Grid>
    )
}

export default Notifications


const useStyles = makeStyles(theme => ({
    header: {
        background: '#14D9D9',
        borderRadius: '0 0 34px 34px',
        height: "130px",
        width: '100%',
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center'

    },
    headerTitle: {
        fontFamily: theme.fontFamily.Roboto,
        fontStyle: "normal",
        fontWeight: 'bold',
        fontSize: "24px",
        lineHeight: "28px",
        color: '#FFF',
        textAlign: 'center'

    }
}))