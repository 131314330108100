import React, { useState } from 'react';
import { Grid, Typography, Button, ListItemIcon, ListItemText, Menu, MenuItem } from '@material-ui/core'
import { ArrowBackSharp, BorderColor, Delete, MoreVert, EmojiPeople, Details } from '@material-ui/icons'
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { useHistory } from 'react-router-dom'
import Request from './Requests'
import MakeAnnouncement from './MakeAnnouncement'
import retreatAnnouncementIcon from '../../assets/Icons/retreatAnnouncementIcon.svg'
import { useSelector } from 'react-redux'
import DeleteCourseComponent from './DeleteCourseComponent';

function Comments(props) {
    const classes = useStyles();
    const history = useHistory();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const user = useSelector(state => state?.auth?.user);
    const course = useSelector(state => state?.retreatCourse?.trainingCourseDetails);
    const joinStatus = 'Joined'

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const StyledMenu = withStyles({
        paper: {
            border: '1px solid #d3d4d5',
        },
    })((props) => (
        <Menu
            elevation={0}
            getContentAnchorEl={null}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            {...props}
        />
    ));

    const StyledMenuItem = withStyles((theme) => ({
        root: {
            '&:focus': {
                backgroundColor: '#EBEBEB',
                '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                },
            },
        },
    }))(MenuItem);

    return (
        <Grid container className={classes.container}>
            <Grid item xs={2} sm={2} md={1} lg={1} onClick={() => history.goBack()}><ArrowBackSharp style={{ color: '#3D3D3D', textAlign: 'center' }} /></Grid>
            <Grid item xs={course?.user?.id === user?.id ? 6 : 7} sm={course?.user?.id === user?.id ? 6 : 7} md={8} lg={8}>
                <Typography className={classes.heading}>{course?.name}</Typography>
            </Grid>
            <Grid item xs={3} onClick={handleClick} style={{ textAlign: 'center' }} onClick={() => props?.detailHandler()}>
                <Button className={!props?.detail ? classes.selected : classes.notSelected}>Detail</Button>
            </Grid>
            {course?.isMentor && <Grid item xs={1} onClick={handleClick}>
                <MoreVert />
            </Grid>}
            {/* {teacher ?
                <Grid item xs={12} sm={12} md={7} lg={7} container style={{paddingTop:'12px'}}>
                    <Grid item xs={3}>
                        <Request />
                    </Grid>
                    <Grid item xs={7}>
                        <MakeAnnouncement />
                    </Grid>
                    <Grid item xs={1} onClick={()=> history.push('/add-retreat')}>
                        <BorderColor className={classes.icon}/>
                    </Grid>
                    <Grid item xs={1} style={{textAlign:'right'}}>
                        <Delete className={classes.icon}/>
                    </Grid>
                </Grid>
                : <Grid item xs={12} sm={12} md={7} lg={7}>
                    {joinStatus === 'sent' ? <Button className={joinStatus === 'sent' ? classes.requestSent : classes.joinNowButton}>{joinStatus === 'sent' ? 'Request Sent' : 'Join Now'}</Button> :
                        <Typography className={classes.joinedDate}>Joined on 16 March 2021</Typography>
                    }
                </Grid>} */}

            <StyledMenu
                id="customized-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <StyledMenuItem>
                    <ListItemIcon>
                        <img src={retreatAnnouncementIcon} width='30' />
                    </ListItemIcon>
                    <MakeAnnouncement />
                </StyledMenuItem>
                {course?.isPrivate && <StyledMenuItem>
                    <ListItemIcon>
                        <EmojiPeople fontSize="small" />
                    </ListItemIcon>
                    <Request />
                </StyledMenuItem>}

                {course?.user?.id === user?.id && <StyledMenuItem onClick={() => history.push(course?.retreatType === 'retreat' ? `/edit-retreat/${course?.id}` : `/edit-teacher-training/${course?.id}`)}>
                    <ListItemIcon>
                        <BorderColor fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary="Edit" />
                </StyledMenuItem>}
                {course?.user?.id === user?.id && <StyledMenuItem>
                    <DeleteCourseComponent />
                    {/* <ListItemIcon>
                        <Delete fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary="Delete" />  */}
                </StyledMenuItem>}
            </StyledMenu>

        </Grid>
    );
}

export default Comments;

const useStyles = makeStyles(theme => ({
    container: {
        height: '90px',
        width: '100%',
        alignItems: 'center',
        position: 'fixed',
        background: '#E5E5E5',
        zIndex: 2
    },
    center: {
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
    },
    heading: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '16px',
        lineHeight: '100%',
        color: '#353B35',
    },
    joinedDate: {
        fontFamily: 'Roboto',
        fontStyle: 'italic',
        fontWeight: 'bold',
        fontSize: '14px',
        lineHeight: '100%',
        color: '#818581',
        float: 'right',

    },
    joinNowButton: {
        minWidth: '90px',
        color: '#fff',
        float: 'right',
        background: '#09A9BB',
        '&:hover': {
            background: '#09A9BB',
        }
    },
    requestSent: {
        minWidth: '90px',
        color: '#fff',
        float: 'right',
        background: '#9F9F9F',
        textTransform: 'none',
        '&:hover': {
            background: '#9F9F9F',
        }
    },
    icon: {
        color: '#666666',
        cursor: 'pointer'
    },
    selected: {
        color: '#353B35',
        border: '1px solid #353B35',


    },
    notSelected: {
        color: '#fff',
        background: '#09A9BB',

        '&:hover': {
            background: '#09A9BB',

        }
    }

}));