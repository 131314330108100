import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { Grid, Tooltip, useMediaQuery } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import videoPlayIcon from "../../../assets/Icons/videoPlayIcon.svg";
import EpisodeLocked from "../../../assets/Icons/EpisodeLocked.svg";
import replayIcon from "../../../assets/Icons/replayIcon.svg";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import Truncate from "react-truncate";
import EpisodeDescription from "../../../components/PlayEpisode";
import {
  DialogTitle,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@material-ui/core";
import { selectedSession } from "../../../actions/Session";

function ViewCourseEpCard(props) {
  const intl = useIntl();
  const history = useHistory();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [openErrorDialog, setOpenErrorDialog] = React.useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  let authReducerState = useSelector((state) => state?.auth);
  let reducerUser = useSelector((state) => state?.user?.user);


  const classes = useStyles();
  const {
    thumbnail,
    title,
    duration,
    day,
    progress,
    episode,
    status,
    watchStatus,
    isPaid,
    isFree,
    error,
  } = props;

  const handleClose = () => {
    history.push("/more/subscription");
    setOpenErrorDialog(false);

  };

  return (
    <div>
      {open && (
        <EpisodeDescription
          open={open}
          episode={episode}
          openHandler={(e) => setOpen(e)}
        />
      )}
      <Card className={classes.root}>
        <CardContent
          style={{
            backgroundImage:
              watchStatus === "Completed" || progress === 100
                ? `linear-gradient(90deg, ${props?.category?.color1
                } ${100}%, #FFFF ${0}%)`
                : `linear-gradient(90deg, #f1f4f6 ${progress}%, #FFFF ${0}%)`,
            height: "100%",
          }}
        >
          <Grid container justify="center" direction="row">
            <Grid xs={4} md={4} lg={4} xl={2} item>
              <div
                style={{
                  background: thumbnail && `url(${thumbnail}), #C4C4C4`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "contain",
                  backgroundPosition: "center",
                }}
                className={classes.imageContainer}
              />
            </Grid>
            <Grid
              xs={6}
              md={6}
              lg={6}
              xl={8}
              item
              style={{ paddingLeft: "20px" }}
            >
              <Typography
                className={classes.Title}
                style={{
                  color:
                    watchStatus === "Completed" || progress === 100
                      ? "#FFF"
                      : watchStatus === "Started" && progress > 40
                        ? "#2A4A67"
                        : "rgba(0, 0, 0, 0.8)",
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                {day}
                {authReducerState?.user?.id === props.userId && (
                  <Chip
                    className={
                      status === "Active"
                        ? classes.activeEpisode
                        : status === "InActive"
                          ? classes.inProgressEpisode
                          : status === "InProgressUploading"
                            ? classes.inProgressEpisode
                            : status === "InProgressTranscoding"
                              ? classes.inProgressEpisode
                              : status === "Blocked"
                                ? classes.blockedEpisode
                                : classes.blockedEpisode
                    }
                    label={status}
                    style={{ height: "18px", marginLeft: "5px" }}
                  />
                )}
              </Typography>
              <Tooltip title={title}>
                <Typography
                  className={classes.subContent}
                  style={{
                    color:
                      watchStatus === "Completed" || progress === 100
                        ? "#FFF"
                        : watchStatus === "Started" && progress > 40
                          ? "#2A4A67"
                          : "rgba(0, 0, 0, 0.8)",
                  }}
                >
                  <Truncate
                    lines={isMobile ? 2 : 4}
                    ellipsis={<span>...</span>}
                  >
                    {`${title}`}
                  </Truncate>
                </Typography>
              </Tooltip>
              <Typography
                className={classes.moreDetail}
                onClick={() => {
                  setOpen(true);
                }}
              >
                More Details
              </Typography>
            </Grid>

            <Grid
              xs={2}
              md={2}
              lg={2}
              xl={2}
              tem
              className={classes.epIcon}
              style={{ display: "flex", justifyContent: "center" }}
            >
              {props?.plan?.name === "Subscription" ? (
                reducerUser?.isExpiredSubscription &&
                  authReducerState?.user?.id !== props.userId ? (
                  <img src={EpisodeLocked} />
                ) : (
                  <div
                    style={{ textDecoration: "none", cursor: "pointer" }}
                    onClick={() =>
                      props?.plan?.name === "Subscription" && reducerUser?.purchasedSubscription?.length === 0
                        && reducerUser?.role === "Regular" && authReducerState?.user?.id !== props.userId
                        ? setOpenErrorDialog(true)
                        : history.push(
                          `/play-course/${props?.courseId}/${episode.id}`
                        )
                    }
                  >
                    <img
                      src={
                        isFree || authReducerState?.user?.id === props.userId
                          ? watchStatus === "Completed" || progress === 100
                            ? replayIcon
                            : watchStatus === "Started"
                              ? videoPlayIcon
                              : watchStatus === "Not Started"
                                ? videoPlayIcon
                                : videoPlayIcon
                          : isPaid
                            ? watchStatus === "Completed" || progress === 100
                              ? replayIcon
                              : watchStatus === "Started"
                                ? videoPlayIcon
                                : watchStatus === "Not Started"
                                  ? videoPlayIcon
                                  : videoPlayIcon
                            : EpisodeLocked
                      }
                      alt="icon"
                    />
                  </div>
                )
              ) : props?.goNext ? (
                <Link
                  to={`/play-course/${props?.courseId}/${episode.id}`}
                  style={{ textDecoration: "none", cursor: "pointer" }}
                >
                  <img
                    src={
                      isFree || authReducerState?.user?.id === props.userId
                        ? watchStatus === "Completed" || progress === 100
                          ? replayIcon
                          : watchStatus === "Started"
                            ? videoPlayIcon
                            : watchStatus === "Not Started"
                              ? videoPlayIcon
                              : videoPlayIcon
                        : isPaid
                          ? watchStatus === "Completed" || progress === 100
                            ? replayIcon
                            : watchStatus === "Started"
                              ? videoPlayIcon
                              : watchStatus === "Not Started"
                                ? videoPlayIcon
                                : videoPlayIcon
                          : EpisodeLocked
                    }
                    alt="icon"
                  />
                </Link>
              ) : props.isClickable && !props?.goNext ? (
                <img
                  onClick={props?.onClick}
                  src={
                    isFree || authReducerState?.user?.id === props.userId
                      ? watchStatus === "Completed" || progress === 100
                        ? replayIcon
                        : watchStatus === "Started"
                          ? videoPlayIcon
                          : watchStatus === "Not Started"
                            ? videoPlayIcon
                            : videoPlayIcon
                      : isPaid
                        ? watchStatus === "Completed" || progress === 100
                          ? replayIcon
                          : watchStatus === "Started"
                            ? videoPlayIcon
                            : watchStatus === "Not Started"
                              ? videoPlayIcon
                              : videoPlayIcon
                        : EpisodeLocked
                  }
                  alt="icon"
                />
              ) : (
                ""
              )}
              {/* )} */}
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Dialog
          // open={true}
          open={openErrorDialog}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {reducerUser?.purchasedSubscription &&
                reducerUser?.purchasedSubscription[0]?.SubscriptionPlan?.name ===
                "Free"
                ? "Your need to buy a subscription to access course"
                : "Your subscription has ended update your plan to access course"}

            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenErrorDialog(false)}>
              Close
            </Button>
            {
              reducerUser?.purchasedSubscription &&
                reducerUser?.purchasedSubscription[0]?.SubscriptionPlan?.name ===
                "Free"
                ?
                <Button
                  onClick={handleClose}
                  color="primary"
                  className={classes.proceedButton}
                >
                  "Subscribe Now"
                </Button>
                : ''}
          </DialogActions>
        </Dialog>
      </Grid>
    </div>
  );
}

export default ViewCourseEpCard;

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    borderRadius: "11px",
    transform: "rotate(360deg)",
    marginBottom: "50px",
    lineHeight: "3.625em",
  },
  imageContainer: {
    height: 0,
    paddingTop: "56.25%",
    position: "relative",
    borderRadius: "9px",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    marginTop: "-5px",
  },
  Title: {
    fontFamily: theme.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: 900,
    fontSize: "12px",
    lineHeight: "20px",
    color: "rgba(0, 0, 0, 0.8)",
  },
  subContent: {
    fontFamily: theme.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: 900,
    fontSize: "14px",
    lineHeight: "16px",
    marginTop: "5px",
    color: "rgba(0, 0, 0, 0.8)",
  },
  moreDetail: {
    fontFamily: theme.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: 900,
    fontSize: "14px",
    lineHeight: "16px",
    marginTop: "5px",
    color: "#89CEF2",
    paddingTop: "12px",
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  videoIcon: {
    height: "3.438em",
    width: "3.438em",
    borderRadius: "100%",
    backgroundColor: "#FFF",
    textAlign: "left",
    marginRight: "20px",
    textAlign: "center",
    cursor: "pointer",
  },
  epIcon: {
    display: "flex",
    alignItems: "center",
  },
  activeEpisode: {
    background: "#52b202",
    color: "#fff",
  },
  inProgressEpisode: {
    background: "#ff9100",
    color: "#fff",
  },
  blockedEpisode: {
    background: "#f44336",
    color: "#fff",
  },
  proceedButton: {
    background: "#fbae02",
    color: "#fff",
    "&:hover": {
      background: "#fbae02",
      color: "#fff",
    },
    "&:focus": {
      background: "#fbae02",
      color: "#fff",
    },
  },
}));
