import React, { useEffect } from 'react';
import { Grid, Typography, Badge } from '@material-ui/core'
import { NotificationsOutlined as NotificationsIcon,  } from '@material-ui/icons'
import headerMoreIcon from '../../../assets/Icons/headerMoreIcon.svg'
import { makeStyles, useTheme } from "@material-ui/styles";
import { useHistory, Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getUnseenNotificationsCount } from '../../../actions/notifications'
import BellIcon from '../../../assets/Icons/bellIcon.svg'
import BellIconNotification from '../../../assets/Icons/bellIconNotifications.svg'

function HeaderWithMoreBellIcon(props) {
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const dispatch = useDispatch();
    const authReducerState = useSelector((state) => state.auth);
    const noOfUnseenNotifications = useSelector((state) => state?.notifications?.noOfUnseenNotifications);

    useEffect(() => {
        dispatch(getUnseenNotificationsCount({ token: authReducerState?.token }))
    }, [])

    return (
        <Grid container className={classes.container}>
            <Grid item xs={2} justifyContent='center' className={classes.center}>
                <img src={headerMoreIcon} onClick={() => history.push('/more')} />
            </Grid>
            <Grid item xs={8} justifyContent='center'>
                <Typography className={classes.heading}>{props?.text}</Typography>
            </Grid>
            <Grid item xs={2} justifyContent='center' className={classes.center}>
                <Link to="/notification">
                    {noOfUnseenNotifications === 0 ? <img src={BellIcon} /> :
                        <Badge color="primary" badgeContent={noOfUnseenNotifications || 0} classes={{ colorPrimary: classes.mobileBadge }}>
                            <img src={BellIconNotification} />
                        </Badge>}
                </Link>

            </Grid>
        </Grid>
    );
}

export default HeaderWithMoreBellIcon;

const useStyles = makeStyles((theme) => ({
    container: {
        position: 'fixed',
        height: '72px',
        display: 'flex',
        alignItems: 'center',
        zIndex: 100,
        borderBottom: '1px solid #EAEAEA',
        backgroundColor: "#FFF"
    },
    heading: {
        fontFamily: 'Reef',
        fontStyle: 'normal',
        fontWeight: 900,
        fontSize: '28px',
        lineHeight: '38px',
        color: '#343434',
        textAlign: 'center',
        marginTop: '-5px',

    },
    iconStyle: {
        color: '#606770', fontSize: '30px', textAlign: 'center'
    },
    center: {
        textAlign: 'center'
    },
    mobileBadge: {

        background: "#14D9D9",

    },
}));