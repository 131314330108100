import API from "../utils/API";

// types
import {

    GET_USER_CHAT,
    GET_CHAT_MESSAGES,
    UPLOADED_CHAT_MEDIA,
    SELECTED_CHAT_USER,
    GROUP_CHAT_MESSAGES,
    UPLOADED_CHAT_MEDIA_PROGRESS,
    CHAT_LOADER,
    CHAT_MESSAGES_LOADER

} from "./types";


export const getUserChats = (token,load) => async dispatch => {
  if(load){
    dispatch({
      type: CHAT_LOADER,
      payload: true
    });
  }
    const config = {
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${token}`
      }
    };
  
    try {
      const res = await API.get(`/api/v1/chat/getUserChats`,config);
      dispatch({
        type: GET_USER_CHAT,
        payload: res.data.data
      });
      dispatch({
        type: CHAT_LOADER,
        payload: false
      });
      
    } catch (error) {
      console.log(error);
      dispatch({
        type: CHAT_LOADER,
        payload: false
      });
      
    }
  }

export const getRoomMessages = ({token,roomId,load}) => async dispatch => {
  if(load){
    dispatch({
      type: CHAT_MESSAGES_LOADER,
      payload: true
    });
  }
   
    const config = {
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${token}`
      },
      params:{
        roomId
      }
    };
  
    try {
      const res = await API.get(`/api/v1/chat/getRoomMessages`,config);
      dispatch({
        type: GET_CHAT_MESSAGES,
        payload: res.data.data
      });
      dispatch({
        type: CHAT_MESSAGES_LOADER,
        payload: false
      });
      
    } catch (error) {
      console.log(error);
      dispatch({
        type: CHAT_MESSAGES_LOADER,
        payload: false
      });
    }
  }

  // GROUP MESSAGES
export const getGroupMessages = ({token,roomId,load}) => async dispatch => {
  if(load){
    dispatch({
      type: CHAT_LOADER,
      payload: true
    });
  }
    const config = {
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${token}`
      },
      params:{
        roomId,
        groupType:'Challenge'
      }
    };
  
    try {
      const res = await API.get(`/api/v1/chat/getGroupMessages`,config);
      dispatch({
        type: GROUP_CHAT_MESSAGES,
        payload: res.data.data
      });
      dispatch({
        type: CHAT_LOADER,
        payload: false
      });
      
    } catch (error) {
      console.log(error);
      dispatch({
        type: CHAT_LOADER,
        payload: false
      });
    }
  }

  export const sendChatMedia = ({token,chatMedia,roomId,onUploadProgress,groupType}) => async dispatch => {
   
    const config = {
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${token}`
      },
      params:{
        roomId,
        groupType
      },
      onUploadProgress : event => (
        dispatch({
          type: UPLOADED_CHAT_MEDIA_PROGRESS,
          payload: (Math.round((100 * event.loaded) / event.total)),
        })
        
        )
      
      
}
    try {
      const res = await API.post(`/api/v1/chat/uploadChatMedia`,chatMedia,config);
      dispatch({
        type: UPLOADED_CHAT_MEDIA,
        payload: res.data.data
      });
      dispatch({
        type: UPLOADED_CHAT_MEDIA_PROGRESS,
        payload: 0,
      })
      return  res.data.data
    } catch (error) {
      console.log(error);
      dispatch({
        type: UPLOADED_CHAT_MEDIA_PROGRESS,
        payload: 0,
      })
      return  null
    }
  }



  export const clearMediaUrl = () => async dispatch => {
  
    try {
      dispatch({
        type: UPLOADED_CHAT_MEDIA,
        payload: null
      });
      
    } catch (error) {
      console.log(error);
      
    }
  }

  export const selectedChatUser = (user) => async dispatch => {
  
    try {
      dispatch({
        type: SELECTED_CHAT_USER,
        payload: user
      });
      
    } catch (error) {
      console.log(error);
      
    }
  }