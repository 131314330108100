import React, { useEffect, useState } from "react";
import {
  Grid,
  Tab,
  Box,
  Tabs,
  Typography,

} from "@material-ui/core";
import TopBar from "../../../components/AppBar";
import { ArrowBack } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

import { useLocation, useHistory, Link, useParams } from "react-router-dom";
import { useIntl } from "react-intl";
import CategoriesLevelCourses from "../../../components/Common/CategoriesLevelCourses";

const StyledTabs = withStyles({
  indicator: {
    justifyContent: "center",
    backgroundColor: "transparent",
    "& > span": {
      maxWidth: 30,
      width: "100%",
      backgroundColor: "#09A9BB"
    }
  }
})(props => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

const StyledTab = withStyles(theme => ({
  root: {
    minWidth: "60px !important",
    textTransform: "none",
    color: "#080808e3",
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    "&:focus": {
      opacity: 1
    }
  },
  selected: {
    color: "#09A9BB"
  }
}))(props => <Tab disableRipple {...props} />);
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3} pl={0} pr={0}>
          <>{children}</>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function ViewAllCategoriesCourses(props) {
  const classes = useStyles();
  const intl = useIntl();
  let history = useHistory();
  let params = useParams();
  let location = useLocation();

  useEffect(() => {
    if (!location?.state?.category?.id) {
      history.push('/explore/browse')
    }


  }, [])


  const [value, setValue] = useState(0);


  const handleChange = (event, newValue) => {
    setValue(newValue);

  };

  const hexToRGB = (hex, alpha) => {
    var r = parseInt(hex && hex.slice(1, 3), 16),
      g = parseInt(hex && hex.slice(3, 5), 16),
      b = parseInt(hex && hex.slice(5, 7), 16);

    if (alpha) {
      return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
    } else {
      return "rgb(" + r + ", " + g + ", " + b + ")";
    }
  };

  return (
    <Grid container style={{ padding: '10px 20px 10px 20px' }}>
    {/*   <Grid item xs={2} md={2} lg={1}>
        <Link to={'/explore/browse'}>
          <ArrowBack
            style={{
              fontSize: "30px",
              cursor: "pointer",
              color: "#068594",
              paddingBottom: '15px'
            }}
          />
        </Link>
      </Grid>

      <Grid item xs={10} md={10} lg={11}>
        <Typography className={classes.categoryTitle}>
          {location?.state?.category?.name}
        </Typography>
      </Grid> */}

             <TopBar
              appBarConfig={{
                show: true,
                text:  location?.state?.category?.name,
                backgroundColor: "#ffff"
              }}
              style={{
                color: "#068594",
                backgroundColor: "#ffff",
                float: "left"
              }}
              />

      <Grid item xs={12} className={classes.root} style={{
        backgroundImage: `url(${location?.state?.category?.cover})`,
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center"

      }}>

      </Grid>



      <Grid item xs={12}>
        <StyledTabs
          value={value}
          onChange={handleChange}
          aria-label="styled tabs example"
        >
          <StyledTab
            label={intl.formatMessage({ id: "allLevels" })}
          />
          <StyledTab
            label={intl.formatMessage({ id: "beginner" })}
          />
          <StyledTab
            label={intl.formatMessage({ id: "skilled" })}
          />
          <StyledTab label={intl.formatMessage({ id: "expert" })} />
        </StyledTabs>
      </Grid>
      <Grid item xs={12}>
        <TabPanel value={value} index={0}  >
          {location?.state?.category?.id && <CategoriesLevelCourses level={'All'} />}
        </TabPanel>

        <TabPanel value={value} index={1}  >
          {location?.state?.category?.id && <CategoriesLevelCourses level={'Beginner'} />}
        </TabPanel>

        <TabPanel value={value} index={2}  >
          {location?.state?.category?.id && <CategoriesLevelCourses level={'Intermediate'} />}
        </TabPanel>

        <TabPanel value={value} index={3}  >
          {location?.state?.category?.id && <CategoriesLevelCourses level={'Expert'} />}
        </TabPanel>
      </Grid>
    </Grid>
  );
}

export default ViewAllCategoriesCourses;

const useStyles = makeStyles(theme => ({
  root: {
    height: "0",
    position: "relative",
    paddingTop: "25%",

    borderRadius: "10px",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    background: '#c2c2c2'

  },
  colorCover: {
    borderRadius: "10px",
    width: "100%",
    height: "100%",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    position: "absolute",
    top: 0
  },
  courseTagline: {
    fontFamily: theme.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: 900,
    fontSize: "24px",
    lineHeight: "33px",
    letterSpacing: "0.015em",
    color: "#FFFFFF"
  },
  courseTitle: {
    fontFamily: theme.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: 900,
    fontSize: "48px",
    lineHeight: "65px",
    color: "#FFFFFF",
    position: 'absolute',
    top: 0,
    padding: "40px 0 0 40px"
  },

  noContent: {
    color: "#7d7b79",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "19px",
    padding: "12px 10px 10px 17px",
    textAlign: "center"
  },
  header: {
    width: "100%",
    height: "10.625em",
    background: " #09A9BB",
    borderRadius: "0px 0px 34px 34px ",
    lineHeight: "10.025em"
  },
  desktopHeader: {
    width: "100%",
    height: "5.625em",
    borderRadius: "0px 0px 34px 34px ",
    lineHeight: "5.025em"
  },
  exploreBtn: {
    width: "76px",
    height: "60px",
    borderRadius: "10px",
    transform: "rotate(360deg)"
  },
  expBtnTxt: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "14px",
    color: " #FFF"
  },
  heading: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "18px",
    lineHeight: "21px",
    color: "#3C4A6B",
    marginTop: "30px",
    marginBottom: "15px"
  },
  hideScrollBar: {
    flexWrap: "unset",
    overflowX: "auto",
    "&::-webkit-scrollbar": {
      display: "none"
    }
  },
  categoryTitle: {
    fontFamily: theme.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: 900,
    fontSize: "20px",
    color: "#000",
    paddingBottom: '15px'
  },
}));
