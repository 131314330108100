import { useState, useEffect } from "react";
import {
  Button,
  Card,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  TextField,
  Typography,
  useMediaQuery,
  FormControl,
  InputLabel,
  Select,
  FormHelperText,
  MenuItem,
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import { useSelector } from "react-redux";
import AddImage from "../../../components/AddCourse/AddImage/Index";
import { useIntl } from "react-intl";
import Editor from "../../../components/Editor";
import {
  convertToRaw,
  EditorState,
  ContentState,
  convertFromHTML,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import DropZoneArea from "../../../components/AddCourse/DropZone/DropZoneArea";

import "./courseContainer.scss";
const DescriptionFormContainer = (props) => {
  const theme = useTheme();
  const location = useLocation();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const intl = useIntl();
  let coursesReducerState = useSelector((state) => state.courses);
  let trainingCoursesReducerState = useSelector(
    (state) => state.trainingCourse
  );
 
  let configurations = useSelector((state) => state?.setting?.siteConfig);

  const [image, setImage] = useState(
    props.courseData && props.courseData.courseImage
      ? URL.createObjectURL(props.courseData.courseImage)
      : props.courseData?.image
      ? props.courseData.image
      : ""
  );
  const [imageFile, setImageFile] = useState(
    props.courseData && props.courseData.courseImage
      ? props.courseData.courseImage
      : ""
  );
  const [category, setCategory] = useState(
    props.courseData?.categoryId ? props.courseData.categoryId : ""
  );
  const [retreatType, setRetreatType] = useState(
    props?.courseData && props.courseData?.isPrivate ? "private" : "public"
  );
  const [subCategoryList, setSubCategoryList] = useState(
    props.courseData?.categoryId &&
      coursesReducerState?.allCategoriesList?.length > 0 &&
      coursesReducerState.allCategoriesList.filter(
        (item) => props.courseData?.categoryId === item.id
      )[0]
      ? coursesReducerState.allCategoriesList.filter(
          (item) => props.courseData?.categoryId === item.id
        )[0].childCategories
      : []
  );
  const [subCategory, setSubCategory] = useState(
    props.courseData?.subCategoryId ? props.courseData.subCategoryId : ""
  );
  const [errors, setErrors] = useState({});
  const [course_name, setCourseName] = useState(
    props.courseData?.name ? props.courseData.name : ""
  );
  const [course_remarks, setCourseRemarks] = useState(
    EditorState.createEmpty()
  );
  const [level, setLevel] = useState(
    props.courseData?.level ? props.courseData.level : ""
  );
  const [numberOfStudent, setNumberOfStudents] = useState(
    props.courseData?.noOfStudent ? props.courseData.noOfStudent : ""
  );
  const [tags, setTags] = useState(
    props.courseData?.courseTags?.length > 0
      ? props.courseData.courseTags?.map((item) => item.name)
      : props?.courseData?.tags
      ? props?.courseData?.tags
      : []
  );

  const [previewApproach, setPreviewApproach] = useState(
    props.courseData?.uploadApproach ? props.courseData.uploadApproach : ""
  );
  const [previewUrl, setPreviewUrl] = useState(
    props.courseData?.previewUrl ? props.courseData.previewUrl : ""
  );
  const [previewVideoFile, setPreviewVideoFile] = useState(null);

  const classes = useStyles();

  const selectedFile = (file) => {
    if (file) {
      setErrors({ ...errors, imageError: null });
      setImage(URL.createObjectURL(file));
      setImageFile(file);
    }
  };

  function is_url(str) {
    let regexp =
      /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
    if (regexp.test(str)) {
      return true;
    } else {
      return false;
    }
  }

  const loadVideo = (file) =>
    new Promise((resolve, reject) => {
      try {
        let video = document.createElement("video");
        video.preload = "metadata";

        video.onloadedmetadata = function () {
          resolve(this);
        };

        video.onerror = function () {
          reject("Invalid video. Please select a video file.");
        };

        video.src = window.URL.createObjectURL(file);
      } catch (e) {
        reject(e);
      }
    });
  console.log(coursesReducerState);

  const hasError = async () => {
    let errorsTemp = {};
    if (!category) {
      errorsTemp = {
        ...errorsTemp,
        categoryError: "Category is required",
        subcategoryError: "Select a category then select a sub-category",
      };
    } else if (!subCategory) {
      errorsTemp = {
        ...errorsTemp,
        subcategoryError: "Subcategory is required",
      };
    }
    if (tags?.length === 0) {
      errorsTemp = { ...errorsTemp, tagsError: "At least provide one tab" };
    }
    if (!level) {
      errorsTemp = { ...errorsTemp, levelError: "Level is required" };
    }

    if (!image) {
      errorsTemp = { ...errorsTemp, imageError: "Image is required" };
    }
    if (!course_name) {
      errorsTemp = {
        ...errorsTemp,
        courseNameError: " Title is required",
      };
    }
    if (!course_remarks.getCurrentContent().hasText()) {
      errorsTemp = {
        ...errorsTemp,
        courseRemarksError: "description is required",
      };
    }

    if (
      previewApproach &&
      !previewVideoFile &&
      !previewUrl &&
      !props.courseData?.ipfsCid
    ) {
      errorsTemp = {
        ...errorsTemp,
        previewApproachError: "Either preview url or Video file is required",
      };
    }
    if (previewApproach === "upload" && previewVideoFile) {
      const video = await loadVideo(previewVideoFile);
      if (
        video.duration < 5 ||
        video.duration >
          (configurations?.coursePreviewVideoSize
            ? configurations?.coursePreviewVideoSize * 60
            : 120)
      ) {
        errorsTemp = {
          ...errorsTemp,
          videoSizeError: `Preview video must be between 5 second to ${
            configurations?.coursePreviewVideoSize || 2
          } minutes`,
        };
      }
    }
    if (previewApproach === "external" && !is_url(previewUrl)) {
      errorsTemp = {
        ...errorsTemp,
        invalidUrl: "Please provide a valid url",
      };
    }
    if (!numberOfStudent) {
      errorsTemp = {
        ...errorsTemp,
        numberOfStudentError: "Please enter student limit",
      };
    }

    return errorsTemp;
  };

  useEffect(() => {
    if (props?.courseData?.description) {
      const blocksFromHtml = convertFromHTML(
        unescape(props.courseData?.description)
      );
      const { contentBlocks, entityMap } = blocksFromHtml;
      const contentState = ContentState.createFromBlockArray(
        contentBlocks,
        entityMap
      );
      const editorState = EditorState.createWithContent(contentState);
      setCourseRemarks(editorState);
    }
  }, []);

  const handleCourseSubmit = async () => {
    const errorTemp = await hasError();
    const courseDesHtml = await escape(
      draftToHtml(convertToRaw(course_remarks.getCurrentContent()))
    );

    const blocks = await convertToRaw(course_remarks.getCurrentContent())
      .blocks;

    const shortDescription = blocks
      .map((block) => !block.text.trim() || block.text)
      .join(" ");

    Object.keys(errorTemp).length
      ? setErrors(errorTemp)
      : props.courseData?.id
      ? props.handleNext({
          courseData: {
            courseId: props.courseData.id,
            name: course_name,
            description: courseDesHtml,
            level: level,
            tags: tags.join(","),
            categoryId: category,
            subCategoryId: subCategory,
            shortDescription,
            noOfStudent: numberOfStudent,
            previewUrl,
            retreatType: props?.courseData?.retreatType,
            isPrivate: retreatType === "public" ? false : true,
            uploadApproach: previewApproach,
            upload:
              previewApproach === "upload" && previewVideoFile
                ? {
                    approach: "tus",
                    size: previewVideoFile && previewVideoFile.size,
                  }
                : undefined,
          },
          courseImage: imageFile ? imageFile : null,
          previewVideoFile,
        })
      : props.handleNext({
          courseData: {
            name: course_name,
            description: courseDesHtml,
            level: level,
            tags: tags.join(","),
            categoryId: category,
            subCategoryId: subCategory,
            shortDescription,
            previewUrl,
            retreatType:
              location?.pathname?.includes("/add-retreat") ||
              location?.pathname?.includes("/edit-retreat")
                ? "retreat"
                : "teacherTraining",
            isPrivate: retreatType === "public" ? false : true,
            noOfStudent: numberOfStudent,
            uploadApproach: previewApproach,
            upload:
              previewApproach === "upload"
                ? {
                    approach: "tus",
                    size: previewVideoFile && previewVideoFile.size,
                  }
                : undefined,
          },
          courseImage: imageFile,
          previewVideoFile,
        });
  };

  const setSelectedVideoFile = (files) => {
    if (files?.length) {
      setErrors({
        ...errors,
        previewApproachError: null,
        videoSizeError: null,
      });

      setPreviewVideoFile(files[0]);
    } else {
      setPreviewVideoFile(null);
    }
  };

  return (
    <>
      <Grid
        container
        style={{
          paddingLeft: isMobile && !isDesktop ? "0px" : "2rem",
          padding: isMobile && !isDesktop ? "10px 20px" : "0px",
          marginBottom: "30px",
        }}
      >
        <Grid container spacing={4}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Card
              style={{ marginTop: "1rem", borderRadius: "20px" }}
              elevation={0}
            >
              <AddImage
                btnText={intl.formatMessage({ id: "addImage" })}
                image={image}
                selectedFile={selectedFile}
              />
            </Card>
            <Grid container>
              <p className={classes.errorMessage}>
                {errors && errors.imageError}
              </p>
            </Grid>
          </Grid>
          <Grid
            style={{
              padding: isMobile ? "10px 15px" : "0px 0px 0px 50px",
            }}
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
          >
            <Grid item xs={12}>
              <TextField
                id="standard-multiline-static"
                fullWidth
                value={course_name}
                className="courseDescriptionNameField"
                onChange={(e) => {
                  setCourseName(
                    e.target.value.replace(
                      /[\\#@!$%^&*()_+|~=`{}\[\]";'<>?\/]/,
                      ""
                    )
                  );
                  e.target.value &&
                    errors.courseNameError &&
                    setErrors({ ...errors, courseNameError: null });
                }}
                margin="normal"
                label="Title"
              />
            </Grid>
            <Grid container>
              <p className={classes.errorMessage}>
                {errors && errors.courseNameError}
              </p>
            </Grid>

            <Grid item xs={12}>
              <FormControl
                style={{
                  width: "100%",
                  marginTop: "13px",
                  marginBottom: "5px",
                }}
                error={errors && errors.episodeApproach}
              >
                <InputLabel id="demo-simple-select-error-label">
                  Type
                </InputLabel>
                <Select
                  labelId="demo-simple-select-error-label"
                  id="demo-simple-select-error"
                  value={retreatType}
                  fullWidth
                  onChange={(e) => {
                    setRetreatType(e.target.value);
                  }}
                >
                  <MenuItem value={"public"}>Public</MenuItem>
                  <MenuItem value={"private"}>Private</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <Typography className={classes.levelLabel}>
                {intl.formatMessage({ id: "level" })}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              style={{ marginTop: "1rem", marginBottom: "1rem" }}
            >
              {[
                "All",
                intl.formatMessage({ id: "beginner" }),
                intl.formatMessage({ id: "intermediate" }),
                intl.formatMessage({ id: "expert" }),
              ].map((singleLevel, i) => (
                <Chip
                  label={singleLevel}
                  clickable
                  key={i}
                  onClick={() => {
                    setLevel(singleLevel);
                    errors.levelError &&
                      setErrors({ ...errors, levelError: null });
                  }}
                  variant="outlined"
                  style={{
                    fontFamily: theme.fontFamily.Roboto,
                    fontStyle: "normal",
                    fontWeight: "normal",
                    fontSize: "11px",
                    lineHeight: "13px",
                    border: level === singleLevel ? "0px" : "2px solid #FBAE02",
                    color: level === singleLevel ? "#FFFFFF" : "#FBAE02",
                    paddingLeft: "10px",
                    paddingRight: "10px",
                    marginRight: "10px",
                    marginBottom: "1rem",
                    background:
                      level === singleLevel
                        ? "linear-gradient(180deg, #FFC000 -13.24%, #F28705 100%)"
                        : "transparent",
                  }}
                />
              ))}
            </Grid>
            <Grid container>
              <p className={classes.errorMessage}>
                {errors && errors.levelError}
              </p>
            </Grid>
            <Grid item xs={12} sm={12} lg={12}>
              <Divider
                style={{
                  color: "rgba(168, 182, 200, 0.3)",
                  backgroundColor: "rgba(168, 182, 200, 0.3)",
                }}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid
          container
          style={{
            marginTop: "2rem",
            marginBottom: "1rem",
            padding: isMobile && !isDesktop ? "0px" : "0px",
          }}
        >
          <Grid item xs={12}>
            <Typography
              style={{
                fontFamily: theme.fontFamily.Roboto,
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "14px",
                lineHeight: "16px",
                paddingBottom: "10px",
                color: "#505050",
              }}
            >
              Description
            </Typography>

            <Editor
              editorState={course_remarks}
              placeholder={"Description..."}
              changeDescription={(des) => {
                setErrors({
                  ...errors,
                  courseRemarksError: null,
                });
                setCourseRemarks(des);
              }}
            />
          </Grid>
          <Grid container>
            <p className={classes.errorMessage}>
              {errors && errors.courseRemarksError}
            </p>
          </Grid>

          <Grid
            container
            style={{
              marginTop: "2rem",
              marginBottom: "1rem",
              padding: isMobile && !isDesktop ? "0px" : "0px",
            }}
          >
            <Grid item xs={12}>
              <Typography className={classes.heading1}>
                {intl.formatMessage({ id: "preview" })}
              </Typography>
            </Grid>

            <Grid item xs={12} container>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <FormControl
                  style={{
                    width: "100%",
                    marginTop: "13px",
                    marginBottom: "5px",
                  }}
                  error={errors && errors.episodeApproach}
                >
                  <InputLabel id="demo-simple-select-error-label">
                    Preview Approach
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-error-label"
                    id="demo-simple-select-error"
                    value={previewApproach || ""}
                    fullWidth
                    onChange={(e) => {
                      setPreviewApproach(e.target.value);
                      setErrors({ ...errors, episodeApproach: null });
                      if (e.target.value === "external") {
                        !props?.courseData?.id ||
                        props?.courseData?.uploadApproach === "upload"
                          ? setPreviewUrl("")
                          : setPreviewUrl(props?.courseData?.previewUrl);
                        setPreviewVideoFile("");
                      } else {
                        !props?.courseData?.id ||
                        props?.courseData?.uploadApproach === "external"
                          ? setPreviewUrl("")
                          : setPreviewUrl(props?.courseData?.previewUrl);
                      }
                    }}
                  >
                    <MenuItem value={""}>Select Approach</MenuItem>
                    <MenuItem value={"upload"}>Video File</MenuItem>
                    <MenuItem value={"external"}>Video Url</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            {previewApproach !== "" && (
              <Grid item xs={12} container>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <TextField
                    style={{ marginBottom: "25px" }}
                    value={previewUrl || ""}
                    disabled={previewApproach === "upload"}
                    onChange={(e) => {
                      setPreviewUrl(e.target.value);
                      setErrors({
                        ...errors,
                        previewApproachError: null,
                        invalidUrl: null,
                      });
                    }}
                    error={
                      errors &&
                      (errors.previewApproachError || errors.invalidUrl)
                    }
                    helperText={
                      errors &&
                      (errors.previewApproachError || errors.invalidUrl)
                    }
                    label={"preview url"}
                    fullWidth
                  />
                </Grid>
              </Grid>
            )}

            {previewApproach !== "" && (
              <Grid item xs={12} container>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <Card elevation={0}>
                    <DropZoneArea
                      dropzoneText={intl.formatMessage({ id: "drapndrop" })}
                      btnText={intl.formatMessage({ id: "browse" })}
                      files={previewVideoFile}
                      disabled={previewApproach === "external"}
                      setFiles={setSelectedVideoFile}
                    />
                  </Card>
                </Grid>
                <Grid item xs={12}>
                  <p className={classes.errorMessage}>
                    {errors &&
                      (errors.previewApproachError || errors.videoSizeError)}
                  </p>
                </Grid>
              </Grid>
            )}
          </Grid>

          <Grid item xs={12}>
            <Typography
              style={{
                fontFamily: theme.fontFamily.Roboto,
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "14px",
                lineHeight: "16px",
                color: "#505050",
                paddingTop: "10px",
              }}
            >
              {intl.formatMessage({ id: "category" })}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              marginTop: "1rem",
              marginBottom: "1rem",
            }}
          >
            {coursesReducerState &&
              coursesReducerState.allCategoriesList &&
              coursesReducerState.allCategoriesList.length > 0 &&
              coursesReducerState.allCategoriesList
                .filter((item) => item.parentCategoryId === null)
                .map((singleCategory) => (
                  <Chip
                    label={singleCategory.name}
                    clickable
                    key={singleCategory.id}
                    onClick={() => {
                      setCategory(singleCategory.id);
                      setSubCategory(null);
                      setSubCategoryList(singleCategory.childCategories);
                      errors.categoryError &&
                        setErrors({ ...errors, categoryError: null });
                    }}
                    variant="outlined"
                    style={{
                      maxWidth: "90vw",
                      fontFamily: theme.fontFamily.Roboto,
                      fontStyle: "normal",
                      fontWeight: "normal",
                      fontSize: "11px",
                      lineHeight: "13px",
                      border:
                        category === singleCategory.id
                          ? "0px"
                          : "2px solid #FBAE02",
                      color:
                        category === singleCategory.id ? "#FFFFFF" : "#FBAE02",
                      paddingLeft: "10px",
                      paddingRight: "10px",
                      marginBottom: "0.5rem",
                      marginRight: "10px",
                      background:
                        category === singleCategory.id
                          ? "linear-gradient(180deg, #FFC000 -13.24%, #F28705 100%)"
                          : "transparent",
                      wordBreak: "break-word",
                    }}
                  />
                ))}
          </Grid>
          <Grid container>
            <p className={classes.errorMessage}>
              {errors && errors.categoryError}
            </p>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <Divider
              style={{
                color: "rgba(168, 182, 200, 0.3)",
                backgroundColor: "rgba(168, 182, 200, 0.3)",
              }}
            />
          </Grid>
        </Grid>

        <Grid
          container
          style={{
            marginTop: "2rem",
            marginBottom: "1rem",
            padding: isMobile && !isDesktop ? "0px" : "0px",
          }}
        >
          <Grid item xs={12}>
            <Typography
              style={{
                fontFamily: theme.fontFamily.Roboto,
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "14px",
                lineHeight: "16px",
                color: "#505050",
              }}
            >
              {intl.formatMessage({ id: "subCategory" })}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              marginTop: "1rem",
              marginBottom: "1rem",
            }}
          >
            {category &&
              subCategoryList?.length > 0 &&
              subCategoryList.map((singleCategory) => (
                <Chip
                  label={singleCategory.name}
                  clickable
                  key={singleCategory.id}
                  onClick={() => {
                    setSubCategory(singleCategory.id);
                    errors.subcategoryError &&
                      setErrors({ ...errors, subcategoryError: null });
                  }}
                  variant="outlined"
                  style={{
                    maxWidth: "90vw",
                    fontFamily: theme.fontFamily.Roboto,
                    fontStyle: "normal",
                    fontWeight: "normal",
                    fontSize: "11px",
                    lineHeight: "13px",
                    border:
                      subCategory === singleCategory.id
                        ? "0px"
                        : "2px solid #FBAE02",
                    color:
                      subCategory === singleCategory.id ? "#FFFFFF" : "#FBAE02",
                    paddingLeft: "10px",
                    paddingRight: "10px",
                    marginBottom: "0.5rem",
                    marginRight: "10px",
                    background:
                      subCategory === singleCategory.id
                        ? "linear-gradient(180deg, #FFC000 -13.24%, #F28705 100%)"
                        : "transparent",

                    wordBreak: "break-word",
                  }}
                />
              ))}
          </Grid>
          <Grid container>
            <p className={classes.errorMessage}>
              {errors && errors.subcategoryError}
            </p>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <Divider
              style={{
                color: "rgba(168, 182, 200, 0.3)",
                backgroundColor: "rgba(168, 182, 200, 0.3)",
              }}
            />
          </Grid>
        </Grid>

        <Grid container style={{ marginBottom: "1rem" }}>
          <Grid item xs={12} md={6} lg={6}>
            <TextField
              id="standard-multiline-static"
              fullWidth
              value={numberOfStudent}
              className="courseDescriptionNameField"
              InputProps={{ min: 0 }}
              onChange={(e) => {
                setNumberOfStudents(e.target.value > 0 ? e.target.value : "");
                e.target.value &&
                  errors.numberOfStudentError &&
                  setErrors({ ...errors, numberOfStudentError: null });
              }}
              margin="normal"
              label="Number of Students"
              type="number"
            />
          </Grid>
          <Grid container>
            <p className={classes.errorMessage}>
              {errors && errors.numberOfStudentError}
            </p>
          </Grid>
        </Grid>
        <Grid container style={{ marginTop: "2rem", marginBottom: "1rem" }}>
          <Grid item xs={12}>
            <Typography
              style={{
                fontFamily: theme.fontFamily.Roboto,
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "14px",
                lineHeight: "16px",

                color: "#505050",
              }}
            >
              {intl.formatMessage({ id: "tags" })}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            style={{ marginTop: "1rem", marginBottom: "1rem" }}
          >
            {tags && tags?.length > 0 ? (
              tags.map((tag, i) => (
                <Chip
                  label={tag}
                  variant="outlined"
                  classes={{
                    deleteIcon: classes.deleteIcon,
                  }}
                  key={i}
                  onDelete={() => setTags(tags?.filter((item) => item !== tag))}
                  style={{
                    fontFamily: theme.fontFamily.Reef,
                    fontStyle: "normal",
                    fontWeight: "normal",
                    fontSize: "14px",
                    lineHeight: "19px",
                    border: "0px",
                    color: "#FFFFFF",
                    padding: "10px",
                    paddingRight: "0px",
                    marginBottom: "1rem",
                    marginRight: "10px",
                    background:
                      "linear-gradient(180deg, #FFC000 -13.24%, #F28705 100%)",
                  }}
                />
              ))
            ) : (
              <></>
            )}
            <TextField
              className="courseDescriptionTextFields"
              onBlur={(ev) => {
                if (ev.target.value.trim()) {
                  setTags([...tags, ev.target.value]);
                  errors.tagsError && setErrors({ ...errors, tagsError: null });
                }
                ev.target.value = "";
                ev.preventDefault();
              }}
              onKeyPress={(ev) => {
                console.log(`Pressed keyCode ${ev.key}`);
                if (ev.key === "Enter") {
                  if (ev.target.value.trim()) {
                    setTags([...tags, ev.target.value]);
                  }
                  setErrors({ ...errors, tagsError: null });
                  ev.target.value = "";
                  ev.preventDefault();
                }
              }}
            />
          </Grid>
          <Grid container>
            <p className={classes.errorMessage}>{errors && errors.tagsError}</p>
          </Grid>
        </Grid>

        <Grid
          style={{
            paddingLeft: isMobile && !isDesktop ? "0" : "2rem",
            padding: isMobile && !isDesktop ? "0px" : "0px",
          }}
          item
          xs={12}
          sm={12}
          md={12}
          lg={6}
        >
          <Button
            style={{
              width: "100%",
              height: "2.750em",
              background:
                "linear-gradient(180deg, #FFC000 -13.24%, #F28705 100%)",
              borderRadius: isMobile && !isDesktop ? "50px" : "11px",
              fontFamily: theme.fontFamily.Roboto,
              fontStyle: "normal",
              fontWeight: 500,
              fontSize: "20px",
              lineHeight: "23px",
              color: "#FFFFFF",
            }}
            disabled={trainingCoursesReducerState.courseLoader}
            onClick={() => handleCourseSubmit()}
          >
            {trainingCoursesReducerState.courseLoader  ? (
              <CircularProgress style={{ color: "#FFF" }} size={20} />
            ) : props.courseData?.id ? (
              `${intl.formatMessage({ id: "update" })} & ${intl.formatMessage({
                id: "next",
              })}`
            ) : (
              intl.formatMessage({ id: "next" })
            )}
          </Button>
          
        </Grid>
        {!Object.values(errors).every((o) => o === null) && (
          <Grid
            container
            style={{
              paddingLeft: isMobile && !isDesktop ? "0px" : "2rem",
              padding: isMobile && !isDesktop ? "0px 0px" : "0px",
            }}
          >
            <p className={classes.errorMessage}>{"fix above errors"}</p>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default DescriptionFormContainer;

const useStyles = makeStyles((theme) => ({
  deleteIcon: {
    color: "#fff",
    width: "22px",
    cursor: "pointer",
    height: "22px",
    margin: "0 5px 0 -6px",
    "-webkit-tap-highlight-color": "transparent",
    "&:hover": {
      color: "#fff",
    },
  },
  errorMessage: {
    color: "#f44336",
    margin: 0,
    fontSize: "0.75rem",
    marginTop: " 3px",
    textAlign: "left",
    fontFamily: "Reef",
    fontWeight: 400,
    lineHeight: 1.66,
  },
  textEditorError: {
    fontSize: "0.75rem",
    marginTop: "3px",
    textAlign: "left",
    fontFamily: "Reef,Roboto",
    fontWeight: "400",
    lineHeight: 1.66,
    color: "#f44336",
  },
  levelLabel: {
    paddingTop: "25px",
    paddingBottom: "5px",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "11px",
    lineHeight: "16px",
    color: "#505050",
    opacity: "0.78",
  },
  heading1: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "16px",
    color: "#505050",
    paddingTop: "10px",
  },
}));
