import { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  CircularProgress,
  useMediaQuery,
  FormControl,
  NativeSelect,
  InputBase,
  Button,
} from "@material-ui/core";
import { Link,useHistory } from "react-router-dom";
import { useTheme, withStyles } from "@material-ui/core/styles";
import TopBar from "../../../components/AppBar";
import FavoriteCourseCard from "../../../components/Common/FavoriteCourseCard";
import { useSelector, useDispatch } from "react-redux";
import { useIntl } from "react-intl";
import { makeStyles } from "@material-ui/styles";
import API from "../../../utils/API";
import InfiniteScroll from "react-infinite-scroll-component";
import Header from "../../../components/Common/MobileHeader/HeaderWithMoreBellIcon";
import CategoriesFilter from "../../../components/Common/CategoriesFilters";
import { deleteFavoriteCourse } from "../../../actions/courses";
import { deleteTrainingCourseFavorite } from "../../../actions/retreatCourse";
import SubscribeNowBtn from "../../../components/Common/SubscribeNowBtn";

const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: "#FFEFD1",
    border: "1px solid #FFEFD1",
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    fontFamily: "Reef",
    fontStyle: "normal",
    fontWeight: 900,
    fontSize: "18px",
    lineHeight: "25px",
    "&:focus": {
      borderRadius: 4,
      borderColor: "#FBAE02",
      boxShadow: "0 0 0 0.2rem #FFEFD1",
    },
  },
}))(InputBase);

const Favorite = () => {
  const classes = useStyles();
  const theme = useTheme();
  const intl = useIntl();
  const history = useHistory();
  const dispatch = useDispatch();
  const [localLoader, setLocalLoader] = useState(false);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [type, setType] = useState("Course");
  let reducerUser = useSelector((state) => state.user?.user);

  let auth = useSelector((state) => state.auth);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));



  const limit = 12;

  useEffect(() => {
    setLocalLoader(true);
  }, []);

  useEffect(() => {
    getData();
  }, [selectedCategory, type]);

  const getData = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth?.token}`,
      },
      params: {
        page,
        limit,
        sortOrder: "DESC",
        sortBy: "createdAt",
        categoryId: selectedCategory,
        retreatType:
          type === "Retreat"
            ? "retreat"
            : type === "TeacherTraining"
            ? "teacherTraining"
            : undefined,
      },
    };

    try {
      const res = await API.get(
        type !== "Course"
          ? `/api/v1/trainingCourse/getFavouriteCourses`
          : `/api/v1/course/getFavouriteCourses`,
        config
      );
      let allData = [];
      if (page === 1) {
        allData = await [...res?.data?.data?.rows];
      } else {
        allData = await [...data, ...res?.data?.data?.rows];
      }
      setData(allData);
      setPage(page + 1);
      allData.length >= res?.data?.data?.count && setHasMore(false);
      setLocalLoader(false);
    } catch (error) {
      console.log(error);
      setLocalLoader(false);
      dispatch({
        type: "SHOW_MESSAGE",
        payload: {
          title: `Failed`,
          message:
            error.response &&
            error.response &&
            error.response.data &&
            error.response.data.message
              ? error.response.data.message
              : `Something went wrong while fetching data`,
          severity: "error",
        },
      });
    }
  };

  const handleSelectType = (e) => {
    setPage(1);
    setType(e.target.value);
    setLocalLoader(true);
  };

  const handleSelectedCategory = (categoryId) => {
    setPage(1);
    setSelectedCategory(categoryId);
  };

  const handleRemoveFavorite = async (id) => {
    const res = await dispatch(
      type !== "Course"
        ? deleteTrainingCourseFavorite({ courseId: id, token: auth?.token })
        : deleteFavoriteCourse({ courseId: id, token: auth?.token })
    );
    if (res) {
      type !== "Course"
        ? setData(data?.filter((item) => item?.trainingCourse?.id !== id))
        : setData(data?.filter((item) => item?.course?.id !== res));
    }
  };

  return (
    <Grid container id="InfiniteScrollParent" className={classes.scroll}>
      {isMobile && (
        <Grid item xs={12} style={{ height: "66px" }}>
          <Header text="Favorite" />
        </Grid>
      )}

      {!isMobile && (
        <Grid
          item
          xs={12}
          container
          style={{
            minHeight: "90px",
            position: "fixed",
            backgroundColor: "#fff",
            zIndex: 5,
          }}
          justify="space-between"
        >
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <TopBar
              appBarConfig={{ show: true, text: "Favorite" }}
              style={{
                color: "#068594",
                backgroundColor: "#blue",
                float: "left",
              }}
            />
          </Grid>
          <Grid item container xs={6} sm={6} md={6} lg={6} xl={6}>
            <Grid
              item
              xs={6}
              sm={6}
              md={6}
              lg={6}
              xl={6}
              className={classes.subscribeBtnContainer}
            >
                <SubscribeNowBtn />

            </Grid>
          </Grid>
        </Grid>
      )}

      <Grid
        item
        xs={12}
        container
        style={{
          padding: isMobile ? "15px 15px 15px 15px" : "15px 0px 15px 25px",
          marginTop: isMobile ? "0px" : "80px",
        }}
      >
        <Grid item xs={6} md={3} lg={2}>
          {isDesktop && (
            <Typography
              style={{ float: "left" }}
              className={classes.specificSearchHeading}
            >
              Type
            </Typography>
          )}
          {isMobile && (
            <Grid
              item
              xs={6}
              sm={3}
              md={3}
              style={{ marginTop: "5px", marginLeft: "55px" }}
              className={classes.subscribeBtnContainer}
            >
                {/*<Button*/}
                {/*      className={classes.subscribeBtn}*/}
                {/*      // style={{color: "blue", fontWeight : '700'}}*/}
                {/*      //  disabled={!courseDetail?.id }*/}

                {/*      onClick={() => history.push("/more/subscription-plan")}*/}
                {/*    >*/}
                {/*      <Typography>*/}
                {/*        {reducerUser?.purchasedSubscription?.SubscriptionPlan*/}
                {/*          ?.name === "free" &&*/}
                {/*        reducerUser?.purchasedSubscription.length === 0*/}
                {/*          ? "SUBSCRIBE"*/}
                {/*          : "UPGRADE"}*/}
                {/*      </Typography>*/}
                {/*    </Button>*/}
            </Grid>
          )}
        </Grid>
        <Grid item xs={6} md={3} lg={2}>
          <FormControl
            className={classes.margin}
            style={{
              width: isMobile ? "80%" : "100%",
              marginLeft: isMobile ? "20px" : "0px",
            }}
          >
            <NativeSelect
              value={type}
              onChange={handleSelectType}
              input={<BootstrapInput />}
            >
              <option value={"Course"}>Online Courses</option>
              {/*<option value={"Retreat"}>Online Retreats</option>*/}
              {/*<option value={"TeacherTraining"}>Online Teacher Training</option>*/}
            </NativeSelect>
          </FormControl>
        </Grid>
      </Grid>

      <Grid
        item
        xs={12}
        style={{
          padding: isMobile ? "15px 0px 15px 15px" : "15px 0px 15px 25px",
        }}
      >
        <CategoriesFilter handleSelectedCategory={handleSelectedCategory} />
      </Grid>

      <Grid
        item
        container
        xs={12}
        style={{
          padding: isMobile ? "0px 0px 80px 15px" : "0px 10px 30px 25px",
          marginTop: isMobile ? "10px" : "15px",
        }}
      >
        {localLoader ? (
          <Grid
            item
            xs={12}
            className={classes.center}
            style={{ minHeight: "65vh" }}
          >
            <CircularProgress
              color="primary"
              style={{ color: "#09A9BB" }}
              size={30}
            />
          </Grid>
        ) : data?.length === 0 ? (
          <Grid
            item
            xs={12}
            className={classes.center}
            style={{ minHeight: "65vh" }}
          >
            <Typography className={classes.noContent}>
              No Record found
            </Typography>
          </Grid>
        ) : (
          <Grid item xs={12}>
            <InfiniteScroll
              dataLength={data.length}
              next={getData}
              hasMore={hasMore}
              loader={hasMore && <h4>Loading...</h4>}
              style={{ overflow: "hidden" }}
              scrollableTarget="InfiniteScrollParent"
            >
              <Grid
                item
                xs={12}
                container
                style={{ minHeight: "65vh" }}
                spacing={2}
              >
                {data?.map((item, ind) => {
                  return (
                    <Grid
                      item
                      xs={12}
                      md={4}
                      lg={3}
                      key={ind}
                      style={{ paddingBottom: "25px" }}
                    >
                      {type === "Retreat" ? (
                        <FavoriteCourseCard
                          id={item?.trainingCourse?.id}
                          slug={item?.trainingCourse?.slug}
                          image={item?.trainingCourse?.image}
                          name={item?.trainingCourse?.name}
                          category={item?.trainingCourse?.category?.name}
                          totalDuration={item?.trainingCourse?.totalDuration}
                          handleRemoveFavorite={handleRemoveFavorite}
                          link={`/retreat-details/${item?.trainingCourse?.id}`}
                        />
                      ) : type === "TeacherTraining" ? (
                        <FavoriteCourseCard
                          id={item?.trainingCourse?.id}
                          slug={item?.trainingCourse?.slug}
                          image={item?.trainingCourse?.image}
                          name={item?.trainingCourse?.name}
                          category={item?.trainingCourse?.category?.name}
                          totalDuration={item?.trainingCourse?.totalDuration}
                          handleRemoveFavorite={handleRemoveFavorite}
                          link={`/teacher-training-details/${item?.trainingCourse?.id}`}
                        />
                      ) : (
                        <FavoriteCourseCard
                          id={item?.course?.id}
                          slug={item?.course?.slug}
                          image={item?.course?.image}
                          name={item?.course?.name}
                          category={item?.course?.category?.name}
                          totalDuration={item?.course?.totalDuration}
                          plan={item?.course?.plan}
                          price={item?.course?.price}
                          handleRemoveFavorite={handleRemoveFavorite}
                          link={`/view-course/${item?.course?.slug}`}
                        />
                      )}
                    </Grid>
                  );
                })}
              </Grid>
            </InfiniteScroll>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};
export default Favorite;

const useStyles = makeStyles((theme) => ({
  center: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  noContent: {
    color: "#7d7b79",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "19px",

    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  subscribeBtn: {
    height: "2.463em",
    width: "60%",
    background: "#4DB051",
    borderRadius: "10px",
    transform: "rotate(360deg)",
    fontFamily: theme.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "10px",
    color: "#FFFFFF",
  },

  scroll: {
    height: "100vh",
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      width: "0px",
      background: "transparent",
    },
  },
  infinScroll: {
    overflowY: "unset",
  },
}));
