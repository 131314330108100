import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useSelector, useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import {
  Grid,
  CircularProgress,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/styles";
import API from "../../../utils/API";
import CourseCard from "../../../components/Common/CourseCard";

function SubCategoriesLevelCourses(props) {
  const dispatch = useDispatch();
  const location = useLocation();
  const classes = useStyles();
  const theme = useTheme();

  const [localLoader, setLocalLoader] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [data, setData] = useState([]);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const limit = 12;

  let auth = useSelector((state) => state.auth);
  useEffect(() => {
    return () => setData([]);
  }, []);
  useEffect(() => {
    setData([]);
    setLocalLoader(true);
    getData({
      pageNo: 1,
      categoryId: location?.state?.category?.parentCategoryId,
      subCategoryId: location?.state?.category?.id,
      level: props?.level,
    });
  }, []);

  const getData = async ({ pageNo, categoryId, subCategoryId, level }) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth?.token}`,
      },
      params: {
        page: pageNo,
        limit,
        sortOrder: "DESC",
        sortBy: "createdAt",
        categoryId,
        subCategoryId,
        level,
      },
    };
    try {
      const res = await API.get(`/api/v1/course/getCategoryCourses`, config);

      if (data.length > 0 && pageNo === 1) {
        const allData = await [...res.data.data.courses];
        setData(allData);
        setPage(page + 1);
        allData.length >= res.data.data.totalCount && setHasMore(false);
        setLocalLoader(false);
      } else {
        const allData = await [...data, ...res.data.data.courses];
        setData(allData);
        setPage(page + 1);
        allData.length >= res.data.data.totalCount && setHasMore(false);
        setLocalLoader(false);
      }
    } catch (error) {
      console.log(error);
      setLocalLoader(false);
      dispatch({
        type: "SHOW_MESSAGE",
        payload: {
          title: `Failed`,
          message:
            error.response &&
            error.response &&
            error.response.data &&
            error.response.data.message
              ? error.response.data.message
              : `Something went wrong while fetching data`,
          severity: "error",
        },
      });
    }
  };
  return (
    <Grid container>
      {localLoader ? (
        <Grid item xs={12} className={classes.center}>
          <CircularProgress
            color="primary"
            style={{ color: "#09A9BB" }}
            size={30}
          />
        </Grid>
      ) : data?.length === 0 && !localLoader ? (
        <Grid item xs={12} className={classes.center}>
          <Typography className={classes.noContent}>No Record found</Typography>
        </Grid>
      ) : (
        <Grid item xs={12} id="InfiniteScrollParent">
          <InfiniteScroll
            dataLength={data.length}
            next={async () =>
              await getData({
                pageNo: page + 1,
                categoryId: location?.state?.category?.parentCategoryId,
                subCategoryId: location?.state?.category?.id,
                level: props?.level,
              })
            }
            hasMore={hasMore}
            loader={<h4>Loading...</h4>}
            style={{ overflow: "unset", height: "100vh" }}
          >
            <Grid container style={{ paddingBottom: "100px" }}>
              <Grid
                item
                xs={12}
                container
                spacing={isMobile ? 0 : 3}
                justifyContent={"space-between"}
              >
                {data?.map((course) => (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={3}
                    lg={3}
                    xl={3}
                    key={course?.id}
                    style={{
                      minWidth: "190px",
                      margin: "0px 0px 10px 0px",
                      paddingRight: !isMobile ? "0px" : "",
                    }}
                  >
                    <Link
                      to={`/view-course/${course?.slug}`}
                      style={{ textDecoration: "none" }}
                    >
                      <CourseCard
                        image={course?.image}
                        name={course?.name}
                        totalDuration={course?.totalDuration}
                        width="100%"
                        plan={course?.plan}
                        price={course?.price}
                      />
                    </Link>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </InfiniteScroll>
        </Grid>
      )}
    </Grid>
  );
}

export default SubCategoriesLevelCourses;

const useStyles = makeStyles((theme) => ({
  infinScroll: {
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      width: "0px",
      background: "transparent",
    },
  },
  center: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  noContent: {
    color: "#7d7b79",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "19px",

    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  scroll: {
    height: "50vh",
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      width: "0px",
      background: "transparent",
    },
  },
}));
