import React, { useEffect, useState } from "react";
import {
  Grid,
  TextField,
  Divider,
  Button,
  CircularProgress,
  useMediaQuery,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import RelateIcon from "../../../assets/Icons/RelateIcon.png";
import AppBar from "../../../components/AppBar/index";
import { updateSetting } from "../../../actions/setting";
import { useDispatch, useSelector } from "react-redux";
import { useTheme } from "@material-ui/core/styles";
import MobileAppbar from "../../../components/More/settings/AppBar";
import { useIntl } from "react-intl";
import { useForm, Controller } from "react-hook-form";
import axios from "axios";

function ContactUs(props) {
  let dispatch = useDispatch();
  const classes = useStyles();
  let authReducerState = useSelector((state) => state.auth);
  const [localLoader, setLocalLoader] = useState(false);
  let updatePrivacy = useSelector((state) => state.message);
  const intl = useIntl();
  const { register, errors, handleSubmit, control, reset } = useForm();

  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));

  const onSubmit = async (data) => {
    try {
      setLocalLoader(true);
      const res = await axios.post(
        "https://landingadmin.tantraschooloflove.com/contacts",
        data
      );
      reset();
      setLocalLoader(false);
      dispatch({
        type: "SHOW_MESSAGE",
        payload: {
          title: `success`,
          message: "contact info with message sent successfully",
          severity: "success",
        },
      });
    } catch (error) {
      console.log("error", error);
      setLocalLoader(false);
      dispatch({
        type: "SHOW_MESSAGE",
        payload: {
          title: `Failed`,
          message: "contact info sent failed",
          severity: "error",
        },
      });
    }
  };

  return (
    <Grid container>
      <Grid xs={12}>
        {isMobile && !isDesktop ? (
          <MobileAppbar
            body={intl.formatMessage({ id: "contactUs" })}
            onClick={props.handleBack}
            backAction={"Settings"}
            backgroundColor="#87AC26"
          />
        ) : (
          <AppBar
            appBarConfig={{
              show: true,
              text: intl.formatMessage({ id: "contactUs" }),
              backAction: props.handleBack,
            }}
          />
        )}
      </Grid>

      <Grid
        xs={12}
        sm={12}
        md={10}
        lg={9}
        style={{ padding: "0px 30px 90px 30px" }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <TextField
            classes={{ root: classes.root }}
            inputRef={register({
              required: "name field can't be empty!",
            })}
            label="Name"
            name="name"
            error={!!errors.name}
            helperText={errors.name && errors.name.message}
            type="text"
          />

          <TextField
            classes={{ root: classes.root }}
            inputRef={register({
              required: intl.formatMessage({ id: "requiredEmailField" }),
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: intl.formatMessage({ id: "invalidEmail" }),
              },
            })}
            label={intl.formatMessage({ id: "email" })}
            name="email"
            helperText={errors.email && errors.email.message}
            error={!!errors.email}
            type="text"
          />

          <TextField
            classes={{ root: classes.root }}
            inputRef={register({
              required: "subject field can't be empty!",
            })}
            label="Subject"
            name="subject"
            error={!!errors.subject}
            helperText={errors.subject && errors.subject.message}
            type="text"
          />

          <TextField
            id="message"
            inputRef={register({
              required: "message field can't be empty!",
            })}
            classes={{ root: classes.messageRoot }}
            style={{ width: "100%" }}
            placeholder="Message"
            name="message"
            type="text"
            multiline
            rows={7}
            variant="outlined"
            error={!!errors.message}
            helperText={errors.message && errors.message.message}
          />

          <Grid container>
            <Grid item xs={5} md={4} lg={3} xl={2}>
              <Button
                type="submit"
                disabled={localLoader}
                className={classes.btn}
              >
                {localLoader ? (
                  <CircularProgress size={15} style={{ color: "#09a9bb" }} />
                ) : (
                  "Send"
                )}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
}

export default ContactUs;

const useStyles = makeStyles((theme) => ({
  logoImage: {
    marginLeft: "10px",
    marginTop: "10px",
    height: "75px",
    width: "75px",
  },
  title: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "22px",
    lineHeight: "26px",
    color: "#000000",
  },
  fullHeight: {
    height: "100%",
    width: "70%",
    display: "flex",
  },
  body: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "18px",
    lineHeight: "26px",
    letterSpacing: "0.035em",
    color: "#9E9393",
  },
  subTitle: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "16px",
    color: "#8B8B8B",
  },
  header: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "22px",
    lineHeight: "26px",
    color: "#000000",
  },
  selectedBtn: {
    background: "linear-gradient(#87AC26 1%,#87AC26 100%)",
    borderRadius: "11px",
    color: "#FFFFFF",
    padding: "10px",
    height: "50px",
    width: "140px",
  },
  unSelectBtn: {
    borderColor: "#87AC26",
    borderRadius: "11px",
    color: "#87AC26",
    padding: "10px",
    height: "50px",
    width: "140px",
  },
  btn: {
    background: "linear-gradient(210.1deg, #15AEBF 13.46%, #14D9D9 76.64%);",
    boxShadow: "0px 3px 6px rgba(18, 166, 166, 0.25)",
    width: "100%",
    height: "50px",
    marginTop: "20px",
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "20px",
    lineHeight: "28px",
    letterSpacing: "0.09em",
    color: "#FFFFFF",
    textTransform: "none",
  },
  root: {
    width: "100%",
    marginBottom: "5%",
    "& .MuiInputBase-root": {
      color: "#a9a9a9",
      borderBottom: "1px solid #a9a9a9",
    },
    "& .MuiFormLabel-root": {
      color: "#a9a9a9",
    },
    "& .MuiFormLabel-root.Mui-focused": {
      color: "#a9a9a9",
    },
    "& .MuiInputBase-input": {
      color: "#000000",
    },
    "& .MuiInput-underline": {
      "&::before": {
        borderBottom: "1px solid #a9a9a9",
      },
      "&::after": {
        borderBottom: "1px solid #a9a9a9",
      },
    },

    "& .MuiInput-underline:hover:not(.Mui-disabled)": {
      "&::before": {
        borderBottom: "1px solid #a9a9a9",
      },
      "&::after": {
        borderBottom: "1px solid #a9a9a9",
      },
    },
  },
  messageRoot: {
    marginTop: "5%",
    "& .MuiInputBase-root": {
      color: "#a9a9a9",
      border: "1px solid #a9a9a9",
    },
    "& .MuiFormLabel-root": {
      color: "#a9a9a9",
    },
    "& .MuiFormLabel-root.Mui-focused": {
      color: "#a9a9a9 !important",
    },
    "& .MuiInputBase-input": {
      color: "#000000",
    },
  },
}));
