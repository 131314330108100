import React from "react";
import { Grid, Typography, Avatar } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from 'react-redux'
import Rating from '@material-ui/lab/Rating'

function CourseReviews(props) {
  const classes = useStyles();
  const courseUserID = useSelector(state => state?.courses?.courseDetail?.user?.id)
 

  return (
    <>
      {props?.courseReviews?.map((review, ind) => (
        <div className={classes.root}>
          <Grid container style={{ margin: "15px 15px 15px 0px" }}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <div style={{ display: 'flex', marginTop: '-20px' }}>
                {review?.user?.pictureUrl ? (
                  <Avatar variant="square" src={review?.user?.pictureUrl} style={{ width: "60px", height: "60px", marginLeft: "10px", borderRadius: '8px' }} />

                ) : (
                    <Avatar variant="square" style={{ width: "60px", height: "60px", marginLeft: "10px", borderRadius: '8px' }} />
                  )}
                <div style={{ marginTop: courseUserID === review?.user?.id ? '35px' : '20px', marginLeft: '8px' }}>
                  <Typography style={{ fontSize: "14px" }}>
                    {review?.user?.name}
                  </Typography>

                  {courseUserID !== review?.user?.id &&
                    <Rating
                      name="simple-controlled"
                      size={'small'}
                      value={review?.rating}
                      readOnly={true}
                      precision={0.5}
                      classes={{sizeSmall:classes.ratingSize}}
                    />
                  }
                </div>
              </div>

            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Typography
                className={`${classes.ReviewText} ${classes.ReviewTextSubTitle}`}
              >
                {review?.review}
              </Typography>
            </Grid>
          </Grid>
        </div>

      ))}
    </>
  );
}

export default CourseReviews;

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    minHeight: "100px",
    marginTop: "45px",
    background: "#FFFFFF",
    boxShadow: "0px 6px 6px rgba(0, 0, 0, 0.05)",
    borderRadius: "17px"
  },
  CourseImageCardText: {
    fontFamily: theme.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: 900,
    fontSize: "16px",
    lineHeight: "10px",
    color: " #FFFFFF"
  },
  instructorIntroText: {
    fontFamily: theme.fontFamily.Reef
  },
  ReviewText: {
    fontFamily: "Reef",
    fontStyle: "normal",
    fontWeight: 900
  },
  ReviewTextSubTitle: {
    fontSize: "16px",
    lineHeight: "22px",
    color: "#B0B0B0",
    letterSpacing: "0.015em",
    padding: "0px 10px 10px 10px"
  },
  ratingSize:{
    fontSize:'10px'
  }
}));
