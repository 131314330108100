import API from "../utils/API";

import { SET_SITE_SETTINGS } from "./types";

export const getSiteConfigs = ({ token }) => async dispatch => {
  // set header
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    }
  };

  try {
    const res = await API.get(`/api/v1/config`, config);
    let resData = {};
    if (res?.data?.data) {
      res.data.data.map(item => {
        resData[item.key] = item.value;
      });
      dispatch({
        type: SET_SITE_SETTINGS,
        payload: resData
      });
    }
  } catch (error) {
    console.log(error);
  }
};
