// types
import {
    GET_NEW_TEACHERS,
    TEACHER_LOADER
  } from "../actions/types";
  
  // initial state
  const initialState = {
    newTeachers: [],
    teacherLoader: false,
    
  };
  
  // handle actions
  export default function(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
      case GET_NEW_TEACHERS:
        return {
          ...state,
          newTeachers: payload
        };
      case TEACHER_LOADER:
           return {
          ...state,
          teacherLoader: payload
        };
    
      default:
        return state;
    }
  }
  