import API from "../utils/API";
import * as tus from "tus-js-client";
import axios from "axios";

// types
import {
  SET_CATEGORIES,
  SET_PLANS,
  ADD_SUCCESSFUL,
  SET_COURSE,
  COURSE_LOADER_TRUE,
  COURSE_LOADER_FALSE,
  SHOW_MESSAGE,
  SET_EPISODES,
  REMOVE_EPISODES,
  COURSE_DETAIL,
  LATEST_CATEGORIES,
  ADD_COURSE_REVIEW,
  ADD_COURSE_RATING,
  GET_NEW_COURSES,
  GET_RECENT_COURSES,
  FAVORITE_COURSE,
  GET_USER_UPLOADED_COURSES,
  GET_USER_ENROLLED_COURSES,
  GET_QUOTE,
  GET_COURSES_GROUP_BY_CATEGORY,
  GET_COURSES_BY_CATEGORY,
  SEARCH_NEW_COURSES,
  EPISODE_DETAIL,
  JOIN_FREE_COURSE,
  COURSE_STATS_DETAIL,
  GET_ALL_CREATED_JOINED_SESSIONS,
  GET_ALL_CHALLENGES,
  LOADER,
  SEARCH,
  COURSE_LOADER,
  PREVIEW_VIDEO_PROGRESS,
  SET_CATEGORIES_LIST,
} from "./types";
import EpisodeAddContainer from "../containers/Common/CoursesContainer/EpisodesAddContainer";

const IPFS_API_URL =
  process.env.REACT_APP_IPFS_API_URL || "https://ipfs.tantraschooloflove.com/api/v0/add";
export const getAllCategories = () => async (dispatch) => {
  // set header
  const config = {
    headers: {
      "Content-Type": "application/json",
      //   Authorization: `Bearer ${token}`
    },
  };

  try {
    const res = await API.get(`/api/v1/category/getAllCategories`, config);
    dispatch({
      type: SET_CATEGORIES,
      payload: res.data.data?.length
        ? res.data.data.filter((item) => item.parentCategoryId === null)
        : [],
    });
  } catch (error) {
    console.log(error);
  }
};

//Get all categories lists
export const getAllCategoriesList = () => async (dispatch) => {
  // set header
  const config = {
    headers: {
      "Content-Type": "application/json",
      //   Authorization: `Bearer ${token}`
    },
  };
  try {
    const res = await API.get(`/api/v1/category/getAllCategoriesList`, config);
    dispatch({
      type: SET_CATEGORIES_LIST,
      payload: res.data.data?.length
        ? res.data.data.filter((item) => item.parentCategoryId === null)
        : [],
    });
  } catch (error) {
    console.log(error);
  }
};

// get latest categories

export const getLatestCategories = () => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const res = await API.get(`/api/v1/category/getAllCategories`, config);
    dispatch({
      type: LATEST_CATEGORIES,
      payload: res.data.data.filter((item) => item.parentCategoryId === null),
    });
  } catch (error) {
    console.log(error);
  }
};

export const updateEpisodeOrderApi =
  ({ episodes, token }) =>
  async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    const body = JSON.stringify({ episodes });
    try {
      const res = await API.patch(
        `/api/v1/course/changeEpisodesOrder`,
        body,
        config
      );
      if (res.status === 200) {
        dispatch({
          type: "SET_ALL_EPISODES",
          payload: res.data.data,
        });
        return {
          success: true,
        };
      }
    } catch (error) {
      console.log(error);

      dispatch({
        type: SHOW_MESSAGE,
        payload: {
          title: `Could not update episode order`,
          message:
            error.response &&
            error.response &&
            error.response.data &&
            error.response.data.message
              ? error.response.data.message
              : `Something went wrong while updating order`,
          severity: "error",
        },
      });
      return {
        success: false,
      };
    }
  };

export const courseActiveInActive =
  ({ token, courseId, status }) =>
  async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    const body = JSON.stringify({ courseId, status });
    try {
      const res = await API.patch(`/api/v1/course/changeStatus`, body, config);
      dispatch(getCourseDetailById({ courseId, token }));
      delete res.data.data.episodes;
      dispatch({
        type: SET_COURSE,
        payload: {
          ...res.data.data,
          tags: res.data.data.tags.split(","),
        },
      });
      dispatch({
        type: SHOW_MESSAGE,
        payload: {
          title: `success`,
          message: res.data.message,
          severity: "success",
        },
      });
      return {
        status: true,
      };
    } catch (error) {
      console.log(error);
      dispatch({
        type: SHOW_MESSAGE,
        payload: {
          title: `Failed`,
          message:
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : `change status failed`,
          severity: "error",
        },
      });
      return {
        status: false,
      };
    }
  };

export const changeEpisodeStatus =
  ({ token, episodeId, status, showMessage }) =>
  async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      params: {
        episodeId,
        status,
      },
    };

    try {
      const res = await API.patch(
        `/api/v1/course/changeEpisodeStatus`,
        JSON.stringify({}),
        config
      );
      dispatch({
        type: SET_EPISODES,
        payload: res.data.data,
      });
      showMessage &&
        dispatch({
          type: SHOW_MESSAGE,
          payload: {
            title: `success`,
            message: res.data.message,
            severity: "success",
          },
        });
      return res.data.data;
    } catch (error) {
      console.log(error);
      showMessage &&
        dispatch({
          type: SHOW_MESSAGE,
          payload: {
            title: `Failed`,
            message:
              error.response &&
              error.response.data &&
              error.response.data.message
                ? error.response.data.message
                : `change status failed`,
            severity: "error",
          },
        });
    }
  };

export const getAllPlans =
  ({ token }) =>
  async (dispatch) => {
    // set header
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const res = await API.get(`/api/v1/plan/getAllPlans`, config);
      dispatch({
        type: SET_PLANS,
        payload: res.data.data,
      });
    } catch (error) {
      console.log(error);
    }
  };

export const deleteCourse =
  ({ token, courseId }) =>
  async (dispatch) => {
    dispatch({
      type: COURSE_LOADER_TRUE,
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const res = await API.delete(
        `/api/v1/course/deleteCourse/${courseId}`,
        config
      );
      dispatch({
        type: SHOW_MESSAGE,
        payload: {
          title: `Course Deleted`,
          message:
            res.data && res.data.message ? res.data.message : `Course Deleted`,
          severity: "warning",
        },
      });
      dispatch({
        type: COURSE_LOADER_FALSE,
      });
    } catch (error) {
      dispatch({
        type: COURSE_LOADER_FALSE,
      });
      dispatch({
        type: SHOW_MESSAGE,
        payload: {
          title: `Course Added`,
          message:
            error.response &&
            error.response.data &&
            error.response.data.errors &&
            error.response.data.errors.length
              ? error.response.data.errors[0]
              : error.response &&
                error.response.data &&
                error.response.data.message
              ? error.response.data.message
              : `Something went wrong while deleting`,
          severity: "error",
        },
      });
    }
  };

export const addCourse =
  ({ courseData, token, plan, courseImage, episodes, previewVideoFile }) =>
  async (dispatch) => {
    // set header

    dispatch({
      type: COURSE_LOADER_TRUE,
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    // set body
    // const client = create("https://ipfs.omidlife.com/");
    // const added = await client.add(previewVideoFile?.path);
    // console.log({ added });
    // const responseFromIPFS = await client(urlSource(previewVideoFile?.path));

    // console.log({ responseFromIPFS });

    // const ipfs = create();
    // const file = await ipfs.add(
    //   urlSource("https://ipfs.io/images/ipfs-logo.svg")
    // );
    // console.log(file);
    // debugger;
    try {
      // const res = await API.post(`/api/v1/course/createCourse`, body, config);
      // debugger;
      if (previewVideoFile) {
        let formData = new FormData();
        formData.append("file", previewVideoFile);

        // const body = { ...formData };
        // debugger;
        const ipfsResponse = await axios.post(IPFS_API_URL, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },

        });

        if (ipfsResponse && ipfsResponse.data && ipfsResponse.data.Hash) {
          courseData["ipfsCid"] = ipfsResponse.data.Hash;
        }
      }

      const body = JSON.stringify({ ...courseData });

      const res = await API.post(`/api/v1/course/createCourse`, body, config);

      if (res.data.data.course) {
        dispatch(
          addImage({
            courseData: { ...res.data.data.course, image: courseImage },
            token,
          })
        );
        // debugger;

        // if (previewVideoFile) {
        //   dispatch(
        //     addCoursePreviewVideo({
        //       courseData: {
        //         videoFile: previewVideoFile,
        //         ...courseData,
        //         ...res.data.data,
        //       },

        //       token,
        //       res,
        //     })
        //   );
        // } else {
        dispatch({
          type: SET_COURSE,
          payload: {
            ...res.data.data.course,
            courseTags: res.data.data.courseTags,
            tags: courseData.tags.split(","),
          },
        });
        dispatch({
          type: ADD_SUCCESSFUL,
          payload: true,
        });
        dispatch({
          type: SHOW_MESSAGE,
          payload: {
            title: `Course`,
            message:
              res.data && res.data.message ? res.data.message : `Course Added`,
            severity: "success",
          },
        });
        dispatch({
          type: COURSE_LOADER_FALSE,
        });
        // }
      } else {
        dispatch({
          type: COURSE_LOADER_FALSE,
        });
      }
      // debugger
    } catch (error) {
      console.log(error);
      dispatch({
        type: SHOW_MESSAGE,
        payload: {
          title: `Could not add course`,
          message:
            error.response &&
            error.response.data &&
            error.response.data.errors &&
            error.response.data.errors.length
              ? ` One or more values is missing from json validation`
              : error.response &&
                error.response.data &&
                error.response.data.message
              ? error.response.data.message
              : ` Something went wrong try after some time`,
          severity: "error",
        },
      });
      dispatch({
        type: COURSE_LOADER_FALSE,
      });
    }
  };

export const addCoursePreviewVideo =
  ({ courseData, token, res }) =>
  async (dispatch) => {
    // set header
    // dispatch({
    //   type: COURSE_LOADER_TRUE
    // });

    // set body
    console.log(courseData);
    // let formData = new FormData();

    // formData.append("episode", episodeData.videoFile);
    // const body = formData;
    // console.log('body', body)
    try {
      // dispatch({
      //   type: "EPISODE__LOADER",
      //   payload: courseData.id
      // });
      // const videoFileUpdateResponse = await API.patch(vidoeUrlResponse.data.data, reader.readAsBinaryString(episodeData.videoFile), configVideoUpload )
      // console.log(videoFileUpdateResponse)

      var myHeaders = new Headers();
      myHeaders.append("Tus-Resumable", "1.0.0");
      myHeaders.append("Upload-Offset", 0);
      myHeaders.append("Content-Type", "application/offset+octet-stream");
      myHeaders.append("Accept", "application/vnd.vimeo.*+json;version=3.4");

      console.log("tus supported", tus.isSupported);
      var upload = new tus.Upload(courseData.videoFile, {
        uploadUrl: courseData.uploadLink,
        endpoint: courseData.uploadLink,
        overridePatchMethod: true,
        retryDelays: [0, 3000, 5000, 10000, 20000],
        chunkSize: 12800000,
        metadata: {
          filename: courseData?.course?.name + " " + courseData?.course.id,
          filetype: courseData.videoFile.type,
        },
        onError: function (error) {
          console.log("Failed because: " + error);
        },
        onProgress: function (bytesUploaded, bytesTotal) {
          var percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2);
          console.log(bytesUploaded, bytesTotal, percentage + "%");
          dispatch({
            type: PREVIEW_VIDEO_PROGRESS,
            payload: percentage,
          });
        },
        onSuccess: function () {
          // dispatch({
          //   type: "EPISODE_VIDEO_LOADER",
          //   payload: courseData.id
          // });
          dispatch({
            type: PREVIEW_VIDEO_PROGRESS,
            payload: 0,
          });

          dispatch({
            type: SET_COURSE,
            payload: {
              ...res.data.data.course,
              courseTags: res.data.data.courseTags,
              tags: courseData.tags.split(","),
            },
          });
          dispatch({
            type: ADD_SUCCESSFUL,
            payload: true,
          });
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              title: `Course Added`,
              message:
                res.data && res.data.message
                  ? res.data.message
                  : `Course Added`,
              severity: "success",
            },
          });
          dispatch({
            type: COURSE_LOADER_FALSE,
          });

          console.log("Download %s from %s", upload.file.name, upload.url);
        },
      });

      // Start the upload
      await upload.start();

      // await fetch(vidoeUrlResponse.data.data, requestOptions)
      //   .then(response => response.text())
      //   .then(result => console.log(result))
      //   .catch(error => console.log('error', error));
      // login user
      // const res = await API.post(
      //   `/api/v1/course/uploadEpisode?episodeId=${episodeData.id}&courseId=${episodeData.courseId}`,
      //   body,
      //   config
      // );
      // dispatch({ type: "EPISODE_VIDEO_PROGRESS", payload: 0 });
      // dispatch({
      //   type: SET_EPISODES,
      //   payload: res.data.data
      // });
      // localStorage.setItem("token",res.data.token);
    } catch (error) {}
  };

export const updateCourse =
  ({
    courseData,
    token,
    // plan,
    courseImage,
    // episodes
    previewVideoFile,
  }) =>
  async (dispatch) => {
    // set header
    dispatch({
      type: COURSE_LOADER_TRUE,
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    // set body
    // console.log('body', body)
    try {
      // login user
      if (previewVideoFile) {
        let formData = new FormData();
        formData.append("file", previewVideoFile);

        const ipfsResponse = await axios.post(IPFS_API_URL, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        if (ipfsResponse && ipfsResponse.data && ipfsResponse.data.Hash) {
          courseData["ipfsCid"] = ipfsResponse.data.Hash;
        }
      }

      const body = JSON.stringify({ ...courseData });

      const res = await API.patch(`/api/v1/course/updateCourse`, body, config);
      // localStorage.setItem("token",res.data.token);
      if (res.data.data.course && courseImage) {
        dispatch(
          addImage({
            courseData: { ...res.data.data.course, image: courseImage },
            token,
          })
        );
      }

      // if (res.data.data.course && previewVideoFile) {
      //   await dispatch(
      //     addCoursePreviewVideo({
      //       courseData: {
      //         videoFile: previewVideoFile,
      //         ...courseData,
      //         ...res.data.data,
      //       },
      //       // onUploadProgress: event => {
      //       //   onUploadProgress=(Math.round((100 * event.loaded) / event.total));
      //       // },
      //       token,
      //       res,
      //     })
      //   );
      // } else {
      dispatch({
        type: SET_COURSE,
        payload: {
          ...res.data.data.course,
          tags: courseData.tags.split(","),
        },
      });
      dispatch({
        type: ADD_SUCCESSFUL,
        payload: true,
      });
      dispatch({
        type: SHOW_MESSAGE,
        payload: {
          title: `Course Updated`,
          message:
            res.data && res.data.message ? res.data.message : `Course Updated`,
          severity: "success",
        },
      });
      dispatch({
        type: COURSE_LOADER_FALSE,
      });
      // }
    } catch (error) {
      console.log(error);
      dispatch({
        type: SHOW_MESSAGE,
        payload: {
          title: `Could not update course`,
          message:
            error.response &&
            error.response.data &&
            error.response.data.errors &&
            error.response.data.errors.length
              ? ` One or more values is missing from json validation`
              : error.response &&
                error.response.data &&
                error.response.data.message
              ? error.response.data.message
              : ` Something went wrong try after some time`,
          severity: "error",
        },
      });
      dispatch({
        type: COURSE_LOADER_FALSE,
      });
    }
  };

export const addImage =
  ({ courseData, token }) =>
  async (dispatch) => {
    // set header
    dispatch({
      type: COURSE_LOADER_TRUE,
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    // set body
    let formData = new FormData();
    formData.append("image", courseData.image);
    const body = formData;
    // console.log('body', body)
    try {
      // login user
      const res = await API.post(
        `/api/v1/course/uploadCourseImage?courseId=${courseData.id}`,
        body,
        config
      );
      console.log({ courseData: res.data.data });
      // localStorage.setItem("token",res.data.token);
      dispatch({
        type: SET_COURSE,
        payload: {
          ...res.data.data,
          tags: res.data.data.tags.split(","),
          step: 1,
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

export const addEpisode =
  ({
    episodeData,
    episodeImage,
    episodeVideo,
    token,
  },  onUploadProgress
  ) =>
  async (dispatch) => {
    // set header
    dispatch({
      type: COURSE_LOADER_TRUE,
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
  
      // onUploadProgress
    };

    // set body

    // console.log('body', body)
    try {
      // login user

      if (episodeVideo) {
        let formData = new FormData();
        formData.append("file", episodeVideo);
        const ipfsResponse = await axios.post(IPFS_API_URL, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          } ,
              //   onUploadProgress: event => {
              //   onUploadProgress(Math.round((100 * event.loaded) / event.total));
            
              // },
              onUploadProgress: function(progressEvent) {
                var percentCompleted = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
                onUploadProgress(percentCompleted);
                console.log(percentCompleted)
              }
          
        });
        // debugger
        

        if (ipfsResponse && ipfsResponse.data && ipfsResponse.data.Hash) {
          episodeData["ipfsCid"] = ipfsResponse.data.Hash;
          // courseData.ipfsCid(ipfsResponse.Hash);
        }
      }

      const body = JSON.stringify({
        ...episodeData,
        upload: {
          approach: "tus",
          size: episodeVideo.size,
        },
      });
      const res = await API.post(`api/v1/course/addEpisode`, body, config );
      if (res.data.data) {
        if (episodeImage) {
          dispatch({
            type: SET_EPISODES,
            payload: res.data.data,
          });
          await dispatch(
            addEpisodeImage({
              episodeData: {
                videoFile: episodeVideo,
                image: episodeImage,
                ...res.data.data,
              },
              token,
            })
          );
        }

        if (episodeVideo) {
          await dispatch(
            addEpisodeVideo({
              episodeData: {
                videoFile: episodeVideo,
                image: episodeImage,
                ...res.data.data,
              },
        
              token,
            })
          );
          
        }
      }
      dispatch({
        type: COURSE_LOADER_FALSE,
      });
    } catch (error) {
      dispatch({
        type: COURSE_LOADER_FALSE,
      });
      dispatch({
        type: SHOW_MESSAGE,
        payload: {
          title: `Couldn't not add episode`,
          message:
            error.response &&
            error.response.data &&
            error.response.data.errors &&
            error.response.data.errors.length
              ? error.response.data.errors[0]
              : error.response &&
                error.response.data &&
                error.response.data.message
              ? error.response.data.message
              : `Something went wrong while adding`,
          severity: "error",
        },
      });
    }
  };

export const updateEpisode =
  ({ episodeData, episodeImage, episodeVideo, token },  onUploadProgress) =>
  async (dispatch) => {
    // set header
    dispatch({
      type: COURSE_LOADER_TRUE,
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      
    };
    // set body

    // console.log('body', body)
    try {
      if (episodeVideo) {
        let formData = new FormData();
        formData.append("file", episodeVideo);

        const ipfsResponse = await axios.post(IPFS_API_URL, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          // onUploadProgress: event => {
          //   onUploadProgress(Math.round((100 * event.loaded) / event.total));
  
          // },
          onUploadProgress: function(progressEvent) {
            var percentCompleted = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
            onUploadProgress(percentCompleted);
            console.log(percentCompleted)
          }
        });

        if (ipfsResponse && ipfsResponse.data && ipfsResponse.data.Hash) {
          episodeData["ipfsCid"] = ipfsResponse.data.Hash;
        }
      }

      const body = episodeVideo
        ? JSON.stringify({
            ...episodeData,
            upload: {
              approach: "tus",
              size: episodeVideo.size,
            },
          })
        : JSON.stringify({ ...episodeData });
      // login user
      const res = await API.patch(`api/v1/course/updateEpisode`, body, config);
      if (res.data.data) {
        dispatch({
          type: SET_EPISODES,
          payload: res.data.data,
        });
        if (episodeImage) {
          await dispatch(
            addEpisodeImage({
              episodeData: {
                ...res.data.data,
                videoFile: episodeVideo,
                image: episodeImage,
              },
              token,
            })
          );
        }
        if (episodeVideo) {
          await dispatch(
            addEpisodeVideo({
              episodeData: {
                ...res.data.data,
                videoFile: episodeVideo,
                image: episodeImage,
              },
              token,
            })
          );
        }
      }
      dispatch({
        type: COURSE_LOADER_FALSE,
      });
    } catch (error) {
      dispatch({
        type: COURSE_LOADER_FALSE,
      });
      dispatch({
        type: SHOW_MESSAGE,
        payload: {
          title: `Could not Update Episode`,
          message:
            error.response &&
            error.response.data &&
            error.response.data.errors &&
            error.response.data.errors.length
              ? error.response.data.errors[0]
              : error.response &&
                error.response.data &&
                error.response.data.message
              ? error.response.data.message
              : `Something went wrong while updating`,
          severity: "error",
        },
      });
    }
  };

export const addUpdatePlan =
  ({ plan, price, courseData, episodesList, token }) =>
  async (dispatch) => {
    // set header
    dispatch({
      type: COURSE_LOADER_TRUE,
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    // set body

    const body = JSON.stringify({
      courseId: courseData.id,
      planId: plan.id,
      coursePrice: price,
    });
    // console.log('body', body)
    try {
      // login user
      const res = await API.put(
        `api/v1/course/addOrUpdateCoursePlan`,
        body,
        config
      );
      if (res.data.data) {
        await episodesList.map(async (item) => {
          await dispatch(
            updateEpisode({
              episodeData: {
                ...item,
                episodeId: item.id,
              },
              token,
            })
          );
        });
        dispatch({
          type: SET_COURSE,
          payload: {
            ...res.data.data,
            plan,
            tags: res.data.data.tags.split(","),
          },
        });
        dispatch({
          type: ADD_SUCCESSFUL,
          payload: true,
        });
      }
      dispatch({
        type: COURSE_LOADER_FALSE,
      });
    } catch (error) {
      dispatch({
        type: COURSE_LOADER_FALSE,
      });
      dispatch({
        type: SHOW_MESSAGE,
        payload: {
          title: `Could not update plan`,
          message:
            error.response &&
            error.response.data &&
            error.response.data.errors &&
            error.response.data.errors.length
              ? error.response.data.errors[0]
              : error.response &&
                error.response.data &&
                error.response.data.message
              ? error.response.data.message
              : `Something went wrong while updating plan`,
          severity: "error",
        },
      });
    }
  };

export const deleteEpisode =
  ({ episodeData, token }) =>
  async (dispatch) => {
    // set header
    dispatch({
      type: COURSE_LOADER_TRUE,
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    // set body
    console.log(episodeData);
    let formData = new FormData();
    formData.append("thumbnail", episodeData.image);
    // formData.append("episode", episodeData.videoFile);
    const body = episodeData;
    // console.log('body', body)
    try {
      // login user
      const res = await API.delete(`/api/v1/course/deleteEpisode`, {
        ...config,
        data: JSON.stringify(body),
      });
      dispatch({
        type: SHOW_MESSAGE,
        payload: {
          title: `Episode Deleted`,
          message:
            res && res.data && res.data.message
              ? res.data.message
              : ` Something went wrong try after some time`,
          severity: "warning",
        },
      });
      dispatch({
        type: REMOVE_EPISODES,
        payload: episodeData,
      });
      dispatch({
        type: COURSE_LOADER_FALSE,
      });
      // localStorage.setItem("token",res.data.token);
    } catch (error) {
      dispatch({
        type: SHOW_MESSAGE,
        payload: {
          title: `Could not delete episode`,
          message:
            error.response &&
            error.response.data &&
            error.response.data.errors &&
            error.response.data.errors.length
              ? ` One or more values is missing from json validation`
              : error.response &&
                error.response.data &&
                error.response.data.message
              ? error.response.data.message
              : ` Something went wrong try after some time`,
          severity: "error",
        },
      });
      dispatch({
        type: COURSE_LOADER_FALSE,
      });
    }
  };

export const invitePeople =
  ({ data, token }) =>
  async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    const body = JSON.stringify({ ...data });
    try {
      // login user
      const res = await API.post(`/api/v1/course/inviteUsers`, body, config);
      dispatch({
        type: SHOW_MESSAGE,
        payload: {
          title: `Invite Sent`,
          message:
            res && res.data && res.data.message
              ? res.data.message
              : `Invite send successfully`,
          severity: "success",
        },
      });
      return {
        loader: false,
        success: true,
      };
    } catch (error) {
      dispatch({
        type: SHOW_MESSAGE,
        payload: {
          title: `Could not sent invite`,
          message:
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : `Something went wrong`,
          severity: "error",
        },
      });
      return {
        loader: false,
        success: true,
      };
    }
  };

export const addEpisodeImage =
  ({ episodeData, token }) =>
  async (dispatch) => {
    // set header
    dispatch({
      type: COURSE_LOADER_TRUE,
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      onUploadProgress: (event) => {
        console.log(Math.round((100 * event.loaded) / event.total));
        dispatch({
          type: "EPISODE_THUMBNAIL_PROGRESS",
          payload: Math.round((100 * event.loaded) / event.total),
        });
      },
    };
    // set body
    console.log(episodeData);
    let formData = new FormData();
    formData.append("thumbnail", episodeData.image);
    // formData.append("episode", episodeData.videoFile);
    const body = formData;
    // console.log('body', body)
    try {
      // login user
      const res = await API.post(
        `/api/v1/course/uploadThumbnail?episodeId=${episodeData.id}&courseId=${episodeData.courseId}`,
        body,
        config
      );
      dispatch({
        type: SET_EPISODES,
        payload: res.data.data,
      });
      // localStorage.setItem("token",res.data.token);
    } catch (error) {}
  };
export const addEpisodeVideo =
  ({ episodeData, token }) =>
  async (dispatch) => {
    // set header
    dispatch({
      type: COURSE_LOADER_TRUE,
    });

    // set body
    console.log(episodeData);
    // let formData = new FormData();

    // formData.append("episode", episodeData.videoFile);
    // const body = formData;
    // console.log('body', body)
    try {
      dispatch({
        type: "EPISODE_VIDEO_LOADER",
        payload: episodeData.id,
      });
      // const videoFileUpdateResponse = await API.patch(vidoeUrlResponse.data.data, reader.readAsBinaryString(episodeData.videoFile), configVideoUpload )
      // console.log(videoFileUpdateResponse)

      var myHeaders = new Headers();
      myHeaders.append("Tus-Resumable", "1.0.0");
      myHeaders.append("Upload-Offset", 0);
      myHeaders.append("Content-Type", "application/offset+octet-stream");
      myHeaders.append("Accept", "application/vnd.vimeo.*+json;version=3.4");

      console.log("tus supported", tus.isSupported);
      var upload = new tus.Upload(episodeData.videoFile, {
        uploadUrl: episodeData.uploadLink,
        endpoint: episodeData.uploadLink,
        overridePatchMethod: true,
        retryDelays: [0, 3000, 5000, 10000, 20000],
        chunkSize: 12800000,
        metadata: {
          filename: episodeData.name + " " + episodeData.id,
          filetype: episodeData.videoFile.type,
        },
        onError: function (error) {
          console.log("Failed because: " + error);
        },
        onProgress: function (bytesUploaded, bytesTotal) {
          var percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2);
          console.log(bytesUploaded, bytesTotal, percentage + "%");
          dispatch({
            type: "EPISODE_VIDEO_PROGRESS",
            payload: { episode_id: episodeData.id, percentage },
          });
        },
        onSuccess: function () {
          dispatch({
            type: "EPISODE_VIDEO_LOADER",
            payload: episodeData.id,
          });
          const intervalStatus = setInterval(async () => {
            const episodeStatusData = await dispatch(
              changeEpisodeStatus({
                episodeId: episodeData.id,
                status: "Active",
                token: token,
                showMessage: false,
              })
            );
            if (episodeStatusData?.status === "Active") {
              clearInterval(intervalStatus);
            }
          }, 5000);
          console.log("Download %s from %s", upload.file.name, upload.url);
        },
      });
      console.log(upload);

      // Start the upload
      await upload.start();

      // await fetch(vidoeUrlResponse.data.data, requestOptions)
      //   .then(response => response.text())
      //   .then(result => console.log(result))
      //   .catch(error => console.log('error', error));
      // login user
      // const res = await API.post(
      //   `/api/v1/course/uploadEpisode?episodeId=${episodeData.id}&courseId=${episodeData.courseId}`,
      //   body,
      //   config
      // );
      // dispatch({ type: "EPISODE_VIDEO_PROGRESS", payload: 0 });
      // dispatch({
      //   type: SET_EPISODES,
      //   payload: res.data.data
      // });
      // localStorage.setItem("token",res.data.token);
    } catch (error) {}
  };

// get course Details by id

export const getCourseDetailById =
  ({ courseId, token }) =>
  async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      params: {
        courseId,
      },
    };

    try {
      const res = await API.get(`/api/v1/course/getCourseDetails`, config);
      const reviewRes = await dispatch(getCourseReviews({ token, courseId }));
      dispatch({
        type: COURSE_DETAIL,
        payload: {
          ...res.data.data,
          reviews_ratings: reviewRes?.reviews_ratings,
          averageRating: reviewRes?.averageRating,
          totalRatingsCount: reviewRes?.totalRatingsCount,
          totalReviewsCount: reviewRes?.totalReviewsCount,
        },
      });

      return { status: "success" };
    } catch (error) {
      console.log(error);

      dispatch({
        type: SHOW_MESSAGE,
        payload: {
          title: `Could not get course`,
          message:
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : ` Something went wrong try after some time`,
          severity: "error",
        },
      });
      return {
        status: "fail",
        message: error?.response?.data?.message
          ? error?.response?.data?.message
          : "something went wrong",
      };
    }
  };

// get course reviews by id

export const getCourseReviews =
  ({ courseId, token }) =>
  async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      params: {
        courseId,
      },
    };

    try {
      const res = await API.get(
        `/api/v1/course/get-course-rating-reviews`,
        config
      );

      return res.data.data;
    } catch (error) {
      console.log(error);
    }
  };

export const getCourseDetailByIdLoader =
  ({ courseId, token }) =>
  async (dispatch) => {
    dispatch({
      type: COURSE_LOADER,
      payload: true,
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      params: {
        courseId,
      },
    };

    try {
      const res = await API.get(`/api/v1/course/getCourseDetails`, config);
      const reviewRes = await dispatch(getCourseReviews({ token, courseId }));
      dispatch({
        type: COURSE_DETAIL,
        payload: {
          ...res.data.data,
          reviews_ratings: reviewRes?.reviews_ratings,
          averageRating: reviewRes?.averageRating,
          totalRatingsCount: reviewRes?.totalRatingsCount,
          totalReviewsCount: reviewRes?.totalReviewsCount,
        },
      });

      dispatch({
        type: COURSE_LOADER,
        payload: false,
      });
      return { status: "success" };
    } catch (error) {
      console.log(error);
      dispatch({
        type: COURSE_LOADER,
        payload: false,
      });
      dispatch({
        type: SHOW_MESSAGE,
        payload: {
          title: `Could not get course`,
          message:
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : ` Something went wrong try after some time`,
          severity: "error",
        },
      });
      return {
        status: "fail",
        message: error?.response?.data?.message
          ? error?.response?.data?.message
          : "something went wrong",
      };
    }
  };

// get course stats detail

export const getCourseStats =
  ({ courseId, token }) =>
  async (dispatch) => {
    dispatch({
      type: LOADER,
      payload: true,
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      params: {
        courseId,
      },
    };

    try {
      const res = await API.get(`/api/v1/course/getCourseStats`, config);
      dispatch({
        type: COURSE_STATS_DETAIL,
        payload: res.data.data,
      });
      dispatch({
        type: LOADER,
        payload: false,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: SHOW_MESSAGE,
        payload: {
          status: `Fail`,
          message:
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : ` Something went wrong try after some time`,
          severity: "error",
        },
      });
      dispatch({
        type: LOADER,
        payload: false,
      });
    }
  };

// get course details for edit

export const getCourseDetails =
  ({ courseId, token }) =>
  async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      params: {
        courseId,
      },
    };

    try {
      const res = await API.get(`/api/v1/course/getCourseDetails`, config);
      dispatch({
        type: SET_COURSE,
        payload: { ...res.data.data },
      });
      // dispatch({
      //   type: SET_EPISODES,
      //   payload: res.data.data?.episodes
      // });
      return "success";
    } catch (error) {
      console.log(error);
      return "fail";
    }
  };

// get course Reviews

// export const getCourseReviews = ({ courseId, token }) => async dispatch => {
//   console.log("courseId,token", courseId, token);
//   const config = {
//     headers: {
//       "Content-Type": "application/json",
//       Authorization: `Bearer ${token}`
//     }
//   };
//   const body = JSON.stringify({ courseId });

//   try {
//     const res = await API.get(`/api/v1/course/getCourseRatings`, body, config);
//     dispatch({
//       type: COURSE_REVIEWS,
//       payload: res.data.data
//     });
//   } catch (error) {
//     console.log(error);
//   }
// };

// create course review

export const createCourseReviewRating =
  ({ token, courseId, review, rating }) =>
  async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    const body = JSON.stringify({ courseId, review, rating });

    try {
      const res = await API.post(
        `/api/v1/course/add-course-review-rating`,
        body,
        config
      );
      // dispatch({
      //   type: CREATE_COURSE_REVIEW,
      //   payload: res.data.data
      // });
      // console.log("res.data.review", res.data.data);

      // dispatch({
      //   type: ADD_COURSE_REVIEW,
      //   payload: res.data.data
      // });

      dispatch({
        type: SHOW_MESSAGE,
        payload: {
          title: `success`,
          message: res.data.message,
          severity: "success",
        },
      });
      dispatch(getCourseDetailById({ courseId, token }));
    } catch (error) {
      console.log(error.response.message);
      dispatch({
        type: SHOW_MESSAGE,
        payload: {
          title: `Failed`,
          message:
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : `failed`,
          severity: "error",
        },
      });
    }
  };

// add course rating

export const addCourseRating =
  ({ courseId, rating, token }) =>
  async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    const body = JSON.stringify({ courseId, rating });

    try {
      const res = await API.post(
        `/api/v1/course/addCourseRating`,
        body,
        config
      );
      dispatch({
        type: ADD_COURSE_RATING,
        payload: res.data.data,
      });
      // console.log("res.data.rating", res.data.data);

      dispatch({
        type: SHOW_MESSAGE,
        payload: {
          title: `success`,
          message: res.data.message,
          severity: "success",
        },
      });
    } catch (error) {
      dispatch({
        type: SHOW_MESSAGE,
        payload: {
          title: `Failed`,
          message:
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : `failed`,
          severity: "error",
        },
      });
      // dispatch({
      //   type: SHOW_MESSAGE,
      //   payload: {
      //     title: `Failed`,
      //     message: error?.message,
      //     severity: "error"
      //   }
      // });
      // console.log(error);
    }
  };

// add course favorite

export const addFavoriteCourse =
  ({ courseId, token }) =>
  async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      params: {
        courseId,
      },
    };

    try {
      const res = await API.post(
        `/api/v1/course/addFavouriteCourse`,
        { courseId: courseId },
        config
      );
      dispatch({
        type: FAVORITE_COURSE,
        payload: true,
      });

      dispatch({
        type: SHOW_MESSAGE,
        payload: {
          title: `success`,
          message: res.data.message,
          severity: "success",
        },
      });

      return courseId;
    } catch (error) {
      dispatch({
        type: SHOW_MESSAGE,
        payload: {
          title: `Failed`,
          message:
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : `failed`,
          severity: "error",
        },
      });
      console.log(error);
    }
  };

// delete course favorite

export const deleteFavoriteCourse =
  ({ courseId, token }) =>
  async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      params: {
        courseId,
      },
    };

    try {
      const res = await API.delete(
        `/api/v1/course/deleteFavouriteCourse`,
        config
      );
      dispatch({
        type: FAVORITE_COURSE,
        payload: false,
      });

      dispatch({
        type: SHOW_MESSAGE,
        payload: {
          title: `success`,
          message: res.data.message,
          severity: "warning",
        },
      });

      return courseId;
    } catch (error) {
      dispatch({
        type: SHOW_MESSAGE,
        payload: {
          title: `Failed`,
          message: "Remove favorite course failed",
          severity: "error",
        },
      });
      console.log(error);
    }
  };

// get new courses

export const getNewCourses =
  ({ token, page, limit, categoryId, categoryName, setLoader }) =>
  async (dispatch) => {
    setLoader && setLoader(true);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      params: {
        page: page || undefined,
        limit: limit || undefined,
        categoryId: categoryId || "",
        //  categoryName: categoryName,
        sortOrder: "DESC",
        sortBy: "createdAt",
      },
    };

    try {
      const res = await API.get(`/api/v1/course/getNewCourses`, config);

      dispatch({
        type: GET_NEW_COURSES,
        payload: res.data.data,
      });
      console.log(res.data.data, "response");
    } catch (error) {
      console.log(error);
    }
    setLoader && setLoader(false);
  };

// get recent courses

export const getRecentCourses =
  ({
    token,
    // limit,
    setLoader,
  }) =>
  async (dispatch) => {
    setLoader && setLoader(true);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      params: {
        page: 1,
        // limit:4,
        sortOrder: "DESC",
        sortBy: "createdAt",
      },
    };
    try {
      const res = await API.get(`/api/v1/course/getRecentCourses`, config);
      dispatch({
        type: GET_RECENT_COURSES,
        payload: res.data.data,
      });
    } catch (error) {
      console.log(error);
    }
    setLoader && setLoader(false);
  };

// get user's uploaded courses

export const getUserUploadedCourses = (token) => async (dispatch) => {
  dispatch({
    type: LOADER,
    payload: true,
  });
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const res = await API.get(`/api/v1/course/getUserUploadedCourses`, config);
    dispatch({
      type: GET_USER_UPLOADED_COURSES,
      payload: res.data.data,
    });
    dispatch({
      type: LOADER,
      payload: false,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: LOADER,
      payload: false,
    });
  }
};

// get user's enrolled courses

export const getUserEnrolledCourses = (token) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const res = await API.get(`/api/v1/course/getUserEnrolledCourses`, config);
    dispatch({
      type: GET_USER_ENROLLED_COURSES,
      payload: res.data.data,
    });
  } catch (error) {
    console.log(error);
  }
};

// Get quote of the day

export const getQuoteOfTheDay = () => async (dispatch) => {
  // set header
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const res = await API.get(`/api/v1/quote/getQuoteOfTheDay`, config);
    // localStorage.setItem("token",res.data.token);
    console.log(res);
    dispatch({
      type: GET_QUOTE,
      payload: res.data.data,
    });
    return res.data.data;
  } catch (error) {
    console.log(error);
  }
};

// Get all courses group by category

export const getCoursesGroupByCategory =
  ({ token }) =>
  async (dispatch) => {
    // set header
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const res = await API.get(
        `/api/v1/course/coursesGroupByCategory`,
        config
      );
      // localStorage.setItem("token",res.data.token);
      // console.log(res);
      dispatch({
        type: GET_COURSES_GROUP_BY_CATEGORY,
        payload: res.data.data,
      });
      return res.data.data;
    } catch (error) {
      console.log(error);
    }
  };

// Get all courses  by category

export const getCoursesbyCategory =
  ({ token, id }) =>
  async (dispatch) => {
    // set header
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const res = await API.get(
        `/api/v1/course/getCategoryCourses?page=1&limit=40&sortOrder=DESC&sortBy=createdAt&categoryId=${id}`,
        config
      );

      dispatch({
        type: GET_COURSES_BY_CATEGORY,
        payload: res.data.data,
      });
    } catch (error) {
      console.log(error);
    }
  };

// Search new courses

export const searchNewCourses =
  ({ searchTerm, categoryId, token, setLoader }) =>
  async (dispatch) => {
    // set header
    setLoader && setLoader(true);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      params: {
        searchTerm,
        categoryId,
        sortOrder: "DESC",
        sortBy: "createdAt",
      },
    };
    try {
      const res = await API.get(`/api/v1/search/searchCourse`, config);
      // localStorage.setItem("token",res.data.token);
      // console.log(res);
      dispatch({
        type: SEARCH_NEW_COURSES,
        payload: res.data.data,
      });
    } catch (error) {
      console.log(error);
    }
    setLoader && setLoader(false);
  };

// Search enrolled courses

export const searchEnrolledCourses =
  ({ searchTerm, categoryId, token, setLoader }) =>
  async (dispatch) => {
    // set header
    setLoader && setLoader(true);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      params: {
        searchTerm,
        categoryId,
        sortOrder: "DESC",
        sortBy: "createdAt",
      },
    };
    try {
      const res = await API.get(`/api/v1/search/searchEnrolledCourses`, config);
      // localStorage.setItem("token",res.data.token);
      // console.log(res);
      dispatch({
        type: GET_USER_ENROLLED_COURSES,
        payload: res.data.data,
      });
    } catch (error) {
      console.log(error);
    }
    setLoader && setLoader(false);
  };

// Search new courses

export const searchUploadedCourses =
  ({ searchTerm, categoryId, token, setLoader }) =>
  async (dispatch) => {
    // set header
    setLoader && setLoader(true);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      params: {
        searchTerm,
        categoryId,
        sortOrder: "DESC",
        sortBy: "createdAt",
      },
    };
    try {
      const res = await API.get(`/api/v1/search/searchUploadedCourses`, config);
      // localStorage.setItem("token",res.data.token);
      // console.log(res);
      dispatch({
        type: GET_USER_UPLOADED_COURSES,
        payload: res.data.data,
      });
    } catch (error) {
      console.log(error);
    }
    setLoader && setLoader(false);
  };

// Search courses group by category

export const searchCoursesGroupByCategory =
  ({ searchTerm, token, setLoader }) =>
  async (dispatch) => {
    // set header
    setLoader && setLoader(true);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      params: {
        searchTerm,
        sortOrder: "DESC",
        sortBy: "createdAt",
      },
    };
    try {
      const res = await API.get(
        `/api/v1/search/searchCoursesGroupByCategory`,
        config
      );
      // localStorage.setItem("token",res.data.token);
      // console.log(res);
      await dispatch({
        type: GET_COURSES_GROUP_BY_CATEGORY,
        payload: res.data.data,
      });
      setLoader && setLoader(false);
    } catch (error) {
      console.log(error);
      setLoader && setLoader(false);
    }
  };

// Search recent courses

export const searchRecentCourses =
  ({ searchTerm, categoryId, token, setLoader }) =>
  async (dispatch) => {
    // set header
    setLoader && setLoader(true);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      params: {
        searchTerm,
        categoryId,
        sortOrder: "DESC",
        sortBy: "createdAt",
      },
    };
    try {
      const res = await API.get(`/api/v1/search/searchCourse`, config);
      // localStorage.setItem("token",res.data.token);
      // console.log(res);
      dispatch({
        type: GET_RECENT_COURSES,
        payload: res.data.data,
      });
    } catch (error) {
      console.log(error);
    }
    setLoader && setLoader(false);
  };

// Search

// Search

export const search =
  ({ searchTerm, type, categoryId, category, token }) =>
  async (dispatch) => {
    // set header
    dispatch({
      type: LOADER,
      payload: true,
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      params: {
        searchTerm,
        categoryId,
        category,
        type,
      },
    };
    try {
      const res = await API.get(`/api/v1/search`, config);
      dispatch({
        type: SEARCH,
        payload: res.data.data,
      });
      if (category === "my" && type === "Challenge") {
        let challenges = res?.data?.data?.map((item) => {
          return { challenge: item };
        });
        dispatch({
          type: GET_ALL_CHALLENGES,
          payload: challenges,
        });
      }
      if (category === "my" && type === "Session") {
        dispatch({
          type: GET_ALL_CREATED_JOINED_SESSIONS,
          payload: res.data.data,
        });
      }
      dispatch({
        type: LOADER,
        payload: false,
      });
    } catch (error) {
      dispatch({
        type: LOADER,
        payload: false,
      });

      console.log(error);
    }
  };

// get episode Details by id

export const getEpisodeDetailById =
  ({ episodeId, token }) =>
  async (dispatch) => {
    dispatch({
      type: LOADER,
      payload: true,
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      params: {
        episodeId,
      },
    };

    try {
      const res = await API.get(`/api/v1/course/getEpisodeDetails`, config);

      dispatch({
        type: EPISODE_DETAIL,
        payload: res.data.data,
      });
      dispatch({
        type: LOADER,
        payload: false,
      });
      return { status: "success" };
    } catch (error) {
      console.log(error);
      dispatch({
        type: LOADER,
        payload: false,
      });
      dispatch({
        type: SHOW_MESSAGE,
        payload: {
          title: `Failed`,
          message:
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : `failed`,
          severity: "error",
        },
      });
      return {
        status: "fail",
        message: error?.response?.data?.message
          ? error?.response?.data?.message
          : "something went wrong",
      };
    }
  };

// create episode review

export const createEpisodeReview =
  ({ episodeId, review, token }) =>
  async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    const body = JSON.stringify({ episodeId, review });

    try {
      const res = await API.post(
        `/api/v1/course/addEpisodeReview`,
        body,
        config
      );
      // dispatch({
      //   type: CREATE_COURSE_REVIEW,
      //   payload: res.data.data
      // });
      // console.log("res.data.review", res.data.data);

      dispatch({
        type: ADD_COURSE_REVIEW,
        payload: res.data.data,
      });
      dispatch({
        type: SHOW_MESSAGE,
        payload: {
          title: `success`,
          message: res.data.message,
          severity: "success",
        },
      });
    } catch (error) {
      console.log(error.response.message);
      dispatch({
        type: SHOW_MESSAGE,
        payload: {
          title: `Failed`,
          message:
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : `failed`,
          severity: "error",
        },
      });
    }
  };

// add episode rating

export const addEpisodeRating =
  ({ episodeId, rating, token }) =>
  async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    const body = JSON.stringify({ episodeId, rating });

    try {
      const res = await API.post(
        `/api/v1/course/addEpisodeRating`,
        body,
        config
      );
      dispatch({
        type: ADD_COURSE_RATING,
        payload: res.data.data,
      });
      // console.log("res.data.rating", res.data.data);

      dispatch({
        type: SHOW_MESSAGE,
        payload: {
          title: `success`,
          message: res.data.message,
          severity: "success",
        },
      });
    } catch (error) {
      dispatch({
        type: SHOW_MESSAGE,
        payload: {
          title: `Failed`,
          message:
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : `failed`,
          severity: "error",
        },
      });
      console.log(error);
    }
  };

// episode completed

// export const episodeCompleted = ({ episodeId, token }) => async dispatch => {
//   const config = {
//     headers: {
//       "Content-Type": "application/json",
//       Authorization: `Bearer ${token}`
//     }
//   };
//   const body = JSON.stringify({ episodeId });

//   try {
//     const res = await API.post(`/api/v1/course/episodeCompleted`, body, config);
//   } catch (error) {
//     console.log(error);
//   }
// };

// episode started

export const episodeStartOrComplete =
  ({ status, episodeId, token }) =>
  async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    const body = JSON.stringify({ status, episodeId });
    try {
      await API.post(`/api/v1/course/episodeStartOrComplete`, body, config);
    } catch (error) {
      console.log(error);
    }
  };

// join Free course

export const joinFreeCourse =
  ({ courseId, token }) =>
  async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    const body = JSON.stringify({ courseId });
    try {
      const res = await API.post(`/api/v1/course/joinFreeCourse`, body, config);
      dispatch({
        type: JOIN_FREE_COURSE,
        payload: res.data.data,
      });

      dispatch(
        getCourseDetailById({
          courseId: res?.data?.data?.courseId,
          token,
        })
      );
      // console.log("res.data.rating", res.data.data);

      dispatch({
        type: SHOW_MESSAGE,
        payload: {
          title: `success`,
          message: res.data.message,
          severity: "success",
        },
      });
      return { success: true };
    } catch (error) {
      /* dispatch({
      type: SHOW_MESSAGE,
      payload: {
        title: `Failed`,
        message:
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : `failed`,
        severity: "error"
      }
    }); */
      console.log(error);
      return {
        success: false,
        message:
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : `failed`,
        severity: "error",
      };
    }
  };

// join subscription course

export const joinUnderSubscriptionCourse =
  ({ courseId, token }) =>
  async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    const body = JSON.stringify({ courseId });
    try {
      const res = await API.post(
        `/api/v1/course/joinCourseUnderSubscription`,
        body,
        config
      );

      dispatch(
        getCourseDetailById({
          courseId,
          token,
        })
      );
      // console.log("res.data.rating", res.data.data);

      dispatch({
        type: SHOW_MESSAGE,
        payload: {
          title: `success`,
          message: res.data.message,
          severity: "success",
        },
      });
      return { success: true };
    } catch (error) {
      /*  dispatch({
      type: SHOW_MESSAGE,
      payload: {
        title: `Failed`,
        message:
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : `failed`,
        severity: "error"
      }
    }); */
      console.log(error);
      return {
        success: false,
        message:
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : `failed`,
      };
    }
  };

// get user's uploaded courses by userId

export const getUserUploadedCoursesByUserId =
  ({ token, userId }) =>
  async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const res = await API.get(
        `/api/v1/user/getUserCreatedCourses/${userId}`,
        config
      );
      return res?.data?.data;
    } catch (error) {
      console.log(error);
    }
  };

// get user's enrolled courses by userId

export const getUserEnrolledCoursesByUserId =
  ({ token, userId }) =>
  async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const res = await API.get(
        `/api/v1/user/getUserEnrolledCourses/${userId}`,
        config
      );
      return res?.data?.data;
    } catch (error) {
      console.log(error);
    }
  };

// get user's enrolled session and challenges by id

export const getUserEnrolledSessionsChallengesByUserId =
  ({ token, userId }) =>
  async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const res = await API.get(
        `/api/v1/user/getUserEnrolledChallengesAndSessions/${userId}`,
        config
      );
      return res?.data?.data;
    } catch (error) {
      console.log(error);
    }
  };
