import React from "react";
import { useIntl } from "react-intl";


const NotFound = () => {
  const intl = useIntl();
  return (
    <div>{intl.formatMessage({ id: "not_found" })}</div>
  );
}


export default NotFound;