import React, { useEffect, useState } from "react";
import MaterialTable from "material-table";
import {
  Card,
  CardContent,
  CardHeader,
  Avatar,
  Typography,
  Grid,
  CircularProgress
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { getCourseStats } from "../../../actions/courses";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import moment from 'moment'
function CourseStats() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const params = useParams();
  const [userData, setUserData] = useState([]);

  const auth = useSelector(state => state?.auth);
  const courseStats = useSelector(state => state?.courses?.courseStats);
  const courseDetail = useSelector(state => state?.courses?.courseDetail);
  let configurations = useSelector(state => state?.setting?.siteConfig);
  const loader = useSelector(state => state?.loader?.loader);

  useEffect(() => {
    dispatch(
      getCourseStats({ token: auth?.token, courseId: courseDetail?.id })
    );
    return () => {
      dispatch({ type: "COURSE_STATS_DETAIL", payload: {} });
    };
  }, []);

  useEffect(() => {
    if (courseStats?.id) {
      let users = courseStats?.users?.map((user, index) => {
        return {
          serialNumber: index + 1,
          pictureUrl: user?.pictureUrl,
          name: user?.name,
          joinDate: moment(user?.EnrolledCourse?.joinDate).format('DD-MM-YYYY'),
          isPaid: user?.EnrolledCourse?.isPaid,
          payment: user?.payments[0]?.amount
            ? `${configurations?.currency}${user?.payments[0]?.amount}`
            : `${configurations?.currency}0`,
          status: user?.courseTrackStatus.toLowerCase() === 'active' ? 'Started' : user?.courseTrackStatus.toLowerCase() === 'inactive' ? 'Not Started' : user?.courseTrackStatus.toLowerCase() === 'completed' ? 'Completed' : ''
        };
      });
      setUserData(users);
    }
  }, [courseStats]);

  return (
    <>
      {loader ? (
        <Grid container justify="center">
          <Grid
            item
            xs={12}
            style={{ textAlign: "center", marginTop: "100px" }}
          >
            <CircularProgress size={25} color="primary" />
          </Grid>{" "}
        </Grid>
      ) : (
        <>
          <Card className={classes.root}>
            <CardHeader
              avatar={
                <Avatar aria-label="user's pic" src={courseStats?.image}>
                  {courseStats?.name &&
                    courseStats?.name.charAt(0).toUpperCase()}
                </Avatar>
              }
              title={
                <Typography
                  className={`${classes.courseStatsTextFamily} ${classes.courseTitle}`}
                >
                  {courseStats?.name}
                </Typography>
              }
              subheader={
                <Typography
                  className={classes.courseStatsTextFamily}
                  style={{
                    fontSize: "14px",
                    lineHeight: "19px",
                    color: "#B0B0B0"
                  }}
                >
                  {courseStats?.plan?.name}{" "}
                  {courseStats?.plan?.name === "Free"
                    ? ""
                    : `${configurations?.currency} ${courseStats?.price}`}
                </Typography>
              }
            />

            <CardContent>
              <Grid container>
                <Grid item xs={12} container>
                  <Grid item xs={9} sm={9} md={6} lg={5} xl={3}>
                    <Typography className={classes.courseStatsTextFamily}>
                      Total Revenue({configurations?.currency}) :
                    </Typography>
                  </Grid>
                  <Grid item xs={3} sm={3} md={5} lg={5} xl={4}>
                    <Typography className={classes.curseStatsSubTitleText}>
                      {courseStats?.totalRevenue || 0}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid item xs={12} container>
                  <Grid item xs={9} sm={9} md={6} lg={5} xl={3}>
                    <Typography className={classes.courseStatsTextFamily}>
                      Total Net Revenue ( after Platform Fee ) {configurations?.currency} :
                    </Typography>
                  </Grid>
                  <Grid item xs={3} sm={3} md={3} lg={3} xl={2}>
                    <Typography className={classes.curseStatsSubTitleText}>
                      {courseStats?.netRevenueAfterDeduction || 0}

                    </Typography>
                  </Grid>
                </Grid>

                <Grid item xs={12} container justify="flex-end">
                  <Grid item xs={12} container>
                    <Grid item xs={9} sm={9} md={6} lg={5} xl={3}>
                      <Typography className={classes.courseStatsTextFamily}>
                        Total Users :
                      </Typography>
                    </Grid>
                    <Grid item xs={3} sm={3} md={3} lg={3} xl={2}>
                      <Typography className={classes.curseStatsSubTitleText}>
                        {courseStats?.totalUsersJoined || 0}
                      </Typography>
                    </Grid>
                  </Grid>
                  {courseStats?.plan?.name === "Freemium" && (
                    <>
                      <Grid item xs={12} container>
                        <Grid item xs={9} sm={9} md={6} lg={5} xl={3}>
                          <Typography
                            className={classes.courseStatsTextFamily}
                            style={{ marginLeft: "10px" }}
                          >
                            - Paid :
                          </Typography>
                        </Grid>
                        <Grid item xs={3} sm={3} md={3} lg={3} xl={2}>
                          <Typography
                            className={classes.curseStatsSubTitleText}
                          >
                            {courseStats?.totalUsersPaidForCourse}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} container>
                        <Grid item xs={9} sm={9} md={6} lg={5} xl={3}>
                          <Typography
                            className={classes.courseStatsTextFamily}
                            style={{ marginLeft: "10px" }}
                          >
                            - Free :
                          </Typography>
                        </Grid>
                        <Grid item xs={3} sm={3} md={3} lg={3} xl={2}>
                          <Typography
                            className={classes.curseStatsSubTitleText}
                          >
                            {courseStats?.totalUsersFreeJoinedCourse}
                          </Typography>
                        </Grid>
                      </Grid>
                    </>
                  )}
                </Grid>

                <Grid item xs={12} container>
                  <Grid item xs={9} sm={9} md={6} lg={5} xl={3}>
                    <Typography className={classes.courseStatsTextFamily}>
                      Favorites :
                    </Typography>
                  </Grid>
                  <Grid item xs={3} sm={3} md={3} lg={3} xl={2}>
                    <Typography className={classes.curseStatsSubTitleText}>
                      {courseStats?.totalFansCount || 0}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid item xs={12} container>
                  <Grid item xs={9} sm={9} md={6} lg={5} xl={3}>
                    <Typography className={classes.courseStatsTextFamily}>
                      Average Rating :
                    </Typography>
                  </Grid>
                  <Grid item xs={3} sm={3} md={3} lg={3} xl={2}>
                    <Typography className={classes.curseStatsSubTitleText}>
                      {courseStats?.averageRating || 0}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <MaterialTable
            columns={[
              {
                title: "Sr.",
                field: "serialNumber",
                width: 50
              },
              {
                title: "Image ",
                field: "pictureUrl",
                sorting: false,
                searchable: false,
                render: rowData => (
                  <Avatar
                    style={{ width: "50px", height: "50px" }}
                    src={rowData.pictureUrl}
                  />
                ),
                headerStyle: { paddingLeft: "25px" }
              },
              {
                title: "Name",
                field: "name",
              },
              {
                title: "Join Date",
                field: "joinDate",
              },

              {
                title: "Is Paid",
                field: "isPaid",
              },

              {
                title: "Payment",
                field: "payment",
              },
              {
                title: "Status",
                field: "status",
              }
            ]}
            data={userData}
            options={{
              rowStyle: {
                backgroundColor: "#EEE"
              },

            }}
            title="Course Stats"
          />
        </>
      )}
    </>
  );
}

export default CourseStats;

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    minHeight: "10.750em",
    borderRadius: "10px",
    boxShadow: "0px 16px 20px rgba(123, 0, 0, 0.05)",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    marginTop: "30px",
    marginBottom: "40px"

  },
  courseStatsTextFamily: {
    fontFamily: theme.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: 900
  },
  courseTitle: {
    fontSize: "20px",
    lineHeight: "27px",
    color: "#1B1B1B"
  },
  courseStatsTitle: {
    fontFamily: theme.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: 900,
    fontSize: "18px",
    lineHeight: "27px",
    color: "#1B1B1B",
    marginTop: "7px"
  },
  curseStatsSubTitleText: {
    fontFamily: theme.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: 900,
    fontSize: "16px",
    lineHeight: "27px",
    color: "#B0B0B0",
    letterSpacing: "0.015em"
  }
}));
