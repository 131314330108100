import React from 'react'
import { makeStyles } from '@material-ui/styles'
import ListChats from '../../../../components/More/Chat/ListChats'
import { Grid, Typography } from '@material-ui/core'
import { ArrowBack } from '@material-ui/icons'
import { useHistory } from 'react-router-dom'
function Inbox(props) {
    const classes = useStyles();
    const history = useHistory();

    return (
        <Grid container >

            <Grid item xs={12} container className={classes.header}>
                <Grid item xs={1} />
                <Grid item xs={2}>
                    <ArrowBack style={{ color: '#FFF', fontSize: '26px' }} onClick={() => history.push('/more')} />
                </Grid>
                <Grid item xs={6}>
                    <Typography className={classes.heading}>Inbox</Typography>
                </Grid>
                <Grid item xs={3} />
            </Grid>
            <Grid item xs={12} style={{ paddingTop: '115px' }}>
                <ListChats onClick={props?.onClick} newChat={props?.newChat} />
            </Grid>
        </Grid>
    )
}

export default Inbox

const useStyles = makeStyles(theme => ({
    header: {
        height: '112px',
        background: '#09A9BB',
        display: 'flex',
        alignItems: 'center',
        position: 'fixed',
        zIndex: 5
    },
    heading: {
        color: '#FFFFFF',
        fontFamily: theme.fontFamily.Reef,
        fontStyle: "normal",
        fontWeight: 900,
        fontSize: "26px",
        lineHeight: "35px",
        textAlign: 'center'
    }
}))
