import React, { useState } from 'react';
import { Popover, Typography, Grid, Card, CardContent, Button, IconButton, TextField, useMediaQuery, CircularProgress } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { useDispatch, useSelector } from 'react-redux'
import retreatAnnouncementIcon from '../../../assets/Icons/retreatAnnouncementIcon.svg'
import { makeAnnouncement } from '../../../actions/retreatCourse'

function Requests(props) {
    const classes = useStyles();
    const theme = useTheme()
    const dispatch = useDispatch()
    const [anchorEl, setAnchorEl] = useState(null);
    const [title, setTitle] = useState('');
    const [loader, setLoader] = useState(false);
    const [announcement, setAnnouncement] = useState('');
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const user = useSelector(state => state?.auth);
    const course = useSelector(state => state?.retreatCourse?.trainingCourseDetails);


    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setTitle('');
        setAnnouncement('');
    };

    const handleMakeAnnouncement = async () => {
        setLoader(true);
        const res = await dispatch(makeAnnouncement({
            token: user?.token,
            trainingCourseId: course?.id,
            title: title,
            description: announcement,
            type: "TrainingCourse",
        }))
        if (res) {
            setLoader(false);
            setTitle('');
            setAnnouncement('');
            setAnchorEl(null);
        }
    }

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <div>
            <Grid container onClick={handleClick}>
                {!isMobile && <Grid item >
                    <img src={retreatAnnouncementIcon} width='30' />
                </Grid>}
                <Grid item>
                    <Typography style={{ color: open ? '#01BDD2' : '#353B35', cursor: 'pointer' }}>Make Announcement</Typography>
                </Grid>
            </Grid>

            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Card className={classes.root}>
                    <CardContent>
                        <Grid container>
                            <Grid container spacing={2} item={12} className={classes.headerContainer}>
                                <Grid item xs={10}><Typography className={classes.title}>Make announcement</Typography></Grid>

                                <Grid item xs={2}>
                                    <IconButton className={classes.closeIcon} onClick={handleClose}><Close /></IconButton>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} >
                                <TextField
                                    id="outlined-multiline-static"
                                    fullWidth
                                    placeholder="title"
                                    variant="outlined"
                                    value={title}
                                    classes={{ root: classes.textArea }}
                                    onChange={(e) => setTitle(e.target.value)}
                                />
                            </Grid>

                            <Grid item xs={12} >
                                <TextField
                                    id="outlined-multiline-static"
                                    multiline
                                    fullWidth
                                    rows={4}
                                    placeholder="Announcement..."
                                    variant="outlined"
                                    value={announcement}
                                    classes={{ root: classes.textArea }}
                                    onChange={(e) => setAnnouncement(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} >
                                <Button className={classes.makeAnnouncementBtn} disabled={loader} onClick={handleMakeAnnouncement}>{loader ? <CircularProgress style={{ color: '#fff' }} size={20} /> : 'Make Announcement'}</Button>
                            </Grid>
                        </Grid>
                    </CardContent>

                </Card>
            </Popover>
        </div >
    );
}

export default Requests;


const useStyles = makeStyles(theme => ({
    container: {
        MinHeight: '100vh',
        background: '#FBFBFB',
        padding: '30px 15px 50px 15px'
    },
    root: {
        width: '300px',
    },
    textArea: {
        background: '#F1F1F1',
        marginBottom: "15px",
        borderRadius: '11px'
    },
    card: {
        width: '100%',
        background: '#FFFFFF',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.05)',
        borderRadius: '10px',
    },
    colorPrimary: {
        background: '#01BDD2',
        color: '#FFF'
    },
    title: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '16px',
        lineHeight: '100%',
        color: '#666666',
        float: 'left',

    },
    closeIcon: {
        backgroundColor: "#F1F1F4",
        color: '#E54D40',
        height: '20px',
        width: '20px',
        '&:hover': {
            backgroundColor: "#F1F1F4",
            color: '#E54D40',
        }
    },
    headerContainer: {
        height: '60px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    makeAnnouncementBtn: {
        background: '#09A9BB',
        '&:hover': {
            background: '#09A9BB',
        },
        borderRadius: '4px',
        color: '#FFF',
        justifyContent: 'center',
        textTransform: 'none',
        width: '100%'
    }

}));