import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import {
    AppBar,
    Tab,
    Tabs,
    Box,
    Fab,
    useMediaQuery,
    IconButton
} from "@material-ui/core";
import {
    useTheme
} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { useIntl } from "react-intl";
import { Add, Close } from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ArrowBack } from "@material-ui/icons";
import StatsOverview from "../.././../../components/More/ChallengesStats/StatsOverview";
import { createChallengeData, addParticipants } from "../.././../../actions/challenges";
import Challenges from "../.././../../components/More/ChallengesStats/Challenges";
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialIcon from "@material-ui/lab/SpeedDialIcon";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";

const StyledTabs = withStyles({
    root: {
        width: "100%",
    },
    indicator: {
        display: "flex",
        height: "4px",
        justifyContent: "center",
        backgroundColor: "transparent",
        "& > span": {
            width: "100%",
            backgroundColor: "#F28705",
        },
    },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

const StyledTab = withStyles((theme) => ({
    root: {
        minWidth: "60px !important",

        textTransform: "none",
        color: "#F28705",
        fontWeight: theme.typography.fontWeightRegular,
        fontSize: theme.typography.pxToRem(15),
        marginRight: theme.spacing(1),
        "&:focus": {
            opacity: 1,
        },
    },
}))((props) => <Tab disableRipple {...props} />);
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={2}>
                    <>{children}</>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function AllChallenges(props) {
    const classes = useStyles();
    const intl = useIntl();
    const dispatch = useDispatch();
    let history = useHistory();
    const [value, setValue] = React.useState(0);
    const [open, setOpen] = React.useState(false);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    return (
        <Grid container style={{ backgroundColor: "#fbfaf9", paddingBottom: isMobile && "60px" }}>
            {/*app bar*/}
            <Grid container>
                <Grid item xs={12}>
                    <AppBar
                        className={classes.appBar}
                        style={{ backgroundColor: "#fff" }}
                    >
                        <Grid
                            item
                            xs={2}
                            style={{ justifyContent: "center", display: "flex" }}
                        >
                            <IconButton
                                onClick={() => history.push("/more")}
                                className={classes.backButton}
                            >
                                <ArrowBack />
                            </IconButton>
                        </Grid>
                        <Grid item xs={8} >

                            <Typography className={classes.appBarText} variant="h6">
                                {intl.formatMessage({ id: "challengesStat" })}
                            </Typography>

                        </Grid>
                        <Grid item xs={2}>

                        </Grid>
                    </AppBar>
                </Grid>
            </Grid>
            <Grid container style={{ backgroundColor: "#fff", marginTop: '100px', position: 'fixed', zIndex: 200 }}>
                <StyledTabs
                    value={value}
                    variant="fullWidth"
                    onChange={handleChange}
                    aria-label="styled tabs example"
                >
                    <StyledTab label={intl.formatMessage({ id: "stats" })} classes={{
                        root: classes.tabLabel,
                        selected: classes.tabSelected
                    }} />
                    <StyledTab label={intl.formatMessage({ id: "challenges" })} classes={{
                        root: classes.tabLabel,
                        selected: classes.tabSelected
                    }} />
                </StyledTabs>
            </Grid>
            <Grid item xs={12} sm={6} md={6} style={{ marginTop: '130px' }}>
                <TabPanel value={value} index={0} style={{ width: "100%", height: '76vh', paddingBottom: isMobile && "60px" }}>
                    <StatsOverview />
                </TabPanel>
                <TabPanel value={value} index={1} style={{ width: "100%", height: '76vh', marginTop: '20px' }}>
                    <Challenges />
                </TabPanel>

            </Grid>

            <Grid container style={{ minHeight: "10vh" }}>
                <Grid item xs={8}></Grid>

                <Grid xs={4}>
                    <SpeedDial
                        ariaLabel="SpeedDial example"
                        className={classes.speedDial}
                        style={{
                            bottom: isMobile ? "12%" : theme.spacing.unit * 3,
                            right: theme.spacing.unit * 4,
                        }}
                        hidden={false}
                        icon={
                            <SpeedDialIcon icon={<Add />} openIcon={<Close />} />
                        }
                        onClose={handleClose}
                        onOpen={handleOpen}
                        open={open}
                        direction={'left'}
                    >
                        <SpeedDialAction
                            className={classes.btnStyle}
                            icon={"Challenge"}
                            tooltipTitle=""
                            onClick={() => {
                                dispatch(createChallengeData({}));
                                dispatch(addParticipants([]))

                                return history.push("/more/create-challenge")
                            }}
                        />


                    </SpeedDial>

                </Grid>

            </Grid>

        </Grid>
    );
}

export default AllChallenges;

const useStyles = makeStyles((theme) => ({
    appBar: {
        color: "#000",
        flexDirection: "row",
        paddingTop: theme.spacing(1.5),
        paddingBottom: theme.spacing(1.5),
        right: "unset",
        position: "fixed",
        boxShadow: "none",
        height: '100px',
        display: 'flex',
        alignItems: 'center',
        zIndex: 200

    },

    appBarText: {
        fontFamily: theme.fontFamily.Roboto,
        fontStyle: "normal",
        fontWeight: "900",
        fontSize: "20px",
        lineHeight: "23px",
        textAlign: 'center'
    },

    backButton: {
        background: "transparent",
        color: "#000",
        padding: "0px",
    },

    inputField: {
        width: "100%",
        background: "#F28705",
        borderRadius: "82px",
    },
    tabLabel: {
        color: "#DFDFDF",
        fontFamily: theme.fontFamily.Roboto,
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "16px",
        lineHeight: "19px",
        minWidth: "20%",
        textTransform: "none"
    },
    tabSelected: {
        color: "#F28705",
        fontFamily: theme.fontFamily.Roboto,
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "16px",
        lineHeight: "19px"
    },

    selectCategory: {
        background: "#F28705",
        fontFamily: theme.fontFamily.Roboto,
        fontStyle: "normal",
        fontSize: "14px",
        lineHeight: "16px",
        textAlign: "center",
        height: "41px",
        width: "156px",

        borderRadius: "20px",
        fontWeight: 500,
        color: "#ffffff",
        paddingRight: "10px",
        "&.MuiInput-underline::before": {
            borderBottom: "none",
        },
        "&.MuiInput-underline:after": {
            borderBottom: "none",
        },
        "&.MuiInput-underline:hover:not(.Mui-disabled):before": {
            borderBottom: "none",
        },
        "& .MuiSelect-icon": {
            color: "#ffffff !important",
            paddingRight: "10px",
        },
    },
    iconSelect: {
        color: "#ffffff",
        paddingRight: "10px",
        variant: "filled",
    },
    adjustCardsLayout: {
        "&.MuiGrid-spacing-xs-2": { margin: "0px !important" },
        justifyContent: "center",
    },
    hideScrollBar: {
        flexWrap: "unset",
        overflowX: "auto",
        "&::-webkit-scrollbar": {
            display: "none",
        },
    },
    paper: {
        position: "absolute",
        height: 330,
        maxWidth: 315,
        minWidth: 300,
        justifyContent: "center",
        backgroundColor: theme.palette.background.paper,
        outline: "none",
    },

    modelBodyText: {
        fontFamily: theme.fontFamily.Roboto,
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "17px",
        lineHeight: "27px",
        textAlign: "center",
        letterSpacing: "0.02em",
        color: "#192247",
    },


    modalBtnGrid: {
        padding: "0px",
        alignSelf: "flex-end",
        color: "#fff",
        cursor: "pointer",
        background: `linear-gradient(#09A9BB 1%,#09A9BB 100%)`,
        height: "73px",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
    },
    msgBody: {
        minHeight: "240px",
        alignItems: "center",
        display: "flex",
    },
    noContent: {
        color: "#7d7b79",
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "16px",
        lineHeight: "19px",
        padding: "12px 10px 10px 17px",
        textAlign: "center",
    },
    btnStyle: {
        background: "linear-gradient(180deg, #FFC000 -13.24%, #F28705 100%)",
        color: "#fff",
        height: "50px",
        width: "50px",
        fontFamily: theme.fontFamily.Reef,
        fontStyle: "normal",
        fontWeight: 900,
        fontSize: "10px",
        lineHeight: "19px",
        textAlign: "center",
    },
    btnOPen: {
        background: "linear-gradient(180deg, #FFC000 -13.24%, #F28705 100%)",
        color: "#fff",
        width: "50px",
        height: "50px",
        fontFamily: theme.fontFamily.Reef,
        fontStyle: "normal",
        fontWeight: 900,
        fontSize: "14px",
        lineHeight: "19px",
        textAlign: "center",
    },
    speedDial: {
        position: 'fixed',
        "& .MuiFab-primary:hover": {
            width: "50px",
            height: "50px",
            color: "#E54D40",
            backgroundColor: "#E5E5E5 !important",


        },
        "& .MuiFab-primary": {
            width: "50px",
            height: "50px",
            backgroundColor: "#F28705 !important",


        },
        "&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft": {
            bottom: theme.spacing(2),
            right: theme.spacing(2),
        },
        "&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight": {
            top: theme.spacing(2),
            left: theme.spacing(2),
        },
    },
}));
