import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

import { ArrowBack } from "@material-ui/icons";

function AppBar(props) {
  const classes = useStyles();

  const { body, backAction, backgroundColor } = props;
  return (
    <Grid
      item
      xs={12}
      container
      style={{ backgroundColor: backgroundColor }}
      className={classes.header}
    >
      <Grid item xs={1} />
      <Grid item xs={2}>
        <ArrowBack
          className={classes.backArrow}
          onClick={() => props.onClick(backAction)}
        />
      </Grid>
      <Grid item xs={6}>
        <Typography className={classes.headerTitle}>{body}</Typography>
      </Grid>
      <Grid item xs={3} />
    </Grid>
  );
}

export default AppBar;

const useStyles = makeStyles((theme) => ({
  header: {
    borderRadius: "0 0 34px 34px",
    height: "90px",
    width: "100%",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
  },
  headerTitle: {
    fontFamily: theme.fontFamily.Reef,
    color: theme.colors.white,
    fontStyle: "normal",
    fontWeight: 900,
    fontSize: "26px",
    lineHeight: "35px",
    textAlign: "center",
  },
  backArrow: {
    color: theme.colors.white,
    fontSize: "26px",
  },
}));
