import React from "react";
import { Grid } from "@material-ui/core";
import ListChats from "../../../../components/More/Chat/ListChats";
import ChatMessages from "../../../../components/More/Chat/ChatMessagesCard";
import { Switch, Route } from 'react-router-dom'

function ChatScreen(props) {


  return (
    <Grid container>
      <Grid item lg={6} xs={6}>
        <ListChats />
      </Grid>
      <Grid item lg={6} xs={6}>
        <Switch>
          <Route exact path={`/more/inbox/messages/:roomId`} {...props} component={ChatMessages} />
        </Switch>
      </Grid>
    </Grid>
  );
}

export default ChatScreen;
