import React, { useState, useEffect } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import { useMediaQuery, Hidden, Button, Dialog, DialogContent, DialogTitle, Tooltip, IconButton, CircularProgress } from '@material-ui/core';
import { KeyboardArrowLeft, KeyboardArrowRight, Close, Share, InsertInvitation, } from '@material-ui/icons';
import Typography from '@material-ui/core/Typography';

import ChallengeStartTimer from '../../../components/StartChallenge/challengeStartTimer'
import ChallengeParticipant from "../../../components/ViewChallengeDetail/chatParticipantCard";
import exerciseBackground from "../../../assets/img/soloExerciseBackground.png";
import { useSelector, useDispatch } from "react-redux";
import { challengeCompleteStatus } from '../../../actions/challenges';
import { useHistory, useParams, useLocation } from "react-router-dom";
import InviteAndShare from '../../../components/Common/InviteAndShare'
import ReactPlayer from "react-player/lazy";
import moment from 'moment';
import clsx from 'clsx'
import EpisodePaidDialog from '../../../components/Common/episodePaidDialog'
import IntroVideo from '../../../assets/videos/OmidIntro.mp4'
import ChallengeDetail from '../../../components/ViewChallengeDetail/ChallengeDetail';


function StartChallenge(props) {
  const classes = useStyles();
  const location = useLocation();
  const theme = useTheme();
  const params = useParams();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [step, setStep] = useState(0)
  const [episode, setEpisode] = useState(null)
  const [play, setPlay] = useState(false)
  const [startChallengeDialog, setStartChallengeDialog] = useState(false);
  const [shareOpen, setShareOpen] = useState(false);
  const [timerType, setTimerType] = useState('preparation');
  const [startTime, setStartTime] = useState('');
  const [isCompleted, setIsCompleted] = useState(false);
  const [episodeThumbnail, setEpisodeThumbnail] = useState('');
  const [joinError, setJoinError] = useState(false);
  const [todayChallengeEpisodeDetail, setTodayChallengeEpisodeDetail] = useState('');
  const [introVideo, setIntroVideo] = useState(true);

 

  let history = useHistory();
  const authReducerState = useSelector(state => state.auth)
  const challengeDetail = useSelector(state => state?.challenges?.challengeDetail)


  useEffect(() => {
    let participant = challengeDetail?.challengeParticipants?.find(participant => (participant?.id === authReducerState?.user?.id));

    setIsCompleted(
      moment(moment().format("YYYY-MM-DD")).isAfter(
        moment.utc(challengeDetail?.challengeDates?.[
          challengeDetail?.challengeDates?.length - 1
        ]?.dateTime, 'x').local().format("YYYY-MM-DD")
      )
    );

    setStartTime(challengeDetail?.challengeDates?.length > 0 && challengeDetail?.challengeDates[0]?.dateTime)

    let dateIndex = challengeDetail?.challengeDates?.findIndex(item => moment(moment.utc(item?.dateTime, 'x').local().format("YYYY-MM-DD")).isSame(
      location?.specificDate ? moment.utc(location?.specificDate, 'x').local().format("YYYY-MM-DD") :
        moment().format("YYYY-MM-DD")));
    timerType === 'preparation' && setStartChallengeDialog(true)
    if (participant) {

      let episodesIndexes = challengeDetail?.challengeEpisodes?.map((el, index) => index)
      let datesIndexes = challengeDetail?.challengeDates?.fill(episodesIndexes).flat();
      setEpisode(datesIndexes && datesIndexes[dateIndex])
    } else {
      history.push(`/challenge/details/${params?.id}`)
    }

  }, [challengeDetail])

  const episodePaidStatus = async (go) => {
    const challengeEpisodeLocal = go === 'next' ? challengeDetail && challengeDetail?.challengeEpisodes && challengeDetail?.challengeEpisodes[step + 1] : challengeDetail && challengeDetail?.challengeEpisodes && challengeDetail?.challengeEpisodes[step - 1];


    if (challengeEpisodeLocal?.episode?.isFree) {
      return true
    } else if (challengeEpisodeLocal?.episode?.coursePaid) {
      return true
    }
    setTodayChallengeEpisodeDetail(challengeEpisodeLocal)
    return false

  }

  const handleEpisodeComplete = async () => {
    setIntroVideo(!introVideo);
    if (!introVideo) {

      let thumbnailLocal = challengeDetail && challengeDetail?.challengeEpisodes && challengeDetail?.challengeEpisodes[episode]?.episode?.thumbnail

      if (challengeDetail?.challengeGroup === "step" && challengeDetail?.challengeEpisodes?.length - 1 !== step) {
        const payStatus = await episodePaidStatus('next')

        if (payStatus) {
          setStartChallengeDialog(true)
          setPlay(false)
          setStep(step + 1)
        } else {
          setEpisodeThumbnail(thumbnailLocal)
          setJoinError(true)
        }

      } else if (challengeDetail?.challengeGroup !== "step" || (challengeDetail?.challengeGroup === "step" && challengeDetail?.challengeEpisodes?.length - 1 === step)) {
        setEpisodeThumbnail(challengeDetail && challengeDetail?.challengeEpisodes && challengeDetail?.challengeEpisodes[episode]?.episode?.thumbnail)
      }
    }

  }

  const handleEpisodeStart = () => {
    setEpisodeThumbnail('')
    dispatch(challengeCompleteStatus({ token: authReducerState?.token, challengeId: params?.id }))
  }

  function secondsToTime(secs) {
    var hours = Math.floor(secs / (60 * 60));

    var divisor_for_minutes = secs % (60 * 60);
    var minutes = Math.floor(divisor_for_minutes / 60);

    var divisor_for_seconds = divisor_for_minutes % 60;
    var seconds = Math.ceil(divisor_for_seconds);

    var obj = {
      "h": hours,
      "m": minutes,
      "s": seconds
    };

    if (obj?.h === 0 && obj.m === 0) {
      return `${obj.s} sec`
    } else if (obj?.h === 0) {
      return `${obj.m} min ${obj.s} sec`
    }
    else {
      return `${obj.h} hr ${obj.m} min ${obj.s} sec`
    }

  }



  const end = () => {
    history.goBack();
  }

  const handleStep = async (e, move) => {
    let thumbnailLocal = challengeDetail && challengeDetail?.challengeEpisodes && challengeDetail?.challengeEpisodes[episode]?.episode?.thumbnail
    setPlay(false)
    e.preventDefault()
    if (move === 'next') {
      const payStatus = await episodePaidStatus('next')
      if (payStatus) {
        setIntroVideo(true);
        setStartChallengeDialog(true)
        setStep(step + 1)
      } else {
        setEpisodeThumbnail(thumbnailLocal)
        setJoinError(true)
      }

    } else if (move === 'prev') {
      if (episodePaidStatus('prev')) {
        setIntroVideo(true);
        setStep(step - 1)
      } else {
        setEpisodeThumbnail(thumbnailLocal)
        setJoinError(true)
      }

    }
  }

  const handleClose = () => {
    setStartChallengeDialog(false)
    setTimerType('rest')
    setPlay(true)
  }

  const findEpDescriptionHandler = () => {
    if (challengeDetail?.challengeGroup === "step") {
      return challengeDetail?.challengeEpisodes && challengeDetail?.challengeEpisodes[step]?.episode?.description ? challengeDetail?.challengeEpisodes && challengeDetail?.challengeEpisodes[step]?.episode?.description : null
    } else if (challengeDetail?.challengeGroup === "solo") {
      return challengeDetail && challengeDetail?.challengeEpisodes && challengeDetail?.challengeEpisodes[episode]?.episode?.description ? challengeDetail && challengeDetail?.challengeEpisodes && challengeDetail?.challengeEpisodes[episode]?.episode?.description : null
    }
  }

  console.log("challengeDetail?.challengeEpisodes===" + {challengeDetail});

  const IPFS_BASE_URL = process.env.REACT_APP_IPFS_BASE_URL;




  ;
  const videoUrl = introVideo ? IntroVideo : challengeDetail?.challengeEpisodes?.[step]?.episode?.ipfsCid
    ? `${IPFS_BASE_URL}/${challengeDetail?.challengeEpisodes?.[step]?.episode?.ipfsCid}`
    : challengeDetail?.challengeEpisodes?.[step]?.episode?.multimediaUrl;

    // console.log("Video URL" + videoUrl)
  
  // debugger

  return (
    <Grid style={{ marginLeft: !isMobile && '125px', padding: isMobile && '0px 15px 0px 15px' }}>
      <EpisodePaidDialog joinError={joinError} todayChallengeEpisodeDetail={todayChallengeEpisodeDetail} joinErrorHandler={() => {
        setJoinError(false)
        setTodayChallengeEpisodeDetail('')
      }} />
      <Grid item xs={12} >
        {shareOpen && (
          <Dialog open={shareOpen} onClose={() => setShareOpen(false)}  >
            <DialogTitle>
              <Grid container justify="space-between">
                <Grid item>
                  <Typography component="h4" variant="h5">
                    {shareOpen}
                  </Typography>
                </Grid>
                <Grid item>
                  <Button onClick={() => setShareOpen(false)}>
                    <Close />
                  </Button>
                </Grid>
              </Grid>
            </DialogTitle>
            <DialogContent style={{ padding: isMobile && 0 }}>
              <InviteAndShare
                url={`/share/challenge/${params?.id}`}
                imageUrl={challengeDetail?.imageUrl}
                sumaryText={'Join this amazing challenge '}
                link={'Hey there,  Check this out, I think you will love it'}
                type='challenge'
                invite={shareOpen === "Invite"}
                share={shareOpen === "Share"}
                handleNext={() => setShareOpen(false)}
              />
            </DialogContent>
          </Dialog>
        )}
      </Grid>
      <Grid item xs={12} >

        <Dialog
          open={startChallengeDialog}

          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title" className={classes.dialogHeading}>{timerType === 'preparation' ? "Preparation Time" : "Rest Time"}</DialogTitle>
          <DialogContent style={{ background: `url(${exerciseBackground}), #244562` }}>
            <ChallengeStartTimer timerType={timerType} timer={timerType === 'preparation' ? challengeDetail?.prepTime * 1 : challengeDetail?.restTime * 1} closeDialog={handleClose} />
          </DialogContent>

        </Dialog>
      </Grid>
      <Grid container className={classes.appBar} style={{ marginTop: !isMobile && '0px' }} alignItems='center'>
        <Grid container item xs={12} md={11} alignItems='center'>
          <Hidden smUp>
            <Grid item xs={2} />
          </Hidden>
          <Grid item xs={8} md={6}>
            <Typography className={isMobile ? classes.headerMobile : classes.header} style={{ textAlign: isMobile && 'center' }}>
              {challengeDetail?.title}
            </Typography>
          </Grid>
          <Grid item xs={2} md={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Typography className={classes.headerEnd} onClick={() => end()}>
              End
            </Typography>
          </Grid>
        </Grid>
      </Grid>


      <Grid container   >
        <Grid item container xs={12} md={10} className={classes.hideScrollBar}>
          {
            challengeDetail?.challengeParticipants?.map(participant => {
              if (participant?.id !== authReducerState?.user?.id) {
                return <Grid item xs={2} md={1} key={participant?.id}>
                  <ChallengeParticipant participant={participant} />
                </Grid>
              }
            })
          }
        </Grid>
      </Grid>

      <Grid item xs={12} md={11}>
        <div className={classes.playerWrapper}>
          <div className={clsx(classes.reactPlayer, classes.preLoadBody)} >
            <CircularProgress style={{ color: '#09A9BB' }} />
          </div>
          {(challengeDetail?.challengeGroup === "step" ? (challengeDetail?.challengeEpisodes && challengeDetail?.challengeEpisodes[step]?.episode?.ipfsCid||challengeDetail?.challengeEpisodes && challengeDetail?.challengeEpisodes[step]?.episode?.multimediaUrl) : (challengeDetail && challengeDetail?.challengeEpisodes && challengeDetail?.challengeEpisodes[episode]?.episode?.ipfsCid || challengeDetail && challengeDetail?.challengeEpisodes && challengeDetail?.challengeEpisodes[episode]?.episode?.multimediaUrl)) ?

            <ReactPlayer
              controls="true"
              width="100%"
              height="100%"
              className={classes.reactPlayer}
              playing={play}

              onStart={handleEpisodeStart}
              onEnded={handleEpisodeComplete}
              light={episodeThumbnail}

              // url={introVideo ? IntroVideo : challengeDetail?.challengeGroup === "step" ? challengeDetail?.challengeEpisodes && challengeDetail?.challengeEpisodes[step]?.episode?.multimediaUrl : challengeDetail && challengeDetail?.challengeEpisodes && challengeDetail?.challengeEpisodes[episode]?.episode?.multimediaUrl}
              url={videoUrl}
              config={{
                // youtube: {
                //   playerVars: { showinfo: 1 },
                // },
                file: { attributes: { controlsList: 'nodownload' } },
              }}
          
              /> :
            <div className={classes.reactPlayer} style={{ backgroundColor: '#a9a9a9', justifyContent: 'center', alignItems: 'center', width: "100%", height: "100%", display: 'flex' }}>
              Error loading media: File could not be played!
            </div>

          }
        </div>
      </Grid>

      {challengeDetail?.type === "Group" && !isCompleted && <Grid item xs={12} md={11} container justify='flex-end' spacing={1} >
        <Grid item>
          <Tooltip title={<span style={{ fontSize: '14px', letterSpacing: 0.30 }}>Invite</span>} placement='top'>
            <IconButton
              disabled={challengeDetail?.status === 'Incomplete' || challengeDetail?.status === 'Blocked' || challengeDetail?.status === 'InActive'}
              onClick={() => setShareOpen("Invite")}
              className={classes.inviteAndShare}
            >
              <InsertInvitation />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item>
          <Tooltip title={<span style={{ fontSize: '14px', letterSpacing: 0.30 }}>Share</span>} placement='top'>
            <IconButton
              disabled={challengeDetail?.status === 'Incomplete' || challengeDetail?.status === 'Blocked' || challengeDetail?.status === 'InActive'}
              onClick={() => setShareOpen("Share")}
              className={classes.inviteAndShare}
            >
              <Share />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>}

      {challengeDetail?.challengeGroup === "step" && <Grid item container xs={12} md={11}>
        <Grid item xs={4}><Button startIcon={<KeyboardArrowLeft />} onClick={(e) => handleStep(e, 'prev')} disabled={step === 0} className={classes.button}>Prev Step</Button></Grid>
        <Grid item xs={4} >
          <Typography className={classes.time} style={{ textAlign: 'center' }}>Current Step {step + 1}</Typography>
        </Grid>
        <Grid item xs={4} justifyContent='flex-end' style={{ textAlign: 'right' }}><Button endIcon={<KeyboardArrowRight />} onClick={(e) => handleStep(e, 'next')} disabled={challengeDetail?.challengeEpisodes?.length - 1 === step} className={classes.button}>Next Step</Button></Grid>
      </Grid>}

      <Grid item container xs={12}>

        <Grid item container xs={12} style={{ paddingBottom: '15px' }}>
          <Grid item xs={6} sm={6} md={2} lg={2}>
            <Typography className={classes.time}>Preparation Time :</Typography>
          </Grid>
          <Grid item xs={4} sm={4} md={4} lg={4}>
            <Typography className={classes.time}>{secondsToTime(challengeDetail?.prepTime * 1)}</Typography>
          </Grid>
          {challengeDetail?.challengeGroup === 'step' && <>
            <Grid item xs={6} sm={6} md={2} lg={2}>
              <Typography className={classes.time}>Rest Time :</Typography>
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={4} >
              <Typography className={classes.time}>{secondsToTime(challengeDetail?.restTime * 1)}</Typography>
            </Grid>
          </>}
          <Grid item xs={6} sm={6} md={2} lg={2}>
            <Typography className={classes.time}>Exercise Time :</Typography>
          </Grid>
          <Grid item xs={4} sm={4} md={4} lg={4}>
            <Typography className={classes.time}>{
              moment.utc(startTime, 'x').local().format('LT')
            }</Typography>
          </Grid>

        </Grid>

        <Grid item xs={12} md={11}>

          <Typography
            style={{
              fontFamily: theme.fontFamily.Reef,
              fontStyle: "normal",
              fontWeight: 900,
              fontSize: "20px",
              lineHeight: "27px",
              color: "#000000",
              paddingTop: '15px',
              paddingBottom: '5px'
            }}
          >
            {'Description'}
          </Typography>
          {findEpDescriptionHandler() ? <div className={classes.epDescription} dangerouslySetInnerHTML={{ __html: unescape(findEpDescriptionHandler()) }} /> :
            <div className={classes.epDescription} style={{ textAlign: 'center', paddingTop: '50px' }}>No description found</div>
          }

        </Grid>
      </Grid>



    </Grid>
  );
}

export default StartChallenge;


const useStyles = makeStyles(theme => ({

  appBar: {
    backgroundColor: '#FFF',
    alignItems: 'center',
    width: '100%',
    boxShadow: 'none',
    display: 'flex',
    height: '60px',
    justifyContent: 'space-between',
  },
  header: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '24px',
    lineHeight: '28px',
    color: '#000000',
  },
  headerEnd: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '24px',
    lineHeight: '28px',
    color: '#DA3324',
    cursor: "pointer",
  },
  hideScrollBar: {
    flexWrap: "unset",
    overflowX: "auto",
    "&::-webkit-scrollbar": {
      display: "none"
    }
  },
  time: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: 'normal',
    fontWeight: '900',
    fontSize: '16px',
    lineHeight: '20px',
    color: '#000000',
    marginTop: '20px'
  },

  headerMobile: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '24px',
    lineHeight: '28px',
    color: '#3C4A6B',
    textAlign: 'center'
  },
  button: {
    background: "linear-gradient(360deg, #15AEBF 0%, #A6D6FF 100%)",
    color: '#FFF',
    textTransform: 'none',
    marginTop: '15px'
  },
  playerWrapper: {
    position: 'relative',
    paddingTop: '56.25%'

  },
  reactPlayer: {
    position: 'absolute',
    top: 0,
    left: 0
  },
  dialogHeading: {
    background: '#244562',
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: 900,
    fontSize: "36px",
    lineHeight: "42px",
    letterSpacing: "0.045em",
    color: theme.colors.white,
    textAlign: 'center'
  },
  inviteAndShare: {
    background: "linear-gradient(360deg, #15AEBF 0%, #A6D6FF 100%)",
    marginTop: "1rem",
    fontSize: "20px",
    lineHeight: "10px",
    color: "#FFFFFF"
  },
  epDescription: {
    border: '1px solid #a9a9a9', padding: '5px 10px', borderRadius: 10, minHeight: '100px'
  },
  preLoadBody: {
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#a9a9a9',
    width: '100%'
  }
}));
