import React from 'react'
import { Grid, Avatar, Tab, Box, useMediaQuery, Tabs, Button, Typography } from '@material-ui/core'
import { Add, Done, Clear } from '@material-ui/icons'
import { makeStyles, useTheme } from "@material-ui/styles";

function FriendInvitationCard(props) {
    const classes = useStyles();
    const theme = useTheme();

    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));


    return (
        <Grid container className={classes.container} justify='space-between'>

            <Grid item xs={3}>
                <Avatar src={props?.friend?.pictureUrl} className={classes.image} style={{ height: isMobile && '60px', width: isMobile && '60px' }} />
            </Grid>
            <Grid item xs={7}>
                <Typography className={classes.username}>@{props?.friend?.username}</Typography>
                <Typography className={classes.name}>{props?.friend?.name}</Typography>
                {props?.friend?.isFriend && <Typography className={classes.friend}>Friend</Typography>}
            </Grid>
            <Grid item xs={2}>
                {
                    props?.friend?.status === 'sent' ?
                        <Button className={[classes.btn, classes.sentBtn]} onClick={() => props?.friend?.invited ? '' : props?.invitation('cancel', props?.friend)}>
                            <Done />
                        </Button> :
                        props?.friend?.status === 'invited' ?
                            <Button className={[classes.btn, classes.cancelBtn]}
                            >
                                <Clear />
                            </Button> :
                            <Button className={classes.btn} onClick={() => props?.invitation('sent', props?.friend)} >
                                <Add />
                            </Button>
                }


            </Grid>
        </Grid>
    )
}

export default FriendInvitationCard


const useStyles = makeStyles(theme => ({
    container: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        borderBottom: '1px solid rgba(168, 182, 200, 0.3)',
        height: '6.5rem',
        transform: 'rotate(360deg)',
        boxShadow: "0px 0px 0px rgba(0, 0, 0, 0.00)",
    },
    image: {
        height: '70px',
        width: '70px'
    },
    username: {
        fontFamily: theme.fontFamily.Roboto,
        fontStyle: "italic",
        fontWeight: "bold",
        fontSize: "18px",
        lineHeight: "21px",
        color: '#3C4A6B',
        marginLeft: '5px'
    },
    name: {
        fontFamily: theme.fontFamily.Roboto,
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "16px",
        lineHeight: "21px",
        color: '#3C4A6B',
        marginTop: '5px',
        marginLeft: '5px'

    },
    friend: {
        fontFamily: theme.fontFamily.Roboto,
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "16px",
        lineHeight: "21px",
        color: '#A9A9A9',
        marginTop: '5px',
        marginLeft: '5px'

    },
    btn: {
        borderRadius: '26px',
        height: '64px',
        width: '64px',
        color: '#FFF',
        background: '#fbae02',
        '&:hover': {
            background: '#fbae02',
        }
    },
    sentBtn: {
        background: '#A9A9A9',
        '&:hover': {
            background: '#A9A9A9',
        }
    },
    cancelBtn: {
        background: '#FF4C3B',
        '&:hover': {
            background: '#FF4C3B',
        }
    }
}))