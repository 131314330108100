import React from "react";
import { Grid, Avatar, Box, Typography, Badge } from "@material-ui/core";
import { Sms } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

function FriendActiveStatusCard(props) {
  const classes = useStyles();
  const intl = useIntl();
  const authReducer = useSelector(state => state?.auth);

  return (
    <Box className={classes.container} style={{ background: "#FFF" }}>
      <Grid container>
        <Grid item xs={2}>
          {props?.activeStatus === "Active Now" ? (
            <Badge
              color="secondary"
              overlap="circle"
              badgeContent=" "
              variant="dot"
              classes={{ colorSecondary: classes.badge }}
            >
              <Avatar
                src={props?.friend?.pictureUrl}
                className={classes.large}
              />
            </Badge>
          ) : (
            <Avatar src={props?.friend?.pictureUrl} className={classes.large} />
          )}
        </Grid>
        <Grid item xs={5}>
          <Typography className={classes.title}>
            {props?.friend?.name}
          </Typography>
        </Grid>
        <Grid item xs={3} style={{ textAlign: "center" }}>
          <Typography className={classes.timeAgo}>
            {props?.activeStatus === "Active Now" ? (
              <span style={{ color: "#F28705" }}>
                {intl.formatMessage({ id: "activeNow" })}
              </span>
            ) : (
              <span style={{ color: "#B6B6B6" }}>{props?.activeStatus}</span>
            )}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Link
            to={{
              pathname: `/more/inbox/messages/${
                props?.friend?.id > authReducer?.user?.id
                  ? `${authReducer?.user?.id}_${props?.friend?.id}`
                  : `${props?.friend?.id}_${authReducer?.user?.id}`}`,
              friendInfo: {
                user: {
                  pictureUrl: props?.friend?.pictureUrl,
                  name: props?.friend?.name
                },
                lastMessage: {},
                id:
                  props?.friend?.id > authReducer?.user?.id
                    ? `${authReducer?.user?.id}_${props?.friend?.id}`
                    : `${props?.friend?.id}_${authReducer?.user?.id}`
              }
            }}
          >
            <Sms style={{ color: "#09A9BB", marginTop: "10px" }} />
          </Link>
        </Grid>
      </Grid>
    </Box>
  );
}

export default FriendActiveStatusCard;

const useStyles = makeStyles(theme => ({
  container: {
    height: "80PX",
    width: "100%",
    background: "#fbfaf9",
    borderBottom: "1px solid #E9E9E9",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    alignContent: "center",
    padding: "0 5px 0 5px"
  },
  title: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "16px",
    color: "#797979",
    marginTop: "15px"
  },
  timeAgo: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "12px",
    lineHeight: "14px",
    marginTop: "15px",
    opacity: 0.7
  },
  badge: {
    backgroundColor: "#5BE4E4"
  }
}));
