import React from 'react'
import AppBar from '../../../../components/Common/AppBarBackBtnTitle'
import { Grid } from '@material-ui/core'
import ConnectsComponent from '../../../../components/Connects'
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";

function Connects() {
    const intl = useIntl();
    const history = useHistory();
    return (
        <Grid container style={{ background: '#FFF', minHeight: '100vh' }}>
            <Grid item container xs={12} style={{ position: 'fixed', background: '#FFF', zIndex: 200 }}>
                <AppBar title={intl.formatMessage({ id: "connections" })} onClick={() => history.push('/more')} />
            </Grid>
            <Grid item container xs={12} style={{ marginTop: '90px' }}>
                <ConnectsComponent />
            </Grid>
        </Grid>
    )
}

export default Connects
