import React, { useState } from 'react';
import { Button, Grid, Typography } from '@material-ui/core'
import { ArrowBackSharp, BorderColor, Delete } from '@material-ui/icons'
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from 'react-router-dom'
import Request from './Requests'
import MakeAnnouncement from './MakeAnnouncement'
import { useSelector } from 'react-redux'
import JoinCourseComponent from './joinTrainingCourse'
import DeleteCourseComponent from './DeleteCourseComponent';


function Comments(props) {
    const classes = useStyles();
    const history = useHistory();
    const auth = useSelector(state => state?.auth);
    const user = useSelector(state => state?.auth?.user);
    const course = useSelector(state => state?.retreatCourse?.trainingCourseDetails);

  

    return (
        <Grid container className={classes.container}>
            <Grid item xs={2} sm={2} md={1} lg={1} onClick={() => history.goBack()}><ArrowBackSharp style={{ color: '#3D3D3D', cursor:'pointer' }} /></Grid>
            <Grid item xs={10} sm={10} md={4} lg={4}><Typography className={classes.heading}>{course?.name}</Typography></Grid>
            {(course?.isMentor) ?
                <Grid item xs={12} sm={12} md={7} lg={7} container style={{ paddingTop: '12px' }}>
                    <Grid item xs={3}>
                    {course?.isPrivate &&<Request />}
                    </Grid>
                    <Grid item xs={course?.user?.id === user?.id ? 7 : 9} >
                        <MakeAnnouncement />
                    </Grid>
                    {course?.user?.id === user?.id &&<Grid item xs={1} onClick={() => history.push(course?.retreatType === 'retreat' ? `/edit-retreat/${course?.id}` : `/edit-teacher-training/${course?.id}`)}>
                         <BorderColor className={classes.icon} />
                    </Grid>}
                    {course?.user?.id === user?.id &&   <Grid item xs={1} style={{ textAlign: 'right' }}>
                        {/* <Delete className={classes.icon} /> */}
                       <DeleteCourseComponent />
                    </Grid>}
                </Grid>
                : <Grid item xs={12} sm={12} md={7} lg={7}>
                    {/* {!course?.isUserTaking  ? <Button className={course?.isUserTaking ? classes.requestSent : classes.joinNowButton}>{joinStatus === 'sent' ? 'Request Sent' : 'Join Now'}</Button> :
                        <Typography className={classes.joinedDate}>Joined on 16 March 2021</Typography>} */}
                    <JoinCourseComponent />
                </Grid>

            }
        </Grid>
    );
}

export default Comments;

const useStyles = makeStyles(theme => ({
    container: {
        height: '90px',
        width: '100%',
        alignItems: 'center'
    },
    center: {
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
    },
    heading: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '20px',
        lineHeight: '100%',
        color: '#353B35',
    },
    joinedDate: {
        fontFamily: 'Roboto',
        fontStyle: 'italic',
        fontWeight: 'bold',
        fontSize: '14px',
        lineHeight: '100%',
        color: '#818581',
        float: 'right',

    },
    joinNowButton: {
        minWidth: '90px',
        color: '#fff',
        float: 'right',
        background: '#09A9BB',
        '&:hover': {
            background: '#09A9BB',
        }
    },
    requestSent: {
        minWidth: '90px',
        color: '#fff',
        float: 'right',
        background: '#9F9F9F',
        textTransform: 'none',
        '&:hover': {
            background: '#9F9F9F',
        }
    },
    icon: {
        color: '#666666',
        cursor: 'pointer'
    }

}));