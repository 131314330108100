import React from "react";
import { useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import SoloExerciseMobileContainer from "../../containers/Mobile/SoloExercise/Index";
import { Redirect } from "react-router-dom";
const SoloExercise = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));

  let categoriesList = useSelector((state) => state?.courses?.categoriesList);

  return (
    <>
      {(!isMobile && isDesktop) ||
      (categoriesList && categoriesList.length === 0) ? (
        <Redirect to="/profile" />
      ) : (
        <SoloExerciseMobileContainer />
      )}
    </>
  );
};

export default SoloExercise;
