import React from "react";
import LargeButton from "../../../components/Buttons/MobileLargeButton";
import { Grid, Typography } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
import simpleLogo from "../../../assets/Icons/RelateIcon.png";

import { useDispatch, useSelector } from "react-redux";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    background: `#C4C4C4`,

    minHeight: "100vh",
    height: "100%",
    overFlow: "auto",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "top center"
  },
  topRightCircle: {
    width: "100px",
    height: "200px",
    borderRadius: "0px 110px 110px 0px",
    float: "left",
    backgroundColor: "#09A9BB",
    marginTop: theme.spacing(7),
    opacity: "0.5"
  },
  bottomLeftCircle: {
    width: "50px",
    height: "50px",
    borderRadius: "50%",
    backgroundColor: "#14D9D9",
    marginTop: theme.spacing(4),
    opacity: "0.5"
  },
  centerContainer: {
    textAlign: "center",
    margin: "auto"
  },
  subHeading: {
    fontFamily: theme.fontFamily.Reef,
    marginTop: "90px",
    fontStyle: "normal",
    fontWeight: "900",
    fontSize: "32px",
    lineHeight: "44px",
    textAlign: "center",
    letterSpacing: " 0.02em",
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    color: "#FFFFFF"
  },
  buttonContainer: {
    marginTop: theme.spacing(2)
  }
}));

const ChooseIdentity = () => {
  const theme = useTheme();

  const classes = useStyles();
  const history = useHistory();
  let dispatch = useDispatch();
  const intl = useIntl();
  const configs = useSelector(state => state?.setting?.siteConfig)

  const buttons = [
    {
      text: intl.formatMessage({ id: "student" }),
      value: "Regular"
    },

    {
      text: intl.formatMessage({ id: "teacher" }),
      value: "Instructor"
    }
  ];
  const goToAuthPage = identity => {
    dispatch({ type: "SET_ROLE", payload: identity });

    history.push({
      pathname: `/auth`,
      id: 1
    });
  };



  return (
    <React.Fragment>
      <div className={classes.root} style={{ backgroundImage: `url(${configs?.landingMobileBackground})` }}>
        <Grid container style={{}}>
          <Grid container>
            <Grid item xs={2}>
              <div className={classes.topRightCircle} />
            </Grid>
            <Grid
              item
              xs={8}
              justify="center"
              className={classes.centerContainer}
              container
            >
              <img
                src={simpleLogo}
                alt="logo"
                style={{ marginTop: "30px", }}
              />

              <Typography className={classes.subHeading}>
                {intl.formatMessage({ id: "chooseIdentity" })}
              </Typography>

              <Grid item xs={12}>
                {buttons.map((button, index) => (
                  <LargeButton
                    key={index}
                    onClick={() => goToAuthPage(button.value)}
                    text={button.text}
                    width={"100%"}
                    fontFamily={theme.fontFamily.Reef}
                    fontStyle={"normal"}
                    fontSize={window.outerWidth <= 350 ? "16px" : "20px"}
                    fontWeight={"900"}
                    lineHeight={"30px"}
                    letterSpacing={"0.05em"}
                  />
                ))}
              </Grid>
            </Grid>
            <Grid item xs={2}>
              <div className={classes.bottomLeftCircle} />
            </Grid>
          </Grid>


        </Grid>
      </div>
    </React.Fragment>
  );
};

export default ChooseIdentity;
