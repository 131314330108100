import React, { useEffect } from "react";
import { Grid, Avatar, Typography } from "@material-ui/core";
import ChatMessagesCard from "../../../components/ViewChallengeDetail/GroupChat";
import { ArrowBack, MoreVert } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { getChallengeDetail } from "../../../actions/challenges";
import { SocketContextProvider } from "../../../Context/socketContext";

function GroupChat(props) {
  const classes = useStyles();
  const params = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const challengeDetail = useSelector(
    (state) => state?.challenges?.challengeDetail
  );
  const auth = useSelector((state) => state?.auth);

  useEffect(() => {

    dispatch(
      getChallengeDetail({
        userid: auth?.user,
        token: auth?.token,
        challengeId: params?.id,
      })
    );


  }, []);

  return (
    <SocketContextProvider>
      <Grid container>

        <Grid
          item
          xs={12}

        >
          <ChatMessagesCard
            style={{ color: "#606060" }}
            roomId={props?.chat?.id}
          />
        </Grid>
      </Grid>
    </SocketContextProvider>
  );
}

export default GroupChat;

const useStyles = makeStyles((theme) => ({
  header: {
    height: "110px",
    backgroundColor: "#FFFFFF",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    position: "fixed",
    zIndex: 200,
    width: "100%",
    padding: "0px 15px",
  },
  photo: {
    width: "66px",
    height: "66px",
    textAlign: "center",
    marginLeft: "5px",
  },
  userName: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "137.19%",
    color: "#656565",
  },
  heading: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '24px',
    lineHeight: '28px',
    color: '#3C4A6B',
    textAlign: 'center'
  },
}));


