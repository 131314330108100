import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Fab from "@material-ui/core/Fab";

const useStyles = makeStyles(theme => ({
  styles: {
    margin: '16px 0px',
    paddingLeft: "0.5rem",
    paddingRight: theme.spacing(1),
    border: "2px solid #FFFFFF",
    background: "transparent",
    textTransform:'unset'
  }
}));

const LetStartButton = ({
  text,
  onClick,
  fontFamily,
  fontStyle,
  fontSize,
  fontWeight,
  lineHeight,
  letterSpacing,
  width,
  key
}) => {
  const classes = useStyles();

  return (
    <div key={key}>
      <Fab
        variant="extended"
        aria-label="add"
        className={classes.styles}
        style={{
          color: "white",
          fontFamily,
          fontSize,
          fontStyle,
          fontWeight: fontWeight,
          lineHeight,
          letterSpacing,
          width,

        }}
        onClick={onClick}
      >
        {text}
      </Fab>
    </div>
  );
};

export default LetStartButton;
