import React from 'react';
import { Grid, Typography, useMediaQuery, useTheme } from '@material-ui/core'
import { makeStyles } from "@material-ui/core/styles";
import { Image, Videocam, InsertDriveFile } from '@material-ui/icons'
import PdfIcon from '../../../assets/Icons/adobeIcon.svg'
import downloadIcon from '../../../assets/img/downloadIcon.svg'

function SupportedFileCard(props) {
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const { file } = props

    function bytesToSize(bytes) {
        var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        if (bytes == 0) return '0 Byte';
        var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
        return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
    }

    return (
        <Grid container spacing={2} className={classes.container} style={props?.style}>
            <Grid item xs={3} sm={3} md={3} lg={3} xl={2}>
                <a href={`${process.env.REACT_APP_BASE_URL}/${file?.url}`} target='_blank'>
                    <div style={{
                        background: file?.type === 'pdf' ? 'rgba(229, 77, 64, 0.26)' : file?.type === 'Video' ? 'rgba(0, 0, 0, 0.22)' : file?.type === 'image' ? 'rgba(65, 65, 65, 0.22)' : 'rgba(21, 174, 191, 0.22)',
                        color: file?.type === 'pdf' ? '#E54D40' : file?.type === 'Video' ? '#1C1C1C' : file?.type === 'image' ? '#939393' : '#01BDD2'
                    }} className={classes.Icon}>
                        {file?.type === 'pdf' ? <img src={PdfIcon} height='20px' /> : file?.type === 'Video' ? <Videocam /> : file?.type === 'image' ? <Image /> : <InsertDriveFile />}
                    </div>
                </a>
            </Grid>
            <Grid item container xs={7} sm={7} md={9} lg={9} xl={10} >
                <Grid item xs={12}>
                    <a href={`${process.env.REACT_APP_BASE_URL}/${file?.url}`} target='_blank' style={{ textDecoration: 'none' }}>

                        <Typography className={classes.heading}>{file?.name}</Typography>
                    </a>

                </Grid>
                <Grid item xs={12}>
                    <Typography className={classes.heading} style={{ fontSize: '12px', paddingTop: '5px' }}>{bytesToSize(file?.size)}</Typography>
                </Grid>
            </Grid>
            {isMobile && <Grid item xs={2} sm={2} md={0} lg={0} xl={0}>
                <a href={`${process.env.REACT_APP_BASE_URL}/${file?.url}`} target='_blank'>
                    <img src={downloadIcon} />
                </a>
            </Grid>

            }

        </Grid>
    );
}

export default SupportedFileCard;

const useStyles = makeStyles(theme => ({
    heading: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '14px',
        lineHeight: '100%',
        color: '#353B35',
        paddingLeft: '7px'
    },

    card: {
        width: '100%',
        background: '#FFFFFF',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.05)',
        borderRadius: '10px',
    },
    Icon: {
        borderRadius: '10px', height: '50px', width: '50px', display: 'flex', alignItems: 'center', justifyContent: 'center'
    },
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }

}));