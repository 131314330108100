import React, { useState, useEffect, useRef } from 'react';
import { Popover, Typography, Badge, Card, CardContent, Avatar, Grid, IconButton, Button } from '@material-ui/core'
import { Close, CheckCircle, Cancel } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import { acceptRejectJoinRequest, getTrainingCourseRequests } from '../../../actions/retreatCourse'

function Requests(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const target = useRef(null);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [approveStatus, setApprove] = useState('send');
    const [noOfPendingRequest, setNoOfPendingRequest] = useState(0);
    const [loader, setLoader] = useState('');
    const requests = useSelector(state => state?.retreatCourse?.trainingCourseRequests);
    const user = useSelector(state => state?.auth);
    const course = useSelector(state => state?.retreatCourse?.trainingCourseDetails);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        dispatch(getTrainingCourseRequests({ token: user?.token, trainingCourseId: course?.id }));
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const handleApprove = async (e, status, request) => {
        e.preventDefault()
        setLoader(status)
        const res = await dispatch(acceptRejectJoinRequest({ courseId: course?.id, token: user?.token, userId: request?.user?.id, status, requestId: request?.id }));
        if (res) {
            setAnchorEl(anchorEl)
            setApprove(status);
            return setLoader('')
        }
        setLoader('')

    }



    useEffect(() => {
        if (requests) {
            const noOfReq = requests?.filter(item => item?.status === 'Applied');
            setNoOfPendingRequest(noOfReq?.length)
        }
    }, [requests])


    return (
        <div>
            {<Badge badgeContent={noOfPendingRequest > 0 ? noOfPendingRequest : 0} color="primary" classes={{ colorPrimary: classes.colorPrimary }}>
                <Typography onClick={handleClick} style={{ color: open ? '#01BDD2' : '#353B35', cursor: 'pointer' }}>Requests</Typography>
            </Badge>

            }
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Card className={classes.root}>
                    <CardContent>
                        <Grid container>

                            <Grid container spacing={2} item={12} className={classes.headerContainer}>
                                <Grid item xs={6}><Typography className={classes.title}>Joining Requests</Typography></Grid>
                                <Grid item xs={4}  >
                                    <Button className={classes.noOfRequests}>
                                        {noOfPendingRequest}
                                    </Button>
                                </Grid>
                                <Grid item xs={2}>
                                    <IconButton className={classes.closeIcon} onClick={handleClose}><Close /></IconButton>
                                </Grid>
                            </Grid>
                            {requests?.length > 0 ? requests?.map(request => <Grid container item xs={12} key={request?.id} style={{ borderBottom: '1px solid #F3F3F3', padding: "10px 0px" }}>

                                <Grid item xs={12} container spacing={2} >
                                    <Grid item >
                                        <Avatar aria-label="recipe" className={classes.avatar} src={request?.user?.pictureurl}>
                                            {request?.user?.name[0]}
                                        </Avatar>
                                    </Grid>
                                    <Grid item >
                                        <Typography className={classes.name}>
                                            {request?.user?.name}
                                        </Typography>
                                        <Typography className={classes.username}>
                                            @{request?.user?.username}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography className={classes.description}>
                                        {(request?.status === 'Accepted' || request?.status === 'Joined') ? request?.user?.name + "'s request is approved" : request?.status === 'Rejected' ? request?.user?.name + "'s request is rejected" : request?.user?.name + "'s requested to join this course"}
                                    </Typography>
                                </Grid>
                                {(request?.status === 'Accepted' || request?.status === 'Joined') ? <Grid item xs={12} container spacing={2}>
                                    <Grid item >
                                        <Typography className={classes.supportedFileTitle} style={{ color: '#87AC26' }}> Approved</Typography>
                                    </Grid>

                                    <Grid item style={{ textAlign: 'center', color: '#87AC26' }}>
                                        <CheckCircle />
                                    </Grid>
                                </Grid> : request?.status === 'Rejected' ? <Grid item xs={12} container spacing={2}>
                                    <Grid item>
                                        <Typography className={classes.supportedFileTitle} style={{ color: '#E54D40' }}>Not Approve</Typography>
                                    </Grid>

                                    <Grid item style={{ textAlign: 'center', color: '#E54D40' }}>
                                        <Cancel />
                                    </Grid>
                                </Grid> : <Grid item xs={12} container spacing={2}>
                                    <Grid item xs={4} >
                                        <Button className={classes.approveBtn} onClick={(e) => handleApprove(e, 'Accepted', request)} disabled={loader}>{'Approve'}</Button>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Button className={classes.notApproveBtn} onClick={(e) => handleApprove(e, 'Rejected', request)} disabled={loader}>{'Not Approve'}</Button>
                                    </Grid>
                                </Grid>
                                }
                            </Grid>) : <Grid item xs={12} className={classes.noContent}>
                                <Typography className={classes.noContentText}>No Request found</Typography>
                            </Grid>}

                        </Grid>
                    </CardContent>

                </Card>
            </Popover>
        </div>
    );
}

export default Requests;


const useStyles = makeStyles(theme => ({
    container: {
        MinHeight: '100vh',
        background: '#FBFBFB',
        padding: '30px 15px 50px 15px'
    },
    root: {
        width: '300px',
    },
    card: {
        width: '100%',
        background: '#FFFFFF',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.05)',
        borderRadius: '10px',
    },
    colorPrimary: {
        background: '#01BDD2',
        color: '#FFF',
    },
    noOfRequests: {

        '&:hover': {
            backgroundColor: 'rgba(20, 217, 217, 0.22)'
        },
        boxShadow: 'none',
        height: '20px',
        minWidth: '20px',
        backgroundColor: 'rgba(20, 217, 217, 0.22)'
    },
    title: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '16px',
        lineHeight: '100%',
        color: '#666666',
        float: 'left',

    },
    description: {
        fontFamily: 'Reef',
        fontStyle: 'normal',
        fontWeight: '900',
        fontSize: '14px',
        lineHeight: '100%',
        color: '#525252',
        padding: '20px 0px'

    },
    closeIcon: {
        backgroundColor: "#F1F1F4",
        color: '#E54D40',
        height: '20px',
        width: '20px',
        '&:hover': {
            backgroundColor: "#F1F1F4",
            color: '#E54D40',
        }
    },
    headerContainer: {
        height: '60px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    name: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '14px',
        lineHeight: '25px',
        color: '#383838',
    },
    username: {
        fontFamily: 'Roboto',
        fontStyle: 'italic',
        fontWeight: 'bold',
        fontSize: '12px',
        color: '#383838',
    },
    notApproveBtn: {
        fontFamily: theme.fontFamily.Roboto,
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "14px",
        color: "#666666",
        textTransform: 'none'
    },
    approveBtn: {
        background: '#09A9BB',
        '&:hover': {
            background: '#09A9BB',
        },
        borderRadius: '4px',
        color: '#FFF',
        justifyContent: 'center',
        textTransform: 'none',
    },
    noContent: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    noContentText: {
        fontFamily: theme.fontFamily.Roboto,
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "14px",
        color: "#666666",

    }


}));