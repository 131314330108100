import React, { useEffect, useState } from 'react';
import { Avatar, Grid, Card, Typography } from '@material-ui/core'
import { makeStyles, withStyles } from "@material-ui/core/styles";

function PlayTrainingVideo(props) {
    const classes = useStyles();

    return (
        <Grid container spacing={1} style={{ width: 'max-content' }}>
            <Grid item >
                <Avatar src={props?.mentor?.pictureUrl}>
                    {props?.mentor?.name[0]}
                </Avatar>
            </Grid>
            <Grid item >
                <Typography className={classes.role}>MENTOR</Typography>
                <Typography className={classes.name}>{props?.mentor?.name}</Typography>
            </Grid>

        </Grid>
    );
}

export default PlayTrainingVideo;

const useStyles = makeStyles(theme => ({
    role: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '12px',
        lineHeight: '100%',
        color: '#C6C6C6',
        paddingTop: '5px',

    },

    name: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '14px',
        lineHeight: '100%',
        color: '#353B35',
        paddingTop: '3px',
    },

}));