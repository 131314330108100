import React from 'react'
import AppBar from "../../../../components/Common/AppBarBackBtnTitle";
import RelateWithFriends from "../../../../components/More/RelateWithFriends";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { Grid } from '@material-ui/core'
function RelateWithFriendsMobile(props) {
  const intl = useIntl();
  const history = useHistory();

  return (
    <Grid container >
      <Grid item xs={12}>

        <AppBar
          title={intl.formatMessage({ id: "relateWithFriends" })}
          headerStyle={{ color: '#3C4A6B', fontStyle: 'Roboto', fontWeight: 'bold' }}
          onClick={() => history.push('/more')}

        />


      </Grid>
      <Grid item xs={12} style={{ backgroundColor: '#fbfaf9', height: '100vh' }}>
        <RelateWithFriends />

      </Grid>
    </Grid>

  )
}

export default RelateWithFriendsMobile
