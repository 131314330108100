import React, { useState, useEffect } from "react";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";

import Grid from "@material-ui/core/Grid";
import {
  AppBar,
  TextField,
  useMediaQuery,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { useIntl } from "react-intl";
import Cards from "../../../components/Cards/Card";
import Slider from "@material-ui/core/Slider";
import { useSelector, useDispatch } from "react-redux";
import { getSessionDetails } from "../../../actions/Session";
import moment from "moment";
import API from "../../../utils/API";

function Schedule(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const intl = useIntl();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const authReducerState = useSelector((state) => state.auth);
  const authUser = useSelector((state) => state.user?.user);


  const [valueStart, setValueStart] = React.useState(
    props.selectedSession && props.selectedSession.startTime
      ? props.selectedSession.startTime
      : 0
  );
  const [valueEnd, setValueEnd] = React.useState(
    props.selectedSession && props.selectedSession.endTime
      ? props.selectedSession.endTime
      : 0
  );
  const [session_name, setSessionName] = useState(
    props.selectedSession && props.selectedSession.name
      ? props.selectedSession.name
      : ""
  );

  const [participants, setParticipants] = useState([]);

  useEffect(() => {
    if (props?.selectedSession?.sessionParticipants?.length > 0) {
      setParticipants(props?.selectedSession?.sessionParticipants)
    }

  }, [props?.selectedSession?.sessionParticipants]);

  const [invited] = useState(getUsers);
  function getUsers() {
    let arr = [];
    if (props?.selectedSession?.invitations?.length > 0) {
      props.selectedSession.invitations.map((n) => {
        arr.push(n.invitationReceiverId);
      });
    }
    return arr;
  }
  const [days, setDays] = useState(getDays);
  function getDays() {
    let days = [];
    props.selectedSession &&
      props.selectedSession.sessionDates &&
      props.selectedSession.sessionDates.map((n) => {
        days.push(n.date);
      });
    return days;
  }
  const userFriends = useSelector((state) => state?.userRelations?.userFriends);

  const chcekInvited = (id) => {
    if (invited.indexOf(id) === -1) {
      return false;
    } else {
      return true;
    }
  };

  const [startTime, setStartTime] = useState(moment(props?.selectedSession?.sessionDates && props?.selectedSession?.sessionDates[0]?.dateTime, "x").format('LT'));
  const [endTime, setEndTime] = useState(moment(props?.selectedSession?.sessionDates && props?.selectedSession?.sessionDates[0]?.endingDateTime, "x").format('LT'));
  const getFomatatedTime = (value, component) => {
    let hours =
      parseInt((value * 15) / 60) > 12
        ? parseInt((value * 15) / 60) - 12
        : parseInt((value * 15) / 60);
    let min = ((value * 15) / 60 - parseInt((value * 15) / 60)) * 60;
    let ampm = parseInt((value * 15) / 60) > 12 ? "PM" : "AM";

    let calculatedTime = `${hours}:${min} ${ampm}`;

    if (hours === 0) {
      hours = 12;
    }
    if (component === "start") {
      setStartTime(calculatedTime);
    } else {
      setEndTime(calculatedTime);
    }


    return `${hours}:${min} ${ampm}`;


  };
 
  const handleMarkedCompleted = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authReducerState.token}`,
      },

    };
    // set body

    const body = '';

    try {
      const res = await API.patch(
        `/api/v1/session/set-session-complete/${props?.selectedSession?.id}`,
        body,
        config
      );

      dispatch(
        getSessionDetails({
          userid: authReducerState?.user?.id,
          token: authReducerState?.token,
          id: props?.selectedSession?.id,
        })
      );



      dispatch({
        type: 'SHOW_MESSAGE',
        payload: {
          title: `Success`,
          message: res.data.message,
          severity: "success",
        },
      });
    } catch (error) {
      dispatch({
        type: 'SHOW_MESSAGE',
        payload: {
          title: `Failed`,
          message:
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : ` failed`,
          severity: "error",
        },
      });
    }
  }

  const isSessionTime = () => {
    if (props?.selectedSession?.status === 'Active' && (props?.selectedSession?.sessionDates?.length > 0) && (props?.selectedSession?.userId === authReducerState?.user?.id) && (moment().isBefore(moment(props?.selectedSession?.sessionDates[0]?.endingDateTime, 'x').local()))) {
      return true
    } else {
      return false
    }
  }

  const canMarkedCompleted = () => {
    if (props?.selectedSession?.status === 'Active' && (props?.selectedSession?.userId === authReducerState?.user?.id) && (props?.selectedSession?.sessionDates?.length > 0) && (moment().isAfter(moment(props?.selectedSession?.sessionDates[0]?.endingDateTime, 'x').local()))) {
      return true
    } else if (props?.selectedSession?.status === 'Active' && (props?.selectedSession?.userId !== authReducerState?.user?.id) && props?.selectedSession?.isCompleted && (props?.selectedSession?.sessionDates?.length > 0) && (moment().isAfter(moment(props?.selectedSession?.sessionDates[0]?.endingDateTime, 'x').local()))) {
      return true
    } else {
      return false
    }
  }



  return (
    <Grid
      style={{
        paddingLeft: isMobile ? "3px" : "5px",
        background: isMobile ? "#FBFAF9" : "",
      }}
    >



      {canMarkedCompleted() && <>
        <Typography className={classes.SectionHaeding} >
          {intl.formatMessage({ id: "Deliver Status" })}
        </Typography>
        <Grid container >
          <Grid
            item
            xs={12}>
            <FormControlLabel
              classes={{ label: classes.checkboxLabel }}
              control={<Checkbox disabled={props?.selectedSession?.isCompleted} color='primary' classes={{ colorPrimary: classes.Checkbox }} checked={props?.selectedSession?.isCompleted} onChange={handleMarkedCompleted} name="antoine" />}
              label={props?.selectedSession?.isCompleted ? "This session is delivered" : "Marked this session as completed"}
            />
          </Grid>
        </Grid></>}



      <Grid
        container
        style={{
          padding: "20px 0px",
        }}
        spacing={2}
        className={classes.hideScrollBar}
      >
        {participants?.length > 0 && participants?.map((participant) => {
          return (
            <Grid item xs={4} md={3} lg={3} style={{ maxWidth: "140px" }}>
              <Cards
                username={participant?.username}
                image={participant?.pictureUrl}
                subTitle={true}
                containerStyle={classes.containerStyle}
                icon={true}
                root={
                  isMobile ? classes.friendsRootMobile : classes.friendsRoot
                }
              />
            </Grid>
          );
        })
         
        }
      </Grid>
 
      <Typography className={classes.SectionHaeding}>
        {intl.formatMessage({ id: "selectedDate" })}
      </Typography>
      <Grid container style={{
        padding: "20px 0px",

      }}
        spacing={2} className={classes.hideScrollBar}>
        {/* <Grid container  > */}
        {props?.selectedSession?.sessionDates?.length > 0 && props?.selectedSession?.sessionDates?.map((n, i) => {
          return (
            <Grid
              item
              container
              item xs={4} md={3} lg={3}
              key={i}
              style={{
                minWidth: '140px',
              }}
            >
              <div style={{
                width: "100%",
                background: "#fff",
                marginBottom: "10px",
                boxShadow: "0px 4px 12px rgba(173, 173, 173, 0.05)",
                borderRadius: "11px",
                height: "70px",
                color: '#606060'
              }}>
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      paddingTop: "5px",

                    }}
                  >
                    {moment(n?.dateTime, "x").local().format('ddd')}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{ display: "flex", justifyContent: "center", paddingTop: "5px", }}
                  >
                    {moment(n?.dateTime, "x").local().format('DD MMM YYYY')}
                  </Grid>
                </Grid>
              </div>
            </Grid>
          );
        })}
        {/* </Grid> */}

      </Grid>
      <Typography className={classes.SectionHaeding}>{"Time slot"}</Typography>

      <Grid container style={{ padding: "10px 0px 10px 0px" }}>
        <Typography
          className={classes.infoText}
          style={{ color: "#68C5E3" }}
          component="p"
        >
          {props?.selectedSession?.sessionDates?.length > 0 && props?.selectedSession?.sessionDates?.map((n, i) => {
            return (
              <>
                {moment(n?.dateTime, "x").local().format('ddd (DD MMM YYYY)')}
                {/* {moment(n).format("ddd (DD MMM YYYY) ")}
                {days.length - 1 > i && ` , `} */}
              </>
            );
          })}
        </Typography>

        <Typography className={classes.infoText} component="p">
          {days?.length === 1
            ? intl.formatMessage({ id: "isSelectedFor" })
            : intl.formatMessage({ id: "areSelectedFor" })}
        </Typography>

        <Typography className={classes.infoText} component="p">
          {intl.formatMessage({ id: "betweenLocal" })}
        </Typography>

        <Typography
          className={classes.infoText}
          style={{ color: "#68C5E3" }}
          component="p"
        >
          {startTime} {intl.formatMessage({ id: "to" })} {endTime}
        </Typography>
        <Typography className={classes.infoText} component="p">
          {intl.formatMessage({ id: "localTime" })}
        </Typography>
      </Grid>

      {isSessionTime() && <Grid container style={{ padding: "10px 0px 10px 0px" }}>
        <Grid
          item
          xs={12}>
          <TextField
            disabled={true}
            id="standard-multiline-static"
            fullWidth
            value={(moment().add(15, 'minutes').isAfter(moment(props?.selectedSession?.sessionDates[0]?.dateTime, 'x').local())) ? props?.selectedSession?.rtmpUrl : 'Will be available before 15 min of session start'}
            className={classes.textField}
            margin="normal"
            label={'RTMP url'}
          />
        </Grid>

        <Grid
          item
          xs={12}>
          <TextField
            disabled={true}
            id="standard-multiline-static"
            fullWidth
            value={(moment().add(15, 'minutes').isAfter(moment(props?.selectedSession?.sessionDates[0]?.dateTime, 'x').local())) ? props?.selectedSession?.streamingKey : 'Will be available before 15 min  of session start'}
            className={classes.textField}
            margin="normal"
            label={'Streaming Key'}
          />
        </Grid>
      </Grid>}


    </Grid>
  );
}

export default Schedule;

const useStyles = makeStyles((theme) => ({
  friendsRoot: {
    width: "130px",
    maxHeight: "260px",
    borderRadius: "11px",
    backgroundColor: "#FFF",
    boxShadow: " 0px 4px 4px rgba(0, 0, 0, 0.05)",
  },
  friendsRootMobile: {
    width: "100px",
    maxHeight: "260px",
    borderRadius: "11px",
    backgroundColor: "#FFF",
    boxShadow: " 0px 4px 4px rgba(0, 0, 0, 0.05)",
  },
  addFriedRoot: {
    width: "80px",
    maxHeight: "260px",
    backgroundColor: "#FBAE02",
    height: "8rem",
    boxShadow: "0px 4px 12px rgba(173, 173, 173, 0.05)",
    borderRadius: "11px",
  },
  daysRoot: {
    marginBottom: "14px",
    width: "77px",
    maxHeight: "260px",
    borderRadius: "11px",
    boxShadow: " 0px 4px 4px rgba(0, 0, 0, 0.05)",
  },
  selectedDaysRoot: {
    marginBottom: "14px",
    width: "77px",
    maxHeight: "260px",
    borderRadius: "11px",
    backgroundColor: "#FBAE02",
    boxShadow: " 0px 4px 4px rgba(0, 0, 0, 0.05)",
  },
  addFriendTitle: {
    fontFamily: theme.fontFamily.Reef,
    fontStyle: "normal",
    fontSize: "25px",
    paddingTop: "39px",
    paddingBottom: "25px",
    textAlign: "center",
    color: "#fff",
  },
  title: {
    fontFamily: theme.fontFamily.Reef,
    fontStyle: "normal",
    fontSize: "25px",
    textAlign: "center",
    color: "#fff",
  },
  unSelectedDaysTitle: {
    fontFamily: theme.fontFamily.Reef,
    fontStyle: "normal",
    fontSize: "25px",
    textAlign: "center",
    color: "#3C4A6B",
  },
  daysTitle: {
    fontFamily: theme.fontFamily.Reef,
    fontStyle: "normal",
    fontSize: "25px",
    textAlign: "center",
    color: "#fff",
  },
  subTitle: {
    fontFamily: theme.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: "900",
    fontSize: "10px",
    lineHeight: "14px",
    textAlign: "center",
  },
  containerStyle: {
    padding: "0px",
    height: "8rem",
    justifyContent: "space-around",
  },
  containerStyleAdd: {
    padding: "0px",
    height: "8rem",
    width: "80px",
    boxShadow: "0px 4px 12px rgba(173, 173, 173, 0.05)",
    borderRadius: "11px",
    justifyContent: "space-around",
  },
  daysContainerStyle: {
    padding: "0px",
    height: "6rem",
    justifyContent: "space-around",
  },
  hideScrollBar: {
    flexWrap: "unset",
    overflowX: "auto",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  timeHeader: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "16px",
    color: "#192247",
  },
  SectionHaeding: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: "500",
    fontsize: "14px",
    lineHeight: "20px",
    color: "#606060",
    marginTop: "10px",
  },
  SectionHaeding1: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: "normal",
    fontze: "17px",
    lineHeight: "20px",
    color: "#192247",
  },

  infoText: {
    paddingLeft: " 5px",
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: "normal",
    fontze: "17px",
    lineHeight: "20px",
    color: "#606060",
  },

  boxShadows: {
    background: "#fff",

    marginTop: "10px",
    marginBottom: "20px",
    boxShadow: "0px 4px 12px rgba(173, 173, 173, 0.05)",
    borderRadius: "11px",
    padding: "20px",
  },
  appBar: {
    backgroundColor: "#FBFAF9",
    width: "100%",
    boxShadow: "none",
    display: "flex",
    marginBottom: "20px",
    justifyContent: "space-between",
  },
  headerGrid: {
    background: "#FBF1E0",
    height: "2.75em",
    fontSize: "20px",
    boxShadow: `inset 2px 2px 4px rgba(0, 65, 72, 0.05)`,
    borderRadius: `12px`,
  },
  selectedBtn: {
    background: "linear-gradient(180deg, #FFC000 -13.24%, #F28705 100%)",
    width: "100%",
    height: "2.750em",
    borderRadius: "11px",
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: 500,
    textTransform: "none",
    fontSize: "20px",
    lineHeight: "23px",
    color: "#fff",
    marginBottom: "70px",
  },
  subTitleStyle: {
    fontFamily: theme.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: "900",
    fontSize: "10px",
    lineHeight: "14px",
    textAlign: "center",
    color: "#fff",
  },
  unselectedBtn: {
    width: "100%",
    height: "2.750em",
    borderRadius: "11px",
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: 500,
    textTransform: "none",
    fontSize: "20px",
    lineHeight: "23px",
    color: "#F8A303",
    marginBottom: "70px",
  },
  cssApply: {
    "& .picker-container": {
      cursor: "default",
      "& .picker-item": {
        color: "#FAAB02  !important",
      },
      "& .picker-item-selected": {
        fontFamily: theme.fontFamily.Roboto,
        fontStyle: "normal",
        fontWeight: 900,
        fontSize: "30px",
        lineHeight: " 42px",
        color: "#FAAB02 !important",
      },
    },
  },
  textField: {
    "& .MuiInput-root": {
      fontFamily: theme.fontFamily.Roboto,
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "16px",
      letterSpacing: "0.015em",
      lineHeight: "23px",
      color: "#606060",
    },
    "& .MuiInputBase-multiline": {
      padding: `10px 10px`,
    },
    "& .MuiInputLabel-root": {
      fontFamily: theme.fontFamily.Roboto,
      fontWeight: 500,
      fontSize: "20px",
      lineHeight: "20px",
      color: "#606060",
      "&::after": {
        fontFamily: theme.fontFamily.Roboto,
        fontWeight: 500,
        fontSize: "20px",
        lineHeight: "20px",
        color: "#606060",
      },
    },

    "& .MuiInput-underline:before": {
      borderBottom: "1px solid rgba(168, 182, 200, 0.3)",
      borderTop: "none",
    },
    "& .MuiInput-underline::after": {
      borderBottom: "1px solid rgba(168, 182, 200, 0.3)",
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled)::before": {
      borderBottom: "1px solid rgba(168, 182, 200, 0.3)",
    },
  },
  nameTextField: {
    "& .MuiInput-root": {
      fontFamily: theme.fontFamily.Roboto,
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "20px",
      lineHeight: "23px",
      color: "#000000",
    },
    "& .MuiInputBase-multiline": {
      padding: `10px 10px`,
    },
    "& .MuiInputLabel-root": {
      fontFamily: theme.fontFamily.Roboto,
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "17px",
      lineHeight: "20px",
      color: "#9E9393",
      "&::after": {
        fontFamily: theme.fontFamily.Roboto,
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "17px",
        lineHeight: "20px",
        color: "#9E9393",
      },
    },

    "& .MuiInput-underline:before": {
      borderBottom: "1px solid rgba(168, 182, 200, 0.3)",
      borderTop: "none",
    },
    "& .MuiInput-underline::after": {
      borderBottom: "1px solid rgba(168, 182, 200, 0.3)",
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled)::before": {
      borderBottom: "1px solid rgba(168, 182, 200, 0.3)",
    },
  },
  Checkbox: {
    '&.Mui-checked': {
      color: '#09A9BB'
    }
  },
  checkboxLabel: {
    color: '#606060'

  }
}));

const CustomSlider = withStyles({
  root: {
    color: "rgba(168, 182, 200, 0.3)",
    padding: "13px 0",
    cursor: "default",
  },
  thumb: {
    borderRadius: "50px",
    color: "#fff",
    height: "40px",
    width: "65px",
    backgroundColor: "#FAAA02",
    marginTop: -17,
    marginLeft: -13,
    boxShadow: "#ebebeb 0 2px 2px",
    "&:focus, &:hover, &$active": {
      boxShadow: "#ccc 0 2px 3px 1px",
    },
    "& .bar": {
      height: 9,
      width: 1,
      backgroundColor: "rgba(168, 182, 200, 0.3)",
      marginLeft: 1,
      marginRight: 1,
    },
  },
  active: {},
  track: {
    height: 3,
  },
  rail: {
    color: "#d8d8d8",
    opacity: 1,
    height: 3,
  },
})(Slider);
