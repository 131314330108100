import React, { useEffect } from "react";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import EditProfileInfoMobile from "../../containers/Mobile/Profile/EditProfileInfo";
import EditProfileInfoWebContainer from "../../containers/Web/Profile/EditProfileInfo";
import { getCurrentLoggedInUser, getUsersFriends } from "../../actions/user";
import { useDispatch, useSelector } from "react-redux";

function EditProfilePage(props) {
  const theme = useTheme();
  let dispatch = useDispatch();

  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  let authReducerState = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(getCurrentLoggedInUser({ token: authReducerState?.token }));
    dispatch(getUsersFriends(authReducerState?.token));
  }, []);
  return (
    <>
      {isDesktop && <EditProfileInfoWebContainer />}
      {isMobile && !isDesktop && <EditProfileInfoMobile />}
    </>
  );
}

export default EditProfilePage;
