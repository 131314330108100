import React from "react";
import { useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import SubscriptionPlanComponent from "../../../components/More/SubscriptionPlan";
import More from "../../../containers/Web/More";

const SubscriptionPlan = () => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <>
      {isDesktop && <More />}
      {isMobile && !isDesktop && <SubscriptionPlanComponent />}
    </>
  );
};

export default SubscriptionPlan;
