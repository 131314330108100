import React, { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import { TextField, Grid, Button, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { convertToRaw, EditorState, ContentState, convertFromHTML } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import Editor from '../../../components/Editor'

import saveBtnEditProfileWeb from '../../../assets/img/saveBtnEditProfileWeb.svg'
import { updateUserProfile } from '../../../actions/user'
import './profileInfoFormWeb.scss'
function ProfileInfoFormWeb(props) {
    const dispatch = useDispatch();
    const classes = useStyles();
    const history = useHistory();

    const user = useSelector(state => state?.user?.user);
    const authReducerState = useSelector(state => state.auth);
    const [error, setError] = useState(false)
    const [html, setHtml] = useState('')


    const [editorState, setEditorState] = React.useState(EditorState.createEmpty());

    const { register, errors, handleSubmit, setValue } = useForm();
    const onSubmit = async (data) => {
        const role = authReducerState?.user?.role
        const htmlContent = role !== 'Regular' && await escape(draftToHtml(convertToRaw(editorState.getCurrentContent())))
        if (!(editorState.getCurrentContent().hasText()) && (role !== 'Regular')) {
            return setError(true)
        }
        const data1 = role !== 'Regular' ? { ...data, description: htmlContent } : data


        const res = await dispatch(updateUserProfile(data1, authReducerState?.token));
        if (res && res === 'success') {
            history.push('/profile')
        } else {
            return
        }
    };

    useEffect(() => {
        setValue("firstName", user?.firstName)
        setValue("lastName", user?.lastName)
        setValue("email", user?.email)
        setValue("title", user?.title)
        setValue("city", user?.city)
        setValue("websiteUrl", user?.websiteUrl)
        setValue("facebookUrl", user?.facebookUrl)
        setValue("instagramUrl", user?.instagramUrl)
        setValue("youtubeChannelUrl", user?.youtubeChannelUrl)
        setValue("description", user?.description)
        if (user?.id && authReducerState?.user?.role !== 'Regular') {
            const blocksFromHtml = convertFromHTML(unescape(user?.description));
            const { contentBlocks, entityMap } = blocksFromHtml;
            const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
            const editorState = EditorState.createWithContent(contentState);
            setEditorState(editorState);
        }

    }, [user])


    return (

        <form onSubmit={handleSubmit(onSubmit)}>

            <div className={classes.profileInfoWebTextField}>
                <Grid container direction='row'>
                    <Grid item xs={8}>
                        <TextField
                            className={classes.profileInfoWebTextField}
                            inputRef={register({
                                required: "first name is required!"
                            })}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            label="First Name"
                            name="firstName"
                            helperText={errors.firstName && errors.firstName.message}
                            error={!!errors.firstName}
                            type="text"
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <Button type='submit'>
                            <img src={saveBtnEditProfileWeb} />
                        </Button>
                    </Grid>
                </Grid>

                <Grid container direction='row'>
                    <Grid item xs={12}>
                        <TextField
                            className={classes.profileInfoWebTextField}
                            inputRef={register({
                                required: "last name is required!"
                            })}
                            InputLabelProps={{
                                shrink: true,
                            }}

                            label="Last Name"
                            name="lastName"
                            helperText={errors.lastName && errors.lastName.message}
                            error={!!errors.lastName}
                            type="text"
                        />
                    </Grid>
                </Grid>

                <Grid container direction='row'>
                    <Grid item xs={12}>
                        <TextField
                            className={classes.profileInfoWebTextField}
                            inputRef={register({
                                required: user?.role !== 'Regular' ? "title is required!" : false
                            })}
                            InputLabelProps={{
                                shrink: true,
                            }}

                            label="Title"
                            name="title"
                            helperText={errors.title && errors.title.message}
                            error={!!errors.title}
                            type="text"
                        />
                    </Grid>

                </Grid>

                <TextField
                    className={classes.profileInfoWebTextField}
                    inputRef={register({
                        required: user?.role !== 'Regular' ? "city is required!" : false
                    })}
                    InputLabelProps={{
                        shrink: true,
                    }}

                    label="City"
                    name="city"
                    helperText={errors.city && errors.city.message}
                    error={!!errors.city}
                    type="text"
                />
                <TextField
                    className={classes.profileInfoWebTextField}
                    inputRef={register({
                        required: "email is required!",
                        pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                            message: "Invalid email address!"
                        }
                    })}

                    InputLabelProps={{
                        shrink: true,
                    }}
                    label="Email Address"
                    name="email"
                    helperText={errors.email && errors.email.message}
                    error={!!errors.email}
                    disabled={true}
                    type="text"
                />
                {user?.role === 'Instructor' &&
                    <>
                        <TextField
                            className={classes.profileInfoWebTextField}
                            inputRef={register({
                                pattern: {
                                    value: /(([\w]+:)?\/\/)?(([\d\w]|%[a-fA-f\d]{2,2})+(:([\d\w]|%[a-fA-f\d]{2,2})+)?@)?([\d\w][-\d\w]{0,253}[\d\w]\.)+[\w]{2,63}(:[\d]+)?(\/([-+_~.\d\w]|%[a-fA-f\d]{2,2})*)*(\?(&?([-+_~.\d\w]|%[a-fA-f\d]{2,2})=?)*)?(#([-+_~.\d\w]|%[a-fA-f\d]{2,2})*)?/,
                                    message: "Invalid url address!"
                                }

                            })}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            label="Website URL"
                            name="websiteUrl"
                            helperText={errors.websiteUrl && errors.websiteUrl.message}
                            error={!!errors.websiteUrl}
                            type="text"
                        />
                        <TextField
                            className={classes.profileInfoWebTextField}
                            inputRef={register({
                                pattern: {
                                    value: /^(https?:\/\/){0,1}(www\.){0,1}facebook\.com/,
                                    message: "Invalid facebook url!"
                                }
                            })}

                            InputLabelProps={{
                                shrink: true,
                            }}
                            label="Facebook URL"
                            name="facebookUrl"
                            helperText={errors.facebookUrl && errors.facebookUrl.message}
                            error={!!errors.facebookUrl}
                            type="text"
                        />
                        <TextField
                            className={classes.profileInfoWebTextField}
                            inputRef={register({
                                pattern: {
                                    value: /(?:(?:http|https):\/\/)?(?:www\.)?(?:instagram\.com|instagr\.am)\/([A-Za-z0-9-_\.]+)/im,
                                    message: "Invalid instagram url!"
                                }

                            })}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            label="Instagram URL"
                            name="instagramUrl"
                            helperText={errors.instagramUrl && errors.instagramUrl.message}
                            error={!!errors.instagramUrl}
                            type="text"
                        />
                        <TextField
                            className={classes.profileInfoWebTextField}
                            inputRef={register({
                                pattern: {
                                    value: /(?:(?:http|https):\/\/)?((www\.)?youtube\.com|youtu\.?be)\/.+$/,
                                    message: "Invalid youtube url!"
                                }
                            })}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            label="Youtube Channel URL"
                            name="youtubeChannelUrl"
                            helperText={errors.youtubeChannelUrl && errors.youtubeChannelUrl.message}
                            error={!!errors.youtubeChannelUrl}
                            type="text"
                        />
                    </>
                }

                {

                    authReducerState?.user?.role !== 'Regular' ?
                        <>
                            <Editor editorState={editorState} placeholder={'Describe yourself...'} changeDescription={(des) => {
                                setError(false)
                                setEditorState(des)
                            }} />
                            {error && <Typography className={classes.textEditorError}>Description is required</Typography>}

                        </> :
                        <TextField
                            className={classes.profileInfoWebTextField}
                            name='description'
                            multiline
                            label="Description"

                            InputLabelProps={{
                                shrink: true,
                            }}
                            inputRef={register({
                                required: user?.role !== 'Regular' ? "description is required!" : false,
                            })}
                            helperText={errors.description && errors.description.message}
                            error={!!errors.description}
                        />
                }

            </div>
        </form>
    );
}

export default ProfileInfoFormWeb;

const useStyles = makeStyles(theme => ({
    profileInfoWebTextField: {
        width: '100%',
        marginBottom: '27px',
        '& .MuiInputLabel-root': {
            fontFamily: 'Roboto',
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: '18px',
            lineHeight: '21px',
            color: '#3c4a6b',
        },
        '& .MuiInputLabel-root::after': {
            fontFamily: 'Roboto',
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: '18px',
            lineHeight: '21px',
            color: '#3c4a6b',
        },
        '& .MuiInputBase-input': {
            fontFamily: 'Roboto',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '14px',
            lineHeight: '16px',
            color: '#7B92A6',
            opacity: 0.78,
            paddingTop: '15px',
        },
        '& .MuiInput-underline::before': {
            borderBottom: '1px solid #e9e9e9',
        },
        '& .MuiInput-underline::after': {
            borderBottom: '1px solid #e9e9e9',
        },
        '& .MuiInput-underline:hover:not(.Mui-disabled)::before': {
            borderBottom: '1px solid #e9e9e9',
        },
    },
    textEditorError: {
        fontSize: '0.75rem',
        marginTop: '3px',
        textAlign: 'left',
        fontFamily: 'Reef,Roboto',
        fontWeight: '400',
        lineHeight: 1.66,
        color: '#f44336'
    }
}))