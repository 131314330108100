import React, { useState } from "react";
import {
  Grid,
  Typography,
  Button,
  Box,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
  Modal,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import AppBar from "../../../components/AppBar/index";
import { useSelector } from "react-redux";
import { useTheme } from "@material-ui/core/styles";
import { useIntl } from "react-intl";
import StartBtn from "../../../assets/img/startBtn.svg";
import { useForm } from "react-hook-form";

import "date-fns";

import StartPage from "./Start";
import Picker from "react-scrollable-picker";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import SoloExerciseBackground from "../../../assets/img/soloExerciseBackground.png";

function SoloExercise(props) {
  const theme = useTheme();
  const intl = useIntl();
  const classes = useStyles();
  const [currentPage, setCurerrentPage] = useState("index");
  const [modalStyle] = useState(getModalStyle);
  const [category, setCategory] = React.useState("");

  const [valueGroups, setValueGroup] = useState({
    min: "00",
    sec: "10",
  });
  const [valueGroupsRep, setValueGroupRep] = useState({
    repitions: "1",
  });
  const [optionGroupsRep] = useState({
    repitions: [
      { value: "1", label: "1" },
      { value: "2", label: "2" },
      { value: "3", label: "3" },
      { value: "4", label: "4" },
      { value: "5", label: "5" },
    ],
  });

  const [preprationTime, setPreprationTime] = useState({
    min: "",
    sec: "",
  });
  const [intervalRestTime, setIntervalRestTime] = useState({
    min: "",
    sec: "",
  });
  const [exerciseDuration, setExerciseDuration] = useState({
    min: "",
    sec: "",
  });
  const [repititions, setRepititions] = useState({
    rep: "1",
  });

  const [optionGroups] = useState(getPickerData);
  function getPickerData() {
    let i;
    let min = [];
    for (i = 0; i < 60; i++) {
      min.push({
        value: i <= 9 ? `0${i}` : `${i}`,
        label: i <= 9 ? `0${i}` : `${i}`,
      });
    }
    return { min: [...min], sec: [...min] };
  }

  const { errors } = useForm();
  function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
      borderColor: theme.colors.white,
      borderRadius: "4px",
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
    };
  }

  const [openModel, setOpenModel] = useState(false);
  const [option, setOption] = useState("preprationTime");

  const handleChange = (name, value) => {
    option === "repititions"
      ? setValueGroupRep({ ...valueGroupsRep, [name]: value })
      : setValueGroup({ ...valueGroups, [name]: value });
  };

  const handleCloseModal = () => {
    setOpenModel(false);
  };
  const setData = () => {
    if (option === "preprationTime") {
      setPError(false);
      setPreprationTime({
        min: valueGroups.min,
        sec: valueGroups.sec,
      });
    } else if (option === "exerciseDuration") {
      setEError(false);
      setExerciseDuration({
        min: valueGroups.min,
        sec: valueGroups.sec,
      });
    } else if (option === "intervalRestTime") {
      setIError(false);
      setIntervalRestTime({
        min: valueGroups.min,
        sec: valueGroups.sec,
      });
    } else if (option === "repititions") {
      setRepititions({
        rep: valueGroupsRep.repitions,
      });
    }

    setOption("");
    handleCloseModal();
  };
  const usernameModelBody = (
    <div style={modalStyle} className={classes.paper}>
      <div
        style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
      />
      {option === "repititions" ? (
        <Grid container justify="center">
          <Grid item>
            <Typography className={classes.modalDesc}>
              {intl.formatMessage({ id: "repititions" })}
            </Typography>
          </Grid>
        </Grid>
      ) : (
        <Grid container justify="center">
          <Grid item>
            <Typography className={classes.modalDesc}>
              {intl.formatMessage({ id: "Min" })}
            </Typography>
          </Grid>
          <Grid item>
            <Typography className={classes.modalDesc}>
              {intl.formatMessage({ id: "Sec" })}
            </Typography>
          </Grid>
        </Grid>
      )}
      <Picker
        optionGroups={option === "repititions" ? optionGroupsRep : optionGroups}
        valueGroups={option === "repititions" ? valueGroupsRep : valueGroups}
        onChange={handleChange}
      />
      <Button className={classes.modelBtn} onClick={() => setData()}>
        {intl.formatMessage({ id: "set" })}
      </Button>
    </div>
  );

  const [cError, setCError] = useState(false);
  const [eError, setEError] = useState(false);
  const [pError, setPError] = useState(false);
  const [iError, setIError] = useState(false);

  const handleChangePage = (value) => {
    if (value === "start") {
      let cError = category === "";
      let pError = preprationTime.min === "" && preprationTime.sec === "";
      let eError = exerciseDuration.min === "" && exerciseDuration.sec === "";
      let iError = intervalRestTime.min === "" && intervalRestTime.sec === "";
      if (!cError && !eError && !pError) {
        setCurerrentPage(value);
      } else {
        setCError(cError);
        setPError(pError);
        setEError(eError);
        setIError(iError);
      }
    } else {
      setCurerrentPage(value);
    }
  };

  const handleClick = (value) => {
    setOpenModel(true);
    setOption(value);
  };

  let categoriesList = useSelector((state) => state?.courses?.categoriesList);

  const handleSelect = (event) => {
    setCError(false);
    setCategory(event.target.value);
  };

  return (
    <Grid
      container
      xs={12}
      className={
        currentPage === "index" ? classes.rootIndex : classes.rootStart
      }
    >
      <Grid container>
        <Box component="span" p={0} mx={5}>
          <AppBar
            appBarConfig={{
              show: true,
              backgroundColor: "#244562",
              arrowColor: "white",
              bodyColor: "white",
              text: intl.formatMessage({ id: "soloExercise" }),
              backAction: props.handleBack,
            }}
          />
        </Box>
      </Grid>
      {currentPage === "index" ? (
        <Grid
          container
          style={{
            overflow: "hidden",
            paddingLeft: "5%",
            marginBottom: "5px",
            marginTop: "5px",
          }}
        >
          <Grid item xs={3}>
            <Grid
              container
              direction="column"
              spacing={2}
              style={{
                minHeight: "80vh",
                justifyContent: "space-between",
              }}
            >
              <Grid container justify="space-around">
                <FormControl error={errors.role} className={classes.inputField}>
                  <Select
                    className={classes.selectCategory}
                    style={{ width: "100%" }}
                    classes={{
                      iconFilled: classes.iconSelect,
                      icon: classes.iconSelect,
                    }}
                    value={category}
                    onChange={handleSelect}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    <MenuItem value="">
                      {intl.formatMessage({ id: "category" })}
                    </MenuItem>
                    {categoriesList?.map((category, ind) => {
                      return (
                        <MenuItem value={category?.name} key={ind}>
                          {category?.name}
                        </MenuItem>
                      );
                    })}
                  </Select>

                  <FormHelperText
                    style={{ color: "white", textAlign: "center" }}
                  >
                    {cError && intl.formatMessage({ id: "selectCategory" })}
                  </FormHelperText>
                </FormControl>
              </Grid>

              <Grid container>
                <Grid
                  container
                  direction="column"
                  justify="space-around"
                  className={classes.gridStyle}
                  onClick={() => {
                    handleClick("preprationTime");
                  }}
                >
                  <Grid item>
                    <Typography
                      style={{
                        color:
                          option === "preprationTime"
                            ? "#09A9BB"
                            : preprationTime.min === "" &&
                              preprationTime.sec === ""
                              ? "#C4C4C4"
                              : "#FFF",
                      }}
                      className={classes.bodyTitle}
                    >
                      <Grid container justify="center">
                        <Grid item>
                          {intl.formatMessage({
                            id: "preprationTime",
                          })}
                        </Grid>
                        <Grid item>
                          <NavigateNextIcon style={{ fontSize: 30 }} />
                        </Grid>
                      </Grid>
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      style={{
                        color:
                          option === "preprationTime"
                            ? "#09A9BB"
                            : preprationTime.min === "" &&
                              preprationTime.sec === ""
                              ? "#C4C4C4"
                              : "#FFF",
                      }}
                      className={classes.bodySubtitle}
                    >
                      {preprationTime.min === "" && preprationTime.sec === ""
                        ? intl.formatMessage({ id: "setDuration" })
                        : `${preprationTime.min}:${preprationTime.sec}`}
                    </Typography>
                  </Grid>
                  {pError && (
                    <Grid item>
                      <Typography
                        style={{ color: "white" }}
                        className={classes.bodySubtitle}
                      >
                        {intl.formatMessage({
                          id: "requiredDuration",
                        })}
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              </Grid>

              <Grid container>
                <Grid
                  container
                  direction="column"
                  justify="space-around"
                  className={classes.gridStyle}
                  onClick={() => {
                    handleClick("exerciseDuration");
                  }}
                >
                  <Grid item>
                    <Typography
                      style={{
                        color:
                          option === "exerciseDuration"
                            ? "#09A9BB"
                            : exerciseDuration.min === "" &&
                              exerciseDuration.sec === ""
                              ? "#C4C4C4"
                              : "#FFF",
                      }}
                      className={classes.bodyTitle}
                    >
                      <Grid container justify="center">
                        <Grid item>
                          {intl.formatMessage({
                            id: "exerciseDuration",
                          })}
                        </Grid>
                        <Grid item>
                          <NavigateNextIcon style={{ fontSize: 30 }} />
                        </Grid>
                      </Grid>
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      style={{
                        color:
                          option === "exerciseDuration"
                            ? "#09A9BB"
                            : exerciseDuration.min === "" &&
                              exerciseDuration.sec === ""
                              ? "#C4C4C4"
                              : "#FFF",
                      }}
                      className={classes.bodySubtitle}
                    >
                      {exerciseDuration.min === "" &&
                        exerciseDuration.sec === ""
                        ? intl.formatMessage({ id: "setDuration" })
                        : `${exerciseDuration.min}:${exerciseDuration.sec}`}
                    </Typography>
                  </Grid>
                  {eError && (
                    <Grid item>
                      <Typography
                        style={{ color: "white" }}
                        className={classes.bodySubtitle}
                      >
                        {intl.formatMessage({
                          id: "requiredDuration",
                        })}
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              </Grid>

              <Grid container>
                <Grid
                  container
                  direction="column"
                  justify="space-around"
                  className={classes.gridStyle}
                  onClick={() => {
                    handleClick("intervalRestTime");
                  }}
                >
                  <Grid item>
                    <Typography
                      style={{
                        color:
                          option === "intervalRestTime"
                            ? "#09A9BB"
                            : intervalRestTime.min === "" &&
                              intervalRestTime.sec === ""
                              ? "#C4C4C4"
                              : "#FFF",
                      }}
                      className={classes.bodyTitle}
                    >
                      <Grid container justify="center">
                        <Grid item>
                          {intl.formatMessage({
                            id: "intervalRestTime",
                          })}
                        </Grid>
                        <Grid item>
                          <NavigateNextIcon style={{ fontSize: 30 }} />
                        </Grid>
                      </Grid>
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      style={{
                        color:
                          option === "intervalRestTime"
                            ? "#09A9BB"
                            : intervalRestTime.min === "" &&
                              intervalRestTime.sec === ""
                              ? "#C4C4C4"
                              : "#FFF",
                      }}
                      className={classes.bodySubtitle}
                    >
                      {intervalRestTime.min === "" &&
                        intervalRestTime.sec === ""
                        ? intl.formatMessage({ id: "setTime" })
                        : `${intervalRestTime.min}:${intervalRestTime.sec}`}
                    </Typography>
                  </Grid>
                  {iError && (
                    <Grid item>
                      <Typography
                        style={{ color: "white" }}
                        className={classes.bodySubtitle}
                      >
                        {intl.formatMessage({
                          id: "requiredRestTime",
                        })}
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              </Grid>

              <Grid container>
                <Grid
                  container
                  direction="column"
                  justify="space-around"
                  className={classes.gridStyle}
                  onClick={() => {
                    handleClick("repititions");
                  }}
                >
                  <Grid item>
                    <Typography
                      style={{
                        color:
                          option === "repititions"
                            ? "#09A9BB"
                            : repititions.rep === ""
                              ? "#C4C4C4"
                              : "#FFF",
                      }}
                      className={classes.bodyTitle}
                    >
                      <Grid container justify="center">
                        <Grid item>
                          {intl.formatMessage({ id: "repititions" })}
                        </Grid>
                        <Grid item>
                          <NavigateNextIcon style={{ fontSize: 30 }} />
                        </Grid>
                      </Grid>
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      style={{
                        color:
                          option === "repititions"
                            ? "#09A9BB"
                            : repititions.rep === ""
                              ? "#C4C4C4"
                              : "#FFF",
                      }}
                      className={classes.bodySubtitle}
                    >
                      {repititions.rep === ""
                        ? intl.formatMessage({ id: "setRepeats" })
                        : `${repititions.rep}`}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={8}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              alt=""
              src={StartBtn}
              onClick={() => handleChangePage("start")}
              className={classes.startImg}
            />
          </Grid>
        </Grid>
      ) : (
        <StartPage
          category={category}
          preprationTime={preprationTime}
          intervalRestTime={intervalRestTime}
          exerciseDuration={exerciseDuration}
          repititions={repititions}
          handleGoBack={handleChangePage}
        />
      )}
      <Modal
        disableEnforceFocus={true}
        open={openModel}
        onClose={handleCloseModal}
      >
        {usernameModelBody}
      </Modal>
    </Grid>
  );
}

export default SoloExercise;

const useStyles = makeStyles((theme) => ({
  rootIndex: {
    backgroundColor: "#244562",
    width: "100%",
    minHeight: "100vh",
  },
  rootStart: {
    background: `url(${SoloExerciseBackground})`,
    backgroundRepeat: "no-repeat",
    backgroundColor: "#244562",
    width: "100%",
    backgroundSize: "100% 100%",
    minHeight: "100vh",
  },
  gridStyle: {
    cursor: "pointer",
  },
  inputField: {
    width: "100%",
    background: "#36526C",
    borderRadius: "10px",
  },
  selectCategory: {
    background: "#36526C",
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontSize: "24px",
    lineHeight: "28px",
    textAlign: "center",
    height: "41px",
    width: "156px",

    borderRadius: "20px",
    fontWeight: 500,
    color: "#ffffff",
    paddingRight: "10px",
    "&.MuiInput-underline::before": {
      borderBottom: "none",
    },
    "&.MuiInput-underline:after": {
      borderBottom: "none",
    },
    "&.MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottom: "none",
    },
    "& .MuiSelect-icon": {
      color: "#ffffff !important",
      paddingRight: "10px",
    },
  },
  iconSelect: {
    color: "#ffffff",
    paddingRight: "10px",
    variant: "filled",
  },
  formControl: {
    color: theme.palette.background.paper,
    margin: theme.spacing(1),
    minWidth: "120px",
  },
  startImg: {

    width: "372.21px",
    height: "371.81px",
    cursor: "pointer",
  },

  paper: {
    position: "absolute",
    height: 330,
    maxWidth: 315,
    minWidth: 300,
    justifyContent: "center",
    backgroundColor: theme.palette.background.paper,
    outline: "none",
  },
  modalDesc: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "24px",
    lineHeight: "28px",
    color: "#3C4A6B",
    textAlign: "center",
    padding: theme.spacing(2, 4, 3),
  },
  modelBtn: {
    background: `linear-gradient(#09A9BB 1%,#09A9BB 100%)`,
    height: "73px",
    width: "100%",
    fontFamily: theme.fontFamily.Roboto,

    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "21px",
    marginTop: "37px",
    color: theme.colors.white,
  },
  bodyTitle: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "24px",
    lineHeight: "28px",
    textAlign: "center",
    margin: '10px 0px 10px 0px'

  },
  bodySubtitle: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "21px",
    textAlign: "center",
    marginBottom: "30px",

  },
  modelStyle: {
    borderColor: theme.colors.white,
    borderRadius: "4px",
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
  },
}));
