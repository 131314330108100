import React, { useEffect } from "react";
import { useParams, useHistory, useLocation, withRouter, Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { resetPassword } from "../../../actions/auth";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import InputAdornment from "@material-ui/core/InputAdornment";
import Ellipse from "../../../assets/Ellipse3.svg";
import RelateIcon from "../../../assets/Icons/RelateIcon.png";
import TopBar from "../../../components/AppBar";
import {
    Typography,
    TextField,
    CircularProgress,
    IconButton
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { useIntl } from "react-intl";

const useStyles = makeStyles(theme => ({
    inputField: {
        width: "100%",
        marginTop: '15px'
    },

    signInBtn: {
        background: "linear-gradient(186.43deg, #15AEBF 13.46%, #14D9D9 76.64%)",
        boxShadow: "0px 3px 6px rgba(18, 166, 166, 0.25)",
        borderRadius: "47px",
        width: "100%",
        height: "64px",
        fontFamily: theme.fontFamily.Roboto,
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "24px",
        lineHeight: "28px",
        letterSpacing: "0.09em",
        color: "#FFFFFF",
        textTransform: "none",
        margin: '40px auto',
        marginBottom: '20px',
        textAlign: 'center'
    },

    topRight: {
        width: "150px",
        height: "150px",
        float: 'right',

    },
    bottomLeft: {
        width: "50px",
        height: "50px",
        borderRadius: "100%",
        position: 'fixed',
        bottom: 30,
        marginLeft: "50px",
    },
    title: {
        fontFamily: theme.fontFamily.Reef,
        fontStyle: "normal",
        fontWeight: 900,
        fontSize: "32px",
        lineHeight: "44px",
        textAlign: 'center'
    },
    backTxt: {
        fontSize: '1.2rem',
        fontFamily: theme.fontFamily.Reef,
        fontWeight: 400,
        lineHeight: 1.5,
        textAlign: 'center',
        '&:hover': {
            textDecoration: 'underline',
        }

    }
}));

const ResetPassword = () => {
    let history = useHistory();
    let params = useParams();
    const classes = useStyles();
    const intl = useIntl();
    const { state } = useLocation();
    const dispatch = useDispatch();
    const authReducerState = useSelector(state => state.auth);
    const loading = useSelector((state) => state?.loader?.loader);
    const { register, errors, handleSubmit, watch } = useForm();
    const [values, setValues] = React.useState({
        password: "",
        showPassword: false,
        showConfirmPassword: false
    });
    const onSubmit = data => {
        dispatch(resetPassword({ password: data.newPassword, confirmPassword: data.confirmPassword, token: params.token, history }));
    };

    useEffect(() => {
        if (authReducerState.isAuthenticated) {
            history.push(state?.from ? state.from : "/dashboard");
        }
    }, [authReducerState.isAuthenticated]);

    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };

    return (
        <Grid container  >
            <Grid item xs={2} >
                <TopBar
                    appBarConfig={{
                        show: true,
                        text: ""
                    }}
                />
                <div
                    className={classes.bottomLeft}
                    style={{ backgroundColor: "#a6eae9" }}
                />

            </Grid>
            <Grid item xs={8} container justify='center' style={{ height: '100vh', alignItems: 'center', display: 'flex', alignContent: 'center', zIndex: 2 }}>
                <Grid item xs={12} sm={10} md={8} lg={5} xl={5} container justify='center'>
                    <Grid item xs={12} style={{ textAlign: 'center' }}>
                        <img src={RelateIcon} alt="logo" style={{
                            textAlign: 'center', paddingBottom: '15px', height: '94px'
                        }} />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h5" className={classes.title}>
                            {intl.formatMessage({ id: "resetPassword" })}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} >
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Grid container>
                                <Grid item xs={12} style={{ padding: '8px 8px' }}>

                                    <TextField
                                        className={classes.inputField}
                                        type={values.showPassword ? "text" : "password"}
                                        label={intl.formatMessage({ id: "newPassword" })}
                                        inputRef={register({
                                            required: intl.formatMessage({ id: "requiredPassword" }),
                                            minLength: {
                                                value: 5,
                                                message: intl.formatMessage({ id: "errorPassword" })
                                            }
                                        })}
                                        name="newPassword"
                                        error={!!errors.newPassword}
                                        helperText={errors.newPassword && errors.newPassword.message}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton onClick={handleClickShowPassword} edge="end">
                                                        {values.showPassword ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={12} style={{ padding: '7px 8px' }}>

                                    <TextField
                                        className={classes.inputField}
                                        type={values.showConfirmPassword ? "text" : "password"}
                                        label={intl.formatMessage({ id: "confirmPassword" })}
                                        inputRef={register({
                                            required: intl.formatMessage({ id: "requiredConfirmPassword" }),

                                            validate: (value) => value === watch('newPassword') || "Passwords don't match."
                                        })}
                                        name="confirmPassword"
                                        error={!!errors.confirmPassword}
                                        helperText={errors.confirmPassword && errors.confirmPassword.message}

                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton onClick={() => setValues({ ...values, showConfirmPassword: !values.showConfirmPassword })} edge="end">
                                                        {values.showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        className={classes.signInBtn}
                                        disabled={loading}
                                        onClick={() => handleSubmit()}
                                    >
                                        {loading ? (
                                            <CircularProgress size={15} color="primary" />
                                        ) : (
                                            intl.formatMessage({ id: "continue" })
                                        )}
                                    </Button>
                                </Grid>

                                <Grid item xs={12}>
                                    <Link to='/auth' style={{
                                        textDecoration: 'none',
                                        color: 'rgb(16, 85, 153)'
                                    }}>
                                        <Typography className={classes.backTxt}>{intl.formatMessage({ id: "backToLogin" })}</Typography>
                                    </Link>
                                </Grid>
                            </Grid>
                        </form>
                    </Grid></Grid>
            </Grid>
            <Grid item xs={2} >
                <img
                    src={Ellipse}
                    className={classes.topRight}
                    alt=""
                />
            </Grid>
        </Grid >
    );
};

export default withRouter(ResetPassword);

