import { useState, useEffect } from "react";
import {
    Grid,
    Button,
    Typography,
    TextField,
    CircularProgress
} from "@material-ui/core";
import {
    Search as SearchIcon
} from "@material-ui/icons";
import { useSelector, useDispatch } from "react-redux";
import { useIntl } from "react-intl";
import { makeStyles } from "@material-ui/styles";
import FriendInvitationCard from "./FriendInvitationCard";
import { addFriendsInChallenge } from "../../actions/challenges";
import { useParams } from 'react-router-dom'
import {
    searchUsers,
} from "../../actions/userRelations";

function InviteFriends(props) {
    const intl = useIntl();
    const dispatch = useDispatch();
    const params = useParams();
    const classes = useStyles();

    const [friendsArr, setFriendsArr] = useState([]);
    const [friendsArrLocal, setFriendsArrLocal] = useState([]);
    const loader = useSelector((state) => state?.loader?.loader);
    const challengeDetail = useSelector(state => state?.challenges?.challengeDetail)
    const [searchTerm, setSearchTerm] = useState("");
    const auth = useSelector(state => state?.auth)
    const searchedFriends = useSelector(
        (state) => state?.userRelations?.searchUsers
    );

    useEffect(() => {
        if (challengeDetail?.invitations?.length > 0 || challengeDetail?.challengeParticipants?.length > 0 || friendsArrLocal?.length > 0) {
            let friendsArrLocally = searchedFriends.filter(function (friend) {
                return !challengeDetail?.challengeParticipants.some(function (participant) {
                    return friend?.id === participant?.id;
                });
            })?.filter(function (friend) {
                return !friendsArrLocal?.some(function (participant) {
                    return friend?.id === participant?.id;
                });
            })?.filter(function (friend) {
                return !challengeDetail?.invitations.some(function (invitation) {
                    return friend?.id === invitation?.invitationReceiverId;
                });
            })


            let challengeInvitedLocal = challengeDetail?.invitations?.map(friend => {
                return {
                    ...friend?.invitationReceivers,
                    status: 'invited',
                }
            })

            let challengeParticipantsLocal = challengeDetail?.challengeParticipants?.map(friend => {
                if (!friend?.self) {
                    return {
                        ...friend,
                        status: 'accepted',
                    }
                }
            }).filter(Boolean)

            let selected = [...friendsArrLocal, ...challengeParticipantsLocal, ...challengeInvitedLocal].filter(function (friend) {
                return searchedFriends?.some(function (participant) {
                    return participant?.id === friend?.id; // assumes unique id
                });
            })

            setFriendsArr([...friendsArrLocally, ...selected]);

        } else {
            setFriendsArr([...searchedFriends]);
        }
    }, [searchedFriends]);


    const invitationHandler = (status, participant) => {
        if (status === "sent") {
            let filterFriends = friendsArr?.map((friend) => {
                if (friend?.id === participant?.id) {
                    let localFriend = {
                        ...friend,
                        status: "sent",
                    };
                    setFriendsArrLocal([...friendsArrLocal, localFriend])
                    return localFriend
                }
                return friend;
            });

            setFriendsArr([...filterFriends]);
        }
        if (status === "cancel") {
            let filterFriends = friendsArr?.map((friend) => {
                if (friend?.id === participant?.id) {
                    return {
                        ...friend,
                        status: null,
                    };
                }
                return friend;
            });
            let filterFriendsLocal = friendsArrLocal?.filter((friend) => (friend?.id !== participant?.id))
            setFriendsArr([...filterFriends]);
            setFriendsArrLocal(filterFriendsLocal);

        }
    };


    const handleAddParticipants = () => {
        let participantsToBeAdded = [];

        participantsToBeAdded = friendsArr.length > 0 &&
            friendsArr
                .map((friend) => {
                    if (friend.status === "sent") {
                        return friend?.id
                    }
                })
                .filter(Boolean);
        if (participantsToBeAdded?.length > 0) {
            dispatch(addFriendsInChallenge({
                token: auth?.token, data: {
                    challengeOrSessionId: params?.id,
                    type: "Challenge",
                    userIds: participantsToBeAdded
                },
            }));
            return props.closeModal(false)
        }
        return props.closeModal(false)

    };


    const handleSearch = () => {
        dispatch(searchUsers({ token: auth?.token, searchTerm }));
    };

    return (
        <Grid container>
            <Grid item xs={12}>
                <Typography style={{ color: '#09A9BB', padding: '5px 5px 0px 5px' }}>Search for specific people click on SEARCH {<SearchIcon />} to populate all available users</Typography>
            </Grid>
            <Grid item xs={12} style={{ padding: '0px 5px 0px 5px' }}>
                <TextField
                    className={classes.homeSearchField}
                    type="Search"
                    style={{ width: "100%" }}
                    placeholder="Search..."
                    onChange={(e) => setSearchTerm(e.target.value)}
                    onKeyPress={(e) => {
                        (e.which === 13 || e.keyCode === 13) && handleSearch();
                    }}
                    name="search"
                    InputProps={{
                        endAdornment: (
                            <SearchIcon onClick={handleSearch} style={{ cursor: 'pointer' }} />
                        ),
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <Grid container>
                    {loader ? <Grid item xs={12} style={{ textAlign: 'center' }}><CircularProgress style={{ color: '#F28705' }} size={20} /></Grid> : friendsArr.length > 0 ? (
                        <Grid container item xs={12} className={classes.container}>
                            {friendsArr?.map((friend) => {
                                return (
                                    <Grid item xs={12} key={friend?.id}>
                                        <FriendInvitationCard
                                            invitation={invitationHandler}
                                            friend={friend}
                                        />
                                    </Grid>
                                );
                            })}
                        </Grid>
                    ) : (
                        <Grid item xs={12}>
                            <Typography className={classes.noRecord}>
                                No Record Found
                            </Typography>
                        </Grid>
                    )}

                    <Grid item xs={12} md={12} className={classes.top}>
                        <Button

                            className={classes.completeBtn}
                            onClick={handleAddParticipants}
                        >
                            {intl.formatMessage({ id: "invite" })}
                        </Button>
                    </Grid>
                </Grid>


            </Grid>
        </Grid>
    );
}

export default InviteFriends;

const useStyles = makeStyles((theme) => ({
    container: {
        padding: '0px 20px 0px 5px',
        height: '245px',
        overflow: 'auto',
        "&::-webkit-scrollbar": {
            display: "none"
        },
        display: 'flex',
    },
    noRecord: {
        color: "#DFDFDF",
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "16px",

        textAlign: "center",
        height: '272px',
        lineHeight: '272px'

    },
    completeBtn: {
        width: "100%",
        height: "3.0em",
        background: "linear-gradient(180deg, #FFC000 -13.24%, #F28705 100%)",
        fontFamily: theme.fontFamily.Roboto,
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "20px",
        lineHeight: "23px",
        color: "#FFFFFF",
        textTransform: "none",
    },
    homeSearchField: {
        "& .MuiInputBase-root": {
            backgroundColor: '#dce8f2',
            color: '#A9A9A9',
            fontFamily: theme.fontFamily.Roboto,
            fontStyle: "normal",
            fontWeight: 500,
            fontSize: "18px",
            lineHeight: "21px",
            padding: "10px",
            minHeight: "64px",
            alignSelf: "center",
            marginTop: "12px",
            marginBottom: "20px",
            height: "58px",
            width: "100%",
            borderRadius: "10px",
        },
        "& .MuiBadge-colorPrimary": {
            backgroundColor: "#F9A702;",
        },

        "& .MuiInput-underline:before": {
            border: "0px solid #FBF1E0",
        },
        "& .MuiInput-underline::after": {
            border: "0px solid #FBF1E0",
        },
        "& .MuiInput-underline:hover:not(.Mui-disabled)::before": {
            border: "0px solid #FBF1E0",
        },
    },
}));
