import  { useEffect, useState } from "react";
import moment from "moment";
import ListChatCard from "./ListChatCard";
import { makeStyles } from "@material-ui/styles";
import { Grid, Typography, CircularProgress } from "@material-ui/core";
import { Panorama } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import {  selectedChatUser } from "../../../actions/chat";
import { useHistory, useLocation } from "react-router-dom";

function ListChats(props) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { friendInfo } = useLocation();
  const history = useHistory();
  const selectedId = useSelector((state) => state.chat?.selectedRoomId);
  const chatsReducer = useSelector((state) => state?.chat?.chats);
  const chatLoader = useSelector(state => state?.chat?.chatLoader);
  const [activeChat, setActiveChat] = useState();
  const [chats, setChats] = useState([]);
  

  useEffect(() => {
    if (friendInfo) {
      const filterChat = chatsReducer?.filter((chat) => {
        if (chat?.id === friendInfo?.id) {
          return chat;
        }
      });

      filterChat?.length > 0
        ? setChats(chatsReducer)
        : setChats([friendInfo, ...chatsReducer]);
    } else {
      setChats(chatsReducer);
    }
  }, [chatsReducer]);



  const chatCardClickHandler = (e, chat) => {
    e.preventDefault();
    setActiveChat(chat?.id);
    dispatch({ type: "SELECTED_ROOM_ID", payload: chat.id });
    dispatch(selectedChatUser(chat?.user));
    return history.push({ pathname: `/more/inbox/messages/${chat?.id}`, friendInfo })
  };
  let periods = {
    month: 30 * 24 * 60 * 60 * 1000,
    week: 7 * 24 * 60 * 60 * 1000,
    day: 24 * 60 * 60 * 1000,
    hour: 60 * 60 * 1000,
    minute: 60 * 1000,
  };
  function formatTime(timeCreated) {
    var diff = Date.now() - timeCreated;

    if (diff > periods.month) {
      return Math.floor(diff / periods.month) + " m";
    } else if (diff > periods.week) {
      return Math.floor(diff / periods.week) + " w";
    } else if (diff > periods.day) {
      return Math.floor(diff / periods.day) + " d";
    } else if (diff > periods.hour) {
      return Math.floor(diff / periods.hour) + " hr";
    } else if (diff > periods.minute) {
      return Math.floor(diff / periods.minute) + " min";
    }
    return "Just now";
  }



  return (
    <Grid container className={classes.chatsCard} >
      {chatLoader ? <Grid item xs={12} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: "100vh" }}>
        <CircularProgress size={20} style={{ color: '#09A9BB' }} />
      </Grid> :
        chats?.length > 0 ? (
          chats.map((chat) => {
            return (
              <Grid
                item
                lg={12}
                xs={12}
                onClick={(e) => chatCardClickHandler(e, chat)}
                key={chat?.id}
              >
                <ListChatCard
                  status={chat?.id === selectedId ? "active" : "notActive"}
                  msgUserName={chat?.user?.name}
                  msgDescription={
                    chat?.lastMessage?.messageType === "Image" ? (
                      <Panorama />
                    ) : chat?.lastMessage?.message?.length > 20 ? (
                      chat?.lastMessage?.message?.slice(0, 20)
                    ) : (
                          chat?.lastMessage?.message
                        )
                  }
                  timeAgo={formatTime(
                    moment(chat?.lastMessage?.createdAt).valueOf()
                  )}
                  unReadMessages={
                    chat?.id === activeChat ? 0 : chat?.unreadMessages?.length
                  }
                  image={chat?.user?.pictureUrl}
                />
              </Grid>
            );
          })
        ) : (
            <Grid item xs={12}>
              <Typography className={classes.noContent}>No chat yet</Typography>
            </Grid>
          )}
    </Grid>
  );
}

export default ListChats;

const useStyles = makeStyles((theme) => ({
  chatsCard: {
    maxHeight: "100vh",
    overflow: "scroll",
    "&::-webkit-scrollbar": {
      width: "0px",
      background: "transparent",
    },
  },
  noContent: {
    color: "#7d7b79",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "19px",
    padding: "12px 10px 10px 17px",
    textAlign: "center",
  },
}));
