// types
import {
  TRAINING_ADD_SUCCESSFUL,
  SET_TRAINING_COURSE,
  TRAINING_COURSE_LOADER_TRUE,
  TRAINING_COURSE_LOADER_FALSE,
  SET_LESSONS,
  REMOVE_LESSON,
  TRAINING_COURSE_DETAIL,
  TRAINING_COURSE_REVIEWS,
  ADD_TRAINING_COURSE_REVIEW,
  ADD_TRAINING_COURSE_RATING,
  GET_NEW_TRAINING_COURSES,
  GET_RECENT_TRAINING_COURSES,
  SET_SECTIONS,
  REMOVE_SECTION,
  UNSET_TRAINING_COURSE,
  GET_USER_UPLOADED_TRAINING_COURSES,
  GET_USER_ENROLLED_TRAINING_COURSES,
  ADD_UPDATE_RETREAT_PLAN,
  LESSON_DETAIL,
  ACCEPT_OR_REJECT_STUDENT,
  TRAINING_COURSE_STATS_DETAIL,
  LOADER,
  TRAINING_COURSE_LOADER,
  TRAINING_PREVIEW_VIDEO_PROGRESS,
  SET_TRAINING_COURSE_SECTIONS,
  LOG_OUT,
  ACTIVE_TRAINING_COURSE
} from "../actions/types";

// initial state
const initialState = {
  plansList: [],
  courseData: null,
  add_success: false,
  courseLoader: false,
  step: 0,
  mentors: [],
  sections: [],
  courseDetail: {},
  courseStats: {},
  episodeDetail: {},
  courseReviews: null,
  newReview: null,
  newRating: null,
  latestCourses: {
    filter: false,
    latestCoursesList: []
  },
  recentCoursesList: null,
  userUploadedCourses: null,
  userEnrolledCoursesList: null,
  quoteOfTheDay: {},
  coursesGroupByCategoryList: null,
  coursesByCategory: [],
  episodeVideoProgress: [],
  episodeThumbnailProgress: 0,
  searchData: [],
  courseLoader: false,
  coursePreviewProgress: 0,
  videoUploadLoaders: [],
  lesson: null
};

// handle actions
export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case TRAINING_PREVIEW_VIDEO_PROGRESS:
      return {
        ...state,
        coursePreviewProgress: payload
      };
    case TRAINING_COURSE_LOADER:
      return {
        ...state,
        courseLoader: payload
      };
    case 'IN_ACTIVE_LAST_SECTION':
      return {
        ...state,
        courseData: { ...state?.courseData, status: payload }
      };
    case 'ADD_UPDATE_RETREAT_PLAN':
      return {
        ...state,
        courseData: { ...payload, sections: state?.courseData?.sections }
      };
    case ACTIVE_TRAINING_COURSE:
      return {
        ...state,
        courseData: { ...state?.courseData, status: payload }
      };
    case SET_TRAINING_COURSE_SECTIONS:
      return {
        ...state,
        sections: payload.sections,
        courseData: { ...state.courseData, sections: payload.sections }
      };
    case GET_NEW_TRAINING_COURSES:
      return {
        ...state,
        latestCourses: {
          ...state.latestCourses,
          filter: true,
          latestCoursesList: payload
        }
      };

    case GET_USER_UPLOADED_TRAINING_COURSES:
      return {
        ...state,
        userUploadedCourses: payload
      };
    case GET_USER_ENROLLED_TRAINING_COURSES:
      return {
        ...state,
        userEnrolledCoursesList: payload
      };
    case GET_RECENT_TRAINING_COURSES:
      return {
        ...state,
        recentCoursesList: payload
      };
    case TRAINING_COURSE_DETAIL:
      return {
        ...state,
        courseDetail: payload
      };
    case TRAINING_COURSE_STATS_DETAIL:
      return {
        ...state,
        courseStats: payload
      };
    case ACCEPT_OR_REJECT_STUDENT:
      return {
        ...state,
        courseDetail: { ...state.courseDetail, isUserTaking: true }
      };
    case "LESSON_VIDEO_PROGRESS":
      return {
        ...state,
        episodeVideoProgress: state.episodeVideoProgress.filter(
          item => item.episode_id === payload.episode_id
        )[0]
          ? state.episodeVideoProgress.filter(
            item =>
              item.episode_id === payload.episode_id && item.percentage < 100
          )[0]
            ? state.episodeVideoProgress.map(item => {
              if (item.episode_id === payload.episode_id) {
                return payload;
              } else {
                return item;
              }
            })
            : state.episodeVideoProgress.filter(
              item => item.episode_id !== payload.episode_id
            )
          : [...state.episodeVideoProgress, payload]
      };
    case "LESSON_THUMBNAIL_PROGRESS":
      return {
        ...state,
        episodeThumbnailProgress: payload
      };
    case "LESSON_VIDEO_LOADER":
      return {
        ...state,
        videoUploadLoaders: state.videoUploadLoaders.includes(payload)
          ? state.videoUploadLoaders.filter(item => item !== payload)
          : [...state.videoUploadLoaders, payload]
      };
    case SET_LESSONS:
      return {
        ...state,
        lesson: payload
      };
    case LESSON_DETAIL:
      return {
        ...state,
        episodeDetail: payload
      };
    case TRAINING_COURSE_REVIEWS:
      return {
        ...state,
        courseReviews: payload
      };
    case ADD_TRAINING_COURSE_REVIEW:
      return {
        ...state,
        newReview: payload
      };
    case ADD_TRAINING_COURSE_RATING:
      return {
        ...state,
        newRating: payload
      };
    case UNSET_TRAINING_COURSE:
      return {
        ...state,
        courseData: null
      };
    case SET_TRAINING_COURSE:
      console.log("this is test", payload);
      return {
        ...state,
        courseData: {
          ...state.courseData,
          ...payload
        }
      };

    case TRAINING_COURSE_LOADER_FALSE:
      return {
        ...state,
        courseLoader: false
      };
    case TRAINING_COURSE_LOADER_TRUE:
      return {
        ...state,
        courseLoader: true
      };
    case TRAINING_ADD_SUCCESSFUL:
      return {
        ...state,
        add_success: payload
      };
    case SET_SECTIONS:
      return {
        ...state,
        sections: state.sections.filter(epi => epi.id === payload.id)[0]
          ? [
            ...state.sections,
            {
              ...state.sections.filter(epi => epi.id === payload.id)[0],
              ...payload
            }
          ]
          : [...state.sections, payload],
        courseData: {
          ...state.courseData,
          sections:
            state.courseData?.id === payload.trainingCourseId
              ? state.courseData?.sections?.length > 0
                ? state.courseData.sections.filter(
                  ep => ep.id === payload.id
                )[0]
                  ? state.courseData.sections.map(ep => {
                    if (ep.id === payload.id) {
                      return payload;
                    }
                    return ep;
                  })
                  : [...state.courseData.sections, payload]
                : [payload]
              : []
        }
      };

    case LOG_OUT:
      return {
        categoriesList: [],
        plansList: [],
        courseData: null,
        add_success: false,
        courseLoader: false,
        step: 0,
        sections: []
      };

    default:
      return state;
  }
}
