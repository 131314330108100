import React, { useState } from "react";
import {
  Grid,
  Typography,
  Button,
  Box,
  FormControl,
  FormHelperText,
  Modal,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useSelector } from "react-redux";
import { useTheme } from "@material-ui/core/styles";
import { useIntl } from "react-intl";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import StartPage from "./Start";
import Picker from "react-scrollable-picker";
import { ArrowBack } from "@material-ui/icons";

function SoloExercise(props) {
  const theme = useTheme();
  const intl = useIntl();
  const classes = useStyles();
  let categoriesList = useSelector((state) => state?.courses?.categoriesList);

  const [currentPage, setCurerrentPage] = useState("index");
  const [modalStyle] = useState(getModalStyle);
  const [category, setCategory] = useState({
    categories: "",
  });
  const [valueGroupsCategory, setValueGroupsCategory] = useState({
    categories: "Meditation",
  });

  const [categoryGroups] = useState(getCategory);
  function getCategory() {
    let categories = [];
    let i;
    for (i = 0; i < categoriesList.length; i++) {
      categories.push({
        value: `${categoriesList[i]?.name}`,
        label: `${categoriesList[i]?.name}`,
      });
    }

    return { categories: [...categories] };
  }
  const [valueGroups, setValueGroup] = useState({
    min: "00",
    sec: "10",
  });
  const [valueGroupsRep, setValueGroupRep] = useState({
    repitions: "2",
  });
  const [optionGroupsRep] = useState({
    repitions: [
      { value: "1", label: "1" },
      { value: "2", label: "2" },
      { value: "3", label: "3" },
      { value: "4", label: "4" },
      { value: "5", label: "5" },
    ],
  });

  const [preprationTime, setPreprationTime] = useState({
    min: "",
    sec: "",
  });
  const [intervalRestTime, setIntervalRestTime] = useState({
    min: "",
    sec: "",
  });
  const [exerciseDuration, setExerciseDuration] = useState({
    min: "",
    sec: "",
  });
  const [repititions, setRepititions] = useState({
    rep: "1",
  });

  const [optionGroups] = useState(getPickerData);
  function getPickerData() {
    let i;
    let min = [];
    for (i = 0; i < 60; i++) {
      min.push({
        value: i <= 9 ? `0${i}` : `${i}`,
        label: i <= 9 ? `0${i}` : `${i}`,
      });
    }
    return { min: [...min], sec: [...min] };
  }

  const { errors } = useForm();
  function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
      borderColor: theme.colors.white,
      borderRadius: "4px",
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
    };
  }

  const [openModel, setOpenModel] = useState(false);
  const [option, setOption] = useState("preprationTime");

  const handleChange = (name, value) => {
    option === "repititions"
      ? setValueGroupRep({ ...valueGroupsRep, [name]: value })
      : option === "category"
        ? setValueGroupsCategory({ ...category, [name]: value })
        : setValueGroup({ ...valueGroups, [name]: value });
  };

  const handleCloseModal = () => {
    setOpenModel(false);
  };
  const setData = () => {
    if (option === "preprationTime") {
      setPError(false);

      setPreprationTime({
        min: valueGroups.min,
        sec: valueGroups.sec,
      });
    } else if (option === "exerciseDuration") {
      setEError(false);
      setExerciseDuration({
        min: valueGroups.min,
        sec: valueGroups.sec,
      });
    } else if (option === "intervalRestTime") {
      setIError(false);
      setIntervalRestTime({
        min: valueGroups.min,
        sec: valueGroups.sec,
      });
    } else if (option === "repititions") {
      setRepititions({
        rep: valueGroupsRep.repitions,
      });
    } else if (option === "category") {
      setCError(false);

      setCategory({
        categories: valueGroupsCategory.categories,
      });
    }

    setOption("");
    handleCloseModal();
  };
  const usernameModelBody = (
    <div style={modalStyle} className={classes.paper}>
      <div
        style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
      />

      {option === "repititions" ? (
        <Grid container justify="center">
          <Grid item>
            <Typography className={classes.modalDesc}>
              {intl.formatMessage({ id: "repititions" })}
            </Typography>
          </Grid>
        </Grid>
      ) : option === "category" ? (
        <Grid container justify="center">
          <Grid item>
            <Typography className={classes.modalDesc}>
              {intl.formatMessage({ id: "category" })}
            </Typography>
          </Grid>
        </Grid>
      ) : (
        <Grid container justify="center">
          <Grid item>
            <Typography className={classes.modalDesc}>
              {intl.formatMessage({ id: "Min" })}
            </Typography>
          </Grid>
          <Grid item>
            <Typography className={classes.modalDesc}>
              {intl.formatMessage({ id: "Sec" })}
            </Typography>
          </Grid>
        </Grid>
      )}
      <Picker
        optionGroups={
          option === "repititions"
            ? optionGroupsRep
            : option === "category"
              ? categoryGroups
              : optionGroups
        }
        valueGroups={
          option === "repititions"
            ? valueGroupsRep
            : option === "category"
              ? valueGroupsCategory
              : valueGroups
        }
        onChange={handleChange}
      />
      <Button className={classes.modelBtn} onClick={() => setData()}>
        {intl.formatMessage({ id: "set" })}
      </Button>
    </div>
  );

  const [cError, setCError] = useState(false);
  const [eError, setEError] = useState(false);
  const [pError, setPError] = useState(false);
  const [iError, setIError] = useState(false);

  const handleChangePage = (value) => {
    if (value === "start") {
      let cError = category === "";
      let pError = preprationTime.min === "" && preprationTime.sec === "";
      let eError = exerciseDuration.min === "" && exerciseDuration.sec === "";
      let iError = intervalRestTime.min === "" && intervalRestTime.sec === "";
      if (!cError && !eError && !pError) {
        setCurerrentPage(value);
      } else {
        setCError(cError);
        setPError(pError);
        setEError(eError);
        setIError(iError);
      }
    } else {
      setCurerrentPage(value);
    }
  };

  const handleClick = (value) => {
    setOpenModel(true);
    setOption(value);
  };

  return (
    <>
      {currentPage === "index" ? (
        <Box
          height="100vh"
          display="flex"
          flexDirection="column"
          className={classes.root}
          style={{ paddingBottom: '190px' }}
        >
          <Grid container>
            <Box component="span" p={0} width={1}>
              <Grid
                container
                style={{ backgroundColor: "#244562" }}
                className={classes.header}
              >
                <Grid item xs={2} style={{ textAlign: "center" }}>
                  <Link to="/profile">
                    <ArrowBack className={classes.backArrow} />
                  </Link>
                </Grid>
                <Grid item xs={10} style={{ paddingRight: "16.666667%" }}>
                  <Typography className={classes.headerTitle}>
                    {intl.formatMessage({ id: "timerSetting" })}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Grid>

          <Grid
            container
            style={{
              marginBottom: "5px",
              marginTop: "80px",
            }}
          >
            <Box
              width={1}
              display="flex"
              flexDirection="column"
              spacing={2}
              style={{ justifyContent: "space-between" }}
            >
              <Grid container justify="space-around">
                <FormControl error={errors.role} className={classes.inputField}>
                  <Grid container>
                    <Grid
                      container
                      direction="column"
                      justify="space-around"
                      className={classes.gridStyle}
                      onClick={() => {
                        handleClick("category");
                      }}
                    >
                      <Grid item>
                        <Typography
                          style={{
                            color:
                              option === "exerciseDuration"
                                ? "#09A9BB"
                                : exerciseDuration.min === "" &&
                                  exerciseDuration.sec === ""
                                  ? "#C4C4C4"
                                  : "#FFF",
                          }}
                          className={classes.bodyTitle}
                        >
                          <Grid container justify="center">
                            {intl.formatMessage({ id: "category" })}
                          </Grid>
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography
                          style={{
                            color:
                              option === "category"
                                ? "#09A9BB"
                                : category.categories === ""
                                  ? "#C4C4C4"
                                  : "#FFF",
                          }}
                          className={classes.bodySubtitle}
                        >
                          {category.categories === ""
                            ? intl.formatMessage({ id: "select" })
                            : `${category.categories}`}
                        </Typography>
                      </Grid>
                      {eError && (
                        <Grid item>
                          <Typography
                            style={{ color: "white" }}
                            className={classes.bodySubtitle}
                          >
                            {intl.formatMessage({ id: "requiredCategory" })}
                          </Typography>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>

                  <FormHelperText
                    style={{ color: "white", textAlign: "center" }}
                  >
                    {cError && intl.formatMessage({ id: "selectCategory" })}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid container>
                <Grid
                  container
                  direction="column"
                  justify="space-around"
                  className={classes.gridStyle}
                  onClick={() => {
                    handleClick("preprationTime");
                  }}
                >
                  <Grid item>
                    <Typography
                      style={{
                        color:
                          option === "preprationTime"
                            ? "#09A9BB"
                            : preprationTime.min === "" &&
                              preprationTime.sec === ""
                              ? "#C4C4C4"
                              : "#FFF",
                      }}
                      className={classes.bodyTitle}
                    >
                      <Grid container justify="center">
                        {intl.formatMessage({ id: "preprationTime" })}
                      </Grid>
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      style={{
                        color:
                          option === "preprationTime"
                            ? "#09A9BB"
                            : preprationTime.min === "" &&
                              preprationTime.sec === ""
                              ? "#C4C4C4"
                              : "#FFF",
                      }}
                      className={classes.bodySubtitle}
                    >
                      {preprationTime.min === "" && preprationTime.sec === ""
                        ? intl.formatMessage({ id: "setDuration" })
                        : `${preprationTime.min}:${preprationTime.sec}`}
                    </Typography>
                  </Grid>
                  {pError && (
                    <Grid item>
                      <Typography
                        style={{ color: "white" }}
                        className={classes.bodySubtitle}
                      >
                        {intl.formatMessage({
                          id: "requiredPreperationTime",
                        })}
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              </Grid>

              <Grid container>
                <Grid
                  container
                  direction="column"
                  justify="space-around"
                  className={classes.gridStyle}
                  onClick={() => {
                    handleClick("exerciseDuration");
                  }}
                >
                  <Grid item>
                    <Typography
                      style={{
                        color:
                          option === "exerciseDuration"
                            ? "#09A9BB"
                            : exerciseDuration.min === "" &&
                              exerciseDuration.sec === ""
                              ? "#C4C4C4"
                              : "#FFF",
                      }}
                      className={classes.bodyTitle}
                    >
                      <Grid container justify="center">
                        {intl.formatMessage({
                          id: "exerciseDuration",
                        })}
                      </Grid>
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      style={{
                        color:
                          option === "exerciseDuration"
                            ? "#09A9BB"
                            : exerciseDuration.min === "" &&
                              exerciseDuration.sec === ""
                              ? "#C4C4C4"
                              : "#FFF",
                      }}
                      className={classes.bodySubtitle}
                    >
                      {exerciseDuration.min === "" &&
                        exerciseDuration.sec === ""
                        ? intl.formatMessage({ id: "setDuration" })
                        : `${exerciseDuration.min}:${exerciseDuration.sec}`}
                    </Typography>
                  </Grid>
                  {eError && (
                    <Grid item>
                      <Typography
                        style={{ color: "white" }}
                        className={classes.bodySubtitle}
                      >
                        {intl.formatMessage({
                          id: "requiredDuration",
                        })}
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              </Grid>

              <Grid container>
                <Grid
                  container
                  direction="column"
                  justify="space-around"
                  className={classes.gridStyle}
                  onClick={() => {
                    handleClick("intervalRestTime");
                  }}
                >
                  <Grid item>
                    <Typography
                      style={{
                        color:
                          option === "intervalRestTime"
                            ? "#09A9BB"
                            : intervalRestTime.min === "" &&
                              intervalRestTime.sec === ""
                              ? "#C4C4C4"
                              : "#FFF",
                      }}
                      className={classes.bodyTitle}
                    >
                      <Grid container justify="center">
                        {intl.formatMessage({
                          id: "intervalRestTime",
                        })}
                      </Grid>
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      style={{
                        color:
                          option === "intervalRestTime"
                            ? "#09A9BB"
                            : intervalRestTime.min === "" &&
                              intervalRestTime.sec === ""
                              ? "#C4C4C4"
                              : "#FFF",
                      }}
                      className={classes.bodySubtitle}
                    >
                      {intervalRestTime.min === "" &&
                        intervalRestTime.sec === ""
                        ? intl.formatMessage({ id: "setTime" })
                        : `${intervalRestTime.min}:${intervalRestTime.sec}`}
                    </Typography>
                  </Grid>
                  {iError && (
                    <Grid item>
                      <Typography
                        style={{ color: "white" }}
                        className={classes.bodySubtitle}
                      >
                        {intl.formatMessage({
                          id: "requiredRestTime",
                        })}
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              </Grid>

              <Grid container>
                <Grid
                  container
                  direction="column"
                  justify="space-around"
                  className={classes.gridStyle}
                  onClick={() => {
                    handleClick("repititions");
                  }}
                >
                  <Grid item>
                    <Typography
                      style={{
                        color:
                          option === "repititions"
                            ? "#09A9BB"
                            : repititions.rep === ""
                              ? "#C4C4C4"
                              : "#FFF",
                      }}
                      className={classes.bodyTitle}
                    >
                      <Grid container justify="center">
                        {intl.formatMessage({ id: "repititions" })}
                      </Grid>
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      style={{
                        color:
                          option === "repititions"
                            ? "#09A9BB"
                            : repititions.rep === ""
                              ? "#C4C4C4"
                              : "#FFF",
                      }}
                      className={classes.bodySubtitle}
                    >
                      {repititions.rep === ""
                        ? intl.formatMessage({ id: "setRepeats" })
                        : `${repititions.rep}`}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid container >
                <Grid container direction="row" justify="space-around">
                  <Grid item xs={1} />
                  <Grid item xs={10}>
                    <Button
                      disabled={
                        category.categories === "" ||
                        (preprationTime.min === "" &&
                          preprationTime.sec === "") ||
                        (intervalRestTime.min === "" &&
                          intervalRestTime.sec === "") ||
                        (exerciseDuration.min === "" &&
                          exerciseDuration.sec === "")
                      }
                      className={
                        category.categories === "" ||
                          (preprationTime.min === "" &&
                            preprationTime.sec === "") ||
                          (intervalRestTime.min === "" &&
                            intervalRestTime.sec === "") ||
                          (exerciseDuration.min === "" &&
                            exerciseDuration.sec === "")
                          ? classes.btnStyleDisable
                          : classes.btnStyle
                      }
                      onClick={() => {
                        handleChangePage("start");
                      }}
                    >
                      {intl.formatMessage({ id: "next" })}
                    </Button>
                  </Grid>
                  <Grid item xs={1} />
                </Grid>
              </Grid>
            </Box>
          </Grid>

          <Grid container>
            <Modal
              disableEnforceFocus={true}
              open={openModel}
              onClose={handleCloseModal}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
            >
              {usernameModelBody}
            </Modal>
          </Grid>
        </Box>
      ) : (
        <StartPage
          category={category}
          preprationTime={preprationTime}
          intervalRestTime={intervalRestTime}
          exerciseDuration={exerciseDuration}
          repititions={repititions}
          handleGoBack={handleChangePage}
        />
      )}
    </>
  );
}

export default SoloExercise;

const useStyles = makeStyles((theme) => ({
  btnStyle: {
    color: "#fff",
    background: `linear-gradient(180deg, #15AEBF 0%, #A6D6FF 100%)`,
    height: "60px",
    borderRadius: "10px",
    width: "100%",
    fontFamily: theme.fontFamily.Roboto,

    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "21px",
    marginTop: "37px",
  },
  btnStyleDisable: {
    color: "#fff",
    background: `linear-gradient(180deg, #15AEBF 0%, #A6D6FF 100%)`,
    height: "60px",
    borderRadius: "10px",
    width: "100%",
    fontFamily: theme.fontFamily.Roboto,

    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "21px",
    marginTop: "37px",
  },

  root: {
    backgroundColor: "#244562",
    width: "100%",
  },
  gridStyle: {
    cursor: "pointer",
  },
  inputField: {
    width: "100%",
    borderRadius: "10px",
  },
  selectCategory: {
    background: "#36526C",
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontSize: "24px",
    lineHeight: "28px",
    textAlign: "center",
    height: "41px",
    width: "156px",

    borderRadius: "20px",
    fontWeight: 500,
    color: "#ffffff",
    paddingRight: "10px",
    "&.MuiInput-underline::before": {
      borderBottom: "none",
    },
    "&.MuiInput-underline:after": {
      borderBottom: "none",
    },
    "&.MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottom: "none",
    },
    "&.MuiSelect-icon": {
      color: "#ffffff !important",
      paddingRight: "10px",
    },
  },
  header: {
    borderRadius: "0 0 34px 34px",
    height: "80px",
    width: "100%",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    position: 'fixed',
    zIndex: 1
  },
  headerTitle: {
    fontFamily: theme.fontFamily.Reef,
    color: theme.colors.white,
    fontStyle: "normal",
    fontWeight: 900,
    fontSize: "26px",
    lineHeight: "35px",
    textAlign: "center",
  },
  backArrow: {
    color: theme.colors.white,
    fontSize: "26px",
  },
  iconSelect: {
    color: "#ffffff",
    paddingRight: "10px",
  },
  formControl: {
    color: theme.palette.background.paper,
    margin: theme.spacing(1),
    minWidth: "120px",
  },
  startImg: {
    marginTop: "30%",
    marginLeft: "20%",
    width: "120px",
    height: "120px",
    cursor: "pointer",
  },

  paper: {
    position: "absolute",
    height: 330,
    maxWidth: 315,
    minWidth: 300,
    justifyContent: "center",
    backgroundColor: theme.palette.background.paper,
    outline: "none",
  },
  modalDesc: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "24px",
    lineHeight: "28px",
    color: "#3C4A6B",
    textAlign: "center",
    padding: theme.spacing(2, 4, 3),
  },
  modelBtn: {
    background: `linear-gradient(#38546D 1%,#38546D 100%)`,
    height: "73px",
    width: "100%",
    fontFamily: theme.fontFamily.Roboto,

    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "21px",
    marginTop: "37px",
    color: theme.colors.white,
  },
  bodyTitle: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "20px",
    lineHeight: "23px",
    textAlign: "center",
    margin: '10px 0px 10px 0px'
  },
  bodySubtitle: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "16px",
    textAlign: "center",
    marginTop: "10px",
    marginBottom: "30px",
  },
  modelStyle: {
    borderColor: theme.colors.white,
    borderRadius: "4px",
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
  },
}));
