import React, { useState, useEffect } from "react";
import { Grid, Modal, Typography } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useIntl } from "react-intl";

const useStyles = makeStyles(theme => ({
  root: {
    color: "#eaeaf0",
    display: "flex",
    height: 22,
    alignItems: "center"
  },
  active: {
    color: "#FBAE02",
    "& > div": {
      width: 28,
      height: 28,
      backgroundColor: "#FBAE02"
    }
  },
  circle: {
    width: 14,
    height: 14,
    border: `4px solid currentColor`,
    boxSizing: "border-box",
    borderRadius: "50%"
  },
  completed: {
    backgroundColor: "#3C4A6B",
    color: "#fff",
    borderRadius: "50%",
    zIndex: 1,
    fontSize: 18
  },
  activeTypography: {
    color: "#FBAE02",
    cursor: "pointer"
  },

  completedTypography: {
    color: "#3C4A6B",
    cursor: "pointer"
  },

  inProgressTypography: {
    color: "#C4C4C4",
    cursor: "pointer"
  },
  link: {
    background: "#FBAE02",
    color: "#fff",
    padding: ".5rem",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "none"
    }
  },
  paper: {
    position: "absolute",
    height: 330,
    maxWidth: 315,
    minWidth: 300,
    justifyContent: "center",
    backgroundColor: theme.palette.background.paper,
    outline: "none"
  },
  msgBody: {
    minHeight: "240px",
    alignItems: "center",
    display: "flex"
  },
  modelBodyText: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "17px",
    lineHeight: "27px",
    textAlign: "center",
    letterSpacing: "0.02em",
    color: "#192247"
  },
  modelBtn: {
    height: "73px",
    width: "100%",
    fontFamily: theme.fontFamily.Roboto,
    cursor: "pointer",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "21px",
    textAlign: "center",
    paddingTop: "3rem",
    textDecoration: "none",
    color: theme.colors.white
  },

  modalBtnGrid: {
    padding: "0px",
    alignSelf: "flex-end",
    color: "#fff",
    cursor: "pointer",
    background: `linear-gradient(#09A9BB 1%,#09A9BB 100%)`,
    height: "73px",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    display: "flex"
  }
}));

const CompleteYourProfile = props => {
  let classes = useStyles();
  const theme = useTheme();
  const intl = useIntl();
  const [modalStyle] = useState(getModalStyle);

  function getModalStyle() {
    const top = 50;
    const left = 50;
    return {
      borderColor: theme.colors.white,
      borderRadius: "4px",
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top - 4}%, -${left}%)`
    };
  }
  let userData = useSelector(state => state.user);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (
      userData.user &&
      userData.user.id &&
      (!userData.user.pictureUrl || !userData.user.coverUrl || !userData.user.title || !userData.user.description )
    ) {
      setOpen(true);
    }
  }, [userData]);

  return (
    <Modal disableEnforceFocus={true} open={open}>
      <Grid
        container
        direction="row"
        spacing={3}
        style={modalStyle}
        className={classes.paper}
      >
        <Grid item className={classes.msgBody}>
          <Typography className={classes.modelBodyText}>
            {intl.formatMessage({ id: "completeYourProfile" })}
          </Typography>
        </Grid>
        <Grid item className={classes.modalBtnGrid}>
          <Link to={"/profile"} className={classes.modelBtn}>
            Okay
          </Link>
        </Grid>
      </Grid>
    </Modal>
  );
};
export default CompleteYourProfile;
