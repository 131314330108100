// types
import {
  SHOW_MESSAGE,
  HIDE_MESSAGE,
  HIDE_MESSAGE_DIALOG,
  SHOW_MESSAGE_DIALOG,
} from "../actions/types";

// initial state
const initialState = {
  title: null,
  message: null,
  severity: "success",
  show: false,
  popup: {
    title: null,
    message: null,
    show: false,
  },
};

// handle actions
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SHOW_MESSAGE:
      return {
        ...state,
        title: payload.title,
        message: payload.message,
        severity: payload.severity,
        show: true,
      };
    case SHOW_MESSAGE_DIALOG: {
      return {
        ...state,
        popup: {
          title: payload.title,
          message: payload.message,
          show: true,
        },
      };
    }
    case HIDE_MESSAGE:
      return {
        ...state,
        show: false,
      };
    case HIDE_MESSAGE_DIALOG: {
      return {
        ...state,
        popup: {
          title: null,
          message: null,
          show: false,
        },
      };
    }
    default:
      return state;
  }
}
