import React, { useEffect } from 'react'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import LetsStartWebContainer from '../../containers/Web/LetsStart'
import LetsStartWMobileContainer from '../../containers/Mobile/LetsStart'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { getSiteConfigs } from '../../actions/siteSetting'


const LetsStartPage = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const isLogin = useSelector(state => state.auth?.isAuthenticated)
  useEffect(() => {
    dispatch(getSiteConfigs({ token: '' }))
    if (isLogin) {

      history.push('/dashboard')
    }
  }, [])
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'))

  return <>{isDesktop ? <LetsStartWebContainer /> : <LetsStartWMobileContainer />}</>
}
export default LetsStartPage