import React from 'react'
import { Grid, Card, Avatar, Typography, Badge } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';



function ChallengeParticipantCard(props) {
    const classes = useStyles();

    return (
        <Card className={classes.container} style={{ background: props?.background }}>
            <Grid container >
                <Grid item xs={12} className={classes.imageContainer}>

                    <Avatar src={props?.participant?.pictureUrl} style={{
                        height: '45px',
                        width: '45px'
                    }} />
                </Grid>
                <Grid item xs={12}>
                    <Typography className={classes.username}>@{props?.participant?.username}</Typography>
                </Grid>

            </Grid>

        </Card>
    )
}

export default ChallengeParticipantCard

const useStyles = makeStyles(theme => ({
    container: {
        height: '85px',
        width: '100%',
        boxShadow: 'none'

    },
    imageContainer: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '10px'

    },
    username: {
        fontFamily: theme.fontFamily.Roboto,
        fontStyle: 'italic',
        fontSize: '11px',
        fontWeight: 'bold',
        lineHeight: '13px',
        textAlign: 'center',
        color: '#3C4A6B',
        marginTop: '8px'

    },
    color: {
        backgroundColor: '#5BE4E4'
    }
}))