import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Sidebar from "../../components/Web/Sidebar";
const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  }
}));

function DesktopLayout(props) {
  const classes = useStyles();

  return (
    <div>
      <div className={classes.root}>
        <Grid container>
          <Grid item xs={2} sm={2} md={1} lg={1}>
            <Sidebar />
          </Grid>
          <Grid
            item
            xs={10}
            sm={10}
            md={11}
            lg={11}
            style={{ paddingLeft: "10px" }}
          >
            {props.children}
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default DesktopLayout;
