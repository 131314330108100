import React, { useState, useEffect } from "react";
import {
  Grid,
  Card,
  CardMedia,
  Typography,
  Avatar,
  Button,
  Divider,
  Hidden,
  useMediaQuery,
  Modal,
} from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import { Link, useHistory, useParams } from "react-router-dom";
import { makeStyles, useTheme } from "@material-ui/styles";
import { useIntl } from "react-intl";
import { useSelector, useDispatch } from "react-redux";
import {
  followUser,
  sendFriendRequest,
  unFollowUser,
} from "../../actions/userRelations";
import { getUserFollowersFollowingsById } from "../../actions/publicUser";

function ProfileInfo(props) {
  const classes = useStyles();
  const intl = useIntl();
  const history = useHistory();
  const params = useParams();
  const theme = useTheme();
  const dispatch = useDispatch();
  const publicUser = useSelector((state) => state?.publicUser?.publicUserInfo);
  const authReducer = useSelector((state) => state?.auth);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [modalStyle] = useState(getModalStyle);
  const [openModel, setOpenModel] = useState(false);
  const [userFollowingsFollowers, setUserFollowingsFollowers] = useState("");

  const getData = async () => {
    const res = await dispatch(
      getUserFollowersFollowingsById({
        token: authReducer?.token,
        userId: params?.userId,
      })
    );
    if (res) {
      setUserFollowingsFollowers(res);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  function getModalStyle() {
    const top = 50;
    const left = 50;
    return {
      borderColor: theme.colors.white,
      borderRadius: "4px",
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top - 4}%, -${left}%)`,
    };
  }

  const handleFollowUser = () => {
    dispatch(followUser({ token: authReducer?.token, userId: publicUser?.id }));
  };

  const addFriend = () => {
    dispatch(
      sendFriendRequest({
        token: authReducer?.token,
        requestReceiverId: publicUser?.id,
      })
    );
  };

  const handleCloseModal = () => {
    setOpenModel(false);
  };

  const modalBody = (
    <Grid
      container
      direction="row"
      spacing={3}
      style={modalStyle}
      className={classes.paper}
    >
      <Grid item className={classes.msgBody}>
        <Typography className={classes.modelBodyText}>
          {intl.formatMessage({ id: "canNotSendMessage" })}
        </Typography>
      </Grid>
      <Grid item className={classes.modalBtnGrid}>
        <Button onClick={() => handleCloseModal()} className={classes.modelBtn}>
          {intl.formatMessage({ id: "okay" })}
        </Button>
      </Grid>
    </Grid>
  );

  const handleSendMessage = () => {
    publicUser?.friend?.status === "friends"
      ? history.push({
          pathname:
            publicUser?.friend?.status === "friends"
              ? `/more/inbox/messages/${
                  publicUser?.id > authReducer?.user?.id
                    ? `${authReducer?.user?.id}_${publicUser?.id}`
                    : `${publicUser?.id}_${authReducer?.user?.id}`
                }`
              : "",
          friendInfo: {
            user: {
              pictureUrl: publicUser?.pictureUrl,
              name: publicUser?.name,
            },
            lastMessage: {},
            id:
              publicUser?.id > authReducer?.user?.id
                ? `${authReducer?.user?.id}_${publicUser?.id}`
                : `${publicUser?.id}_${authReducer?.user?.id}`,
          },
        })
      : setOpenModel(true);
  };

  return (
    <Grid container>
      {/* <Grid item xs={12} md={11}> */}
      <Modal
        disableEnforceFocus={true}
        open={openModel}
        onClose={handleCloseModal}
      >
        {modalBody}
      </Modal>
      {/* </Grid> */}
      <Hidden smDown>
        <Grid xs={0} md={1} />
      </Hidden>
      <Grid item xs={12} md={11}>
        <Card className={classes.root} style={{ marginTop: isMobile && "0px" }}>
          <CardMedia
            className={classes.media}
            image={publicUser?.coverUrl}
            title="profile background"
            style={{ paddingBottom: isMobile && "15px" }}
          >
            <Grid container className={classes.heroImageContentContainer}>
              <Hidden smUp>
                <Grid xs={12}>
                  <ArrowBack
                    style={{ color: "#FFFF", paddingTop: "10px" }}
                    onClick={() => history.goBack()}
                  />
                </Grid>
              </Hidden>
              <Grid item container xs={8} className={classes.titleName}>
                <Grid item xs={11}>
                  <Typography className={classes.name}>
                    {publicUser?.name}
                  </Typography>
                  <Typography className={classes.username}>
                    @{publicUser?.username}
                  </Typography>
                </Grid>
                <Grid item container xs={12} spacing={1}>
                  <Grid item xs={5}>
                    {userFollowingsFollowers?.isFollowing ? (
                      <Button className={classes.btn} disabled>
                        {intl.formatMessage({ id: "following" })}
                      </Button>
                    ) : (
                      <Button
                        className={classes.btn}
                        onClick={handleFollowUser}
                      >
                        {intl.formatMessage({ id: "follow" })}
                      </Button>
                    )}
                  </Grid>
                  <Grid item xs={5}>
                    <Button
                      className={classes.btn}
                      onClick={handleSendMessage}
                      style={{
                        background:
                          "linear-gradient(180deg, rgba(21, 174, 191, 0.5) 0%, rgba(166, 214, 255, 0.5) 100%)",
                        borderRadius: "0px 0px 30px 0px",
                      }}
                    >
                      {intl.formatMessage({ id: "message" })}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item container xs={3}>
                <Grid item xs={11}>
                  <Avatar
                    src={publicUser?.pictureUrl}
                    className={classes.userPhoto}
                  />
                </Grid>
              </Grid>
            </Grid>
          </CardMedia>
          <Grid container className={classes.contentContainer}>
            <Grid item container xs={12} className={classes.relationContainer}>
              <Grid item xs={4}>
                {publicUser?.friend ? (
                  <Typography className={classes.addFriendTxt}>
                    {publicUser?.friend?.status === "friends"
                      ? intl.formatMessage({ id: "friends" })
                      : intl.formatMessage({ id: "requestPending" })}
                  </Typography>
                ) : (
                  <Typography
                    onClick={addFriend}
                    className={classes.addFriendTxt}
                    style={{ marginTop: "5px", cursor: "pointer" }}
                  >
                    {intl.formatMessage({ id: "addFriend" })}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={4}>
                <Typography className={classes.addFriendTxt}>
                  {userFollowingsFollowers?.followings?.length}
                </Typography>
                <Typography
                  className={`${classes.addFriendTxt} ${classes.relationSubTxt}`}
                >
                  {intl.formatMessage({ id: "following" })}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography className={classes.addFriendTxt}>
                  {userFollowingsFollowers?.followers?.length}
                </Typography>
                <Typography
                  className={`${classes.addFriendTxt} ${classes.relationSubTxt}`}
                >
                  {intl.formatMessage({ id: "followers" })}
                </Typography>
              </Grid>
              <Grid item xs={10}>
                <Divider className={classes.divider} />
              </Grid>
            </Grid>

            <Grid container item xs={12} className={classes.aboutContainer}>
              <Grid item xs={12}>
                <Typography
                  className={classes.addFriendTxt}
                  style={{
                    color: "#3C4A6B",
                    fontSize: "18px",
                    marginBottom: "5px",
                    display: "block",
                  }}
                >
                  {intl.formatMessage({ id: "title" })}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  className={`${classes.relationSubTxt}`}
                  style={{
                    color: "#454141",
                    fontSize: "14px",
                    letterSpacing: "0.015em",
                    lineHeight: "18px",
                    paddingBottom: "15px",
                  }}
                >
                  {publicUser?.title}
                </Typography>
              </Grid>
            </Grid>

            <Grid
              container
              item
              xs={12}
              className={classes.aboutContainer}
              style={{ marginTop: "0px" }}
            >
              <Grid item xs={12}>
                <Typography
                  className={classes.addFriendTxt}
                  style={{
                    color: "#3C4A6B",
                    fontSize: "18px",
                    display: "block",
                  }}
                >
                  {intl.formatMessage({ id: "about" })}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  className={`${classes.relationSubTxt}`}
                  style={{
                    color: "#454141",
                    fontSize: "14px",
                    letterSpacing: "0.015em",
                    lineHeight: "18px",
                  }}
                >
                  {publicUser?.id && publicUser?.role !== "Regular" ? (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: unescape(publicUser?.description),
                      }}
                    />
                  ) : (
                    publicUser?.description
                  )}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
}

export default ProfileInfo;

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    minHeight: "260px",
    borderRadius: "11px",
    boxShadow: "0px 16px 20px rgba(123, 0, 0, 0.05)",
    backgroundColor: "#FFFFFF",
    marginTop: "30px",
  },

  media: {
    height: "230px",
    borderTopLeftRadius: "0px !important",
    borderRadius: "11px",
    backgroundImage:
      "linear-gradient(180deg, rgba(255, 255, 255, 0.23) 0%, rgba(0, 0, 0, 0.23) 39.58%, #000000 100%)",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  heroImageContentContainer: {
    backgroundImage:
      "linear-gradient(360deg, rgba(0, 32, 60, 0.95) 0%, rgba(5, 39, 68, 0.183073) 55%, rgba(123, 146, 166, 0) 100%)",
    display: "flex",
    justifyContent: "center",
    justifyItems: "center",

    height: "230px",
  },
  titleName: {
    display: "flex",
    alignItems: "center",
    marginTop: "65px",
  },
  name: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "26px",
    color: "#FFFFFF",
  },
  userPhoto: {
    height: "70px",
    width: "70px",
    marginTop: "100px",
    alignItems: "center",
  },
  btn: {
    background: "linear-gradient(360deg, #15AEBF 0%, #A6D6FF 100%)",
    borderRadius: "30px 0px 0px 0px",
    fontFamily: "Reef",
    fontFtyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "25px",
    color: "#FFFFFF",
    height: "44px",
    width: "100%",
    textTransform: "none",
  },
  username: {
    fontFamily: "Roboto",
    fontFtyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    color: "#FFFFFF",
  },
  contentContainer: {
    margin: "15px 0px 40px 0px",
  },
  relationContainer: {
    display: "flex",
    justifyContent: "center",
    justifyItems: "center",
    textAlign: "center",
  },
  addFriendTxt: {
    fontFamily: "Roboto",
    fontFtyle: "normal",
    fontWeight: "bold",
    fontSize: "20px",
    lineHeight: "23px",
    color: "#09A9BB",
  },
  relationSubTxt: {
    fontFamily: "Roboto",
    fontFtyle: "normal",
    fontWeight: "normal",
    fontSize: "11px",
    lineHeight: "13px",
    color: "#09A9BB",
    opacity: 0.65,
  },
  divider: {
    height: "1px solid #EDEDED",
    marginTop: "30px",
  },
  aboutContainer: {
    margin: "30px 20px 0px 20px",
  },
  paper: {
    position: "absolute",
    height: 330,
    maxWidth: 315,
    minWidth: 300,
    justifyContent: "center",
    backgroundColor: theme.palette.background.paper,
    outline: "none",
  },
  msgBody: {
    minHeight: "240px",
    alignItems: "center",
    display: "flex",
  },
  modelBtn: {
    height: "73px",
    width: "100%",
    fontFamily: theme.fontFamily.Roboto,
    cursor: "pointer",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "21px",
    color: theme.colors.white,
  },

  modalBtnGrid: {
    padding: "0px",
    alignSelf: "flex-end",
    color: "#fff",
    cursor: "pointer",
    background: `linear-gradient(#09A9BB 1%,#09A9BB 100%)`,
    height: "73px",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
  },
  modelBodyText: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "17px",
    lineHeight: "27px",
    textAlign: "center",
    letterSpacing: "0.02em",
    color: "#192247",
  },
  link: {
    textDecoration: "none",
    "& :hover": {
      color: "#09a9bb !important",
    },
  },
}));
