import { createMuiTheme, responsiveFontSizes } from "@material-ui/core/styles";
import { enUS } from "@material-ui/core/locale";

let theme = createMuiTheme(
  {

    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        tablet: 640,
        md: 960,
        lg: 1280,
        xl: 1920
      }
    },
    palette: {
      primary: {
        light: "#7986cb",
        main: "#3f51b5",
        dark: "#303f9f",
        contrastText: "#fff"
      },
      secondary: {
        light: "#ff4081",
        main: "#f50057",
        dark: "#c51162",
        contrastText: "#fff"
      }
    },
    colors: {
      inputBoxBg: "#dce8f2",
      white: "#FFFFFF",
      sideBar: "#09A9BB",
      selected: "#15aebf",
      unselected: "#000000"
    },
    fontFamily: {
      Roboto: "Roboto",
      Reef: "Reef"
    },
    typography: {
      fontFamily: ["Reef", "Roboto"].join(",")
    },
    fontFamily: {
      Roboto: "Roboto",
      Reef: "Reef"
    },
    typography: {
      fontFamily: ["Reef", "Roboto"].join(",")
    }
  },
  enUS
);

theme = responsiveFontSizes(theme);
export default theme;
