import { useSelector } from "react-redux";
import { Grid, Box, Typography } from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import { Link, useHistory } from "react-router-dom";
import ChallengeDetail from "../../../components/ViewChallengeDetail/ChallengeDetail";
import chatBtn from "../../../assets/img/chatBtn.svg";
import React, { useRef, useEffect } from "react";
import Truncate from "react-truncate";

function ViewChallengeDetails() {
  const classes = useStyles();
  const history = useHistory();
  const challengeDetail = useSelector(
    (state) => state?.challenges?.challengeDetail
  );
  const auth = useSelector((state) => state?.auth);
  const myRef = useRef(null);

  const checkInviteHandler = () => {
    let challengeParticipant = challengeDetail?.challengeParticipants?.find(
      (participant) => participant?.id === auth?.user?.id
    );
    if (challengeParticipant && challengeDetail?.type === "Group") {
      return true;
    }
    return false;
  };
  //   useEffect(() => {
  //     executeScroll();
  //   }, []);
  //   var makeInViewport = function (elemId) {
  //     let bounding = document.getElementById(elemId).getBoundingClientRect();
  //     console.log("bounding",bounding)
  //     console.log("window.innerHeight ",window.innerHeight )
  //     console.log("document.documentElement.clientHeight ",document.documentElement.clientHeight)
  //     if (
  //       !(
  //         bounding.top >= 60 &&
  //         bounding.left >= 0 &&
  //         bounding.bottom <=
  //           (window.innerHeight || document.documentElement.clientHeight) &&
  //         bounding.right <=
  //           (window.innerWidth || document.documentElement.clientWidth)
  //       )
  //     ) {
  //       if (bounding.top < 80) {
  //         window.scrollBy(0, -160 ,{top:-100});
  //         console.log("2",bounding.top - 160);

  //       } else if (
  //         bounding.bottom > window.innerHeight ||
  //         document.documentElement.clientHeight
  //       ) {
  //         window.scrollBy(0, -(window.innerHeight - bounding.bottom),{top:-100});
  //         console.log("2",-(window.innerHeight - bounding.bottom));
  //       }
  //     }
  //   };
  //   useEffect(() => {
  //     makeInViewport("Full_Card");
  //     makeInViewport("top");
  //   }, []);
  return (
    <Grid
      container
      style={{ backgroundColor: "#E5E5E5", paddingBottom: "60px" }}
    >
      <Grid item xs={12}>
        <Box className={classes.container}>
          <Grid container>
            <Grid
              item
              xs={2}
              className={classes.flexContent}
              onClick={() => history.goBack()}
            >
              <ArrowBack style={{ marginTop: "7px" }} />
            </Grid>
            <Grid item xs={8} className={classes.flexContent}>
              <Typography className={classes.heading}>
                <Truncate lines={3} ellipsis={<span>...</span>}>
                  {challengeDetail?.title}
                </Truncate>
              </Typography>
            </Grid>
            <Grid item xs={2} className={classes.flexContent}>
              {checkInviteHandler() === true && (
                <Link to={`/groupChat/${challengeDetail?.id}`}>
                  <img src={chatBtn} alt="" />
                </Link>
              )}
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        style={{ margin: "110px 0px 15px 0px", padding: "10px 15px 0px 15px" }}
      >
        <ChallengeDetail />
      </Grid>
    </Grid>
  );
}

export default ViewChallengeDetails;

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    height: "110px",
    backgroundColor: "#FFF",
    position: "fixed",
    zIndex: 200,
    top:0
  },
  heading: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "24px",
    lineHeight: "28px",
    color: "#3C4A6B",
    margin: "auto",
  },

  flexContent: {
    height: "110px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));
