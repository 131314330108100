// types
import {

  GET_PENDING_REQUESTS,
  APPROVE_REQUESTS,
  REJECT_REQUESTS,
  SEARCH_FRIENDS,
  SEND_REQUEST,
  GET_USER_FRIENDS,
  GET_ALL_INVITATIONS,
  APPROVE_INVITATION,
  REJECT_INVITATION,
  SEARCH_USERS,
  GET_USER_FOLLOWINGS,
  GET_USER_FOLLOWERS

} from "../actions/types";

// initial state
const initialState = {
  pendingRequests: [],
  searchFriends: [],
  searchUsers: [],
  userFriends: [],
  allInvitations:[],
  userFollowers:[],
  userFollowings:[]
};

// handle actions
export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {

    case GET_PENDING_REQUESTS:
      return {
        ...state,
        pendingRequests: payload
      };

    case APPROVE_REQUESTS:
      let requests = state?.pendingRequests.map(request => {
        if (request.id === payload.id) {
          return {
            ...request,
            status: 'accepted'
          }
        }
        return request

      })
      return {
        ...state,
        userFriends: [...state.userFriends, payload],
        pendingRequests: requests
      };
    case REJECT_REQUESTS:
      let filterRejectRequest = state?.pendingRequests.filter((request) => request.id !== payload.id)
      return {
        ...state,
        pendingRequests: filterRejectRequest
      };
    case APPROVE_INVITATION:
      let invitations = state?.allInvitations.map(invitation => {
        if (invitation.id === payload.id) {
          return {
            ...invitation,
            status: 'Accepted'
          }
        }
        return invitation

      })
      return {
        ...state,
        allInvitations: invitations
      };

    case REJECT_INVITATION:
      let filterRejectInvitation = state?.allInvitations.filter((invitation) => invitation.id !== payload.id)
      return {
        ...state,
        allInvitations: filterRejectInvitation
      };

    case SEARCH_FRIENDS:
      return {
        ...state,
        searchFriends: payload
      };
    case SEARCH_USERS:
      return {
        ...state,
        searchUsers: payload
      };
    case GET_USER_FRIENDS:
      return {
        ...state,
        userFriends: payload
      };
    case SEND_REQUEST:
      let filterFriends = state?.searchFriends.map(friend => {
        if (friend?.id === payload?.requestReceiverId) {
          return {
            ...friend,
            status: 'sent'
          }
        }
        return friend

      })
      return {
        ...state,
        searchFriends: filterFriends
      };
    case GET_ALL_INVITATIONS:
      return {
        ...state,
        allInvitations: payload
      };
    case GET_USER_FOLLOWERS:
      return {
        ...state,
        userFollowers: payload
      };
    case GET_USER_FOLLOWINGS:
      return {
        ...state,
        userFollowings: payload
      };
    default:
      return state;
  }
}
