import React, { Suspense, lazy } from "react";
import ConfigProvider from "../../providers/Config/Provider";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core/styles";
import theme from "../../configs/theme";
import { PersistGate } from "redux-persist/lib/integration/react";
import { Provider } from "react-redux";
import storeConfig from "../../store";
import Layout from "../../containers/Layout/Layout";
import ReactGA from "react-ga";

const App = ({ config: appConfig }) => {
  const config = { ...appConfig };
  const { components, containers } = config;
  const { store, persistor } = storeConfig;
  const { Loading } = components || {};
  const { AppContainer = React.Fragment } = containers || {};

  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <ConfigProvider appConfig={config}>
            <AppContainer>
              <Router>
                <Switch>
                  <Route component={Layout} />
                </Switch>
              </Router>
            </AppContainer>
          </ConfigProvider>
        </PersistGate>
      </Provider>
    </ThemeProvider>
  );
};

export default App;
