import API from "../utils/API";


import {
  SET_SESSION,
  SET_ALL_SESSIONS,
  SHOW_MESSAGE,
  ADD_SESSION_LOCAL,
  GET_FRIENDS_SESSION,
  SELECTED_SESSION,
  DELETE_SESSION,
  GET_SESSION_DETAIL,
  SESSION_LOADER,
  JOIN_SESSION,
  GET_ALL_CREATED_JOINED_SESSIONS,
  NEW_CREATED_SESSION,
  GET_RECENT_SESSIONS,
  LOADER,
  SESSION_STATS,
  ADD_PARTICIPANTS_IN_CHALLENGE,
  SESSION_PARTICIPANTS
} from "./types";

export const addSessionLocal = (data) => async (dispatch) => {
  dispatch({
    type: ADD_SESSION_LOCAL,
    payload: data.payload,
  });
};
export const selectedSession = (data) => async (dispatch) => {
  console.log("details", data);
  dispatch({
    type: SELECTED_SESSION,
    payload: data,
  });
};

export const getSessionDetails = ({ userid, token, id }) => async (
  dispatch
) => {
  // set header
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    dispatch({
      type: SESSION_LOADER,
      payload: { showLoader: true, isAdded: false },
    });
    let res = await API.get(
      `/api/v1/session/get-Session-details/${id}`,
      config
    );
    let sessionParticipants = res?.data?.data?.invitations?.map(participant=>{
      return {
        ...participant?.invitationReceivers,
        status:'sent',
        invited:true
      }
    });
    dispatch({
      type: ADD_PARTICIPANTS_IN_CHALLENGE,
      payload: [...sessionParticipants],
    });
    res = res.data.data;
    const found = res?.sessionParticipants?.find(
      (element) => element.id === userid
    );
    let isJoined = false;
    if (found !== undefined) {
      isJoined = true;
    } else {
      isJoined = false;
    }
    dispatch({
      type: JOIN_SESSION,
      payload: res?.isJoined,
    });

    dispatch({
      type: GET_SESSION_DETAIL,
      payload: res,
    });

   
    dispatch({
      type: SESSION_LOADER,
      payload: { showLoader: false, isAdded: false },
    });

    return {status: 'success'}

  } catch (error) {
    dispatch({
      type: SESSION_LOADER,
      payload: { showLoader: false, isAdded: false },
    });
    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        title: `Failed`,
        message:
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : ` failed`,
        severity: "error",
      },
    });
    console.log(error);
    return {status: 'fail',message: error?.response?.data?.message? error?.response?.data?.message : 'something went wrong'}
  }
};

// Get session participants
export const getSessionParticipant = ({ token, id }) => async (dispatch) => {
 
  // set header
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const res = await API.get(`/api/v1/session/get-Session-participants/${id}`, config);
    
    dispatch({
      type: SESSION_PARTICIPANTS,
      payload: res.data.data,
    });
   
  } catch (error) {
    
    console.log(error);
  }
};

// get all sessions
export const getAllSessions = ({ token }) => async (dispatch) => {
  dispatch({
    type: SESSION_LOADER,
    payload: { showLoader: true, isAdded: false },
  });
  // set header
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const res = await API.get(`/api/v1/session/get-all-sessions`, config);
    let data1 = res.data.data.sort(function (a, b) {
      return b.id - a.id;
    });
    dispatch({
      type: SESSION_LOADER,
      payload: { showLoader: false, isAdded: false },
    });
    dispatch({
      type: SET_ALL_SESSIONS,
      payload: data1,
    });
  } catch (error) {
    dispatch({
      type: SESSION_LOADER,
      payload: { showLoader: false, isAdded: false },
    });
    console.log(error);
  }
};

// Get recent sessions
export const getRecentSessions = ({ token, page, limit}) => async (dispatch) => {
  dispatch({
    type: LOADER,
    payload: true,
  });
  // set header
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    params:{
      page:page || undefined,
        limit: limit || undefined
    }
  };

  try {
    const res = await API.get(`/api/v1/session/get-recent-sessions`, config);
    dispatch({
      type: GET_RECENT_SESSIONS,
      payload: res.data.data,
    });
    dispatch({
      type: LOADER,
      payload: false,
    });
    
  } catch (error) {
    dispatch({
      type: LOADER,
      payload: false,
    });
    console.log(error);
  }
};

export const createSession = ({ token, session, image }) => async (
  dispatch
) => {
  dispatch({
    type: SESSION_LOADER,
    payload: { showLoader: true, isAdded: false },
  });
  // set header
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const body = JSON.stringify({ ...session });

    const res = await API.post(`/api/v1/session/`, body, config);
    if (res.data.data && image !== "") {
      dispatch(
        addImage({
          id: res.data.data.id,
          image: image,
          token,
        })
      );
    }

    console.log("res", res, session);
    dispatch({
      type: SET_SESSION,
      payload: { ...res.data.data },
    });
    dispatch({
      type: ADD_SESSION_LOCAL,
      payload: { ...res.data.data },
    });
    
    dispatch({type:NEW_CREATED_SESSION,payload:res.data.data})
    dispatch({
      type: GET_SESSION_DETAIL,
      payload: {},
    });
    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        title: `success`,
        message: res.data.message,
        severity: "success",
      },
    });

    return 'success'
  } catch (error) {
    dispatch({
      type: SESSION_LOADER,
      payload: { showLoader: false, isAdded: false },
    });
    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        title: `Failed`,
        message:
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : ` failed`,
        severity: "error",
      },
    });
    return 'fail'

  }
};

export const addImage = ({ image, id, token }) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  // set body
  let formData = new FormData();
  formData.append("image", image);
  const body = formData;
  try {
    const res = await API.post(
      `/api/v1/session/upload-session-image?sessionId=${id}`,
      body,
      config
    );
    dispatch({
      type: ADD_SESSION_LOCAL,
      payload: { ...res.data.data },
    });
    
    
  } catch (error) {
    console.log(error);
  }
};

export const getFriendsSessions = ({ token }) => async (dispatch) => {
  // set header
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const res = await API.get(`/api/v1/session/get-friend-sessions`, config);
    dispatch({
      type: GET_FRIENDS_SESSION,
      payload: res.data.data,
    });
  } catch (error) {
    console.log(error);
  }
};

export const updateSession = ({ token, session, image, id }) => async (
  dispatch
) => {
  // set header
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  dispatch({
    type: SESSION_LOADER,
    payload: { showLoader: true, isAdded: false },
  });
  try {
    const body = JSON.stringify({ ...session });
    const res = await API.patch(
      `/api/v1/session/update-session/${id}`,
      body,
      config
    );
    if (res.data.data && image !== "") {
      dispatch(
        addImage({
          id: res.data.data.id,
          image: image,
          token,
        })
      );
    }

    dispatch({
      type: SET_SESSION,
      payload: { ...res.data.data },
    });
    dispatch({
      type: ADD_SESSION_LOCAL,
      payload: { ...res.data.data },
    });
    
    dispatch({type:NEW_CREATED_SESSION,payload:res.data.data})
    dispatch({
      type: GET_SESSION_DETAIL,
      payload: {},
    });
    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        title: `success`,
        message: res.data.message,
        severity: "success",
      },
    });
    return 'success'
  } catch (error) {
    dispatch({
      type: SESSION_LOADER,
      payload: { showLoader: false, isAdded: false },
    });
    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        title: `Failed`,
        message:
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : ` failed`,
        severity: "error",
      },
    });
    return 'fail'
  }
};

export const deleteSession = ({ token, id }) => async (dispatch) => {
  // set header
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const res = await API.delete(
      `/api/v1/session/delete-session/${id}`,
      config
    );
    console.log("res", res);
    dispatch({
      type: DELETE_SESSION,
      payload: id,
    });
    console.log("next step message", res);

    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        title: `success`,
        message: res?.data?.message,
        severity: "success",
      },
    });
    console.log("res", res);
  } catch (error) {
    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        title: `Failed`,
        message:
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : ` failed`,
        severity: "error",
      },
    });
  }
};

export const joinSession = ({ token, user, data }) => async (dispatch) => {
  // set header
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const body = JSON.stringify({ ...data });

    const res = await API.post(
      `/api/v1/challenge/join-sessionOrChallenge`,
      body,
      config
    );
    console.log("join", res);
    dispatch({
      type: JOIN_SESSION,
      payload: true,
    });
    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        title: `success`,
        message: res.data.message,
        severity: "success",
      },
    });
    return {success: true};
  } catch (error) {
    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        title: `Failed`,
        message:
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : ` failed`,
        severity: "error",
      },
    });
  }
};

export const joinUnderSubscriptionSession = ({ token, user, data }) => async (dispatch) => {
  // set header
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const body = JSON.stringify({ ...data });

    const res = await API.post(
      `/api/v1/session/join-under-subscription-session`,
      body,
      config
    );
    dispatch({
      type: JOIN_SESSION,
      payload: true,
    });
    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        title: `success`,
        message: res.data.message,
        severity: "success",
      },
    });
    return {success: true};
  } catch (error) {
    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        title: `Failed`,
        message:
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : ` failed`,
        severity: "error",
      },
    });
  }
};


// get all joined and created sessions
export const getAllJoinedAndCreatedSessions = ({ token }) => async (dispatch) => {
  dispatch({
    type: SESSION_LOADER,
    payload: { showLoader: true, isAdded: false },
  });
  // set header
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    }
  };
 

  try {
    const res = await API.get(`/api/v1/session`, config);
    
    dispatch({
      type: SESSION_LOADER,
      payload: { showLoader: false, isAdded: false },
    });
    dispatch({
      type: GET_ALL_CREATED_JOINED_SESSIONS,
      payload: res.data.data,
    });
  } catch (error) {
    dispatch({
      type: SESSION_LOADER,
      payload: { showLoader: false, isAdded: false },
    });
    console.log(error);
  }
};

// get sessions stats
export const getSessionStats = ({ token,sessionId }) => async (dispatch) => {
  dispatch({
    type: LOADER,
    payload: true,
  });
  // set header
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    params:{
      sessionId
    }
  };
  try {
    const res = await API.get(`/api/v1/session/get-session-stats`, config);
    dispatch({
      type: SESSION_STATS,
      payload: res.data.data,
    });
    dispatch({
      type: LOADER,
      payload: false,
    });
  } catch (error) {
    dispatch({
      type: LOADER,
      payload: false,
    });
    console.log(error);
  }
};


// session  active deactivate status

export const sessionActiveInActive = ({ token, sessionId,status }) => async (
  dispatch
) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  const body = JSON.stringify({ sessionId,status });
  try {
    // login user
    const res = await API.patch(
      `/api/v1/session/change-session-status`,
      body,
      config
    );
    dispatch(getSessionDetails({token,id:sessionId}))
    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        title: `success`,
        message: res.data.message,
        severity: "success",
      },
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        title: `Failed`,
        message:
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : `change status failed`,
        severity: "error",
      },
    });
  }
};