import React,{useEffect} from "react";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ConnectsMobileContainer from "../../../containers/Mobile/More/Connects";
import MoreWebContainer from "../../../containers/Web/More";
import {useSelector, useDispatch} from 'react-redux'
import {
  getPendingRequests,
  getAllInvitations,
  getUserFollowers,
  getUserFollowings
} from "../../../actions/userRelations";
import { getUsersFriends } from "../../../actions/user";

function Connects(props) {
  const theme = useTheme();
  let dispatch = useDispatch();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const authReducer = useSelector((state) => state?.auth);
const loader = true
  useEffect(() => {
    dispatch(getUsersFriends(authReducer?.token));
    dispatch(getPendingRequests(authReducer?.token));
    dispatch(getAllInvitations(authReducer?.token, loader));
    dispatch(getUserFollowers(authReducer?.token));
    dispatch(getUserFollowings(authReducer?.token));
  })
 
  return (
    <>
      {isDesktop && <MoreWebContainer />}
      {isMobile && !isDesktop && <ConnectsMobileContainer />}
    </>
  );
}

export default Connects;
