import React, { useState, useEffect } from "react";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import {
  AppBar,
  Tab,
  Tabs,
  Box,
  Button,
  FormControl,
  Select,
  Modal,
  MenuItem,
  FormHelperText
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { useIntl } from "react-intl";
import {
  getAllSessions,
  getFriendsSessions,
  selectedSession
} from "../../../actions/Session";
import { Toolbar, IconButton } from "@material-ui/core";
import SessionCard from "../../../components/Common/Session/SessionCard/index";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ArrowBack } from "@material-ui/icons";
import Calender from ".././../../assets/Icons/calender.svg";
const StyledTabs = withStyles(theme => ({
  root: {
    width: "100%"
  },
  indicator: {
    display: "flex",
    height: "0.2rem",
    justifyContent: "center",
    backgroundColor: "transparent",
    "& > span": {
      width: "100%",
      backgroundColor: theme.colors.selected
    }
  }
}))(props => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

const StyledTab = withStyles(theme => ({
  root: {
    minWidth: "60px !important",

    textTransform: "none",
    color: theme.colors.selected,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    "&:focus": {
      opacity: 1
    }
  }
}))(props => <Tab disableRipple {...props} />);
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <>{children}</>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function Sessions(props) {
  const classes = useStyles();
  const intl = useIntl();
  const theme = useTheme();
  let dispatch = useDispatch();
  let history = useHistory();

  const authReducerState = useSelector(state => state.auth);
  const sessionsList = useSelector(state => state.session.sessionsList);
  const friendSessionList = useSelector(
    state => state.session.friendSessionList
  );
  const [value, setValue] = React.useState(0);
  const [optionlist] = useState([
    { name: "All" },
    { name: "Completed" },
    { name: "Available" }
  ]);
  const [option, setOption] = useState("All");
  console.log("state", authReducerState);

  useEffect(() => {
    dispatch(
      getAllSessions({ token: authReducerState && authReducerState.token })
    );
    dispatch(
      getFriendsSessions({
        token: authReducerState && authReducerState.token
      })
    );
  }, []);
  const getFomatatedTime = value => {
    let hours =
      parseInt((value * 15) / 60) > 12
        ? parseInt((value * 15) / 60) - 12
        : parseInt((value * 15) / 60);
    let min = ((value * 15) / 60 - parseInt((value * 15) / 60)) * 60;
    let ampm = parseInt((value * 15) / 60) > 12 ? "PM" : "AM";

    return `${hours}: ${min} ${ampm}`;
  };
  const handleSelect = event => {
    setOption(event.target.value);
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [modalStyle] = useState(getModalStyle);
  function getModalStyle() {
    const top = 50;
    const left = 50;
    return {
      borderColor: theme.colors.white,
      borderRadius: "4px",
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top - 4}%, -${left}%)`
    };
  }
  const [openModel, setOpenModel] = useState(false);
  const handleCloseModal = () => {
    setOpenModel(false);
  };
  const handleOpenModal = () => {
    setOpenModel(true);
  };
  const goDetails = (page, session) => {
    dispatch(
      selectedSession({
        data: session
      })
    );
    if (page === "Session") {
      history.push({
        pathname: `/session`,

        editSession: true
      });
    }
    if (page === "SessionDetails") {
      history.push({
        pathname: `more/session/details/${session.id}`,

        editSession: true
      });
    }
  };

  const modalBody = (
    <Grid
      container
      direction="row"
      spacing={3}
      style={modalStyle}
      className={classes.paper}
    >
      <Grid item className={classes.msgBody}>
        <Typography className={classes.modelBodyText}>
          {intl.formatMessage({ id: "sessionNotStartedYet" })}
        </Typography>
      </Grid>
      <Grid item className={classes.modalBtnGrid}>
        <Button onClick={() => handleCloseModal()} className={classes.modelBtn}>
          {intl.formatMessage({ id: "okay" })}
        </Button>
      </Grid>
    </Grid>
  );
  return (
    <Grid
      container
      style={{ backgroundColor: "rgb(251, 250, 249)", marginBottom: "60px" }}
    >
      {/*app bar*/}
      <Grid container>
        <Grid item xs={12}>
          <AppBar
            className={classes.appBar}
            style={{ backgroundColor: "#fff" }}
          >
            <Grid
              item
              xs={2}
              style={{ justifyContent: "center", display: "flex" }}
            >
              <IconButton
                onClick={() => history.push("/more/relate-with-friends")}
                className={classes.backButton}
              >
                <ArrowBack />
              </IconButton>
            </Grid>
            <Grid item xs={8}>
              <Toolbar>
                <Typography className={classes.appBarText} variant="h6">
                  {intl.formatMessage({ id: "sessions" })}
                </Typography>
              </Toolbar>
            </Grid>
            <Grid item xs={2}>
              <Toolbar>{/* <img src={Calender} alt="" /> */}</Toolbar>
            </Grid>
          </AppBar>
        </Grid>
      </Grid>
      <Grid container style={{ backgroundColor: "#fff" }}>
        <StyledTabs
          value={value}
          variant="fullWidth"
          onChange={handleChange}
          aria-label="styled tabs example"
        >
          <StyledTab label={intl.formatMessage({ id: "myRelateSessions" })} />
          <StyledTab label={intl.formatMessage({ id: "FriendSession" })} />
        </StyledTabs>
      </Grid>
      <TabPanel
        value={value}
        index={0}
        style={{ width: "100%", minHeight: "100vh" }}
      >
        <Grid item xs={12} container alignItems="center">
          <Grid item xs={7}>
            <Typography className={classes.appBartext}>
              {intl.formatMessage({ id: "showing" })}{" "}
              {sessionsList &&
                sessionsList.filter(a =>
                  option === "All"
                    ? a.isExpired !== null
                    : a.isExpired === (option === "Completed" ? true : false)
                ).length}{" "}
              {intl.formatMessage({ id: "results" })}
            </Typography>
          </Grid>
          <Grid item xs={5}>
            <FormControl className={classes.inputField}>
              <Select
                className={classes.selectCategory}
                style={{ width: "100%" }}
                classes={{
                  iconFilled: classes.iconSelect,
                  icon: classes.iconSelect
                }}
                value={option}
                onChange={handleSelect}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
              >
                {optionlist?.map((op, ind) => {
                  return (
                    <MenuItem value={op?.name} key={ind}>
                      {op?.name}
                    </MenuItem>
                  );
                })}
              </Select>

              <FormHelperText style={{ color: "white", textAlign: "center" }} />
            </FormControl>
          </Grid>
        </Grid>
        {sessionsList &&
          sessionsList.filter(a =>
            option === "All"
              ? a.isExpired !== null
              : a.isExpired === (option === "Completed" ? true : false)
          ).length === 0 ? (
          <Grid item xs={12}>
            <Typography className={classes.noContent}>
              No Record found
            </Typography>
          </Grid>
        ) : (
          <Grid
            container
            style={{ marginTop: "20px" }}
            xs={12}
            lg={12}
            spacing={2}
            className={classes.adjustCardsLayout}
          >
            {sessionsList &&
              sessionsList
                .filter(a =>
                  option === "All"
                    ? a.isExpired !== null
                    : a.isExpired === (option === "Completed" ? true : false)
                )
                .map((n, i) => {
                  return (
                    <Grid
                      item
                      xs={12}
                      sm={5}
                      lg={5}
                      key={i}

                    >
                      <SessionCard
                        goDetails={goDetails}
                        session={n}
                        image={n && n.imageUrl}
                        name={n && n.name}
                        friends={`${n.sessionParticipants &&
                          n.sessionParticipants.length} friends`}
                        totalDuration={n && n.duration}
                        time={getFomatatedTime(n && n.startTime)}
                      />
                    </Grid>
                  );
                })}
          </Grid>
        )}
      </TabPanel>

      <TabPanel
        value={value}
        index={1}
        style={{ width: "100%", minHeight: "100vh" }}
      >
        <Grid item xs={12} container alignItems="center">
          <Grid item xs={7}>
            <Typography className={classes.appBartext}>
              {intl.formatMessage({ id: "showing" })}{" "}
              {
                friendSessionList?.filter(a =>
                  option === "All"
                    ? a?.session?.isExpired !== null
                    : a?.session?.isExpired ===
                    (option === "Completed" ? true : false)
                )?.length
              }{" "}
              {intl.formatMessage({ id: "results" })}
            </Typography>
          </Grid>
          <Grid item xs={5}>
            <FormControl className={classes.inputField}>
              <Select
                className={classes.selectCategory}
                style={{ width: "100%" }}
                classes={{
                  iconFilled: classes.iconSelect,
                  icon: classes.iconSelect
                }}
                value={option}
                onChange={handleSelect}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
              >
                {optionlist?.map((op, ind) => {
                  return (
                    <MenuItem value={op?.name} key={ind}>
                      {op?.name}
                    </MenuItem>
                  );
                })}
              </Select>

              <FormHelperText style={{ color: "white", textAlign: "center" }} />
            </FormControl>
          </Grid>
        </Grid>
        {friendSessionList &&
          friendSessionList.filter(a =>
            option === "All"
              ? a?.session?.isExpired !== null
              : a?.session?.isExpired === (option === "Completed" ? true : false)
          ).length === 0 ? (
          <Grid item xs={12}>
            <Typography className={classes.noContent}>
              No Record found
            </Typography>
          </Grid>
        ) : (
          <Grid
            container
            style={{ marginTop: "20px" }}
            xs={12}
            lg={12}
            spacing={2}
            className={classes.adjustCardsLayout}
          >
            {friendSessionList &&
              friendSessionList
                .filter(a =>
                  option === "All"
                    ? a?.session?.isExpired !== null
                    : a?.session?.isExpired ===
                    (option === "Completed" ? true : false)
                )
                .map((n, i) => {
                  return (
                    <Grid
                      item
                      xs={12}
                      sm={5}
                      lg={3}
                      key={i}

                    >
                      <SessionCard
                        image={n && n.session && n.session.imageUrl}
                        goDetails={goDetails}
                        session={n.session}
                        name={n && n.session && n.session.name}
                        friends={
                          n?.session?.sessionParticipants?.length === 0
                            ? "no friends"
                            : n?.session?.sessionParticipants?.length > 1
                              ? `${n?.session?.sessionParticipants?.length} friends`
                              : `${n?.session?.sessionParticipants?.length} friend`
                        }
                        totalDuration={n && n.session && n.session.duration}
                        time={getFomatatedTime(
                          n && n.session && n.session.startTime
                        )}
                      />
                    </Grid>
                  );
                })}
          </Grid>
        )}
      </TabPanel>
      <Grid container>
        <Modal
          disableEnforceFocus={true}
          open={openModel}
          onClose={handleCloseModal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          {modalBody}
        </Modal>
      </Grid>
    </Grid>
  );
}

export default Sessions;

const useStyles = makeStyles(theme => ({
  appBar: {
    color: "#000",
    flexDirection: "row",
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    right: "unset",
    position: "relative",
    boxShadow: "none"
  },

  appBarText: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: "900",
    fontSize: "20px",
    lineHeight: "23px"
  },

  backButton: {
    background: "transparent",
    color: "#000",
    padding: "0px"
  },
  appBartext: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "italic",
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "21px",

    color: theme.colors.selected
  },
  inputField: {
    width: "100%",
    background: theme.colors.selected,
    borderRadius: "82px"
  },

  selectCategory: {
    background: theme.colors.selected,
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontSize: "14px",
    lineHeight: "16px",
    textAlign: "center",
    height: "41px",
    width: "156px",

    borderRadius: "20px",
    fontWeight: 500,
    color: "#ffffff",
    paddingRight: "10px",
    "&.MuiInput-underline::before": {
      borderBottom: "none"
    },
    "&.MuiInput-underline:after": {
      borderBottom: "none"
    },
    "&.MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottom: "none"
    },
    "& .MuiSelect-icon": {
      color: "#ffffff !important",
      paddingRight: "10px"
    }
  },
  iconSelect: {
    color: "#ffffff",
    paddingRight: "10px",
    variant: "filled"
  },
  adjustCardsLayout: {
    "&.MuiGrid-spacing-xs-2": { margin: "0px !important" },
    justifyContent: "center"
  },
  hideScrollBar: {
    flexWrap: "unset",
    overflowX: "auto",
    "&::-webkit-scrollbar": {
      display: "none"
    }
  },
  paper: {
    position: "absolute",
    height: 330,
    maxWidth: 315,
    minWidth: 300,
    justifyContent: "center",
    backgroundColor: theme.palette.background.paper,
    outline: "none"
  },

  modelBodyText: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "17px",
    lineHeight: "27px",
    textAlign: "center",
    letterSpacing: "0.02em",
    color: "#192247",
    overflow: "auto"
  },

  modelBtn: {
    height: "73px",
    width: "100%",
    fontFamily: theme.fontFamily.Roboto,
    cursor: "pointer",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "21px",
    color: theme.colors.white
  },

  modalBtnGrid: {
    padding: "0px",
    alignSelf: "flex-end",
    color: "#fff",
    cursor: "pointer",
    background: `linear-gradient(#09A9BB 1%,#09A9BB 100%)`,
    height: "73px",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    display: "flex"
  },
  msgBody: {
    minHeight: "240px",
    alignItems: "center",
    display: "flex"
  },
  noContent: {
    color: "#7d7b79",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "19px",
    padding: "12px 10px 10px 17px",
    textAlign: "center"
  }
}));
