import API from "../utils/API";

import {
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  AUTH_LOADER,
  SHOW_MESSAGE,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  LOADER,
} from "./types";

import { getCurrentLoggedInUser } from "./user";

// forgot password

export const resendEmail = (email) => async (dispatch) => {
  dispatch({
    type: LOADER,
    payload: true,
  });
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    // login user
    const body = JSON.stringify({
      email,
    });
    const res = await API.post(
      `/api/v1/auth/sendVerificationEmailLink`,
      body,
      config
    );

    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        title: `success`,
        message: res.data.message,
        severity: "success",
      },
    });
    dispatch({
      type: LOADER,
      payload: false,
    });
    return { status: 200 };
  } catch (error) {
    dispatch({
      type: LOADER,
      payload: false,
    });
    console.log(error);
    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        title: `Failed`,
        message:
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : ` failed`,
        severity: "error",
      },
    });
    return { status: 400 };
  }
};
export const forgotPassword = (email) => async (dispatch) => {
  dispatch({
    type: LOADER,
    payload: true,
  });
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    // login user
    const body = JSON.stringify({
      email,
    });
    const res = await API.post(`/api/v1/auth/forgotPassword`, body, config);

    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        title: `success`,
        message: res.data.message,
        severity: "success",
      },
    });
    dispatch({
      type: LOADER,
      payload: false,
    });
  } catch (error) {
    dispatch({
      type: LOADER,
      payload: false,
    });
    console.log(error);
    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        title: `Failed`,
        message:
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : ` failed`,
        severity: "error",
      },
    });
  }
};

//reset password
export const resetPassword =
  ({ password, confirmPassword, token, history }) =>
  async (dispatch) => {
    dispatch({
      type: LOADER,
      payload: true,
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
      params: { token },
    };

    try {
      // login user
      const body = JSON.stringify({
        password,
        confirmPassword,
      });
      const res = await API.patch(`/api/v1/auth/resetPassword`, body, config);
      dispatch({
        type: RESET_PASSWORD,
        payload: res.data.data?.resetURL,
      });

      dispatch({
        type: SHOW_MESSAGE,
        payload: {
          title: `success`,
          message: res.data.message,
          severity: "success",
        },
      });
      dispatch({
        type: LOADER,
        payload: false,
      });
      history.push("/auth");
    } catch (error) {
      dispatch({
        type: LOADER,
        payload: false,
      });
      console.log(error);
      dispatch({
        type: SHOW_MESSAGE,
        payload: {
          title: `Failed`,
          message:
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : ` failed`,
          severity: "error",
        },
      });
    }
    };

    // login user
export const loginOTP=
({
  email,
  password,
  username,
  url,
  firstName,
  lastName,
  role,
  title,
  websiteUrl,
  facebookUrl,
  instagramUrl,
  youtubeChannelUrl,
  recaptcha_token,
}) =>
async (dispatch) => {
  dispatch({
    type: AUTH_LOADER,
    payload: true,
  });
  // set header
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  // set body
  const body = JSON.stringify({
    email,
    password,
  });

  try {
    // login user
    const res = await API.post(`/api/v1/auth/otp-login`, body, config);
    if (res.data?.data?.token) {
      localStorage.setItem("userDataLocal", JSON.stringify(res.data.data));
      dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data.data,
      });
      dispatch(getCurrentLoggedInUser({ token: res.data.data.token }));
      if (res.data.status) {
        dispatch({
          type: SHOW_MESSAGE,
          payload: {
            title: `${url} Success`,
            message:
              res && res.data && res.data.message
                ? res.data.message
                : `${url} Success`,
            severity: "success",
          },
        });
      }
    } else if (res.data.status) {
      dispatch({
        type: SHOW_MESSAGE,
        payload: {
          title: `${url} Success`,
          message:
            res && res.data && res.data.message
              ? res.data.message
              : `${url} Success`,
          severity: "success",
        },
      });
    }

    dispatch({
      type: AUTH_LOADER,
      payload: false,
    });
    return res.data;
  } catch (error) {
    dispatch({
      type: AUTH_LOADER,
      payload: false,
    });
    dispatch({
      type: LOGIN_FAILURE,
      payload:
        error.response && error.response.data ? error.response.data : "error",
    });
    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        title: `${url} Failed`,
        message:
          error.response &&
          error.response.data &&
          error.response.data.errors &&
          error.response.data.errors.length
            ? `${url} Failed`
            : error.response &&
              error.response.data &&
              error.response.data.message
            ? error.response.data.message
            : `${url} Failed`,
        severity: "error",
      },
    });
  }
};

// login user
export const loginUser =
  ({
    email,
    password,
    username,
    url,
    firstName,
    lastName,
    role,
    title,
    websiteUrl,
    facebookUrl,
    instagramUrl,
    youtubeChannelUrl,
    recaptcha_token,
  }) =>
  async (dispatch) => {
    dispatch({
      type: AUTH_LOADER,
      payload: true,
    });
    // set header
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    // set body
    const body = JSON.stringify({
      email,
      password,
      firstName,
      lastName,
      username,
      role,
      title,
      websiteUrl,
      facebookUrl,
      instagramUrl,
      youtubeChannelUrl,
      recaptcha_token,
    });

    try {
      // login user
      const res = await API.post(`/api/v1/auth/${url}`, body, config);
      if (res.data?.data?.token) {
        localStorage.setItem("userDataLocal", JSON.stringify(res.data.data));
        dispatch({
          type: LOGIN_SUCCESS,
          payload: res.data.data,
        });
        dispatch(getCurrentLoggedInUser({ token: res.data.data.token }));
      /*   if (res.data.status) {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              title: `${url} Success`,
              message:
                res && res.data && res.data.message
                  ? res.data.message
                  : `${url} Success`,
              severity: "success",
            },
          });
        } */
      } /* else if (res.data.status) {
        dispatch({
          type: SHOW_MESSAGE,
          payload: {
            title: `${url} Success`,
            message:
              res && res.data && res.data.message
                ? res.data.message
                : `${url} Success`,
            severity: "success",
          },
        });
      } */

      dispatch({
        type: AUTH_LOADER,
        payload: false,
      });
      return res.data;
    } catch (error) {
      dispatch({
        type: AUTH_LOADER,
        payload: false,
      });
      dispatch({
        type: LOGIN_FAILURE,
        payload:
          error.response && error.response.data ? error.response.data : "error",
      });
      dispatch({
        type: SHOW_MESSAGE,
        payload: {
          title: `${url} Failed`,
          message:
            error.response &&
            error.response.data &&
            error.response.data.errors &&
            error.response.data.errors.length
              ? `${url} Failed`
              : error.response &&
                error.response.data &&
                error.response.data.message
              ? error.response.data.message
              : `${url} Failed`,
          severity: "error",
        },
      });
    }
  };

export const socialLoginUser =
  ({ access_token, url, role }) =>
  async (dispatch) => {
    dispatch({
      type: AUTH_LOADER,
      payload: true,
    });
    // set header
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    // set body
    const body = JSON.stringify({ access_token, role });

    try {
      // login user
      const res = await API.post(`/api/v1/auth/${url}`, body, config);

      localStorage.setItem("userDataLocal", JSON.stringify(res.data.data));
      dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data.data,
      });
      dispatch(getCurrentLoggedInUser({ token: res.data.data.token }));
    } catch (error) {
      dispatch({
        type: AUTH_LOADER,
        payload: false,
      });
      dispatch({
        type: LOGIN_FAILURE,
        payload:
          error.response && error.response.data ? error.response.data : "error",
      });
      console.log(error);
      dispatch({
        type: SHOW_MESSAGE,
        payload: {
          title: `${url} Failed`,
          message:
            error.response &&
            error.response.data &&
            error.response.data.errors &&
            error.response.data.errors.length
              ? `${url} Failed`
              : error.response &&
                error.response.data &&
                error.response.data.message
              ? error.response.data.message
              : `${url} Failed`,
          severity: "error",
        },
      });
    }
  };



// Connect with social
export const socialConnect =
  ({ access_token, url, token }) =>
  async (dispatch) => {
    dispatch({
      type: AUTH_LOADER,
      payload: true,
    });
    // set header
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    // set body
    const body = JSON.stringify({ access_token });

    try {
      const res = await API.patch(`/api/v1/auth/${url}`, body, config);
      dispatch({
        type: SHOW_MESSAGE,
        payload: {
          title: `success`,
          message: res.data.message,
          severity: "success",
        },
      });
      return dispatch(getCurrentLoggedInUser({ token }));
    } catch (error) {
      dispatch({
        type: AUTH_LOADER,
        payload: false,
      });

      dispatch({
        type: SHOW_MESSAGE,
        payload: {
          title: `${url} Failed`,
          message:
            error.response &&
            error.response.data &&
            error.response.data.errors &&
            error.response.data.errors.length
              ? `${url} Failed`
              : error.response &&
                error.response.data &&
                error.response.data.message
              ? error.response.data.message
              : `${url} Failed`,
          severity: "error",
        },
      });
    }
  };
