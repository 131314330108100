import { useEffect } from "react";
import { useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import ChatMessagesMobileContainer from "../../../containers/Mobile/More/Chat/ChatMessages";
import More from "../../../containers/Web/More";
import { useSelector, useDispatch } from "react-redux";
import { getUserChats } from "../../../actions/chat";
import { SocketContextProvider } from '../../../Context/socketContext'



const ChatMessages = () => {
  const theme = useTheme();
  let dispatch = useDispatch();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const authReducer = useSelector(state => state?.auth);




  useEffect(() => {
    dispatch(getUserChats(authReducer?.token));
  }, []);




  return (
    <SocketContextProvider>
      {isDesktop && <More />}
      {isMobile && !isDesktop && <ChatMessagesMobileContainer />}
    </SocketContextProvider>
  );
};

export default ChatMessages;
