import React, { useState, useEffect } from "react";
import { Grid, CircularProgress, Button, TextField } from "@material-ui/core";
import { useForm } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import "./payment.scss";
import { useIntl } from "react-intl";
import Cards from "react-credit-cards";
import "react-credit-cards/es/styles-compiled.css";
import { Number, Cvc, Expiration } from "react-credit-card-primitives";
import { useDispatch, useSelector } from "react-redux";
import { addCustomerCard } from "../../../actions/stripe";
import {useHistory, useLocation} from 'react-router-dom'

function AddPaymentForm(props) {
  const location = useLocation();
  const classes = useStyles();
  const intl = useIntl();
  const history = useHistory();
  const [cardNumber, setCardNumber] = useState("");
  const [name, setName] = useState("");
  const authReducerState = useSelector(state => state.auth);
  const { stripeLoader, stripeSuccess } = useSelector(state => state.stripe);
  const [focused, setFocused] = useState("cardNumber");
  const [expiry, setExpiry] = useState("");
  const [cvc, setCvc] = useState("");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");
  const dispatch = useDispatch();
  const {
    getValues,
    register,
    errors,
    handleSubmit,
    setValue,
    setError
  } = useForm();
  const onSubmit = async data => {
    const res = await dispatch(
      addCustomerCard({
        cardData: {
          number: cardNumber,
          holderName: data.holderName,
          month: month,
          year: year,
          cvv: data.CVV
        },
        token: authReducerState.token
      })
    );
    if (res === 'success') {
      location?.state?.type === 'subscription' ? history.push({pathname:'/more/subscription-plan', state:{id:location?.state?.id, type: location?.state?.type, tab: location?.state?.tab}}) :
      history.goBack();
    }

  };

  useEffect(() => {
    if (stripeSuccess) {
      props.onClick();
      dispatch({ type: "SET_STRIPE_SUCCESS", payload: false });
    }
  }, [stripeSuccess]);

  return (
    <div className={classes.addPaymentCard} id="PaymentForm">

      <Grid container xs={12} item >
        <Cards
          cvc={cvc}
          focused={focused}
          expiry={expiry}
          name={name}
          number={cardNumber}
        />
      </Grid>
      <Grid container xs={12} direction="column">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={classes.root}>
            <Number
              onChange={e => {
                console.log(e);

                setCardNumber(e?.value);
              }}
              render={({ value, valid, type, getInputProps }) => {
                const propsInput = getInputProps();
                return (
                  <TextField
                    name="cc-number"
                    value={propsInput.value}
                    className="textFields"
                    inputRef={register({
                      required: intl.formatMessage({
                        id: "requiredCardNumber"
                      }),
                      validate: () => {
                        if (!valid) {
                          return "Invalid card";
                        }
                      }
                    })}
                    onChange={e => {
                      propsInput.onChange(e);
                    }}
                    onFocus={() => {
                      setFocused("cardNumber");
                    }}
                    autoComplete={false}
                    inputProps={{
                      maxlength: type === "Visa" ? 19 : propsInput.maxlength
                    }}
                    label={intl.formatMessage({ id: "cardNumber" })}
                    helperText={
                      errors["cc-number"] && errors["cc-number"].message
                    }
                    error={!!errors["cc-number"]}
                  />
                );
              }}
            />

            <TextField
              className="textFields"
              inputRef={register({
                required: intl.formatMessage({ id: "requiredCardHolderName" })
              })}
              onFocus={() => {
                setFocused("name");
              }}
              onChange={e => {
                setValue(e.target.name, e.target.value);
                setName(e.target.value);
              }}
              label={intl.formatMessage({ id: "cardHolderName" })}
              name="holderName"
              helperText={errors.holderName && errors.holderName.message}
              error={!!errors.holderName}
              type="text"
            />
            <Grid
              item
              container
              xs={12}
              spacing={1}
              direction="row"
              justify="space-between"
              style={{ textAlign: "center" }}
            >
              <Grid item xs={6}>
                <Expiration
                  onChange={e => {
                    console.log(e);
                    setMonth(e.month);
                    setYear(e.year);
                    setExpiry(
                      e.year
                        ? e.year?.toString()
                        : "  " + e.month
                          ? e.month?.toString()
                          : ""
                    );
                  }}
                  render={({
                    getInputProps,
                    value,
                    valid,
                    error,
                    month,
                    year
                  }) => {
                    const propsInput = getInputProps();
                    return (
                      <TextField
                        {...propsInput}
                        fullWidth
                        inputRef={register({
                          required: intl.formatMessage({ id: "requiredMM" }),
                          validate: () => {
                            if (error) {
                              return "Invalid Date";
                            }
                          }
                        })}
                        inputProps={{
                          maxLength: 7
                        }}
                        onFocus={() => {
                          setFocused("expiry");
                        }}
                        name="expiry"
                        helperText={
                          errors["expiry"] && errors["expiry"].message
                        }
                        error={!!errors["expiry"]}
                      />
                    );
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <Cvc
                  onChange={e => {
                    console.log(e);
                    setCvc(e.value);
                  }}
                  render={({ valid, getInputProps }) => {
                    const propsInput = getInputProps();
                    return (
                      <TextField
                        {...propsInput}
                        fullWidth
                        inputRef={register({
                          required: intl.formatMessage({ id: "requiredCVV" }),
                          validate: () => {
                            if (!valid) {
                              return "Invalid CVV";
                            }
                          }
                        })}
                        inputProps={{
                          maxLength: 3
                        }}
                        onFocus={() => {
                          setFocused("cvc");
                        }}
                        name="CVV"
                        helperText={errors.CVV && errors.CVV.message}
                        error={!!errors.CVV}
                      />
                    );
                  }}
                />
              </Grid>
            </Grid>
            <Grid
              item
              container
              xs={12}
              direction="row"
              justify="center"
              style={{ textAlign: "center", marginTop: "40px" }}
            >
              <Grid item xs={6}>
                <Button className={classes.cardBtn} onClick={props.onClick}>
                  {intl.formatMessage({ id: "cancel" })}
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  type="submit"
                  disabled={stripeLoader}
                  onClick={() => handleSubmit()}
                  className={classes.cardBtn}
                  style={{ background: "#09A9BB", color: "#FFF" }}
                >
                  {stripeLoader ? (
                    <CircularProgress size={15} style={{ color: "#fff" }} />
                  ) : (
                    intl.formatMessage({ id: "add" })
                  )}
                </Button>
              </Grid>
            </Grid>
          </div>
        </form>
      </Grid>
    </div>
  );
}

export default AddPaymentForm;

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    "& > *": {
      margin: theme.spacing(1),
      width: "100%",
      margin: "15px 0px"
    }
  },

  addPaymentCard: {
    minHeight: "22.125em",
    width: "100%",
    boxShadow: "none",

    marginBottom: "40px"
  },
  cardBtn: {
    width: "9.250em",
    height: "3.063em",
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "16px",
    borderRadius: "8px"
  }
}));
