import React from 'react';
import ProfileInfo from './ProfileInfo'

function Profile(props) {

    return (
        <ProfileInfo />
    );
}





export default Profile;