// types
import { GET_NOTIFICATIONS,GET_UNSEEN_NOTIFICATIONS_COUNT } from "../actions/types";

// initial state
const initialState = {
  allNotifications:[],
  noOfUnseenNotifications:0
};

// handle actions
export default function(state = initialState, action) {
    const { type, payload } = action;
  
    switch (type) {
      case GET_NOTIFICATIONS:
        return {
          ...state,
          allNotifications:payload
        };
      case GET_UNSEEN_NOTIFICATIONS_COUNT:
        return {
          ...state,
          noOfUnseenNotifications:payload
        };
      default:
        return state;
    }
  }