import { CardMedia, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import DoubleImageIcon from "../../../../assets/DoubleImageIcon.svg";
import { useIntl } from "react-intl";
const AddImage = (props) => {
  const { image, selectedFile, btnText } = props;
  const classes = useStyles();
  const intl = useIntl();

  return (
    <>
      {!image ? (
        <CardMedia className={classes.courseDescriptionImageCard}>
          <Grid
            container
            direction="column"
            justify="center"
            style={{
              textAlign: "center",
              lineHeight: "5em",
              marginTop: "10%",
              position: "absolute",
              top: 0,
             

            }}
          >
            <Grid xs={12}>
              <img src={DoubleImageIcon} alt="" />
            </Grid>
            <Grid xs={12}>
              <Typography className={classes.cardText}>
                {intl.formatMessage({ id: "itsEmptyHere" })}
              </Typography>
            </Grid>
            <Grid xs={12}>
              <label className="profile_image">
                <input
                  type="file"
                  className="profile_image_input"
                  style={{ display: "none" }}
                  accept="image/*"
                  onChange={selectedFile}
                />
              </label>
            </Grid>
          </Grid>
        </CardMedia>
      ) : (
          <CardMedia
            className={classes.courseDescriptionImageCard}
            style={{ background: `url(${image})`, backgroundRepeat: `no-repeat` }}
          >
            <Grid
              container
              direction="column"
              justify="center"
              style={{
                textAlign: "center",
                lineHeight: "5em",
                marginTop: "10%",
                opacity: "1",
                position: "absolute",
                top: 0,
                left: 0,
                bottom: 100,
                right: 0
              }}
            >
              <Grid
                style={{
                  zIndex: 100,
                  marginTop: image ? "20%" : "0%",
                  alignItems: "center",
                }}
                xs={12}
              >
                <label className="profile_image">
                  <input
                    type="file"
                    className="profile_image_input"
                    style={{ display: "none" }}
                    accept="image/*"
                    onChange={selectedFile}
                  />
                </label>
              </Grid>
            </Grid>
          </CardMedia>
        )}
    </>
  );
};

export default AddImage;

const useStyles = makeStyles((theme) => ({
  courseDescriptionImageCard: {
    height: "15.438em",
    backgroundColor: "#FEEFCC",
    borderRadius: "20px",
    backgroundSize: "cover !important",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "top center",
    position: "relative",
    height: 0,
    paddingTop: `56.25%`,
    width:'100%'
  },
  cardButton: {
    width: "18em",
    height: "4em",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "20px",
    backgroundColor: "#FBAE02",
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: 300,
    fontSize: "10px",
    lineHeight: "10px",
    color: "#FFFFFF",
    marginTop: "20px",
    margin: "auto",
  },
  cardText: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "16px",
    color: "#363636",
  },
  profile_image: {
    padding: "10px",
    cursor: "pointer",
  },
}));
