import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom'

import CourseCard from "../../Common/CourseCard";


function SubscriptionStuff({ data, title, link, joined, available }) {
    const classes = useStyles();
    return (
        <>
            <Grid item xs={12} >
                <Typography className={classes.title}>
                    {title} ({joined}/{available}):
                </Typography>
            </Grid>
            <Grid
                item
                xs={12}
                container
                spacing={2}
                className={classes.hideScrollBar}>
                {
                    data?.length > 0 ? data.map(item => 
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={4}
                            style={{ minWidth: "250px" }}
                            key={item?.id}
                        >
                            <Link
                                to={link + item?.id}
                                style={{ textDecoration: "none" }}>
                                <CourseCard
                                    image={item?.imageUrl??item?.image}
                                    
                                    name={item?.name} />
                            </Link>
                        </Grid>
                    ) : <Grid item xs={12}>
                        <Typography variant="p" color="textSecondary" component="p">
                            No Record found
                        </Typography>
                    </Grid>
                }
            </Grid>
        </>
    );
}

export default SubscriptionStuff;

const useStyles = makeStyles((theme) => ({
    title: {
        fontFamily: theme.fontFamily.Reef,
        fontStyle: "normal",
        fontWeight: 900,
        paddingBottom:10
    },
    hideScrollBar: {
        flexWrap: "unset",
        overflowX: "auto",
        "&::-webkit-scrollbar": {
            display: "none",
        },
    },

}));