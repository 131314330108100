import {  Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

function SenderMessage(props) {
    const classes = useStyles();

    return (
        <Box>
            <div className={classes.chatUserPic} style={{ backgroundImage: `url(${props?.message?.sender?.pictureUrl})` }}>

            </div>
            <div className={classes.container}>
                <Typography className={classes.message}>{props?.message?.message}</Typography>
            </div>
        </Box>
    )
}

export default SenderMessage
const useStyles = makeStyles(theme => ({
    container: {
        background: '#09A9BB',
        borderRadius: '9px',
        minHeight: '68px',
        width: '100%',
        marginLeft:'14px',
        margin: '-18px 0px 0px 0',
    },
    message: {


        color: ' #FFFFFF',
        fontFamily: theme.fontFamily.Roboto,
        fontStyle: "normal",
        fontWeight: 'normal',
        fontSize: "14px",
        lineHeight: "19px",
        padding: '12px 10px 10px 17px'
    },
    chatUserPic: {
        height: '34px',
        width: '34px',
        borderRadius: '11px',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        zIndex:100,
        position:'relative',
    }
}))