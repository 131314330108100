// types
import {
    TRAINING_COURSE_DETAIL,
    TRAINING_COURSE_REQUESTS,
    TRAINING_COURSE_PARTICIPANTS,
    GET_ALL_TRAINING_COURSE,
    TO_BE_PLAY_TRAINING_COURSE_EPISODE,
    COURSE_FAVORITE,
    TRAINING_COURSE_CONTENT,
    TRAINING_COURSE_REQUESTS_STATUS,
    TRAINING_COURSE_SECTION_SUPPORTED_FILES,
    TRAINING_COURSE_COMMENTS,
    ADD_COURSE_COMMENT,
    TRAINING_COURSE_ANNOUNCEMENTS,
    GET_ENROLLED_RETREATS,
    GET_SECTION_ASSIGNMENTS,
    STUDENT_SUBMITTED_SECTION_ASSIGNMENTS_FILES,
    STUDENT_ASSIGNMENT_APPROVE_DISAPPROVE,
    GET_RETREAT_ASSIGNMENTS_FILES,
    GET_RETREAT_INVITED_USERS,
    CHANGE_VIEW_STATUS_LESSON,
    RETREAT_STATS_DETAIL,
    GET_ALL_CREATED_TEACHER_TRAINING,
    GET_ENROLLED_TEACHER_TRAININGS
    
} from "../actions/types";

// initial state
const initialState = {
    trainingCourseDetails: {},
    trainingCourseParticipants: [],
    trainingCourseRequests: [],
    allUploadedTrainingCourses: [],
    allUploadedTeacherTrainings: [],
    toBePlayedLesson: {},
    courseContent: [],
    sectionSupportedFiles: [],
    sectionAssignmentsFiles: [],
    trainingCourseComments: [],
    announcements: [],
    enrolledRetreats:[],
    enrolledTeacherTrainings:[],
    studentSubmittedSectionAssignments:[],
    retreatAssignmentsFiles:[],
    retreatInvitedUsers:[],
    retreatStats:{}
};

// handle actions
export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case TRAINING_COURSE_DETAIL:
            return {
                ...state,
                trainingCourseDetails: payload
            };
        case CHANGE_VIEW_STATUS_LESSON:
            let content = state?.courseContent?.map(item => item?.id === payload?.sectionId ? {...item, lessons: item?.lessons?.map(item1 => item1?.id === payload?.lessonId ? {...item1, watchStatus:'Completed'} : item1)} : item);
            
            return {
                ...state,
                courseContent: content
            };
        case GET_RETREAT_ASSIGNMENTS_FILES:
            return {
                ...state,
                retreatAssignmentsFiles: payload
            };
        case RETREAT_STATS_DETAIL:
            return {
                ...state,
                retreatStats: payload
            };
        case GET_RETREAT_INVITED_USERS:
            return {
                ...state,
                retreatInvitedUsers: payload
            };
        case TRAINING_COURSE_REQUESTS:
            return {
                ...state,
                trainingCourseRequests: payload
            };
        case STUDENT_SUBMITTED_SECTION_ASSIGNMENTS_FILES:
            return {
                ...state,
                studentSubmittedSectionAssignments: payload
            };
        case STUDENT_ASSIGNMENT_APPROVE_DISAPPROVE:
            let assArr = []
            assArr = state?.studentSubmittedSectionAssignments?.length > 0 && state?.studentSubmittedSectionAssignments?.map(item => (item?.id === payload?.assignmentId) ? ({ ...item, studentSubmittedAssignments: [{...item?.studentSubmittedAssignments[0], status: payload?.status}]}) : item);
            return {
                ...state,
                studentSubmittedSectionAssignments: assArr
            };
        case GET_ENROLLED_RETREATS:
            return {
                ...state,
                enrolledRetreats: payload
            };
        case GET_ENROLLED_TEACHER_TRAININGS:
            return {
                ...state,
                enrolledTeacherTrainings: payload
            };
        case GET_SECTION_ASSIGNMENTS:
            return {
                ...state,
                sectionAssignmentsFiles: payload
            };
        case TRAINING_COURSE_ANNOUNCEMENTS:
            return {
                ...state,
                announcements: payload
            };
        case TRAINING_COURSE_PARTICIPANTS:
            return {
                ...state,
                trainingCourseParticipants: payload
            };
        case GET_ALL_TRAINING_COURSE:
            return {
                ...state,
                allUploadedTrainingCourses: payload
            };
        case GET_ALL_CREATED_TEACHER_TRAINING:
            return {
                ...state,
                allUploadedTeacherTrainings: payload
            };
        case TO_BE_PLAY_TRAINING_COURSE_EPISODE:
            return {
                ...state,
                toBePlayedLesson: payload
            };
        case COURSE_FAVORITE:
            return {
                ...state,
                trainingCourseDetails: { ...state?.trainingCourseDetails, isUserFan: payload }
            };
        case TRAINING_COURSE_CONTENT:
            return {
                ...state,
                courseContent: payload
            };
        case TRAINING_COURSE_SECTION_SUPPORTED_FILES:
            return {
                ...state,
                sectionSupportedFiles: payload
            };
        case TRAINING_COURSE_COMMENTS:
            return {
                ...state,
                trainingCourseComments: payload
            };
        case ADD_COURSE_COMMENT:
            return {
                ...state,
                trainingCourseComments: [...state.trainingCourseComments, payload]
            };
        case TRAINING_COURSE_REQUESTS_STATUS:
            const trainingCourseRequestsLocal = state.trainingCourseRequests?.map(item => item?.id === payload?.requestId ? { ...item, status: payload.status } : item);

            return {
                ...state,
                trainingCourseRequests: [...trainingCourseRequestsLocal]
            };
        default:
            return state;
    }
}
