import { useState, useEffect } from "react";
import { Grid, Typography, Box, CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import StartBtn from "../../assets/img/soloExerciseBtn.png";
import Play from "../../assets/Icons/play.svg";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import Sound1 from "../../assets/sound1.mp3";
import speechRestTimeStart from "../../assets/sounds/speechRestTimeStart.mp3";
import speechPlay from "../../assets/sounds/speechPlay.mp3";
import speechPause from "../../assets/sounds/speechPause.mp3";
import useSound from "use-sound";

function SoloExercise(props) {
  const classes = useStyles();
  const [pause, setPause] = useState(false);
  const [option, setOPtion] = useState(props?.timerType);

  const [timer, setTimer] = useState(getTime);
  const [pTimer] = useState(getTime);

  const [playOn] = useSound(Sound1, { volume: 0.5 });

  const [speechPlayPlayOn] = useSound(speechPlay, { volume: 1 });
  const [speechPausePlayOn] = useSound(speechPause, { volume: 1 });
  const [speechRestTimeStartPlayOn] = useSound(speechRestTimeStart, {
    volume: 1,
  });
  function getTime() {
    var hours = Math.floor((props?.timer) / 3600)
    var minutes = Math.floor((props?.timer) / 60) % 60
    var seconds = (props?.timer) % 60

    let obj = {
      "h": hours,
      "m": minutes,
      "s": seconds
    };
    return (
      parseInt(obj?.h) * 60 * 60 +
      parseInt(obj?.m) * 60 +
      parseInt(obj?.s)
    );
  }


  useEffect(() => {
    playOn();
  }, []);
  useEffect(() => {
    if (pause) {
      speechPausePlayOn();
    } else {
      speechPlayPlayOn();
    }
  }, [pause]);
  useEffect(() => {
    if (timer < 3 && timer > 0) {
      playOn();
    }
  }, [timer, pause]);
  useEffect(() => {
    if (!pause && timer > 0) {
      setTimeout(() => {
        setTimer(timer - 1);
      }, 1000);
    } else if ((option === "preparation" || option === "rest") && timer === 0) {

      return props?.closeDialog()
    }

  }, [timer, pause]);

  const handleSetPause = (value) => {
    setPause(value);
  };


  return (
    <Grid
      container
      style={{
        overflow: "hidden",
      }}
    >
      <Grid item xs={1} style={{ display: "flex", justifyContent: "flex-end" }} />

      <Grid item xs={10} style={{ justifyContent: "center", display: "flex", marginBottom: '25px' }}>
        <div
          className={classes.timer}
          style={{
            background: `url(${StartBtn})`,
          }}
        >
          <Box position="relative" display="inline-flex">
            <CircularProgress
              variant="static"
              size={180}
              thickness={1.7}
              style={{ color: "#FAA902" }}
              value={
                (timer /
                  (pTimer)) *
                100
              }
            />
            <Box
              top={0}
              left={0}
              bottom={0}
              right={0}
              position="absolute"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Grid
                container
                alignItems="center"
                direction="column"
                justify="center"
              >
                <Grid item>
                  <Typography
                    variant="caption"
                    component="div"
                    className={classes.timerBody}
                  >
                    {option === "preparation"
                      ? "PREP"
                      : "REST"}
                  </Typography>
                </Grid>

                <Grid item>
                  <Typography
                    variant="caption"
                    component="div"
                    className={classes.timerText}
                  >
                    {parseInt((1 / 3600) * timer)}:{parseInt(timer / 60)}:{timer - parseInt(timer / 60) * 60}
                  </Typography>
                </Grid>
                <Grid item>
                  <div
                    className={classes.playIcon}
                    onClick={() => handleSetPause(!pause)}
                  >
                    {pause ? (
                      <PlayArrowIcon style={{ fontSize: 35, color: "white" }} />
                    ) : (
                      <img
                        alt=""
                        src={Play}
                        style={{ width: "35px", height: "18px" }}
                      />
                    )}
                  </div>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </div>
      </Grid>
      <Grid item xs={1} />
      <Grid item xs={12} container justify='flex-end'>
        <Grid item xs={1} sm={1} >
          <Typography style={{ color: '#f44242', fontSize: '14px', letterSpacing: '.05', cursor: 'pointer' }} onClick={() => props?.closeDialog()}>Skip</Typography>
        </Grid>
      </Grid>



    </Grid>
  );
}

export default SoloExercise;

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#244562",
  },
  timer: {
    minWidth: "271px",
    height: "271px",
    backgroundRepeat: `no-repeat !important`,
    backgroundSize: `100% 100% !important`,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  startImg: {
    marginTop: "30%",
    marginLeft: "20%",
    width: "120px",
    height: "120px",
    cursor: "pointer",
  },
  timerText: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "36px",
    lineHeight: "56px",
    letterSpacing: "0.045em",
    color: theme.colors.white,
  },
  playIcon: {
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    width: "46px",
    height: "46px",
    background: `linear-gradient(180deg, #153959 0%, #7B92A6 100%)`,
  },
  timerBody: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "18px",
    lineHeight: "21px",
    color: "#F28705",
  },
  timeLeftText: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "21px",
    letterSpacing: "0.04em",
    color: `rgba(255, 255, 255, 0.8)`,
  },
  timeLeft: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: 900,
    fontSize: "36px",
    lineHeight: "42px",
    letterSpacing: "0.045em",
    color: theme.colors.white,
  },
  endText: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "24px",
    lineHeight: "28px",
    color: "#F3887F",
    cursor: "pointer",
  },
}));
