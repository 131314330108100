import React, { useEffect, useState } from 'react';
import { Grid, Typography, Button, Dialog, DialogTitle, DialogContent, DialogActions, IconButton } from '@material-ui/core'
import { Close } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

function ExpireSubscriptionPopup(props) {
    const history = useHistory();
    const classes = useStyles();

    const user = useSelector(state => state?.user?.user);

    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
        if (props?.setPopUp) {
            return props?.setPopUp()
        }
    }

    const handleUpdate = () => {
        setOpen(false);
        user?.isExpiredSubscription && user?.purchasedSubscription && user?.purchasedSubscription[0]?.status === 'active' ? history.push('/more/subscription') : history.push('/more/subscription-plan')
    }

   /*  useEffect(() => {
        setOpen(user?.isExpiredSubscription)
    }, []); */

    useEffect(() => {
        props?.subscriptionExpire && setOpen(true)
    }, [props?.subscriptionExpire])

    return (
        <Dialog open={open} onClose={handleClose}  >
            <DialogTitle>
                <Grid container justify="space-between">
                    <Grid item>
                        <Typography component="h4" variant="h5" >
                            Attention!
                </Typography>
                    </Grid>
                    <Grid item>
                        <IconButton onClick={handleClose}>
                            <Close />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent style={{ display: 'flex', justifyContent: 'center' }}>
                <Typography >
                    {
                        user?.isExpiredSubscription && user?.purchasedSubscription && user?.purchasedSubscription[0]?.status === 'active' ? 'Your Monthly subscribed material has expired, please rejoin for next month or cancel any joined material. Click Update to view or update details.' : 'Your subscription plan has expired and your under subscription material is no longer be accessible please purchase new subscription plan to access again.'
                    }
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleUpdate} className={classes.modelBtn} >
                   { user?.isExpiredSubscription && user?.purchasedSubscription && user?.purchasedSubscription[0]?.status === 'active' ? 'Update' : 'Purchase'}
                    </Button>
            </DialogActions>
        </Dialog>
    );
}

export default ExpireSubscriptionPopup;

const useStyles = makeStyles((theme) => ({

    modelBtn: {
        fontFamily: theme.fontFamily.Roboto,
        cursor: "pointer",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "21px",
        textTransform: 'none',
        color: theme.colors.white,
        background: `linear-gradient(#09A9BB 1%,#09A9BB 100%)`
    },

}));