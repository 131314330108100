import React, { useEffect, useState } from "react";
import { Grid, Typography, useMediaQuery, CircularProgress } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import { useIntl } from "react-intl";
import CourseCard from "../Common/CourseCard";
import { Link } from "react-router-dom";
import { getEnrolledRetreats } from '../../actions/retreatCourse';
import { getUserUploadedCoursesByUserId, getUserEnrolledCoursesByUserId, getUserEnrolledSessionsChallengesByUserId } from '../../actions/courses';

function UserEnrolledInfo() {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const intl = useIntl();
  const [enrolledRetreatLoader, setEnrolledRetreatLoader] = useState(false);
  const [uploadedCoursesLoader, setUploadedCoursesLoader] = useState(false);
  const [enrolledTeacherTrainingLoader, setEnrolledTeacherTrainingLoader] = useState(false);
  const [userUploadedCourses, setUserUploadedCourses] = useState([]);
  const [enrolledCoursesLoader, setEnrolledCoursesLoader] = useState(false);
  const [enrolledCourses, setUserEnrolledCourses] = useState([]);
  const [sessionChallengeLoader, setSessionChallengeLoader] = useState(false);
  const [userSessionChallenge, setUserSessionChallenge] = useState('');

  const publicUser = useSelector(state => state?.publicUser?.publicUserInfo);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  let enrolledTrainingCoursesList = useSelector(state => state?.retreatCourse?.enrolledRetreats);
  let enrolledTeacherTrainingList = useSelector(state => state?.retreatCourse?.enrolledTeacherTrainings);
  const authReducer = useSelector((state) => state?.auth);


  const getData = async () => {
    if (publicUser?.id) {

      setEnrolledRetreatLoader(true)
      const res = await dispatch(getEnrolledRetreats({ token: authReducer?.token, userId: publicUser?.id, retreatType: 'retreat' }));
      if (res) {
        setEnrolledRetreatLoader(false)
      }

      setEnrolledTeacherTrainingLoader(true)
      const teacherTrainingRes = await dispatch(getEnrolledRetreats({ token: authReducer?.token, userId: publicUser?.id, retreatType: 'teacherTraining' }));
      if (teacherTrainingRes) {
        setEnrolledTeacherTrainingLoader(false)
      }

      setUploadedCoursesLoader(true)
      const resUploadedCourses = await dispatch(getUserUploadedCoursesByUserId({ token: authReducer?.token, userId: publicUser?.id }));
      if (resUploadedCourses) {
        setUserUploadedCourses(resUploadedCourses?.courses)
        setUploadedCoursesLoader(false)
      } else {
        setUploadedCoursesLoader(false)
      }

      setEnrolledCoursesLoader(true)
      const resEnrolledCourses = await dispatch(getUserEnrolledCoursesByUserId({ token: authReducer?.token, userId: publicUser?.id }));
      if (resEnrolledCourses) {
        setUserEnrolledCourses(resEnrolledCourses?.enrolledCourses)
        setEnrolledCoursesLoader(false)
      } else {
        setEnrolledCoursesLoader(false)
      }

      setSessionChallengeLoader(true)
      const resEnrolledSessionChallenges = await dispatch(getUserEnrolledSessionsChallengesByUserId({ token: authReducer?.token, userId: publicUser?.id }));
      if (resEnrolledSessionChallenges) {
        setUserSessionChallenge(resEnrolledSessionChallenges)
        setSessionChallengeLoader(false)
      } else {
        setSessionChallengeLoader(false)
      }
    }
  }

  useEffect(() => {
    getData()

    return () => {

      dispatch({
        type: 'GET_ENROLLED_RETREATS',
        payload: []
      });
      dispatch({
        type: 'GET_USER_UPLOADED_COURSES',
        payload: []
      });

      dispatch({
        type: 'GET_ENROLLED_TEACHER_TRAININGS',
        payload: []
      });
    }
  }, [publicUser]);




  return (
    <Grid container style={{ paddingLeft: isMobile && "15px" }}>
      {publicUser?.role !== "Regular" && (
        <>
          <Grid item xs={12}>
            <Typography className={classes.heading}>
              {intl.formatMessage({ id: "teachingCourses" })}
            </Typography>
          </Grid>

          <Grid
            item
            container
            xs={12}
            spacing={2}
            className={classes.scrollBar}
          >
            {uploadedCoursesLoader ? (
              <Grid container justify="center">
                <CircularProgress
                  color="primary"
                  style={{ color: "#09A9BB" }}
                  size={20}
                />
              </Grid>
            ) : userUploadedCourses?.length > 0 ? (
              userUploadedCourses?.map((course) => {
                return (
                  <Grid item key={course}>
                    <Link
                      to={`/view-course/${course?.slug}`}
                      style={{ textDecoration: "none" }}
                    >
                      <CourseCard
                        category={course?.category?.name}
                        name={course?.name}
                        totalDuration={Math.round(course?.totalDuration)}
                        image={course?.image}
                        plan={course?.plan}
                        price={course?.price}
                      />
                    </Link>
                  </Grid>
                );
              })
            ) : (
              <Grid xs={12}>
                <Typography
                  variant="p"
                  color="textSecondary"
                  component="p"
                  style={{ textAlign: "center" }}
                >
                  No Data found
                </Typography>
              </Grid>
            )}
          </Grid>
        </>
      )}

      <Grid item xs={12}>
        <Typography className={classes.heading}>
          {intl.formatMessage({ id: "enrolledCourses" })}
        </Typography>
      </Grid>

      <Grid item container xs={12} spacing={2} className={classes.scrollBar}>
        {enrolledCoursesLoader ? (
          <Grid container justify="center">
            <CircularProgress
              color="primary"
              style={{ color: "#09A9BB" }}
              size={20}
            />
          </Grid>
        ) : enrolledCourses?.length > 0 ? (
          enrolledCourses?.map((course) => {
            return (
              <Grid item key={course}>
                <Link
                  to={`/view-course/${course?.slug}`}
                  style={{ textDecoration: "none" }}
                >
                  <CourseCard
                    category={course?.category?.name}
                    name={course?.name}
                    totalDuration={Math.round(course?.totalDuration)}
                    image={course?.image}
                    plan={course?.plan}
                    price={course?.price}
                  />
                </Link>
              </Grid>
            );
          })
        ) : (
          <Grid xs={12}>
            <Typography
              variant="p"
              color="textSecondary"
              component="p"
              style={{ textAlign: "center" }}
            >
              No Data found
            </Typography>
          </Grid>
        )}
      </Grid>

      <Grid item xs={12}>
        <Typography className={classes.heading}>
          {intl.formatMessage({ id: "enrolledRetreats" })}
        </Typography>
      </Grid>

      <Grid item container xs={12} spacing={2} className={classes.scrollBar}>
        {enrolledRetreatLoader ? (
          <Grid container justify="center">
            <CircularProgress
              color="primary"
              style={{ color: "#09A9BB" }}
              size={20}
            />
          </Grid>
        ) : enrolledTrainingCoursesList?.length > 0 ? (
          enrolledTrainingCoursesList?.map((retreat) => {
            return (
              <Grid item key={retreat?.id}>
                <Link
                  to={`/retreat-details/${retreat?.id}`}
                  style={{ textDecoration: "none" }}
                >
                  <CourseCard
                    category={retreat?.category?.name}
                    name={retreat?.name}
                    totalDuration={Math.round(retreat?.totalDuration)}
                    image={retreat?.image}
                  />
                </Link>
              </Grid>
            );
          })
        ) : (
          <Grid xs={12}>
            <Typography
              variant="p"
              color="textSecondary"
              component="p"
              style={{ textAlign: "center" }}
            >
              No Data found
            </Typography>
          </Grid>
        )}
      </Grid>

      <Grid item xs={12}>
        <Typography className={classes.heading}>
          {intl.formatMessage({ id: "enrolledTeacherTrainings" })}
        </Typography>
      </Grid>

      <Grid item container xs={12} spacing={2} className={classes.scrollBar}>
        {enrolledTeacherTrainingLoader ? (
          <Grid container justify="center">
            <CircularProgress
              color="primary"
              style={{ color: "#09A9BB" }}
              size={20}
            />
          </Grid>
        ) : enrolledTeacherTrainingList?.length > 0 ? (
          enrolledTeacherTrainingList?.map((retreat) => {
            return (
              <Grid item key={retreat?.id}>
                <Link
                  to={`/retreat-details/${retreat?.id}`}
                  style={{ textDecoration: "none" }}
                >
                  <CourseCard
                    category={retreat?.category?.name}
                    name={retreat?.name}
                    totalDuration={Math.round(retreat?.totalDuration)}
                    image={retreat?.image}
                  />
                </Link>
              </Grid>
            );
          })
        ) : (
          <Grid xs={12}>
            <Typography
              variant="p"
              color="textSecondary"
              component="p"
              style={{ textAlign: "center" }}
            >
              No Data found
            </Typography>
          </Grid>
        )}
      </Grid>

      <Grid item xs={12}>
        <Typography className={classes.heading}>
          {intl.formatMessage({ id: "sessions" })}
        </Typography>
      </Grid>

      <Grid item container xs={12} spacing={2} className={classes.scrollBar}>
        {sessionChallengeLoader ? (
          <Grid container justify="center">
            <CircularProgress
              color="primary"
              style={{ color: "#09A9BB" }}
              size={20}
            />
          </Grid>
        ) : userSessionChallenge?.sessions?.length > 0 ? (
          userSessionChallenge?.sessions?.map((session) => {
            return (
              <Grid item key={session}>
                <Link
                  to={`/more/session/details/${session?.id}`}
                  style={{ textDecoration: "none" }}
                >
                  <CourseCard name={session?.name} image={session?.imageUrl} />
                </Link>
              </Grid>
            );
          })
        ) : (
          <Grid xs={12}>
            <Typography
              variant="p"
              color="textSecondary"
              component="p"
              style={{ textAlign: "center" }}
            >
              No Data found
            </Typography>
          </Grid>
        )}
      </Grid>

      <Grid item xs={12}>
        <Typography className={classes.heading}>
          {intl.formatMessage({ id: "challenges" })}
        </Typography>
      </Grid>

      <Grid item container xs={12} spacing={2} className={classes.scrollBar}>
        {sessionChallengeLoader ? (
          <Grid container justify="center">
            <CircularProgress
              color="primary"
              style={{ color: "#09A9BB" }}
              size={20}
            />
          </Grid>
        ) : userSessionChallenge?.challenges?.length > 0 ? (
          userSessionChallenge?.challenges?.map((challenge) => {
            return (
              <Grid item key={challenge.id}>
                <Link
                  to={`/challenge/details/${challenge?.id}`}
                  style={{ textDecoration: "none" }}
                >
                  <CourseCard
                    category={challenge?.category}
                    name={challenge?.title}
                    image={challenge?.imageUrl}
                  />
                </Link>
              </Grid>
            );
          })
        ) : (
          <Grid xs={12}>
            <Typography
              variant="p"
              color="textSecondary"
              component="p"
              style={{ textAlign: "center" }}
            >
              No Data found
            </Typography>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}

export default UserEnrolledInfo;

const useStyles = makeStyles(theme => ({
  heading: {
    fontFamily: "Reef",
    fontStyle: "normal",
    fontWeight: 900,
    fontSize: "20px",
    lineHeight: "27px",
    color: "#000000",
    marginBottom: "3px",
    marginTop: "20px"
  },
  link: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "16px",
    color: "#068594",
    textAlign: "right",
    marginRight: "15px",
    marginTop: "20px",
    textDecoration: 'none'
  },

  scrollBar: {
    marginTop: "10px",
    flexWrap: "unset",
    overflowX: "auto",
    "&::-webkit-scrollbar": {
      display: "none"
    }
  },

}));
