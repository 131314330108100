import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Truncate from "react-truncate";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { useIntl } from "react-intl";
import { Tooltip, useTheme, useMediaQuery } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "230px",
    borderRadius: "11px",
    boxShadow: " 0px 4px 4px rgba(0, 0, 0, 0.05)",
  },
  planTooltip: {
    position: "absolute",
    bottom: "10px",
    textAlign: "center",
    background: "#4DB051",
    right: "0px",
    borderRadius: "13px",
    padding: "0px 10px",
    borderTopRightRadius: "0px",
    borderBottomRightRadius: "0px",
    fontFamily: theme?.palette?.fontFamily?.Reef,
    color: "#fff",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "14px",
    display: "flex",
    justifyContent: "center",
    width: "auto",
  },
  media: {
    height: "0",
    position: "relative",
    paddingTop: "56.25%",
    width: "100%",
    BorderRadiusTopRight: "11px",
    BorderRadiusTopLeft: "11px",
    background: "#C4C4C4",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
  },
  title: {
    fontFamily: theme.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: "900",
    fontSize: "14px",
    lineHeight: "19px",
    textAlign: "center",
    color: "#000000",
  },
  subTitle: {
    fontFamily: theme.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: "900",
    fontSize: "10px",
    lineHeight: "14px",
    textAlign: "center",
    color: "#C2C2C2",
  },
  nameBold: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "14px",
    color: "#3C4A6B",
    textAlign: "center",
    marginTop: "10px",
  },
}));

function CourseCard(props) {
  const classes = useStyles();
  const theme = useTheme();
  const intl = useIntl();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const configs = useSelector((state) => state?.setting?.siteConfig);

console.log(configs,"configs")
  return (
    <Card className={classes.root} style={{ width: props?.width && "100%" }}>
      <CardMedia
        className={classes.media}
        image={props?.image}
        style={{ backgroundColor: props?.image && "#fff" }}
      >
        <Typography
          className={classes.planTooltip}
          style={{ fontSize: isMobile && "18px" }}
        >
          {props?.plan?.name === "Paid"
            ? `Premium: ${configs?.currency}${props?.price}`
            : props?.plan?.name === "Subscription"
            ? "Subscribe"

            : props?.plan?.name==="Donation"?
          "Free":
          props?.plan === "free" ?
          "Free" : props?.plan === "paid" ? `Premium: ${configs?.currency}${props?.price}` :  props?.plan?.name
          }
        </Typography>
        {/* {props?.category && (
          <Grid
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              bottom: 0,
              right: 0
            }}
            container
            justify="flex-end"
          >
            <Grid item xs={1}>
              <Typography
                style={{ color: "#FFF", marginTop: "147px", fontSize: "20px" }}
              >
                &bull;{"   "}{" "}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography
                style={{
                  color: "#FFF",
                  marginTop: "155px",
                  marginRight: "5px",
                  fontSize: "10px"
                }}
              >
                {props?.category}
              </Typography>
            </Grid>
          </Grid>
        )} */}
      </CardMedia>
      <CardContent style={{ padding: "7px 0px" }}>
        <Tooltip title={props?.name}>
          <Typography
            gutterBottom
            style={{ width: "100%" }}
            className={props?.courseNameBold ? classes.nameBold : classes.title}
          >
            <Truncate lines={1} ellipsis={<span>...</span>}>
              {`    ${props?.name}    `}
            </Truncate>
          </Typography>
        </Tooltip>
        <Grid container>
          <Grid item xs={4}>
            <Typography
              variant="body2"
              component="p"
              className={classes.subTitle}
            >
              {props?.friends}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            {props?.toDuration && (
              <Typography
                variant="body2"
                component="p"
                className={classes.subTitle}
              >
                {props?.toDuration + " "}
                {intl.formatMessage({ id: "mins" })}
              </Typography>
            )}
          </Grid>
          <Grid item xs={4}>
            <Typography
              variant="body2"
              component="p"
              className={classes.subTitle}
            >
              {props?.time}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default CourseCard;
