
import PlayCourseWebContainer from "../../containers/Web/PlayCourse";

function PlayCoursePage() {

  return (
    <>
      <PlayCourseWebContainer />
    </>
  );
}

export default PlayCoursePage;
