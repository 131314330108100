import React, { useState } from 'react'
import AppBar from "../../../../components/Common/AppBarBackBtnTitle";
import CreateChallengeComponent from "../../../../components/More/CreateChallenge";
import { addParticipants } from "../../../../actions/challenges";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { Grid } from '@material-ui/core'
import { useDispatch } from 'react-redux'

function CreateChallenge() {
    const intl = useIntl()
    const history = useHistory()
    const dispatch = useDispatch()

    const [friendAddState, setFriendAddState] = useState(false)

    const addFriend = (state) => {
        setFriendAddState(state)
    }
    const handleBack = () => {
        if (friendAddState) {
            setFriendAddState(false)
        } else {
            dispatch(addParticipants({}))
            history.goBack()
        }

    }

    return (
        <Grid container >
            {friendAddState ?
                <Grid item xs={12}>
                    <AppBar
                        title={intl.formatMessage({ id: "invite" })}
                        onClick={handleBack}
                        crossLocation="/more/relate-with-friends"
                    />
                </Grid>
                : <Grid item xs={12}>
                    <AppBar
                        title={intl.formatMessage({ id: "challenge" })}
                        onClick={() => history.goBack()}
                        crossLocation="relate-with-friends"
                    />
                </Grid>}
            <Grid item xs={12} style={{ padding: '20px' }}>
                <CreateChallengeComponent addFriend={addFriend} addFriendState={friendAddState} />
            </Grid>
        </Grid>
    )
}

export default CreateChallenge
