import { useState, useLayoutEffect } from "react";

export const useWindowSize = () => {
  const [size, setSize] = useState([0, 0]);

  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
};

export const getPrice = (price, subscrption, interval) => {
  console.log(price, subscrption, interval);
  if (subscrption && subscrption.length > 0) {
    let discount = 1;

    if (interval === "retreat") {
      if (subscrption[0]?.interval === "yearly") {
        discount = subscrption[0]?.SubscriptionPlan?.annualRetreatDiscount;
      } else {
        discount = subscrption[0]?.SubscriptionPlan?.monthlyRetreatDiscount;
      }
    } else {
      discount = subscrption[0]?.SubscriptionPlan?.omidDiscount;
    }
    // interval===yearly// annualRetreatDiscount
    // interval===monthly// monthlyRetreatDiscount

    // if(subscrption[0]?.interval==="yearly"){
    return `${Math.floor(price - (discount * price) / 100)}`;
  }
};
