import React, { useEffect } from "react";
import { Grid, useMediaQuery, Typography } from "@material-ui/core";
import { useTheme, makeStyles } from "@material-ui/core/styles";
import NotificationCard from "./NotificationCard";
import { getAllNotifications, readNotification } from "../../actions/notifications";
import { ImportContacts } from "@material-ui/icons";
import yogaIcon from "../../assets/Icons/yogaIcon.svg";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import moment from "moment";

function AllNotifications(props) {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  const auth = useSelector(
    (state) => state?.auth
  );
  const allNotifications = useSelector(
    (state) => state?.notifications?.allNotifications
  );

  useEffect(() => {
    dispatch(getAllNotifications({ token: auth?.token }))
  }, [])

  let periods = {
    month: 30 * 24 * 60 * 60 * 1000,
    week: 7 * 24 * 60 * 60 * 1000,
    day: 24 * 60 * 60 * 1000,
    hour: 60 * 60 * 1000,
    minute: 60 * 1000,
  };

  function formatTime(timeCreated) {
    var diff = Date.now() - timeCreated;

    if (diff > periods.month) {
      return Math.floor(diff / periods.month) + "m ago";
    } else if (diff > periods.week) {
      return Math.floor(diff / periods.week) + "w ago";
    } else if (diff > periods.day) {
      return Math.floor(diff / periods.day) + "d ago";
    } else if (diff > periods.hour) {
      return Math.floor(diff / periods.hour) + "h ago";
    } else if (diff > periods.minute) {
      return Math.floor(diff / periods.minute) + "min ago";
    }
    return "Just now";
  }

  const notificationRouteHandler = (notification) => {
    if (notification?.isRead) {
      return history.push(notification?.referenceUrl)
    } else {
      dispatch(readNotification({ token: auth?.token, notificationId: notification?.id }))
      return history.push(notification?.referenceUrl)
    }
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        {/* <AppBar appBarConfig={{ show: true}} style={{ color: '#068594' }}/> */}

        <div>
          <Grid container xs={12}>


            {
              allNotifications?.length > 0 ? allNotifications?.map((notification) => {
                return <Grid item xs={12} key={notification?.id} onClick={notification?.referenceUrl === "#" ? '' : () => notificationRouteHandler(notification)}>
                  <NotificationCard
                    background={isMobile && !isDesktop ? "#FFF" : "#F3F3F3"}
                    IconContainerBackground={notification?.type === 'Course' ? "#d0eff2" : "#ede5e6"}
                    Icon={
                      notification?.type === 'Course' ?
                        <ImportContacts
                          style={{ color: "#15AEBF", fontSize: "40px" }}
                        />
                        : <img
                          src={yogaIcon}
                          alt=""
                          style={{ color: "#15AEBF", fontSize: "40px" }}
                        />
                    }
                    description={notification?.description}
                    timeAgo={formatTime(moment(notification?.createdAt).valueOf())}
                    read={notification?.isRead}
                  />
                </Grid>
              }) : <Grid item xs={12}><Typography className={classes.noRecord}>No new notification</Typography></Grid>
            }








            {/* <Grid item xs={12}>
              <NotificationCard
                background={isMobile && !isDesktop ? "#FFF" : "#F3F3F3"}
                IconContainerBackground="#d0eff2"
                Icon={
                  <ImportContacts
                    style={{ color: "#15AEBF", fontSize: "40px" }}
                  />
                }
                description="Instructor has passed a new course, “The Art of Meditation”"
                timeAgo="10 hours ago"
              />
            </Grid>
            <Grid item xs={12}>
              <NotificationCard
                background={isMobile && !isDesktop ? "#FFF" : "#F3F3F3"}
                IconContainerBackground="#ede5e6"
                Icon={
                  <img
                    src={yogaIcon}
                    alt=""
                    style={{ color: "#15AEBF", fontSize: "40px" }}
                  />
                }
                description="Your daily session of yoga is happening now"
                timeAgo="10 hours ago"
              />
            </Grid>
            <Grid item xs={12}>
              <NotificationCard
                background={isMobile && !isDesktop ? "#FFF" : "#F3F3F3"}
                IconContainerBackground="#d0eff2"
                Icon={
                  <ImportContacts
                    style={{ color: "#15AEBF", fontSize: "40px" }}
                  />
                }
                description="Instructor has passed a new course, “The Art of Meditation”"
                timeAgo="10 hours ago"
              />
            </Grid>
            <Grid item xs={12}>
              <NotificationCard
                background={isMobile && !isDesktop ? "#FFF" : "#F3F3F3"}
                IconContainerBackground="#ede5e6"
                Icon={
                  <img
                    src={yogaIcon}
                    alt=""
                    style={{ color: "#15AEBF", fontSize: "40px" }}
                  />
                }
                description="Your daily session of yoga is happening now"
                timeAgo="10 hours ago"
              />
            </Grid> */}
          </Grid>
        </div>
      </Grid>
    </Grid>
  );
}

export default AllNotifications;

const useStyles = makeStyles(theme => ({
  noRecord: {
    color: '#000000',
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "19px",
    textAlign: "center",
    marginTop: "40px"
  }
}))
