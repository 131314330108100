// types
import { GET_USER_BY_ID,FOLLOW_USER } from "../actions/types";

// initial state
const initialState = {
  publicUserInfo:{}
};

// handle actions
export default function(state = initialState, action) {
    const { type, payload } = action;
  
    switch (type) {
      case GET_USER_BY_ID:
        return {
          ...state,
          publicUserInfo:payload
        };
      case FOLLOW_USER:
        return {
          ...state,
          publicUserInfo:{...state?.publicUserInfo, isFollowing : true}
        };
      default:
        return state;
    }
  }