import React, { useEffect } from "react";
import { useTheme } from "@material-ui/core/styles";
import { CircularProgress, Typography, Grid ,IconButton} from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import ViewCourseWebContainer from "../../containers/Web/ViewCourse";
import ViewCourseMobileContainer from "../../containers/Mobile/ViewCourse";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { getCourseDetailByIdLoader } from "../../actions/courses";
import { ArrowBack, Close } from "@material-ui/icons";
import TopBar from "../../components/AppBar";
import { cleanup } from "@testing-library/react";
import ReactGA from "react-ga";
function ViewCoursePage() {
  const theme = useTheme();
  const { courseId } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const courseDetail = useSelector(state => state?.courses?.courseDetail);
  const courseLoader = useSelector(state => state?.courses?.courseLoader);
  const authReducerState = useSelector(state => state.auth);
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [error, setError] = React.useState(null);

  useEffect(() => {
    getData();
    ReactGA.initialize(process.env.REACT_APP_TRACKING_ID);
    ReactGA.pageview(window.location?.pathname);
  }, []);


  const getData = async () => {
    if (courseId) {
      const courseSlugId = courseId.split("-");
      const responseData = await dispatch(
        getCourseDetailByIdLoader({
          courseId: courseSlugId[courseSlugId.length - 1],
          token: authReducerState.token
        })
      );


      if (responseData?.status === "success") {
        return;
      } else if (responseData?.status === "fail") {
        setError(responseData?.message ? responseData?.message : "Not found");
      } else if (!responseData) {
        setError("Not found");
      }
    }
  };
  useEffect(() => {
    return () => {
      dispatch({
        type: "COURSE_DETAIL",
        payload: null
      });
    };
  }, []);


  return (
    <>
      {courseLoader ? (
        <Grid
          item
          xs={12}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh"
          }}
        >
          <CircularProgress size={30} style={{ color: "#15AEBF" }} />
        </Grid>
      ) : !courseLoader && error ? (
        <Grid container style={{ backgroundColor: "#E5E5E5" }}>
          <Grid
            item
            xs={11}
            style={{ display: "flex", alignItems: "center", height: "87px" }}
          >
           <TopBar
              appBarConfig={{
                show: true,
                text: "Course",
                backgroundColor: "#E5E5E5"
              }}
              style={{
                color: "#068594",
                backgroundColor: "#E5E5E5",
                float: "left"
              }}
              />

          </Grid>
          <Grid xs={12} item>
            <div
              style={{
                background: "#E5E5E5",
                height: "calc(100vh - 87px)",
                width: "100%",
                alignItems: "center",
                justifyContent: "center",
                display: "flex"
              }}
            >
              <Typography
                style={{
                  fontFamily: theme.fontFamily.Roboto,
                  fontStyle: "normal",
                  fontWeight: 500,
                  fontSize: "24px",
                  lineHeight: "28px",
                  color: "#000000",
                  marginTop: "7px"
                }}
              >
                {error}
              </Typography>
            </div>
          </Grid>{" "}
        </Grid>
      ) : (
        <>
          {isDesktop && <ViewCourseWebContainer />}
          {isMobile && !isDesktop && <ViewCourseMobileContainer />}
        </>
      )}
    </>
  );
}

export default ViewCoursePage;
