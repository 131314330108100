import React from "react";
import { Card, Grid, Typography } from "@material-ui/core";
import { ChevronRight } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";

function MenuCard(props) {
  const classes = useStyles();

  return (
    <Card
      className={classes.root}
      style={{ background: props?.selected === true && "#09A9BB" }}
    >
      {/* <CardContent> */}
      <Grid container style={{ margin: " 0 10px" }}>
        <Grid item xs={4} sm={4} md={4} lg={3} xl={3}>
          <div
            className={classes.menuIconStyle}
            style={{
              background:
                props?.selected === true
                  ? "#3abac9"
                  : props?.IconContainerBackground,
            }}
          >
            {props?.Icon}
          </div>
        </Grid>

        <Grid item container xs={7} sm={7} md={7} lg={8} xl={8}>
          <Grid item xs={12}>
            <Typography
              className={classes.title}
              style={{
                color:
                  props?.selected === true
                    ? "#FFF"
                    : props?.textColor
                    ? props?.textColor
                    : "",
                marginTop: !props?.detail && "20px",
              }}
            >
              {" "}
              {props.title}
            </Typography>
            {props?.detail && (
              <Typography
                className={classes.subTitle}
                style={{
                  color:
                    props?.selected === true
                      ? "#FFF"
                      : props?.textColor
                      ? props?.textColor
                      : "",
                  opacity: 1,
                }}
              >
                {" "}
                <span
                  className={classes.dot}
                  style={{
                    background:
                      props?.selected === true
                        ? "#FFF"
                        : props?.textColor
                        ? props?.textColor
                        : "",
                    opacity: 1,
                  }}
                />{" "}
                {props?.detail}
              </Typography>
            )}
          </Grid>
        </Grid>

        <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
          <div
            className={classes.rightArrowContainer}
            style={{ background: props?.selected === true && "#FFF" }}
          >
            <ChevronRight style={{ color: "#5F72A4", marginTop: "2px" }} />
          </div>
        </Grid>
      </Grid>
    </Card>
  );
}

export default MenuCard;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "88px",
    width: "100%",
    boxShadow: "0px 4px 12px rgba(173, 173, 173, 0.05)",
    borderRadius: "11px",
    backgroundColor: "#FFF",
    alignItems: "center",
    cursor: "pointer",
  },
  menuIconStyle: {
    borderRadius: "11px",
    height: "66px",
    width: "66px",
    justifyContent: "center",
    lineHeight: "78px",
    alignItems: "center",
    display: "flex",
  },
  title: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "18px",
    lineHeight: "21px",
    color: "#3C4A6B",
    marginTop: "15px",
    marginLeft: "6px",
  },
  subTitle: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "14px",
    color: "#5F72A4",
    opacity: 0.7,
    textAlign: "left",
    marginLeft: "6px",
  },
  dot: {
    background: "#7D8CB5",
    opacity: 0.7,
    height: "6px",
    width: "6px",
    borderRadius: "50%",
    display: "inline-block",
    marginRight: "3px",
  },
  rightArrowContainer: {
    height: "26px",
    width: "26px",
    borderRadius: "50%",
    background: "#F1F1F4",
    marginTop: "18px",
    textAlign: "center",
  },
}));
