import React, { useEffect } from "react";
import { useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import NotificationWebContainer from "../../containers/Web/Notifications";
import NotificationsMobileContainer from "../../containers/Mobile/Notifications";


const Notification = () => {
  const theme = useTheme();

  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  

  return (
    <>
      {isDesktop && <NotificationWebContainer />}
      {!isDesktop && isMobile && <NotificationsMobileContainer />}
    </>
  );
};

export default Notification;
