import React, { useEffect, useState } from "react";
import { Grid, Typography, useMediaQuery } from "@material-ui/core";
import { useLocation, useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import AppBar from "../../components/AppBar";
import {
  getCoursesGroupByCategory,
  getUserEnrolledCourses,
  getUserUploadedCourses,
} from "../../actions/courses";
import { createChallengeData } from "../../actions/challenges";
import CourseCard from "../../components/Common/CourseCard";

import "./searchFeed.scss";
function CategoryViewAll() {
  const classes = useStyles();
  const history = useHistory();
  const { state } = useLocation();
  const theme = useTheme();
  const [categoryArr, setCategoryArr] = useState([]);

  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  let dispatch = useDispatch();
  const authReducerState = useSelector((state) => state.auth);
  let userUploadedCoursesList = useSelector(
    (state) => state?.courses?.userUploadedCourses
  );
  const coursesGroupByCategory = useSelector(
    (state) => state?.courses?.coursesGroupByCategoryList
  );

  const getData = () => {
    dispatch(getCoursesGroupByCategory({ token: authReducerState?.token }));
  };
  const userEnrolledCourses = useSelector(
    (state) => state?.courses?.userEnrolledCoursesList
  );
  const courseClickHandler = (e, course) => {
    e.preventDefault();
    console.log(course,"course")
   if (course?.id) {
      if (state?.toBeOpenTab) {
        dispatch(
          createChallengeData({
            courseInfo: { ...course, state },
            course: true,
          })
        );
        history.push(`/more/create-challenge`);
      } else {
        history.push(`/view-course/${course?.slug}`);
      }
    }
  };

  console.log(state?.category, "catrogy****");
  console.log(coursesGroupByCategory, "coursesGroupByCategory****");
  useEffect(() => {
    getData();
    filteredData();
    dispatch(getUserEnrolledCourses(authReducerState?.token));
    {
      authReducerState.user.role !== "Regular" &&
        dispatch(getUserUploadedCourses(authReducerState?.token));
    }
  }, []);

  const filteredData = () => {
    if (coursesGroupByCategory != null && coursesGroupByCategory != undefined && Object.keys(coursesGroupByCategory).length > 0) {
      const categoryCourses =
      Object.keys(coursesGroupByCategory)
        .filter((key) => key.includes(state?.category))
        .reduce((obj, key) => {
          return Object.assign(obj, {
            [key]: coursesGroupByCategory[key],
          });
        }, {});
    setCategoryArr(categoryCourses[state?.category]);
    } else {
      setCategoryArr([]);
    }

    /*  console.log(categoryCourses,"categoryCourses");
  console.log(categoryCourses[state?.category],"array&&&&&&&&&&&&&&&&") */
  };

  console.log(categoryArr, "categoryArr");

  return (
    <Grid container style={{ paddingBottom: "60px" }}>
      <Grid item xs={11}>
        {isDesktop && <AppBar appBarConfig={{ show: true, text: "" }} />}
      </Grid>
      <Grid item xs={1} />
      <Grid item xs={1} />
      <Grid item xs={11} container>
        <Grid item xs={12} container>
          <Grid item lg={9} xs={12}>
            <Grid container>
              <Grid item xs={12} container spacing={3}>
                <Grid item xs={12}>
                  <Typography className={classes.heading}>
                    {`${state?.category}  Courses`}
                  </Typography>
                </Grid>

                {categoryArr &&
                  categoryArr.filter(
                    item =>
                      (item?.status === 'Active')
                  ).length > 0 ? (categoryArr
                    .filter(
                      item =>
                        item?.status === 'Active'
                    )
                    .map((course, index) => {

                      return (
                        <Grid
                          item
                          xs={6}
                          md={4}
                          lg={4}
                          key={index}
                          onClick={(e) => courseClickHandler(e, course)}
                          style={{ marginTop: "5px" }}
                        >
                          <CourseCard
                            image={course?.image}
                            name={course?.name}
                            courseNameBold="true"
                          />
                        </Grid>
                      );
                  })
                ) : (
                  <Grid item xs={12}>
                    <Typography className={classes.noContent}>
                      No Course found
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </Grid>

            {/*  <Grid container>
              <Grid item xs={12} container spacing={3}>
                <Grid item xs={12}>
                  <Typography className={classes.heading}>
                    User Enrolled Courses
                  </Typography>
                </Grid>
                {state?.category ? userEnrolledCourses &&
                  userEnrolledCourses.filter(
                    item =>
                    (state?.category ===
                      item?.course?.enrolledCourses?.category?.name && item?.course?.enrolledCourses?.status === 'Active' && !(item?.course?.enrolledCourses?.isPrivate))
                  ).length > 0 ? userEnrolledCourses
                    .filter(
                      item =>
                        state?.category ===
                        item?.course?.enrolledCourses?.category?.name && item?.course?.enrolledCourses?.status === 'Active' && !(item?.course?.enrolledCourses?.isPrivate)
                    )
                    .map((course, index) => {
                      const { enrolledCourses } = course.course;
                      return (
                        <Grid
                          item
                          xs={6}
                          md={4}
                          lg={4}
                          key={index}
                          onClick={e => courseClickHandler(e, enrolledCourses)}
                          style={{ marginTop: "5px" }}
                        >
                          <CourseCard
                            image={enrolledCourses?.image}
                            name={enrolledCourses?.name}
                            courseNameBold="true"
                          />
                        </Grid>
                      );
                    }) : <Grid item xs={12}>
                  <Typography className={classes.noContent}>
                    No Enrolled Courses
                  </Typography>
                </Grid> : userEnrolledCourses && userEnrolledCourses.length > 0 ? (
                  userEnrolledCourses?.map((course, index) => {
                    const { enrolledCourses } = course.course;
                    return (
                      <Grid
                        item
                        xs={6}
                        md={4}
                        lg={4}
                        key={index}
                        onClick={e => courseClickHandler(e, enrolledCourses)}
                        style={{ marginTop: "5px" }}
                      >
                        <CourseCard
                          image={enrolledCourses?.image}
                          name={enrolledCourses?.name}
                          courseNameBold="true"
                        />
                      </Grid>
                    );
                  })
                ) : (
                  <Grid item xs={12}>
                    <Typography className={classes.noContent}>
                      No Record found
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </Grid> */}
            {authReducerState?.user.role !== "Regular" && (
              <Grid container>
                <Grid item xs={12} container>
                  <Grid item xs={12}>
                    <Typography className={classes.heading}>
                      User Uploaded Courses
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={12} container spacing={3}>
                  {state?.category ? (
                    userUploadedCoursesList &&
                    userUploadedCoursesList.filter(
                      (item) =>
                        state?.category === item.category?.name &&
                        item?.status === "Active" &&
                        !item?.isPrivate
                    ).length > 0 ? (
                      userUploadedCoursesList
                        .filter(
                          (item) =>
                            state?.category === item.category?.name &&
                            item?.status === "Active" &&
                            !item?.isPrivate
                        )
                        .map((course, index) => {
                          const enrolledCourses = course;
                          return (
                            <Grid
                              item
                              xs={6}
                              md={4}
                              lg={4}
                              key={index}
                              onClick={(e) =>
                                courseClickHandler(e, enrolledCourses)
                              }
                              style={{ marginTop: "5px" }}
                            >
                              <CourseCard
                                image={enrolledCourses?.image}
                                name={enrolledCourses?.name}
                                courseNameBold="true"
                              />
                            </Grid>
                          );
                        })
                    ) : (
                      <Grid item xs={12}>
                        <Typography className={classes.noContent}>
                          No Enrolled Courses
                        </Typography>
                      </Grid>
                    )
                  ) : userUploadedCoursesList &&
                    userUploadedCoursesList.length > 0 ? (
                    userUploadedCoursesList.map((course, index) => {
                      const enrolledCourses = course;
                      return (
                        <Grid
                          item
                          xs={6}
                          md={4}
                          lg={4}
                          key={index}
                          onClick={(e) =>
                            courseClickHandler(e, enrolledCourses)
                          }
                        >
                          <CourseCard
                            image={enrolledCourses?.image}
                            name={enrolledCourses?.name}
                            courseNameBold="true"
                          />
                        </Grid>
                      );
                    })
                  ) : (
                    <Grid item xs={12}>
                      <Typography className={classes.noContent}>
                        No Record found
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default CategoryViewAll;

const useStyles = makeStyles((theme) => ({
  noContent: {
    color: "#7d7b79",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "19px",
    padding: "12px 10px 10px 17px",
    textAlign: "center",
  },
  heading: {
    fontFamily: theme.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: 900,
    fontSize: "20px",
    lineHeight: "27px",
    color: "#000000",
    marginTop: "25px",
  },
  viewAll: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "16px",
    color: " #068594",
    textDecoration: "none",
  },
  hideScrollBar: {
    flexWrap: "unset",
    overflowX: "auto",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  scrollBar: {
    flexWrap: "unset",
    overflowX: "auto",
  },
}));