import { useState, useEffect, useRef, useContext } from 'react'
import { Grid, Avatar, Typography, Card, CardContent, CardActions, IconButton, CardHeader, OutlinedInput, useMediaQuery, CircularProgress } from '@material-ui/core'
import { AttachFile } from '@material-ui/icons'
import { makeStyles, useTheme } from '@material-ui/styles'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import Send from '@material-ui/icons/Send';
import Close from '@material-ui/icons/Close';
import userPhoto from '../../../assets/img/courseBackground.svg'
import ReceiveMessageCard from "./ReceiveMessageCard";
import SendMessageCard from "./SendMessageCard";
import { SocketContext } from '../../../Context/socketContext'
import {
    getRoomMessages,
    sendChatMedia,
    getUserChats
} from "../../../actions/chat";

function ChatMessages(props) {
    const classes = useStyles();
    const theme = useTheme();
    const params = useParams();
    const dispatch = useDispatch();
    const { friendInfo } = useLocation();
    const history = useHistory();
    const scrollAble = useRef(null);
    const selectedChatUser = useSelector(state => state?.chat?.selectedChatUser);
    const authReducer = useSelector(state => state.auth);
    const chatMessages = useSelector(state => state?.chat?.chatMessages);
    const mediaUploadProgress = useSelector(state => state?.chat?.mediaUploadProgress);
    const chatLoader = useSelector(state => state?.chat?.chatMessagesLoader);
    const allChats = useSelector(state => state?.chat?.chats);
    const chatsReducer = useSelector((state) => state?.chat?.chats);


    const [message, setMessage] = useState("");
    const [userExist, setUserExist] = useState(true);
    const [messages, setMessages] = useState([]);
    const [socketIo, setRefreshRoom, refreshRoom] = useContext(SocketContext);

    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const receiverId = params.roomId.split("_").filter(item => item !== authReducer?.user?.id?.toString())[0];
    useEffect(() => {
        scrollAble.current.scrollIntoView();
    });
    const updateReadStatus = async () => {

        await socketIo.emit("readStatus", {
            roomId: params?.roomId,
            receiverId: receiverId,
            readerId: authReducer?.user?.id,
        });

        dispatch(getUserChats(authReducer?.token));
    }

    useEffect(() => {
        if (refreshRoom?.roomId === params?.roomId) {
            dispatch(
                getRoomMessages({ token: authReducer?.token, roomId: params?.roomId, load: false })
            );
            updateReadStatus();
        }
    }, [refreshRoom, params?.roomId]);

    useEffect(() => {
        if (params?.roomId && socketIo) {

            socketIo.emit("subscribe", params?.roomId);
            dispatch(
                getRoomMessages({ token: authReducer?.token, roomId: params?.roomId, load: true })
            );
            socketIo.on("messageRead", async roomId => {
                if (roomId) {
                    dispatch(
                        getRoomMessages({ token: authReducer?.token, roomId: params?.roomId, load: false })
                    );
                }
            })
            dispatch({ type: "SELECTED_ROOM_ID", payload: params?.roomId });
            updateReadStatus()

        }
        const roomId = params?.roomId;
        return () => {
            dispatch({
                type: 'GET_CHAT_MESSAGES',
                payload: []
            });
            dispatch({
                type: 'SELECTED_CHAT_USER',
                payload: {}
            });
            dispatch({ type: "SELECTED_ROOM_ID", payload: null });

        }
    }, [params?.roomId])

    useEffect(() => {
        const userExistLocal = allChats?.find(chat => {
            return chat?.id === params?.roomId
        });
        userExistLocal?.id && setUserExist(userExistLocal?.userExist);
    }, [allChats])

    useEffect(() => {
        if (chatMessages.length > 0) {
            setMessages(chatMessages);
            dispatch({
                type: 'SELECTED_CHAT_USER',
                payload: (chatMessages[0]?.senderId !== authReducer?.user?.id ? chatMessages[0]?.sender : chatMessages[0]?.receiver)
            })
        }
    }, [chatMessages.length]);


    const sendMessage = () => {
        const receiverIdTemp = params.roomId.split("_").filter(item => item !== authReducer?.user?.id?.toString())[0];
        if (message.trim().length === 0) {
            setMessage('')
        } else {
            const data = {
                roomId: params?.roomId,
                message: {
                    message,
                    messageType: "Text",
                    read: false,
                    senderId: authReducer?.user?.id,
                    receiverId: receiverIdTemp
                }
            };
            socketIo && socketIo.emit("message", data);
            socketIo && socketIo.emit("readStatus", {
                roomId: message?.roomId,
                receiverId: receiverIdTemp, readerId: authReducer?.user?.id
            });
            dispatch(
                getRoomMessages({ token: authReducer?.token, roomId: params?.roomId })
            );

            setMessage("");
            dispatch(getUserChats(authReducer?.token));
        }
    };


    const sendMultimediaMessage = async (e) => {
        const formData = new FormData();
        formData.append("chatMedia", e.target.files[0]);
        const resUrl = await dispatch(
            sendChatMedia({
                token: authReducer?.token,
                chatMedia: formData,
                roomId: params?.roomId,

            })
        );
        if (resUrl) {
            const data = {
                roomId: params?.roomId,
                message: {
                    message: resUrl?.chatMediaUrl,
                    messageType: "Image",
                    read: false,
                    senderId: authReducer?.user?.id,
                    receiverId: selectedChatUser?.id
                }
            };
            socketIo.emit("message", data);
            socketIo && socketIo.emit("readStatus", {
                roomId: message?.roomId,
                receiverId: receiverId, readerId: authReducer?.user?.id
            });
            dispatch(
                getRoomMessages({ token: authReducer?.token, roomId: params?.roomId })
            );
            setMessage("");
            dispatch(getUserChats(authReducer?.token));
        }
    };

    return (

        <Grid container >
            <Grid xs={12}>

                <Card className={classes.root} style={{ height: '100vh', width: '100%' }}>

                    {isMobile && <CardHeader
                        className={classes.header}

                        avatar={
                            <Avatar src={selectedChatUser?.pictureUrl} aria-label="recipe" className={classes.avatar}>
                                {selectedChatUser?.name && selectedChatUser?.name[0]}
                            </Avatar>
                        }

                        title={selectedChatUser?.name}
                        action={
                            <IconButton className={classes.headerAction}>
                                <Close onClick={() => history.push('/more/inbox')} />
                            </IconButton>
                        }
                    />}
                    <CardContent className={classes.messageBody}>
                        {/* <Typography variant="body2" color="textSecondary" component="p" > */}
                        <Grid container style={{ marginBottom: '30px', paddingTop: isMobile ? '165px' : '100px' }}>
                            {chatLoader ? <Grid item xs={12} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: "100vh" }}>
                                <CircularProgress size={20} style={{ color: '#09A9BB' }} />
                            </Grid> :
                                messages?.length > 0 ? messages?.map(message => {
                                    if (message?.sender?.id !== authReducer?.user?.id) {
                                        return <Grid item container xs={12}>
                                            <Grid item xs={10} lg={10} style={{ marginBottom: "10px" }}>
                                                {
                                                    message?.messageType === "Text" ? <ReceiveMessageCard message={message} /> : <div
                                                        className={classes.mediaStyle}
                                                        style={{
                                                            backgroundImage: `url(${message?.message})`,
                                                            marginRight: "15px",
                                                            marginLeft: "0px"
                                                        }}
                                                    ></div>
                                                }
                                            </Grid>
                                            <Grid item lg={2} xs={2} />
                                        </Grid>
                                    } else {
                                        return <Grid item container xs={12}>
                                            <Grid item lg={2} xs={2} />
                                            <Grid item xs={10} lg={10} style={{ marginBottom: "10px" }}>
                                                {
                                                    message?.messageType === "Text" ? <SendMessageCard message={message} /> : <div
                                                        className={classes.mediaStyle}
                                                        style={{
                                                            backgroundImage: `url(${message?.message})`,
                                                            marginRight: "15px",
                                                            marginLeft: "0px"
                                                        }}
                                                    ></div>
                                                }
                                            </Grid>
                                        </Grid>
                                    }
                                }) : (
                                    <Grid item xs={12}>
                                        <Typography className={classes.noContent}>
                                            No message yet
                                        </Typography>
                                    </Grid>
                                )
                            }
                            {mediaUploadProgress > 0 && mediaUploadProgress < 100 && (
                                <>
                                    <Grid item lg={2} xs={2} />
                                    <Grid item xs={10} style={{ marginBottom: "10px" }}>
                                        <div
                                            className={classes.mediaStyle}
                                            style={{
                                                background: `#c2c2c2`,
                                                marginRight: "15px",
                                                marginLeft: "0px",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center"
                                            }}
                                        >
                                            <CircularProgress
                                                variant="static"
                                                value={mediaUploadProgress}
                                            />
                                        </div>
                                    </Grid>
                                </>
                            )}
                            <Grid item xs={12}>
                                <div ref={scrollAble} style={{ height: '5px' }}></div>
                            </Grid>
                        </Grid>

                        {/* </Typography> */}
                    </CardContent>

                    <CardActions style={{ bottom: 3, position: 'sticky', background: '#FBFAF9', zIndex: 200, padding: isMobile ? '0px 8px 0px 8px' : '0px 10px 0px 10px' }}>
                        {userExist ? <Grid container >
                            <Grid xs={9} md={10} lg={10} xl={11} >
                                <OutlinedInput
                                    multiline
                                    rowsMax={2}
                                    fullWidth
                                    placeholder={'Type here...'}
                                    classes={{ root: classes.messageInputRoot, focused: classes.focused }}
                                    id="outlined-adornment-weight"
                                    value={message}
                                    onChange={(e) => setMessage(e.target.value)}
                                    onKeyPress={e => {
                                        (e.which === 13 || e.keyCode === 13) && message && sendMessage();
                                    }}
                                    endAdornment={<>
                                        <label className={classes.inputFile}>
                                            <AttachFile className={classes.iconStyle} />
                                            <input
                                                className={classes.attachFile}
                                                type="file"
                                                accept="image/*"
                                                onChange={sendMultimediaMessage}
                                            />
                                        </label>

                                    </>}
                                    aria-describedby="outlined-weight-helper-text"
                                    inputProps={{
                                        'aria-label': 'weight',
                                    }}
                                    labelWidth={0}
                                />

                            </Grid>
                            <Grid xs={3} md={2} lg={2} xl={1} style={{ textAlign: 'right' }}>
                                <IconButton onClick={message && sendMessage} style={{ background: '#09A9BB', height: '60px', width: '60px' }}>
                                    <Send style={{ color: '#FFF' }} />
                                </IconButton>

                            </Grid>
                        </Grid> :
                            <Grid item xs={12}>
                                <Typography style={{ textAlign: 'center', color: '#09A9BB' }}>This user no longer exist!</Typography>
                            </Grid>}

                    </CardActions>
                </Card>
            </Grid>

        </Grid>
    )
}

export default ChatMessages

const useStyles = makeStyles(theme => ({
    header: {
        position: 'fixed', width: '100%', top: 0, padding: '0px 0px 0px 8px', display: 'flex', alignItems: 'center', minHeight: '70px', background: '#FFF', zIndex: 200, wordBreak: 'break-all'
    },
    headerAction: {
        margin: '0px 8px 0px 0px', display: 'flex', alignItems: 'center', minHeight: '85px'
    },
    photo: {
        width: '66px',
        height: '66px',
        textAlign: 'center',
        marginLeft: '5px'
    },
    noContent: {
        color: "#7d7b79",
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "16px",
        lineHeight: "19px",
        padding: "20px 10px 10px 17px",
        textAlign: "center"
    },
    userName: {
        fontFamily: theme.fontFamily.Roboto,
        fontStyle: "normal",
        fontWeight: 'bold',
        fontSize: "16px",
        lineHeight: "137.19%",
        color: '#656565',
    },
    messageInput: {
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "green"
        },
        "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "red"
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "purple"
        }
    },
    inputFile: {
        '& input[type="file"]': {
            display: 'none'
        }
    },
    iconStyle: {
        color: '#717171'
    },
    messageInputRoot: {
        color: '#78849E',
        width: '100%',
        borderRadius: '25px',
        background: '#E3E3E3',
        border: '1px solid #E3E3E3 !important',

    },
    focused: {

        border: '1px solid #E3E3E3 !important',

    },
    messageBody: {
        background: '#FBFAF9',
        height: '100%', overflowY: 'auto',
        "&::-webkit-scrollbar": {
            width: "0px",
            background: "transparent" /* make scrollbar transparent */
        }
    },
    mediaStyle: {
        height: "270px",
        width: "100%",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        marginTop: "10px",
        borderRadius: "9px",
        backgroundPosition: "center",
        marginLeft: "15px"
    },
}))