import { makeStyles } from "@material-ui/core/styles";
import { CheckCircleTwoTone } from "@material-ui/icons";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import { useIntl } from "react-intl";
import Truncate from "react-truncate";
import { useParams } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        maxHeight: "260px",
        borderRadius: "11px",
        boxShadow: " 0px 4px 4px rgba(0, 0, 0, 0.05)",

    },
    media: {
        height: "0",
        position: "relative",
        paddingTop: "56.25%",
        width: "100%",
        BorderRadiusTopRight: "11px",
        BorderRadiusTopLeft: "11px",
        background: "#C4C4C4",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: 'center'
    },
    title: {
        fontFamily: theme.fontFamily.Reef,
        fontStyle: "normal",
        fontWeight: "900",
        fontSize: "14px",
        lineHeight: "19px",
        textAlign: "center",
        color: "#000000",
        margin: "auto",
        paddingBottom: "10px",
    },
    subTitle: {
        fontFamily: theme.fontFamily.Reef,
        fontStyle: "normal",
        fontWeight: "900",
        fontSize: "10px",
        lineHeight: "14px",
        textAlign: "center",
        color: "#C2C2C2",
    },
    nameBold: {
        fontFamily: theme.fontFamily.Roboto,
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "18px",
        lineHeight: "21px",
        letterSpacing: " 0.05em",
        color: "#3C4A6B",
        marginLeft: "20px",
        marginTop: "10px",
    },
}));

function CourseCard(props) {
    const classes = useStyles();
    const intl = useIntl();
    const params = useParams();

    return (
        <Card className={classes.root}>
            <CardMedia className={classes.media} image={props?.image} style={{backgroundColor: props?.image && '#fff'}}>
                <Tooltip title={params?.id ? '' : props?.checkedStatus ? 'unselect episode' : 'select episode'} placement='top'>
                    <Grid container style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0
                    }}>
                        <Grid xs={10} />
                        <Grid xs={2}>
                            {(!params?.id && props?.checkedStatus) && <CheckCircleTwoTone style={{ color: '#87AC26', fontSize: '30px', marginTop: '5px', cursor: 'pointer' }}
                            />}
                        </Grid>
                    </Grid>
                </Tooltip>

            </CardMedia>
            <CardContent style={{ padding: '7px' }}>
                <Tooltip title={props?.name}>

                    <Typography
                        gutterBottom
                        className={props?.courseNameBold ? classes.nameBold : classes.title}
                    >
                        <Truncate lines={1} ellipsis={<span>...</span>}>
                        {`    ${ props?.name}   `}
                        </Truncate>
                    </Typography>
                </Tooltip>

            </CardContent>
        </Card>
    );
}

export default CourseCard;
