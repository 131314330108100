import { Editor } from 'react-draft-wysiwyg';
import { useMediaQuery } from '@material-ui/core'
import { useTheme } from '@material-ui/styles'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './rich.css';

const MyEditor = (props) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const onEditorStateChange = (editorState) => {
        return props?.changeDescription(editorState)
    };


    return (
        <div className='RichEditor-root' >
            <Editor
                editorState={props?.editorState}
                toolbar={{
                    options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'history'],
                    inline: {
                        options: ['bold', 'italic', 'underline']
                    },
                    list: {
                        options: ['unordered', 'ordered']
                    }
                }}

                wrapperClassName="demo-wrapper"
                editorClassName="demo-editor"
                onEditorStateChange={onEditorStateChange}
                style={{ border: '1px solid black' }}
                placeholder={props?.placeholder}
            />
           
        </div >
    );

}

export default MyEditor