import React, { useEffect } from "react";
import { Route, Redirect, useHistory, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import InstallPrompt from "../../../utils/InstallPromt";
import MobileLayout from "../../../containers/Layout/MobileLayout";
import DesktopLayout from "../../../containers/Layout/DesktopLayout";
import CommonFileLayout from "../../../containers/Layout/CommonFileLayout";
import { getCurrentLoggedInUser } from "../../../actions/user";

function Layout(props) {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return props?.commonFileLayout ? (
    <CommonFileLayout
      appBarConfig={props.appBarConfig}
      showBottomNav={props.showBottomNav}
    >
      {props.children}
    </CommonFileLayout>
  ) : isMobile && !isDesktop ? (
    <MobileLayout
      appBarConfig={props.appBarConfig}
      showBottomNav={props.showBottomNav}
    >
      {props.children}
    </MobileLayout>
  ) : (
    <DesktopLayout>{props.children}</DesktopLayout>
  );
}

function PrivateRoute({
  component: Component,
  showBottomNav,
  appBarConfig,
  notAccessible,
  commonFileLayout,
  ...rest
}) {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const appConfig = useSelector((state) => state.auth);
  const { isAuthenticated, user } = appConfig || {};
  const { isAuthenticatedFunction = () => false, signInURL = "/auth" } =
    !isAuthenticated || {};

  const getUserData = async () => {
    const userData = JSON.parse(localStorage.getItem("userDataLocal"));
    if (userData?.token) {
      const res = await dispatch(
        getCurrentLoggedInUser({ token: userData?.token })
      );
      if (res && res?.response && res?.response?.status === 403) {
        dispatch({ type: "LOG_OUT" });
        localStorage.setItem("userDataLocal", null);
        return history.push("/auth");
      }
    }
  };

  useEffect(() => {
    getUserData();
  }, []);

  return (
    <>
      <InstallPrompt />
      <Layout
        appBarConfig={appBarConfig}
        showBottomNav={showBottomNav}
        commonFileLayout={commonFileLayout}
      >
        <Route
          {...rest}
          render={(props) =>
            isAuthenticated && !notAccessible?.includes(user.role) ? (
              <Component {...props} key={props.location.key} />
            ) : (
              <Redirect
                to={{
                  pathname: isAuthenticated ? "/dashboard" : signInURL,
                  state: { from: props.location },
                  search:
                    !isAuthenticated &&
                    new URLSearchParams(location.search).get("tab") === "signup"
                      ? "?tab=signup"
                      : "",
                }}
              />
            )
          }
        />
      </Layout>
    </>
  );
}

export default PrivateRoute;
