import React, { useState, useEffect } from 'react';
import { Grid, Typography, Button, Dialog, DialogTitle, DialogContent, DialogActions, IconButton, Checkbox, CircularProgress } from '@material-ui/core'
import { Close } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import { useSelector, useDispatch } from 'react-redux';
import { unjoinUnderSubscription, continueWithExisting } from '../../../actions/SubscriptionPlan'

function ExpireSubscriptionPopup() {
    const dispatch = useDispatch();
    const classes = useStyles();

    const allJoined = useSelector(state => state?.subscriptionPlan?.userUnderSubscriptionJoined);

    const auth = useSelector((state) => state?.auth);
    const user = useSelector((state) => state?.user?.user);

    const [open, setOpen] = useState(false);
    const [loader, setLoader] = useState(false);
    const [error, setError] = useState(["omidCourses"]);
    const [unjoin, setUnjoinList] = useState({
      courses: [],
      sessions: [],
      retreats: [],
    });

    const [totalCourses, setTotalCourses] = useState();
    const [totalSessions, setTotalSessions] = useState();
    const [totalRetreats, setTotalRetreats] = useState();
    const [totalTeacherTrainings, setTeacherTrainings] = useState();

    const handleChange = (e, item, name) => {
      let unJoinedLocal = unjoin;
      if (e.target.checked) {
        unJoinedLocal[name].push(item?.id);
        setUnjoinList({ ...unJoinedLocal });
      } else {
        let popArr = unJoinedLocal[name].filter((i) => i !== item?.id);
        setUnjoinList({ ...unjoin, [name]: popArr });
      }
    };

    const handleContinue = async () => {
      setLoader(true);
      await dispatch(continueWithExisting({ token: auth.token }));
      setLoader(false);
      setOpen(false);
    };

    const handleClose = () => {
      setOpen(false);
      setLoader(false);
    };

    const handleLeave = async () => {
      setLoader(true);
      await dispatch(
        unjoinUnderSubscription({
          token: auth.token,
          data: {
            courseIds: unjoin?.courses,
            sessionIds: unjoin?.sessions,
            retreatIds: unjoin?.retreats,
          },
        })
      );
      setUnjoinList({ courses: [], sessions: [], retreats: [] });
      setLoader(false);
    };

    useEffect(() => {
      if (
        user &&
        user?.isExpiredSubscription &&
        user?.purchasedSubscription &&
        user?.purchasedSubscription[0]?.status === "active"
      ) {
        const {
          omidCourses,
          teacherCourses,
          omidSessions,
          teacherSessions,
          omidRetreats,
          teacherRetreats,
          omidTeacherTrainings,
          teachersTeacherTrainings,
        } = allJoined;

        setTotalCourses(parseInt(omidCourses) + parseInt(teacherCourses));
        setTotalSessions(parseInt(omidSessions) + parseInt(teacherSessions));
        setTotalRetreats(parseInt(omidRetreats) + parseInt(teacherRetreats));
        setTeacherTrainings(
          parseInt(omidTeacherTrainings) + parseInt(teachersTeacherTrainings)
        );

        if (
          totalCourses?.length > 0 ||
          totalSessions?.length > 0 ||
          totalRetreats?.length > 0 ||
          totalTeacherTrainings?.length > 0
        ) {
          setOpen(true);
        } else {
          dispatch(continueWithExisting({ token: auth.token }));
        }
      }
    }, [user]);


    return (
        <Dialog open={open}>
            <DialogTitle>
                <Grid container justify="space-between">
                    <Grid item>
                        <Typography component="h4" variant="h5" >
                            Leave or continue
                        </Typography>
                    </Grid>
                    <Grid item>
                        <IconButton onClick={handleClose}>
                            <Close />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent style={{ display: 'flex', justifyContent: 'center' }}>
                <Grid container>
                    <Grid item xs={12}>
                        <Typography >
                            Your monthly courses you joined have ended at the end of the month.
                            Please cancel any course you do not wish to continue in order to open space for new courses you wish to join in the coming month.
                        </Typography>
                    </Grid>

                    {allJoined?.totalCourses?.length > 0 && <><Grid item xs={12}>
                      {/*   <Typography className={classes.label}></Typography> */}
                    </Grid>
                        {allJoined?.totalCourses.map(item => (<Grid item xs={12} key={item?.id} container style={{ alignItems: 'center' }}>
                            <Grid item xs={2}><Checkbox color='primary' classes={{ colorPrimary: classes.checkbox }} onChange={(e) => handleChange(e, item, 'courses')} /></Grid>
                            <Grid item xs={10}><Typography>{item?.name}</Typography></Grid>
                            {/* <Grid item xs={5}>{item?.user?.name}</Grid> */}
                        </Grid>))
                        }
                    </>
                    }
                    {allJoined?.teacherCourses?.length > 0 && <> <Grid item xs={12}>
                       {/*  <Typography className={classes.label}>Delete:</Typography> */}
                    </Grid>

                        {
                            allJoined?.teacherCourses.map(item => (<Grid item xs={12} key={item?.id} container style={{ alignItems: 'center' }}>
                                <Grid item xs={2}><Checkbox color='primary' onChange={(e) => handleChange(e, item, 'courses')} classes={{ colorPrimary: classes.checkbox }} /></Grid>
                                <Grid item xs={10}><Typography>{item?.name}</Typography></Grid>
                                {/* <Grid item xs={5}>{item?.user?.name}</Grid> */}
                            </Grid>))
                        }
                    </>

                    }

                    {allJoined?.totalSessions?.length > 0 && <> <Grid item xs={12}>
                      {/*   <Typography className={error.includes('omidSessions') ? classes.labelError : classes.label}>Delete:</Typography> */}
                    </Grid>

                        {
                            allJoined?.totalSessions.map(item => (<Grid item xs={12} key={item?.id} container style={{ alignItems: 'center' }}>
                                <Grid item xs={2}><Checkbox color='primary' onChange={(e) => handleChange(e, item, 'sessions')} classes={{ colorPrimary: classes.checkbox }} /></Grid>
                                <Grid item xs={10}><Typography>{item?.name}</Typography></Grid>
                                {/* <Grid item xs={5}>{item?.user?.name}</Grid> */}
                            </Grid>))
                        }
                    </>

                    }
                    {allJoined?.teacherSessions?.length > 0 && <> <Grid item xs={12}>
                       {/*  <Typography className={classes.label}>Delete:</Typography> */}
                    </Grid>

                        {
                            allJoined?.teacherSessions.map(item => (<Grid item xs={12} key={item?.id} container style={{ alignItems: 'center' }}>
                                <Grid item xs={2}><Checkbox color='primary' onChange={(e) => handleChange(e, item, 'sessions')} classes={{ colorPrimary: classes.checkbox }} /></Grid>
                                <Grid item xs={10}><Typography>{item?.name}</Typography></Grid>
                                {/* <Grid item xs={5}>{item?.user?.name}</Grid> */}
                            </Grid>))
                        }
                    </>

                    }
                    {allJoined?.totalRetreats?.length > 0 && <> <Grid item xs={12}>
                       {/*  <Typography className={classes.label}>Delete:</Typography> */}
                    </Grid>

                        {
                            allJoined?.totalRetreats.map(item => (<Grid item xs={12} key={item?.id} container style={{ alignItems: 'center' }}>
                                <Grid item xs={2}><Checkbox color='primary' onChange={(e) => handleChange(e, item, 'retreats')} classes={{ colorPrimary: classes.checkbox }} /></Grid>
                                <Grid item xs={10}><Typography>{item?.name}</Typography></Grid>
                                {/* <Grid item xs={5}>{item?.user?.name}</Grid> */}
                            </Grid>))
                        }
                    </>

                    }
                    {allJoined?.teacherRetreats?.length > 0 && <> <Grid item xs={12}>
                       {/*  <Typography className={classes.label}>Delete:</Typography> */}
                    </Grid>

                        {
                            allJoined?.teacherRetreats.map(item => (<Grid item xs={12} key={item?.id} container style={{ alignItems: 'center' }}>
                                <Grid item xs={2}><Checkbox color='primary' onChange={(e) => handleChange(e, item, 'retreats')} classes={{ colorPrimary: classes.checkbox }} /></Grid>
                                <Grid item xs={10}><Typography>{item?.name}</Typography></Grid>
                                {/* <Grid item xs={5}>{item?.user?.name}</Grid> */}
                            </Grid>))
                        }
                    </>

                    }
                    {allJoined?.totalTeacherTrainings?.length > 0 && <> <Grid item xs={12}>
                      {/*   <Typography className={classes.label}>Delete:</Typography> */}
                    </Grid>

                        {
                            allJoined?.totalTeacherTrainings.map(item => (<Grid item xs={12} key={item?.id} container style={{ alignItems: 'center' }}>
                                <Grid item xs={2}><Checkbox color='primary' onChange={(e) => handleChange(e, item, 'retreats')} classes={{ colorPrimary: classes.checkbox }} /></Grid>
                                <Grid item xs={10}><Typography>{item?.name}</Typography></Grid>
                                {/* <Grid item xs={5}>{item?.user?.name}</Grid> */}
                            </Grid>))
                        }
                    </>

                    }
                    {allJoined?.teachersTeacherTrainings?.length > 0 && <> <Grid item xs={12}>
                      {/*   <Typography className={classes.label}>Delete:</Typography> */}
                    </Grid>

                        {
                            allJoined?.teachersTeacherTrainings.map(item => (<Grid item xs={12} key={item?.id} container style={{ alignItems: 'center' }}>
                                <Grid item xs={2}><Checkbox color='primary' onChange={(e) => handleChange(e, item, 'retreats')} classes={{ colorPrimary: classes.checkbox }} /></Grid>
                                <Grid item xs={10}><Typography>{item?.name}</Typography></Grid>
                                {/* <Grid item xs={5}>{item?.user?.name}</Grid> */}
                            </Grid>))
                        }
                    </>

                    }
                </Grid>
            </DialogContent>
            <DialogActions>
                {
                    loader ? <Button className={classes.modelBtn} >
                        <CircularProgress className={classes.loader} size={20} />
                    </Button> :
                        <>
                            <Button onClick={handleLeave} className={classes.modelBtn} disabled={unjoin?.courses?.length <= 0 && unjoin?.sessions?.length <= 0 && unjoin?.retreats?.length <= 0}>
                                cancel
                            </Button>
                            <Button onClick={handleContinue} className={classes.modelBtn} >
                                Continue
                            </Button>
                        </>
                }
            </DialogActions>

        </Dialog>
    );
}

export default ExpireSubscriptionPopup;

const useStyles = makeStyles((theme) => ({

    modelBtn: {
        fontFamily: theme.fontFamily.Roboto,
        cursor: "pointer",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "21px",
        textTransform: 'none',
        color: theme.colors.white,
        background: `linear-gradient(#09A9BB 1%,#09A9BB 100%)`
    },
    checkbox: {
        color: '#09A9BB',
        '&.Mui-checked': {
            color: '#09A9BB',
        }
    },
    label: {
        fontWeight: 'bold',
        fontSize: '16px'

    },
    labelError: {
        fontWeight: 'bold',
        color: '#ff0000'

    },
    errorTxt: {
        color: '#ff0000',
        textAlign: 'center'
    },
    loader: {
        color: '#fff',
    }
}));