import React, { useState, useEffect } from "react";
import { Grid, Box, useMediaQuery, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import CardWithThumbnail from "./SocialConnectsCard";
import Facebook from "../../../assets/Icons/facebook.svg";
import Google from "../../../assets/Icons/google.svg";

import AppBar from "../../../components/AppBar/index";
import { useTheme } from "@material-ui/core/styles";
import MobileAppbar from "../../../components/More/settings/AppBar";
import { useSelector, useDispatch } from 'react-redux'
import { useIntl } from "react-intl";
import { getCurrentLoggedInUser } from '../../../actions/user'

function SocialNetworks(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [currentIcon, setCurronIcon] = useState(false);
  const [option, setOption] = useState(null);

  const intl = useIntl();
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  const auth = useSelector(state => state?.auth)
  const user = useSelector(state => state?.user?.user)

  useEffect(() => {
    dispatch(getCurrentLoggedInUser({ token: auth?.token }))
  }, [])

  function getImage(props) {
    return (
      <div
        style={{
          height: "3.438em"
        }}
      >
        <img src={props} alt="" />
      </div>
    );
  }

  const handleClick = title => {
    setOption(title);
  };




  return (
    <Grid container lg={8} md={8} sm={12} xs={12} xl={8}>
      {isMobile && !isDesktop ? (
        <div
          style={{
            width: "100%",
            marginBottom: "1.5rem"
          }}
        >
          <MobileAppbar
            body={intl.formatMessage({
              id: "socialMediaAccounts"
            })}
            onClick={props.handleBack}
            backAction={intl.formatMessage({
              id: "settings"
            })}
            backgroundColor="#D7AFB4"
          />
        </div>
      ) : (
        <AppBar
          appBarConfig={{
            show: true,
            text: intl.formatMessage({
              id: "SocialNetworks"
            }),
            backAction: props.handleBack
          }}
        />
      )}
      {[
        {
          title: "Facebook",
          iconColor: "#0364D6",
          icon: getImage(Facebook),
          action: user?.facebookId ? intl.formatMessage({
            id: "Connected"
          }) : '+'
        },
        {
          title: "Google",
          iconColor: "#B8B8B8",
          icon: getImage(Google),
          action: user?.googleId ? intl.formatMessage({
            id: "Connected"
          }) : '+',
          showSwitch: false
        },

      ].map((item, index) => {
        return (
          <Grid item xs={12} >

            <CardWithThumbnail
              rootCard={classes.root}
              actionBtn={item && item.action && item.action}
              actionBtnStyle={item &&
                item.action &&
                item.action ===
                intl.formatMessage({
                  id: "Connected"
                })
                ? classes.actionText
                : classes.unselectActionText}

              title={item.title}
              IconContainerBackground={item.iconColor}
              Icon={item.icon}
              handleClick={handleClick}
              iconStyle={classes.iconStyle}
              currentIcon={currentIcon}
              handleChange={setCurronIcon}
              titleStyle={classes.title}
              showImage={true}
            />

          </Grid>
        );
      })}
    </Grid>
  );
}

export default SocialNetworks;

const useStyles = makeStyles(theme => ({
  actionText: {
    color: "#C2C2C2",
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "18px",
    lineHeight: "21px",
    textAlign: "left",
    marginRight: "20px",
    cursor: "pointer"
  },
  unselectActionText: {
    color: "#000000",
    cursor: "pointer",
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "30px",
    lineHeight: "21px",
    textAlign: "left",
    marginRight: "20px"
  },

  iconStyle: {
    fontSize: 30,
    textAlign: "center"
  },
  selectedIconStyle: {
    color: "red",
    fontSize: 30
  },
  title: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "18px",
    lineHeight: "21px",
    color: "#3C4A6B"
  },
  body: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "21px"
  },
  root: {
    width: "100%",
    height: "5rem",
    borderRadius: "11px",
    transform: "rotate(360deg)"
  },
  container: {
    marginTop: "0px"
  },
  paper: {
    position: "absolute",
    height: 250,
    maxWidth: 315,
    minWidth: 300,
    justifyContent: "center",
    backgroundColor: theme.palette.background.paper
  },
  modalDesc: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "24px",
    lineHeight: "28px",
    color: "#3C4A6B",
    textAlign: "center",
    padding: theme.spacing(2, 4, 3)
  },
  availabilityMsg: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "18px",
    textAlign: "center",
    lineHeight: "21px",
    color: "#87AC26",
    padding: "10px"
  },
  inputBox: {
    textAlign: "center",
    padding: "10px"
  }
}));
