import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Guide1 from "../../../assets/guide1.svg";
import Guide2 from "../../../assets/guide2.svg";
import Guide3 from "../../../assets/guide3.svg";
import Ellipse from "../../../assets/Ellipse3.svg";
import MobileStepper from "@material-ui/core/MobileStepper";
import { Button, Paper, Card, CardContent, CardMedia } from "@material-ui/core";

import { useHistory } from "react-router-dom";

export default function Guide() {
  const classes = useStyles();

  const history = useHistory();
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  return (
    <Grid
      container
      style={{
        height: "100vh"
      }}
    >
      <Grid item xs={2}>
        <div
          className={classes.bottomLeft}
          style={{ backgroundColor: "#a6eae9" }}
        />
      </Grid>

      <Grid
        item
        style={{
          height: "100%",
          position: "relative",
          bottom: 30,
          marginTop: "2rem"
        }}
        container
        xs={8}
      >
        <Grid container style={{ width: "100%" }} justify="center">
          <Grid
            item
            xs={4}
            md={4}
            sm={4}
            lg={4}
            style={{ flexGrow: 1 }}
            className={
              activeStep === 0
                ? classes.firstCardActiveFirst
                : activeStep === 1
                  ? classes.secondCardActiveFirst
                  : classes.thirdCardActiveFirst
            }
          >
            <Paper
              style={{
                borderRadius: "0px",
                height: "100%",
                boxShadow: "0px 15px 30px rgba(0, 0, 0, 0.1)"
              }}
            >
              <Card
                style={{ height: "100%" }}
                classes={{ root: classes.cardRoot }}
                elevation={0}
              >
                <CardMedia
                  component="img"
                  src={Guide1}
                  className={
                    activeStep === 0
                      ? classes.activeImage
                      : activeStep === 1
                        ? classes.inactiveImageSecond
                        : classes.inactiveImageThird
                  }
                />
                <CardContent
                  style={{
                    textAlign: "center",
                    position: "absolute",
                    bottom: "0px"
                  }}
                >
                  <Typography
                    className={activeStep === 0 && classes.cardTitle}
                    variant="h5"
                    component="h2"
                  >
                    Self-Practice at Home
                  </Typography>
                  <Typography
                    className={activeStep === 0 && classes.cardBody}
                    component="p"
                  >
                    Attending instructor-led live & pre-recorded classes outside
                    of your home is an excellent way to teach yourself with self
                    empowerment practices
                  </Typography>
                </CardContent>
              </Card>
            </Paper>
          </Grid>
          <Grid
            xs={4}
            md={4}
            sm={4}
            lg={4}
            style={{ flexGrow: 1 }}
            className={
              activeStep === 0
                ? classes.firstCardActiveSecond
                : activeStep === 1
                  ? classes.secondCardActiveSecond
                  : classes.thirdCardActiveSecond
            }
          >
            <Paper
              style={{
                borderRadius: "0px",
                height: "100%",
                boxShadow: "0px 15px 30px rgba(0, 0, 0, 0.1)"
              }}
            >
              <Card elevation={0}>
                <CardMedia
                  component="img"
                  src={Guide2}
                  className={
                    activeStep === 1
                      ? classes.activeImage
                      : classes.inactiveImageSecond
                  }
                  style={{ marginTop: "1rem" }}
                />
                <CardContent
                  style={{
                    textAlign: "center",
                    position: "absolute",
                    bottom: "0px"
                  }}
                >
                  <Typography
                    className={activeStep === 1 && classes.cardTitle}
                    variant="h5"
                    component="h2"
                  >
                    Group Challenges
                  </Typography>
                  <Typography
                    className={activeStep === 1 && classes.cardBody}
                    component="p"
                  >
                    Group Challenges help empower you to learn how to help
                    yourself in a friendly, encouraging and sharing community
                  </Typography>
                </CardContent>
              </Card>
            </Paper>
          </Grid>
          <Grid
            xs={4}
            md={4}
            sm={4}
            lg={4}
            style={{ flexGrow: 1 }}
            className={
              activeStep === 0
                ? classes.firstCardActiveThird
                : activeStep === 1
                  ? classes.secondCardActiveThird
                  : classes.thirdCardActiveThird
            }
          >
            <Paper
              style={{
                borderRadius: "0px",
                height: "100%",
                boxShadow: "0px 15px 30px rgba(0, 0, 0, 0.1)"
              }}
            >
              <Card elevation={0}>
                <CardMedia
                  component="img"
                  src={Guide3}
                  className={
                    activeStep === 0
                      ? classes.inactiveImageThird
                      : activeStep === 1
                        ? classes.inactiveImageSecond
                        : classes.activeImage
                  }
                  style={{ marginTop: "1rem" }}
                />
                <CardContent
                  style={{
                    textAlign: "center",
                    position: "absolute",
                    bottom: "0px"
                  }}
                >
                  <Typography
                    className={activeStep === 2 && classes.cardTitle}
                    variant="h5"
                    component="h2"
                  >
                    Top Courses
                  </Typography>
                  <Typography
                    className={activeStep === 2 && classes.cardBody}
                    component="p"
                  >
                    Multiple categories and amazing courses on breathing,
                    meditation, yoga and tantra
                  </Typography>
                </CardContent>
              </Card>
            </Paper>
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          style={{ marginTop: "2rem" }}
          container
          justify="center"
        >
          <MobileStepper
            variant="dots"
            steps={3}
            position="relative"
            activeStep={activeStep}
            style={{ background: "transparent" }}
          />
        </Grid>

        <Grid item xs={12} container justify="center">
          <Grid item xs={12} sm={5} lg={5} container justify="space-between">
            <Button
              className={classes.bottomButton}
              onClick={() =>
                activeStep === 0 ? history.push("/edit-profile-info") : handleBack()
              }
            >
              {activeStep === 0 ? "Skip" : "Previous"}
            </Button>
            <Button
              className={classes.bottomButton}
              onClick={() =>
                activeStep >= 2 ? history.push("/edit-profile-info") : handleNext()
              }
            >
              Next
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <Grid item container xs={2} justify="flex-end">
        <Grid item xs={12} style={{ flexBasis: 0 }}>
          <img src={Ellipse} className={classes.topRight} alt="" />
        </Grid>
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles(theme => ({
  bottomLeft: {
    width: "50px",
    height: "50px",
    borderRadius: "100%",
    position: "fixed",
    bottom: 30,
    marginLeft: "50px"
  },

  cardRoot: {
    overflow: "unset",
    height: "100%"
  },
  firstCardActiveFirst: {
    position: "relative",
    left: "33%",
    zIndex: 2,
    height: "450px"
  },

  firstCardActiveSecond: {
    position: "relative",
    left: "2%",
    top: "4%",
    zIndex: 0,
    height: "405px"
  },
  secondCardActiveSecond: {
    position: "relative",
    left: "0%",

    zIndex: 2,
    height: "450px"
  },
  secondCardActiveFirst: {
    position: "relative",
    left: "31%",
    top: "4%",
    zIndex: 0,
    height: "405px"
  },

  secondCardActiveThird: {
    position: "relative",
    left: "-31%",
    top: "4%",
    zIndex: 0,
    height: "405px"
  },
  thirdCardActiveSecond: {
    position: "relative",
    left: "-2%",
    top: "4%",
    zIndex: 0,
    height: "405px"
  },
  thirdCardActiveFirst: {
    position: "relative",
    left: "29%",
    top: "8%",
    zIndex: -1,
    height: "369px"
  },
  thirdCardActiveThird: {
    position: "relative",
    left: "-33%",
    zIndex: 2,
    height: "450px"
  },
  firstCardActiveThird: {
    position: "relative",
    left: "-29%",
    top: "8%",
    zIndex: -1,
    height: "369px"
  },
  inactiveImageSecond: {
    height: "187px",
    objectFit: "fill",
    paddingTop: theme.spacing(1)
  },
  inactiveImageThird: {
    height: "150px",
    objectFit: "fill",
    paddingTop: theme.spacing(1)
  },
  activeImage: {
    height: "220px",
    objectFit: "fill",
    paddingTop: theme.spacing(1)
  },
  fbBtn: {
    width: "164px",
    height: "69px",
    background: "#0656B4",
    borderRadius: "59px",
    "&:hover": {
      background: "#0656B4"
    }
  },
  topRight: {
    width: "150px",
    height: "150px"
  },
  cardTitle: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "24px",
    lineHeight: "37px",
    color: "#151348"
  },
  bottomButton: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "22px",
    lineHeight: "151.69%",

    textAlign: "center",
    letterSpacing: "0.045em",

    color: "#151348"
  },
  cardBody: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "15px",
    lineHeight: "33px",

    textAlign: "center",

    color: "#151348"
  }
}));

