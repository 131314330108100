import React, { useEffect, useState } from 'react';
import { Tab, Tabs, Box, Grid, Button, useMediaQuery, useTheme } from '@material-ui/core'
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";
import AssignmentDetail from './Detail'
import Submits from './Submits';
import SeeSubmission from './SeeSubmission';
import { useSelector } from 'react-redux'

const StyledTabs = withStyles({
    indicator: {
        display: "flex",
        justifyContent: "center",
        backgroundColor: "#09A9BB",
        "& > span": {
            maxWidth: 30,
            width: "100%",
            backgroundColor: "#09A9BB"
        }
    }
})(props => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

const StyledTab = withStyles(theme => ({
    root: {
        minWidth: "60px !important",
        textTransform: "none",
        color: "#080808e3",
        fontWeight: theme.typography.fontWeightRegular,
        fontSize: theme.typography.pxToRem(15),
        marginRight: theme.spacing(1),

        fontFamily: theme.fontFamily.Roboto,
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "16px",
        lineHeight: "14px",
        color: " #FFF",
        "&:focus": {
            opacity: 1
        }
    },
    selected: {
        color: "#09A9BB"
    }
}))(props => <Tab disableRipple {...props} />);
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={index === 0 ? 0 : 3} pt={3}>
                    <>{children}</>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
};

function ContentSection(props) {
    const classes = useStyles();
    const theme = useTheme();
    const intl = useIntl();
    const auth = useSelector(state => state?.auth);
    const user = useSelector(state => state?.auth?.user);
    const course = useSelector(state => state?.retreatCourse?.trainingCourseDetails);
    const [value, setValue] = useState(0);
    const [student, setStudent] = useState(true);
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));


    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    useEffect(() => {
        if (course?.id && user?.id) {
            if (user?.id === course?.user?.id) {
                setStudent(false);
            } else if (course?.mentors?.length > 0 && course?.mentors?.findIndex(item => item?.id === user?.id) > -1) {
                setStudent(false);
            } else {
                setStudent(true);

            }
        }
    }, [course, user])

    return (
        <Grid container>
            {
                isMobile ? <Grid item xs={12} container>
                    <Grid item xs={6}>
                        <Button onClick={() => setValue(0)} className={value === 0 ? classes.btnSelected : classes.btn}>{intl.formatMessage({ id: "detail" })}</Button>
                    </Grid>
                    {student && <Grid item xs={6}>
                        <Button onClick={() => setValue(1)} className={value === 1 ? classes.btnSelected : classes.btn}>{intl.formatMessage({ id: "submit" })}</Button>
                    </Grid>}
                    {!student && <Grid item xs={6}>
                        <Button onClick={() => setValue(1)} className={value === 1 ? classes.btnSelected : classes.btn}>{intl.formatMessage({ id: "seeSubmission" })}</Button>
                    </Grid>}

                    

                    {value === 0 && <Grid item xs={12} className={classes.marginTop}>
                        <AssignmentDetail />
                    </Grid>}
                    {student ?
                        value === 1 && <Grid item xs={12} className={classes.marginTop}>
                            <Submits />
                        </Grid> :
                        value === 1 && <Grid item xs={12} className={classes.marginTop}>
                            <SeeSubmission />
                        </Grid>
                    }

                </Grid> : <>
                    <Grid item xs={12}>
                        <StyledTabs
                            value={value}
                            onChange={handleChange}
                            aria-label="styled tabs example"
                            style={{ borderBottom: '1px solid #e8e8e8' }}
                        >
                            <StyledTab
                                label={intl.formatMessage({ id: "detail" })}
                            />
                            {student ? <StyledTab
                                label={intl.formatMessage({ id: "submit" })}
                            /> :
                                <StyledTab
                                    label={intl.formatMessage({ id: "seeSubmission" })}
                                />}
                        </StyledTabs>
                    </Grid>

                    <Grid item xs={12}>
                        <TabPanel value={value} index={0}>
                            <AssignmentDetail />
                        </TabPanel>

                        {student ? <TabPanel value={value} index={1}>
                            <Submits />
                        </TabPanel> :

                            <TabPanel value={value} index={1}>
                                <SeeSubmission />
                            </TabPanel>}


                    </Grid>

                </>
            }



        </Grid>
    );
}

export default ContentSection;

const useStyles = makeStyles(theme => ({
    container: {
        height: '100vh',
        background: '#FBFBFB',
        padding: '30px 15px 50px 15px'
    },

    card: {
        width: '100%',
        background: '#FFFFFF',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.05)',
        borderRadius: '10px',
    },
    btnSelected: {
        background: '#01BDD2',
        color: '#fff',
        borderRadius: 'none',
        height: '50px',
        width: '100%',
        '&:hover': {
            background: '#01BDD2',
            color: '#fff',

        }
    },
    btn: {
        background: '#fff',
        color: '#383838',
        border: '1px solid #707070',
        borderRadius: 'none',
        height: '50px',
        width: '100%',
        '&:hover': {
            border: '1px solid #707070',
            background: '#fff',
            color: '#383838',

        }
    },
    marginTop: {
        marginTop: '24px'
    }
}));