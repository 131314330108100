import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  Modal,
  Button,
  useMediaQuery,
  CircularProgress,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  Dialog,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import CardWithThumbnail from "../../../components/More/settings/CardWithThumbnailItem";
import TextField from "@material-ui/core/TextField";
import SocialNetworks from "./SocialNetworks";
// import PrivacyPolicy from "./PrivacyPolicy";
import Suggestions from "../../Web/More/Setting/Suggestion";
import Languages from "./Languages";
import CustomerSupport from "./CustomerSupport";
import ContactUs from "./ContactUs";
import ChangePassword from "./ChangePassword";

import Username from "../../../assets/Icons/username.svg";
import Install from "../../../assets/Icons/install.svg";
import Language from "../../../assets/Icons/language.svg";
import Privacy from "../../../assets/Icons/privacy.svg";
import Notification from "../../../assets/Icons/notification.svg";
import Suggestion from "../../../assets/Icons/suggestion.svg";
import Support from "../../../assets/Icons/support.svg";
import SocialNetwork from "../../../assets/Icons/socialNetwork.svg";
import RelateIcon from "../../../assets/Icons/RelateIcon.png";

import { useDispatch, useSelector } from "react-redux";
import {
  getAllLanguages,
  updateSetting,
  getSettings,
  addSuggestion,
  updateUsername,
} from "../../../actions/setting";
import { getCurrentLoggedInUser } from "../../../actions/user";
import { useTheme } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import VpnKey from "@material-ui/icons/VpnKey";
import ExitToApp from "@material-ui/icons/ExitToApp";
import MailOutline from "@material-ui/icons/MailOutline";
import Subject from "@material-ui/icons/Subject";
import ContactSupport from "@material-ui/icons/ContactSupport";
import AppBar from "../../../components/More/settings/AppBar";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import API from "../../../utils/API";

function Settings(props) {
  const classes = useStyles();
  const theme = useTheme();
  const intl = useIntl();
  const history = useHistory();

  const [modalStyle] = useState(getModalStyle);
  const [openModel, setOpenModel] = useState(false);
  const [option, setOption] = useState("");
  let dispatch = useDispatch();

  const [suggestion, setSuggestion] = useState("");

  const [currentAction, setCurrentAction] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [emptyTextField, setEmptyTextField] = useState(false);
  const [closeAccount, setCloseAccount] = useState(false);
  const [localLoader, setLocalLoader] = useState(false);

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  let authReducerState = useSelector((state) => state.auth);
  const settings = useSelector((state) => state?.setting?.settings);
  let updatedSetting = useSelector((state) => state.message);
  let User = useSelector((state) => state.user.user);
  const [username, setUsername] = useState(User?.username ? User.username : "");
  const isIos = navigator.userAgent.includes("Mac") && "ontouchend" in document;
  useEffect(() => {
    if (updatedSetting.show === true && updatedSetting.severity === "success") {
      setTimeout(setOpenModel(false), 2000);
      setShowLoader(false);
    }
  });
  useEffect(() => {
    setShowLoader(false);
  }, [updatedSetting.show]);

  const handleChange = (value) => {
    setSuggestion(value);
  };

  useEffect(() => {
    dispatch(getAllLanguages({ token: authReducerState.token }));
    dispatch(getSettings({ token: authReducerState.token }));
    dispatch(getCurrentLoggedInUser({ token: authReducerState.token }));
  }, []);

  const handleChangeStatus = (status, item) => {
    const settingsObj =
      item === intl.formatMessage({ id: "availability" })
        ? { available: status }
        : item === intl.formatMessage({ id: "emailNotifications" })
        ? { emailNotification: status }
        : { notification: status };
    dispatch(
      updateSetting({
        token: authReducerState.token,
        setting: { ...settingsObj },
      })
    );
    setCurrentAction(item);
    setShowLoader(true);
  };

  const handleOpenModal = () => {
    setOpenModel(true);
  };
  function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
      borderColor: theme.colors.white,
      borderRadius: "4px",
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
    };
  }

  function getImage(props) {
    return (
      <div style={{ height: "3.438em" }}>
        <img src={props} alt="" />
      </div>
    );
  }
  const handleCloseModal = () => {
    setOption(null);
    setOpenModel(false);
  };
  const updateUserName = () => {
    if (username !== "") {
      setShowLoader(true);
      setCurrentAction("Username");

      let userName = username.replace(/@/, "");
      dispatch(
        updateUsername({
          token: authReducerState.token,
          username: { username: userName },
        })
      );
    } else {
      setEmptyTextField(true);
    }
  };
  const usernameModelBody = (
    <div style={modalStyle} className={classes.paper}>
      <Typography className={classes.modalDesc}>
        {intl.formatMessage({ id: "username" })}
      </Typography>
      <div className={classes.inputBox}>
        <TextField
          value={username}
          onChange={(e) => {
            setUsername(e.target.value);
            setEmptyTextField(false);
          }}
          style={{ textAlign: "center" }}
          placeholder={intl.formatMessage({ id: "username" })}
        />
      </div>
      <Typography className={classes.availabilityMsg}>
        {emptyTextField ? intl.formatMessage({ id: "enterUsername" }) : ""}
        {/* Available <CheckCircleRoundedIcon/> */}
      </Typography>
      <Button className={classes.usernameModelBtn} onClick={updateUserName}>
        {showLoader ? (
          <CircularProgress style={{ color: "#FFF" }} size={20} />
        ) : (
          intl.formatMessage({ id: "set" })
        )}
      </Button>
    </div>
  );
  const handleClick = (title) => {
    setOption(title);
    if (title === intl.formatMessage({ id: "Privacy" })) {
      return window.open(
        `${process.env.REACT_APP_LANDING_URL}/privacy-policy-tantra-online`,
        "_blank"
      );
    }
    if (title === intl.formatMessage({ id: "termsConditions" })) {
      return window.open(
        `${process.env.REACT_APP_LANDING_URL}/terms-and-conditions-tantra-online`,
        "_blank"
      );
    }
    if (title === intl.formatMessage({ id: "closeMyAccount" })) {
      setCloseAccount(true);
    }
    if (
      title === intl.formatMessage({ id: "username" }) ||
      title === intl.formatMessage({ id: "rateOurApp" })
    ) {
      handleOpenModal();
    } else if (title === intl.formatMessage({ id: "makeASuggestion" })) {
      isMobile && !isDesktop && handleOpenModal();
    }
  };
  const backAction = () => {
    setOption(null);
  };
  const addSuggestions = () => {
    setShowLoader(true);
    dispatch(
      addSuggestion({
        token: authReducerState.token,
        suggestion: { suggestion: suggestion },
      })
    );
  };
  const addRating = () => {
    handleCloseModal();
    return isIos
      ? ""
      : window.open(
          "https://play.google.com/store/apps/details?id=com.omid.life",
          "_blank"
        );
  };

  const handleCloseAccount = async () => {
    setLocalLoader(true);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authReducerState?.token}`,
      },
    };
    try {
      let landing = null;
      landing = process.env.REACT_APP_LANDING_URL;
      const body = JSON.stringify({});
      const res = await API.patch(`/api/v1/user/closeAccount`, body, config);
      setLocalLoader(false);
      dispatch({
        type: "SHOW_MESSAGE",
        payload: {
          title: `Success`,
          message:
            res.data && res.data.message
              ? res.data.message
              : `Close Account request sent successfully`,
          severity: "success",
        },
      });
      handleClose();
      dispatch({ type: "LOG_OUT" });
      localStorage.setItem("userDataLocal", null);
      landing ? (window.location.href = landing) : history.push("/auth");
    } catch (error) {
      console.log(error);
      setLocalLoader(false);
      dispatch({
        type: "SHOW_MESSAGE",
        payload: {
          title: `Failed`,
          message:
            error.response &&
            error.response &&
            error.response.data &&
            error.response.data.message
              ? error.response.data.message
              : `Something went wrong while closing account`,
          severity: "error",
        },
      });
      handleClose();
    }
  };

  const handleClose = () => {
    setCloseAccount(false);
    return;
  };

  const suggestionModelBody = (
    <div style={modalStyle} className={classes.paperSugestionModel}>
      <div
        style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
      >
        <CloseIcon
          className={classes.closeIcon}
          onClick={() => handleCloseModal()}
        />
      </div>
      <Typography
        className={classes.modalDescSugestionModel}
        style={{ padding: "0px 32px" }}
      >
        {intl.formatMessage({ id: "makeASuggestion" })}
      </Typography>
      <Typography className={classes.availabilityMsgSugestionModel}>
        {intl.formatMessage({ id: "suggestionsBody" })}
      </Typography>
      <div className={classes.inputBoxSugestionModel}>
        <TextField
          style={{ width: "90%" }}
          value={suggestion}
          onChange={(e) => handleChange(e.target.value)}
          placeholder={intl.formatMessage({ id: "suggestion" })}
          multiline
          rows={4}
        />
      </div>

      <Button
        className={classes.modelBtnSugestionModel}
        onClick={addSuggestions}
      >
        {showLoader ? (
          <CircularProgress style={{ color: "#FFF" }} size={20} />
        ) : (
          "Submit"
        )}
      </Button>
    </div>
  );

  const rateUsModelBody = (
    <div style={modalStyle} className={classes.paperRateUsModel}>
      <div
        style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
      >
        <CloseIcon
          className={classes.closeIcon}
          onClick={() => handleCloseModal()}
        />
      </div>
      <div className={classes.rateusImage}>
        <img src={RelateIcon} alt="" height="80px" />
      </div>
      <Typography className={classes.modalDescSugestionModel}>
        {intl.formatMessage({ id: "rateUsModelHeading" })}
      </Typography>
      <Typography className={classes.availabilityMsgSugestionModel}>
        {intl.formatMessage({ id: "rateUsModelBody" })}
      </Typography>

      <Button className={classes.modelBtnRateUs} onClick={addRating}>
        {isIos ? "Coming soon" : intl.formatMessage({ id: "rate" })}
      </Button>
    </div>
  );

  const closeAccountModal = (
    <Dialog aria-labelledby="simple-dialog-title" open={closeAccount}>
      <DialogTitle id="simple-dialog-title" style={{ color: "#3C4A6B" }}>
        Close Account
      </DialogTitle>

      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {` Are you sure you want to deactivate your account?`}
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button
          onClick={handleClose}
          color="primary"
          disabled={localLoader ? true : false}
        >
          Cancle
        </Button>
        <Button
          onClick={handleCloseAccount}
          color="primary"
          autoFocus
          disabled={localLoader ? true : false}
        >
          {localLoader ? (
            <CircularProgress style={{ color: "#3F51B5" }} size={20} />
          ) : (
            "Confirm"
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
  const getUsername = () => {
    return User?.username === null || User?.username === undefined
      ? ""
      : `@${User?.username}`;
  };
  return (
    <Grid>
      {option === intl.formatMessage({ id: "SocialNetworks" }) ? (
        <SocialNetworks handleBack={backAction} />
      ) : option === intl.formatMessage({ id: "changePassword" }) ? (
        <ChangePassword handleBack={backAction} />
      ) : option === intl.formatMessage({ id: "contactUs" }) ? (
        <ContactUs handleBack={backAction} />
      ) : option === intl.formatMessage({ id: "makeASuggestion" }) &&
        isDesktop ? (
        <Suggestions handleBack={backAction} />
      ) : option === intl.formatMessage({ id: "customerSupport" }) ? (
        <CustomerSupport handleBack={backAction} />
      ) : (
        <div>
          {isMobile && !isDesktop ? (
            <AppBar
              body={"Settings"}
              onClick={() => history.push("/more")}
              backAction={"mainMenu"}
              backgroundColor="#14D9D9"
            />
          ) : (
            ""
          )}

          <Grid container className={classes.container}>
            {[
              {
                title: intl.formatMessage({ id: "username" }),
                iconColor: "#15AEBF",
                icon: getImage(Username),
                action: getUsername(),
                showSwitch: false,
              },
              {
                title: intl.formatMessage({ id: "notifications" }),
                iconColor: "#FE7271",
                icon: getImage(Notification),
                showSwitch: true,
                currentIcon: settings?.notification,
              },
              {
                title: intl.formatMessage({ id: "emailNotifications" }),
                iconColor: "#727272",
                icon: (
                  <MailOutline
                    style={{
                      color: "#FFF",
                      alignItems: "center",
                      alignSelf: "center",
                      display: "flex",
                      height: 30,
                      width: 30,
                    }}
                  />
                ),
                showSwitch: true,
                currentIcon: settings?.emailNotification,
              },
              {
                title: intl.formatMessage({ id: "SocialNetworks" }),
                iconColor: "#D7AFB4",
                icon: getImage(SocialNetwork),
                showSwitch: false,
              },

              {
                title: intl.formatMessage({ id: "Language" }),
                iconColor: "#FE7271",
                icon: getImage(Language),
                action: settings?.language?.name,
                showSwitch: false,
              },
              {
                title: intl.formatMessage({ id: "rateOurApp" }),
                iconColor: "#FBAE02",
                icon: getImage(Install),
                showSwitch: false,
              },
              {
                title: intl.formatMessage({ id: "makeASuggestion" }),
                iconColor: "#727272",
                icon: getImage(Suggestion),
                showSwitch: false,
              },
              {
                title: intl.formatMessage({ id: "contactUs" }),
                iconColor: "#D7AFB4",
                icon: (
                  <ContactSupport
                    style={{
                      color: "#FFF",
                      alignItems: "center",
                      alignSelf: "center",
                      display: "flex",
                      height: 30,
                      width: 30,
                    }}
                  />
                ),
                showSwitch: false,
              },

              {
                title: intl.formatMessage({ id: "Privacy" }),
                iconColor: "#87AC26",
                icon: getImage(Privacy),
                showSwitch: false,
              },
              {
                title: intl.formatMessage({ id: "termsConditions" }),
                iconColor: "#09A9BB",
                icon: (
                  <Subject
                    style={{
                      color: "#FFF",
                      alignItems: "center",
                      alignSelf: "center",
                      display: "flex",
                      height: 30,
                      width: 30,
                    }}
                  />
                ),
                showSwitch: false,
              },
              {
                title: intl.formatMessage({ id: "changePassword" }),
                iconColor: "#D7AFB4",
                icon: (
                  <VpnKey
                    style={{
                      color: "#FFF",
                      alignItems: "center",
                      alignSelf: "center",
                      display: "flex",
                      height: 30,
                      width: 30,
                    }}
                  />
                ),
                showSwitch: false,
              },
              {
                title: intl.formatMessage({ id: "closeMyAccount" }),
                iconColor: "#FE7271",
                icon: (
                  <ExitToApp
                    style={{
                      color: "#FFF",
                      alignItems: "center",
                      alignSelf: "center",
                      display: "flex",
                      height: 30,
                      width: 30,
                    }}
                  />
                ),
                showSwitch: false,
              },
            ].map((item, index) => {
              if (item.title === intl.formatMessage({ id: "installOurApp" })) {
                return null;
              } else {
                return (
                  <Grid item xs={12}>
                    <CardWithThumbnail
                      rootCard={classes.root}
                      actionBtn={item && item.action && item.action}
                      title={item.title}
                      IconContainerBackground={item.iconColor}
                      Icon={item.icon}
                      handleClick={handleClick}
                      iconStyle={classes.iconStyle}
                      currentIcon={item.currentIcon}
                      handleChange={handleChangeStatus}
                      titleStyle={classes.title}
                      showIconButton={item.showSwitch}
                      showImage={true}
                      actionBtnStyle={classes.actionBtnStyle}
                      currentAction={currentAction}
                      showLoader={showLoader}
                    />
                  </Grid>
                );
              }
            })}
            <Grid item xs={12} style={{ height: "6rem" }} />
          </Grid>
        </div>
      )}
      <Grid container>
        <Modal
          disableEnforceFocus={true}
          open={openModel}
          onClose={handleCloseModal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          {option === intl.formatMessage({ id: "username" })
            ? usernameModelBody
            : option === intl.formatMessage({ id: "makeASuggestion" })
            ? suggestionModelBody
            : rateUsModelBody}
        </Modal>
      </Grid>
      <Grid container>{closeAccountModal}</Grid>
    </Grid>
  );
}

export default Settings;

const useStyles = makeStyles((theme) => ({
  iconStyle: {
    fontSize: 30,
    textAlign: "center",
  },
  selectedIconStyle: {
    color: "red",
    fontSize: 30,
  },
  title: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "21px",
    color: "#000000",
  },
  body: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "21px",
  },
  root: {
    width: "100%",
    height: "5rem",
    borderRadius: "11px",
    transform: "rotate(360deg)",
  },
  container: {
    marginTop: "20px",
  },
  paper: {
    position: "absolute",
    height: 274,
    maxWidth: 300,
    minWidth: 260,
    justifyContent: "center",
    backgroundColor: theme.palette.background.paper,
    outline: "none",
  },
  usernameModelBtn: {
    background: `linear-gradient(#38546D 1%,#38546D 100%)`,
    height: "73px",
    textTransform: "none",
    width: "100%",
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "21px",
    marginTop: "37px",
    color: theme.colors.white,
  },
  modalDesc: {
    marginTop: "15px",
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "24px",
    lineHeight: "28px",
    color: "#3C4A6B",
    textAlign: "center",
    padding: theme.spacing(2, 4, 3),
  },
  availabilityMsg: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "18px",
    textAlign: "center",
    lineHeight: "21px",
    color: "#87AC26",
    padding: "10px",
  },
  inputBox: {
    "& .MuiInputBase-input": {
      textAlign: "center",
    },
    textAlign: "center",
    padding: "10px",
    "& .MuiInput-root": {
      fontFamily: theme.fontFamily.Roboto,
      fontStyle: "normal",
      fontWeight: 300,
      fontSize: "24px",
      letterSpacing: "-0.055em",
      lineHeight: "28px",
      textAlign: "center",
      color: "#282D48",
    },
    "& .MuiInput-underline:before": {
      borderBottom: "1px solid rgba(168, 182, 200, 0.3)",
      borderTop: "none",
    },
    "& .MuiInput-underline::after": {
      borderBottom: "1px solid rgba(168, 182, 200, 0.3)",
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled)::before": {
      borderBottom: "1px solid rgba(168, 182, 200, 0.3)",
    },
    "& .MuiInputLabel-root": {
      fontFamily: theme.fontFamily.Roboto,
      fontStyle: "normal",
      fontWeight: 300,
      fontSize: "24px",
      letterSpacing: "-0.055em",
      lineHeight: "28px",
      textAlign: "center",
      color: "#282D48",
      "&::after": {
        fontFamily: theme.fontFamily.Roboto,
        fontStyle: "normal",
        fontWeight: 300,
        textAlign: "center",
        fontSize: "14px",
        lineHeight: "28px",
        letterSpacing: "-0.055em",
        color: "#282D48",
      },
    },
  },
  header: {
    background: "#14D9D9",
    borderRadius: "0 0 34px 34px",
    height: "130px",
    width: "100%",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
  },
  actionBtnStyle: {
    minWidth: "57px",
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "18px",
    lineheight: "21px",
    color: "#C2C2C2",
  },
  headerTitle: {
    fontFamily: theme.fontFamily.Reef,
    color: "#FFFFFF",
    fontStyle: "normal",
    fontWeight: 900,
    fontSize: "26px",
    lineHeight: "35px",
    textAlign: "center",
  },
  paperSugestionModel: {
    position: "absolute",
    height: 358,
    maxWidth: 315,
    minWidth: 300,
    justifyContent: "center",
    backgroundColor: theme.palette.background.paper,
    outline: "none",
  },
  closeIcon: {
    color: "#C7C7C7",
    fontSize: "25px",
    padding: "10px",
    cursor: "pointer",
  },
  modelBtnSugestionModel: {
    background: `linear-gradient(#727272 1%,#727272 100%)`,
    height: "73px",
    width: "100%",
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "18px",
    lineHeight: "21px",
    marginTop: "5px",
    outlined: "none",
    color: theme.palette.background.paper,
  },
  modalDescSugestionModel: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "24px",
    lineHeight: "28px",
    color: "#3C4A6B",
    textAlign: "center",
    padding: theme.spacing(2, 4, 2),
  },
  availabilityMsgSugestionModel: {
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "18px",
    textAlign: "center",
    lineHeight: "21px",
    padding: "10px",
  },
  inputBoxSugestionModel: {
    textAlign: "center",
    margin: "10px",

    background: "#FBFAFA",
    border: "1.5px solid #BABABA",
    boxSizing: "border-box",
    borderRadius: "11px",
    "& .MuiInput-root": {
      fontFamily: theme.fontFamily.Roboto,
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "18px",
      lineHeight: "26px",
      color: "##3C4A6B",
    },
    "& .MuiInputBase-multiline": {
      padding: `10px 10px`,
      fontFamily: theme.fontFamily.Roboto,
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "18px",
      lineHeight: "26px",
      color: "##3C4A6B",
    },
    "& .MuiInput-underline:before": {
      borderBottom: "0px solid #FAAC02",
      borderTop: "none",
    },
    "& .MuiInput-underline::after": {
      borderBottom: "0px solid #FAAC02",
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled)::before": {
      borderBottom: "0px solid #FAAC02",
    },
  },
  paperRateUsModel: {
    position: "absolute",
    height: 300,
    maxWidth: 315,
    minWidth: 300,
    justifyContent: "center",
    backgroundColor: theme.palette.background.paper,
    outline: "none",
  },
  modelBtnRateUs: {
    background: `linear-gradient(#FBAE02 1%,#FBAE02 100%)`,
    height: "73px",
    width: "100%",
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "18px",
    lineHeight: "21px",
    marginTop: "8px",
    color: theme.palette.background.paper,
  },
  rateusImage: {
    width: "100%",
    justifyContent: "center",
    display: "flex",
  },
}));
