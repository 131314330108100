import React,{useEffect} from "react";
import { useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import AllChallengesMobileContainer from "../../../containers/Mobile/More/AllChallenges";
import MoreWebContainer from "../../../containers/Web/More";
import {getAllChallenges} from "../../../actions/challenges";
import { useDispatch, useSelector } from "react-redux";
const Session = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  const authReducer = useSelector(state=> state?.auth);
useEffect(()=>{
  dispatch(getAllChallenges(authReducer?.token));
})
  return (
    <>
      {isDesktop && <MoreWebContainer />}
      {isMobile && !isDesktop && <AllChallengesMobileContainer />}
    </>
  );
};

export default Session;
