import React from "react";
import { Card, Grid, Typography, Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Truncate from "react-truncate";


function NotificationCard(props) {
  const classes = useStyles();

  return (
    <Card
      className={classes.root}
      style={{ backgroundColor: props?.background }}
    >
      {/* <CardContent> */}
      <Grid container style={{ margin: " 0 15px" }}>
        <Grid item xs={3} sm={4} lg={3} md={3}>
          <div
            className={classes.menuIconStyle}
            style={{
              background: props?.selected
                ? "#3abac9"
                : props?.IconContainerBackground,
            }}
          >
            {props?.Icon}
          </div>
        </Grid>

        <Grid item container sm={8} lg={9} md={9} xs={9}>
          <Grid item xs={12}>
            <Tooltip title={props.description}>
              <Typography className={classes.description} style={{ color: props?.read === true && '#525252' }}>
                <Truncate lines={2} ellipsis={<span>...</span>} >
                  {props.description}
                </Truncate>
              </Typography>
            </Tooltip>

            {/* {props.description} */}
            {props?.timeAgo && (
              <Typography className={classes.subTitle}>
                {" "}
                {props?.timeAgo}
              </Typography>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
}

export default NotificationCard;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "88px",
    width: "100%",
    boxShadow: "none",
    backgroundColor: "#FFF",
    cursor: "pointer",
    alignContent: "center",
    alignItems: "center",
    borderBottom: "1px solid rgba(168, 182, 200, 0.3)",
  },
  menuIconStyle: {
    borderRadius: "11px",
    height: "66px",
    width: "66px",
    justifyContent: "center",
    lineHeight: "78px",
    alignItems: "center",
    display: "flex",
  },
  description: {
    fontFamily: theme.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: '900',
    fontSize: "16px",
    lineHeight: "22px",
    color: "rgba(0, 0, 0,0.9)",
  },
  subTitle: {
    fontFamily: theme.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: 900,
    fontSize: "12px",
    lineHeight: "16px",
    color: "#8B8B8B",
    opacity: 0.5,
    textAlign: "left",
    marginTop: '5px'
  },
}));
