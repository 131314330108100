import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Grid,
  Avatar,
  Typography,
  useMediaQuery,
  Button,
} from "@material-ui/core";
import { makeStyles, useTheme, withStyles } from "@material-ui/styles";

import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import InviteFriends from "../../Common/InviteFriends/Index";

import { useHistory } from "react-router-dom";

function Invite(props) {
  const classes = useStyles();
  const classes1 = useStyles1();
  let history = useHistory();


  const [invited, setInvited] = useState(
    props.sessionData && props.sessionData.userIds
      ? props.sessionData.userIds
      : []
  );

  const intl = useIntl();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const searchedFriends = useSelector(
    (state) => state?.userRelations?.searchUsers
  );
  const [sessionUsers] = useState(
    props?.sessionData?.sessionUsers ? props?.sessionData?.sessionUsers : []
  );
  const handleInvite = (id) => {
    if (sessionUsers.indexOf(id) === -1) {
      let found = invited.indexOf(id);
      if (found === -1) {
        setInvited([...invited, id]);
      } else {
        let invite = [...invited];
        invite.splice(found, 1);
        setInvited([...invite]);
      }
    }
  };
  const chcekInvited = (id) => {
    if (invited.indexOf(id) === -1 && sessionUsers.indexOf(id) === -1) {
      return false;
    } else {
      return true;
    }
  };

  const handleCancle = () => {
    history.push("/more");
  };
  const handleGoNext = () => {
    props.handleNext({
      userIds: [...invited],
    });
  };

  return (
    <Grid container style={{ background: "#FBFAF9",minHeight:'100vh' }}>
      <Grid item lg={8} xs={12}>
        <Grid container style={{ padding: "0px" }}>
          <Grid item container lg={12} xs={12}>
            <Grid
              item
              xs={12}
              style={{ padding: invited.length > 0 ? "10px" : "20px" }}
            >
              <Grid
                container
                style={{
                  paddingBottom: "20px",
                  paddingTop: "30px",
                  borderBottom: "1px solid rgba(168, 182, 200, 0.3)",
                }}
              >
                <Typography className={classes1.addFriend}>
                  Invite Peoples
                </Typography>
              </Grid>
              <InviteFriends
                handleInvite={handleInvite}
                chcekInvited={chcekInvited}
                invited={invited}
              />
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              marginTop: "45px",
              paddingLeft: isMobile ? "0" : "2rem",
              padding: isMobile ? "10px" : "0px",
              paddingTop: "0px",
            }}
          >
            <Button
              style={{
                width: isMobile ? "100%" : "360px",
                height: "62px",
                background:
                  "linear-gradient(180deg, #FFC000 -13.24%, #F28705 100%)",
                borderRadius: "11px",
                fontFamily: theme.fontFamily.Roboto,
                fontStyle: "normal",
                fontWeight: 500,
                fontSize: "20px",
                lineHeight: "23px",
                textTransform: "none",
                color: "#FFFFFF",
              }}
              onClick={() => handleGoNext()}
            >
              {props.screen === "live"
                ? intl.formatMessage({ id: "goLive" })
                : intl.formatMessage({ id: "finish" })}
            </Button>
          </Grid>
        </Grid>

        {isMobile && (
          <Grid
            style={{
              paddingLeft: "10px",
              paddingRight: "10px",
            }}
            xs={12}
            sm={12}
            md={12}
            lg={10}
          >
            <Button
              onClick={() => handleCancle()}
              style={{
                width: "100%",
                height: "2.750em",
                borderRadius: "11px",
                fontFamily: theme.fontFamily.Roboto,
                fontStyle: "normal",
                fontWeight: 500,
                textTransform: "none",
                fontSize: "20px",
                lineHeight: "23px",
                color: "#D95959",
                marginBottom: "10px",
              }}
            >
              {intl.formatMessage({ id: "cancel" })}
            </Button>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}

export default Invite;

const useStyles = makeStyles((theme) => ({
  addedFriendCard: {
    background: "#F2F2F2",
    boxShadow: "inset 2px 2px 4px rgba(0, 65, 72, 0.05)",
    borderRadius: "12px",
    paddingTop: "10px",
    marginTop: "10px",
    borderTopLeftRadius: "0px",
    borderBottomLeftRadius: "0px",
  },
  noContent: {
    height: "35vh",
    color: "#7d7b79",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "19px",
    padding: "12px 10px 10px 17px",
    textAlign: "center",
  },
  containerStyle: {
    marginBottom: "5px",
  },
  CancelBtnPosition: {
    width: "100vw",
    position: "fixed",
    bottom: "0px",
    boxShadow: "0px -12px 12px rgba(0, 0, 0, 0.03)",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    zIndex: 100,
    background: "0px",
  },
  NextBtnPosition: {
    width: "100vw",
    position: "fixed",
    bottom: "60px",
    boxShadow: "0px -12px 12px rgba(0, 0, 0, 0.03)",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    zIndex: 101,
  },
  subTitle: {
    fontFamily: "Roboto",
    fontStyle: "italic",
    fontWeight: "bold",
    fontSize: "8px",
    lineHeight: "9px",
    color: "#3C4A6B",
    textAlign: "center",
  },
  appBar: {
    backgroundColor: "#FBFAF9",
    width: "100%",
    boxShadow: "none",
    display: "flex",
    justifyContent: "space-between",
  },
  headerGrid: {
    background: "#FBF1E0",
    height: "2.75em",
    fontSize: "20px",
    boxShadow: `inset 2px 2px 4px rgba(0, 65, 72, 0.05)`,
    borderRadius: `12px`,
  },
  selectedBtn: {
    background: "linear-gradient(180deg, #FFC000 -13.24%, #F28705 100%)",
    width: "100%",
    height: "2.750em",
    borderRadius: "11px",
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: 500,
    textTransform: "none",
    fontSize: "20px",
    lineHeight: "23px",
    color: "#fff",
    marginBottom: "70px",
  },
  unselectedBtn: {
    width: "100%",
    height: "2.750em",
    borderRadius: "11px",
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: 500,
    textTransform: "none",
    fontSize: "20px",
    lineHeight: "23px",
    color: "#F8A303",
    marginBottom: "70px",
  },
  tabLabel: {
    color: "#DFDFDF",
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "19px",
    minWidth: "27%",
    textTransform: "none",
  },
  tabSelected: {
    color: "#F28705",
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "19px",
  },
  indicatorStyling: {
    backgroundColor: "#F28705",
    height: "5px",
    maxWidth: "60px",
  },
  indicatorStylingMobile: {
    backgroundColor: "#F28705",
    height: "5px",
  },
  tabsContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
}));
const useStyles1 = makeStyles((theme) => ({
  addFriend: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "20px",
    lineHeight: "23px",
    color: "#3C4A6B",
    paddingLeft: "30px",
    marginBotton: "20px",
  },
  title: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "italic",
    fontWeight: "bold",
    fontSize: "18px",
    lineHeight: "21px",
    color: "#3C4A6B",
    width: "10ch",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  body: {
    width: "10ch",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "21px",
    color: "#3C4A6B",
  },
  root: {
    width: "100%",
    height: "5rem",
    transform: "rotate(360deg)",
    borderBottom: "1px solid rgba(168, 182, 200, 0.3)",
  },
  container: {
    marginTop: "20px",
  },

  actionBtnStyle: {
    minWidth: "57px",
  },
  headerTitle: {
    fontFamily: theme.fontFamily.Reef,
    color: "#FFFFFF",
    fontStyle: "normal",
    fontWeight: 900,
    fontSize: "26px",
    lineHeight: "35px",
    textAlign: "center",
  },

  closeIcon: {
    color: "#C7C7C7",
    fontSize: "25px",
    padding: "10px",
    cursor: "pointer",
  },
  modelBtnSugestionModel: {
    background: `linear-gradient(#727272 1%,#727272 100%)`,
    height: "73px",
    width: "100%",
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "18px",
    lineHeight: "21px",
    marginTop: "8px",
    color: theme.palette.background.paper,
  },
  modalDescSugestionModel: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "24px",
    lineHeight: "28px",
    color: "#3C4A6B",
    textAlign: "center",
    padding: theme.spacing(2, 4, 2),
  },
  availabilityMsgSugestionModel: {
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "18px",
    textAlign: "center",
    lineHeight: "21px",
    padding: "10px",
  },
  inputBoxSugestionModel: {
    textAlign: "center",
    paddingTop: "20px",
    paddingBottom: "20px",
  },
  paperRateUsModel: {
    position: "absolute",
    height: 400,
    maxWidth: 315,
    minWidth: 300,
    justifyContent: "center",
    backgroundColor: theme.palette.background.paper,
  },
  modelBtnRateUs: {
    background: `linear-gradient(#FBAE02 1%,#FBAE02 100%)`,
    height: "73px",
    width: "100%",
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "18px",
    lineHeight: "21px",
    marginTop: "8px",
    color: theme.palette.background.paper,
  },
  rateusImage: {
    height: "13em",
    width: "100%",
    justifyContent: "center",
    display: "flex",
  },
}));
