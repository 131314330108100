import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Modal, Typography, Button, Grid, TextField, MenuItem, Select, FormControl, InputLabel, Input } from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import { useIntl } from "react-intl";

function ChallengePartsCard(props) {
  const classes = useStyles();
  const theme = useTheme();

  const [durationTypeName, setDurationTypeName] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [optionGroups, setOptionGroups] = useState();
  const [hours, setHours] = useState('0');
  const [minutes, setMinutes] = useState('0');
  const [seconds, setSeconds] = useState('10');
  const intl = useIntl();
  const [modalStyle] = useState(getModalStyle);

  const getTimeNumberList = (timeType) => {
    let timeNumber = []
    let lessT = timeType === 'h' ? 13 : 61;
    for (let i = 0; i < lessT; i++) {
      timeNumber.push({ value: i, label: i < 10 ? '0' + i : i })
    }
    return timeNumber
  }

  useEffect(() => {
    setHours(props?.stepValue?.hr ? props?.stepValue?.hr : '0')
    setMinutes(props?.stepValue?.min ? props?.stepValue?.min : '0')
    setSeconds(props?.stepValue?.sec ? props?.stepValue?.sec : '10')
  }, [props?.stepValue])


  const handleModal = (e, durationType) => {
    if (props?.disabled) {
      return null;
    }
    if (e && durationType) {
      setDurationTypeName(durationType);
    }
    setOpenModal(!openModal);
  };

  const setData = () => {
    handleModal();

    return props?.handleSetTime(durationTypeName, { hr: hours, min: minutes, sec: seconds });
  };

  const ModelBody = (
    <div style={modalStyle} className={classes.paper}>
      <div
        style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
      />
      {
        <Grid container justify="center">
          <Grid item xs={12} container>
            <Grid item xs={4}><Typography className={classes.heading}>Hour</Typography></Grid>
            <Grid item xs={4}><Typography className={classes.heading}>Minute</Typography></Grid>
            <Grid item xs={4}><Typography className={classes.heading}>Second</Typography></Grid>
          </Grid>
          <Grid item xs={12} container spacing={1} padding='0px 10px 0px 10px'>

            <Grid item xs={4}>
              <Select
                value={hours}
                onChange={(e) => setHours(e.target.value)}
                label='Hour'
                style={{ width: '100%' }}
              >
                <MenuItem aria-label="None" value="" />

                {
                  getTimeNumberList('h').map(op => <MenuItem value={op?.value.toString()}>{op?.label}</MenuItem>)
                }
              </Select>


            </Grid>
            <Grid item xs={4}>

              <Select
                labelId="demo-dialog-select-label"
                id="demo-dialog-select"
                style={{ width: '100%' }}
                label='Minute'
                value={minutes}
                onChange={(e) => setMinutes(e.target.value)}

              >
                <MenuItem aria-label="None" value="" />

                {
                  getTimeNumberList('m').map(op => <MenuItem value={op?.value.toString()}>{op?.label}</MenuItem>)
                }
              </Select>

            </Grid>
            <Grid item xs={4}>
              <Select
                labelId="demo-dialog-select-label"
                id="demo-dialog-select"
                style={{ width: '100%' }}
                label='Seconds'
                value={seconds}
                onChange={(e) => setSeconds(e.target.value)}
              >
                <MenuItem aria-label="None" value="" />

                {
                  getTimeNumberList('s').map(op => <MenuItem value={op?.value.toString()}>{op?.label}</MenuItem>)
                }
              </Select>
            </Grid>
          </Grid>
          <Grid item xs={12} style={{ paddingTop: '23px' }}>
            <Button className={classes.modelBtn} onClick={setData}>
              {intl.formatMessage({ id: "set" })}
            </Button>
          </Grid>
        </Grid>
      }
    </div >
  );

  function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
      borderColor: theme.colors.white,
      borderRadius: "4px",
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
    };
  }

  const formateDuration = (time) => {
    if (time === null) {
      return "Set Time";
    }
    if (time.hr === "00" && time.min === "00") {
      return (
        <span className={classes.subText} style={{ color: "#363636" }}>
          {" "}
          {`${props?.stepValue?.sec} sec`}
        </span>
      );
    }
    if (time.hr === "00") {
      return (
        <span className={classes.subText} style={{ color: "#363636" }}>
          {" "}
          {`${props?.stepValue?.min} min ${props?.stepValue?.sec} sec`}
        </span>
      );
    } else {
      return (
        <span className={classes.subText} style={{ color: "#363636" }}>
          {" "}
          {`${props?.stepValue?.hr} hr ${props?.stepValue?.min} min ${props?.stepValue?.sec
            } sec`}
        </span>
      );
    }
  };

  return (
    <div className={classes.root}>
      <Grid container onClick={(e) => handleModal(e, props?.step)}>
        <Grid item xs={12}>
          <Typography className={classes.text}>{props?.text}</Typography>
        </Grid>
        <Grid item xs={12}>
          {props?.step === "exerciseTime" ? (
            <Typography className={classes.subText}>
              {props?.stepValue !== null ? (
                <span style={{ color: "#363636" }}>
                  {" "}
                  {`${props?.stepValue?.hr} : ${props?.stepValue?.min}   ${props?.stepValue?.formate
                    }`}
                </span>
              ) : (
                "Set Time"
              )}
            </Typography>
          ) : (
            <Typography className={classes.subText}>
              {formateDuration(props?.stepValue)}
            </Typography>
          )}
        </Grid>
      </Grid>
      <div>
        <Modal
          disableEnforceFocus={true}
          open={openModal}
          onClose={handleModal}
        >
          {ModelBody}
        </Modal>
      </div>
    </div>
  );
}

export default ChallengePartsCard;

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100px",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "6px",
    boxShadow: " 0px 4px 4px rgba(0, 0, 0, 0.05)",
    cursor: "pointer",
    backgroundColor: "#FFF",
  },
  text: {
    display: "block",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "21px",
    color: "#858585",
    textAlign: "center",
  },
  subText: {
    color: "#a9a9a9",
    display: "block",
    textAlign: "center",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "16px",
  },
  paper: {
    position: "absolute",
    height: 230,
    maxWidth: 230,
    minWidth: 170,
    justifyContent: "center",
    backgroundColor: theme.palette.background.paper,
    outline: "none",
  },
  modalDesc: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "20px",
    lineHeight: "28px",
    color: "#3C4A6B",
    textAlign: "center",
    padding: theme.spacing(2, 4, 3),
  },
  modelBtn: {
    background: `linear-gradient( #F79E03 1%,#F79E03 100%)`,
    height: "60px",
    width: "100%",
    fontFamily: theme.fontFamily.Roboto,
    textAlign: "center",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "21px",
    marginTop: "41px",
    color: theme.colors.white,

  },
  selectCategory: {
    height: "54px",
    width: "100%",
    textAlign: "center",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 500,
    borderRadius: "5px",
    fontSize: "14px",
    lineHeight: "16px",
    color: "#ffffff",
    paddingRight: "10px",
    
  },
  heading: {
    height: "54px",
    width: "100%",
    textAlign: "center",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 500,
    borderRadius: "5px",
    fontSize: "16px",
    lineHeight: "16px",
    color: "#000",
    paddingTop: "20px",
  }
}));
