import API from "../utils/API";
import { getUserById } from './publicUser'

// types
import {
  GET_PENDING_REQUESTS,
  APPROVE_REQUESTS,
  SHOW_MESSAGE,
  REJECT_REQUESTS,
  SEARCH_FRIENDS,
  SEND_REQUEST,
  FOLLOW_USER,
  GET_ALL_INVITATIONS,
  APPROVE_INVITATION,
  REJECT_INVITATION,
  GET_USER_FRIENDS,
  SEARCH_USERS,
  GET_USER_FOLLOWERS,
  GET_USER_FOLLOWINGS,
  LOADER
} from "./types";

export const getPendingRequests = (token) => async (dispatch) => {
  dispatch({
    type: LOADER,
    payload: true,
  });
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  try {

    const res = await API.get(
      `/api/v1/userRelation/getPendingRequests`,
      config
    );
    dispatch({
      type: GET_PENDING_REQUESTS,
      payload: res.data.data,
    });
    dispatch({
      type: LOADER,
      payload: false,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: LOADER,
      payload: false,
    });
  }
};

export const getUsersFriends = token => async dispatch => {
  dispatch({
    type: LOADER,
    payload: true,
  });
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    }
  };

  try {

    const res = await API.get(`/api/v1/userRelation/getUserFriends`, config);
    dispatch({
      type: GET_USER_FRIENDS,
      payload: res.data.data
    });
    dispatch({
      type: LOADER,
      payload: false,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: LOADER,
      payload: false,
    });
  }
};

export const approveRequest = ({ requestId, approveStatus, token }) => async (
  dispatch
) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  const body = JSON.stringify({ requestId, approveStatus });

  try {

    const res = await API.patch(
      `/api/v1/userRelation/approveRequest`,
      body,
      config
    );
    if (approveStatus === "accepted") {
      dispatch({
        type: APPROVE_REQUESTS,
        payload: res.data.data,
      });
      dispatch({
        type: SHOW_MESSAGE,
        payload: {
          title: `success`,
          message: `you are now friends`,
          severity: "success",
        },
      });
      return dispatch(getUsersFriends(token))
    }
    if (approveStatus === "rejected") {
      dispatch({
        type: REJECT_REQUESTS,
        payload: res.data.data,
      });
    }
  } catch (error) {
    console.log(error);
    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        title: ` Failed`,
        message:
          error.response.data.errors && error.response.data.errors.length
            ? `something wrong`
            : error.response.data.message
              ? error.response.data.message
              : `something wrong`,
        severity: "error",
      },
    });
  }
};

export const searchFriends = ({ searchTerm, token }) => async (dispatch) => {
  dispatch({
    type: LOADER,
    payload: true,
  });
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    params: {
      searchTerm,
    },
  };

  try {

    const res = await API.get(`/api/v1/search/searchUser`, config);
    dispatch({
      type: SEARCH_FRIENDS,
      payload: res.data.data,
    });

    dispatch({
      type: LOADER,
      payload: false,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: LOADER,
      payload: false,
    });
  }
};

export const searchUsers = ({ searchTerm, token }) => async (dispatch) => {
  dispatch({
    type: LOADER,
    payload: true,
  });
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    params: {
      searchTerm,
    },
  };

  try {

    const res = await API.get(`/api/v1/search/searchUsersForSessionOrChallenge`, config);
    dispatch({
      type: SEARCH_USERS,
      payload: res.data.data,
    });
    dispatch({
      type: LOADER,
      payload: false,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: LOADER,
      payload: false,
    });
  }
};

export const followUser = ({ token, userId }) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  const body = JSON.stringify({ userId });
  try {

    const res = await API.post(`/api/v1/userRelation/followUser`, body, config);
    dispatch({
      type: FOLLOW_USER,
      payload: res.data.data,
    });
    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        title: `success`,
        message: res?.data?.message,
        severity: "success",
      },
    });
    return dispatch(getUserById({ token, userId }))
  } catch (error) {
    console.log(error);
    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        title: ` Failed`,
        message:
          error.response.data.errors && error.response.data.errors.length
            ? `something wrong`
            : error.response.data.message
              ? error.response.data.message
              : `something wrong`,
        severity: "error",
      },
    });
  }
};

export const unFollowUser = ({ token, userId }) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  const body = JSON.stringify({ userId });
  try {

    const res = await API.delete(`/api/v1/userRelation/unFollowUser`, body, config);

    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        title: `success`,
        message: res?.data?.message,
        severity: "success",
      },
    });
    return dispatch(getUserById({ token, userId }))
  } catch (error) {
    console.log(error);
    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        title: ` Failed`,
        message:
          error.response.data.errors && error.response.data.errors.length
            ? `something wrong`
            : error.response.data.message
              ? error.response.data.message
              : `something wrong`,
        severity: "error",
      },
    });
  }
};

export const sendFriendRequest = ({ token, requestReceiverId }) => async (
  dispatch
) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  const body = JSON.stringify({ requestReceiverId });

  try {

    const res = await API.post(
      `/api/v1/userRelation/sendFriendRequest`,
      body,
      config
    );

    dispatch({
      type: SEND_REQUEST,
      payload: res.data.data,
    });
    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        title: `success`,
        message: `friend request send successfully`,
        severity: "success",
      },
    });
    return dispatch(getUserById({ token, userId: requestReceiverId }))
  } catch (error) {
    console.log(error);
    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        title: ` Failed`,
        message:
          error.response.data.errors && error.response.data.errors.length
            ? `something wrong`
            : error.response.data.message
              ? error.response.data.message
              : `something wrong`,
        severity: "error",
      },
    });
  }
};

// invitations

export const getAllInvitations = (token, loader) => async (dispatch) => {
 loader && dispatch({
    type: LOADER,
    payload: true,
  });
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  try {

    const res = await API.get(`/api/v1/challenge/get-all-invitations`, config);
    dispatch({
      type: GET_ALL_INVITATIONS,
      payload: res.data.data,
    });
    dispatch({
      type: LOADER,
      payload: false,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: LOADER,
      payload: false,
    });
  }
};

export const approveInvitation = ({
  invitationId,
  status,
  token,
  type,
}) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  const body = JSON.stringify({ status });

  try {
    const res = await API.patch(
      `/api/v1/${type}/change-invitation-status/${invitationId}`,
      body,
      config
    );

    if (status === "Accepted") {
      dispatch({
        type: APPROVE_INVITATION,
        payload: res.data.data,
      });
      dispatch({
        type: SHOW_MESSAGE,
        payload: {
          title: `success`,
          message: `you have accepted invitation`,
          severity: "success",
        },
      });
    }
    if (status === "Ignored") {
      dispatch({
        type: REJECT_INVITATION,
        payload: res.data.data,
      });
    }
  } catch (error) {
    console.log(error);
     dispatch(getAllInvitations(token))
    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        title: ` Failed`,
        message:
          error.response.data.errors && error?.response?.data?.errors?.length
            ? `something wrong`
            : error.response.data.message
              ? error.response.data.message
              : `something wrong`,
        severity: "error",
      },
    });
  }
};

//approve reject mentor ship
export const approveMentorInvitation = ({
  invitationId,
  status,
  token,
  type,
}) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  const body = JSON.stringify({ status, invitationId });

  try {
    const res = await API.patch(
      `/api/v1/trainingCourse/acceptOrRejectMentorInvitation`,
      body,
      config
    );

    dispatch(getAllInvitations(token))

    dispatch({
      type: SHOW_MESSAGE,
      title: `Invitation`,
      payload: {
        title: `success`,
        message: res?.data?.message,
        severity: "success",
      },
    });

  } catch (error) {
    console.log(error);
     dispatch(getAllInvitations(token))
    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        title: ` Failed`,
        message:
          error.response.data.errors && error?.response?.data?.errors?.length
            ? `something wrong`
            : error.response.data.message
              ? error.response.data.message
              : `something wrong`,
        severity: "error",
      },
    });
  }
};


// followers

export const getUserFollowers = (token) => async (dispatch) => {
  dispatch({
    type: LOADER,
    payload: true,
  });
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  try {

    const res = await API.get(`/api/v1/userRelation/getUserFollowers`, config);
    dispatch({
      type: GET_USER_FOLLOWERS,
      payload: res.data.data,
    });
    dispatch({
      type: LOADER,
      payload: false,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: LOADER,
      payload: false,
    });
  }
};

// followings

export const getUserFollowings = (token) => async (dispatch) => {
  dispatch({
    type: LOADER,
    payload: true,
  });
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const res = await API.get(`/api/v1/userRelation/getUserFollowings`, config);
    dispatch({
      type: GET_USER_FOLLOWINGS,
      payload: res.data.data,
    });
    dispatch({
      type: LOADER,
      payload: false,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: LOADER,
      payload: false,
    });
  }
};