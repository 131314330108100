import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Truncate from "react-truncate";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { useIntl } from "react-intl";
import { Tooltip } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import favoriteHeartIconSelected from '../../../assets/Icons/favoriteHeartIconSelected.png'
import {useSelector} from "react-redux"
import { useTheme, useMediaQuery } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  planTooltip: {
    position: "absolute",
    bottom: "10px",
    textAlign: "center",
    background: "#4DB051",
    right: "0px",
    borderRadius: "13px",
    padding: "0px 10px",
    borderTopRightRadius: "0px",
    borderBottomRightRadius: "0px",
    fontFamily: theme?.palette?.fontFamily?.Reef,
    color: "#fff",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "14px",
    display: "flex",
    justifyContent: "center",
    width: "auto",
  },
  root: {
    width: "100%",
    cursor:'pointer',
    borderRadius: "11px",
    boxShadow: " 0px 4px 4px rgba(0, 0, 0, 0.05)"
  },
  media: {
    height: "0",
    position: "relative",
    paddingTop: "56.25%",
    width: "100%",
    BorderRadiusTopRight: "11px",
    BorderRadiusTopLeft: "11px",
    background: "#C4C4C4",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain"
  },
  title: {
    fontFamily: theme.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: "900",
    fontSize: "14px",
    lineHeight: "19px",
    textAlign: "center",
    color: "#000000",

  },
  subTitle: {
    fontFamily: theme.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: "900",
    fontSize: "10px",
    lineHeight: "14px",
    textAlign: "center",
    color: "#C2C2C2"
  },
  nameBold: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "14px",
    color: "#3C4A6B",
    textAlign: 'center',
    marginTop: "10px"
  }
}));

function CourseCard(props) {
  const classes = useStyles();
  const intl = useIntl();
  const theme = useTheme();
  const history = useHistory();
  const configs = useSelector((state) => state?.setting?.siteConfig);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Card className={classes.root} onClick={() => history.push(props?.link)}>
      

      <CardMedia className={classes.media} image={props?.image} style={{ backgroundColor: props?.image && '#fff' }} >
      {props?.plan&&<Typography
          className={classes.planTooltip}
          // style={{ fontSize: isMobile && "18px" }}
        >
          {props?.plan?.name === "Paid"
            ? `Premium: ${configs?.currency}${props?.price}`
            : props?.plan?.name === "Subscription"
            ? "Subscribe"

            : props?.plan?.name==="Donation"?
          "Free":
            props?.plan?.name
          }
        </Typography>}
        <Grid container style={{ position: 'absolute', top: 0 }}>
          <Grid item xs={12} style={{ padding: '7px', textAlign: 'right' }}>
            <img src={favoriteHeartIconSelected} style={{ color: "#09A9BB", fontSize: '30px', cursor: 'pointer' }} onClick={(e) => {
              e.stopPropagation();
              return props?.handleRemoveFavorite(props?.id)
            }} />
          </Grid>
        </Grid>
      </CardMedia>

      <CardContent style={{ padding: '7px 0px', cursor: 'pointer' }} >
 
        <Tooltip title={props?.name}>
          <Typography
            gutterBottom
            style={{ width: "100%", }}
            className={props?.courseNameBold ? classes.nameBold : classes.title}
          >
            <Truncate lines={1} ellipsis={<span>...</span>} >
              {`    ${props?.name}    `}
            </Truncate>
          </Typography>
        </Tooltip>
        <Grid container>
        
          <Grid item xs={4}>
            <Typography
              variant="body2"
              component="p"
              className={classes.subTitle}
            >
              {props?.friends}
            </Typography>
          </Grid>
          <Grid item xs={4}>
           
            {props?.toDuration && (
              <Typography
                variant="body2"
                component="p"
                className={classes.subTitle}
              >
                {props?.toDuration + " "}
                {intl.formatMessage({ id: "mins" })}
              </Typography>
            )}
          </Grid>
          <Grid item xs={4}>
            <Typography
              variant="body2"
              component="p"
              className={classes.subTitle}
            >
              {props?.time}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default CourseCard;
