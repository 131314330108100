import React, { useState, useEffect } from "react";
import { useTheme } from "@material-ui/core/styles";
import {
  Grid,
  TextField,
  Typography,
  Card,
  CardContent,
  useMediaQuery,
  Button,
  Modal,
  Hidden,
  CircularProgress,
  Tooltip,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useSelector, useDispatch } from "react-redux";
import Truncate from "react-truncate";
import { useHistory } from "react-router-dom";
import { addUpdatePlan } from "../../../actions/retreatCourse";
import FreemiumPackageComponent from "../../../components/AddCourse/PackageFreemium";
import "./courseContainer.scss";
import { useIntl } from "react-intl";

const PlanContainer = props => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const intl = useIntl();
  const regex = /^\s*?(\d+(\.\d{1,2})?|\.\d{1,2})\s*$/;
  const [plan, setPlan] = useState(
    props.courseData && props.courseData.plan ? props.courseData.plan : null
  );
  const [price, setPrice] = useState(null);
  const [priceError, setPriceError] = useState(null);
  let history = useHistory();
  let dispatch = useDispatch();
  const classes = useStyles();
  const [loader, setLoader] = useState(false);
  let coursesReducerState = useSelector(state => state.trainingCourse);
  let coursesReducer = useSelector(state => state.courses);
  let authReducerState = useSelector(state => state.auth);
  const [open, setOpen] = React.useState(false);
  const [shouldUpdate, setShouldUpdate] = useState(false);
  const [modalStyle] = React.useState(getModalStyle);
  const [episodes, setEpisodes] = useState(
    props.courseData &&
      props.courseData.episodes &&
      props.courseData.episodes.length > 0
      ? props.courseData.episodes
      : []
  );
  const handlePlanClick = (e, selectedPlan) => {
    e.preventDefault();
    setPlan(selectedPlan);
    setShouldUpdate(true);
    if (selectedPlan?.name === "One Time") {
      return handleOpenModal();
    }
  };

  const handleChangeLock = index => {
    let f = [...episodes];
    f[index].isFree = !episodes[index].isFree;
    setEpisodes([...f]);
  };

  const updateEpisode = name => {
    if (name === "Free" || name === "Donation" || name === "Subscription") {
      const f = episodes.map(n => {
        n.isFree = true;
        return n;
      });
      setEpisodes([...f]);
    } else {
      const f = episodes.map(n => {
        n.isFree = false;
        return n;
      });
      setEpisodes([...f]);
    }
  };

  useEffect(() => {
    if (coursesReducerState?.courseData?.episodes?.length > 0) {
      setEpisodes(coursesReducerState.courseData.episodes);
    } else {
      setEpisodes([]);
    }
    if (coursesReducerState?.courseData?.plan?.id > 0) {
      setShouldUpdate(false);
      setPlan(coursesReducerState.courseData.plan);
      setPrice(
        coursesReducerState.courseData.price
          ? coursesReducerState.courseData.price
          : coursesReducerState.courseData.plan?.price
      );

    }
    setLoader(false);
    setPriceError(null);
  }, [coursesReducerState]);
  useEffect(() => {
    if (loader && coursesReducerState.add_success) {
      setLoader(false);
      setPriceError(null);
    }
  }, [coursesReducerState.add_success]);

  useEffect(() => {
    if (plan?.id && shouldUpdate) {
      updateEpisode(plan.name);
      setPrice("");
      setPriceError(null);
    }
  }, [plan]);

  function rand() {
    return Math.round(Math.random() * 20) - 10;
  }

  function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`
    };
  }
  const validatePrice = () => {
    if (plan?.name !== "Donation" && plan?.name !== "Free" && plan?.name !== "Subscription" && !price) {
      setPriceError('Price is required')
      return true;
    }
    return false;
  };
  const handleOpenModal = () => {
    setOpen(true);
  };

  const handleAddUpdatePlan = async () => {
    if (!validatePrice()) {
      setLoader(true);

      const res = await dispatch(
        addUpdatePlan({
          plan,
          price: price ? parseFloat(price) : 0,
          courseData: coursesReducerState.courseData,
          token: authReducerState.token
        })
      );
      if (res?.id) {
        setLoader(false);
        return props.handleNext();
      } else {
        setLoader(false);

      }
    }
  }

  const handleCloseModal = () => {
    setOpen(false);
  };
  const modalBody = (
    <div style={modalStyle} className={classes.paper}>
      <Typography className={classes.modalDesc}>
        {intl.formatMessage({ id: "courseModelBody" })}
      </Typography>

      <Button className="modalBtn" onClick={handleCloseModal}>
        {intl.formatMessage({ id: "IUnderstand" })}
      </Button>
    </div>
  );
  return (
    <>
      <Grid
        container
        style={{
          paddingLeft: isMobile && !isDesktop ? "0px" : "2rem",
          padding: isMobile && !isDesktop ? "10px 20px" : "0px"
        }}
      >
        <Hidden smUp>
          <Typography
            style={{
              width: "100%",
              marginBottom: "16px",
              textAlign: "center",
              fontFamily: theme.fontFamily.Roboto,
              fontStyle: "normal",
              fontWeight: "bold",
              fontSize: "24px",
              lineHeight: "28px"
            }}
          >
            {intl.formatMessage({ id: "chooseYourPlan" })}
          </Typography>
        </Hidden>

        <Grid container spacing={2}>
          {coursesReducer &&
            coursesReducer.plansList &&
            coursesReducer.plansList.length > 0 &&
            coursesReducer.plansList
              .filter(item => ((item.type === "Course") && (item?.name !== 'Freemium' && item?.name !== 'Donation')))
              .map(singlePlan => (
                <Grid
                  style={{ cursor: "pointer" }}
                  onClick={e => {
                    handlePlanClick(e, singlePlan);
                  }}
                  key={singlePlan.id}
                  item
                  xs={6}
                  lg={2}
                >
                  <Tooltip title={singlePlan.description}>
                    <Card
                      style={{
                        textAlign: "center",
                        background:
                          plan && plan.id === singlePlan.id
                            ? "#FBAE02"
                            : "#FFFFFF"
                      }}
                      elevation={3}
                    >
                      <CardContent style={{ textAlign: "center" }}>
                        <Typography
                          style={{
                            fontFamily: theme.fontFamily.Roboto,
                            fontStyle: "normal",
                            fontWeight: "500",
                            fontSize: "16px",
                            lineHeight: "19px",

                            color:
                              plan && plan.id === singlePlan.id
                                ? "#FFFFFF"
                                : "#000000"
                          }}
                        >
                          {singlePlan.name}
                        </Typography>
                        <Typography
                          style={{
                            fontFamily: theme.fontFamily.Roboto,
                            fontStyle: "normal",
                            fontWeight: "500",
                            fontSize: "12px",
                            lineHeight: "14px",

                            color:
                              plan && plan.id === singlePlan.id
                                ? "#FFFFFF"
                                : "#000000"
                          }}
                        >
                          <Truncate lines={1} ellipsis={"..."}>
                            {singlePlan.description}
                          </Truncate>
                        </Typography>
                      </CardContent>
                    </Card>
                  </Tooltip>
                </Grid>
              ))}
        </Grid>
        {plan && plan?.name !== "Donation" && plan?.name !== "Free" && plan?.name !== "Subscription" && (
          <Grid container>
            <Grid item xs={12} md={5} lg={5}>
              <TextField
                disabled={plan?.name === "Donation" || plan?.name === "Free"}
                className="packagesTextFields"
                fullWidth
                type="number"
                placeholder='Retreat Price'
                onBlur={() => {
                  if (!price) {
                    setPriceError("Price is required");
                  } else if (price && !regex.test(parseFloat(price))) {
                    setPriceError(
                      "Price should be up-to two decimal positive number"
                    );
                  } else if (parseFloat(price) === 0) {
                    setPriceError("Price should be greater than 0");
                  } else {
                    setPriceError(null);
                  }
                }}

                onChange={e => {
                  setPriceError(null)
                  setPrice(e.target.value)
                }
                }
                error={Boolean(priceError)}
                helperText={priceError}
                value={price}
              />
            </Grid>

          </Grid>
        )}
        {plan?.name === "Freemium" ? (
          <Grid container>
            <FreemiumPackageComponent
              handleChangeLock={handleChangeLock}
              episodes={episodes}
            />
          </Grid>
        ) : (
          ""
        )}

        <Grid container>
          <Modal
            open={open}
            onClose={handleCloseModal}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            {modalBody}
          </Modal>
        </Grid>
      </Grid>
      <Grid
        style={{
          paddingLeft: isMobile && !isDesktop ? "0" : "2rem",
          padding: isMobile && !isDesktop ? "10px" : "0px"
        }}
        item
        xs={12}
        md={12}
        lg={5}
      >
        <Button
          style={{
            width: "100%",
            height: "2.750em",
            background:
              !plan || loader
                ? "#C2C2C2"
                : "linear-gradient(180deg, #FFC000 -13.24%, #F28705 100%)",
            borderRadius: isMobile && !isDesktop ? "50px" : "11px",
            fontFamily: theme.fontFamily.Roboto,
            fontStyle: "normal",
            fontWeight: 500,
            fontSize: "20px",
            lineHeight: "23px",
            color: "#FFFFFF",
            marginBottom: "30px",
            marginTop: "20px"
          }}
          disabled={!plan || loader || priceError}
          onClick={handleAddUpdatePlan}
        >
          {loader ? (
            <CircularProgress size={20} style={{ color: "#fff" }} />
          ) : (
            "Next"
          )}
        </Button>
      </Grid>
    </>
  );
};

export default PlanContainer;

const useStyles = makeStyles(theme => ({
  courseDescriptionImageCard: {
    maxWidth: "22.125em",
    height: "15.438em",
    backgroundColor: "#FEEFCC",
    borderRadius: "20px",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "top center"
  },
  cardButton: {
    width: "8.875em",
    height: "2.488em",
    borderRadius: "20px",
    backgroundColor: "#FBAE02",
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: 300,
    fontSize: "10px",
    lineHeight: "10px",
    color: "#FFFFFF",
    marginTop: "20px",
    margin: "auto"
  },
  cardText: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "16px",
    color: "#363636"
  },
  profile_image: {
    padding: "10px",
    cursor: "pointer"
  },
  paper: {
    position: "absolute",
    height: 250,
    maxWidth: 315,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #FFF"
  },
  modalDesc: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "17px",
    marginTop: "20%",
    textAlign: "center",
    letterSpacing: "0.02em",
    color: "#192247",
    padding: theme.spacing(2, 4, 3)
  }
}));
