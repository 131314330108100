import { useState } from 'react'
import {
    Card,
    Grid,
    Dialog,
    DialogContent,
    Button,
    useMediaQuery,
    CircularProgress
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { PlayArrow, PlayCircleOutline, Cancel } from "@material-ui/icons";
import ReactPlayer from "react-player/lazy";
import clsx from 'clsx'
import IntroVideo from '../../assets/videos/OmidIntro.mp4'


function CoursePreview(props) {
    const classes = useStyles();
    const theme = useTheme();

    const [previewDialog, setPreviewDialog] = useState(null);
    const [thumbnail, setThumbnail] = useState(null);
    const [introVideo, setIntroVideo] = useState(true);
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));


    const handleClosePreviewDialog = () => {
        setPreviewDialog(null);
        setThumbnail(null)
    }
    const handleOpenPreviewDialog = () => {
        setPreviewDialog(props?.courseDetail?.previewUrl)
    }


    const handleEnded = () => {
        setIntroVideo(!introVideo)
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                {!isMobile ? <PlayCircleOutline style={{ fontSize: props?.fontSize }} variant="contained"
                    color="default"
                    className={classes.button}
                    onClick={handleOpenPreviewDialog} /> :
                    <Button
                        variant="contained"
                        color="default"
                        className={classes.button1}
                        startIcon={<PlayArrow />}
                        onClick={handleOpenPreviewDialog
                        }>
                        Preview
                    </Button >}
            </Grid>
            <Grid item xs={12}>
                <Dialog
                    fullWidth={true}
                    minWidth={'sm'}
                    open={previewDialog}
                    onClose={handleClosePreviewDialog}
                    aria-labelledby="responsive-dialog-title"
                    classes={{ root: classes.dialogBody }}
                >


                    <DialogContent style={{ padding: '0px' }} >

                        <div onClick={handleClosePreviewDialog} style={{ textAlign: 'right' }} >
                            <Cancel style={{ color: 'red', cursor: 'pointer' }} />
                        </div>

                        {thumbnail ? <Card elevation={0} className={classes.ratio} style={{ borderRadius: '0px' }}>

                            <img
                                src={`${props?.courseDetail?.image}`}
                                className={classes.imageCardRoot}
                                style={{ borderRadius: '0px' }}
                                alt=""
                            />

                        </Card> : <div className={classes.playerWrapper}>
                            <div className={clsx(classes.reactPlayer, classes.preLoadBody)}>
                                <CircularProgress style={{ color: '#09A9BB' }} />
                            </div>
                            <ReactPlayer
                                controls="true"
                                height="100%"
                                width="100%"
                                className={classes.reactPlayer}
                                onEnded={handleEnded}
                                playing={true}
                                onError={() => {
                                    previewDialog && setThumbnail(`${props?.courseDetail?.image}`)
                                }}
                                url={introVideo ? IntroVideo : previewDialog}
                                config={{
                                    youtube: {
                                        playerVars: { showinfo: 1 }
                                    }
                                }}
                            />
                        </div>}
                    </DialogContent>


                </Dialog>

            </Grid>
        </Grid>
    )
}

export default CoursePreview

const useStyles = makeStyles(() => ({

    playerWrapper: {
        position: "relative",
        paddingTop: "56.25%",
        height: 0
    },
    reactPlayer: {
        position: "absolute",
        top: 0,
        left: 0
    },
    imageCardRoot: {
        position: "absolute",
        width: "100%",
        height: "100%",
        top: 0,
        left: 0,
        backgroundColor: "#a9a9a9",
        backgroundPosition: "center",
    },
    ratio: {
        position: "relative",
        width: "100%",
        height: 0,

        paddingTop: `56.25%`,
    },
    button: {
        textTransform: 'none',
        color: '#09B1D3',
        cursor: 'pointer',

    },
    button1: {
        textTransform: 'none',
        color: '#fff',
        cursor: 'pointer',
        backgroundColor: '#09B1D3',
        '&:hover': {
            backgroundColor: '#09B1D3',
        }
    },
    dialogBody: {
        backgroundColor: 'transparent',
        '& .MuiPaper-root': {
            backgroundColor: 'transparent',
            boxShadow: 'none'
        }

    },
    preLoadBody: {
        display: 'flex', height: '100%', alignItems: 'center', justifyContent: 'center', backgroundColor: '#a9a9a9',
        width: '100%'
    }
}));