import SubscriptionCard from './SubscriptionCard';
import { Grid, useMediaQuery } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import LeaveOrContinue from './UnderSubscriptionUpdatePopup'




function SubscriptionPlan({ annual, plans = [] }) {
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));


    return (
        <>
            <LeaveOrContinue />
            <Grid container justify='center'>
                <Grid item xs={12} container spacing={1} className={classes.hideScrollBar} style={{ justifyContent: isMobile ? '' : ((plans.length <= 2 && !annual)?'space-between' : 'space-evenly'  || (plans.length <= 3 && annual)) ? 'space-between' : 'space-evenly' }}  >
                    {plans && plans?.map((item, ind) => (((item?.name !== 'Free' && annual) || !annual)) && (<Grid item key={item.id} >

                        <SubscriptionCard annual={annual} plan={item} ind={ind} color={classes[`color1`]} background={classes[`background1`]} />
                    </Grid>
                    ))}
                </Grid>
            </Grid>
        </>
    );
}

export default SubscriptionPlan;

const useStyles = makeStyles((theme) => ({

    root: {
        width: '100%',
    },
    color1: {
        color: '#14D9D9',
    },

    background1: {
        background: 'linear-gradient(270.51deg, #14D9D9 100%, #09A9BB 0%)',

    },
    color2: {
        color: '#09A9BB',
    },
    background2: {
        background: 'linear-gradient(270.51deg, #14D9D9 0.5%, #09A9BB 111.02%)',
    },
    color3: {
        color: '#505050',
    },
    background3: {
        background: 'linear-gradient(270.51deg, #8A8A8A 0.5%, #2D2D2D 111.02%)',
    },
    color4: {
        color: '#F79D03',
    },
    background4: {
        background: 'linear-gradient(270.51deg, #FFC000 0.5%, #F28705 111.02%)',
    },
    color5: {
        color: '#FA6425',
    },
    background5: {
        background: 'linear-gradient(270.86deg, #F28705 0.74%, #FF4C3B 110.65%)',
    },
    hideScrollBar: {
        paddingTop: '15px',
        width: '100%',
        flexWrap: "unset",
        overflowX: "auto",
        justifyContent: 'space-between',

        "&::-webkit-scrollbar": {
            display: "none",
        },
    },
    heading: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '20px',
        lineHeight: '115%',
        color: '#000',
    },
    backArrow: {
        cursor: 'pointer'
    }
}));