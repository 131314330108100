import React, { useState, useEffect } from 'react';
import {
    TextField,
    Grid,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Search, Clear } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import {
    search,
} from "../../../actions/courses";
import searchIcon from '../../../assets/Icons/BottomNavigationIcons/serchIcon.svg'
import SubscribeNowBtn from '../../../components/Common/SubscribeNowBtn'

function ViewAllSearchComponent(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const authReducerState = useSelector(state => state.auth);
    const [searchTerm, setSearchTerm] = useState('');

    const handleSearch = () => {
        if (searchTerm) {
            dispatch(
                search({
                    searchTerm,
                    type: props?.searchType,
                    token: authReducerState?.token,
                    categoryId: props?.categoryId
                })
            );
            return props?.searchState(true, searchTerm)
        }

    }

    useEffect(() => {
        handleSearch()
    }, [props?.categoryId, props?.searchType])

    const searchInputChangeHandler = (e) => {
        e.preventDefault()
        setSearchTerm(e.target.value);
        if (!e.target.value) {
            return props?.searchState(false, '')
        }
    }

    return (
        <Grid container >
            {props?.mobileSearch ? <Grid item xs={12}>
                <TextField
                    className={classes.searchFieldMobile}
                    onKeyPress={e => {
                        (e.key === 'Enter') && handleSearch();
                    }}
                    value={searchTerm}
                    style={{ width: "100%", }}
                    placeholder="Search..."
                    name="search"
                    onChange={searchInputChangeHandler}
                    InputProps={{
                        startAdornment: (
                            <img style={{ cursor: "pointer", paddingRight: '12px' }} onClick={handleSearch} />
                        ),
                        endAdornment: (
                            <Clear style={{ cursor: "pointer", paddingRight: '12px' }} onClick={() => {
                                setSearchTerm('')
                                return props?.closeMobileSearchBar()
                            }} />
                        ),
                    }}
                />
            </Grid> : <>
                <Grid item xs={6}>
                    <TextField
                        className={classes.searchField}
                        type="Search"
                        onKeyPress={e => {
                            (e.key === 'Enter') && handleSearch();
                        }}
                        value={searchTerm}
                        style={{ width: "100%", }}
                        placeholder="Search..."
                        name="search"
                        onChange={searchInputChangeHandler}
                        InputProps={{
                            endAdornment: (
                                <img src={searchIcon} style={{ cursor: "pointer" }} onClick={handleSearch} />
                            )
                        }}
                    />
                </Grid>
                <Grid item xs={6} container justify='center'>
                    <Grid item xs={6} style={{display:'flex', alignItems:'center'}}>
                        <SubscribeNowBtn />
                    </Grid>
                </Grid>
            </>}
        </Grid>
    )
}

export default ViewAllSearchComponent

const useStyles = makeStyles(theme => ({
    searchFieldMobile: {
        position: 'fixed',
        zIndex: 100,
        '& .MuiInputBase-root': {
            backgroundColor: '#fff',
            color: '#000',
            fontFamily: 'Roboto',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '18px',
            lineHeight: '21px',
            padding: '10px 10px 10px 10px',
          
            height: '66px',
            width: '100%',
            borderBottom: '1px solid #EAEAEA',
            zIndex: 5
        },
        '& .MuiInput-underline:before': {
            border: '0px solid #FFF',
        },
        '& .MuiInput-underline::after': {
            border: '0px solid #FFF',
        },
        '& .MuiInput-underline:hover:not(.Mui-disabled)::before': {
            border: '0px solid #FFF',
        }
    },
    searchField: {
        '& .MuiInputBase-root': {
            backgroundColor: '#',
            color: '#A9A9A9',
            fontFamily: 'Roboto',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '18px',
            lineHeight: '21px',
            padding: '10px 10px 10px 10px',
          
            height: '58px',
            width: '100%',
            border: '1px solid #A9A9A9',
            borderRadius: '10px',
            zIndex: 5
        },
        '& .MuiInput-underline:before': {
            border: '0px solid #FFF',
        },
        '& .MuiInput-underline::after': {
            border: '0px solid #FFF',
        },
        '& .MuiInput-underline:hover:not(.Mui-disabled)::before': {
            border: '0px solid #FFF',
        }
    }
}))