import React, { useEffect, useState } from 'react';
import { Grid, Divider, Typography, useMediaQuery } from '@material-ui/core'
import { makeStyles, withStyles, useTheme } from "@material-ui/core/styles";
import ProgressCard from './ProgressCard'
import LockedSessionCard from './LockedSessionCard'
import { useSelector, useDispatch } from 'react-redux'
import LiveSession from './liveSessionCard';
import moment from 'moment';
import ExpireSubscriptionPopup from '../../../../components/Common/ExpireSubscriptionPopup';

function Content(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const theme = useTheme();
    const [ownerShipStatus, setOwnerShipStatus] = useState('')
    const [inProgressSectionIndex, setInProgressSectionIndex] = useState('');
    const [subscriptionExpire, setSubscriptionExpire] = useState(false);
    const user = useSelector(state => state?.auth?.user);
    const reducerUser = useSelector(state => state?.user?.user);
    const course = useSelector(state => state?.retreatCourse?.trainingCourseDetails);
    const courseContent = useSelector(state => state?.retreatCourse?.courseContent);
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const handlePlayEpisode = (lesson) => {
        if (reducerUser?.isExpiredSubscription && course?.plan?.name === 'Subscription' && user?.id !== course?.user?.id) {
            return setSubscriptionExpire(true)
        } else {
            dispatch({
                type: "TO_BE_PLAY_TRAINING_COURSE_EPISODE",
                payload: lesson
            })
            return isMobile && props?.switch()
        }
    }

    useEffect(() => {
        if (courseContent) {
            if (user?.id === course?.user?.id || course?.isMentor) {
                setOwnerShipStatus('creator');
            } else if ((user?.id !== course?.user?.id) && (!course?.isUserTaking)) {
                setOwnerShipStatus('notJoined');
            } else if ((user?.id !== course?.user?.id) && (course?.isUserTaking)) {
                const start = moment(course?.userTaking?.createdAt, "YYYY-MM-DD")
                const current = moment().startOf('day');

                const noOfDays = moment.duration(start.diff(current)).asDays();

                const index = courseContent?.length > 0 && courseContent.findIndex(item => (!item?.sectionCompleted || (item?.limit > 0 && noOfDays < item?.limit)));

                setInProgressSectionIndex(index > -1 ? index : 0)
                setOwnerShipStatus('joined');
            }
        }

    }, [courseContent, course?.isUserTaking])

    return (
        <Grid container>
            {subscriptionExpire && <ExpireSubscriptionPopup subscriptionExpire={subscriptionExpire} setPopUp={() => setSubscriptionExpire(false)} />}
            {
                ownerShipStatus === 'joined' ? courseContent?.map((section, ind) => <Grid item container xs={12} key={section?.id}>
                    <Grid item xs={12}>
                        <Typography className={classes.heading}>{ind === 0 ? section?.name : `Section ${ind + 1} : ${section?.name}`}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        {(ind <= inProgressSectionIndex) ? section?.lessons?.map((lesson) => lesson?.type === 'live' ? <div key={lesson?.id}><LiveSession lesson={lesson} ownerShipStatus={ownerShipStatus} switch={props?.switch} /></div> : <div key={lesson?.id} onClick={() => handlePlayEpisode(lesson)}><ProgressCard lesson={lesson} /></div>) : (ind > inProgressSectionIndex) ? section?.lessons?.map((lesson) => <div key={lesson?.id} ><LockedSessionCard lesson={lesson} /></div>) : 'No Data'
                        }

                    </Grid>
                    <Grid item xs={12}>
                        <Divider className={classes.divider} />
                    </Grid>
                </Grid>) : courseContent?.map((section, ind) => <Grid item container xs={12} key={section?.id}>
                    <Grid item xs={12}>
                        <Typography className={classes.heading}>{ind === 0 ? section?.name : `Section ${ind + 1} : ${section?.name}`}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        {ownerShipStatus === 'creator' ? section?.lessons?.map((lesson) => lesson?.type === 'live' ? <div key={lesson?.id}><LiveSession lesson={lesson} ownerShipStatus={ownerShipStatus} switch={props?.switch} /></div> : <div key={lesson?.id} onClick={() => handlePlayEpisode(lesson)}><ProgressCard lesson={lesson} /></div>) : (ownerShipStatus === 'notJoined') ? section?.lessons?.map((lesson) => <div key={lesson?.id} ><LockedSessionCard lesson={lesson} /></div>) : 'No Data'
                        }

                    </Grid>
                    <Grid item xs={12}>
                        <Divider className={classes.divider} />
                    </Grid>
                </Grid>)
            }
            {/* <Grid item container xs={12}>
                <Grid item xs={12}>
                    <Typography className={classes.heading}>Yoga Fitness Mentoring</Typography>
                </Grid>
                <Grid item xs={12}>
                    {['completed', 'progress', 'notPlayed'].map((item) => <ProgressCard status={item} />)}
                </Grid>
                <Grid item xs={12}>
                    <Divider className={classes.divider} />
                </Grid>
            </Grid>


            <Grid item container xs={12}>
                <Grid item xs={12}>
                    <Typography className={classes.heading}>Session 2 : The Pose</Typography>
                </Grid>
                <Grid item xs={12}>
                    {['Recorded ', 'Live', 'Assignment'].map((item) => <LockedSessionCard content={item} />)}
                </Grid>
                
            </Grid> */}

        </Grid>
    );
}

export default Content;

const useStyles = makeStyles(theme => ({
    heading: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '18px',
        lineHeight: '100%',
        color: '#353B35',
        paddingBottom: '10px'
    },

    card: {
        width: '100%',
        background: '#FFFFFF',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.05)',
        borderRadius: '10px',
    },
    divider: {
        color: '#E2E2E2',
        height: '2px',
        margin: "15px 0px"
    }

}));