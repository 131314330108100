import React from "react";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import WebGuide from "../Web/GuideWeb";
import MobileGuide from "../Mobile/Guide";
const Auth = () => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      {isDesktop && (
        <>
          <WebGuide />
        </>
      )}
      {isMobile && !isDesktop && (
        <>
          <MobileGuide />
        </>
      )}
    </>
  );
};
export default Auth;
