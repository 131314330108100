import React from "react";
import { Snackbar } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";

function AlertComponent(props) {
  return <Alert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2)
    }
  }
}));

function CustomizedAlerts(props) {
  const message = useSelector(state => state.message);
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  let dispatch = useDispatch();
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    dispatch({
      type: "HIDE_MESSAGE"
    });
  };

  return (
    <div
      className={classes.root}
      style={{ position: "absolute", top: "50%", bottom: "50%" }}
    >
      <Snackbar
        anchorOrigin={{ vertical: "center", horizontal: "center" }}
        open={message.show}
   
        onClose={handleClose}
        autoHideDuration={3000}
      >
        <AlertComponent onClose={handleClose} severity={message.severity}>
          <AlertTitle>{message.title}</AlertTitle>
          {message.message}
        </AlertComponent>
      </Snackbar>
    </div>
  );
}

export default CustomizedAlerts;
