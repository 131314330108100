import API from "../utils/API";
// types
import {
    GET_NOTIFICATIONS,
    GET_UNSEEN_NOTIFICATIONS_COUNT
} from "./types";


//Get all notifications

export const getAllNotifications = ({ token }) => async dispatch => {
    const config = {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        }
    };
    try {
        const res = await API.get(`/api/v1/notification/get-notifications`, config);
        dispatch({
            type: GET_NOTIFICATIONS,
            payload: res.data.data
        });
    } catch (error) {
        console.log(error);
    }
};


//Get unseen notifications count

export const getUnseenNotificationsCount = ({ token }) => async dispatch => {
    const config = {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        }
    };
    try {
        const res = await API.get(`/api/v1/notification/get-unseen-notifications-count`, config);
        dispatch({
            type: GET_UNSEEN_NOTIFICATIONS_COUNT,
            payload: res.data.data
        });
    } catch (error) {
        console.log(error);
    }
};

export const readNotification = ({ token, notificationId }) => async dispatch => {
    const config = {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
        params: {
            notificationId
        }
    };
    try {
        await API.patch(`/api/v1/notification/read-notification`, null, config);
    } catch (error) {
        console.log(error);
    }
};