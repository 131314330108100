import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Truncate from "react-truncate";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { useIntl } from "react-intl";
import { Tooltip } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    borderRadius: "11px",
    boxShadow: " 0px 4px 4px rgba(0, 0, 0, 0.05)"
  },
  media: {
    height: "0",
    position: "relative",
    paddingTop: "100%",
    width: "100%",
    BorderRadiusTopRight: "11px",
    BorderRadiusTopLeft: "11px",
    background: "#C4C4C4",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain"
  },
  title: {
    fontFamily: theme.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: "900",
    fontSize: "14px",
    lineHeight: "19px",
    textAlign: "center",
    color: "#000000",
  },
  subTitle: {
    fontFamily: theme.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: "900",
    fontSize: "10px",
    lineHeight: "14px",
    textAlign: "center",
    color: "#C2C2C2"
  },
  nameBold: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "18px",
    lineHeight: "14px",
    color: "#3C4A6B",
    marginTop: "10px"
  }
}));

function UserCard(props) {
  const classes = useStyles();
  const intl = useIntl();
  return (
    <Card className={classes.root}>
      <CardMedia className={classes.media} image={props?.image}>
        {props?.category && (
          <Grid
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              bottom: 0,
              right: 0
            }}
            container
            justify="flex-end"
          >
            <Grid item xs={1}>
              <Typography
                style={{ color: "#FFF", marginTop: "147px", fontSize: "20px" }}
              >
                &bull;{"   "}{" "}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography
                style={{
                  color: "#FFF",
                  marginTop: "155px",
                  marginRight: "5px",
                  fontSize: "10px"
                }}
              >
                {props?.category}
              </Typography>
            </Grid>
          </Grid>
        )}
      </CardMedia>
      <CardContent style={{ padding: '7px 0px' }}>
        <Tooltip title={props?.name}>
          <Typography
            gutterBottom
            style={{ width: "100%", }}
            className={props?.courseNameBold ? classes.nameBold : classes.title}
          >
            <Truncate lines={1} ellipsis={<span>...</span>} >
              {`    ${props?.name}    `}
            </Truncate>
          </Typography>
        </Tooltip>

        <Tooltip title={props?.title}>
          <Typography
            gutterBottom
            style={{ width: "100%", color: '#C2C2C2' }}
            className={props?.courseNameBold ? classes.nameBold : classes.title}
          >
            <Truncate lines={1} ellipsis={<span>...</span>} >
              {`    ${props?.title || ''}    `}
            </Truncate>
          </Typography>
        </Tooltip>
      </CardContent>
    </Card>
  );
}

export default UserCard;
