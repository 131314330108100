import React, { useEffect } from "react";
import { useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { getUserChats } from "../../actions/chat";
import { getAllChallenges } from "../../actions/challenges";

import MoreWebContainer from "../../containers/Web/More";
import MoreMobileContainer from "../../containers/Mobile/More/MainMenu";
import socketIOClient from "socket.io-client";

let socket = null;


const More = () => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  let dispatch = useDispatch();
  const authReducer = useSelector(state => state?.auth);
  const chatsReducer = useSelector(state => state?.chat?.chats);
  const selectedRoomId = useSelector(state => state?.chat?.selectedRoomId);
  useEffect(() => {
    dispatch(getAllChallenges(authReducer?.token));
    dispatch(getUserChats(authReducer?.token));


  }, []);
  useEffect(() => {

  }, [selectedRoomId]);

  useEffect(() => {
    chatsReducer &&
      chatsReducer.map(chat => {

      });

  }, [chatsReducer]);

  return (
    <>
      {isDesktop && <MoreWebContainer />}
      {isMobile && !isDesktop && (
        <>
          <MoreMobileContainer />
        </>
      )}
    </>
  );
};

export default More;
