import React, { useEffect, useState } from 'react'
import { Grid, Chip } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { makeStyles, useTheme } from "@material-ui/styles";
import ChallengeStatsCard from './ChallengeStatsCard'


function Challenges() {
    const theme = useTheme()
    const classes = useStyles();
    const [filterChallenges, setFilterChallenges] = useState('All')
    const [userChallenges, setUserChallenges] = useState()

    const challengesStats = useSelector(state => state?.challenges?.challengesStats)

    useEffect(() => {
        setUserChallenges(challengesStats?.challenges);
    }, [challengesStats]);

    const filterChallenge = (e, value) => {
        e.preventDefault()
        setFilterChallenges(value)
        let filterChallengesLocally = []
        if (value === 'Completed') {
            filterChallengesLocally = challengesStats?.challenges?.filter((challenge) => {

                if (challenge?.challenge?.isChallengeExpired === true) {
                    return challenge
                }

            })
            setUserChallenges(filterChallengesLocally)
        } else if (value === 'In Progress') {
            filterChallengesLocally = challengesStats?.challenges?.filter((challenge) => {

                if (challenge?.challenge?.isChallengeExpired === false) {
                    return challenge
                }

            })
            setUserChallenges(filterChallengesLocally)
        } else if (value === 'All') {
            filterChallengesLocally = challengesStats?.challenges
            setUserChallenges(filterChallengesLocally)
        }



    }

    return (
        <Grid container  >

            <Grid item container xs={12} spacing={1}>
                <Grid item xs={4}>
                    <Chip label="All" variant="outlined" className={filterChallenges === 'All' ? classes.tag : classes.selectedTag} onClick={(e) => filterChallenge(e, 'All')} />
                </Grid>
                <Grid item xs={4}>
                    <Chip label="Completed" variant="outlined" className={filterChallenges === 'Completed' ? classes.tag : classes.selectedTag} onClick={(e) => filterChallenge(e, 'Completed')} />
                </Grid>
                <Grid item xs={4}>
                    <Chip label="In Progress" variant="outlined" className={filterChallenges === 'In Progress' ? classes.tag : classes.selectedTag} onClick={(e) => filterChallenge(e, 'In Progress')} />
                </Grid>
            </Grid>

            <Grid item container xs={11} md={11} >
                {
                    userChallenges?.length > 0 ? userChallenges?.map((challenge) => {
                        return <Grid item xs={12} style={{ marginTop: '30px' }} key={challenge?.challenge?.id}>
                            <ChallengeStatsCard challenge={challenge?.challenge} />
                        </Grid>
                    }) : <Grid item xs={12} className={classes.challengeNameTxt} style={{ color: '#7d7b79', marginTop: '50px', textAlign: 'center',alignItems:'center' }}>No Challenge detail found</Grid>
                }

            </Grid>

        </Grid>
    )
}

export default Challenges

const useStyles = makeStyles(theme => ({

    tag: {
        fontFamily: theme.fontFamily.Roboto,
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '14px',
        lineHeight: '16px',
        backgroundColor: '#F28705 !important',
        color: '#FFF !important',
        width: '100%',
        height: '49px',
        borderRadius: '82px',
        border: 'none',
    },
    selectedTag: {
        fontFamily: theme.fontFamily.Roboto,
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '14px',
        lineHeight: '16px',
        backgroundColor: '#FFF',
        color: '#F28705',
        width: '100%',
        height: '49px',
        borderRadius: '82px',
        border: '1px solid #BBBB',
    },
    challengeNameTxt: {
        fontFamily: theme.fontFamily.Roboto,
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '14px',
        lineHeight: '16px',

    },

}))