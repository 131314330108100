import React from 'react'
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Typography, Grid, useMediaQuery, IconButton } from '@material-ui/core'
import { useTheme } from '@material-ui/styles'
import { Close } from '@material-ui/icons'

function EpisodeDescription(props) {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const handleClose = () => {
        return props?.openHandler(false);
    };

    return (
        <Grid container>
            <Grid item xs={12}>
                <Dialog onClose={handleClose} fullWidth={true} aria-labelledby="customized-dialog-title" open={props?.open}>
                    <DialogTitle id="customized-dialog-title" onClose={handleClose}  >
                        <Grid container>
                            <Grid item xs={11} sm={11}>
                                {props?.episode?.name}
                            </Grid>
                            <Grid item xs={1} sm={1} >
                                <IconButton aria-label="close" onClick={handleClose} style={{ height: '20px', width: '20px' }}>
                                    <Close />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </DialogTitle>
                    <DialogContent dividers>
                        <Typography gutterBottom >
                            <div dangerouslySetInnerHTML={{ __html: props?.episode?.description ? unescape(props?.episode?.description) : 'No description' }} />
                        </Typography>


                    </DialogContent>
                    <DialogActions>
                        <Button autoFocus onClick={handleClose} color="primary">
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </Grid>


        </Grid>
    )
}



export default EpisodeDescription
