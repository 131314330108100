
import { useState, useEffect } from "react";
import { Grid, Tab, Box, Tabs, Typography, Card } from "@material-ui/core";

import { ArrowBack } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { useTheme, withStyles } from "@material-ui/core/styles";
import { useLocation } from "react-router-dom";
import { useIntl } from "react-intl";
import SubCategoriesLevelCourses from "../../../components/Common/SubCategoriesLevelCourses";

import { useHistory } from "react-router-dom";
const StyledTabs = withStyles({
  indicator: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
    "& > span": {
      maxWidth: 30,
      width: "100%",
      backgroundColor: "#09A9BB"
    }
  }
})(props => (
  <Tabs {...props} centered TabIndicatorProps={{ children: <span /> }} />
));

const StyledTab = withStyles(theme => ({
  root: {
    minWidth: "60px !important",
    textTransform: "none",
    color: "#080808e3",
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    "&:focus": {
      opacity: 1
    }
  },
  selected: {
    color: "#09A9BB"
  }
}))(props => <Tab disableRipple {...props} />);
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box pt={3} pb={3} pl={1} pr={1}>
          <>{children}</>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function ViewAllSubCategory(props) {
  const classes = useStyles();
  const intl = useIntl();
  const location = useLocation();
  let history = useHistory();
  const theme = useTheme();
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const hexToRGB = (hex, alpha) => {
    var r = parseInt(hex && hex.slice(1, 3), 16),
      g = parseInt(hex && hex.slice(3, 5), 16),
      b = parseInt(hex && hex.slice(5, 7), 16);

    if (alpha) {
      return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
    } else {
      return "rgb(" + r + ", " + g + ", " + b + ")";
    }
  };

  const courseViewHeroImageCard = (
    <Card className={classes.root}>
      <div
        className={classes.media}
        style={{
          backgroundImage: `url(${location?.state?.category?.thumbnail})`,
          backgroundPosition: "center"
        }}
      >
        <div
          className={classes.root}
          style={{
            height: "100%"
          }}
        >
          <Grid container style={{ alignItems: 'center', marginTop: '10px' }}>
            <Grid
              item
              xs={2}
              style={{ textAlign: "center" }}
            >
              <ArrowBack
                style={{ color: "#FFF", textAlign: "center" }}
                onClick={e => {
                  e.preventDefault();
                  history.goBack();
                }}
              />
            </Grid>
            <Grid
              item
              xs={10}
              style={{ textAlign: "center" }}
            >
              <div className={classes.categoryTitleContainer}>
                <Typography className={classes.categoryTitle}>
                  {location?.state?.category?.name}
                </Typography>
              </div>
            </Grid>

          </Grid>
        </div>
      </div>
    </Card>
  );

  useEffect(() => {
    if (!location?.state?.category?.id) {
      history.push("/explore/browse");
    }
  }, []);

  return (
    <Grid container>
      <Grid item xs={12}>
        {courseViewHeroImageCard}
      </Grid>

      <Grid item xs={12}>
        <div
          style={{
            position: "absolute",
            zIndex: "100",
            minHeight: "350px",
            width: "100%",
            marginTop: "203px",
            background: "#F8F8F8",
            marginBottom: "60px"
          }}
        >
          <Grid container>

            <Grid container item xs={12} md={12}>
              <Grid xs={12} style={{ height: "60px", background: '#FFF' }}>
                <StyledTabs
                  value={value}
                  onChange={handleChange}
                  aria-label="styled tabs example"
                  style={{ margin: "auto" }}
                >
                  <StyledTab label={intl.formatMessage({ id: "allLevels" })} />
                  <StyledTab label={intl.formatMessage({ id: "beginner" })} />
                  <StyledTab label={intl.formatMessage({ id: "skilled" })} />
                  <StyledTab label={intl.formatMessage({ id: "expert" })} />
                </StyledTabs>
              </Grid>
              <Grid xs={12}>
                <TabPanel value={value} index={0}>
                  {location?.state?.category?.id && (
                    <SubCategoriesLevelCourses level={"All"} />
                  )}
                </TabPanel>

                <TabPanel value={value} index={1}>
                  {location?.state?.category?.id && (
                    <SubCategoriesLevelCourses level={"Beginner"} />
                  )}
                </TabPanel>

                <TabPanel value={value} index={2}>
                  {location?.state?.category?.id && (
                    <SubCategoriesLevelCourses level={"Intermediate"} />
                  )}
                </TabPanel>

                <TabPanel value={value} index={3}>
                  {location?.state?.category?.id && (
                    <SubCategoriesLevelCourses level={"Expert"} />
                  )}
                </TabPanel>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Grid>
    </Grid>
  );
}

export default ViewAllSubCategory;

const useStyles = makeStyles(theme => ({
  hideScrollBar: {
    flexWrap: "unset",
    overflowX: "auto",
    "&::-webkit-scrollbar": {
      display: "none"
    }
  },
  headings: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "18px",
    lineHeight: "21px",
    letterSpacing: "0.05em",
    color: "#3C4A6B",
    paddingTop: "20px",
    paddingBottom: "15px"
  },
  media: {
    height: 0,
    position: "relative",
    paddingTop: "56.25%",
    background: "#c2c2c2",

    transform: "rotate(360deg)",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat"
  },
  root: {
    width: "100%",
    height: "15em",

    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    position: "absolute",
    top: 0

  },

  courseTagline: {
    fontFamily: theme.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: 900,
    fontSize: "20px",
    lineHeight: "27px",
    letterSpacing: "0.015em",
    color: "#FFFFFF"
  },
  courseTitle: {
    fontFamily: theme.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: 900,
    fontSize: "36px",
    lineHeight: "49px",
    color: "#FFFFFF"
  },

  noContent: {
    color: "#7d7b79",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "19px",
    padding: "12px 10px 10px 17px",
    textAlign: "center"
  },
  header: {
    width: "100%",
    height: "10.625em",
    background: " #09A9BB",
    borderRadius: "0px 0px 34px 34px ",
    lineHeight: "10.025em"
  },
  desktopHeader: {
    width: "100%",
    height: "5.625em",
    borderRadius: "0px 0px 34px 34px ",
    lineHeight: "5.025em"
  },
  exploreBtn: {
    width: "76px",
    height: "60px",
    borderRadius: "10px",
    transform: "rotate(360deg)"
  },
  expBtnTxt: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "14px",
    color: " #FFF"
  },
  heading: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "18px",
    lineHeight: "21px",
    color: "#3C4A6B",
    marginTop: "30px",
    marginBottom: "15px"
  },
  hideScrollBar: {
    flexWrap: "unset",
    overflowX: "auto",
    "&::-webkit-scrollbar": {
      display: "none"
    }
  },
  categoryTitle: {
    fontFamily: theme.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: 900,
    fontSize: "20px",
    color: "#fff",

  },
  categoryTitleContainer: {
    height: '41px',
    background: 'rgba(19, 11, 14, 0.5)',
    borderRadius: '5px',
    padding: "5px 15px",
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 'fit-content',
  }
}));
