import React from "react";
import { Grid, Avatar, Box, Typography, Button } from "@material-ui/core";
import { Done } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import { Link } from 'react-router-dom'
import { useIntl } from "react-intl";
function FriendInviteCard(props) {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <Box className={classes.container} style={{ background: "#FFF" }}>
      <Grid container>
        <Grid item xs={2}>
          <Avatar src={props?.pictureUrl} className={classes.large} />
        </Grid>
        <Grid item xs={7}>
          <Link to={props?.goTo} style={{ textDecoration: 'none' }}>
            <Typography
              className={classes.title}
              style={{
                color: props?.status === "pending" ? "#000000" : "#797979"
              }}
            >
              {props?.title}
            </Typography>
          </Link>
          <Typography className={classes.timeAgo}>{props?.timeAgo}</Typography>
        </Grid>

        <Grid item xs={3} style={{ textAlign: "center" }}>
          <Button
            className={`${classes.btn}`}
            onClick={props.Okay}
            disabled={props?.status === "accepted" ? true : false}
          >
            {props?.status === "pending" ? (
              "Accept"
            ) : props?.status === "accepted" ? (
              <Done style={{ color: '#FFF' }} />
            ) : (
              ""
            )}
          </Button>
          {props?.status === "pending" ? (
            <Typography
              className={classes.btnText}
              style={{ color: "#CFCFCF", marginTop: "3px" }}
              onClick={props.reject}
            >

              {intl.formatMessage({ id: "ignore" })}
            </Typography>
          ) : props?.status === "accepted" ? (
            <Typography
              className={classes.btnText}
              style={{ color: "#FBAE02", marginTop: "3px" }}
            >
              {intl.formatMessage({ id: "accepted" })}
            </Typography>
          ) : (
            ""
          )}
        </Grid>
      </Grid>
    </Box>
  );
}

export default FriendInviteCard;

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    height: "80PX",
    width: "100%",
    background: "#fbfaf9",
    borderBottom: "1px solid #E9E9E9",
    alignItems: "center",
    cursor: "pointer",
    padding: "0 5px 0 5px"
  },
  title: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "16px",
    margin: "5px 0px 0px 3px"
  },
  timeAgo: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "14px",
    marginTop: "3px",
    marginLeft: "3px",
    color: "#B6B6B6",
    opacity: 0.7
  },
  large: {
    height: "50px",
    width: "50px"
  },
  btn: {
    maxWidth: "83px",
    height: "25px",
    backgroundColor: "#FBAE02 !important",
    borderRadius: "20px",
    fontFamily: "Roboto !important",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "14px",
    color: "#FFFFFF",
    textTransform: "none",
    marginTop: "10px"
  },
  btnText: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "16px",
    color: "#FFFFFF"
  }
}));
