import React, { useEffect, useState } from 'react';
import { Grid, Typography, Button, Dialog, DialogTitle, DialogContent, DialogActions, IconButton } from '@material-ui/core'
import { Close } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

function RecommendUpgradeSubscriptionPopup(props) {
    const history = useHistory();
    const classes = useStyles();


    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
        if (props?.setRecommendUpgrade) {
            return props?.setRecommendUpgrade()
        }
    }

    const handleUpdate = () => {
        setOpen(false);
        history.push('/more/subscription-plan')
    }



    useEffect(() => {
        props?.recommend && setOpen(false)
    }, [props?.recommend])

    return (
        <></>
    );
}

export default RecommendUpgradeSubscriptionPopup;

const useStyles = makeStyles((theme) => ({

    modelBtn: {
        fontFamily: theme.fontFamily.Roboto,
        cursor: "pointer",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "21px",
        textTransform: 'none',
        color: theme.colors.white,
        background: `linear-gradient(#09A9BB 1%,#09A9BB 100%)`
    },

}));
