import API from "../utils/API";

import {
  GET_ALL_SUBSCRIPTIONS_PLAN,
  SUBSCRIPTIONS_LOADER,
  SHOW_MESSAGE,
  GET_ALL_USER_JOINED_UNDER_SUBSCRIPTION
} from "./types";

import { getCurrentLoggedInUser } from './user'


export const getAllSubscriptions = ({ token }) => async (dispatch) => {
  dispatch({
    type: SUBSCRIPTIONS_LOADER,
    payload: true,
  });
  // set header
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const res = await API.get(`/api/v1/subscriptionPlan/getAllSubscriptionPlans`, config);
    dispatch({
      type: GET_ALL_SUBSCRIPTIONS_PLAN,
      payload: res.data.data,
    });

    dispatch({
      type: SUBSCRIPTIONS_LOADER,
      payload: false,
    });
    return res.data.data

  } catch (error) {
    console.log(error);
    dispatch({
      type: SUBSCRIPTIONS_LOADER,
      payload: false,
    });
  }
};

export const cancelSubscription = ({ id, subscriptionId, token }) => async dispatch => {
  // set header

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    params: {
      subscriptionId
    }
  };
  try {
    const res = await API.delete(
      `/api/v1/purchaseSubscription/deletePurchaseSubscription/${id}`,
      // body,
      config
    );
    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        title: `Subscription`,
        message:
          res.data && res.data.message ? res.data.message : `subscribed cancelled successfully`,
        severity: "success"
      }
    });

    dispatch(getCurrentLoggedInUser({ token }))

    return res.data.data
  } catch (error) {
    console.log(error);
    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        title: `Failed`,
        message:
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : `cancel subscription failed`,
        severity: "error",
      },
    });
  }
};

export const reactiveSubscription  = ({ id, subscriptionId, token }) => async dispatch => {
  // set header
console.log(token,"token&&&&&&&&")
  const body = {}
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    params: {
      subscriptionId
    }
    
  };
  try {
    const res = await API.patch(
      `/api/v1/purchaseSubscription/reactiveSubscriptionPlan/${id}`,
       body,
      config
    );
    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        title: `Subscription`,
        message:
          res.data && res.data.message ? res.data.message : `subscription reactivated successfully`,
        severity: "success"
      }
    });
    dispatch(getCurrentLoggedInUser({ token }))
    return res.data.data
  } catch (error) {
    console.log(error);
    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        title: `Failed`,
        message:
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : `reactive subscription failed`,
        severity: "error",
      },
    });
  }
};

export const getUserJoinedUnderSubscription = ({ token }) => async (dispatch) => {
  // set header
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const res = await API.get(`/api/v1/user/getUserUnderSubscriptionCourses`, config);
    dispatch({
      type: GET_ALL_USER_JOINED_UNDER_SUBSCRIPTION,
      payload: res.data.data,
    });
    return res.data.data

  } catch (error) {
    console.log(error);

  }
};

//unjoine under subscription
export const unjoinUnderSubscription = ({ token, data }) => async (dispatch) => {
  // set header
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const body = JSON.stringify({ ...data })
    const res = await API.post(`/api/v1/course/leaveSubscriptionCourses`, body, config);

    dispatch(getUserJoinedUnderSubscription({ token }))
    return res.data.data

  } catch (error) {
    console.log(error);

  }
};

//continue with same
export const continueWithExisting = ({ token }) => async (dispatch) => {
  // set header
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const body = JSON.stringify({  })
    const res = await API.patch(`/api/v1/user/updateUserSubscriptionDates`,body, config);
    return res.data.data

  } catch (error) {
    console.log(error);

  }
};

export const subscriptionLogs = ({ token }) => async (dispatch) => {
  
  // set header
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const res = await API.get(`/api/v1/user/getUserSubscriptionHistory`, config);
    
    return res.data.data

  } catch (error) {
    console.log(error);
    
  }
};