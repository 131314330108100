import React, { useEffect } from "react";

import { Link, useHistory, useLocation } from "react-router-dom";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { ArrowForwardIos } from "@material-ui/icons";

import { loginUser } from "../../../actions/auth";
import { loginOTP } from "../../../actions/auth";


import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import InputAdornment from "@material-ui/core/InputAdornment";
import {
  IconButton,
  CircularProgress,
  useMediaQuery,
  Fab,
  TextField
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { useIntl } from "react-intl";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    "& > *": {
      width: "100%",
      margin: "11px 8px 11px 8px"
    }
  },

  signInBtn: {
    background: "linear-gradient(186.43deg, #15AEBF 13.46%, #14D9D9 76.64%)",
    boxShadow: "0px 3px 6px rgba(18, 166, 166, 0.25)",
    borderRadius: "47px",
    width: "355px",
    height: "64px",
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "24px",
    lineHeight: "28px",
    letterSpacing: "0.09em",
    color: "#FFFFFF",
    textTransform: "none"
  },
  signInBtnIcon: {
    background: "linear-gradient(210.1deg, #15AEBF 13.46%, #14D9D9 76.64%)",
    boxShadow: "0px 3px 6px rgba(18, 166, 166, 0.25)",
    width: "initial",
    height: "initial",
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "24px",
    lineHeight: "28px",
    letterSpacing: "0.09em",
    transform: "rotate(75deg)",
    padding: "1rem",
    color: "#FFFFFF",
    textTransform: "none"
  },
  forwardIcon: {
    transform: "rotate(-75deg)"
  }
}));

const Login = () => {
  let history = useHistory();
  const classes = useStyles();
  const intl = useIntl();
  const { state } = useLocation();
  const dispatch = useDispatch();
  const authReducerState = useSelector(state => state.auth);
  const theme = useTheme();
  const {setValue, register, errors, handleSubmit } = useForm();

  const onSubmit = data => {
    dispatch(loginOTP({ ...data, url: "login" }));
  };
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));

  const [values, setValues] = React.useState({
    email: "",
    password: "",
    showPassword: false
  });


  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };


  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={classes.root}>

        <TextField
          className={classes.inputField}
          onChange={(e)=>setValue("email",e.target.value?.trim())}
          inputRef={register({
            required: intl.formatMessage({ id: "requiredEmail" }),
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
              message: intl.formatMessage({ id: "invalidEmail" })
            }
          })}
          label={intl.formatMessage({ id: "email" })}
          name="email"
          helperText={errors.email && errors.email.message}
          error={!!errors.email}
          type="text"
        />


        <TextField
          className={classes.inputField}
          type={values.showPassword ? "text" : "password"}
          label={intl.formatMessage({ id: "otpPassword" })}
          inputRef={register({
            required: intl.formatMessage({ id: "requiredPassword" }),
            minLength: {
              value: 5,
              message: intl.formatMessage({ id: "errorPassword" })
            }
          })}
          name="password"
          error={!!errors.password}
          helperText={errors.password && errors.password.message}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleClickShowPassword} edge="end">
                  {values.showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            )
          }}
        />

        
        {isDesktop ? (
          <Button
            type="submit"
            variant="contained"
            className={classes.signInBtn}
            disabled={authReducerState.loader}
            onClick={() => handleSubmit()}
          >
            {authReducerState.loader ? (
              <CircularProgress size={15} color="primary" />
            ) : (
              intl.formatMessage({ id: "sign_in" })
            )}
          </Button>
        ) : (
          <Fab
            type="submit"
            onClick={() => handleSubmit()}
            size="medium"
            color="primary"
            className={classes.signInBtnIcon}
          >
            {authReducerState.loader ? (
              <CircularProgress size={15} color="primary" />
            ) : (
              <ArrowForwardIos className={classes.forwardIcon} />
            )}
          </Fab>
        )}
      </div>
    </form>
  );
};

export default Login;
