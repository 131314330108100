import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import {
  Grid,
  LinearProgress,
  Tooltip,
  FormControlLabel,
  Switch
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import lockIcon from "../../../assets/Icons/lockIcon.svg";
import Truncate from "react-truncate";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import liveIcon from '../../../assets/Icons/liveIcon.svg'

const CustomSwitch = withStyles({
  switchBase: {
    "&$checked": {
      color: "#29cc10"
    },
    "&$checked + $track": {
      backgroundColor: "#29cc10"
    }
  },
  checked: {},
  track: {}
})(Switch);

const BorderLinearProgress = withStyles(theme => ({
  root: {
    height: 10,
    borderRadius: 5
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === "light" ? 200 : 700]
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#1a90ff"
  }
}))(LinearProgress);

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    borderRadius: "11px",
    transform: "rotate(360deg)"
  },
  imageContainer: {
    height: 0,
    paddingTop: "56.25%"
  },
  iconContainer: {
    display: "flex",
    alignContent: "center",
    alignItems: "center"
  },
  Title: {
    fontFamily: theme.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: 900,
    fontSize: "16px",
    lineHeight: "22px",
    color: "#FFFFFF",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover"
  },
  subContent: {
    fontFamily: theme.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: 900,
    fontSize: "12px",
    lineHeight: "16px",
    marginTop: "11px",
    color: " rgba(255, 255, 255, 0.8)"
  },
  videoIcon: {
    height: "3.438em",
    width: "3.438em",
    borderRadius: "100%",
    backgroundColor: "#FFF",
    alignItems: "center",
    textAlign: "center",
    lineHeight: "4.438em"
  },
  activeCourse: {
    background: "#52b202",
    color: "#fff"
  },
  incompleteCourse: {
    background: "#ff9100",
    color: "#fff"
  },
  blockedCourse: {
    background: "#f44336",
    color: "#fff"
  },
  activeEpisode: {
    background: "#52b202",
    color: "#fff"
  },
  inProgressEpisode: {
    background: "#ff9100",
    color: "#fff"
  },
  blockedEpisode: {
    background: "#f44336",
    color: "#fff"
  },
  label: {
    display: "flex"
  }
}));

function CourseEpisodeCard(props) {
  const classes = useStyles();
  
  const {
    image,
    title,
    courseBy,
    duration,
    
    status,
    category,
    backgroundColor,
    isFree,
    textColor,
    videoIconColor,
    videoIconBgColor
  } = props;

  return (
    <Card className={classes.root} style={{ background: backgroundColor }}>
      <CardContent>
        <Grid container>
          <Grid item xs={4} sm={4} md={4}>
            <div
              style={{
                background: `url(${image}), #C4C4C4`,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                borderRadius: "9px"
              }}
              className={classes.imageContainer}
            />
          </Grid>

          <Grid item xs={5} container style={{ paddingLeft: "15px" }}>
            <Grid item xs={12}>
              <Tooltip title={title}>
                <Typography
                  className={classes.Title}
                  style={{ color: textColor }}
                >
                  <Truncate lines={1} ellipsis={<span>...</span>}>
                    {title}
                  </Truncate>
                  {/* {title.length > 15 ? `${title.slice(0, 14)}...` : title} */}
                </Typography>
              </Tooltip>
            </Grid>

            <Grid item xs={12} container justify="space-between">
              <Grid item xs={4}>
                <Typography
                  className={classes.subContent}
                  style={{ color: textColor }}
                >
                  {isFree === true ? "Free" : "Paid"}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography
                  className={classes.subContent}
                  style={{ color: textColor }}
                >
                  {category}
                </Typography>
              </Grid>
            </Grid>

            {props?.episode?.type === 'live' ? <Grid item xs={12} container spacing={1} style={{paddingTop:'5px'}}>
              <Grid item ><img src={liveIcon} /></Grid>
              <Grid item >
                <Chip
                  className={
                    status === "Active"
                      ? classes.activeEpisode
                      : status === "InActive"
                        ? classes.inProgressEpisode
                        : status === "Pending"
                        ? classes.inProgressEpisode
                        : status === "InProgressUploading"
                          ? classes.inProgressEpisode
                          : status === "InProgressTranscoding"
                            ? classes.inProgressEpisode
                            : status === "Blocked"
                              ? classes.blockedEpisode
                              : classes.blockedEpisode
                  }
                  label={status}
                  style={{ height: "18px" }}
                /></Grid>
            </Grid> : <Grid item xs={12}>
              <FormControlLabel
                style={{ marginRight: "0px" }}
                classes={{
                  label: classes.label
                }}
                control={
                  <CustomSwitch
                    onChange={props.handleChangeStatus}
                    checked={props?.status === "Active" ? true : false}
                  />
                }
                label={
                  <Chip
                    className={
                      status === "Active"
                        ? classes.activeEpisode
                        : status === "InActive"
                          ? classes.inProgressEpisode
                          : status === "InProgressUploading"
                            ? classes.inProgressEpisode
                            : status === "InProgressTranscoding"
                              ? classes.inProgressEpisode
                              : status === "Blocked"
                                ? classes.blockedEpisode
                                : classes.blockedEpisode
                    }
                    label={status}
                    style={{ height: "18px" }}
                  />
                }
              />
            </Grid>}
          </Grid>

          <Grid item xs={3} container justify="center">
            <Grid
              item
              xs={12}
              container
              justify="flex-end"
              className={classes.iconContainer}
            >
              <Grid item xs={8} sm={8} md={8} lg={8} xl={6}>
                {isFree === true ? (
                  <div
                    className={classes.videoIcon}
                    style={{ background: videoIconBgColor, margin: "auto" }}
                  >
                    <PlayArrowIcon
                      style={{
                        color: videoIconColor ? videoIconColor : "#7B92A6",
                        height: "1.188em",
                        width: "1.375em"
                      }}
                    />
                  </div>
                ) : (
                  <img
                    src={lockIcon}
                    style={{
                      height: "3.438em",
                      width: "3.438em"
                    }}
                    alt=""
                  />
                )}
              </Grid>
            </Grid>
          </Grid>

          {/* {progress === 100 && <CheckIcon style={{ color: "#FFF" }} />} */}
        </Grid>
      </CardContent>
    </Card>
  );
}

export default CourseEpisodeCard;
