import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  AppBar,
  BottomNavigation,
  BottomNavigationAction,
  Toolbar,
  Typography,
  IconButton,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";

import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import PropTypes from "prop-types";
import Icon from "@material-ui/core/Icon";

import Mice from "../../../assets/Icons/mice.svg";
import Video from "../../../assets/Icons/video.svg";
import Participents from "../../../assets/Icons/participents.svg";
import Share from "../../../assets/Icons/share.svg";
import Sessionend from "../../../assets/img/sessionend.png";

function ElevationScroll(props) {
  const { children, window } = props;

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,

  window: PropTypes.func,
};

function MobileLayout(props) {
  const classes = useStyles();
  let history = useHistory();

  const [value, setValue] = React.useState(

    ""
  );

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div
      style={{
        height: "100vh",
        background: `url(${Sessionend})`,
        backgroundSize: "100% 100%",
        backgroundRepeat: `no-repeat`,
      }}
    >
      <>
        <ElevationScroll {...props}>
          <AppBar className={classes.appBar}>
            <IconButton
              onClick={() => history.goBack()}
              className={classes.backButton}
            >
            </IconButton>
            <Toolbar>
              <Typography className={classes.header}>
                {"Yoga Masterclass"}
              </Typography>
            </Toolbar>
            <IconButton
              onClick={() => history.push("/profile")}
              className={classes.backButton}
            >
              <Typography className={classes.endtext}>{"End"}</Typography>
            </IconButton>
          </AppBar>
        </ElevationScroll>
        <Toolbar />
        <Toolbar />{" "}
      </>

      <BottomNavigation
        value={value}
        onChange={handleChange}
        className={classes.rootNavigation}
        showLabels={true}
      >
        <BottomNavigationAction
          label="Home"
          value="home"
          classes={{
            selected: classes.selectedBottomItem,
          }}
          className={
            value === "home"
              ? classes.selectedBottomItemBackgroundLeft
              : classes.bottomIcon
          }
          icon={
            <Icon classes={{ root: classes.iconRoot }}>
              <img
                alt=""
                src={Mice}
                style={{ width: "100%", height: "25px" }}
              />
            </Icon>
          }
        />

        <BottomNavigationAction
          label="Search"
          value="search"
          classes={{
            selected: classes.selectedBottomItem,
          }}
          className={
            value === "search"
              ? classes.selectedBottomItemBackground
              : classes.bottomIcon
          }
          icon={
            <Icon classes={{ root: classes.iconRoot }}>
              <img
                src={Video}
                alt=""
                style={{ width: "100%", height: "25px" }}
              />
            </Icon>
          }
        />
        <BottomNavigationAction
          label="Profile"
          value="profile"
          classes={{
            selected: classes.selectedBottomItem,
          }}
          className={
            value === "profile"
              ? classes.selectedBottomItemBackground
              : classes.bottomIcon
          }
          icon={
            <Icon classes={{ root: classes.iconRoot }}>
              <img
                src={Participents}
                alt=""
                style={{ width: "100%", height: "25px" }}
              />
            </Icon>
          }
        />
        <BottomNavigationAction
          label="More"
          value="more"
          classes={{
            selected: classes.selectedBottomItem,
          }}
          className={
            value === "more"
              ? classes.selectedBottomItemBackgroundRight
              : classes.bottomIcon
          }
          icon={
            <Icon classes={{ root: classes.iconRoot }}>
              <img
                src={Share}
                alt=""
                style={{ width: "100%", height: "25px" }}
              />
            </Icon>
          }
        />
      </BottomNavigation>
    </div>
  );
}

export default MobileLayout;

const useStyles = makeStyles((theme) => ({
  endtext: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "21px",
    color: "#DA3324",
  },
  header: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "20px",
    lineHeight: "23px",
    color: "#000000",
  },
  rootNavigation: {
    width: "100vw",
    position: "fixed",
    bottom: "0px",
    boxShadow: "0px -12px 12px rgba(0, 0, 0, 0.03)",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    zIndex: 100,
    background: "rgba(27, 25, 47, 0.8)",
  },
  imageIcon: {
    height: "100%",
  },
  iconRoot: {
    textAlign: "center",
    paddingTop: "12px",
    color: "#ffff",
  },
  appBar: {

    backgroundColor: "transparent",
    color: "#000",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
  },
  appBarText: {
    fontStyle: "normal",
    fontWeight: "900",
    fontSize: "26px",
    lineHeight: "35px",
  },
  backButton: {
    background: "transparent",
    color: "#000",
  },
  bottomIcon: {
    color: "#09A9BB",
  },
  selectedBottomItemBackgroundLeft: {
    background: "#09A9BB",
    borderRadius: "0px 30px 0px 0px",
    width: "100%",
    height: "82px",
    position: "relative",
    bottom: "18px",
    paddingTop: theme.spacing(2),
  },
  selectedBottomItemBackgroundRight: {
    background: "#09A9BB",
    borderRadius: "30px 0px 0px 0px",
    width: "100%",
    height: "82px",
    position: "relative",
    bottom: "18px",
    paddingTop: "8px",
  },
  selectedBottomItemBackground: {
    background: "#09A9BB",
    borderRadius: "30px 30px 0px 0px",
    width: "100%",
    height: "82px",
    position: "relative",
    bottom: "18px",
  },
  selectedBottomItem: {
    color: "#ffff",
  },
  selectedBottomIcon: {
    paddingTop: "12px",
    color: "#ffff",
  },
}));
