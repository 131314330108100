import React, { useEffect } from "react";
import {
  Grid,
  Typography,
  Divider,
  Box,
  useMediaQuery,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import RelateIcon from "../../../assets/Icons/RelateIcon.png";
import AppBar from "../../../components/AppBar/index";

import { useSelector } from "react-redux";
import { useTheme } from "@material-ui/core/styles";
import MobileAppbar from "../../../components/More/settings/AppBar";
import { useIntl } from "react-intl";

function CustomerSupport(props) {
  const classes = useStyles();
  let updatePrivacy = useSelector((state) => state.message);
  const intl = useIntl();

  useEffect(() => {
    if (updatePrivacy.show === true && updatePrivacy.severity === "success") {
      setTimeout(props.handleBack(null), 2000);
    }
  });
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  return (
    <Grid container lg={8} md={8} sm={8} xs={12} xl={8}>
      <Grid container>
        {isMobile && !isDesktop ? (
          <MobileAppbar
            body={intl.formatMessage({ id: "customerSupport" })}
            onClick={props.handleBack}
            backAction={"Settings"}
            backgroundColor="#09A9BB"
          />
        ) : (
          <div>
            <AppBar
              appBarConfig={{
                show: true,
                text: intl.formatMessage({ id: "customerSupport" }),
                backAction: props.handleBack,
              }}
            />
          </div>
        )}
      </Grid>

      {isMobile && !isDesktop && (
        <Grid container justify="center">
          <img src={RelateIcon} alt="logo" className={classes.logoImage} />
        </Grid>
      )}

      <Grid
        container
        style={{
          textAlign: isMobile && !isDesktop ? "center" : "left",
          overflow: "hidden",
          marginLeft: "25px",
          marginBottom: "5px",
          marginTop: "25px",
        }}
      >
        <Grid item xs={11}>
          <Typography
            className={classes.header}
            style={{ marginBottom: isMobile && !isDesktop ? "0px" : "20px" }}
          >
            {intl.formatMessage({ id: "customerServiceHeading" })}
          </Typography>
        </Grid>
        <Grid item xs={11}>
          <Typography className={classes.body}>
            {intl.formatMessage({ id: "customerServiceBody" })}
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={10} style={{ marginLeft: "25px" }}>
        <Divider />
      </Grid>
      {/* <ChatScreen
        showImae={false}
        senderTime={"TODAY, 2:30 PM "}
        reciver={
          "Hi Chris ! This is Helen here from relate app, How can i help you ?"
        }
        recieverTime={"TODAY, 2:31 PM"}
        sender={"Hi Helen ! I can’t get my card attached ?"}
      /> */}
    </Grid>
  );
}

export default CustomerSupport;

const useStyles = makeStyles((theme) => ({
  logoImage: {
    marginLeft: "10px",
    marginTop: "10px",
    height: "75px",
    width: "75px",
  },
  header: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "22px",
    lineHeight: "26px",
  },
  body: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "20px",
    lineHeight: "29px",
    color: "#8B8B8B",
  },
}));
