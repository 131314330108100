import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  AppBar,
  BottomNavigation,
  BottomNavigationAction,
  Grid,
  Toolbar,
  Typography,
  IconButton,
  useMediaQuery
} from "@material-ui/core";
import { useLocation, useHistory } from "react-router-dom";
import {
  HomeOutlined,
  SearchOutlined,
  HorizontalSplitOutlined,
  PersonOutline,
  ArrowBack,
  Close
} from "@material-ui/icons";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import PropTypes from "prop-types";
import Sidebar from "../../components/Web/Sidebar";

function ElevationScroll(props) {
  const { children, window } = props;

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,

  window: PropTypes.func
};

function MobileLayout(props) {
  const classes = useStyles();
  const theme = useTheme();
  let location = useLocation();
  let history = useHistory();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [value, setValue] = React.useState(
    location && location.pathname.split("/").length
      ? location.pathname.split("/")[1]
      : ""
  );

  const handleChange = (event, newValue) => {
    history.push(`/${newValue}`);
    setValue(newValue);
  };

  return (
    <div>
      {isMobile && !isDesktop && props.appBarConfig && props.appBarConfig.show && (
        <>
          <ElevationScroll {...props}>
            <AppBar className={classes.appBar}>
              <IconButton
                onClick={() => history.goBack()}
                className={classes.backButton}
              >
                <ArrowBack />
              </IconButton>
              <Toolbar>
                <Typography className={classes.appBarText} variant="h6">
                  {props.appBarConfig.text}
                </Typography>
              </Toolbar>
              {props.appBarConfig.cross && (
                <IconButton
                  onClick={() => history.push(props.appBarConfig.action)}
                  className={classes.backButton}
                >
                  <Close />
                </IconButton>
              )}
            </AppBar>
          </ElevationScroll>
          <Toolbar />
          <Toolbar />{" "}
        </>
      )}
      <div className={isDesktop && classes.root}>
        <Grid container >
          {isDesktop && <Grid item xs={2} sm={2} md={1} lg={1} style={{ position: 'relative', left: 0 }}>
            <Sidebar />
          </Grid>}
          <Grid
            item
            xs={isDesktop ? 9 : '12'}
            sm={isDesktop ? 10 : '12'}
            md={isDesktop ? 11 : '12'}
            lg={isDesktop ? 11 : '12'}
            style={{ paddingLeft: isDesktop && "10px" }}
          >
            {props.children}
          </Grid>
        </Grid>
      </div>

      {isMobile && !isDesktop && props.showBottomNav && (
        <BottomNavigation
          value={value}
          onChange={handleChange}
          className={classes.rootNavigation}
        >
          <BottomNavigationAction
            label="Dashboard"
            value="dashboard"
            classes={{
              selected: classes.selectedBottomItem
            }}
            className={
              value === "dashboard"
                ? classes.selectedBottomItemBackgroundLeft
                : classes.bottomIcon
            }
            icon={
              <HomeOutlined
                className={value === "dashboard" ? classes.selectedBottomIcon : ""}
              />
            }
          />
          <BottomNavigationAction
            label="Explore"
            value="explore"
            classes={{
              selected: classes.selectedBottomItem
            }}
            className={
              value === "explore"
                ? classes.selectedBottomItemBackground
                : classes.bottomIcon
            }
            icon={
              <SearchOutlined
                className={value === "explore" ? classes.selectedBottomIcon : ""}
              />
            }
          />
          <BottomNavigationAction
            label="Profile"
            value="profile"
            classes={{
              selected: classes.selectedBottomItem
            }}
            className={
              value === "profile"
                ? classes.selectedBottomItemBackground
                : classes.bottomIcon
            }
            icon={
              <PersonOutline
                className={
                  value === "profile" ? classes.selectedBottomIcon : ""
                }
              />
            }
          />
          <BottomNavigationAction
            label="More"
            value="more"
            classes={{
              selected: classes.selectedBottomItem
            }}
            className={
              value === "more"
                ? classes.selectedBottomItemBackgroundRight
                : classes.bottomIcon
            }
            icon={
              <HorizontalSplitOutlined
                className={value === "more" ? classes.selectedBottomIcon : ""}
              />
            }
          />
        </BottomNavigation>
      )}
    </div>
  );
}

export default MobileLayout;

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  rootNavigation: {
    width: "100vw",
    position: "fixed",
    bottom: "0px",
    borderRadius: "30px 30px 0px 0px",
    boxShadow: "0px -12px 12px rgba(0, 0, 0, 0.03)",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    backgroundColor: "#FFF",
    zIndex: 100
  },
  appBar: {
    background: "#fff",
    color: "#000",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5)
  },
  appBarText: {
    fontFamily: theme.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: "900",
    fontSize: "26px",
    lineHeight: "35px"
  },
  backButton: {
    background: "transparent",
    color: "#000",
    "&:hover": {
      background: "transparent"
    }
  },
  bottomIcon: {
    color: "#09A9BB"
  },
  selectedBottomItemBackgroundLeft: {
    background: "#09A9BB",
    borderRadius: "0px 30px 0px 0px",
    width: "100%",
    height: "82px",
    position: "relative",
    bottom: "18px",
    paddingTop: theme.spacing(2)
  },
  selectedBottomItemBackgroundRight: {
    background: "#09A9BB",
    borderRadius: "30px 0px 0px 0px",
    width: "100%",
    height: "82px",
    position: "relative",
    bottom: "18px",
    paddingTop: theme.spacing(2)
  },
  selectedBottomItemBackground: {
    background: "#09A9BB",
    borderRadius: "30px 30px 0px 0px",
    width: "100%",
    height: "82px",
    position: "relative",
    bottom: "18px"
  },
  selectedBottomItem: {
    color: "#ffff"
  },
  selectedBottomIcon: {
    paddingTop: theme.spacing(3),
    color: "#ffff"
  }
}));
