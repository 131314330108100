import React, { useEffect, useState } from "react";
import MaterialTable from "material-table";
import {
  Card,
  CardContent,
  CardHeader,
  Avatar,
  Typography,
  Grid,
  CircularProgress
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { getSessionStats } from "../../../../actions/Session";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import moment from 'moment'
function CourseStats() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const params = useParams();
  const [userData, setUserData] = useState([]);

  const auth = useSelector(state => state?.auth);
  const sessionStats = useSelector(state => state?.session?.sessionStats);
  const loader = useSelector(state => state?.loader?.loader);
  let configurations = useSelector(state => state?.setting?.siteConfig);

  useEffect(() => {
    dispatch(
      getSessionStats({ token: auth?.token, sessionId: params?.id })
    );
    return () => {
      dispatch({ type: "SESSION_STATS", payload: {} });
    };
  }, []);

  useEffect(() => {
    if (sessionStats?.id) {
      let users = sessionStats?.sessionParticipants?.map((user, index) => {
        return {
          serialNumber: index + 1,
          pictureUrl: user?.pictureUrl,
          name: user?.name,
          joinDate: moment(user?.createdAt).format("DD-MM-YYYY"),
          role: user?.role,
          email: user?.email,
        };
      });
      setUserData(users);
    }
  }, [sessionStats]);

  return (
    <>
      {loader ? (
        <Grid container justify="center">
          <Grid
            item
            xs={12}
            style={{ textAlign: "center", marginTop: "100px" }}
          >
            <CircularProgress size={25} style={{ color: "#14D9D9" }} />
          </Grid>{" "}
        </Grid>
      ) : (
        <>
          <Card className={classes.root}>
            <CardHeader
              avatar={
                <Avatar aria-label="user's pic" src={sessionStats?.imageUrl}>
                  {sessionStats?.name &&
                    sessionStats?.name.charAt(0).toUpperCase()}
                </Avatar>
              }
              title={
                <Typography
                  className={`${classes.courseStatsTextFamily} ${classes.courseTitle}`}
                >
                  {sessionStats?.name}
                </Typography>
              }
              subheader={
                <Typography
                  className={classes.courseStatsTextFamily}
                  style={{
                    fontSize: "14px",
                    lineHeight: "19px",
                    color: "#B0B0B0",
                  }}
                >
                  {sessionStats?.isFree
                    ? "Free"
                    : `${configurations?.currency} ${sessionStats?.price || 0}`}
                </Typography>
              }
            />

            <CardContent>
              <Grid container>
                <Grid item xs={12} container>
                  <Grid item xs={9} sm={9} md={6} lg={5} xl={3}>
                    <Typography className={classes.courseStatsTextFamily}>
                      Status :
                    </Typography>
                  </Grid>
                  <Grid item xs={3} sm={3} md={3} lg={3} xl={2}>
                    <Typography className={classes.curseStatsSubTitleText}>
                      {sessionStats?.status}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid item xs={12} container>
                  <Grid item xs={9} sm={9} md={6} lg={5} xl={3}>
                    <Typography className={classes.courseStatsTextFamily}>
                      Session Type :
                    </Typography>
                  </Grid>
                  <Grid item xs={3} sm={3} md={3} lg={3} xl={2}>
                    <Typography className={classes.curseStatsSubTitleText}>
                      {sessionStats?.type}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid item xs={12} container>
                  <Grid item xs={9} sm={9} md={6} lg={5} xl={3}>
                    <Typography className={classes.courseStatsTextFamily}>
                      Total Revenue({configurations?.currency}) :
                    </Typography>
                  </Grid>
                  <Grid item xs={3} sm={3} md={3} lg={3} xl={2}>
                    <Typography className={classes.curseStatsSubTitleText}>
                      {sessionStats?.totalRevenue || 0}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid item xs={12} container>
                  <Grid item xs={9} sm={9} md={6} lg={5} xl={3}>
                    <Typography className={classes.courseStatsTextFamily}>
                      Total Net Revenue ( after Platform Fee ){" "}
                      {configurations?.currency} :
                    </Typography>
                  </Grid>
                  <Grid item xs={3} sm={3} md={3} lg={3} xl={2}>
                    <Typography className={classes.curseStatsSubTitleText}>
                      {sessionStats?.netRevenueAfterDeduction || 0}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid item xs={12} container justify="flex-end">
                  <Grid item xs={12} container>
                    <Grid item xs={9} sm={9} md={6} lg={5} xl={3}>
                      <Typography className={classes.courseStatsTextFamily}>
                        Total Users :
                      </Typography>
                    </Grid>
                    <Grid item xs={3} sm={3} md={3} lg={3} xl={2}>
                      <Typography className={classes.curseStatsSubTitleText}>
                        {sessionStats?.sessionParticipants?.length}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <MaterialTable
            columns={[
              {
                title: "Sr.",
                field: "serialNumber",
                width: 50,
              },
              {
                title: "Image ",
                field: "pictureUrl",
                sorting: false,
                searchable: false,
                render: (rowData) => (
                  <Avatar
                    style={{ width: "50px", height: "50px" }}
                    src={rowData.pictureUrl}
                  />
                ),
                headerStyle: { paddingLeft: "25px" },
              },
              {
                title: "Name",
                field: "name",
              },
              {
                title: "Join Date",
                field: "joinDate",
              },

              {
                title: "Role",
                field: "role",
              },

              {
                title: "Email",
                field: "email",
              },
            ]}
            data={userData}
            options={{
              rowStyle: {
                backgroundColor: "#EEE",
              },
            }}
            title="Session Stats"
          />
        </>
      )}
    </>
  );
}

export default CourseStats;

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    minHeight: "10.750em",
    borderRadius: "10px",
    boxShadow: "0px 16px 20px rgba(123, 0, 0, 0.05)",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    marginTop: "30px",
    marginBottom: "40px"
  },
  courseStatsTextFamily: {
    fontFamily: theme.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: 900
  },
  courseTitle: {
    fontSize: "20px",
    lineHeight: "27px",
    color: "#1B1B1B"
  },
  courseStatsTitle: {
    fontFamily: theme.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: 900,
    fontSize: "18px",
    lineHeight: "27px",
    color: "#1B1B1B",
    marginTop: "7px"
  },
  curseStatsSubTitleText: {
    fontFamily: theme.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: 900,
    fontSize: "16px",
    lineHeight: "27px",
    color: "#B0B0B0",
    letterSpacing: "0.015em"
  }
}));
