import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Grid,
  Card,
  Typography,
  useMediaQuery,
  CardContent
} from "@material-ui/core";
import ViewCourseEpCard from "../ViewCourseEpCard";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";

const hexToRGB = (hex, alpha) => {
  var r = parseInt(hex && hex.slice(1, 3), 16),
    g = parseInt(hex && hex.slice(3, 5), 16),
    b = parseInt(hex && hex.slice(5, 7), 16);

  if (alpha) {
    return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
  } else {
    return "rgb(" + r + ", " + g + ", " + b + ")";
  }
};

function CourseViewEpisodesCard(props) {
  const theme = useTheme();
  const classes = useStyles();
  let courseDetail = useSelector(state => state?.courses?.courseDetail);
  let authReducerState = useSelector(state => state?.auth);
  let isMobile = useMediaQuery(theme.breakpoints.up("sm")) ? false : true;
  const intl = useIntl();

  const calculateProgress = episode => {
    if (localStorage.getItem(`ep${episode?.id}`)) {
      let watchedMs = localStorage.getItem(`ep${episode?.id}`);

      let status = (watchedMs / (episode?.duration * 60)) * 100;
      if (status > 99) {
        return 100
      } else {
        return Math.round(status);
      }
    }
    return 0;
  };
  return (
    <Card
      className={classes.episodesCardContainer}
      style={{
        boxShadow: isMobile && "none"
      }}
    >
      <CardContent
        style={
          { marginTop: "30px" }
        }
      >
        <Grid container>

          {props?.episodes?.length > 0 ? (
            props?.episodes?.map((episode, ind) => {
              return (
                <Grid
                  item
                  xs={12}
                  className={classes.episodeSubContainers}
                  key={ind}
                >
                  {(episode?.isFree && courseDetail?.isUserTaking) ||
                    (!episode?.isFree && courseDetail?.isPaid) ||
                    courseDetail?.user?.id === authReducerState?.user?.id ? (

                    <ViewCourseEpCard
                      textColor="#2A4A67"
                      day={`Episode ${ind + 1}`}
                      title={episode?.name}
                      duration={episode?.duration}
                      watchStatus={episode?.watchStatus}
                      status={episode?.status}
                      thumbnail={episode?.thumbnail}
                      progress={calculateProgress(episode)}
                      isPaid={courseDetail?.isPaid}
                      isFree={episode.isFree}
                      plan={courseDetail?.plan?.name}
                      userId={courseDetail?.userId}
                      category={courseDetail?.category}
                      episode={episode}
                      courseId={courseDetail?.id}
                      goNext={true}

                    />

                  ) : (
                    <ViewCourseEpCard
                      textColor="#2A4A67"
                      day={`Day ${ind + 1}`}
                      title={episode?.name}
                      duration={episode?.duration}
                      watchStatus={episode?.watchStatus}
                      status={episode?.status}
                      thumbnail={episode?.thumbnail}
                      isPaid={courseDetail?.isPaid}
                      isFree={episode.isFree}
                      isClickable={true}
                      courseId={courseDetail?.id}
                      userId={courseDetail?.userId}
                      episode={episode}
                      onClick={() => props.setOpen(true)}
                      goNext={false}

                    />
                  )}
                </Grid>
              );
            })
          ) : (
            <Grid item xs={12} className={classes.episodeSubContainers}>
              <Typography className={classes.noRecord}>No Record</Typography>
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
}

export default CourseViewEpisodesCard;

const useStyles = makeStyles(theme => ({
  episodesCardContainer: {
    width: "100%",
    minHeight: "5.750em",
    borderRadius: "10px",
    boxShadow: "0px 16px 20px rgba(123, 0, 0, 0.05)",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat"
  },
  episodeSubContainers: {
    marginTop: "-30px"
  },
  courseTitle: {
    fontFamily: theme.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: 900,
    fontSize: "36px",
    color: "#FFFFFF",
    padding: "4% 0 12% 0"
  },
  noRecord: {
    color: "#FFF",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "19px",
    textAlign: "center",
    marginTop: "30px"
  }
}));
