import React, { useEffect, useState } from 'react';
import { Grid, CircularProgress, Typography } from '@material-ui/core'
import SupportedFileCard from '../../common/supportedFileCard'
import { getSectionsSupportedFiles } from '../../../../actions/retreatCourse';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from "@material-ui/core/styles";


function SupportedFiles(props) {
    const classes = useStyles();

    const dispatch = useDispatch();

    const [loader, setLoader] = useState(false)
    const [playingLesson, setPlayingLesson] = useState(null)
    const user = useSelector(state => state?.auth);
    const supportedFiles = useSelector(state => state?.retreatCourse?.sectionSupportedFiles);
    const lesson = useSelector(state => state?.retreatCourse?.toBePlayedLesson);

    const getData = async () => {
        if (playingLesson?.id) {
            setLoader(true)
            const res = await dispatch(getSectionsSupportedFiles({ sectionId: playingLesson?.sectionId, token: user?.token }));
            if (res) {
                setLoader(false)
            }
        }
    }

    useEffect(() => {

        lesson && setPlayingLesson(lesson)
    }, [lesson])

    useEffect(() => {
        getData();
        return () => {
            dispatch({
                type: 'TRAINING_COURSE_SECTION_SUPPORTED_FILES',
                payload: []
            });
        }
    }, [playingLesson]);



    return (
        <Grid container>
            {loader ? <Grid item xs={12} className={classes.noContent}>
                <CircularProgress size={20} style={{ color: '#09a9bb' }} />
            </Grid>
                : supportedFiles?.length > 0 ?
                    <Grid item xs={12} container spacing={1}>
                        {supportedFiles.map(file => <Grid xs={12} sm={6} md={4} lg={4} xl={3} style={{ paddingBottom: '10px' }} key={file?.id}><SupportedFileCard file={file} /></Grid>)}
                    </Grid> : <Grid item xs={12} className={classes.noContent}>
                        <Typography className={classes.noContentText}>No supported file found</Typography>
                    </Grid>
            }
        </Grid>
    );
}

export default SupportedFiles;

const useStyles = makeStyles(theme => ({
    noContent: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: "40px"
    },
    noContentText: {
        fontFamily: theme.fontFamily.Roboto,
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "14px",
        color: "#666666",

    }

}));