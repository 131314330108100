import { useEffect, useState } from "react";
import { useTheme, makeStyles } from "@material-ui/core/styles";
import { CircularProgress, Typography, Grid } from "@material-ui/core";
import { VerifiedUser, Error } from "@material-ui/icons";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useDispatch, useSelector } from "react-redux";
import { useParams, Link } from "react-router-dom";
import RelateIcon from "../../../assets/Icons/RelateIcon.png";

import API from '../../../utils/API'

function ViewCoursePage() {
    const theme = useTheme();
    const dispatch = useDispatch();
    const params = useParams();
    const classes = useStyles();

    const authReducerState = useSelector(state => state.auth);
    const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const [loader, setLoader] = useState(false);
    const [apiResponse, setApiResponse] = useState('');
    const [message, setMessage] = useState('');

    useEffect(() => {
        getData()
    }, []);

    const getData = async () => {
        setLoader(true)
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${authReducerState?.token}`
            },
            params: {
                token: params?.token
            }
        };
        try {
            const body = JSON.stringify({});
            const res = await API.patch(`/api/v1/auth/verifyEmail`, body, config);
            setApiResponse('success')
            setMessage(res?.data && res.data.message ? res.data.message : `Email  verified`)
            dispatch({
                type: 'SHOW_MESSAGE',
                payload: {
                    title: `Success`,
                    message:
                        res.data && res.data.message ? res.data.message : `Email  verified`,
                    severity: "success"
                }
            });
            setLoader(false);

        } catch (error) {
            console.log(error);
            setApiResponse('fail')
            setMessage(error?.response &&
                error.response &&
                error.response.data &&
                error.response.data.message
                ? error.response.data.message
                : `Email not verified`)
            dispatch({
                type: 'SHOW_MESSAGE',
                payload: {
                    title: `Failed`,
                    message:
                        error.response &&
                            error.response &&
                            error.response.data &&
                            error.response.data.message
                            ? error.response.data.message
                            : `Email not verified`,
                    severity: "error"
                }
            });
            setLoader(false)
        }
    };




    return (
        <Grid container

        >
            <Grid item xs={2} md={3}>
                <div
                    className={classes.bottomLeft}
                    style={{ backgroundColor: "#a6eae9" }}
                />
            </Grid>
            <Grid item container xs={8} md={6} md style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
                <Grid item xs={12} style={{ textAlign: "center", zIndex: 1 }}>
                    <a href={process.env.REACT_APP_LANDING_URL}>
                        <img src={RelateIcon} alt="logo" style={{ marginTop: "30px", }} />
                    </a>
                </Grid>
                {
                    loader ? <Grid item xs={12} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', }}>
                        <CircularProgress size={30} style={{ color: '#15AEBF' }} />
                    </Grid> :
                        <Grid item xs={12} container style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '70vh' }}>
                            <Grid item xs={12} className={classes.center}>
                                {
                                    apiResponse === 'success' ? <VerifiedUser className={classes.iconStyle} style={{ color: 'green' }} /> : apiResponse === 'fail' ? <Error className={classes.iconStyle} /> : ''
                                }
                            </Grid>

                            <Grid item xs={12}>
                                <Typography className={classes.messageHeading}>
                                    {message}
                                </Typography>
                            </Grid>

                            <Grid item xs={12} className={classes.center}>
                                {apiResponse === 'success' && <Link to='/auth' className={classes.link}>Go to Login</Link>}
                            </Grid>

                        </Grid>
                }
            </Grid>

            <Grid item container xs={2} md={3} justify='flex-end'>
                <Grid item xs={12} style={{ flexBasis: 0 }}>
                    <div className={classes.topRightCircle}></div>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default ViewCoursePage;


const useStyles = makeStyles(theme => ({
    messageHeading: {
        fontFamily: theme.fontFamily.Reef,
        fontStyle: "normal",
        fontWeight: 900,
        fontSize: "30px",
        textAlign: 'center',
        color: "#000",
        paddingBottom: '15px'
    },
    iconStyle: {
        color: 'red',
        height: '100px',
        width: '100px',
        textAlign: 'center'
    },
    center: {
        textAlign: 'center'
    },
    link: {
        fontFamily: theme.fontFamily.Reef,
        fontStyle: "normal",
        fontWeight: 900,
        fontSize: "18px",
        textAlign: 'center',
    },
    bottomLeft: {
        width: "50px",
        height: "50px",
        borderRadius: "100%",
        position: 'fixed',
        bottom: 30,
        marginLeft: "50px",
    },

    topRightCircle: {
        width: "150px",
        height: "150px",
        borderRadius: "2% 0px 2% 100%",
        float: "right",
        backgroundColor: "#09A9BB",
        opacity: "0.5"
    }
}))