import { CardMedia, Grid, Typography, useMediaQuery } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/styles";
import React, { useState } from "react";
import { Edit } from "@material-ui/icons"
import DoubleImageIcon from "../../../assets/DoubleImageIcon.svg";
import CropImage from "../../Common/CropImageComponent";
import { useIntl } from "react-intl";
const AddImage = props => {
  const { image, selectedFile, btnText } = props;
  const classes = useStyles();
  const theme = useTheme();
  const intl = useIntl();
  const [openDialog, setOpenDialog] = useState(false);
  const [upImg, setUpImage] = useState("");
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleClose = () => {
    setOpenDialog(false);
    setUpImage(null);
  };

  const handleSelectedFile = e => {
    if (e.target.files && e.target.files[0]) {
      setUpImage(e.target.files[0]);
      setOpenDialog(true);
    }
  };

  return (
    <>
      {upImg && (
        <CropImage
          upImg={upImg}
          openDialog={openDialog}
          setOpenDialog={setOpenDialog}
          handleClose={handleClose}
          setCoverImage={file => {
            setUpImage(null);
            selectedFile(file);
          }}
        />
      )}
      {!image ? (
        <CardMedia className={classes.courseDescriptionImageCard}>
          <Grid
            container
            direction="column"
            justify="center"
            style={{
              textAlign: "center",
              lineHeight: "5em",
              marginTop: isMobile ? "5%" : "10%",
              position: "absolute",
              top: 0
            }}
          >
            <Grid item xs={12}>
              <img src={DoubleImageIcon} alt="" />
            </Grid>

            <Grid item xs={12} style={{ height: '10px' }}>
              <Typography className={classes.cardText}>
                {intl.formatMessage({ id: "itsEmptyHere" })}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <label className="profile_image">
                <input
                  type="file"
                  className="profile_image_input"
                  style={{ display: "none" }}
                  accept="image/*"
                  onChange={handleSelectedFile}
                  onClick={(e) => { e.target.value = '' }}

                />
                <div className={classes.cardButtonEmpty} style={{ marginTop: isMobile ? '10px' : '20px' }}>
                  <Typography>{btnText}</Typography>
                </div>
              </label>
            </Grid>
          </Grid>
        </CardMedia>
      ) : (
          <CardMedia
            id={"imageWidth"}
            className={classes.courseDescriptionImageCard}
            style={{ background: `url(${image})`, backgroundRepeat: `no-repeat` }}
          >

            <Grid
              container
              direction="column"
              justify="center"
              style={{
                textAlign: "center",
                lineHeight: "5em",
                opacity: "1",
                position: "absolute",
                top: 0,
                left: 0,
                bottom: 100,
                right: 0
              }}
            >
              <Grid
                style={{
                  zIndex: 100,
                }}
                item
                container
                justify="flex-end"
                xs={12}
              >
                <label >
                  <input
                    type="file"
                    className="profile_image_input"
                    style={{ display: "none" }}
                    accept="image/*"
                    onChange={handleSelectedFile}
                    onClick={(e) => { e.target.value = '' }}
                  />
                  <div className={classes.cardButton}>
                    <Edit />
                  </div>
                </label>
              </Grid>
            </Grid>
          </CardMedia>
        )}
    </>
  );
};

export default AddImage;

const useStyles = makeStyles(theme => ({
  courseDescriptionImageCard: {
    backgroundColor: "#FEEFCC",
    borderRadius: "20px",
    backgroundSize: "cover !important",
    backgroundRepeat: "no-repeat !important",
    backgroundPosition: "top center",
    position: "relative",
    height: 0,
    paddingTop: `56.25%`,
    width: '100%'
  },
  cardButton: {
    backgroundColor: "#FBAE02",
    cursor: "pointer",
    width: "4em",
    height: "4em",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "20px",
    fontStyle: "normal",
    fontWeight: 300,
    fontSize: "10px",
    lineHeight: "10px",
    color: "#FFFFFF",
    margin: "10px 10px 0px 0px"
  },
  cardText: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "16px",
    color: "#363636",
  },
  profile_image: {
    padding: "10px",
    cursor: "pointer"
  },
  cardButtonEmpty: {
    cursor: "pointer",
    width: "18em",
    height: "4em",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "20px",
    backgroundColor: "#FBAE02",
    fontStyle: "normal",
    fontWeight: 300,
    fontSize: "10px",
    lineHeight: "10px",
    color: "#FFFFFF",
    marginTop: "20px",
    margin: "auto",
  },
}));
