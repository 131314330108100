import React from 'react';
import { CardContent, Grid, Card, Avatar, Typography, Badge } from '@material-ui/core'
import { Star } from '@material-ui/icons'
import { makeStyles } from "@material-ui/core/styles";


function MentorComment(props) {
    const classes = useStyles();

    return (
        <Card className={classes.cardContainer}>
            <CardContent style={{ padding: '0px' }}>
                <Grid container >
                    <Grid item xs={2} >
                        <Avatar src={props?.mentor?.pictureUrl}>
                            {props?.mentor?.name[0]}
                        </Avatar>
                    </Grid>
                    <Grid container item xs={10} >
                        <Grid item container xs={12}>
                            <Grid item ><Star className={classes.star} /></Grid>
                            <Grid item ><Typography className={classes.mentorName}>{props?.mentor?.name}</Typography></Grid>
                        </Grid>
                        <Grid item xs={12}><Typography className={classes.content}>
                            {props?.mentor?.title}
                            </Typography></Grid>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
          


    );
}

export default MentorComment;

const useStyles = makeStyles(theme => ({
    cardContainer: {
        background: '#fff',
        width: '100%',
        boxShadow: 'none',
        paddingBottom: '18px',
    },

    mentorName: {
        fontFamily: theme.fontFamily.Roboto,
        fontStyle: "normal",
        fontWeight: 'bold',
        fontSize: "16px",
        lineHeight: "20px",
        color: "#383838"
    },
    user: {
        fontFamily: theme.fontFamily.Roboto,
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "12px",
        lineHeight: "19px",
        color: "#9F9F9F"
    },

    content: {
        fontFamily: theme.fontFamily.Roboto,
        fontStyle: "normal",
        fontWeight: 900,
        fontSize: "14px",
        lineHeight: "15px",
        color: "#B7B7B7",
        paddingLeft: '10px'
    },
    star: {
        color: '#FBAE02', height: '19px', lineHeight: '20px'
    },



}));