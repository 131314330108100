import React, { useEffect, useState } from "react";
import MyCoursesCard from "../../../../components/Profile/MyCourses/MyCoursesCard";
import UploadedCourseCard from "../../../../components/Profile/MyCourses/UploadedCourseCard";
import {
  Grid,
  Fab,
  AppBar,
  Tabs,
  Tab,
  Typography,
  Box,
  Select,
  MenuItem,
  useMediaQuery,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  CircularProgress,
  Button,
  Modal
} from "@material-ui/core";
import { Add, ArrowBack, Delete, Edit } from "@material-ui/icons";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import { useHistory, Link, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  getUserUploadedCourses,
  getUserEnrolledCourses,
  deleteCourse
} from "../../../../actions/courses";
import {
  getAllCreatedTrainingCourses,
  deleteTrainingCourse
} from "../../../../actions/retreatCourse";
import "./myCourses.scss";
import { useIntl } from "react-intl";
import ExpireSubscriptionPopup from '../../../../components/Common/ExpireSubscriptionPopup';

const StyledTabs = withStyles({
  indicator: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
    height: "3px",
    "& > span": {
      background: "#09a9bb",
      borderRadius: "12px",
      maxWidth: 40,
      width: "100%"
    }
  }
})(props => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

const StyledTab = withStyles(theme => ({
  root: {
    textTransform: "none",
    color: "#fff",
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    "&:focus": {
      opacity: 1
    }
  }
}))(props => <Tab disableRipple {...props} />);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={2}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}



function MyCourses(props) {
  const classes = useStyles();
  const intl = useIntl();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  let location = useLocation();
  let history = useHistory();
  let dispatch = useDispatch();
  const [category, setCategory] = React.useState("");
  const [value, setValue] = React.useState(
    location && location.id ? location.id : 0
  );
  const [comingSoon, setComingSoon] = useState(false);
  const [modalStyle] = useState(getModalStyle);
  function getModalStyle() {
    const top = 50;
    const left = 50;
    return {
      borderColor: theme.colors.white,
      borderRadius: "4px",
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top - 4}%, -${left}%)`
    };
  }
  let user = useSelector(state => state?.auth?.user);
  let reducerUser = useSelector(state => state?.user?.user);
  let authReducerState = useSelector(state => state?.auth);
  let categoriesList = useSelector(state => state?.courses?.categoriesList);
  let userUploadedCoursesList = useSelector(
    state => state?.courses?.userUploadedCourses
  );

  let courseDetail = useSelector((state) => state?.courses?.courseDetail);
  let uploadedTrainingCoursesList = useSelector(state => state?.retreatCourse?.allUploadedTrainingCourses);
  let uploadedTeacherTrainingList = useSelector(state => state?.retreatCourse?.allUploadedTeacherTrainings);

  const userEnrolledCoursesState = useSelector(
    state => state?.courses?.userEnrolledCoursesList
  );
  console.log(userEnrolledCoursesState,"userEnrolledCoursesState")
  const courseLoader = useSelector(state => state?.courses?.courseLoader);
  const [deleteTrainingCourseLoader, setDeleteTrainingCourseLoader] = useState(false);
  const [userUploadedCourses, setUserUploadedCourses] = useState();
  const [userEnrolledCourses, setUserEnrolledCourses] = useState();
  const [subscriptionExpire, setSubscriptionExpire] = useState(false);
  const [uploadedTrainingCourses, setUploadedTrainingCourses] = useState([]);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [selectedCourse, setSelectedCourse] = useState(null);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  const handleGoToInprogressCourseEpisode = (course) => {
    if (reducerUser?.isExpiredSubscription && course?.plan?.name === 'Subscription' && course?.user?.id !== user?.id) {
      return setSubscriptionExpire(true)
    }
    if (!course?.isPaid && course?.plan?.name === 'Freemium') {
      let inProgress = ''
      let notStarted = ''
      const freeEpisodes = course?.episodes?.length > 0 && course?.episodes?.filter(item => {
        inProgress = !inProgress && item?.isFree && (item?.watchStatus === 'Started') && item?.multimediaUrl ? item?.id : '';
        notStarted = !notStarted && item?.isFree && (item?.watchStatus === 'Not Started') && item?.multimediaUrl ? item?.id : '';
        return item?.isFree && item?.multimediaUrl
      });

      if (inProgress) {
        return history.push(`/play-course/${course?.id}/${inProgress}`)
      } else if (notStarted) {
        return history.push(`/play-course/${course?.id}/${notStarted}`)
      } else if (freeEpisodes?.length > 0) {
        return history.push(`/play-course/${course?.id}/${freeEpisodes[0].id}`)
      } else {
        return history.push(`/view-course/${course?.slug}`)
      }
    } else {
      const inProgressEpisode = course?.episodes?.length > 0 && course?.episodes?.find(item => ((item?.watchStatus === 'Started' && item?.multimediaUrl)));

      const notStarted = course?.episodes?.length > 0 && course?.episodes?.find(item => ((item?.watchStatus === 'Not Started' && item?.multimediaUrl)));

      const multimediaEpisode = course?.episodes?.length > 0 && course?.episodes?.find(item => (item?.multimediaUrl));

      if (inProgressEpisode) {
        return history.push(`/play-course/${course?.id}/${inProgressEpisode?.id}`)
      } else if (notStarted) {
        return history.push(`/play-course/${course?.id}/${notStarted?.id}`)
      } else if (multimediaEpisode) {
        return history.push(`/play-course/${course?.id}/${multimediaEpisode?.id}`)

      }
    }


  }

  const handleSelect = event => {
    setCategory(event.target.value);
    if (event.target.value !== "") {
      let enrolledCoursesFiltered = userEnrolledCoursesState?.filter(course => {
        if (course?.course?.enrolledCourses?.category?.name === event.target.value) {
          return course;
        }
      });

      setUserEnrolledCourses(enrolledCoursesFiltered);

      let uploadedCoursesFiltered = userUploadedCoursesList?.filter(course => {
        if (course?.category?.name === event.target.value) {
          return course;
        }
      });
      setUserUploadedCourses(uploadedCoursesFiltered);

      let uploadedTrainingCoursesFiltered = uploadedTrainingCoursesList?.filter(course => {
        if (course?.category?.name === event.target.value) {
          return course;
        }
      });

      setUploadedTrainingCourses(uploadedTrainingCoursesFiltered);

    } else {
      setUserUploadedCourses(userUploadedCoursesList);
      setUserEnrolledCourses(userEnrolledCoursesState);
      setUploadedTrainingCourses(uploadedTrainingCoursesList);

    }
  };

  useEffect(() => {
    setUserUploadedCourses(userUploadedCoursesList);
    setUserEnrolledCourses(userEnrolledCoursesState);
    setUploadedTrainingCourses(uploadedTrainingCoursesList);
  }, [userUploadedCoursesList, userEnrolledCoursesState, uploadedTrainingCoursesList]);

  useEffect(() => {
    dispatch(getUserEnrolledCourses(authReducerState?.token));
    authReducerState.user.role !== "Regular" &&
      dispatch(getUserUploadedCourses(authReducerState?.token));
    authReducerState.user.role !== "Regular" &&
      dispatch(getAllCreatedTrainingCourses({ token: authReducerState?.token, retreatType: 'retreat' }));
    authReducerState.user.role !== "Regular" &&
      dispatch(getAllCreatedTrainingCourses({ token: authReducerState?.token, retreatType: 'teacherTraining' }));

    return () => {
      dispatch({
        type: 'GET_ALL_TRAINING_COURSE',
        payload: []
      });

      dispatch({
        type: 'GET_ALL_CREATED_TEACHER_TRAINING',
        payload: []
      });

    }

  }, []);

  useEffect(() => {
    if (selectedCourse && !courseLoader) {
      setSelectedCourse(null);
      dispatch(getUserUploadedCourses(authReducerState?.token));
    }
  }, [courseLoader]);

  const modalBody = (
    <Grid
      container
      direction="row"
      spacing={3}
      style={modalStyle}
      className={classes.paper}
    >
      <Grid item className={classes.msgBody}>
        <Typography className={classes.modelBodyText}>
          Coming Soon....
        </Typography>
      </Grid>
      <Grid item className={classes.modalBtnGrid}>
        <Button
          onClick={() => setComingSoon(false)}
          className={classes.modelBtn}
        >
          {intl.formatMessage({ id: "okay" })}
        </Button>
      </Grid>
    </Grid>
  );

  const handleDeleteCourse = () => {
    if (selectedCourse.id && value === 0) {
      dispatch(
        deleteCourse({
          token: authReducerState?.token,
          courseId: selectedCourse.id
        })
      );
    } else if (selectedCourse.id && value === 1) {
      setDeleteTrainingCourseLoader(true);
      const res = dispatch(
        deleteTrainingCourse({
          token: authReducerState?.token,
          courseId: selectedCourse.id
        })
      );
      if (res) {
        setDeleteTrainingCourseLoader(false);
        setSelectedCourse(null);
      }

    } else {
      setSelectedCourse(null);
    }
  };
  return (
    <Grid
      container
      direction="row"
      style={{ marginBottom: isMobile && "30px" }}
    >
      <Grid item xs={12}>
        <ExpireSubscriptionPopup subscriptionExpire={subscriptionExpire} setPopUp={() => setSubscriptionExpire(false)} />
      </Grid>
      <Modal
        disableEnforceFocus={true}
        open={comingSoon}
        onClose={() => setComingSoon(false)}
      >
        {modalBody}
      </Modal>
      {isMobile && !isDesktop && (
        <Grid item xs={12} container className={classes.appBarContainer}>
          <Grid item container xs={10} className={classes.appBarMobile}>
            <Grid item xs={3}>
              <Link to="/profile">
                <ArrowBack />
              </Link>
            </Grid>
            <Grid item xs={9}>
              <Typography className={classes.heading}>My Courses</Typography>
            </Grid>
          </Grid>
        </Grid>
      )}
      <Grid
        item
        container
        xs={12}
        md={10}
        lg={9}
        direction="row"
        style={{ marginTop: isMobile && "110px" }}>
        <Grid item container xs={11} sm={10} md={10} lg={10}>
          {isMobile ? (
            <Grid
              item
              xs={12}
              md={4}
              lg={4}
              style={{
                display: "flex",
                justifyContent: "flex-end",
                textAlign: "right"
              }}
            >
              <Select
                className="selectCategory"
                value={category}
                onChange={handleSelect}
                displayEmpty
                classes={{ select: classes.select }}
                inputProps={{ "aria-label": "Without label" }}
              >
                <MenuItem value=""

                >
                  {intl.formatMessage({ id: "allCategories" })}
                </MenuItem>
                {categoriesList
                  ?.filter(item => item.parentCategoryId === null)
                  .map((category, ind) => {
                    return (
                      <MenuItem value={category?.name} key={ind}  >
                        {category?.name}
                      </MenuItem>
                    );
                  })}

                {/* <MenuItem value={20}>Twenty</MenuItem>
          <MenuItem value={30}>Thirty</MenuItem> */}
              </Select>
            </Grid>
          ) : (
            ""
          )}
          <Grid item xs={12} sm={8} md={8} lg={8}>
            <AppBar position="static" className={classes.appBar}>
              {user?.role === "Regular" ? (
                <StyledTabs value={value} onChange={handleChange}>
                  <StyledTab
                    label={intl.formatMessage({ id: "inProgress" })}
                    classes={{
                      root: classes.tabLabel,
                      selected: classes.tabSelected
                    }}
                  />
                  <StyledTab
                    label={intl.formatMessage({ id: "completed" })}
                    classes={{
                      root: classes.tabLabel,
                      selected: classes.tabSelected
                    }}
                  />
                </StyledTabs>
              ) : (
                <StyledTabs value={value} onChange={handleChange}>

                  <StyledTab
                    label={intl.formatMessage({ id: "uploaded" })}
                    classes={{
                      root: classes.tabLabel,
                      selected: classes.tabSelected
                    }}
                  />
                  <StyledTab
                    label={intl.formatMessage({ id: "trainingCourses" })}
                    classes={{
                      root: classes.tabLabel,
                      selected: classes.tabSelected
                    }}
                  />
                  <StyledTab
                    label={intl.formatMessage({ id: "teacherTrainings" })}
                    classes={{
                      root: classes.tabLabel,
                      selected: classes.tabSelected
                    }}
                  />
                </StyledTabs>
              )}
            </AppBar>
          </Grid>
          {!isMobile ? (
            <Grid item xs={4} md={4} lg={4} xl={4} style={{ textAlign: "right" }}>
              <Select
                className="selectCategory"
                value={category}
                onChange={handleSelect}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                classes={{ select: classes.select }}
              >
                <MenuItem value="" >
                  {intl.formatMessage({ id: "allCategories" })}
                </MenuItem>
                {categoriesList
                  ?.filter(item => item.parentCategoryId === null)
                  .map((category, ind) => {
                    return (
                      <MenuItem value={category?.name} key={ind} >
                        {category?.name}
                      </MenuItem>
                    );
                  })}


              </Select>
            </Grid>
          ) : (
            ""
          )}
        </Grid>
        <Grid item xs={12} container direction="row">
          <Grid item xs={12} md={12}>
            {user?.role === "Regular" && (
              <TabPanel value={value} index={0} style={{ marginLeft: "-15px" }}>
                <Grid container direction="row" spacing={!isMobile && 3}>
                  {userEnrolledCourses?.map((item, ind) => {
                    const { enrolledCourses } = item.course;
                    if (Math.ceil(item?.course?.progress) < 100) {
                      return (
                        <Grid
                          key={enrolledCourses?.id}
                          item
                          xs={12}
                          sm={11}
                          md={6}
                          lg={6}
                          style={{ paddingBottom: '20px', cursor: 'pointer' }}
                          onClick={() => handleGoToInprogressCourseEpisode(enrolledCourses)}>

                          <MyCoursesCard
                            title={enrolledCourses?.name}
                            courseBy={enrolledCourses?.user?.name}
                            duration={item?.course.totalDuration}
                            progress={item?.course?.progress}
                            category={enrolledCourses?.category?.name}
                            image={enrolledCourses?.image}

                          />
                        </Grid>
                      );
                    }
                  })}
                </Grid>
              </TabPanel>
            )}
          </Grid>
          <Grid item xs={12} md={12}>
            {user?.role === "Regular" && (
              <TabPanel value={value} index={1}>
                <Grid container direction="row" spacing={!isMobile && 3}>
                  {userEnrolledCourses?.map((item, ind) => {
                    const { enrolledCourses } = item.course;
                    if (Math.ceil(item?.course?.progress) === 100) {
                      return (
                        <Grid
                          item
                          xs={12}
                          md={6}
                          lg={6}
                          key={enrolledCourses?.id}
                          style={{ paddingBottom: '20px' }}
                          onClick={() => handleGoToInprogressCourseEpisode(enrolledCourses)}>


                          <MyCoursesCard
                            title={enrolledCourses?.name}
                            courseBy={enrolledCourses?.user?.name}
                            duration={item?.course.totalDuration}
                            progress={item?.course?.progress}
                            category={enrolledCourses?.category?.name}
                            image={enrolledCourses?.image}

                          />
                        </Grid>
                      );
                    }
                  })}
                </Grid>
              </TabPanel>
            )}
          </Grid>
          <Grid item xs={11} md={12}>
            {(user?.role === "Instructor" || user?.role === "Studio") && (
              <TabPanel value={value} index={0}>
                <Grid
                  container
                  direction="row"
                  spacing={isDesktop ? 3 : 0}
                  style={{ width: "100%" }}
                >
                  {userUploadedCourses?.map((course, ind) => {
                    return (
                      <Grid key={course?.id} item xs={12} md={6} lg={6}>
                        <Link
                          to={`/view-course/${course?.slug}`}
                          style={{ textDecoration: "none" }}
                        >
                          <UploadedCourseCard

                            course={course}

                          />
                        </Link>
                        <Grid
                          container
                          justify="flex-end"
                          spacing={2}
                          style={{ marginBottom: "1rem" }}
                        >
                          {(user?.role !== "Instructor") && <Grid item>
                            <IconButton
                              style={{
                                background:
                                  "linear-gradient(180deg, #FFC000 -13.24%, #F28705 100%)",
                                color: "#fff"
                              }}
                              size="small"
                              onClick={() => setSelectedCourse(course)}
                            >
                              <Delete size="small" />
                            </IconButton>
                          </Grid>}
                          <Grid item>
                            <IconButton
                              style={{
                                background:
                                  "linear-gradient(180deg, #FFC000 -13.24%, #F28705 100%)",
                                color: "#fff"
                              }}
                              onClick={() =>
                                history.push(`/edit-course/${course?.id}`)
                              }
                              size="small"
                            >
                              <Edit size="small" />
                            </IconButton>
                          </Grid>
                        </Grid>
                      </Grid>
                    );
                  })}
                </Grid>
              </TabPanel>
            )}
          </Grid>
          <Grid item xs={11} md={12}>
            {(user?.role === "Instructor" || user?.role === "Studio") && (
              <TabPanel value={value} index={1}>
                <Grid
                  container
                  direction="row"
                  spacing={isDesktop ? 3 : 0}
                  style={{ width: "100%" }}
                >
                  {uploadedTrainingCourses?.map((course, ind) => {
                    return (
                      <Grid key={course?.id} item xs={12} md={6} lg={6}>
                        <Link
                          to={`/retreat-details/${course?.id}`}
                          style={{ textDecoration: "none" }}
                        >
                          <UploadedCourseCard

                            course={course}

                          />
                        </Link>
                        <Grid
                          container
                          justify="flex-end"
                          spacing={2}
                          style={{ marginBottom: "1rem" }}
                        >

                          {(user?.role !== "Instructor") && <Grid item>
                            <IconButton
                              style={{
                                background:
                                  "linear-gradient(180deg, #FFC000 -13.24%, #F28705 100%)",
                                color: "#fff"
                              }}
                              size="small"
                              onClick={() => setSelectedCourse(course)}
                            >
                              <Delete size="small" />
                            </IconButton>
                          </Grid>}
                          <Grid item>
                            <IconButton
                              style={{
                                background:
                                  "linear-gradient(180deg, #FFC000 -13.24%, #F28705 100%)",
                                color: "#fff"
                              }}
                              onClick={() =>
                                history.push(`/edit-retreat/${course?.id}`)
                              }
                              size="small"
                            >
                              <Edit size="small" />
                            </IconButton>
                          </Grid>
                        </Grid>
                      </Grid>
                    );
                  })}
                </Grid>
              </TabPanel>
            )}
          </Grid>

          <Grid item xs={11} md={12}>
            {(user?.role === "Instructor" || user?.role === "Studio") && (
              <TabPanel value={value} index={2}>
                <Grid
                  container
                  direction="row"
                  spacing={isDesktop ? 3 : 0}
                  style={{ width: "100%" }}
                >
                  {uploadedTeacherTrainingList?.map((course, ind) => {
                    return (
                      <Grid key={course?.id} item xs={12} md={6} lg={6}>
                        <Link
                          to={`/teacher-training-details/${course?.id}`}
                          style={{ textDecoration: "none" }}
                        >
                          <UploadedCourseCard

                            course={course}

                          />
                        </Link>
                        <Grid
                          container
                          justify="flex-end"
                          spacing={2}
                          style={{ marginBottom: "1rem" }}
                        >
                         {(user?.role !== "Instructor")&& <Grid item>
                            <IconButton
                              style={{
                                background:
                                  "linear-gradient(180deg, #FFC000 -13.24%, #F28705 100%)",
                                color: "#fff"
                              }}
                              size="small"
                              onClick={() => setSelectedCourse(course)}
                            >
                              <Delete size="small" />
                            </IconButton>
                          </Grid>}
                          <Grid item>
                            <IconButton
                              style={{
                                background:
                                  "linear-gradient(180deg, #FFC000 -13.24%, #F28705 100%)",
                                color: "#fff"
                              }}
                              onClick={() =>
                                history.push(`/edit-teacher-training/${course?.id}`)
                              }
                              size="small"
                            >
                              <Edit size="small" />
                            </IconButton>
                          </Grid>
                        </Grid>
                      </Grid>
                    );
                  })}
                </Grid>
              </TabPanel>
            )}
          </Grid>

        </Grid>
      </Grid>

      <Dialog
        open={Boolean(selectedCourse)}
        onClose={() => setSelectedCourse(null)}
      >
        <DialogContent>
          <Typography component="h4" variant="h6">
            {intl.formatMessage({ id: "areYouSure" })} {selectedCourse?.name}
          </Typography>
        </DialogContent>
        <DialogActions>
          {courseLoader || deleteTrainingCourseLoader ? (
            <CircularProgress size={15} style={{ color: "#F28705" }} />
          ) : (
            <Button onClick={handleDeleteCourse} style={{ color: "#F28705" }}>
              {intl.formatMessage({ id: "yes" })}
            </Button>
          )}
          <Button onClick={() => setSelectedCourse(null)}>
            {intl.formatMessage({ id: "no" })}
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}

export default MyCourses;

const useStyles = makeStyles(theme => ({
  appBar: {
    backgroundColor: "#FFF",
    width: "100%",
    boxShadow: "none",
    display: "flex",
    justifyContent: "space-between"
  },
  tabTitle: {
    fontFamily: theme.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "21px",
    lineHeight: "10px"
  },

  tabPanel: {
    backgroundColor: "white"
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },

  tabLabel: {
    color: "#cacaca",
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "21px",
    textTransform: "none",
    minWidth: "20%"
  },
  tabSelected: {
    color: "#09a9bb",
    textShadow: "0px 2px 4px rgba(5, 85, 94, 0.31)",
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "21px",
    minWidth: "20%",
    textTransform: "none"
  },
  appBarContainer: {
    position: "fixed",
    zIndex: 200,
    backgroundColor: "#FFF"
  },
  appBarMobile: {
    height: "110px",
    width: "100%",
    display: "flex",
    alignItems: "center",
    padding: "0 15px 0 15px "
  },
  modalBtnGrid: {
    padding: "0px",
    alignSelf: "flex-end",
    color: "#fff",
    cursor: "pointer",
    background: "linear-gradient(180deg, #FFC000 -13.24%, #F28705 100%)",
    height: "73px",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    display: "flex"
  },
  paper: {
    position: "absolute",
    height: 330,
    maxWidth: 315,
    minWidth: 300,
    justifyContent: "center",
    backgroundColor: theme.palette.background.paper,
    outline: "none"
  },

  modelBodyText: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "17px",
    lineHeight: "27px",
    textAlign: "center",
    letterSpacing: "0.02em",
    color: "#192247"
  },
  modelBtn: {
    height: "73px",
    width: "100%",
    fontFamily: theme.fontFamily.Roboto,
    cursor: "pointer",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "21px",
    color: theme.colors.white
  },

  msgBody: {
    minHeight: "240px",
    alignItems: "center",
    display: "flex"
  },
  heading: {
    fontFamily: theme.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: 900,
    fontSize: "26px",
    lineHeight: "35px",
    color: "#2E2E2E",
    textAlign: "center"
  },
  select: {
    color: '#FFF'
  }



}));
