import React from 'react';
import { makeStyles } from "@material-ui/styles";
import {
    Grid,
    Button,
    Typography,
    Dialog,
    DialogContent,
    DialogTitle,
  } from "@material-ui/core";
import {useIntl} from 'react-intl'
import {Link} from 'react-router-dom'
import {  CancelRounded } from '@material-ui/icons'

function EpisodePaidDialog(props) {
  const classes = useStyles();
  const intl = useIntl();

    return (
        <div>
            <Dialog open={props?.joinError} onClose={() => props?.joinErrorHandler(false)}  >
                <Grid item style={{ textAlign: 'right' }}>
                    <CancelRounded style={{ color: '#C4C4C4', padding: '5px 5px 0px 0px', cursor: 'pointer' }} onClick={() => props?.joinErrorHandler(false)} />
                </Grid>
                <DialogTitle>
                    <Grid container justify="space-between">
                        <Grid item xs={11}>
                            <Typography className={classes.paymentHeader}>
                                {intl.formatMessage({ id: "paidEpisodesForJoinChallenge" })}
                            </Typography>
                        </Grid>

                    </Grid>
                </DialogTitle>
                <DialogContent style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Grid container >
                        <>
                            <Grid item xs={8}><Typography className={classes.paymentCourseName}>{props?.todayChallengeEpisodeDetail?.episode?.course?.name}</Typography></Grid>
                            <Grid item xs={4} style={{ textAlign: 'right' }}><Link to={`/view-course/${props?.todayChallengeEpisodeDetail?.episode?.courseId}`} style={{ textDecoration: 'none' }}><Button className={classes.paymentBtn}>{intl.formatMessage({ id: "joinNow" })}</Button></Link></Grid>
                            <>
                                <Grid item xs={12}><Typography className={classes.paymentEpisodeName}>{props?.todayChallengeEpisodeDetail?.episode?.name}</Typography></Grid>
                            </>

                        </>
                        {/* {joinErrorData?.length > 0 && joinErrorData?.map((course) => (
              <React.Fragment key={course?.courseId}>
                <Grid item xs={8}><Typography className={classes.paymentCourseName}>{course?.courseName}</Typography></Grid>
                <Grid item xs={4} style={{ textAlign: 'right' }}><Link to={`/view-course/${course?.courseId}`} style={{ textDecoration: 'none' }}><Button className={classes.paymentBtn}>{intl.formatMessage({ id: "joinNow" })}</Button></Link></Grid>
                {
                  course?.courseEpisodes?.length > 0 && course?.courseEpisodes?.map((episode, ind) => (
                    <React.Fragment key={episode?.episodeId}>
                      <Grid item xs={12}><Typography className={classes.paymentEpisodeName}>{ind + 1}) {episode?.episodeName}</Typography></Grid>

                    </React.Fragment>
                  ))
                }
                <Grid item xs={12} style={{ paddingBottom: '10px', paddingTop: '5px' }}><Divider /></Grid>
              </React.Fragment>
            ))} */}
                    </Grid>
                </DialogContent>
            </Dialog>
        </div>
    );
}

export default EpisodePaidDialog;


const useStyles = makeStyles(theme => ({
paymentHeader: {
    color: "#000",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "19px",
   
  },
  paymentCourseName: {
    color: "#000",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "19px",
    
  },
  paymentEpisodeName: {
    color: "#000",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "19px",
  
  },
  paymentBtn: {
    background: 'linear-gradient(360deg, rgb(76, 175, 80) 100%, rgb(229, 186, 191) 0%)',
    height: '30px',
    textDecoration: 'none',
    color: '#FFFFFF',
    fontSize: '14px',
    fontStyle: 'normal',
    fontFamily: 'Reef',
    fontWeight: 500,
    lineHeight: '10px',
    letterSpacing: 0.30
  }

}))