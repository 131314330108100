import React from "react";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core/styles";
import { Clear as ClearIcon, Menu as MenuIcon } from "@material-ui/icons";
import ShareIcon from "../../../assets/shareIcon.png";
import RelateIcon from "../../../assets/Icons/RelateIcon.png";
const useStyles = makeStyles((theme) => ({
  GenralText: {
    fontFamily: "Helvetica Neue",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "18px",
    display: "inline",

    color: "#000000",
  },
  DetailText: {
    fontFamily: "Helvetica Neue",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "18px",
    display: "inline",

    color: "#777777",
  },

  button: {
    width: " 100%",
    height: "100%",
    background: "#FF3366",
    borderRadius: "25px",
    fontFamily: "Helvetica Neue",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "13px",
    lineHeight: "18px",
    color: "#FFFFFF",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#FF3366",
    },
  },
  cancelbutton: {
    width: " 100%",
    height: "100%",
    background: "#FFFFFF",
    borderRadius: "25px",
    fontFamily: "Helvetica Neue",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "13px",
    lineHeight: "18px",
    color: "#000000",
    textTransform: "none",
  },
  buttonDiv: {
    paddingTop: "1rem",
    paddingBottom: "0.5rem",
    height: "50px",
    paddingLeft: "0rem",
    paddingRight: "0rem",
    bottom: 13,
  },
  dialog: {
    width: "300px",
  },
}));

export default function InstallDialog(props) {
  const { open, setOpen } = props;
  const classes = useStyles();

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        style={{ width: "100%", height: "500px", margin: "50px 0px 0px 0px" }}
        fullScreen={false}
      >
        <DialogContent>
          <Grid style={{ textAlign: "end" }}>
            <div onClick={handleClose}>
              <ClearIcon />
            </div>
          </Grid>
          <Grid style={{ textAlign: "center" }}>
            {" "}
            <img src={RelateIcon} width={"100px"} alt=""></img>
          </Grid>

          <Grid style={{ textAlign: "center", marginBottom: "30px" }}>
            <p className={classes.GenralText}>
              To Download the tantraschooloflove.com app to your Home Screen, Please open
              share{" "}
            </p>
            <span>
              <img src={ShareIcon} width={"25px"} height={"25px"} alt=""></img>
            </span>
            <p className={classes.GenralText}>
              {" "}
              and then press 'Add to Home Screen'
            </p>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}
