import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Popper from '@material-ui/core/Popper';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import Add from '@material-ui/icons/Add';
import FiberManualRecord from '@material-ui/icons/FiberManualRecord';
import CheckCircle from '@material-ui/icons/CheckCircle';
import Edit from '@material-ui/icons/Edit';
import Close from '@material-ui/icons/Close';
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Button, Card, CardContent, Divider, Grid, TextField } from '@material-ui/core';
import {updateAssignment} from '../../actions/retreatCourse'
import { useDispatch, useSelector } from "react-redux";
import moment from 'moment';


export default function ScheduleAssignment(props) {
  let dispatch = useDispatch();

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [open, setOpen] = React.useState(false);
    const [loader, setLoader] = React.useState(false);
    const [placement, setPlacement] = React.useState();
    const [publishNow, setPublishNow] = React.useState(true);
    const [timeZone, setTimeZone] = React.useState('Asia/Karachi');
    const [date, setDate] = React.useState(moment().format('YYYY-MM-DD'))
    const [time, setTime] = React.useState('10:00')
    const classes = useStyles();
    let authReducerState = useSelector(state => state.auth);
  let course = useSelector(state => state.trainingCourse?.courseData);

    const timeZones = moment.tz.names().filter(tz => tz.match(/^(((Africa|America|Antarctica|Asia|Australia|Europe|Arctic|Atlantic|Indian|Pacific)\/.+)|(UTC))$/))

    let { file } = props;

    const handleClick = (newPlacement) => (event) => {
        setAnchorEl(event.currentTarget);
        setOpen((prev) => placement !== newPlacement || !prev);
        setPlacement(newPlacement);
    };


    const handlePublishType = (event, type) => {
        event.preventDefault()
        setPublishNow(type);
    };

    const changeHandler = (e, value) => {
        if (e.target.name === 'date') {
            setDate(e.target.value)
        } else if (e.target.name === 'time') {
            console.log('e.target.value',e.target.value, value);
            setTime(e.target.value)
        } else {
            setTimeZone(value)
        }
    }

    const handleCross = () => {
        setOpen(false)
    }


    const updateAssignmentHandler = async () => {
        setLoader(true)
        const res = await dispatch(
            updateAssignment({
                sectionAssignmentId: file?.id,
                name: file?.name,
                timeZone,
                scheduleType: publishNow === 'open' ? 'published' : 'scheduled',
                date,
                time,
                courseId: course?.id,
                token: authReducerState.token,
            })
        );
        if (res?.id) {
            setLoader(false)
            setOpen(false)
        } else {
            setLoader(false)
        }

    };


    useEffect(() => {
        if (props?.file?.scheduleType === 'published') {
            setPublishNow('open')
        } else {
            setPublishNow('schedule')
            setTimeZone(props?.file?.timeZone);
            setDate(moment.utc(props?.file?.dateTimeUtc).local().format('YYYY-MM-DD'))
            setTime(moment.utc(props?.file?.dateTimeUtc).local().format("HH:MM"))
        }
    }, [props?.file?.id])


    return (
        <div className={classes.root}>
            <Popper open={open} anchorEl={anchorEl} placement={'right-start'} transition>
                <Card style={{ maxWidth: '300px' }}>

                    <CardContent>
                        <Grid container >
                            <Grid item xs={12} container justify='flex-end'>
                                <Grid item xs={2} md={1}>
                                    <IconButton className={classes.closeIcon} onClick={handleCross}><Close /></IconButton>
                                </Grid>
                            </Grid>

                            <> <Grid item xs={12} container style={{ height: '50px' }}>

                                <Grid item xs={10} className={classes.centerHorizontal}>
                                    <Typography className={publishNow !== 'open' ? classes.checkedHeading : classes.uncheckedHeading}>
                                        Open
                                </Typography>
                                </Grid>
                                <Grid item xs={2} className={classes.center}>
                                    {
                                        publishNow !== 'open' ? < FiberManualRecord onClick={(e) => handlePublishType(e, 'open')} className={classes.unCheckedIcon} /> : <CheckCircle className={classes.checkedIcon} />
                                    }
                                </Grid>
                            </Grid>

                                <Grid item xs={12}>
                                    <Divider />
                                </Grid></>

                            <Grid item xs={12} container style={{ height: '50px' }}>
                                <Grid item xs={10} className={classes.centerHorizontal}>
                                    <Typography className={publishNow !== 'schedule'  ? classes.checkedHeading : classes.uncheckedHeading}>
                                        Submission
                                </Typography>
                                </Grid>
                                <Grid item xs={2} className={classes.center}>
                                    {
                                       publishNow !== 'schedule' ? < FiberManualRecord onClick={(e) => handlePublishType(e, 'schedule')} className={classes.unCheckedIcon} /> : <CheckCircle className={classes.checkedIcon} />
                                    }
                                </Grid>
                            </Grid>

                            <Grid item xs={12}>
                                <Typography className={classes.checkedHeading} style={{ fontSize: '14px' }}>
                                Select submission date
                                </Typography>
                            </Grid>


                            <Grid item xs={12} >
                                <TextField
                                    id="date"
                                    variant='outlined'
                                    type="date"
                                    value={date}
                                    format='DD-MM-YYYY'
                                    name='date'
                                    InputProps={{ inputProps: { min: moment().format("YYYY-MM-DD"), } }}
                                    disabled={publishNow !== 'schedule'}
                                    onChange={changeHandler}
                                    classes={{ root: classes.dateAndTime }}
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12} >
                                <TextField
                                    id="time"
                                    variant='outlined'
                                    type="time"
                                    value={time}
                                    name='time'
                                    disabled={publishNow !== 'schedule'}
                                    onChange={changeHandler}
                                    defaultValue="07:30"
                                    classes={{ root: classes.dateAndTime }}
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}

                                />


                            </Grid>
                            <Grid item xs={12}>
                                <Autocomplete
                                    id="combo-box-demo"
                                    options={timeZones}
                                    fullWidth
                                    disabled={publishNow !== 'schedule'}
                                    onChange={changeHandler}
                                    name='timeZone'
                                    value={timeZone}
                                    getOptionLabel={(zone) => `(UTC ${moment.tz(zone).format('Z')})  ${zone}`}
                                    renderInput={(params) => <TextField classes={{ root: classes.dateAndTime }} {...params} placeholder="Select time zone" variant="outlined" />}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Button className={classes.publishButton} disabled={(publishNow === 'schedule' && (!date || !time || !timeZone)) || (!publishNow)} onClick={updateAssignmentHandler}>{loader ? <CircularProgress size={20} style={{ color: '#fff' }} /> : 'update'}
                                </Button>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>

            </Popper>



            <Chip
                label={props?.file?.scheduleType === 'published' ? 'submission open' : 'submission scheduled'}
                clickable
                color="primary"
                deleteIcon={<Edit />}
                onClick={handleClick('right-start')}
                className={classes.chipStyle}
                classes={{ colorPrimary: props?.file?.scheduleType === 'published' ? classes.saved : classes.scheduled }}
            />



        </div>
    );
}



const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        display: 'inline'

    },
    chipStyle: {
        height: '20px'
    },
    typography: {
        padding: theme.spacing(2),
    },
    saved: {
        background: '#FBAE02 !important'
    },
    scheduled: {
        background: '#52B202 !important'

    },
    button: {
        background: '#FBAE02 !important',
        boxShadow: '0px 2px 2px rgba(216, 118, 0, 0.18)',
        color: "#FFF",
        height: "40px",
        width: '40px',
    },
    heading: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '20px',
        lineHeight: '100%',
        color: ' #666666',
        paddingBottom: '30px'
    },
    closeIcon: {
        backgroundColor: "#F1F1F4",
        color: '#E54D40',
        height: '25px',
        width: '25px',
        '&:hover': {
            backgroundColor: "#F1F1F4",
            color: '#E54D40',
        }

    },
    browsButtonContainer: {
        background: '#F1F1F1',
        borderRadius: '11px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '80px',
        minWidth: '120px'
    },
    browsButton: {
        color: '#fff',
        background: '#FBAE02',
        borderRadius: '20px',
        height: '30px',
        padding: '0px 50px',
        minWidth: '30px',
        '&:hover': {
            color: '#fff',
            background: '#FBAE02',
        }
    },
    publishButton: {
        color: '#fff',
        background: '#FBAE02',
        borderRadius: '11px',
        width: '100%',
        padding: '12px 0px',
        marginTop: '30px',
        '&:hover': {
            color: '#fff',
            background: '#FBAE02',
        }
    },
    profile_image: {
        "& input[type='file']": {
            display: 'none',
        }
    },
    dateAndTime: {
        marginTop: '10px',
        marginBottom: '10px',
        color: '#FBAE02',
        border: '1px solid #E5E5E5 !important',
        borderRadius: '11px',
        '& label.Mui-focused': {
            color: 'white',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#FBAE02',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#666666',
            },
            '&:hover fieldset': {
                borderColor: '#666666',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#FBAE02',
            },
        },
    },
    checkedHeading: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '25px',
        color: '#C4C4C4',
    },
    uncheckedHeading: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '25px',
        color: '#666666',
    },
    checkedIcon: {
        color: '#2A4A67',
        justifyContent: 'center',
        cursor:'pointer'

    },
    unCheckedIcon: {
        color: '#D7D7D7',
        fontSize: '30px',
        justifyContent: 'center',
        cursor:'pointer'
    },
    center: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    centerHorizontal: {
        display: 'flex',

        alignItems: 'center'
    },
    error: {
        color: '#E54D40',
        fontSize: '12px',
    },
    scheduleText: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '16px',
        lineHeight: '22px',
        color: '#666666',
        paddingTop: '10px'
    }


}));