import React from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";
import RelateIcon from "../../../assets/Icons/RelateIcon.png";
import { Typography } from "@material-ui/core";
import { useSelector } from 'react-redux';

const LetStart = () => {
  const classes = useStyles();
  const intl = useIntl();
  const configs = useSelector(state => state?.setting?.siteConfig);
  return (
    <React.Fragment>
      <div style={{ backgroundImage: `url(${configs?.landingBackground})` }} className={classes.root} >
        <Grid container>
          <Grid item container xs={12}>
            <Grid item xs={3} />
            <Grid item xs={6} className={classes.centerContainer}>
              <img src={RelateIcon} alt="logo" style={{ marginTop: "30px", width: "150px", height: "150px" }} />
              <Typography className={classes.heading}>
                {configs?.landingTitle}
                {/* {intl.formatMessage({ id: "letsStartHeading" })} */}
              </Typography>
              <Typography className={classes.subHeading}>
                {configs?.landingBaseline}

                {/* {intl.formatMessage({ id: "letsStartSubHeading" })} */}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <div className={classes.topRightCircle}></div>
            </Grid>
          </Grid>
          <Grid item container xs={12}>
            <Grid item xs={3} />
            <Grid item xs={6} className={classes.centerContainer}>
              <Grid container justify="center">
                <Grid item>
                  <Link
                    to="/choose-identity"
                    style={{ textDecoration: "none" }}
                  >
                    <Button
                      variant="contained"
                      className={classes.letsStartBtn}
                    >
                      {intl.formatMessage({ id: "letsStart" })}
                    </Button>
                  </Link>
                </Grid>
              </Grid>
              <Grid container justify="center">
                <Grid item>
                  <Link to="/auth" style={{ textDecoration: "none" }}>
                    <Button
                      variant="contained"
                      className={classes.letsStartBtn}
                      style={{
                        marginTop: "48px"
                      }}
                    >
                      {intl.formatMessage({ id: "sign_in" })}
                    </Button>
                  </Link>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={3} />
          </Grid>
          <Grid item xs={12}>
            <Grid item xs={1}>
              <div className={classes.bottomLeftCircle}></div>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    background: `#C4C4C4`,
    minHeight: "100vh",
    height: "100%",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat"
  },
  topRightCircle: {
    width: "150px",
    height: "150px",
    borderRadius: "2% 0px 2% 100%",
    float: "right",
    backgroundColor: "#09A9BB",
    opacity: "0.5"
  },
  bottomLeftCircle: {
    width: "50px",
    height: "50px",
    borderRadius: "50%",
    float: "right",
    backgroundColor: "#14D9D9",
    opacity: "0.5"
  },
  centerContainer: {
    textAlign: "center",
    margin: "auto"
  },
  heading: {
    fontFamily: theme.fontFamily.Reef,
    marginTop: "50px",
    fontStyle: "normal",
    fontWeight: "900",
    fontSize: "48px",
    lineHeight: "65px",
    textAlign: "center",
    letterSpacing: " 0.02em",
    color: "#FFFFFF"
  },
  subHeading: {
    fontFamily: theme.fontFamily.Reef,
    marginTop: "30px",
    fontStyle: "normal",
    fontWeight: "900",
    fontSize: "36px",
    lineHeight: "49px",
    textAlign: "center",
    letterSpacing: " 0.02em",
    color: "#FFFFFF"
  },
  letsStartBtn: {
    textTransform: "none",
    width: "355px",
    height: " 67px",
    background: "none",
    border: "2px solid #FFFFFF",
    borderRadius: "59px",
    fontFamily: theme.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: 900,
    fontSize: "24px",
    lineHeight: "33px",
    letterSpacing: " 0.09em",
    color: "#FFFFFF",
    marginTop: "65px",
    "&:hover": {
      background: "#09A9BB",
      border: "none"
    }
  }
}));

export default LetStart;
