import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Grid,
  Avatar,
  TextField,
  Typography,
  Box,
  CircularProgress,
  Button,
} from "@material-ui/core";
import {
  Search as SearchIcon
} from "@material-ui/icons";
import { makeStyles, useTheme, withStyles } from "@material-ui/styles";

import { useSelector, useDispatch } from "react-redux";
import { useIntl } from "react-intl";
import FriendInvitationCard from "./FriendInvitationCard";
import { useHistory } from "react-router-dom";
import { addParticipants } from "../../../actions/challenges";
import {
  searchUsers,
} from "../../../actions/userRelations";

function Invite(props) {
  const classes = useStyles();
  const intl = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();
  const theme = useTheme();
  const [friendsArr, setFriendsArr] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
 
  const loader = useSelector((state) => state?.loader?.loader);
  const challengeParticipantsReducer = useSelector(
    (state) => state?.challenges?.challengeParticipants
  );
  const searchedFriends = useSelector(
    (state) => state?.userRelations?.searchUsers
  );
  const authReducer = useSelector((state) => state?.auth);
  const { handleAddFriendMode } = props;

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box>
            <>{children}</>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };

  useEffect(() => {
    dispatch({
      type: 'SEARCH_USERS',
      payload: []})
  },[])

  useEffect(() => {
    if (challengeParticipantsReducer.length > 0) {
      

      let searched = searchedFriends.filter(function (friend) {
        return !challengeParticipantsReducer.some(function (participant) {
          return friend?.id === participant?.id; 
        });
      });

      let selected = challengeParticipantsReducer.filter(function (friend) {
        return searchedFriends.some(function (participant) {
          return friend?.id === participant?.id; 
        });
      });

     
      setFriendsArr([...searched, ...selected]);
      
    } else {
      setFriendsArr([...searchedFriends]);
    }
  }, [searchedFriends]);

  useEffect(
    () => {
      if(searchTerm === ''){dispatch({ type: 'LOADER', payload: false })}
      const timeout = setTimeout(() => {
        searchTerm !== '' && dispatch(searchUsers({ token: authReducer?.token, searchTerm }));
      }, 2000);

      return () => clearTimeout(timeout);
    },
    [searchTerm]
  );

  const invitationHandler = (status, participant) => {
    if (status === "sent") {
      let filterFriends = friendsArr?.map((friend) => {
        if (friend?.id === participant?.id) {
          let localFriend = {
            ...friend,
            status: "sent",
          }
          dispatch(addParticipants([...challengeParticipantsReducer,localFriend]));
          return localFriend
        }
        return friend;
      });
     
      setFriendsArr([...filterFriends]);
   
    }
    if (status === "cancel") {
      let filterFriends = friendsArr?.map((friend) => {
        if (friend?.id === participant?.id) {
          return {
            ...friend,
            status: null,
          };
        }
        return friend;
      });

    
      let filterReducerFriends = challengeParticipantsReducer?.filter((friend) => (friend?.id !== participant?.id))
      dispatch(addParticipants([...filterReducerFriends]));
      setFriendsArr([...filterFriends]);
    }
  };

  const handleAddParticipants = () => {
   
    return handleAddFriendMode();
  };


  const handleCancel = () => {
    history.push("/more");
  };

  

  const handleSearch = (e) => {
       dispatch(searchUsers({ token: authReducer?.token, searchTerm }));
  }

  return (
    <Grid container style={{ padding: 0 }}>
      
      <Grid item xs={12}>
        <TextField
          className={classes.homeSearchField}
          type="Search"
          style={{ width: "100%" }}
          placeholder="Search..."
          onChange={(e)=>{
            dispatch({type:'LOADER',payload:true});
            setSearchTerm(e.target.value)}}
          onKeyPress={(e) => {
            (e.which === 13 || e.keyCode === 13) && handleSearch();
          }}
          name="search"
          InputProps={{
            endAdornment: (
              <SearchIcon onClick={handleSearch} style={{cursor:'pointer'}}/>
            ),
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Grid container>
          <Grid
            item
            xs={12}
            container
            style={{
              width: "100vw",
            }}
            spacing={1}
          >
            <Grid item xs={12}>
              <Typography className={classes.addFriend}>
                Invite Peoples
                </Typography>
            </Grid>
            
            <Grid
              item
              xs={12}
              container
              style={{minHeight:"300px"}}
            >
              {loader ? <Grid item xs={12} style={{textAlign:'center',marginTop:'20px'}}><CircularProgress style={{color:'#F28705'}}  size={20}/></Grid> : friendsArr.length > 0 ?
                friendsArr?.map((friend) => {
                  return (
                    <Grid item xs={12}>
                      <FriendInvitationCard
                        friend={friend}
                        invitation={invitationHandler}
                      />
                    </Grid>
                  );
                }): 
                (
                  <Grid item xs={12}>
                    <Typography className={classes.noRecord}>
                      No Record Found
                  </Typography>
                  </Grid>
                )
                }
            </Grid>

            <Grid item xs={12}>
              <Button
                className={classes.nextBtn}
                onClick={handleAddParticipants}
              >
                {props.screen === "live"
                  ? intl.formatMessage({ id: "goLive" })
                  : intl.formatMessage({ id: "next" })}
              </Button>
            </Grid>
          </Grid>
        </Grid>
       
      </Grid>
    </Grid>
  );
}

export default Invite;

const useStyles = makeStyles((theme) => ({
  sideContainer: {
    background: "#F2F2F2",
    boxShadow: "inset 2px 2px 4px rgba(0, 65, 72, 0.05)",
    borderRadius: "0px 12px 12px 0px",
   
  },
  cancelBtn: {
    width: "100%",
    height: "2.750em",
    borderRadius: "11px",
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: 500,
    textTransform: "none",
    fontSize: "20px",
    lineHeight: "23px",
    color: "#D95959",
    marginBottom: "10px",
  },
  nextBtn: {
    width: "100%",
    height: "62px",
    background: "linear-gradient(180deg, #FFC000 -13.24%, #F28705 100%)",
    borderRadius: "11px",
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "20px",
    lineHeight: "23px",
    textTransform: "none",
    color: "#FFFFFF",
  },
  addedFriendCard: {
    background: "#F2F2F2",
    boxShadow: "inset 2px 2px 4px rgba(0, 65, 72, 0.05)",
    borderRadius: "12px",
    paddingTop: "10px",
    marginTop: "10px",
    borderTopLeftRadius: "0px",
    borderBottomLeftRadius: "0px",
  },
  containerStyle: {
    marginBottom: "5px",
  },
  CancleBtnPosition: {
    width: "100vw",
    position: "fixed",
    bottom: "0px",
    boxShadow: "0px -12px 12px rgba(0, 0, 0, 0.03)",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    zIndex: 100,
  },
  NextBtnPosition: {
    width: "100vw",
    position: "fixed",
    bottom: "60px",
    boxShadow: "0px -12px 12px rgba(0, 0, 0, 0.03)",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    zIndex: 100,
  },
  subTitle: {
    fontFamily: "Roboto",
    fontStyle: "italic",
    fontWeight: "bold",
    fontSize: "8px",
    lineHeight: "9px",
    color: "#3C4A6B",
    textAlign: "center",
  },
  appBar: {
    backgroundColor: "#FFF",
    width: "100%",
    boxShadow: "none",
    display: "flex",
    justifyContent: "space-between",
  },
  noRecord: {
    color: "#DFDFDF",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "19px",
    textAlign: "center",
    marginTop: "30px",
    marginBottom: "30px",
  },
  headerGrid: {
    background: "#FBF1E0",
    height: "2.75em",
    fontSize: "20px",
    boxShadow: `inset 2px 2px 4px rgba(0, 65, 72, 0.05)`,
    borderRadius: `12px`,
  },
  selectedBtn: {
    background: "linear-gradient(180deg, #FFC000 -13.24%, #F28705 100%)",
    width: "100%",
    height: "2.750em",
    borderRadius: "11px",
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: 500,
    textTransform: "none",
    fontSize: "20px",
    lineHeight: "23px",
    color: "#fff",
    marginBottom: "70px",
  },
  unselectedBtn: {
    width: "100%",
    height: "2.750em",
    borderRadius: "11px",
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: 500,
    textTransform: "none",
    fontSize: "20px",
    lineHeight: "23px",
    color: "#F8A303",
    marginBottom: "70px",
  },
  tabLabel: {
    color: "#DFDFDF",
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "19px",
    minWidth: "27%",
    textTransform: "none",
  },
  tabSelected: {
    color: "#F28705",
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "19px",
  },
  indicatorStyling: {
    backgroundColor: "#F28705",
    height: "5px",
    maxWidth: "60px",
  },
  indicatorStylingMobile: {
    backgroundColor: "#F28705",
    height: "5px",
  },
  tabsContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  addFriend: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "20px",
    lineHeight: "23px",
    color: "#3C4A6B",
    paddingBottom: "20px",
    marginTop: "20px",
    borderBottom: "1px solid rgba(168, 182, 200, 0.3)",
  },
  homeSearchField: {
    "& .MuiInputBase-root": {
      backgroundColor: '#dce8f2',
      color: '#A9A9A9',
      fontFamily: theme.fontFamily.Roboto,
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "18px",
      lineHeight: "21px",
      padding: "10px",
      minHeight: "64px",
      alignSelf: "center",
      marginTop: "12px",
      marginBottom: "20px",
      height: "58px",
      width: "100%",
      borderRadius: "10px",
    },
    "& .MuiBadge-colorPrimary": {
      backgroundColor: "#F9A702;",
    },

    "& .MuiInput-underline:before": {
      border: "0px solid #FBF1E0",
    },
    "& .MuiInput-underline::after": {
      border: "0px solid #FBF1E0",
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled)::before": {
      border: "0px solid #FBF1E0",
    },
  },
}));
