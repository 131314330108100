import React, { useState, useEffect } from "react";
import {
  Grid,
  TextField,
  useMediaQuery,
  Button,
  Typography,
  CircularProgress
} from "@material-ui/core";
import {
  Search as SearchIcon
} from "@material-ui/icons";
import { useSelector, useDispatch } from "react-redux";
import { useIntl } from "react-intl";
import { makeStyles, useTheme } from "@material-ui/styles";
import FriendInvitationCard from "./FriendInvitationCard";
import { addParticipants,getChallengeDetail } from "../../../actions/challenges";
import { getSessionDetails } from "../../../actions/Session";
import {
  searchUsers,
} from "../../../actions/userRelations";
import { useParams } from "react-router-dom";

function InviteFriends(props) {
  const intl = useIntl();
  const dispatch = useDispatch();
  const theme = useTheme();
  const classes = useStyles();
  const params = useParams();
  const [friendsArr, setFriendsArr] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  let loader = useSelector((state) => state?.loader?.loader);
  const challengeParticipantsReducer = useSelector(
    (state) => state?.challenges?.challengeParticipants
  );
  const authReducer = useSelector((state) => state?.auth);
  const searchedFriends = useSelector(
    (state) => state?.userRelations?.searchUsers
  );

  useEffect(() => {
    dispatch({
      type: 'SEARCH_USERS',
      payload: []
    })
    if(props?.invitationType === 'challenge')
    {dispatch(
      getChallengeDetail({
        token: authReducer?.token,
        challengeId: params?.id,
      })
    );}
     if(props?.invitationType === 'session'){
      dispatch(
        getSessionDetails({
          token: authReducer?.token,
          id: params?.id,
        })
      )
    }

    return ()=>{
      dispatch({
        type: 'ADD_PARTICIPANTS_IN_CHALLENGE',
        payload: [],
      });
    }
  }, [])

  useEffect(() => {
    if (challengeParticipantsReducer.length > 0) {

      let searched = searchedFriends.filter(function (friend) {

        return !challengeParticipantsReducer.some(function (participant) {
          return friend?.id === participant?.id; 
        });
      });
      let selected = challengeParticipantsReducer.filter(function (friend) {
        return searchedFriends.some(function (participant) {
          return friend?.id === participant?.id; 
        });
      });
      
      setFriendsArr([...searched, ...selected]);
    } else {
      setFriendsArr([...searchedFriends]);
    }
  }, [searchedFriends]);

  useEffect(
    () => {
      if (searchTerm === '') { dispatch({ type: 'LOADER', payload: false }) }
      const timeout = setTimeout(() => {
        searchTerm !== '' && dispatch(searchUsers({ token: authReducer?.token, searchTerm }));
      }, 2000);

      return () => clearTimeout(timeout);
    },
    [searchTerm]
  );



  const invitationHandler = (status, participant) => {
    if (status === "sent") {
      let filterFriends = friendsArr?.map((friend) => {
        if (friend?.id === participant?.id) {
          let localFriend = {
            ...friend,
            status: "sent",
          }
          dispatch(addParticipants([...challengeParticipantsReducer, localFriend]));
          return localFriend
        }
        return friend;
      });
      setFriendsArr([...filterFriends]);
    }
    if (status === "cancel") {
      let filterFriends = friendsArr?.map((friend) => {
        if (friend?.id === participant?.id) {
          return {
            ...friend,
            status: null,
          };
        }
        return friend;
      });

      let filterReducerFriends = challengeParticipantsReducer?.filter((friend) => (friend?.id !== participant?.id))
      dispatch(addParticipants([...filterReducerFriends]));
      setFriendsArr([...filterFriends]);
    }
  };





  const handleSearch = () => {

    dispatch(searchUsers({ token: authReducer?.token, searchTerm }));
  };

  return (
    <Grid container >


      <Grid item xs={12}>
        <Typography className={classes.header}>Invite Peoples</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography style={{color:'#09A9BB'}}>Search for specific people click on SEARCH {<SearchIcon/>} to populate all available users</Typography>
      </Grid>
      <Grid item xs={12}>
        <TextField
          className={classes.homeSearchField}
          type="Search"
          style={{ width: "100%" }}
          placeholder="Search..."
          onChange={(e) => {
            dispatch({ type: 'LOADER', payload: true });
            setSearchTerm(e.target.value)
          }}
          name="search"
          onKeyPress={(e) => {
            (e.which === 13 || e.keyCode === 13) && handleSearch();
          }}
          InputProps={{
            endAdornment: (
              <SearchIcon onClick={handleSearch} style={{ cursor: 'pointer' }} />
            ),
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Grid container >
          <Grid container item xs={12} style={{ minHeight: '200px' }}>
            {loader ? <Grid item xs={12} style={{ textAlign: 'center' }} ><CircularProgress style={{ color: '#F28705' }} size={20} /></Grid> : friendsArr.length > 0 ? (
              friendsArr?.map((friend) => {
                return (
                  <Grid item xs={12} key={friend?.id} >
                    <FriendInvitationCard
                      invitation={invitationHandler}
                      friend={friend}
                    />
                  </Grid>
                );
              })
            ) : (
                <Grid item xs={12}>
                  <Typography className={classes.noRecord}>
                    No Record Found
              </Typography>
                </Grid>
              )}
          </Grid>

        </Grid>
      </Grid>
    </Grid>
  );
}

export default InviteFriends;

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: "#fbfbfb",
    boxShadow: "none",
  },
  tabLabel: {
    color: "#DFDFDF",
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "21px",
    minWidth: "20%",
    textTransform: "none",
    padding: 0,
  },
  tabSelected: {
    color: "#09A9BB",
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "21px",
  },
  indicatorStyling: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "#09A9BB",
    height: "3px",
    "& > span": {
      maxWidth: 20,
      width: "100%",
      backgroundColor: "#09A9BB",
    },
  },
  indicatorStylingMobile: {
    backgroundColor: "#09A9BB",
    height: "3px",
    borderRadius: "11px",
  },
  tabsContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  noRecord: {
    color: "#DFDFDF",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "19px",
    textAlign: "center",
    marginTop: "30px",
    marginBottom: "30px",
  },
  completeBtn: {
    width: "100%",
    height: "3.0em",
    background: "linear-gradient(180deg, #FFC000 -13.24%, #F28705 100%)",
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "20px",
    lineHeight: "23px",
    color: "#FFFFFF",
    marginBottom: "30px",
    borderRadius: "11px",
    textTransform: "none",
    marginTop: "30px",
  },
  header: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "20px",
    lineHeight: "23px",
    color: "#3C4A6B",

  },
  homeSearchField: {
    "& .MuiInputBase-root": {
      backgroundColor: '#dce8f2',
      color: '#A9A9A9',
      fontFamily: theme.fontFamily.Roboto,
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "18px",
      lineHeight: "21px",
      padding: "10px",
      minHeight: "64px",
      alignSelf: "center",
      marginTop: "12px",
      marginBottom: "20px",
      height: "58px",
      width: "100%",
      borderRadius: "10px",
    },
    "& .MuiBadge-colorPrimary": {
      backgroundColor: "#F9A702;",
    },

    "& .MuiInput-underline:before": {
      border: "0px solid #FBF1E0",
    },
    "& .MuiInput-underline::after": {
      border: "0px solid #FBF1E0",
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled)::before": {
      border: "0px solid #FBF1E0",
    },
  },
}));


