import React, { useEffect, useState } from "react";
import moment from "moment";
import CourseCard from "../Common/CourseCard";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {
  useMediaQuery,
  Badge,
  CircularProgress,
  Card,
  CardContent,
  Chip,
  IconButton,
  Button,
} from "@material-ui/core";
import { Add, Edit } from "@material-ui/icons";
import CategoriesFilter from "../../components/Common/CategoriesFilters";
import headerMoreIcon from "../../assets/Icons/headerMoreIcon.svg";
import BellIcon from "../../assets/Icons/bellIcon.svg";
import BellIconNotification from "../../assets/Icons/bellIconNotifications.svg";

import MyCoursesCard from "../Profile/MyCourses/MyCoursesCard";
import Quote from "./Quote";
import preloadImage from "./Quote";
import {
  getLatestCategories,
  getNewCourses,
  getRecentCourses,
  getQuoteOfTheDay,
  getUserEnrolledCourses,
  getUserUploadedCourses,
} from "../../actions/courses";
import { getAllJoinedAndCreatedSessions } from "../../actions/Session";
import { getUnseenNotificationsCount } from "../../actions/notifications";
import {
  createChallengeData,
  addParticipants,
  getAllChallenges,
} from "../../actions/challenges";
import {
  getAllCreatedTrainingCourses,
  getEnrolledRetreats,
} from "../../actions/retreatCourse";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import logo from "../../assets/Icons/RelateIcon.png";
import { useIntl } from "react-intl";
import AllCoursesSessionsChallengesTeachers from "./AllCoursesSessionChallengesInstructor";
import CategoryCourses from "./categoryCourses";
import ExpireSubscriptionPopup from "../Common/ExpireSubscriptionPopup";
import SubscribeNowBtn from "../Common/SubscribeNowBtn";
import "./home.scss";

const Home = () => {
  const classes = useStyles();
  let dispatch = useDispatch();
  const theme = useTheme();
  const history = useHistory();
  const intl = useIntl();

  const [loader, setLoader] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [trainingCourseLoader, setTrainingCourseLoader] = useState(false);
  const [uploadedTeacherTrainingLoader, setUploadedTeacherTrainingLoader] =
    useState(false);
  const [enrolledRetreatsLoader, setEnrolledRetreatsLoader] = useState(false);
  const [subscriptionExpire, setSubscriptionExpire] = useState(false);
  const [enrolledTeacherTrainingLoader, setEnrolledTeacherTrainingLoader] =
    useState(false);
  const authReducerState = useSelector((state) => state.auth);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  let reducerUser = useSelector((state) => state?.user?.user);

  const allChallenges = useSelector(
    (state) => state?.challenges?.allChallenges
  );

  const noOfUnseenNotifications = useSelector(
    (state) => state?.notifications?.noOfUnseenNotifications
  );

  let uploadedTrainingCoursesList = useSelector(
    (state) => state?.retreatCourse?.allUploadedTrainingCourses
  );
  let enrolledTrainingCoursesList = useSelector(
    (state) => state?.retreatCourse?.enrolledRetreats
  );
  let uploadedTeacherTrainingList = useSelector(
    (state) => state?.retreatCourse?.allUploadedTeacherTrainings
  );
  let enrolledTeacherTrainingList = useSelector(
    (state) => state?.retreatCourse?.enrolledTeacherTrainings
  );

  const userEnrolledCourses = useSelector(
    (state) => state?.courses?.userEnrolledCoursesList
  );

  const allSessionsList = useSelector(
    (state) => state?.session?.allCreatedAndJoinedSessionsList
  );

  const userUploadedCourses = useSelector(
    (state) => state?.courses?.userUploadedCourses
  );
  useEffect(() => {
    dispatch(getQuoteOfTheDay())
      .then((res) => {
        preloadImage(res.authorPicture);
        console.log(res, "quote api response");
      })
      .catch((err) => {
        console.log("error");
      });
    dispatch(getLatestCategories());
    dispatch(getUnseenNotificationsCount({ token: authReducerState?.token }));
  }, []);

  const handleGoToInprogressCourseEpisode = (course) => {
    if (
      reducerUser?.isExpiredSubscription &&
      course?.plan?.name === "Subscription" &&
      course?.user?.id !== reducerUser?.id
    ) {
      return setSubscriptionExpire(true);
    }
    if (!course?.isPaid && course?.plan?.name === "Freemium") {
      let inProgress = "";
      let notStarted = "";
      const freeEpisodes =
        course?.episodes?.length > 0 &&
        course?.episodes?.filter((item) => {
          inProgress =
            !inProgress &&
              item?.isFree &&
              item?.watchStatus === "Started" &&
              item?.multimediaUrl
              ? item?.id
              : "";
          notStarted =
            !notStarted &&
              item?.isFree &&
              item?.watchStatus === "Not Started" &&
              item?.multimediaUrl
              ? item?.id
              : "";
          return item?.isFree && item?.multimediaUrl;
        });

      if (inProgress) {
        return history.push(`/play-course/${course?.id}/${inProgress}`);
      } else if (notStarted) {
        return history.push(`/play-course/${course?.id}/${notStarted}`);
      } else if (freeEpisodes?.length > 0) {
        return history.push(`/play-course/${course?.id}/${freeEpisodes[0].id}`);
      } else {
        return history.push(`/view-course/${course?.slug}`);
      }
    } else {
      const inProgressEpisode =
        course?.episodes?.length > 0 &&
        course?.episodes?.find(
          (item) => item?.watchStatus === "Started" && item?.multimediaUrl
        );

      const notStarted =
        course?.episodes?.length > 0 &&
        course?.episodes?.find(
          (item) => item?.watchStatus === "Not Started" && item?.multimediaUrl
        );

      const multimediaEpisode =
        course?.episodes?.length > 0 &&
        course?.episodes?.find((item) => item?.multimediaUrl);

      if (inProgressEpisode) {
        return history.push(
          `/play-course/${course?.id}/${inProgressEpisode?.id}`
        );
      } else if (notStarted) {
        return history.push(`/play-course/${course?.id}/${notStarted?.id}`);
      } else if (multimediaEpisode) {
        return history.push(
          `/play-course/${course?.id}/${multimediaEpisode?.id}`
        );
      }
    }
  };

  useEffect(() => {
    if (selectedCategory === "my") {
      getData();
    }
  }, [selectedCategory]);

  const getData = async () => {
    if (authReducerState?.user?.role !== "Regular") {
      dispatch(getUserUploadedCourses(authReducerState?.token));
      // dispatch(getNewCourses());
      setTrainingCourseLoader(true);
      setUploadedTeacherTrainingLoader(true);

      const trainingCourseRes = await dispatch(
        getAllCreatedTrainingCourses({
          token: authReducerState?.token,
          retreatType: "retreat",
        })
      );
      if (trainingCourseRes) {
        setTrainingCourseLoader(false);
      }

      const teacherTrainingRes = await dispatch(
        getAllCreatedTrainingCourses({
          token: authReducerState?.token,
          retreatType: "teacherTraining",
        })
      );
      if (teacherTrainingRes) {
        setUploadedTeacherTrainingLoader(false);
      }
    }

    // dispatch((getRecentCourses()));
    dispatch(getUserEnrolledCourses(authReducerState?.token));

    dispatch(getAllChallenges(authReducerState?.token));
    dispatch(
      getAllJoinedAndCreatedSessions({ token: authReducerState?.token })
    );
    setEnrolledRetreatsLoader(true);
    setEnrolledTeacherTrainingLoader(true);

    const enrolledRetreatsRes = await dispatch(
      getEnrolledRetreats({
        token: authReducerState?.token,
        retreatType: "retreat",
      })
    );
    if (enrolledRetreatsRes) {
      setEnrolledRetreatsLoader(false);
    }

    const enrolledTeacherTrainingRes = await dispatch(
      getEnrolledRetreats({
        token: authReducerState?.token,
        retreatType: "teacherTraining",
      })
    );
    if (enrolledTeacherTrainingRes) {
      setEnrolledTeacherTrainingLoader(false);
    }
  };

  const handleChangePage = (link) => {
    if (link === "Session") {
      history.push("/more/session/create");
    } else if (link === "Challenge") {
      dispatch(createChallengeData({}));
      dispatch(addParticipants([]));
      return history.push("/more/create-challenge");
    }
  };

  const getFormattedTime = (value) => {
    let hours =
      parseInt((value * 15) / 60) > 12
        ? parseInt((value * 15) / 60) - 12
        : parseInt((value * 15) / 60);
    let min = ((value * 15) / 60 - parseInt((value * 15) / 60)) * 60;
    let ampm = parseInt((value * 15) / 60) > 12 ? "PM" : "AM";
    if (hours === 0) {
      hours = 12;
    }

    return `${hours}: ${min} ${ampm}`;
  };

  const handleSelectCategory = (id) => {
    if (id === "") {
      setSelectedCategory(id);
    } else {
      setSelectedCategory(id);
    }
  };

  const currentSessionStatus = (session) => {
    if (session?.status === "Active" && session?.sessionDates?.length > 0) {
      if (
        moment().isBefore(
          moment(session?.sessionDates[0]?.dateTime, "x").local()
        )
      ) {
        return "Scheduled";
      } else if (
        moment().isAfter(
          moment(session?.sessionDates[0]?.endingDateTime, "x").local()
        )
      ) {
        return "Delivered";
      } else {
        return "Live";
      }
    } else {
      return session?.status;
    }
  };

  return (
    <>
      <Grid container direction="row">
        <Grid item xs={12}>
          <Quote />
        </Grid>
        <Grid item xs={12}>
          <ExpireSubscriptionPopup
            subscriptionExpire={subscriptionExpire}
            setPopUp={() => setSubscriptionExpire(false)}
          />
        </Grid>
        {isMobile && !isDesktop && (
          <>
            <Grid
              item
              container
              xs={12}
              className={classes.mobileHeaderContainer}
            >
              <Grid item xs={2} style={{ textAlign: "left" }}>
                <img
                  src={headerMoreIcon}
                  onClick={() => history.push("/more")}
                />
              </Grid>
              <Grid item xs={8}>
                <img src={logo} alt="logo" style={{ width: "101px" }} />
              </Grid>

              <Grid item xs={2} style={{ textAlign: "right" }}>
                <Grid item xs={1} />
                <Link to="/notification">
                  {noOfUnseenNotifications === 0 ? (
                    <img src={BellIcon} />
                  ) : (
                    <Badge
                      color="primary"
                      badgeContent={noOfUnseenNotifications || 0}
                      classes={{ colorPrimary: classes.mobileBadge }}
                    >
                      <img src={BellIconNotification} />
                    </Badge>
                  )}
                </Link>
              </Grid>
            </Grid>
          </>
        )}
      </Grid>

      <Grid
        container
        style={{
          padding: "0px 15px 0px 15px",
          background: isMobile && "#F8F8F8",
          maxHeight: "100vh",
        }}
      >
        <Grid
          item
          xs={12}
          sm={12}
          md={selectedCategory === "my" ? 8 : 12}
          lg={selectedCategory === "my" ? 8 : 12}
          container
          style={{
            padding:
              isMobile &&
                !(
                  userEnrolledCourses &&
                  userEnrolledCourses.length > 0 &&
                  selectedCategory === "my"
                )
                ? "0px 0px 70px 0px"
                : isMobile
                  ? "0px"
                  : "0px 25px 0px 30px",
          }}
        >
          {!isMobile && (
            <Grid
              item
              container
              xs={12}
              justify="space-between"
              style={{
                marginBottom: "15px",
                marginTop: isMobile ? "75px" : "30px",
              }}
            >
              <Grid item xs={12} md={3} lg={2} xl={2}>
                <Typography
                  style={{
                    fontFamily: theme.fontFamily.Reef,
                    fontStyle: "normal",
                    fontWeight: 900,
                    fontSize: "26px",

                    color: "#343434",
                  }}
                >
                  Dashboard
                </Typography>
              </Grid>
              <Grid item xs={12} md={4} lg={4} xl={4}>
                {isMobile && <SubscribeNowBtn />}
                {isDesktop && <SubscribeNowBtn />}
              </Grid>
            </Grid>
          )}

          <Grid
            container
            item
            xs={12}
            style={{ paddingBottom: "25px", paddingTop: isMobile && "80px" }}
          >
            <Grid item xs={12} sm={12} md={12}>
              <CategoriesFilter
                handleSelectedCategory={handleSelectCategory}
              />
            </Grid>
          </Grid>

          {selectedCategory === "my" ? (
            <Grid
              item
              xs={12}
              container
              className={!isMobile && classes.scroll}
            >
              {authReducerState?.user?.role !== "Regular" && (
                <Grid
                  container
                  direction="row"
                  style={{ paddingBottom: "25px" }}
                >
                  <Grid container item xs={12}>
                    <Grid item xs={6}>
                      <Typography
                        className={classes.heading}
                        style={{ marginTop: "12px" }}
                      >
                        <>
                          My Courses{" "}
                          <IconButton
                            onClick={() => history.push("/add-course")}
                            className={classes.button}
                          >
                            <Add />
                          </IconButton>
                        </>
                      </Typography>
                    </Grid>
                    {isMobile && (
                      <Grid
                        item
                        xs={6}
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        {/*<Button*/}
                        {/*  className={classes.subscribeBtn}*/}
                        {/*  // style={{color: "blue", fontWeight : '700'}}*/}
                        {/*  //  disabled={!courseDetail?.id }*/}

                        {/*  onClick={() => history.push("/more/subscription-plan")}*/}
                        {/*>*/}
                        {/*  <Typography>*/}
                        {/*    {reducerUser?.purchasedSubscription &&*/}
                        {/*      reducerUser?.purchasedSubscription[0]?.SubscriptionPlan?.name ===*/}
                        {/*      "Free"*/}
                        {/*      ? "SUBSCRIBE"*/}
                        {/*      : "UPGRADE"}*/}
                        {/*  </Typography>*/}
                        {/*</Button>*/}
                      </Grid>
                    )}
                  </Grid>

                  <Grid item xs={12} container>
                    <Grid
                      item
                      xs={12}
                      container
                      direction="row"
                      spacing={2}
                      className={
                        isMobile && !isDesktop
                          ? classes.hideScrollBar
                          : classes.scrollBar
                      }
                    >
                      {loader ? (
                        <Grid container justify="center">
                          <CircularProgress
                            color="primary"
                            style={{ color: "#09A9BB" }}
                            size={20}
                          />
                        </Grid>
                      ) : (
                        authReducerState?.user?.role !== "Regular" &&
                        (userUploadedCourses &&
                          userUploadedCourses.length > 0 ? (
                          userUploadedCourses.map((course) => {
                            return (
                              <Grid
                                item
                                xs={12}
                                sm={12}
                                md={4}
                                style={{
                                  minWidth: "250px",
                                  marginTop: "-25px",
                                }}
                                key={course?.id}
                              >
                                <Grid
                                  style={{
                                    position: "relative",
                                    top: "34px",
                                    width: "230px",
                                  }}
                                  container
                                  justify="space-between"
                                >
                                  <Badge
                                    classes={{
                                      badge:
                                        course?.status === "Active"
                                          ? classes.activeCourse
                                          : course?.status === "Incomplete"
                                            ? classes.incompleteCourse
                                            : course?.status === "Blocked"
                                              ? classes.blockedCourse
                                              : course?.status === "InActive"
                                                ? classes.inactiveCourse
                                                : "",
                                    }}
                                    style={{
                                      marginLeft:
                                        course?.status === "Active"
                                          ? "1.3rem"
                                          : course?.status === "InActive"
                                            ? "1.5rem"
                                            : course?.status === "Incomplete"
                                              ? "2rem"
                                              : course?.status === "Blocked"
                                                ? "1.5rem"
                                                : "0px",
                                    }}
                                    badgeContent={course.status}
                                  />
                                  <Edit
                                    style={{
                                      color: "#F28705",
                                      cursor: "pointer",
                                      zIndex: 1,
                                      top: -10,
                                      position: "relative",
                                    }}
                                    onClick={() =>
                                      history.push(
                                        `/edit-course/${course?.slug}`
                                      )
                                    }
                                  />
                                </Grid>
                                <Link
                                  to={`/view-course/${course?.slug}`}
                                  style={{ textDecoration: "none" }}
                                >
                                  <CourseCard
                                    image={course?.image}
                                    showEdit={true}
                                    courseId={course?.id}
                                    courseSlug={course?.slug}
                                    category={course?.category?.name}
                                    name={course?.name}
                                    totalDuration={course?.totalDuration}
                                    discountDetail={course?.discountDetail}
                                    plan={course?.plan}
                                    price={course?.price}
                                  />
                                </Link>
                              </Grid>
                            );
                          })
                        ) : (
                          <Grid item xs={12}>
                            <Typography
                              variant="p"
                              color="textSecondary"
                              component="p"
                            >
                              No Record found
                            </Typography>
                          </Grid>
                        ))
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              )}

              {authReducerState?.user?.role === "Regular" &&
                userEnrolledCourses?.length > 0 && (
                  <Grid
                    container
                    direction="row"
                    style={{ paddingBottom: "25px" }}
                  >
                    <Grid container>
                      <Grid item xs={6}>
                        <Typography
                          className={classes.heading}
                          style={{ marginTop: "12px" }}
                        >
                          My Courses
                        </Typography>
                      </Grid>
                      {isMobile && (
                        <Grid
                          item
                          xs={6}
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          {/*<Button*/}
                          {/*  className={classes.subscribeBtn}*/}
                          {/*  // style={{color: "blue", fontWeight : '700'}}*/}
                          {/*  //  disabled={!courseDetail?.id }*/}

                          {/*  onClick={() => history.push("/more/subscription-plan")}*/}
                          {/*>*/}
                          {/*  <Typography>*/}
                          {/*    {reducerUser?.purchasedSubscription &&*/}
                          {/*      reducerUser?.purchasedSubscription[0]?.SubscriptionPlan?.name ===*/}
                          {/*      "Free" ? "SUBSCRIBE" : "UPGRADE"}*/}
                          {/*  </Typography>*/}
                          {/*</Button>*/}
                          {/*  <SubscribeNowBtn noMargin={true} /> */}
                        </Grid>
                      )}
                    </Grid>
                    <Grid container>
                      <Grid
                        item
                        xs={12}
                        container
                        direction="row"
                        spacing={2}
                        className={
                          isMobile && !isDesktop
                            ? classes.hideScrollBar
                            : classes.scrollBar
                        }
                      >
                        {loader ? (
                          <Grid container justify="center">
                            <CircularProgress
                              color="primary"
                              style={{ color: "#09A9BB" }}
                              size={20}
                            />
                          </Grid>
                        ) : userEnrolledCourses?.length > 0 ? (
                          userEnrolledCourses.map((item, ind) => {
                            const { enrolledCourses } = item.course;
                            const course = enrolledCourses;
                            return (
                              <Grid
                                item
                                xs={12}
                                sm={12}
                                md={4}
                                style={{ minWidth: "250px" }}
                                key={ind}
                              >
                                <Link
                                  to={`/view-course/${course?.slug}`}
                                  style={{ textDecoration: "none" }}
                                >
                                  <CourseCard
                                    image={course?.image}
                                    category={course?.category?.name}
                                    name={course?.name}
                                    totalDuration={course?.totalDuration}
                                    plan={course?.plan}
                                    price={course?.price}
                                  />
                                </Link>
                              </Grid>
                            );
                          })
                        ) : (
                          <Grid item xs={12}>
                            <Typography
                              variant="p"
                              color="textSecondary"
                              component="p"
                            >
                              No Record found
                            </Typography>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                )}

              <Grid container style={{ paddingBottom: "25px" }}>
                <Grid container item xs={12}>
                  <Grid item xs={12}>
                    <Typography className={classes.heading}>
                      <>
                        My Challenges{" "}
                        {reducerUser?.canCreateChallenge === true && <IconButton
                          onClick={() => handleChangePage("Challenge")}
                          className={classes.button}
                        >
                          <Add />
                        </IconButton>}
                      </>
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    container
                    direction="row"
                    spacing={2}
                    className={
                      isMobile && !isDesktop
                        ? classes.hideScrollBar
                        : classes.scrollBar
                    }
                  >
                    {loader ? (
                      <Grid container justify="center">
                        <CircularProgress
                          color="primary"
                          style={{ color: "#09A9BB" }}
                          size={20}
                        />
                      </Grid>
                    ) : allChallenges?.length > 0 ? (
                      allChallenges?.map((challenge) => {
                        return (
                          <Grid
                            item
                            style={{ minWidth: "250px", marginTop: "-25px" }}
                            key={challenge?.challenge?.id}
                          >
                            <Grid
                              style={{
                                position: "relative",
                                top: "23px",
                                width: "230px",
                                height: "23px",
                              }}
                              container
                              justify="flex-end"
                            >
                              {challenge?.challenge?.userId ===
                                authReducerState?.user?.id &&
                                !moment(moment().format("YYYY-MM-DD")).isAfter(
                                  moment
                                    .utc(
                                      challenge?.challenge?.challengeDates?.[
                                        challenge?.challenge?.challengeDates
                                          ?.length - 1
                                      ]?.dateTime,
                                      "x"
                                    )
                                    .local()
                                    .format("YYYY-MM-DD")
                                ) && (
                                  <Edit
                                    style={{
                                      color: "#F28705",
                                      cursor: "pointer",
                                      zIndex: 1,
                                    }}
                                    onClick={() =>
                                      history.push(
                                        `/more/edit-challenge/${challenge?.challenge?.id}`
                                      )
                                    }
                                  />
                                )}
                            </Grid>
                            <Link
                              to={`/challenge/details/${challenge?.challenge?.id}`}
                              style={{ textDecoration: "none" }}
                            >
                              <CourseCard
                                image={challenge?.challenge?.imageUrl}
                                category={
                                  challenge?.challenge?.course?.category?.name
                                }
                                friends={
                                  challenge?.challenge?.participantsCount > 1
                                    ? `${challenge?.challenge
                                      ?.participantsCount - 1
                                    } friends`
                                    : challenge?.challenge
                                      ?.participantsCount === 1
                                      ? `${challenge?.challenge
                                        ?.participantsCount - 1
                                      } friend`
                                      : "0 friend"
                                }
                                name={challenge?.challenge?.title}
                                totalDuration={Math.round(
                                  challenge?.challenge?.duration / 60
                                )}
                                time={moment(
                                  challenge?.challenge?.exerciseTime,
                                  "LTS"
                                ).format("LT")}
                                plan={challenge?.challenge?.plan}
                                price={challenge?.challenge?.price}
                              />
                            </Link>
                          </Grid>
                        );
                      })
                    ) : (
                      <Grid item xs={12}>
                        <Typography
                          variant="p"
                          color="textSecondary"
                          component="p"
                        >
                          No Record found
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>

              {(authReducerState?.user?.role !== "Regular" ||
                allSessionsList?.length > 0) && (
                  <Grid container style={{ paddingBottom: "25px" }}>
                    <Grid container item xs={12}>
                      <Grid item xs={12}>
                        <Typography className={classes.heading}>
                          {authReducerState?.user?.role !== "Regular" ? (
                            <>
                              My Sessions{" "}
                              <IconButton
                                onClick={() => handleChangePage("Session")}
                                className={classes.button}
                              >
                                <Add />
                              </IconButton>
                            </>
                          ) : (
                            "My Sessions"
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid
                        item
                        xs={12}
                        container
                        direction="row"
                        spacing={2}
                        className={
                          isMobile && !isDesktop
                            ? classes.hideScrollBar
                            : classes.scrollBar
                        }
                      >
                        {loader ? (
                          <Grid container justify="center">
                            <CircularProgress
                              color="primary"
                              style={{ color: "#09A9BB" }}
                              size={20}
                            />
                          </Grid>
                        ) : allSessionsList?.length > 0 ? (
                          allSessionsList?.map((session, ind) => {
                            return (
                              <Grid item style={{ marginTop: "-25px" }} key={ind}>
                                <Grid
                                  style={{
                                    position: "relative",
                                    top: "21px",
                                    width: "230px",
                                    zIndex: 1,
                                    height: "23px",
                                  }}
                                  container
                                  justify="space-between"
                                >
                                  <Grid item>
                                    {
                                      <Chip
                                        label={currentSessionStatus(session)}
                                        style={{
                                          color: "#FFF",
                                          height: "20px",
                                          background: `${currentSessionStatus(session) ===
                                            "Live"
                                            ? "#39a746"
                                            : currentSessionStatus(session) ===
                                              "Scheduled"
                                              ? "#39a746"
                                              : "#fbae02"
                                            }`,
                                          zIndex: 1,
                                        }}
                                      />
                                    }
                                  </Grid>
                                  <Grid item>
                                    {session?.userId ===
                                      authReducerState?.user?.id &&
                                      session?.type !== "live" &&
                                      moment(
                                        moment(
                                          session?.sessionDates &&
                                          session?.sessionDates[0]?.date
                                        )
                                      ).isAfter(moment()) && (
                                        <Edit
                                          style={{
                                            color: "#F28705",
                                            cursor: "pointer",
                                            zIndex: 1,
                                          }}
                                          onClick={() =>
                                            history.push(
                                              `/more/session/edit/${session?.id}`
                                            )
                                          }
                                        />
                                      )}
                                  </Grid>
                                </Grid>
                                <Link
                                  to={`/more/session/details/${session?.id}`}
                                  style={{ textDecoration: "none" }}
                                >
                                  <CourseCard
                                    image={session?.imageUrl}
                                    friends={
                                      session?.sessionParticipants?.length > 1
                                        ? `${session?.sessionParticipants?.length} friends`
                                        : session?.sessionParticipants?.length ===
                                          1
                                          ? `${session?.sessionParticipants?.length} friend`
                                          : "0 friend"
                                    }
                                    name={session?.name}
                                    time={getFormattedTime(session?.startTime)}
                                  />
                                </Link>
                              </Grid>
                            );
                          })
                        ) : (
                          <Grid item xs={12}>
                            <Typography
                              variant="p"
                              color="textSecondary"
                              component="p"
                            >
                              No Record found
                            </Typography>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                )}

              {authReducerState?.user?.role !== "Regular" && (
                <Grid
                  container
                  direction="row"
                  style={{ paddingBottom: "25px" }}
                >
                  <Grid container item xs={12}>
                    <Grid item xs={12}>
                      <Typography className={classes.heading}>
                        <>
                          My Retreats{" "}
                          <IconButton
                            onClick={() => history.push("/add-retreat")}
                            className={classes.button}
                          >
                            <Add />
                          </IconButton>
                        </>
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} container>
                    <Grid
                      item
                      xs={12}
                      container
                      direction="row"
                      spacing={2}
                      className={
                        isMobile && !isDesktop
                          ? classes.hideScrollBar
                          : classes.scrollBar
                      }
                    >
                      {trainingCourseLoader ? (
                        <Grid container justify="center">
                          <CircularProgress
                            color="primary"
                            style={{ color: "#09A9BB" }}
                            size={20}
                          />
                        </Grid>
                      ) : (
                        authReducerState?.user?.role !== "Regular" &&
                        (uploadedTrainingCoursesList &&
                          uploadedTrainingCoursesList.length > 0 ? (
                          uploadedTrainingCoursesList.map((course) => {
                            return (
                              <Grid
                                item
                                xs={12}
                                sm={12}
                                md={4}
                                style={{
                                  minWidth: "250px",
                                  marginTop: "-25px",
                                }}
                                key={course?.id}
                              >
                                <Grid
                                  style={{
                                    position: "relative",
                                    top: "34px",
                                    width: "230px",
                                  }}
                                  container
                                  justify="space-between"
                                >
                                  <Badge
                                    classes={{
                                      badge:
                                        course?.status === "Active"
                                          ? classes.activeCourse
                                          : course?.status === "Incomplete"
                                            ? classes.incompleteCourse
                                            : course?.status === "Blocked"
                                              ? classes.blockedCourse
                                              : course?.status === "InActive"
                                                ? classes.inactiveCourse
                                                : "",
                                    }}
                                    style={{
                                      marginLeft:
                                        course?.status === "Active"
                                          ? "1.3rem"
                                          : course?.status === "InActive"
                                            ? "1.5rem"
                                            : course?.status === "Incomplete"
                                              ? "2rem"
                                              : course?.status === "Blocked"
                                                ? "1.5rem"
                                                : "0px",
                                    }}
                                    badgeContent={course.status}
                                  />
                                  <Edit
                                    style={{
                                      color: "#F28705",
                                      cursor: "pointer",
                                      zIndex: 1,
                                      top: -10,
                                      position: "relative",
                                    }}
                                    onClick={() =>
                                      history.push(
                                        `/edit-retreat/${course?.slug}`
                                      )
                                    }
                                  />
                                </Grid>
                                <Link
                                  to={`/retreat-details/${course?.id}`}
                                  style={{ textDecoration: "none" }}
                                >
                                  <CourseCard
                                    image={course?.image}
                                    showEdit={true}
                                    courseId={course?.id}
                                    courseSlug={course?.slug}
                                    category={course?.category?.name}
                                    name={course?.name}
                                    totalDuration={course?.totalDuration}
                                    plan={course?.plan}
                                  />
                                </Link>
                              </Grid>
                            );
                          })
                        ) : (
                          <Grid item xs={12}>
                            <Typography
                              variant="p"
                              color="textSecondary"
                              component="p"
                            >
                              No Record found
                            </Typography>
                          </Grid>
                        ))
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              )}

              {enrolledTrainingCoursesList?.length > 0 && (
                <Grid
                  container
                  direction="row"
                  style={{ paddingBottom: "25px" }}
                >
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography className={classes.heading}>
                        Enrolled Retreats
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid
                      item
                      xs={12}
                      container
                      direction="row"
                      spacing={2}
                      className={
                        isMobile && !isDesktop
                          ? classes.hideScrollBar
                          : classes.scrollBar
                      }
                    >
                      {enrolledRetreatsLoader ? (
                        <Grid container justify="center">
                          <CircularProgress
                            color="primary"
                            style={{ color: "#09A9BB" }}
                            size={20}
                          />
                        </Grid>
                      ) : enrolledTrainingCoursesList?.length > 0 ? (
                        enrolledTrainingCoursesList.map((course, ind) => {
                          return (
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={4}
                              style={{ minWidth: "250px" }}
                              key={ind}
                            >
                              <Link
                                to={`/retreat-details/${course?.id}`}
                                style={{ textDecoration: "none" }}
                              >
                                <CourseCard
                                  image={course?.image}
                                  category={course?.category?.name}
                                  name={course?.name}
                                  totalDuration={course?.totalDuration}
                                  plan={course?.plan}
                                  price={course?.price}

                                />
                              </Link>
                            </Grid>
                          );
                        })
                      ) : (
                        <Grid item xs={12}>
                          <Typography
                            variant="p"
                            color="textSecondary"
                            component="p"
                          >
                            No Record found
                          </Typography>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              )}

              {authReducerState?.user?.role !== "Regular" && (
                <Grid
                  container
                  direction="row"
                  style={{ paddingBottom: "25px" }}
                >
                  <Grid container item xs={12}>
                    <Grid item xs={12}>
                      <Typography className={classes.heading}>
                        <>
                          My Teacher Trainings{" "}
                          <IconButton
                            onClick={() =>
                              history.push("/add-teacher-training")
                            }
                            className={classes.button}
                          >
                            <Add />
                          </IconButton>
                        </>
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} container>
                    <Grid
                      item
                      xs={12}
                      container
                      direction="row"
                      spacing={2}
                      className={
                        isMobile && !isDesktop
                          ? classes.hideScrollBar
                          : classes.scrollBar
                      }
                    >
                      {uploadedTeacherTrainingLoader ? (
                        <Grid container justify="center">
                          <CircularProgress
                            color="primary"
                            style={{ color: "#09A9BB" }}
                            size={20}
                          />
                        </Grid>
                      ) : (
                        authReducerState?.user?.role !== "Regular" &&
                        (uploadedTeacherTrainingList &&
                          uploadedTeacherTrainingList.length > 0 ? (
                          uploadedTeacherTrainingList.map((course) => {
                            return (
                              <Grid
                                item
                                xs={12}
                                sm={12}
                                md={4}
                                style={{
                                  minWidth: "250px",
                                  marginTop: "-25px",
                                }}
                                key={course?.id}
                              >
                                <Grid
                                  style={{
                                    position: "relative",
                                    top: "34px",
                                    width: "230px",
                                  }}
                                  container
                                  justify="space-between"
                                >
                                  <Badge
                                    classes={{
                                      badge:
                                        course?.status === "Active"
                                          ? classes.activeCourse
                                          : course?.status === "Incomplete"
                                            ? classes.incompleteCourse
                                            : course?.status === "Blocked"
                                              ? classes.blockedCourse
                                              : course?.status === "InActive"
                                                ? classes.inactiveCourse
                                                : "",
                                    }}
                                    style={{
                                      marginLeft:
                                        course?.status === "Active"
                                          ? "1.3rem"
                                          : course?.status === "InActive"
                                            ? "1.5rem"
                                            : course?.status === "Incomplete"
                                              ? "2rem"
                                              : course?.status === "Blocked"
                                                ? "1.5rem"
                                                : "0px",
                                    }}
                                    badgeContent={course.status}
                                  />
                                  <Edit
                                    style={{
                                      color: "#F28705",
                                      cursor: "pointer",
                                      zIndex: 1,
                                      top: -10,
                                      position: "relative",
                                    }}
                                    onClick={() =>
                                      history.push(
                                        `/edit-teacher-training/${course?.slug}`
                                      )
                                    }
                                  />
                                </Grid>
                                <Link
                                  to={`/teacher-training-details/${course?.id}`}
                                  style={{ textDecoration: "none" }}
                                >
                                  <CourseCard
                                    image={course?.image}
                                    showEdit={true}
                                    courseId={course?.id}
                                    courseSlug={course?.slug}
                                    category={course?.category?.name}
                                    name={course?.name}
                                    totalDuration={course?.totalDuration}
                                    plan={course?.plan}

                                  />
                                </Link>
                              </Grid>
                            );
                          })
                        ) : (
                          <Grid item xs={12}>
                            <Typography
                              variant="p"
                              color="textSecondary"
                              component="p"
                            >
                              No Record found
                            </Typography>
                          </Grid>
                        ))
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              )}

              {enrolledTeacherTrainingList?.length > 0 && (
                <Grid
                  container
                  direction="row"
                  style={{ paddingBottom: "25px" }}
                >
                  <Grid container>
                    {/* <Grid item xs={1} /> */}
                    <Grid item xs={12}>
                      <Typography className={classes.heading}>
                        Enrolled Teacher Trainings
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid
                      item
                      xs={12}
                      container
                      direction="row"
                      spacing={2}
                      className={
                        isMobile && !isDesktop
                          ? classes.hideScrollBar
                          : classes.scrollBar
                      }
                    >
                      {enrolledTeacherTrainingLoader ? (
                        <Grid container justify="center">
                          <CircularProgress
                            color="primary"
                            style={{ color: "#09A9BB" }}
                            size={20}
                          />
                        </Grid>
                      ) : enrolledTeacherTrainingList?.length > 0 ? (
                        enrolledTeacherTrainingList.map((course, ind) => {
                          return (
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={4}
                              style={{ minWidth: "250px" }}
                              key={ind}
                            >
                              <Link
                                to={`/teacher-training-details/${course?.id}`}
                                style={{ textDecoration: "none" }}
                              >
                                <CourseCard
                                  image={course?.image}
                                  category={course?.category?.name}
                                  name={course?.name}
                                  totalDuration={course?.totalDuration}
                                  plan={course?.plan}

                                />
                              </Link>
                            </Grid>
                          );
                        })
                      ) : (
                        <Grid item xs={12}>
                          <Typography
                            variant="p"
                            color="textSecondary"
                            component="p"
                          >
                            No Record found
                          </Typography>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          ) : (
            <Grid
              item
              xs={12}
              className={!isMobile && classes.scroll}
              style={{ maxHeight: "100vh" }}
            >
              <AllCoursesSessionsChallengesTeachers
                selectedCategory={selectedCategory}
              />
              {/*  <CategoryCourses selectedCategory={selectedCategory}/> */}
            </Grid>
          )}
        </Grid>

        {selectedCategory === "my" && (
          <Grid
            item
            xs={12}
            sm={12}
            md={4}
            lg={4}
            className={!isMobile && classes.scroll}
            style={{ maxHeight: "100vh" }}
          >
            <Grid container>
              {userEnrolledCourses &&
                userEnrolledCourses.length > 0 &&
                selectedCategory === "my" ? (
                <Grid item xs={12}>
                  <Card
                    className={classes.coursesCard}
                    style={{
                      backgroundColor: isMobile && "#F8F8F8",
                      marginBottom: isMobile && "80px",
                    }}
                  >
                    {isMobile ? (
                      <>
                        <Typography
                          className={classes.heading}
                          style={{
                            fontSize: "24px",
                            lineHeight: "33px",
                            marginBottom: "0px",
                          }}
                        >
                          {intl.formatMessage({ id: "myProgress" })}
                        </Typography>

                        {userEnrolledCourses?.map((item, ind) => {
                          const { enrolledCourses } = item.course;
                          if (item?.course?.progress < 100) {
                            return (
                              <Grid
                                item
                                sm={12}
                                key={ind}
                                style={{
                                  marginTop: "12px",
                                  marginBottom: isMobile && "15px",
                                }}
                                onClick={() =>
                                  handleGoToInprogressCourseEpisode(
                                    enrolledCourses
                                  )
                                }
                              >
                                <MyCoursesCard
                                  title={enrolledCourses?.name}
                                  courseBy={enrolledCourses?.user?.name}
                                  duration={item?.course.totalDuration}
                                  progress={item?.course?.progress}
                                  category={enrolledCourses?.category?.name}
                                  image={enrolledCourses?.image}
                                />
                              </Grid>
                            );
                          }
                        })}
                      </>
                    ) : (
                      <CardContent style={{ padding: "10px" }}>
                        <Typography
                          className={classes.heading}
                          style={{
                            fontSize: "24px",
                            lineHeight: "33px",
                            marginBottom: "0px",
                          }}
                        >
                          {intl.formatMessage({ id: "myProgress" })}
                        </Typography>

                        {userEnrolledCourses?.map((item, ind) => {
                          const { enrolledCourses } = item.course;
                          if (item?.course?.progress < 100) {
                            return (
                              <Grid
                                item
                                sm={12}
                                key={ind}
                                style={{
                                  marginTop: "12px",
                                  marginBottom: isMobile && "15px",
                                  cursor: "pointer",
                                }}
                                onClick={() =>
                                  handleGoToInprogressCourseEpisode(
                                    enrolledCourses
                                  )
                                }
                              >
                                <MyCoursesCard
                                  title={enrolledCourses?.name}
                                  courseBy={enrolledCourses?.user?.name}
                                  progress={item?.course?.progress}
                                  category={enrolledCourses?.category?.name}
                                  image={enrolledCourses?.image}
                                />
                              </Grid>
                            );
                          }
                        })}
                      </CardContent>
                    )}
                  </Card>
                </Grid>
              ) : (
                ""
              )}{" "}
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default Home;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  gutterBottom: {
    marginBottom: "1.563em",
  },
  gutterTop: {
    marginTop: "4.875em",
  },
  subscribeBtn: {
    height: "2.463em",
    width: "60%",
    background: "#4DB051",
    borderRadius: "10px",
    transform: "rotate(360deg)",
    fontFamily: theme.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "10px",
    color: "#FFFFFF",
    marginTop: "10px",
  },

  heading: {
    fontFamily: theme.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: 900,
    fontSize: "20px",
    lineHeight: "27px",
    color: "#000000",
    marginBottom: "1rem",
  },
  quoteCard: {
    background: "#F7EFF0",
    borderRadius: "17px",
    minHeight: "135px",
    width: "100%",
    marginTop: "25px",
    marginBottom: "5px",
  },
  quoteText: {
    fontFamily: theme.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: 900,
    fontSize: "14px",
    lineHeight: "25px",
    color: "#3F5B73",
    padding: "2px",
  },
  cite: {
    fontFamily: theme.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: 900,
    fontSize: "12px",
    lineHeight: "16px",
    color: "#9F9F9F",
    margin: "auto",
    textAlign: "center",
    marginTop: "5px",
    marginBottom: "5px",
  },
  pageHeading: {
    fontFamily: theme.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: 900,
    fontSize: "36px",
    lineHeight: "49px",
    color: "#343434",
  },
  hideScrollBar: {
    flexWrap: "unset",
    overflowX: "auto",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  scrollBar: {
    flexWrap: "unset",
    overflowX: "auto",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },

  coursesCard: {
    minHeight: "200px",
    width: "100%",
    backgroundColor: "#E8EEEF",
    boxShadow: "none",
  },
  btnStyle: {
    background: "linear-gradient(180deg, #FFC000 -13.24%, #F28705 100%)",
    color: "#fff",
    height: "50px",
    width: "50px",
    fontFamily: theme.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: 900,
    fontSize: "10px",
    lineHeight: "19px",
    textAlign: "center",
  },
  speedDial: {
    "& .MuiFab-primary:hover": {
      width: "50px",
      height: "50px",
      color: "#E54D40",
      backgroundColor: "#E5E5E5 !important",
    },
    "& .MuiFab-primary": {
      width: "50px",
      height: "50px",
      backgroundColor: "#F28705 !important",
    },
    "&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft": {
      bottom: theme.spacing(2),
      right: theme.spacing(1),
    },
    "&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight": {
      top: theme.spacing(2),
      left: theme.spacing(1),
    },
  },
  button: {
    background: "#FBAE02 !important",
    boxShadow: "0px 2px 2px rgba(216, 118, 0, 0.18)",
    color: "#FFF",
    height: "40px",
    width: "40px",
  },
  activeCourse: {
    background: "#52b202",
    color: "#fff",
  },
  incompleteCourse: {
    background: "#ff9100",
    color: "#fff",
  },
  blockedCourse: {
    background: "#f44336",
    color: "#fff",
  },
  inactiveCourse: {
    background: "#6c757d",
    color: "#fff",
  },
  mobileBadge: {
    background: "#14D9D9",
  },
  searchFilter: {
    color: "#b1b2b3 !important",
    fontFamily: theme.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "19px",
    minWidth: "20%",
    textTransform: "none",
    paddingTop: "10px",
  },
  homeSearchField: {
    "& .MuiInputBase-root": {
      color: "#A9A9A9",
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "18px",
      lineHeight: "21px",
      padding: "10px 10px 10px 10px",
      marginTop: "25px",
      marginBottom: "25px",
      height: "58px",
      width: "100%",
      border: "1px solid #A9A9A9",
      borderRadius: "10px",
    },
    "& .MuiInput-underline:before": {
      border: "0px solid #FFF",
    },
    "& .MuiInput-underline::after": {
      border: "0px solid #FFF",
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled)::before": {
      border: "0px solid #FFF",
    },
  },
  scroll: {
    maxHeight: "100vh",
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      width: "0px",
      background: "transparent" /* make scrollbar transparent */,
    },
  },
  mobileHeaderContainer: {
    textAlign: "center",
    alignItems: "center",
    display: "flex",
    height: "72px",
    borderBottom: "1px solid #EAEAEA",
    position: "fixed",
    backgroundColor: "#FFF",
    zIndex: 50,
    padding: "0px 15px",
  },
}));
