import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { Grid, Tooltip, Chip } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Truncate from "react-truncate";



const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    borderRadius: "11px",
    transform: "rotate(360deg)"
  },
  imageContainer: {
    width: "100%",
    height: "0",
    paddingTop: "56.25%",
    position: "relative"
  },
  iconContainer: {
    display: "flex",
    alignContent: "center",
    alignItems: "center"
  },
  Title: {
    fontFamily: theme.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: 900,
    fontSize: "16px",
    lineHeight: "22px",
    color: "#727275",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover"
  },
  subContent: {
    fontFamily: theme.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: 900,
    fontSize: "12px",
    lineHeight: "16px",
    marginTop: "11px",
    color: " #727275"
  },
  videoIcon: {
    height: "3.438em",
    width: "3.438em",
    borderRadius: "100%",
    border: '1px solid #727275',
    textAlign: "center",
    lineHeight: "4.438em"
  },
  activeCourse: {
    background: "#52b202",
    color: "#fff"
  },
  incompleteCourse: {
    background: "#ff9100",
    color: "#fff"
  },
  blockedCourse: {
    background: "#f44336",
    color: "#fff"
  }
}));

function MyCoursesCard(props) {
  const classes = useStyles();
  const { backgroundColor, course } = props;


  return (
    <Card className={classes.root} style={{ background: backgroundColor }}>
      <CardContent>
        <Grid container>
          <Grid item xs={4} sm={4} md={4}>
            <div
              style={{
                background: `url(${course?.image}), #C4C4C4`,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                borderRadius: "9px"
              }}
              className={classes.imageContainer}
            />
          </Grid>

          <Grid item xs={8} sm={8} md={8} style={{ paddingLeft: "15px" }}>
            <Grid item xs={12} container justify="space-between">
              <Grid item xs={6}>
                <Tooltip title={course?.name}>
                  <Typography className={classes.Title}>
                    <Truncate lines={2} ellipsis={<span>...</span>} >
                      {`${course?.name}`}
                    </Truncate>

                  </Typography>
                </Tooltip>
              </Grid>

              <Grid item xs={6} container justify="flex-end">
                <Grid item>
                  <Chip
                    className={
                      course?.status === "Active"
                        ? classes.activeCourse
                        : course?.status === "Incomplete"
                          ? classes.incompleteCourse
                          : course?.status === "Blocked"
                            ? classes.blockedCourse
                            : ""
                    }
                    label={course?.status}
                    style={{ height: "18px" }}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} container>
              <Grid item xs={4}>
                <Typography className={classes.subContent}>
                  {course?.plan?.name}
                </Typography>
              </Grid>

              <Grid item xs={4}>
                <Typography className={classes.subContent}>
                  {course?.category?.name}
                </Typography>
              </Grid>

              <Grid item xs={4}>
                <Typography className={classes.subContent}>
                  Users: {course?.totalUsersJoined}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item container>
          <Grid item xs={8}>
            <Typography className={classes.subContent}>
              Total Revenue: ${course?.totalRevenue || 0}
            </Typography>
          </Grid>

          <Grid item xs={4} container justify="flex-end">
            <Typography className={classes.subContent}>
              Avg. Rating: {course?.averageRating > 0 ? course?.averageRating : 'not rated'}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default MyCoursesCard;
