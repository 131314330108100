import API from "../utils/API";
import * as tus from "tus-js-client";
import axios from "axios";


// types
import {
  SET_PLANS,
  TRAINING_ADD_SUCCESSFUL,
  SET_TRAINING_COURSE,
  TRAINING_COURSE_LOADER_TRUE,
  TRAINING_COURSE_LOADER_FALSE,
  SHOW_MESSAGE,
  SET_LESSONS,
  TRAINING_COURSE_DETAIL,
  SET_SECTIONS,
  SET_TRAINING_COURSE_SECTIONS,
  TRAINING_PREVIEW_VIDEO_PROGRESS
} from "./types";
import { getRetreatMentors } from './retreatCourse'

const IPFS_API_URL =
  process.env.REACT_APP_IPFS_API_URL || "https://ipfs.tantraschooloflove.com/api/v0/add";

export const updateLessonOrderApi = ({ lessons, token }) => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    }
  };
  const body = JSON.stringify({ lessons });
  try {
    const res = await API.patch(
      `/api/v1/trainingCourse/changeEpisodesOrder`,
      body,
      config
    );
    if (res.status === 200) {
      dispatch({
        type: "SET_ALL_EPISODES",
        payload: res.data.data
      });
      return {
        success: true
      };
    }
  } catch (error) {
    console.log(error);

    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        title: `Could not update episode order`,
        message:
          error.response &&
            error.response &&
            error.response.data &&
            error.response.data.message
            ? error.response.data.message
            : `Something went wrong while updating order`,
        severity: "error"
      }
    });
    return {
      success: false
    };
  }
};

export const courseActiveInActive = ({
  token,
  courseId,
  status
}) => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    }
  };
  const body = JSON.stringify({ courseId, status });
  try {
    const res = await API.patch(
      `/api/v1/trainingCourse/changeStatus`,
      body,
      config
    );
    dispatch(getCourseDetailById({ courseId, token }));
    delete res.data.data.episodes;
    dispatch({
      type: 'IN_ACTIVE_LAST_SECTION',
      payload: 'Active'
    });
    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        title: `success`,
        message: res.data.message,
        severity: "success"
      }
    });
    return {
      status: true
    };
  } catch (error) {
    console.log(error);
    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        title: `Failed`,
        message:
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : `change status failed`,
        severity: "error"
      }
    });
    return {
      status: false
    };
  }
};

export const changeSectionStatus = ({
  token,
  sectionId,
  trainingCourseId,
  status,
  showMessage
}) => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    }
  };

  try {
    const res = await API.patch(
      `/api/v1/trainingCourse/changeSectionStatus`,
      JSON.stringify({
        trainingCourseId,
        sectionId,
        status
      }),
      config
    );
    const lessonsResponse = await API.get(
      `/api/v1/trainingCourse/getAllLessons?`,
      {
        ...config,
        params: {
          sectionId: res.data.data.id,
          trainingCourseId
        }
      }
    );

    const contentResp = await API.get(
      "/api/v1/trainingCourse/getCourseContent",
      {
        ...config,
        params: {
          trainingCourseId
        }
      }
    );

    dispatch({
      type: SET_TRAINING_COURSE_SECTIONS,
      payload: { sections: contentResp.data.data }
    });

    showMessage &&
      dispatch({
        type: SHOW_MESSAGE,
        payload: {
          title: `success`,
          message: res.data.message,
          severity: "success"
        }
      });
    return {
      status: true,
      section: res?.data?.data
    };
  } catch (error) {
    console.log(error);
    showMessage &&
      dispatch({
        type: SHOW_MESSAGE,
        payload: {
          title: `Failed`,
          message:
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : `change status failed`,
          severity: "error"
        }
      });
    return {
      status: false
    };
  }
};

export const changeEpisodeStatus = ({
  token,
  episodeId,
  status,
  showMessage,
  courseId
}) => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    params: {
      lessonId: episodeId,
      status
    }
  };

  try {
    const res = await API.patch(
      `/api/v1/trainingCourse/changeEpisodeStatus`,
      JSON.stringify({}),
      config
    );

    const contentResp = await API.get(
      "/api/v1/trainingCourse/getCourseContent",
      {
        ...config,
        params: {
          trainingCourseId: courseId
        }
      }
    );

    dispatch({
      type: SET_TRAINING_COURSE_SECTIONS,
      payload: { sections: contentResp.data.data }
    });

    dispatch({
      type: SET_LESSONS,
      payload: res.data.data
    });
    showMessage &&
      dispatch({
        type: SHOW_MESSAGE,
        payload: {
          title: `success`,
          message: res.data.message,
          severity: "success"
        }
      });
    return res.data.data;
  } catch (error) {
    console.log(error);
    showMessage &&
      dispatch({
        type: SHOW_MESSAGE,
        payload: {
          title: `Failed`,
          message:
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : `change status failed`,
          severity: "error"
        }
      });
  }
};

export const getAllPlans = ({ token }) => async dispatch => {
  // set header
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    }
  };

  try {
    const res = await API.get(`/api/v1/plan/getAllPlans`, config);
    dispatch({
      type: SET_PLANS,
      payload: res.data.data
    });
  } catch (error) {
    console.log(error);
  }
};

export const deleteCourse = ({ token, courseId }) => async dispatch => {
  dispatch({
    type: TRAINING_COURSE_LOADER_TRUE
  });
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    }
  };
  try {
    const res = await API.delete(
      `/api/v1/trainingCourse/deleteCourse/${courseId}`,
      config
    );
    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        title: `Course Deleted`,
        message:
          res.data && res.data.message ? res.data.message : `Course Deleted`,
        severity: "warning"
      }
    });
    dispatch({
      type: TRAINING_COURSE_LOADER_FALSE
    });
  } catch (error) {
    dispatch({
      type: TRAINING_COURSE_LOADER_FALSE
    });
    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        title: `Course Added`,
        message:
          error.response &&
            error.response.data &&
            error.response.data.errors &&
            error.response.data.errors.length
            ? error.response.data.errors[0]
            : error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : `Something went wrong while deleting`,
        severity: "error"
      }
    });
  }
};

export const addMentors = ({
  token,
  userIds,
  trainingCourseId
}) => async dispatch => {
  dispatch({
    type: TRAINING_COURSE_LOADER_TRUE
  });
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    }
  };
  const body = JSON.stringify({ userIds, trainingCourseId });
  try {
    const res = await API.post(
      `/api/v1/trainingCourse/addMentors`,
      body,
      config
    );
    if (res.data.data) {
      await dispatch(
        getCourseDetails({
          courseId: trainingCourseId,
          token
        })
      );
      dispatch({
        type: TRAINING_COURSE_LOADER_FALSE
      });
      dispatch({
        type: SHOW_MESSAGE,
        payload: {
          title: `Mentors`,
          message:
            res.data && res.data.message ? res.data.message : `Mentors Added`,
          severity: "success"
        }
      });
    }
    return {
      status: true
    };
  } catch (error) {
    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        title: `Could not add mentors`,
        message:
          error.response &&
            error.response.data &&
            error.response.data.errors &&
            error.response.data.errors.length
            ? ` One or more values is missing from json validation`
            : error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : ` Something went wrong try after some time`,
        severity: "error"
      }
    });
    dispatch({
      type: TRAINING_COURSE_LOADER_FALSE
    });
    return {
      status: false
    };
  }
};

// Add Section
export const addSections = ({
  token,
  name,
  limit,
  trainingCourseId
}) => async dispatch => {
  dispatch({
    type: TRAINING_COURSE_LOADER_TRUE
  });
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    }
  };
  const body = JSON.stringify({ name, limit, trainingCourseId });
  try {
    const res = await API.post(
      `/api/v1/trainingCourse/createSection`,
      body,
      config
    );
    if (res.data.data?.id) {
      const lessonsResponse = await API.get(
        `/api/v1/trainingCourse/getAllLessons?`,
        {
          ...config,
          params: {
            sectionId: res.data.data.id,
            trainingCourseId
          }
        }
      );
      const section = {
        ...res.data.data,
        lessons: lessonsResponse.data.data
      };
      dispatch({
        type: SET_SECTIONS,
        payload: section
      });
      dispatch({
        type: TRAINING_COURSE_LOADER_FALSE
      });
      dispatch({
        type: SHOW_MESSAGE,
        payload: {
          title: `Section`,
          message:
            res.data && res.data.message ? res.data.message : `Section Added`,
          severity: "success"
        }
      });
      return {
        status: true,
        section
      };
    }
    return {
      status: false
    };
  } catch (error) {
    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        title: `Could not add section`,
        message:
          error.response &&
            error.response.data &&
            error.response.data.errors &&
            error.response.data.errors.length
            ? ` One or more values is missing from json validation`
            : error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : ` Something went wrong try after some time`,
        severity: "error"
      }
    });
    dispatch({
      type: TRAINING_COURSE_LOADER_FALSE
    });
    return {
      status: false
    };
  }
};

// Update Section
export const updateSections = ({
  token,
  name,
  limit,
  sectionId,
  trainingCourseId
}) => async dispatch => {
  dispatch({
    type: TRAINING_COURSE_LOADER_TRUE
  });
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    }
  };
  const body = JSON.stringify({ name, limit, trainingCourseId, sectionId });
  try {
    const res = await API.patch(
      `/api/v1/trainingCourse/updateSection`,
      body,
      config
    );
    if (res.data.data) {
      const lessonsResponse = await API.get(
        `/api/v1/trainingCourse/getAllLessons?`,
        {
          ...config,
          params: {
            sectionId: res.data.data.id,
            trainingCourseId
          }
        }
      );
      const section = {
        ...res.data.data,
        lessons: lessonsResponse.data.data
      };
      dispatch({
        type: SET_SECTIONS,
        payload: section
      });
      dispatch({
        type: TRAINING_COURSE_LOADER_FALSE
      });
      dispatch({
        type: SHOW_MESSAGE,
        payload: {
          title: `Section`,
          message:
            res.data && res.data.message ? res.data.message : `Section Added`,
          severity: "success"
        }
      });
      return {
        status: true,
        section
      };
    }
    return {
      status: false
    };
  } catch (error) {
    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        title: `Could not add section`,
        message:
          error.response &&
            error.response.data &&
            error.response.data.errors &&
            error.response.data.errors.length
            ? ` One or more values is missing from json validation`
            : error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : ` Something went wrong try after some time`,
        severity: "error"
      }
    });
    dispatch({
      type: TRAINING_COURSE_LOADER_FALSE
    });
    return {
      status: false
    };
  }
};

export const removeMentors = ({
  token,
  userId,
  trainingCourseId
}) => async dispatch => {
  dispatch({
    type: TRAINING_COURSE_LOADER_TRUE
  });
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    }
  };
  try {
    const res = await API.delete(
      `/api/v1/trainingCourse/removeMentor?userId=${userId}&trainingCourseId=${trainingCourseId}`,
      config
    );
    if (res.status) {
      await dispatch(
        getCourseDetails({
          courseId: trainingCourseId,
          token
        })
      );

      dispatch({
        type: SHOW_MESSAGE,
        payload: {
          title: `Mentors`,
          message:
            res.data && res.data.message ? res.data.message : `Mentor removed`,
          severity: "warning"
        }
      });
      dispatch({
        type: TRAINING_COURSE_LOADER_FALSE
      });
      return {
        status: true
      };
    }
    return {
      status: false
    };
  } catch (error) {
    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        title: `Could not remove mentor`,
        message:
          error.response &&
            error.response.data &&
            error.response.data.errors &&
            error.response.data.errors.length
            ? ` One or more values is missing from json validation`
            : error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : ` Something went wrong try after some time`,
        severity: "error"
      }
    });
    dispatch({
      type: TRAINING_COURSE_LOADER_FALSE
    });
    return {
      status: false
    };
  }
};

export const addCourse = ({
  courseData,
  token,
  plan,
  courseImage,
  episodes,
  previewVideoFile
}) => async dispatch => {
  // set header
  dispatch({
    type: TRAINING_COURSE_LOADER_TRUE
  });
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    }
  };

  // set body
  try {
    


    if (previewVideoFile) {
      let formData = new FormData();
      formData.append("file", previewVideoFile);

      // const body = { ...formData };
      // debugger;
      const ipfsResponse = await axios.post(IPFS_API_URL, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },

      });

      if (ipfsResponse && ipfsResponse.data && ipfsResponse.data.Hash) {
        courseData["ipfsCid"] = ipfsResponse.data.Hash;
        dispatch({
          type: TRAINING_ADD_SUCCESSFUL,
          payload: true
        });
      }
    }

    const body = JSON.stringify({ ...courseData });

    const res = await API.post(
      `/api/v1/trainingCourse/createTrainingCourse`,
      body,
      config
    );
    if(res){
      dispatch({
        type: TRAINING_COURSE_LOADER_FALSE
      });
    }
    if (res.data.data.course) {
      dispatch(
        addImage({
          courseData: { ...res.data.data.course, image: courseImage },
          token
        })
      );


      if (previewVideoFile) {
        dispatch(
          addCoursePreviewVideo({
            courseData: {
              videoFile: previewVideoFile,
              ...courseData,
              ...res.data.data
            },

            token,
            res
          })
        );
      } else {
        dispatch({
          type: SET_TRAINING_COURSE,
          payload: {
            ...res.data.data.course,
            courseTags: res.data.data.courseTags,
            tags: courseData.tags.split(",")
          }
        });
        dispatch({
          type: TRAINING_ADD_SUCCESSFUL,
          payload: true
        });
        dispatch({
          type: SHOW_MESSAGE,
          payload: {
            title: `Course`,
            message:
              res.data && res.data.message ? res.data.message : `Course Added`,
            severity: "success"
          }
        });
        dispatch({
          type: TRAINING_COURSE_LOADER_FALSE,
        

        });
      }
      // if(res?.data?.course?.previewUrl != null){
      //   dispatch({
      //     type: TRAINING_ADD_SUCCESSFUL,
      //     payload: true
      //   });
      // }
   
    }
    
    else {
      dispatch({
        type: TRAINING_COURSE_LOADER_FALSE
      });
    }
  } catch (error) {
    console.log(error);
    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        title: `Could not add course`,
        message:
          error.response &&
            error.response.data &&
            error.response.data.errors &&
            error.response.data.errors.length
            ? ` One or more values is missing from json validation`
            : error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : ` Something went wrong try after some time`,
        severity: "error"
      }
    });
    dispatch({
      type: TRAINING_COURSE_LOADER_FALSE
    });
  }
};

export const addCoursePreviewVideo = ({
  courseData,
  token,
  res
}) => async dispatch => {

  try {

    var myHeaders = new Headers();
    myHeaders.append("Tus-Resumable", "1.0.0");
    myHeaders.append("Upload-Offset", 0);
    myHeaders.append("Content-Type", "application/offset+octet-stream");
    myHeaders.append("Accept", "application/vnd.vimeo.*+json;version=3.4");

    console.log("tus supported", tus.isSupported);
    var upload = new tus.Upload(courseData.videoFile, {
      uploadUrl: courseData.uploadLink,
      endpoint: courseData.uploadLink,
      overridePatchMethod: true,
      retryDelays: [0, 3000, 5000, 10000, 20000],
      chunkSize: 12800000,
      metadata: {
        filename: courseData?.course?.name + " " + courseData?.course.id,
        filetype: courseData.videoFile.type
      },
      onError: function (error) {
        console.log("Failed because: " + error);
      },
      onProgress: function (bytesUploaded, bytesTotal) {
        var percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2);
        console.log(bytesUploaded, bytesTotal, percentage + "%");
        dispatch({
          type: TRAINING_PREVIEW_VIDEO_PROGRESS,
          payload: percentage
        });
      },
      onSuccess: function () {

        dispatch({
          type: TRAINING_PREVIEW_VIDEO_PROGRESS,
          payload: 0
        });

        dispatch({
          type: SET_TRAINING_COURSE,
          payload: {
            ...res.data.data.course,
            courseTags: res.data.data.courseTags,
            tags: courseData.tags.split(",")
          }
        });
        dispatch({
          type: TRAINING_ADD_SUCCESSFUL,
          payload: true
        });
        dispatch({
          type: SHOW_MESSAGE,
          payload: {
            title: `Course Added`,
            message:
              res.data && res.data.message ? res.data.message : `Course Added`,
            severity: "success"
          }
        });
        dispatch({
          type: TRAINING_COURSE_LOADER_FALSE
        });

        console.log("Download %s from %s", upload.file.name, upload.url);
      }
    });

    // Start the upload
    await upload.start();
  } catch (error) { }
};

export const updateCourse = ({
  courseData,
  token,
  courseImage,
  previewVideoFile
}) => async dispatch => {
  // set header
  dispatch({
    type: TRAINING_COURSE_LOADER_TRUE
  });
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    }
  };

  // set body
  const body = JSON.stringify({ ...courseData });
  try {
    const res = await API.patch(
      `/api/v1/trainingCourse/updateCourse`,
      body,
      config
    );
    if (res.data.data.course && courseImage) {
      dispatch(
        addImage({
          courseData: { ...res.data.data.course, image: courseImage },
          token
        })
      );
    }

    if (res.data.data.course && previewVideoFile) {
      await dispatch(
        addCoursePreviewVideo({
          courseData: {
            videoFile: previewVideoFile,
            ...courseData,
            ...res.data.data
          },
       
          token,
          res
        })
      );
    } else {
      dispatch({
        type: SET_TRAINING_COURSE,
        payload: {
          ...res.data.data.course,
          tags: courseData.tags.split(",")
        }
      });
      dispatch({
        type: TRAINING_ADD_SUCCESSFUL,
        payload: true
      });
      dispatch({
        type: SHOW_MESSAGE,
        payload: {
          title: `Course Updated`,
          message:
            res.data && res.data.message ? res.data.message : `Course Updated`,
          severity: "success"
        }
      });
      dispatch({
        type: TRAINING_COURSE_LOADER_FALSE
      });
    }
  } catch (error) {
    console.log(error);
    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        title: `Could not update course`,
        message:
          error.response &&
            error.response.data &&
            error.response.data.errors &&
            error.response.data.errors.length
            ? ` One or more values is missing from json validation`
            : error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : ` Something went wrong try after some time`,
        severity: "error"
      }
    });
    dispatch({
      type: TRAINING_COURSE_LOADER_FALSE
    });
  }
};

export const addImage = ({ courseData, token }) => async dispatch => {
  // set header
  // dispatch({
  //   type: TRAINING_COURSE_LOADER_TRUE
  // });
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    }
  };
  // set body
  let formData = new FormData();
  formData.append("image", courseData.image);
  const body = formData;
  try {
    const res = await API.post(
      `/api/v1/trainingCourse/uploadCourseImage?courseId=${courseData.id}`,
      body,
      config
    );
    dispatch({
      type: SET_TRAINING_COURSE,
      payload: {
        ...res.data.data,
        tags: res.data.data.tags.split(","),
        step: 1
      }
    });
  
  } catch (error) {
    console.log(error);
  }
};

export const addEpisode = ({
  episodeData,
  episodeImage,
  episodeVideo,
  token
  // onUploadProgress
}) => async dispatch => {
  // set header
  dispatch({
    type: TRAINING_COURSE_LOADER_TRUE
  });
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    }

  };
  // set body

  const body = JSON.stringify({
    ...episodeData,
    upload: {
      approach: "tus",
      size: episodeVideo.size
    }
  });
  try {
    const res = await API.post(
      `api/v1/trainingCourse/addEpisode`,
      body,
      config
    );
    if (res.data.data) {
      if (episodeImage) {
        dispatch({
          type: SET_LESSONS,
          payload: res.data.data
        });
        await dispatch(
          addEpisodeImage({
            episodeData: {
              ...episodeData,
              videoFile: episodeVideo,
              image: episodeImage,
              ...res.data.data
            },
            token
          })
        );
      }

      if (episodeVideo && episodeData?.type !== 'live') {
        await dispatch(
          addEpisodeVideo({
            episodeData: {
              videoFile: episodeVideo,
              image: episodeImage,
              ...res.data.data
            },
          
            token
          })
        );
      }
    }







    const lessonsResponse = await API.get(
      `/api/v1/trainingCourse/getAllLessons?`,
      {
        ...config,
        params: {
          sectionId: episodeData.sectionId,
          trainingCourseId: episodeData.courseId
        }
      }
    );

    const contentResp = await API.get(
      "/api/v1/trainingCourse/getCourseContent",
      {
        ...config,
        params: {
          trainingCourseId: episodeData.courseId
        }
      }
    );
    dispatch({
      type: SET_TRAINING_COURSE_SECTIONS,
      payload: { sections: contentResp.data.data }
    });
    dispatch({
      type: TRAINING_COURSE_LOADER_FALSE
    });

    return {
      status: true,
      lessons: lessonsResponse.data.data
    };
  } catch (error) {
    dispatch({
      type: TRAINING_COURSE_LOADER_FALSE
    });
    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        title: `Couldn't not add episode`,
        message:
          error.response &&
            error.response.data &&
            error.response.data.errors &&
            error.response.data.errors.length
            ? error.response.data.errors[0]
            : error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : `Something went wrong while adding`,
        severity: "error"
      }
    });
    return {
      status: false
    };
  }
};

export const updateEpisode = ({
  episodeData,
  episodeImage,
  episodeVideo,
  token
}) => async dispatch => {
  // set header
  dispatch({
    type: TRAINING_COURSE_LOADER_TRUE
  });
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    }
  };
  // set body

  const body = episodeVideo
    ? JSON.stringify({
      ...episodeData,
      upload: {
        approach: "tus",
        size: episodeVideo.size
      }
    })
    : JSON.stringify({ ...episodeData });
  try {
    const res = await API.patch(
      `api/v1/trainingCourse/updateEpisode`,
      body,
      config
    );
    if (res.data.data) {
      dispatch({
        type: SET_LESSONS,
        payload: res.data.data
      });
      if (episodeImage) {
        await dispatch(
          addEpisodeImage({
            episodeData: {
              ...episodeData,
              ...res.data.data,
              videoFile: episodeVideo,

              image: episodeImage
            },
            token
          })
        );
      }
      if (episodeVideo) {
        await dispatch(
          addEpisodeVideo({
            episodeData: {
              ...episodeData,
              ...res.data.data,
              videoFile: episodeVideo,
              image: episodeImage
            },
            token
          })
        );
      }
    }
    const lessonsResponse = await API.get(
      `/api/v1/trainingCourse/getAllLessons?`,
      {
        ...config,
        params: {
          sectionId: episodeData.sectionId,
          trainingCourseId: episodeData.courseId
        }
      }
    );
    const contentResp = await API.get(
      "/api/v1/trainingCourse/getCourseContent",
      {
        ...config,
        params: {
          trainingCourseId: episodeData.courseId
        }
      }
    );
    dispatch({
      type: SET_TRAINING_COURSE_SECTIONS,
      payload: { sections: contentResp.data.data }
    });
    dispatch({
      type: TRAINING_COURSE_LOADER_FALSE
    });

    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        title: `Mentors`,
        message:
          res.data && res.data.message ? res.data.message : `Mentors Added`,
        severity: "success"
      }
    });
    return {
      status: true,
      lessons: lessonsResponse.data.data
    };
  } catch (error) {
    dispatch({
      type: TRAINING_COURSE_LOADER_FALSE
    });
    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        title: `Could not Update Episode`,
        message:
          error.response &&
            error.response.data &&
            error.response.data.errors &&
            error.response.data.errors.length
            ? error.response.data.errors[0]
            : error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : `Something went wrong while updating`,
        severity: "error"
      }
    });
    return {
      status: false
    };
  }
};

export const addUpdatePlan = ({
  plan,
  price,
  courseData,
  episodesList,
  token
}) => async dispatch => {
  // set header
  dispatch({
    type: TRAINING_COURSE_LOADER_TRUE
  });
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    }
  };
  // set body

  const body = JSON.stringify({
    courseId: courseData.id,
    planId: plan.id,
    coursePrice: price
  });
  try {
    const res = await API.put(
      `api/v1/trainingCourse/addOrUpdateCoursePlan`,
      body,
      config
    );
    if (res.data.data) {
      await episodesList.map(async item => {
        await dispatch(
          updateEpisode({
            episodeData: {
              ...item,
              episodeId: item.id
            },
            token
          })
        );
      });
      dispatch({
        type: SET_TRAINING_COURSE,
        payload: { ...res.data.data, plan, tags: res.data.data.tags.split(",") }
      });
      dispatch({
        type: TRAINING_ADD_SUCCESSFUL,
        payload: true
      });
    }
    dispatch({
      type: TRAINING_COURSE_LOADER_FALSE
    });
  } catch (error) {
    dispatch({
      type: TRAINING_COURSE_LOADER_FALSE
    });
    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        title: `Could not update plan`,
        message:
          error.response &&
            error.response.data &&
            error.response.data.errors &&
            error.response.data.errors.length
            ? error.response.data.errors[0]
            : error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : `Something went wrong while updating plan`,
        severity: "error"
      }
    });
  }
};

export const deleteEpisode = ({
  episodeData,
  courseId,
  token
}) => async dispatch => {
  // set header
  dispatch({
    type: TRAINING_COURSE_LOADER_TRUE
  });
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    }
  };
  // set body
  let formData = new FormData();
  formData.append("thumbnail", episodeData.image);
  const body = episodeData;
  try {
    const res = await API.delete(`/api/v1/trainingCourse/deleteEpisode`, {
      ...config,
      data: JSON.stringify(body)
    });
    const lessonsResponse = await API.get(
      `/api/v1/trainingCourse/getAllLessons?`,
      {
        ...config,
        params: {
          sectionId: episodeData.sectionId,
          trainingCourseId: courseId
        }
      }
    );
    await API.get(
      "/api/v1/trainingCourse/getCourseContent",
      {
        ...config,
        params: {
          trainingCourseId: courseId
        }
      }
    );

    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        title: `Episode Deleted`,
        message:
          res && res.data && res.data.message
            ? res.data.message
            : ` Something went wrong try after some time`,
        severity: "warning"
      }
    });
    dispatch({
      type: TRAINING_COURSE_LOADER_FALSE
    });
    return {
      status: true,
      lessons: lessonsResponse.data.data
    };
  } catch (error) {
    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        title: `Could not delete episode`,
        message:
          error.response &&
            error.response.data &&
            error.response.data.errors &&
            error.response.data.errors.length
            ? ` One or more values is missing from json validation`
            : error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : ` Something went wrong try after some time`,
        severity: "error"
      }
    });
    dispatch({
      type: TRAINING_COURSE_LOADER_FALSE
    });
    return {
      status: false
    };
  }
};

export const deleteSection = ({
  sectionId,
  trainingCourseId,
  token
}) => async dispatch => {
  // set header

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    params: {
      sectionId,
      trainingCourseId
    }
  };

  try {
    const res = await API.delete(`/api/v1/trainingCourse/deleteSection`, {
      ...config,
    });


    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        title: `Section Deleted`,
        message:
          res && res.data && res.data.message
            ? res.data.message
            : ` Something went wrong try after some time`,
        severity: "warning"
      }
    });

    return {
      status: true,
    };
  } catch (error) {
    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        title: `Could not delete section`,
        message:
          error.response &&
            error.response.data &&
            error.response.data.errors &&
            error.response.data.errors.length
            ? ` One or more values is missing from json validation`
            : error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : ` Something went wrong try after some time`,
        severity: "error"
      }
    });

    return {
      status: false
    };
  }
};

export const invitePeople = ({ data, token }) => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    }
  };
  const body = JSON.stringify({ ...data });
  try {
    const res = await API.post(
      `/api/v1/trainingCourse/inviteUsers`,
      body,
      config
    );
    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        title: `Invite Sent`,
        message:
          res && res.data && res.data.message
            ? res.data.message
            : `Invite send successfully`,
        severity: "success"
      }
    });
    return {
      loader: false,
      success: true
    };
  } catch (error) {
    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        title: `Could not sent invite`,
        message:
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : `Something went wrong`,
        severity: "error"
      }
    });
    return {
      loader: false,
      success: true
    };
  }
};

export const addEpisodeImage = ({ episodeData, token }) => async dispatch => {
  // set header
  dispatch({
    type: TRAINING_COURSE_LOADER_TRUE
  });
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    onUploadProgress: event => {
      console.log(Math.round((100 * event.loaded) / event.total));
      dispatch({
        type: "LESSON_THUMBNAIL_PROGRESS",
        payload: Math.round((100 * event.loaded) / event.total)
      });
    }
  };
  // set body

  let formData = new FormData();
  formData.append("thumbnail", episodeData.image);
  const body = formData;
  try {
    const res = await API.post(
      `/api/v1/trainingCourse/uploadThumbnail?lessonId=${episodeData.id}&courseId=${episodeData.courseId}&sectionId=${episodeData.sectionId}`,
      body,
      config
    );
    dispatch({
      type: SET_LESSONS,
      payload: res.data.data
    });
  } catch (error) { }
};
export const addEpisodeVideo = ({ episodeData, token }) => async dispatch => {
  dispatch({
    type: TRAINING_COURSE_LOADER_TRUE
  });

 
  try {
    dispatch({
      type: "LESSON_VIDEO_LOADER",
      payload: episodeData.id
    });
  

    var myHeaders = new Headers();
    myHeaders.append("Tus-Resumable", "1.0.0");
    myHeaders.append("Upload-Offset", 0);
    myHeaders.append("Content-Type", "application/offset+octet-stream");
    myHeaders.append("Accept", "application/vnd.vimeo.*+json;version=3.4");

    console.log("tus supported", tus.isSupported);
    var upload = new tus.Upload(episodeData.videoFile, {
      uploadUrl: episodeData.uploadLink,
      endpoint: episodeData.uploadLink,
      overridePatchMethod: true,
      retryDelays: [0, 3000, 5000, 10000, 20000],
      chunkSize: 12800000,
      metadata: {
        filename: episodeData.name + " " + episodeData.id,
        filetype: episodeData.videoFile.type
      },
      onError: function (error) {
        console.log("Failed because: " + error);
      },
      onProgress: function (bytesUploaded, bytesTotal) {
        var percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2);
        console.log(bytesUploaded, bytesTotal, percentage + "%");
        dispatch({
          type: "LESSON_VIDEO_PROGRESS",
          payload: { episode_id: episodeData.id, percentage }
        });
      },
      onSuccess: function () {
        dispatch({
          type: "LESSON_VIDEO_LOADER",
          payload: episodeData.id
        });
        const intervalStatus = setInterval(async () => {
          const episodeStatusData = await dispatch(
            changeEpisodeStatus({
              episodeId: episodeData?.lessonId,
              status: "Active",
              token: token,
              courseId: episodeData?.courseId,
              showMessage: false
            })
          );
          if (episodeStatusData?.status === "Active") {
            clearInterval(intervalStatus);
          }
        }, 5000);
      }
    });

    // Start the upload
    await upload.start();
  } catch (error) { }
};

// get course details for edit

export const getCourseDetails = ({ courseId, token }) => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    params: {
      courseId
    }
  };

  try {
    const res = await API.get(
      `/api/v1/trainingCourse/getCourseDetails`,
      config
    );
    const contentResp = await API.get(
      "/api/v1/trainingCourse/getCourseContent",
      {
        ...config,
        params: {
          trainingCourseId: courseId
        }
      }
    );
    const mentorRes = await dispatch(getRetreatMentors({ courseId, token }))

    dispatch({
      type: SET_TRAINING_COURSE,
      payload: { ...res.data.data, sections: contentResp.data.data, mentors: mentorRes?.mentors, isMentor: mentorRes?.isMentor }
    });
    return "success";
  } catch (error) {
    console.log(error);
    return "fail";
  }
};

//update lessons order

export const updateEpisodeOrderApi = ({
  episodes,
  trainingCourseId,
  token
}) => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    }
  };
  const body = JSON.stringify({ lessons: episodes });
  try {
    const res = await API.patch(
      `/api/v1/trainingCourse/changeEpisodesOrder`,
      body,
      config
    );
    const contentResp = await API.get(
      "/api/v1/trainingCourse/getCourseContent",
      {
        ...config,
        params: {
          trainingCourseId
        }
      }
    );

    dispatch({
      type: SET_TRAINING_COURSE_SECTIONS,
      payload: { sections: contentResp.data.data }
    });
    if (res.status === 200) {
      dispatch({
        type: "SET_ALL_EPISODES",
        payload: res.data.data
      });
      return {
        success: true
      };
    }
  } catch (error) {
    console.log(error);

    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        title: `Could not update episode order`,
        message:
          error.response &&
            error.response &&
            error.response.data &&
            error.response.data.message
            ? error.response.data.message
            : `Something went wrong while updating order`,
        severity: "error"
      }
    });
    return {
      success: false
    };
  }
};

//get course details by id
export const getCourseDetailById = ({ courseId, token }) => async dispatch => {
  
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    params: {
      courseId
    }
  };

  try {
    const res = await API.get(
      `/api/v1/trainingCourse/getCourseDetails`,
      config
    );

    const mentorRes = await dispatch(getRetreatMentors({ courseId, token }))

    dispatch({
      type: TRAINING_COURSE_DETAIL,
      payload: { ...res.data.data, mentors: mentorRes?.mentors, isMentor: mentorRes?.isMentor }
    });
    
    return { status: "success" };
  } catch (error) {
    console.log(error);
    
    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        title: `Could not get course`,
        message:
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : ` Something went wrong try after some time`,
        severity: "error"
      }
    });
    return {
      status: "fail",
      message: error?.response?.data?.message
        ? error?.response?.data?.message
        : "something went wrong"
    };
  }
};
