import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import { Avatar } from "@material-ui/core";

import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "80px",
    maxHeight: "260px",
    borderRadius: "11px",
    backgroundColor: "#FBAE02",
    boxShadow: " 0px 4px 4px rgba(0, 0, 0, 0.05)",
  },

  title: {
    fontFamily: theme.fontFamily.Reef,
    fontStyle: "normal",
    fontSize: "25px",

    textAlign: "center",
    color: "#fff",
  },
  subTitle: {
    fontFamily: theme.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: "900",
    fontSize: "10px",
    lineHeight: "14px",
    textAlign: "center",
  },
}));

function Cards(props) {
  const classes = useStyles();

  return (
    <Card className={props?.root}>
      <CardActionArea>
        <CardContent style={{ padding: "0px" }}>
          <Grid direction="column" container className={props?.containerStyle}>
            <Grid item style={{ alignSelf: "center" }}>
              {props?.icon ? (
                <Avatar
                  src={props?.image}
                  style={{
                    height: "60px",
                    width: "60px",
                  }}
                />
              ) : (
                <Typography className={props?.title}>
                  {props?.titleText}
                </Typography>
              )}
            </Grid>
            {props?.subTitle ? (
              <Grid item>
                <Typography
                  gutterBottom
                  className={
                    props.subTitleStyle ? props.subTitleStyle : classes.subTitle
                  }
                >
                  {props?.username}
                  {/* {"@username"} */}
                </Typography>
              </Grid>
            ) : (
              ""
            )}
          </Grid>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

export default Cards;
