// types
import {
  SET_CURRENT_LOGGED_IN_USER,
  UPDATE_USERNAME,
  UPDATE_PROFILE,
  UPLOADED_IMAGE,
  FORGOT_PASSWORD,
  LOG_OUT
} from "../actions/types";

// initial state
const initialState = {
  user: {},
  resetPasswordLink: null
};

// handle actions
export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case FORGOT_PASSWORD:
      return {
        ...state,
        resetPasswordLink: payload
      };
    case SET_CURRENT_LOGGED_IN_USER:
      return {
        ...state,
        user: payload
      };
    case UPDATE_USERNAME:
      return {
        ...state,

        user: { ...state.user, ...payload }
      };
    case UPDATE_PROFILE:
      return {
        ...state,
        user: { ...state.user, ...payload }
      };
    case UPLOADED_IMAGE:
      return {
        ...state,
        user: payload
      };
    case LOG_OUT: {
      return {
        ...initialState
      };
    }
    default:
      return state;
  }
}

