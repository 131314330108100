import React, { useState, useEffect } from "react";
import { Grid, Typography, useMediaQuery, CircularProgress, Badge, IconButton } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import { makeStyles, useTheme } from "@material-ui/styles";
import { Link } from "react-router-dom";
import CourseCard from "../../Common/CourseCard";
import Session from "../../../containers/Common/Session/index";

import Edit from "@material-ui/icons/Edit";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import CreateChallenge from "../../../containers/Web/More/CreateChallenge";
import {
  createChallengeData,
  addParticipants,
} from "../../../actions/challenges";
import SessionList from "../../../containers/Common/Session/SessionList";
import SessionDetails from "../../../components/Common/Session/SessionDetails";
import AllSessions from "../../../containers/Web/Session/AllSessions";
import moment from "moment";
import {
  getAllCreatedTrainingCourses,
  getEnrolledRetreats
} from "../../../actions/retreatCourse";

const actions = [{ icon: "Challenge" }, { icon: "Session" }];

function RelateWithFriends(props) {
  const classes = useStyles();
  let history = useHistory();
  const theme = useTheme();
  const dispatch = useDispatch();

  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [currentPage, setCurrentPage] = useState("relateWithFriends");
  const [direction] = React.useState("left");
  const [open, setOpen] = React.useState(false);
  const [retreatLoader, setRetreatLoader] = React.useState(false);
  const [teacherTrainingLoader, setTeacherTrainingLoader] = React.useState(false);
  const [editSession, setEditSession] = React.useState(false);
  const [hidden] = React.useState(false);
  const allChallenges = useSelector(
    (state) => state?.challenges?.allChallenges
  );
  const authReducer = useSelector((state) => state?.auth);
  const loader = useSelector((state) => state?.loader?.loader);
  let uploadedTrainingCoursesList = useSelector(state => state?.retreatCourse?.allUploadedTrainingCourses);
  let enrolledTrainingCoursesList = useSelector(state => state?.retreatCourse?.enrolledRetreats);
  let uploadedTeacherTrainingList = useSelector(state => state?.retreatCourse?.allUploadedTeacherTrainings);
  let enrolledTeacherTrainingList = useSelector(state => state?.retreatCourse?.enrolledTeacherTrainings);

  const getData = async () => {
    if (authReducer?.user?.role === 'Regular') {
      setRetreatLoader(true)
      const res = await dispatch(getEnrolledRetreats({ token: authReducer?.token, retreatType: 'retreat' }));
      if (res) {
        setRetreatLoader(false)
      }

      setTeacherTrainingLoader(true)
      const teacherTrainingRes = await dispatch(getEnrolledRetreats({ token: authReducer?.token, retreatType: 'teacherTraining' }));
      if (teacherTrainingRes) {
        setTeacherTrainingLoader(false)
      }
    } else if (authReducer?.user?.role !== 'Regular') {
      setRetreatLoader(true)
      const res = await dispatch(getAllCreatedTrainingCourses({ token: authReducer?.token, retreatType: 'retreat' }));
      if (res) {
        setRetreatLoader(false)
      }
      setTeacherTrainingLoader(true)
      const teacherTrainingRes = await dispatch(getAllCreatedTrainingCourses({ token: authReducer?.token, retreatType: 'teacherTraining' }));
      if (teacherTrainingRes) {
        setTeacherTrainingLoader(false)
      }
    }
  }

  useEffect(() => {
    getData()

    return () => {
      dispatch({
        type: 'GET_ALL_TRAINING_COURSE',
        payload: []
      });
      dispatch({
        type: 'GET_ENROLLED_RETREATS',
        payload: []
      });
      dispatch({
        type: 'GET_ALL_CREATED_TEACHER_TRAINING',
        payload: []
      });
      dispatch({
        type: 'GET_ENROLLED_TEACHER_TRAININGS',
        payload: []
      });
    }
  }, []);


  const handleChangePage = (page) => {
    dispatch(createChallengeData({}));
    dispatch(addParticipants([]));
    if (page === "Session") {
      history.push("/more/session/create");
    }
    if (page === "Challenge") {
      history.push("/more/create-challenge");
    }
    if (page === "Retreat") {
      history.push("/add-retreat");
    }
    if (page === "TeacherTraining") {
      history.push("/add-teacher-training");
    }

    handleClose();
  };

  const handleEditSesion = (page, edit) => {
    if (page === "Session" && isMobile && !isDesktop) {
      history.push({
        pathname: `/session`,
        editSession: true,
      });
    }

    if (page === "Challenge" && isMobile) {
      history.push("/create-challenge");
    }
    setCurrentPage(page);
    handleClose();
    setEditSession(edit);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const goToAllSection = () => {
    return history.push("/more/all-challenges");
  };

  return (
    <>
      <Grid
        container
        style={{
          padding: (currentPage !== "allsessions" && isMobile) ? "20px 15px 0px 15px" : (currentPage !== "allsessions" && !isMobile) ? "45px 25px 0px 25px" : "",

        }}
      >
        {currentPage === "relateWithFriends" ? (
          <>
            <Grid item container xs={12} lg={12} >
              <Grid item xs={9} lg={9}>
                <Typography className={classes.heading}> {authReducer?.user?.role !== "Regular" ? (
                  <>
                    My Sessions{" "}
                    <IconButton
                      onClick={() => handleChangePage("Session")}
                      className={classes.button}>
                      <Add />
                    </IconButton>

                  </>
                ) : (
                  "My Sessions"
                )}</Typography>
              </Grid>
              <Grid item xs={3} lg={3} style={{ textAlign: "right", paddingTop: '7px' }}>
                <Link to="/more/all-sessions" className={classes.linkStyle}>
                  view all
                </Link>
              </Grid>
              <Grid
                item
                container
                xs={12}
                lg={12}
                className={classes.hideScrollBar}
                style={{ marginTop: '2px' }}
              >
                <SessionList handleChangePage={handleEditSesion} />
              </Grid>
            </Grid>
            <Grid item container xs={12} lg={12} style={{ marginTop: "30px" }}>
              <Grid item xs={9} lg={9}>
                <Typography className={classes.heading}><>
                  My Challenges{" "}
                  <IconButton
                    onClick={() => handleChangePage("Challenge")}
                    className={classes.button}>
                    <Add />
                  </IconButton>

                </></Typography>
              </Grid>
              <Grid item xs={3} lg={3} style={{ textAlign: "right", paddingTop: '10px' }}>
                <div onClick={goToAllSection} className={classes.linkStyle}>
                  view all
                </div>
              </Grid>
              {loader ? (
                <Grid item xs={12} justify="center" className={classes.noContent} style={{ marginTop: '40px' }}>
                  <CircularProgress color="primary" size={20} />
                </Grid>)
                : (allChallenges?.length > 0 ? (
                  <Grid
                    item
                    container
                    xs={12}
                    lg={12}
                    spacing={2}
                    className={classes.hideScrollBar}

                  >
                    {allChallenges?.slice(0, 4).map((challenge) => {
                      return (
                        <Grid
                          item

                          key={challenge?.challenges?.id}

                        >
                          <Link
                            to={`/challenge/details/${challenge?.challenge?.id}`}
                            style={{ textDecoration: "none" }}
                          >
                            <CourseCard
                              image={challenge?.challenge?.imageUrl}
                              category={
                                challenge?.challenge?.course?.category?.name
                              }
                              friends={
                                challenge?.challenge?.participantsCount > 1
                                  ? `${challenge?.challenge?.participantsCount - 1} friends`
                                  : challenge?.challenge?.participantsCount === 1 ? `${challenge?.challenge?.participantsCount - 1} friend` : '0 friend'
                              }
                              name={challenge?.challenge?.title}
                              totalDuration={Math.round(
                                challenge?.challenge?.duration / 60
                              )}
                              time={moment(
                                challenge?.challenge?.exerciseTime,
                                "LTS"
                              ).format("LT")}
                              plan={challenge?.challenge?.plan}
                              price={challenge?.challenge?.price}
                            />
                          </Link>
                        </Grid>
                      );
                    })}
                  </Grid>
                ) : (
                  <Grid item xs={12}>
                    <Typography className={classes.noContent}>
                      No Record found
                    </Typography>
                  </Grid>
                ))}


            </Grid>

            <Grid item container xs={12} lg={12} style={{ marginTop: "30px" }}>
              <Grid item xs={12} lg={12}>
                <Typography className={classes.heading}><>
                  My Retreats{" "}
                  {authReducer?.user?.role !== 'Regular' && <IconButton
                    onClick={() => handleChangePage("Retreat")}
                    className={classes.button}>
                    <Add />
                  </IconButton>}

                </></Typography>
              </Grid>

              {retreatLoader ? (
                <Grid item xs={12} justify="center" className={classes.noContent} style={{ marginTop: '40px' }}>
                  <CircularProgress color="primary" size={20} />
                </Grid>)
                : ((authReducer?.user?.role === 'Regular' ? enrolledTrainingCoursesList?.length > 0 : uploadedTrainingCoursesList?.length > 0) ? (
                  <Grid
                    item
                    container
                    xs={12}
                    lg={12}
                    spacing={2}
                    className={classes.hideScrollBar}

                  >
                    {(authReducer?.user?.role === 'Regular' ? enrolledTrainingCoursesList : uploadedTrainingCoursesList).map((course) => {
                      return (

                        <Grid
                          item

                          style={{ marginTop: authReducer?.user?.role !== 'Regular' && '-25px' }}
                          key={course?.id}>
                          {authReducer?.user?.role !== 'Regular' && <Grid style={{

                            position: "relative",
                            top: "34px",
                            width: '230px',

                          }} container justify="space-between">
                            <Badge classes={{
                              badge: course?.status === "Active" ? classes.activeCourse : course?.status === "Incomplete" ? classes.incompleteCourse : course?.status === "Blocked" ? classes.blockedCourse : course?.status === "InActive" ? classes.inactiveCourse : ""
                            }} style={{ marginLeft: course?.status === "Active" ? "1.3rem" : course?.status === "InActive" ? "1.5rem" : course?.status === "Incomplete" ? "2rem" : course?.status === "Blocked" ? "1.5rem" : "0px" }} badgeContent={course.status} />
                            <Edit style={{
                              color: "#F28705",
                              cursor: "pointer",
                              zIndex: 1,
                              top: -10,
                              position: "relative",
                            }} onClick={() => history.push(`/edit-retreat/${course?.slug}`)} />
                          </Grid>}
                          <Link
                            to={`/retreat-details/${course?.id}`}
                            style={{ textDecoration: "none" }}
                          >
                            <CourseCard
                              image={course?.image}
                              showEdit={true}
                              courseId={course?.id}
                              courseSlug={course?.slug}
                              category={course?.category?.name}
                              name={course?.name}
                              totalDuration={course?.totalDuration}
                            />
                          </Link>
                        </Grid>

                      );
                    })}
                  </Grid>
                ) : (
                  <Grid item xs={12}>
                    <Typography className={classes.noContent}>
                      No Record found
                    </Typography>
                  </Grid>
                ))}

            </Grid>


            <Grid item container xs={12} lg={12} style={{ marginTop: "30px" }}>
              <Grid item xs={12} lg={12}>
                <Typography className={classes.heading}><>
                  My Teacher Trainings{" "}
                  {authReducer?.user?.role !== 'Regular' && <IconButton
                    onClick={() => handleChangePage("TeacherTraining")}
                    className={classes.button}>
                    <Add />
                  </IconButton>}

                </></Typography>
              </Grid>

              {teacherTrainingLoader ? (
                <Grid item xs={12} justify="center" className={classes.noContent} style={{ marginTop: '40px' }}>
                  <CircularProgress color="primary" size={20} />
                </Grid>)
                : ((authReducer?.user?.role === 'Regular' ? enrolledTeacherTrainingList?.length > 0 : uploadedTeacherTrainingList?.length > 0) ? (
                  <Grid
                    item
                    container
                    xs={12}
                    lg={12}
                    spacing={2}
                    className={classes.hideScrollBar}
                    style={{ paddingBottom: "100px" }}
                  >
                    {(authReducer?.user?.role === 'Regular' ? enrolledTeacherTrainingList : uploadedTeacherTrainingList).map((course) => {
                      return (

                        <Grid
                          item

                          style={{ marginTop: authReducer?.user?.role !== 'Regular' && '-25px' }}
                          key={course?.id}>
                          {authReducer?.user?.role !== 'Regular' && <Grid style={{

                            position: "relative",
                            top: "34px",
                            width: '230px',

                          }} container justify="space-between">
                            <Badge classes={{
                              badge: course?.status === "Active" ? classes.activeCourse : course?.status === "Incomplete" ? classes.incompleteCourse : course?.status === "Blocked" ? classes.blockedCourse : course?.status === "InActive" ? classes.inactiveCourse : ""
                            }} style={{ marginLeft: course?.status === "Active" ? "1.3rem" : course?.status === "InActive" ? "1.5rem" : course?.status === "Incomplete" ? "2rem" : course?.status === "Blocked" ? "1.5rem" : "0px" }} badgeContent={course.status} />
                            <Edit style={{
                              color: "#F28705",
                              cursor: "pointer",
                              zIndex: 1,
                              top: -10,
                              position: "relative",
                            }} onClick={() => history.push(`/edit-teacher-training/${course?.slug}`)} />
                          </Grid>}
                          <Link
                            to={`/teacher-training-details/${course?.id}`}
                            style={{ textDecoration: "none" }}
                          >
                            <CourseCard
                              image={course?.image}
                              showEdit={true}
                              courseId={course?.id}
                              courseSlug={course?.slug}
                              category={course?.category?.name}
                              name={course?.name}
                              totalDuration={course?.totalDuration}
                          plan={course?.plan}

                            />
                          </Link>
                        </Grid>

                      );
                    })}
                  </Grid>
                ) : (
                  <Grid item xs={12}>
                    <Typography className={classes.noContent}>
                      No Record found
                    </Typography>
                  </Grid>
                ))}
            </Grid>




          </>
        ) : currentPage === "Session" ? (
          <Grid item xs={12} lg={12}>
            <Session editSession={editSession} />
          </Grid>
        ) : currentPage === "SessionDetails" ? (
          <Grid item xs={12} lg={12}>
            <SessionDetails editSession={editSession} />
          </Grid>
        ) : currentPage === "allsessions" ? (
          <Grid item xs={12} lg={12}>
            <AllSessions
              handleChangePage={handleEditSesion}
              editSession={editSession}
            />
          </Grid>
        ) : (
          <Grid item xs={12} lg={12}>
            <CreateChallenge gokBack={handleChangePage} />
          </Grid>
        )}
      </Grid>
    </>
  );
}

export default RelateWithFriends;

const useStyles = makeStyles((theme) => ({
  heading: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "24px",
    color: "#000000",
    marginBottom: "10px",
  },
  linkStyle: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    cursor: "pointer",
    color: "#14D9D9",
    textDecoration: "none",
    marginRight: "20px",

  },
  button: {
    background: '#FBAE02 !important',
    boxShadow: '0px 2px 2px rgba(216, 118, 0, 0.18)',
    color: "#FFF",
    height: "40px",
    width: '40px'
  },
  exampleWrapper: {
    width: "82vw",
    marginRight: "40px",
    marginBottom: "30px",
    display: "flex",
    justifyContent: "flex-end",
    marginTop: theme.spacing(3),
  },
  radioGroup: {
    margin: theme.spacing(1, 0),
  },
  btnStyle: {
    background: "linear-gradient(180deg, #FFC000 -13.24%, #F28705 100%)",
    color: "#fff",
    width: "50px",
    height: "50px",
    fontFamily: theme.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: 900,
    fontSize: "10px",
    lineHeight: "19px",
    textAlign: "center",
  },
  activeCourse: {
    background: "#52b202",
    color: "#fff"
  },
  incompleteCourse: {
    background: "#ff9100",
    color: "#fff"
  },
  blockedCourse: {
    background: "#f44336",
    color: "#fff"
  },
  inactiveCourse: {
    background: "#6c757d",
    color: "#fff"
  },
  btnOPen: {
    background: "linear-gradient(180deg, #FFC000 -13.24%, #F28705 100%)",
    color: "#fff",
    width: "50px",
    height: "50px",
    fontFamily: theme.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: 900,
    fontSize: "10px",
    lineHeight: "19px",
    textAlign: "center",
  },
  speedDial: {
    position: 'fixed',
    "& .MuiFab-primary:hover": {
      width: "50px",
      height: "50px",
      color: "#E54D40",
      backgroundColor: "#E5E5E5 !important",
    },
    "& .MuiFab-primary": {
      width: "50px",
      height: "50px",
      backgroundColor: "#F28705 !important",
    },
    "&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft": {
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    "&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight": {
      top: theme.spacing(2),
      left: theme.spacing(2),
    },
  },
  noContent: {
    color: "#7d7b79",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "19px",
    padding: "12px 10px 10px 17px",
    textAlign: "center",
  },
  hideScrollBar: {
    flexWrap: "unset",
    overflowX: "auto",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
}));
