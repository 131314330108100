import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Edit } from "@material-ui/icons";
import { Link, useHistory } from "react-router-dom";
import {
  Typography,
  Grid,
  Avatar,
  MenuItem,
  Menu,
  IconButton,
  Button,
  CircularProgress,
} from "@material-ui/core";

import SoloExercise from "../../SoloExercise/Index";
import MyCourses from "../../../Web/Profile/MyCourses";
import { Add } from "@material-ui/icons";
import { getAllCategories } from "../../../../actions/courses";
import pencilIcon from "../../../../assets/Icons/pencilIcon.png";
import MyCoursesIcon from "../../../../assets/Icons/MyCoursesIcon.png";
import pencilIconWhite from "../../../../assets/Icons/pencilIconWhite.svg";
import ExerciseIcon from "../../../../assets/Icons/ExerciseIcon.svg";
import ExerciseIconWhite from "../../../../assets/Icons/ExerciseIconWhite.svg";
import MyCoursesIconWhite from "../../../../assets/Icons/CoursesIconWhite.svg";
import { useSelector, useDispatch } from "react-redux";
import { useIntl } from "react-intl";
import {
  createChallengeData,
  addParticipants,
} from "../../../../actions/challenges";
import { getUserFollowersFollowingsById } from "../../../../actions/publicUser";
import { resendEmail } from "../../../../actions/auth";

import "./profileInfo.scss";

function ProfileInfo(props) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const classes = useStyles();
  let history = useHistory();
  const intl = useIntl();
  const [loader, setLoader] = React.useState(false);

  const authReducerState = useSelector((state) => state?.auth);
  const user = useSelector((state) => state?.user?.user);
  const userFriends = useSelector((state) => state?.userRelations?.userFriends);
  const [screen, setScreen] = useState("info");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [userFollowingsFollowers, setUserFollowingsFollowers] = useState("");

  useEffect(() => {
    dispatch(getAllCategories({ token: authReducerState.token }));
  }, []);

  const handleScreen = (e, selectScreen) => {
    if (selectScreen === "exercise") {
      history.push("/solo-exercise");
    }
    e.preventDefault();
    setScreen(selectScreen);
  };
  const handleChangeScreen = (value) => {
    setScreen(value);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSelectedMenu = (path) => {
    setAnchorEl(null);
    if (path === "/more/create-challenge") {
      dispatch(createChallengeData({}));
      dispatch(addParticipants([]));
    }
    history.push(path);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const getData = async () => {
    const res = await dispatch(
      getUserFollowersFollowingsById({
        token: authReducerState?.token,
        userId: user?.id,
      })
    );
    if (res) {
      setUserFollowingsFollowers(res);
    }
  };

  useEffect(() => {
    user?.id && getData();
  }, [user]);
  const handleResendEmail = async () => {
    setLoader(true);
    let res = await dispatch(resendEmail(authReducerState?.user?.email));
    setLoader(false);

    // resendEmail()
  };
  return (
    <>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        style={{ textAlign: "center", top: "50px" }}
        PaperProps={{
          style: {
            maxHeight: "200px",
          },
        }}
      >
        <MenuItem
          onClick={() => handleSelectedMenu("add-course")}
          className={classes.addDialogBtn}
        >
          Add Course
        </MenuItem>
        <MenuItem
          onClick={() => handleSelectedMenu("/more/create-challenge")}
          className={classes.addDialogBtn}
        >
          Add Challenge
        </MenuItem>
        <MenuItem
          onClick={() => handleSelectedMenu("/more/session/create")}
          className={classes.addDialogBtn}
        >
          Add Session
        </MenuItem>
        <MenuItem
          onClick={() => handleSelectedMenu("/add-retreat")}
          className={classes.addDialogBtn}
        >
          Online Retreat
        </MenuItem>
        <MenuItem
          onClick={() => handleSelectedMenu("/add-teacher-training")}
          className={classes.addDialogBtn}
        >
          Online Teacher training
        </MenuItem>
        <MenuItem className={classes.addDialogBtn}></MenuItem>
      </Menu>
      <Grid container direction="column">
        {screen === "exercise" ? (
          <SoloExercise handleChangeScreen={handleChangeScreen} />
        ) : screen === "courses" ? (
          <MyCourses />
        ) : (
          <div style={{ height: "100%", width: "100%", margin: "auto" }}>
            <div
              className={classes.profilePic}
              style={{
                backgroundImage: `url(${user?.coverUrl}), linear-gradient(180deg, rgba(255, 255, 255, 0.23) 0%, rgba(0, 0, 0, 0.23) 39.58%, #000000 100%)`,
                backgroundPosition: "center",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
              }}
            >
              <Grid
                container
                direction="column"
                alignItems="center"
                alignContent="center"
                justify="center"
                style={{ padding: "0px 15px 0px 15px" }}
              >
                <Grid
                  item
                  container
                  xs={12}
                  direction="row"
                  justify="space-around"
                  style={{ paddingTop: "170px" }}
                >
                  <Grid item xs={8}>
                    <Typography className={classes.name}>
                      {user?.name}
                    </Typography>
                    <Typography className={classes.username}>
                      @{user?.username}
                    </Typography>
                  </Grid>

                  <Grid item xs={2}>
                    <Avatar
                      src={user?.pictureUrl}
                      className={classes.userPhoto}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </div>
            <div className="contentContainer">
              <Grid
                container
                style={{ marginTop: "30px", textAlign: "center" }}
                justify="center"
              >
                <Grid item xs={4}>
                  <Link
                    to="/more/connections/friends"
                    style={{ textDecoration: "none" }}
                  >
                    <Typography className="counts">
                      {userFriends?.length}
                    </Typography>
                    <Typography className="countsType">
                      {intl.formatMessage({ id: "friends" })}
                    </Typography>
                  </Link>
                </Grid>
                <Grid item xs={4}>
                  <Link
                    to="/more/connections/following"
                    style={{ textDecoration: "none" }}
                  >
                    <Typography className="counts">
                      {userFollowingsFollowers?.followings?.length || 0}
                    </Typography>
                    <Typography className="countsType">
                      {intl.formatMessage({ id: "following" })}
                    </Typography>
                  </Link>
                </Grid>
                <Grid item xs={4}>
                  <Link
                    to="/more/connections/followers"
                    style={{ textDecoration: "none" }}
                  >
                    <Typography className="counts">
                      {userFollowingsFollowers?.followers?.length || 0}
                    </Typography>
                    <Typography className="countsType">
                      {intl.formatMessage({ id: "followers" })}
                    </Typography>
                  </Link>
                </Grid>

                <Grid item xs={12} container>
                  <Grid xs={6}>
                    <Button
                      disableRipple={true}
                      className={classes.customButton}
                      onClick={() => history.push("/edit-profile-info")}
                    >
                      {"Edit profile"}
                    </Button>
                  </Grid>
                  <Grid xs={6}>
                    <Button
                      disableRipple={true}
                      className={classes.customButton}
                      onClick={() => history.push("/more/connections")}
                    >
                      {"Connections"}
                    </Button>
                  </Grid>
                </Grid>

                <Grid
                  container
                  item
                  spacing={2}
                  xs={12}
                  justify="center"
                  direction="row"
                  style={{ marginTop: "20px" }}
                >
                  <Grid item xs={4}>
                    <div
                      onClick={(e) => handleScreen(e, "info")}
                      className={
                        screen === "info" ? "cardActiveMobile" : "cardMobile"
                      }
                      style={{ lineHeight: "90px", cursor: "pointer" }}
                    >
                      <img
                        src={screen === "info" ? pencilIconWhite : pencilIcon}
                        alt="edit"
                      />
                    </div>
                    <Typography className={classes.titleMobile}>
                      {intl.formatMessage({ id: "info" })}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <div
                      onClick={(e) => handleScreen(e, "courses")}
                      className={
                        screen === "courses" ? "cardActiveMobile" : "cardMobile"
                      }
                      style={{ cursor: "pointer" }}
                    >
                      <img
                        src={
                          screen === "courses"
                            ? MyCoursesIconWhite
                            : MyCoursesIcon
                        }
                        alt="courses"
                      />
                    </div>
                    <Typography className={classes.titleMobile} align="center">
                      {intl.formatMessage({ id: "myCourses" })}
                    </Typography>
                  </Grid>
                  {authReducerState?.user?.role === "Instructor" ||
                  authReducerState?.user?.role === "Studio" ? (
                    <Grid item xs={4}>
                      <div
                        onClick={handleClick}
                        className={
                          screen === "exercise"
                            ? "cardActiveMobile"
                            : "cardMobile"
                        }
                        style={{ cursor: "pointer" }}
                      >
                        <Add style={{ fontSize: "48px", fontWeight: "bold" }} />
                      </div>
                      <Typography
                        className={classes.titleMobile}
                        align="center"
                      >
                        {intl.formatMessage({ id: "addCourse" })}
                      </Typography>
                    </Grid>
                  ) : (
                    <Grid item xs={4}>
                      <div
                        onClick={(e) => handleScreen(e, "exercise")}
                        className={
                          screen === "exercise"
                            ? "cardActiveMobile"
                            : "cardMobile"
                        }
                        style={{ cursor: "pointer" }}
                      >
                        <img
                          src={
                            screen === "exercise"
                              ? ExerciseIconWhite
                              : ExerciseIcon
                          }
                          alt="exercises"
                        />
                      </div>
                      <Typography
                        className={classes.titleMobile}
                        align="center"
                      >
                        {intl.formatMessage({ id: "soloExercise" })}
                      </Typography>
                    </Grid>
                  )}
                  {!user?.emailConfirmed && (
                    <Grid
                      item
                      container
                      xs={12}
                      style={{
                        marginTop: "0px",
                        paddingTop: "0px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          width: "100%",
                        }}
                      >
                        <p style={{ color: "red",padding:"0px 10px" }}>
                          Your Email is not verified. Please verify your Email
                        </p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          width: "100%",
                        }}
                      >
                        <Button
                          disableRipple={true}
                          disabled={loader}
                          className={classes.customButton}
                          onClick={() => handleResendEmail()}
                          style={{ width: "130px", display: "block" }}
                        >
                          {loader ? (
                            <CircularProgress
                              style={{ color: "#fff" }}
                              size={20}
                            />
                          ) : (
                            "Resend Email"
                          )}
                        </Button>
                      </div>
                    </Grid>
                  )}
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  justify="space-around"
                  direction="row"
                  style={{ marginTop: "20px", marginBottom: "100px" }}
                >
                  <Grid item xs={11} sm={11}>
                    <Typography className="infoTitleMobile">
                      {intl.formatMessage({ id: "title" })}
                    </Typography>
                    <Typography className="infoSubTitleMobile">
                      {user?.title}
                    </Typography>
                  </Grid>
                  <Grid item xs={11} sm={11}>
                    <Typography className="infoTitleMobile">
                      {intl.formatMessage({ id: "city" })}
                    </Typography>
                    <Typography className="infoSubTitleMobile">
                      {user?.city}
                    </Typography>
                  </Grid>
                  <Grid item xs={11} sm={11}>
                    <Typography className="infoTitleMobile">
                      {intl.formatMessage({ id: "emailAddress" })}
                    </Typography>
                    <Typography className="infoSubTitleMobile">
                      {user?.email}
                    </Typography>
                  </Grid>
                  {user?.role !== "Regular" && (
                    <Grid item xs={11} sm={11}>
                      <Typography className="infoTitleMobile">
                        {intl.formatMessage({ id: "websiteUrl" })}
                      </Typography>
                      <a
                        href={
                          /(http(s?)):\/\//i.test(user?.websiteUrl)
                            ? user?.websiteUrl
                            : `http://${user?.websiteUrl}`
                        }
                        target="_blank"
                        className={classes.link}
                      >
                        <Typography className="infoSubTitleMobile">
                          {user?.websiteUrl}
                        </Typography>
                      </a>
                    </Grid>
                  )}
                  {user?.role !== "Regular" && (
                    <Grid item xs={11} sm={11}>
                      <Typography className="infoTitleMobile">
                        {intl.formatMessage({ id: "facebookUrl" })}
                      </Typography>
                      <a
                        href={
                          /(http(s?)):\/\//i.test(user?.facebookUrl)
                            ? user?.facebookUrl
                            : `http://${user?.facebookUrl}`
                        }
                        target="_blank"
                        className={classes.link}
                      >
                        <Typography className="infoSubTitleMobile">
                          {user?.facebookUrl}
                        </Typography>
                      </a>
                    </Grid>
                  )}
                  {user?.role !== "Regular" && (
                    <Grid item xs={11} sm={11}>
                      <Typography className="infoTitleMobile">
                        {intl.formatMessage({ id: "instagramUrl" })}
                      </Typography>
                      <a
                        href={
                          /(http(s?)):\/\//i.test(user?.instagramUrl)
                            ? user?.instagramUrl
                            : `http://${user?.instagramUrl}`
                        }
                        target="_blank"
                        className={classes.link}
                      >
                        <Typography className="infoSubTitleMobile">
                          {user?.instagramUrl}
                        </Typography>
                      </a>
                    </Grid>
                  )}
                  {user?.role !== "Regular" && (
                    <Grid item xs={11} sm={11}>
                      <Typography className="infoTitleMobile">
                        {intl.formatMessage({ id: "youtubeChannelUrl" })}
                      </Typography>
                      <a
                        href={
                          /(http(s?)):\/\//i.test(user?.youtubeChannelUrl)
                            ? user?.youtubeChannelUrl
                            : `http://${user?.youtubeChannelUrl}`
                        }
                        target="_blank"
                        className={classes.link}
                      >
                        <Typography className="infoSubTitleMobile">
                          {user?.youtubeChannelUrl}
                        </Typography>
                      </a>
                    </Grid>
                  )}

                  <Grid item xs={11} sm={11}>
                    <Typography className="infoTitleMobile">
                      {intl.formatMessage({ id: "description" })}
                    </Typography>

                    <Typography className="infoSubTitleMobile">
                      {user?.id && user?.role !== "Regular" ? (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: unescape(user?.description),
                          }}
                        />
                      ) : (
                        user?.description
                      )}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </div>
        )}
      </Grid>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "100%",
  },

  profilePic: {
    height: "26.250em",

    width: "100%",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    zIndex: 1,
    position: "absolute",
  },
  btnStyle: {
    background: "linear-gradient(180deg, #FFC000 -13.24%, #F28705 100%)",
    color: "#fff",
    height: "50px",
    width: "50px",
    fontFamily: theme.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: 900,
    fontSize: "10px",
    lineHeight: "19px",
    textAlign: "center",
  },
  speedDial: {
    position: "fixed",
    "& .MuiFab-primary:hover": {
      width: "50px",
      height: "50px",
      color: "#E54D40",
      backgroundColor: "#E5E5E5 !important",
    },
    "& .MuiFab-primary": {
      width: "50px",
      height: "50px",
      backgroundColor: "#F28705 !important",
    },
    "&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft": {
      bottom: theme.spacing(2),
      right: theme.spacing(1),
    },
    "&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight": {
      top: theme.spacing(2),
      left: theme.spacing(1),
    },
  },
  addDialogBtn: {
    width: "100%",
    textAlign: "center",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "21px",
    color: "#F28705",
  },
  userPhoto: {
    lineHeight: "26.250em",
    marginTop: "-20px",
    height: "75px",
    width: "75px",
    textAlign: "center",
  },
  name: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "28px",
    color: " #FFFFFF",
    textAlign: "left",
  },
  username: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",

    textAlign: "left",
    color: "#FFFFFF",
  },
  editIcon: {
    background: "#6E6E6E !important",
    marginLeft: "5px",
  },
  link: {
    textDecoration: "none",
    "& :hover": {
      color: "#09a9bb",
    },
  },
  titleMobile: {
    fontFamily: "Roboto",
    fontFtyle: "normal",
    fontWeight: "bold",
    fontSize: "12px",
    lineHeight: "21px",
    color: "#3c4a6b",
    marginTop: "7px",
    textAlign: "center",
  },
  customButton: {
    background: `linear-gradient(360deg, #09A9BB 100%, #09A9BB 0%)`,
    height: "2.463em",
    width: "85%",
    borderRadius: "10px",
    transform: "rotate(360deg)",
    fontFamily: theme.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "10px",
    color: "#FFFFFF",

    marginTop: "12px",
  },
}));

export default ProfileInfo;
