import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import SocialButtonContainer from "../../components/Auth/SocialLogin";
import Signup from "../../components/Auth/Signup";
import Login from "../../components/Auth/OtpLogin";
import Ellipse4 from "../../assets/Ellipse4.svg";
import { useLocation } from "react-router-dom";
import RelateIcon from "../../assets/Icons/RelateIcon.png";
import "./auth.scss";
import { useIntl } from "react-intl";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={2}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

export default function AuthTabs() {
  const classes = useStyles();
  const intl = useIntl();

  let location = useLocation();
  const [value, setValue] = React.useState(
    location && location.id
      ? location.id
      : new URLSearchParams(location.search).get("tab") === "signup"
        ? 1
        : 0
  );

  const handleChange = newValue => {
    setValue(newValue);
  };

  const setSignIn = () => {
    setValue(0);
  };


  return (
    <Grid container>
      <Grid item container xs={12}>
        {value === 1 ? (
          <Grid item xs={2}>
            <div>
              <img src={Ellipse4} className={classes.topLeft} alt="" />
            </div>

          </Grid>
        ) : (
          <Grid item xs={2}>
            <div
              className={classes.topLeftSmall}
              style={{ backgroundColor: "#14D9D9" }}
            />
          </Grid>
        )}

        <Grid item xs={6} justify="center" className={classes.centerContainer}>
          <a href={process.env.REACT_APP_LANDING_URL}>
            <img src={RelateIcon} alt="logo" style={{ width: "100px" }} />
          </a>
        </Grid>
        {value === 0 ? (
          <Grid item xs={2}>
            <div className={classes.topRightCircle}></div>
          </Grid>
        ) : (
          <Grid item xs={2}>
            <div
              className={classes.topLeftSmall}
              style={{ backgroundColor: "#D95959" }}
            />
          </Grid>
        )}
      </Grid>

      <Grid item container xs={12} style={{ textAlign: "center" }}>
        <Grid container justify="center">
          <Grid item xs={10} className={classes.paperContainer}>
          {/*   <Grid container justify="center">
              <Grid
                item
                onClick={() =>
                  value === 1 ? handleChange(0) : handleChange(1)
                }
                xs={10}
              >
                <Paper className={classes.controllerPaper} elevation={8}>
                  <Typography className={classes.controllerTitle}>
                    {value === 0
                      ? intl.formatMessage({ id: "sign_up" })
                      : intl.formatMessage({ id: "sign_in" })}
                  </Typography>
                </Paper>
              </Grid>
            </Grid>
 */}
            <Paper elevation={8}>
              <Grid
                container
                className={classes.topBarContainer}
                justify="center"
              >
                <Grid item xs={6}>
                  <div
                    className={classes.topBar}
                    style={{
                      backgroundColor: value === 0 ? "#387F4D" : "#F13131"
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container className={classes.formContainer}>
                <Typography className={classes.title}>
                  {
                    intl.formatMessage({ id: "sign_inOtp" })
                    }
                </Typography>

                <Grid item xs={12}>
                  {<Login /> /* : <Signup setSignIn={setSignIn} />*/ }
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        {/*   <SocialButtonContainer /> */}
        </Grid>
      </Grid>

      {/* <Grid item xs={10} /> */}
    </Grid>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: "#FFFFF !important",
    width: 380
  },
  title: {
    fontFamily: theme.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: 900,
    fontSize: "32px",
    lineHeight: "44px",
    textTransform: "uppercase"
  },

  topBarContainer: {
    padding: theme.spacing(2)
  },
  topBar: {
    borderRadius: "16px",
    width: "100%",
    height: theme.spacing(0.8)
  },
  topLeft: {
    width: "120px",
    height: "120px",
    top: "-10px",
    transform: "rotate(-90deg)",
    marginBottom: theme.spacing(16)
  },
  topRightCircle: {
    width: "100px",
    height: "200px",
    borderRadius: "110px 0px 0px 110px",
    float: "right",
    backgroundColor: "#12A6A6",
    textAlign: "center",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: theme.spacing(7)
  },
  tabPanel: {
    backgroundColor: "white"
  },
  paperContainer: {
    position: "relative",
    top: "-125px",

    zIndex: 5
  },
  centerContainer: {
    textAlign: "center",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: theme.spacing(6)
  },
  formContainer: {
    padding: theme.spacing(2)
  },
  topLeftSmall: {
    width: "46px",
    height: "46px",
    borderRadius: "100%",
    float: "left",
    marginTop: theme.spacing(13),
    position: "relative",
    top: "3px",
    left: "10px"
  },
  controllerPaper: {
    padding: `10px 16px 16px 16px`,
    zIndex: -1,
    position: "relative",
    top: "11px"
  },
  controllerTitle: {
    zIndex: 100,
    fontFamily: theme.fontFamily.Reef,
    textTransform: "uppercase",
    fontStyle: "normal",
    fontWeight: "900",
    fontSize: "18px",
    lineHeight: "25px",
    color: "rgba(52, 52, 52, 1)",
    marginTop: "1px"
  },
  fbBtn: {
    background: "linear-gradient(209.7deg, #0075FF 4.58%, #0B3A70 88.99%);",
    boxShadow: "0px 3px 6px rgba(18, 166, 166, 0.25)",
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "24px",
    letterSpacing: "0.09em",
    color: "#FFFFFF",
    textTransform: "none"
  },

  gBtn: {
    background: "linear-gradient(210.07deg, #FFFFFF 13.46%, #FFFFFF 76.64%);",
    boxShadow: "0px 3px 6px rgba(18, 166, 166, 0.25)",
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: 200,
    fontSize: "18px",
    letterSpacing: "0.09em",
    color: "#FFFFFF",
    textTransform: "none"
  }
}));
