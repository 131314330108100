import React from "react";
import { Grid, useMediaQuery, CircularProgress } from "@material-ui/core";
import ProfileInfo from "../../../components/ViewProfile/ProfileInfo";
import UserEnrolledInfo from "../../../components/ViewProfile/UserEnrolledInfo";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";

function ViewProfile() {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const loader = useSelector((state) => state?.loader?.loader);

  return (
    <Grid container style={{ backgroundColor: "#fcfcfc" }}>
      {loader ? (
        <Grid item xs={12} className={classes.loaderStyle}>
          <CircularProgress color="primary" size={40} />
        </Grid>
      ) : (
        <>
          <Grid
            item
            xs={12}
            sm={12}
            md={4}
            lg={4}
            className={!isMobile && classes.scroll}
          >
            <ProfileInfo />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={8}
            lg={8}
            style={{
              paddingTop: isMobile ? "30px" : "20px",
              paddingLeft: !isMobile && "40px",
            }}
            className={!isMobile && classes.scroll}
          >
            <UserEnrolledInfo />
          </Grid>
        </>
      )}
    </Grid>
  );
}

export default ViewProfile;

const useStyles = makeStyles((theme) => ({
  scroll: {
    height: "100vh",
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  loaderStyle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
  },
}));
