import React, { useState, useEffect } from 'react';
import { Grid, Typography, Avatar, Divider, CircularProgress } from '@material-ui/core'
import { makeStyles } from "@material-ui/core/styles";
import AssignmentApproveCard from './AssignmentApproveCard';
import { useDispatch, useSelector } from 'react-redux'
import { getStudentSubmittedSectionAssignmentsFiles } from '../../../../../actions/retreatCourse'

function SeeSubmission(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [loader, setLoader] = useState(false)
    const [selectedUser, setSelectedUser] = useState('')
    const [playingLesson, setPlayingLesson] = useState(null)
    const course = useSelector(state => state?.retreatCourse?.trainingCourseDetails);
    const participants = useSelector(state => state?.retreatCourse?.trainingCourseParticipants);
    const assignments = useSelector(state => state?.retreatCourse?.studentSubmittedSectionAssignments);
    const user = useSelector(state => state?.auth);
    const lesson = useSelector(state => state?.retreatCourse?.toBePlayedLesson);


    const getData = async () => {
        if (playingLesson) {
            setLoader(true)
            const res = await dispatch(getStudentSubmittedSectionAssignmentsFiles({ sectionId: playingLesson?.sectionId, token: user?.token, studentId: selectedUser?.user?.id }));
            if (res) {
                setLoader(false)
            }
        }
    }

    useEffect(() => {

        lesson && setPlayingLesson(lesson)
    }, [lesson])

    useEffect(() => {
        getData();
        return () => {
            dispatch({
                type: 'STUDENT_SUBMITTED_ASSIGNMENTS_FILES',
                payload: []
            });
        }
    }, [playingLesson, selectedUser]);

    useEffect(() => {
        if (participants?.length > 0) {
            setSelectedUser(participants[0]);
        }

    }, [participants])



    return (
        <Grid container spacing={2} className={classes.container} >
            <Grid item container xs={12} spacing={1}>
                {
                    participants?.length > 0 ? participants?.map(participant => <Grid item onClick={() => setSelectedUser(participant)} key={participant?.id} style={{ cursor: 'pointer' }}>
                        <Avatar src={participant?.user?.pictureUrl} style={{ height: participant?.id === selectedUser?.id && '45px', width: participant?.id === selectedUser?.id && '45px' }} >{participant && participant?.user?.name[0]}</Avatar>
                        <Typography>{participant?.user?.username}</Typography>
                    </Grid>) : <Grid item xs={12} className={classes.noContent}>
                        <Typography className={classes.noContentText}>No user found</Typography>
                    </Grid>
                }

            </Grid>

            <Grid item xs={12}>
                <Divider />
            </Grid>

            <Grid item xs={12} container>
                {loader ? <Grid item xs={12} className={classes.noContent}>
                    <CircularProgress size={20} style={{ color: '#09a9bb' }} />
                </Grid>
                    : assignments?.length > 0 ?
                        <Grid item xs={12} container spacing={1} className={classes.container}>
                            {assignments?.map((assignment, ind) => <Grid item xs={12} key={assignment?.id}> < AssignmentApproveCard assignment={assignment} ind={ind} /></Grid>)
                            }
                        </Grid> : <Grid item xs={12} className={classes.noContent}>
                            <Typography className={classes.noContentText}>No assignment found</Typography>
                        </Grid>

                }
            </Grid>
        </Grid>
    );
}

export default SeeSubmission;

const useStyles = makeStyles(theme => ({
    username: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '8px',
        lineHeight: '16px',
        color: '#212121',

    },
    container: {
        maxHeight: '400px',
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
            width: '0px',
            background: 'transparent'
        }

    },
    noContent: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: "40px"
    },
    noContentText: {
        fontFamily: theme.fontFamily.Roboto,
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "14px",
        color: "#666666",

    }

}));