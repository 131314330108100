import React, { useEffect, useState } from 'react';
import { IconButton, Grid, CircularProgress, Typography, Box, Tooltip } from '@material-ui/core'
import { Check, Pause, PlayArrow } from '@material-ui/icons'
import { makeStyles, withStyles } from "@material-ui/core/styles";
import moment from 'moment'
import Truncate from "react-truncate";

function CircularProgressWithLabel(props) {
    return (
        <Box position="relative" display="inline-flex">
            <CircularProgress variant="determinate" {...props} size={46} style={{ color: '#09A9BB' }} />
            <Box
                top={0}
                left={0}
                bottom={0}
                right={0}
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                <IconButton color="primary" aria-label="upload picture" component="span" style={{ background: '#D3F0F3', color: '#09A9BB', height: '17px', width: '17px' }}>
                    {<Pause />}
                </IconButton>
            </Box>
        </Box>
    );
}

function ProgressCard(props) {
    const classes = useStyles();

    return (
        <Grid container className={classes.container}>
            <Grid item item xs={8} container spacing={1} className={classes.container}>
                <Grid item xs={3}>
                    {props?.lesson?.watchStatus !== 'Started' ? <IconButton color="primary" aria-label="upload picture" component="span" style={{ background: props?.lesson?.watchStatus === 'completed' ? '#09A9BB' : '#D3F0F3', color: props?.lesson?.watchStatus === 'completed' ? '#fff' : '#09A9BB' }}>
                        {props?.lesson?.watchStatus === 'Completed' ? <Check /> : <PlayArrow />}
                    </IconButton> :
                        <CircularProgressWithLabel value={25} />
                    }
                </Grid>
                <Grid item xs={9}>
                    <Tooltip title={props?.lesson?.name}>
                        <Typography className={classes.content}>
                            <Truncate lines={2} ellipsis={<span>...</span>} >
                                {props?.lesson?.name}
                            </Truncate>
                        </Typography>
                    </Tooltip>
                </Grid>

            </Grid>
            <Grid item xs={4} >
                <Typography className={classes.content} style={{ justifyContent: 'flex-end', textAlign: 'right' }}>
                    {props?.lesson?.type === 'live' ? `Live: ${moment.utc(props?.lesson?.date).local().format('DD/MM/YYYY')} ${moment.utc(props?.lesson?.startDateTime).local().format('hh:mm A')}` : "Recorded"}
                </Typography>

            </Grid>

        </Grid>
    );
}

export default ProgressCard;

const useStyles = makeStyles(theme => ({
    content: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '16px',
        lineHeight: '100%',
        color: '#353B35',
        display: 'flex',
        alignItems: 'center',
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer'
    },
    container: {
        display: 'flex',
        alignItems: 'center',
        height: '70px'
    },

    center: {
        display: 'flex',
        alignItems: 'center'
    },

}));