import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Grid, TextField, CircularProgress } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { useSelector, useDispatch } from "react-redux";
import CardWithThumbnail from "../../Common/Session/InviteFriendCard";
import CheckIcon from "@material-ui/icons/Check";
import AddIcon from "@material-ui/icons/Add";
import {
  searchUsers,
} from "../../../actions/userRelations";

function InviteFriends(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const authReducer = useSelector((state) => state?.auth);
  let loader = useSelector((state) => state?.loader?.loader);
  const searchedFriends = useSelector(
    (state) => state?.userRelations?.searchUsers
  );
  function getImage(props) {
    return <div>{props}</div>;
  }
 

  const handleSearch = () => {
    dispatch(searchUsers({ token: authReducer?.token, searchTerm }));
  };
  useEffect(() => {
    dispatch({
      type: 'SEARCH_USERS',
      payload: []})
      
  },[])

  useEffect(
   
    () => {
      if(searchTerm === ''){dispatch({ type: 'LOADER', payload: false })}
      const timeout = setTimeout(() => {
        searchTerm !== '' && dispatch(searchUsers({ token: authReducer?.token, searchTerm }));
      }, 2000);
      
      return () => clearTimeout(timeout);
    },
    [searchTerm]
  );

  return (
    <>
      <TextField
        className={classes.homeSearchField}
        type="Search"
        value={searchTerm}
        style={{ width: "100%" }}
        placeholder="Search..."
        onChange={(e) => {
          dispatch({ type: 'LOADER', payload: true });
          setSearchTerm(e.target.value)
        }}
        onKeyPress={(e) => {
          (e.which === 13 || e.keyCode === 13) && handleSearch();
        }}
        name="search"
        InputProps={{
          endAdornment: (
            <SearchIcon
              style={{ cursor: "pointer" }}
              onClick={handleSearch}
            />
          ),
        }}
      />

      <Grid container  style={{minHeight:'200px'}}>
        {loader ? (<Grid item xs={12} style={{ textAlign: 'center' }}><CircularProgress style={{ color: '#F28705' }} size={20} /></Grid>) :
          (<Grid item lg={12} xs={12}>
            {searchedFriends?.length === 0 ||
              searchedFriends?.filter((u) =>
                u?.name?.toUpperCase()?.includes(searchText?.toUpperCase())
              )?.length === 0 ? (
                <Typography className={classes.noContent}>
                  No Record Found
                </Typography>
              ) : (
                searchedFriends &&
                searchedFriends
                  .filter((u) =>
                    u.name.toUpperCase().includes(searchText?.toUpperCase())
                  )
                  .map((friend, index) => {
                    return (
                      <CardWithThumbnail
                        rootCard={classes.root}
                        title={friend?.name}
                        IconContainerBackground={
                          props?.chcekInvited(friend.id)
                            ? "#A9A9A9"
                            : "linear-gradient(180deg, #FFC000 -13.24%, #F28705 100%)"
                        }
                        Icon={getImage(
                          props?.chcekInvited(friend.id) ? (
                            <CheckIcon />
                          ) : (
                              <AddIcon />
                            )
                        )}
                        handleClick={"handleClick"}
                        handleInvite={props?.handleInvite}
                        iconStyle={classes.iconStyle}
                        currentIcon={"item.currentIcon"}
                        handleChange={"handleChangeStatus"}
                        titleStyle={classes.title}
                        showImage={true}
                        friend={friend}
                        showIconButton={false}
                        actionBtnStyle={classes.actionBtnStyle}
                        currentAction={"currentAction"}
                        body={
                          friend && friend.username ? `@${friend?.username}` : ""
                        }
                        bodyStyle={classes.body}
                        showLoader={"showLoader"}
                        id={friend.id}
                      />
                    );
                  })
              )}
          </Grid>)}
      </Grid>
    </>
  );
}

export default InviteFriends;

const useStyles = makeStyles((theme) => ({
  noContent: {
    color: "#7d7b79",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "19px",
    textAlign: "center",
  },
  title: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "italic",
    fontWeight: "bold",
    fontSize: "18px",
    lineHeight: "21px",
    color: "#3C4A6B",
  },
  body: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "21px",
    color: "#3C4A6B",
  },
  root: {
    width: "100%",
    height: "5rem",
    transform: "rotate(360deg)",
    borderBottom: "1px solid rgba(168, 182, 200, 0.3)",
  },
  actionBtnStyle: {
    minWidth: "57px",
  },

  homeSearchField: {
    "& .MuiInputBase-root": {
      backgroundColor: '#dce8f2',
      color: '#A9A9A9',
      fontFamily: theme.fontFamily.Roboto,
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "18px",
      lineHeight: "21px",
      padding: "10px",
      minHeight: "64px",
      alignSelf: "center",
      marginTop: "12px",
      marginBottom: "20px",
      height: "58px",
      width: "100%",
      borderRadius: "10px",
    },
    "& .MuiBadge-colorPrimary": {
      backgroundColor: "#F9A702;",
    },

    "& .MuiInput-underline:before": {
      border: "0px solid #FBF1E0",
    },
    "& .MuiInput-underline::after": {
      border: "0px solid #FBF1E0",
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled)::before": {
      border: "0px solid #FBF1E0",
    },
  },
}));
