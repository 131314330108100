import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  Dialog,
  DialogContent,
  DialogActions
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const useStyles = makeStyles({
  root: {
    color: "#eaeaf0",
    display: "flex",
    height: 22,
    alignItems: "center"
  },
  active: {
    color: "#FBAE02",
    "& > div": {
      width: 28,
      height: 28,
      backgroundColor: "#FBAE02"
    }
  },
  circle: {
    width: 14,
    height: 14,
    border: `4px solid currentColor`,
    boxSizing: "border-box",
    borderRadius: "50%"
  },
  completed: {
    backgroundColor: "#3C4A6B",
    color: "#fff",
    borderRadius: "50%",
    zIndex: 1,
    fontSize: 18
  },
  activeTypography: {
    color: "#FBAE02",
    cursor: "pointer"
  },

  completedTypography: {
    color: "#3C4A6B",
    cursor: "pointer"
  },

  inProgressTypography: {
    color: "#C4C4C4",
    cursor: "pointer"
  },
  link: {
    background: "#09A9BB",
    color: "#fff",
    padding: ".5rem",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "none"
    }
  }
});

const AddLinkAccountDialog = props => {
  let classes = useStyles();
  let authReducerState = useSelector(state => state.auth);
  let userData = useSelector(state => state.user);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (
      userData.user &&
      userData.user.id &&
      (!userData.user.pictureUrl ||
        !userData.user.coverUrl ||
        !userData.user.title ||
        !userData.user.description)
    ) {
      setOpen(true);
    }
  }, [userData]);

  return (
    <>
      {authReducerState?.user?.role &&
        authReducerState.user.role !== "Regular" && (
          <Dialog open={open}>
            <DialogContent style={{ minWidth: "40vw" }}>
              <Grid container justify="center">
                <Typography component={"h4"} variant={"h6"}>
                  Your profile is incomplete
                </Typography>
              </Grid>
              <Grid container justify="center">
                <Typography component={"p"} variant={"p"}>
                  You have not completed your profile. kindly complete your
                  profile and then proceed to add {props.type}.
                </Typography>
              </Grid>
            </DialogContent>
            <DialogActions>
              {(!userData.user.pictureUrl ||
                !userData.user.coverUrl ||
                !userData.user.title ||
                !userData.user.description) && (
                <Link className={classes.link} to={"/profile"}>
                  Complete your profile
                </Link>
              )}
              <Link className={classes.link} to={"/dashboard"}>
                Home
              </Link>
            </DialogActions>
          </Dialog>
        )}
    </>
  );
};
export default AddLinkAccountDialog;
