import { useState } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Button } from "@material-ui/core";

import { useSelector, useDispatch } from 'react-redux'

import { applyForJoinTrainingCourse, joinFreeRetreat, joinUnderSubscriptionRetreat } from '../../actions/retreatCourse';
import CustomerCardList from "../Common/CustomerCardList";
import RecommendUpgrade from "../Common/recommentUpgradeSubscriptionPopup";
import {getPrice} from "../../common/methods"
import moment from 'moment';

function JoinTrainingCourse(props) {
    const classes = useStyles();
    let reducerUser = useSelector(state => state?.user?.user);

    const dispatch = useDispatch()
    const [paymentDialog, setPaymentDialog] = useState(false);
    const [recommendUpgrade, setRecommendUpgrade] = useState(false);
    const user = useSelector(state => state?.auth);
    const course = useSelector(state => state?.retreatCourse?.trainingCourseDetails);
    const participants = useSelector(state => state?.retreatCourse?.trainingCourseParticipants);
    let configurations = useSelector(state => state?.setting?.siteConfig);

    const handleJoinCourse = async () => {
        if (course?.plan?.name === 'Free') {
            let res = await dispatch(joinFreeRetreat({ token: user?.token, trainingCourseId: course?.id }));
            if (res === 'success') {
                setRecommendUpgrade(true);
            }

        } else if (course?.plan?.name === 'Subscription') {
            let res = await dispatch(joinUnderSubscriptionRetreat({ token: user?.token, trainingCourseId: course?.id }));
            if (res === 'success') {
                setRecommendUpgrade(true);
            }

        } else {
            setPaymentDialog(true)
        }
    }

    const handleSendRequest = () => {
        dispatch(applyForJoinTrainingCourse({ token: user?.token, courseId: course?.id }));
    }



    return (
      <div>
        <CustomerCardList
          open={paymentDialog}
          setOpen={setPaymentDialog}
          setRecommendUpgrade={setRecommendUpgrade}
          type="retreat"
          retreatId={course?.id}
          plan={course?.plan}
          // price={course?.price}
          price={getPrice(
            course?.price,
            reducerUser?.purchasedSubscription,
            "retreat"
          )}
        />
        <RecommendUpgrade
          recommend={recommendUpgrade}
          setRecommendUpgrade={() => setRecommendUpgrade(false)}
        />
        {participants?.length >= course?.noOfStudent ? (
          <Typography className={classes.joinedDate}>
            Join Limit Reached
          </Typography>
        ) : course?.userTakingStatus &&
          course?.userTaking?.status === "Joined" ? (
          <Typography className={classes.joinedDate}>
            Joined on{" "}
            {moment(course?.userTaking?.createdAt).format("DD, MMMM YYYY")}
          </Typography>
        ) : !course?.isPrivate ? (
          <Button className={classes.joinNowButton} onClick={handleJoinCourse}>
            {course?.plan?.name === "Paid"
              ? <>{`Join `} 
              <strike>{`${configurations?.currency}${course?.price
              ? course?.price
                : 0}`
              }
              </strike>
              
              <span style={{paddingLeft:"2px"}}>{`(${configurations?.currency}${
                getPrice( course?.price ? course?.price : 0,reducerUser?.purchasedSubscription,"retreat")})`
              }
              </span></>
              : "Join Now"}
          </Button>
        ) : course?.isPrivate &&
          course?.userTaking &&
          course?.userTaking?.status === "Applied" ? (
          <Button className={classes.requestSent}>{"Request Sent"}</Button>
        ) : course?.userTakingStatus &&
          course?.userTaking?.status === "Rejected" ? (
          <Typography className={classes.rejected}>Request Rejected</Typography>
        ) : course?.userTakingStatus &&
          course?.userTaking?.status === "Accepted" ? (
          <Button className={classes.joinNowButton} onClick={handleJoinCourse}>
            {course?.plan?.name === "Paid"
              ? 

                <>{`Join `} 
                            <strike>{`${configurations?.currency}
                            ${course?.price
                            ? course?.price
                              : 0}`
                            }
                            </strike>
                            
                            <span style={{paddingLeft:"2px"}}>{`(${configurations?.currency}${
                              getPrice( course?.price ? course?.price : 0,reducerUser?.purchasedSubscription,"retreat")})`
                            }
                            </span></>
              : "Join Now"}
          </Button>
        ) : (
          <Button className={classes.joinNowButton} onClick={handleSendRequest}>
            {"Send Join Request"}
          </Button>
        )}
      </div>
    );
}

export default JoinTrainingCourse;

const useStyles = makeStyles(theme => ({

    joinedDate: {
        fontFamily: 'Roboto',
        fontStyle: 'italic',
        fontWeight: 'bold',
        fontSize: '14px',
        lineHeight: '100%',
        color: '#818581',
        float: 'right',

    },
    joinNowButton: {
        minWidth: '90px',
        color: '#fff',
        float: 'right',
        background: '#09A9BB',
        '&:hover': {
            background: '#09A9BB',
        }
    },
    requestSent: {
        minWidth: '90px',
        color: '#fff',
        float: 'right',
        background: '#9F9F9F',
        textTransform: 'none',
        '&:hover': {
            background: '#9F9F9F',
        }
    },
    rejected: {
        fontFamily: 'Roboto',
        fontStyle: 'italic',
        fontWeight: 'bold',
        fontSize: '14px',
        lineHeight: '100%',
        color: 'red',
        float: 'right',
    }


}));