import React from 'react';
import { Grid, Typography } from '@material-ui/core'
import { makeStyles } from "@material-ui/core/styles";
import moment from 'moment'

function Announcements(props) {
    const classes = useStyles();
    const { announcement } = props
    return (
        <Grid container>
            <Grid item xs={2} sm={2} md={2} lg={1} xl={1} className={classes.time}>
                <Typography >{moment(announcement?.createdAt).format('MMM DD')}</Typography>
                <Typography>{moment(announcement?.createdAt).format('LT')}</Typography>
            </Grid>
            <Grid item xs={10} sm={10} md={10} lg={11} xl={11} container className={classes.announcementTxtContainer}>
                <Grid item xs={12}>
                    <Typography className={classes.announcementHeading}>{announcement?.title}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography>{announcement?.description}</Typography>
                </Grid>

            </Grid>
        </Grid>
    );
}

export default Announcements;

const useStyles = makeStyles(theme => ({
    cardContainer: {
        background: '#FAF9F9',
        width: '100%',
        boxShadow: 'none',
    },
    time: {
        fontFamily: theme.fontFamily.Roboto,
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "14px",
        lineHeight: "100%",
        color: "#B6B6B6",
        textAlign: 'center'
    },

    announcementTxtContainer: {
        fontFamily: theme.fontFamily.Roboto,
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "16px",
        lineHeight: "100%",
        color: "#B6B6B6",
        borderLeft: '2px solid #B6B6B6',
        marginBottom: '20px',
        paddingLeft: '15px',
        minHeight: '60px'

    },
    announcementHeading: {
        fontFamily: theme.fontFamily.Roboto,
        fontStyle: "bold",
        fontWeight: 'normal',
        fontSize: "18px",
        lineHeight: "100%",
        color: "#353B35",

    }

}));