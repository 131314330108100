import React, { useEffect, useState } from 'react';
import { IconButton, Grid, CircularProgress, Typography, Box, Avatar } from '@material-ui/core'
import { Check, Pause, PlayArrow } from '@material-ui/icons'
import { makeStyles, withStyles } from "@material-ui/core/styles";
import PropTypes from 'prop-types';

function CircularProgressWithLabel(props) {
    return (
        <Box position="relative" display="inline-flex">
            <CircularProgress variant="static"  {...props} size={46} style={{ color: '#09A9BB' }} />
            <Box
                top={0}
                left={0}
                bottom={0}
                right={0}
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                <IconButton color="primary" aria-label="upload picture" component="span" style={{ background: '#D3F0F3', color: '#09A9BB', height: '17px', width: '17px', fontSize: '12px' }}>
                    {`${Math.round(
                        props.value
                    )}%`}
                   

                </IconButton>
            </Box>
        </Box>
    );
}

CircularProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate variant.
     * Value between 0 and 100.
     */
    value: PropTypes.number.isRequired,
};

function ParticipantCard(props) {
    const classes = useStyles();

    return (
        <Grid container className={classes.container}>
            <Grid item item xs={10} container spacing={1} className={classes.container}>
                <Grid item >
                    <Avatar src={props?.participant?.user?.pictureUrl}>
                        {props?.participant?.user?.name[0]}
                    </Avatar>
                </Grid>
                <Grid item >
                    <Typography className={classes.content}>{props?.participant?.user?.name}</Typography>
                </Grid>

            </Grid>
            <Grid item xs={2} >
                {props?.participant?.trainingCourseProgress === 100 ? <IconButton color="primary" component="span" style={{ background: '#09A9BB', color: '#fff' }}>
                    <Check />
                </IconButton> : <div>
                    <CircularProgressWithLabel value={props?.participant?.trainingCourseProgress || 0} />
                </div>
                }
            </Grid>

        </Grid>
    );
}

export default ParticipantCard;

const useStyles = makeStyles(theme => ({
    content: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '16px',
        lineHeight: '100%',
        color: '#353B35',
        display: 'flex',
        alignItems: 'center',
    },
    container: {
        display: 'flex',
        alignItems: 'center',
        height: '70px'
    },

    center: {
        display: 'flex',
        alignItems: 'center'
    },
    progress: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '12px',
        lineHeight: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }

}));