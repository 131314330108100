import { useState, useEffect } from "react";
import MentorCard from "../../../../components/AddEditRetreat/MentorCard";
import { Add, CancelRounded, Search as SearchIcon } from "@material-ui/icons";
import {
  Grid,
  IconButton,
  Typography,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Button,
  TextField,
  useMediaQuery,
  CircularProgress
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { addMentors, removeMentors } from "../../../../actions/trainingCourses";
import { makeStyles } from "@material-ui/styles";
import { useIntl } from "react-intl";
import AddMentorCard from "./AddMentorCard";
import API from "../../../../utils/API";
import { useSelector, useDispatch } from "react-redux";

function AddMentor(props) {
  const classes = useStyles();
  const intl = useIntl();
  const user = useSelector(state => state?.auth);

  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [openRemoveMentor, setOpenRemoveMentor] = useState(null);
  const [loader, setLoader] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchedData, setSearchedData] = useState([]);
  const [selectedMentor, setSelectedMentor] = useState([]);
  const [mentor, setMentor] = useState([]);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  let trainingCoursesReducerState = useSelector(state => state.trainingCourse);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setSearchedData([]);
    setSelectedMentor([]);
    setOpen(false);
  };

  const addMentorsRequest = async () => {

    const res = await dispatch(
      addMentors({
        userIds: selectedMentor,
        token: user?.token,
        trainingCourseId: props.courseData?.id
      })
    );
    if (res?.status) {
      setSearchedData([]);
      setSelectedMentor([]);
      setOpen(false);
    }
  };

  const handleSearch = async () => {
    setLoader(true);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user?.token}`
      },
      params: {
        searchTerm,
        type: "Instructor"
      }
    };
    try {
      const res = await API.get(`/api/v1/search`, config);
      setSearchedData(res.data.data);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.log(error);
    }
  };

  const invitationHandler = mentor => {
    return setSelectedMentor([...selectedMentor, mentor]);
  };

  const removeMentor = id => {
    const localMentorArr = selectedMentor.filter(item => item !== id);
    return setSelectedMentor([...localMentorArr]);
  };
  const handleOpenRemoveMentor = mentor => {
    setOpenRemoveMentor(mentor);
  };

  const removeMentorRequest = async () => {
    const res = await dispatch(
      removeMentors({
        userId: openRemoveMentor?.id,
        token: user?.token,
        trainingCourseId: props.courseData?.id
      })
    );

    if (res?.status) {
      setOpenRemoveMentor(null);
    }
  };

  useEffect(() => {
    if (props.courseData?.mentors) {
      setMentor(props.courseData.mentors);
    }
  }, [props.courseData]);

  return (
    <Grid container style={{ padding: "0px 12px" }}>
      <Grid item xs={12}>
        <Typography className={classes.heading}>
          <>
            Add Mentor{" "}
            <IconButton onClick={handleClickOpen} className={classes.button}>
              <Add />
            </IconButton>
          </>
        </Typography>
      </Grid>
      <Grid item xs={12} container spacing={2}>
        {mentor?.length > 0 ? (
          mentor?.map(mentor => (
            <Grid item xs={6} sm={6} md={3} lg={3} key={mentor?.id}>
              <MentorCard
                name={mentor?.name}
                status={mentor?.mentorStatus}
                image={mentor?.pictureUrl}
                id={mentor?.id}
                creatorId={trainingCoursesReducerState?.courseData?.userId}
                mentor={mentor}
                removeMentor={handleOpenRemoveMentor}
              />
            </Grid>
          ))
        ) : (
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Typography className={classes.noRecord}>
              Click on Add button to add mentors
            </Typography>
          </Grid>
        )}
      </Grid>
      <Grid
        style={{
          paddingLeft: isMobile && !isDesktop ? "0" : "2rem",
          padding: isMobile && !isDesktop ? "10px" : "0px"
        }}
        item
        xs={12}
        sm={12}
        md={12}
        lg={6}
      >
        <Button
          style={{
            width: "100%",
            height: "2.750em",
            marginTop: "20px",
            background: trainingCoursesReducerState.courseLoader
              ? "#C2C2C2"
              : "linear-gradient(180deg, #FFC000 -13.24%, #F28705 100%)",
            borderRadius: isMobile && !isDesktop ? "50px" : "11px",
            fontFamily: theme.fontFamily.Roboto,
            fontStyle: "normal",
            fontWeight: 500,
            fontSize: "20px",
            lineHeight: "23px",
            color: "#FFFFFF",
            marginBottom: "30px"
          }}
          disabled={trainingCoursesReducerState.courseLoader}
          onClick={props?.handleNext}
        >
          {intl.formatMessage({ id: "next" })}
        </Button>
      </Grid>
      <Dialog
        open={Boolean(openRemoveMentor)}
        onClose={() => setOpenRemoveMentor(null)}
        maxWidth={"xs"}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" style={{ padding: "0px" }}>
          {" "}
          <Grid container justify="flex-end">
            <Grid item xs={3} sm={2}>
              <CancelRounded
                style={{
                  color: "#D0CFCF",
                  fontSize: "30px",
                  cursor: "pointer",
                  float: "right",
                  paddingTop: "10px",
                  paddingRight: "10px"
                }}
                onClick={() => setOpenRemoveMentor(null)}
              />
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item xs={12}>
              <Typography
                style={{ padding: "5px 5px 0px 5px" }}
                component="p"
                variant="h5"
              >
                Are you sure you want to remove {openRemoveMentor?.name}?
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={trainingCoursesReducerState.courseLoader}
            className={classes.yesBtn}
            onClick={removeMentorRequest}
          >
            {trainingCoursesReducerState.courseLoader ? (
              <CircularProgress style={{ color: "#F28705" }} size={20} />
            ) : (
              intl.formatMessage({ id: "yes" })
            )}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth={"xs"}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" style={{ padding: "0px" }}>
          {" "}
          <Grid container justify="flex-end">
            <Grid item xs={3} sm={2}>
              <CancelRounded
                style={{
                  color: "#D0CFCF",
                  fontSize: "30px",
                  cursor: "pointer",
                  float: "right"
                }}
                onClick={handleClose}
              />
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item xs={12}>
              <Typography
                style={{ color: "#09A9BB", padding: "5px 5px 0px 5px" }}
              >
                Search for specific people click on SEARCH {<SearchIcon />} to
                populate all available users
              </Typography>
            </Grid>
            <Grid item xs={12} style={{ padding: "0px 5px 0px 5px" }}>
              <TextField
                className={classes.homeSearchField}
                type="Search"
                style={{ width: "100%" }}
                placeholder="Search..."
                onChange={e => setSearchTerm(e.target.value)}
                onKeyPress={e => {
                  (e.which === 13 || e.keyCode === 13) && handleSearch();
                }}
                name="search"
                InputProps={{
                  endAdornment: (
                    <SearchIcon
                      onClick={handleSearch}
                      style={{ cursor: "pointer" }}
                    />
                  )
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                {loader ? (
                  <Grid
                    item
                    xs={12}
                    style={{ textAlign: "center", height: "220px" }}
                  >
                    <CircularProgress style={{ color: "#F28705" }} size={20} />
                  </Grid>
                ) : searchedData.length > 0 ? (
                  <Grid container item xs={12} className={classes.container}>
                    {searchedData?.map(friend => {
                      if (
                        selectedMentor.find(mentor => mentor === friend?.id)
                      ) {
                        return (
                          <Grid item xs={12} key={friend?.id}>
                            <AddMentorCard
                              removeMentor={removeMentor}
                              selected={true}
                              friend={friend}
                            />
                          </Grid>
                        );
                      }

                      return (
                        <Grid item xs={12} key={friend?.id}>
                          <AddMentorCard
                            invitation={invitationHandler}
                            friend={friend}
                          />
                        </Grid>
                      );
                    })}
                  </Grid>
                ) : (
                  <Grid item xs={12}>
                    <Typography className={classes.noRecord}>
                      No Record Found
                    </Typography>
                  </Grid>
                )}

                <Grid item xs={12} md={12} className={classes.top}>
                  <Button
                    disabled={trainingCoursesReducerState.courseLoader || selectedMentor?.length === 0}
                    className={classes.completeBtn}
                    onClick={addMentorsRequest}
                  >
                    {trainingCoursesReducerState.courseLoader ? (
                      <CircularProgress style={{ color: "#FFF" }} size={20} />
                    ) : (
                      intl.formatMessage({ id: "finish" })
                    )}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </Grid>
  );
}

export default AddMentor;

const useStyles = makeStyles(theme => ({
  container: {
    padding: "0px 20px 0px 5px",
    height: "245px",
    overflow: "auto",
    "&::-webkit-scrollbar": {
      display: "none"
    },
    display: "flex"
  },
  heading: {
    marginBottom: "15px"
  },
  button: {
    background: "#FBAE02 !important",
    boxShadow: "0px 2px 2px rgba(216, 118, 0, 0.18)",
    color: "#FFF",
    height: "40px",
    width: "40px"
  },
  noRecord: {
    color: "#DFDFDF",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",

    textAlign: "center",
    height: "220px",
    lineHeight: "220px"
  },
  completeBtn: {
    width: "100%",
    height: "3.0em",
    background: "linear-gradient(180deg, #FFC000 -13.24%, #F28705 100%)",
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "20px",
    lineHeight: "23px",
    color: "#FFFFFF",
    textTransform: "none"
  },
  yesBtn: {
    marginTop: "10px",
    color: "#F28705 ",
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "20px",
    lineHeight: "23px",

    textTransform: "none"
  },
  homeSearchField: {
    "& .MuiInputBase-root": {
      backgroundColor: "#dce8f2",
      color: "#A9A9A9",
      fontFamily: theme.fontFamily.Roboto,
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "18px",
      lineHeight: "21px",
      padding: "10px",
      minHeight: "64px",
      alignSelf: "center",
      marginTop: "12px",
      marginBottom: "20px",
      height: "58px",
      width: "100%",
      borderRadius: "10px"
    },
    "& .MuiBadge-colorPrimary": {
      backgroundColor: "#F9A702;"
    },

    "& .MuiInput-underline:before": {
      border: "0px solid #FBF1E0"
    },
    "& .MuiInput-underline::after": {
      border: "0px solid #FBF1E0"
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled)::before": {
      border: "0px solid #FBF1E0"
    }
  }
}));
