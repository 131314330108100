import { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CourseEpisodeCard from "./CourseEpisodeCard";
import CourseUploadPreviewImage from "../../../../assets/img/CourseUploadPreviewImage.svg";
import {
  Grid,
  Dialog,
  DialogActions,
  Button,
  Tooltip,
  Typography,
  DialogContent,
  Divider,
  useMediaQuery,
  CircularProgress,
  IconButton
} from "@material-ui/core";
import {
  courseActiveInActive,
  changeEpisodeStatus,
  getCourseDetails
} from "../../../../actions/courses";
import InviteFriends from "./InviteFriends";
import { Close, Share, InsertInvitation } from "@material-ui/icons";
import SocialMediaShare from "../../../../components/More/Common/SocialMediaShare.js";
import { useTheme } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import { useIntl } from "react-intl";
import CourseDescriptionCard from "../CourseDescriptionCard";
import { useHistory } from 'react-router-dom'

const CourseUploadPreviewCard1 = props => {
  const classes = useStyles();
  const intl = useIntl();
  let configurations = useSelector(state => state?.setting?.siteConfig);

  return (
    <div
      className={classes.root}
      style={{
        background: `url(${props.image
          ? props.image
          : props.courseImage
            ? URL.createObjectURL(props.courseImage)
            : CourseUploadPreviewImage
          })`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        paddingTop: "56.25%",
        position: "relative",
        height: 0
      }}
    >
      <div
        className={classes.root}
        style={{
          background:
            "linear-gradient(360deg, rgba(21, 57, 89, 0.8) 0%, rgba(123, 146, 166, 0.8) 100%)",
          top: 0,
          position: "absolute"
        }}
      >
        <Grid
          container
          direction="row"
          style={{
            alignItems: "space-between"
          }}
        >
          <Grid item xs={1} />
          <Grid item xs={10} sm={10} md={10} lg={8} xl={8}>
            <Typography className={classes.courseTitle}>
              {props.course && props.course.name}
            </Typography>
          </Grid>
          <Grid item xs={1} sm={1} md={1} lg={3} />
        </Grid>
        <Grid container justify="space-between" style={{ paddingTop: "100px" }}>
          <Grid item xs={4}>
            <Button className={classes.startNowBtn}>
              {props.plan?.price === 0 ? (
                <Typography>{intl.formatMessage({ id: "joinNow" })}</Typography>
              ) : (
                <Typography>
                  {intl.formatMessage({ id: "joinNow" })}{" "}
                  {configurations?.currency}{" "}
                  {props.course?.price
                    ? props.course.price
                    : props.plan && props.plan.price
                      ? props.plan.price
                      : 0}
                </Typography>
              )}
            </Button>
          </Grid>
          {props.course?.status === "Active" && (
            <Grid item xs={4}>
              <Button
                onClick={() => props.setOpenInvite(true)}
                className={classes.startNowBtn}
              >
                Invite
              </Button>
            </Grid>
          )}
          {props.course?.status === "Active" && (
            <Grid item xs={4}>
              <Button
                onClick={() => props.setOpen(true)}
                className={classes.startNowBtn}
              >
                Share
              </Button>
            </Grid>
          )}
        </Grid>
      </div>
    </div>
  );
};

const CourseUploadPreviewCard = props => {
  const classes = useStyles();
  const intl = useIntl();
  let configurations = useSelector(state => state?.setting?.siteConfig);

  return (
    <Grid container>
      <Grid item xs={12}>
        <div
          className={classes.root}
          style={{
            background: `url(${props.image
              ? props.image
              : props.courseImage
                ? URL.createObjectURL(props.courseImage)
                : CourseUploadPreviewImage
              })`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            paddingTop: "56.25%",
            position: "relative",
            height: 0
          }}
        ></div>
      </Grid>
      <Grid item xs={12}>
        <Typography className={classes.courseTitle}>
          {props.course && props.course.name}
        </Typography>
      </Grid>

      <Grid xs={12} container justify="space-between">
        <Grid item xs={4} sm={3} md={4} lg={4} xl={3}>
          <Button className={classes.startNowBtn}>
            {props.plan?.price === 0 ? (
              <Typography>{intl.formatMessage({ id: "joinNow" })}</Typography>
            ) : (
              <Typography>
                {intl.formatMessage({ id: "joinNow" })}{" "}
                {configurations?.currency}{" "}
                {props.course?.price
                  ? props.course.price
                  : props.plan && props.plan.price
                    ? props.plan.price
                    : 0}
              </Typography>
            )}
          </Button>
        </Grid>

        <Grid
          item
          xs={5}
          sm={3}
          container
          justify="center"
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center"
          }}
        >
          <Grid item>
            <Tooltip
              title={
                <span style={{ fontSize: "14px", letterSpacing: 0.3 }}>
                  Invite
                </span>
              }
              placement="top"
            >
              <IconButton
                onClick={() => props.setOpenInvite(true)}
                className={classes.inviteAndShare}
                disabled={props?.course && props.course?.status !== "Active"}
              >
                <InsertInvitation />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item>
            <Tooltip
              title={
                <span style={{ fontSize: "14px", letterSpacing: 0.3 }}>
                  Share
                </span>
              }
              placement="top"
            >
              <IconButton
                onClick={() => props.setOpen(true)}
                className={classes.inviteAndShare}
                style={{ marginLeft: "10px" }}
                disabled={props?.course && props.course?.status !== "Active"}
              >
                <Share />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};



function Upload(props) {
  const classes = useStyles();
  const theme = useTheme();
  const intl = useIntl();
  const history = useHistory();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const dispatch = useDispatch();
  let coursesReducerState = useSelector(state => state.courses);
  let authReducerState = useSelector(state => state.auth);
  let accountLinked = useSelector(state => state.stripe?.bankAccountInfo);
  const [expand, setExpand] = useState(false);
  const [open, setOpen] = useState(false);
  const [openInvite, setOpenInvite] = useState(false);
  const [loader, setLoader] = useState(false);
  const expandDescription = () => {
    setExpand(!expand);
  };
  const [linkAccountDialog, setLinkAccountDialog] = useState(false)

  const changeCourseStatus = async () => {
    if (props.course) {
      if (props.course?.status === "Active") {
        props.handleNext();
      } else if (!accountLinked && props?.course?.plan?.name !== 'Free') {
        setLinkAccountDialog(true);
      } else {
        setLoader(true);
        const { status } = await dispatch(
          courseActiveInActive({
            courseId: props.course.id,
            status: "Active",
            token: authReducerState.token
          })
        );
        setLoader(false);
        if (status) {
          props.handleNext();
        }
      }
    }
  };

  const handleChangeStatus = async episode => {
    if (episode.status === "Active") {
      await dispatch(
        changeEpisodeStatus({
          episodeId: episode.id,
          status: "InActive",
          token: authReducerState.token,
          showMessage: true
        })
      );
      if (
        props.course.episodes?.filter(item => item.status === "Active")
          ?.length <= 1
      ) {
        dispatch(
          getCourseDetails({
            courseId: props.course.id,
            token: authReducerState.token
          })
        );
      }
    } else {
      await dispatch(
        changeEpisodeStatus({
          episodeId: episode.id,
          status: "Active",
          token: authReducerState.token,
          showMessage: true
        })
      );
      if (
        props.course.episodes?.filter(item => item.status === "Active")
          ?.length <= 1
      ) {
        dispatch(
          getCourseDetails({
            courseId: props.course.id,
            token: authReducerState.token
          })
        );
      }
    }
  };

  const isIos = () => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return /iphone|ipad|ipod/.test(userAgent);
  };


  const closeDialog = () => {
    setLinkAccountDialog(false);
  }

  return (
    <>
      <Grid
        container
        direction="row"
        style={{ padding: isMobile && "0px 15px" }}
      >
        <Grid item xs={12} sm={12} md={7} lg={7}>

          <CourseUploadPreviewCard
            course={props.course ? props.course : null}
            plan={props.course ? props.course.plan : null}
            courseImage={props.course ? props.course.courseImage : null}
            image={props.course ? props.course.image : null}
            expandDescription={expandDescription}
            expand={expand}
            setOpen={setOpen}
            setOpenInvite={setOpenInvite}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={5}
          lg={5}
          style={{ paddingLeft: !isMobile && "50px" }}
        >
          <CourseDescriptionCard
            remarks={props?.course && props?.course?.remarks}
            tags={props?.course && props?.course?.tags}
          />
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        style={{ marginTop: "2.375em", padding: isMobile && "0px 15px" }}
      >
        <Grid item xs={4} lg={6}>
          <Typography className={classes.epText}>
            {intl.formatMessage({ id: "episodes" })}
          </Typography>
          <Divider
            style={{
              width: "2.938em",
              height: "0.188em",
              background: "#09A9BB",
              borderRadius: "12px",
              margin: "2% 0 0 2%"
            }}
          />
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        style={{ marginTop: "3%", padding: isMobile && "0px 15px" }}
      >

        <Grid
          item
          container
          xs={12}
          sm={12}
          md={11}
          lg={10}
          direction="row"
          spacing={isMobile ? 0 : 2}
        >
          {props?.course &&
            props?.course?.episodes?.length > 0 &&
            props.course.episodes.map(episode => (
              <Grid
                key={episode.id}
                item
                xs={12}
                md={6}
                style={{ marginBottom: "10px" }}
              >
                <CourseEpisodeCard
                  textColor="#2A4A67"
                  videoIconBgColor="#2A4A67"
                  videoIconColor="#FFF"
                  title={episode.name}
                  image={episode.thumbnail}
                  isFree={episode?.isFree}
                  status={episode?.status}
                  courseBy={
                    authReducerState &&
                    authReducerState.user &&
                    authReducerState.user.name
                  }
                  handleChangeStatus={() => handleChangeStatus(episode)}
                  duration={episode.videoFile ? episode.videoFile.duration : ""}
                  type=""
                />
              </Grid>
            ))}

          <Grid item container xs={12}>
            {props.course.episodes?.filter(item => item.status === "Active")
              ?.length === 0 && (
                <Grid item xs={10} container justify="center" lg={12}>
                  <Typography component="p" variant="caption">
                    {" "}
                    You can not activate your course unless you have one active
                    episode{" "}
                  </Typography>
                </Grid>
              )}
            <Grid
              style={{
                padding: isMobile && !isDesktop ? "10px 0px" : "0px"
              }}
              item
              xs={12}
              md={12}
              lg={6}
            >
              <Button
                style={{
                  width: "100%",
                  height: "2.750em",
                  background:
                    "linear-gradient(180deg, #FFC000 -13.24%, #F28705 100%)",
                  borderRadius: isMobile && !isDesktop ? "50px" : "11px",
                  fontFamily: theme.fontFamily.Roboto,
                  fontStyle: "normal",
                  fontWeight: 500,
                  fontSize: "20px",
                  lineHeight: "23px",
                  color: "#FFFFFF",
                  marginBottom: "30px"
                }}
                disabled={
                  coursesReducerState.courseLoader ||
                  loader ||
                  props.course.episodes.filter(item => item.status === "Active")
                    .length === 0
                }
                onClick={changeCourseStatus}
              >
                {coursesReducerState.courseLoader || loader ? (
                  <CircularProgress style={{ color: "#fff" }} size={20} />
                ) : props.course.status === "Active" ? (
                  intl.formatMessage({ id: "next" })
                ) : (
                  "Activate"
                )}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogContent style={{ padding: isIos() && "0px" }}>
          <SocialMediaShare
            url={`${window.location.origin}/share/course/${props.course?.slug}`}
            imageUrl={props?.course?.image}
            sumaryText={"Here is new course"}
            title={"Hey there,  Check this out, I think you will love it"}
            tags={
              props.course?.courseTags
                ? props.course?.courseTags.map(item => item.name)
                : []
            }
            header={intl.formatMessage({ id: "courseShareText" })}
          />
        </DialogContent>
      </Dialog>
      {openInvite && (
        <Dialog open={openInvite} onClose={() => setOpenInvite(false)}>
          <DialogContent>
            <InviteFriends
              setOpenInvite={setOpenInvite}
              course={props.course}
            />
          </DialogContent>
        </Dialog>
      )}


      <Dialog open={linkAccountDialog}>
        <DialogContent >
          <Grid container justify="center">
            <Typography component={"h4"} variant={"h6"}>
              Bank Account is not linked
            </Typography>
          </Grid>
          <Grid container justify="center">
            <Typography component={"p"} variant={"p"}>
              Please link your bank account or change course plan to Free to activate this course.
            </Typography>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button className={classes.link} onClick={() => history.push("/more/payment-methods?tab=linkedAccount")}>
            Link Account
          </Button>
          <Button className={classes.link} onClick={closeDialog}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

    </>
  );
}

export default Upload;

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    height: "100%",
    borderRadius: "10px",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat"

  },
  media: {
    width: "100%",

    background:
      "linear-gradient(360deg, rgba(21, 57, 89, 0.8) 0%, rgba(123, 146, 166, 0.8) 100%)",
    borderRadius: "11px",
    transform: "rotate(360deg)",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat"
  },
  courseTitle: {
    fontFamily: theme.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: 900,
    fontSize: "20px",
    color: "#000",
    paddingBottom: "15px"
  },
  courseDescription: {
    fontFamily: theme.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: 900,
    fontSize: "20px",
    lineHeight: "27px",
    letterSpacing: "0.015em",
    color: "#FFFFFF",
    padding: "20px 0px"
  },
  startNowBtn: {

    height: "2.463em",
    width: "100%",
    background: "linear-gradient(360deg, #15AEBF 0%, #A6D6FF 100%)",
    borderRadius: "10px",
    transform: "rotate(360deg)",
    fontFamily: theme.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "20px",
    lineHeight: "10px",
    color: "#FFFFFF"

  },
  epText: {
    fontFamily: theme.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "21px",
    color: "#09A9BB",
    textShadow: "0px 2px 4px rgba(5, 85, 94, 0.31)"
  },
  readMore: {
    fontSize: "12px",
    cursor: "pointer",
    color: "#C4C4C4",
    "&:hover": {
      textDecoration: "underline",
      color: "#84CDF0"
    }
  },
  inviteAndShare: {
    background: "linear-gradient(360deg, #15AEBF 0%, #A6D6FF 100%)",
    fontSize: "20px",
    lineHeight: "10px",
    color: "#FFFFFF"
  },
  link: {
    background: "#09A9BB",
    color: "#fff",
    padding: ".5rem",
    textDecoration: "none",
    "&:hover": {
      background: "#09A9BB",
      textDecoration: "none"
    }
  }

}));
