import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  useMediaQuery,
  Grid,
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { ArrowBack } from "@material-ui/icons";

import PropTypes from "prop-types";

function ElevationScroll(props) {
  const { children } = props;


  return React.cloneElement(children, {
    elevation: 0,
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func,
};

function AppBarLayout(props) {
  const classes = useStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  let history = useHistory();

  return (
    <>
      {props.appBarConfig && props.appBarConfig.show && (
        <ElevationScroll {...props}>
          <AppBar
            className={!isDesktop ? classes.appBarMobile : classes.appBar}
            style={{ backgroundColor: props.appBarConfig?.backgroundColor }}
          >
            <Grid container>
              <Grid container item xs={6} style={{ alignItems: "center" }}>
                <Grid item>
                  <IconButton
                    onClick={() =>
                      props.appBarConfig.backAction
                        ? props.appBarConfig.backAction()
                        : history.goBack()
                    }
                    className={classes.backButton}
                    style={{ color: props.appBarConfig?.arrowColor }}
                  >
                    <ArrowBack />
                  </IconButton>
                </Grid>
                <Grid item>
                  <Toolbar>
                    <Typography
                      className={
                        !isDesktop
                          ? classes.appBarTextMobile
                          : classes.appBarText
                      }
                      variant="h6"
                      style={{ color: props.appBarConfig?.bodyColor }}
                    >
                      {props.appBarConfig.text}
                    </Typography>
                  </Toolbar>
                </Grid>
              </Grid>
              
            </Grid>
          </AppBar>
        </ElevationScroll>
      )}
    </>
  );
}

export default AppBarLayout;

const useStyles = makeStyles((theme) => ({
  appBar: {
    background: "#fff",
    color: "#000",
    flexDirection: "row",
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    right: "unset",
    position: "relative",
  },
  appBarMobile: {
    background: "#FFFFFF",
    color: "#000",
    flexDirection: "row",
    padding: theme.spacing(1.5),
    justifyContent: "space-between",
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    right: "unset",
    position: "sticky",
  },
  appBarText: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: "900",
    fontSize: "20px",
    lineHeight: "23px",
  },
  appBarTextMobile: {
    fontFamily: theme.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: "900",
    fontSize: "26px",
    lineHeight: "35px",
  },
  searchButton: {
    background: "#FBF1E0",
    borderRadius: "12px",
    width: "50px",
    height: "50px",
    color: "#F9A702",
    padding: "0px",
  },
  backButton: {
    background: "transparent",
    color: "#000",
    padding: "0px",
  },
  homeSearchField: {
    "& .MuiInputBase-root": {
      backgroundColor: "#FBF1E0",
      color: "rgba(249,167,2,.5)",
      fontFamily: theme.fontFamily.Roboto,
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "18px",
      lineHeight: "21px",
      padding: "10px",
      minHeight: "64px",
      alignSelf: "center",
      marginTop: "12px",
      marginBottom: "20px",
      height: "58px",
      width: "100%",
      borderRadius: "10px",
    },
    "& .MuiBadge-colorPrimary": {
      backgroundColor: "#F9A702;",
    },

    "& .MuiInput-underline:before": {
      border: "0px solid #FBF1E0",
    },
    "& .MuiInput-underline::after": {
      border: "0px solid #FBF1E0",
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled)::before": {
      border: "0px solid #FBF1E0",
    },
  },
}));
