import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";
import simpleLogo from "../../../assets/img/simpleLogo.png";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import { useIntl } from "react-intl";
import { useSelector } from 'react-redux';

function LetStart() {
  const classes = useStyles();
  const intl = useIntl();
  const configs = useSelector(state => state?.setting?.siteConfig)

  return (
    <Grid container className={classes.root} style={{ backgroundImage: `url(${configs?.landingBackground})` }}>
      <Grid
        container
        item
        xs={12}
        justify="center"
      >
        <Grid item xs={12} style={{ textAlign: "center", marginTop: '30px' }}>
          <img src={simpleLogo} alt="logo" style={{ width: "100px", height: "100px" }} />
        </Grid>

        <Grid item xs={12} style={{ textAlign: "center" }}>
          <Typography className={classes.heading}>
            {configs?.landingTitle}
          </Typography>
          <Typography className={classes.subHeading}>
            {configs?.landingBaseline}
          </Typography>
        </Grid>

        <Grid item xs={10}>
          <Link to="/choose-identity" style={{ textDecoration: "none" }}>
            <Button variant="contained" className={classes.letsStartBtn}>
              {intl.formatMessage({ id: "letsStart" })}
            </Button>
          </Link>
        </Grid>

        <Grid item xs={10} >
          <Link to="/auth" style={{ textDecoration: "none" }}>
            <Button variant="contained" className={classes.letsStartBtn} >
              {intl.formatMessage({ id: "sign_in" })}
            </Button>
          </Link>
        </Grid>

      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    background: `#C4C4C4`,
    backgroundAttachment: 'fixed',
    height: "100vh",


    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center"
  },
  centerBtnContainer: {
    textAlign: "center",
    margin: "auto"
  },
  centerContainer: {
    textAlign: "center",
    margin: "auto"

  },
  heading: {
    fontStyle: "normal",
    fontWeight: 900,
    fontSize: "36px",
    lineHeight: "49px",
    fontFamily: theme.fontFamily.Reef,
    color: "#FFFFFF"
  },
  letsStartBtn: {
    textTransform: "none",
    width: "100%",
    height: "67px",
    background: "none",
    borderRadius: "59px",
    border: "2px solid #FFFFFF",
    fontFamily: theme.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: 900,
    fontSize: "22px",
    lineHeight: "30px",
    letterSpacing: " 0.09em",
    color: "#FFFFFF",
    marginTop: "30px",
    "&:hover": {
      background: "#09A9BB",
      border: "none"
    }
  },
  subHeading: {
    fontFamily: theme.fontFamily.Reef,
    marginTop: "30px",
    fontStyle: "normal",
    fontWeight: "900",
    fontSize: "26px",
    lineHeight: "49px",
    textAlign: "center",
    letterSpacing: " 0.02em",
    color: "#FFFFFF"
  },
}));

export default LetStart;
