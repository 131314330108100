import React, { useState, useEffect, useContext } from "react";
import { Grid, Box } from "@material-ui/core";


import {
  CardMembership,
  Assignment,
  Group,
  Email,
  Settings,
  Payment,
  PeopleAlt
} from "@material-ui/icons";
import {
  useParams,
  useLocation,
  Switch,
  Route,
  useHistory,
  useRouteMatch
} from "react-router-dom";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { useIntl } from "react-intl";
import AllSessions from "../Session/AllSessions";
import AllChallenges from "../More/AllChallenges";
import Connects from "../../../components/Connects";
import CreateSession from "../../../containers/Common/Session";
import SessionDetails from "../../../components/Common/Session/SessionDetails";
import SessionInvite from "../../../components/More/Common/SessionInvitations";
import SubscriptionPlan from "../../../components/More/SubscriptionPlan";
import Subscription from "../../../components/More/Subscription";
import ChallengeInvitations from "../../../components/More/Common/ChallengeInvitations";
import MainMenuCard from "../../../components/More/mainMenuCard";
import ChatScreen from "./Chat/Inbox";
import RelateWithFriends from "../../../components/More/RelateWithFriends";
import PaymentMethod from "../../Common/Payment";
import CreateChallengeScreen from "./CreateChallenge";
import Setting from "../../Common/Settings/Index";
import ChallengesStats from "./ChallengesStats";
import { SocketContext } from '../../../Context/socketContext'

function MoreContainer(props) {
  const classes = useStyles();
  const intl = useIntl();
  const { path } = useRouteMatch();


  const socket = useContext(SocketContext);

  const params = useParams();

  const location = useLocation();
  const history = useHistory();
  const chatsReducer = useSelector(state => state?.chat?.chats);
  const sessionsList = useSelector(state => state.session.sessionsList);
  const allPendingRequests = useSelector(
    state => state?.userRelations?.pendingRequests
  );
  let reducerUser = useSelector((state) => state?.user?.user);
  const [unreadMessages, setUnreadMessages] = useState(0);

  useEffect(() => {
    let noOfUnReadMessages = chatsReducer
      ?.map(chat => {
        return chat?.unreadMessages?.length;
      })
      .reduce((prev, next) => prev + next, 0);
    setUnreadMessages(noOfUnReadMessages);
  }, [chatsReducer]);
  const MenuScreenHandler = (e, path) => {
    e.preventDefault();
    return history.push(path);
  };

  return (

    <Grid container>

      <Grid
        item
        lg={4}
        md={4}
        sm={5}
        container
        style={{
          background: "#F3F3F3",
        }}
        className={classes.screenSize}
      >

        <Grid container item xs={12}>
          <Grid item xs={12} style={{ padding: "40px 25px 20px 25px" }}>
            <Box
              onClick={e => {
                MenuScreenHandler(e, "/more/relate-with-friends");
              }}
            >
              <MainMenuCard
                IconContainerBackground="#fce7cd"
                Icon={
                  <Group
                    style={{
                      color:
                        location.pathname === "/more/relate-with-friends" ||
                          location.pathname === "/more/all-sessions" ||
                          location.pathname === "/more/all-challenges" ||
                          location.pathname === "/more/create-challenge" ||
                          location.pathname === "/more"
                          ? "#FFF"
                          : "#F28705",
                      fontSize: "40px"
                    }}
                  />
                }
                title="Relate With friends"
                detail={
                  sessionsList.length === 0
                    ? ""
                    : sessionsList.length > 1
                      ? `${sessionsList.length} sessions`
                      : `${sessionsList.length} session`
                }
                selected={
                  location.pathname === "/more/relate-with-friends" ||
                    location.pathname === "/more/all-sessions" ||
                    location.pathname === "/more/all-challenges" ||
                    location.pathname === "/more/create-challenge" ||
                    location.pathname === "/more"
                    ? true
                    : false
                }
              />
            </Box>
          </Grid>
          <Grid item xs={12} style={{ padding: "0 25px 20px 25px" }}>
            <Box
              onClick={e => {
                MenuScreenHandler(e, "/more/inbox");
              }}
            >
              <MainMenuCard
                IconContainerBackground="#ceeef1"
                Icon={
                  <Email
                    style={{
                      color:
                        location.pathname === "/more/inbox" ||
                          location.pathname ===
                          `/more/inbox/messages/${params?.roomId}`
                          ? "#FFF"
                          : "#09A9BB",
                      fontSize: "40px"
                    }}
                  />
                }
                selected={
                  location.pathname === "/more/inbox" ||
                    location.pathname ===
                    `/more/inbox/messages/${params?.roomId}`
                    ? true
                    : false
                }
                title={intl.formatMessage({ id: "inbox" })}
                detail={
                  unreadMessages > 0 && unreadMessages < 10
                    ? `${unreadMessages} New message`
                    : unreadMessages > 10
                      ? `${unreadMessages} New messages`
                      : ""
                }
              />
            </Box>
          </Grid>

          <Grid item xs={12} style={{ padding: "0 25px 20px 25px" }}>
            <Box
              onClick={e => {
                MenuScreenHandler(e, "/more/connections");
              }}
            >
              <MainMenuCard
                IconContainerBackground="#fce7cd"
                Icon={
                  <PeopleAlt
                    style={{
                      color:
                        location.pathname === "/more/connections"
                          ? "#FFF"
                          : "#F28705",
                      fontSize: "40px"
                    }}
                  />
                }
                selected={
                  location.pathname === "/more/connections" ? true : false
                }
                title={intl.formatMessage({ id: "connections" })}
                detail={
                  allPendingRequests?.length > 0 &&
                    allPendingRequests?.length < 10
                    ? `${allPendingRequests?.length} new connection pending`
                    : allPendingRequests?.length > 10
                      ? `${unreadMessages} new connections pending`
                      : ""
                }
              />
            </Box>
          </Grid>


          <Grid item xs={12} style={{ padding: "0 25px 20px 25px" }}>
            <Box
              onClick={e => {
                MenuScreenHandler(e, "/more/challenges-stats");
              }}
            >
              <MainMenuCard
                IconContainerBackground="#fce7cd"
                Icon={
                  <Assignment
                    style={{
                      color:
                        location.pathname === "/more/challenges-stats"
                          ? "#FFF"
                          : "#F28705",
                      fontSize: "40px"
                    }}
                  />
                }
                title="Challenges & Stats"
                selected={
                  location.pathname === "/more/challenges-stats"
                    ? true
                    : false
                }
              />
            </Box>
          </Grid>
          <Grid item xs={12} style={{ padding: "0 25px 20px 25px" }}>
            <Box
              onClick={e => {
                MenuScreenHandler(e, "/more/payment-methods");
              }}
            >
              <MainMenuCard
                IconContainerBackground="#f2d0cd"
                Icon={
                  <Payment
                    style={{
                      color:
                        location.pathname === "/more/payment-methods"
                          ? "#FFF"
                          : "#E54D40",
                      fontSize: "40px"
                    }}
                  />
                }
                title="Payment Method"
                selected={
                  location.pathname === "/more/payment-methods" ? true : false
                }
              />
            </Box>
          </Grid>

          <Grid item xs={12} style={{ padding: "0 25px 20px 25px" }}>
            <Box
              onClick={e => {
                reducerUser?.purchasedSubscription.length === 0 ?
                MenuScreenHandler(e, "/more/subscription-plan") :
                MenuScreenHandler(e, "/more/subscription");
              }}
            >
              <MainMenuCard
                IconContainerBackground="#ceeef1"
                Icon={
                  <CardMembership
                    style={{
                      color:
                        location.pathname === "/more/subscription"
                          ? "#FFF"
                          : "#09A9BB",
                      fontSize: "40px"
                    }}
                  />
                }
                title="Subscriptions"
                selected={
                  location.pathname === "/more/subscription" ? true : false
                }
              />
            </Box>
          </Grid>

          <Grid item xs={12} style={{ padding: "0 25px 20px 25px" }}>
            <Box
              onClick={e => {
                MenuScreenHandler(e, "/more/settings");
              }}
            >
              <MainMenuCard
                IconContainerBackground="#d0d7de"
                Icon={
                  <Settings
                    style={{
                      color:
                        location.pathname === "/more/settings"
                          ? "#FFF"
                          : "#153959",
                      fontSize: "40px"
                    }}
                  />
                }
                title="Settings"
                selected={
                  location.pathname === "/more/settings" ? true : false
                }
              />
            </Box>
          </Grid>

        </Grid>

      </Grid>


      <Grid
        item
        lg={8}
        md={8}
        sm={7}
        className={classes.screenSize}

      >

        <Switch>
          <Route
            exact
            path={`/more/relate-with-friends`}
            {...props}
            component={RelateWithFriends}
          />
          <Route
            exact
            path={"/more/session/edit/:id"}
            {...props}
            component={CreateSession}
          />
          <Route
            exact
            path={`/more/session/create`}
            {...props}
            component={CreateSession}
          />
          <Route
            exact
            path={`/more/session/details/:id`}
            {...props}
            component={SessionDetails}
          />
          <Route
            exact
            path="/more/challenge/invite/:id"
            {...props}
            component={ChallengeInvitations}
          />
          ,
          <Route
            exact
            path="/more/session/invite/:id"
            {...props}
            component={SessionInvite}
          />
          ,

          <Route
            exact
            path={`/more/all-sessions`}
            {...props}
            component={AllSessions}
          />
          <Route
            exact
            path={`/more/all-challenges`}
            component={AllChallenges}
          />
          <Route
            exact
            path={`/more/subscription-plan`}
            component={SubscriptionPlan}
          />
          <Route
            exact
            path={`/more/subscription`}
            component={Subscription}
          />
          <Route
            exact
            path={`/more/create-challenge`}
            component={CreateChallengeScreen}
          />
          <Route
            exact
            path={`/more/edit-challenge/:id`}
            component={CreateChallengeScreen}
          />
          <Route exact path={`/more/connections/:tab?`} component={Connects} />
          <Route
            exact
            path={`/more/challenges-stats`}
            component={ChallengesStats}
          />
          <Route
            exact
            path={`/more/payment-methods`}
            component={PaymentMethod}
          />
          <Route exact path={`/more/settings`} component={Setting} />
          <Route
            path={`/more/inbox`}
            component={ChatScreen}
            roomId={params?.roomId}
          />
          <Route path={path} {...props} component={RelateWithFriends} />
        </Switch>
      </Grid>
    </Grid>
  );
}

export default MoreContainer;

const useStyles = makeStyles(theme => ({
  screenSize: {
    height: "100vh",
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      width: "0px",
      background: "transparent"
    }
  }
}));
