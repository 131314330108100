import React from "react";
import { useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";

import More from "../../../containers/Web/More/index";
import AllSessionsMobile from "../../../containers/Mobile/Session/AllSessions";
const AllSessions = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <>{!isMobile && isDesktop ? <More /> : <AllSessionsMobile />}</>
  );
};

export default AllSessions;
