import { makeStyles, withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import {
  Grid,
  IconButton,
  Dialog,
  DialogContent,
  DialogActions,
  CircularProgress,
  Button,
  Box,
  Switch,
  FormControlLabel,
  LinearProgress
} from "@material-ui/core";
import { Edit, Delete } from "@material-ui/icons";
import Typography from "@material-ui/core/Typography";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import Truncate from "react-truncate";
import liveIcon from '../../assets/Icons/liveIcon.svg'

const CustomSwitch = withStyles({
  switchBase: {
    "&$checked": {
      color: "#29cc10"
    },
    "&$checked + $track": {
      backgroundColor: "#29cc10"
    }
  },
  checked: {},
  track: {}
})(Switch);

function CircularProgressWithLabel(props) {
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress variant="static" {...props} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography
          variant="caption"
          component="div"
          color="textSecondary"
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

function LinearProgressWithLabel(props) {
  return (
    <Box display="flex" alignItems="center" style={{ width: "100%" }}>
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    cursor: "grab",
    borderRadius: "11px",
    transform: "rotate(360deg)",
    marginBottom: "50px"
  },
  imageContainer: {
    
    paddingTop: "56.25%",
    height: 0,
    borderRadius: "9px",
    backgroundRepeat: "no-repeat !important",
    backgroundSize: "contain"
  },
  Title: {
    fontFamily: theme.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: 900,
    fontSize: "16px",
    lineHeight: "22px",
    color: "rgba(0, 0, 0, 0.8)"
  },
  subContent: {
    fontFamily: theme.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: 900,
    fontSize: "14px",
    lineHeight: "16px",
    marginTop: "5px",
    color: "rgba(0, 0, 0, 0.8)"
  },
  lockIcon: {
    height: "3.438em",
    width: "3.438em",
    borderRadius: "100%",
    backgroundColor: "#FFF",
    textAlign: "left",
    marginRight: "20px",
    marginTop: "40%"
  },
  inProgress: {
    fontSize: "10px",
    paddingLeft: ".25rem",

    backgroundColor: "#ff9100",
    color: "#fff",
    borderRadius: "10px"
  },
  blocked: {
    fontSize: "10px",
    paddingLeft: ".25rem",

    borderRadius: "10px",
    backgroundColor: "#f44336",
    color: "#fff"
  },
  startTimeTxt: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: "normal",

    fontSize: "12px",
    lineHeight: "10px",
    color: "#ff9100"
  },
  progressBarColor: {
    backgroundColor: "#FBAE02"
  }
}));

function PackageEpisodeCard(props) {
  const classes = useStyles();
  const intl = useIntl();

  const courseLoader = useSelector(state => state?.courses?.courseLoader);
  const episodeThumbnailProgress = useSelector(
    state => state?.courses?.episodeThumbnailProgress
  );

  const {
    title,

    backgroundColor,
    imageUrl
  } = props;
  return (
    <div>
      <Card className={classes.root} style={{ background: backgroundColor }}>
        <CardContent>
          <Grid container justify="flex-start" direction="row">
            <Grid xs={3} item>
              {/* <img src={userPhoto}/> */}
              {!imageUrl && courseLoader ? (
                <CircularProgressWithLabel
                  style={{
                    color: "#FBAE02"
                  }}
                  size={50}
                  value={episodeThumbnailProgress}
                />
              ) : (
                <div
                  className={classes.imageContainer}
                  style={{
                    background: `url(${imageUrl}), #C4C4C4`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "contain",
                    backgroundPosition: "center"
                  }}
                />
              )}
            </Grid>
            <Grid item xs={9} style={{ paddingLeft: "15px" }}>
              <Typography className={classes.Title}>
                <Truncate lines={2} ellipsis={<span>...</span>}>
                  {title}
                </Truncate>
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            {props.episodeVideoUploadLoader && props.episodeVideoProgress != 0  ? (
              <LinearProgressWithLabel
                style={{
                  color: "#FBAE02",
                  backgroundColor: "rgba(251, 174, 2,.3)"
                }}
                classes={{
                  bar1Determinate: classes.progressBarColor
                }}
                value={props.episodeVideoProgress}
                // value={50}
              />
            ) : !props?.status.includes("InProgress") ? (
              <>
                <Grid container justify="space-between">
                  {props?.type === 'live' ? <Grid item style={{paddingTop:'5px'}}><Grid container justify="space-between" spacing={1}><Grid item ><img src={liveIcon} /></Grid>
                    <Grid item >
                      <span className={classes.startTimeTxt}>
                        {props?.status}
                      </span></Grid> </Grid></Grid>:  <Grid item>
                      {(props?.status === "Active" ||
                        props?.status === "InActive") && (
                          <FormControlLabel
                            style={{ marginRight: "0px" }}
                            control={
                              <CustomSwitch
                                onChange={props.handleChangeStatus}
                                checked={props?.status === "Active" ? true : false}
                              />
                            }
                            label={
                              <span className={classes.startTimeTxt}>
                                {props?.status}
                              </span>
                            }
                          />
                        )}
                    </Grid>}
                  <Grid item>
                      <Grid container justify="space-between" spacing={2}>
                        <Grid item>
                          <IconButton
                            style={{
                              background:
                                "linear-gradient(180deg, #FFC000 -13.24%, #F28705 100%)",
                              color: "#fff"
                            }}
                            disabled={courseLoader}
                            size="small"
                            onClick={props.deleteClick}
                          >
                            <Delete size="small" />
                          </IconButton>
                        </Grid>
                        {((props?.type === 'live' && props?.status === 'Pending') || (props?.liveType !== 'vimeo') || (props?.type !== 'live')) && <Grid item>
                          <IconButton
                            style={{
                              background:
                                "linear-gradient(180deg, #FFC000 -13.24%, #F28705 100%)",
                              color: "#fff"
                            }}
                            disabled={courseLoader}
                            size="small"
                            onClick={props.editClick}
                          >
                            <Edit size="small" />
                          </IconButton>
                        </Grid>}
                      </Grid>
                    </Grid>
                  </Grid>
              </>
            ) : (
              <Grid container justify="space-between" alignItems="center">
                  <Grid item>
                    <Typography
                      className={
                        props.status.includes("InProgress")
                          ? classes.inProgress
                          : classes.blocked
                      }
                      component="p"
                    >
                      {props.status === "InProgressUploading"
                        ? "In progress uploading"
                        : props.status}
                    </Typography>
                  </Grid>
                  {props.status.includes("InProgress") && (
                    <Grid item>
                      <FormControlLabel
                        style={{ marginRight: "0px" }}
                        control={
                          <CustomSwitch
                            onChange={props.handleChangeStatus}
                            checked={props?.status === "Active" ? true : false}
                          />
                        }
                        label={""}
                      />
                    </Grid>
                  )}
                  <Grid item>
                    <Grid container justify="space-between" spacing={2}>
                      <Grid item>
                        <IconButton
                          style={{
                            background:
                              "linear-gradient(180deg, #FFC000 -13.24%, #F28705 100%)",
                            color: "#fff"
                          }}
                          disabled={courseLoader}
                          size="small"
                          onClick={props.deleteClick}
                        >
                          <Delete size="small" />
                        </IconButton>
                      </Grid>
                      <Grid item>
                        <IconButton
                          style={{
                            background:
                              "linear-gradient(180deg, #FFC000 -13.24%, #F28705 100%)",
                            color: "#fff"
                          }}
                          disabled={courseLoader}
                          size="small"
                          onClick={props.editClick}
                        >
                          <Edit size="small" />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
            )}
          </Grid>
        </CardContent>
      </Card>
        <Dialog
          open={Boolean(props.selectedEpisode)}
          onClose={() => props.setSelectedEpisode(null)}
        >
          <DialogContent>
            <Typography component="h4" variant="h6">
              {intl.formatMessage({ id: "areYouSure" })}{" "}
              {props.selectedEpisode?.name}
            </Typography>
            {(props.showWarning && props.selectedEpisode?.status === 'Active') && (
              <Typography component="p" variant="caption" color="secondary">
            This is last active episode of course. If you delete this episode
            your course status will be turn to incomplete and then only be
            active after you active it by your self. After adding at least one
            active episode
              </Typography>
            )}
          </DialogContent>
          <DialogActions>
            {courseLoader ? (
              <CircularProgress size={15} style={{ color: "#F28705" }} />
            ) : (
              <Button
                onClick={props.handleDeleteEpisode}
                style={{ color: "#F28705" }}
              >
                {intl.formatMessage({ id: "yes" })}
              </Button>
            )}
            <Button onClick={() => props.setSelectedEpisode(null)}>
              {intl.formatMessage({ id: "no" })}
            </Button>
          </DialogActions>
        </Dialog>
    </div >
  );
}

export default PackageEpisodeCard;
