import API from "../utils/API";

import {
    GET_NEW_TEACHERS,
    TEACHER_LOADER
} from "./types";

export const getNewTeachers = ({ token,page,limit,categoryId }) => async (dispatch) => {
    dispatch({
        type: TEACHER_LOADER,
        payload: true
      });
  // set header
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    params:{
        page:page || undefined,
        limit: limit || undefined,
        categoryId: categoryId || '',
        sortOrder:'DESC',
        sortBy : 'createdAt',

    }
  };

  try {
    const res = await API.get(`/api/v1/user/getLatestTeachers`, config);
    dispatch({
      type: GET_NEW_TEACHERS,
      payload: res.data.data,
    });
    dispatch({
        type: TEACHER_LOADER,
        payload: false
      });
  } catch (error) {
    console.log(error);
    dispatch({
        type: TEACHER_LOADER,
        payload: false
      });
  }
};
