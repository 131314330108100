import React, { useState, useEffect } from "react";
import { Grid, Typography, Box, CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import StartBtn from "../../../assets/img/soloExerciseBtn.png";
import Play from "../../../assets/Icons/play.svg";

import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import MenuIcon from "../../../assets/Icons/menu1.svg";
import Sound1 from "../../../assets/sound1.mp3";
import speechExerciseTimeStart from "../../../assets/sounds/speechExerciseTimeStart.mp3";
import speechRestTimeStart from "../../../assets/sounds/speechRestTimeStart.mp3";
import speechPlay from "../../../assets/sounds/speechPlay.mp3";
import speechPause from "../../../assets/sounds/speechPause.mp3";
import useSound from "use-sound";

function SoloExercise(props) {
  const classes = useStyles();
  const [pause, setPause] = useState(false);
  const [option, setOPtion] = useState("preprationTime");

  const [timer, setTimer] = useState(getPreprationTime);
  const [Ptimer] = useState(getPreprationTime);
  const [playOn] = useSound(Sound1, { volume: 0.5 });
  const [speechExerciseTimeStartPlayOn] = useSound(speechExerciseTimeStart, {
    volume: 1,
  });
  const [speechPlayPlayOn] = useSound(speechPlay, { volume: 1 });
  const [speechPausePlayOn] = useSound(speechPause, { volume: 1 });
  const [speechRestTimeStartPlayOn] = useSound(speechRestTimeStart, {
    volume: 1,
  });
  function getPreprationTime() {
    return (
      parseInt(props.preprationTime.min) * 60 +
      parseInt(props.preprationTime.sec)
    );
  }

  const [iTimer] = useState(getIntervalTime);
  function getIntervalTime() {
    return (
      parseInt(props.intervalRestTime.min) * 60 +
      parseInt(props.intervalRestTime.sec)
    );
  }

  const [eTimer] = useState(getExerciseTime);
  function getExerciseTime() {
    return (
      parseInt(props.exerciseDuration.min) * 60 +
      parseInt(props.exerciseDuration.sec)
    );
  }

  const [leftTime, setLeftTime] = useState(getLeftTime);
  function getLeftTime() {
    return (
      parseInt(props.exerciseDuration.min) * 60 +
      parseInt(props.exerciseDuration.sec) * parseInt(props.repititions.rep)
    );
  }

  const [rep, setRep] = useState(1);
  useEffect(() => {
    playOn();
  }, []);
  useEffect(() => {
    if (pause) {
      speechPausePlayOn();
    } else {
      speechPlayPlayOn();
    }
  }, [pause]);
  useEffect(() => {
    if (timer < 3 && timer > 0) {
      playOn();
    }
  }, [timer, pause]);
  useEffect(() => {
    if (!pause && timer > 0) {
      setTimeout(() => {
        setTimer(timer - 1);
      }, 1000);
    } else if (option === "preprationTime" && timer === 0) {
      speechExerciseTimeStartPlayOn();
      setTimer(eTimer);
      setOPtion("exerciseDuration");
    } else if (option === "exerciseDuration" && timer === 0) {
      speechRestTimeStartPlayOn();
      setOPtion("intervalRestTime");
      setTimer(iTimer);
    }
    if (
      option === "intervalRestTime" &&
      timer === 0 &&
      rep < props.repititions.rep
    ) {
      setOPtion("exerciseDuration");
      setTimer(eTimer);
      speechExerciseTimeStartPlayOn();
      setRep(rep + 1);
    }
    if (!pause && timer > 0 && option === "exerciseDuration") {
      setTimeout(() => {
        setLeftTime(leftTime - 1);
      }, 900);
    }
  }, [timer, pause]);
  const handleSetPause = (value) => {
    setPause(value);
  };

  return (
    <Grid
      container
      style={{
        overflow: "hidden",
      }}
    >
      <Grid item xs={1} style={{ display: "flex", justifyContent: "flex-end" }}>
        <Typography
          variant="caption"
          component="p"
          style={{ cursor: "pointer" }}
          onClick={() => props?.handleGoBack("index")}
        >
          <img
            src={MenuIcon}
            alt=""
            style={{ width: "30px", height: "20px" }}
          />
          {/* {/* <MenuIcon style={{ fontSize: 40 ,color:'#ffffff'}}/> */}
        </Typography>
      </Grid>
      <Grid item xs={10} style={{ justifyContent: "center", display: "flex" }}>
        <div
          className={classes.timer}
          style={{
            background: `url(${StartBtn})`,
          }}
        >
          <Box position="relative" display="inline-flex">
            <CircularProgress
              variant="static"
              size={180}
              thickness={1.7}
              style={{ color: "#FAA902" }}
              value={
                (timer /
                  (option === "preprationTime"
                    ? Ptimer
                    : option === "exerciseDuration"
                    ? eTimer
                    : iTimer)) *
                100
              }
            />
            <Box
              top={0}
              left={0}
              bottom={0}
              right={0}
              position="absolute"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Grid
                container
                alignItems="center"
                direction="column"
                justify="center"
              >
                <Grid item>
                  <Typography
                    variant="caption"
                    component="div"
                    className={classes.timerBody}
                  >
                    {option === "exerciseDuration"
                      ? "EXER"
                      : option === "preprationTime"
                      ? "PREP"
                      : "REST"}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    variant="caption"
                    component="div"
                    className={classes.timerBody}
                  >
                    {`${rep}/${props?.repititions.rep}`}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    variant="caption"
                    component="div"
                    className={classes.timerText}
                  >
                    {parseInt(timer / 60)}:{timer - parseInt(timer / 60) * 60}
                  </Typography>
                </Grid>
                <Grid item>
                  <div
                    className={classes.playIcon}
                    onClick={() => handleSetPause(!pause)}
                  >
                    {pause ? (
                      <PlayArrowIcon style={{ fontSize: 35, color: "white" }} />
                    ) : (
                      <img
                        alt=""
                        src={Play}
                        style={{ width: "35px", height: "18px" }}
                      />
                    )}
                  </div>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </div>
      </Grid>
      <Grid item xs={1} />
      <Grid item xs={1} />
      <Grid
        item
        xs={10}
        style={{ justifyContent: "center", display: "flex", marginTop: "20px" }}
      >
        <Typography
          variant="caption"
          component="div"
          className={classes.timeLeftText}
        >
          {"Time Left"}
        </Typography>
      </Grid>
      <Grid item xs={1} />
      <Grid item xs={1} />

      <Grid
        item
        xs={10}
        style={{ justifyContent: "center", display: "flex", marginTop: "20px" }}
      >
        <Typography
          variant="caption"
          component="div"
          className={classes.timeLeft}
        >
          {parseInt(leftTime / 60)}:{leftTime - parseInt(leftTime / 60) * 60}
        </Typography>
      </Grid>
      <Grid item xs={1} />
      <Grid item xs={1} />

      <Grid
        item
        xs={10}
        style={{ justifyContent: "center", display: "flex", marginTop: "60px" }}
      >
        <Typography
          variant="caption"
          component="div"
          className={classes.endText}
          onClick={() => props?.handleGoBack("index")}
        >
          {"END"}
        </Typography>
      </Grid>
      <Grid item xs={1} />
    </Grid>
  );
}

export default SoloExercise;

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#244562",
  },
  timer: {
    width: "271px",
    height: "272px",
    backgroundRepeat: `no-repeat !important`,
    backgroundSize: `100% 100% !important`,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  startImg: {
    marginTop: "30%",
    marginLeft: "20%",
    width: "120px",
    height: "120px",
    cursor: "pointer",
  },
  timerText: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "48px",
    lineHeight: "56px",
    letterSpacing: "0.045em",
    color: theme.colors.white,
  },
  playIcon: {
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    width: "46px",
    height: "46px",
    background: `linear-gradient(180deg, #153959 0%, #7B92A6 100%)`,
  },
  timerBody: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "18px",
    lineHeight: "21px",
    color: "#F28705",
  },
  timeLeftText: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "21px",
    letterSpacing: "0.04em",
    color: `rgba(255, 255, 255, 0.8)`,
  },
  timeLeft: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: 900,
    fontSize: "36px",
    lineHeight: "42px",
    letterSpacing: "0.045em",
    color: theme.colors.white,
  },
  endText: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "24px",
    lineHeight: "28px",
    color: "#F3887F",
    cursor: "pointer",
  },
}));
