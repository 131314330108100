import React, { useState, useEffect } from 'react'
import { Grid, Avatar, Typography } from '@material-ui/core'
import ChatMessagesCard from '../../../../components/More/Chat/ChatMessagesCard'
import { ArrowBack, MoreVert } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import { useHistory, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'


function ChatMessages(props) {
    const classes = useStyles();
    const { friendInfo } = useLocation();
    const history = useHistory();
    const [selectedChatUser, setSelectedChatUser] = useState();
    const selectedUser = useSelector(state => state?.chat?.selectedChatUser)

    useEffect(() => {
        if (friendInfo) {
            setSelectedChatUser(friendInfo?.user)
        } else {
            setSelectedChatUser(selectedUser)
        }
    }, [selectedUser])


    return (
        <ChatMessagesCard />

    )
}

export default ChatMessages

const useStyles = makeStyles(theme => ({
    header: {
        height: '110px',
        backgroundColor: '#FFFFFF',
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center',
        position: 'fixed',
        zIndex: 200,
        width: '100%',
        padding: '0px 15px'
    },
    photo: {
        width: '66px',
        height: '66px',
        textAlign: 'center',
        marginLeft: '5px'
    },
    userName: {
        fontFamily: theme.fontFamily.Roboto,
        fontStyle: "normal",
        fontWeight: 'bold',
        fontSize: "16px",
        lineHeight: "137.19%",
        color: '#656565',
    }
}))