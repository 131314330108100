import React, { useState, useEffect } from "react";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";

import Grid from "@material-ui/core/Grid";
import {
  AppBar,
  TextField,
  useMediaQuery,
  Button,
  CircularProgress,
  Tooltip,
  Select,
  MenuItem,
  FormControl
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useIntl } from "react-intl";
import Cards from "../../../components/Cards/Card";
import Slider from "@material-ui/core/Slider";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardTimePicker
} from "@material-ui/pickers";
import CloseIcon from "@material-ui/icons/Close";
import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import { DatePicker } from "@material-ui/pickers";
import DateRangeIcon from "@material-ui/icons/DateRange";
import AccessTime from "@material-ui/icons/AccessTime";
import { error } from "jquery";
import { getNodeText } from "@testing-library/react";
import momentTimeZone from "moment-timezone";
import moment from "moment";
const timeZones = moment.tz.names().filter(tz => tz.match(/^(((Africa|America|Antarctica|Asia|Australia|Europe|Arctic|Atlantic|Indian|Pacific)\/.+)|(UTC))$/))



const defaultMaterialTheme = createMuiTheme({
  palette: {
    primary: { 500: "#FBAE02" }
  },
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        color: { 100: "#fff" }
      }
    },

    MuiPickersModal: {
      dialogAction: {
        color: { 500: "#FBAE02" }
      }
    }
  }
});
function Schedule(props) {
  const classes = useStyles();
  const intl = useIntl();
  const theme = useTheme();
  const params = useParams();

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  let history = useHistory();
  const [selectedDate, setSelectedDate] = React.useState(null);
  const [timeZone, setTimeZone] = React.useState(props.sessionData &&
    props.sessionData.schedule &&
    props.sessionData.schedule.timeZone
    ? props.sessionData.schedule.timeZone
    : '');
  const [selectedDates, setSelectedDates] = React.useState(
    props.sessionData &&
      props.sessionData.schedule &&
      props.sessionData.schedule.dates
      ? props.sessionData.schedule.dates
      : []
  );
  const [errorDuplicateDates, setErrorDuplicateDates] = React.useState(false);
  const [errorName, setErrorName] = React.useState(false);
  const [errorName1, setErrorName1] = React.useState(false);
  const [errorDate, setErrorDate] = React.useState(false);

  const handleDateChange = date => {
    var dayName = date?.toString().split(" ");
    if (dayName && dayName[0] !== "Invalid") {
      let find = false;
      for (let i = 0; i < selectedDates.length; i++) {
        let n = selectedDates[i];
        if (
          n.toString().split(" ")[0] === date.toString().split(" ")[0] &&
          n.toString().split(" ")[1] === date.toString().split(" ")[1] &&
          n.toString().split(" ")[2] === date.toString().split(" ")[2] &&
          n.toString().split(" ")[3] === date.toString().split(" ")[3]
        ) {
          find = true;
        }
      }
      if (find) {
        setErrorDuplicateDates(true);
      } else {
        let sDates = [...selectedDates];
        sDates = [...selectedDates, date];
        setSelectedDates([...sDates]);
      }
    } else {
      setSelectedDate(date);
    }
  };

  const handleKeypress = (e, date) => {
    setSelectedDate(date);
    e.preventDefault();
    return false;
  };
  const delateDate = index => {
    let sDate = [...selectedDates];
    sDate.splice(index, 1);
    setSelectedDates([...sDate]);
  };

  const [valueStart, setValueStart] = React.useState(
    props.sessionData &&
      props.sessionData.schedule &&
      props.sessionData.schedule.start
      ? props.sessionData.schedule.start
      : 30
  );
  const [valueEnd, setValueEnd] = React.useState(
    props.sessionData &&
      props.sessionData.schedule &&
      props.sessionData.schedule.end
      ? props.sessionData.schedule.end
      : 34
  );
  const [session_name, setSessionName] = useState(
    props.sessionData &&
      props.sessionData.sessionDetails &&
      props.sessionData.sessionDetails.name
      ? props.sessionData.sessionDetails.name
      : ""
  );

  const [daysName] = useState(getDays);
  function getDays() {
    let arr = [
      intl.formatMessage({ id: "Mon" }),
      intl.formatMessage({ id: "Tue" }),
      intl.formatMessage({ id: "Wed" }),
      intl.formatMessage({ id: "Thu" }),
      intl.formatMessage({ id: "Fri" }),
      intl.formatMessage({ id: "Sat" }),
      intl.formatMessage({ id: "Sun" })
    ];
    let newArr = [];
    let ind = new Date().getDay();
    for (let i = ind; i < arr.length; i++) {
      newArr.push(arr[i]);
    }
    for (let j = 0; j < ind; j++) {
      newArr.push(arr[j]);
    }
    newArr.push(intl.formatMessage({ id: "Daily" }));
    return newArr;
  }

  const [valueGroups, setValueGroup] = useState({
    min:
      props.sessionData &&
        props.sessionData.schedule &&
        props.sessionData.schedule.duration
        ? props.sessionData.schedule.duration
        : "15"
  });
  const [invited] = useState(
    props?.sessionData?.userIds && props?.sessionData?.sessionUsers
      ? [...props?.sessionData?.userIds, ...props?.sessionData?.sessionUsers]
      : props?.sessionData?.sessionUsers
        ? [...props?.sessionData?.sessionUsers]
          ? props?.sessionData?.userIds
          : [...props?.sessionData?.userIds]
        : []
  );
  const [sessionUsers] = useState(
    props?.sessionData?.sessionUsers ? props?.sessionData?.sessionUsers : []
  );
  const [days, setDays] = useState(
    props.sessionData &&
      props.sessionData.schedule &&
      props.sessionData.schedule.days
      ? props.sessionData.schedule.days
      : [false, false, false, false, false, false, false, false]
  );
  const [timeError, setErrorTime] = useState(false);
  const userFriends = useSelector(state => state?.userRelations?.userFriends);
  const handleSliderChangeStart = (event, newValue) => {
    setErrorTime(false);

    setValueStart(newValue);
  };
  const handleSliderChangeEnd = (event, newValue) => {
    if (newValue > valueStart) {
      setValueEnd(newValue);
      setErrorTime(false);
    } else {
      setErrorTime(true);
    }
  };

  const [optionGroups] = useState(getPickerData);
  function getPickerData() {
    let i;
    let min = [];
    for (i = 5; i <= 60; i = i + 5) {
      min.push({ value: `${i}`, label: `${i}` });
    }
    return { min: [...min] };
  }

  const handleChange = (name, value) => {
    setValueGroup({ ...valueGroups, [name]: value });
  };

  const chcekInvited = id => {
    if (invited.indexOf(id) === -1) {
      return false;
    } else {
      return true;
    }
  };


  const [startTime, setStartTime] = useState(props.sessionData &&
    props.sessionData.schedule &&
    props.sessionData.schedule.start
    ? moment(props.sessionData.schedule.start, 'LT').format()
    : moment('10:00 AM', 'LT').format());

  const [endTime, setEndTime] = useState(props.sessionData &&
    props.sessionData.schedule &&
    props.sessionData.schedule.end
    ? moment(props.sessionData.schedule.end, 'LT').format()
    : moment('11:00 AM', 'LT').format());

  const getFomatatedTime = (value, component) => {
    let hours =
      parseInt((value * 15) / 60) > 12
        ? parseInt((value * 15) / 60) - 12
        : parseInt((value * 15) / 60);
    let min = ((value * 15) / 60 - parseInt((value * 15) / 60)) * 60;
    let ampm = parseInt((value * 15) / 60) > 12 ? "PM" : "AM";
    let calculatedTime = `${hours}: ${min} ${ampm}`;
    if (hours === 0) {
      hours = 12;
    }
    if (component === "start") {
      setStartTime(calculatedTime);
    } else {
      setEndTime(calculatedTime);
    }
    return `${hours}: ${min} ${ampm}`;
  };
  function CustomThumbComponentStart(props) {
    return <span {...props}>{value}</span>;
  }

  function CustomThumbComponentEnd(props) {
    return <span {...props}>{value}</span>;
  }
  const [selectedDays, setSelectedDays] = useState(
    props.sessionData &&
      props.sessionData.schedule &&
      props.sessionData.schedule.days
      ? props.sessionData.schedule.days
      : []
  );

  const handleClickDay = (day, index) => {
    if (day === daysName[7]) {
      if (day === daysName[7] && days[7]) {
        setDays([false, false, false, false, false, false, false, false]);
      } else if (day === daysName[7]) {
        setDays([false, false, false, false, false, false, false, true]);
      }
    } else {
      const arr = [...days];
      arr[index] = !days[index];
      arr[7] = false;
      let found = selectedDays.indexOf(day);
      if (found === -1) {
        setSelectedDays([...selectedDays, day]);
      } else {
        let selectedDays1 = [...selectedDays];
        selectedDays1.splice(found, 1);
        setSelectedDays([...selectedDays1]);
      }

      setDays([...arr]);
    }
  };

  const checkSelectDays = () => {
    if (selectedDates.length > 0) {
      return false;
    } else {
      return true;
    }
  };
  const [value, setValue] = React.useState(props.curerntTab);
  const handleChangeTab = (event, newValue) => {
    props.handleChangeTab(newValue);
    setValue(newValue);
  };
  const handleCancle = () => {
    history.push("/more");
  };

  let isSessionAdded = useSelector(state => state.message);

  useEffect(() => {
    if (errorDuplicateDates) {
      setTimeout(() => setErrorDuplicateDates(false), 10000);
    }
  }, [errorDuplicateDates]);
  useEffect(() => {
    if (isSessionAdded.show === true && isSessionAdded.severity === "success") {
      setTimeout(history.push("/more"), 2000);
    }
  });
  const [isOpen, setIsOpen] = useState(false);
  const goNext = () => {
    if (
      startTime > endTime ||
      session_name === undefined ||
      session_name === "" ||
      !timeZone || selectedDates?.length === 0
    ) {
      if (session_name === undefined || session_name === "") {
        setErrorName(true);
      } if (selectedDates?.length === 0) {
        setErrorDate(true);
      }
      if (timeZone === undefined || !timeZone) {
        setErrorName1(true);
      }
      if (startTime > endTime) {
        setErrorTime(true);
      }

    }
    else {
      props.handleNext({
        schedule: {
          end: endTime,
          start: startTime,
          dates: selectedDates,
          name: session_name,
          timeZone
        }
      });
    }
  };

  return (
    <Grid
      style={{
        paddingLeft: isMobile ? "5px" : "0px",
        paddingRight: isMobile ? "5px" : "0px",
        background: isMobile ? "#FBFAF9" : ""
      }}
    >

      <Typography className={classes.SectionHaeding1} style={{ margin: isMobile ? '0px 0px -8px 0px' : '10px 0px -8px 0px' }}>{intl.formatMessage({ id: "sessionName" })}</Typography>
      <Grid container>
        <Grid
          item
          xs={12}
          md={5}
          lg={4}
          style={{
            marginBottom: "20px",
            paddingLeft: isMobile ? "0px" : "2rem",
            padding: isMobile ? "10px 20px" : "0px 20px "
          }}
        >

          <TextField
            id="standard-multiline-static"
            fullWidth
            value={session_name}
            disabled={true}

            className={classes.timeTextField}
            onChange={e => {
              setErrorName(false);
              setSessionName(e.target.value);
            }}
            margin="normal"
          />
          {errorName && (
            <small style={{ color: "red" }}>
              * Session name required!
            </small>)}
        </Grid>

      </Grid>
      <Typography className={classes.SectionHaeding1} style={{ margin: isMobile ? '0px 0px -8px 0px' : '10px 0px -8px 0px' }}>
        {intl.formatMessage({ id: "selectDate" })}
      </Typography>
      <Grid
        container
        justify="space-evenly"
        style={{ padding: isMobile ? "10px 20px" : "0px 20px " }}
      >
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid container className={classes.date}>
            <ThemeProvider theme={defaultMaterialTheme}>
              <KeyboardDatePicker

                className={classes.date}
                margin="normal"
                id="date-picker-dialog"
                open={isOpen}
                onOpen={() => {
                  setErrorDate(false)
                  setIsOpen(true)
                }
                }
                onClick={() => selectedDates?.length > 0 ? '' : setIsOpen(true)}
                InputProps={{ readOnly: true }}
                onClose={() => setIsOpen(false)}
                format="yyyy/MM/dd"
                placeholder="yyyy/MM/dd"
                value={selectedDate}
                minDate={new Date(new Date().getTime() + 86400000)}

                onChange={handleDateChange}
                KeyboardButtonProps={{
                  "aria-label": "change date"
                }}
                disabled={selectedDates?.length > 0}
              />

            </ThemeProvider>
          </Grid>
        </MuiPickersUtilsProvider>



        <Grid container>
          {selectedDates?.map((n, i) => {
            return (
              <Grid
                item
                container
                key={i}
                style={{
                  maxWidth: isMobile ? "83%" : "353px",
                  background: "#fff",
                  marginTop: "10px",
                  margin: "5px",
                  marginBottom: "10px",
                  boxShadow: "0px 4px 12px rgba(173, 173, 173, 0.05)",
                  borderRadius: "11px",
                  width: "120px",
                  height: "70px",
                  position: "relative"
                }}
              >
                <Grid
                  container
                  justify="flex-end"
                  style={{
                    position: "absolute",
                    zIndex: 2,
                    right: "5px",
                    top: "5px"
                  }}
                >
                  <Tooltip title="Delete Session">
                    <CloseIcon
                      onClick={() => delateDate(i)}
                      style={{
                        fontSize: "20px",
                        cursor: "pointer",
                        color: "#FBAE02"
                      }}
                    />
                  </Tooltip>
                </Grid>

                <Grid
                  container
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    paddingTop: "5px"
                  }}
                >

                  {n.toString().split(" ")[0]}
                  {/* {n.toString().split(" ")[0]} */}
                </Grid>
                <Grid
                  container
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  {
                    `${n.toString().split(" ")[2]}  ${n.toString().split(" ")[1]
                    }  ${n.toString().split(" ")[3]} `
                  }

                </Grid>
              </Grid>
            );
          })}
        </Grid>

      </Grid>

      <Typography className={classes.SectionHaeding1} style={{ margin: '20px 0px 8px 0px' }}>{"Time zone"}</Typography>


      <Grid container>
        <Grid
          item
          xs={12}
          md={5}
          lg={4}
          style={{
            marginBottom: "20px",
            paddingLeft: isMobile ? "0px" : "2rem",
            padding: isMobile ? "10px 20px" : "0px 20px "
          }}
        >

          <Autocomplete
            id="combo-box-demo"
            options={timeZones}
            fullWidth
            className={classes.selectTimeZone}
            onChange={(e, value) => {
              setErrorName1(false)
              setTimeZone(value)
            }}
            value={timeZone || null}
            getOptionLabel={(zone) => `(UTC ${moment.tz(zone).format('Z')})  ${zone}`}
            renderInput={(params) => <TextField classes={{ root: classes.timeTextField }} {...params} placeholder="Select time zone" />}
          />


          {errorName1 && (
            <small style={{ color: "red" }}>
              time zone required
            </small>
          )}
        </Grid>
      </Grid>

      <Typography className={classes.SectionHaeding1} style={{ margin: isMobile ? '0px 0px -8px 0px' : '10px 0px -8px 0px' }}>{"Start time"}</Typography>
      <Grid container>
        <Grid
          item
          xs={12}
          md={5}
          lg={4}
          style={{
            marginBottom: "20px",
            paddingLeft: isMobile ? "0px" : "2rem",
            padding: isMobile ? "10px 20px" : "0px 20px "
          }}
        >

          <MuiPickersUtilsProvider utils={DateFnsUtils} style={{ width: '100%' }}>
            <Grid container className={classes.date}>
              <ThemeProvider theme={defaultMaterialTheme} >
                <KeyboardTimePicker
                  className={classes.date}
                  classes={{ root: classes.timeTextField }}
                  margin="normal"
                  id="time-picker"
                  minutesStep={15}
                  value={startTime}
                  onChange={newValue => setStartTime(newValue)}
                  KeyboardButtonProps={{
                    'aria-label': 'change time',
                  }}
                  InputProps={{ readOnly: true }}

                  keyboardIcon={<AccessTime />}

                />

              </ThemeProvider>
            </Grid>
          </MuiPickersUtilsProvider>


        </Grid>
      </Grid>


      <Typography className={classes.SectionHaeding1} style={{ margin: isMobile ? '0px 0px -8px 0px' : '10px 0px -8px 0px' }}>{"End time"}</Typography>
      <Grid container>
        <Grid
          item
          xs={12}
          md={5}
          lg={4}
          style={{
            marginBottom: "20px",
            paddingLeft: isMobile ? "0px" : "2rem",
            padding: isMobile ? "10px 20px" : "0px 20px "
          }}
        >

          <MuiPickersUtilsProvider utils={DateFnsUtils} style={{ width: '100%' }}>
            <Grid container className={classes.date}>
              <ThemeProvider theme={defaultMaterialTheme} >
                <KeyboardTimePicker
                  className={classes.date}
                  classes={{ root: classes.timeTextField }}
                  margin="normal"
                  id="time-picker"
                  minutesStep={15}
                  value={endTime}
                  onChange={newValue => setEndTime(newValue)}
                  KeyboardButtonProps={{
                    'aria-label': 'change time',
                  }}
                  InputProps={{ readOnly: true }}
                  keyboardIcon={<AccessTime />}
                />
              </ThemeProvider>
            </Grid>
          </MuiPickersUtilsProvider>


          {timeError && (
            <small style={{ color: "red" }}>
              End time must be after Start time
            </small>
          )}
        </Grid>
      </Grid>
      <Grid container style={{ padding: "10px" }}>
        {selectedDates?.length > 0 && (
          <>
            <Typography
              className={classes.infoText}
              style={{ color: "#68C5E3" }}
              component="p"
            >
              {selectedDates.map((n, i) => {
                return (
                  <>
                    {`${n.toString().split(" ")[0]}  ${n.toString().split(" ")[2]
                      }  ${n.toString().split(" ")[1]}  ${n.toString().split(" ")[3]
                      } `}
                    {selectedDates.length - 1 > i && ` , `}
                  </>
                );
              })}
            </Typography>

            <Typography className={classes.infoText} component="p">
              {selectedDates?.length === 1
                ? intl.formatMessage({ id: "isSelectedFor" })
                : intl.formatMessage({ id: "areSelectedFor" })}
            </Typography>

            <Typography className={classes.infoText} component="p">
              {intl.formatMessage({ id: "between" })}
            </Typography>

            <Typography
              className={classes.infoText}
              style={{ color: "#68C5E3" }}
              component="p"
            >

              {moment(startTime).format('hh:mm a')} {intl.formatMessage({ id: "to" })} {moment(endTime).format('hh:mm a')}
            </Typography>
          </>
        )}
      </Grid>

      <Grid
        style={{
          marginTop: "20px",
          padding: isMobile ? "10px" : "0px",
          paddingLeft: isMobile ? "10px" : "2rem"
        }}
        xs={12}
        sm={10}
        md={10}
        lg={10}
      >
        <Button
          onClick={() => goNext()}

          className={classes.BtnStyle}
          style={{
            maxWidth: isMobile ? "" : "300px",
            background: "linear-gradient(180deg, #FFC000 -13.24%, #F28705 100%)"
          }}
        >

          {props.showLoader ? (
            <CircularProgress style={{ color: "#FFF" }} size={20} />
          ) : (

            isMobile
              ? intl.formatMessage({ id: "finish" })
              : intl.formatMessage({ id: "finish" })

          )}
        </Button>
      </Grid>
      {isMobile && (
        <Grid
          style={{
            paddingLeft: "10px",
            paddingRight: "10px"
          }}
          xs={12}
          sm={12}
          md={12}
          lg={10}
        >
          <Button
            onClick={() => handleCancle()}
            className={classes.cancleBtnStyle}
          >
            {intl.formatMessage({ id: "cancel" })}
          </Button>
        </Grid>
      )}
    </Grid>
  );
}

export default Schedule;

const useStyles = makeStyles(theme => ({
  date: {
    "& .MuiPickersDatePickerRoot-toolbar": {
      backgroundColor: "#FBAE02"
    }
  },
  friendsRoot: {
    width: "130px",
    maxHeight: "260px",
    borderRadius: "11px",
    backgroundColor: "#FFF",
    boxShadow: " 0px 4px 4px rgba(0, 0, 0, 0.05)"
  },
  friendsRootMobile: {
    width: "100px",
    maxHeight: "260px",
    borderRadius: "11px",
    backgroundColor: "#FFF",
    boxShadow: " 0px 4px 4px rgba(0, 0, 0, 0.05)"
  },
  addFriedRoot: {
    width: "80px",
    maxHeight: "260px",
    backgroundColor: "#FBAE02",
    height: "8rem",
    boxShadow: "0px 4px 12px rgba(173, 173, 173, 0.05)",
    borderRadius: "11px"
  },
  daysRoot: {
    marginBottom: "14px",
    width: "77px",
    maxHeight: "260px",
    borderRadius: "11px",
    boxShadow: " 0px 4px 4px rgba(0, 0, 0, 0.05)"
  },
  selectedDaysRoot: {
    marginBottom: "14px",
    width: "77px",
    maxHeight: "260px",
    borderRadius: "11px",
    backgroundColor: "#FBAE02",
    boxShadow: " 0px 4px 4px rgba(0, 0, 0, 0.05)"
  },
  addFriendTitle: {
    fontFamily: theme.fontFamily.Reef,
    fontStyle: "normal",
    fontSize: "25px",
    paddingTop: "39px",
    paddingBottom: "25px",
    textAlign: "center",
    color: "#fff"
  },
  title: {
    fontFamily: theme.fontFamily.Reef,
    fontStyle: "normal",
    fontSize: "25px",
    textAlign: "center",
    color: "#fff"
  },
  unSelectedDaysTitle: {
    fontFamily: theme.fontFamily.Reef,
    fontStyle: "normal",
    fontSize: "25px",
    textAlign: "center",
    color: "#3C4A6B"
  },
  daysTitle: {
    fontFamily: theme.fontFamily.Reef,
    fontStyle: "normal",
    fontSize: "25px",
    textAlign: "center",
    color: "#fff"
  },
  subTitle: {
    fontFamily: theme.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: "900",
    fontSize: "10px",
    lineHeight: "14px",
    textAlign: "center"
  },
  containerStyle: {
    padding: "0px",
    height: "8rem",
    justifyContent: "space-around"
  },
  containerStyleAdd: {
    padding: "0px",
    height: "8rem",
    width: "80px",
    boxShadow: "0px 4px 12px rgba(173, 173, 173, 0.05)",
    borderRadius: "11px",
    justifyContent: "space-around"
  },
  daysContainerStyle: {
    padding: "0px",
    height: "6rem",
    justifyContent: "space-around"
  },
  hideScrollBar: {
    flexWrap: "unset",
    overflowX: "auto",
    "&::-webkit-scrollbar": {
      display: "none"
    }
  },
  timeHeader: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "16px",
    color: "#192247"
  },
  SectionHaeding: {
    paddingLeft: " 20px",
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: "500",
    fontsize: "17px",
    lineHeight: "20px",
    color: "#606060",
    marginTop: "10px",
    textAlign: "left"
  },
  SectionHaeding1: {
    paddingLeft: " 20px",
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: "normal",
    fontze: "17px",
    lineHeight: "20px",
    color: "#192247",
    textAlign: "left"
  },

  infoText: {
    paddingLeft: " 5px",
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: "normal",
    fontze: "17px",
    lineHeight: "20px",
    color: "#192247"
  },
  BtnStyle: {
    width: "100%",
    height: "2.750em",
    background: "linear-gradient(180deg, #FFC000 -13.24%, #F28705 100%)",
    borderRadius: "11px",
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "20px",
    lineHeight: "23px",
    textTransform: "none",
    color: "#FFFFFF"
  },
  cancleBtnStyle: {
    width: "100%",
    height: "2.750em",
    borderRadius: "11px",
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: 500,
    textTransform: "none",
    fontSize: "20px",
    lineHeight: "23px",
    color: "#D95959",
    marginBottom: "10px"
  },
  boxShadows: {
    background: "#fff",

    marginTop: "10px",
    marginBottom: "20px",
    boxShadow: "0px 4px 12px rgba(173, 173, 173, 0.05)",
    borderRadius: "11px",
    padding: "20px"
  },
  appBar: {
    backgroundColor: "#FBFAF9",
    width: "100%",
    boxShadow: "none",
    display: "flex",
    marginBottom: "20px",
    justifyContent: "space-between"
  },
  headerGrid: {
    background: "#FBF1E0",
    height: "2.75em",
    fontSize: "20px",
    boxShadow: `inset 2px 2px 4px rgba(0, 65, 72, 0.05)`,
    borderRadius: `12px`
  },
  selectedBtn: {
    background: "linear-gradient(180deg, #FFC000 -13.24%, #F28705 100%)",
    width: "100%",
    height: "2.750em",
    borderRadius: "11px",
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: 500,
    textTransform: "none",
    fontSize: "20px",
    lineHeight: "23px",
    color: "#fff",
    marginBottom: "70px"
  },
  subTitleStyle: {
    fontFamily: theme.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: "900",
    fontSize: "10px",
    lineHeight: "14px",
    textAlign: "center",
    color: "#fff"
  },
  unselectedBtn: {
    width: "100%",
    height: "2.750em",
    borderRadius: "11px",
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: 500,
    textTransform: "none",
    fontSize: "20px",
    lineHeight: "23px",
    color: "#F8A303",
    marginBottom: "70px"
  },
  cssApply: {
    "& .picker-container": {
      "& .picker-item": {
        color: "#FAAB02  !important"
      },
      "& .picker-item-selected": {
        fontFamily: theme.fontFamily.Roboto,
        fontStyle: "normal",
        fontWeight: 900,
        fontSize: "30px",
        lineHeight: " 42px",
        color: "#FAAB02 !important"
      }
    }
  },
  nameTextField: {
    "& .MuiInput-root": {
      fontFamily: theme.fontFamily.Roboto,
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "20px",
      lineHeight: "23px",
      color: "#000000"
    },
    "& .MuiInputBase-multiline": {
      padding: `10px 10px`
    },
    "& .MuiInputLabel-root": {
      fontFamily: theme.fontFamily.Roboto,
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "17px",
      lineHeight: "20px",
      color: "#9E9393",
      "&::after": {
        fontFamily: theme.fontFamily.Roboto,
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "17px",
        lineHeight: "20px",
        color: "#9E9393"
      }
    },

    "& .MuiInput-underline:before": {
      borderBottom: "1px solid rgba(168, 182, 200, 0.3)",
      borderTop: "none"
    },
    "& .MuiInput-underline::after": {
      borderBottom: "1px solid rgba(168, 182, 200, 0.3)"
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled)::before": {
      borderBottom: "1px solid rgba(168, 182, 200, 0.3)"
    }
  },
  timeTextField: {
    "& .MuiInput-root": {
      width: "100%",
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: 500,
      borderRadius: "5px",
      fontSize: "14px",
      color: "rgba(0, 0, 0, 0.87)",
      paddingRight: "10px",

    },
    "& .MuiInputBase-multiline": {
      padding: `10px 10px`
    },
    "& .MuiInputLabel-root": {
      fontFamily: theme.fontFamily.Roboto,
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "17px",
      lineHeight: "20px",
      color: "#9E9393",
      "&::after": {
        fontFamily: theme.fontFamily.Roboto,
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "17px",
        lineHeight: "20px",
        color: "#9E9393"
      }
    },

    "& .MuiInput-underline:before": {
      borderBottom: "1px solid rgba(168, 182, 200, 0.3)",
      borderTop: "none"
    },
    "& .MuiInput-underline::after": {
      borderBottom: "1px solid rgba(168, 182, 200, 0.3)"
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled)::before": {
      borderBottom: "1px solid rgba(168, 182, 200, 0.3)"
    }
  },
  selectTimeZone: {
    width: "100%",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 500,
    borderRadius: "5px",
    fontSize: "14px",
    color: "#CECDCC",
    paddingRight: "10px",
    "&.MuiInput-underline::before": {
      borderBottom: "1px solid rgba(168, 182, 200, 0.3)",
    },
    "&.MuiInput-underline:after": {
      borderBottom: "1px solid rgba(168, 182, 200, 0.3)",
    },
    "&.MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottom: "1px solid rgba(168, 182, 200, 0.3)",
    },
  },
}));

const CustomSlider = withStyles({
  root: {
    color: "rgba(168, 182, 200, 0.3)",
    padding: "13px 0"
  },
  thumb: {
    borderRadius: "50px",
    color: "#fff",
    height: "40px",
    width: "65px",
    backgroundColor: "#FAAA02",
    marginTop: -17,
    marginLeft: -13,
    boxShadow: "#ebebeb 0 2px 2px",
    "&:focus, &:hover, &$active": {
      boxShadow: "#ccc 0 2px 3px 1px"
    },
    "& .bar": {
      height: 9,
      width: 1,
      backgroundColor: "rgba(168, 182, 200, 0.3)",
      marginLeft: 1,
      marginRight: 1
    }
  },
  active: {},
  track: {
    height: 3
  },
  rail: {
    color: "#d8d8d8",
    opacity: 1,
    height: 3
  }
})(Slider);
