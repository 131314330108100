import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  CircularProgress,
  InputBase,
  FormControl,
  NativeSelect,
  useMediaQuery,
} from "@material-ui/core";
import CourseCard from "../../../components/Common/CourseCard";
import { useLocation, Link, useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import moment from "moment";
import { getAllCategories } from "../../../actions/courses";
import { getRecentChallenges } from "../../../actions/challenges";

import CategoryCard from "../../../components/Common/CategoryCard";
import ViewAllSearchComponent from "../../../components/Common/ViewAllSearchComponent";
import Header from "../../../components/Common/MobileHeader/HeaderWithMoreSearchIcon";
import CategoriesFilter from "../../../components/Common/CategoriesFilters";
import SearchedComponent from "./SearchedComponent";
import TopBar from "../../../components/AppBar";
import "./searchFeed.scss";
import SubscribeNowBtn from "../../../components/Common/SubscribeNowBtn";

const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: "#FFEFD1",
    border: "1px solid #FFEFD1",
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    fontFamily: "Reef",
    fontStyle: "normal",
    fontWeight: 900,
    fontSize: "18px",
    lineHeight: "25px",
    "&:focus": {
      borderRadius: 4,
      borderColor: "#FBAE02",
      boxShadow: "0 0 0 0.2rem #FFEFD1",
    },
  },
}))(InputBase);

function SearchFeed() {
  const classes = useStyles();
  const { id, state } = useLocation();
  const theme = useTheme();
  const history = useHistory();
  const params = useParams();
  let dispatch = useDispatch();
  const authReducerState = useSelector((state) => state.auth);
  const loader = useSelector((state) => state.loader?.loader);
  const searchData = useSelector((state) => state?.courses?.searchData);
  const recentChallenges = useSelector(
    (state) => state?.challenges?.recentChallenges
  );
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const categoriesList = useSelector((state) => state?.courses?.categoriesList);
  const [displayMobileSearchBar, setDisplayMobileSearchBar] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [searching, setSearching] = useState(false);
  const [searchSpecificIn, setSearchSpecificIn] = useState("Course");
  const [searchTerm, setSearchTerm] = useState("");

  const getData = () => {
    dispatch(getAllCategories({ token: authReducerState?.token }));
    dispatch(
      getRecentChallenges({
        token: authReducerState?.token,
        page: 1,
        limit: 12,
        categoryId: "",
      })
    );
  };

  const handleSubCategory = (e, childCategory) => {
    e.preventDefault();
    history.push({
      pathname: `/sub-category/${childCategory?.name}`,
      state: { category: childCategory },
    });
  };

  useEffect(() => {
    getData();
  }, []);

  const searchState = (isSearched, searchKeyword) => {
    setSearching(isSearched);
    setSearchTerm(searchKeyword);
    if (!isSearched && searching) {
      getData();
    }
  };

  const openMobileSearchBar = () => {
    setDisplayMobileSearchBar(!displayMobileSearchBar);
  };

  const closeMobileSearchBar = () => {
    setSearching(false);
    setDisplayMobileSearchBar(!displayMobileSearchBar);
    getData();
  };

  const handleSelectedCategory = (categoryId) => {
    setSelectedCategory(categoryId);
  };

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        container
        style={{
          paddingLeft: !isMobile && "45px",
          background: isMobile && "#F8F8F8",
          minHeight: "100vh",
        }}
      >
        {isMobile && (
          <Grid item xs={12} style={{ height: "66px" }}>
            {!displayMobileSearchBar ? (
              <Header
                text="Explore"
                openMobileSearchBar={openMobileSearchBar}
              />
            ) : (
              <ViewAllSearchComponent
                searchState={searchState}
                searchType={searchSpecificIn}
                mobileSearch={true}
                closeMobileSearchBar={closeMobileSearchBar}
                categoryId={selectedCategory}
              />
            )}
          </Grid>
        )}

        {!isMobile && (
          <Grid
            item
            xs={12}
            container
            style={{
              minHeight: "90px",
              position: "fixed",
              backgroundColor: "#fff",
              zIndex: 5,
            }}
          >
            <Grid item xs={12} sm={12} md={2} lg={2} xl={1}>
              <TopBar
                appBarConfig={{ show: true, text: "Explore" }}
                style={{
                  color: "#068594",
                  backgroundColor: "#FCFCFC",
                  float: "left",
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={10}
              md={9}
              lg={9}
              xl={10}
              style={{
                minHeight: "90px",
                alignItems: "center",
                display: "flex",
                padding: isMobile && "0px 15px 0px 0px",
                marginLeft: !isMobile && "25px",
                zIndex: 5,
              }}
            >
              <ViewAllSearchComponent
                searchState={searchState}
                searchType={searchSpecificIn}
                categoryId={selectedCategory}
              />
            </Grid>
          </Grid>
        )}

        {searchTerm && searchData?.length > 0 && (
          <Grid
            item
            xs={12}
            style={{
              padding: isMobile ? "15px 15px 15px 15px" : "15px 0px 15px 25px",
              marginTop: isMobile ? "0px" : "90px",
              borderBottom: "1px solid #EAEAEA",
            }}
            container
          >
            <Grid item xs={6} md={4} lg={3}>
              <Typography className={classes.specificSearchHeading}>
                Results for ‘{searchTerm}’
              </Typography>
            </Grid>
            <Grid
              item
              xs={6}
              md={4}
              lg={3}
              style={{ textAlign: isMobile && "right" }}
            >
              <Typography className={classes.specificSearchHeading}>
                {searchData?.length}
              </Typography>
            </Grid>
          </Grid>
        )}

        {searching && (
          <Grid
            item
            xs={12}
            container
            style={{
              padding: isMobile ? "15px 15px 15px 15px" : "15px 0px 15px 25px",
              marginTop:
                searchTerm && searchData?.length === 0 && !isMobile && 80,
            }}
          >
            <Grid
              item
              xs={6}
              md={3}
              lg={2}
              style={{ display: "flex", alignItems: "center" }}
            >
              <Typography className={classes.specificSearchHeading}>
                Search specific in
              </Typography>
            </Grid>
            <Grid item xs={6} md={3} lg={2}>
              <FormControl className={classes.margin} style={{ width: "100%" }}>
                <NativeSelect
                  value={searchSpecificIn}
                  onChange={(e) => setSearchSpecificIn(e.target.value)}
                  input={<BootstrapInput />}
                >
                  <option value={"Course"}>Online Courses</option>
                  <option value={"Challenge"}>Online Challenges</option>
                  <option value={"Session"}>Live Sessions</option>
                  <option value={"Retreat"}>Online Retreats</option>
                  <option value={"TeacherTraining"}>
                    Online Teacher Trainings
                  </option>
                </NativeSelect>
              </FormControl>
            </Grid>
          </Grid>
        )}

        {searching &&
          searchSpecificIn !== "Session" &&
          searchSpecificIn !== "Challenge" && (
            <Grid
              item
              xs={12}
              style={{
                padding: isMobile ? "15px 0px 15px 15px" : "15px 0px 15px 25px",
              }}
            >
              <CategoriesFilter
                handleSelectedCategory={handleSelectedCategory}
              />
            </Grid>
          )}

        {searching ? (
          <Grid
            item
            xs={12}
            container
            style={{
              padding: isMobile ? "15px 0px 15px 15px" : "15px 0px 15px 25px",
            }}
          >
            {" "}
            <SearchedComponent searchSpecificIn={searchSpecificIn} />{" "}
          </Grid>
        ) : loader ? (
          <Grid item xs={12} className={classes.center}>
            <CircularProgress
              color="primary"
              style={{ color: "#09A9BB" }}
              size={30}
            />
          </Grid>
        ) : (
          <Grid
            container
            style={{
              padding: isMobile ? "5px 5px 90px 10px" : "90px 15px 15px 25px",
            }}
          >
            <Grid
              container
              xs={12}
              style={{ display: "flex" }}
              className={classes.headingContainer}
            >
              <Grid item xs={10}>
                <Typography
                  style={{ float: "left", textTransform: "uppercase" }}
                  className={classes.challengeHeading1}
                >
                  New challenges
                </Typography>
                {/* {isMobile && <Grid item xs={2} sm={3} md={3} style={{marginLeft: '330px' ,marginRight: '60px' ,marginBottom: '5px'}} className={classes.subscribeBtnContainer} >
                    <SubscribeNowBtn/>
                </Grid>}  */}
              </Grid>
              <Grid item xs={2}>
                <Link to={`/view-all-challenges`} className={classes.viewAll1}>
                  view all
                </Link>
              </Grid>
            </Grid>

            <Grid
              item
              xs={12}
              container
              className={classes.hideScrollBar}
              spacing={2}
            >
              {loader ? (
                <Grid container justify="center">
                  <CircularProgress
                    color="primary"
                    style={{ color: "#09A9BB" }}
                    size={20}
                  />
                </Grid>
              ) : recentChallenges && recentChallenges?.length === 0 ? (
                <Grid item xs={12}>
                  <Typography className={classes.noContent}>
                    No Record found
                  </Typography>
                </Grid>
              ) : (
                recentChallenges?.map((challenge, ind) => {
                  return (
                    <Grid item key={ind}>
                      <Link
                        to={`/challenge/details/${challenge?.id}`}
                        style={{ textDecoration: "none" }}
                      >
                        <CourseCard
                          image={challenge?.imageUrl}
                          category={challenge?.course?.category?.name}
                          friends={
                            challenge?.participantsCount > 1
                              ? `${challenge?.participantsCount} friends`
                              : challenge?.participantsCount === 1
                              ? `${challenge?.participantsCount - 1} friend`
                              : "0 friend"
                          }
                          price={challenge?.price}
                          plan={challenge?.plan}
                          name={challenge?.title}
                          totalDuration={Math.round(challenge?.duration / 60)}
                          time={moment(challenge?.exerciseTime, "LTS").format(
                            "LT"
                          )}
                        />
                      </Link>
                    </Grid>
                  );
                })
              )}
            </Grid>

            {categoriesList
              ?.filter((item) => item?.parentCategoryId === null)
              .map((category, ind) => {
                const { name, id } = category;
                return (
                  <React.Fragment key={category.id}>
                    {category?.coursesCount ? (
                      <React.Fragment key={ind}>
                        <Grid
                          item
                          xs={12}
                          container
                          className={classes.headingContainer}
                          id={name}
                        >
                          <Grid item xs={6}>
                            <Typography className={classes.heading1}>
                              {name}
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Link
                              to={{
                                pathname: `/category/${name}`,
                                state: { category },
                              }}
                              className={classes.viewAll1}
                            >
                              view all
                            </Link>
                          </Grid>
                        </Grid>

                        <Grid
                          item
                          container
                          xs={12}
                          className={classes.scrollBar}
                          spacing={isMobile ? 2 : 3}
                        >
                          {category?.childCategories?.map(
                            (childCategory, index) => {
                              return (
                                <React.Fragment key={childCategory.id}>
                                  {childCategory?.coursesCount ? (
                                    <Grid
                                      item
                                      md={3}
                                      xs={8}
                                      key={index}
                                      onClick={(e) =>
                                        handleSubCategory(e, childCategory)
                                      }
                                      style={{
                                        minWidth: isMobile ? "270px" : "380px",
                                        cursor: "pointer",
                                      }}
                                    >
                                      <CategoryCard
                                        image={childCategory?.thumbnail
                                          ?.replace(/'/, "")
                                          .trim()}
                                        name={childCategory?.name}
                                        courseNameBold="true"
                                      />
                                    </Grid>
                                  ) : null}
                                </React.Fragment>
                              );
                            }
                          )}
                        </Grid>
                      </React.Fragment>
                    ) : null}
                  </React.Fragment>
                );
              })}
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}

export default SearchFeed;

const useStyles = makeStyles((theme) => ({
  noContent: {
    color: "#7d7b79",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "19px",
    padding: "12px 10px 10px 17px",
    textAlign: "center",
  },
  heading: {
    fontFamily: theme.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: 900,
    fontSize: "20px",
    lineHeight: "27px",
    color: "#000000",
    marginBottom: "15px",
  },
  viewAll: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "16px",
    color: " #068594",
    textDecoration: "none",
  },
  headingContainer: {
    display: "flex",
    alignItems: "center",
    padding: "20px 0px 10px 0px",
  },

  heading1: {
    fontFamily: theme.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: 900,
    fontSize: "20px",
    lineHeight: "27px",
    color: "#000000",
  },
  challengeHeading1: {
    fontFamily: theme.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: 900,
    fontSize: "20px",
    lineHeight: "27px",
    color: "#000000",
    textTransform: "uppercase",
  },
  viewAll1: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "16px",
    color: "#B4B4B4",
    textDecoration: "none",
    paddingRight: "5px",
    justifyContent: "flex-end",
    display: "flex",
    alignItems: "center",
  },
  hideScrollBar: {
    flexWrap: "unset",
    overflowX: "auto",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  scrollBar: {
    flexWrap: "unset",
    overflowX: "auto",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  tabsContainer: {
    display: "flex",
  },
  appBar: {
    backgroundColor: "#FFF",
    width: "100%",
    boxShadow: "none",
    display: "flex",
    justifyContent: "space-between",
  },
  tabLabel: {
    color: "#080808e3 !important",
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "19px",
    minWidth: "20%",
    textTransform: "none",
  },
  searchFilter: {
    color: "#b1b2b3 !important",
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "14px",
    textAlign: "center",
    minWidth: "20%",
    textTransform: "none",
  },
  tabSelected: {
    color: "#09a9bb !important",
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "19px",
  },

  feedSearchField: {
    "& .MuiInputBase-root": {
      color: "#A9A9A9",
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "18px",
      lineHeight: "21px",
      padding: "10px 10px 10px 10px",
      marginTop: "25px",
      marginBottom: "15px",
      height: "58px",
      width: "100%",
      border: "1px solid #A9A9A9",
      borderRadius: "10px",
    },
    "& .MuiInput-underline:before": {
      border: "0px solid #FFF",
    },
    "& .MuiInput-underline::after": {
      border: "0px solid #FFF",
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled)::before": {
      border: "0px solid #FFF",
    },
  },
  center: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));
