import API from "../utils/API";

import {
  SET_LANGUAGES,
  UPDATE_SETTING,
  SET_SETTING,
  SHOW_MESSAGE,
  UPDATE_USERNAME,
  SETTING_LOADER,
} from "./types";

export const getAllLanguages = ({ token }) => async (dispatch) => {
  dispatch({
    type: SETTING_LOADER,
    payload: { showLoader: true, isUpdated: false },
  });
  // set header
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const res = await API.get(`/api/v1/setting/getAllLanguages`, config);
    dispatch({
      type: SET_LANGUAGES,
      payload: res.data.data,
    });
  } catch (error) {
    console.log(error);
  }
};
export const getSettings = ({ token }) => async (dispatch) => {
  // set header
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const res = await API.get(`/api/v1/setting/getSettings`, config);
    dispatch({
      type: SET_SETTING,
      payload: res.data.data,
    });
  } catch (error) {
    console.log(error);
  }
};

export const updateSetting = ({ token, setting }) => async (dispatch) => {
  dispatch({
    type: SETTING_LOADER,
    payload: { showLoader: true, isUpdated: false },
  });

  // set header
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const body = JSON.stringify({ ...setting });
    const res = await API.patch(`/api/v1/setting/updateSettings`, body, config);
    dispatch({
      type: UPDATE_SETTING,
      payload: { ...setting },
    });
    dispatch({
      type: SETTING_LOADER,
      payload: { showLoader: true, isUpdated: true },
    });
    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        title: `success`,
        message: res.data.message,
        severity: "success",
      },
    });
  } catch (error) {
    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        title: `Failed`,
        message:
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : `Update ${Object.keys(setting)[0]} failed`,
        severity: "error",
      },
    });
  }
};
export const updateUsername = ({ token, username }) => async (dispatch) => {
  // set header
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const body = JSON.stringify({ ...username });
    const res = await API.post(`/api/v1/user/updateUsername`, body, config);
    dispatch({
      type: UPDATE_USERNAME,
      payload: res.data.data,
    });
    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        title: `success`,
        message: res.data.message,
        severity: "success",
      },
    });
  } catch (error) {
    console.log("error updating user name error :", error);
    console.log("error updating user name:", error.response);
    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        title: `Failed`,
        message:
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : "failed",
        severity: "error",
      },
    });
  }
};

export const addSuggestion = ({ token, suggestion }) => async (dispatch) => {
  // set header
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const body = JSON.stringify({ ...suggestion });

    const res = await API.post(`/api/v1/setting/addSuggestion`, body, config);

    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        title: `success`,
        message: res.data.message,
        severity: "success",
      },
    });
  } catch (error) {
    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        title: `Failed`,
        message:
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : `Add Suggestion failed`,
        severity: "error",
      },
    });
  }
};
