import API from "../utils/API";

// types
import {
  TRAINING_COURSE_DETAIL,
  SHOW_MESSAGE,
  TRAINING_COURSE_REQUESTS,
  TRAINING_COURSE_PARTICIPANTS,
  GET_ALL_TRAINING_COURSE,
  COURSE_FAVORITE,
  TRAINING_COURSE_CONTENT,
  TRAINING_COURSE_REQUESTS_STATUS,
  TRAINING_COURSE_SECTION_SUPPORTED_FILES,
  TRAINING_COURSE_COMMENTS,
  ADD_COURSE_COMMENT,
  SET_TRAINING_COURSE_SECTIONS,
  TRAINING_COURSE_ANNOUNCEMENTS,
  ACTIVE_TRAINING_COURSE,
  GET_ENROLLED_RETREATS,
  GET_SECTION_ASSIGNMENTS,
  STUDENT_SUBMITTED_SECTION_ASSIGNMENTS_FILES,
  STUDENT_ASSIGNMENT_APPROVE_DISAPPROVE,
  GET_RETREAT_ASSIGNMENTS_FILES,
  ADD_UPDATE_RETREAT_PLAN,
  GET_RETREAT_INVITED_USERS,
  CHANGE_VIEW_STATUS_LESSON,
  RETREAT_STATS_DETAIL,
  GET_ALL_CREATED_TEACHER_TRAINING,
  GET_ENROLLED_TEACHER_TRAININGS
} from "./types";


// get course details
export const getTrainingCourseDetailById = ({ token, courseId }) => async dispatch => {

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    params: {
      courseId
    }
  };

  try {
    const res = await API.get(`/api/v1/trainingCourse/getCourseDetails`, config);
    const mentorsRes = await dispatch(getRetreatMentors({ token, courseId }));
    dispatch({
      type: TRAINING_COURSE_DETAIL,
      payload: { ...res.data.data, mentors: mentorsRes?.mentors, isMentor: mentorsRes?.isMentor }
    });

    return { ...res.data.data, mentors: mentorsRes };
  } catch (error) {
    console.log(error);

    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        title: `Could not get course`,
        message:
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : ` Something went wrong try after some time`,
        severity: "error"
      }
    });
    return {
      status: "fail",
      message: error?.response?.data?.message
        ? error?.response?.data?.message
        : "something went wrong"
    };
  }
};

// get training course content
export const getTrainingCourseContent = ({ token, trainingCourseId }) => async dispatch => {

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    params: {
      trainingCourseId
    }
  };

  try {
    const res = await API.get(`/api/v1/trainingCourse/getCourseContent`, config);
    dispatch({
      type: TRAINING_COURSE_CONTENT,
      payload: res.data.data
    });

    return res.data.data;
  } catch (error) {
    console.log(error);

    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        title: `Could not get course`,
        message:
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : ` Something went wrong try after some time`,
        severity: "error"
      }
    });
    return {
      status: "fail",
      message: error?.response?.data?.message
        ? error?.response?.data?.message
        : "something went wrong"
    };
  }
};

//apply for training course
export const applyForJoinTrainingCourse = ({ token, courseId }) => async dispatch => {

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    params: {
      courseId
    }
  };
  const body = JSON.stringify({ courseId });

  try {
    await API.post(`/api/v1/trainingCourse/applyTrainingCourse`, body, config);
    dispatch(getTrainingCourseDetailById({ token, courseId }));
  } catch (error) {
    console.log(error);
    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        title: `Could not get course`,
        message:
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : ` Something went wrong try after some time`,
        severity: "error"
      }
    });

  }
};

// get training course requests
export const getTrainingCourseRequests = ({ token, trainingCourseId }) => async dispatch => {

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    params: {
      trainingCourseId
    }
  };

  try {
    const res = await API.get(`/api/v1/trainingCourse/appliedUsersList`, config);
    dispatch({
      type: TRAINING_COURSE_REQUESTS,
      payload: res.data.data
    });

    return { status: "success" };
  } catch (error) {
    console.log(error);

    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        title: `Could not get course`,
        message:
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : ` Something went wrong try after some time`,
        severity: "error"
      }
    });
    return {
      status: "fail",
      message: error?.response?.data?.message
        ? error?.response?.data?.message
        : "something went wrong"
    };
  }
};

// get training course Participants
export const getTrainingCourseParticipants = ({ token, courseId }) => async dispatch => {

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },

  };

  try {
    const res = await API.get(`/api/v1/trainingCourse/getParticipantsList/${courseId}`, config);
    dispatch({
      type: TRAINING_COURSE_PARTICIPANTS,
      payload: res.data.data
    });

    return { status: "success" };
  } catch (error) {
    console.log(error);

    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        title: `Could not get course`,
        message:
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : ` Something went wrong try after some time`,
        severity: "error"
      }
    });
    return {
      status: "fail",
      message: error?.response?.data?.message
        ? error?.response?.data?.message
        : "something went wrong"
    };
  }
};


//accept reject student join request
export const acceptRejectJoinRequest = ({ token, courseId, status, userId, requestId }) => async dispatch => {

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },

  };
  const body = JSON.stringify({ courseId, status, userId });

  try {
    const res = await API.post(`/api/v1/trainingCourse/acceptOrRejectStudent`, body, config);
    dispatch({
      type: TRAINING_COURSE_REQUESTS_STATUS,
      payload: { requestId, status },
    });
    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        title: `success`,
        message: res.data.message,
        severity: "success",
      },
    });
    return { status: 'success' }

  } catch (error) {
    console.log(error);
    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        title: `Could not get course`,
        message:
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : ` Something went wrong try after some time`,
        severity: "error"
      }
    });

  }
};

//change lesson started/completed status
export const lessonStartOrCompleteStatus = ({ token, lessonId, status, trainingCourseId }) => async dispatch => {

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },

  };
  const body = JSON.stringify({ lessonId, status });

  try {
    const res = await API.post(`/api/v1/trainingCourse/episodeStartOrComplete`, body, config);

    if (status === 'Completed') {
      await dispatch({
        type: CHANGE_VIEW_STATUS_LESSON,
        payload: res?.data?.data
      })
      dispatch(getTrainingCourseContent({ token, trainingCourseId }));
    }
    return { status: 'success' }

  } catch (error) {
    console.log(error);
  }
};


//get all training courses
export const getAllCreatedTrainingCourses = ({ token, retreatType }) => async dispatch => {

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    params: {
      retreatType
    }

  };

  try {
    const res = await API.get(`/api/v1/trainingCourse/getUserUploadedCourses`, config);
    if (retreatType === 'retreat') {
      dispatch({
        type: GET_ALL_TRAINING_COURSE,
        payload: res.data.data
      });
    } else if (retreatType === 'teacherTraining') {
      dispatch({
        type: GET_ALL_CREATED_TEACHER_TRAINING,
        payload: res.data.data
      });
    }

    return { status: "success" };
  } catch (error) {
    console.log(error);

    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        title: `Could not get course`,
        message:
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : ` Something went wrong try after some time`,
        severity: "error"
      }
    });
    return {
      status: "fail",
      message: error?.response?.data?.message
        ? error?.response?.data?.message
        : "something went wrong"
    };
  }
};

// Delete training course
export const deleteTrainingCourse = ({ token, courseId }) => async dispatch => {

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    }
  };
  try {
    const res = await API.delete(
      `/api/v1/trainingCourse/delete/${courseId}`,
      config
    );
    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        title: `Course Deleted`,
        message:
          res.data && res.data.message ? res.data.message : `Course Deleted`,
        severity: "warning"
      }
    });
    dispatch(getAllCreatedTrainingCourses({ token }))
    return { status: 'success' }
  } catch (error) {

    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        title: `Course Deleted`,
        message:
          error.response &&
            error.response.data &&
            error.response.data.errors &&
            error.response.data.errors.length
            ? error.response.data.errors[0]
            : error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : `Something went wrong while deleting`,
        severity: "error"
      }
    });
    return { status: 'fail' }

  }
};

//Add favorite course
export const addFavoriteCourse = ({ token, courseId }) => async dispatch => {

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    params: {
      courseId
    }

  };
  const body = JSON.stringify({ courseId });

  try {
    const res = await API.post(`/api/v1/trainingCourse/addFavouriteCourse`, body, config);

    dispatch({
      type: COURSE_FAVORITE,
      payload: true
    });

    dispatch({
      type: SHOW_MESSAGE,
      title: `Add Favorite`,
      payload: {
        title: `success`,
        message: res.data.message,
        severity: "success",
      },
    });
    return { status: 'success' }

  } catch (error) {
    console.log(error);
    dispatch({
      type: SHOW_MESSAGE,
      title: `Add Favorite`,
      payload: {
        title: `Could not get course`,
        message:
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : ` Something went wrong try after some time`,
        severity: "error"
      }
    });

  }
};

// Delete  course favorite
export const deleteTrainingCourseFavorite = ({ token, courseId }) => async dispatch => {

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    params: {
      courseId
    }
  };
  try {
    const res = await API.delete(
      `/api/v1/trainingCourse/deleteFavouriteCourse`,
      config
    );
    dispatch({
      type: COURSE_FAVORITE,
      payload: false
    });

    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        title: `Delete Favorite`,
        message:
          res.data && res.data.message ? res.data.message : `Delete Favorite`,
        severity: "warning"
      }
    });

    return { status: 'success' }
  } catch (error) {

    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        title: `Delete Favorite`,
        message:
          error.response &&
            error.response.data &&
            error.response.data.errors &&
            error.response.data.errors.length
            ? error.response.data.errors[0]
            : error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : `Something went wrong while deleting favorite`,
        severity: "error"
      }
    });
    return { status: 'fail' }

  }
};


// get section supported files
export const getSectionsSupportedFiles = ({ token, sectionId }) => async dispatch => {

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    params: {
      sectionId
    }

  };

  try {
    const res = await API.get(`/api/v1/trainingCourse/getSectionFiles`, config);
    dispatch({
      type: TRAINING_COURSE_SECTION_SUPPORTED_FILES,
      payload: res.data.data
    });

    return { status: "success" };
  } catch (error) {
    console.log(error);

    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        title: `Could not get files`,
        message:
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : ` Something went wrong try after some time`,
        severity: "error"
      }
    });
    return {
      status: "fail",
      message: error?.response?.data?.message
        ? error?.response?.data?.message
        : "something went wrong"
    };
  }
};

// get  course announcements
export const getCourseAnnouncements = ({ token, trainingCourseId }) => async dispatch => {

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    params: {
      trainingCourseId
    }

  };

  try {
    const res = await API.get(`/api/v1/trainingCourse/getAnnouncements`, config);
    dispatch({
      type: TRAINING_COURSE_ANNOUNCEMENTS,
      payload: res.data.data
    });

    return { status: "success" };
  } catch (error) {
    console.log(error);

    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        title: `Could not get files`,
        message:
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : ` Something went wrong try after some time`,
        severity: "error"
      }
    });
    return {
      status: "fail",
      message: error?.response?.data?.message
        ? error?.response?.data?.message
        : "something went wrong"
    };
  }
};


//send textComment
export const addTextComment = ({ token, trainingCourseId, comment }) => async dispatch => {

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    }

  };
  const body = JSON.stringify({ trainingCourseId, comment });

  try {
    const res = await API.post(`/api/v1/trainingCourse/addTrainingCourseComment`, body, config);

    dispatch({
      type: ADD_COURSE_COMMENT,
      payload: res.data.data
    });


    dispatch({
      type: SHOW_MESSAGE,
      title: `Add Favorite`,
      payload: {
        title: `success`,
        message: res.data.message,
        severity: "success",
      },
    });
    return { status: 'success' }

  } catch (error) {
    console.log(error);
    dispatch({
      type: SHOW_MESSAGE,
      title: `Add Favorite`,
      payload: {
        title: `Could not get course`,
        message:
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : ` Something went wrong try after some time`,
        severity: "error"
      }
    });
    return { status: 'fail' }

  }
};


//add file in section
export const addSectionFile = ({
  fileName,
  file,
  size,
  type,
  sectionId,
  token,
  courseId
}) => async dispatch => {

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    params: {
      name: fileName,
      file,
      size,
      type,
      sectionId
    }

  };

  let formData = new FormData();
  formData.append("file", file);
  const body = formData

  try {
    const res = await API.post(`/api/v1/trainingCourse/uploadSupportedFile`, body, config);
    const contentResp = await API.get(
      "/api/v1/trainingCourse/getCourseContent",
      {
        ...config,
        params: {
          trainingCourseId: courseId
        }
      }
    );
    dispatch({
      type: SET_TRAINING_COURSE_SECTIONS,
      payload: { sections: contentResp.data.data }
    });
    dispatch({
      type: SHOW_MESSAGE,
      title: `Add file`,
      payload: {
        title: `success`,
        message: res.data.message,
        severity: "success",
      },
    });
    return res.data.data


  } catch (error) {
    console.log(error);
    dispatch({
      type: SHOW_MESSAGE,
      title: `Add file`,
      payload: {
        title: `Could not get course`,
        message:
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : ` Something went wrong try after some time`,
        severity: "error"
      }
    });
    return { status: 'fail' }

  }
};


//delete section file assignment file
export const deleteSectionOrAssignmentFile = ({
  fileId,
  type,
  token,
  courseId
}) => async dispatch => {

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    params: {
      fileId,
      type,
    }

  };

  try {
    const res = await API.delete(`/api/v1/trainingCourse/deleteFile`, config);
   
    dispatch({
      type: SHOW_MESSAGE,
      title: `Add file`,
      payload: {
        title: `success`,
        message: res.data.message,
        severity: "success",
      },
    });
    return { id: fileId }

  } catch (error) {
    console.log(error);
    dispatch({
      type: SHOW_MESSAGE,
      title: `Add file`,
      payload: {
        title: `Could not get course`,
        message:
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : ` Something went wrong try after some time`,
        severity: "error"
      }
    });
    return { status: 'fail' }

  }
};

//add assignment files in section
export const addAssignmentFile = ({
  name,
  file,
  size,
  type,
  sectionId,
  date,
  time,
  timeZone,
  token,
  courseId,
  scheduleType
}) => async dispatch => {

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    params: {
      name,
      file,
      size,
      type,
      date,
      time,
      sectionId,
      timeZone,
      scheduleType
    }
  };

  let formData = new FormData();
  formData.append("file", file);
  const body = formData

  try {
    const res = await API.post(`/api/v1/trainingCourse/uploadSectionAssignmentFile`, body, config);
    const contentResp = await API.get(
      "/api/v1/trainingCourse/getCourseContent",
      {
        ...config,
        params: {
          trainingCourseId: courseId
        }
      }
    );
    dispatch({
      type: SET_TRAINING_COURSE_SECTIONS,
      payload: { sections: contentResp.data.data }
    });

    dispatch({
      type: SHOW_MESSAGE,
      title: `Add assignment file`,
      payload: {
        title: `success`,
        message: res.data.message,
        severity: "success",
      },
    });
    return res.data.data

  } catch (error) {
    console.log(error);
    dispatch({
      type: SHOW_MESSAGE,
      title: `Add assignment file`,
      payload: {
        title: `Could not get course`,
        message:
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : ` Something went wrong try after some time`,
        severity: "error"
      }
    });
    return { status: 'fail' }

  }
};

//update assignment files in section
export const updateAssignment = ({
  name,
  date,
  time,
  timeZone,
  token,
  scheduleType,
  sectionAssignmentId,
  courseId
}) => async dispatch => {

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    params: {
      name,
      date,
      time,
      timeZone,
      token,
      scheduleType,
      sectionAssignmentId
    }
  };

  const body = JSON.stringify({})

  try {
    const res = await API.patch(`/api/v1/trainingCourse/updateAssignment`, body, config);
    const contentResp = await API.get(
      "/api/v1/trainingCourse/getCourseContent",
      {
        ...config,
        params: {
          trainingCourseId: courseId
        }
      }
    );
    dispatch({
      type: SET_TRAINING_COURSE_SECTIONS,
      payload: { sections: contentResp.data.data }
    });

    dispatch({
      type: SHOW_MESSAGE,
      title: `Add assignment file`,
      payload: {
        title: `success`,
        message: res.data.message,
        severity: "success",
      },
    });
    return res.data.data

  } catch (error) {
    console.log(error);
    dispatch({
      type: SHOW_MESSAGE,
      title: `Add assignment file`,
      payload: {
        title: `Could not get course`,
        message:
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : ` Something went wrong try after some time`,
        severity: "error"
      }
    });
    return { status: 'fail' }

  }
};


// get training comments
export const getTrainingCourseComments = ({ token, trainingCourseId }) => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    params: {
      trainingCourseId,
      sortOrder: 'DESC'
    }
  };

  try {
    const res = await API.get(`/api/v1/trainingCourse/getTrainingCourseComments`, config);
    dispatch({
      type: TRAINING_COURSE_COMMENTS,
      payload: res.data.data
    });

    return res.data.data;
  } catch (error) {
    console.log(error);

    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        title: `Could not get course`,
        message:
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : ` Something went wrong try after some time`,
        severity: "error"
      }
    });
    return {
      status: "fail",
      message: error?.response?.data?.message
        ? error?.response?.data?.message
        : "something went wrong"
    };
  }
};


// add or update course plan

export const addUpdatePlan = ({
  plan,
  price,
  courseData,
  token
}) => async dispatch => {
  // set header

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    }
  };
  // set body

  const body = JSON.stringify({
    courseId: courseData.id,
    planId: plan.id,
    coursePrice: price
  });
  try {
    const res = await API.put(
      `/api/v1/trainingCourse/addOrUpdateCoursePlan`,
      body,
      config
    );

    dispatch({
      type: ADD_UPDATE_RETREAT_PLAN,
      payload: res.data.data
    });
    dispatch({
      type: SHOW_MESSAGE,
      title: `Add file`,
      payload: {
        title: `success`,
        message: res.data.message,
        severity: "success",
      },
    });

    return res.data.data
  } catch (error) {

    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        title: `Could not update plan`,
        message:
          error.response &&
            error.response.data &&
            error.response.data.errors &&
            error.response.data.errors.length
            ? error.response.data.errors[0]
            : error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : `Something went wrong while updating plan`,
        severity: "error"
      }
    });
    return { status: 'fail' }
  }
};


// make Announcement

export const makeAnnouncement = ({
  trainingCourseId,
  title,
  description,
  type,
  token
}) => async dispatch => {
  // set header
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    }
  };
  // set body
  const body = JSON.stringify({
    trainingCourseId,
    title,
    description,
    type
  });
  try {
    const res = await API.post(
      `/api/v1/trainingCourse/addAnnouncement`,
      body,
      config
    );

   
    dispatch({
      type: SHOW_MESSAGE,
      title: `Announcement`,
      payload: {
        title: `success`,
        message: res.data.message,
        severity: "success",
      },
    });

    return res.data.data
  } catch (error) {

    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        title: `Announcement`,
        message:
          error.response &&
            error.response.data &&
            error.response.data.errors &&
            error.response.data.errors.length
            ? error.response.data.errors[0]
            : error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : `Something went wrong while updating plan`,
        severity: "error"
      }
    });
    return { status: 'fail' }
  }
};


export const courseActiveInActive = ({
  token,
  courseId,
  status
}) => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    }
  };
  const body = JSON.stringify({ courseId, status });
  try {
    const res = await API.patch(`/api/v1/trainingCourse/changeStatus`, body, config);

    dispatch({
      type: ACTIVE_TRAINING_COURSE,
      payload: 'Active'
    });
    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        title: `success`,
        message: res.data.message,
        severity: "success"
      }
    });
    return {
      status: true
    }
  } catch (error) {
    console.log(error);
    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        title: `Failed`,
        message:
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : `change status failed`,
        severity: "error"
      }
    });
    return {
      status: false
    }
  }
};


//get all training courses
export const getEnrolledRetreats = ({ token, userId, retreatType }) => async dispatch => {

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    params: {
      userId: userId || undefined,
      retreatType
    }

  };

  try {
    const res = await API.get(`/api/v1/trainingCourse/getUserEnrolledCourses`, config);

    if (retreatType === 'retreat') {
      dispatch({
        type: GET_ENROLLED_RETREATS,
        payload: res.data.data
      });
    } else if (retreatType === 'teacherTraining') {
      dispatch({
        type: GET_ENROLLED_TEACHER_TRAININGS,
        payload: res.data.data
      });
    }

    return { status: "success" };
  } catch (error) {
    console.log(error);

    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        title: `Could not get course`,
        message:
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : ` Something went wrong try after some time`,
        severity: "error"
      }
    });
    return {
      status: "fail",
      message: error?.response?.data?.message
        ? error?.response?.data?.message
        : "something went wrong"
    };
  }
};


//get assignment files
export const getAssignmentsFiles = ({ token, sectionId }) => async dispatch => {

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    params: {
      sectionId
    }

  };

  try {
    const res = await API.get(`/api/v1/trainingCourse/getSectionAssignmentFiles`, config);
    dispatch({
      type: GET_SECTION_ASSIGNMENTS,
      payload: res.data.data
    });
    return { status: "success" };

  } catch (error) {
    console.log(error);

    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        title: `Could not get assignments`,
        message:
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : ` Something went wrong try after some time`,
        severity: "error"
      }
    });
    return {
      status: "fail",
      message: error?.response?.data?.message
        ? error?.response?.data?.message
        : "something went wrong"
    };
  }
};

//get assignment files
export const getRetreatAssignmentsFiles = ({ token, trainingCourseId }) => async dispatch => {

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    params: {
      trainingCourseId
    }

  };

  try {
    const res = await API.get(`/api/v1/trainingCourse/getTrainingCourseAssignmentFiles`, config);
    dispatch({
      type: GET_RETREAT_ASSIGNMENTS_FILES,
      payload: res.data.data
    });
    return { status: "success" };

  } catch (error) {
    console.log(error);

    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        title: `Could not get assignments`,
        message:
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : ` Something went wrong try after some time`,
        severity: "error"
      }
    });
    return {
      status: "fail",
      message: error?.response?.data?.message
        ? error?.response?.data?.message
        : "something went wrong"
    };
  }
};


//submit assignment
export const submitAssignmentFile = ({
  name,
  file,
  size,
  type,
  sectionId,
  token,
  sectionAssignmentId
}) => async dispatch => {

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    params: {
      name,
      size,
      type,
      sectionAssignmentId
    }

  };

  let formData = new FormData();
  formData.append("file", file);
  const body = formData

  try {
    const res = await API.post(`/api/v1/trainingCourse/uploadStudentSubmittedAssignmentFile`, body, config);
    await dispatch(getAssignmentsFiles({ token, sectionId }))
    dispatch({
      type: SHOW_MESSAGE,
      title: `Add file`,
      payload: {
        title: `success`,
        message: res.data.message,
        severity: "success",
      },
    });
    return res.data.data


  } catch (error) {
    console.log(error);
    dispatch({
      type: SHOW_MESSAGE,
      title: `Add file`,
      payload: {
        title: `Could not get course`,
        message:
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : ` Something went wrong try after some time`,
        severity: "error"
      }
    });
    return { status: 'fail' }

  }
};


//get student submitted assignment
export const getStudentSubmittedSectionAssignmentsFiles = ({
  studentId,
  sectionId,
  token
}) => async dispatch => {

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    params: {
      studentId,
      sectionId
    }

  };

  try {
    const res = await API.get(`/api/v1/trainingCourse/getStudentSubmittedFiles`, config);
    dispatch({
      type: STUDENT_SUBMITTED_SECTION_ASSIGNMENTS_FILES,
      payload: res.data.data
    });
    return res.data.data
  } catch (error) {
    console.log(error);
   
    return { status: 'fail' }

  }
};

//approve or disapprove assignment file
export const approveDisApproveAssignment = ({ token, assignmentId, studentSubmittedAssignmentId, status }) => async dispatch => {

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },

  };
  const body = JSON.stringify({ studentSubmittedAssignmentId, status });
  try {
    const res = await API.patch(`/api/v1/trainingCourse/changeStudentSubmittedAssignmentStatus`, body, config);
    dispatch({
      type: STUDENT_ASSIGNMENT_APPROVE_DISAPPROVE,
      payload: { assignmentId, status }
    });

    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        title: `success`,
        message: res.data.message,
        severity: "success",
      },
    });
    return res?.data?.data
  } catch (error) {

    console.log(error);
    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        title: `Could not get course`,
        message:
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : ` Something went wrong try after some time`,
        severity: "error"
      }
    });
    return 'fail'

  }
};


//INVITE USERS
export const invitePeopleForRetreat = ({ data, token }) => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    }
  };
  const body = JSON.stringify({ ...data });
  try {
    const res = await API.post(`/api/v1/trainingCourse/inviteUsers`, body, config);
    dispatch(getRetreatInvitedUsers({ token, trainingCourseId: data?.courseId }))
    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        title: `Invite Sent`,
        message:
          res && res.data && res.data.message
            ? res.data.message
            : `Invite send successfully`,
        severity: "success"
      }
    });
    return { status: 'success' }
  } catch (error) {
    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        title: `Could not sent invite`,
        message:
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : `Something went wrong`,
        severity: "error"
      }
    });
    return { status: 'fail' }
  }
};


//join free retreat

export const joinFreeRetreat = ({ trainingCourseId, token }) => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    }
  };
  const body = JSON.stringify({ trainingCourseId });
  try {
    let res = await API.post(
      `/api/v1/trainingCourse/joinFreeTrainingCourse`,
      body,
      config
    );
    dispatch(getTrainingCourseContent({ token, trainingCourseId }));
    dispatch(getTrainingCourseParticipants({ token, courseId: trainingCourseId }));

    dispatch(
      getTrainingCourseDetailById({
        courseId: trainingCourseId,
        token
      })
    );
    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        title: `join Retreat`,
        message:
          res && res.data && res.data.message
            ? res.data.message
            : `join Retreat successfully`,
        severity: "success"
      }
    });
    return 'success'
  } catch (error) {
    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        title: `join Retreat`,
        message:
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : `something went wrong try after some time `,
        severity: "error"
      }
    });
  }
};

//join under subscription retreat

export const joinUnderSubscriptionRetreat = ({ trainingCourseId, token }) => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    }
  };
  const body = JSON.stringify({ trainingCourseId });
  try {
    let res = await API.post(
      `/api/v1/trainingCourse/joinTrainingCourseUnderSubscription`,
      body,
      config
    );
    dispatch(getTrainingCourseContent({ token, trainingCourseId }));
    dispatch(getTrainingCourseParticipants({ token, courseId: trainingCourseId }));

    dispatch(
      getTrainingCourseDetailById({
        courseId: trainingCourseId,
        token
      })
    );
    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        title: `join Retreat`,
        message:
          res && res.data && res.data.message
            ? res.data.message
            : `join Retreat successfully`,
        severity: "success"
      }
    });
    return 'success'
  } catch (error) {
    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        title: `join Retreat`,
        message:
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : `something went wrong try after some time `,
        severity: "error"
      }
    });
  }
};



//get latest retreats 
export const getNewRetreats = ({
  token,
  page,
  limit,
  categoryId,
  retreatType
}) => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    params: {
      page: page || undefined,
      limit: limit || undefined,
      categoryId: categoryId || '',
      sortOrder: "DESC",
      sortBy: "createdAt",
      retreatType
    }
  };

  try {
    const res = await API.get(`/api/v1/trainingCourse/getNewCourses`, config);
    return res?.data?.data
  } catch (error) {
    console.log(error);

  }

};


//get retreat invited users
export const getRetreatInvitedUsers = ({
  token,
  trainingCourseId
}) => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    params: {
      trainingCourseId
    }
  };

  try {
    const res = await API.get(`/api/v1/trainingCourse/getInvitedUserIds`, config);
    dispatch({
      type: GET_RETREAT_INVITED_USERS,
      payload: res?.data?.data
    });
    return res?.data?.data
  } catch (error) {
    console.log(error);

  }

};


//get live chat messages

export const getChatMessages = ({ token, roomId, type }) => async dispatch => {

  const config = {
    headers: {
      "Content-Type": "application/json",
      'Authorization': `Bearer ${token}`
    },
    params: {
      roomId,
      groupType: type
    }
  };

  try {
    const res = await API.get(`/api/v1/chat/getGroupMessages`, config);
    return res?.data?.data
  } catch (error) {
    console.log(error);

  }
}

// get retreat stats detail

export const getRetreatStats = ({ courseId, token }) => async dispatch => {

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    params: {
      courseId
    }
  };

  try {
    const res = await API.get(`/api/v1/trainingCourse/getCourseStats`, config);
    dispatch({
      type: RETREAT_STATS_DETAIL,
      payload: res.data.data
    });
    return res.data.data
  } catch (error) {
    console.log(error);
    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        status: `Fail`,
        message:
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : ` Something went wrong try after some time`,
        severity: "error"
      }
    });

  }
};


// get retreat mentors
export const getRetreatMentors = ({ token, courseId }) => async dispatch => {

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    params: {
      courseId
    }

  };

  try {
    const res = await API.get(`/api/v1/trainingCourse/getCourseMentors`, config);

    return res?.data?.data

  } catch (error) {
    console.log(error);


  }
};