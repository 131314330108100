import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { Grid, CircularProgress, useMediaQuery } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import FiberManualRecordIcon from "../../../assets/Icons/DotFilled.svg";
import PropTypes from "prop-types";
import Switch from "@material-ui/core/Switch";
import { withStyles } from "@material-ui/core/styles";

const CustomSwitch = withStyles({
  switchBase: {
    color: "white",
    "&$checked": {
      color: "#87AC26",
    },
    "&$checked + $track": {
      backgroundColor: "#87AC26",
    },
  },
  checked: {},
  track: {},
})(Switch);

const useStyles = makeStyles((theme) => ({
  imageContainer: {
    width: "5.229em",
    height: "5.229em",
    borderRadius: "9px",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  icon: {
    textAlign: "left",
    cursor: "pointer",
    marginRight: "20px",
  },
  actionText: {
    color: "#C2C2C2",
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "18px",
    lineHeight: "21px",
    textAlign: "left",
    marginRight: "20px",
  },
  header: {
    fontFamily: theme.fontFamily.Reef,
    fontStyle: "normal",
    textAlign: "left",
    fontWeight: 900,
    fontSize: "12px",
    color: "rgba(0, 0, 0, 0.8)",
  },
  control: {
    padding: theme.spacing(2),
  },
  bodyIcon: {
    fontSize: "10px",
    marginTop: "10px",
  },
  marginLeft: {
    paddingLeft: "7px",
  },
  fullHeight: {
    height: "100%",
  },
  root: {
    width: "100%",
    height: "6rem",
    borderRadius: "11px",
    transform: "rotate(360deg)",
    boxShadow: "0px 0px 0px rgba(123, 0, 0, 0.05)",
  },
  bodyStyle: {
    fontSize: "10px",
    color: "#2A4A67",
  },
  menuIconStyle: {
    borderRadius: "11px",
    height: "66px",
    width: "66px",
    justifyContent: "center",
    lineHeight: "78px",
    alignItems: "center",
    display: "flex",
  },
  rootPadding: {
    paddingTop: "5px",
    paddingBottom: "5px",
  },
}));

CardWithThumbNail.propTypes = {
  // thumbnail url
  imageUrl: PropTypes.string,
  // icon component
  icon: PropTypes.string,
  // style object for icon
  iconStyle: PropTypes.object,
  //Selected Icon if change icon on select
  selectedIcon: PropTypes.string,
  //style object for selected icon
  selectedIconStyle: PropTypes.object,
  // shows current selected icon . true for icon and false for selectedIcon
  currentIcon: PropTypes.bool,
  // function to handle icon click .input =currentSelectedIcon :true/false
  handleChange: PropTypes.func,
  // title text to display in card body
  title: PropTypes.string,
  // title text style object
  titleStyle: PropTypes.object,
  // text to diplay under title in card body
  body: PropTypes.string,
  // style object for text displayed under title in card body
  bodyStyle: PropTypes.object,
  //text to display over title in card body
  header: PropTypes.string,
  // true for show icon
  showIconButton: PropTypes.bool,
  // true for show thumbnail
  showImage: PropTypes.bool,
  // style object for card
  rootCard: PropTypes.object,
};
CardWithThumbNail.defaultProps = {
  currentIcon: true,
  showIconButton: false,
  showImage: false,
};
function CardWithThumbNail(props) {
  const classes = useStyles();
  const theme = useTheme();
  let isMobile = useMediaQuery(theme.breakpoints.up("sm")) ? false : true;

  const {
    currentIcon,
    title,
    titleStyle,
    body,
    bodyStyle,
    header,
    showIconButton,
    showImage,
    rootCard,
    id,
    currentAction,
    showLoader,
  } = props;

  return (
    <div>
      <Card className={[classes.root, rootCard && rootCard]}>
        <CardContent
          classes={{
            root: classes.rootPadding,
          }}
          style={{ paddingLeft: isMobile ? '' : '2rem' }}
        >
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <Grid>
              <Grid
                container
                justify="flex-start"
                style={{ cursor: "pointer" }}
                spacing={0}
                onClick={() => {
                  props.handleClick((id && id) || title);
                }}
              >
                {showImage ? (
                  <Grid item>
                    <div
                      className={classes.menuIconStyle}
                      style={{
                        alignItems: "baseline",
                        background:
                          props?.selected === "true"
                            ? "#3abac9"
                            : props?.IconContainerBackground,
                      }}
                    >
                      {props?.Icon}
                    </div>
                  </Grid>
                ) : (
                  ""
                )}

                <Grid item className={classes.marginLeft}>
                  <Grid
                    container
                    direction="row"
                    className={classes.fullHeight}
                    justify="center"
                    alignItems="center"
                    alignContent="center"
                  >
                    <Grid container>
                      <Typography className={classes.header}>
                        {header && header}
                      </Typography>
                    </Grid>
                    <Grid container>
                      <Typography className={titleStyle && titleStyle}>
                        {title && title}
                      </Typography>
                    </Grid>

                    {body && (
                      <Grid container>
                        <Grid xs={1}>
                          <img
                            src={FiberManualRecordIcon}
                            style={{ fontSize: "10px", marginTop: "10px" }}
                            alt=""
                          />
                        </Grid>
                        <Grid xs={10}>
                          <Typography
                            className={bodyStyle && bodyStyle}
                            style={{ fontSize: "10px", color: "#2A4A67" }}
                          >
                            {body && body}
                          </Typography>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {showIconButton ? (
              <Grid>
                <Grid
                  container
                  direction="row"
                  justify="center"
                  alignItems="center"
                  className={classes.icon}
                >
                  <Grid item>
                    {currentAction &&
                      currentAction === title &&
                      title &&
                      showLoader && (
                        <CircularProgress size={20} color="primary" />
                      )}
                  </Grid>
                  <Grid item>
                    <CustomSwitch
                      checked={currentIcon}
                      onChange={() => props.handleChange(!currentIcon, title)}
                      name="checkedB"
                    />
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              <Grid>
                <Grid
                  container
                  direction="column"
                  justify="flex-end"
                  alignItems="center"
                >
                  <Typography className={props.actionBtnStyle}>
                    {props.actionBtn}
                  </Typography>
                </Grid>
              </Grid>
            )}
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
}

export default CardWithThumbNail;
