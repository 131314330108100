import { CardMedia, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import DoubleImageIcon from "../../../assets/DoubleImageIcon.svg";
import { useIntl } from "react-intl";
const AddImage = (props) => {
  const { image } = props;
  const classes = useStyles();
  const intl = useIntl();

  return (
    <>
      {!image ? (
        <CardMedia className={classes.courseDescriptionImageCard}>
          <Grid
            container
            direction="column"
            justify="center"
            style={{
              textAlign: "center",
              lineHeight: "5em",
              marginTop: "10%",
              position: "absolute",
              top: 0,
            }}
            
          >
            <Grid xs={12} >
              <img src={DoubleImageIcon} alt="" />
            </Grid>
            <Grid xs={12} >
              <Typography className={classes.cardText}>
                {intl.formatMessage({ id: "itsEmptyHere" })}
              </Typography>
            </Grid>
          
          </Grid>
        </CardMedia>
      ) : (
        <CardMedia
          className={classes.courseDescriptionImageCard}
          style={{ background: `url(${image})`, backgroundRepeat: `no-repeat` }}
        >
          <Grid
            container
            direction="column"
            justify="center"
            style={{
              textAlign: "center",
              lineHeight: "5em",
              marginTop: "10%",
              opacity: "1",
              position: "absolute",
                top: 0,
                left: 0,
                bottom: 100,
                right: 0
            }}
          >
           
          </Grid>
        </CardMedia>
      )}
    </>
  );
};

export default AddImage;

const useStyles = makeStyles((theme) => ({
  courseDescriptionImageCard: {
    height: "15.438em",
    backgroundColor: "#FEEFCC",
    width:'100%',
    borderRadius: "20px",
    backgroundSize: "cover !important",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "top center",
    position: "relative",
    height: 0,
    paddingTop: `56.25%`
  },
  cardButton: {
    width: "18em",
    height: "4em",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "20px",
    backgroundColor: "#FBAE02",
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: 300,
    fontSize: "10px",
    lineHeight: "10px",
    color: "#FFFFFF",
    marginTop: "20px",
  },
  cardText: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "16px",
    color: "#363636",
  },
  profile_image: {
    padding: "10px",
    cursor: "pointer",
  },
}));
