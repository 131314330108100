import React, { useState, useEffect, useCallback, useRef } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Avatar,
  Dialog,
  DialogActions,
  Button,
  DialogContent,
  Modal,
  Typography,
} from "@material-ui/core";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import editProfileMobileBackground from "../../../../assets/img/editProfileMobileBackground.svg";
import { ArrowBack } from "@material-ui/icons";
import EditProfileInfoForm from "../../../../components/Profile/EditProfileForm";
import { uploadPic } from "../../../../actions/user";
import { Grid } from "@material-ui/core";
import { Edit } from "@material-ui/icons";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { useIntl } from "react-intl";

import "./editProfileInfo.scss";

const pixelRatio = 4;

function getResizedCanvas(canvas, newWidth, newHeight) {
  const tmpCanvas = document.createElement("canvas");
  tmpCanvas.width = newWidth;
  tmpCanvas.height = newHeight;

  const ctx = tmpCanvas.getContext("2d");
  ctx.drawImage(
    canvas,
    0,
    0,
    canvas.width,
    canvas.height,
    0,
    0,
    newWidth,
    newHeight
  );

  return tmpCanvas;
}
function EditProfileInfo(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const intl = useIntl();
  const theme = useTheme();
  const history = useHistory();

  const authReducerState = useSelector((state) => state.auth);
  const user = useSelector((state) => state?.user?.user);
  const [profileImage, setProfileImage] = useState("");
  const [coverImage, setCoverImage] = useState("");
  const [openDialog, setOpenDialog] = React.useState(false);
  const [open, setOpen] = useState(false);

  const [upImg, setUpImg] = useState();
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const [crop, setCrop] = useState({ unit: "%", width: 50, aspect: 16 / 9 });
  const [cropProfilePic, setCropProfilePic] = useState({
    unit: "%",
    width: 30,
    aspect: 1,
  });
  const [completedCrop, setCompletedCrop] = useState(null);
  const [imageType, setImageType] = useState("");
  const [modalStyle] = useState(getModalStyle);

  function getModalStyle() {
    const top = 50;
    const left = 50;
    return {
      borderColor: theme.colors.white,
      borderRadius: "4px",
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top - 4}%, -${left}%)`,
    };
  }

  function Upload(previewCanvas, crop) {
    if (!crop || !previewCanvas) {
      return;
    }

    const canvas = getResizedCanvas(previewCanvas, crop.width, crop.height);
    canvas.toBlob(
      (blob) => {
        const previewUrl = window.URL.createObjectURL(blob);

        if (imageType === "cover") {
          setCoverImage(previewUrl);
          const formData = new FormData();
          formData.append("cover", blob);
          dispatch(uploadPic(formData, "uploadCover", authReducerState?.token));
          setOpenDialog(false);
        } else if (imageType === "profile") {
          setProfileImage(previewUrl);
          const formData = new FormData();
          formData.append("picture", blob);
          dispatch(
            uploadPic(formData, "uploadPicture", authReducerState?.token)
          );
          setOpenDialog(false);
        }
      },
      "image/png",
      1
    );
  }

  const onSelectFile = (e, type) => {
    if (e.target.files && e.target.files.length > 0) {
      setImageType(type);
      setOpenDialog(true);
      const reader = new FileReader();
      reader.addEventListener("load", () => setUpImg(reader.result));
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);

  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }

    const image = imgRef.current;
    const canvas = previewCanvasRef.current;
    const crop = completedCrop;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext("2d");

    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingEnabled = false;

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );
  }, [completedCrop]);

  const handleClose = () => {
    setOpenDialog(false);
  };

  useEffect(() => {
    setProfileImage(user?.pictureUrl);
    setCoverImage(user?.coverUrl);
    if (user && user.id && (!user?.pictureUrl || !user?.coverUrl)) {
      setOpen(true);
    }
  }, [user?.pictureUrl, user?.coverUrl]);

  return (
    <Grid container justify="center">
      <Grid item xs={12}>
        <Modal
          disableEnforceFocus={true}
          open={open}
          onClose={() => setOpen(false)}
        >
          <Grid
            container
            direction="row"
            spacing={3}
            style={modalStyle}
            className={classes.paper}
          >
            <Grid item className={classes.msgBody}>
              <Typography className={classes.modelBodyText}>
                {intl.formatMessage({ id: "completeProfileMessage" })}
              </Typography>
            </Grid>
            <Grid item className={classes.modalBtnGrid}>
              <Button
                onClick={() => setOpen(false)}
                className={classes.modelBtn}
              >
                {intl.formatMessage({ id: "okay" })}
              </Button>
            </Grid>
          </Grid>
        </Modal>
      </Grid>
      <Grid item sm={12}>
        <Dialog
          open={openDialog}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <div className="App">
              <ReactCrop
                src={upImg}
                onImageLoaded={onLoad}
                crop={imageType === "cover" ? crop : cropProfilePic}
                onChange={(c) =>
                  imageType === "cover" ? setCrop(c) : setCropProfilePic(c)
                }
                onComplete={(c) => setCompletedCrop(c)}
                circularCrop={
                  imageType === "cover"
                    ? false
                    : imageType === "profile"
                    ? true
                    : ""
                }
              />
              <div>
                <canvas
                  ref={previewCanvasRef}
                  style={{
                    width: completedCrop?.width ?? 0,
                    height: completedCrop?.height ?? 0,
                    borderRadius:
                      imageType === "cover"
                        ? ""
                        : imageType === "profile"
                        ? "50%"
                        : "",
                  }}
                />
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button
              type="button"
              disabled={!completedCrop?.width || !completedCrop?.height}
              onClick={() => Upload(previewCanvasRef.current, completedCrop)}
              color="primary"
              autoFocus
            >
              Done
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>

      <div className={classes.root}>
        <div
          style={{
            background: `linear-gradient(180deg, rgba(21, 57, 89, 0.8) 0%, rgba(123, 146, 166, 0.8) 100%)`,
            paddingBottom: "90px",
          }}
        >
          <Grid item className="backArrowContainer">
            <ArrowBack
              className={classes.backArrow}
              onClick={() => history.goBack()}
            />
          </Grid>

          <div style={{ height: "200px", width: "100%" }}>
            <Grid container direction="column" justify="center">
              <Grid item container xs={12} direction="row">
                <div
                  className={classes.profilePic}
                  style={{
                    backgroundImage: `url(${coverImage}), linear-gradient(180deg, rgba(255, 255, 255, 0.23) 0%, rgba(0, 0, 0, 0.23) 39.58%, #000000 100%)`,
                  }}
                >
                  <label className="profile_image">
                    <div className="edit">
                      <Edit style={{ color: "#FFF", marginTop: "7px" }} />
                    </div>
                    <input
                      className="profile_image_input"
                      className={classes.profilePic}
                      type="file"
                      accept="image/*"
                      onChange={(event) => onSelectFile(event, "cover")}
                      onClick={(e) => {
                        e.target.value = "";
                      }}
                    />
                  </label>
                </div>
                <Grid item container xs={12} justify="center">
                  <div className={classes.userImage}>
                    <label className="profile_image">
                      <Edit
                        style={{
                          color: "#FFF",
                          zIndex: 100,
                          marginTop: "45px",
                          position: "absolute",
                          left: "48%",
                        }}
                      />
                      <input
                        className="profile_image_input"
                        type="file"
                        accept="image/*"
                        onChange={(event) => onSelectFile(event, "profile")}
                        onClick={(e) => {
                          e.target.value = "";
                        }}
                      />
                    </label>
                    <Avatar
                      src={profileImage}
                      className={classes.analystImage}
                      style={{ zIndex: 50 }}
                    />
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </div>
          <div>
            <Grid container direction="column" justify="center">
              <Grid item xs={12}>
                <EditProfileInfoForm />
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </Grid>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    background:
      "linear-gradient(180deg, rgba(255, 255, 255, 0.23) 0%, rgba(0, 0, 0, 0.23) 39.58%, #000000 100%)",

    backgroundImage: `url(${editProfileMobileBackground})`,
    height: "100%",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "top center",
  },
  profilePic: {
    marginTop: "30px",
    margin: "auto",
    height: "150px",
    width: "90%",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    borderRadius: "10px",
    zIndex: 1,
  },
  userImage: {
    zIndex: 3,
    marginTop: "-50px",
    textAlign: "center",
  },
  analystImage: {
    width: "75px",
    height: "75px",
    boxShadow: "0 2px 6px 0",
  },
  paper: {
    position: "absolute",
    height: 330,
    maxWidth: 315,
    minWidth: 300,
    justifyContent: "center",
    backgroundColor: theme.palette.background.paper,
    outline: "none",
  },
  msgBody: {
    minHeight: "240px",
    alignItems: "center",
    display: "flex",
  },
  modelBodyText: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "17px",
    lineHeight: "27px",
    textAlign: "center",
    letterSpacing: "0.02em",
    color: "#192247",
  },
  modelBtn: {
    height: "73px",
    width: "100%",
    fontFamily: theme.fontFamily.Roboto,
    cursor: "pointer",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "21px",
    color: theme.colors.white,
  },

  modalBtnGrid: {
    padding: "0px",
    alignSelf: "flex-end",
    color: "#fff",
    cursor: "pointer",
    background: `linear-gradient(#09A9BB 1%,#09A9BB 100%)`,
    height: "73px",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
  },
  backArrow: {
    cursor: "pointer",
    color: "white",
  },
}));

export default EditProfileInfo;
