import React, { useEffect, useState } from 'react'
import {
    Grid,
    Typography,
    CircularProgress,
    useMediaQuery
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import TopBar from "../../../components/AppBar";
import ChallengeCard from "../../../components/Common/CourseCard";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { useIntl } from "react-intl";
import { makeStyles } from "@material-ui/styles";
import API from "../../../utils/API";
import InfiniteScroll from "react-infinite-scroll-component";
import ViewAllSearchComponent from '../../../components/Common/ViewAllSearchComponent'
import moment from "moment";
import Header from '../../../components/Common/MobileHeader/HeaderWithMoreSearchIcon';
import SubscribeNowBtn from '../../../components/Common/SubscribeNowBtn';

const ViewAllChallenges = () => {
    const classes = useStyles();
    const theme = useTheme();
    const intl = useIntl();
    const dispatch = useDispatch();
    const [data, setData] = useState([])
    const [searching, setSearching] = useState(false)
    const [localLoader, setLocalLoader] = useState(false)
    const [totalCount, setTotalCount] = useState(0)
    const [page, setPage] = useState(1)
    const [hasMore, setHasMore] = useState(true)
    const [displayMobileSearchBar, setDisplayMobileSearchBar] = useState(false)
    const [selectedCategory, setSelectedCategory] = useState('')

    let auth = useSelector(state => state.auth);
    const searchData = useSelector(state => state?.courses?.searchData);
    const loader = useSelector(state => state?.loader?.loader);
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const limit = 12

    useEffect(() => {
        if (data.length === 0 && !searching) {
            getCounts();
        }

        return () => {
            dispatch({
                type: 'SEARCH',
                payload: []
            });
        }
    }, [searching])

    useEffect(() => {
        if (searching) {
            setPage(1)
            setLocalLoader(loader)
        }
    }, [loader])

    useEffect(() => {
        if (searching) {
            setData(searchData)
        }
    }, [searchData]);

    useEffect(() => {
        !searching && getCounts();
    }, [selectedCategory]);

    const getData = async (count) => {
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${auth?.token}`
            },
            params: {
                page,
                limit,
                sortOrder: "DESC",
                sortBy: "createdAt",
                categoryId: selectedCategory

            }
        };
        try {
            const res = await API.get(`/api/v1/challenge/get-recent-challenges`, config);
            const allData = await [...data, ...res.data.data]
            setData(allData);
            setPage(page + 1)
            allData.length >= (count || totalCount) && setHasMore(false)
            setLocalLoader(false)
        } catch (error) {
            console.log(error);
            setLocalLoader(false)
            dispatch({
                type: 'SHOW_MESSAGE',
                payload: {
                    title: `Failed`,
                    message:
                        error.response &&
                            error.response &&
                            error.response.data &&
                            error.response.data.message
                            ? error.response.data.message
                            : `Something went wrong while fetching data`,
                    severity: "error"
                }
            });


        }
    };


    const getCounts = async () => {

        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${auth?.token}`
            },
            params: {
                type: 'Challenge'
            }
        };
        try {
            setLocalLoader(true)
            const res = await API.get(`/api/v1/user/getCount`, config);
            setTotalCount(res?.data?.data * 1)
            getData(res?.data?.data * 1)

        } catch (error) {
            console.log(error);

        }
    };


    const searchState = (isSearched) => {
        setSearching(isSearched);
        if (!isSearched && searching) {
            setData([])
            setPage(1)
            setHasMore(true)
        }
    }

    const openMobileSearchBar = () => {
        setDisplayMobileSearchBar(!displayMobileSearchBar);
    }

    const closeMobileSearchBar = () => {
        setSearching(false)
        setPage(1)
        setDisplayMobileSearchBar(!displayMobileSearchBar);
        getData();
    }

    const handleSelectedCategory = (categoryId) => {
        setPage(1)
        setSelectedCategory(categoryId);
    }

    return <Grid container id='InfiniteScrollParent' className={classes.scroll} >

        {isMobile && <Grid item xs={12} style={{ height: '66px' }} >
            {!displayMobileSearchBar ? <Header text='Challenges' openMobileSearchBar={openMobileSearchBar} /> :
                <ViewAllSearchComponent searchState={searchState} searchType={'Challenge'} mobileSearch={true} closeMobileSearchBar={closeMobileSearchBar} categoryId={selectedCategory} />}
        </Grid>}

        {!isMobile && <Grid item xs={12} container style={{ minHeight: '90px', position: 'fixed', backgroundColor: "#fff", zIndex: 5 }} >
            <Grid item xs={12} sm={12} md={2} lg={2} xl={1}  >
                <TopBar
                    appBarConfig={{ show: true, text: 'Challenges' }}
                    style={{ color: "#068594", backgroundColor: "#FCFCFC", float: 'left', }}
                />
            </Grid>
            <Grid item xs={12} sm={10} md={9} lg={9} xl={9} style={{ minHeight: '90px', alignItems: 'center', display: 'flex', padding: isMobile && '0px 15px 0px 15px', marginLeft: !isMobile && '25px', }} >
                <ViewAllSearchComponent searchState={searchState} searchType={'Challenge'} />
            </Grid>
        </Grid>}
        {isMobile && <Grid item xs={11} sm={3} md={3} style={{marginTop: '10px', marginRight: '50px' }} className={classes.subscribeBtnContainer} >
                    <SubscribeNowBtn/>
                </Grid>}
        <Grid item container xs={12} style={{ padding: isMobile ? '0px 0px 80px 15px' : '0px 10px 30px 25px', marginTop: isMobile ? '20px' : '100px' }} >
            {localLoader ? (<Grid item xs={12} className={classes.center} style={{ minHeight: '65vh' }}>
                <CircularProgress color="primary" style={{ color: '#09A9BB' }} size={30} />
            </Grid>) :
                data?.length === 0 && !localLoader ? (
                    <Grid item xs={12} className={classes.center} style={{ minHeight: '65vh' }}>
                        <Typography className={classes.noContent}>
                            No Record found
                        </Typography>
                    </Grid>) :
                    <Grid item xs={12}>
                        <InfiniteScroll
                            dataLength={data.length}
                            next={getData}
                            hasMore={hasMore}
                            loader={!searching && hasMore && <h4>Loading...</h4>}
                            style={{ overflow: 'hidden' }}

                            scrollableTarget='InfiniteScrollParent'
                        >

                            <Grid item xs={12} container style={{ minHeight: '65vh' }} spacing={2}>
                                {data?.map((item, ind) => {
                                    return (
                                        <Grid
                                            item
                                            xs={12}
                                            md={4}
                                            lg={3}
                                            key={ind}
                                            style={{ paddingBottom: "25px" }}
                                        >
                                            <Link
                                                to={`/challenge/details/${item?.id}`}
                                                style={{ textDecoration: "none" }}
                                            >
                                                <ChallengeCard
                                                    image={item?.imageUrl}
                                                    category={
                                                        item?.course?.category?.name
                                                    }
                                                    friends={
                                                        item?.participantsCount > 1
                                                            ? `${item?.participantsCount - 1} friends`
                                                            : item?.participantsCount === 1 ? `${item?.participantsCount - 1} friend` : '0 friend'
                                                    }
                                                    name={item?.title}
                                                    totalDuration={Math.round(
                                                        item?.duration / 60
                                                    )}
                                                    time={moment(
                                                        item?.exerciseTime,
                                                        "LTS"
                                                    ).format("LT")}
                                                    width={'100%'}
                                                    price={item?.price}
                                                    plan={item?.plan}
                                                />
                                            </Link>
                                        </Grid>)
                                })}
                            </Grid>
                        </InfiniteScroll>
                    </Grid>}
        </Grid>
    </Grid>
}
export default ViewAllChallenges


const useStyles = makeStyles((theme) => ({
    center: {
        display: 'flex',
        justifyContent: "center",
        alignItems: "center",

    },
    scroll: {
        height: '100vh',
        overflowY: 'scroll',
        '&::-webkit-scrollbar': {
            width: '0px',
            background: 'transparent', /* make scrollbar transparent */
        }
    },
    infinScroll: {
        overflowY: 'scroll',
        '&::-webkit-scrollbar': {
            width: '0px',
            background: 'transparent', /* make scrollbar transparent */
        }
    },

    noContent: {
        color: "#7d7b79",
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "16px",
        lineHeight: "19px",

        display: 'flex',
        justifyContent: "center",
        alignItems: "center",
    },
}))