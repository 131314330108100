import React, { useContext, useEffect, useState } from "react";
import { IntlProvider } from "react-intl";
import { Switch, useLocation } from "react-router-dom";
import intl from "intl";
import { useDispatch } from "react-redux";
import "@formatjs/intl-relativetimeformat/polyfill";
import areIntlLocalesSupported from "intl-locales-supported";
import AlertMessage from "../../components/Alert";
import getDefaultRoutes from "../Routing/DefaultRoutes";
import LocaleContext from "../../providers/Locale/Context";
import ConfigContext from "../../providers/Config/Context";
import LocaleProvider from "../../providers/Locale/Provider";
import { getLocaleMessages } from "../../utils/locale";
import { getCurrentLoggedInUser } from "../../actions/user";
import { getSiteConfigs } from "../../actions/siteSetting";

const loadLocalePolyfill = locale => {

  let localesMyAppSupports = [locale];

  if (global.Intl) {
    if (!areIntlLocalesSupported(localesMyAppSupports)) {

      let IntlPolyfill = intl;
      Intl.NumberFormat = IntlPolyfill.NumberFormat;
      Intl.DateTimeFormat = IntlPolyfill.DateTimeFormat;
    }
  } else {
    global.Intl = intl;
  }
};

export const LayoutContent = () => {
  const [messages, setMessages] = useState([]);
  const { appConfig } = useContext(ConfigContext);
  const { routes = [], containers, locale: confLocale } = appConfig || {};
  const { locales, onError } = confLocale || {};
  const { LayoutContainer = React.Fragment } = containers || {};
  const defaultRoutes = getDefaultRoutes(appConfig);
  const location = useLocation();
  const { locale } = useContext(LocaleContext);


  useEffect(() => {
    const loadPolyfills = async () => {
      loadLocalePolyfill(locale);

      for (let i = 0; i < locales.length; i++) {
        const l = locales[i];
        if (l.locale === locale) {
          if (l.loadData) {
            await l.loadData;
          }
        }
      }
    };
  }, [locale, locales]);

  useEffect(() => {
    const loadMessages = async () => {
      const messages = await getLocaleMessages(locale, locales);
      setMessages(messages);
    };
    loadMessages();
  }, [locale, locales]);

  return (
    <IntlProvider
      locale={locale}
      key={locale}
      messages={messages}
      onError={onError}
    >
      <LayoutContainer>
        <AlertMessage />
        <Switch>
          {routes.map((Route, i) => {
            return React.cloneElement(Route, { key: `@customRoutes/${i}` });
          })}
          {defaultRoutes.map((Route, i) => {
            return React.cloneElement(Route, { key: `@defaultRoutes/${i}` });
          })}
        </Switch>
      </LayoutContainer>
    </IntlProvider>
  );
};

export const Layout = () => {
  const { appConfig } = useContext(ConfigContext);
  const { locale } = appConfig || {};
  const { defaultLocale, persistKey } = locale || {};
  const dispatch = useDispatch();
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("userDataLocal"));
    const getUserData = async () => {
      if (userData?.token) {

        dispatch(getCurrentLoggedInUser({ token: userData.token }));
        dispatch(getSiteConfigs({ token: userData.token }));
      }
    };
    getUserData();
  }, []);
  return (
    <LocaleProvider defaultLocale={defaultLocale} persistKey={persistKey}>
      <LayoutContent appConfig={appConfig} />
    </LocaleProvider>
  );
};

export default Layout;
