import React, { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from 'react-router-dom'
import { TextField, Grid, Button, Typography, CircularProgress } from "@material-ui/core"
import { updateUserProfile } from "../../../actions/user";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { convertToRaw, EditorState, convertFromHTML, ContentState } from 'draft-js';
import Editor from '../../../components/Editor'
import draftToHtml from 'draftjs-to-html';


function EditProfileForm(props) {
    const dispatch = useDispatch()
    const history = useHistory();
    const authReducerState = useSelector(state => state.auth);
    const user = useSelector(state => state?.user?.user);
    const { register, errors, handleSubmit, setValue } = useForm();
    const [localLoader, setLocalLoader] = useState(false);
    const [editorState, setEditorState] = React.useState(EditorState.createEmpty());
    const [error, setError] = useState(false)
    const classes = useStyles();


    useEffect(() => {
        setValue("firstName", user?.firstName)
        setValue("lastName", user?.lastName)
        setValue("email", user?.email)
        setValue("title", user?.title)
        setValue("city", user?.city)
        setValue("description", user?.description)
        setValue("websiteUrl", user?.websiteUrl)
        setValue("facebookUrl", user?.facebookUrl)
        setValue("instagramUrl", user?.instagramUrl)
        setValue("youtubeChannelUrl", user?.youtubeChannelUrl)
        setValue("description", user?.description)
        if (user?.id && authReducerState?.user?.role !== 'Regular') {
            const blocksFromHtml = convertFromHTML(unescape(user?.description));
            const { contentBlocks, entityMap } = blocksFromHtml;
            const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
            const editorState = EditorState.createWithContent(contentState);
            setEditorState(editorState);
        }
    }, [user])

    const onSubmit = async (data) => {
        const role = authReducerState?.user?.role
        const htmlContent = role !== 'Regular' && await escape(draftToHtml(convertToRaw(editorState.getCurrentContent())))
        if (!(editorState.getCurrentContent().hasText()) && (role !== 'Regular')) {
            return setError(true)
        }
        const data1 = role !== 'Regular' ? { ...data, description: htmlContent } : data

        setLocalLoader(true)
        const res = await dispatch(updateUserProfile(data1, authReducerState?.token));
        if (res && res === 'success') {
            setLocalLoader(false)
            history.push('/profile')

        } else {
            setLocalLoader(false)
            return
        }
    };




    return (
        <Grid container item xs={12} direction='column' justify='center' style={{ margin: 'auto', padding: '0px 12px 0px 10px' }}>

            <form onSubmit={handleSubmit(onSubmit)}>

                <div className={classes.root}>

                    <TextField
                        className={classes.inputField}
                        inputRef={register({
                            required: "first name is required!"
                        })}
                        label="First Name"
                        name="firstName"
                        helperText={errors.firstName && errors.firstName.message}
                        error={!!errors.firstName}
                        type="text"
                        InputLabelProps={{
                            shrink: true,
                        }}

                    />

                    <TextField
                        className={classes.inputField}
                        inputRef={register({
                            required: "last name is required!"
                        })}
                        label="Last Name"
                        name="lastName"
                        helperText={errors.lastName && errors.lastName.message}
                        error={!!errors.lastName}
                        type="text"

                        InputLabelProps={{
                            shrink: true,
                        }}
                    />


                    <TextField
                        className={classes.inputField}

                        inputRef={register({
                            required: user?.role !== 'Regular' ? "title is required!" : false,
                        })}
                        label="Title"
                        name="title"
                        helperText={errors.title && errors.title.message}
                        error={!!errors.title}
                        type="text"

                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <TextField
                        className={classes.inputField}

                        inputRef={register({
                            required: user?.role !== 'Regular' ? "city is required!" : false,
                        })}
                        label="City"
                        name="city"
                        helperText={errors.city && errors.city.message}
                        error={!!errors.city}
                        type="text"

                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <TextField
                        className={classes.inputField}

                        inputRef={register({
                            required: "email is required!",
                            pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                message: "Invalid email address!"
                            }
                        })}
                        disabled={true}
                        label="Email"
                        name="email"
                        helperText={errors.email && errors.email.message}
                        error={!!errors.email}
                        type="text"

                        InputLabelProps={{
                            shrink: true,
                        }}

                    />

                    {user?.role === 'Instructor' &&
                        <>
                            <TextField
                                className={classes.inputField}
                                inputRef={register({
                                    pattern: {
                                        value: /(([\w]+:)?\/\/)?(([\d\w]|%[a-fA-f\d]{2,2})+(:([\d\w]|%[a-fA-f\d]{2,2})+)?@)?([\d\w][-\d\w]{0,253}[\d\w]\.)+[\w]{2,63}(:[\d]+)?(\/([-+_~.\d\w]|%[a-fA-f\d]{2,2})*)*(\?(&?([-+_~.\d\w]|%[a-fA-f\d]{2,2})=?)*)?(#([-+_~.\d\w]|%[a-fA-f\d]{2,2})*)?/,
                                        message: "Invalid url address!"
                                    }

                                })}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                label="Website URL"
                                name="websiteUrl"
                                helperText={errors.websiteUrl && errors.websiteUrl.message}
                                error={!!errors.websiteUrl}
                                type="text"
                            />
                            <TextField
                                className={classes.inputField}
                                inputRef={register({
                                    pattern: {
                                        value: /^(https?:\/\/){0,1}(www\.){0,1}facebook\.com/,
                                        message: "Invalid facebook url!"
                                    }
                                })}

                                InputLabelProps={{
                                    shrink: true,
                                }}
                                label="Facebook URL"
                                name="facebookUrl"
                                helperText={errors.facebookUrl && errors.facebookUrl.message}
                                error={!!errors.facebookUrl}
                                type="text"
                            />
                            <TextField
                                className={classes.inputField}
                                inputRef={register({
                                    pattern: {
                                        value: /(?:(?:http|https):\/\/)?(?:www\.)?(?:instagram\.com|instagr\.am)\/([A-Za-z0-9-_\.]+)/im,
                                        message: "Invalid instagram url!"
                                    }

                                })}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                label="Instagram URL"
                                name="instagramUrl"
                                helperText={errors.instagramUrl && errors.instagramUrl.message}
                                error={!!errors.instagramUrl}
                                type="text"
                            />
                            <TextField
                                className={classes.inputField}
                                inputRef={register({
                                    pattern: {
                                        value: /(?:(?:http|https):\/\/)?((www\.)?youtube\.com|youtu\.?be)\/.+$/,
                                        message: "Invalid youtube url!"
                                    }
                                })}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                label="Youtube Channel URL"
                                name="youtubeChannelUrl"
                                helperText={errors.youtubeChannelUrl && errors.youtubeChannelUrl.message}
                                error={!!errors.youtubeChannelUrl}
                                type="text"
                            />
                        </>
                    }

                    {authReducerState?.user?.role !== 'Regular' ?
                        <>
                            <Editor editorState={editorState} placeholder={'Describe yourself...'} changeDescription={(des) => {
                                setError(false)
                                setEditorState(des)
                            }} />
                            {error && <Typography className={classes.textEditorError}>Description is required</Typography>}

                        </> :
                        <TextField
                            className={[classes.inputField, classes.textArea]}
                            id="standard-multiline-flexible"
                            name='description'
                            multiline

                            label="Description"
                            inputRef={register({
                                required: user?.role !== 'Regular' ? "description is required!" : false,
                            })}
                            helperText={errors.description && errors.description.message}
                            error={!!errors.description}

                            InputLabelProps={{
                                shrink: true,
                            }}
                            type='textArea'

                        />}
                    <Button
                        type="submit"
                        variant="contained"
                        className={classes.updateBtn}
                        disabled={localLoader}
                        onClick={() => handleSubmit()}
                    >
                        {localLoader ? <CircularProgress size={20} style={{ color: '#FFF' }} /> : 'Update'}
                    </Button>
                    <Link to='/profile' style={{ textDecoration: 'none' }}> <Typography className={classes.cancelBtn}>Cancel</Typography></Link>
                </div>
            </form>
        </Grid>
    );
}


const useStyles = makeStyles(theme => ({
    root: {
        width: "100%",
        "& > *": {
            width: "100%",
            margin: "20px 0px",
        }
    },
    description: {
        opacity: '0.3',
        border: '2px solid #FFFFFF',
        boxSizing: 'border-box',
        borderRadius: '10px',
    },
    updateBtn: {
        background: 'linear-gradient(180deg, #A6D6FF 0%, #15AEBF 100%)',
        borderRadius: '10px',
        fontFamily: theme.fontFamily.Reef,
        fontStyle: 'normal',
        fontWeight: '900',
        fontSize: '24px',
        lineHeight: '33px',
        height: '65px',
        color: '#FFFFFF',
    },
    inputField: {
        width: '100%',
        letterSpacing: 0.6,
        marginBottom: '25px',
        '& .MuiInputLabel-root': {
            fontFamily: 'Roboto',
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: '20px',
            lineHeight: '21px',
            letterSpacing: 0.6,
            color: '#FFF',
        },
        '& .MuiInputLabel-root::after': {
            fontFamily: 'Roboto',
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: '20px',
            lineHeight: '21px',
            letterSpacing: 0.6,
            color: '#FFF',
        },
        '& .MuiInputBase-input': {
            fontFamily: 'Roboto',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '18px',
            lineHeight: '18px',
            color: '#FFF',
            opacity: 0.78,
            paddingTop: '15px',
            letterSpacing: 0.6,

        },
        '& .MuiInput-underline::before': {
            borderBottom: '1px solid #FFF',
        },
        '& .MuiInput-underline::after': {
            borderBottom: '1px solid #FFF',
        },
        '& .MuiInput-underline:hover:not(.Mui-disabled)::before': {
            borderBottom: '1px solid #FFF',
        },
    },
    textArea: {
        '& .MuiInput-underline': {
            border: '2px solid #FFF',
            borderRadius: '10px',
            padding: '0px 5px 15px 5px',
            letterSpacing: 0.6,
        }
    },
    cancelBtn: {
        fontFamily: 'Reef',
        fontStyle: 'normal',
        fontWeight: 900,
        fontSize: '20px',
        lineHeight: '27px',
        textAlign: 'center',
        textDecoration: 'none',
        color: '#E7584D',
        marginBottom: '20px',
    },
    textEditorError: {
        fontSize: '0.75rem',
        marginTop: '3px',
        textAlign: 'left',
        fontFamily: 'Reef,Roboto',
        fontWeight: '400',
        lineHeight: 1.66,
        color: '#f44336'
    }
}))
export default EditProfileForm;