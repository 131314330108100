import React, { useState, useEffect } from 'react';
import { Grid, Typography, Button, CircularProgress, useMediaQuery, useTheme } from '@material-ui/core'
import { makeStyles } from "@material-ui/core/styles";
import CheckCircle from '@material-ui/icons/CheckCircle';
import AssignmentFile from './assignmentCard'
import clsx from 'clsx'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { submitAssignmentFile } from '../../../../../actions/retreatCourse'

function AssignmentSubmissionCard(props) {
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();
    const [file, setFile] = useState('')
    const [loader, setLoader] = useState(false)
    const { assignment, sectionId } = props
    const user = useSelector(state => state?.auth);
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));


    const handleSelectFile = (event) => {
        setFile(event.target.files[0]);

    }

    const handleSubmission = async () => {
        setLoader(true);
        let fileType = /^.+\.([^.]+)$/.exec(file?.name)[1] || ''
        const filename = file.name.split('.')[0]
        const data = { sectionAssignmentId: assignment?.id, sectionId, token: user?.token, file, size: file?.size, name: filename, type: fileType }
        const res = await dispatch(submitAssignmentFile({ ...data }));
        if (res) {
            setLoader(false);
        }
    }

    useEffect(() => {
        assignment?.submitStatus !== 'NotSubmitted' && setFile(assignment?.submittedAssignment)
    }, [])

    return (
        <Grid container>
            <Grid item xs={12} container style={{ marginBottom: '15px', borderBottom: isMobile && '2px solid #E2E2E2', paddingBottom: isMobile && "25px" }}>
                <Grid item xs={12} sm={12} md={3} lg={3} xl={3} container className={!isMobile && clsx(classes.assignmentTitleContainer, classes.verticalCenter)}>
                    <Grid item xs={6} sm={6} md={12}>
                        <Typography className={!isMobile ? classes.assignmentTitle : classes.assignmentTitleMobile}>Assignment # {props?.ind + 1}</Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={12}>
                        <Typography className={!isMobile ? classes.assignmentTime : classes.assignmentTimeMobile}>{moment.utc(assignment?.dateTimeUtc).local().format("MMM DD HH:MM")}</Typography>
                    </Grid>
                </Grid>

                <Grid item container xs={8} sm={8} md={6} lg={6} xl={6}>
                    <Grid item xs={12} sm={12} md={8} lg={8} xl={8} className={!isMobile && clsx(classes.verticalCenter, classes.horizontalCenter)} style={{ paddingLeft: !isMobile && '10px' }}>
                        {((assignment?.submitStatus === 'NotSubmitted') && (!file)) ?
                            <div className={classes.browsButtonContainer}> <Button className={classes.browsButton}
                                variant="contained"
                                component="label" >
                                Upload File
                                <input
                                    type="file"
                                    accept=".docx, .doc"
                                    hidden
                                    disabled={moment(moment.utc(assignment?.dateTimeUtc).local().format("YYYY-MM-DD HH:MM")).isBefore(
                                        moment().format("YYYY-MM-DD HH:MM"))}
                                    onChange={handleSelectFile}
                                />
                            </Button></div> : <AssignmentFile file={(assignment?.submitStatus === 'NotSubmitted') ? file : assignment?.submittedAssignment} style={{ background: '#EEEEEE', width: "90%", borderRadius: '11px' }} />}
                    </Grid>

                    {!isMobile && <Grid item xs={12} sm={12} md={4} lg={4} xl={4} className={classes.verticalCenter} container>
                        <Grid item xs={12}>
                            <Typography className={classes.supportedFileTitle}>Supported Files</Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <Typography className={classes.supportedFileFormate}>DOCX, DOC</Typography>
                        </Grid>
                    </Grid>}

                </Grid>

                <Grid item xs={4} sm={4} md={3} lg={3} xl={3} className={clsx(classes.verticalCenter, classes.horizontalCenter)}>
                    {
                        ((moment(moment.utc(assignment?.dateTimeUtc).local().format("YYYY-MM-DD HH:MM")).isBefore(
                            moment().format("YYYY-MM-DD HH:MM"))) && (assignment?.submitStatus === 'NotSubmitted')) ? <Typography className={classes.supportedFileTitle} style={{ color: '#E54D40' }}>Due date passed</Typography>
                            : ((assignment?.submitStatus === 'NotSubmitted') && (file)) ? <Button className={classes.browsButton}
                                disabled={loader}
                                onClick={handleSubmission}
                                variant="contained"
                                component="label" >
                                {loader ? <CircularProgress size={15} style={{ color: '#fff' }} /> : 'Submit'}
                            </Button> : (assignment?.submitStatus === 'Submitted') ? < CheckCircle style={{ color: '#1DC242' }} /> : (assignment?.submitStatus === 'Approved') ? <Typography className={classes.supportedFileTitle} style={{ color: '#87AC26' }}> Approved</Typography> : (assignment?.submitStatus === 'NotApproved') ? <><Typography className={classes.supportedFileTitle} style={{ color: '#E54D40' }}>Not Approved</Typography>
                                {/* <Typography className={classes.nextEpNA} >Next episode unavailable</Typography> */}
                            </> : ''

                    }
                </Grid>


            </Grid>


        </Grid>
    );
}

export default AssignmentSubmissionCard;

const useStyles = makeStyles(theme => ({
    cardContainer: {
        minHeight: '60px',
        width: '100%',
        boxShadow: 'none',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'

    },
    assignmentTitle: {
        fontFamily: theme.fontFamily.Roboto,
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "16px",
        color: "#B6B6B6",
        paddingBottom: '5px',
    },
    assignmentTime: {
        fontFamily: theme.fontFamily.Roboto,
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "14px",
        color: "#B6B6B6",

    },
    assignmentTitleMobile: {
        fontFamily: theme.fontFamily.Roboto,
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "16px",
        textAlign: 'left',
        color: "#B6B6B6",
        paddingBottom: '5px',
        marginBottom: '15px'

    },
    assignmentTimeMobile: {
        fontFamily: theme.fontFamily.Roboto,
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "14px",
        textAlign: 'right',
        color: "#B6B6B6",
        marginBottom: '15px'

    },
    announcementTxtContainer: {
        paddingLeft: '15px'
    },
    supportedFileTitle: {
        fontFamily: theme.fontFamily.Roboto,
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "16px",
        lineHeight: "25px",
        color: "#666666",
    },
    supportedFileFormate: {
        fontFamily: theme.fontFamily.Roboto,
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "12px",
        lineHeight: "19px",
        color: "#A7A7A7",
    },

    announcementHeading: {
        fontFamily: theme.fontFamily.Roboto,
        fontStyle: "bold",
        fontWeight: 'normal',
        fontSize: "18px",
        lineHeight: "100%",
        color: "#353B35",
        textAlign: 'center',

    },
    assignmentTitleContainer: {
        borderRight: '1px solid #3D3D3D',

    },
    verticalCenter: {
        display: 'flex',
        alignItems: 'center'

    },
    horizontalCenter: {
        display: 'flex',
        justifyContent: 'center'

    },
    browsButtonContainer: {
        border: '2px dashed #000000',
        borderRadius: '11px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '60px',
        width: '90%'
    },
    browsButton: {
        color: '#fff',
        background: '#FBAE02',
        borderRadius: '20px',
        height: '30px',
        padding: '0px 40px',
        minWidth: '30px',
        '&:hover': {
            color: '#fff',
            background: '#FBAE02',
        }
    },
    supportedFileTitle: {
        fontFamily: theme.fontFamily.Roboto,
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "14px",
        lineHeight: "25px",
        color: "#666666",
        textAlign: 'center'
    },

}));