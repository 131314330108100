import React, { useState, useRef, useEffect } from 'react';
import { Grid, TextField, CircularProgress, Typography } from '@material-ui/core'
import { makeStyles } from "@material-ui/core/styles";
import { Send, CameraAlt } from "@material-ui/icons";
import LiveChatCard from './liveChatCard'
import MentorChatCard from './MentorChatCard'
import SocketIOClient from "socket.io-client";
import { useSelector, useDispatch } from 'react-redux'
import { getChatMessages } from '../../../../actions/retreatCourse'
import { sendChatMedia } from '../../../../actions/chat';
import clsx from 'clsx'

function LiveChat(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const scrollAble = useRef(null);
    const [message, setMessage] = useState('');
    const [messages, setMessages] = useState([]);
    const [socketIo, setSocketIo] = useState();
    const [loader, setLoader] = useState(false);
    const authReducer = useSelector(state => state.auth);
    const lesson = useSelector(state => state?.retreatCourse?.toBePlayedLesson);

    const getMessages = async ({ loading }) => {
        loading && setLoader(true)
        let res = await dispatch(
            getChatMessages({
                token: authReducer?.token,
                roomId: `lesson-${lesson?.id}`,
                type: 'Lesson'
            })
        );
        if (res) {
            setMessages(res)
            setLoader(false)
        } else {
            setLoader(false)
        }
    }

    useEffect(() => {
        if (lesson) {
            getMessages({ loading: true })
            let socket;
            socket = SocketIOClient(process.env.REACT_APP_SOCKET_URL, {
                reconnection: true,
                reconnectionDelay: 5000,
                reconnectionDelayMax: 5000,
                reconnectionAttempts: 2,
                rememberUpgrade: true,
                transports: ["polling", "websocket"]
            });
            socket.emit("connection", () => {
                console.log("socket connected");
            });

            setSocketIo(socket);
            socket && socket.emit("subscribe", `lesson-${lesson?.id}`)
            socket && socket.on("message", async message => {
                if (message?.senderId !== authReducer?.user?.id) {
                    getMessages({ loading: false })
                }
            });

            return () => {
                socket && socket.emit("unsubscribe", `lesson-${lesson?.id}`);
                socket.emit("disconnect", async function () {
                    console.log("socket disconnected...");
                });
            };
        }
    }, [lesson]);

    useEffect(() => {
        scrollAble && scrollAble.current && scrollAble.current.scrollIntoView();
    });


    const sendMessage = () => {

        if (message.trim().length === 0) {
            setMessage('')
        } else {
            const data = {
                roomId: `lesson-${lesson?.id}`,
                message: {
                    message,
                    messageType: "Text",
                    read: false,
                    senderId: authReducer?.user?.id,
                    groupType: "Lesson"
                }
            };
            socketIo && socketIo.emit("message", data);
            getMessages({ loading: false })
            setMessage("");

        }
    };


    const sendMultimediaMessage = async (e) => {
        const formData = new FormData();
        formData.append("chatMedia", e.target.files[0]);
        const resUrl = await dispatch(
            sendChatMedia({
                token: authReducer?.token,
                chatMedia: formData,
                groupType: 'Lesson',
                roomId: `lesson-${lesson?.id}`,

            })
        );
        if (resUrl) {
            const data = {
                roomId: `lesson-${lesson?.id}`,
                message: {
                    message: resUrl?.chatMediaUrl,
                    messageType: "Image",
                    read: false,
                    groupType: 'Lesson',
                    senderId: authReducer?.user?.id,

                }
            };
            socketIo && socketIo.emit("message", data);
            getMessages({ loading: false })
            setMessage("");
        }
    };

    return (
        <Grid container>
            {loader ? <Grid item xs={12} className={classes.noContent}>
                <CircularProgress size={20} style={{ color: '#09a9bb' }} />
            </Grid> : messages?.length > 0 ?
                <Grid item xs={12} className={classes.container}>
                    {messages.map(message => message?.sender?.isMentor ? <MentorChatCard message={message} /> : <LiveChatCard message={message} />)}
                    <div ref={scrollAble} style={{ height: '10px' }}></div>
                </Grid> : <Grid item xs={12} className={classes.noContent}>
                    <Typography className={classes.noContentText}>No message found</Typography>
                </Grid>}

            <Grid item xs={12}>
                <TextField
                    id="outlined-textarea"
                    placeholder='Type message...'
                    multiline
                    variant="outlined"
                    classes={{ root: classes.messageInputRoot, focused: classes.focused }}
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    rowsMax={4}
                    onKeyPress={e => {
                        (e.charCode === 13) && message && sendMessage();
                    }}
                    InputProps={{
                        startAdornment: (
                            <label className={clsx(classes.inputFile, classes.iconStyle)}>
                                < CameraAlt className={classes.iconStyle} style={{ marginRight: '15px', paddingTop: '5px' }} />
                                <input
                                    type="file"
                                    accept="image/*"
                                    onChange={sendMultimediaMessage}
                                />
                            </label>

                        ),
                        endAdornment: (
                            < Send className={classes.iconStyle} style={{ marginLeft: '15px' }} onClick={() => sendMessage()} />
                        )
                    }}
                />
            </Grid>
        </Grid>
    );
}

export default LiveChat;

const useStyles = makeStyles(theme => ({
    container: {
        height: '400px',
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
            width: '0px',
            background: 'transparent'
        }
    },

    card: {
        width: '100%',
        background: '#FFFFFF',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.05)',
        borderRadius: '10px',
    },

    messageInputRoot: {
        marginTop: "15px",
        color: '#78849E',
        width: '100%',
        background: '#EBEBEB',
        '& focused': {
            border: '1px solid #E3E3E3 !important',
        }

    },
    focused: {
        border: '1px solid #E3E3E3 !important',
    },
    iconStyle: {
        color: '#3D3D3D',
        cursor: 'pointer'
    },
    noContent: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: "100px"
    },
    noContentText: {
        fontFamily: theme.fontFamily.Roboto,
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "14px",
        color: "#666666",

    },
    inputFile: {
        cursor: 'pointer',
        '& input[type="file"]': {
            display: 'none'
        }
    },

}));