import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { StarRateOutlined } from "@material-ui/icons";

import {
  Card,
  Typography,
  Avatar,
  CardHeader,
  CardContent,
  Chip,
  Grid
} from "@material-ui/core";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux"

const hexToRGB = (hex, alpha) => {
  var r = parseInt(hex && hex.slice(1, 3), 16),
    g = parseInt(hex && hex.slice(3, 5), 16),
    b = parseInt(hex && hex.slice(5, 7), 16);

  if (alpha) {
    return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
  } else {
    return "rgb(" + r + ", " + g + ", " + b + ")";
  }
};



function SessionInstructorIntro(props) {
  const classes = useStyles();
  const { instructor, tags, remarks, status } = props;
  const [expand, setExpand] = useState(false);
  const [expandIntro, setExpandIntro] = useState(false);
  const intl = useIntl();
  const authReducerState = useSelector(state => state.auth);
  let selectedSession = useSelector((state) => state.session.selectedSession);


  const expandDescription = () => {
    setExpand(!expand);
  };

  const handleRating = rating => {
    let starsRating = [];
    for (let i = 1; i < 6; i++) {
      if (rating >= i) {
        starsRating.push(
          <StarRateOutlined
            style={{ width: "20px", height: "20px", color: "#FFC700" }}
          />
        );
      } else {
        starsRating.push(
          <StarRateOutlined
            style={{ width: "20px", height: "20px", color: "rgb(223, 229, 234)" }}
          />
        );
      }
    }
    return starsRating;
  };

  return (
    <Card className={classes.root}>
      <CardHeader
        avatar={
          <Avatar aria-label="user's pic" src={selectedSession?.user?.pictureUrl}>
            {selectedSession?.user?.name.charAt(0).toUpperCase()}
          </Avatar>
        }
        title={
          <Link
            to={`/view-profile/${selectedSession?.user?.id}`}
            style={{ textDecoration: "none" }}
          >
            <Typography
              className={`${classes.instructorIntroText} ${classes.instructorIntroTextTitle}`}
            >
              {selectedSession?.user?.name}
            </Typography>
          </Link>
        }
        subheader={
          <Typography
            className={classes.instructorIntroText}
            style={{ fontSize: "14px", lineHeight: "19px", color: "#B0B0B0" }}>
            {selectedSession?.user?.title}
          </Typography>
        }
      />


      <CardContent>
        <Typography
          className={`${classes.instructorIntroText} ${classes.instructorIntroTextSubTitle}`}
          style={{ marginTop: "-40px" }}>
          {/* {selectedSession?.user?.description?.length > 100 && (!expandIntro) ? <>{selectedSession?.user?.description.slice(0, 99)}... <span className={classes.readMore} onClick={() => setExpandIntro(true)}>See More</span></> : selectedSession?.user?.description?.length > 100 && (expandIntro) ? <>{selectedSession?.user?.description} <span className={classes.readMore} onClick={() => setExpandIntro(false)}>See Less</span></> : selectedSession?.user?.description} */}
          {selectedSession?.user?.description && <div dangerouslySetInnerHTML={{ __html: unescape(selectedSession?.user?.description) }} />}
        </Typography>
      </CardContent>
    </Card>
  );
}

export default SessionInstructorIntro;

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: '15px',
    width: "100%",
    borderRadius: "10px",
    boxShadow: "0px 16px 20px rgba(123, 0, 0, 0.05)",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat"
  },
  instructorIntroText: {
    fontFamily: theme.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: 900
  },
  instructorIntroTextTitle: {
    fontSize: "20px",
    lineHeight: "27px",
    color: "#1B1B1B"
  },
  instructorIntroTextSubTitle: {
    fontSize: "16px",
    lineHeight: "22px",
    color: "#B0B0B0",
    letterSpacing: "0.015em",
    padding: "10px 0"
  },
  courseTags: {
    background:
      "linear-gradient(180deg, rgba(123, 146, 166, 0.95) 0%, rgba(21, 57, 89, 0.95) 100%)",
    borderRadius: "60px",
    transform: "rotate(3600deg)",
    marginRight: "3px"
  },
  readMore: {
    fontSize: '12px',
    cursor: 'pointer',
    color: '#84CDF0',
    '&:hover': {
      textDecoration: 'underline',
      color: '#84CDF0',
    }
  }
}));
