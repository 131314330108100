import React,{useEffect} from "react";
import { useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import RelateWithFriendMobileContainer from "../../../containers/Mobile/More/RelateWithFriend";
import More from "../../../containers/Web/More";
import { getAllChallenges } from "../../../actions/challenges";
import {useDispatch,useSelector} from 'react-redux'

const RelateWithFriends = () => {
  const theme = useTheme();
  let dispatch = useDispatch();

  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const authReducer = useSelector((state) => state?.auth);

  useEffect(()=>{
    dispatch(getAllChallenges(authReducer?.token));
  },[])
  return (
    <>
      {isDesktop && <More />}
      {isMobile && !isDesktop && <RelateWithFriendMobileContainer />}
    </>
  );
};

export default RelateWithFriends;
