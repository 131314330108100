// types
import {
  LOG_OUT,
  SET_ALL_CARDS,
  SET_STRIPE_LOADER,
  SET_STRIPE_SUCCESS,
  SET_EXTERNAL_ACCOUNT
} from "../actions/types";

// initial state
const initialState = {
  customerCardList: [],
  stripeLoader: false,
  stripeSuccess: false,
  bankAccountInfo:null
};

// handle actions
export default function(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SET_ALL_CARDS:
      return {
        ...state,
        customerCardList: payload
      };
    case SET_EXTERNAL_ACCOUNT:
      return {
        ...state,
        bankAccountInfo: payload
      };
    case SET_STRIPE_LOADER:
      return {
        ...state,
        stripeLoader: payload
      };
    case SET_STRIPE_SUCCESS:
      return {
        ...state,
        stripeSuccess: payload
      };

    case LOG_OUT:
      return {
        customerCardList: [],
        stripeLoader: false,
        stripeSuccess: false
      };

    default:
      return state;
  }
}
