import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  useMediaQuery
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { ArrowBack, Close } from "@material-ui/icons";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import PropTypes from "prop-types";

function ElevationScroll(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined
  });

  return React.cloneElement(children, {
    elevation: 0
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func
};

function AppBarLayout(props) {
  const classes = useStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  let history = useHistory();


  return (
    <>
      {props.appBarConfig && props.appBarConfig.show && (
        <ElevationScroll {...props}>
          <AppBar
            className={!isDesktop ? classes.appBarMobile : classes.appBar}
            style={{ backgroundColor: props.appBarConfig?.backgroundColor, marginLeft:props.appBarConfig?.marginLeft }}
          >
            <IconButton
              onClick={() =>
                props.appBarConfig.backAction
                  ? props.appBarConfig.backAction()
                  : history.goBack()
              }
              disableFocusRipple
              className={classes.backButton}
              style={{ color: props.appBarConfig?.arrowColor }}
            >
              <ArrowBack />
            </IconButton>
            <Toolbar>
              <Typography
                className={
                  !isDesktop ? classes.appBarTextMobile : classes.appBarText
                }
                variant="h6"
                style={{ color: props.appBarConfig?.bodyColor , fontSize : isDesktop? "20px" : "18px" }}
              >
                {props.appBarConfig.text}
              </Typography>
            </Toolbar>
            {props.appBarConfig.close ? (
              <IconButton
                onClick={() => history.push(props.appBarConfig.action)}
                className={classes.backButton}
              >
                <Close />
              </IconButton>
            ) : (
              <div />
            )}
          </AppBar>
        </ElevationScroll>
      )}
    </>
  );
}

export default AppBarLayout;

const useStyles = makeStyles(theme => ({
  appBar: {
    background: "#fff",
    color: "#000",
    flexDirection: "row",
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    right: "unset",
    position: "relative",
    marginLeft: "25px"
  },
  appBarMobile: {
    background: "#FFFFFF",
    color: "#000",
    flexDirection: "row",
    padding: theme.spacing(1.5),
    justifyContent: "space-between",
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    right: "unset",
    position: "sticky"
  },
  appBarText: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: "900",
    fontSize: "20px",
    lineHeight: "23px"
  },
  appBarTextMobile: {
    fontFamily: theme.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: "900",
    fontSize: "26px",
    lineHeight: "35px"
  },
  backButton: {
    background: "transparent",
    color: "#000",
    padding: "0px",
    "&:hover": {
      background: "transparent"
    }
  }
}));
