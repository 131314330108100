import React, { useRef, useEffect, useState } from "react";
import { Grid, Card, Typography, Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useParams, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment";
import EpisodePaidDialog from "../Common/episodePaidDialog";

function ChallengeRepetitionCard(props) {
  const classes = useStyles();
  const params = useParams();
  const history = useHistory();

  const scrollAble = useRef(null);
  const challengeDetail = useSelector(
    (state) => state?.challenges?.challengeDetail
  );
  const [todayChallengeEpisodeDetail, setTodayChallengeEpisodeDetail] =
    useState("");
  const [joinError, setJoinError] = useState(false);

  useEffect(() => {
    if (scrollAble && scrollAble.current) {
      return scrollAble.current.scrollIntoView({ inline: "center" });
    }
  });

  const getColor = (type, date) => {
    if (type === "background") {
      let background =
        date?.status === "previous" && date?.challengeTracks?.length === 0
          ? "#B3B3B3"
          : date?.status === "previous" && date?.challengeTracks?.length > 0
          ? "#008000"
          : date?.status === "next"
          ? "#F9A502"
          : "#FFF";
      return background;
    }
    if (type === "color") {
      let color = date?.status === "upComing" ? "#3C4A6B" : "#FFF";
      return color;
    }
  };
  const episodePaidStatus = async () => {
    if (challengeDetail?.challengeGroup === "step") {
      if (
        challengeDetail &&
        challengeDetail?.challengeEpisodes &&
        challengeDetail?.challengeEpisodes[0]?.episode?.isFree
      ) {
        return history.push({
          pathname: `/start-challenge/${params?.id}`,
          specificDate: props?.date?.dateTime,
        });
      } else if (
        challengeDetail &&
        challengeDetail?.challengeEpisodes &&
        challengeDetail?.challengeEpisodes[0]?.coursePaid
      ) {
        return history.push({
          pathname: `/start-challenge/${params?.id}`,
          specificDate: props?.date?.dateTime,
        });
      } else {
        setTodayChallengeEpisodeDetail(
          challengeDetail &&
            challengeDetail?.challengeEpisodes &&
            challengeDetail?.challengeEpisodes[0]
        );
        return setJoinError(true);
      }
    } else {
      let datesLocal = [...challengeDetail?.challengeDates];
      let dateIndex = challengeDetail?.challengeDates?.findIndex((item) =>
        moment(
          moment.utc(item?.dateTime, "x").local().format("YYYY-MM-DD")
        ).isSame(
          moment(props?.date?.dateTime, "x").local().format("YYYY-MM-DD")
        )
      );
      let episodesIndexes = challengeDetail?.challengeEpisodes?.map(
        (el, index) => index
      );
      let datesIndexes = datesLocal?.fill(episodesIndexes).flat();
      let episodeIndex = datesIndexes[dateIndex];

      const episodeLocal =
        challengeDetail &&
        challengeDetail?.challengeEpisodes &&
        challengeDetail?.challengeEpisodes[episodeIndex];
      if (episodeLocal?.episode?.isFree) {
        return history?.push({
          pathname: `/start-challenge/${params?.id}`,
          specificDate: props?.date?.dateTime,
        });
      } else if (episodeLocal?.coursePaid) {
        return history?.push({
          pathname: `/start-challenge/${params?.id}`,
          specificDate: props?.date?.dateTime,
        });
      } else {
        setTodayChallengeEpisodeDetail(episodeLocal);
        return setJoinError(true);
      }
    }
  };

  return (
    <>
      <EpisodePaidDialog
        joinError={joinError}
        todayChallengeEpisodeDetail={todayChallengeEpisodeDetail}
        joinErrorHandler={() => {
          setJoinError(false);
          setTodayChallengeEpisodeDetail("");
        }}
      />

      <Card
        className={classes.container}
        onClick={() =>
          props?.date?.status === "previous" && episodePaidStatus()
        }
        style={{
          backgroundColor: getColor("background", props?.date),
          color: getColor("color", props?.date),

          cursor: props?.date?.status === "previous" ? "pointer" : "default",
        }}
      >
        <Tooltip
          title={
            props?.date?.status === "previous" ? (
              <span style={{ letterSpacing: ".6px", fontSize: "12px" }}>
                This challenge date has been passed click on this date to play
                this date challenge again
              </span>
            ) : props?.date?.status === "next" ? (
              <span style={{ letterSpacing: ".6px", fontSize: "12px" }}>
                You can play this challenge after start time by clicking on
                Start Now button
              </span>
            ) : (
              <span style={{ letterSpacing: ".6px", fontSize: "12px" }}>
                Challenge can be start on given date and time
              </span>
            )
          }
        >
          <Grid container>
            <Grid item xs={12}>
              <Typography className={classes.day}>
                {moment(props?.date?.dateTime, "x").local().format("dddd")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography className={[classes.day, classes.date]}>
                {moment(props?.date?.dateTime, "x").local().format("DD")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography className={[classes.day, classes.month]}>
                {moment(props?.date?.dateTime, "x").local().format("MMM")}
              </Typography>
            </Grid>
          </Grid>
        </Tooltip>
        {props?.date?.status === "next" && <div ref={scrollAble} />}
      </Card>
    </>
  );
}

export default ChallengeRepetitionCard;

const useStyles = makeStyles((theme) => ({
  container: {
    height: "124px",
    borderRadius: "15px",
    display: "flex",
    textAlign: "center",
    alignItems: "center",
    boxShadow: "0px 6px 15px rgba(0, 0, 0, 0.05)",
    width: "100%",
  },
  day: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "16px",
    letterSpacing: "0.075em",
  },
  date: {
    fontSize: "36px",
    lineHeight: "42px",
    margin: "5px 0px",
  },
  month: {
    fontSize: "12px",
    lineHeight: "14px",
  },
}));
