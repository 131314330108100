import { useState, useEffect, forwardRef } from "react";
import {
  Grid,
  TextField,
  Card,
  Button,
  useMediaQuery,
  CircularProgress,
  Typography,
  Tooltip,
  Hidden,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  CardContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@material-ui/core";
import "./courseContainer.scss";

import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import Truncate from "react-truncate";
import { useTheme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import { ReactSortable, Swap } from "react-sortablejs";
import {
  addEpisode,
  updateEpisodeOrderApi,
  updateEpisode,
  deleteEpisode,
  changeEpisodeStatus,
} from "../../../actions/courses";
import AddImage from "../../../components/AddCourse/AddImage/Index";
import EpisodeEditCard from "../../../components/AddCourse/EpisodeEditCard";
import DropZoneArea from "../../../components/AddCourse/DropZone/DropZoneArea";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import Editor from "../../../components/Editor";
import {
  convertToRaw,
  EditorState,
  ContentState,
  convertFromHTML,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";

function is_url(str) {
  let regexp =
    /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
  if (regexp.test(str)) {
    return true;
  } else {
    return false;
  }
}

const CustomComponent = forwardRef((props, ref) => {
  return (
    <Grid container spacing={2} ref={ref}>
      {props.children}
    </Grid>
  );
});

const EpisodeAddContainer = (props) => {
  const classes = useStyles();
  let dispatch = useDispatch();
  const [image, setImage] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [videoFile, setVideoFile] = useState(null);
  const [episodeUrl, setEpisodeUrl] = useState(null);
  const [episodeName, setEpisodeName] = useState(null);
  const [episodeApproach, setEpisodeApproach] = useState("upload");
  const [selectedEpisodeEdit, setSelectedEpisodeEdit] = useState(null);
  const [selectedEpisode, setSelectedEpisode] = useState(null);
  const [episodeDrag, setEpisodeDrag] = useState(false);
  const [loader, setLoader] = useState(false);
  const [episodeDescription, setEpisodeDescription] = useState(
    EditorState.createEmpty()
  );
  const [showPregressBar, setShowProgressBar] = useState(false);

  const [progressBarValue, setProgressBarValue] = useState(0);

  const [errors, setErrors] = useState({});
  const intl = useIntl();
  const [episodes, setEpisodes] = useState(
    props.courseData &&
      props.courseData.episodes &&
      props.courseData.episodes.length > 0
      ? props.courseData.episodes
      : []
  );
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  let coursesReducerState = useSelector((state) => state.courses);
  let authReducerState = useSelector((state) => state.auth);

  const selectedFile = (file) => {
    if (file) {
      setErrors({ ...errors, image: null });
      setImage(URL.createObjectURL(file));
      setImageFile(file);
    }
  };
  const setSelectedVideoFile = (files) => {
    if (files?.length) {
      setErrors({ ...errors, video: null });
      setVideoFile(files[0]);
    } else {
      setVideoFile(null);
    }
  };

  useEffect(() => {
    if (coursesReducerState?.courseData?.episodes?.length > 0) {
      setEpisodes(coursesReducerState.courseData.episodes);
    } else {
      setEpisodes([]);
    }
  }, [coursesReducerState]);
  useEffect(() => {
    if (
      !coursesReducerState.courseLoader &&
      (selectedEpisode || selectedEpisodeEdit)
    ) {
      setSelectedEpisode(null);
      setSelectedEpisodeEdit(null);
    }
    if (!coursesReducerState.courseLoader) {
      setImage(null);
      setVideoFile("");
      setEpisodeName("");
      setImageFile("");
      setEpisodeUrl("");
      setEpisodeApproach("upload");
      setEpisodeDescription(EditorState.createEmpty());
    }
  }, [coursesReducerState.courseLoader]);

  const handleChangeStatus = (episode) => {
    if (episode.status === "Active") {
      dispatch(
        changeEpisodeStatus({
          episodeId: episode.id,
          status: "InActive",
          token: authReducerState.token,
          showMessage: true,
        })
      );
    } else {
      dispatch(
        changeEpisodeStatus({
          episodeId: episode.id,
          status: "Active",
          token: authReducerState.token,
          showMessage: true,
        })
      );
    }
  };

  const updateEpisodeOrder = async () => {
    setLoader(true);
    const response = await dispatch(
      updateEpisodeOrderApi({
        episodes: episodes,
        token: authReducerState.token,
      })
    );
    if (response.success) {
      props.handleNext();
    }
  };

  const validate = () => {
    let errs = {};
    if (!episodeName) {
      errs.episodeName = "Episode Name is required";
    }
    console.log({ selectedEpisode });
    if (!videoFile && !episodeUrl && selectedEpisode?.ipfsCid) {
      errs.video = "Either episode url or Video file is required";
    }
    if (episodeUrl && !is_url(episodeUrl) && selectedEpisode?.ipfsCid) {
      errs.video = "Episode url is not a valid url";
    }
    if (!imageFile && !image) {
      errs.image = "image is required";
    }
    if (!episodeApproach) {
      errs.episodeApproach = "Episode approach is required";
    }
    if (!episodeDescription.getCurrentContent().hasText()) {
      errs.description = "Episode description is required";
    }

    return errs;
  };
  return (
    <>
      <Grid
        container
        style={{
          paddingLeft: isMobile && !isDesktop ? "0px" : "2rem",
          padding: isMobile && !isDesktop ? "10px 20px" : "0px",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} lg={6}>
            <Card elevation={0}>
              <AddImage
                btnText={intl.formatMessage({ id: "addThumbnail" })}
                image={image}
                aspect={"1 / 1"}
                selectedFile={selectedFile}
              />
            </Card>

            <Grid container>
              <p className={classes.errorMessage}>{errors && errors.image}</p>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <Card elevation={0}>
              <DropZoneArea
                dropzoneText={intl.formatMessage({ id: "drapndrop" })}
                btnText={intl.formatMessage({ id: "browse" })}
                files={videoFile}
                disabled={episodeApproach === "external"}
                setFiles={setSelectedVideoFile}
              />
            </Card>
            <Grid container>
              <p className={classes.errorMessage}>
                {!episodeUrl && errors && errors.video}
              </p>
            </Grid>
          </Grid>
          <Grid
            style={{
              paddingLeft: isMobile && !isDesktop ? "0px" : "2rem",
              padding: isMobile && !isDesktop ? "10px 20px" : "0px",
            }}
            item
            xs={12}
            md={6}
            lg={6}
          >
            <Card elevation={0} />
          </Grid>
        </Grid>
        <Grid
          container
          spacing={2}
          style={{
            marginTop: "2rem",
            marginBottom: "1rem",
            padding: isMobile && !isDesktop ? "10px 20px" : "0px",
          }}
        >
          <Grid item xs={12} md={5} lg={5}>
            <TextField
              value={episodeUrl || ""}
              disabled={episodeApproach === "upload"}
              onChange={(e) => {
                setEpisodeUrl(e.target.value);
                setErrors({ ...errors, video: null });
              }}
              error={errors && errors.video}
              helperText={errors && errors.video}
              label={intl.formatMessage({ id: "episodeUrl" })}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={5} lg={5}>
            <TextField
              value={episodeName || ""}
              onChange={(e) => {
                setEpisodeName(e.target.value);
                setErrors({ ...errors, episodeName: null });
              }}
              error={errors && errors.episodeName}
              helperText={errors && errors.episodeName}
              label={intl.formatMessage({ id: "episodeName" })}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} md={2} lg={2}>
            <FormControl
              className={classes.formControl}
              error={errors && errors.episodeApproach}
            >
              <InputLabel id="demo-simple-select-error-label">
                Episode Approach
              </InputLabel>
              <Select
                labelId="demo-simple-select-error-label"
                id="demo-simple-select-error"
                value={episodeApproach || ""}
                onChange={(e) => {
                  setEpisodeApproach(e.target.value);
                  setErrors({ ...errors, episodeApproach: null });
                  if (e.target.value === "external") {
                    !selectedEpisodeEdit ||
                    selectedEpisodeEdit?.uploadApproach === "upload"
                      ? setEpisodeUrl("")
                      : setEpisodeUrl(selectedEpisodeEdit.multimediaUrl);
                    setVideoFile("");
                  } else {
                    !selectedEpisodeEdit ||
                    selectedEpisodeEdit?.uploadApproach === "external"
                      ? setEpisodeUrl("")
                      : setEpisodeUrl(selectedEpisodeEdit.multimediaUrl);
                  }
                }}
              >
                <MenuItem value={"upload"}>Video File</MenuItem>
                <MenuItem value={"external"}>Video Url</MenuItem>
              </Select>
              <FormHelperText>
                {errors && errors.episodeApproach}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.descriptionTitle}>
              Episode Description
            </Typography>
            <>
              <Editor
                editorState={episodeDescription}
                placeholder={"Episode Description..."}
                changeDescription={(des) => {
                  setErrors({ ...errors, description: null });
                  setEpisodeDescription(des);
                }}
              />
            </>
          </Grid>
          <Grid container>
            <p className={classes.errorMessage}>
              {errors && errors.description}
            </p>
          </Grid>
        </Grid>
        <Grid
          container
          style={{
            marginTop: "2rem",
            marginBottom: "1rem",
            padding: isMobile && !isDesktop ? "10px 20px" : "0px",
          }}
          spacing={1}
        >
          <Grid item xs={12} md={6} lg={6}>
            <Button
              style={{
                background: "transparent",
                border: "3px solid #F28705",
                textAlign: "center",
                padding: "1.5rem",
                fontFamily: theme.fontFamily.Roboto,
                fontStyle: "normal",
                fontWeight: "normal",
                fontSize: "19px",
                lineHeight: "22px",
                color: "#0F0F0F",
                width: "100%",
              }}
              onClick={(e) => {
                const errs = validate();
                setShowProgressBar(true);
                e.preventDefault();
                console.log(errs);
                if (Object.keys(errs).length === 0) {
                  selectedEpisodeEdit?.id
                    ? dispatch(
                        updateEpisode(
                          {
                            episodeData: {
                              ...selectedEpisodeEdit,
                              episodeId: selectedEpisodeEdit.id,
                              name: episodeName,
                              uploadApproach: episodeApproach,
                              multimediaUrl: episodeUrl,
                              multimediaType: "video",
                              duration: selectedEpisodeEdit.duration,
                              courseId: coursesReducerState.courseData.id,
                              description: escape(
                                draftToHtml(
                                  convertToRaw(
                                    episodeDescription.getCurrentContent()
                                  )
                                )
                              ),
                            },
                            episodeImage: imageFile,
                            episodeVideo: videoFile,
                            token: authReducerState.token,
                          },
                          (data) => setProgressBarValue(data)
                        )
                      )
                    : dispatch(
                        addEpisode(
                          {
                            episodeData: {
                              name: episodeName,
                              isFree: true,
                              uploadApproach: episodeApproach,
                              multimediaUrl: episodeUrl,
                              multimediaType: "video",
                              courseId: coursesReducerState.courseData.id,
                              description: escape(
                                draftToHtml(
                                  convertToRaw(
                                    episodeDescription.getCurrentContent()
                                  )
                                )
                              ),
                            },
                            episodeImage: imageFile,
                            episodeVideo: videoFile,
                            token: authReducerState.token,
                          },
                          (data) => setProgressBarValue(data)
                        )
                      );
                } else {
                  setErrors(errs);
                }
              }}
            >
              {coursesReducerState.courseLoader ? (
                <CircularProgress size={20} style={{ color: "#000" }} />
              ) : selectedEpisodeEdit && selectedEpisodeEdit.id ? (
                intl.formatMessage({ id: "updateEpisode" })
              ) : (
                intl.formatMessage({ id: "addNextEpisode" })
              )}
            </Button>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            {showPregressBar &&
              coursesReducerState.courseLoader &&
              episodeApproach === "upload" &&
              progressBarValue > 0 && (
                <div style={{ width: 100, height: 50, marginLeft: "25%" }}>
                  <CircularProgressbar
                    value={progressBarValue ? progressBarValue : 0}
                    text={`${progressBarValue ? progressBarValue : 0}%`}
                  />
                </div>
              )}
          </Grid>
          {selectedEpisodeEdit && (
            <Grid item xs={12} md={4} lg={2}>
              <Button
                disabled={coursesReducerState.courseLoader}
                style={{
                  background: coursesReducerState.courseLoader
                    ? "#C2C2C2"
                    : "#F28705",
                  border: "3px solid #F28705",
                  textAlign: "center",
                  padding: "1.5rem",
                  fontFamily: theme.fontFamily.Roboto,
                  fontStyle: "normal",
                  fontWeight: "normal",
                  fontSize: "19px",
                  lineHeight: "22px",
                  color: "#fff",
                  width: "100%",
                }}
                onClick={(e) => {
                  setSelectedEpisode(null);
                  setSelectedEpisodeEdit(null);
                  setImage(null);
                  setVideoFile("");
                  setEpisodeName("");
                  setImageFile("");
                  setEpisodeUrl("");
                  setEpisodeApproach(null);
                  setEpisodeDescription(null);
                  setEpisodeDescription(EditorState.createEmpty());
                }}
              >
                {intl.formatMessage({ id: "cancel" })}
              </Button>
            </Grid>
          )}
        </Grid>
        <Grid xs={12}>
          <Typography style={{ color: "#40B58A", marginBottom: "5px" }}>
            Don't leave page if episode status is in progress!
          </Typography>
        </Grid>
        <Hidden smDown>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {episodes?.length > 1 && (
                <Typography>
                  <span style={{ color: "red" }}>*</span>
                  {intl.formatMessage({ id: "dragText" })}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12}>
              <ReactSortable
                list={episodes}
                onChange={(e) => {
                  console.log("event", e);
                }}
                setList={(newList) => {
                  console.log(newList);
                  setEpisodes(newList);
                }}
                direction="horizontal"
                tag={CustomComponent}
              >
                {episodes &&
                  episodes.length > 0 &&
                  episodes.map((ep) => (
                    <Grid key={ep.id} id={ep.id} item xs={12} md={6} lg={4}>
                      <EpisodeEditCard
                        title={ep.name}
                        imageUrl={ep.thumbnail}
                        id={ep.id}
                        multimediaUrl={ep.multimediaUrl}
                        episodeVideoProgress={
                          coursesReducerState?.episodeVideoProgress?.filter(
                            (item) => item.episode_id === ep.id
                          )[0]?.percentage
                            ? coursesReducerState.episodeVideoProgress?.filter(
                                (item) => item.episode_id === ep.id
                              )[0].percentage
                            : 0
                        }
                        showWarning={Boolean(
                          episodes.filter((item) => item.status === "Active")
                            .length <= 1
                        )}
                        episodeVideoUploadLoader={
                          coursesReducerState?.videoUploadLoaders?.includes(
                            ep.id
                          )
                            ? true
                            : false
                        }
                        handleChangeStatus={() => handleChangeStatus(ep)}
                        status={ep.status}
                        setSelectedEpisode={setSelectedEpisode}
                        selectedEpisode={selectedEpisode}
                        deleteClick={() => setSelectedEpisode(ep)}
                        handleDeleteEpisode={() => {
                          dispatch(
                            deleteEpisode({
                              episodeData: {
                                courseId: selectedEpisode?.courseId,
                                episodeId: selectedEpisode?.id,
                              },
                              token: authReducerState.token,
                            })
                          );
                        }}
                        editClick={() => {
                          setImage(ep.thumbnail);
                          setSelectedEpisodeEdit(ep);
                          setVideoFile(ep.videoFile);
                          setEpisodeName(ep.name);
                          setImageFile(ep.imageFile);
                          setEpisodeUrl(ep.multimediaUrl);
                          setEpisodeApproach(ep.uploadApproach);
                          const blocksFromHtml = convertFromHTML(
                            unescape(ep?.description)
                          );
                          const { contentBlocks, entityMap } = blocksFromHtml;
                          const contentState =
                            ContentState.createFromBlockArray(
                              contentBlocks,
                              entityMap
                            );
                          const editorState =
                            EditorState.createWithContent(contentState);
                          ep?.description
                            ? setEpisodeDescription(editorState)
                            : setEpisodeDescription(EditorState.createEmpty());
                        }}
                      />
                    </Grid>
                  ))}
              </ReactSortable>
            </Grid>
          </Grid>
        </Hidden>
        <Hidden mdUp>
          <Dialog
            fullScreen
            style={{
              paddingTop: "1rem",
              paddingBottom: "1rem",
            }}
            open={episodeDrag}
          >
            <DialogTitle>
              <Typography component="h4" variant="h5">
                {intl.formatMessage({ id: "dragText" })}
              </Typography>
            </DialogTitle>
            <DialogContent>
              <ReactSortable
                list={episodes}
                onChange={(e) => {
                  console.log("event", e);
                }}
                setList={(newList) => {
                  console.log(newList);
                  setEpisodes(newList);
                }}
                direction="vertical"
                tag={CustomComponent}
              >
                {episodes &&
                  episodes.length > 0 &&
                  episodes.map((ep) => (
                    <Grid key={ep.id} id={ep.id} spacing={1} item xs={11}>
                      <Card>
                        <CardContent style={{ padding: ".8rem" }}>
                          <Typography component="p" variant="p">
                            <Truncate lines={1} ellipsis={<span>...</span>}>
                              {ep.name}
                            </Truncate>
                          </Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                  ))}
              </ReactSortable>
            </DialogContent>
            <DialogActions>
              <Button
                style={{
                  background: "#F28705",
                  border: "3px solid #F28705",
                  textAlign: "center",
                  paddingLeft: "1.5rem",
                  paddingRight: "1.5rem",
                  fontFamily: theme.fontFamily.Roboto,
                  fontStyle: "normal",
                  fontWeight: "normal",
                  fontSize: "19px",
                  lineHeight: "22px",
                  color: "#fff",
                }}
                onClick={() => setEpisodeDrag(false)}
              >
                Ok
              </Button>
            </DialogActions>
          </Dialog>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              {episodes &&
                episodes.length > 0 &&
                episodes.map((ep) => (
                  <Grid key={ep.id} id={ep.id} item xs={12} md={6} lg={4}>
                    <EpisodeEditCard
                      title={ep.name}
                      imageUrl={ep.thumbnail}
                      id={ep.id}
                      multimediaUrl={ep.multimediaUrl}
                      episodeVideoProgress={
                        coursesReducerState?.episodeVideoProgress?.filter(
                          (item) => item.episode_id === ep.id
                        )[0]?.percentage
                          ? coursesReducerState.episodeVideoProgress?.filter(
                              (item) => item.episode_id === ep.id
                            )[0].percentage
                          : 0
                      }
                      showWarning={Boolean(
                        episodes.filter((item) => item.status === "Active")
                          .length <= 1
                      )}
                      episodeVideoUploadLoader={
                        coursesReducerState?.videoUploadLoaders?.includes(ep.id)
                          ? true
                          : false
                      }
                      setSelectedEpisode={setSelectedEpisode}
                      selectedEpisode={selectedEpisode}
                      handleChangeStatus={() => handleChangeStatus(ep)}
                      deleteClick={() => setSelectedEpisode(ep)}
                      handleDeleteEpisode={() => {
                        dispatch(
                          deleteEpisode({
                            episodeData: {
                              courseId: selectedEpisode?.courseId,
                              episodeId: selectedEpisode?.id,
                            },
                            token: authReducerState.token,
                          })
                        );
                      }}
                      status={ep.status}
                      editClick={() => {
                        setImage(ep.thumbnail);
                        setSelectedEpisodeEdit(ep);
                        setVideoFile(ep.videoFile);
                        setEpisodeName(ep.name);
                        setImageFile(ep.imageFile);
                        setEpisodeUrl(ep.multimediaUrl);
                        setEpisodeApproach(ep.uploadApproach);
                        const blocksFromHtml = convertFromHTML(
                          unescape(ep?.description)
                        );
                        const { contentBlocks, entityMap } = blocksFromHtml;
                        const contentState = ContentState.createFromBlockArray(
                          contentBlocks,
                          entityMap
                        );
                        const editorState =
                          EditorState.createWithContent(contentState);
                        setEpisodeDescription(editorState);
                      }}
                    />
                  </Grid>
                ))}
            </Grid>
          </Grid>
          <Grid container justify="center">
            <Grid item xs={11}>
              {episodes?.length > 1 && (
                <Button
                  disabled={coursesReducerState.courseLoader}
                  style={{
                    background: coursesReducerState.courseLoader
                      ? "#C2C2C2"
                      : "#F28705",
                    border: "3px solid #F28705",
                    textAlign: "center",
                    padding: "1.5rem",
                    fontFamily: theme.fontFamily.Roboto,
                    fontStyle: "normal",
                    fontWeight: "normal",
                    fontSize: "19px",
                    lineHeight: "22px",
                    color: "#fff",
                    width: "100%",
                  }}
                  onClick={() => setEpisodeDrag(true)}
                >
                  Change Episode order
                </Button>
              )}
            </Grid>
          </Grid>
        </Hidden>
      </Grid>
      <Grid
        style={{
          paddingLeft: isMobile && !isDesktop ? "0" : "2rem",
          padding: isMobile && !isDesktop ? "10px" : "0px",
        }}
        item
        xs={12}
        md={6}
        lg={6}
      >
        <Tooltip
          title={episodes.length === 0 ? "Add at-least one episode" : ""}
        >
          <div>
            <Button
              disabled={
                loader ||
                episodes.length === 0 ||
                coursesReducerState.courseLoader ||
                (selectedEpisodeEdit && selectedEpisodeEdit.id)
              }
              style={{
                width: "100%",
                height: "2.750em",
                background:
                  loader ||
                  episodes.length === 0 ||
                  coursesReducerState.courseLoader ||
                  (selectedEpisodeEdit && selectedEpisodeEdit.id)
                    ? "#C2C2C2"
                    : "linear-gradient(180deg, #FFC000 -13.24%, #F28705 100%)",
                borderRadius: isMobile && !isDesktop ? "50px" : "11px",
                fontFamily: theme.fontFamily.Roboto,
                fontStyle: "normal",
                fontWeight: 500,
                fontSize: "20px",
                lineHeight: "23px",
                color: "#FFFFFF",
                marginBottom: "30px",
              }}
              onClick={() => {
                updateEpisodeOrder();
              }}
            >
              {loader ? (
                <CircularProgress size={20} style={{ color: "#fff" }} />
              ) : (
                intl.formatMessage({ id: "next" })
              )}
            </Button>
          </div>
        </Tooltip>
      </Grid>
    </>
  );
};

export default EpisodeAddContainer;

const useStyles = makeStyles((theme) => ({
  rootDropZone: {
    width: "100%",
    height: "92%",
    opacity: "0.2",
    border: "2px dashed #000000",
    boxSizing: "border-box",
    borderRadius: "20px",
    marginTop: "10px",
  },
  errorMessage: {
    color: "#f44336",
    margin: 0,
    fontSize: "0.75rem",
    marginTop: " 3px",
    textAlign: "left",
    fontFamily: "Reef",
    fontWeight: 400,
    lineHeight: 1.66,
  },
  courseDescriptionImageCard: {
    height: "15.438em",
    backgroundColor: "#FEEFCC",
    borderRadius: "20px",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "top center",
  },
  cardButton: {
    width: "18em",
    height: "2.488em",
    borderRadius: "20px",
    backgroundColor: "#FBAE02",
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: 300,
    fontSize: "10px",
    lineHeight: "10px",
    color: "#FFFFFF",
    marginTop: "20px",
    margin: "auto",
  },
  cardText: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "16px",
    color: "#363636",
  },
  profile_image: {
    padding: "10px",
    cursor: "pointer",
  },
  progressCard: {
    width: "100%",
    height: "7em",
    borderRadius: "11px",
    transform: "rotate(360deg)",
    marginBottom: "50px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  formControl: {
    width: "100%",
  },
  descriptionTitle: {
    paddingTop: "5px",
    paddingBottom: "5px",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "11px",
    lineHeight: "16px",
    color: "#505050",
    opacity: "0.78",
  },
}));
