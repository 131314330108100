import React from "react";
import LargeButton from "../../../components/Buttons/WebLargeButton";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import simpleLogo from "../../../assets/Icons/RelateIcon.png";
import { useIntl } from "react-intl";
import { useTheme } from "@material-ui/core/styles";


const useStyles = makeStyles(theme => ({
  root: {

    background: `#C4C4C4`,

    minHeight: "100vh",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "top center"
  },
  topRightCircle: {
    width: "150px",
    height: "150px",
    borderRadius: "2% 0px 2% 100%",
    float: "right",
    backgroundColor: "#09A9BB",
    opacity: "0.5"
  },
  centerContainer: {
    textAlign: "center",
    margin: "auto"
  },
  bottomLeftCircle: {
    width: "40px",
    height: "40px",
    borderRadius: "50%",
    float: "right",
    backgroundColor: "#14D9D9",
    opacity: "0.5"

  },
  subHeading: {
    fontFamily: theme.fontFamily.Reef,
    marginTop: "60px",
    fontStyle: "normal",
    fontWeight: "900",
    fontSize: "36px",
    lineHeight: "49px",
    textAlign: "center",
    letterSpacing: " 0.02em",
    color: "#FFFFFF"
  }
}));

const ChooseIdentity = () => {
  const theme = useTheme();
  const classes = useStyles();
  const history = useHistory();
  let dispatch = useDispatch();
  const intl = useIntl();
  const configs = useSelector(state => state?.setting?.siteConfig);

  const buttons = [
    {
      text: intl.formatMessage({ id: "student" }),
      value: "Regular"
    },

    {
      text: intl.formatMessage({ id: "teacher" }),
      value: "Instructor"
    }
  ];
  const goToAuthPage = identity => {
    dispatch({ type: "SET_ROLE", payload: identity });
    history.push({
      pathname: `/auth`,
      id: 1
    });
  };



  return (
    <React.Fragment>
      <div
        className={classes.root}
        style={{ backgroundImage: `url(${configs?.landingBackground})` }}
      >
        <Grid container>
          <Grid item container xs={12}>
            <Grid item xs={3} />
            <Grid
              item
              xs={6}
              justify="center"
              className={classes.centerContainer}
            >
              <img
                src={simpleLogo}
                alt="logo"
                style={{ marginTop: "25px", }}
              />

              <Typography className={classes.subHeading}>
                {intl.formatMessage({ id: "chooseIdentity" })}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <div className={classes.topRightCircle}></div>
            </Grid>
          </Grid>

          <Grid item container xs={12}>
            <Grid item xs={3} />
            <Grid item xs={6} className={classes.centerContainer}>
              {buttons.map((button, index) => (
                <LargeButton
                  key={index}
                  onClick={() => goToAuthPage(button.value)}
                  text={button.text}
                  width={"355px"}
                  fontFamily={theme.fontFamily.Reef}
                  fontStyle={"normal"}
                  fontSize={"22px"}
                  fontWeight={"900"}
                  lineHeight={"30px"}
                  letterSpacing={"0.09em"}
                />
              ))}
            </Grid>
            <Grid item xs={3} />
          </Grid>
          <Grid item xs={12}>
            <Grid item xs={1}>
              <div className={classes.bottomLeftCircle}></div>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  );
};

export default ChooseIdentity;
