import { Grid, Avatar, Button, Typography } from "@material-ui/core";
import { Add, Done, Clear } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
function AddMentorCard(props) {
  const classes = useStyles();

  return (
    <Grid container className={classes.container} justify="space-between">
      <Grid item xs={3}>
        <Avatar src={props?.friend?.pictureUrl} className={classes.image} />
      </Grid>
      <Grid item xs={7}>
        <Typography className={classes.username}>
          @{props?.friend?.username}
        </Typography>
        <Typography className={classes.name}>{props?.friend?.name}</Typography>
        {props?.friend?.isFriend && (
          <Typography className={classes.friend}>Friend</Typography>
        )}
      </Grid>
      <Grid item xs={2}>
        {props?.selected ? (
          <Button
            onClick={() => props?.removeMentor(props?.friend?.id)}
            className={[classes.btn, classes.sentBtn]}
          >
            <Done />
          </Button>
        ) : (
          <Button
            className={classes.btn}
            onClick={() => props?.invitation(props?.friend.id)}
          >
            <Add />
          </Button>
        )}
      </Grid>
    </Grid>
  );
}

export default AddMentorCard;

const useStyles = makeStyles(theme => ({
  container: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    borderBottom: "1px solid rgba(168, 182, 200, 0.3)",
    height: "6.5rem",
    transform: "rotate(360deg)",
    boxShadow: "0px 0px 0px rgba(0, 0, 0, 0.00)"
  },
  image: {
    height: "70px",
    width: "70px"
  },
  username: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "italic",
    fontWeight: "bold",
    fontSize: "18px",
    lineHeight: "21px",
    color: "#3C4A6B",
    marginLeft: "5px"
  },
  name: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "21px",
    color: "#3C4A6B",
    marginTop: "5px",
    marginLeft: "5px"
  },
  friend: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "21px",
    color: "#A9A9A9",
    marginTop: "5px",
    marginLeft: "5px"
  },
  btn: {
    borderRadius: "26px",
    height: "64px",
    width: "64px",
    color: "#FFF !important",
    background: "#fbae02",
    "&:hover": {
      background: "#fbae02"
    }
  },
  sentBtn: {
    background: "#A9A9A9",
    "&:hover": {
      background: "#A9A9A9"
    }
  },
  cancelBtn: {
    background: "#FF4C3B",
    "&:hover": {
      background: "#FF4C3B"
    }
  }
}));
