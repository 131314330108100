import React from 'react';
import { CardContent, Grid, Card, Avatar, Typography } from '@material-ui/core'
import { makeStyles } from "@material-ui/core/styles";
import moment from 'moment'

function MentorComment(props) {
    const classes = useStyles();
    const { comment } = props

    return (
        <Grid container spacing={2}>
            <Grid item xs={2} sm={2} md={2} lg={1}><Avatar src={comment?.user?.pictureUrl}>{comment?.user?.name[0]}</Avatar></Grid>
            <Grid item xs={10} sm={10} md={10} lg={11}>
                <Card className={classes.cardContainer}>
                    <CardContent>
                        <Grid container>
                            <Grid container item xs={9}>
                                <Grid item ><Typography className={classes.mentorName}>{comment?.user?.name}</Typography></Grid>
                            </Grid>
                            <Grid item xs={3}><Typography className={classes.time}>{moment(comment?.createdAt).format('LT')}</Typography></Grid>
                            <Grid item xs={12}><Typography className={classes.content}>
                                {comment?.comment}
                            </Typography></Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>

        </Grid>
    );
}

export default MentorComment;

const useStyles = makeStyles(theme => ({
    cardContainer: {
        background: '#FAF9F9',
        width: '100%',
        boxShadow: 'none'
    },

    mentorName: {
        fontFamily: theme.fontFamily.Roboto,
        fontStyle: "normal",
        fontWeight: 'bold',
        fontSize: "16px",
        lineHeight: "25px",
        color: "#383838"
    },
    time: {
        fontFamily: theme.fontFamily.Roboto,
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "12px",
        lineHeight: "14px",
        color: "#868686",
        textAlign: "right"
    },
    content: {
        fontFamily: theme.fontFamily.Roboto,
        fontStyle: "normal",
        fontWeight: 900,
        fontSize: "16px",
        lineHeight: "25px",
        color: "#888888"
    },

}));