import React,{useEffect} from "react";
import { useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import ChallengesStatsMobileContainer from "../../../containers/Mobile/More/ChallengesStats";
import More from "../../../containers/Web/More";
import {useDispatch,useSelector} from 'react-redux'
import { getAllChallenges, getChallengesStats } from "../../../actions/challenges";


const ChallengesStats = () => {
  const theme = useTheme();
  let dispatch = useDispatch();
  const authReducer = useSelector((state) => state?.auth);
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    dispatch(getAllChallenges(authReducer?.token));
    dispatch(getChallengesStats({ token: authReducer?.token }))
  })

  return (
    <>
      {isDesktop && <More />}
      {isMobile && !isDesktop && <ChallengesStatsMobileContainer />}
    </>
  );
};

export default ChallengesStats;
