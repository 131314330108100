import React, { useState, useEffect } from "react";
import { makeStyles, useTheme, ThemeProvider } from "@material-ui/core/styles";
import clsx from "clsx";
import { useIntl } from "react-intl";
import { useSelector, useDispatch } from "react-redux";
import { ReactSortable } from "react-sortablejs";
import {
  Grid,
  Radio,
  Typography,
  Card,
  Select,
  MenuItem,
  Button,
  TextField,
  useMediaQuery,
  CardContent,
  CircularProgress,
  Divider,
  createMuiTheme,
  InputAdornment,
  Icon,
  Tooltip,
  InputLabel,
} from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { ADD_ID } from "../../../actions/types";
import {
  CheckCircle,
  Brightness1,
  Edit,
  DeleteForever,
  DragIndicator,
  DateRange,
  AccessTime,
  Error,
} from "@material-ui/icons";
import { useHistory, useParams } from "react-router-dom";
import { getAllCategories } from "../../../actions/courses";
import { getUsersFriends } from "../../../actions/user";
import AddImage from "../AddImage/Index";
import RepeatChallengeCard from "./RepeatChallengeCard";
import ChallengePartsCard from "./ChallengePartsCard";
import CourseEpisodeCard from "./ChallengeStepCard";
import ChallengeParticipants from "./ChallengeParticipants";
import InviteFriends from "./InviteFriends";
import InviteFriendsMobile from "./InviteFriendsMobile";
import {
  createChallenge,
  addParticipants,
  createChallengeData,
  getChallengeDetail,
  updateChallenge,
} from "../../../actions/challenges";
import momentTimeZone from "moment-timezone";
import moment from "moment";
import {
  getCoursesGroupByCategory,
    getUserEnrolledCourses,
  getUserUploadedCourses,
} from "../../../actions/courses";
import CourseCard from "../../../components/Common/CourseCard";
 import {Dialog, DialogTitle, DialogContent, DialogActions, IconButton } from '@material-ui/core';


const defaultMaterialTheme = createMuiTheme({
  palette: {
    primary: { 500: "#FBAE02" },
  },
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        color: { 100: "#fff" },
      },
    },

    MuiPickersModal: {
      dialogAction: {
        color: { 500: "#FBAE02" },
      },
    },
  },
});

function CreateChallenge(props) {
  const classes = useStyles();
  const history = useHistory();
  const params = useParams();
  const dispatch = useDispatch();
  const theme = useTheme();

  const intl = useIntl();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  let categoriesList = useSelector((state) => state?.courses?.categoriesList);
  let loader = useSelector((state) => state?.loader?.loader);
  const challengeParticipantsReducer = useSelector(
    (state) => state?.challenges?.challengeParticipants
  );
  const ids = useSelector((state) => state?.challenges?.id);
  const imageUrl = useSelector((state) => state?.challenges?.imageUrl);
  const newType = useSelector((state) => state?.challenges?.type);

  let authReducer = useSelector((state) => state?.auth);
  let reducerUser = useSelector((state) => state?.user?.user);
  let createChallengeDataReducer = useSelector(
    (state) => state?.challenges?.createChallengeData
  );
  console.log(createChallengeDataReducer,"createChallengeDataReducer")
  const authReducerState = useSelector((state) => state.auth);

  let challengeReducerDetail = useSelector(
    (state) => state?.challenges?.challengeDetail
  );
  console.log(challengeReducerDetail,"challengeReducerDetail")


  const [challengeType, setChallengeType] = useState("Solo Challenge");
  const [challengeSubType, setChallengeSubType] = useState("Individual");
  const [image, setImage] = useState("");
  const [category, setCategory] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [repeatChallenge, setRepeatChallenge] = useState("Daily");
  const [planValue, setPlanValue] = useState("free");
  const [checkedDay, setCheckedDay] = React.useState([]);
  const [prepDuration, setPrepDuration] = useState(null);
  const [restDuration, setRestDuration] = useState(null);
  const [exerciseTime, setExerciseTime] = useState("10:00");
  const [addFriendMode, setAddFriendMode] = useState(false);
  const [challengeDescription, setChallengeDescription] = useState("");
  const [challengeTitle, setChallengeTitle] = useState("");
  const [recurringPeriod, setRecurringPeriod] = useState(null);
  const [durationValue, setDurationValue] = useState(0);
  const [priceValue, setPriceValue] = useState(0);
  const [courseInfo, setCourseInfo] = useState({});
  const [update, setUpdate] = useState("create");
  const [stepName, setStepName] = useState("");
  const [startDate, setStartDate] = useState(moment().format("YYYY/MM/DD"));
  const [isOpen, setIsOpen] = useState(false);
  const [stepChallengeEpLength, setStepChallengeEpLength] = useState("");
  const [selectedChallengeEpisodes, setSelectedChallengeEpisodes] = useState(
    []
  );
  let userUploadedCoursesList = useSelector(
    (state) => state?.courses?.userUploadedCourses
  );
  const coursesGroupByCategory = useSelector(
    (state) => state?.courses?.coursesGroupByCategoryList
  );
  const [categoryArr, setCategoryArr] = useState([]);
  const [toBeUpdatedChallengeIndex, setToBeUpdatedChallengeIndex] =
    useState(null);
  const [timeZone, setTimeZone] = React.useState("");
  const [error, setError] = useState("");
  const [stepError, setStepError] = useState("");
  const [isStarted, setIsStarted] = useState(false);
  const [openModel, setOpenModel] = useState(false);

  const timeZones = moment.tz
    .names()
    .filter((tz) =>
      tz.match(
        /^(((Africa|America|Antarctica|Asia|Australia|Europe|Arctic|Atlantic|Indian|Pacific)\/.+)|(UTC))$/
      )
    );

  useEffect(() => {
    dispatch(getUsersFriends(authReducer?.token));
    dispatch(getAllCategories());
    dispatch(getCoursesGroupByCategory({ token: authReducerState?.token }));
    if (params?.id) {
      dispatch(
        getChallengeDetail({
          token: authReducer?.token,
          challengeId: params?.id,
        })
      );
    }
  }, []);
  useEffect(() => {
    setAddFriendMode(props.addFriendState);
  }, [props.addFriendState]);


  function secondsToTime(secs) {
    let hours = Math.floor(secs / (60 * 60));

    let divisor_for_minutes = secs % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60);

    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds);

    let obj = {
      hr: hours,
      min: minutes,
      sec: seconds,
    };

    if (obj.hr === 0 && obj.min === 0) {
      let newObj = {
        hr: "00",
        min: "00",
        sec: seconds,
      };
      return newObj;
    } else if (obj.hr === 0 && obj.min !== 0) {
      let newObj = { hr: "00", min: minutes, sec: seconds };
      return newObj;
    } else {
      return obj;
    }
  }

  const calculateRecurring = () => {
    let weekly = {};
    challengeReducerDetail &&
      challengeReducerDetail.challengeDates &&
      challengeReducerDetail.challengeDates.map((date, ind) => {
        let day = new Date(date.date).getDay();
        if (weekly[day]) {
          weekly = { ...weekly, [day]: weekly[day] + 1 };
        } else {
          weekly[day] = 1;
        }
      });
    return weekly;
  };


  const setAllFieldsData = () => {
    if (params?.id) {
      let calculatePrepTime = secondsToTime(challengeReducerDetail?.prepTime);
      let calculateRestTime = secondsToTime(challengeReducerDetail?.restTime);

      setIsStarted(
        moment(moment.utc(challengeReducerDetail?.startDate).local()).isBefore(
          moment()
        )
      );

      let detailChallengeSteps = challengeReducerDetail?.challengeEpisodes?.map(
        (ep) => {
          return {
            stepName: ep?.episode?.name,
            ...ep?.episode?.course,
            episodes: [{ ...ep?.episode, episode: null }],
          };
        }
      );

      let allChallengeEpisodes = challengeReducerDetail?.challengeEpisodes?.map(
        (ep, ind) => {
          return {
            ...ep?.episode,
          };
        }
      );
      let rec =
        challengeReducerDetail &&
        challengeReducerDetail?.periodicity === "weekly" &&
        calculateRecurring();
      setImage(challengeReducerDetail?.imageUrl);
      setChallengeType(
        challengeReducerDetail?.challengeGroup === "step"
          ? "Step Challenge"
          : "Solo Challenge"
      );
      setChallengeSubType(challengeReducerDetail?.type);
      setCategory(
        challengeReducerDetail?.challengeGroup === "solo"
          ? challengeReducerDetail?.challengeEpisodes &&
              challengeReducerDetail?.challengeEpisodes[0]?.episode?.course
                ?.category?.name
          : ""
      );
      setChallengeTitle(challengeReducerDetail?.title);
      setRecurringPeriod(
        challengeReducerDetail?.periodicity === "daily"
          ? challengeReducerDetail?.challengeDates?.length
          : Object.values(rec)[0]
      );
      setDurationValue(
        challengeReducerDetail?.totalDurationInMinutes
          ? challengeReducerDetail?.totalDurationInMinutes
          : 0
      );
      setPriceValue(
        challengeReducerDetail?.price
          ? challengeReducerDetail?.price
          : Object.values(rec)[0]
      );
      setPlanValue(challengeReducerDetail?.plan === "free" ? "free" : "paid")

      setCheckedDay(
        challengeReducerDetail?.periodicity === "daily"
          ? []
          : Object.keys(rec).map((item) => item * 1)
      );
      setChallengeDescription(challengeReducerDetail?.description);
      setStartDate(
        challengeReducerDetail?.startDate &&
          moment
            .utc(challengeReducerDetail?.startDate)
            .tz(challengeReducerDetail?.timeZone)
            .format("YYYY/MM/DD")
      );
      setRepeatChallenge(
        challengeReducerDetail?.periodicity === "weekly" ? "Weekly" : "Daily"
      );
      setPrepDuration(calculatePrepTime);

      setRestDuration(calculateRestTime);

      setExerciseTime(challengeReducerDetail?.exerciseTime);
      setTimeZone(challengeReducerDetail?.timeZone || "");
      setCourseInfo(
        challengeReducerDetail?.challengeGroup === "solo"
          ? {
              ...challengeReducerDetail?.challengeEpisodes[0]?.episode?.course,
              episodes: allChallengeEpisodes,
            }
          : ""
      );
      setSelectedChallengeEpisodes(detailChallengeSteps);
    } else if (!params?.id && createChallengeDataReducer?.courseInfo) {
      setUpdate(
        createChallengeDataReducer?.courseInfo?.state?.updated
          ? createChallengeDataReducer?.courseInfo?.state?.updated
          : "create"
      );
      setToBeUpdatedChallengeIndex(
        createChallengeDataReducer?.courseInfo?.state?.toBeUpdatedChallengeIndex
      );
      setChallengeType(
        createChallengeDataReducer?.courseInfo?.state?.currentTab
          ? createChallengeDataReducer?.courseInfo?.state?.currentTab
          : "Solo Challenge"
      );
      setChallengeSubType(
        createChallengeDataReducer?.courseInfo?.state?.challengeSubType
          ? createChallengeDataReducer?.courseInfo?.state?.challengeSubType
          : "Individual"
      );
      setImage(createChallengeDataReducer?.courseInfo?.image);
      setCategory(
        createChallengeDataReducer?.category
          ? createChallengeDataReducer?.category
          : ""
      );
      setChallengeTitle(
        createChallengeDataReducer?.challengeTitle
          ? createChallengeDataReducer?.challengeTitle
          : ""
      );
      setStartDate(
        createChallengeDataReducer?.startDate
          ? createChallengeDataReducer?.startDate
          : moment().format("YYYY/MM/DD")
      );
      setRecurringPeriod(
        createChallengeDataReducer?.recurringPeriod
          ? createChallengeDataReducer?.recurringPeriod
          : ""
      );
      setDurationValue(
        createChallengeDataReducer?.totalDurationInMinutes
          ? createChallengeDataReducer?.totalDurationInMinutes
          : ""
      );


      setPlanValue(challengeReducerDetail?.plan === "free" ? "free" : "paid")
      setPriceValue(
        createChallengeDataReducer?.price
        ? createChallengeDataReducer?.price
        : ""
      )
      setChallengeDescription(
        createChallengeDataReducer?.challengeDescription
          ? createChallengeDataReducer?.challengeDescription
          : ""
      );
      setRepeatChallenge(
        createChallengeDataReducer?.repeatChallenge
          ? createChallengeDataReducer?.repeatChallenge
          : "Daily"
      );
      setCheckedDay(
        createChallengeDataReducer?.checkedDay
          ? createChallengeDataReducer?.checkedDay
          : []
      );
      setPrepDuration(
        createChallengeDataReducer?.prepDuration
          ? createChallengeDataReducer?.prepDuration
          : null
      );
      setRestDuration(
        createChallengeDataReducer?.restDuration
          ? createChallengeDataReducer?.restDuration
          : null
      );

      setExerciseTime(
        createChallengeDataReducer?.exerciseTime
          ? createChallengeDataReducer?.exerciseTime
          : "10:00"
      );
      setTimeZone(
        createChallengeDataReducer?.timeZone
          ? createChallengeDataReducer?.timeZone
          : ""
      );
      setCourseInfo(createChallengeDataReducer?.courseInfo);
      setStepName(
        createChallengeDataReducer?.stepName
          ? createChallengeDataReducer?.stepName
          : ""
      );
      setSelectedChallengeEpisodes(
        createChallengeDataReducer?.selectedChallengeEpisodes
          ? createChallengeDataReducer?.selectedChallengeEpisodes
          : []
      );
    }
  };

  useEffect(() => {
    setAllFieldsData();
  }, [createChallengeDataReducer, challengeReducerDetail]);

  const handleChallengeType = (e) => {
    setError("");
    if (e.target.value === "Solo Challenge") {
      setCourseInfo(createChallengeDataReducer?.courseInfo);
    }
    setChallengeType(e.target.value);
  };
  const handleChallengeSubType = (e) => {
    setError("");
    setChallengeSubType(e.target.value);
  };

  const handleSelectCategory = (event) => {
    setError("");
    setStepError("");
    setCategory(event.target.value);
    event.target.value &&
      setCategoryId(
        categoriesList.filter((item) => item.name === event.target.value)[0].id
      );

  };

  const handleDateChange = (date) => {
    setStartDate(date);
  };

  const handleKeypress = (e, date) => {
    setStartDate(date);
    e.preventDefault();
    return false;
  };

  const handleCheckedDays = (value) => () => {
    if (params?.id && isStarted) {
      return null;
    }
    const currentIndex = checkedDay.indexOf(value);
    const newChecked = [...checkedDay];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setError("");
    setCheckedDay(newChecked);
  };

  const handleAddFriendMode = () => {
    if (params?.id && isStarted) {
      return null;
    }
    setError("");
    setAddFriendMode(!addFriendMode);
    return props.addFriend(!addFriendMode);
  };

  const handleSetTime = (durationTypeName, valueGroups) => {
    setError("");
    if (durationTypeName === "prepTime") {
      setPrepDuration({
        hr: valueGroups.hr,
        min: valueGroups.min,
        sec: valueGroups.sec,
      });
    } else if (durationTypeName === "restDuration") {
      setRestDuration({
        hr: valueGroups.hr,
        min: valueGroups.min,
        sec: valueGroups.sec,
      });
    }
  };

  const handleCreateChallenge = async () => {
    if (
      challengeType === "Step Challenge" &&
      !selectedChallengeEpisodes?.length > 0
    ) {
      setError(`* please add at least one step!`);
    } else if (challengeType === "Solo Challenge" && !courseInfo?.id) {
      setError(`* please select a course by category!`);
    }else if (
      challengeType === "Solo Challenge" &&
      !courseInfo?.episodes?.filter((ep) => ep?.checkedStatus).length > 0 &&
      !params?.id
    ) {
      setError(`* please select atleast one episode!`);
    } else if (challengeTitle === "") {
      setError(`* challenge Title can't be empty!`);
    } else if (challengeDescription === "") {
      setError(`* challenge description can't be empty!`);
    } else if (startDate === "") {
      setError(`* start date can't be empty!`);
    } else if (timeZone === "") {
      setError(`* please select a timezone!`);
    } else if (!recurringPeriod) {
      setError(`* recurring period can't be empty!`);
    }
     else if (recurringPeriod > 365) {
      setError(`* recurring period can't be more then 365!`);
    }
    else if (!durationValue) {
      setError(`* Duration can't be empty!`);
    }
    else if (durationValue  < 0) {
      setError(`* Duration can't less than 0!`);
    }
    else if (planValue=== "paid" && !priceValue) {
      setError(`* price can't be empty!`);
    } else if (repeatChallenge !== "Daily" && checkedDay.length === 0) {
      setError(`* select at least one week day!`);
    } else if (!prepDuration) {
      setError(`* prep duration is required!`);
    } else if (!restDuration && challengeType === "Step Challenge") {
      setError(`* rest duration is required!`);
    } else if (!exerciseTime) {
      setError(`* exercise time is required!`);
    } else {
      let userIds =
        challengeSubType === "Group"
          ? challengeParticipantsReducer?.length > 0
            ? challengeParticipantsReducer?.map((participant) => participant.id)
            : []
          : null;
      let prepTimeInSeconds =
        prepDuration.hr * 60 * 60 +
        prepDuration.min * 60 +
        prepDuration.sec * 1;
      let restDurationInSeconds =
        restDuration?.hr * 60 * 60 +
        restDuration?.min * 60 +
        restDuration?.sec * 1;

      if (params?.id) {
        let data1 = {
          challengeId: params?.id,
          title: challengeTitle,
          description: challengeDescription,
          prepTime: prepTimeInSeconds,
          restTime: restDurationInSeconds,
          startDate: moment(startDate).format("YYYY-MM-DD"),
          exerciseTime,
          timeZone,
          periodicity: repeatChallenge,
          weekDays: checkedDay,
          userIds,
          recurringPeriod,
          totalDurationInMinutes: durationValue,
         price : planValue === "paid" ? priceValue : 0,
          plan: planValue,
         /*  courseEpisodes:
          challengeType === "Solo Challenge"
            ? courseInfo?.episodes
                ?.map((ep) => {
                  if (ep?.checkedStatus) {
                    return {
                      courseId: courseInfo?.id,
                      episodeId: ep?.id,
                    };
                  }
                })
                .filter(Boolean)
            : selectedChallengeEpisodes?.map((ep) => {
                return {
                  courseId: ep?.id,
                  episodeId: ep?.episodes[0]?.id,
                  step: ep?.stepName,
                };
              }), */
        };
        if (isStarted) {
          dispatch(
            updateChallenge(authReducer.token, {
              challengeId: params?.id ? params?.id : challengeReducerDetail?.id,
              title: data1?.title,
              description: data1?.description,
              price: planValue ==="free" ? 0 : priceValue,
              plan: planValue,
              totalDurationInMinutes :durationValue,
              courseEpisodes:
              challengeType === "Solo Challenge"
                ? courseInfo?.episodes
                    ?.map((ep) => {
                      if (ep?.checkedStatus) {
                        return {
                          courseId: courseInfo?.id,
                          episodeId: ep?.id,
                        };
                      }
                    })
                    .filter(Boolean)
                : selectedChallengeEpisodes?.map((ep) => {
                    return {
                      courseId: ep?.id,
                      episodeId: ep?.episodes[0]?.id,
                      step: ep?.stepName,
                    };
                  }),
            })
          );
        } else {
          dispatch(updateChallenge(authReducer.token, data1));
        }
        dispatch(createChallengeData({}));
        dispatch(addParticipants({}));
      } else {
        let data = {
          title: challengeTitle,
          description: challengeDescription,
          prepTime: prepTimeInSeconds,
          restTime:
            challengeType === "Step Challenge"
              ? restDurationInSeconds
              : undefined,
          startDate: moment(startDate).format("YYYY-MM-DD"),
          exerciseTime,
          timeZone,
          type: challengeSubType,
          periodicity: repeatChallenge,
          weekDays: checkedDay,
          userIds,
          recurringPeriod,
          totalDurationInMinutes: durationValue,
          plan : planValue,
          price: planValue === "paid" ? priceValue : 0,

          challengeGroup: challengeType === "Solo Challenge" ? "solo" : "step",
          courseEpisodes:
            challengeType === "Solo Challenge"
              ? courseInfo?.episodes
                  ?.map((ep) => {
                    if (ep?.checkedStatus) {
                      return {
                        courseId: courseInfo?.id,
                        episodeId: ep?.id,
                      };
                    }
                  })
                  .filter(Boolean)
              : selectedChallengeEpisodes?.map((ep) => {
                  return {
                    courseId: ep?.id,
                    episodeId: ep?.episodes[0]?.id,
                    step: ep?.stepName,
                  };
                }),
        };
        dispatch(createChallenge(authReducer.token, data));
        dispatch(addParticipants({}));
        dispatch(createChallengeData({}));
      }
    }
  };

  const handleUpdateStep = (e, id, index) => {
    e.preventDefault();
    setUpdate("updated");
    setStepName(selectedChallengeEpisodes[index]?.stepName);
    setCourseInfo({ ...selectedChallengeEpisodes[index] });
    setCategory(selectedChallengeEpisodes[index]?.category?.name);
    setStepChallengeEpLength(selectedChallengeEpisodes[index]?.episodes[0]?.id);
    setToBeUpdatedChallengeIndex(index);
  };

  const handleBrowse = () => {
    dispatch(
      createChallengeData({
        courseInfo: {
          ...courseInfo,
          state: {
            currentTab: challengeType,
            challengeSubType,
            toBeOpenTab: "explore",
            category,
            categoryId,
            updated: update,
            toBeUpdatedChallengeIndex,
          },
        },
        category,
        repeatChallenge,
        checkedDay,
        prepDuration,
        restDuration,
        startDate,
        timeZone,
        exerciseTime,
        challengeType,
        challengeDescription,
        recurringPeriod,
        planValue,
        priceValue,
        challengeTitle,
        selectedChallengeEpisodes,
        stepName,
      })
    );

    return history.push({
      pathname: `/courses/user-enrolled`,
      state: {
        currentTab: challengeType,
        challengeSubType,
        toBeOpenTab: "explore",
        category,
        categoryId,
        updated: update,
        toBeUpdatedChallengeIndex,
      },
    });
  };

  const updateChallengeStep = () => {
    if (stepName === "") {
      setStepError("* step must have a name!");
    } else if (stepChallengeEpLength === "") {
      setStepError("* please select one episode for create step!");
    } else {
      let selectedStepEp = courseInfo?.episodes?.filter(
        (ep) => ep?.checkedStatus
      );
      if (challengeType === "Step Challenge" && selectedStepEp?.length > 1) {
        setStepError("* you can only select one episode in step challenge!");
      } else if (
        stepName !== "" &&
        category !== "" &&
        challengeType === "Step Challenge" &&
        selectedStepEp?.length === 1
      ) {
        let selectedChallengeEpisodesLocal = selectedChallengeEpisodes
          ?.map((item, ind) => {
            if (ind === toBeUpdatedChallengeIndex) {
              return {
                ...item,
                stepName,
                episodes: selectedStepEp,
              };
            }
            return item;
          })
          .filter(Boolean);

        setSelectedChallengeEpisodes(selectedChallengeEpisodesLocal);

        setStepName("");
        setCategory("");
        setCourseInfo({});
        setUpdate("create");
        setStepChallengeEpLength("");
        setToBeUpdatedChallengeIndex(null);
      }
    }
  };

  const addChallengeStep = () => {
    if (stepName === "") {
      setStepError("* step must have a name!");
    } else if (stepChallengeEpLength === "") {
      setStepError("* please select one episode for create step!");
    } else {
      let selectedStepEp = courseInfo?.episodes?.filter(
        (ep) => ep?.checkedStatus
      );
      if (challengeType === "Step Challenge" && selectedStepEp?.length > 1) {
        setStepError("* you can only select one episode in step challenge!");
      } else if (
        stepName !== "" &&
        category !== "" &&
        challengeType === "Step Challenge" &&
        selectedStepEp.length === 1
      ) {
        let selectedChallengeEpisodesLocal = selectedChallengeEpisodes
          ?.filter((item) => item?.episodes[0]?.id !== selectedStepEp[0]?.id)
          .filter(Boolean);

        setSelectedChallengeEpisodes([
          ...selectedChallengeEpisodesLocal,
          { stepName, ...courseInfo, episodes: selectedStepEp },
        ]);
        setStepName("");
        setCategory("");
        setStepChallengeEpLength("");
        setCourseInfo({});
      }
    }
  };


  const selectEpisode = (e, id) => {
    e.preventDefault();
    setStepError("");

    if (challengeType === "Step Challenge") {
      if (stepChallengeEpLength !== "") {
        if (stepChallengeEpLength === id) {
          setStepChallengeEpLength("");
          let episodesLocally = courseInfo?.episodes?.map((episode) => {
            if (episode?.checkedStatus && episode?.id === id) {
              return { ...episode, checkedStatus: false };
            } else if (!episode?.checkedStatus && episode?.id === id) {
              return { ...episode, checkedStatus: true };
            }
            return episode;
          });
          setCourseInfo({ ...courseInfo, episodes: [...episodesLocally] });
        }
      } else if (stepChallengeEpLength === "") {
        setStepChallengeEpLength(id);
        let episodesLocally = courseInfo?.episodes?.map((episode) => {
          if (episode?.checkedStatus && episode?.id === id) {
            return { ...episode, checkedStatus: false };
          } else if (!episode?.checkedStatus && episode?.id === id) {
            return { ...episode, checkedStatus: true };
          }
          return episode;
        });
        setCourseInfo({ ...courseInfo, episodes: [...episodesLocally] });
      }
    }

    if (challengeType !== "Step Challenge") {
      setError("");
      let episodesLocally = courseInfo?.episodes?.map((episode) => {
        if (episode?.checkedStatus && episode?.id === id) {
          return { ...episode, checkedStatus: false };
        } else if (!episode?.checkedStatus && episode?.id === id) {
          return { ...episode, checkedStatus: true };
        }
        return episode;
      });
      setCourseInfo({ ...courseInfo, episodes: [...episodesLocally] });
    }
  };


  const checkAlreadyAddedEpisode = (ep) => {
    if (
      selectedChallengeEpisodes?.length > 0 &&
      selectedChallengeEpisodes?.some(
        (step) => step?.episodes[0]?.id === ep?.id
      )
    ) {
      if (update === "create") {
        let episodesLocally = courseInfo?.episodes?.filter(
          (episode) => episode?.id !== ep?.id
        );

        setCourseInfo({ ...courseInfo, episodes: [...episodesLocally] });
        return true;
      } else {
        return false;
      }
    }
  };

  const removeStep = (e, index) => {
    e.preventDefault();
    let selectedEpisodesLocally = selectedChallengeEpisodes?.filter(
      (step, i) => {
        return i !== index;
      }
    );

    setSelectedChallengeEpisodes([...selectedEpisodesLocally]);
  };
  useEffect(() => {
    if (ids !== -1) {
      let idd = ids;
      let typee = newType;
      let imageUrlLocal = imageUrl;

      dispatch({
        type: ADD_ID,
        payload: { id: -1, type: "", imageUrl: "" },
      });
      if (typee === "Group") {
        history.push({
          pathname: `/more/challenge/invite/${idd}`,
          from: "create",
          state: { imageUrl: imageUrlLocal },
        });
      } else {
        history.push(`/more/relate-with-friends`);
      }
    }
  }, [ids, newType, imageUrl]);


  return (
    <>
    {reducerUser?.canCreateChallenge === true ? (
       <Grid container style={{ paddingBottom: isMobile && "70px" }}>
       {!addFriendMode ? (
         <>
           <Grid item container xs={12}>
             <Grid item container xs={12} md={8}>
               {isMobile && <Grid xs={1} />}
               <Grid item xs={2} lg={1}>
               <Radio
                   checked={challengeType === "Solo Challenge"}
                   onChange={handleChallengeType}
                   className={classes.root}
                   classes={{ root: classes.root }}
                   disableRipple
                   color="default"
                   value="Solo Challenge"
                   checkedIcon={
                     <span className={clsx(classes.icon, classes.checkedIcon)} />
                   }
                   icon={<span className={classes.icon} />}
                   disabled={params?.id ? true : false}
                 />
               </Grid>
               <Grid item xs={3} lg={4}>
                 <Typography className={classes.radioTxt}>
                   {" "}
                   {intl.formatMessage({ id: "soloChallenge" })}
                 </Typography>
               </Grid>
               <Grid item xs={2} lg={1}>
               <Radio
                   checked={challengeType === "Step Challenge"}
                   onChange={handleChallengeType}
                   classes={{ root: classes.root }}
                   disableRipple
                   color="default"
                   value="Step Challenge"
                   checkedIcon={
                     <span className={clsx(classes.icon, classes.checkedIcon)} />
                   }
                   icon={<span className={classes.icon} />}
                   disabled={params?.id ? true : false}
                 />
               </Grid>
               <Grid item xs={3} lg={4}>
                 <Typography className={classes.radioTxt}>
                   {" "}
                   {intl.formatMessage({ id: "stepChallenge" })}
                 </Typography>
               </Grid>
               {isMobile && <Grid xs={1} />}
             </Grid>
           </Grid>

           {/* <Grid item container xs={12} justify='center'> */}
           <Grid item xs={12} md={6}>
             <Divider
               style={{
                 margin: "10px 0px 10px 0px",
                 marginLeft: !isMobile && "3px",
               }}
             />
           </Grid>
           {/* </Grid> */}

           <Grid item container xs={12}>
             <Grid item container xs={12} md={8}>
               {isMobile && <Grid xs={1} />}
               <Grid item xs={2} lg={1}>
               <Radio
                   checked={challengeSubType === "Individual"}
                   onChange={handleChallengeSubType}
                   className={classes.root}
                   classes={{ root: classes.root }}
                   disableRipple
                   color="default"
                   value="Individual"
                   checkedIcon={
                     <span className={clsx(classes.icon, classes.checkedIcon)} />
                   }
                   icon={<span className={classes.icon} />}
                   disabled={params?.id ? true : false}
                 />
               </Grid>
               <Grid item con xs={3} lg={4}>
                 <Typography className={classes.radioTxt}>
                   {" "}
                   {intl.formatMessage({ id: "Individual" })}
                 </Typography>
               </Grid>
               <Grid item con xs={2} lg={1}>
               <Radio
                   checked={challengeSubType === "Group"}
                   onChange={handleChallengeSubType}
                   classes={{ root: classes.root }}
                   disableRipple
                   color="default"
                   value="Group"
                   checkedIcon={
                     <span className={clsx(classes.icon, classes.checkedIcon)} />
                   }
                   icon={<span className={classes.icon} />}
                   disabled={params?.id ? true : false}
                 />
               </Grid>
               <Grid item con xs={3} lg={4}>
                 <Typography className={classes.radioTxt}>
                   {" "}
                   {intl.formatMessage({ id: "Group" })}
                 </Typography>
               </Grid>
               {isMobile && <Grid xs={1} />}
             </Grid>
           </Grid>

           {challengeType === "Step Challenge" && (
             <Grid item container xs={12}>
               <Grid item container xs={12} md={7}>
                 <TextField
                   id="standard-multiline-static"
                   className={classes.txtField}
                   fullWidth
                   value={stepName}
                   disabled={params?.id ? true : false}
                   onChange={(e) => {
                     setStepError("");
                     setStepName(e.target.value);
                   }}
                   margin="normal"
                   label={intl.formatMessage({ id: "stepName" })}
                 />
               </Grid>
             </Grid>
           )}

{challengeType === "Solo Challenge" && (
             <Grid item container xs={12}>
               <Grid item xs={12} md={6}>
                 <Card
                   style={{
                     marginTop: "1rem",
                     backgroundColor: isMobile ? "" : "rgb(251, 250, 249)",
                   }}
                   elevation={0}
                 >
                   <AddImage image={image} />
                 </Card>
               </Grid>
             </Grid>
           )}

           <Grid item container xs={12} className={classes.top}>
           <Grid item xs={12} style={{ marginBottom: "5px" }}>
               {(update === "updated"
                 ? courseInfo?.episodes?.length > 1
                 : courseInfo?.episodes?.length > 0) &&
                 !params?.id && (
                   <Typography>
                     {intl.formatMessage({ id: "chooseEpisode" })}
                   </Typography>
                 )}
             </Grid>
             <Grid item xs={12} style={{ marginBottom: "5px" }}>
               {challengeType === "Step Challenge" &&
                 courseInfo?.episodes?.length > 1 && (
                   <Typography>
                     <span style={{ color: "red" }}>*</span>
                     {intl.formatMessage({ id: "stepChallengeRestriction" })}
                   </Typography>
                 )}
             </Grid>
             <Grid
               item
               container
               xs={12}
               md={11}
               spacing={1}
               className={isMobile && classes.scrollBar}
             >
              {console.log(courseInfo,"courseInfo")}
               {courseInfo &&
                 courseInfo?.episodes
                   ?.filter((ep) => ep?.status === "Active")
                   ?.map((courseEpisode) => {
                     if (
                       challengeType === "Step Challenge" &&
                       checkAlreadyAddedEpisode(courseEpisode)
                     ) {
                       return null;
                     }
                     return (
                       <Grid
                         item
                         xs={4}
                         key={courseEpisode?.id}
                         style={{
                          minWidth: "200px",
                          cursor:
                            params?.id ||
                            (challengeType === "Step Challenge" &&
                              stepChallengeEpLength !== "")
                              ? "default"
                              : "pointer",
                        }}
                        onClick={(e) =>
                          params?.id ? "" : selectEpisode(e, courseEpisode?.id)
                        }
                       >
                         <CourseEpisodeCard
                           id={courseEpisode?.id}
                           image={courseEpisode?.thumbnail}
                           name={courseEpisode?.name}
                           totalDuration={courseEpisode?.duration}
                           checkedStatus={courseEpisode?.checkedStatus}
                         />
                       </Grid>
                     );
                   })}
               <Grid item xs={4} />
             </Grid>
           </Grid>

           <Grid item container xs={12} className={classes.top}>
             <Grid item container xs={12} md={6} spacing={1}>
               <Grid item xs={8}>
               <Select
                   className={classes.selectCategory}
                   classes={{ icon: classes.dropIcon }}
                   value={category}
                   onChange={handleSelectCategory}
                   displayEmpty
                   disabled={params?.id ? true : false}
                 >
                     <MenuItem value="">
                     {intl.formatMessage({ id: "selectCategory" })}
                   </MenuItem>
                   {categoriesList?.map((category, ind) => {
                     if (!category?.parentCategoryId) {
                       return (
                         <MenuItem value={category?.name} key={ind}>
                           {category?.name}
                         </MenuItem>
                       );
                     }
                   })}
                 </Select>
               </Grid>
               <Grid item xs={4}>
               <Button
                   className={classes.selectCategory}
                   disabled={category === "" || params?.id ? true : false}
                   onClick={handleBrowse}
                   style={{ textTransform: "none" }}
                 >
                   Browse
                 </Button>
               </Grid>
             </Grid>
           </Grid>

           {challengeType === "Step Challenge" && (
             <Grid item container xs={12} className={classes.top}>
               <Grid item container xs={12} md={6}>
                 {update === "updated" ? (
                   <Button
                     style={{
                       background: "transparent",
                       border: "3px solid #fbae02",
                       textAlign: "center",
                       padding: "1.5rem",
                       fontFamily: theme.fontFamily.Roboto,
                       fontStyle: "normal",
                       fontWeight: "normal",
                       fontSize: "19px",
                       lineHeight: "22px",
                       color: "#0F0F0F",
                       width: "100%",
                     }}
                     disabled={params.id ? true : false}
                     onClick={updateChallengeStep}
                   >
                     {intl.formatMessage({ id: "update" })}
                   </Button>
                 ) : (
                   <Button
                    disabled={params.id ? true : false}
                     style={{
                       background: "transparent",
                       border: "3px solid #fbae02",
                       textAlign: "center",
                       padding: "1.5rem",
                       fontFamily: theme.fontFamily.Roboto,
                       fontStyle: "normal",
                       fontWeight: "normal",
                       fontSize: "19px",
                       lineHeight: "22px",
                       color: "#0F0F0F",
                       width: "100%",
                     }}
                     onClick={addChallengeStep}
                   >
                     + {intl.formatMessage({ id: "addStep" })}
                   </Button>
                 )}
               </Grid>
             </Grid>
           )}

           <Grid item xs={12} md={12}>
             <Typography style={{ color: "red", marginBottom: "30px" }}>
               {stepError}
             </Typography>
           </Grid>

           {challengeType === "Step Challenge" && (
             <>
               <Grid
                 item
                 xs={12}
                 className={classes.top}
                 style={{ marginBottom: "5px" }}
               >
                 {selectedChallengeEpisodes?.length > 1 && (
                   <Typography>
                     <span style={{ color: "red" }}>*</span>
                     {intl.formatMessage({ id: "dragTextChallenge" })}
                   </Typography>
                 )}
               </Grid>
               <Grid item xs={12} spacing={2}>
                 <Card style={{ backgroundColor: "#fbfbfb" }}>
                   <CardContent>
                     <Grid container style={{ textAlign: "center" }}>
                       <Grid
                         container
                         item
                         xs={12}
                         style={{
                           paddingBottom: "10px",
                           borderBottom: "1px solid black",
                         }}
                       >
                         <Grid
                           item
                           xs={1}
                           className={classes.challengeStepHeading}
                         >
                           Step
                         </Grid>
                         <Grid item xs={3}>
                           <Typography className={classes.challengeStepHeading}>
                             Step Name
                           </Typography>
                         </Grid>

                         <Grid item xs={3}>
                           <Typography className={classes.challengeStepHeading}>
                             Course Name
                           </Typography>
                         </Grid>

                         <Grid item xs={3}>
                           <Typography className={classes.challengeStepHeading}>
                             Episode Name
                           </Typography>
                         </Grid>

                         {!params?.id && (
                           <Grid item xs={2}>
                             <Typography
                               className={classes.challengeStepHeading}
                             >
                               Action
                             </Typography>
                           </Grid>
                         )}

                       </Grid>

                       {selectedChallengeEpisodes?.length > 0 ? (
                         <Grid
                           container
                           item
                           xs={12}
                           style={{ marginTop: "10px" }}
                         >
                           <ReactSortable
                             list={selectedChallengeEpisodes}
                             setList={setSelectedChallengeEpisodes}
                             style={{ width: "100%" }}
                             disabled={params.id ? true : false}
                           >
                             {selectedChallengeEpisodes?.map((step, index) => {
                               return (
                                 <Grid
                                   container
                                   item
                                   xs={12}
                                   style={{ marginTop: "10px", cursor: "grab" }}
                                   key={index}
                                 >
                                   <Grid item xs={1}>
                                     <Tooltip title="Grab" placement="top">
                                       <DragIndicator
                                         className={classes.challengeStepText}
                                         style={{ fontSize: "20px" }}
                                       />
                                     </Tooltip>
                                   </Grid>
                                   <Grid item xs={3}>
                                     <Typography
                                       className={classes.challengeStepText}
                                     >
                                       {step?.stepName}
                                     </Typography>
                                   </Grid>
                                   <Grid item xs={3}>
                                     <Typography
                                       className={classes.challengeStepText}
                                     >
                                       {step?.name}
                                     </Typography>{" "}
                                   </Grid>
                                   <Grid item xs={3}>
                                     <Typography
                                       className={classes.challengeStepText}
                                     >
                                       {step?.episodes &&
                                         step?.episodes[0]?.name}
                                     </Typography>
                                   </Grid>

                                   {!params?.id && (
                                     <Grid item xs={2}>
                                       {" "}
                                       <Typography className={classes.removeBtn}>
                                         <Edit
                                           onClick={(e) =>
                                             handleUpdateStep(
                                               e,
                                               step?.episodes &&
                                                 step?.episodes[0]?.id,
                                               index
                                             )
                                           }
                                         />{" "}
                                         <DeleteForever
                                           style={{ color: "#ff0000" }}
                                           onClick={(e) => removeStep(e, index)}
                                         />
                                       </Typography>
                                     </Grid>
                                   )}

                                 </Grid>
                               );
                             })}
                           </ReactSortable>
                         </Grid>
                       ) : (
                         <Grid item xs={12}>
                           {" "}
                           <Typography
                             style={{ marginTop: "30px" }}
                             className={classes.challengeStepText}
                           >
                             Added steps will display here
                           </Typography>
                         </Grid>
                       )}
                     </Grid>
                   </CardContent>
                 </Card>
               </Grid>
             </>
           )}

           <Grid item container xs={12}>
             <Grid item container xs={12} md={6}>
             <TextField
                 id="standard-multiline-static"
                 className={classes.txtField}
                 fullWidth
                 value={challengeTitle}
                 onChange={(e) => {
                   setError("");
                   setChallengeTitle(e.target.value);
                 }}
                 margin="normal"
                 label={intl.formatMessage({ id: "ChallengeTitle" })}
               />
             </Grid>
           </Grid>

           <Grid item container xs={12} md={6}>
             <Grid item xs={12}>
               <TextField
                 id="standard-multiline-static"
                 className={classes.description}
                 fullWidth
                 value={challengeDescription}
                 onChange={(e) => {
                   setError("");
                   setChallengeDescription(e.target.value);
                 }}
                 margin="normal"
                 rows="5"
                 multiline
                 label={
                   <span style={{ padding: "10px 0 0 10px" }}>
                     {intl.formatMessage({ id: "challenge" })}
                   </span>
                 }
               />
             </Grid>
           </Grid>

           <Grid item container xs={12} style={{ marginTop: "10px" }}>
             <Grid item xs={12} md={6}>
               <MuiPickersUtilsProvider utils={DateFnsUtils}>
                 <Grid container className={classes.date}>
                   <ThemeProvider theme={defaultMaterialTheme}>
                     <DatePicker
                       className={classes.calenderRoot}
                       margin="normal"
                       id="date-picker-dialog"
                       open={isOpen}
                       onOpen={() =>
                         isStarted ? setIsOpen(false) : setIsOpen(true)
                       }
                       onClose={() => setIsOpen(false)}
                       format="yyyy/MM/dd"
                       value={startDate}
                       minDate={
                         ((params?.id && !isStarted) || !params?.id) &&
                         new Date()
                       }
                       onKeyPress={(event, date) => {
                         handleKeypress(event, date);
                       }}
                       onChange={handleDateChange}
                       KeyboardButtonProps={{
                         "aria-label": "change date",
                       }}
                       InputProps={{
                         endAdornment: (
                           <InputAdornment position={"end"}>
                             <Icon>
                               <DateRange
                                 style={{
                                   cursor: "pointer",
                                   color: "#5E5E5E",
                                 }}
                                 onClick={() =>
                                   isStarted ? setIsOpen(false) : setIsOpen(true)
                                 }
                               />
                             </Icon>
                           </InputAdornment>
                         ),
                       }}
                     />
                   </ThemeProvider>
                 </Grid>
               </MuiPickersUtilsProvider>
             </Grid>
           </Grid>

           <Grid item container xs={12} className={classes.top}>
             <Grid item container xs={12} md={6}>
               <TextField
                 id="time"
                 classes={{ root: classes.timeLabel }}
                 label="Exercise Time"
                 type="time"
                 value={exerciseTime}
                 disabled={isStarted}
                 onChange={(e) => {
                   setError("");
                   setExerciseTime(e.target.value);
                 }}
                 InputLabelProps={{
                   shrink: true,
                 }}
                 inputProps={{
                   step: 300,
                 }}
               />
             </Grid>
           </Grid>
           <Grid item container xs={12} className={classes.top}>
             <Grid item container xs={12} md={6}>
               <Autocomplete
                 id="combo-box-demo"
                 options={timeZones}
                 fullWidth
                 className={classes.selectTimeZone}
                 onChange={(e, value) => {
                   setError("");
                   setTimeZone(value);
                 }}
                 disabled={isStarted}
                 value={timeZone || null}
                 getOptionLabel={(zone) =>
                   `(UTC ${moment.tz(zone).format("Z")})  ${zone}`
                 }
                 renderInput={(params) => (
                   <TextField
                     classes={{ root: classes.txtField }}
                     {...params}
                     placeholder="Select time zone"
                   />
                 )}
               />
             </Grid>
           </Grid>

           <Grid item container xs={12} className={classes.top}>
             <Grid item container xs={12} md={6} spacing={1}>
               <Grid
                 item
                 xs={6}
                 onClick={(e) => {
                   if (params?.id && isStarted) {
                     return null;
                   }
                   setError("");
                   setRepeatChallenge("Daily");
                 }}
               >
                 <RepeatChallengeCard
                   text={intl.formatMessage({ id: "Daily" })}
                   selected={repeatChallenge === "Daily" ? true : false}
                 />
               </Grid>
               <Grid
                 item
                 xs={6}
                 onClick={(e) => {
                   if (params?.id && isStarted) {
                     return null;
                   }
                   setRepeatChallenge("Weekly");
                 }}
               >
                 <RepeatChallengeCard
                   text={intl.formatMessage({ id: "Weekly" })}
                   selected={repeatChallenge === "Weekly" ? true : false}
                 />
               </Grid>
               {/* <Grid item xs={4} onClick={e => setRepeatChallenge("Monthly")}>
                 <RepeatChallengeCard
                   text={intl.formatMessage({ id: "Monthly" })}
                   selected={repeatChallenge === "Monthly" ? true : false}
                 />
               </Grid> */}
             </Grid>
           </Grid>

           {repeatChallenge !== "Daily" && (
             <Grid item container xs={12} className={classes.top}>
               <Grid
                 item
                 xs={12}
                 sm={12}
                 md={8}
                 lg={6}
                 xl={6}
                 container
                 justify="space-evenly"
               >
                 {["S", "M", "T", "W", "T", "F", "S"].map((value, ind) => {
                   return (
                     <Grid
                       item
                       key={ind}
                       onClick={handleCheckedDays(ind)}
                       style={{ cursor: "pointer" }}
                     >
                       <Typography style={{ textAlign: "center" }}>
                         {value}
                       </Typography>
                       {checkedDay?.indexOf(ind) !== -1 ? (
                         <CheckCircle className={classes.dayChecked} />
                       ) : (
                         <Brightness1 className={classes.dayUnChecked} />
                       )}
                     </Grid>
                   );
                 })}
               </Grid>
             </Grid>
           )}

           <Grid item container xs={12}>
             <Grid item container xs={12} md={6}>
               <TextField
                 id="standard-multiline-static"
                 type="number"
                 className={classes.txtField}
                 fullWidth
                 value={durationValue}
                 InputProps={{
                   inputProps: {
                     min: 0,
                   },
                 }}
                // disabled={isStarted}
                 InputLabelProps={{
                   shrink: true,
                 }}
                 onChange={(e) => {
                   setError("");
                   e.target.value > 0
                     ? setDurationValue(parseInt(e.target.value))
                     : setDurationValue("");
                 }}
                 margin="normal"
                 label={"Total Duration"}
               />
             </Grid>
           </Grid>

           <Grid item container xs={12}>
             <Grid item container xs={12} md={6}>
               <TextField
                 id="standard-multiline-static"
                 type="number"
                 className={classes.txtField}
                 fullWidth
                 value={recurringPeriod}
                 InputProps={{
                   inputProps: {
                     min: 1,
                     max: 365,
                   },
                 }}
                 disabled={isStarted}
                 InputLabelProps={{
                   shrink: true,
                 }}
                 onChange={(e) => {
                   setError("");
                   e.target.value > 0
                     ? setRecurringPeriod(e.target.value)
                     : setRecurringPeriod("");
                 }}
                 margin="normal"
                 label={intl.formatMessage({ id: "recurring" })}
               />
             </Grid>
           </Grid>

           <Grid item container xs={12} className={classes.top} spacing={1}>
             <Grid item xs={6} md={3}>
               <ChallengePartsCard
                 text={intl.formatMessage({ id: "prepTime" })}
                 handleSetTime={handleSetTime}
                 stepValue={prepDuration}
                 step="prepTime"
                 disabled={isStarted}
               />
             </Grid>
             {challengeType === "Step Challenge" && (
               <Grid item xs={6} md={3}>
                 <ChallengePartsCard
                   text={intl.formatMessage({ id: "restTime" })}
                   handleSetTime={handleSetTime}
                   stepValue={restDuration}
                   step="restDuration"
                   disabled={isStarted}
                 />
               </Grid>
             )}
           </Grid>

           <Grid item container xs={12} className={classes.top}>
             <Grid item container xs={12} md={6} spacing={1}>
               <Grid
                 item
                 xs={6}
                 onClick={(e) => {
                 /*   if (params?.id && isStarted) {
                     return null;
                   } */
                   setError("");
                   setPlanValue("free");
                 }}
               >
                 <RepeatChallengeCard
                   text={intl.formatMessage({ id: "Free" })}
                   selected={planValue === "free" ? true : false}
                 />
               </Grid>
               <Grid
                 item
                 xs={6}
                 onClick={(e) => {
                 /*   if (params?.id && isStarted) {
                     return null;
                   } */
                   setPlanValue("paid");
                 }}
               >
                 <RepeatChallengeCard
                   text={intl.formatMessage({ id: "Paid" })}
                   selected={planValue === "paid" ? true : false}
                 />
               </Grid>

               {planValue !== "free" && (
            <Grid item container xs={12}>
            <Grid item container xs={12} md={12}>
              <TextField
                id="standard-multiline-static"
                type="number"
                className={classes.txtField}
                fullWidth
                value={priceValue}
                InputProps={{
                  inputProps: {
                    min: 0,
                  },
                }}
              //  disabled={isStarted}
                InputLabelProps={{
                  shrink: true,
                }}
                required
                onChange={(e) => {
                  setError("");
                  e.target.value > 0
                    ? setPriceValue(e.target.value)
                    : setPriceValue(0);
                }}
                margin="normal"
                label={intl.formatMessage({ id: "price" })}
              />
            </Grid>
          </Grid>
           )}
               {/* <Grid item xs={4} onClick={e => setRepeatChallenge("Monthly")}>
                 <RepeatChallengeCard
                   text={intl.formatMessage({ id: "Monthly" })}
                   selected={repeatChallenge === "Monthly" ? true : false}
                 />
               </Grid> */}
             </Grid>
           </Grid>

           {challengeSubType === "Group" && (
             <Grid item xs={12} className={classes.top}>
               <ChallengeParticipants
                 handleAddFriendMode={handleAddFriendMode}
               />
             </Grid>
           )}

        <Grid item xs={12} md={6} className={classes.top}>
             <Button
               style={{ borderRadius: isMobile && "30px" }}
               className={classes.completeBtn}
               onClick={handleCreateChallenge}
             >
               {loader ? (
                 <CircularProgress size={15} color="primary" />
               ) : (params?.id || challengeReducerDetail?.id) ? (
                 intl.formatMessage({ id: "update" })
               ) : (
                 intl.formatMessage({ id: "complete" })
               )}
             </Button>
           </Grid>
           <Grid item xs={12} md={12}>
             <Typography style={{ color: "red", marginBottom: "30px" }}>
               {error}
             </Typography>
           </Grid>
         </>
       ) : (
         <>
           {isMobile ? (
             <>
               <Grid item container xs={12}>
                 <Grid item container xs={12} md={6}>
                   <InviteFriendsMobile
                     handleAddFriendMode={handleAddFriendMode}
                   />
                 </Grid>
               </Grid>
             </>
           ) : (
             <>
               <Grid item container xs={12}>
                 <Grid item container xs={12} md={6}>
                   <InviteFriends handleAddFriendMode={handleAddFriendMode} />
                 </Grid>
               </Grid>
             </>
           )}
         </>
       )}
     </Grid>
    ) : (
      <Grid container style={{marginTop:"150px", justifyContent:"center"}}>
       {!isMobile && <Error style={{  color: "#D8000C",}}/>}
        <Typography className={classes.errorText}>
          Create Challenge Access Denied
        </Typography>
      </Grid>

    )}
   {/*  <Dialog  open={openModel} onClose={handleClose} style={{width:"80%"}}>
            <DialogTitle>
                <Grid container justify="space-between">
                    <Grid item>
                        <Typography component="h4" variant="h5" >
                            Attention!
                </Typography>
                    </Grid>

                </Grid>
            </DialogTitle>
            <DialogContent style={{ display: 'flex', justifyContent: 'center' }}>
            <Grid container style={{ paddingBottom: "60px" }}>

      <Grid item xs={1} />
      <Grid item xs={1} />
      <Grid item xs={11} container>
        <Grid item xs={12} container>
          <Grid item lg={9} xs={12}>
            <Grid container>
              <Grid item xs={12} container spacing={3}>
                <Grid item xs={12}>
                  <Typography className={classes.heading}>
                    {`${category}  Courses`}
                  </Typography>
                </Grid>

                {categoryArr &&
                  categoryArr.filter(
                    item =>
                      (item?.status === 'Active')
                  ).length > 0 ? (categoryArr
                    .filter(
                      item =>
                        item?.status === 'Active'
                    )
                    .map((course, index) => {

                      return (
                        <Grid
                          item
                          xs={6}
                          md={4}
                          lg={4}
                          key={index}
                          onClick={(e) => courseClickHandler(e, course)}
                          style={{ marginTop: "5px" }}
                        >
                          <CourseCard
                            image={course?.image}
                            name={course?.name}
                            courseNameBold="true"
                          />
                        </Grid>
                      );
                  })
                ) : (
                  <Grid item xs={12}>
                    <Typography className={classes.noContent}>
                      No Course found
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </Grid>

            {authReducerState?.user.role !== "Regular" && (
              <Grid container>
                <Grid item xs={12} container>
                  <Grid item xs={12}>
                    <Typography className={classes.heading}>
                      User Uploaded Courses
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={12} container spacing={3}>
                  {category ? (
                    userUploadedCoursesList &&
                    userUploadedCoursesList.filter(
                      (item) =>
                        category === item.category?.name &&
                        item?.status === "Active" &&
                        !item?.isPrivate
                    ).length > 0 ? (
                      userUploadedCoursesList
                        .filter(
                          (item) =>
                            category === item.category?.name &&
                            item?.status === "Active" &&
                            !item?.isPrivate
                        )
                        .map((course, index) => {
                          const enrolledCourses = course;
                          return (
                            <Grid
                              item
                              xs={6}
                              md={4}
                              lg={4}
                              key={index}
                              onClick={(e) =>
                                courseClickHandler(e, enrolledCourses)
                              }
                              style={{ marginTop: "5px" }}
                            >
                              <CourseCard
                                image={enrolledCourses?.image}
                                name={enrolledCourses?.name}
                                courseNameBold="true"
                              />
                            </Grid>
                          );
                        })
                    ) : (
                      <Grid item xs={12}>
                        <Typography className={classes.noContent}>
                          No Enrolled Courses
                        </Typography>
                      </Grid>
                    )
                  ) : userUploadedCoursesList &&
                    userUploadedCoursesList.length > 0 ? (
                    userUploadedCoursesList.map((course, index) => {
                      const enrolledCourses = course;
                      return (
                        <Grid
                          item
                          xs={6}
                          md={4}
                          lg={4}
                          key={index}
                          onClick={(e) =>
                            courseClickHandler(e, enrolledCourses)
                          }
                        >
                          <CourseCard
                            image={enrolledCourses?.image}
                            name={enrolledCourses?.name}
                            courseNameBold="true"
                          />
                        </Grid>
                      );
                    })
                  ) : (
                    <Grid item xs={12}>
                      <Typography className={classes.noContent}>
                        No Record found
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
            </DialogContent>
            <DialogActions>

            </DialogActions>
        </Dialog> */}
    </>
  );
}

export default CreateChallenge;

const useStyles = makeStyles((theme) => ({
  root: {
    "&:hover": {
      backgroundColor: "transparent",
    },
    "& body": {
      fontFamily: theme.fontFamily.Roboto,
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "18px",
      lineHeight: "21px",
      color: "#3C4A6B",
    },
  },
  calenderRoot: {
    width: "100%",
    "& .MuiInputBase-root": {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: "16px",
      color: "#c9c9c9 !important",
      opacity: 0.78,
      "&::before": {
        borderBottom: "1px solid #c9c9c9",
      },
      "&::after": {
        borderBottom: "1px solid #c9c9c9",
      },
    },
    "& .MuiInputBase-root:hover:not(.Mui-disabled)": {
      "&::before": {
        borderBottom: "1px solid #c9c9c9",
      },
      "&::after": {
        borderBottom: "1px solid #c9c9c9",
      },
    },

    "& .MuiInput-underline": {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: "16px",
      color: "#c9c9c9 !important",
      opacity: 0.78,
      "&::before": {
        borderBottom: "1px solid #c9c9c9",
      },
      "&::after": {
        borderBottom: "1px solid #c9c9c9",
      },
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled)": {
      "&::before": {
        borderBottom: "1px solid #c9c9c9",
      },
      "&::after": {
        borderBottom: "1px solid #c9c9c9",
      },
    },
    "& .MuiIconButton-label-113": {
      color: "#FBAE02",
    },
  },
  date: {
    width: "100%",

    "& .MuiPickersDatePickerRoot-toolbar": {
      backgroundColor: "#FBAE02",
    },
  },
  icon: {
    borderRadius: "50%",
    width: 30,
    height: 30,
    boxShadow: "inset 0 0 0 1px #E5E5E5, inset 0 -1px 0 #E5E5E5",
    backgroundColor: "#E5E5E5",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "$root.Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: "#ebf1f5",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)",
    },
  },
  checkedIcon: {
    backgroundColor: "#E5E5E5",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 30,
      height: 30,
      backgroundImage: "radial-gradient(#F79E03,#F79E03 28%,transparent 32%)",
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: "#E5E5E5",
    },
  },
  radioTxt: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "21px",
    color: "#3C4A6B",
    marginTop: "12px",
  },
  errorText :{
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "22px",
    lineHeight: "21px",
    color: "#D8000C",
    marginTop: "2px",
  },

  selectCategory: {
    background: "#FBAE02 !important",
    height: "54px",
    width: "100%",
    textAlign: "center",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 500,
    borderRadius: "5px",
    fontSize: "14px",
    lineHeight: "16px",
    color: "#ffffff",
    paddingRight: "10px",
    "&.MuiInput-underline::before": {
      borderBottom: "none",
    },
    "&.MuiInput-underline:after": {
      borderBottom: "none",
    },
    "&.MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottom: "none",
    },
  },
  top: {
    marginTop: "35px",
  },
  description: {
    "& .MuiInputBase-input": {
      color: "#a9a9a9",
      padding: "10px 10px",
    },
    "& .MuiInputLabel-root": {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: "16px",
      color: "#a9a9a9",
      opacity: 0.78,
    },
    "& .MuiInput-underline": {
      "&::before": {
        borderBottom: "0px",
      },
      "&::after": {
        borderBottom: "0px",
      },
    },

    "& .MuiInput-underline:hover:not(.Mui-disabled)": {
      "&::before": {
        borderBottom: "0px",
      },
      "&::after": {
        borderBottom: "0px",
      },
    },
    "& .MuiInputBase-multiline": {
      border: "1px solid #FAAC02",
      borderRadius: "10px",
    },
  },
  txtField: {
    "& .MuiInputBase-input": {
      color: "#a9a9a9",
    },
    "& .MuiInputLabel-root": {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: "16px",
      color: "#a9a9a9",
      opacity: 0.78,
    },
    "& .MuiInput-underline": {
      "&::before": {
        borderBottom: "1px solid #c9c9c9",
      },
      "&::after": {
        borderBottom: "1px solid #c9c9c9",
      },
    },

    "& .MuiInput-underline:hover:not(.Mui-disabled)": {
      "&::before": {
        borderBottom: "1px solid #c9c9c9",
      },
      "&::after": {
        borderBottom: "1px solid #c9c9c9",
      },
    },
  },
  daysList: {
    boxShadow: "border-box",
    display: "flex",
    justifyContent: "space-evenly",
    "& *": {
      display: "inline",
      textAlign: "center",
      cursor: "pointer",
      padding: 0,
    },
  },
  dayChecked: {
    height: "40px",
    width: "40px",
    color: "#fbae02",
  },

  dayUnChecked: {
    height: "40px",
    width: "40px",
    color: "#efefef",
  },
  scrollBar: {
    flexWrap: "unset",
    overflowX: "auto",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  completeBtn: {
    width: "100%",
    height: "3.0em",
    background: "linear-gradient(180deg, #FFC000 -13.24%, #F28705 100%)",
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "20px",
    lineHeight: "23px",
    color: "#FFFFFF",
    borderRadius: "11px",
    textTransform: "none",
    marginTop: "30px",
  },
  dropIcon: {
    color: "#FFF",
  },
  challengeStepText: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "16px",
    color: "#a9a9a9",
    opacity: 0.78,
  },
  challengeStepHeading: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 900,
    fontSize: "16px",
    lineHeight: "16px",
    color: "#000000",
    opacity: 0.78,
  },
  removeBtn: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "16px",
    opacity: 0.78,
    cursor: "pointer",
  },
  timeLabel: {
    width: "100%",
    "& .MuiInputBase-input": {
      color: "#a9a9a9",
    },
    "& .MuiInputLabel-shrink": {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: "16px",
      color: "#a9a9a9",
      opacity: 0.78,
    },
    "& .MuiInput-underline:after": {
      borderBottom: "1px solid #c9c9c9 !important",
    },

    "& .MuiInput-underline:before": {
      borderBottom: "1px solid #c9c9c9 !important",
    },
  },
  selectTimeZone: {
    width: "100%",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 500,
    borderRadius: "5px",
    fontSize: "14px",
    color: "#a9a9a9",
    "& .MuiInputLabel-root": {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: "16px",
      color: "#a9a9a9",
      opacity: 0.78,
    },
    "&.MuiInput-underline::before": {
      borderBottom: "1px solid #c9c9c9",
    },
    "&.MuiInput-underline:after": {
      borderBottom: "1px solid #c9c9c9",
    },
    "&.MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottom: "1px solid #c9c9c9",
    },
  },
  timeZoneLabel: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "11px",
    lineHeight: "20px",
    color: "#a9a9a9",
    opacity: 0.78,
  },
}));
