import React, { useState } from "react";
import { Grid, useMediaQuery, Card, Typography } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import ChallengeParticipantCard from "./ChallengeParticipantCard";

function ChallengeParticipants(props) {
  const classes = useStyles();
  const theme = useTheme();
  const challengeParticipants = useSelector(
    (state) => state?.challenges?.challengeParticipants
  );
  return (
    <Grid container spacing={1}>
      <Grid item xs={2} sm={1}>
        <Card
          className={classes.addParticipantContainer}
          onClick={props.handleAddFriendMode}
        >
          <Grid container>
            <Grid item xs={12}>
              <Typography className={classes.addIcon}>
                {" "}
                <Add />
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.subText}>
                {challengeParticipants.length > 0 &&
                  challengeParticipants.length}{" "}
                Friends
              </Typography>
            </Grid>
          </Grid>
        </Card>
      </Grid>
      <Grid
        item
        container
        xs={10}
        sm={11}
        className={classes.hideScrollBar}
        spacing={1}
      >
        {challengeParticipants.length > 0 &&
          challengeParticipants.map((friend) => {
            return (
              <Grid item xs={3} className={classes.imageContainer}>
                <ChallengeParticipantCard friend={friend} />
              </Grid>
            );
          })}
      </Grid>
    </Grid>
  );
}

export default ChallengeParticipants;

const useStyles = makeStyles((theme) => ({
  subText: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "italic",
    fontSize: "11px",
    fontWeight: "bold",
    color: "#fff",
    textAlign: "center",
  },
  addIcon: {
    color: "#FFF",
    fontSize: "27px",
    textAlign: "center",
    marginTop: "30px",
  },
  addParticipantContainer: {
    background: " #FAAB02",
    boxShadow: "0px 4px 12px rgba(173, 173, 173, 0.05)",
    borderRadius: "11px",
    height: "113px",
    display: "flex",
    justifyContent: "center",
    cursor: "pointer",
  },
  imageContainer: {
    maxWidth: "105px",
    display: "flex",
    justifyContent: "centre",
    alignItems: "center",
  },
  hideScrollBar: {
    flexWrap: "unset",
    overflowX: "auto",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  addFriendTitle: {
    fontFamily: theme.fontFamily.Reef,
    fontStyle: "normal",
    fontSize: "25px",
    paddingTop: "39px",
    paddingBottom: "25px",
    textAlign: "center",
    color: "#fff",
  },
  containerStyleAdd: {
    padding: "0px",
    height: "8rem",
    width: "80px",
    boxShadow: "0px 4px 12px rgba(173, 173, 173, 0.05)",
    borderRadius: "11px",
    justifyContent: "space-around",
  },
  containerStyle: {
    padding: "0px",
    height: "8rem",
    justifyContent: "space-around",
  },
  friendsRootMobile: {
    width: "100px",
    maxHeight: "260px",
    borderRadius: "11px",
    backgroundColor: "#FFF",
    boxShadow: " 0px 4px 4px rgba(0, 0, 0, 0.05)",
  },
}));
