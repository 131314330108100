// types
import {
  GET_USER_CHAT,
  GET_CHAT_MESSAGES,
  UPLOADED_CHAT_MEDIA,
  SELECTED_CHAT_USER,
  SELECTED_ROOM_ID,
  GROUP_CHAT_MESSAGES,
  NEW_MESSAGE,
  UPLOADED_CHAT_MEDIA_PROGRESS,
  CHAT_LOADER,
  CHAT_MESSAGES_LOADER
} from "../actions/types";

// initial state
const initialState = {
  chats: [],
  chatMessages: [],
  groupChatMessages: [],
  selectedChatUser: {},
  uploadedMediaUrl: null,
  selectedRoomId: null,
  mediaUploadProgress: 0,
  chatLoader:false,
  chatMessagesLoader:false,
  
};

export default function(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_USER_CHAT:
      return {
        ...state,
        chats: payload
      };
    case NEW_MESSAGE:
      return {
        ...state,
        chatMessages:[...state.chatMessages, payload]
      };
    case GET_CHAT_MESSAGES:
      return {
        ...state,
        chatMessages: payload
      };
    case  GROUP_CHAT_MESSAGES:
      return {
        ...state,
        groupChatMessages: payload
      };
    case UPLOADED_CHAT_MEDIA:
      return {
        ...state,
        uploadedMediaUrl: payload?.chatMediaUrl
      };
    case SELECTED_CHAT_USER:
      return {
        ...state,
        selectedChatUser: payload
      };
    case SELECTED_ROOM_ID:
      return {
        ...state,
        selectedRoomId: payload
      };
    case UPLOADED_CHAT_MEDIA_PROGRESS:
      return {
        ...state,
        mediaUploadProgress: payload
      };
    case CHAT_LOADER:
      return {
        ...state,
        chatLoader: payload
      };
    case CHAT_MESSAGES_LOADER:
      return {
        ...state,
        chatMessagesLoader: payload
      };
    default:
      return state;
  }
}
