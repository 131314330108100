import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import {
  Card,
  Typography,
  Avatar,
  Grid,
  CardContent,
  Chip,
  Divider,
  Button
} from "@material-ui/core";
import { useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import moment from 'moment'

function InstructorIntroCardMobileView(props) {
  const intl = useIntl();
  const classes = useStyles();
  const [expand, setExpand] = useState(false);
  const [expandIntro, setExpandIntro] = useState(false);
  const authReducerState = useSelector(state => state.auth);
  const authUser = useSelector(state => state.user?.user);
  let courseDetail = useSelector(state => state?.courses?.courseDetail);

  const expandDescription = () => {
    setExpand(!expand);
  };

  const hexToRGB = (hex, alpha) => {
    var r = parseInt(hex && hex.slice(1, 3), 16),
      g = parseInt(hex && hex.slice(3, 5), 16),
      b = parseInt(hex && hex.slice(5, 7), 16);

    if (alpha) {
      return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
    } else {
      return "rgb(" + r + ", " + g + ", " + b + ")";
    }
  };

  let { instructor, tags, remarks } = props;

  return (
    <Card className={classes.root}>
      <CardContent>
        <Grid container>
          <Grid item xs={4}>
            <Avatar
              alt=""
              src={instructor?.pictureUrl}
              className={classes.large}
            >
              {instructor?.name.charAt(0).toUpperCase()}
            </Avatar>
          </Grid>
          <Grid item xs={1} />
          <Grid item xs={7}>
            <Typography
              className={`${classes.instructorIntroText} ${classes.instructorIntroTextSubTitle}`}
            >
              {instructor?.description && <div dangerouslySetInnerHTML={{ __html: unescape(instructor?.description) }} />}

            </Typography>
          </Grid>

          <Grid item xs={4} style={{ textAlign: "center", paddingTop: "5px" }}>
            <Typography
              className={`${classes.instructorIntroText} ${classes.instructorIntroTextTitle}`}
            >
              {instructor?.name}
            </Typography>
          </Grid>
          <Grid item xs={1} />

          <Grid item xs={7}>
            <Link to={`/view-profile/${instructor?.id}`} style={{ textDecoration: 'none' }}>
              <Button className={classes.visitProfileBtn} variant="contained">
                <Typography className={classes.visitProfileBtnTxt}>
                  Visit Profile
                </Typography>
              </Button>
            </Link>
          </Grid>
          <Grid item xs={12}>
            <Divider
              style={{ height: "1px solid #C2C2C2", marginTop: "20px" }}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography
              className={`${classes.instructorIntroText} ${classes.instructorIntroTextTitle}`}
              style={{ paddingTop: "20px" }}
            >
              Description
            </Typography>
            <Typography
              className={`${classes.instructorIntroText} ${classes.instructorIntroTextSubTitle}`}
            >
              {remarks && <div dangerouslySetInnerHTML={{ __html: unescape(remarks) }} />}
            </Typography>


            {tags?.length > 0 && <Typography
              className={`${classes.instructorIntroText} ${classes.instructorIntroTextTitle}`}
              style={{ paddingTop: "10px" }}
            >
              Course Tags
            </Typography>}
            <Typography
              className={`${classes.instructorIntroText} ${classes.instructorIntroTextSubTitle}`}
            >
              {tags?.map((tag, ind) => {
                return (
                  <Chip
                    label={tag}
                    key={ind}
                    className={`${classes.instructorIntroText}  ${classes.courseTags}`}
                    style={{
                      fontSize: "14px",
                      color: "#FFF",
                      marginTop: '4px',
                      background: `linear-gradient(360deg, ${hexToRGB(
                        props?.category?.color1,
                        1
                      )} 30%, ${hexToRGB(props?.category?.color2, 1)} 90%)`,

                    }}
                  />
                );
              })}
            </Typography>
          </Grid>

        </Grid>
      </CardContent>
    </Card>
  );
}

export default InstructorIntroCardMobileView;

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    minHeight: "19.750em",
    boxShadow: "none",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat"
  },
  instructorIntroText: {
    fontFamily: theme.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: 900
  },
  instructorIntroTextTitle: {
    fontSize: "20px",
    lineHeight: "27px",
    color: "#1B1B1B"
  },
  instructorIntroTextSubTitle: {
    fontSize: "16px",
    lineHeight: "25px",
    color: "#B0B0B0",
    letterSpacing: "0.015em",
    padding: "10px 0"
  },
  courseTags: {
    background:
      "linear-gradient(180deg, rgba(123, 146, 166, 0.95) 0%, rgba(21, 57, 89, 0.95) 100%)",
    borderRadius: "60px",
    transform: "rotate(3600deg)",
    marginRight: "3px"
  },
  large: {
    width: "109px",
    height: "109px"
  },
  visitProfileBtn: {
    background: "#09A9BB !important",
    borderRadius: "60px",
    width: "100%",
    height: "37px",
    textTransform: "none"
  },
  visitProfileBtnTxt: {
    fontFamily: theme.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: 900,
    fontSize: "14px",
    lineHeight: "19px",
    color: "#FFF"
  },
  readMore: {
    fontSize: '12px',
    cursor: 'pointer',
    color: '#84CDF0',
    '&:hover': {
      color: '#84CDF0',
    }
  },

}));
