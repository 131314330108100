// types
import {
  ADD_PARTICIPANTS_IN_CHALLENGE,
  GET_ALL_CHALLENGES,
  GET_CHALLENGE_BY_ID,
  CREATE_CHALLENGE,
  CREATE_CHALLENGE_DATA,
  GET_CHALLENGE_STATS,
  JOIN_CHALLENGS,
  IS_JOINED,
  GET_RECENT_CHALLENGES,
  ADD_ID,
} from "../actions/types";

// initial state
const initialState = {
  challengeParticipants: [],
  allChallenges: [],
  challengeDetail: {},
  createChallengeData: {},
  challengesStats: {},
  joined: false,
  id: -1,
  type: "",
  imageUrl:"",
  recentChallenges:[]
};

// handle actions
export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case ADD_ID: {
      return {
        ...state,
        id: payload.id,
        type: payload.type,
        imageUrl:payload.imageUrl
      };
    }

    case IS_JOINED: {
      return {
        ...state,
        joined: payload,
      };
    }
    case JOIN_CHALLENGS: {
      if (state?.challengeDetail?.challengeParticipants) {
        let c = state?.challengeDetail?.challengeParticipants;
        c = [...c, payload.user];
        let s = state?.challengeDetail;
        s["challengeParticipants"] = c;
        return {
          ...state,
          challengeDetail: s,
          joined: payload.joined,
        };
      }
    }
    case ADD_PARTICIPANTS_IN_CHALLENGE:
      return {
        ...state,
        challengeParticipants: payload,
      };
    case CREATE_CHALLENGE_DATA:
      console.log(payload,"******")
      if (payload.course) {
        return {
          ...state,
          createChallengeData: {
            ...state.createChallengeData,
            courseInfo: payload.courseInfo,
          },
        };
      } else {
        return {
          ...state,
          createChallengeData: payload,
        };
      }
    case GET_ALL_CHALLENGES:
      return {
        ...state,
        allChallenges: payload,
      };
    case GET_RECENT_CHALLENGES:
      return {
        ...state,
        recentChallenges: payload,
      };

    case GET_CHALLENGE_BY_ID:
      return {
        ...state,
        challengeDetail: payload,
      };
    case CREATE_CHALLENGE:

      return {
        ...state,
        allChallenges: [...state.allChallenges, { challenge: payload }],
      };
    case GET_CHALLENGE_STATS:
      return {
        ...state,
        challengesStats: payload,
      };

    default:
      return state;
  }
}
