import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Grid, DialogContent, Avatar, Dialog, DialogActions, Button, Modal, Typography, } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import { useSelector, useDispatch } from "react-redux";
import ProfileInfoForm from '../../../../components/Profile/ProfileInfoFormWeb'
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

import { uploadPic } from '../../../../actions/user'
import { makeStyles, useTheme } from "@material-ui/core/styles";
import './profileInfo.scss'
import { useIntl } from "react-intl";

const pixelRatio = 4;

function getResizedCanvas(canvas, newWidth, newHeight) {
    const tmpCanvas = document.createElement("canvas");
    tmpCanvas.width = newWidth;
    tmpCanvas.height = newHeight;

    const ctx = tmpCanvas.getContext("2d");
    ctx.drawImage(
        canvas,
        0,
        0,
        canvas.width,
        canvas.height,
        0,
        0,
        newWidth,
        newHeight
    );

    return tmpCanvas;
}



function EditProfileInfo(props) {
    const classes = useStyles();
    const dispatch = useDispatch()
    const intl = useIntl();
    const theme = useTheme();

    const [ProfileImage, setProfileImage] = useState('')
    const [coverImage, setCoverImage] = useState('')
    const authReducerState = useSelector(state => state.auth);
    const [openDialog, setOpenDialog] = React.useState(false);
    const user = useSelector(state => state.user?.user);
    const [open, setOpen] = useState(false);
    const [upImg, setUpImg] = useState();
    const imgRef = useRef(null);
    const previewCanvasRef = useRef(null);
    const [crop, setCrop] = useState({ unit: "%", width: 50, aspect: 16 / 9 });
    const [cropProfilePic, setCropProfilePic] = useState({ unit: "%", width: 30, aspect: 1 });
    const [completedCrop, setCompletedCrop] = useState(null);
    const [imageType, setImageType] = useState('');
    const [modalStyle] = useState(getModalStyle);

    function getModalStyle() {
        const top = 50;
        const left = 50;
        return {
            borderColor: theme.colors.white,
            borderRadius: "4px",
            top: `${top}%`,
            left: `${left}%`,
            transform: `translate(-${top - 4}%, -${left}%)`,
        };
    }

    function Upload(previewCanvas, crop) {
        if (!crop || !previewCanvas) {
            return;
        }

        const canvas = getResizedCanvas(previewCanvas, crop.width, crop.height);
        canvas.toBlob(
            blob => {
                const previewUrl = window.URL.createObjectURL(blob);

                if (imageType === 'cover') {
                    setCoverImage(previewUrl)
                    const formData = new FormData()
                    formData.append("cover", blob);
                    dispatch(uploadPic(formData, 'uploadCover', authReducerState?.token));
                    setOpenDialog(false)

                } else if (imageType === 'profile') {
                    setProfileImage(previewUrl)
                    const formData = new FormData()
                    formData.append("picture", blob);
                    dispatch(uploadPic(formData, 'uploadPicture', authReducerState?.token));
                    setOpenDialog(false)

                }
            },
            "image/png",
            1
        );
    }

    const onSelectFile = (e, type) => {
        if (e.target.files && e.target.files.length > 0) {
            setImageType(type)
            setOpenDialog(true)
            const reader = new FileReader();
            reader.addEventListener("load", () => setUpImg(reader.result));
            reader.readAsDataURL(e.target.files[0]);
        }
    };

    const onLoad = useCallback(img => {
        imgRef.current = img;
    }, []);

    useEffect(() => {
        if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
            return;
        }
        console.log("in use effect", completedCrop);
        const image = imgRef.current;
        const canvas = previewCanvasRef.current;
        const crop = completedCrop;

        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        const ctx = canvas.getContext("2d");

        canvas.width = crop.width * pixelRatio;
        canvas.height = crop.height * pixelRatio;

        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingEnabled = false;

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );
    }, [completedCrop]);


    const handleClose = () => {
        setOpenDialog(false);
    };

    useEffect(() => {
        setProfileImage(user?.pictureUrl)
        setCoverImage(user?.coverUrl)
        if (user && user.id && (!user?.pictureUrl || !user?.coverUrl)) {

            setOpen(true);
        }
    }, [user?.pictureUrl, user?.coverUrl])

    return (
        <Grid container direction='row'>
            <Modal
                disableEnforceFocus={true}
                open={open}
                onClose={() => setOpen(false)}>
                <Grid
                    container
                    direction="row"
                    spacing={3}
                    style={modalStyle}
                    className={classes.paper}>
                    <Grid item className={classes.msgBody}>
                        <Typography className={classes.modelBodyText}>
                            {intl.formatMessage({ id: "completeProfileMessage" })}
                        </Typography>
                    </Grid>
                    <Grid item className={classes.modalBtnGrid}>
                        <Button onClick={() => setOpen(false)} className={classes.modelBtn}>
                            {intl.formatMessage({ id: "okay" })}
                        </Button>
                    </Grid>
                </Grid>
            </Modal>

            <Grid item sm={12}>
                <Dialog
                    open={openDialog}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    maxWidth={'md'}
                    fullWidth={true}
                >

                    <DialogContent>
                        <div className="App">
                            <Grid container >
                                <Grid container item xs={12} sm={12} md={8} lg={6} xl={6} >
                                    <ReactCrop
                                        src={upImg}
                                        onImageLoaded={onLoad}
                                        crop={imageType === 'cover' ? crop : cropProfilePic}
                                        onChange={c => imageType === 'cover' ? setCrop(c) : setCropProfilePic(c)}
                                        onComplete={c => setCompletedCrop(c)}
                                        circularCrop={imageType === 'cover' ? false : imageType === 'profile' ? true : ''}

                                    />
                                </Grid>
                                <Grid container item xs={12} sm={12} md={8} lg={6} xl={6} >

                                    <div >
                                        <Grid container >
                                            <Grid container item xs={12} sm={12} md={8} lg={6} xl={6} style={{ height: '280px', width: '100%' }}>
                                                <canvas
                                                    ref={previewCanvasRef}
                                                    style={{
                                                        width: '100%',
                                                        height: '290px',
                                                        borderRadius: imageType === 'cover' ? '' : imageType === 'profile' ? '50%' : ''
                                                    }}

                                                />
                                            </Grid>
                                        </Grid>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            Cancel
                        </Button>
                        <Button
                            type="button"
                            disabled={!completedCrop?.width || !completedCrop?.height}
                            onClick={() =>
                                Upload(previewCanvasRef.current, completedCrop)
                            }
                            color="primary" autoFocus
                        >
                            Done
                        </Button>
                    </DialogActions>
                </Dialog>
            </Grid>
            <Grid item container direction='row' sm={8} md={4} lg={4} xl={4}>
                <Grid item sm={12}>
                    <div className={classes.basicInfoBox}
                        style={{ backgroundImage: `url(${coverImage}),linear-gradient(180deg, rgba(255, 255, 255, 0.23) 0%, rgba(0, 0, 0, 0.23) 39.58%, #000000 100%)`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'noRepeat' }}>
                        <Grid container direction='row'>

                            <Grid item xs={12}>
                                <label className='profile_image'>
                                    <div className='edit' >
                                        <Edit style={{ color: '#FFF', marginTop: '7px' }} />

                                    </div>
                                    <input
                                        className='profile_image_input'
                                        className={classes.profilePic}
                                        type='file'
                                        accept='image/*'
                                        onClick={(e) => { e.target.value = '' }}
                                        onChange={event => onSelectFile(event, "cover")}
                                    />
                                </label>
                            </Grid>


                        </Grid>

                    </div>
                    <Grid item sm={12} container justify='center'>

                        <div style={{ marginTop: '-50px', justifyContent: 'center' }}>
                            <label className='profile_image'  >
                                <Edit style={{ position: 'absolute', textAlign: 'center', color: '#FFF', zIndex: 100, marginTop: '75px', paddingLeft: '35px' }} />
                                <input
                                    className='profile_image_input'
                                    type='file'
                                    accept='image/*'
                                    onChange={event => onSelectFile(event, "profile")}
                                    onClick={(e) => { e.target.value = '' }}
                                />
                            </label>
                            <Avatar src={ProfileImage} className='userPhotoWeb' style={{ margin: '15px auto', height: '90px', width: '90px', zIndex: 2 }} />
                        </div>
                    </Grid>

                </Grid>


                <Grid item container direction='row' sm={12}>
                    <Grid item sm={1} />
                    <Grid item sm={10} style={{ marginTop: '-10px' }}>
                        <ProfileInfoForm />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item sm={4}></Grid>
            <Grid item sm={4}></Grid>
        </Grid>
    );
}

export default EditProfileInfo;

const useStyles = makeStyles(theme => ({
    basicInfoBox: {
        width: '100%',
        height: '14.438em',
        borderRadius: '11px',
        backgroundImage: 'linear-gradient(180deg, rgba(255, 255, 255, 0.23) 0%, rgba(0, 0, 0, 0.23) 39.58%, #000000 100%)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        boxSizing: 'border-box',
        backgroundPosition: 'center',
    },
    heroImageStudioName: {
        fontFamily: theme.fontFamily.Roboto,
        fontStyle: 'italic',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '16px',
        color: '#FFFFFF',
        marginTop: '15px'
    },

    userImage: {
        zIndex: 3,
        margin: 'auto',


    },
    paper: {
        position: "absolute",
        height: 330,
        maxWidth: 315,
        minWidth: 300,
        justifyContent: "center",
        backgroundColor: theme.palette.background.paper,
        outline: "none",
    },
    msgBody: {
        minHeight: "240px",
        alignItems: "center",
        display: "flex",
    },
    modelBodyText: {
        fontFamily: theme.fontFamily.Roboto,
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "17px",
        lineHeight: "27px",
        textAlign: "center",
        letterSpacing: "0.02em",
        color: "#192247",
    },
    modelBtn: {
        height: "73px",
        width: "100%",
        fontFamily: theme.fontFamily.Roboto,
        cursor: "pointer",
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "18px",
        lineHeight: "21px",
        color: theme.colors.white,
    },

    modalBtnGrid: {
        padding: "0px",
        alignSelf: "flex-end",
        color: "#fff",
        cursor: "pointer",
        background: `linear-gradient(#09A9BB 1%,#09A9BB 100%)`,
        height: "73px",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
    },
}))


