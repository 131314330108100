import React,{useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Popper from '@material-ui/core/Popper';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Add from '@material-ui/icons/Add';

import Close from '@material-ui/icons/Close';

import { Button, Card, CardContent, CircularProgress, Divider, Grid, TextField } from '@material-ui/core';
import FileCard from './FileCard'

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        display: 'inline'

    },
    typography: {
        padding: theme.spacing(2),
    },
    button: {
        background: '#FBAE02 !important',
        boxShadow: '0px 2px 2px rgba(216, 118, 0, 0.18)',
        color: "#FFF",
        height: "40px",
        width: '40px',
    },
    heading: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '20px',
        lineHeight: '100%',
        color: ' #666666',
        paddingBottom: '30px'
    },
    closeIcon: {
        backgroundColor: "#F1F1F4",
        color: '#E54D40',
        height: '25px',
        width: '25px',
        '&:hover': {
            backgroundColor: "#F1F1F4",
            color: '#E54D40',
        }

    },
    browsButtonContainer: {
        background: '#F1F1F1',
        borderRadius: '11px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '80px',
        minWidth: '120px'
    },
    browsButton: {
        color: '#fff',
        background: '#FBAE02',
        borderRadius: '20px',
        height: '30px',
        padding: '0px 50px',
        minWidth: '30px',
        '&:hover': {
            color: '#fff',
            background: '#FBAE02',
        }
    },
    publishButton: {
        color: '#fff',
        background: '#FBAE02',
        borderRadius: '11px',
        width: '100%',
        padding: '12px 0px',
        marginTop: '30px',
        '&:hover': {
            color: '#fff',
            background: '#FBAE02',
        }
    },
    profile_image: {
        "& input[type='file']": {
            display: 'none',
        }
    },
    dateAndTime: {
        marginTop: '10px',
        marginBottom: '10px',
        color: '#FBAE02',
        width: '98%',
        border: '1px solid #E5E5E5 !important',
        borderRadius: '11px',
        '& label.Mui-focused': {
            color: 'white',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#FBAE02',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#666666',
            },
            '&:hover fieldset': {
                borderColor: '#666666',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#FBAE02',
            },
        },
    },
    checkedHeading: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '25px',
        color: '#C4C4C4',
    },
    uncheckedHeading: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '25px',
        color: '#666666',
    },
    checkedIcon: {
        color: '#2A4A67',
        justifyContent: 'center'

    },
    unCheckedIcon: {
        color: '#D7D7D7',
        fontSize: '30px',
        justifyContent: 'center'
    },
    center: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    centerHorizontal: {
        display: 'flex',
        alignItems: 'center'
    },
    error:{
        color: '#E54D40',
        fontSize: '12px',
    }

}));



export default function AddAssignment(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [open, setOpen] = React.useState(false);
    const [fileLoader, setFileLoader] = React.useState(false);

    const [fileName, setFileName] = React.useState('');
    const [file, setFile] = React.useState('');
    const [error, setError] = React.useState('');
    const [placement, setPlacement] = React.useState();

    const classes = useStyles();


    const handleClick = (newPlacement) => (event) => {
        setAnchorEl(event.currentTarget);
        setOpen((prev) => placement !== newPlacement || !prev);
        setPlacement(newPlacement);
    };
    const handleSelectFile = (event) => {
        let localFile = event.target.files && event.target.files[0]
        setError('')
        setFile(localFile);
        setFileName(localFile.name.match(/[^\.]+/)[0]);

    };

    const clickCrossHandler = () => {
        setFile('');
        setFileName('');
    };

    const closeDialog = () => {
        setFile('');
        setFileName('');
        setOpen(false)
    };

    const addFileHandler = () => {
        if (!fileName) {
            return setError('file name is required!')
        } else if (!file) {
            return setError('supported file is required!')
        } else {
            const fileLocal = file;
            const fileNameLocal = fileName;
            let fileType = file.type.split('/')[0];
             fileType = fileType ==='image' ? 'image' : /^.+\.([^.]+)$/.exec(file?.name)[1] || ''
            return props.handleAddFile({ file: fileLocal, sectionId: props?.section?.id, size: fileLocal.size, type: fileType, fileName: fileNameLocal })
        }

    };

    useEffect(() => {
        if (!fileLoader && props?.loader) {
            setFileLoader(true)
        } else if (fileLoader && !props?.loader) {
            setFileLoader(false)
            closeDialog()
        }
    }, [props?.loader])

    return (
        <div className={classes.root}>
            <Popper open={open} anchorEl={anchorEl} placement={'right-start'} transition>
                <Card style={{ maxWidth: '300px' }}>
                    <CardContent>
                        <Grid container justify='flex-end'>
                            <Grid item xs={2} md={1}>
                                <IconButton className={classes.closeIcon} onClick={closeDialog}><Close /></IconButton>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography className={classes.heading}>
                                    Upload File
                                </Typography>
                            </Grid>
                            <Grid item xs={12} >
                                <TextField
                                    id="datetime-local"
                                    type="text"
                                    placeholder='File Name'
                                    className={classes.textField}
                                    variant='outlined'
                                    fullWidth
                                    classes={{ root: classes.dateAndTime }}
                                    value={fileName}
                                    onChange={(e) =>{setError(''); setFileName(e.target.value)}}
                                />


                            </Grid>
                            {file ? <FileCard file={file} type={/^.+\.([^.]+)$/.exec(file?.name)[1] || ''} fileName={fileName} clickCrossHandler={clickCrossHandler} /> : <Grid item xs={12} className={classes.browsButtonContainer}>
                                <Button className={classes.browsButton}
                                    variant="contained"
                                    component="label" >
                                    Upload File
                                       <input
                                        type="file"
                                        hidden
                                        onChange={handleSelectFile}
                                        accept="image/*, .pdf,.docx, .doc"
                                    />
                                </Button>
                            </Grid>}
                            <Grid item xs={12}>
                                <Button className={classes.publishButton} onClick={addFileHandler}>{fileLoader? <CircularProgress size={20} style={{color:'#fff'}}/> : 'Add' }</Button>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography className={classes.error}>
                                    {error}
                                </Typography>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>

            </Popper>



            <IconButton
                onClick={handleClick('right-start')}
                className={classes.button}>
                <Add />
            </IconButton>

        </div>
    );
}