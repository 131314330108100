import { useState } from 'react';
import { CardContent, Grid, Card } from '@material-ui/core'
import { makeStyles } from "@material-ui/core/styles";
import TopBar from "../../../components/TrainingRetreat/TopBarMobile";
import PlayTrainingVideoCard from '../../../components/TrainingRetreat/PlayTrainingVideo'
import ContentSection from '../../../components/TrainingRetreat/ContentSection'


function Retreat(props) {
    const classes = useStyles();
    const [detail, setDetail] = useState(false);



    return (
        <Grid container className={classes.container}>
            <Grid item xs={12} container >
                <Grid item xs={12} style={{ borderBottom: '2px solid #E2E2E2', marginBottom: '90px' }}>
                    <TopBar detailHandler={() => setDetail(!detail)} detail={detail} />
                </Grid>

                <Grid item xs={12} container >

                    <Grid item xs={12} >
                        <PlayTrainingVideoCard detail={detail} switch={() => setDetail(false)} />
                    </Grid>

                    {!detail && <Grid item xs={12} style={{ marginTop: '15px' }}>
                        <Card className={classes.card}>
                            <CardContent style={{ paddingTop: '5px' }}>
                                <ContentSection />
                            </CardContent>
                        </Card>
                    </Grid>}

                </Grid>


            </Grid>


        </Grid>
    );
}

export default Retreat;


const useStyles = makeStyles(theme => ({
    container: {
        MinHeight: '100vh',
        background: '#FBFBFB',
    },

    card: {
        width: '100%',
        background: '#FFFFFF',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.05)',
        borderRadius: '10px',
    },
    heading: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '14px',
        lineHeight: '16px',
        color: '#C4C4C4',
    }

}));