import React from "react";
import { useTheme } from "@material-ui/core/styles";
import { Fab, Button, useMediaQuery } from "@material-ui/core";
import SocialLogin from "react-social-login";

class SocialLoginButton extends React.Component {
  render() {
    const { component: ButtonComponent } = this.props;
    return (
      <ButtonComponent onClick={this.props.triggerLogin} {...this.props}>
        {this.props.children}
      </ButtonComponent>
    );
  }
}

export default SocialLogin(SocialLoginButton);
