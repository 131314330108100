import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import videoPlayIcon from "../../../assets/Icons/videoPlayIcon.svg";
import completeCheckIcon from "../../../assets/Icons/completeCheckIcon.svg";
import { useHistory } from "react-router-dom";
import { Grid, useMediaQuery, Card, Typography, Tooltip } from "@material-ui/core";
import Truncate from "react-truncate";

function ChallengeStatsCard(props) {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const { challenge } = props;
  let isMobile = useMediaQuery(theme.breakpoints.up("sm")) ? false : true;
  return (
    <Card
      className={classes.root}
      style={{
        width: "100%",
        background:
          challenge?.challengeCompletedPercentage === 100
            ? challenge?.challengeEpisodes[0]?.episode?.course?.category?.color1
            :
            `-webkit-linear-gradient(left, #d4dbe1 ${challenge?.challengeCompletedPercentage}%, #FFFF 0%`,
      }}
      onClick={() => {
        history.push(`/challenge/details/${challenge?.id}`);
      }}
    >
      <Grid container>
        <Grid xs={4} md={4} xl={3} item>
          <div
            style={{
              backgroundImage: `url(${challenge?.imageUrl})`,
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              backgroundPosition: 'center'
            }}
            className={classes.imageContainer}
          ></div>
        </Grid>

        <Grid xs={8} md={8} xl={9} item container style={{ paddingLeft: '15px' }}>

          <Grid item xs={9} md={9} justify='space-between'>
            <Tooltip title={challenge?.title} >
              <Typography
                className={classes.title}
                style={{
                  color: challenge?.challengeCompletedPercentage === 100 && "#FFF",
                }}
              >
                <Truncate lines={1} ellipsis={<span>...</span>} >
                  {`${challenge?.title}`}
                </Truncate>

              </Typography>
            </Tooltip>
            <Typography
              className={classes.description}
              style={{
                color: challenge?.challengeCompletedPercentage === 100 && "#FFF",
              }}
            >
              <Truncate lines={3} ellipsis={<span>...</span>} >
                {`${challenge?.description}`}
              </Truncate>
            </Typography>
          </Grid>

          <Grid item xs={3} md={3} className={classes.iconContainer}>
            {
              <img
                src={
                  challenge?.challengeCompletedPercentage === 100
                    ? completeCheckIcon
                    : videoPlayIcon
                }
                alt=""
              />
            }
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
}

export default ChallengeStatsCard;

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "7em",
    borderRadius: "11px",
    transform: "rotate(360deg)",
    lineHeight: "3.625em",
    display: "flex",
    boxShadow: "4px 4px 6px rgba(0, 0, 0, 0.05)",
    alignItems: "center",
    padding: "0px 10px 0px 10px",
    cursor: "pointer",
  },
  imageContainer: {
    borderRadius: "9px",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    background: "#C4C4C4",
    height: 0,
    paddingTop: "56.25%",
    position: "relative"
  },
  title: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "26px",
    color: "#606060",
    marginTop: "7px",
  },
  description: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "15px",
    color: "#606060",
    marginTop: "5px",
  },
  iconContainer: {
    display: "flex",
    alignItems: "center",
  },
}));
