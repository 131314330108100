import React, { useState, useEffect } from 'react';
import { Grid, CircularProgress, Typography } from '@material-ui/core'
import { makeStyles } from "@material-ui/core/styles";
import AssignmentSubmissionCard from './AssignmentSubmissionCards'
import { getAssignmentsFiles } from '../../../../../actions/retreatCourse'
import { useSelector, useDispatch } from 'react-redux'

function Submit(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [loader, setLoader] = useState(false)
    const [playingLesson, setPlayingLesson] = useState(null)
    const user = useSelector(state => state?.auth);
    const assignments = useSelector(state => state?.retreatCourse?.sectionAssignmentsFiles);
    const lesson = useSelector(state => state?.retreatCourse?.toBePlayedLesson);



    const getData = async () => {
        if (playingLesson) {
            setLoader(true)
            const res = await dispatch(getAssignmentsFiles({ sectionId: playingLesson?.sectionId, token: user?.token }));
            if (res) {
                setLoader(false)
            }
        }
    }

    useEffect(() => {

        lesson && setPlayingLesson(lesson)
    }, [lesson])

    useEffect(() => {
        getData();
        return () => {
            dispatch({
                type: 'GET_SECTION_ASSIGNMENTS',
                payload: []
            });
        }
    }, [playingLesson]);

    return (

        <Grid container>
            {loader ? <Grid item xs={12} className={classes.noContent}>
                <CircularProgress size={20} style={{ color: '#09a9bb' }} />
            </Grid>
                : assignments?.length > 0 ?
                    <Grid item xs={12} container spacing={1} className={classes.container}>
                        {assignments?.map((assignment, ind) => <Grid item xs={12} key={assignment?.id}> < AssignmentSubmissionCard assignment={assignment} ind={ind} sectionId={playingLesson?.sectionId} /></Grid>)
                        }
                    </Grid> : <Grid item xs={12} className={classes.noContent}>
                        <Typography className={classes.noContentText}>No assignment found</Typography>
                    </Grid>

            }
        </Grid>
    );
}

export default Submit;

const useStyles = makeStyles(theme => ({
    cardContainer: {
        background: '#FAF9F9',
        width: '100%',
        boxShadow: 'none'
    },


    content: {
        fontFamily: theme.fontFamily.Roboto,
        fontStyle: "normal",
        fontWeight: 900,
        fontSize: "16px",
        lineHeight: "25px",
        color: "#888888"
    },
    container: {
        maxHeight: '400px',
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
            width: '0px',
            background: 'transparent'
        }

    },
    noContent: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: "40px"
    },
    noContentText: {
        fontFamily: theme.fontFamily.Roboto,
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "14px",
        color: "#666666",

    }

}));