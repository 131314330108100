import React, { useEffect } from "react";
import { useHistory, useLocation, Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { forgotPassword } from "../../../actions/auth";

import Ellipse from "../../../assets/Ellipse3.svg";
import RelateIcon from "../../../assets/Icons/RelateIcon.png";
import TopBar from "../../../components/AppBar";

import {
    Typography,
    TextField,
    CircularProgress
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { useIntl } from "react-intl";

const useStyles = makeStyles(theme => ({
    inputField: {
        width: "100%",
        marginTop: '15px'
    },

    signInBtn: {
        background: "linear-gradient(186.43deg, #15AEBF 13.46%, #14D9D9 76.64%)",
        boxShadow: "0px 3px 6px rgba(18, 166, 166, 0.25)",
        borderRadius: "47px",
        width: "100%",
        height: "64px",
        fontFamily: theme.fontFamily.Roboto,
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "24px",
        lineHeight: "28px",
        letterSpacing: "0.09em",
        color: "#FFFFFF",
        textTransform: "none",
        margin: '40px auto',
        marginBottom: '20px',
        textAlign: 'center'
    },

    topRight: {
        width: "150px",
        height: "150px",
        float: 'right'
    },
    bottomLeft: {
        width: "50px",
        height: "50px",
        borderRadius: "100%",
        position: 'fixed',
        bottom: 30,
        marginLeft: "50px",
    },
    title: {
        fontFamily: theme.fontFamily.Reef,
        fontStyle: "normal",
        fontWeight: 900,
        fontSize: "32px",
        lineHeight: "44px",
        textAlign: 'center'
    },
    backTxt: {
        fontSize: '1.2rem',
        fontFamily: theme.fontFamily.Reef,
        fontWeight: 400,
        lineHeight: 1.5,
        textAlign: 'center',
        '&:hover': {
            textDecoration: 'underline',
        }

    }

}));

const ForgotPassword = () => {
    let history = useHistory();
    const classes = useStyles();
    const intl = useIntl();
    const { state } = useLocation();
    const dispatch = useDispatch();
    const authReducerState = useSelector(state => state.auth);
    const loading = useSelector((state) => state?.loader?.loader);
    const { register, errors, handleSubmit } = useForm();

    const onSubmit = data => {
        dispatch(forgotPassword(data?.email));
    };
    useEffect(() => {
        if (authReducerState.isAuthenticated) {
            history.push(state?.from ? state.from : "/dashboard");
        }

        return () => {
            dispatch({
                type: 'FORGOT_PASSWORD',
                payload: null
            })
        }
    }, [authReducerState.isAuthenticated]);

    return (
        <Grid container  >
            <Grid item xs={2} >
                <TopBar
                    appBarConfig={{
                        show: true,
                        text: ""
                    }}
                />
                <div
                    className={classes.bottomLeft}
                    style={{ backgroundColor: "#a6eae9" }}
                />

            </Grid>
            <Grid item xs={8} container justify='center' style={{ height: '100vh', alignItems: 'center', display: 'flex', alignContent: 'center' }}>

                <Grid item xs={12} sm={10} md={8} lg={5} xl={5} container justify='center'>
                    <Grid item xs={12} style={{ textAlign: 'center', zIndex: 2 }}>
                        <img src={RelateIcon} alt="logo" style={{
                            textAlign: 'center', paddingBottom: '15px', height: '94px'

                        }} />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h5" className={classes.title}>
                            {intl.formatMessage({ id: "forgotPassword" })}?
                        </Typography>
                    </Grid>
                    <Grid item xs={12} >
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Grid container>
                                <Grid item xs={12} style={{ padding: '0px 8px' }}>
                                    <TextField
                                        className={classes.inputField}
                                        inputRef={register({
                                            required: intl.formatMessage({ id: "requiredEmail" }),
                                            pattern: {
                                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                                message: intl.formatMessage({ id: "invalidEmail" })
                                            }
                                        })}
                                        label={intl.formatMessage({ id: "email" })}
                                        name="email"
                                        helperText={errors.email && errors.email.message}
                                        error={!!errors.email}
                                        type="text"

                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        className={classes.signInBtn}
                                        disabled={loading}
                                        onClick={() => handleSubmit()}
                                    >
                                        {loading ? (
                                            <CircularProgress size={15} color="primary" />
                                        ) : (
                                            intl.formatMessage({ id: "continue" })
                                        )}
                                    </Button>
                                </Grid>

                                <Grid item xs={12}>
                                    <Link to='/auth' style={{
                                        textDecoration: 'none',
                                        color: 'rgb(16, 85, 153)'
                                    }}>
                                        <Typography className={classes.backTxt}>{intl.formatMessage({ id: "backToLogin" })}</Typography>
                                    </Link>
                                </Grid>
                            </Grid>
                        </form>
                    </Grid>

                </Grid>
            </Grid>
            <Grid item xs={2} >
                <img
                    src={Ellipse}
                    className={classes.topRight}
                    alt=""
                />
            </Grid>
        </Grid>
    );
};

export default ForgotPassword;

