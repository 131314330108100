import React from "react";
import { useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import SettingsMobileContainer from "../../../containers/Common/Settings/Index";
import More from "../../../containers/Web/More";

const Settings = () => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <>
      {isDesktop && <More />}
      {isMobile && !isDesktop && <SettingsMobileContainer />}
    </>
  );
};

export default Settings;
