import React from 'react'
import { Grid, Avatar, Box, Typography, Badge,useMediaQuery } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/styles'
function ListChatCard(props) {
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Box className={classes.container} style={{background: props?.status === 'active' || isMobile ? '#FFF' :'' }}>
            <Grid container >
                <Grid item xs={1} />
                <Grid item xs={2}><Avatar src={props?.image} className={classes.large}/></Grid>
                <Grid item xs={7}>
                    <Typography className={classes.name} style={{ color: props?.unReadMessages > 0 ? '#000000' : '#797979' }}>{props?.msgUserName}</Typography>
                    <Typography className={classes.msgDescription} style={{ color: props?.unReadMessages > 0 ? '#000000' : '#797979' }}>{props?.msgDescription}</Typography>
                </Grid>
                <Grid item xs={1} style={{textAlign:'center'}}>
                    <Typography className={classes.timeStyle}>{props?.timeAgo}</Typography>
                    {props?.unReadMessages > 0 && <Badge badgeContent={props?.unReadMessages} color="primary"  classes={{ colorPrimary: classes.badge }} />}
                </Grid>

                <Grid item xs={1} />


            </Grid>
        </Box>

    )
}

export default ListChatCard

const useStyles = makeStyles(theme => ({
    container: {
        height: '100PX',
        width: '100%',
        background: '#fbfaf9',
        borderBottom: '1px solid #E9E9E9',
        display: 'flex',
        alignItems: 'center',
        cursor:'pointer'
    },
    name: {
        fontFamily: theme.fontFamily.Roboto,
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "14px",
        lineHeight: "16px",

    },
    msgDescription: {
        fontFamily: theme.fontFamily.Roboto,
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "16px",
        lineHeight: "19px",
        marginTop: '3px'
    },
    timeStyle: {
        fontFamily: theme.fontFamily.Roboto,
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "12px",
        lineHeight: "14px",
        color: ' #3F5B73',
        opacity: 0.7,
    },
    badge: {
        backgroundColor: '#09A9BB',
    },
    large:{
        height:'50px',
        width:'50px'
    }
}))