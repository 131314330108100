import React from 'react';
import { Grid, IconButton, Typography } from '@material-ui/core'
import { makeStyles } from "@material-ui/core/styles";
import { Image, Videocam, InsertDriveFile, Close } from '@material-ui/icons'
import PdfIcon from '../../assets/Icons/adobeIcon.svg'
import EditAssignment from './EditAssignment'

function SupportedFileCard(props) {
    const classes = useStyles();
    const { file, fileName, type } = props

    function bytesToSize(bytes) {
        var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        if (bytes == 0) return '0 Byte';
        var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
        return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
     }

    return (
        <Grid container  className={classes.container} >
            <Grid item xs={3} sm={3} md={3} lg={3} xl={3} className={classes.center}>
                <div style={{
                    background: type === 'application' || type === 'pdf' ? 'rgba(229, 77, 64, 0.26)' : type === 'video' ? 'rgba(0, 0, 0, 0.22)' : type === 'image' ? 'rgba(65, 65, 65, 0.22)' : 'rgba(21, 174, 191, 0.22)',
                    color: type === 'application' || type === 'pdf' ? '#E54D40' : type === 'video' ? '#1C1C1C' : type === 'image' ? '#939393' : '#01BDD2'
                }} className={classes.Icon}>
                    {type === 'application' || type === 'pdf' ? <img src={PdfIcon} height='20px' /> : type === 'video' ? <Videocam /> : type === 'image' ? <Image /> : <InsertDriveFile />}
                </div>
            </Grid>
            <Grid item container xs={7} sm={7} md={7} lg={7} xl={8} className={classes.center}>
                <Grid item xs={12}>
                    <Typography className={classes.heading}>{fileName}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography className={classes.heading} style={{ fontSize: '12px', paddingTop: '5px' }}>{bytesToSize(file?.size)}</Typography>
                </Grid>
                <Grid item xs={12}>
                   <EditAssignment file={props?.file}/>
                </Grid>
            </Grid>
            <Grid item  xs={2} sm={2} md={2} lg={2} xl={1} className={classes.center}>
                <IconButton className={classes.closeIcon} onClick={()=> props?.clickCrossHandler()}><Close /></IconButton>
            </Grid>

        </Grid>
    );
}

export default SupportedFileCard;

const useStyles = makeStyles(theme => ({
    heading: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '14px',
        lineHeight: '100%',
        color: '#353B35',
    },

    card: {
        width: '100%',
        background: '#FFFFFF',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.08)',
        borderRadius: '10px',
    },
    Icon: {
        borderRadius: '10px', height: '50px', width: '50px', display: 'flex', alignItems: 'center', justifyContent: 'center'
    },
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: '#FFFFFF',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.08)',
        minHeight:'90px',
        borderRadius:'11px'

    },
    closeIcon:{
        backgroundColor:"#F1F1F4",
        color:'#E54D40',
        height:'25px',
        width:'25px',

    },
    center: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    }

}));