import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  CircularProgress,
  Button,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  useMediaQuery,
} from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";
import { useIntl } from "react-intl";
import ShowCard from "../PaymentCard/ShowCard";
import {
  getAllCustomerCards,
  joinPaidSession,
  joinCourse,
  payCourse,
  joinChallenges,
  joinPaidRetreat,
  buySubscription,
  upgradeSubscription,
} from "../../../actions/stripe";

import { useDispatch, useSelector } from "react-redux";
import { reactiveSubscription } from "../../../actions/SubscriptionPlan";
import SubscriptionPlan from "../../More/Subscription";
import subscriptionPlan from "../../../reducers/subscriptionPlan";

function CustomerCardList(props) {
  const intl = useIntl();
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const authReducerState = useSelector((state) => state.auth);
  const auth = useSelector((state) => state?.auth);
  const user = useSelector((state) => state?.user?.user);
  let purchasedSubscriptionInfo =
    user?.purchasedSubscription && user?.purchasedSubscription[0];
  const customerCardList = useSelector(
    (state) => state?.stripe?.customerCardList
  );
  let configurations = useSelector((state) => state?.setting?.siteConfig);
  const { plan } = props;

  const { stripeLoader, stripeSuccess } = useSelector((state) => state.stripe);
  const [selectedCard, setSelectedCard] = useState(false);
  const [allCardContainer, setAllCardContainer] = useState(false);
  const [amount, setAmount] = useState(null);
  const [joinCourseConfirmation, setJoinCourseConfirmation] = useState(false);

  useEffect(() => {
    if (props.open) {
      dispatch(getAllCustomerCards({ token: authReducerState.token }));
    }
  }, [props.open]);
  useEffect(() => {
    return () => console.log("end of model for message");
  }, []);

  useEffect(() => {
    if (stripeSuccess) {
      setSelectedCard(null);
      props.setOpen(false);
      setAllCardContainer(false);
      props.setRecommendUpgrade && props.setRecommendUpgrade(true);
      props.setShareOpen && props.setShareOpen("Share");
      setAmount(null);
      dispatch({ type: "SET_STRIPE_SUCCESS", payload: false });
      setJoinCourseConfirmation(false);
      location?.state?.type === "subscription" &&
        history?.replace({ ...location, state: undefined });
    }
  }, [stripeSuccess]);
  const handleClose = () => {
    props.setOpen(false);
    setAllCardContainer(false);
    setAmount(null);
    setSelectedCard(null);
    location?.state?.type === "subscription" &&
      history?.replace({ ...location, state: undefined });
  };

  const handleJoinConfirmationClose = () => {
    setJoinCourseConfirmation(false);
    location?.state?.type === "subscription" &&
      history?.replace({ ...location, state: undefined });
  };

  useEffect(() => {
    if (customerCardList.length === 1) {
      setSelectedCard(customerCardList[0]);
      if (props.open && customerCardList && props?.plan?.name !== "Donation") {
        setAllCardContainer(false);
        props.setOpen(false);
        setJoinCourseConfirmation(true);
      }
    } else if (
      props.open &&
      ((customerCardList && customerCardList?.length === 0) ||
        !props?.customerId)
    ) {
      props?.type === "upgradeSubscription" ||
      props?.type === "buySubscription" ||
      props?.type === "reactiveSubscription"
        ? history.push({
            pathname: "/more/payment-methods",
            state: {
              id: props.planId,
              type: "subscription",
              tab: props?.interval,
            },
          })
        : history.push("/more/payment-methods");
    } else if (props.open && customerCardList && customerCardList?.length > 1) {
      setAllCardContainer(true);
    }
  }, [customerCardList]);

  console.log(props,"props")

  return (
    <>
      <Dialog
        open={allCardContainer}
        classes={{
          paper: classes.paper,
        }}
        onClose={handleClose}
      >
        <DialogContent style={{ padding: "8px 15px" }}>
          <Grid container justify="space-between" spacing={1}>
            <Grid item>
              {" "}
              <Typography
                className={classes.heading}
                component="h2"
                variant="h5"
              >
                {intl.formatMessage({ id: "customerCardList" })}
              </Typography>
            </Grid>
            {/* <Grid item>
              <Button
                onClick={() => history.push("/more/payment-methods")}
                className={classes.proceedButton}
              >
                {intl.formatMessage({ id: "addCard" })}
              </Button>
            </Grid> */}
          </Grid>
          {props.plan?.name === "Donation" && (
            <Grid container style={{ width: "100%" }}>
              <TextField
                label={intl.formatMessage({ id: "insertAmount" })}
                value={amount}
                fullWidth
                InputLabelProps={{
                  classes: {
                    root: classes.testifiedLabel,
                  },
                }}
                inputProps={{ min: 0 }}
                onChange={(e) =>
                  setAmount(e.target.value < 0 ? "" : e.target.value)
                }
                type="number"
                helperText={intl.formatMessage({ id: "paymentShouldBe" })}
              />
            </Grid>
          )}
          <Grid container>
            {customerCardList?.length > 0 ? (
              customerCardList.map((customerCard) => (
                <Grid
                  key={customerCard.id}
                  className={classes.paymentCard}
                  item
                  xs={12}
                >
                  <ShowCard
                    brand={customerCard.brand}
                    number={customerCard.last4}
                    isSelected={selectedCard?.id === customerCard.id}
                    year={customerCard.exp_year}
                    isClickable={true}
                    handleClick={() => {
                      setSelectedCard(customerCard);
                    }}
                  />
                </Grid>
              ))
            ) : (
              <Grid container justify="center">
                {stripeLoader ? (
                  <CircularProgress size={20} style={{ color: "#fbae02" }} />
                ) : (
                  <Typography component="p" variant="caption">
                    {intl.formatMessage({ id: "addCardSuggestion" })}
                  </Typography>
                )}
              </Grid>
            )}
            <Grid item xs={12} />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container>
            <Grid item xs={6} style={{ textAlign: "end" }}>
              <Button
                onClick={() => history.push("/more/payment-methods")}
                className={classes.proceedButton}
                variant="contained"
              >
                {intl.formatMessage({ id: "addCard" })}
              </Button>
            </Grid>
            <Grid item xs={6} style={{ textAlign: "end" }}>
              <Button
                disabled={
                  !selectedCard || (props.plan?.name === "Donation" && !amount)
                }
                onClick={() => {
                  setJoinCourseConfirmation(true);
                }}
                variant="contained"
                className={classes.proceedButton}
                style={{ background: "#39A746", color: "#fff" }}
              >
                {stripeLoader ? (
                  <CircularProgress size={15} style={{ color: "#fff" }} />
                ) : (
                  intl.formatMessage({ id: "proceed" })
                )}
              </Button>

              <Button onClick={handleClose}>
                {intl.formatMessage({ id: "close" })}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
      <Dialog
        open={joinCourseConfirmation}
        onClose={handleJoinConfirmationClose}
      >
        <DialogContent>
          {props?.plan?.name === "Donation" ||
          props?.plan?.name === "Free" ||
          props?.plan?.name === "Freemium" ? (
            <Typography className={classes.heading} component="h2" variant="h5">
              Are you sure you want to pay {configurations?.currency}
              {amount ? amount : props?.price} with selected card{" "}
              {selectedCard ? `XXXX-${selectedCard.last4}` : ""}?
            </Typography>
          ) : props?.paymentType === "upgrade" ? (
            <Typography className={classes.heading} component="h2" variant="h5">
              Are you sure you want to pay {configurations?.currency}
              {amount ? amount : props?.price} to upgrade subscription plan with
              selected card {selectedCard ? `XXXX-${selectedCard.last4}` : ""}?
            </Typography>
          ) : props?.type === "reactiveSubscription" &&
            purchasedSubscriptionInfo?.status === "cancelled" &&
            purchasedSubscriptionInfo?.isRevertAble === "yes" &&
            purchasedSubscriptionInfo?.SubscriptionPlanId === props?.planId &&
            props.paymentType === "reactive" ? (
            <Typography className={classes.heading} component="h2" variant="h5">
              Are you sure you want to reactive subscription?
            </Typography>
          ) : (
            <Typography className={classes.heading} component="h2" variant="h5">
              Are you sure you want to pay {configurations?.currency}
              {amount ? amount : props?.price} to join this{" "}
              {props?.sessionId
                ? "session"
                :props?.challengeId ?
                "challenge"
                : props?.type === "retreat"
                ? "retreat"
                : props?.type === "buySubscription"
                ? "Subscription"
                : "course"}{" "}
              with selected card{" "}
              {selectedCard ? `XXXX-${selectedCard.last4}` : ""}?
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            disabled={
              !selectedCard || (props.plan?.name === "Donation" && !amount)
            }
            onClick={() => {
              props?.type === "upgradeSubscription"
                ? dispatch(
                    upgradeSubscription({
                      subscriptionId: props?.subscriptionId,
                      newPriceId: props?.newPriceId,
                      purchasedSubscriptionId: props?.id,
                      subscriptionPlanId: props?.planId,
                      token: authReducerState.token,
                      cardId: selectedCard.id,
                      interval: props?.interval,
                    })
                  )
                : props?.type === "reactiveSubscription"
                ? dispatch(
                    reactiveSubscription({
                      token: auth?.token,
                      id: purchasedSubscriptionInfo?.id,
                      subscriptionId: purchasedSubscriptionInfo?.subscriptionId,
                    })
                  ).then((res) => {
                    props.setOpen(false);
                    setJoinCourseConfirmation(false);
                  })
                : props?.type === "buySubscription"
                ? dispatch(
                    buySubscription({
                      subscriptionPlanId: props.planId,
                      customerId: props?.customerId,
                      cardId: selectedCard.id,
                      interval: props?.interval,
                      token: authReducerState.token,
                    })
                  )
                : props?.type === "retreat"
                ? dispatch(
                    joinPaidRetreat({
                      cardData: {
                        trainingCourseId: props.retreatId,
                        cardId: selectedCard.id,
                        currency:
                          configurations?.currency === "€" ? "EUR" : "USD",
                        price: props?.price,
                      },
                      token: authReducerState.token,
                    })
                  )
                : props.plan?.name === "Donation"
                ? dispatch(
                    payCourse({
                      cardData: {
                        donationPrice: amount,
                        courseId: props.courseId,
                        cardId: selectedCard.id,
                        currency:
                          configurations?.currency === "€" ? "EUR" : "USD",
                        price: props.plan?.price,
                      },
                      token: authReducerState.token,
                    })
                  )
                : props.plan?.name === "Freemium"
                ? dispatch(
                    payCourse({
                      cardData: {
                        courseId: props.courseId,
                        cardId: selectedCard.id,
                        currency:
                          configurations?.currency === "€" ? "EUR" : "USD",
                        price: props.plan?.price,
                      },
                      token: authReducerState.token,
                    })
                  )
                : props.sessionId
                ? dispatch(
                    joinPaidSession({
                      cardData: {
                        sessionId: props.sessionId,
                        cardId: selectedCard.id,
                        currency:
                          configurations?.currency === "€" ? "EUR" : "USD",
                      },
                      userid: authReducerState?.user?.id,
                      token: authReducerState.token,
                    })
                  )
                            : props?.plan === "paid" ?
                            dispatch(
                              joinChallenges({
                              /*   cardData: {
                                //  courseId: props.courseId,


                                  price: props.plan?.price,
                                }, */
                               data: {
                                  challengeOrSessionId: props.challengeId, type: "Challenge",
                                  cardId: selectedCard.id,
                                  currency:
                                    configurations?.currency === "€" ? "EUR" : "USD",
                                },
                                token: authReducerState.token,
                              })
                            ) :

                            dispatch(
                    joinCourse({
                      cardData: {
                        courseId: props.courseId,

                        cardId: selectedCard.id,
                        currency:
                          configurations?.currency === "€" ? "EUR" : "USD",
                        price: props.plan?.price,
                      },
                      token: authReducerState.token,
                    })
                  );
            }}
            variant="contained"
            className={classes.proceedButton}
          >
            {stripeLoader ? (
              <CircularProgress size={15} style={{ color: "#fff" }} />
            ) : (
              intl.formatMessage({ id: "yes" })
            )}
          </Button>
          <Button onClick={handleJoinConfirmationClose} disabled={stripeLoader}>
            {intl.formatMessage({ id: "no" })}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default CustomerCardList;

const useStyles = makeStyles((theme) => ({
  paymentCard: {
    marginBottom: "1rem",
  },

  heading: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",

    color: "#3D3D3D",
  },
  paper: {
    minWidth: window.innerWidth > 700 ? "50%" : "90%",
    maxWidth: "unset",
  },
  testifiedLabel: {
    color: "#3f51b5",
    padding: 0,
    fontSize: "1rem",
    fontFamily: " Reef,Roboto",
    fontWeight: 400,
    lineHeight: 1,
  },
  proceedButton: {
    background: "#fbae02",
    color: "#fff",
    "&:hover": {
      background: "#fbae02",
      color: "#fff",
    },
    "&:focus": {
      background: "#fbae02",
      color: "#fff",
    },
  },
}));
