import  { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import { Tooltip, Button, Modal } from "@material-ui/core";
import { Delete, Create } from "@material-ui/icons";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Grid from "@material-ui/core/Grid";
import { deleteChallenge } from "../../actions/challenges";
import Typography from "@material-ui/core/Typography";
import { useIntl } from "react-intl";
import { Link, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Truncate from "react-truncate";
import moment from "moment";

function ChallengeCard(props) {
  const classes = useStyles();
  const intl = useIntl();
  const history = useHistory();
  const dispatch = useDispatch();
  const authReducerState = useSelector(state => state?.auth);

  const [modalStyle] = useState(getModalStyle);
  const [openModel, setOpenModel] = useState(false);
  function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
      borderColor: "#fff",
      borderRadius: "4px",
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top - 4}%, -${left}%)`
    };
  }
  const handleCloseModal = () => {
    setOpenModel(false);
  };
  const handleOpenModal = () => {
    setOpenModel(true);
  };
  const confirmDelete = () => {
    handleCloseModal();
    dispatch(
      deleteChallenge({
        token: authReducerState?.token,
        id: props?.id
      })
    );
  };
  const modalBody = (
    <Grid
      container
      direction="row"
      spacing={3}
      style={modalStyle}
      className={classes.paper}
    >
      <Grid item xs={12} className={classes.msgBody}>
        <Typography className={classes.modelBodyText}>
          {` ${intl.formatMessage({ id: "confirmDelete" })}  ${props?.name} ?`}
        </Typography>
      </Grid>
      <Grid container>
        <Grid item xs={6} className={classes.modalBtnGrid}>
          <Button
            onClick={() => handleCloseModal()}
            className={classes.modelBtn}
          >
            {intl.formatMessage({ id: "cancle" })}
          </Button>
        </Grid>
        <Grid item xs={6} className={classes.modalBtnGrid}>
          <Button onClick={() => confirmDelete()} className={classes.modelBtn}>
            {intl.formatMessage({ id: "delete" })}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <>
      <Modal
        disableEnforceFocus={true}
        open={openModel}
        onClose={handleCloseModal}
      >
        {modalBody}
      </Modal>
      <Card className={classes.root}>
        <CardMedia className={classes.media} image={props?.image} style={{ backgroundColor: props?.image && '#fff' }}>
          {props?.tooltip &&
            <Grid container>
              <Grid item xs={12} style={{ textAlign: "right" }}>
                {props?.dates && !(moment(moment().format("YYYY-MM-DD")).isAfter(
                  moment.utc(props?.dates?.[
                    props?.dates?.length - 1
                  ]?.dateTime, 'x').local().format("YYYY-MM-DD")
                )) && <Tooltip title="Edit Challenge">
                    <Create
                      onClick={() =>
                        history.push(`/more/edit-challenge/${props?.id}`)
                      }
                      style={{
                        fontSize: "25px",
                        cursor: "pointer",
                        color: "#F28705"
                      }}
                    />
                  </Tooltip>}
                {(moment(moment.utc(props?.startDate).local()).isAfter(moment())) && <Tooltip title="Delete Challenge">
                  <Delete
                    onClick={() => handleOpenModal()}
                    style={{
                      fontSize: "25px",
                      cursor: "pointer",
                      color: "#F28705"
                    }}
                  />
                </Tooltip>}
              </Grid>
            </Grid>
          }

          {props?.category && (
            <Grid container>

              <Grid item xs={9} style={{ textAlign: "right" }}>
                <Typography
                  style={{
                    color: "#FFF",
                    marginTop: props?.tooltip ? "110px" : "140px",
                    fontSize: "20px"
                  }}
                >
                  &bull;{"   "}{" "}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography
                  style={{
                    color: "#FFF",
                    marginTop: props?.tooltip ? "118px" : "146px",
                    marginRight: "5px",
                    fontSize: "10px"
                  }}
                >
                  {props?.category}
                </Typography>
              </Grid>
            </Grid>
          )}
        </CardMedia>
        <Link
          to={`/challenge/details/${props?.id}`}
          style={{ textDecoration: "none" }}
        >
          <Tooltip title="Challenge Details">
            <CardContent style={{ padding: '7px 0px' }}>

              <Typography
                gutterBottom
                className={
                  props?.courseNameBold ? classes.nameBold : classes.title
                }
              >
                <Truncate lines={1} ellipsis={<span>...</span>} >
                  {`    ${props?.name}   `}
                </Truncate>
              </Typography>
              <Grid container>
               
                <Grid item xs={4}>
                  <Typography
                    variant="body2"
                    component="p"
                    className={classes.subTitle}
                  >
                    {props?.friends}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  {props?.totalDuration >= 0 && (
                    <Typography
                      variant="body2"
                      component="p"
                      className={classes.subTitle}
                    >
                      {props?.totalDuration + " "}
                      {intl.formatMessage({ id: "mins" })}
                    </Typography>
                  )}
                  {props?.toDuration && (
                    <Typography
                      variant="body2"
                      component="p"
                      className={classes.subTitle}
                    >
                      {props?.toDuration + " "}
                      {intl.formatMessage({ id: "mins" })}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={4}>
                  <Typography
                    variant="body2"
                    component="p"
                    className={classes.subTitle}
                  >
                    {props?.time}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Tooltip>
        </Link>
      </Card>
    </>
  );
}

export default ChallengeCard;

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    maxHeight: "260px",
    borderRadius: "11px",
    boxShadow: " 0px 4px 4px rgba(0, 0, 0, 0.05)"
  },
  media: {
    height: 0,
    paddingTop: "56.25%",
    position: "relative",
    BorderRadiusTopRight: "11px",
    BorderRadiusTopLeft: "11px",
    background: "#C4C4C4",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover"
  },
  title: {
    fontFamily: theme.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: "900",
    fontSize: "14px",
    lineHeight: "19px",
    textAlign: "center",
    color: "#000000",
    margin: "auto",
    paddingBottom: "0.35em"
  },
  subTitle: {
    fontFamily: theme.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: "900",
    fontSize: "10px",
    lineHeight: "14px",
    textAlign: "center",
    color: "#C2C2C2"
  },
  nameBold: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "18px",
    lineHeight: "21px",
    letterSpacing: " 0.05em",
    color: "#3C4A6B",
    marginLeft: "20px",
    marginTop: "10px"
  },
  paper: {
    position: "absolute",
    height: 330,
    maxWidth: 315,
    minWidth: 300,
    justifyContent: "center",
    backgroundColor: theme.palette.background.paper,
    outline: "none"
  },

  modelBodyText: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "17px",
    lineHeight: "27px",
    textAlign: "center",
    letterSpacing: "0.02em",
    color: "#192247",
    maxWidth: 310,
    padding: "0px 20px 0px 20px",
    overflow: "auto"
  },

  modelBtn: {
    height: "73px",
    width: "50%",
    fontFamily: theme.fontFamily.Roboto,
    cursor: "pointer",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "21px",
    color: theme.colors.white
  },

  modalBtnGrid: {
    padding: "0px",
    alignSelf: "flex-end",
    color: "#fff",
    cursor: "pointer",
    background: `linear-gradient(#09A9BB 1%,#09A9BB 100%)`,
    height: "73px",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    display: "flex"
  },
  msgBody: {
    maxWidth: 315,
    minWidth: 300,
    minHeight: "240px",
    alignItems: "center",
    display: "flex"
  }
}));
