import React, { useEffect, useRef, useState } from 'react'
import { Grid, Typography, useMediaQuery, CircularProgress } from '@material-ui/core'
import ReactPlayer from "react-player/lazy";
import { makeStyles, useTheme } from "@material-ui/styles";
import TopBar from "../AppBar";
import { useHistory, useLocation } from 'react-router-dom'
import SessionChat from './SessionChat'
import SessionTimer from './sessionStartTimer'
import IntroVideo from '../../assets/videos/OmidIntro.mp4'
import { Error } from "@material-ui/icons";
import Countdown from 'react-countdown';
import moment from 'moment';
import clsx from 'clsx';
import SubscribeNowBtn from '../Common/SubscribeNowBtn';


function PlaySession() {
    const classes = useStyles();
    const theme = useTheme();
    const location = useLocation();
    const history = useHistory();


    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const [error, setError] = useState(null);
    const [loader, setLoader] = useState(true);
    const [sessionThumbnail, setSessionThumbnail] = useState('');
    const [isSessionTime, setIsSessionTime] = useState(false);
    const [introVideo, setIntroVideo] = useState(true);

    useEffect(() => {
        if (location?.state.sessionDates?.length > 0) {
            setIsSessionTime(moment(location?.state.sessionDates[0]?.dateTime, 'x').local().format('llll') <= (moment().format('llll')));
        }
        setLoader(false)
    }, [])

    const sessionDuration = () => {
        const startTime = location?.state?.sessionDates[0].dateTime;
        const endTime = location?.state?.sessionDates[0].endingDateTime;
        const ms = endTime - startTime;
        var seconds = (ms / 1000).toFixed(0);
        var minutes = Math.floor(seconds / 60);
        var hours = "";
        if (minutes > 59) {
            hours = Math.floor(minutes / 60);
            hours = (hours >= 10) ? hours : "0" + hours;
            minutes = minutes - (hours * 60);
            minutes = (minutes >= 10) ? minutes : "0" + minutes;
        }

        seconds = Math.floor(seconds % 60);
        seconds = (seconds >= 10) ? seconds : "0" + seconds;
        if (hours !== "") {
            return hours + " : " + minutes + " : " + seconds;
        }
        return minutes + " : " + seconds;
    }

    const handleEnded = () => {
        setIntroVideo(!introVideo)
        if (!introVideo) {
            setSessionThumbnail(location?.state?.imageUrl);
        }
    }
    return (
        <>{
            loader ? <Grid item xs={12} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
                <CircularProgress size={30} style={{ color: '#15AEBF' }} />
            </Grid> :
                error ? <Grid container style={{ backgroundColor: "#FFF" }}>
                    <Grid item xs={11} style={{ display: 'flex', alignItems: 'center', height: '87px' }}>
                        <TopBar
                            appBarConfig={{ show: true, text: 'Play Session', backgroundColor: "#E5E5E5", marginLeft: 0, backAction: () => history.goBack() }}
                        />
                    </Grid>
                    <Grid xs={12} item>
                        <div className={classes.reactPlayer} style={{
                            background: '#E5E5E5', height: "100%",
                            width: "100%", alignItems: 'center', justifyContent: 'center', display: 'flex'
                        }}>
                            <Error style={{ fontSize: '30px', marginTop: '6px' }} />
                            <Typography>
                                {"  " + error}
                            </Typography>
                        </div></Grid></Grid> :
                    <Grid container>
                        <Grid container item xs={12} sm={12} md={12} className={classes.container} style={{ padding: !isMobile && '0px 70px 0px 70px', height: isMobile && 'auto' }}>

                            <Grid item xs={12} >
                                <TopBar
                                    appBarConfig={{ show: true, text: 'Play Session', backgroundColor: "#FFF", marginLeft: 0, backAction: () => history.goBack() }}
                                />
                            </Grid>

                            <Grid item xs={12} container>
                                {
                                    isSessionTime || introVideo ?
                                        <Grid item xs={12}>
                                            <div className={classes.playerWrapper}>
                                                <div className={clsx(classes.reactPlayer, classes.preLoadBody)} >
                                                    <CircularProgress style={{ color: '#09A9BB' }} />
                                                </div>
                                                <ReactPlayer
                                                    controls="true"
                                                    height="100%"
                                                    width="100%"
                                                    className={classes.reactPlayer}
                                                    onEnded={handleEnded}
                                                    playing={true}
                                                    light={sessionThumbnail}

                                                    url={introVideo ? IntroVideo : `${location?.state?.meetingId}`}
                                                    config={{
                                                        youtube: {
                                                            playerVars: { showinfo: 1 }
                                                        }
                                                    }}
                                                />
                                            </div> </Grid> :
                                        <Grid container item xs={12} className={classes.playerWrapper} style={{ backgroundImage: `linear-gradient(to bottom,rgba(20,21,22,.3),#141516)` }}>
                                            <Grid item container xs={12} style={{ position: 'absolute', top: isMobile ? '0%' : '28.12%', textAlign: 'center', color: '#FFF' }}>
                                                <SessionTimer time={location?.state.sessionDates?.length > 0 && moment(location?.state.sessionDates[0]?.dateTime, 'x').local()} onComplete={() => setIsSessionTime(true)} />

                                            </Grid>
                                        </Grid>}
                            </Grid>

                            <Grid item xs={12}>
                                <Typography
                                    className={isMobile ? classes.sessionMobileHeading : classes.sessionHeading}
                                >
                                    {location?.state?.name}
                                </Typography>
                            </Grid>

                            <Grid item xs={12} container justify='center'>
                                <Grid item xs={12} md={6} style={{ padding: 10 }}>
                                    <SubscribeNowBtn />
                                </Grid>
                            </Grid>

                            <Grid item xs={12} container>
                                <Grid xs={12} sm={12} md={6} item container>
                                    <Grid item xs={6} sm={3} md={3} lg={3} xl={2}>
                                        <Typography
                                            className={isMobile ? classes.sessionMobileSubHeading : classes.sessionSubHeading}>
                                            Delivered by :
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6} sm={9} md={9} lg={9} xl={10}>
                                        <Typography
                                            className={isMobile ? classes.sessionMobileSubHeading : classes.sessionSubHeading} style={{ fontSize: '16px' }}>
                                            {location?.state?.user?.name}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid xs={12} sm={12} md={6} item container>
                                    <Grid item xs={6} sm={3} md={3} lg={3} xl={2}>
                                        <Typography
                                            className={isMobile ? classes.sessionMobileSubHeading : classes.sessionSubHeading}>
                                            Session duration :
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6} sm={9} md={9} lg={9} xl={10}>
                                        <Typography
                                            className={isMobile ? classes.sessionMobileSubHeading : classes.sessionSubHeading} style={{ fontSize: '16px' }}>
                                            {location?.state?.sessionDates?.length > 0 && sessionDuration()}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid xs={12} sm={12} md={6} item container>
                                    <Grid item xs={6} sm={3} md={3} lg={3} xl={2}>
                                        <Typography
                                            className={isMobile ? classes.sessionMobileSubHeading : classes.sessionSubHeading}>
                                            Start time :
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6} sm={9} md={9} lg={9} xl={10}>
                                        <Typography
                                            className={isMobile ? classes.sessionMobileSubHeading : classes.sessionSubHeading} style={{ fontSize: '16px' }}>
                                            {location?.state?.sessionDates?.length > 0 && moment(location?.state?.sessionDates[0].dateTime, 'x').format('LT')}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid xs={12} sm={12} md={6} item container>
                                    <Grid item xs={6} sm={3} md={3} lg={3} xl={2}>
                                        <Typography
                                            className={isMobile ? classes.sessionMobileSubHeading : classes.sessionSubHeading}>
                                            Session Status :
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6} sm={9} md={9} lg={9} xl={10}>
                                        <Typography
                                            className={isMobile ? classes.sessionMobileSubHeading : classes.sessionSubHeading} style={{ fontSize: '16px' }}>
                                            {location.state?.status}
                                        </Typography>
                                    </Grid>
                                </Grid>


                            </Grid>
                        </Grid>

                    </Grid>
        }</>
    )
}

export default PlaySession


const useStyles = makeStyles(theme => ({
    container: {
        height: '100vh',
        overflowY: "auto",
        "&::-webkit-scrollbar": {
            display: "none"
        }
    },
    playerWrapper: {
        position: "relative",
        paddingTop: "56.25%"
    },
    reactPlayer: {
        position: "absolute",
        top: 0,
        left: 0
    },
    sessionHeading: {
        fontFamily: theme.fontFamily.Reef,
        fontStyle: "normal",
        fontWeight: 900,
        fontSize: "22px",
        lineHeight: "25px",
        marginTop: "7px",
        paddingBottom: '10px'
    },
    sessionMobileHeading: {
        fontFamily: theme.fontFamily.Reef,
        fontStyle: "normal",
        fontWeight: 900,
        fontSize: "22px",
        lineHeight: "25px",
        marginTop: "7px",
        paddingBottom: '10px',
        paddingLeft: "10px"
    },
    sessionSubHeading: {
        fontFamily: theme.fontFamily.Reef,
        fontStyle: "normal",
        fontWeight: 900,
        fontSize: "18px",
        lineHeight: "25px",
        marginTop: "7px",
        paddingBottom: '10px'
    },
    sessionMobileSubHeading: {
        fontFamily: theme.fontFamily.Reef,
        fontStyle: "normal",
        fontWeight: 900,
        fontSize: "18px",
        lineHeight: "25px",
        marginTop: "7px",
        paddingBottom: '10px',
        paddingLeft: "10px"

    },
    errorMessage: {
        fontFamily: theme.fontFamily.Roboto,
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "24px",
        color: "#000000",
        marginTop: "7px"
    },
    preLoadBody: {
        display: 'flex',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#a9a9a9',
        width: '100%'
    }
}))