import React, { useState, useEffect } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import {
    AppBar,
    Tab,
    Tabs,
    Box,
    FormControl,
    Select,
    MenuItem,
    FormHelperText,
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { useIntl } from "react-intl";
import { Toolbar, IconButton } from "@material-ui/core";
import ChallengeCard from "../../../../components/Common/ChallengeCard";
import { useSelector } from "react-redux";
import { useHistory , Link} from "react-router-dom";
import { ArrowBack } from "@material-ui/icons";
import Calender from "../../../../assets/Icons/calender.svg";
import moment from 'moment'

const StyledTabs = withStyles({
    indicator: {
        display: "flex",
        justifyContent: "center",
        height: '3px',
        backgroundColor: "transparent",
        "& > span": {
            maxWidth: 40,
            width: "100%",
            backgroundColor: "#09A9BB",
        },
    },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

const StyledTab = withStyles((theme) => ({
    root: {
        minWidth: "60px !important",
        textTransform: "none",
        color: "#09A9BB",
        fontWeight: theme.typography.fontWeightRegular,
        fontSize: theme.typography.pxToRem(15),
        marginRight: theme.spacing(1),
        "&:focus": {
            opacity: 1,
        },
    }
}))((props) => <Tab disableRipple {...props} />);
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <>{children}</>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function AllChallenges(props) {
    const classes = useStyles();
    const intl = useIntl();
    let history = useHistory();

    const authReducerState = useSelector((state) => state.auth);
    const allChallenges = useSelector((state) => state?.challenges?.allChallenges);

    const [value, setValue] = React.useState(0);
    const [optionList] = useState([{ name: "Completed" }, { name: "In Progress" }]);
    const [option, setOption] = useState("");
    const [relateChallenges, setRelateChallenges] = useState([]);
    const [friendChallenges, setFriendChallenges] = useState([]);



    useEffect(() => {
        const friendsChallenges = []
        const userChallenges = allChallenges?.filter((challenge) => {
            if (challenge?.challenge?.userId === authReducerState?.user?.id) {
                return challenge
            } else {
                friendsChallenges.push(challenge);
                return
            }

        })
        setRelateChallenges(userChallenges)
        setFriendChallenges(friendsChallenges)
    }, [allChallenges]);

    const handleSelect = (event) => {
        if (event.target.value === "") {
            const friendsChallenges = []
            const userChallenges = allChallenges?.filter((challenge) => {
                if (challenge?.challenge?.userId === authReducerState?.user?.id) {
                    return challenge
                } else {
                    friendsChallenges.push(challenge);
                    return
                }

            })
            setRelateChallenges(userChallenges)
            setFriendChallenges(friendsChallenges)
            setOption(event.target.value);
        } else if (event.target.value === "In Progress") {
            const friendsChallenges = []
            const userChallenges = allChallenges?.filter((challenge) => {
                if (challenge?.challenge?.userId === authReducerState?.user?.id && !challenge?.challenge?.isChallengeExpired) {
                    return challenge
                } else if (!challenge?.challenge?.isChallengeExpired) {
                    friendsChallenges.push(challenge);
                    return
                }

            })
            setRelateChallenges(userChallenges)
            setFriendChallenges(friendsChallenges)
            setOption(event.target.value);
        } else if (event.target.value === "Completed") {
            const friendsChallenges = []
            const userChallenges = allChallenges?.filter((challenge) => {
                if (challenge?.challenge?.userId === authReducerState?.user?.id && challenge?.challenge?.isChallengeExpired) {
                    return challenge
                } else if (challenge?.challenge?.isChallengeExpired) {
                    friendsChallenges.push(challenge);
                    return
                }

            })
            setRelateChallenges(userChallenges)
            setFriendChallenges(friendsChallenges)
            setOption(event.target.value);
        }

    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    return (
        <Grid container style={{ backgroundColor: "rgb(251, 250, 249)" }}>
            {/*app bar*/}
            <Grid container style={{ height: "131px" }}>
                <Grid container style={{ height: "81px" }}>
                    <Grid item xs={3}>
                        <AppBar
                            className={classes.appBar}
                            style={{ backgroundColor: "rgb(251, 250, 249)" }}
                        >
                            <IconButton
                                onClick={() => history.push("/more/relate-with-friends")}
                                className={classes.backButton}
                            >
                                <ArrowBack />
                            </IconButton>
                            <Toolbar>
                                <Typography className={classes.appBarText} variant="h6">
                                    {intl.formatMessage({ id: "back" })}
                                </Typography>
                            </Toolbar>
                        </AppBar>
                    </Grid>
                    <Grid item xs={1} />
                    <Grid item xs={8} container alignItems="center">
                        <Grid item xs={2} />

                        <Grid item xs={4}>
                            <Typography className={classes.appBartext}>
                                {intl.formatMessage({ id: "showing" })}{" "}
                                {value === 0 ? relateChallenges?.length : friendChallenges?.length}{" "}
                                {intl.formatMessage({ id: "results" })}
                            </Typography>
                        </Grid>
                        <Grid item xs={5}>
                            <FormControl className={classes.inputField}>
                                <Select
                                    className={classes.selectCategory}
                                    style={{ width: "100%" }}
                                    classes={{
                                        iconFilled: classes.iconSelect,
                                        icon: classes.iconSelect,
                                    }}
                                    value={option}
                                    onChange={handleSelect}
                                    displayEmpty
                                    inputProps={{ "aria-label": "Without label" }}
                                >
                                    <MenuItem value={""} >
                                        All
                                    </MenuItem>
                                    {optionList?.map((op, ind) => {
                                        return (
                                            <MenuItem value={op?.name} key={ind}>
                                                {op?.name}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>

                                <FormHelperText
                                    style={{ color: "white", textAlign: "center" }}
                                />
                            </FormControl>
                        </Grid>

                    </Grid>
                </Grid>
                <Grid container style={{ height: "50px" }}>
                    <Grid item xs={1} />
                    <AppBar position="static" className={classes.TabAppBar}>
                        <StyledTabs
                            value={value}
                            onChange={handleChange}
                            aria-label="styled tabs example"
                        >
                            <StyledTab label={intl.formatMessage({ id: "myRelateChallenges" })} classes={{
                                root: classes.tabLabel,
                                selected: classes.tabSelected
                            }} />
                            <StyledTab label={intl.formatMessage({ id: "friendChallenges" })} classes={{
                                root: classes.tabLabel,
                                selected: classes.tabSelected
                            }} />
                        </StyledTabs>
                    </AppBar>
                </Grid>
            </Grid>
            <TabPanel value={value} index={0} style={{ width: "100%", minHeight: '100vh' }}>
                <Grid container xs={12} lg={12} spacing={2}>
                    {relateChallenges && relateChallenges?.length === 0 ? (
                        <Grid item xs={12}>
                            <Typography className={classes.noContent}>
                                No Record found
                            </Typography>
                        </Grid>
                    ) : (
                        relateChallenges?.map((challenge) => {
                            return (
                                <Grid
                                    item
                                    xs={5}
                                    lg={5}
                                    key={challenge?.challenge?.id}
                                    style={{ minWidth: "180px", maxWidth: "240px" }}

                                >
                                      <Link
                            to={`/challenge/details/${challenge?.challenge?.id}`}
                            style={{ textDecoration: "none" }}
                          >

                                    <ChallengeCard
                                        id={challenge?.challenge?.id}
                                        image={challenge?.challenge?.imageUrl}
                                        category={challenge?.challenge?.course?.category?.name}
                                        name={challenge?.challenge?.title}
                                        friends={challenge?.challenge?.participantsCount > 1
                                            ? `${challenge?.challenge?.participantsCount - 1} friends`
                                            : challenge?.challenge?.participantsCount === 1 ? `${challenge?.challenge?.participantsCount - 1} friend` : '0 friend'}
                                        totalDuration={Math.round(challenge?.challenge?.duration / 60)}
                                        time={moment(challenge?.challenge?.exerciseTime, 'LTS').format('LT')}
                                        tooltip={true}
                                        startDate={challenge?.challenge?.startDate}
                                        dates={challenge?.challenge?.challengeDates}
                                        />
                                        </Link>

                                </Grid>
                            )


                        })
                    )}
                </Grid>
            </TabPanel>
            <TabPanel value={value} index={1} style={{ width: "100%", minHeight: '100vh' }}>
                <Grid container xs={12} lg={12} spacing={2}>
                    {friendChallenges && friendChallenges?.length === 0 ? (
                        <Grid item xs={12}>
                            <Typography className={classes.noContent}>
                                No Record found
                            </Typography>
                        </Grid>
                    ) : (
                        friendChallenges?.map((challenge) => {
                            return (
                                <Grid
                                    item
                                    xs={5}
                                    lg={5}
                                    key={challenge?.challenge?.id}
                                    style={{ minWidth: "180px", maxWidth: "240px" }}

                                >
                                                 <Link
                            to={`/challenge/details/${challenge?.challenge?.id}`}
                            style={{ textDecoration: "none" }}
                          >

                                    <ChallengeCard
                                        id={challenge?.challenge?.id}
                                        image={challenge?.challenge?.imageUrl}
                                        category={challenge?.challenge?.course?.category?.name}
                                        name={challenge?.challenge?.title}
                                        friends={challenge?.challenge?.participantsCount > 1
                                            ? `${challenge?.challenge?.participantsCount - 1} friends`
                                            : challenge?.challenge?.participantsCount === 1 ? `${challenge?.challenge?.participantsCount - 1} friend` : '0 friend'}
                                        totalDuration={Math.round(challenge?.challenge?.duration / 60)}
                                        time={moment(challenge?.challenge?.exerciseTime, 'LTS').format('LT')}
                                        />
                                        </Link>

                                </Grid>
                            )


                        })
                    )}
                </Grid>
            </TabPanel>

        </Grid>
    );
}

export default AllChallenges;

const useStyles = makeStyles((theme) => ({
    appBar: {
        color: "#000",
        flexDirection: "row",
        paddingTop: theme.spacing(1.5),
        paddingBottom: theme.spacing(1.5),
        right: "unset",
        position: "relative",
        marginLeft: "25px",
        boxShadow: "none",

    },

    appBarText: {
        fontFamily: theme.fontFamily.Roboto,
        fontStyle: "normal",
        fontWeight: "900",
        fontSize: "20px",
        lineHeight: "23px",
    },

    backButton: {
        background: "transparent",
        color: "#000",
        padding: "0px",
    },
    appBartext: {
        fontFamily: theme.fontFamily.Roboto,
        fontStyle: "italic",
        fontWeight: 500,
        fontSize: "18px",
        lineHeight: "21px",

        color: "#F28705",
    },
    inputField: {
        width: "100%",
        background: "#F28705",
        borderRadius: "82px",
    },

    selectCategory: {
        background: "#F28705",
        fontFamily: theme.fontFamily.Roboto,
        fontStyle: "normal",
        fontSize: "14px",
        lineHeight: "16px",
        textAlign: "center",
        height: "41px",
        width: "156px",

        borderRadius: "20px",
        fontWeight: 500,
        color: "#ffffff",
        paddingRight: "10px",
        "&.MuiInput-underline::before": {
            borderBottom: "none",
        },
        "&.MuiInput-underline:after": {
            borderBottom: "none",
        },
        "&.MuiInput-underline:hover:not(.Mui-disabled):before": {
            borderBottom: "none",
        },
        "& .MuiSelect-icon": {
            color: "#ffffff !important",
            paddingRight: "10px",
        },
    },
    iconSelect: {
        color: "#ffffff",
        paddingRight: "10px",
        variant: "filled",
    },
    hideScrollBar: {
        flexWrap: "unset",
        overflowX: "auto",
        "&::-webkit-scrollbar": {
            display: "none",
        },
    },
    paper: {
        position: "absolute",
        height: 330,
        maxWidth: 315,
        minWidth: 300,
        justifyContent: "center",
        backgroundColor: theme.palette.background.paper,
        outline: "none",
    },

    modelBodyText: {
        fontFamily: theme.fontFamily.Roboto,
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "17px",
        lineHeight: "27px",
        textAlign: "center",
        letterSpacing: "0.02em",
        color: "#192247",
    },

    modelBtn: {
        height: "73px",
        width: "100%",
        fontFamily: theme.fontFamily.Roboto,
        cursor: "pointer",
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "18px",
        lineHeight: "21px",
        color: theme.colors.white,
    },
    tabLabel: {
        color: "#DFDFDF",
        fontFamily: theme.fontFamily.Roboto,
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "16px",
        lineHeight: "19px",
        minWidth: "20%",
        textTransform: "none",
        textAlign: 'left'
    },
    tabSelected: {
        color: "#47b8c4",
        fontFamily: theme.fontFamily.Roboto,
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "16px",
        lineHeight: "19px",
    },
    modalBtnGrid: {
        padding: "0px",
        alignSelf: "flex-end",
        color: "#fff",
        cursor: "pointer",
        background: `linear-gradient(#09A9BB 1%,#09A9BB 100%)`,
        height: "73px",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
    },
    msgBody: {
        minHeight: "240px",
        alignItems: "center",
        display: "flex",
    },
    noContent: {
        color: "#7d7b79",
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "16px",
        lineHeight: "19px",
        padding: "12px 10px 10px 17px",
        textAlign: "center",
    },
    TabAppBar: {
        backgroundColor: "#fbfaf9",
        width: "100%",
        boxShadow: "none",
        display: "flex",
        marginLeft: "20px",

        justifyContent: "space-between"
    }
}));
