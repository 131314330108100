import React, { useEffect } from "react";

import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ChooseIdentityWeb from '../../Web/ChooseIdentity'
import ChooseIdentityMobile from '../../Mobile/ChooseIdentity'
import { useDispatch } from "react-redux";
import { getSiteConfigs } from '../../../actions/siteSetting'

const ChooseIdentity = () => {
  const theme = useTheme();
  let dispatch = useDispatch();
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));

  useEffect(() => {
    dispatch(getSiteConfigs({ token: '' }))

  }, [])
  return (
    <React.Fragment>
      {isDesktop
        ? <ChooseIdentityWeb />
        : <ChooseIdentityMobile />}
    </React.Fragment>
  );
}


export default ChooseIdentity;