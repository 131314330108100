import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  useMediaQuery,
  Menu,
  MenuItem,
  Button,
  CircularProgress,
} from "@material-ui/core";
import { Add } from "@material-ui/icons";
import pencilIcon from "../../../assets/Icons/pencilIcon.png";
import MyCoursesIcon from "../../../assets/Icons/MyCoursesIcon.png";
import pencilIconWhite from "../../../assets/Icons/pencilIconWhite.svg";
import ExerciseIcon from "../../../assets/Icons/ExerciseIcon.svg";
import ExerciseIconWhite from "../../../assets/Icons/ExerciseIconWhite.svg";
import MyCoursesIconWhite from "../../../assets/Icons/CoursesIconWhite.svg";
import ProfileInfo from "./ProfileInfo";
import MyCourses from "./MyCourses";
import { useHistory } from "react-router-dom";
import { getAllCategories } from "../../../actions/courses";
import { resendEmail } from "../../../actions/auth";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import SoloExercise from "../../Web/SoloExercise/Index";
import SoloExerciseMobile from "../../Mobile/SoloExercise/Index";
import { useTheme, makeStyles } from "@material-ui/core/styles";
import {
  createChallengeData,
  addParticipants,
} from "../../../actions/challenges";
import Alert from "@material-ui/lab/Alert";

function Profile() {
  const intl = useIntl();
  const [screen, setScreen] = useState("info");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [loader, setLoader] = React.useState(false);
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  let authReducerState = useSelector((state) => state.auth);
  let user = useSelector((state) => state.user);
  let dispatch = useDispatch();
  const handleScreen = (e, selectScreen) => {
    e.preventDefault();
    setScreen(selectScreen);
  };

  const handleBack = () => {
    setScreen("courses");
  };

  useEffect(() => {
    dispatch(getAllCategories({ token: authReducerState.token }));
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSelectedMenu = (path) => {
    setAnchorEl(null);
    if (path === "/more/create-challenge") {
      dispatch(createChallengeData({}));
      dispatch(addParticipants([]));
    }
    history.push(path);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const handleResendEmail = async () => {
    setLoader(true);
    let res = await dispatch(resendEmail(authReducerState?.user?.email));
    setLoader(false);

    // resendEmail()
  };
  return (
    <>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        style={{ textAlign: "center", top: "35px" }}
      >
        <MenuItem
          onClick={() => handleSelectedMenu("add-course")}
          className={classes.addDialogBtn}
        >
          Add Course
        </MenuItem>
        <MenuItem
          onClick={() => handleSelectedMenu("/more/create-challenge")}
          className={classes.addDialogBtn}
        >
          Add Challenge
        </MenuItem>
        <MenuItem
          onClick={() => handleSelectedMenu("/more/session/create")}
          className={classes.addDialogBtn}
        >
          Add Session
        </MenuItem>
        <MenuItem
          onClick={() => handleSelectedMenu("/add-retreat")}
          className={classes.addDialogBtn}
        >
          Online Retreat
        </MenuItem>
        <MenuItem
          onClick={() => handleSelectedMenu("/add-teacher-training")}
          className={classes.addDialogBtn}
        >
          Online Teacher training
        </MenuItem>
      </Menu>

      <Grid
        container
        style={{
          marginTop: screen === "exercise" ? "0px" : "20px",
          paddingLeft: screen === "exercise" ? "0px" : "58px",
        }}
      >
        {screen === "exercise" ? (
          isMobile ? (
            <SoloExerciseMobile handleBack={handleBack} />
          ) : (
            <SoloExercise handleBack={handleBack} />
          )
        ) : (
          <Grid
            container
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            spacing={2}
          >
            <Grid
              container
              item
              xs={12}
              sm={12}
              md={8}
              lg={6}
              xl={6}
              spacing={2}
            >
              <Grid
                item
                xs={4}
                lg={4}
                xl={4}
                justify="center"
                style={{ textAlign: "center" }}
              >
                <div
                  onClick={(e) => handleScreen(e, "info")}
                  className={
                    screen === "info" ? classes.cardActive : classes.card
                  }
                  style={{ lineHeight: "100px", cursor: "pointer" }}
                >
                  <img
                    src={screen === "info" ? pencilIconWhite : pencilIcon}
                    alt="edit"
                  />
                </div>
                <Typography className={classes.title}>
                  {intl.formatMessage({ id: "info" })}
                </Typography>
              </Grid>
              <Grid item xs={4} lg={4} xl={4}>
                <div
                  onClick={(e) => handleScreen(e, "courses")}
                  className={
                    screen === "courses" ? classes.cardActive : classes.card
                  }
                  style={{ cursor: "pointer" }}
                >
                  <img
                    src={
                      screen === "courses" ? MyCoursesIconWhite : MyCoursesIcon
                    }
                    alt="courses"
                  />
                </div>
                <Typography className={classes.title} align="center">
                  {intl.formatMessage({ id: "myCourses" })}
                </Typography>
              </Grid>
              {authReducerState?.user?.role === "Instructor" ||
              authReducerState?.user?.role === "Studio" ? (
                <Grid item xs={4} lg={4} xl={4}>
                  <div
                    onClick={handleClick}
                    className={
                      screen === "exercise" ? classes.cardActive : classes.card
                    }
                    style={{ cursor: "pointer" }}
                  >
                    <Add style={{ fontSize: "48px", fontWeight: "bold" }} />
                  </div>
                  <Typography className={classes.title} align="center">
                    {intl.formatMessage({ id: "add" })}
                  </Typography>
                </Grid>
              ) : (
                <Grid item xs={4} lg={4} xl={4}>
                  <div
                    onClick={(e) => handleScreen(e, "exercise")}
                    className={
                      screen === "exercise" ? classes.cardActive : classes.card
                    }
                    style={{ cursor: "pointer" }}
                  >
                    <img
                      src={
                        screen === "exercise" ? ExerciseIconWhite : ExerciseIcon
                      }
                      alt="exercises"
                    />
                  </div>
                  <Typography className={classes.title} align="center">
                    {intl.formatMessage({ id: "soloExercise" })}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
        )}
        {!user?.user?.emailConfirmed && (
          <Grid
            item
            container
            xs={12}
            style={{ marginTop: screen === "exercise" ? "0px" : "10px" }}
          >
            <p style={{ color: "red", paddingLeft: "20px" }}>
              Your Email is not verified. Please verify your Email
              <Button
                disableRipple={true}
                disabled={loader}
                className={classes.customButton}
                onClick={() => handleResendEmail()}
              >
                {loader ? (
                  <CircularProgress style={{ color: "#fff" }} size={20} />
                ) : (
                  "Resend Email"
                )}
              </Button>
            </p>
          </Grid>
        )}
        <Grid
          item
          container
          xs={12}
          style={{ marginTop: screen === "exercise" ? "0px" : "60px" }}
        >
          {screen === "info" ? (
            <ProfileInfo />
          ) : screen === "courses" ? (
            <MyCourses handleBack={handleBack} />
          ) : (
            ""
          )}
        </Grid>
      </Grid>
    </>
  );
}

export default Profile;

const useStyles = makeStyles((theme) => ({
  customButton: {
    background: `linear-gradient(360deg, #09A9BB 100%, #09A9BB 0%)`,
    height: "2.463em",
    width: "120px",
    marginLeft: "20px",
    borderRadius: "10px",
    transform: "rotate(360deg)",
    fontFamily: theme.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "10px",
    color: "#FFFFFF",
  },
  card: {
    width: "100%",
    height: "96px",
    background: "#FFF2D3",
    borderRadius: "10px",
    color: "#F59604",
    lineHeight: "125px",
    textAlign: "center",
  },
  cardActive: {
    width: "100%",
    height: "96px",
    background: "#F59604",
    borderRadius: "10px",
    color: "#FFFFFF",
    lineHeight: "125px",
    textAlign: "center",
  },
  title: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "18px",
    lineHeight: "21px",
    color: "#3C4A6B",
    marginTop: "10px",
    textAlign: "center",
  },
  btnStyle: {
    background: "linear-gradient(180deg, #FFC000 -13.24%, #F28705 100%)",
    color: "#fff",
    height: "50px",
    width: "50px",
    fontFamily: theme.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: 900,
    fontSize: "10px",
    lineHeight: "19px",
    textAlign: "center",
  },
  speedDial: {
    position: "fixed",
    "& .MuiFab-primary:hover": {
      width: "50px",
      height: "50px",
      color: "#E54D40",
      backgroundColor: "#E5E5E5 !important",
    },
    "& .MuiFab-primary": {
      width: "50px",
      height: "50px",
      backgroundColor: "#F28705 !important",
    },
    "&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft": {
      bottom: theme.spacing(2),
      right: theme.spacing(1),
    },
    "&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight": {
      top: theme.spacing(2),
      left: theme.spacing(1),
    },
  },
  addDialogBtn: {
    width: "100%",

    textAlign: "center",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "21px",
    color: "#F28705",
  },
}));
