import React, { useEffect } from "react";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import SessionDetailsMobile from "../../../components/Common/Session/SessionDetails";
import MoreWebContainer from "../../../containers/Web/More";
import ReactGA from "react-ga";

function SessionDetails(props) {
  useEffect(() => {
    ReactGA.initialize(process.env.REACT_APP_TRACKING_ID);

    ReactGA.pageview(window.location.pathname);
  }, []);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      {isDesktop && <MoreWebContainer />}
      {isMobile && !isDesktop && <SessionDetailsMobile />}
    </>
  );
}

export default SessionDetails;
