import API from "../utils/API";
import {
  SET_ALL_CARDS,
  SHOW_MESSAGE,
  SET_STRIPE_LOADER,
  SET_STRIPE_SUCCESS,
  SET_EXTERNAL_ACCOUNT
} from "./types";
import { getCourseDetailById } from "./courses";
import { getChallengeDetail } from "./challenges";
import { getTrainingCourseDetailById, getTrainingCourseContent, getTrainingCourseParticipants } from "./retreatCourse";
import { getCurrentLoggedInUser } from "./user";
import { getSessionDetails } from "./Session";

export const getAllCustomerCards = ({ token }) => async dispatch => {
  dispatch({ type: SET_STRIPE_LOADER, payload: true });
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    }
  };

  try {
    let res = await API.get(`/api/v1/stripe/getAllCustomerCards`, config);
    dispatch({
      type: SET_ALL_CARDS,
      payload: res.data.data
    });
    dispatch({ type: SET_STRIPE_LOADER, payload: false });
  } catch (error) {

    dispatch({ type: SET_STRIPE_LOADER, payload: false });
  }
};

export const verifyStripeAccount = ({ token, country }) => async dispatch => {
  dispatch({ type: SET_STRIPE_LOADER, payload: true });
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    }
  };
  const body = JSON.stringify({ country });
  console.log("token", token);
  try {
    let res = await API.post(
      `/api/v1/stripe/getStripeConnectAccountUrl`,
      body,
      config
    );
    if (res?.data?.data?.url) {
      window.location.href = res?.data?.data?.url;
    }
    dispatch({ type: SET_STRIPE_SUCCESS, payload: true });
    dispatch({ type: SET_STRIPE_LOADER, payload: false });
    return dispatch(getAccountDetails({ token }));
  } catch (error) {
    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        title: `Could not verify Bank account`,
        message:
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : `something went wrong try after some time `,
        severity: "error"
      }
    });
    dispatch({ type: SET_STRIPE_LOADER, payload: false });
  }
};

export const addCustomerCard = ({ cardData, token }) => async dispatch => {
  dispatch({ type: SET_STRIPE_LOADER, payload: true });
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    }
  };
  const body = JSON.stringify({ ...cardData });
  try {
    let res = await API.post(`/api/v1/stripe/addCustomerCard`, body, config);
    dispatch(getAllCustomerCards({ token }));
    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        title: `Card Added`,
        message:
          res && res.data && res.data.message
            ? res.data.message
            : `Card Added `,
        severity: "success"
      }
    });
    dispatch({ type: SET_STRIPE_SUCCESS, payload: true });
    dispatch({ type: SET_STRIPE_LOADER, payload: false });
    return 'success'
  } catch (error) {
    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        title: `Could not Add card`,
        message:
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : `something went wrong try after some time `,
        severity: "error"
      }
    });
    dispatch({ type: SET_STRIPE_LOADER, payload: false });
  }
};

export const linkExternalBankAccount = ({
  bankData,
  token
}) => async dispatch => {
  dispatch({ type: SET_STRIPE_LOADER, payload: true });
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    }
  };
  const body = JSON.stringify({ ...bankData });
  console.log("token", token);
  try {
    let res = await API.post(`/api/v1/stripe/addBankAccount`, body, config);
    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        title: `Bank Linked Successfully`,
        message:
          res && res.data && res.data.message
            ? res.data.message
            : `Bank Linked Successfully `,
        severity: "success"
      }
    });
    dispatch(getCurrentLoggedInUser({ token: token }));
    dispatch({ type: SET_STRIPE_SUCCESS, payload: true });
    dispatch({ type: SET_STRIPE_LOADER, payload: false });
    return dispatch(getAccountDetails({ token }));
  } catch (error) {
    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        title: `Could not Link Bank account`,
        message:
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : `something went wrong try after some time `,
        severity: "error"
      }
    });
    dispatch({ type: SET_STRIPE_LOADER, payload: false });
  }
};

export const getAccountDetails = ({ token }) => async dispatch => {
  dispatch({ type: SET_STRIPE_LOADER, payload: true });
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    }
  };
  try {
    let res = await API.get(`/api/v1/stripe/getExternalLinkAccount`, config);
    dispatch({ type: SET_EXTERNAL_ACCOUNT, payload: res.data.data });
    dispatch({ type: SET_STRIPE_LOADER, payload: false });
  } catch (error) {
    console.log(error)
    dispatch({ type: SET_EXTERNAL_ACCOUNT, payload: null });
    dispatch({ type: SET_STRIPE_LOADER, payload: false });
  }
};

export const deleteLinkedAccount = ({ token }) => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    }
  };
  try {
    let res = await API.delete(`/api/v1/stripe/deleteLinkedAccount`, config);
    await dispatch(getAccountDetails({ token }));
    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        title: `Account deleted`,
        message:
          res && res.data && res.data.message
            ? res.data.message
            : `Account deleted successfully`,
        severity: "warning"
      }
    });
  } catch (error) {
    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        title: `Could not get delete account`,
        message:
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : `something went wrong try after some time `,
        severity: "error"
      }
    });
  }
};

export const deleteCustomerCard = ({ params, token }) => async dispatch => {
  dispatch({ type: SET_STRIPE_LOADER, payload: true });
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    params: {
      ...params
    }
  };

  try {
    let res = await API.delete(`/api/v1/stripe/deleteCustomerCard`, config);
    dispatch(getAllCustomerCards({ token }));
    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        title: `Card deleted`,
        message:
          res && res.data && res.data.message
            ? res.data.message
            : `Card deleted `,
        severity: "warning"
      }
    });
    dispatch({ type: SET_STRIPE_SUCCESS, payload: true });
    dispatch({ type: SET_STRIPE_LOADER, payload: false });
  } catch (error) {
    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        title: `Could not delete card`,
        message:
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : `something went wrong try after some time `,
        severity: "error"
      }
    });
    dispatch({ type: SET_STRIPE_LOADER, payload: false });
  }
};

export const joinCourse = ({ cardData, token }) => async dispatch => {
  dispatch({ type: SET_STRIPE_LOADER, payload: true });
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    }
  };
  const body = JSON.stringify({ ...cardData });
  try {
    let res = await API.post(`/api/v1/course/joinCourse`, body, config);
    dispatch(
      getCourseDetailById({
        courseId: res?.data?.data?.id,
        token
      })
    );
    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        title: `Payment Successful`,
        message:
          res && res.data && res.data.message
            ? res.data.message
            : `Payment Successful`,
        severity: "success"
      }
    });
    dispatch({ type: SET_STRIPE_SUCCESS, payload: true });
    dispatch({ type: SET_STRIPE_LOADER, payload: false });
  } catch (error) {
    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        title: `Payment could not processed`,
        message:
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : `something went wrong try after some time `,
        severity: "error"
      }
    });
    dispatch({ type: SET_STRIPE_LOADER, payload: false });
  }
};

export const joinChallenges = ({ token,data }) => async (dispatch) => {
  // set header
  dispatch({ type: SET_STRIPE_LOADER, payload: true });
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    }
  };
  const body = JSON.stringify({  ...data });

  try {
    let res = await API.post(`/api/v1/challenge/join-sessionOrChallenge`, body, config);
    console.log(res, "*******************")
     dispatch(
      getChallengeDetail({
        challengeId: res?.data?.data?.challengeId,
        token
      })
    );
    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        title: `Payment Successful`,
        message:
          res && res.data && res.data.message
            ? res.data.message
            : `Payment Successful`,
        severity: "success"
      }
    });
    dispatch({ type: SET_STRIPE_SUCCESS, payload: true });
    dispatch({ type: SET_STRIPE_LOADER, payload: false });
  } catch (error) {
    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        title: `Payment could not processed`,
        message:
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : `something went wrong try after some time `,
        severity: "error"
      }
    });
    dispatch({ type: SET_STRIPE_LOADER, payload: false });
  }
};

export const payCourse = ({ cardData, token }) => async dispatch => {
  dispatch({ type: SET_STRIPE_LOADER, payload: true });
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    }
  };
  const body = JSON.stringify({ ...cardData });
  try {
    let res = await API.patch(
      `/api/v1/course/payForFreemiumCourse`,
      body,
      config
    );
    dispatch(
      getCourseDetailById({
        courseId: cardData.courseId,
        token
      })
    );
    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        title: `Payment Successful`,
        message:
          res && res.data && res.data.message
            ? res.data.message
            : `Payment Successful`,
        severity: "success"
      }
    });
    dispatch({ type: SET_STRIPE_SUCCESS, payload: true });
    dispatch({ type: SET_STRIPE_LOADER, payload: false });
  } catch (error) {
    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        title: `Payment could not processed`,
        message:
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : `something went wrong try after some time `,
        severity: "error"
      }
    });
    dispatch({ type: SET_STRIPE_LOADER, payload: false });
  }
};

//join paid retreat
export const joinPaidRetreat = ({ cardData, token }) => async dispatch => {
  dispatch({ type: SET_STRIPE_LOADER, payload: true });
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    }
  };
  const body = JSON.stringify({ ...cardData });
  try {
    let res = await API.post(
      `/api/v1/trainingCourse/joinPaidTrainingCourse`,
      body,
      config
    );
    dispatch(getTrainingCourseContent({token, trainingCourseId: cardData.trainingCourseId}))
    dispatch(getTrainingCourseParticipants({ token, courseId: cardData.trainingCourseId }));
    dispatch(
      getTrainingCourseDetailById({
        courseId: cardData.trainingCourseId,
        token
      })
    );
    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        title: `Payment Successful`,
        message:
          res && res.data && res.data.message
            ? res.data.message
            : `Payment Successful`,
        severity: "success"
      }
    });
    dispatch({ type: SET_STRIPE_SUCCESS, payload: true });
    dispatch({ type: SET_STRIPE_LOADER, payload: false });
  } catch (error) {
    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        title: `Payment could not processed`,
        message:
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : `something went wrong try after some time `,
        severity: "error"
      }
    });
    dispatch({ type: SET_STRIPE_LOADER, payload: false });
  }
};

// Pay Session
export const joinPaidSession = ({
  cardData,
  token,
  userid
}) => async dispatch => {
  dispatch({ type: SET_STRIPE_LOADER, payload: true });
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    }
  };
  const body = JSON.stringify({ ...cardData });
  try {
    let res = await API.post(`/api/v1/session/join-paid-session`, body, config);
    dispatch(
      getSessionDetails({
        id: cardData?.sessionId,
        token,
        userid
      })
    );
    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        title: `Payment Successful`,
        message:
          res && res.data && res.data.message
            ? res.data.message
            : `Payment Successful`,
        severity: "success"
      }
    });
    dispatch({ type: SET_STRIPE_SUCCESS, payload: true });
    dispatch({ type: SET_STRIPE_LOADER, payload: false });
  } catch (error) {
    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        title: `Payment could not processed`,
        message:
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : `something went wrong try after some time `,
        severity: "error"
      }
    });
    dispatch({ type: SET_STRIPE_LOADER, payload: false });
  }
};



export const buySubscription = ({ subscriptionPlanId,customerId,cardId,interval, token }) => async dispatch => {
  // set header
  dispatch({ type: SET_STRIPE_LOADER, payload: true });

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    }
  };
  // set body
  const body = JSON.stringify({subscriptionPlanId, customerId, interval, cardId});
  try {
    const res = await API.post(
      `/api/v1/purchaseSubscription/buySubscription`,
      body,
      config
    );
    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        title: `Subscription`,
        message:
          res.data && res.data.message ? res.data.message : `subscribed successfully`,
        severity: "success"
      }
    });

    dispatch(getCurrentLoggedInUser({token}))
    dispatch({ type: SET_STRIPE_SUCCESS, payload: true });
    dispatch({ type: SET_STRIPE_LOADER, payload: false });

    return res.data.data
  } catch (error) {
    console.log(error);
    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        title: `Failed`,
        message:
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : `purchase subscription failed`,
        severity: "error",
      },
    });
    dispatch({ type: SET_STRIPE_LOADER, payload: false });

  }
};

export const upgradeSubscription = ({ subscriptionPlanId, subscriptionId, newPriceId, purchasedSubscriptionId, cardId, interval, token }) => async dispatch => {
  // set header
  dispatch({ type: SET_STRIPE_LOADER, payload: true });

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    }
  };
  // set body
  const body = JSON.stringify({subscriptionPlanId, interval, cardId,  subscriptionId, newPriceId, purchasedSubscriptionId});
  try {
    const res = await API.patch(
      `/api/v1/purchaseSubscription/updatePurchaseSubscription`,
      body,
      config
    );
    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        title: `Subscription`,
        message:
          res.data && res.data.message ? res.data.message : `subscription upgraded successfully`,
        severity: "success"
      }
    });

    dispatch(getCurrentLoggedInUser({token}))
    dispatch({ type: SET_STRIPE_SUCCESS, payload: true });
    dispatch({ type: SET_STRIPE_LOADER, payload: false });

    return res.data.data
  } catch (error) {
    console.log(error);
    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        title: `Failed`,
        message:
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : `purchase subscription failed`,
        severity: "error",
      },
    });
    dispatch({ type: SET_STRIPE_LOADER, payload: false });

  }
};