import React from 'react'
import { Grid, Card, Box, CircularProgress, LinearProgress, Typography, useMediaQuery, Tooltip } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { makeStyles, useTheme, withStyles } from "@material-ui/styles";
import { useIntl } from "react-intl";
import PropTypes from 'prop-types';
import Truncate from "react-truncate";

const BorderLinearProgress = withStyles((theme) => ({
    root: {
        height: 8,
        borderRadius: 5,
    },
    colorPrimary: {
        backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    },
    bar: {
        borderRadius: 5,
        backgroundColor: '#1a90ff',
    },
}))(LinearProgress);

function CircularProgressWithLabel(props) {
    const theme = useTheme()
    const classes = useStyles();
    let isMobile = useMediaQuery(theme.breakpoints.up("sm")) ? false : true;


    return (
        <Box position="relative" display="inline-flex">
            <CircularProgress variant="static" {...props} size={150} classes={{ circle: classes.circle }} />
            <Box
                top={0}
                left={0}
                bottom={0}
                right={0}
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                <Typography variant="caption" component="div" style={{ color: isMobile ? '#000000' : '#FFF', fontSize: '26px' }}>{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}

CircularProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate and static variants.
     * Value between 0 and 100.
     */
    value: PropTypes.number.isRequired,
};


function StatsOverview() {
    const theme = useTheme()
    const classes = useStyles();
    const intl = useIntl()
    let isMobile = useMediaQuery(theme.breakpoints.up("sm")) ? false : true;
    const challengesStats = useSelector(state => state?.challenges?.challengesStats)

    return (
        <Grid container>
            <Grid item xs={12}>
                <Typography className={classes.title}>
                    {intl.formatMessage({ id: "statsOverview" })}
                </Typography>
            </Grid>

            <Grid item xs={12} className={classes.top}>
                <Card className={isMobile ? classes.progressCardMobile : classes.progressCard} >
                    <Grid container>
                        <Grid item xs={12} style={{ textAlign: 'center' }}>
                            <CircularProgressWithLabel value={challengesStats?.overAllChallengesCompletionStatus || 0} />
                            <Grid item xs={12}>
                                <Typography className={classes.goalProgressTxt} style={{ color: isMobile && '#525252' }}>{challengesStats?.completedChallengesCount || 0}/{challengesStats?.totalChallengesCount || 0} Challenges are completed</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Card>
            </Grid>

            <Grid item xs={12} container >
                <Card className={isMobile ? classes.challengesStatsCard : classes.challengesStatsCardWeb} >

                    <Grid item xs={12} >
                        <Typography className={classes.subTitle}>
                            {isMobile ? intl.formatMessage({ id: "goalStats" }) : intl.formatMessage({ id: "challengesStats" })}
                        </Typography>
                    </Grid>

                    <Grid item xs={12} container>
                        {
                            challengesStats?.challenges?.length > 0 ? challengesStats?.challenges?.map((challenge) => {
                                return <Grid item xs={12} container key={challenge?.challenge?.id}>
                                    <Grid item xs={12} >
                                        <Tooltip title={challenge?.challenge?.title} >

                                            <Typography className={classes.courseNameTxt}>
                                                <Truncate lines={1} ellipsis={<span>...</span>} >
                                                    {`${challenge?.challenge?.title}`}
                                                </Truncate>
                                            </Typography>

                                        </Tooltip>
                                    </Grid>
                                    <Grid item xs={12} container>
                                        <Grid item xs={10} >
                                            <BorderLinearProgress variant="determinate"

                                                value={challenge?.challenge?.challengeCompletedPercentage}

                                            />
                                        </Grid>
                                        <Grid item xs={2} >
                                            <Typography className={classes.challengeOverviewTxt}>{isMobile ? 'Goal' : 'Challenge  ep'} {challenge?.challenge?.challengeCompletedDays}/{challenge?.challenge?.challengetotalDays}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            }) : <Grid item xs={12} className={classes.courseNameTxt} style={{ color: '#7d7b79', marginTop: '20px', textAlign: 'center' }}>No Challenge detail found</Grid>
                        }
                        {/* <Grid item xs={12} >
                            <Typography className={classes.courseNameTxt}>
                                Breathing
                            </Typography>
                        </Grid>
                        <Grid item xs={12} container>
                            <Grid item xs={10} >
                                <BorderLinearProgress variant="determinate" value={50} />
                            </Grid>
                            <Grid item xs={2} >
                                <Typography className={classes.challengeOverviewTxt}>{isMobile ? 'Goal' : 'Challenge'} 2/6</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} container>
                        <Grid item xs={12} >
                            <Typography className={classes.courseNameTxt}>
                                Meditation
                            </Typography>
                        </Grid>
                        <Grid item xs={12} container>
                            <Grid item xs={10} >
                                <BorderLinearProgress variant="determinate" value={50} />
                            </Grid>
                            <Grid item xs={2} >
                                <Typography className={classes.challengeOverviewTxt}>{isMobile ? 'Goal' : 'Challenge'} 2/6</Typography>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} container>
                        <Grid item xs={12} >
                            <Typography className={classes.courseNameTxt}>
                                Tantra
                            </Typography>
                        </Grid>
                        <Grid item xs={12} container>
                            <Grid item xs={10} >
                                <BorderLinearProgress variant="determinate" value={50} />
                            </Grid>
                            <Grid item xs={2} >
                                <Typography className={classes.challengeOverviewTxt}>{isMobile ? 'Goal' : 'Challenge'} 2/6</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} container>
                        <Grid item xs={12} >
                            <Typography className={classes.courseNameTxt}>
                                Yoga
                            </Typography>
                        </Grid>
                        <Grid item xs={12} container>
                            <Grid item xs={10} >
                                <BorderLinearProgress variant="determinate" value={50} />
                            </Grid>
                            <Grid item xs={2} >
                                <Typography className={classes.challengeOverviewTxt}>{isMobile ? 'Goal' : 'Challenge'} 2/6</Typography>
                            </Grid>
                        </Grid>*/}
                    </Grid>
                </Card>
            </Grid>
        </Grid>
    )
}

export default StatsOverview

const useStyles = makeStyles(theme => ({

    title: {
        fontFamily: theme.fontFamily.Roboto,
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '22px',
        lineHeight: '26px',
        color: '#717171',
        marginTop: '15px'
    },
    subTitle: {
        fontFamily: theme.fontFamily.Roboto,
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '18px',
        lineHeight: '21px',
        color: '#000000'
    },
    progressCard: {
        background: '#09A9BB',
        boxShadow: '0px 6px 15px rgba(0, 0, 0, 0.05)',
        borderRadius: '15px',
        width: '100%',
        height: '234px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    progressCardMobile: {
        background: '#FFF',
        boxShadow: '0px 6px 15px rgba(0, 0, 0, 0.05)',
        borderRadius: '15px',
        width: '100%',
        height: '234px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    circle: {
        color: '#F9A502',
    },
    goalProgressTxt: {
        fontFamily: theme.fontFamily.Roboto,
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '16px',
        lineHeight: '19px',
        color: '#FFFFFF',
        marginTop: '5px',
        display: 'block'
    },
    top: {
        marginTop: '30px'
    },
    courseNameTxt: {
        fontFamily: theme.fontFamily.Roboto,
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '14px',
        lineHeight: '16px',
        color: '#000000',
        margin: '10px 0px 5px 3px',
    },
    challengeOverviewTxt: {
        fontFamily: theme.fontFamily.Roboto,
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '12px',
        lineHeight: '14px',
        color: '#A9A9A9',
        marginLeft: '5px'
    },
    challengesStatsCard: {
        width: '100%',
        background: '#FFF',
        boxShadow: '0px 6px 15px rgba(0, 0, 0, 0.05)',
        borderRadius: '15px',
        padding: '20px 7px 15px 7px',
        marginTop: '30px'
    },
    challengesStatsCardWeb: {
        width: '100%',
        boxShadow: 'none',


        marginTop: '30px'
    }
}))