import React, { useState, useEffect } from "react";
import { Grid, Typography, Box, CircularProgress, useMediaQuery } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/styles";
import StartBtn from "../../assets/img/soloExerciseBtn.png";
import Play from "../../assets/Icons/play.svg";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import Countdown from 'react-countdown';

import useSound from "use-sound";
import moment from 'moment'

function SoloExercise(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [pause, setPause] = useState(false);
  const [option, setOPtion] = useState(props?.timerType);

  const [timer, setTimer] = useState(getTime);
  const [pTimer] = useState(getTime);
  const [remainingPer, setRemainingPer] = useState(100)
  const nowDate = moment().valueOf()


  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  function getTime() {


    var seconds = (props?.timer / 1000).toFixed(0);
    var minutes = Math.floor(seconds / 60);
    var hours = "";
    if (minutes > 59) {
      hours = Math.floor(minutes / 60);
      hours = (hours >= 10) ? hours : "0" + hours;
      minutes = minutes - (hours * 60);
      minutes = (minutes >= 10) ? minutes : "0" + minutes;
    }

    seconds = Math.floor(seconds % 60);

    console.log(hours);
    let obj = {
      "h": hours * 1,
      "m": minutes * 1,
      "s": seconds * 1
    };
    return (
      parseInt(obj?.h) +
      parseInt(obj?.m) +
      parseInt(obj?.s)
    );
  }


  useEffect(() => {
    if (!pause && timer > 0) {
      setTimeout(() => {
        setTimer(timer - 1);
      }, 1000);
    } else if ((option === "preparation" || option === "rest") && timer === 0) {

      return props?.startSession()
    }

  }, [timer, pause]);

  const handleSetPause = (value) => {
    setPause(value);
  };



  return (
    <Grid
      container
      style={{
        overflow: "hidden",
      }}
    >
      <Grid item xs={1} style={{ display: "flex", justifyContent: "flex-end" }} />

      <Grid item xs={10} style={{ justifyContent: "center", display: "flex", marginBottom: '25px' }}>
        <div
          className={classes.timer}
          style={{
            background: `url(${StartBtn})`, height: isMobile && '200px', minWidth: isMobile && '200px'
          }}
        >
          <Box position="relative" display="inline-flex">
            <CircularProgress
              variant="static"
              size={isMobile ? 145 : 180}
              thickness={1.7}
              style={{ color: "#FAA902" }}
              value={remainingPer}
            />
            <Box
              top={0}
              left={0}
              bottom={0}
              right={0}
              position="absolute"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Grid
                container
                alignItems="center"
                direction="column"
                justify="center"
              >
                <Grid item>
                  <Typography
                    variant="caption"
                    component="div"
                    className={classes.timerBody}
                    style={{ fontSize: isMobile && '10px' }}
                  >
                    {'Session will start after'}
                  </Typography>
                </Grid>

                <Grid item>
                  <Typography
                    variant="caption"
                    component="div"
                    className={classes.timerText}
                  >
                    <Countdown date={props?.time} onComplete={() => props.onComplete()} />
                  </Typography>
                </Grid>

              </Grid>
            </Box>
          </Box>
        </div>
      </Grid>
      <Grid item xs={1} />




    </Grid>
  );
}

export default SoloExercise;

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#244562",
  },
  timer: {
    minWidth: "271px",
    height: "271px",
    backgroundRepeat: `no-repeat !important`,
    backgroundSize: `100% 100% !important`,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  startImg: {
    marginTop: "30%",
    marginLeft: "20%",
    width: "120px",
    height: "120px",
    cursor: "pointer",
  },
  timerText: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "56px",
    letterSpacing: "0.045em",
    color: theme.colors.white,
  },
  playIcon: {
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    width: "46px",
    height: "46px",
    background: `linear-gradient(180deg, #153959 0%, #7B92A6 100%)`,
  },
  timerBody: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "18px",
    lineHeight: "21px",
    color: "#F28705",
  },
  timeLeftText: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "21px",
    letterSpacing: "0.04em",
    color: `rgba(255, 255, 255, 0.8)`,
  },
  timeLeft: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: 900,
    fontSize: "36px",
    lineHeight: "42px",
    letterSpacing: "0.045em",
    color: theme.colors.white,
  },
  endText: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "24px",
    lineHeight: "28px",
    color: "#F3887F",
    cursor: "pointer",
  },
}));
