import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import {
  Grid,
  Typography,
  CardContent,
  useMediaQuery
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import CloseIcon from "@material-ui/icons/Close";
import { useIntl } from "react-intl";

const MyDropzone = props => {
  const { files, setFiles, btnText, dropzoneText } = props;
  const classes = useStyles();
  const theme = useTheme();
  const intl = useIntl();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const onDrop = useCallback(acceptedFiles => {
    setFiles(acceptedFiles);
  }, []);

  const removeFiles = e => {
    setFiles(null);
  };

  const { getRootProps, rejectedFiles, getInputProps, open } = useDropzone({
    onDrop,
    noClick: true,
    disabled: props.disabled,
    accept: "video/*"
  });

  return (
    <div
      {...getRootProps()}
      disabled={props.disabled}
      className={classes.courseDescriptionImageCard}
    >
      <Grid
        container
        direction="column"
        justify="center"
        style={{
          textAlign: "center",
          position:'absolute',
          top:0,
          marginTop: "20%"
        }}
      >
        <div>
          <input disabled={props.disabled} {...getInputProps()} />
          {files && files ? (
            <Grid item xs={11} sm={11} md={11} lg={11} xl={11} container>
              <Grid
                item
                xs={11}
                sm={11}
                md={11}
                lg={11}
                xl={11}
                direction="row"
                alignItems="center"
                justify="center"
                className={classes.fileName}
                style={{ maxWidth: isMobile && !isDesktop ? "15em" : "22em" }}
              >
                {rejectedFiles && rejectedFiles.length > 0 ? (
                  <Typography>
                    {intl.formatMessage({ id: "videoUploadMessage" })}
                  </Typography>
                ) : (
                  <Typography>{files && files?.name} </Typography>
                )}
              </Grid>
              <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                <CloseIcon
                  onClick={e => {
                    removeFiles(e);
                  }}
                />
              </Grid>
            </Grid>
          ) : (
            <div style={{ textAlign: "center" }}>
              <Typography className={classes.cardText}>
                {dropzoneText}
              </Typography>
              <Typography className={classes.cardText2}>
                {intl.formatMessage({ id: "or" })}
              </Typography>
            </div>
          )}
          <div
            onClick={open}
            style={{
              cursor: props.disabled ? "default" : "pointer",
              backgroundColor: props.disabled ? "#C2C2C2" : "#FBAE02"
            }}
            className={classes.cardButton}
          >
            <Typography>{btnText}</Typography>
          </div>
        </div>
      </Grid>
    </div>
  );
};
export default MyDropzone;

const useStyles = makeStyles(theme => ({
  courseDescriptionImageCard: {
    position: "relative",
    height: 0,
    paddingTop: `56.25%`,
    borderRadius: "20px",
    backgroundSize: "cover",
    borderStyle: "dashed",
    borderColor: "rgb(0, 0, 0,0.2)"
  },
  fileName: {
    overflowX: "hidden",
    
  },
  cardButton: {
   
    textAlign: "center",
    cursor: "pointer",
    width: "18em",
    height: "2.488em",
    borderRadius: "20px",
    backgroundColor: "#FBAE02",
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: 300,
    fontSize: "10px",
    lineHeight: "10px",
    color: "#FFFFFF",
    marginTop: "20px",
    margin: "auto"
  },
  cardText: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "21px",
    color: "#363636"
  },
  cardText2: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "16px",
    color: "#363636"
  }
}));
