import React from 'react';
import { Grid, Typography } from '@material-ui/core'
import { SearchOutlined } from '@material-ui/icons'
import headerMoreIcon from '../../../assets/Icons/headerMoreIcon.svg'
import { makeStyles, useTheme } from "@material-ui/styles";
import { useHistory } from 'react-router-dom'
import searchIcon from '../../../assets/Icons/BottomNavigationIcons/serchIcon.svg'


function HeaderWithMoreSearchIcon(props) {
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();

    return (
        <Grid container className={classes.container}>
            <Grid item xs={2} justifyContent='center' className={classes.center}>
                <img src={headerMoreIcon} onClick={() => history.push('/more')} />
            </Grid>
            <Grid item xs={8} justifyContent='center'>
                <Typography className={classes.heading}>{props?.text}</Typography>
            </Grid>
            <Grid item xs={2} justifyContent='center' className={classes.center}>
                <img src={searchIcon} className={classes.iconStyle} onClick={() => props?.openMobileSearchBar()} />
            </Grid>
        </Grid>
    );
}

export default HeaderWithMoreSearchIcon;

const useStyles = makeStyles((theme) => ({
    container: {
        position: 'fixed',
        height: '72px',
        display: 'flex',
        alignItems: 'center',
        zIndex: 100,
        borderBottom: '1px solid #EAEAEA',
        backgroundColor: "#FFF"
    },
    heading: {
        fontFamily: 'Reef',
        fontStyle: 'normal',
        fontWeight: 900,
        fontSize: '28px',
        lineHeight: '38px',
        color: '#343434',
        textAlign: 'center',
        marginTop: '-5px',

    },
    iconStyle: {
        color: '#606770', fontSize: '30px', textAlign: 'center'
    },
    center: {
        textAlign: 'center'
    }
}));