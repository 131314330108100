import {
  Button,
  Card,
  Grid,
  TextField,
  Typography,
  useMediaQuery,
  FormControl,
  Radio,
  RadioGroup,
  FormControlLabel,
  IconButton,
  Tooltip,
  Dialog,
  DialogContent,
  DialogTitle,
  Chip
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import { Share, InsertInvitation, Close } from "@material-ui/icons";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import AddImage from "./AddImage/Index";
import { useIntl } from "react-intl";
import InviteAndShare from '../../../components/Common/InviteAndShare'
import ImgNotFound from "../../../assets/img/imgNotFound.png";
import clsx from 'clsx';
import moment from 'moment';

const DescriptionFormContainer = (props) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const intl = useIntl();
  const params = useParams();
  const [deliverStatus, setDeliverStatus] = useState('');

  const [image, setImage] = useState(
    props.selectedSession && props.selectedSession.imageUrl
      ? props.selectedSession.imageUrl
      : ""
  );

  const [session_name, setSessionName] = useState(
    props.selectedSession && props.selectedSession.name
      ? props.selectedSession.name
      : ""
  );
  const [sessionPrice, setSessionPrice] = useState(
    props.selectedSession && props.selectedSession.price
      ? props.selectedSession.price
      : ""
  );
  const [goal, setGoal] = useState(
    props.selectedSession && props.selectedSession.goal
      ? props.selectedSession.goal
      : ""
  );
  const [meetingId, setMeetingId] = useState(
    props.selectedSession && props.selectedSession.meetingId
      ? props.selectedSession.meetingId
      : ""
  );
  const [shareOpen, setShareOpen] = useState(false);


  const classes = useStyles();



  useEffect(() => {
    setShareOpen(props?.shareOpen)
  }, [props?.shareOpen])

  const handleChangeMeetingUrl = (e) => {
    setMeetingId(e.target.value);
  };

  function imageNotFound() {
    setImage(ImgNotFound);
  }

  const loadImage = (variable) => {
    console.log(variable);
    let image = new Image();
    image.src = variable;
    image.onerror = imageNotFound;

    return true;
  };

  function StyledRadio(props) {
    const classes = useStyles();

    return (
      <Radio
        className={classes.root}
        classes={{ root: classes.root }}
        disableRipple
        color="default"
        checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
        icon={<span className={classes.icon} />}
        {...props}
      />
    );
  }

  useEffect(()=>{
    if(props?.selectedSession?.status === 'Active' && props?.selectedSession?.sessionDates?.length > 0){
      
        if(moment().isBefore(moment(props?.selectedSession?.sessionDates[0]?.dateTime, 'x').local())){
          setDeliverStatus('Scheduled')
        }else if(moment().isAfter(moment(props?.selectedSession?.sessionDates[0]?.endingDateTime, 'x').local())){
          setDeliverStatus('Delivered')
        }else{
          setDeliverStatus('Live')
        }
      
    } else {
      setDeliverStatus(props?.selectedSession?.status)
    }
  },[])

 



  return (
    <>
      {shareOpen && (
        <Dialog open={shareOpen} onClose={() => setShareOpen(false)}>
          <DialogTitle>
            <Grid container justify="space-between">
              <Grid item>
                <Typography component="h4" variant="h5">
                  {shareOpen}
                </Typography>
              </Grid>
              <Grid item>
                <Button onClick={() => setShareOpen(false)}>
                  <Close />
                </Button>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent style={{ padding: isMobile && 0 }}>
            <InviteAndShare
              url={`/share/session/${params?.id}`}
              imageUrl={props?.selectedSession?.imageUrl}
              sumaryText={'Join this amazing session'}
              link={'Hey there,  Check this out, I think you will love it'}
              type='session'
              buttonText="close"
              invite={shareOpen === "Invite"}
              share={shareOpen === "Share"}
              handleNext={() => setShareOpen(false)}
            />
          </DialogContent>
        </Dialog>
      )}


      <Grid
        container
        style={{
          backgroundColor: isMobile ? "#FBFAF9" : "",
        }}
      >

        <Grid style={{
          position: "relative",
          top: "39px",
          height: '23px'
        }} container justify="flex-end">

          {<Chip label={deliverStatus} style={{
            color: "#FFF",
            background:`${deliverStatus === 'Live' ?  '#39a746' : deliverStatus === 'Scheduled' ? '#39a746' : '#fbae02' }`,
            zIndex: 1
          }} />}
        </Grid>
        <Grid item xs={12} style={{ marginTop: "1rem" }}>
          <Card
            elevation={0}
            style={{
              backgroundColor: isMobile
                ? "#FBFAF9"
                : "rgb(251, 250, 249)",
            }}
          >
            <AddImage
              btnText={intl.formatMessage({ id: "addImage" })}
              image={loadImage(image) && image}
            />
          </Card>
        </Grid>

        {!props?.selectedSession?.isExpired && <Grid item xs={12} container justify='flex-end' spacing={1} >
          <Grid item>
            <Tooltip title={<span style={{ fontSize: '14px', letterSpacing: 0.30 }}>Invite</span>} placement='top'>
              <IconButton
                disabled={props?.selectedSession?.status !== 'Active'}
                onClick={() => setShareOpen("Invite")}
                className={classes.inviteAndShare}
              >
                <InsertInvitation />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item>
            <Tooltip title={<span style={{ fontSize: '14px', letterSpacing: 0.30 }}>Share</span>} placement='top'>
              <IconButton
                disabled={props?.selectedSession?.status !== 'Active'}
                onClick={() => setShareOpen("Share")}
                className={classes.inviteAndShare}
              >
                <Share />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>}

        {/* <Grid
          item
          xs={12}>
          <TextField
            disabled={true}
            id="standard-multiline-static"
            fullWidth
            value={props?.selectedSession && props.selectedSession?.type}
            className={classes.nameTextField}

            margin="normal"
            label={intl.formatMessage({ id: "sessionType" })}
          />
        </Grid> */}

        {props?.selectedSession?.status === 'Rejected' && <Grid item xs={12}>
          <TextField
            disabled={true}
            id="standard-multiline-static"
            classes={{
              root: classes.rejectReasonField,
            }}
            fullWidth
            value={props?.selectedSession?.rejectReason}
            margin="normal"
            rows={6}
            multiline
            label={
              <span style={{ padding: "10px 0 0 0px" }}>
                {intl.formatMessage({ id: "Reject Reason" })}
              </span>
            }
          />
        </Grid>}

        <Grid
          item
          xs={12}>
          <TextField
            disabled={true}
            id="standard-multiline-static"
            fullWidth
            value={props.selectedSession && props.selectedSession.plan }
            className={classes.nameTextField}
            margin="normal"
            label={intl.formatMessage({ id: "sessionPlan" })}
          />
        </Grid>

        <Grid
          item
          xs={12}>
          {props.selectedSession && !props.selectedSession.plan === 'paid' && <TextField
            disabled={true}
            id="standard-multiline-static"
            fullWidth
            value={sessionPrice}
            className={classes.nameTextField}
            margin="normal"
            label={intl.formatMessage({ id: "Price" })}
          />}
        </Grid>

        <Grid
          item
          xs={12}
        >
          <TextField
            disabled={true}
            id="standard-multiline-static"
            fullWidth
            value={session_name}
            className={classes.nameTextField}
            onChange={(e) => setSessionName(e.target.value)}
            margin="normal"
            label={intl.formatMessage({ id: "name" })}
          />
        </Grid>

        {props.screen === "live" && isMobile ? (
          ""
        ) : (
            <Grid item xs={12}>
              <TextField
                disabled={true}
                id="standard-multiline-static"
                classes={{
                  root: classes.goalTextArea,
                }}
                fullWidth
                value={goal}
                onChange={(e) => setGoal(e.target.value)}
                margin="normal"
                rows={6}
                multiline
                label={
                  <span style={{ padding: "10px 0 0 0px" }}>
                    {intl.formatMessage({ id: "goal" })}
                  </span>
                }
              />
            </Grid>
          )}
      </Grid>
    </>
  );
};

export default DescriptionFormContainer;

const useStyles = makeStyles((theme) => ({
  root: {
    "&:hover": {
      backgroundColor: "transparent",
    },
    "& body": {
      fontFamily: theme.fontFamily.Roboto,
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "18px",
      lineHeight: "21px",
      color: "#3C4A6B",
    },
  },
  icon: {
    borderRadius: "50%",
    width: 30,
    height: 30,
    boxShadow: "inset 0 0 0 1px #E5E5E5, inset 0 -1px 0 #E5E5E5",
    backgroundColor: "#E5E5E5",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "$root.Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: "#ebf1f5",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)",
    },
  },
  checkedIcon: {
    backgroundColor: "#E5E5E5",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 30,
      height: 30,
      backgroundImage: "radial-gradient(#F79E03,#F79E03 28%,transparent 32%)",
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: "#E5E5E5",
    },
  },
  radioTxt: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "21px",
    color: "#3C4A6B",
    marginTop: "12px",
  },
  homeSearchField: {
    "& .MuiInputBase-root": {
      fontFamily: theme.fontFamily.Roboto,
      fontStyle: "normal",
      width: "100%",
      borderRadius: "10px",
      fontWeight: 500,
      fontSize: "18px",
      lineHeight: "21px",
      color: "#3C4A6B",
    },
    "& .MuiBadge-colorPrimary": {},

    "& .MuiInput-underline:before": {
      border: "0px solid",
    },
    "& .MuiInput-underline::after": {
      border: "0px solid",
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled)::before": {
      border: "0px solid",
    },
    "& .MuiInputBase-input ": {
      padding: "0px",
    },
  },
  mobilePadding: {
    marginLeft: "20px",
    marginRight: "20px",
  },
  mobilePaddingBtn: {
    marginLeft: "10px",
    marginRight: "10px",
  },
  meetingTextHeader: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "18px",
    lineHeight: "21px",
    color: "#9E9393",
  },
  meetingText: {
    width: "100%",
    height: "2.750em",
    backgroundColor: "#C4C4C4",
    borderRadius: "5px",
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "21px",
    marginLeft: "0px!important",
    color: "#3C4A6B",
  },
  courseDescriptionImageCard: {
    height: "15.438em",
    backgroundColor: "#FEEFCC",
    borderRadius: "20px",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "top center",
  },
  cardButton: {
    width: "18em",
    height: "2.488em",
    borderRadius: "20px",
    backgroundColor: "#FBAE02",
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: 300,
    fontSize: "10px",
    lineHeight: "10px",
    color: "#FFFFFF",
    marginTop: "20px",
    margin: "auto",
  },
  cardText: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "16px",
    color: "#363636",
  },
  profile_image: {
    padding: "10px",
    cursor: "pointer",
  },

  goalTextArea: {
    "& .MuiInputBase-multiline": {
      border: `1px solid #FAAC02`,
      padding: `10px 10px`,
      borderRadius: "10px",
      fontFamily: theme.fontFamily.Roboto,
      fontStyle: "normal",
      fontWeight: 'normal',
      fontSize: "16px",
      letterSpacing: "0.015em",
      lineHeight: "23px",
      color: "#606060",
    },
    "& .MuiInputLabel-root": {
      fontFamily: theme.fontFamily.Roboto,
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "20px",
      letterSpacing: "0.015em",
      lineHeight: "16px",
      color: "#606060",
      "&::after": {
        fontFamily: theme.fontFamily.Roboto,
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "20px",
        lineHeight: "16px",
        letterSpacing: "0.015em",
        color: "#606060",
        paddingLeft: 0
      },
    },

    "& .MuiInput-underline:before": {
      borderBottom: "0px solid #FAAC02",
      borderTop: "none",
    },
    "& .MuiInput-underline::after": {
      borderBottom: "0px solid #FAAC02",
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled)::before": {
      borderBottom: "0px solid #FAAC02",
    },
  },
  rejectReasonField: {
    "& .MuiInputBase-multiline": {
      border: `1px solid red`,
      padding: `10px 10px`,
      borderRadius: "10px",
      fontFamily: theme.fontFamily.Roboto,
      fontStyle: "normal",
      fontWeight: 'normal',
      fontSize: "16px",
      letterSpacing: "0.015em",
      lineHeight: "23px",
      color: "#606060",
    },
    "& .MuiInputLabel-root": {
      fontFamily: theme.fontFamily.Roboto,
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "20px",
      letterSpacing: "0.015em",
      lineHeight: "16px",
      color: "#606060",
      "&::after": {
        fontFamily: theme.fontFamily.Roboto,
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "20px",
        lineHeight: "16px",
        letterSpacing: "0.015em",
        color: "#606060",
        paddingLeft: 0
      },
    },

    "& .MuiInput-underline:before": {
      borderBottom: "0px solid red",
      borderTop: "none",
    },
    "& .MuiInput-underline::after": {
      borderBottom: "0px solid red",
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled)::before": {
      borderBottom: "0px solid red",
    },
  },
  nameTextField: {
    "& .MuiInput-root": {
      fontFamily: theme.fontFamily.Roboto,
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "16px",
      letterSpacing: "0.015em",
      lineHeight: "23px",
      color: "#606060",
    },
    "& .MuiInputBase-multiline": {
      padding: `10px 10px`,
    },
    "& .MuiInputLabel-root": {
      fontFamily: theme.fontFamily.Roboto,
      fontWeight: 500,
      fontSize: "20px",
      lineHeight: "20px",
      color: "#606060",
      "&::after": {
        fontFamily: theme.fontFamily.Roboto,
        fontWeight: 500,
        fontSize: "20px",
        lineHeight: "20px",
        color: "#606060",
      },
    },

    "& .MuiInput-underline:before": {
      borderBottom: "1px solid rgba(168, 182, 200, 0.3)",
      borderTop: "none",
    },
    "& .MuiInput-underline::after": {
      borderBottom: "1px solid rgba(168, 182, 200, 0.3)",
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled)::before": {
      borderBottom: "1px solid rgba(168, 182, 200, 0.3)",
    },
  },
  inviteAndShare: {
    background: "linear-gradient(360deg, #15AEBF 0%, #A6D6FF 100%)",
    marginTop: "1rem",
    fontSize: "20px",
    lineHeight: "10px",
    color: "#FFFFFF"
  },
}));
