import * as React from "react";

interface IBeforeInstallPromptEvent extends Event {
  readonly platforms: string[];
  readonly userChoice: Promise<{
    outcome: "accepted" | "dismissed";
    platform: string;
  }>;
  prompt(): Promise<void>;
}

export function useAddToHomescreenPrompt(): [
      IBeforeInstallPromptEvent | null,
  () => void,
  boolean // Browser support flag
] {
  const [prompt, setPrompt] = React.useState<IBeforeInstallPromptEvent | null>(
      null
  );
  const [isSupported, setIsSupported] = React.useState<boolean>(false);

  const promptToInstall = () => {
    if (prompt) {
      return prompt.prompt();
    }
    return Promise.reject(
        new Error(
            'Tried installing before the browser sent "beforeinstallprompt" event'
        )
    );
  };

  React.useEffect(() => {
    const ready = (e: IBeforeInstallPromptEvent) => {
      e.preventDefault();
      setPrompt(e);
    };

    const checkSupport = () => {
      setIsSupported(!!window.addEventListener);
    };

    checkSupport(); // Check support when the component mounts

    if (isSupported) {
      window.addEventListener("beforeinstallprompt", ready as any);

      return () => {
        window.removeEventListener("beforeinstallprompt", ready as any);
      };
    }
  }, [isSupported]);

  return [prompt, promptToInstall, isSupported];
}
