import React from 'react'
import { Grid, Avatar, Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
function SendRequestCard(props) {
    const classes = useStyles();


    return (
        <Box className={classes.container} style=
            {{ background: '#FFF' }}>
            <Grid container >
                <Grid item xs={2}>
                     <Avatar src={props?.friend?.pictureUrl} className={classes.large} /></Grid>
                <Grid item xs={6}>
                    <Typography className={classes.title} >{props?.friend?.name}</Typography>

                </Grid>
                <Grid item xs={4} style={{ textAlign: 'center' }}>
                   <Typography className={classes.timeAgo} >{props?.friend?.status ==='sent'?<span style={{ color: '#CFCFCF',cursor:'none' }}>Send</span> :  <span style={{ color: '#F28705' }} onClick={props?.sendRequest}>Send Request</span>}</Typography> 
                </Grid>

            </Grid>
        </Box>

    )
}

export default SendRequestCard

const useStyles = makeStyles(theme => ({
    container: {
        height: '80PX',
        width: '100%',
        background: '#fbfaf9',
        borderBottom: '1px solid #E9E9E9',
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        padding: '0 5px 0 5px'
    },
    title: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "14px",
        lineHeight: "16px",
        color: '#797979',
        marginTop: '15px'

    },
    timeAgo: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: 'normal',
        fontSize: "12px",
        lineHeight: "14px",
        marginTop: '15px',
        opacity: 0.7,


    },
    

}))