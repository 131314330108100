// types
import {
  SET_CATEGORIES,
  LOG_OUT,
  SET_PLANS,
  ADD_SUCCESSFUL,
  SET_EPISODES,
  REMOVE_EPISODES,
  SET_COURSE,
  UNSET_COURSE,
  COURSE_LOADER_FALSE,
  COURSE_LOADER_TRUE,
  COURSE_DETAIL,
  COURSE_REVIEWS,
  LATEST_CATEGORIES,
  ADD_COURSE_RATING,
  ADD_COURSE_REVIEW,
  GET_NEW_COURSES,
  GET_RECENT_COURSES,
  GET_USER_UPLOADED_COURSES,
  GET_USER_ENROLLED_COURSES,
  GET_QUOTE,
  GET_COURSES_GROUP_BY_CATEGORY,
  SEARCH_NEW_COURSES,
  FAVORITE_COURSE,
  EPISODE_DETAIL,
  GET_COURSES_BY_CATEGORY,
  JOIN_FREE_COURSE,
  COURSE_STATS_DETAIL,
  SEARCH,
  COURSE_LOADER,
  PREVIEW_VIDEO_PROGRESS,
  SET_CATEGORIES_LIST,
} from "../actions/types";

// initial state
const initialState = {
  categoriesList: [],
  allCategoriesList: [],
  latestCategoriesList: [],
  plansList: [],
  courseData: null,
  add_success: false,
  courseLoader: false,
  step: 0,
  episodes: [],
  courseDetail: {},
  courseStats: {},
  episodeDetail: {},
  courseReviews: null,
  newReview: null,
  newRating: null,
  latestCourses: {
    filter: false,
    latestCoursesList: [],
  },
  recentCoursesList: null,
  userUploadedCourses: null,
  userEnrolledCoursesList: null,
  quoteOfTheDay: {},
  coursesGroupByCategoryList: null,
  coursesByCategory: [],
  episodeVideoProgress: [],
  episodeThumbnailProgress: 0,
  searchData: [],
  courseLoader: false,
  coursePreviewProgress: 0,
  videoUploadLoaders: [],
};

// handle actions
export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_COURSES_GROUP_BY_CATEGORY:
      return {
        ...state,
        coursesGroupByCategoryList: payload,
      };
    case SEARCH:
      return {
        ...state,
        searchData: payload,
      };
    case GET_COURSES_BY_CATEGORY:
      return {
        ...state,
        coursesByCategory: payload,
      };
    case PREVIEW_VIDEO_PROGRESS:
      return {
        ...state,
        coursePreviewProgress: payload,
      };
    case COURSE_LOADER:
      return {
        ...state,
        courseLoader: payload,
      };
    case GET_NEW_COURSES:
      console.log("this is test", payload);
      return {
        ...state,
        latestCourses: {
          ...state.latestCourses,
          filter: true,
          latestCoursesList: payload,
        },
      };
    case SEARCH_NEW_COURSES:
      return {
        ...state,
        latestCourses: {
          ...state.latestCourses,
          filter: true,
          latestCoursesList: payload,
        },
      };
    case FAVORITE_COURSE:
      return {
        ...state,
        courseDetail: { ...state.courseDetail, isUserFan: payload },
      };
    case GET_USER_UPLOADED_COURSES:
      return {
        ...state,
        userUploadedCourses: payload,
      };
    case GET_USER_ENROLLED_COURSES:
      return {
        ...state,
        userEnrolledCoursesList: payload,
      };
    case GET_RECENT_COURSES:
      return {
        ...state,
        recentCoursesList: payload,
      };
    case COURSE_DETAIL:
      return {
        ...state,
        courseDetail: payload,
      };
    case COURSE_STATS_DETAIL:
      return {
        ...state,
        courseStats: payload,
      };
    case JOIN_FREE_COURSE:
      return {
        ...state,
        courseDetail: { ...state.courseDetail, isUserTaking: true },
      };
    case "EPISODE_VIDEO_PROGRESS":
      return {
        ...state,
        episodeVideoProgress: state.episodeVideoProgress.filter(
          (item) => item.episode_id === payload.episode_id
        )[0]
          ? state.episodeVideoProgress.filter(
              (item) =>
                item.episode_id === payload.episode_id && item.percentage < 100
            )[0]
            ? state.episodeVideoProgress.map((item) => {
                if (item.episode_id === payload.episode_id) {
                  return payload;
                } else {
                  return item;
                }
              })
            : state.episodeVideoProgress.filter(
                (item) => item.episode_id !== payload.episode_id
              )
          : [...state.episodeVideoProgress, payload],
      };
    case "EPISODE_THUMBNAIL_PROGRESS":
      return {
        ...state,
        episodeThumbnailProgress: payload,
      };
    case "EPISODE_VIDEO_LOADER":
      return {
        ...state,
        videoUploadLoaders: state.videoUploadLoaders.includes(payload)
          ? state.videoUploadLoaders.filter((item) => item !== payload)
          : [...state.videoUploadLoaders, payload],
      };
    case EPISODE_DETAIL:
      return {
        ...state,
        episodeDetail: payload,
      };
    case COURSE_REVIEWS:
      return {
        ...state,
        courseReviews: payload,
      };
    case ADD_COURSE_REVIEW:
      return {
        ...state,
        newReview: payload,
      };
    case ADD_COURSE_RATING:
      return {
        ...state,
        newRating: payload,
      };
    case SET_CATEGORIES:
      return {
        ...state,
        categoriesList: payload,
      };
    case SET_CATEGORIES_LIST:
      return {
        ...state,
        allCategoriesList: payload,
      };
    case LATEST_CATEGORIES:
      return {
        ...state,
        latestCategoriesList: payload,
      };
    case SET_PLANS:
      return {
        ...state,
        plansList: payload,
      };
    case UNSET_COURSE:
      return {
        ...state,
        courseData: null,
      };
    case SET_COURSE:
      //    console.log("this is test", payload);
      return {
        ...state,
        courseData: {
          ...state.courseData,
          ...payload,
        },
      };
    case GET_QUOTE:
      return {
        ...state,
        quoteOfTheDay: { ...payload },
      };
    case COURSE_LOADER_FALSE:
      return {
        ...state,
        courseLoader: false,
      };
    case COURSE_LOADER_TRUE:
      return {
        ...state,
        courseLoader: true,
      };
    case ADD_SUCCESSFUL:
      return {
        ...state,
        add_success: payload,
      };
    case SET_EPISODES:
      return {
        ...state,
        episodes: state.episodes.filter((epi) => epi.id === payload.id)[0]
          ? [
              ...state.episodes,
              {
                ...state.episodes.filter((epi) => epi.id === payload.id)[0],
                ...payload,
              },
            ]
          : [...state.episodes, payload],
        courseData: {
          ...state.courseData,
          episodes:
            state.courseData?.id === payload.courseId
              ? state.courseData?.episodes?.length > 0
                ? state.courseData.episodes.filter(
                    (ep) => ep.id === payload.id
                  )[0]
                  ? state.courseData.episodes.map((ep) => {
                      if (ep.id === payload.id) {
                        return payload;
                      }
                      return ep;
                    })
                  : [...state.courseData.episodes, payload]
                : [payload]
              : [],
        },
      };
    case "SET_ALL_EPISODES":
      return {
        ...state,
        episodes: payload,
        courseData: {
          ...state.courseData,
          episodes: payload,
        },
      };
    case REMOVE_EPISODES:
      // console.log(payload)
      return {
        ...state,
        episodes: state.episodes.filter((epi) => epi.id !== payload.episodeId),
        courseData: {
          ...state.courseData,
          episodes:
            state.courseData?.id === payload.courseId
              ? state.courseData.episodes.filter(
                  (ep) => ep.id !== payload.episodeId
                )
              : [],
        },
      };
    case LOG_OUT:
      return {
        categoriesList: [],
        plansList: [],
        courseData: null,
        add_success: false,
        courseLoader: false,
        step: 0,
        episodes: [],
      };

    default:
      return state;
  }
}
