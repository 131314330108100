import React, { useEffect, useState } from 'react';
import { IconButton, Grid, CircularProgress, Typography, Tooltip } from '@material-ui/core'
import { Lock } from '@material-ui/icons'
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Truncate from "react-truncate";
import moment from 'moment'



function ProgressCard(props) {
    const classes = useStyles();

    return (
        <Grid container className={classes.container}>
            <Grid item item xs={8} container spacing={1} className={classes.container}>
                <Grid item xs={3}>
                    <IconButton color="primary" aria-label="upload picture" component="span" style={{ background: '#E6E6E6', color: '#959595', height: '15px', width: '15px' }}>
                        <Lock />
                    </IconButton>
                </Grid>
                <Grid item xs={9}>

                    <Tooltip title={props?.lesson?.name}>
                        <Typography className={classes.content}>
                            <Truncate lines={2} ellipsis={<span>...</span>} >
                                {props?.lesson?.name}
                            </Truncate>
                        </Typography>
                    </Tooltip>
                </Grid>

            </Grid>
            <Grid item xs={4} >
                <Typography className={classes.content} style={{ justifyContent: 'flex-end', textAlign: 'right' }}>
                    {props?.lesson?.type === 'live' ? `Live: ${moment.utc(props?.lesson?.date).local().format('DD/MM/YYYY')} ${moment.utc(props?.lesson?.startDateTime).local().format('hh:mm A')}` : "Recorded"}
                </Typography>

            </Grid>

        </Grid>
    );
}

export default ProgressCard;

const useStyles = makeStyles(theme => ({
    content: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '16px',
        lineHeight: '100%',
        color: '#959595',
        display: 'flex',
        alignItems: 'center',
        display: 'flex',
        alignItems: 'center',
    },
    container: {
        display: 'flex',
        alignItems: 'center',
        height: '70px'
    },

    center: {
        display: 'flex',
        alignItems: 'center'
    },

}));