import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import StartChallengeWebContainer from "../../../containers/Web/StartChallenge";
import { getChallengeDetail } from "../../../actions/challenges";
import { Grid, CircularProgress } from "@material-ui/core";

function StartChallenge() {

  const { id } = useParams();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state?.auth);
  const [localLoader, setLocalLoader] = useState(false);

  useEffect(() => {

    getData()
  }, []);

  const getData = async () => {
    setLocalLoader(true)
    const responseData = await dispatch(
      getChallengeDetail({
        userid: auth?.user,
        token: auth?.token,
        challengeId: id,
      })
    );


    if (responseData?.status === 'success') {
      setLocalLoader(false)
      return
    } else if (responseData?.status === 'fail') {
      setLocalLoader(false)
    } else if (responseData) {
      setLocalLoader(false)
    }
  }




  return (
    <>
      {localLoader ? <Grid item xs={12} style={{ height: '100vh', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
        <CircularProgress color="primary" style={{ color: '#09A9BB' }} size={30} />
      </Grid> :
        <StartChallengeWebContainer />}

    </>
  );
}

export default StartChallenge;
