import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { Grid } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import lockIcon from "../../../assets/Icons/lockIcon.svg";
import lockOpenIcon from "../../../assets/Icons/lockOpenIcon.svg";
import Truncate from "react-truncate";



const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    borderRadius: "11px",
    transform: "rotate(360deg)",
    marginBottom: "50px"
  },
  imageContainer: {
    height: "0",
    paddingTop: '56.25%',
    borderRadius: "9px",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover"
  },
  Title: {
    fontFamily: theme.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: 900,
    fontSize: "12px",
    lineHeight: "22px",
    color: "rgba(0, 0, 0, 0.8)",
    paddingTop:'10px'
  },
  subContent: {
    fontFamily: theme.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: 900,
    fontSize: "14px",
    lineHeight: "16px",
    marginTop: "5px",
    color: "rgba(0, 0, 0, 0.8)"
  },
  lockIcon: {
    height: "3.438em",
    width: "3.438em",
    borderRadius: "100%",
    backgroundColor: "#FFF",
    marginTop: "40%"
  },
  activeEpisode: {
    background: "#52b202",
    color: "#fff"
  },
  inProgressEpisode: {
    background: "#ff9100",
    color: "#fff"
  },
  blockedEpisode: {
    background: "#f44336",
    color: "#fff"
  }
}));

function PackageEpisodeCard(props) {
  const classes = useStyles();



  const {
    status,
    title,
    duration,
    backgroundColor,
    imageUrl,
    lock,
    setLock,
    index
  } = props;
  return (
    <div>
      <Card className={classes.root} style={{ background: backgroundColor }}>
        <CardContent>
          <Grid container justify="center" direction="row">
            <Grid xs={4} item>
              {/* <img src={userPhoto}/> */}
              <div
                className={classes.imageContainer}
                style={{
                  background: `url(${imageUrl}), #C4C4C4`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  backgroundPosition: 'center'
                }}
              />
            </Grid>
            <Grid xs={6} item style={{ paddingLeft: '15px',alignItems:'center' }}>
              <Typography className={classes.subContent}>
                <Truncate lines={2} ellipsis={<span>...</span>} >
                  {title}
                </Truncate>
              </Typography>
              <Typography className={classes.Title}>
                {lock ? "Free" : "Not free"}
              </Typography>
              <Grid container direction="row">
                <Grid xs={6}>
                  {/* <Typography
                    className={classes.subContent}
                    style={{
                      fontSize: "10px",
                      marginTop: "10px",
                      color: "#2A4A67"
                    }}
                  > */}
                   <Chip  className={
                      status === "Active"
                        ? classes.activeEpisode
                        : status === "InActive"
                        ? classes.inProgressEpisode
                        : status === "InProgressUploading"
                        ? classes.inProgressEpisode
                        : status === "InProgressTranscoding"
                        ? classes.inProgressEpisode
                        : status === "Blocked"
                        ? classes.blockedEpisode
                        : classes.blockedEpisode
                    }
                    label={status}
                    style={{ height: "18px"}}/>
                  {/* </Typography> */}
                </Grid>
              </Grid>
            </Grid>
            <Grid xs={2} item style={{textAlign:'center'}}>
              <div className={classes.lockIcon} onClick={() => setLock(index)}>
                <img src={!lock ? lockIcon : lockOpenIcon} alt="" />
              </div>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
}

export default PackageEpisodeCard;
