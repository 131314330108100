import React, { useState } from 'react';
import { Popover, Typography, Grid, Card, CardContent, Button, IconButton, TextField, useMediaQuery, CircularProgress } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { useDispatch, useSelector } from 'react-redux'
import retreatAnnouncementIcon from '../../../../assets/Icons/retreatAnnouncementIcon.svg'
import { makeAnnouncement } from '../../../../actions/retreatCourse'
import ProgressCard from './ProgressCard'
import ExpireSubscriptionPopup from '../../../../components/Common/ExpireSubscriptionPopup';

function Requests(props) {
    const classes = useStyles();
    const theme = useTheme()
    const dispatch = useDispatch()
    const [anchorEl, setAnchorEl] = useState(null);
    const [rtmp, setRtmp] = useState(props?.lesson?.rtmpUrl);
    const [subscriptionExpire, setSubscriptionExpire] = useState(false);
    const [streamingKey, setStreamingKey] = useState(props?.lesson?.streamingKey);
    const [loader, setLoader] = useState(false);
    const [announcement, setAnnouncement] = useState('');
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const user = useSelector(state => state?.auth);
    const reducerUser = useSelector(state => state?.user?.user);
    const course = useSelector(state => state?.retreatCourse?.trainingCourseDetails);


    const handleClick = (event) => {

        if (reducerUser?.isExpiredSubscription && course?.plan?.name === 'Subscription' && user?.id !== course?.user?.id) {
            return setSubscriptionExpire(true)
        } else {
            if (props?.ownerShipStatus === 'creator' && props?.lesson.liveType !== 'zoom') {
                setAnchorEl(event.currentTarget);
            }
            dispatch({
                type: "TO_BE_PLAY_TRAINING_COURSE_EPISODE",
                payload: props?.lesson
            })
            return isMobile && props?.switch()
        }

    };

    const handleClose = () => {
        setAnchorEl(null);
        setRtmp('')
        setStreamingKey('')
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <div>
            {subscriptionExpire && <ExpireSubscriptionPopup subscriptionExpire={subscriptionExpire} setPopUp={() => setSubscriptionExpire(false)} />}
            <Grid container onClick={handleClick}>

                <Grid item item xs={12}>
                    <ProgressCard lesson={props?.lesson} />
                </Grid>
            </Grid>

            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Card className={classes.root}>
                    <CardContent>
                        <Grid container>

                            <Grid container spacing={2} item={12} className={classes.headerContainer}>
                                <Grid item xs={10}><Typography className={classes.title}>Live Session Info</Typography></Grid>

                                <Grid item xs={2}>
                                    <IconButton className={classes.closeIcon} onClick={handleClose}><Close /></IconButton>
                                </Grid>
                            </Grid>
                            {props?.lesson?.status === 'Pending' ? <Grid item xs={12}>
                                <Typography className={classes.text}>
                                    Live Lesson is under review once approved status will be changed to 'Active' and will provide related things to deliver session on given date and time.
                                </Typography>
                            </Grid> : props?.lesson?.status === 'Active' ?
                                <>
                                    <Grid item xs={12} >
                                        <TextField
                                            id="outlined-multiline-static"
                                            fullWidth
                                            placeholder="Rtmp Url"
                                            variant="outlined"
                                            value={rtmp}
                                            disabled

                                            classes={{ root: classes.textArea }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} >
                                        <TextField
                                            id="outlined-multiline-static"
                                            fullWidth
                                            placeholder="Streaming Key"
                                            variant="outlined"
                                            value={streamingKey}
                                            disabled
                                            classes={{ root: classes.textArea }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography className={classes.text}>
                                            For delivering session please enter rtmpUrl and Streaming key in   <span><a href='https://help.livestream.com/hc/en-us/articles/360002053268-How-to-Install-Livestream-Studio' target='__blank'>Encoding Live Streaming Software</a></span> to deliver session.
                                        </Typography>
                                    </Grid>
                                </> : props?.lesson?.status === 'Rejected' ? <Grid item xs={12}>
                                    <Typography className={classes.text}>
                                        {props?.lesson?.rejectReason}
                                    </Typography>
                                </Grid> : ''
                            }

                        </Grid>
                    </CardContent>

                </Card>
            </Popover>
        </div >
    );
}

export default Requests;


const useStyles = makeStyles(theme => ({
    container: {
        MinHeight: '100vh',
        background: '#FBFBFB',
        padding: '30px 15px 50px 15px'
    },
    root: {
        width: '300px',
    },
    textArea: {
        background: '#F1F1F1',
        marginBottom: "15px",
        borderRadius: '11px'
    },
    text: {
        padding: '5px',
        background: '#F1F1F1',
        borderRadius: '11px'
    },
    card: {
        width: '100%',
        background: '#FFFFFF',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.05)',
        borderRadius: '10px',
    },
    colorPrimary: {
        background: '#01BDD2',
        color: '#FFF'
    },
    title: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '16px',
        lineHeight: '100%',
        color: '#666666',
        float: 'left',

    },
    closeIcon: {
        backgroundColor: "#F1F1F4",
        color: '#E54D40',
        height: '20px',
        width: '20px',
        '&:hover': {
            backgroundColor: "#F1F1F4",
            color: '#E54D40',
        }
    },
    headerContainer: {
        height: '60px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    makeAnnouncementBtn: {
        background: '#09A9BB',
        '&:hover': {
            background: '#09A9BB',
        },
        borderRadius: '4px',
        color: '#FFF',
        justifyContent: 'center',
        textTransform: 'none',
        width: '100%'
    }

}));