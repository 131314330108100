import React from "react";
import { useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import CreateChallengeMobileContainer from "../../../containers/Mobile/More/CreateChallenge";
import More from "../../../containers/Web/More";

const CreateChallenge = () => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <>
      {isDesktop && <More />}
      {isMobile && !isDesktop && <CreateChallengeMobileContainer />}
    </>
  );
};

export default CreateChallenge;
