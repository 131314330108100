import React, { useState, useEffect } from "react";
import moment from "moment";
import PropTypes from "prop-types";
import {
  Grid,
  AppBar,
  Tab,
  Box,
  useMediaQuery,
  Tabs,
  TextField,
  Typography,
  CircularProgress
} from "@material-ui/core";
import { Search } from "@material-ui/icons";
import { makeStyles, useTheme, withStyles } from "@material-ui/styles";
import FriendInviteCard from "./FriendInviteCard";
import FriendActiveStatusCard from "./FriendActiveStatusCard";
import FollowersCard from "./FollowersCard";
import { Link, useParams, useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
import SendRequestCard from "./SendRequestCard";
import {
  approveRequest,
  searchFriends,
  sendFriendRequest,
  approveInvitation,
  approveMentorInvitation
} from "../../actions/userRelations";
import { useSelector, useDispatch } from "react-redux";


const StyledTabs = withStyles({
  indicator: {
    display: "flex",
    justifyContent: "center",
    height: "5px",
    backgroundColor: "transparent",
    "& > span": {
      maxWidth: 40,
      width: "100%",
      backgroundColor: "#09A9BB",
    },
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

const StyledTab = withStyles((theme) => ({
  root: {
    minWidth: "60px !important",
    textTransform: "none",
    color: "#F28705",
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    justifyContent: "space-evenly",
    "&:focus": {
      opacity: 1,
    },
  },
}))((props) => <Tab disableRipple {...props} />);

function TabPanel(props) {
  const theme = useTheme();

  const { children, value, index, ...other } = props;
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box style={{ padding: isMobile ? "0px 15px" : "0px 40px" }} >
          <>{children}</>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function Connects() {
  const classes = useStyles();
  const params = useParams();
  const history = useHistory();
  const [value, setValue] = React.useState(0);
  const intl = useIntl();

  const [searchTerm, setSearchTerm] = useState("");
  const [userFriendList, setUserFriendList] = useState([]);
  const [userFollowersList, setUserFollowersList] = useState([]);
  const [userFollowingsList, setUserFollowingsList] = useState([]);

  const theme = useTheme();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const authReducerState = useSelector((state) => state?.auth);
  const userFriends = useSelector((state) => state?.userRelations?.userFriends);
  const userFollowers = useSelector((state) => state?.userRelations?.userFollowers);
  const userFollowings = useSelector((state) => state?.userRelations?.userFollowings);
  const loader = useSelector((state) => state?.loader?.loader);
  const invitations = useSelector(
    (state) => state?.userRelations?.allInvitations
  );
  const authReducer = useSelector((state) => state?.auth);
  const allPendingRequests = useSelector(
    (state) => state?.userRelations?.pendingRequests
  );

  const searchedFriends = useSelector(
    (state) => state?.userRelations?.searchFriends
  );

  const handleChange = (event, newValue) => {
    setValue(newValue);

    history.replace(`/more/connections/${newValue === 0 ? 'search-friends' : newValue === 1 ? 'friend-requests' : newValue === 2 ? 'friends' : newValue === 3 ? 'invites' : newValue === 4 ? 'following' : newValue === 5 ? 'followers' : 0}`)
  };

  useEffect(() => {
    setUserFriendList(userFriends)
    setUserFollowersList(userFollowers)
    setUserFollowingsList(userFollowings)
    params?.tab && setValue(params?.tab === 'search-friends' ? 0 : params?.tab === 'friend-requests' ? 1 : params?.tab === 'friends' ? 2 : params?.tab === 'invites' ? 3 : params?.tab === 'following' ? 4 : params?.tab === 'followers' ? 5 : 0)
  }, [userFriends])

  let periods = {
    month: 30 * 24 * 60 * 60 * 1000,
    week: 7 * 24 * 60 * 60 * 1000,
    day: 24 * 60 * 60 * 1000,
    hour: 60 * 60 * 1000,
    minute: 60 * 1000,
  };

  function formatTime(timeCreated) {
    var diff = Date.now() - timeCreated;

    if (diff > periods.month) {
      return Math.floor(diff / periods.month) + "m ago";
    } else if (diff > periods.week) {
      return Math.floor(diff / periods.week) + "w ago";
    } else if (diff > periods.day) {
      return Math.floor(diff / periods.day) + "d ago";
    } else if (diff > periods.hour) {
      return Math.floor(diff / periods.hour) + "h ago";
    } else if (diff > periods.minute) {
      return Math.floor(diff / periods.minute) + "min ago";
    }
    return "Just now";
  }

  const handleOkayBtn = (e, type, status, req) => {
    e.preventDefault();
    if (type === "friendRequest") {
      dispatch(
        approveRequest({
          requestId: req?.id,
          approveStatus: status,
          token: authReducer?.token,
        })
      );
    }
    if (type === "Session") {
      dispatch(

        approveInvitation({
          invitationId: req?.id,
          type: "session",
          status,
          token: authReducer?.token,
        })
      );
    }
    if (type === "Challenge") {
      dispatch(
        approveInvitation({
          invitationId: req?.id,
          status,
          type: "challenge",
          token: authReducer?.token,
        })
      );
    }
    if (type === "Mentor" || type === "RetreatMentor" || type === "TeacherTrainingMentor" ) {
      dispatch(
        approveMentorInvitation({
          invitationId: req?.id,
          status: status === 'Accepted' ? 'Accepted' : 'Rejected',
          type: 'Mentor',
          token: authReducer?.token,
        })
      );
    }
  };
  const handleSearch = () => {
    dispatch(searchFriends({ token: authReducerState?.token, searchTerm }));

  };
  const searchFieldChangeHandler = (e) => {

    setSearchTerm(e.target.value);
  };

  const handleSendRequest = (e, friend) => {
    e.preventDefault();
    dispatch(
      sendFriendRequest({
        token: authReducerState?.token,
        requestReceiverId: friend?.id,
      })
    );
  };


  const handleSearchFriend = (e) => {
    if (e.target.value === '') {
      return value === 2 ? setUserFriendList(userFriends) : value === 4 ? setUserFollowingsList(userFollowings) : value === 5 ?
      setUserFollowersList(userFollowersList) : ''
    } else {

      let filterPeoples = (value === 2 ? userFriends : value === 4 ? userFollowings : value === 5 ? userFollowers : [])?.filter(friend => (friend?.name.toLowerCase()).indexOf(e.target.value.toLowerCase()) > -1)
      return value === 2 ?  setUserFriendList(filterPeoples) : value === 4 ? setUserFollowingsList(filterPeoples) : value === 5 ?
      setUserFollowersList(filterPeoples) : ''
    }

  }

  return (
    <Grid container style={{ paddingBottom: isMobile && '60px' }}>
      <Grid item xs={12} container>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={8}>
          <AppBar position="static" className={classes.appBar} style={{ padding: isMobile && "0px 15px" }}>
            <StyledTabs
              value={value}
              scrollButtons="auto"
              variant="scrollable"
              onChange={handleChange}
              classes={{
                indicator: isMobile && classes.indicatorStylingMobile,
                flexContainer: classes.tabsContainer,
              }}
              style={{ margin: isMobile ? "0px" : "25px" }}
            >
              <StyledTab
                label="Search Friends"
                classes={{
                  root: classes.tabLabel,
                  selected: classes.tabSelected,
                }}
              />
              <StyledTab
                label={intl.formatMessage({ id: "friendRequests" })}
                classes={{
                  root: classes.tabLabel,
                  selected: classes.tabSelected,
                }}
              />
              <StyledTab
                label={intl.formatMessage({ id: "friends" })}
                classes={{
                  root: classes.tabLabel,
                  selected: classes.tabSelected,
                }}
              />
              <StyledTab
                label={intl.formatMessage({ id: "invites" })}
                classes={{
                  root: classes.tabLabel,
                  selected: classes.tabSelected,
                }}
              />
              <StyledTab
                label={intl.formatMessage({ id: "following" })}
                classes={{
                  root: classes.tabLabel,
                  selected: classes.tabSelected,
                }}
              />
              <StyledTab
                label={intl.formatMessage({ id: "followers" })}
                classes={{
                  root: classes.tabLabel,
                  selected: classes.tabSelected,
                }}
              />


            </StyledTabs>
          </AppBar>
        </Grid>
      </Grid>
      <Grid item xs={12} container>
        <Grid
          item
          xs={12}
          sm={12}
          md={9}
          lg={9}
          xl={6}
          style={{ height: "100vh" }}
        >
          <TabPanel value={value} index={0}>
            <Grid container>
              <Grid item xs={12} sm={12} md={10} lg={8} xl={8}>

                <TextField
                  className={classes.searchField}
                  type="Search"
                  value={searchTerm}
                  style={{ width: '100%' }}
                  placeholder="Search..."
                  onChange={searchFieldChangeHandler}
                  name="search"
                  onKeyPress={(e) => {
                    (e.which === 13 || e.keyCode === 13) && handleSearch();
                  }}
                  InputProps={{
                    endAdornment: (
                      <Search
                        style={{ cursor: "pointer" }}
                        onClick={handleSearch}
                      />
                    ),
                  }}
                />
              </Grid>
              {loader ? (
                <Grid item xs={12} justify="center" className={classes.loaderStyle}>
                  <CircularProgress color="primary" size={20} />
                </Grid>
              ) : (searchedFriends.length > 0 ? (
                searchedFriends?.map((friend) => (
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} key={friend?.id}>
                    <Link
                      to={`/view-profile/${friend?.id}`}
                      style={{ textDecoration: "none" }}
                    >
                      <SendRequestCard
                        friend={friend}
                        sendRequest={(e) => handleSendRequest(e, friend)}
                      />
                    </Link>
                  </Grid>
                ))
              ) : (
                <Grid item xs={12}>
                  <Typography className={classes.noRecord}>
                    No Record
                  </Typography>
                </Grid>
              ))}
            </Grid>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Grid container>
              {loader ? (
                <Grid item xs={12} justify="center" className={classes.loaderStyle}>
                  <CircularProgress color="primary" size={20} />
                </Grid>
              ) : (allPendingRequests?.length > 0 ? (
                allPendingRequests?.map((request) => (
                  <Grid item xs={12}>
                    <FriendInviteCard
                      title={request?.sender?.name}
                      pictureUrl={request?.sender?.pictureUrl}
                      timeAgo={formatTime(moment(request?.createdAt).valueOf())}
                      status={request?.status}
                      goTo={`/view-profile/${request?.sender?.id}`}
                      Okay={(e) =>
                        handleOkayBtn(e, "friendRequest", "accepted", request)
                      }
                      reject={(e) =>
                        handleOkayBtn(e, "friendRequest", "rejected", request)
                      }
                    />
                  </Grid>
                ))
              ) : (
                <Grid item xs={12}>
                  <Typography className={classes.noRecord}>
                    No Record
                  </Typography>
                </Grid>
              ))}
            </Grid>
          </TabPanel>
          <TabPanel value={value} index={2}>
            <Grid container>
              <Grid item xs={12} sm={12} md={10} lg={8} xl={8}>
                <TextField
                  className={classes.searchField}
                  type="Search"
                  style={{ width: '100%' }}
                  placeholder="Search..."
                  name="search"
                  onChange={handleSearchFriend}
                  InputProps={{
                    endAdornment: (
                      <Search style={{ cursor: 'pointer' }} />
                    ),
                  }}
                />

              </Grid>
              {loader ? (
                <Grid item xs={12} justify="center" className={classes.loaderStyle}>
                  <CircularProgress color="primary" size={20} />
                </Grid>
              ) : (userFriendList?.length > 0 ? (
                userFriendList?.map((friend) => (
                  <Grid item xs={12} key={friend?.id}>
                    <Link
                      to={`/view-profile/${friend?.id}`}
                      style={{ textDecoration: "none" }}
                    >
                      <FriendActiveStatusCard friend={friend} />
                    </Link>
                  </Grid>
                ))
              ) : (
                <Grid item xs={12}>
                  <Typography className={classes.noRecord}>
                    No Record
                  </Typography>
                </Grid>
              ))}
            </Grid>
          </TabPanel>
          <TabPanel value={value} index={3}>
            <Grid container>
              {loader ? (
                <Grid item xs={12} justify="center" className={classes.loaderStyle}>
                  <CircularProgress color="primary" size={20} />
                </Grid>
              ) : (invitations?.length > 0 ? (
                invitations?.map((invitation) => (
                  <Grid item xs={12}>
                    <FriendInviteCard
                      pictureUrl={invitation?.invitationSenders?.pictureUrl}
                      title={invitation?.description}
                      goTo={invitation?.type === 'Challenge' ? `/challenge/details/${invitation?.challengeId}` : invitation?.type === 'Session' ? `/more/session/details/${invitation?.sessionId}` :  (invitation?.type === 'RetreatMentor' || invitation?.type === 'TeacherTrainingMentor') ? `/teacher-training-details/${invitation?.trainingCourseId}` : '#'}
                      timeAgo={formatTime(
                        moment(invitation?.createdAt).valueOf()
                      )}
                      status={
                        invitation?.status === "Pending"
                          ? "pending"
                          : invitation?.status === "Accepted"
                            ? "accepted"
                            : invitation?.status === "Rejected"
                              ? "rejected"
                              : "pending"
                      }
                      Okay={(e) =>
                        handleOkayBtn(
                          e,
                          invitation?.type,
                          "Accepted",
                          invitation
                        )
                      }
                      reject={(e) =>
                        handleOkayBtn(
                          e,
                          invitation?.type,
                          "Ignored",
                          invitation
                        )
                      }
                    />
                  </Grid>
                ))
              ) : (
                <Grid item xs={12}>
                  <Typography className={classes.noRecord}>
                    No Record
                  </Typography>
                </Grid>
              ))}
            </Grid>
          </TabPanel>
          <TabPanel value={value} index={4}>
            <Grid container>
              <Grid item xs={12} sm={12} md={10} lg={8} xl={8}>
                <TextField
                  className={classes.searchField}
                  type="Search"
                  style={{ width: '100%' }}
                  placeholder="Search..."
                  name="search"
                  onChange={handleSearchFriend}
                  InputProps={{
                    endAdornment: (
                      <Search style={{ cursor: 'pointer' }} />
                    ),
                  }}
                />

              </Grid>
              {loader ? (
                <Grid item xs={12} justify="center" className={classes.loaderStyle}>
                  <CircularProgress color="primary" size={20} />
                </Grid>
              ) : (userFollowingsList?.length > 0 ? (
                userFollowingsList?.map((friend) => (
                  <Grid item xs={12} key={friend?.id}>
                    {/* <Link
                      to={`/view-profile/${friend?.id}`}
                      style={{ textDecoration: "none" }}
                    > */}
                    <FollowersCard friend={friend} />
                    {/* </Link> */}
                  </Grid>
                ))
              ) : (
                <Grid item xs={12}>
                  <Typography className={classes.noRecord}>
                    No Record
                  </Typography>
                </Grid>
              ))}
            </Grid>
          </TabPanel>
          <TabPanel value={value} index={5}>
            <Grid container>
              <Grid item xs={12} sm={12} md={10} lg={8} xl={8}>
                <TextField
                  className={classes.searchField}
                  type="Search"
                  style={{ width: '100%' }}
                  placeholder="Search..."
                  name="search"
                  onChange={handleSearchFriend}
                  InputProps={{
                    endAdornment: (
                      <Search style={{ cursor: 'pointer' }} />
                    ),
                  }}
                />

              </Grid>
              {loader ? (
                <Grid item xs={12} justify="center" className={classes.loaderStyle}>
                  <CircularProgress color="primary" size={20} />
                </Grid>
              ) : (userFollowersList?.length > 0 ? (
                userFollowersList?.map((friend) => (
                  <Grid item xs={12} key={friend?.id}>
                    {/* <Link
                      to={`/view-profile/${friend?.id}`}
                      style={{ textDecoration: "none" }}
                    > */}
                    <FollowersCard friend={friend} />
                    {/* </Link> */}
                  </Grid>
                ))
              ) : (
                <Grid item xs={12}>
                  <Typography className={classes.noRecord}>
                    No Record
                  </Typography>
                </Grid>
              ))}
            </Grid>
          </TabPanel>


        </Grid>
      </Grid>
    </Grid>
  );
}

export default Connects;

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: "#FFF",
    width: "100%",
    boxShadow: "none",
    display: "flex",
    justifyContent: "space-between",
  },
  tabLabel: {
    color: "#080808e3",
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "19px",
    minWidth: "20%",
    textTransform: "none",
  },
  tabSelected: {
    color: "#09A9BB",
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "19px",
  },
  indicatorStyling: {
    backgroundColor: "#09A9BB",
    height: "5px",
    maxWidth: "60px",
  },
  indicatorStylingMobile: {
    backgroundColor: "#09A9BB",
    height: "5px",
  },
  tabsContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  noRecord: {
    color: "#b9b9b9",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "19px",
    textAlign: "center",
    marginTop: "50px",
  },
  searchField: {
    marginTop: "10px",
    "& .MuiInputBase-root": {
      backgroundColor: "#dce8f2",
      color: "#A9A9A9",
      fontFamily: theme.fontFamily.Roboto,
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "18px",
      lineHeight: "21px",
      padding: "10px",
      minHeight: "64px",
      alignSelf: "center",
      position: 'static',
      marginBottom: "20px",
      height: "58px",
      width: "100%",
      borderRadius: "10px",
    },
    "& .MuiBadge-colorPrimary": {
      backgroundColor: "#F9A702;",
    },

    "& .MuiInput-underline:before": {
      border: "0px solid #FBF1E0",
    },
    "& .MuiInput-underline::after": {
      border: "0px solid #FBF1E0",
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled)::before": {
      border: "0px solid #FBF1E0",
    },
  },
  loaderStyle: {
    display: 'flex',
    alignItems: 'center',
    alignContent: "center",
    height: "30vh"
  }
}));
