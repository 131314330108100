import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { Grid, LinearProgress, Tooltip, Badge } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import Truncate from "react-truncate";

const BorderLinearProgress = withStyles(theme => ({
  root: {
    height: 10,
    borderRadius: 5
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === "light" ? 200 : 700]
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#1a90ff"
  }
}))(LinearProgress);

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    borderRadius: "11px",
    transform: "rotate(360deg)"
  },
  imageContainer: {
    width: "100%",
    height: 0,
    paddingTop: "56.25%",
    position: "relative"
  },
  iconContainer: {
    display: "flex",
    alignContent: "center",
    alignItems: "center"
  },
  Title: {
    fontFamily: theme.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: 900,
    fontSize: "16px",
    lineHeight: "22px",
    color: "#727275",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover"
  },
  subContent: {
    fontFamily: theme.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: 900,
    fontSize: "12px",
    lineHeight: "16px",
    color: "#727275"
  },
  videoIcon: {
    height: "3.238em",
    width: "3.238em",
    borderRadius: "100%",
    border: '1px solid #727275',

    textAlign: "center",
    lineHeight: "4.438em"
  },
  activeCourse: {
    background: "#52b202",
    color: "#fff"
  },
  incompleteCourse: {
    background: "#ff9100",
    color: "#fff"
  },
  blockedCourse: {
    background: "#f44336",
    color: "#fff"
  }
}));

function MyCoursesCard(props) {
  const classes = useStyles();


  const {
    image,
    title,
    courseBy,
    duration,
    category,
    backgroundColor,
    progress,
    textColor,
    videoIconColor,
    videoIconBgColor
  } = props;


  return (
    <Card className={classes.root} style={{ background: backgroundColor }}>
      <CardContent style={{ padding: '12px' }}>
        <Grid container>
          <Grid item xs={4} sm={4} md={4}>
            <div
              style={{
                background: `url(${image}), #C4C4C4`,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                borderRadius: "9px"
              }}
              className={classes.imageContainer}
            />
          </Grid>

          <Grid item xs={5} container style={{ paddingLeft: "15px" }}>
            <Grid item xs={12}>
              <Tooltip title={title}>
                <Typography
                  className={classes.Title}
                  style={{ color: textColor }}
                >
                  <Truncate lines={1} ellipsis={<span>...</span>} >
                    {`${title}`}
                  </Truncate>
                </Typography>
              </Tooltip>
            </Grid>

            <Grid item xs={12}>
              <Typography
                className={classes.subContent}
                style={{ color: textColor }}
              >
                {courseBy}
              </Typography>
            </Grid>

            <Grid item xs={12} container justify="space-between">

              <Grid item xs={12}>
                <Typography
                  className={classes.subContent}
                  style={{ color: textColor }}
                >
                  {category}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={3} container justify="center">
            {props?.userUploaded ? (
              <Grid item xs={12} container justify="flex-end">
                <Grid item xs={4} sm={4} md={3} lg={3} xl={3}>
                  <Badge
                    classes={{
                      badge:
                        props?.activeStatus === "Active"
                          ? classes.activeCourse
                          : props?.activeStatus === "Incomplete"
                            ? classes.incompleteCourse
                            : props?.activeStatus === "Blocked"
                              ? classes.blockedCourse
                              : ""
                    }}
                    badgeContent={props?.activeStatus}
                    style={{ textAlign: "right" }}
                  />{" "}
                </Grid>
              </Grid>
            ) : (
              <React.Fragment className={classes.iconContainer}>
                <Grid item xs={12} container justify="flex-end">
                  <Grid item xs={8} sm={8} md={8} lg={8} xl={6}>
                    <div
                      className={classes.videoIcon}
                      style={{ background: videoIconBgColor, margin: "auto" }}
                    >
                      <PlayArrowIcon
                        style={{
                          color: videoIconColor ? videoIconColor : "#7B92A6",
                          height: "1.188em",
                          width: "1.375em"
                        }}
                      />
                    </div>
                  </Grid>
                </Grid>

                <Grid item xs={12} container justify="flex-end">
                  <Grid item xs={8} sm={8} md={8} lg={8} xl={6}>
                    {progress < 100 && (
                      <BorderLinearProgress
                        variant="determinate"
                        value={progress}
                        style={{ marginTop: "5px", width: "100%" }}
                      />
                    )}
                  </Grid>
                </Grid>
              </React.Fragment>
            )}
          </Grid>

        </Grid>
      </CardContent>
    </Card>
  );
}

export default MyCoursesCard;
