import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  AppBar,
  BottomNavigation,
  BottomNavigationAction,
  Grid,
  Toolbar,
  Typography,
  IconButton,
  Avatar
} from "@material-ui/core";
import { useLocation, useHistory } from "react-router-dom";
import { useSelector } from 'react-redux';
import {
  HomeOutlined,
  SearchOutlined,
  HorizontalSplitOutlined,
  PersonOutline,
  ArrowBack,
  Close,
  NotificationsOutlined,
  FavoriteBorderOutlined,
  Favorite
} from "@material-ui/icons";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import PropTypes from "prop-types";
import instructorIcon from '../../assets/Icons/BottomNavigationIcons/instructorIcon.svg'
import instructorIconSelected from '../../assets/Icons/BottomNavigationIcons/instructorIconSelected.svg'
import searchIcon from '../../assets/Icons/BottomNavigationIcons/serchIcon.svg'
import searchIconSelected from '../../assets/Icons/BottomNavigationIcons/searchIconSelected.svg'
import heartIcon from '../../assets/Icons/BottomNavigationIcons/heartIcon.png'
import heartIconSelected from '../../assets/Icons/BottomNavigationIcons/heartIconSelected.svg'
import homeIcon from '../../assets/Icons/BottomNavigationIcons/homeIcon.svg'
import homeIconSelected from '../../assets/Icons/BottomNavigationIcons/homIconSelected.svg'
import clsx from 'clsx'

function ElevationScroll(props) {
  const { children, window } = props;


  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,

  window: PropTypes.func
};

function MobileLayout(props) {
  const classes = useStyles();
  let location = useLocation();
  let history = useHistory();
  const user = useSelector(state => state?.user?.user);

  const [value, setValue] = React.useState(
    location && location.pathname.split("/").length
      ? location.pathname.split("/")[1]
      : ""
  );

  const handleChange = (event, newValue) => {
    history.push(`/${newValue}`);
    setValue(newValue);
  };

  return (
    <div>
      {props.appBarConfig && props.appBarConfig.show && (
        <>
          <ElevationScroll {...props}>
            <AppBar className={classes.appBar}>
              <IconButton
                onClick={() => history.goBack()}
                className={classes.backButton}
              >
                <ArrowBack />
              </IconButton>
              <Toolbar>
                <Typography className={classes.appBarText} variant="h6">
                  {props.appBarConfig.text}
                </Typography>
              </Toolbar>
              {props.appBarConfig.cross && (
                <IconButton
                  onClick={() => history.push(props.appBarConfig.action)}
                  className={classes.backButton}
                >
                  <Close />
                </IconButton>
              )}
            </AppBar>
          </ElevationScroll>
          <Toolbar />
          <Toolbar />{" "}
        </>
      )}
      <Grid>{props.children}</Grid>
      {props.showBottomNav && (
        <BottomNavigation
          value={value}
          onChange={handleChange}
          className={classes.rootNavigation}
        >
          <BottomNavigationAction
            value="dashboard"
            classes={{
              selected: classes.selectedBottomItem
            }}
            className={
              value === "dashboard"
                ? clsx(classes.selectedBottomItemBackgroundLeft, classes.width)
                : clsx(classes.bottomIcon, classes.width)
            }

            icon={
              value === "dashboard" ? <img src={homeIconSelected} className={classes.selectedBottomIcon} /> : <img src={homeIcon} className={classes.iconSize} />
            }
          />
          <BottomNavigationAction
            value="view-all-teachers"
            classes={{
              selected: classes.selectedBottomItem
            }}
            className={
              value === "view-all-teachers"
                ? clsx(classes.selectedBottomItemBackground, classes.width)
                : clsx(classes.bottomIcon, classes.width)
            }
            icon={
              value === "view-all-teachers" ? <img src={instructorIconSelected} className={classes.selectedBottomIcon} /> : <img src={instructorIcon} className={classes.iconSize} />

            }
          />
          <BottomNavigationAction
            value="explore"
            classes={{
              selected: classes.selectedBottomItem
            }}
            className={
              value === "explore"
                ? clsx(classes.selectedBottomItemBackground, classes.width)
                : clsx(classes.bottomIcon, classes.width)
            }
            icon={
              value === "explore" ? <img src={searchIconSelected} className={classes.selectedBottomIcon} /> : <img src={searchIcon} className={classes.iconSize} />
            }
          />
          <BottomNavigationAction
            value="favorite"
            classes={{
              selected: classes.selectedBottomItem
            }}
            className={
              value === "favorite"
                ? clsx(classes.selectedBottomItemBackground, classes.width)
                : clsx(classes.bottomIcon, classes.width)
            }
            icon={
              value === "favorite" ? <img src={heartIconSelected} className={classes.selectedBottomIcon} /> : <img src={heartIcon} className={classes.iconSize} />
            }
          />

          <BottomNavigationAction
            value="profile"
            classes={{
              selected: classes.selectedBottomItem
            }}
            className={
              value === "profile"
                ? clsx(classes.selectedBottomItemBackground, classes.width)
                : clsx(classes.bottomIcon, classes.width)
            }
            icon={

              <Avatar alt="pic" src={user?.pictureUrl}
                className={value === "profile" ? clsx(classes.profilePic, classes.small) : clsx(classes.iconSize, classes.small)}
              >
                {user?.name && user?.name[0]}
              </Avatar>

            }
          />
        </BottomNavigation>
      )}
    </div>
  );
}

export default MobileLayout;

const useStyles = makeStyles(theme => ({
  rootNavigation: {
    width: "100vw",
    position: "fixed",
    bottom: "0px",

    backgroundColor: "#F3F3F3",
    zIndex: 100,
    height: "60px"
  },
  appBar: {
    background: "#fff",
    color: "#000",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5)
  },
  appBarText: {
    fontFamily: theme.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: "900",
    fontSize: "26px",
    lineHeight: "35px"
  },
  backButton: {
    background: "transparent",
    color: "#000",
    "&:hover": {
      background: "transparent"
    }
  },
  bottomIcon: {
    color: "#606770"
  },
  selectedBottomItemBackgroundLeft: {

    borderRadius: "0px 30px 0px 0px",
    width: "100%",
    height: "82px",
    position: "relative",
    bottom: "18px",
    paddingTop: theme.spacing(2)
  },
  selectedBottomItemBackgroundRight: {

    borderRadius: "30px 0px 0px 0px",
    width: "100%",
    height: "82px",
    position: "relative",
    bottom: "18px",
    paddingTop: theme.spacing(2)
  },
  selectedBottomItemBackground: {

    borderRadius: "30px 30px 0px 0px",
    width: "100%",
    height: "82px",
    position: "relative",
    bottom: "18px"
  },
  selectedBottomItem: {

    color: "#ffff"
  },
  selectedBottomIcon: {
    paddingTop: theme.spacing(3.5),

    color: "#09A9BB",
    fontSize: '30px'

  },
  iconSize: {

    fontSize: '30px'

  },
  small: {
    width: theme.spacing(3.5),
    height: theme.spacing(3.5),
    backgroundColor: '#606770',
    color: '#FFF',

  },
  profilePic: {
    marginTop: theme.spacing(3.5),

  },
  width: {
    '&.MuiBottomNavigationAction-root': {
      minWidth: '50px'
    }
  }
}));
