import React, { useEffect, useState } from 'react';
import { CircularProgress, Grid, Divider, Typography, Dialog, DialogTitle, DialogContent } from '@material-ui/core'
import { InsertInvitation, CancelRounded } from '@material-ui/icons'
import { makeStyles, withStyles } from "@material-ui/core/styles";
import ParticipantCard from './participantCard'
import { getTrainingCourseParticipants } from '../../../../actions/retreatCourse'
import { useSelector, useDispatch } from 'react-redux'
import ShareIcon from '../../../../assets/Icons/RetreatShareIcon.svg'
import InviteFriends from "../../../../containers/Common/RetreatContainer/CoursePreview/InviteFriends";
import SocialMediaShare from "../../../../components/More/Common/SocialMediaShare";
import { useIntl } from "react-intl";

function Participants(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const intl = useIntl();
    const [loader, setLoader] = useState(false)
    const [inProgress, setInProgress] = useState([])
    const [completed, setCompleted] = useState([])
    const [openInvite, setOpenInvite] = useState(false);
    const [open, setOpen] = useState(false);
    const user = useSelector(state => state?.auth);
    const course = useSelector(state => state?.retreatCourse?.trainingCourseDetails);
    const participants = useSelector(state => state?.retreatCourse?.trainingCourseParticipants);

    const getData = async () => {
        setLoader(true)
        const res = await dispatch(getTrainingCourseParticipants({ courseId: course?.id, token: user?.token }));
        if (res) {
            setLoader(false)
        }
    }

    useEffect(() => {
        getData();

    }, []);

    useEffect(() => {
        if (participants) {
            let completedParticipants = [];
            const inProgressCourses = participants?.filter(item => {
                if (item?.trainingCourseProgress === 100) {
                    completedParticipants.push(item);
                    return
                } else {
                    return item
                }
            })
            setInProgress(inProgressCourses);
            setCompleted(completedParticipants);
        }
    }, [participants])

    const isIos = () => {
        const userAgent = window.navigator.userAgent.toLowerCase();
        return /iphone|ipad|ipod/.test(userAgent);
    };

    return (
        <Grid container>
            {loader ? <Grid item xs={12} className={classes.noContent}>
                <CircularProgress size={20} style={{ color: '#09a9bb' }} />
            </Grid>
                : <>{((inProgress?.length === 0) && (completed?.length === 0)) ? <Grid item xs={12} className={classes.noContent}>
                    <Typography className={classes.noContentText}>No Participant found</Typography>
                </Grid> : <> {inProgress?.length > 0 && <Grid item container xs={12}>
                    <Grid item xs={12}>
                        <Typography className={classes.heading}>In progress</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        {inProgress?.map((participant) => <ParticipantCard participant={participant} />)}
                    </Grid>
                    <Grid item xs={12}>
                        <Divider className={classes.divider} />
                    </Grid>
                </Grid>}
                    {completed?.length > 0 && <Grid item container xs={12} mt={2}>
                        <Grid item xs={12}>
                            <Typography className={classes.heading}>Completed</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            {completed?.map((participant) => <ParticipantCard participant={participant} />)}
                        </Grid>

                    </Grid>}
                </>
                }
                    <Grid item xs={12} container justify='center'>
                        {course?.isPrivate ? <Grid item xs={6} sm={6} md={6} lg={6} container onClick={() => course?.status === 'Active' && setOpenInvite(true)} style={{ paddingRight: '3px', cursor: 'pointer', justifyContent: 'center' }}>

                            <Grid item className={classes.center} >
                                <img src={ShareIcon} style={{ paddingTop: '5px', paddingRight: '3px' }} />
                            </Grid>
                            <Grid item className={classes.center}>
                                <Typography > Invite Friends</Typography>
                            </Grid>
                        </Grid> : <Grid item xs={6} sm={6} md={6} lg={6} container style={{ justifyContent: 'center' }}>
                            <Grid item xs={6} container onClick={() => course?.status === 'Active' && setOpenInvite(true)} style={{ paddingRight: '3px', cursor: 'pointer', textAlign: 'center', justifyContent: 'center' }}>

                                <Grid item className={classes.center} >
                                    <InsertInvitation style={{ color: '#C6C6C6' }} />
                                </Grid>
                                <Grid item className={classes.center}>
                                    <Typography > Invite </Typography>
                                </Grid>
                            </Grid>
                            <Grid item xs={6} container onClick={() => course?.status === 'Active' && setOpen(true)} style={{ paddingRight: '3px', cursor: 'pointer', justifyContent: 'center' }}>

                                <Grid item className={classes.center} >
                                    <img src={ShareIcon} style={{ paddingTop: '5px', paddingRight: '3px' }} />
                                </Grid>
                                <Grid item className={classes.center}>
                                    <Typography > Share</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        }
                    </Grid>

                </>}
            <Dialog open={open} onClose={() => setOpen(false)}>
                <DialogContent style={{ padding: isIos() && "0px" }}>
                    <SocialMediaShare
                        url={`${window.location.origin}/share/${course?.retreatType}//${course?.slug}`}
                        imageUrl={course?.image}
                        sumaryText={"Here is new retreat"}
                        title={"Hey there,  Check this out, I think you will love it"}
                        tags={
                            course?.courseTags
                                ? course?.courseTags.map(item => item.name)
                                : []
                        }
                        header={intl.formatMessage({ id: "retreatShareText" })}
                    />
                </DialogContent>
            </Dialog>


            {openInvite && (
                <Dialog open={openInvite} onClose={() => setOpenInvite(false)}>
                    <DialogTitle>
                        <CancelRounded
                            className={classes.cancelBtn}
                            onClick={() => setOpenInvite(false)}
                        />
                    </DialogTitle>
                    <DialogContent>
                        <InviteFriends
                            setOpenInvite={setOpenInvite}
                            course={course}
                        />
                    </DialogContent>
                </Dialog>
            )
            }
        </Grid>
    );
}

export default Participants;

const useStyles = makeStyles(theme => ({
    heading: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '18px',
        lineHeight: '100%',
        color: '#353B35',
        paddingBottom: '10px'
    },

    card: {
        width: '100%',
        background: '#FFFFFF',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.05)',
        borderRadius: '10px',
    },
    divider: {
        color: '#E2E2E2',
        height: '2px',
        margin: "15px 0px"
    },
    noContent: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: "40px"
    },
    cancelBtn: {
        color: "#D0CFCF",
        fontSize: "30px",
        cursor: "pointer",
        float: "right"
    },
    noContentText: {
        fontFamily: theme.fontFamily.Roboto,
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "14px",
        color: "#666666",

    }

}));