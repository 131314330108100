import React from 'react'
import { Grid, Card, Avatar, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

function ChallengeParticipantCard(props) {
    const classes = useStyles();

    return (
        <Card className={classes.container}>
            <Grid container >
                <Grid item xs={12} className={classes.imageContainer}>
                    <Avatar src={props?.friend?.pictureUrl} style={{
                        height: '56px',
                        width: '56px'
                    }} />
                </Grid>
                <Grid item xs={12}>
                <Typography className={classes.username}>@{props?.friend?.username}</Typography>
                </Grid>
            </Grid>

        </Card>
    )
}

export default ChallengeParticipantCard

const useStyles = makeStyles(theme => ({
    container: {
        height: '113px',
        width: '100%',

    },
    imageContainer: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '10px'

    },
    username: {
        fontFamily: theme.fontFamily.Roboto,
        fontStyle: 'italic',
        fontSize: '11px',
        fontWeight: 'bold',
        lineHeight: '13px',
        textAlign: 'center',
        color: '#3C4A6B',
        marginTop:'8px'

    }
}))