import React, { useEffect, useState } from 'react';
import { Tab, Tabs, Box, Grid, useTheme, useMediaQuery } from '@material-ui/core'
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";
import Comments from './Comments';
import SupportedFiles from './SupportedFiles';
import Announcement from './Announcements';
import Assignments from './Assignments';
import RetreatStats from './RetreatStats';
import LiveChat from './LiveChat';
import { useSelector } from 'react-redux'

const StyledTabs = withStyles({
    indicator: {
        display: "flex",
        justifyContent: "center",
        backgroundColor: "#09A9BB",
        "& > span": {
            maxWidth: 30,
            width: "100%",
            backgroundColor: "#09A9BB"
        }
    }
})(props => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

const StyledTab = withStyles(theme => ({
    root: {
        minWidth: "60px !important",
        textTransform: "none",
        color: "#080808e3",
        fontWeight: theme.typography.fontWeightRegular,
        fontSize: theme.typography.pxToRem(15),
        marginRight: theme.spacing(1),

        fontFamily: theme.fontFamily.Roboto,
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "16px",
        lineHeight: "14px",
        color: " #FFF",
        "&:focus": {
            opacity: 1
        }
    },
    selected: {
        color: "#09A9BB"
    }
}))(props => <Tab disableRipple {...props} />);

function TabPanel(props) {
    const theme = useTheme();
    const { children, value, index, ...other } = props;
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const lesson = useSelector(state => state?.retreatCourse?.toBePlayedLesson);
   


    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={index === 3 && isMobile && lesson?.type !== 'live' ? 0 : index === 4 && isMobile && lesson?.type === 'live' ? 0 : 3}>
                    <>{children}</>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
};

function ContentSection(props) {
    const classes = useStyles();
    const intl = useIntl();
    const [value, setValue] = useState(1);
    const lesson = useSelector(state => state?.retreatCourse?.toBePlayedLesson);
    const user = useSelector(state => state?.auth?.user);
    const course = useSelector(state => state?.retreatCourse?.trainingCourseDetails);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    return (
        <Grid container className={classes.container}>
            <Grid item xs={12}>
                <StyledTabs
                    value={value}
                    onChange={handleChange}
                    aria-label="styled tabs example"
                    variant="scrollable"
                    scrollButtons="auto"
                    style={{ borderBottom: '1px solid #e8e8e8' }}
                >
                    {lesson?.type === 'live' && <StyledTab
                        label={intl.formatMessage({ id: "liveChat" })}
                    />}
                    <StyledTab
                        label={intl.formatMessage({ id: "comments" })}
                    />
                    <StyledTab
                        label={intl.formatMessage({ id: "supportedFiles" })}
                    />
                    <StyledTab
                        label={intl.formatMessage({ id: "announcement" })}
                    />
                    <StyledTab label={intl.formatMessage({ id: "assignments" })} />
                   {user?.id === course?.user?.id && <StyledTab label={intl.formatMessage({ id: "stats" })} />}
                </StyledTabs>
            </Grid>

            <Grid item xs={12}>
                {lesson?.type === 'live' && <TabPanel value={value} index={0}  >
                    <LiveChat />
                </TabPanel>}

                <TabPanel value={value} index={lesson?.type === 'live' ? 1 : 0}  >

                    <Comments />
                </TabPanel>

                <TabPanel value={value} index={lesson?.type === 'live' ? 2 : 1}  >
                    <SupportedFiles />
                </TabPanel>

                <TabPanel value={value} index={lesson?.type === 'live' ? 3 : 2}  >
                    <Announcement />
                </TabPanel>

                <TabPanel value={value} index={lesson?.type === 'live' ? 4 : 3}  >
                    <Assignments />
                </TabPanel>
                {user?.id === course?.user?.id && <TabPanel value={value} index={lesson?.type === 'live' ? 5 : 4}  >
                    <RetreatStats />
                </TabPanel>}
            </Grid>

        </Grid>
    );
}

export default ContentSection;

const useStyles = makeStyles(theme => ({
    container: {
        marginBottom: "50px",

    },

    card: {
        width: '100%',
        background: '#FFFFFF',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.05)',
        borderRadius: '10px',
    },

}));