import React, { useEffect, useState, useRef } from "react";
import {
  Grid,
  Card,
  Box,
  CircularProgress,
  Button,
  Typography,
  useMediaQuery,
  Modal,
  Fab,
  Switch,
  FormControlLabel,
  Dialog,
  DialogContent,
  DialogTitle,
  Tooltip,
  Divider
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles, useTheme, withStyles } from "@material-ui/styles";
import { Add, CancelRounded, Edit, Close, Share, InsertInvitation } from '@material-ui/icons'
import { useIntl } from "react-intl";
import moment from "moment";
import { useHistory, useParams, Link } from "react-router-dom";
import ChallengeLessonCard from "../../components/Common/CourseCard";
import ChallengeRepetitionCard from "./ChallengeRepetitionCard";
import PropTypes from "prop-types";
import ChallengeParticipantCard from "./ChallengeParticipantCard";
import { IconButton } from "@material-ui/core";
import ShareIcon from "@material-ui/icons/Share";
import { joinChallenges, challengeActiveInActive } from "../../actions/challenges";
import InviteFriends from './InviteFriends'
import InviteAndShare from '../Common/InviteAndShare'
import EpisodePaidDialog from '../Common/episodePaidDialog'
import Truncate from "react-truncate";
import CustomerCardList from "../../components/Common/CustomerCardList";


function CircularProgressWithLabel(props) {
  const theme = useTheme();

  const classes = useStyles();
  let isMobile = useMediaQuery(theme.breakpoints.up("sm")) ? false : true;

  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress
        variant="static"
        {...props}
        size={150}
        classes={{ circle: classes.circle }}
      />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography
          variant="caption"
          component="div"
          style={{ color: isMobile ? "#FFF" : "#3F5B73", fontSize: "26px" }}
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

CircularProgressWithLabel.propTypes = {

  value: PropTypes.number.isRequired,
};

function ChallengeDetail() {
  const intl = useIntl();
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const params = useParams();

  const challengeDetail = useSelector(
    (state) => state?.challenges?.challengeDetail
  );
  let reducerUser = useSelector((state) => state?.user?.user);
  const [challengeExerciseDates, setChallengeExerciseDates] = React.useState(
    []
  );
  const [todayChallenge, setTodayChallenge] = useState();
  const [todayChallengeEpisodeDetail, setTodayChallengeEpisodeDetail] =
    useState("");
  const [expand, setExpand] = useState(false);
  const [openModel, setOpenModel] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);
  const [openPaymentDialog, setOpenPaymentDialog] = useState(false);
  const [openInviteModel, setInviteModel] = useState(false);
  const [isJoined, setIsJoined] = useState(false);
  const [challengeStatus, setChallengeStatus] = useState(false);
  const [shareOpen, setShareOpen] = useState(false);
  const [joinError, setJoinError] = useState(false);
  const scrollAble = useRef(null);
  const configs = useSelector((state) => state?.setting?.siteConfig);
  const auth = useSelector((state) => state?.auth);
  let isMobile = useMediaQuery(theme.breakpoints.up("sm")) ? false : true;

  const [modalStyle] = useState(getModalStyle);
  const [inviteModalStyle] = useState(getInviteModalStyle);

  function getModalStyle() {
    const top = 50;
    const left = 50;
    return {
      borderColor: theme.colors.white,
      borderRadius: "4px",
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top - 4}%, -${left}%)`,
    };
  }
  function getInviteModalStyle() {
    const top = 50;
    const left = 50;
    return {
      borderColor: theme.colors.white,
      borderRadius: "4px",
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
    };
  }

  const getUserProgress = () => {
    const user =
      challengeDetail &&
      challengeDetail?.challengeParticipants?.find(
        (participant) => participant.self === true
      );
    if (
      user?.challengeCompletedStatus > 99 ||
      user?.challengeCompletedStatus > 100
    ) {
      return 100;
    }
    return Math.round(user?.challengeCompletedStatus);
  };

  useEffect(() => {
    if (
      challengeDetail &&
      challengeDetail?.userId &&
      challengeDetail?.type &&
      challengeDetail?.type === "Individual" &&
      challengeDetail?.userId !== authReducerState?.user?.id
    ) {
      history.goBack();
    }
    let joined =
      challengeDetail &&
      challengeDetail?.challengeParticipants?.find(
        (participant) => participant?.id === auth?.user?.id
      );
    setIsJoined(joined ? true : false);
    let currentChallenge = null;
    const dates =
      challengeDetail &&
      challengeDetail?.challengeDates?.length > 0 &&
      challengeDetail?.challengeDates.map((item) => {
        if (
          moment(
            moment.utc(item?.dateTime, "x").local().format("YYYY-MM-DD")
          ).isSame(moment().format("YYYY-MM-DD"))
        ) {
          currentChallenge = {
            ...item,
            status: "next",
          };
          return {
            ...item,
            status: "next",
          };
        }
        if (
          moment(
            moment.utc(item?.dateTime, "x").local().format("YYYY-MM-DD")
          ).isBefore(moment().format("YYYY-MM-DD"))
        ) {
          return {
            ...item,
            status: "previous",
          };
        }

        if (
          moment(
            moment.utc(item?.dateTime, "x").local().format("YYYY-MM-DD")
          ).isAfter(moment().format("YYYY-MM-DD"))
        ) {
          return {
            ...item,
            status: "upComing",
          };
        } else {
          return null;
        }
      });

    setIsCompleted(
      moment(moment().format("YYYY-MM-DD")).isAfter(
        moment
          .utc(
            challengeDetail?.challengeDates?.[
              challengeDetail?.challengeDates?.length - 1
            ]?.dateTime,
            "x"
          )
          .local()
          .format("YYYY-MM-DD")
      )
    );
    setChallengeStatus(challengeDetail?.status === "Active" ? true : false);

    setTodayChallenge(currentChallenge);
    setChallengeExerciseDates(dates);
  }, [challengeDetail]);

    useEffect(() => {
    scrollAble.current.scrollIntoView();
  });

  const handleCloseModal = () => {
    setOpenModel(false);
  };

  const handleCloseInviteModal = () => {
    setInviteModel(false);
  };

  const modalBody = (
    <Grid
      container
      direction="row"
      spacing={3}
      style={modalStyle}
      className={classes.paper}
    >
      <Grid item className={classes.msgBody}>
        <Typography className={classes.modelBodyText}>
          {isCompleted
            ? intl.formatMessage({ id: "challengeCompletedMessage" })
            : challengeDetail?.type === "Group"
            ? intl.formatMessage({ id: "beforeExactTimeGroup" })
            : intl.formatMessage({ id: "beforeExactTime" })}
        </Typography>
      </Grid>
      <Grid item className={classes.modalBtnGrid}>
        <Button onClick={() => handleCloseModal()} className={classes.modelBtn}>
          {intl.formatMessage({ id: "okay" })}
        </Button>
      </Grid>
    </Grid>
  );

  const inviteFriendModalBody = (
    <Grid
      container
      direction="row"
      style={inviteModalStyle}
      className={classes.paperInvite}
    >
      <Grid item container xs={12} justify="flex-end">
        <Grid item xs={3} sm={2}>
          <CancelRounded
            style={{
              color: "#D0CFCF",
              fontSize: "30px",
              cursor: "pointer",
              float: "right",
            }}
            onClick={() => setInviteModel(false)}
          />
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <InviteFriends closeModal={() => setInviteModel(false)} />
      </Grid>
    </Grid>
  );

  const checkInviteHandler = () => {
    let challengeCreator = challengeDetail?.challengeParticipants?.find(
      (participant) =>
        participant?.id === auth?.user?.id && participant?.self === true
    );
    if (challengeCreator && challengeDetail?.type === "Group" && !isCompleted) {
      return true;
    }
    return false;
  };

  const expandDescription = () => {
    setExpand(!expand);
  };

  const episodePaidStatus = async () => {
    if (challengeDetail?.challengeGroup === "step") {
      const episodeLocal =
        challengeDetail &&
        challengeDetail?.challengeEpisodes &&
        challengeDetail?.challengeEpisodes[0];
      if (episodeLocal?.episode?.isFree) {
        return false;
      } else if (episodeLocal?.episode?.coursePaid) {
        return false;
      } else {
        setTodayChallengeEpisodeDetail(episodeLocal);
        return true;
      }
    } else {
      let datesLocal = [...challengeDetail?.challengeDates];

      let dateIndex = challengeDetail?.challengeDates?.findIndex((item) =>
        moment(
          moment.utc(item?.dateTime, "x").local().format("YYYY-MM-DD")
        ).isSame(moment().format("YYYY-MM-DD"))
      );
      let episodesIndexes = challengeDetail?.challengeEpisodes?.map(
        (el, index) => index
      );
      let datesIndexes = datesLocal?.fill(episodesIndexes).flat();
      let episodeIndex = datesIndexes[dateIndex];

      const episodeLocal =
        challengeDetail &&
        challengeDetail?.challengeEpisodes &&
        challengeDetail?.challengeEpisodes[episodeIndex];

      if (episodeLocal?.episode?.isFree) {
        return false;
      } else if (episodeLocal?.coursePaid) {
        return false;
      } else {
        setTodayChallengeEpisodeDetail(episodeLocal);
        return true;
      }
    }
  };

  const startChallengeHandler = async () => {
    if (isCompleted) {
      return setOpenModel(true);
    } else {
      if (
        !todayChallenge ||
        (moment().format("HH:MM") <
        moment
          .utc(challengeDetail?.challengeDates[0]?.dateTime, "x")
          .local()
          .format("HH:MM")
          ? true
          : false)
      ) {
        return setOpenModel(true);
      } else if (todayChallenge) {
        const payStatus = await episodePaidStatus();
        if (payStatus) {
          setJoinError(true);
        } else if (
          moment().format("HH:MM") >=
          moment
            .utc(challengeDetail?.challengeDates[0]?.dateTime, "x")
            .local()
            .format("HH:MM")
            ? true
            : false
        ) {
          return history.push(`/start-challenge/${params?.id}`);
        }
      }
    }
  };
  let dispatch = useDispatch();
  const authReducerState = useSelector((state) => state.auth);

  const joinChallenge = async () => {
    if (challengeDetail?.plan === "paid") {
      setOpenPaymentDialog(true)
    }else{
      setOpenPaymentDialog(false)
      const data = { challengeOrSessionId: params?.id, type: "Challenge" };
      const responseData = await dispatch(
        joinChallenges({
          token: authReducerState?.token,
          data: data,
        })
      );
      if (responseData?.success) {
        setShareOpen("Share");
      }
    }

  };

  const CustomSwitch = withStyles({
    switchBase: {
      "&$checked": {
        color: "#29cc10",
      },
      "&$checked + $track": {
        backgroundColor: "#29cc10",
      },
    },
    checked: {},
    track: {},
  })(Switch);

  function secondsToTime() {
    let totalDuration = challengeDetail?.challengeEpisodes?.reduce(
      (sum, ep) => sum + ep?.episode?.duration,
      0
    );
    totalDuration = totalDuration * 60;
    var hours = Math.floor(totalDuration / (60 * 60));

    var divisor_for_minutes = totalDuration % (60 * 60);
    var minutes = Math.floor(divisor_for_minutes / 60);

    var divisor_for_seconds = divisor_for_minutes % 60;
    var seconds = Math.ceil(divisor_for_seconds);

    var obj = {
      h: hours,
      m: minutes,
      s: seconds,
    };

    if (obj?.h === 0 && obj.m === 0) {
      return `${obj.s} sec`;
    } else if (obj?.h === 0) {
      return `${obj.m} min ${obj.s} sec`;
    } else {
      return `${obj.h} hr ${obj.m} min ${obj.s} sec`;
    }
  }

  const handleStatusChange = () => {
    dispatch(
      challengeActiveInActive({
        token: auth?.token,
        challengeId: params?.id,
        status: challengeStatus ? "InActive" : "Active",
      })
    );
    return;
  };

  return (
    <>
      <EpisodePaidDialog
        joinError={joinError}
        todayChallengeEpisodeDetail={todayChallengeEpisodeDetail}
        joinErrorHandler={() => {
          setJoinError(false);
          setTodayChallengeEpisodeDetail("");
        }}
      />

      {shareOpen && (
        <Dialog open={shareOpen} onClose={() => setShareOpen(false)}>
          <DialogTitle>
            <Grid container justify="space-between">
              <Grid item>
                <Typography component="h4" variant="h5">
                  {shareOpen}
                </Typography>
              </Grid>
              <Grid item>
                <Button onClick={() => setShareOpen(false)}>
                  <Close />
                </Button>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent
            style={{
              padding: isMobile && 0,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <InviteAndShare
              url={`/share/challenge/${params?.id}`}
              imageUrl={challengeDetail?.imageUrl}
              sumaryText={"Join this amazing challenge "}
              link={"Hey there,  Check this out, I think you will love it"}
              type="challenge"
              invite={shareOpen === "Invite"}
              share={shareOpen === "Share"}
              handleNext={() => setShareOpen(false)}
            />
          </DialogContent>
        </Dialog>
      )}

      <Modal
        disableEnforceFocus={true}
        open={openModel}
        onClose={handleCloseModal}
      >
        {modalBody}
      </Modal>
      <Modal
        disableEnforceFocus={true}
        open={openInviteModel}
        onClose={handleCloseInviteModal}
      >
        {inviteFriendModalBody}
      </Modal>

      <Grid container style={{ marginBottom: "15px" }}>
        <Grid item xs={12}>
          <div ref={scrollAble} style={{ height: '3px' }}></div>
        </Grid>

        {!isMobile && (
          <Grid item xs={12}>
            <Typography className={classes.title}>
              <Truncate lines={3} ellipsis={<span>...</span>}>
                {challengeDetail?.title}
              </Truncate>
            </Typography>
          </Grid>
        )}

        <Grid item xs={12} container>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className={classes.heroImageContainer}
            style={{
              backgroundImage: `url(${challengeDetail?.imageUrl})`,
              marginTop: "25px",
              backgroundPosition: "top",
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              width: "100%",
            }}
          >
            <div className={classes.heroImageContainer}>
              <Grid
                container
                style={{
                  position: "relative",
                  padding: "20px 15px 15px 15px",
                  right: 0,
                }}
              >
                {authReducerState?.user?.id === challengeDetail?.userId && (
                  <Grid
                    container
                    justify="flex-end"
                    style={{
                      zIndex: 2,
                      left: "100%",
                      top: "10px",
                    }}
                  >
                    <FormControlLabel
                      control={
                        <CustomSwitch
                          onChange={handleStatusChange}
                          checked={challengeStatus}
                          disabled={challengeDetail?.status === "Blocked"}
                        />
                      }
                      label={
                        <span className={classes.startTimeTxt}>
                          {challengeDetail?.status}
                        </span>
                      }
                    />
                  </Grid>
                )}

                {challengeDetail?.userId === auth?.user?.id && !isCompleted && (
                  <Grid
                    item
                    container
                    lg={12}
                    xs={12}
                    justify="flex-end"
                    style={{
                      zIndex: 2,
                      right: "10px",
                      top: "10px",
                      marginTop: "7px",
                    }}
                  >
                    <Link to={`/more/edit-challenge/${params?.id}`}>
                      <IconButton style={{ backgroundColor: "#fff" }}>
                        <Edit style={{ color: "#09A9BB" }} />
                      </IconButton>
                    </Link>
                    {/* </Grid> */}
                  </Grid>
                )}

             {/*    <Grid item xs={12}>
                  <Typography
                    className={classes.title}
                    style={{ color: "#FFF" }}
                  >
                    Challenge
                  </Typography>
                </Grid>

                <Grid item xs={12} container>
                  <Grid item xs={12} md={12}>
                    <Typography
                      className={classes.subTitle}
                      style={{ wordBreak: "break-all" }}
                    >
                      {challengeDetail?.description?.length > 70 && !expand ? (
                        <>
                          {challengeDetail?.description.slice(0, 69)}...{" "}
                          <span
                            className={classes.readMore}
                            onClick={expandDescription}
                          >
                            See More
                          </span>
                        </>
                      ) : challengeDetail &&
                        challengeDetail?.description?.length > 70 &&
                        expand ? (
                        <>
                          {challengeDetail?.description}{" "}
                          <span
                            className={classes.readMore}
                            onClick={expandDescription}
                          >
                            See Less
                          </span>
                        </>
                      ) : (
                        challengeDetail?.description
                      )}
                    </Typography>
                  </Grid>
                </Grid> */}
<Grid item xs={12} sm={12} md={12} lg={12} style={{marginTop:challengeDetail?.userId === auth?.user?.id ?"0px" :"100px"}}>

                <Grid
                  item
                  xs={12}
                  container
                  justify="space-between"
                  style={{ marginTop:"30px"}}
                  className={classes.top}
                >
                  <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
                    {challengeDetail &&
                      challengeDetail?.challengeEpisodes?.length > 0 && (
                        <>
                          <Typography className={classes.startTimeTxt}>
                            {intl.formatMessage({ id: "challengeCreator" })}
                          </Typography>

                          <Typography className={classes.startTimeTxt}>
                            <Link
                              to={`/view-profile/${challengeDetail?.user?.id}`}
                              className={classes.startTimeTxt}
                            >
                              {challengeDetail?.user?.name}
                            </Link>
                          </Typography>
                        </>
                      )}
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  container
                  justify="space-between"
                  style={{ marginTop: "20px" }}
                >
                  <Grid item xs={7} sm={7} md={7} lg={8} xl={8}>
                    {challengeDetail &&
                      challengeDetail?.challengeEpisodes?.length > 0 && (
                        <>
                          <Typography className={classes.startTimeTxt}>
                            {intl.formatMessage({ id: "duration" })}
                          </Typography>
                          <Typography className={classes.startTimeTxt}>
                           {/*  {secondsToTime()}
                            {challengeDetail?.challengeGroup === "step"
                              ? "  Per day"
                              : ""} */}
                               {challengeDetail?.totalDurationInMinutes + " " + "min"}
                          </Typography>
                        </>
                      )}
                  </Grid>
                  <Grid item xs={5} sm={5} md={5} lg={4} xl={4}>
                    {challengeDetail &&
                      challengeDetail?.challengeDates?.length > 0 &&
                      challengeDetail?.startDate && (
                        <>
                          <Typography className={classes.startTimeTxt}>
                            {intl.formatMessage({ id: "startDate" })}
                          </Typography>
                          <Typography className={classes.startTimeTxt}>
                            {moment
                              .utc(challengeDetail?.startDate)
                              .local()
                              .format("MMM DD, YYYY")}
                          </Typography>
                        </>
                      )}
                  </Grid>
                </Grid>

                <Grid
                  item
                  xs={12}
                  container
                  justify="space-between"
                  style={{ marginTop: "20px" }}
                >
                  <Grid item xs={7} sm={7} md={7} lg={8}>
                    {!isCompleted && (
                      <>
                        <Typography className={classes.startTimeTxt}>
                          start at
                        </Typography>
                        <Typography className={classes.startTimeTxt}>
                          {challengeDetail?.challengeDates?.length > 0 &&
                            moment
                              .utc(
                                challengeDetail?.challengeDates[0]?.dateTime,
                                "x"
                              )
                              .local()
                              .format("LT")}
                        </Typography>
                      </>
                    )}
                  </Grid>
                  <Grid item xs={5} sm={5} md={5} lg={4} xl={4}>
                    {isJoined ? (
                      <Button
                        onClick={startChallengeHandler}
                        className={classes.startNowBtn}
                      >
                        {isCompleted
                          ? intl.formatMessage({ id: "completed" })
                          : intl.formatMessage({ id: "startNow" })}
                      </Button>
                    ) : (
                      <Button
                        onClick={() => {
                          isCompleted
                            ? startChallengeHandler()
                            : joinChallenge();
                        }}
                        className={classes.startNowBtn}
                      >
                        {isCompleted
                          ? intl.formatMessage({ id: "completed" })
                          : (challengeDetail?.plan === "paid")?
                          `Join For ${configs.currency}${challengeDetail?.price}` :
                           intl.formatMessage({ id: "joinnow" })}
                      </Button>
                    )}
                  </Grid>
                </Grid>


                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>

        {challengeDetail?.type === "Group" && !isCompleted && (
          <Grid item xs={12} container justify="flex-end" spacing={1}>
           {/*  <Grid
              item
              xs={7}
              sm={5}
              md={5}
              lg={10}
              xl={4}
              style={{ display: "flex", float: "left", marginTop: "17px" }}
            >
              <Button
                className={classes.upgradeNowBtn}
                style={{ fontWeight: "700" }}
                onClick={() => history.push("/more/subscription-plan")}
              >
                <Typography>
                  {reducerUser?.user?.purchasedSubscription?.SubscriptionPlan
                    ?.name === "free" &&
                  reducerUser?.user?.purchasedSubscription.length === 0
                    ? "SUBSCRIBE"
                    : "UPGRADE"}
                </Typography>
              </Button>
            </Grid> */}
            <Grid item
            >
              <Tooltip
                title={
                  <span style={{ fontSize: "14px", letterSpacing: 0.3 }}>
                    Invite
                  </span>
                }
                placement="top"
              >
                <IconButton
                  disabled={
                    challengeDetail?.status === "Incomplete" ||
                    challengeDetail?.status === "Blocked" ||
                    challengeDetail?.status === "InActive"
                  }
                  onClick={() => setShareOpen("Invite")}
                  className={classes.inviteAndShare}
                >
                  <InsertInvitation />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item>
              <Tooltip
                title={
                  <span style={{ fontSize: "14px", letterSpacing: 0.3 }}>
                    Share
                  </span>
                }
                placement="top"
              >
                <IconButton
                  disabled={
                    challengeDetail?.status === "Incomplete" ||
                    challengeDetail?.status === "Blocked" ||
                    challengeDetail?.status === "InActive"
                  }
                  onClick={() => setShareOpen("Share")}
                  className={classes.inviteAndShare}
                >
                  <Share />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        )}
           <Grid item xs={12}>
                  <Typography
                    className={classes.title}
                  //  style={{ color: "#FFF" }}
                  >
                    Description
                  </Typography>
                </Grid>

                <Grid item xs={12} container>

                  <Grid item xs={12} md={12}>
                    <Typography
                      className={classes.subTitle}
                      style={{ wordBreak: "break-all" }}
                    >
                      {challengeDetail?.description?.length > 70 && !expand ? (
                        <>
                          {challengeDetail?.description.slice(0, 69)}...{" "}
                          <span
                            className={classes.readMore}
                            onClick={expandDescription}
                          >
                            See More
                          </span>
                        </>
                      ) : challengeDetail &&
                        challengeDetail?.description?.length > 70 &&
                        expand ? (
                        <>
                          {challengeDetail?.description}{" "}
                          <span
                            className={classes.readMore}
                            onClick={expandDescription}
                          >
                            See Less
                          </span>
                        </>
                      ) : (
                        challengeDetail?.description
                      )}
                    </Typography>
                  </Grid>
                </Grid>

        {challengeDetail?.type === "Group" && (
          <>
            {" "}
            <Grid item xs={12} md={12} container>
              <Typography className={classes.title}>
                <>
                  Participants{" "}
                  {checkInviteHandler() === true && (
                    <Button
                      variant="contained"
                      onClick={() => setInviteModel(true)}
                      className={classes.button}
                      startIcon={<Add />}
                    >
                      Invite
                    </Button>
                  )}
                </>
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={11}
              container
              spacing={2}
              style={{ marginTop: "10px" }}
            >
              {challengeDetail?.challengeParticipants?.length > 0 ? (
                challengeDetail?.challengeParticipants?.length === 1 &&
                challengeDetail?.challengeParticipants[0]?.id ===
                  authReducerState?.user?.id ? (
                  <Grid item xs={12} container>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <Typography className={classes.noContent}>
                        No other participant yet
                      </Typography>
                    </Grid>
                  </Grid>
                ) : (
                  challengeDetail?.challengeParticipants?.map((participant) => {
                    if (participant?.id !== authReducerState?.user?.id) {
                      return (
                        <Grid item xs={4} md={2} key={participant?.id}>
                          <ChallengeParticipantCard participant={participant} />
                        </Grid>
                      );
                    }
                  })
                )
              ) : (
                <Grid item xs={12} container>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Typography className={classes.noContent}>
                      No participant yet
                    </Typography>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </>
        )}

        <Grid item xs={12} container className={classes.top}>
          <Grid
            item
            xs={12}
            md={12}
            lg={12}
            container
            spacing={2}
            className={classes.scroll}
          >
            {challengeExerciseDates?.length > 0 &&
              challengeExerciseDates?.map((item) => (
                <Grid
                  item
                  xs={4}
                  md={4}
                  key={item?.id}
                  style={{ minWidth: "100px" }}
                >
                  <ChallengeRepetitionCard status={item} date={item} />
                </Grid>
              ))}
          </Grid>
        </Grid>

        <Grid item xs={12} container className={classes.top}>
          <Grid
            item
            xs={12}
            md={12}
            lg={12}
            container
            spacing={2}
            className={classes.scroll}
          >
            {challengeDetail?.challengeEpisodes?.length > 0 &&
              challengeDetail?.challengeEpisodes?.map((item) => (
                <Grid
                  item
                  xs={5}
                  md={5}
                  key={item?.id}
                  style={{ minWidth: "220px" }}
                >
                  <ChallengeLessonCard
                    id={item?.episode?.id}
                    image={item?.episode?.thumbnail}
                    name={item?.episode?.name}
                    category={item?.episode?.course?.category?.name}
                    totalDuration={item?.episode?.duration}
                    width="100%"
                  />
                </Grid>
              ))}
          </Grid>
        </Grid>

        <Grid item xs={12} container className={classes.top}>
          <Grid item xs={12} md={6}>
            <Card
              className={
                isMobile ? classes.progressCardMobile : classes.progressCard
              }
            >
              <Grid container>
                <Grid item xs={12} style={{ textAlign: "center" }}>
                  <CircularProgressWithLabel value={getUserProgress() || 0} />
                  <Grid item xs={12}>
                    <Typography className={classes.goalProgressTxt}>
                      Goal progress
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </Grid>
      <CustomerCardList
        open={openPaymentDialog}
        setOpen={setOpenPaymentDialog}
       // setRecommendUpgrade={setRecommendUpgrade}
        setShareOpen={setShareOpen}
        challengeId={challengeDetail?.id}
        plan={challengeDetail?.plan}
        price={challengeDetail?.price}
      />
    </>
  );
}

export default ChallengeDetail;

const useStyles = makeStyles((theme) => ({
  title: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "24px",
    lineHeight: "28px",
    color: "#3C4A6B",
    marginTop: "25px",
  },
  heroImageContainer: {
    backgroundImage:
      "linear-gradient(360deg, rgba(0, 32, 60, 0.95) 0%, rgba(5, 39, 68, 0.183073) 78%, rgba(123, 146, 166, 0) 100%)",
    borderRadius: "11px",
    width: "100%",
    minHeight: "340px",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  subTitle: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "26px",
    color: "#7d7b79",
    letterSpacing: "0.04em",
  },
  top: {
    marginTop: "30px",
  },
  startNowBtn: {
    height: "49px",
    width: "100%",
    background: "linear-gradient(360deg, #15AEBF 0%, #A6D6FF 100%)",
    borderRadius: "10px",
    transform: "rotate(360deg)",
    fontFamily: theme.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: 900,
    fontSize: "20px",
    lineHeight: "27px",
    color: "#FFFFFF",
    textTransform: "none",
  },
  upgradeNowBtn: {
    height: "49px",
    width: "30%",
    background: "linear-gradient(360deg, #15AEBF 0%, #A6D6FF 100%)",
    borderRadius: "10px",
    transform: "rotate(360deg)",
    fontFamily: theme.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: 900,
    fontSize: "20px",
    lineHeight: "27px",
    color: "#FFFFFF",
    textTransform: "none",
  },
  startTimeTxt: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "16px",
    color: "#FFFFFF",
  },
  progressCard: {
    background: "#FFFFFF",
    boxShadow: "0px 6px 15px rgba(0, 0, 0, 0.05)",
    borderRadius: "15px",
    width: "100%",
    height: "234px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  progressCardMobile: {
    background: "#3F5B73",
    boxShadow: "0px 6px 15px rgba(0, 0, 0, 0.05)",
    borderRadius: "15px",
    width: "100%",
    height: "234px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  goalProgressTxt: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "19px",
    color: "#FFFFFF",
    marginTop: "5px",
    display: "block",
  },
  circle: {
    color: "#F9A502",
  },
  scroll: {
    flexWrap: "unset",
    overflowX: "auto",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  paper: {
    position: "absolute",
    height: 330,
    maxWidth: 315,
    minWidth: 300,
    justifyContent: "center",
    backgroundColor: theme.palette.background.paper,
    outline: "none",
  },
  paperInvite: {
    position: "absolute",
    height: 430,
    maxWidth: 320,
    minWidth: 300,
    justifyContent: "center",
    backgroundColor: theme.palette.background.paper,
    outline: "none",
  },
  modelBodyText: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "17px",
    lineHeight: "27px",
    textAlign: "center",
    letterSpacing: "0.02em",
    color: "#192247",
  },
  modelBtn: {
    height: "73px",
    width: "100%",
    fontFamily: theme.fontFamily.Roboto,
    cursor: "pointer",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "21px",
    color: theme.colors.white,
  },

  modalBtnGrid: {
    padding: "0px",
    alignSelf: "flex-end",
    color: "#fff",
    cursor: "pointer",
    background: `linear-gradient(#09A9BB 1%,#09A9BB 100%)`,
    height: "73px",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
  },
  msgBody: {
    minHeight: "240px",
    alignItems: "center",
    display: "flex",
  },
  button: {
    backgroundColor: "#F28705 !important",
    color: "#FFF",
    height: "30px",
  },
  noContent: {
    color: "#7d7b79",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "19px",
    padding: "12px 10px 10px 17px",
    textAlign: "center",
  },
  readMore: {
    fontSize: "12px",
    cursor: "pointer",
    color: "#C4C4C4",
    "&:hover": {
      textDecoration: "underline",
      color: "#84CDF0",
    },
  },
  speedDial: {
    position: "fixed",
    top: theme.spacing(10),

    "& .MuiFab-primary:hover": {
      width: "50px",
      height: "50px",
      color: "#E54D40",
      backgroundColor: "#E5E5E5 !important",
    },
    "& .MuiFab-primary": {
      width: "50px",
      height: "50px",
      backgroundColor: "#F28705 !important",
    },
    "&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft": {
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    "&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight": {
      top: theme.spacing(3),
      left: theme.spacing(2),
    },
  },
  checked: {
    color: "green",
  },
  inviteAndShare: {
    background: "linear-gradient(360deg, #15AEBF 0%, #A6D6FF 100%)",
    marginTop: "1rem",
    fontSize: "20px",
    lineHeight: "10px",
    color: "#FFFFFF",
  },
  paymentHeader: {
    color: "#000",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "19px",
  },
  paymentCourseName: {
    color: "#000",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "19px",
  },
  paymentEpisodeName: {
    color: "#000",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "19px",
  },
  paymentBtn: {
    background:
      "linear-gradient(360deg, rgb(76, 175, 80) 100%, rgb(229, 186, 191) 0%)",
    height: "30px",
    textDecoration: "none",
    color: "#FFFFFF",
    width: "60%",
    fontSize: "14px",
    fontStyle: "normal",
    fontFamily: "Reef",
    fontWeight: 500,
    lineHeight: "10px",
    letterSpacing: 0.3,
  },
}));
