import React, { useState, useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import {
  AppBar,
  Tab,
  Tabs,
  Box,
  FormControl,
  Select,
  MenuItem,
  FormHelperText
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { useIntl } from "react-intl";
import moment from "moment";
import { Toolbar, IconButton } from "@material-ui/core";
import ChallengeCard from "../../../../components/Common/ChallengeCard";
import { useSelector } from "react-redux";
import { ArrowBack } from "@material-ui/icons";
import Calender from "../.././../../assets/Icons/calender.svg";

const StyledTabs = withStyles(theme => ({
  root: {
    width: "100%"
  },
  indicator: {
    display: "flex",
    height: "0.2rem",
    justifyContent: "center",
    backgroundColor: "transparent",
    "& > span": {
      width: "100%",
      backgroundColor: theme.colors.selected
    }
  }
}))(props => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

const StyledTab = withStyles(theme => ({
  root: {
    minWidth: "60px !important",

    textTransform: "none",
    color: theme.colors.selected,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    "&:focus": {
      opacity: 1
    }
  }
}))(props => <Tab disableRipple {...props} />);
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <>{children}</>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function AllChallenges(props) {
  const classes = useStyles();
  const intl = useIntl();
  let history = useHistory();
  const [value, setValue] = React.useState(0);
  const [optionList] = useState([
    { name: "Completed" },
    { name: "In Progress" }
  ]);
  const [option, setOption] = useState("");
  const [relateChallenges, setRelateChallenges] = useState([]);
  const [friendChallenges, setFriendChallenges] = useState([]);
  const allChallenges = useSelector(state => state?.challenges?.allChallenges);
  const authReducerState = useSelector(state => state.auth);

  useEffect(() => {
    const friendsChallenges = [];
    const userChallenges = allChallenges?.filter(challenge => {
      if (challenge?.challenge?.userId === authReducerState?.user?.id) {
        return challenge;
      } else {
        friendsChallenges.push(challenge);
        return;
      }
    });
    setRelateChallenges(userChallenges);
    setFriendChallenges(friendsChallenges);
  }, [allChallenges]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleSelect = event => {
    if (event.target.value === "") {
      const friendsChallenges = [];
      const userChallenges = allChallenges?.filter(challenge => {
        if (challenge?.challenge?.userId === authReducerState?.user?.id) {
          return challenge;
        } else {
          friendsChallenges.push(challenge);
          return;
        }
      });
      setRelateChallenges(userChallenges);
      setFriendChallenges(friendsChallenges);
      setOption(event.target.value);
    } else if (event.target.value === "In Progress") {
      const friendsChallenges = [];
      const userChallenges = allChallenges?.filter(challenge => {
        if (
          challenge?.challenge?.userId === authReducerState?.user?.id &&
          !challenge?.challenge?.isChallengeExpired
        ) {
          return challenge;
        } else if (!challenge?.challenge?.isChallengeExpired) {
          friendsChallenges.push(challenge);
          return;
        }
      });
      setRelateChallenges(userChallenges);
      setFriendChallenges(friendsChallenges);
      setOption(event.target.value);
    } else if (event.target.value === "Completed") {
      const friendsChallenges = [];
      const userChallenges = allChallenges?.filter(challenge => {
        if (
          challenge?.challenge?.userId === authReducerState?.user?.id &&
          challenge?.challenge?.isChallengeExpired
        ) {
          return challenge;
        } else if (challenge?.challenge?.isChallengeExpired) {
          friendsChallenges.push(challenge);
          return;
        }
      });
      setRelateChallenges(userChallenges);
      setFriendChallenges(friendsChallenges);
      setOption(event.target.value);
    }
  };

  return (
    <Grid
      container
      style={{
        backgroundColor: "#fbfaf9",
        height: "100%",
        paddingBottom: "30px"
      }}
    >
      {/*app bar*/}
      <Grid container item xs={12}>
        <Grid item xs={12}>
          <AppBar
            className={classes.appBar}
            style={{ backgroundColor: "#fff" }}
          >
            <Grid
              item
              xs={2}
              style={{ justifyContent: "center", display: "flex" }}
            >
              <IconButton
                onClick={() => history.push("/more/relate-with-friends")}
                className={classes.backButton}
              >
                <ArrowBack />
              </IconButton>
            </Grid>
            <Grid item xs={8}>
              <Toolbar>
                <Typography className={classes.appBarText} variant="h6">
                  {intl.formatMessage({ id: "challenges" })}
                </Typography>
              </Toolbar>
            </Grid>

          </AppBar>
        </Grid>
      </Grid>
      <Grid container style={{ backgroundColor: "#fff" }}>
        <StyledTabs
          value={value}
          variant="fullWidth"
          onChange={handleChange}
          aria-label="styled tabs example"
        >
          <StyledTab label={intl.formatMessage({ id: "myChallenges" })} />
          <StyledTab label={intl.formatMessage({ id: "friendChallenges" })} />
        </StyledTabs>
      </Grid>
      <Grid
        item
        xs={12}
        container
        alignItems="center"
        style={{ margin: "20px 20px 0px 20px" }}
      >
        <Grid item xs={7}>
          <Typography className={classes.appBartext}>
            {intl.formatMessage({ id: "showing" })}{" "}
            {value === 0 ? relateChallenges?.length : friendChallenges?.length}{" "}
            {intl.formatMessage({ id: "results" })}
          </Typography>
        </Grid>
        <Grid item xs={5}>
          <FormControl className={classes.inputField}>
            <Select
              className={classes.selectCategory}
              style={{ width: "100%" }}
              classes={{
                iconFilled: classes.iconSelect,
                icon: classes.iconSelect
              }}
              value={option}
              onChange={handleSelect}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
            >
              <MenuItem value={""}>All</MenuItem>
              {optionList?.map((op, ind) => {
                return (
                  <MenuItem value={op?.name} key={ind}>
                    {op?.name}
                  </MenuItem>
                );
              })}
            </Select>

            <FormHelperText style={{ color: "white", textAlign: "center" }} />
          </FormControl>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <TabPanel
          value={value}
          index={0}
          style={{ width: "100%", minHeight: "100vh", paddingBottom: '25px' }}
        >
          <Grid container xs={12} lg={12} spacing={1}>
            {relateChallenges && relateChallenges?.length === 0 ? (
              <Grid item xs={12}>
                <Typography className={classes.noContent}>
                  No Record found
                </Typography>
              </Grid>
            ) : (
              relateChallenges?.map(challenge => {
                return (
                  <Grid
                    item
                    xs={12}
                    lg={3}
                    key={challenge?.challenge?.id}
                    style={{ marginBottom: "15px" }}

                  >
                      <Link
                            to={`/challenge/details/${challenge?.challenge?.id}`}
                            style={{ textDecoration: "none" }}
                          >
                   <ChallengeCard
                      id={challenge?.challenge?.id}
                      image={challenge?.challenge?.imageUrl}
                      category={challenge?.challenge?.course?.category?.name}
                      name={challenge?.challenge?.title}
                      friends={`${challenge?.challenge?.participantsCount} friends`}
                      totalDuration={Math.round(
                        challenge?.challenge?.duration / 60
                      )}
                      time={moment(
                        challenge?.challenge?.exerciseTime,
                        "LTS"
                      ).format("LT")}
                      tooltip={true}
                      startDate={challenge?.challenge?.startDate}
                      dates={challenge?.challenge?.challengeDates}
                      />
                    </Link>

                  </Grid>
                );
              })
            )}
          </Grid>
        </TabPanel>
        <TabPanel
          value={value}
          index={1}
          style={{ width: "100%", minHeight: "100vh" }}
        >
          <Grid container xs={12} sm={12} lg={12} spacing={1}>
            {friendChallenges && friendChallenges?.length === 0 ? (
              <Grid item xs={12}>
                <Typography className={classes.noContent}>
                  No Record found
                </Typography>
              </Grid>
            ) : (
              friendChallenges?.map(challenge => {
                return (
                  <Grid
                    item
                    xs={12}
                    lg={3}
                    key={challenge?.challenge?.id}
                    style={{ marginBottom: "15px" }}

                  >
                       <Link
                            to={`/challenge/details/${challenge?.challenge?.id}`}
                            style={{ textDecoration: "none" }}
                          >

                    <ChallengeCard
                      image={challenge?.challenge?.imageUrl}
                      category={challenge?.challenge?.course?.category?.name}
                      name={challenge?.challenge?.title}
                      friends={`${challenge?.challenge?.participantsCount} friends`}
                      totalDuration={Math.round(
                        challenge?.challenge?.duration / 60
                      )}
                      time={moment(
                        challenge?.challenge?.exerciseTime,
                        "LTS"
                      ).format("LTS")}
                      />
                      </Link>
                  </Grid>
                );

              })
            )}
          </Grid>
        </TabPanel>
      </Grid>
    </Grid>
  );
}

export default AllChallenges;

const useStyles = makeStyles(theme => ({
  appBar: {
    color: "#000",
    flexDirection: "row",
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    right: "unset",
    position: "relative",
    boxShadow: "none"
  },

  appBarText: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: "900",
    fontSize: "20px",
    lineHeight: "23px"
  },

  backButton: {
    background: "transparent",
    color: "#000",
    padding: "0px"
  },
  appBartext: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "italic",
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "21px",

    color: theme.colors.selected
  },
  inputField: {
    width: "100%",
    background: theme.colors.selected,
    borderRadius: "82px"
  },

  selectCategory: {
    background: theme.colors.selected,
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontSize: "14px",
    lineHeight: "16px",
    textAlign: "center",
    height: "41px",
    width: "156px",

    borderRadius: "20px",
    fontWeight: 500,
    color: "#ffffff",
    paddingRight: "10px",
    "&.MuiInput-underline::before": {
      borderBottom: "none"
    },
    "&.MuiInput-underline:after": {
      borderBottom: "none"
    },
    "&.MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottom: "none"
    },
    "& .MuiSelect-icon": {
      color: "#ffffff !important",
      paddingRight: "10px"
    }
  },
  iconSelect: {
    color: "#ffffff",
    paddingRight: "10px",
    variant: "filled"
  },
  adjustCardsLayout: {
    "&.MuiGrid-spacing-xs-2": { margin: "0px !important" },
    justifyContent: "center"
  },
  hideScrollBar: {
    flexWrap: "unset",
    overflowX: "auto",
    "&::-webkit-scrollbar": {
      display: "none"
    }
  },
  paper: {
    position: "absolute",
    height: 330,
    maxWidth: 315,
    minWidth: 300,
    justifyContent: "center",
    backgroundColor: theme.palette.background.paper,
    outline: "none"
  },

  modelBodyText: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "17px",
    lineHeight: "27px",
    textAlign: "center",
    letterSpacing: "0.02em",
    color: "#192247"
  },

  modelBtn: {
    height: "73px",
    width: "100%",
    fontFamily: theme.fontFamily.Roboto,
    cursor: "pointer",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "21px",
    color: theme.colors.white
  },

  modalBtnGrid: {
    padding: "0px",
    alignSelf: "flex-end",
    color: "#fff",
    cursor: "pointer",
    background: `linear-gradient(#09A9BB 1%,#09A9BB 100%)`,
    height: "73px",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    display: "flex"
  },
  msgBody: {
    minHeight: "240px",
    alignItems: "center",
    display: "flex"
  },
  noContent: {
    color: "#7d7b79",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "19px",
    padding: "12px 10px 10px 17px",
    textAlign: "center"
  }
}));
