import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import { ArrowBack, Close } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import { useHistory } from 'react-router-dom'

function AppBarBackBtnTitle(props) {
    const classes = useStyles();
    const history = useHistory();

    return (
        <Grid container className={classes.container}>
            <Grid item xs={1} />
            <Grid item xs={1}>
                <ArrowBack onClick={() => props.onClick(props?.goBackLocation)} />
            </Grid>
            <Grid item xs={8}>
                <Typography className={classes.header} style={{ ...props?.headerStyle }}>{props.title}</Typography>
            </Grid>
            <Grid item xs={1}>
                {
                    props?.crossLocation && <Close onClick={() => history.push(props?.crossLocation)} />
                }
            </Grid>
            <Grid item xs={1} />

        </Grid>
    )
}

export default AppBarBackBtnTitle

const useStyles = makeStyles((theme) => ({
    container: {
        height: '90px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    header: {
        fontFamily: theme.fontFamily.Reef,
        fontStyle: "normal",
        fontWeight: 900,
        fontSize: "26px",
        lineHeight: "35px",
        textAlign: 'center',
        color: '#2E2E2E',
    }
}))