import React, { useEffect, useState } from 'react';
import { Grid, CircularProgress, Typography, Dialog, DialogContent, DialogActions, Avatar, ListItemIcon, ListItemText, useMediaQuery,Button  } from '@material-ui/core'
import { Delete } from '@material-ui/icons'
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useIntl } from "react-intl";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {deleteTrainingCourse} from '../../actions/retreatCourse'

function PlayTrainingVideo(props) {
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();
    const history = useHistory();
    const intl = useIntl();
    const [open, setOpen] = useState(false)
    const [loader, setLoader] = useState(false)
    const course = useSelector(state => state?.retreatCourse?.trainingCourseDetails);
    const auth = useSelector(state => state?.auth);
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const handleDeleteCourse = ()=>{
        setLoader(true);
        const res =  dispatch(
           deleteTrainingCourse({
             token: auth?.token,
             courseId: course.id
           })
         );
         if(res){
           setLoader(false);
           return history.goBack()
         }
    }

    const handleClose = ()=>{
        setOpen(false);
    }

    const handleOpen = ()=>{
        setOpen(true);
    }

    return (
        <  >
            {
                isMobile ? <>
                    <ListItemIcon onClick={handleOpen}>
                        <Delete fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary="Delete" onClick={handleOpen}/>
                </> : <div  onClick={handleOpen} ><Delete className={classes.icon} /></div>
            }

            <Dialog
                open={Boolean(open)}
                onClose={() => setOpen(null)}>
                <DialogContent>
                    <Typography component="h4" variant="h6">
                        {intl.formatMessage({ id: "areYouSure" })} {course?.name}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    {loader ? (
                        <CircularProgress size={15} style={{ color: "#09A9BB" }} />
                    ) : (
                        <Button onClick={handleDeleteCourse} style={{ color: "#09A9BB" }}>
                            {intl.formatMessage({ id: "yes" })}
                        </Button>
                    )}
                    <Button onClick={handleClose}>
                        {intl.formatMessage({ id: "no" })}
                    </Button>
                </DialogActions>
            </Dialog>

        </>
    );
}

export default PlayTrainingVideo;

const useStyles = makeStyles(theme => ({
    role: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '12px',
        lineHeight: '100%',
        color: '#C6C6C6',
        paddingTop: '5px',

    },
    name: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '14px',
        lineHeight: '100%',
        color: '#353B35',
        paddingTop: '3px',
    },
    icon: {
        color: '#666666',
        cursor: 'pointer'
    }

}));