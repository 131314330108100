import {
  Button,
  Card,
  Grid,
  TextField,
  Typography,
  useMediaQuery,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Radio,
  helperText
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import AddImage from "../../../components/AddCourse/AddImage/Index";
import { useIntl } from "react-intl";
import clsx from 'clsx'

const DescriptionFormContainer = (props) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  let selectedSession = useSelector((state) => state.session.selectedSession);
  const intl = useIntl();
  const [errorMessage, setErrorMessage] = useState('')
  const [plan, setIsPlan] = useState((props?.sessionData &&
    props?.sessionData?.sessionDetails &&
    props?.sessionData?.sessionDetails?.plan) || 'free');
  const [sessionPrice, setSessionPrice] = useState(props.sessionData &&
    props.sessionData.sessionDetails &&
    props.sessionData.sessionDetails.price
    ? props.sessionData.sessionDetails.price
    : null);
  const [image, setImage] = useState(
    props.sessionData && props.sessionData.sessionImage
      ? URL.createObjectURL(props.sessionData.sessionImage)
      : props.sessionData && props.sessionData.imageUrl !== ""
        ? props.sessionData.imageUrl
        : ""
  );
  const [imageFile, setImageFile] = useState(
    props.sessionData && props.sessionData.sessionImage
      ? props.sessionData.sessionImage
      : ""
  );

  const [session_name, setSessionName] = useState(
    props?.sessionData?.sessionDetails?.name
      ? props?.sessionData?.sessionDetails?.name
      : ""
  );

  const [goal, setGoal] = useState(
    props.sessionData &&
      props.sessionData.sessionDetails &&
      props.sessionData.sessionDetails.goal
      ? props.sessionData.sessionDetails.goal
      : ""
  );
  const [meetingId, setMeetingId] = useState(
    props.sessionData &&
      props.sessionData.sessionDetails &&
      props.sessionData.sessionDetails.meetingId
      ? props.sessionData.sessionDetails.meetingId
      : ""
  );

  const classes = useStyles();
  const selectedFile = (file) => {
    setErrorMessage('')
    if (file) {
      setImage(URL.createObjectURL(file));
      setImageFile(file);
    }
  };
  const [invalidUrl, setInvalidUrl] = useState(false);

  const handleChangeMeetingUrl = (e) => {
    setErrorMessage('')
    setInvalidUrl(false);
    setMeetingId(e.target.value);
  };

  const is_url = (str) => {
    let expression = /[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/gi;
    let regexp = new RegExp(expression);
    return regexp.test(str);
  };

  const hasError = () => {
    if (!image) {
      return setErrorMessage('session image is required!')
    } else if (!session_name) {
      return setErrorMessage('session name is required!')
    } else if (plan === 'paid' && !sessionPrice) {
      return setErrorMessage('add session price!')
    } else if (!goal) {
      return setErrorMessage('goal is required!')
    }

    else {
      return goNext()
    }

  }

  const goNext = () => {


    props.handleNext({
      sessionDetails: {
        name: session_name,
        goal: goal,
        meetingId: meetingId,
        plan: plan,
        price: plan !== 'paid' ? null : sessionPrice
      },
      sessionImage: imageFile,
    });

  };

  const handleChange = (e) => {
    setIsPlan(e.target.value);

  }

  function StyledRadio(props) {
    const classes = useStyles();

    return (
      <Radio
        className={classes.root}
        classes={{ root: classes.root }}
        disableRipple
        color="default"
        checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
        icon={<span className={classes.icon} />}
        {...props}
      />
    );
  }

  return (
    <>
      <Grid
        container
        style={{
          paddingLeft: isMobile ? "0px" : "2rem",

          height: isMobile && props.screen === "live" ? "100vh" : "",
          backgroundColor: isMobile ? "#FBFAF9" : "",

        }}
      >
        <Grid item xs={12} >
          <FormControl
            style={{ display: 'flex', padding: '15px 25px 0px 25px' }}


          >
            <RadioGroup
              aria-label="gender"
              name="gender1"
              value={plan}
              onChange={handleChange}
            >
              <Grid container justify={isMobile && 'center'}>
                <Grid xs={12} md={6} xl={4} item container >
                  <Grid item xs={4}>
                    <FormControlLabel
                      value={'free'}
                      control={<StyledRadio />}
                      label="Free"
                    />
                  </Grid>
                  <Grid item xs={4}>

                    <FormControlLabel
                      value={'paid'}
                      control={<StyledRadio />}
                      label="Paid"
                    />
                  </Grid>
                  <Grid item xs={4}>

                    <FormControlLabel
                      value={'subscription'}
                      control={<StyledRadio />}
                      label="Subscription"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </RadioGroup>
          </FormControl>
        </Grid>

        <Grid container >
          <Grid
            xs={12}
            sm={12}
            md={6}
            xl={6}
            lg={6}
            container
            className={isMobile ? classes.mobilePadding : ""}
          >
            {/* {props.screen === "live" && isMobile ? (
              ""
            ) : ( */}
            <Grid item xs={12} style={{ marginTop: "1rem" }}>
              <Card
                elevation={0}
                style={{
                  backgroundColor: isMobile
                    ? "#FBFAF9"
                    : "rgb(251, 250, 249)",
                }}
              >
                <AddImage
                  btnText={intl.formatMessage({ id: "addImage" })}
                  image={image}
                  selectedFile={selectedFile}
                />
              </Card>
            </Grid>
            {/* )} */}


            <Grid container item xs={12}>
              <Grid item xs={12}>
                <Grid
                  item
                  xs={12}
                  style={{
                    paddingLeft: isMobile ? "0px" : "0px",

                  }}
                >
                  <TextField
                    id="standard-multiline-static"
                    fullWidth
                    value={session_name}
                    className={classes.nameTextField}
                    onChange={(e) => {
                      setErrorMessage('')
                      setSessionName(e.target.value)
                    }
                    }
                    margin="normal"
                    label={intl.formatMessage({ id: "name" })}
                  />
                </Grid>
                {plan === 'paid' && <Grid
                  item
                  xs={12}
                  style={{
                    paddingLeft: isMobile ? "0px" : "0px",

                  }}
                >
                  <TextField
                    id="standard-multiline-static"
                    fullWidth
                    type='Number'
                    value={sessionPrice}
                    className={classes.nameTextField}
                    onChange={(e) => {
                      setErrorMessage('')
                      e.target.value > 0
                        ? setSessionPrice(e.target.value)
                        : setSessionPrice('')
                    }
                    }
                    margin="normal"
                    label={intl.formatMessage({ id: "Price" })}
                    InputProps={{
                      inputProps: {
                        min: 1,
                      },
                    }}
                  />
                </Grid>}

                <Grid item xs={12}>
                  <TextField
                    id="standard-multiline-static"
                    classes={{
                      root: classes.goalTextArea,
                    }}
                    fullWidth
                    value={goal}
                    onChange={(e) => {
                      setErrorMessage('')
                      setGoal(e.target.value)
                    }
                    }
                    margin="normal"
                    rows={6}
                    multiline
                    label={
                      <span style={{ padding: "10px 0 0 10px" }}>
                        {intl.formatMessage({ id: "goal" })}
                      </span>
                    }
                  />
                </Grid>

                {!isMobile && <><Grid item xs={12}>
                  <Button
                    style={{
                      width: "100%",
                      textTransform: "none",
                      height: "2.750em",
                      background: "linear-gradient(180deg, #FFC000 -13.24%, #F28705 100%)",
                      borderRadius:
                        isMobile && !isDesktop ? "50px" : "11px",
                      fontFamily: theme.fontFamily.Roboto,
                      fontStyle: "normal",
                      fontWeight: 500,
                      fontSize: "20px",
                      lineHeight: "23px",
                      color: "#FFFFFF",
                    }}

                    onClick={hasError}
                  >
                    {isMobile
                      ? intl.formatMessage({ id: "schedule" })
                      : intl.formatMessage({ id: "next" })}
                  </Button>
                </Grid>
                  <Grid item xs={12}>
                    <p className={classes.errorMessage}>
                      {errorMessage}
                    </p>
                  </Grid>
                </>}


                {/* )} */}
              </Grid>
            </Grid>


          </Grid>

          {!isMobile && (
            <Grid
              style={{
                marginTop: "1rem",
                paddingLeft: "40px",
                paddingRight: "0px",
              }}
              s
              direction="column"
              justify="space-between"
              item
              container
              xs={12}
              sm={12}
              md={5}
              xl={5}
              lg={5}
            >

            </Grid>
          )}
          {((props.screen === "live" && !isMobile) || isMobile) && (
            <Grid
              xs={12}
              sm={12}
              md={5}
              xl={5}
              lg={5}
              container
              style={{ alignSelf: "flex-end" }}
              className={isMobile ? classes.mobilePaddingBtn : ""}
            >
              <Grid item xs={12} style={{ marginTop: "2rem" }}>
                <Button
                  style={{
                    textTransform: "none",
                    width: "100%",
                    height: "2.750em",
                    background: "linear-gradient(180deg, #FFC000 -13.24%, #F28705 100%)",
                    borderRadius: isMobile && !isDesktop ? "50px" : "11px",
                    fontFamily: theme.fontFamily.Roboto,
                    fontStyle: "normal",
                    fontWeight: 500,
                    fontSize: "20px",
                    lineHeight: "23px",
                    color: "#FFFFFF",
                  }}

                  onClick={hasError}
                >
                  {isMobile
                    ? intl.formatMessage({ id: "schedule" })
                    : intl.formatMessage({ id: "next" })}
                </Button>
                <Grid item xs={12}>
                  <p className={classes.errorMessage}>
                    {errorMessage}
                  </p>
                </Grid>

              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default DescriptionFormContainer;

const useStyles = makeStyles((theme) => ({
  root: {
    "&:hover": {
      backgroundColor: "transparent",
    },
    "& body": {
      fontFamily: theme.fontFamily.Roboto,
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "18px",
      lineHeight: "21px",
      color: "#3C4A6B",
    },
  },
  icon: {
    borderRadius: "50%",
    width: 30,
    height: 30,
    boxShadow: "inset 0 0 0 1px #E5E5E5, inset 0 -1px 0 #E5E5E5",
    backgroundColor: "#E5E5E5",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "$root.Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: "#ebf1f5",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)",
    },
  },
  errorMessage: {
    color: "#f44336",
    margin: 0,
    fontSize: "0.75rem",
    marginTop: " 3px",
    textAlign: "left",
    fontFamily: "Reef",
    fontWeight: 400,
    lineHeight: 1.66,
    textAlign: 'center'
  },
  checkedIcon: {
    backgroundColor: "#E5E5E5",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 30,
      height: 30,
      backgroundImage: "radial-gradient(#F79E03,#F79E03 28%,transparent 32%)",
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: "#E5E5E5",
    },
  },
  radioTxt: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "21px",
    color: "#3C4A6B",
    marginTop: "12px",
  },
  homeSearchField: {
    "& .MuiFormHelperText-root.Mui-error": {
      color: "#f44336",
      marginTop: "-2px",
      textAlign: "center",
    },
    "& .MuiInputBase-root": {
      fontFamily: theme.fontFamily.Roboto,
      fontStyle: "normal",
      width: "100%",
      borderRadius: "10px",
      fontWeight: 500,
      fontSize: "18px",
      lineHeight: "21px",
      color: "#3C4A6B",
    },
    "& .MuiBadge-colorPrimary": {},

    "& .MuiInput-underline:before": {
      border: "0px solid",
    },
    "& .MuiInput-underline::after": {
      border: "0px solid",
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled)::before": {
      border: "0px solid",
    },
    "& .MuiInputBase-input ": {
      padding: "0px",
    },
  },
  mobilePadding: {
    marginLeft: "20px",
    marginRight: "20px",
  },
  mobilePaddingBtn: {
    marginLeft: "10px",
    marginRight: "10px",
  },
  meetingTextHeader: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "18px",
    lineHeight: "21px",
    color: "#9E9393",
  },
  meetingText: {
    width: "100%",
    height: "2.750em",
    backgroundColor: "#C4C4C4",
    borderRadius: "5px",
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "21px",
    marginLeft: "0px!important",

    color: "#3C4A6B",
  },
  courseDescriptionImageCard: {
    height: "15.438em",
    backgroundColor: "#FEEFCC",
    borderRadius: "20px",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "top center",
  },
  cardButton: {
    width: "18em",
    height: "2.488em",
    borderRadius: "20px",
    backgroundColor: "#FBAE02",
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: 300,
    fontSize: "10px",
    lineHeight: "10px",
    color: "#FFFFFF",
    marginTop: "20px",
    margin: "auto",
  },
  cardText: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "16px",
    color: "#363636",
  },
  profile_image: {
    padding: "10px",
    cursor: "pointer",
  },

  goalTextArea: {
    "& .MuiInputBase-multiline": {
      border: `1px solid #FAAC02`,
      padding: `10px 10px`,
      borderRadius: "10px",
      fontFamily: theme.fontFamily.Roboto,
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "16px",
      letterSpacing: "0.015em",
      lineHeight: "23px",
      color: "#8B8B8B",
    },
    "& .MuiInputLabel-root": {
      fontFamily: theme.fontFamily.Roboto,
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "14px",
      letterSpacing: "0.015em",
      lineHeight: "16px",
      color: "#505050",
      opacity: "0.78",
      "&::after": {
        fontFamily: theme.fontFamily.Roboto,
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "14px",
        lineHeight: "16px",
        letterSpacing: "0.015em",
        color: "#505050",
        opacity: "0.78",
      },
    },

    "& .MuiInput-underline:before": {
      borderBottom: "0px solid #FAAC02",
      borderTop: "none",
    },
    "& .MuiInput-underline::after": {
      borderBottom: "0px solid #FAAC02",
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled)::before": {
      borderBottom: "0px solid #FAAC02",
    },
  },
  nameTextField: {
    "& .MuiInput-root": {
      fontFamily: theme.fontFamily.Roboto,
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "16px",
      letterSpacing: "0.015em",
      lineHeight: "23px",
      color: "#8B8B8B",
    },
    "& .MuiInputBase-multiline": {
      padding: `10px 10px`,
    },
    "& .MuiInputLabel-root": {
      fontFamily: theme.fontFamily.Roboto,
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: "16px",
      color: "#505050",
      opacity: "0.78",
      "&::after": {
        fontFamily: theme.fontFamily.Roboto,
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "14px",
        lineHeight: "16px",
        color: "#505050",
        opacity: "0.78",
      },
    },

    "& .MuiInput-underline:before": {
      borderBottom: "1px solid rgba(168, 182, 200, 0.3)",
      borderTop: "none",
    },
    "& .MuiInput-underline::after": {
      borderBottom: "1px solid rgba(168, 182, 200, 0.3)",
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled)::before": {
      borderBottom: "1px solid rgba(168, 182, 200, 0.3)",
    },
  },
}));
