import React, { useEffect } from "react";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import AuthMobileContainer from "../../containers/Mobile/OtpAuthMobileContainer";
import AuthWebContainer from "../../containers/Web/OtpAuth/index";
import {getSiteConfigs} from "../../actions/siteSetting";
import {useDispatch,useSelector} from 'react-redux'

const OtpAuth = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  const userData = useSelector(state=> state?.auth);

  useEffect(()=>{
    dispatch(getSiteConfigs({ token: userData?.token }));

  },[])

  return (
    <>
      {isDesktop ? (
        <AuthWebContainer />
      ) : (
        isDesktop !== undefined && <AuthMobileContainer />
      )}
    </>
  );
};
export default OtpAuth;
