import PrivateRoute from "../containers/Routing/PrivateRoute";
import PublicRoute from "../containers/Routing/PublicRoute";
import { Redirect } from "react-router-dom";
import Home from "../pages/Home";
import EmailVerification from "../pages/Common/EmailVerification";
import LetStart from "../pages/LetsStart";
import Profile from "../pages/Profile";
import AddCourse from "../pages/AddCourse";
import AddRetreat from "../pages/AddRetreat";
import Guide from "../pages/Guide";
import ChooseIdentity from "../pages/Common/ChooseIdentity";
import Auth from "../pages/Auth";
import OtpAuth from "../pages/OtpAuth";
import EditProfileInfo from "../pages/EditProfileInfo";
import More from "../pages/More";
import RelateWithFriend from "../pages/More/RelateWithFriends";
import Search from "../pages/Search";
import ViewAllCategoryCourses from "../pages/ViewAll/ViewAllCategoryCourses";
import Connects from "../pages/More/Connects";
import ForgotPassword from "../pages/Common/ForgotPassword";
import ResetPassword from "../pages/Common/ResetPassword";
import ViewAllSubCategoryCourses from "../pages/ViewAll/ViewAllSubCategoryCourses";
import ViewAllCourses from "../pages/Common/ViewAllCourses";
import ViewAllRetreats from "../pages/Common/ViewAllRetreats";
import ViewAllTeacherTrainings from "../pages/Common/ViewAllTeacherTrainings";
import ViewAllChallenges from "../pages/Common/ViewAllChallenges";
import ViewAllSessions from "../pages/Common/ViewAllSessions";
import ViewAllTeachers from "../pages/Common/ViewAllTeachers";
import NotFound from "../pages/NotFound";
import CategoryCourses from "../components/Home/categoryCourses";

import UserEnrolledViewAll from "../pages/ViewAll/UserEnrolledViewAll";

import ViewCourse from "../pages/ViewCourse";
import Notification from "../pages/Notification";
import PlayCourse from "../pages/PlayCourse";
import ViewProfile from "../pages/ViewProfile";
import SoloExercise from "../pages/SoloExercise";
import Session from "../pages/More/CreateSession";
import ChallengesStats from "../pages/More/ChallengesStats";
import AllChallenges from "../pages/More/AllChallenges";
// import EditChallenge from "../pages/More/EditChallenge";
import GoLive from "../containers/Mobile/Session/GoLive";
import Inbox from "../pages/More/Inbox";
import ChatMessages from "../pages/More/ChatMessages";
import PaymentMethods from "../pages/More/PaymentMethods";
import SubscriptionPlan from "../pages/More/SubscriptionPlan";
import Subscription from "../pages/More/Subscription";
// import Downloads from "../pages/More/Downloads")
import Settings from "../pages/More/Settings";
import StartChallenge from "../pages/Common/StartChallenge/index";

import ViewChallengeDetail from "../pages/ViewChallengeDetail";
import CreateChallenge from "../pages/More/CreateChallenge";
import ChallengeGroupChat from "../pages/Mobile/ChallengeGroupChat";

import SessionDetails from "../pages/More/Session/SessionDetails";

import SessionInvite from "../pages/More/Session/SessionInvite";
import InviteChallenge from "../pages/More/InviteChallenge/index";

import AllSessions from "../pages/More/Session/AllSessions"
import PlaySession from "../pages/PlaySession";
import PlayRetreat from "../pages/PlayRetreat";
import FavoriteCourses from "../pages/Common/FavoriteCourses";

const routes = [
  <PublicRoute path="/" exact component={LetStart} />,
  <PublicRoute path="/choose-identity" exact component={ChooseIdentity} />,
  <PublicRoute path="/auth" exact component={Auth} />,
  <PublicRoute path="/otp-auth" exact component={OtpAuth}/>,
  <PublicRoute path="/forgot-password" exact component={ForgotPassword} />,
  <PublicRoute path="/reset-password/:token" exact component={ResetPassword} />,
  <PublicRoute
    path="/email-verification/:token"
    exact
    component={EmailVerification}
  />,

  <PrivateRoute
    path="/edit-profile-info"
    exact
    component={EditProfileInfo}
    showBottomNav={true}
  />,
  <PrivateRoute
    path="/dashboard"
    showBottomNav={true}
    exact
    component={Home}
  />,
/*   <PrivateRoute
  path="/view-category-courses/:category"
  showBottomNav={true}
  exact
  component={CategoryCourses}
/>, */
  <PrivateRoute path="/home" showBottomNav={true} exact component={Home} />,
  <PrivateRoute
    path="/favorite"
    showBottomNav={true}
    exact
    component={FavoriteCourses}
  />,

  <PrivateRoute
    path="/view-all-courses"
    showBottomNav={true}
    exact
    component={ViewAllCourses}
  />,
  <PrivateRoute
    path="/view-all-retreats"
    showBottomNav={true}
    exact
    component={ViewAllRetreats}
  />,
  <PrivateRoute
    path="/view-all-teacher-trainings"
    showBottomNav={true}
    exact
    component={ViewAllTeacherTrainings}
  />,
  <PrivateRoute
    path="/view-all-challenges"
    showBottomNav={true}
    exact
    component={ViewAllChallenges}
  />,
  <PrivateRoute
    path="/view-all-sessions"
    showBottomNav={true}
    exact
    component={ViewAllSessions}
  />,
  <PrivateRoute
    path="/view-all-teachers"
    showBottomNav={true}
    exact
    component={ViewAllTeachers}
  />,
  <PrivateRoute
    path="/play-session"
    showBottomNav={true}
    exact
    component={PlaySession}
    commonFileLayout={true}
  />,
  <PrivateRoute
    path="/retreat-details/:id"
    showBottomNav={true}
    exact
    component={PlayRetreat}
    commonFileLayout={true}
  />,
  <PrivateRoute
    path="/teacher-training-details/:id"
    showBottomNav={true}
    exact
    component={PlayRetreat}
    commonFileLayout={true}
  />,

  <PrivateRoute
    path="/view-course/:courseId"
    exact
    component={ViewCourse}
    showBottomNav={true}
  />,

  <PrivateRoute
    path="/more/all-sessions"
    exact
    component={AllSessions}
    showBottomNav={true}
  />,
  <PrivateRoute
    path="/more/all-challenges"
    exact
    component={AllChallenges}
    showBottomNav={true}
  />,
  <PrivateRoute
    path="/more/relate-with-friends"
    exact
    component={RelateWithFriend}
    showBottomNav={true}
  />,
  <PrivateRoute
    path="/more/create-challenge"
    exact
    component={CreateChallenge}
    showBottomNav={true}
  />,
  <PrivateRoute
    path="/more/edit-challenge/:id"
    exact
    component={CreateChallenge}
    showBottomNav={true}
  />,
  <PrivateRoute
    path="/more/session/create"
    showBottomNav={true}
    exact
    component={Session}
  />,
  <PrivateRoute
    path="/more/inbox"
    exact
    component={Inbox}
    showBottomNav={true}
  />,
  <PrivateRoute
    path="/more/connections/:tab?"
    exact
    component={Connects}
    showBottomNav={true}
  />,
  <PrivateRoute
    path="/more/challenges-stats"
    exact
    component={ChallengesStats}
    showBottomNav={true}
  />,
  <PrivateRoute
    path="/more/payment-methods"
    exact
    component={PaymentMethods}
    showBottomNav={true}
  />,
  <PrivateRoute
    path="/more/subscription-plan"
    exact
    component={SubscriptionPlan}
    showBottomNav={true}
  />,
  <PrivateRoute
    path="/more/subscription"
    exact
    component={Subscription}
    showBottomNav={true}
  />,
  <PrivateRoute
    path="/more/settings"
    exact
    component={Settings}
    showBottomNav={true}
  />,
  <PrivateRoute
    path="/more/inbox/messages/:roomId"
    exact
    component={ChatMessages}
    showBottomNav={false}
  />,
  <PrivateRoute
    exact
    path="/more/session/details/:id"
    showBottomNav={true}
    component={SessionDetails}
  />,
  <PrivateRoute
    exact
    path="/more/session/edit/:id"
    showBottomNav={true}
    component={Session}
  />,
  <PrivateRoute
    exact
    path="/more/session/invite/:id"
    showBottomNav={true}
    component={SessionInvite}
  />,
  <PrivateRoute
    exact
    path="/more/challenge/invite/:id"
    showBottomNav={true}
    component={InviteChallenge}
  />,
  <PrivateRoute path="/more" showBottomNav={true} component={More} />,
  <PrivateRoute
    path="/explore/:tab?/:subTab?"
    showBottomNav={true}
    exact
    component={Search}
  />,

  <PrivateRoute
    path="/go-live"
    showBottomNav={true}
    exact
    component={GoLive}
  />,
  <PrivateRoute
    path="/start-challenge/:id"
    showBottomNav={true}
    commonFileLayout={true}
    exact
    component={StartChallenge}
  />,
  <PrivateRoute
    path="/solo-exercise"
    showBottomNav={true}
    exact
    component={SoloExercise}
  />,

  <PrivateRoute
    path="/challenge/details/:id"
    showBottomNav={true}
    exact
    component={ViewChallengeDetail}
  />,
  <PrivateRoute
    path="/groupChat/:id"
    showBottomNav={false}
    exact
    component={ChallengeGroupChat}
  />,

  <PrivateRoute
    path="/view-profile/:userId"
    exact
    component={ViewProfile}
    showBottomNav={true}
  />,

  <PrivateRoute
    path="/play-course/:courseId/:episodeId"
    exact
    showBottomNav={true}
    commonFileLayout={true}
    component={PlayCourse}
  />,
  <PrivateRoute
    notAccessible={["Regular"]}
    path="/add-course"
    showBottomNav={true}
    appBarConfig={{
      show: false,
      text: "New Course",
      cross: true,
      action: "/profile"
    }}
    exact
    component={AddCourse}
  />,
  <PrivateRoute
    notAccessible={["Regular"]}
    path="/add-retreat"
    showBottomNav={true}
    appBarConfig={{
      show: false,
      text: "New Training course",
      cross: true,
      action: "/profile"
    }}
    exact
    component={AddRetreat}
  />,
  <PrivateRoute
    notAccessible={["Regular"]}
    path="/edit-retreat/:id"
    showBottomNav={true}
    appBarConfig={{
      show: false,
      text: "Edit Training course",
      cross: true,
      action: "/profile"
    }}
    exact
    component={AddRetreat}
  />,
  <PrivateRoute
    notAccessible={["Regular"]}
    path="/add-teacher-training"
    showBottomNav={true}
    appBarConfig={{
      show: false,
      text: "New Teacher Training",
      cross: true,
      action: "/profile"
    }}
    exact
    component={AddRetreat}
  />,
  <PrivateRoute
    notAccessible={["Regular"]}
    path="/edit-teacher-training/:id"
    showBottomNav={true}
    appBarConfig={{
      show: false,
      text: "Edit Teacher Training",
      cross: true,
      action: "/profile"
    }}
    exact
    component={AddRetreat}
  />,
  <PrivateRoute
    notAccessible={["Regular"]}
    path="/edit-course/:id"
    showBottomNav={true}
    appBarConfig={{
      show: false,
      text: "Edit Course",
      cross: true,
      action: "/profile"
    }}
    exact
    component={AddCourse}
  />,

  <PrivateRoute
    path="/sub-category/:subCategory"
    showBottomNav={true}
    appBarConfig={{
      show: false,
      text: "",
      cross: false,
      action: "/explore"
    }}
    exact
    component={ViewAllSubCategoryCourses}
  />,
  <PrivateRoute
    path="/courses/user-enrolled"
    showBottomNav={true}
    appBarConfig={{
      show: true,
      text: "",
      cross: false,
      action: "/explore"
    }}
    exact
    component={UserEnrolledViewAll}
  />,
  <PrivateRoute
    path="/category/:category"
    showBottomNav={true}
    appBarConfig={{
      show: false,
      text: "",
      cross: false,
      action: "/explore"
    }}
    exact
    component={ViewAllCategoryCourses}
  />,
  <PrivateRoute
    path="/notification"
    showBottomNav={true}

    exact
    component={Notification}
  />,

  <PrivateRoute
    path="/profile"
    showBottomNav={true}
    exact
    component={Profile}
  />,
  <PublicRoute path="/guide" exact component={Guide} />,
  <PublicRoute path="/404" exact component={NotFound} />,
  <PublicRoute path="*" component={() => <Redirect to="/404" />} />
];

export default routes;
