import { useState, useEffect } from "react";
import {
  Tab,
  Tabs,
  Grid,
  Typography,
  useMediaQuery,
  Box,
  CircularProgress,
} from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import { makeStyles, withStyles, useTheme } from "@material-ui/core/styles";
import { useIntl } from "react-intl";
import { useHistory, useLocation } from "react-router-dom";
import SubscriptionCards from "./subscriptionCards";
import { GET_ALL_USER_JOINED_UNDER_SUBSCRIPTION } from "../../../actions/types";
import {
  getAllSubscriptions,
  getUserJoinedUnderSubscription,
} from "../../../actions/SubscriptionPlan";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

const StyledTabs = withStyles({
  indicator: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "#09A9BB",
    "& > span": {
      maxWidth: 50,
      width: "100%",
      backgroundColor: "#09A9BB",
    },
  },
  flexContainer: {
    justifyContent: "center",
    alignItems: "center",
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

const StyledTab = withStyles((theme) => ({
  root: {
    minWidth: "60px !important",
    textTransform: "none",
    color: "#080808e3",
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),

    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "14px",
    color: " #FFF",
    "&:focus": {
      opacity: 1,
    },
  },
  selected: {
    color: "#09A9BB",
  },
}))((props) => <Tab disableRipple {...props} />);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box pt={2}>
          <>{children}</>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function SubscriptionPlans() {
  const classes = useStyles();
  const theme = useTheme();
  const location = useLocation();
  const dispatch = useDispatch();
  const intl = useIntl();
  const history = useHistory();
  const [value, setValue] = useState(0);
  const [plans, setPlans] = useState([]);
  const [loading, setLoading] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const auth = useSelector((state) => state?.auth);
  const user = useSelector((state) => state?.user?.user);
  let purchasedSubscriptionInfo =
    user?.purchasedSubscription && user?.purchasedSubscription[0];

  const [state, setState] = useState({
    isActiveSessionDiscountTitle: true,
    isActiveCourseDiscountTitle: true,
    isActiveOmidCoursesTitle: true,
    isActiveOmidSessionsTitle: true,
    isActiveRetreatDiscountTitle: true,
    isActiveTeacherTrainingDiscountTitle: true,
  });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getData = async () => {
    setLoading(true);
    await dispatch(getUserJoinedUnderSubscription({ token: auth?.token }));
    let res = await dispatch(getAllSubscriptions({ token: auth?.token }));

    setState({
      isActiveSessionDiscountTitle: res?.isActiveSessionDiscountTitle,
      isActiveCourseDiscountTitle: res?.isActiveCourseDiscountTitle,
      isActiveOmidCoursesTitle: res?.isActiveOmidCoursesTitle,
      isActiveOmidSessionsTitle: res?.isActiveOmidSessionsTitle,
      isActiveRetreatDiscountTitle: res?.isActiveRetreatDiscountTitle,
      isActiveTeacherTrainingDiscountTitle:
        res?.isActiveTeacherTrainingDiscountTitle,
    });

    if (res) {
      setPlans(res);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
    return () => {
      dispatch({
        type: GET_ALL_USER_JOINED_UNDER_SUBSCRIPTION,
        userUnderSubscriptionJoined: {},
      });
    };
  }, []);

  useEffect(() => {
    setValue(
      location?.state?.type === "subscription" &&
        location?.state?.tab === "monthly"
        ? 0
        : location?.state?.type === "subscription" &&
          location?.state?.tab === "yearly"
        ? 1
        : purchasedSubscriptionInfo?.interval === "yearly"
        ? 1
        : 0
    );
  }, [user]);

  return (
    <Grid
      container
      className={classes.container}
      style={{ padding: isMobile && "20px 10px 70px 10px" }}
    >
      <Grid container item xs={12} spacing={1}>
        <Grid item>
          <ArrowBack
            className={classes.backArrow}
            onClick={() => history.goBack()}
          />
        </Grid>
        <Grid item>
          <Typography className={classes.heading}>
            Subscription Plans
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <StyledTabs
          value={value}
          onChange={handleChange}
          aria-label="styled tabs example"
          variant="scrollable"
          scrollButtons="auto"
        >
          <StyledTab label={intl.formatMessage({ id: "monthlyBilling" })} />
          <StyledTab label={intl.formatMessage({ id: "annualBilling" })} />
        </StyledTabs>
      </Grid>
      <Grid item xs={12}>
        <TabPanel value={value} index={0}>
          {loading ? (
            <div className={classes.loader}>
              <CircularProgress style={{ color: "#0EB2EF" }} />
            </div>
          ) : plans?.length > 0 ? (
            <SubscriptionCards annual={false} plans={plans} />
          ) : (
            <div className={classes.loader}>No Data</div>
          )}
        </TabPanel>

        <TabPanel value={value} index={1}>
          {loading ? (
            <div className={classes.loader}>
              <CircularProgress style={{ color: "#0EB2EF" }} />
            </div>
          ) : plans?.length > 0 ? (
            <SubscriptionCards annual={true} plans={plans} />
          ) : (
            <div className={classes.loader}>No Data</div>
          )}
        </TabPanel>
      </Grid>
    </Grid>
  );
}

export default SubscriptionPlans;

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 25,
  },
  heading: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "20px",
    lineHeight: "115%",
    color: "#000",
  },
  backArrow: {
    cursor: "pointer",
  },
  loader: {
    height: "80vh",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));
