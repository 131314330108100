import React, { useEffect } from "react";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ProfileMobileContainer from "../../containers/Mobile/Profile";
import ProfileWebContainer from "../../containers/Web/Profile";
import { getAllCategories } from "../../actions/courses";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentLoggedInUser, getUsersFriends } from "../../actions/user";
function ProfilePage(props) {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  let dispatch = useDispatch();

  let authReducerState = useSelector((state) => state.auth);
  useEffect(() => {
    dispatch(getAllCategories());
    dispatch(getCurrentLoggedInUser({ token: authReducerState?.token }));
    dispatch(getUsersFriends(authReducerState?.token));
  }, []);
  return (
    <>
      {isDesktop && <ProfileWebContainer />}
      {isMobile && !isDesktop && <ProfileMobileContainer />}
    </>
  );
}

export default ProfilePage;
