import React, { useEffect, useState } from "react";
import { useTheme } from "@material-ui/core/styles";
import {
  useMediaQuery,
  Grid,
  Stepper,
  Step,
  StepLabel,
  DialogTitle,
  Typography,
  Modal,
  CircularProgress,
  Button,
  DialogContentText,
  DialogContent,
  Dialog, DialogActions
} from "@material-ui/core";
import AppBar1 from "../../../components/AppBar";
import PropTypes from "prop-types";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Check from "@material-ui/icons/Check";

import StepConnector from "@material-ui/core/StepConnector";

import Radio from "@material-ui/core/Radio";

import Details from "./Details";
import Schedule from "./Schedule";
import Invite from "../../../components/More/Common/InviteFriendsWeb";
import { getUsersFriends } from "../../../actions/user";
import {
  createSession,
  addSessionLocal,
  updateSession,
  getSessionDetails,
} from "../../../actions/Session";

import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import AppBarTop from "../../../components/Common/Session/AppBar";
import GoLive from "../../Mobile/Session/GoLive";
import InviteMobile from "../../../components/More/Common/InviteFrinedMobile";
import { AppBar, Toolbar, IconButton } from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import { useHistory, useParams } from "react-router-dom";
import moment from "moment";
import { CLEAN_SESSION } from "../../../actions/types";

const Session = (props) => {
  const history = useHistory();
  const params = useParams();
  const intl = useIntl();
  const theme = useTheme();
  const classes = useQontoStepIconStyles();
  const classes1 = useStyle();
  const classesModal = useModalStyles();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  let selectedSession = useSelector((state) => state.session.selectedSession);
  let isLoaded = useSelector((state) => state.session.showLoader);
  const [showLoader, setShowLoader] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);
  const [instructionModel, setInstructionModel] = useState(false);
  const [activeStep, setActiveStep] = React.useState(0);
  const [goLive, setGoLive] = React.useState(false);
  const [value, setValue] = React.useState("schedule");
  const [editSession] = useState(params?.id);

  const [sessionData, setsessionData] = useState({});

  const [modalStyle] = useState(getModalStyle);
  function getModalStyle() {
    const top = 50;
    const left = 50;
    return {
      borderColor: theme.colors.white,
      borderRadius: "4px",
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
    };
  }
  const [openModel, setOpenModel] = useState(false);
  const handleCloseModal = () => {
    setOpenModel(false);
  };

  const openSearch = (value) => {
    setSearchOpen(value);
  };
  const [curerntTab, setCurrentTab] = useState(0);
  const handleChangeTab = (newValue) => {
    setCurrentTab(newValue);
  };
  function getSteprs() {
    if (selectedSession && selectedSession.type === "Live") {
      const arr = [
        intl.formatMessage({ id: "details" }),
        intl.formatMessage({ id: "invitePeople" }),
      ];
      setActiveStep(0);
      return arr;
    } else {
      const arr = [
        intl.formatMessage({ id: "details" }),
        intl.formatMessage({ id: "schedule" }),
      ];
      setActiveStep(0);
      return arr;
    }
  }
  useEffect(() => {
    return () => {
      dispatch({ type: CLEAN_SESSION });
    };
  }, []);



  const getState1 = () => {
    setValue(
      selectedSession && selectedSession.type
        ? selectedSession.type.toLowerCase()
        : "schedule"
    );
    const sessionDetails = {
      name: selectedSession && selectedSession.name ? selectedSession.name : "",
      goal: selectedSession && selectedSession.goal ? selectedSession.goal : "",
      meetingId:
        selectedSession && selectedSession.meetingId
          ? selectedSession.meetingId
          : "",
      price: selectedSession && selectedSession.price ? selectedSession.price : null,
      plan: selectedSession && selectedSession.plan,
    };
    let days = [];
    selectedSession &&
      selectedSession.sessionDates &&
      selectedSession.sessionDates.map((n) => {
        days.push(new Date(n.date));
      });

    let arr1 = [];

    selectedSession &&
      selectedSession.sessionParticipants &&
      selectedSession.sessionParticipants.map((n) => {
        arr1.push(n.id);
      });
    selectedSession &&
      selectedSession.invitations &&
      selectedSession.invitations.map((n) => {
        arr1.push(n.invitationReceiverId);
      });
    const schedule = {
      duration:
        selectedSession && selectedSession.duration
          ? selectedSession.duration
          : "",
      start:
        selectedSession && selectedSession.startTime
          ? selectedSession.startTime
          : "",
      end:
        selectedSession && selectedSession.endTime
          ? selectedSession.endTime
          : "",
      dates: [...days],
      timeZone: selectedSession && selectedSession.timeZone
        ? selectedSession.timeZone
        : ""
    };

    const imageUrl =
      selectedSession && selectedSession.imageUrl
        ? selectedSession.imageUrl
        : "";

    return {
      sessionDetails: sessionDetails,
      sessionUsers: [...arr1],
      userIds: [],
      schedule: schedule,
      imageUrl: imageUrl,
    };
  };


  const handleGoLive = () => {
    setGoLive(true);
    handleCloseModal();
  };
  const msgModalBody = (
    <Grid
      container
      direction="row"
      spacing={3}
      style={modalStyle}
      className={classesModal.msgPaper}
    >
      <Grid item className={classesModal.modelBody}>
        <Typography className={classesModal.modelBodyText}>
          You will receive a confirmation once your friends confirm their
          availability
        </Typography>
      </Grid>
      <Grid
        item
        className={classesModal.msgmodalBtnGrid}
        style={{
          width: "100%",
          padding: "0px",
          alignSelf: "flex-end",
          color: "#fff",
          cursor: "pointer",
        }}
      >
        <Button
          className={classesModal.modelBtn}
          onClick={() => {
            handleGoLive();
          }}
          style={{ width: "100%", backgroundColor: "#FCB201" }}
        >
          {intl.formatMessage({ id: "Okay" })}
        </Button>
      </Grid>
    </Grid>
  );
  const [steps, setSteps] = useState(getSteprs);
  const [redUrl, setRedUrl] = useState({});

  let dispatch = useDispatch();
  const authReducerState = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(getUsersFriends(authReducerState?.token));
    if (params?.id) {
      dispatch(
        getSessionDetails({
          userid: authReducerState?.user?.id,
          token: authReducerState?.token,
          id: params?.id,
        })
      );
    }
  }, []);

  const handleNext = async (sessionData1) => {
    setsessionData({ ...sessionData, ...sessionData1 });
    if (activeStep < steps.length - 1) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else {
      let days = [];
      for (let i = 0; i < sessionData1?.schedule?.dates?.length; i++) {
        let n = sessionData1?.schedule?.dates[i];
        let s = moment(n).format("YYYY-MM-DD");
        days.push(s);
      }
      const session = {
        sessionName: sessionData.sessionDetails.name,
        goal: sessionData.sessionDetails.goal,
        startTime: moment(sessionData1.schedule.start).format('HH:mm'),
        endTime: moment(sessionData1.schedule.end).format('HH:mm'),
        plan: sessionData?.sessionDetails?.plan,
        price: sessionData?.sessionDetails?.price,
        timeZone: sessionData1.schedule.timeZone,
        type: "Schedule",

        dates: [...days],
      };
      setShowLoader(true);
      const res = await dispatch(
        editSession === undefined || editSession === false
          ? createSession({
            token: authReducerState?.token,
            image: sessionData.sessionImage,
            session: session,
          })
          : updateSession({
            id: selectedSession && selectedSession.id,
            token: authReducerState?.token,
            image:
              sessionData && sessionData.sessionImage
                ? sessionData.sessionImage
                : "",
            session: session,
          })
      );

      dispatch(addSessionLocal({ payload: session }));
      if (res && res === 'success') {
        setInstructionModel(true)
      }
    }

  };

  const handleBack = () => {
    if (activeStep > 0) {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };
  const goPreviousStep = (e, ind) => {
    e.preventDefault();
    if (ind < activeStep) {
      setActiveStep(ind);
    }
  };

  const handleChange = (event) => {
    if (event.target.value === "live") {
      const arr = [
        intl.formatMessage({ id: "details" }),
        intl.formatMessage({ id: "invitePeople" }),
      ];
      setSteps(arr);
    } else {
      const arr = [
        intl.formatMessage({ id: "details" }),
        intl.formatMessage({ id: "invitePeople" }),
        intl.formatMessage({ id: "schedule" }),
      ];
      setSteps(arr);
    }
    setActiveStep(0);
    setsessionData({});
    setValue(event.target.value);
  };

  const [searchText, setSearchTerm] = useState("");

  const searchFieldChangeHandler = (e) => {
    setSearchTerm(e.target.value);
  };
  const handleSearch = () => { };
  let isSessionAdded = useSelector((state) => state.message);

  useEffect(() => {
    if (authReducerState && authReducerState?.user && authReducerState?.user?.role === 'Regular') {
      history.push('/more')
    }
    else if (
      params?.id && selectedSession && selectedSession?.user && selectedSession?.user?.id !== authReducerState?.user?.id
    ) {
      history.push('/more')
      dispatch({ type: CLEAN_SESSION });
    } else {
      setsessionData(getState1());
    }
  }, [selectedSession]);
  const currentSession = useSelector((state) => state?.session?.currentSession);
  const sessionReducerState = useSelector((state) => state?.session);



  useEffect(() => {
    setShowLoader(false);
  }, [isSessionAdded.show]);
  return (
    <>
      <Dialog
        open={instructionModel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Important note!"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Dear omid Instructor,
            Your session has been {params?.id ? 'updated' : 'created'} successfully and goes under review state. Our team  will review and inform you soon about session approval/rejection.
          </DialogContentText>
        </DialogContent>
        <DialogActions>

          <Button onClick={() => history.push(`/more/relate-with-friends`)} color="primary" autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      <Grid container style={{ backgroundColor: "#fff", paddingBottom: isMobile && '100px' }}>
        {!goLive && isMobile && !isDesktop && (
          <AppBarTop
            appBarConfig={{
              backgroundColor: "#FBFAF9",
              show: activeStep === 0 ? false : true,
              text: intl.formatMessage({ id: "schedule" }),

              close: true,
              action: "/more",
              backAction: handleBack,
            }}
            searchOpen={searchOpen}
            setSearchOpen={openSearch}
            searchText={searchText}
            handleSearch={handleSearch}
            searchFieldChangeHandler={searchFieldChangeHandler}
          />
        )}
        {!goLive && isMobile && !isDesktop && activeStep === 0 && (
          <AppBar1
            appBarConfig={{
              backgroundColor:
                value === "live" ? "rgb(251, 250, 249) !important" : "#FFFFFF",
              show: true,
              text:
                activeStep === 0
                  ? intl.formatMessage({ id: "newSession" }) : intl.formatMessage({ id: "schedule" }),

              close: activeStep === 0 ? true : false,
              action: "/more",
              backAction: activeStep === 0 ? false : handleBack,
            }}
          />
        )}

        <Grid
          style={{ paddingLeft: isMobile && !isDesktop ? "0px" : "3rem" }}
          xs={12}
          md={11}
          lg={11}
        >
          {!goLive && isDesktop && (
            <Grid container>
              <Grid item xs={5} style={{ marginLeft: "-25px" }}>
                <AppBar
                  className={classes1.appBar}
                  style={{ backgroundColor: "#fff" }}
                >
                  <IconButton
                    onClick={() => history.push("/more")}
                    className={classes1.backButton}
                  >
                    <ArrowBack />
                  </IconButton>
                  <Toolbar>
                    <Typography className={classes1.appBarText} variant="h6">
                      {intl.formatMessage({ id: "back" })}
                    </Typography>
                  </Toolbar>
                </AppBar>
              </Grid>
              <Grid item xs={1} />
              <Grid item xs={6}>

              </Grid>
            </Grid>
          )}
        </Grid>


        {!isLoaded ? (
          <Grid
            item
            container
            style={{ paddingLeft: isMobile && !isDesktop ? "0px" : "1rem" }}
            xs={12}
            md={12}
            lg={12}>


            <Grid
              item
              xs={12}
              style={{
                marginLeft: value === "live" ? "-70px" : "-80px",
                paddingLeft: isMobile && !isDesktop ? "0px" : "2rem",
                backgroundColor: "#fff",
              }}
              lg={value === "live" ? 5 : 8}
              xl={value === "live" ? 2 : 4}
            >
              {isDesktop && (
                <Stepper
                  style={{ backgroundColor: "#fff" }}
                  alternativeLabel
                  activeStep={activeStep}
                  connector={<QontoConnector />}
                >
                  {steps.map((label, index) => (
                    <Step key={label}>
                      <StepLabel StepIconComponent={QontoStepIcon}>
                        <Typography
                          className={
                            activeStep === index
                              ? classes.activeTypography
                              : activeStep > index
                                ? classes.completedTypography
                                : classes.inProgressTypography
                          }
                          onClick={(e) => goPreviousStep(e, index)}
                        >
                          {" "}
                          {label}
                        </Typography>
                      </StepLabel>
                    </Step>
                  ))}
                </Stepper>
              )}
            </Grid>

            <Grid item xs={12}>
              {activeStep === 0 && !goLive && (
                <Details
                  sessionData={sessionData && sessionData}
                  screen={value}
                  handleNext={handleNext}
                />
              )}
            </Grid>

            <Grid item xs={12}>
              {activeStep === 1 && !goLive && (
                <Schedule
                  editSession={
                    props &&
                      props.location &&
                      props.location.pathname === "/session"
                      ? props.location && props.location.editSession
                      : props && props.editSession
                  }
                  curerntTab={curerntTab}
                  handleChangeTab={handleChangeTab}
                  sessionData={sessionData}
                  handleNext={handleNext}
                  handleBack={handleBack}
                  showLoader={showLoader}
                />
              )}
            </Grid>



            <Grid item xs={12}>
              {goLive && <GoLive />}
            </Grid>

          </Grid>
        ) : (

          <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: "80vh" }}>
            <CircularProgress size={30} style={{ color: "#68C5E3" }} />

          </Grid>
        )}
        <Grid container>
          <Modal
            disableEnforceFocus={true}
            open={openModel}
            onClose={handleCloseModal}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            {msgModalBody}
          </Modal>
        </Grid>
      </Grid>
    </>
  );
};
export default Session;

const QontoConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  active: {
    "& $line": {
      borderColor: "#C4C4C4",
    },
    "& $alternativeLabel": {
      color: "#FBAE02",
    },
  },
  completed: {
    "& $line": {
      borderColor: "#C4C4C4",
    },
  },
  line: {
    borderColor: "#eaeaf0",
    borderTopWidth: 3,
    borderRadius: 1,
  },
})(StepConnector);

const useQontoStepIconStyles = makeStyles({
  root: {
    color: "#eaeaf0",
    display: "flex",
    height: 22,
    alignItems: "center",
  },
  active: {
    color: "#FBAE02",
    "& > div": {
      width: 28,
      height: 28,
      backgroundColor: "#FBAE02",
    },
  },
  circle: {
    width: 14,
    height: 14,
    border: `4px solid currentColor`,
    boxSizing: "border-box",
    borderRadius: "50%",
  },
  completed: {
    backgroundColor: "#3C4A6B",
    color: "#fff",
    borderRadius: "50%",
    zIndex: 1,
    fontSize: 18,
  },
  activeTypography: {
    color: "#FBAE02",
    cursor: "pointer",
  },

  completedTypography: {
    color: "#3C4A6B",
    cursor: "pointer",
  },

  inProgressTypography: {
    color: "#C4C4C4",
    cursor: "pointer",
  },
});

function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? (
        <Check className={classes.completed} />
      ) : (
        <div className={classes.circle} />
      )}
    </div>
  );
}

QontoStepIcon.propTypes = {

  active: PropTypes.bool,

  completed: PropTypes.bool,
};
const useModalStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    height: 330,
    maxWidth: 315,
    minWidth: 300,
    justifyContent: "center",
    backgroundColor: theme.palette.background.paper,
    outline: "none",
  },
  modelBody: {
    display: "flex",
    justifyContent: "center",
    alignSelf: "center",
    alignItems: "center",
    height: "70%",
    textAlign: "center",
    padding: "14px !important",
  },
  modelBodyText: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "17px",
    lineHeight: "27px",
    textAlign: "center",
    letterSpacing: "0.02em",
    color: "#192247",
  },
  msgPaper: {
    position: "absolute",
    height: 250.6,
    maxWidth: 315,
    minWidth: 300,
    justifyContent: "center",
    backgroundColor: theme.palette.background.paper,
    outline: "none",
  },
  modalDesc: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "24px",
    lineHeight: "28px",
    color: "#3C4A6B",
    textAlign: "center",
    padding: theme.spacing(2, 4, 3),
  },
  modelBtn: {
    height: "73px",
    width: "50%",
    fontFamily: theme.fontFamily.Roboto,
    cursor: "pointer",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "21px",
    color: theme.colors.white,
  },
  msgmodalBtnGrid: {
    height: "73px",
    width: "100%",
    background: "#FCB201",
  },
  modalBtnGrid: {
    background: `linear-gradient(#09A9BB 1%,#09A9BB 100%)`,
    height: "73px",
    width: "100%",
  },
}));

const useStyles = makeStyles((theme) => ({
  root: {
    "&:hover": {
      backgroundColor: "transparent",
    },
    "& body": {
      fontFamily: theme.fontFamily.Roboto,
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "18px",
      lineHeight: "21px",
      color: "#3C4A6B",
    },
  },

  icon: {
    borderRadius: "50%",
    width: 30,
    height: 30,
    boxShadow: "inset 0 0 0 1px #E5E5E5, inset 0 -1px 0 #E5E5E5",
    backgroundColor: "#E5E5E5",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "$root.Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: "#ebf1f5",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)",
    },
  },
  checkedIcon: {
    backgroundColor: "#E5E5E5",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 30,
      height: 30,
      backgroundImage: "radial-gradient(#F79E03,#F79E03 28%,transparent 32%)",
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: "#E5E5E5",
    },
  },
}));

function StyledRadio(props) {
  const classes = useStyles();

  return (
    <Radio
      className={classes.root}
      classes={{ root: classes.root }}
      disableRipple
      color="default"
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      {...props}
    />
  );
}

const useStyle = makeStyles((theme) => ({
  homeSearchField: {
    "& .MuiInputBase-root": {
      backgroundColor: "#FBF1E0",
      color: "rgba(249,167,2,.5)",
      fontFamily: theme.fontFamily.Roboto,
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "18px",
      lineHeight: "21px",
      padding: "10px",
      minHeight: "64px",
      alignSelf: "center",
      marginTop: "12px",
      marginBottom: "20px",
      height: "58px",
      width: "100%",
      borderRadius: "10px",
    },
    "& .MuiBadge-colorPrimary": {
      backgroundColor: "#F9A702;",
    },

    "& .MuiInput-underline:before": {
      border: "0px solid #FBF1E0",
    },
    "& .MuiInput-underline::after": {
      border: "0px solid #FBF1E0",
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled)::before": {
      border: "0px solid #FBF1E0",
    },
  },
  appBar: {

    color: "#000",
    flexDirection: "row",
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    right: "unset",
    position: "relative",
    marginLeft: "25px",
    boxShadow: "none",
  },
  appBarMobile: {
    background: "#FFFFFF",
    color: "#000",
    flexDirection: "row",
    padding: theme.spacing(1.5),
    justifyContent: "space-between",
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    right: "unset",
    position: "sticky",
  },
  appBarText: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: "900",
    fontSize: "20px",
    lineHeight: "23px",
  },
  appBarTextMobile: {
    fontFamily: theme.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: "900",
    fontSize: "26px",
    lineHeight: "35px",
  },
  backButton: {
    background: "transparent",
    color: "#000",
    padding: "0px",
  },
}));
