import React from "react";
import { useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import SearchFeedWebContainer from "../../containers/Web/SearchFeed";

const Search = () => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <>
      <SearchFeedWebContainer />
    </>
  );
};

export default Search;
