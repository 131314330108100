import React from "react";
import PaymentMethod from "../../../Common/Payment";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";

function AddPaymentMethod(props) {
  const intl = useIntl();
  const history = useHistory();

  return (
    <>

      <PaymentMethod />
    </>
  );
}

export default AddPaymentMethod;
