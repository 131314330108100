import React from 'react'
import { useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import ViewAllCategoryWeb from "../../containers/Web/ViewAll/ViewAllCategory";
import ViewAllCategoryMobile from "../../containers/Mobile/ViewAll/ViewAllCategory";

function ViewAllCategoryCourses() {
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    return (
        <>
      {isDesktop && <ViewAllCategoryWeb />}
      {isMobile && !isDesktop && <ViewAllCategoryMobile />}
    </>
    )
}

export default ViewAllCategoryCourses;
