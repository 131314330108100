import React, { useState } from 'react';
import { Grid, Typography, Button, CircularProgress, useMediaQuery, useTheme } from '@material-ui/core'
import { CheckCircle, Cancel } from '@material-ui/icons'
import { makeStyles } from "@material-ui/core/styles";
import DownloadAssignmentCard from './DownloadAssignmentCard'
import clsx from 'clsx'
import moment from 'moment'
import { useSelector, useDispatch } from 'react-redux'
import { approveDisApproveAssignment } from '../../../../../actions/retreatCourse';

function AssignmentApproveCard(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const [loader, setLoader] = useState(false)
    const user = useSelector(state => state?.auth);
    const { ind, assignment } = props
    const submittedAssignment = props?.assignment?.studentSubmittedAssignments && props?.assignment?.studentSubmittedAssignments[0]



    const handleChangeStatus = async (status) => {
        setLoader(true);

        const res = await dispatch(approveDisApproveAssignment({ token: user?.token, studentSubmittedAssignmentId: submittedAssignment?.id, assignmentId: assignment?.id, status: status }));
        if (res) {
            setLoader(false);
        }
    }

    return (
        <Grid container>
            <Grid item xs={12} container style={{ marginBottom: '15px', borderBottom: isMobile && '2px solid #E2E2E2', paddingBottom: isMobile && "25px" }}>
                <Grid item xs={12} sm={12} md={3} lg={3} xl={3} container className={!isMobile && clsx(classes.assignmentTitleContainer, classes.verticalCenter)}>
                    <Grid item xs={6} sm={6} md={12}>
                        <Typography className={!isMobile ? classes.assignmentTitle : classes.assignmentTitleMobile}>Assignment # {ind + 1}</Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={12}>
                        <Typography className={!isMobile ? classes.assignmentTime : classes.assignmentTimeMobile}>{moment.utc(assignment?.dateTimeUtc).local().format("MMM DD HH:MM")}</Typography>
                    </Grid>
                </Grid>

                <Grid item container xs={12} sm={12} md={9} lg={9} xl={9} style={{ alignItems: 'center' }}>
                    <Grid item xs={10} sm={10} md={7} lg={7} xl={7} className={!isMobile && clsx(classes.verticalCenter, classes.horizontalCenter)} style={{ paddingLeft: !isMobile && '10px' }}>
                        <DownloadAssignmentCard file={submittedAssignment} style={{ width: "90%", borderRadius: '11px', boxShadow: isMobile && 'none' }} />
                    </Grid>

                    <Grid item xs={2} sm={2} md={5} lg={5} xl={5} className={classes.verticalCenter} container>
                        {loader ? <Grid item xs={8} className={classes.supportedFileTitle}><CircularProgress size={20} style={{ color: '#09a9bb' }} /></Grid> :
                            submittedAssignment?.status === 'Approved' ? <>
                                {!isMobile && <Grid item xs={8}>
                                    <Typography className={classes.supportedFileTitle} style={{ color: '#87AC26' }}> Approved</Typography>
                                </Grid>}

                                <Grid item xs={isMobile ? 12 : 4} className={classes.approveIcon}>
                                    <CheckCircle className={isMobile && classes.iconSizeMobile} />
                                </Grid>
                            </> : submittedAssignment?.status === 'NotApproved' ?
                                <>
                                    {!isMobile && <Grid item xs={8}>
                                        <Typography className={classes.supportedFileTitle} style={{ color: '#E54D40' }}>Not Approved</Typography>

                                    </Grid>}

                                    <Grid item xs={isMobile ? 12 : 4} className={classes.notApproveIcon}>
                                        <Cancel className={isMobile && classes.iconSizeMobile} />
                                    </Grid>
                                </> : <>
                                    {isMobile ? <Grid item xs={12} container> <Grid item xs={12} className={classes.approveIcon} onClick={() => handleChangeStatus('Approved')}>
                                        <CheckCircle className={isMobile && classes.iconSizeMobile} />
                                    </Grid>
                                        <Grid item xs={12} className={classes.notApproveIcon} onClick={() => handleChangeStatus('NotApproved')}>
                                            <Cancel className={isMobile && classes.iconSizeMobile} />
                                        </Grid>

                                    </Grid> :
                                        <> <Grid item xs={6}>
                                            <Typography className={classes.supportedFileTitle} style={{ cursor: 'pointer' }} onClick={() => handleChangeStatus('NotApproved')}>Not Approve</Typography>
                                        </Grid>

                                            <Grid item xs={6} style={{ textAlign: 'center' }}>
                                                <Button className={classes.approveBtn} onClick={() => handleChangeStatus('Approved')}>Approve</Button>
                                            </Grid></>}
                                </>
                        }
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default AssignmentApproveCard;

const useStyles = makeStyles(theme => ({
    cardContainer: {
        minHeight: '60px',
        width: '100%',
        boxShadow: 'none',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'

    },
    assignmentTitle: {
        fontFamily: theme.fontFamily.Roboto,
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "16px",
        color: "#B6B6B6",
        paddingBottom: '5px',
    },
    assignmentTime: {
        fontFamily: theme.fontFamily.Roboto,
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "14px",
        color: "#B6B6B6",

    },
    assignmentTitleMobile: {
        fontFamily: theme.fontFamily.Roboto,
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "16px",
        textAlign: 'left',
        color: "#B6B6B6",
        paddingBottom: '5px',
        marginBottom: '15px'

    },
    assignmentTimeMobile: {
        fontFamily: theme.fontFamily.Roboto,
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "14px",
        textAlign: 'right',
        color: "#B6B6B6",
        marginBottom: '15px'

    },
    announcementTxtContainer: {
        paddingLeft: '15px'
    },
    supportedFileTitle: {
        fontFamily: theme.fontFamily.Roboto,
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "14px",
        lineHeight: "25px",
        color: "#666666",
        textAlign: 'center'
    },
    nextEpNA: {
        fontFamily: theme.fontFamily.Roboto,
        fontStyle: "italic",
        fontWeight: 500,
        fontSize: "10px",
        color: "#666666",
        textAlign: 'center'
    },
    supportedFileFormate: {
        fontFamily: theme.fontFamily.Roboto,
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "12px",
        lineHeight: "19px",
        color: "#A7A7A7",
    },

    announcementHeading: {
        fontFamily: theme.fontFamily.Roboto,
        fontStyle: "bold",
        fontWeight: 'normal',
        fontSize: "18px",
        lineHeight: "100%",
        color: "#353B35",
        textAlign: 'center',

    },
    assignmentTitleContainer: {
        borderRight: '1px solid #3D3D3D',

    },
    verticalCenter: {
        display: 'flex',
        alignItems: 'center'

    },
    horizontalCenter: {
        display: 'flex',
        justifyContent: 'center'

    },
    browsButtonContainer: {
        border: '2px dashed #000000',
        borderRadius: '11px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '60px',
        width: '90%'
    },
    browsButton: {
        color: '#fff',
        background: '#FBAE02',
        borderRadius: '20px',
        height: '30px',
        padding: '0px 40px',
        minWidth: '30px',
        '&:hover': {
            color: '#fff',
            background: '#FBAE02',
        }
    },
    approveBtn: {
        background: '#87AC26',
        '&:hover': {
            background: '#87AC26',
        },
        borderRadius: '4px',
        color: '#FFF',
        justifyContent: 'center',
        textTransform: 'none',
        textAlign: 'center'
    },
    approveIcon: {
        textAlign: 'center', color: '#87AC26'
    },
    notApproveIcon: {
        textAlign: 'center', color: '#E54D40'
    },


    iconSizeMobile: {
        fontSize: 45

    }

}));