import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  TextField,
  Button,
  Box,
  CircularProgress,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import AppBar from "../../../../components/AppBar/index";
import { addSuggestion } from "../../../../actions/setting";
import { useDispatch, useSelector } from "react-redux";
import { useTheme } from "@material-ui/core/styles";

import { useIntl } from "react-intl";

function Suggestion(props) {
  const [suggestion, setSuggestion] = useState("");
  let isSuggestionAdded = useSelector((state) => state.message);
  const [showLoader, setShowLoader] = useState(false);
  const theme = useTheme();
  const intl = useIntl();
  useEffect(() => {
    if (
      isSuggestionAdded.show === true &&
      isSuggestionAdded.severity === "success"
    ) {
      setTimeout(props.handleBack(null), 2000);
    }
  });

  useEffect(() => {
    setShowLoader(false);
  }, [isSuggestionAdded.show]);

  const handleChange = (value) => {
    setSuggestion(value);
  };

  let dispatch = useDispatch();
  let authReducerState = useSelector((state) => state.auth);
  const handleClick = () => {
    setShowLoader(true);
    dispatch(
      addSuggestion({
        token: authReducerState.token,
        suggestion: { suggestion: suggestion },
      })
    );
  };
  const classes = useStyles();
  return (
    <Grid container xs={11}>
      <Grid container style={{ padding: "12px" }}>
        <AppBar
          appBarConfig={{
            show: true,
            text: intl.formatMessage({ id: "makeASuggestion" }),
            backAction: props.handleBack,
          }}
        />
      </Grid>

      <Grid
        container
        spacing={3}
        style={{
          overflow: "hidden",
          marginLeft: "25px",
          marginBottom: "5px",
          marginTop: "5px",
        }}
      >
        <Grid item xs={12}>
          <Typography className={classes.header}>
            {intl.formatMessage({ id: "makeASuggestion" })}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.body}>
            {intl.formatMessage({ id: "suggestionsBody" })}
          </Typography>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={3}
        style={{
          overflow: "hidden",
          marginLeft: "25px",
          marginBottom: "5px",
          marginTop: "5px",
        }}
      >
        <Grid item xs={8}>
          <TextField
            className={classes.courseDescriptionRemarksField}
            fullWidth
            value={suggestion}
            onChange={(e) => handleChange(e.target.value)}
            margin="normal"
            rows="10"
            multiline
          />
        </Grid>
      </Grid>
      <Grid
        container
        spacing={3}
        style={{
          overflow: "hidden",
          marginLeft: "25px",
          marginBottom: "30px",
          marginTop: "5px",
          padding: "12px",
        }}
      >
        <Grid xs={5}>
          <Button
            style={{
              width: "100%",
              height: "2.750em",
              background:
                "linear-gradient(180deg, #FFC000 -13.24%, #F28705 100%)",
              borderRadius: "11px",
              fontFamily: theme.fontFamily.Roboto,
              fontStyle: "normal",
              fontWeight: 500,
              fontSize: "20px",
              lineHeight: "23px",
              color: "#FFFFFF",
              marginBottom: "30px",
            }}
            onClick={() => {
              handleClick();
            }}
          >
            {showLoader ? (
              <CircularProgress style={{ color: "#FFF" }} size={20} />
            ) : (
              intl.formatMessage({ id: "submit" })
            )}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Suggestion;

const useStyles = makeStyles((theme) => ({
  courseDescriptionRemarksField: {
    "& .MuiInputBase-multiline": {
      border: `1px solid #FAAC02`,
      padding: "10px 10px",
      borderRadius: "10px",
    },
    "& .MuiInputLabel-root": {
      fontFamily: theme.fontFamily.Roboto,
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: "16px",
      color: "#505050",
      opacity: "0.78",
      "& ::after": {
        fontFamily: theme.fontFamily.Roboto,
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "14px",
        lineHeight: "16px",
        color: "#505050",
        opacity: "0.78",
      },
    },
    "& .MuiInput-underline:before": {
      borderBottom: `0px solid #FAAC02`,
      borderTop: "none",
    },
    "& .MuiInput-underline::after": {
      borderBottom: `0px solid #FAAC02`,
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled)::before": {
      borderBottom: `0px solid #FAAC02`,
    },
  },
  logoImage: {
    marginLeft: "10px",
    marginTop: "10px",
    height: "75px",
    width: "75px",
  },
  title: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "22px",
    lineHeight: "26px",
    color: "#000000",
  },
  fullHeight: {
    height: "100%",
  },
  body: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "18px",
    lineHeight: "26px",
    letterSpacing: "0.035em",
    color: "#9E9393",
  },
  subTitle: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "16px",
    color: "#8B8B8B",
  },
  header: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "22px",
    lineHeight: "26px",
    color: "#000000",
  },
  selectedBtn: {
    background: "linear-gradient(#87AC26 1%,#87AC26 100%)",
    borderRadius: "11px",
    color: "#FFFFFF",
    padding: "10px",
    height: "50px",
    width: "140px",
  },
  unSelectBtn: {
    borderColor: "#87AC26",
    borderRadius: "11px",
    color: "#87AC26",
    padding: "10px",
    height: "50px",
    width: "140px",
  },
  btnText: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "21px",
  },
}));
