import React, {useEffect, useState} from 'react';
import { Grid, Typography, CircularProgress } from '@material-ui/core'
import AnnouncementCard from './announcementCard'
import { useDispatch, useSelector } from 'react-redux';
import { getCourseAnnouncements } from '../../../../actions/retreatCourse'
import { makeStyles } from "@material-ui/core/styles";

function Announcements(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [loader, setLoader] = useState(false)
    const user = useSelector(state => state?.auth);
    const course = useSelector(state => state?.retreatCourse?.trainingCourseDetails);
    const announcements = useSelector(state => state?.retreatCourse?.announcements);

    const getData = async () => {
            setLoader(true)
            const res = await dispatch(getCourseAnnouncements({ trainingCourseId: course?.id, token: user?.token }));
            if (res) {
                setLoader(false)
            }
    }


    useEffect(() => {
        getData();
        return () => {
            dispatch({
                type: 'TRAINING_COURSE_ANNOUNCEMENTS',
                payload: []
            });
        }
    }, []);

    return (
        <Grid container>
            { loader ? <Grid item xs={12} className={classes.noContent}>
                <CircularProgress size={20} style={{ color: '#09a9bb' }} />
            </Grid>
                : announcements?.length > 0 ?
                    <Grid item xs={12} container spacing={1} className={classes.container}>
                        {announcements.map(announcement => <Grid item xs={12} key={announcement?.id}><AnnouncementCard announcement={announcement} /></Grid>)}
                    </Grid> : <Grid item xs={12} className={classes.noContent}>
                        <Typography className={classes.noContentText}>No announcement found</Typography>
                    </Grid>}

        </Grid>
    );
}

export default Announcements;


const useStyles = makeStyles(theme => ({
    container: {
        paddingTop:'10px',
        maxHeight: '400px',
        overflowY:'auto',
        '&::-webkit-scrollbar': {
            width: '0px',
            background: 'transparent', /* make scrollbar transparent */
        }

    },
    noContent: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: "40px"
    },
    noContentText: {
        fontFamily: theme.fontFamily.Roboto,
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "14px",
        color: "#666666",

    }

}));

