import React, { useState, useEffect } from 'react'
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import {
  Grid,
  AppBar,
  Tabs,
  Tab,
  useMediaQuery,
  Fab,
  Box
} from "@material-ui/core";
import { Add, Close } from "@material-ui/icons";
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialIcon from "@material-ui/lab/SpeedDialIcon";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import { useIntl } from "react-intl";
import StatsOverview from '../../../../components/More/ChallengesStats/StatsOverview'
import Challenges from '../../../../components/More/ChallengesStats/Challenges'
import { createChallengeData, addParticipants } from '../../../../actions/challenges'
const StyledTabs = withStyles({
  indicator: {
    display: "flex",
    justifyContent: "center",
    height: '3px',
    backgroundColor: "transparent",
    "& > span": {
      maxWidth: 40,
      width: "100%",
      backgroundColor: "#F28705",
    },
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

const StyledTab = withStyles((theme) => ({
  root: {
    minWidth: "60px !important",
    textTransform: "none",
    color: "#F28705",
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    "&:focus": {
      opacity: 1,
    },
  },
}))((props) => <Tab disableRipple {...props} />);

function ChallengesStats() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const theme = useTheme();
  const classes = useStyles();
  const history = useHistory();

  const [value, setValue] = useState(0);
  const [open, setOpen] = React.useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <>{children}</>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
  };



  return (
    <Grid container style={{ marginLeft: '60px' }}>
      {/* <Grid item xs={1} /> */}
      <Grid item xs={12} container >
        <Grid item md={8} lg={6}>
          <AppBar position="static" className={classes.appBar}>
            <StyledTabs
              value={value}
              onChange={handleChange}

              style={{ margin: isMobile ? "0px" : "25px" }}
            >
              <StyledTab
                label={intl.formatMessage({ id: "stats" })}
                classes={{
                  root: classes.tabLabel,
                  selected: classes.tabSelected
                }}
              />
              <StyledTab
                label={intl.formatMessage({ id: "challenges" })}
                classes={{
                  root: classes.tabLabel,
                  selected: classes.tabSelected
                }}
              />

            </StyledTabs>
          </AppBar>
        </Grid>

      </Grid>

      <Grid item xs={12} >
        <TabPanel value={value} index={0}>
          <Grid container>
            <Grid item xs={5} sm={10} md={7} lg={6}>
              <StatsOverview />
            </Grid>
          </Grid>
        </TabPanel>

        <TabPanel value={value} index={1}>
          <Grid container style={{ height: '100vh' }}>
            <Grid item xs={6} sm={10} md={7} lg={6}>
              <Challenges />
            </Grid>
          </Grid>
        </TabPanel>

      </Grid>

      <Grid container style={{ minHeight: "10vh" }}>
        <Grid item xs={8}></Grid>

        <Grid xs={4}>

          <SpeedDial
            ariaLabel="SpeedDial example"
            className={classes.speedDial}
            style={{

              bottom: isMobile ? "12%" : theme.spacing.unit * 3,
              right: theme.spacing.unit * 4,

            }}
            hidden={false}
            icon={
              <SpeedDialIcon icon={<Add />} openIcon={<Close />} />
            }
            onClose={handleClose}
            onOpen={handleOpen}
            open={open}
            direction={'left'}
          >
            <SpeedDialAction
              className={classes.btnStyle}
              icon={"Challenge"}
              tooltipTitle=""
              onClick={() => {
                dispatch(createChallengeData({}));
                dispatch(addParticipants([]))

                return history.push("/more/create-challenge")
              }}
            />


          </SpeedDial>
        </Grid>

      </Grid>
    </Grid>
  )
}

export default ChallengesStats;

const useStyles = makeStyles(theme => ({
  appBar: {
    backgroundColor: "#FFF",
    width: "100%",
    boxShadow: "none",
    display: "flex",
    justifyContent: "space-between",
    marginTop: '25px'
  },
  tabLabel: {
    color: "#DFDFDF",
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "19px",
    textTransform: "none"
  },
  tabSelected: {
    color: "#F28705",
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "19px"
  },

  noRecord: {
    color: "#DFDFDF",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "19px",
    textAlign: "center",
    marginTop: "50px"
  },
  btnStyle: {
    background: "linear-gradient(180deg, #FFC000 -13.24%, #F28705 100%)",
    color: "#fff",
    height: "50px",
    width: "50px",
    fontFamily: theme.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: 900,
    fontSize: "10px",
    lineHeight: "19px",
    textAlign: "center",
  },
  btnOPen: {
    background: "linear-gradient(180deg, #FFC000 -13.24%, #F28705 100%)",
    color: "#fff",
    width: "50px",
    height: "50px",
    fontFamily: theme.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: 900,
    fontSize: "14px",
    lineHeight: "19px",
    textAlign: "center",
  },
  speedDial: {
    position: 'fixed',
    "& .MuiFab-primary:hover": {
      width: "50px",
      height: "50px",
      color: "#E54D40",
      backgroundColor: "#E5E5E5 !important",


    },
    "& .MuiFab-primary": {
      width: "50px",
      height: "50px",
      backgroundColor: "#F28705 !important",


    },
    "&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft": {
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    "&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight": {
      top: theme.spacing(2),
      left: theme.spacing(2),
    },
  },
}));
