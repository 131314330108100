import API from "../utils/API";

// types
import {
    GET_USER_BY_ID,
    LOADER
  
} from "./types";

export const getUserById = ({token,userId}) => async dispatch => {
  dispatch({
    type: LOADER,
    payload: true,
  });
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      },
     
    };
  
    try {
      const res = await API.get(`/api/v1/user/getUserProfileById/${userId}`, config);
      dispatch({
        type: GET_USER_BY_ID,
        payload: res.data.data
      });
      dispatch({
        type: LOADER,
        payload: false,
      });
    } catch (error) {
      dispatch({
        type: LOADER,
        payload: false,
      });
      console.log(error);
    }
  };

  //Get user followers by userId
export const getUserFollowersFollowingsById = ({token,userId}) => async dispatch => {
 
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      },
     
    };
  
    try {
      const res = await API.get(`/api/v1/user/getUserFollwersAndFollowings/${userId}`, config);
      return res?.data?.data
    } catch (error) {
      console.log(error);
      
    }
  };  