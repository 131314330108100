import React from "react";
import { useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import More from "../../../containers/Web/More";
import { useSelector } from "react-redux";
import CreateSessionMobileContainer from "../../../containers/Common/Session";
import AddLinkAccountDialog from "../../../components/Common/AddLinkAccountDialog";

const Session = props => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  const siteConfig = useSelector(state => state.setting?.siteConfig);

  return (
    <>
      {siteConfig?.mode === "production" && (
        <AddLinkAccountDialog type="session" />
      )}
      {isDesktop && <More />}
      {isMobile && !isDesktop && <CreateSessionMobileContainer />}
    </>
  );
};

export default Session;
