import React,{useEffect, useState} from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Chip from "@material-ui/core/Chip";
import Truncate from "react-truncate";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { useIntl } from "react-intl";
import { Tooltip } from "@material-ui/core";
import moment from "moment";

const useStyles = makeStyles(theme => ({
  root: {
    width: "230px",
    borderRadius: "11px",
    boxShadow: " 0px 4px 4px rgba(0, 0, 0, 0.05)"
  },
  media: {
    height: "0",
    position: "relative",
    paddingTop: "56.25%",
    width: "100%",
    BorderRadiusTopRight: "11px",
    BorderRadiusTopLeft: "11px",
    background: "#C4C4C4",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain"
  },
  title: {
    fontFamily: theme.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: "900",
    fontSize: "14px",
    lineHeight: "19px",
    textAlign: "center",
    color: "#000000",
  },
  subTitle: {
    fontFamily: theme.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: "900",
    fontSize: "10px",
    lineHeight: "14px",
    textAlign: "center",
    color: "#C2C2C2"
  },
  nameBold: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "18px",
    lineHeight: "14px",
    color: "#3C4A6B",
    marginTop: "10px"
  }
}));

function ExploreSessionCard(props) {
  const classes = useStyles();
  const intl = useIntl();
  const [deliverStatus, setDeliverStatus] = useState('');
  

  useEffect(()=>{
    if(props?.session?.status === 'Active' && props?.session?.sessionDates?.length > 0){
      
        if(moment().isBefore(moment(props?.session?.sessionDates[0]?.dateTime, 'x').local())){
          setDeliverStatus('Scheduled')
        }else if(moment().isAfter(moment(props?.session?.sessionDates[0]?.endingDateTime, 'x').local())){
          setDeliverStatus('Delivered')
        }else{
          setDeliverStatus('Live')
        }
      
    } else {
      setDeliverStatus(props?.session?.status)
    }
  },[])
  return (
    <Card className={classes.root} style={{width: props?.width && '100%'}}>
      {/* <CardActionArea> */}
      <CardMedia className={classes.media} image={props?.image} style={{ backgroundColor: props?.image && '#fff' }} >
        <Grid style={{

          position: "absolute",
          top: -3,
          zIndex: 1,
          height: '21px'
        }} container justify="space-between">
          <Grid item >
            {<Chip label={deliverStatus} style={{
              color: "#FFF",
              height: "20px",
              background: `${deliverStatus === 'Live' ? '#39a746' : deliverStatus === 'Scheduled' ? '#39a746' : '#fbae02'}`,
              zIndex: 1
            }} />}
          </Grid>
        </Grid>
        {props?.category && (
          <Grid
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              bottom: 0,
              right: 0
            }}
            container
            justify="flex-end"
          >
            <Grid item xs={1}>
              <Typography
                style={{ color: "#FFF", marginTop: "147px", fontSize: "20px" }}
              >
                &bull;{"   "}{" "}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography
                style={{
                  color: "#FFF",
                  marginTop: "155px",
                  marginRight: "5px",
                  fontSize: "10px"
                }}
              >
                {props?.category}
              </Typography>
            </Grid>
          </Grid>
        )}
      </CardMedia>
      <CardContent style={{ padding: '7px 0px' }}>
        <Tooltip title={props?.name}>
          <Typography
            gutterBottom
            style={{ width: "100%", }}
            className={props?.courseNameBold ? classes.nameBold : classes.title}
          >
            <Truncate lines={1} ellipsis={<span>...</span>} >
              {`    ${props?.name}    `}
            </Truncate>
          </Typography>
        </Tooltip>
        <Grid container>
          {/* <Grid item xs={1}>

            </Grid> */}
          <Grid item xs={4}>
            <Typography
              variant="body2"
              component="p"
              className={classes.subTitle}
            >
              {props?.friends}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            {/* {props?.totalDuration >= 0 && (
              <Typography
                variant="body2"
                component="p"
                className={classes.subTitle}
              >
                {props?.totalDuration + " "}
                {intl.formatMessage({ id: "mins" })}
              </Typography>
            )} */}
            {props?.toDuration && (
              <Typography
                variant="body2"
                component="p"
                className={classes.subTitle}
              >
                {props?.toDuration + " "}
                {intl.formatMessage({ id: "mins" })}
              </Typography>
            )}
          </Grid>
          <Grid item xs={4}>
            <Typography
              variant="body2"
              component="p"
              className={classes.subTitle}
            >
              {props?.time}
            </Typography>
          </Grid>
          {/* <Grid item xs={1}></Grid> */}
        </Grid>
      </CardContent>
      {/* </CardActionArea> */}
    </Card>
  );
}

export default ExploreSessionCard;
