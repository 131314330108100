import React, { useEffect, useState } from "react";

import {
  Grid,
  Typography,
  DialogTitle,
  useMediaQuery,
  Dialog,
  Tooltip,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  IconButton,
  CircularProgress,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import AppBar from "../../../components/AppBar";
import Comments from "../../../components/ViewCourse/CourseReviews";
import PostReviewCard from "../../../components/ViewCourse/PostReviewCard";

import { Close, Share, InsertInvitation, Error } from "@material-ui/icons";
import InviteAndShare from "../../Common/CoursesContainer/InviteAndShare";
import CustomerCardList from "../../../components/Common/CustomerCardList";
import { useTheme } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory, Link } from "react-router-dom";
import {
  getEpisodeDetailById,
  getCourseDetailById,
  episodeStartOrComplete,
} from "../../../actions/courses";
import TopBar from "../../../components/AppBar";
import IntroVideo from "../../../assets/videos/OmidIntro.mp4";
import ReactPlayer from "react-player/lazy";
import { useIntl } from "react-intl";
import clsx from "clsx";
import { getPrice } from "../../../common/methods";
function PlayCourseWebContainer(props) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const intl = useIntl();
  const classes = useStyles();
  const history = useHistory();

  const { courseId, episodeId } = useParams();
  let authReducerState = useSelector((state) => state.auth);
  let courseDetail = useSelector((state) => state?.courses?.courseDetail);
  let episodeDetail = useSelector((state) => state?.courses?.episodeDetail);
  let reducerUser = useSelector((state) => state?.user?.user);
  let isMobile = useMediaQuery(theme.breakpoints.down("sm")) ? true : false;
  const [openPaymentDialog, setOpenPaymentDialog] = useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);

  const [openSubscribeDialog, setOpenSubscribeDialog] = React.useState(false);
  const IPFS_BASE_URL = process.env.REACT_APP_IPFS_BASE_URL;
  console.log("omid url" + IPFS_BASE_URL);

  const [error, setError] = React.useState(null);



  // console.log("url of Viodeo " + `${IPFS_BASE_URL}/${episodeDetail?.ipfsCid}`);
  // debugger;
  // console.log({ episodeDetail }, { videoUrl });

  const [shareOpen, setShareOpen] = useState(false);
  const [episodeThumbnail, setEpisodeThumbnail] = useState("");
  const [play, setPlay] = useState(true);
  const [loader, setLoader] = useState(false);
  const [introVideo, setIntroVideo] = useState(true);

  useEffect(() => {
    if (
      reducerUser?.purchasedSubscription?.length === 0 &&
      courseDetail &&
      courseDetail?.id &&
      courseDetail?.plan?.name === "Subscription" &&
      reducerUser?.id !== courseDetail?.user?.id
    ) {
      return setOpenSubscribeDialog(true);
    } else {
      getData();
    }
    return () => {
      dispatch({
        type: "EPISODE_DETAIL",
        payload: {},
      });
    };
  }, []);

  useEffect(() => {
    if (!episodeDetail) {
      setError(`Oops! Media file is corrupted or not exists`);
    }
  }, [episodeDetail?.id]);

  const getData = async () => {
    setLoader(true);
    const responseDataCourse = await dispatch(
      getCourseDetailById({ courseId, token: authReducerState.token })
    );
    const responseData = await dispatch(
      getEpisodeDetailById({ episodeId, token: authReducerState.token })
    );

    if (
      responseData?.status === "success" &&
      responseDataCourse?.status === "success"
    ) {
      setLoader(false);
      setPlay(true);
      return;
    } else if (
      responseData?.status === "fail" ||
      responseDataCourse?.status === "fail"
    ) {
      setLoader(false);

      setError(
        responseDataCourse?.message
          ? responseDataCourse?.message
          : responseData?.message
          ? responseData?.message
          : "Not found"
      );
    } else if (!responseData || !responseDataCourse) {
      setLoader(false);
      setError("Not found");
    }
  };

  const handleProgress = (state) => {
    if (!introVideo) {
      localStorage.setItem(`ep${episodeDetail?.id}`, state?.playedSeconds);
    }
  };

  const handleEnded = (state) => {
    if (!introVideo) {
      localStorage.setItem(`ep${episodeDetail?.id}`, "completed");
      dispatch(
        episodeStartOrComplete({
          status: "Completed",
          episodeId,
          token: authReducerState.token,
        })
      );
      let episodeIndex = null;
      let findPlayedEpisode = courseDetail?.episodes?.some((elem, i) => {
        if (elem?.id.toString() === episodeId) {
          episodeIndex = i;
          return true;
        } else {
          return false;
        }
      });
      if (
        episodeIndex !== null &&
        courseDetail?.episodes &&
        courseDetail?.episodes[episodeIndex + 1]?.id
      ) {
        if (
          courseDetail?.user?.id === authReducerState?.user?.id ||
          (courseDetail?.isUserTaking && courseDetail?.isPaid) ||
          courseDetail?.episodes[episodeIndex + 1]?.isFree
        ) {
          return history.push(
            `/play-course/${courseId}/${
              courseDetail?.episodes[episodeIndex + 1]?.id
            }`
          );
        }
        setEpisodeThumbnail(courseDetail?.episodes[episodeIndex]?.thumbnail);
        setPlay(true);
        setOpenDialog(true);
      } else if (
        episodeIndex !== null &&
        courseDetail?.episodes &&
        !courseDetail?.episodes[episodeIndex + 1]?.id
      ) {
        setEpisodeThumbnail(courseDetail?.episodes[episodeIndex]?.thumbnail);
        setPlay(true);
      }
    }
    setIntroVideo(!introVideo);
  };

  const hexToRGB = (hex, alpha) => {
    var r = parseInt(hex && hex.slice(1, 3), 16),
      g = parseInt(hex && hex.slice(3, 5), 16),
      b = parseInt(hex && hex.slice(5, 7), 16);

    if (alpha) {
      return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
    } else {
      return "rgb(" + r + ", " + g + ", " + b + ")";
    }
  };

  const handleClose = () => {
    setOpenDialog(false);
  };
  const handleClosePopup = () => {
    history.goBack();
    setOpenSubscribeDialog(false);
  };

  const handlePayNowButton = () => {
    setOpenDialog(false);
    setOpenPaymentDialog(true);
  };

  const handleEpisodeStart = (state) => {
    if (!introVideo) {
      setEpisodeThumbnail("");
      dispatch(
        episodeStartOrComplete({
          status: "Started",
          episodeId,
          token: authReducerState.token,
        })
      );
    }
  };

  const videoUrl = introVideo ? IntroVideo : episodeDetail?.ipfsCid
  ? `${IPFS_BASE_URL}/${episodeDetail?.ipfsCid}`
  : episodeDetail.multimediaUrl;

  return (
    <Grid container>
      {error ? (
        <Grid container style={{ backgroundColor: "#E5E5E5" }}>
          <Grid
            item
            xs={11}
            style={{ display: "flex", alignItems: "center", height: "87px" }}
          >
            <TopBar
              appBarConfig={{
                show: true,
                text: "Play Episode",
                backgroundColor: "#E5E5E5",
                backAction: () => history.push(`/view-course/${courseId}`),
              }}
            />
          </Grid>
          <Grid xs={12} item>
            <div
              className={classes.reactPlayer}
              style={{
                background: "#E5E5E5",
                height: "100%",
                width: "100%",
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
              }}
            >
              <Error style={{ fontSize: "30px", marginTop: "6px" }} />
              <Typography
                style={{
                  fontFamily: theme.fontFamily.Roboto,
                  fontStyle: "normal",
                  fontWeight: 500,
                  fontSize: "24px",
                  color: "#000000",
                  marginTop: "7px",
                }}
              >
                {"  " + error}
              </Typography>
            </div>
          </Grid>
        </Grid>
      ) : (
        <>
          {loader ? (
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100vh",
              }}
            >
              <CircularProgress size={30} style={{ color: "#15AEBF" }} />
            </Grid>
          ) : (
            <>
              {shareOpen && (
                <Dialog open={shareOpen} onClose={() => setShareOpen(false)}>
                  <DialogTitle>
                    <Grid container justify="space-between">
                      <Grid item>
                        <Typography component="h4" variant="h5">
                          {shareOpen}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Button onClick={() => setShareOpen(false)}>
                          <Close />
                        </Button>
                      </Grid>
                    </Grid>
                  </DialogTitle>
                  <DialogContent style={{ padding: isMobile && "0px" }}>
                    <InviteAndShare
                      course={courseDetail}
                      buttonText="close"
                      invite={shareOpen === "Invite"}
                      share={shareOpen === "Share"}
                      handleNext={() => setShareOpen(false)}
                    />
                  </DialogContent>
                </Dialog>
              )}
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <CustomerCardList
                  open={openPaymentDialog}
                  setOpen={setOpenPaymentDialog}
                  courseId={courseDetail?.id}
                  plan={
                    courseDetail?.plan?.name === "Free" ||
                    courseDetail?.plan?.name === "Donation"
                      ? { name: "Donation" }
                      : courseDetail?.plan
                  }
                  price={getPrice(
                    courseDetail?.price,
                    reducerUser?.purchasedSubscription,
                    "course"
                  )}

                  // price={courseDetail?.price}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Dialog
                  open={openDialog}
                  aria-labelledby="alert-dialog-slide-title"
                  aria-describedby="alert-dialog-slide-description"
                >
                  <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                      Please complete payment first to access full course.
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={handleClose}
                      color="primary"
                      className={classes.proceedButton}
                    >
                      {intl.formatMessage({ id: "notNow" })}
                    </Button>
                    <Button
                      onClick={handlePayNowButton}
                      color="primary"
                      className={classes.proceedButton}
                    >
                      {intl.formatMessage({ id: "payNow" })}
                    </Button>
                  </DialogActions>
                </Dialog>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Dialog
                  open={openSubscribeDialog}
                  aria-labelledby="alert-dialog-slide-title"
                  aria-describedby="alert-dialog-slide-description"
                >
                  <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                      Your Monthly subscribed material has expired, please
                      rejoin for next month or cancel any joined material. Click
                      Update to view or update details.
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={handleClosePopup}
                      color="primary"
                      className={classes.proceedButton}
                    >
                      {intl.formatMessage({ id: "notNow" })}
                    </Button>
                    {/*<Link*/}
                    {/*  to={"/more/subscription-plan"}*/}
                    {/*  style={{ textDecoration: "none" }}*/}
                    {/*>*/}
                    {/*  <Button color="primary" className={classes.proceedButton}>*/}
                    {/*    Upgrade*/}
                    {/*  </Button>*/}
                    {/*</Link>*/}
                  </DialogActions>
                </Dialog>
              </Grid>

              {/*   <Grid item xs={12} sm={12} md={12} lg={12} xl={12} container>
                <Grid item xs={10}>
                  <AppBar appBarConfig={{ show: true }} />
                </Grid>
              </Grid> */}

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} container>
                <Grid item xs={10}>
                  <AppBar appBarConfig={{ show: true, text: "Play Episode" }} />
                </Grid>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} container>
                <Grid item xs={12} sm={12} md={8} container>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    style={{
                      padding: isMobile
                        ? "0px 10px 0px 10px"
                        : "0px 30px 0px 30px",
                    }}
                  >
                    <div className={classes.playerWrapper}>
                      <div
                        className={clsx(
                          classes.reactPlayer,
                          classes.preLoadBody
                        )}
                      >
                        <CircularProgress style={{ color: "#09A9BB" }} />
                      </div>
                      <ReactPlayer
                        controls="true"
                        height="100%"
                        width="100%"
                        className={classes.reactPlayer}
                        onProgress={handleProgress}
                        onEnded={handleEnded}
                        onStart={handleEpisodeStart}
                        playing={play}
                        light={episodeThumbnail}
                        onError={() => {
                          episodeDetail &&
                            !introVideo &&
                            !episodeDetail.multimediaUrl &&
                            !episodeDetail.ipfsCid &&
                            setError(
                              `Oops! Media file is corrupted or not exists`
                            );
                        }}

                        url={
                          // "https://ipfsn.omidlife.com/ipfs/QmQcfUnosLzXf1tcWuu8QUbz53vKYm9sPQGCAF3ZworqJm"
                          videoUrl
                          // introVideo
                          //   ? IntroVideo
                          //   : `${episodeDetail && episodeDetail?.multimediaUrl}`
                          // "https://ipfsn.omidlife.com/ipfs/QmSPQgk4sn8HDFA78eCoZ47nL17jWDpioxKUxuXqHujGnD"
                        }
                        config={{
                          youtube: {
                            playerVars: { showinfo: 1 },
                          },
                          file: { attributes: { controlsList: 'nodownload' } },

                        }}
                      />
                    </div>

                    {/* <video
                      controls={true}
                      autoPlay={true}
                      poster="https://peach.blender.org/wp-content/uploads/title_anouncement.jpg?x11217"
                      width="100%"
                      height="100%"
                      controlsList="nodownload"
                    >

                      <source
                        src="https://www.youtube.com/watch?v=EngW7tLk6R8"
                        type="video/mp4"
                      ></source>
                      <source
                        src="https://www.youtube.com/watch?v=EngW7tLk6R8"
                        type="video/webm"
                      ></source>
                      <source
                        src="https://www.youtube.com/watch?v=EngW7tLk6R8"
                        type="video/ogg"
                      ></source>
                    </video> */}

                    {/* <iframe
                      width="100%"
                      height="100%"
                      src="https://ipfsn.omidlife.com/ipfs/QmSPQgk4sn8HDFA78eCoZ47nL17jWDpioxKUxuXqHujGnD"
                      src={videoUrl}
                    ></iframe> */}
                  </Grid>

                  <Grid container item xs={12} justify="space-between">
                    <Grid
                      item
                      xs={12}
                      style={{
                        padding: isMobile
                          ? "0px 10px 0px 10px"
                          : "0px 30px 0px 30px",
                      }}
                    >
                      <Typography
                        style={{
                          fontFamily: theme.fontFamily.Roboto,
                          fontStyle: "normal",
                          fontWeight: 500,
                          fontSize: "20px",
                          lineHeight: "26px",
                          color: "#000000",
                          marginTop: "7px",
                        }}
                      >
                        {courseDetail?.name}
                      </Typography>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      style={{
                        padding: isMobile
                          ? "0px 10px 0px 10px"
                          : "0px 30px 0px 30px",
                      }}
                    >
                      <Typography
                        style={{
                          fontFamily: theme.fontFamily.Reef,
                          fontStyle: "normal",
                          fontWeight: 900,
                          fontSize: "18px",
                          lineHeight: "25px",
                          color: "#0EB2EF",
                          marginTop: "7px",
                        }}
                      >
                        {intl.formatMessage({ id: "Ep" })}:{" "}
                        {episodeDetail?.name}
                      </Typography>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      container
                      style={{
                        padding: isMobile
                          ? "15px 10px 0px 10px"
                          : "15px 30px 0px 30px",
                      }}
                    >
                      <Grid item xs={4}>
                        {authReducerState?.user?.id === courseDetail?.userId &&
                        courseDetail?.plan?.name ? (
                          <Button
                            disableRipple={true}
                            style={{
                              background: `linear-gradient(360deg, #4caf50 100%, ${hexToRGB(
                                courseDetail?.category?.color2,
                                1
                              )} 0%)`,
                              height: "2.463em",
                              width: "85%",
                              borderRadius: "10px",
                              transform: "rotate(360deg)",
                              fontFamily: theme.fontFamily.Reef,
                              fontStyle: "normal",
                              fontWeight: 500,
                              fontSize: "20px",
                              lineHeight: "10px",
                              color: "#FFFFFF",
                              cursor: "default",
                            }}
                          >
                            {courseDetail?.plan?.name}
                          </Button>
                        ) : (
                          (courseDetail?.plan?.name === "Free" ||
                            courseDetail?.plan?.name === "Donation") && (
                            <Tooltip
                              title={"Donate to appreciate course creator"}
                            >
                              <Button
                                onClick={() => setOpenPaymentDialog(true)}
                                style={{
                                  background: `linear-gradient(360deg, #088A2D 100%, ${hexToRGB(
                                    courseDetail?.category?.color2,
                                    1
                                  )} 0%)`,
                                  height: "2.463em",
                                  width: "90%",
                                  borderRadius: "10px",
                                  transform: "rotate(360deg)",
                                  fontFamily: theme.fontFamily.Reef,
                                  fontStyle: "normal",
                                  fontWeight: 500,
                                  fontSize: "20px",
                                  lineHeight: "10px",
                                  color: "#FFFFFF",
                                }}
                              >
                                {intl.formatMessage({ id: "donation" })}
                              </Button>
                            </Tooltip>
                          )
                        )}
                      </Grid>
                      <Grid item xs={4}>
                        <Button
                          className={classes.startNowBtn}
                          style={{
                            background: "#088A2D",
                            width: "90%",
                            fontSize: "20px",
                          }}
                          // onClick={() =>
                          //   history.push("/more/subscription-plan")
                          // }
                        >
                          <Typography>
                            {reducerUser?.purchasedSubscription &&
                            reducerUser?.purchasedSubscription[0]
                              ?.SubscriptionPlan?.name === "Free"
                              ? "Subscribe"
                              : ""}
                              {/* : "Upgrade subscription"} */}
                          </Typography>
                        </Button>
                      </Grid>

                      {courseDetail?.status !== "Incomplete" && (
                        <Grid
                          item
                          xs={4}
                          md={4}
                          container
                          spacing={1}
                          justify="flex-end"
                          style={{}}
                        >
                          <Grid item>
                            <Tooltip
                              title={
                                <span
                                  style={{
                                    fontSize: "14px",
                                    letterSpacing: 0.3,
                                  }}
                                >
                                  Invite
                                </span>
                              }
                              placement="top"
                            >
                              <IconButton
                                disabled={
                                  courseDetail?.status === "Active"
                                    ? false
                                    : true
                                }
                                onClick={() => setShareOpen("Invite")}
                                className={classes.inviteAndShare}
                              >
                                <InsertInvitation />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                          <Grid item>
                            <Tooltip
                              title={
                                <span
                                  style={{
                                    fontSize: "14px",
                                    letterSpacing: 0.3,
                                  }}
                                >
                                  Share
                                </span>
                              }
                              placement="top"
                            >
                              <IconButton
                                disabled={
                                  courseDetail?.status === "Incomplete" ||
                                  courseDetail?.status === "Blocked" ||
                                  courseDetail?.status === "InActive"
                                }
                                onClick={() => setShareOpen("Share")}
                                className={classes.inviteAndShare}
                              >
                                <Share />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    style={{
                      padding: isMobile
                        ? "0px 10px 0px 10px"
                        : "0px 30px 0px 30px",
                    }}
                  >
                    <Typography
                      style={{
                        fontFamily: theme.fontFamily.Reef,
                        fontStyle: "normal",
                        fontWeight: 900,
                        fontSize: "20px",
                        lineHeight: "27px",
                        color: "#000000",
                        paddingTop: "15px",
                        paddingBottom: "5px",
                      }}
                    >
                      {intl.formatMessage({ id: "Description" })}
                    </Typography>
                    {episodeDetail?.description ? (
                      <div
                        className={classes.epDescription}
                        dangerouslySetInnerHTML={{
                          __html: unescape(episodeDetail?.description),
                        }}
                      />
                    ) : (
                      <div
                        className={classes.epDescription}
                        style={{ textAlign: "center", paddingTop: "50px" }}
                      >
                        No description found
                      </div>
                    )}
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    style={{
                      padding: isMobile
                        ? "0px 10px 0px 10px"
                        : "0px 30px 0px 30px",
                    }}
                  >
                    <PostReviewCard />
                  </Grid>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={4}
                  lg={4}
                  xl={4}
                  container
                  style={{ height: "20px" }}
                >
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    style={{
                      marginTop: isMobile && "10px",
                      padding: isMobile
                        ? "0px 10px 0px 10px"
                        : "0px 10px 0px 0px",
                    }}
                  >
                    <Typography
                      style={{
                        fontFamily: theme.fontFamily.Reef,
                        fontStyle: "normal",
                        fontWeight: 900,
                        fontSize: "20px",
                        lineHeight: "27px",
                        color: "#000000",
                      }}
                    >
                      {intl.formatMessage({ id: "comments" })}
                    </Typography>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    style={{
                      padding: isMobile
                        ? "0px 10px 0px 10px"
                        : "0px 10px 0px 0px",
                      marginBottom: "70px",
                    }}
                  >
                    {courseDetail?.reviews_ratings?.length > 0 ? (
                      <Comments courseReviews={courseDetail?.reviews_ratings} />
                    ) : (
                      <Typography
                        style={{ marginTop: "30px", textAlign: "center" }}
                      >
                        No Record
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
        </>
      )}
    </Grid>
  );
}

export default PlayCourseWebContainer;

const useStyles = makeStyles((theme) => ({
  playerWrapper: {
    position: "relative",
    paddingTop: "56.25%",
  },
  reactPlayer: {
    position: "absolute",
    top: 0,
    left: 0,
  },
  inviteAndShare: {
    background: "linear-gradient(360deg, #15AEBF 0%, #A6D6FF 100%)",
    fontSize: "20px",
    lineHeight: "10px",
    color: "#FFFFFF",
  },
  startNowBtn: {
    height: "2.463em",
    width: "7.450em",
    background: "linear-gradient(360deg, #15AEBF 0%, #A6D6FF 100%)",
    borderRadius: "10px",
    transform: "rotate(360deg)",
    fontFamily: theme.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "20px",
    lineHeight: "10px",
    color: "#FFFFFF",
  },
  proceedButton: {
    background: "#fbae02",
    color: "#fff",
    "&:hover": {
      background: "#fbae02",
      color: "#fff",
    },
    "&:focus": {
      background: "#fbae02",
      color: "#fff",
    },
  },
  epDescription: {
    border: "1px solid #a9a9a9",
    padding: "5px 10px",
    borderRadius: 10,
    minHeight: "100px",
  },
  preLoadBody: {
    display: "flex",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#a9a9a9",
    width: "100%",
  },
}));
