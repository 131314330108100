import React, { useEffect, useState } from "react";
import { useTheme } from "@material-ui/core/styles";
import { Typography, Grid, CircularProgress } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getChallengeDetail } from "../../actions/challenges";
import { getUsersFriends } from "../../actions/user";
import TopBar from "../../components/AppBar";
import ReactGA from "react-ga";
import ViewChallengeDetailWebContainer from "../../containers/Web/ViewChallengeDetails";
import ViewChallengeDetailMobileContainer from "../../containers/Mobile/ViewChallengeDetails";
import { SocketContextProvider } from "../../Context/socketContext";

function ViewChallengeDetail() {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { id } = useParams();
  const dispatch = useDispatch();
  const [error, setError] = React.useState(null);
  const [localLoader, setLocalLoader] = useState(false);

  const auth = useSelector(state => state?.auth);

  useEffect(() => {
    getData();

   ReactGA.initialize(process.env.REACT_APP_TRACKING_ID);

    ReactGA.pageview(window.location.pathname);


  }, []);

  const getData = async () => {
    setLocalLoader(true);
    const responseData = await dispatch(
      getChallengeDetail({
        token: auth?.token,
        challengeId: id
      })
    );
    dispatch(getUsersFriends(auth?.token));

    if (responseData?.status === "success") {
      setLocalLoader(false);
      return;
    } else if (responseData?.status === "fail") {
      setLocalLoader(false);
      setError(responseData?.message ? responseData?.message : "Not found");
    } else if (responseData) {
      setLocalLoader(false);
      setError("Not found");
    }
  };

  return (
    <SocketContextProvider>
      {localLoader ? (
        <Grid container>
          <Grid xs={12} item>
            <div
              style={{
                height: "calc(100vh - 87px)",
                width: "100%",
                alignItems: "center",
                justifyContent: "center",
                display: "flex"
              }}
            >
              <CircularProgress size={20} style={{ color: "#09A9BB" }} />
            </div>
          </Grid>
        </Grid>
      ) : error ? (
        <Grid container style={{ backgroundColor: "#E5E5E5" }}>
          <Grid
            item
            xs={11}
            style={{ display: "flex", alignItems: "center", height: "87px" }}
          >
            <TopBar
              appBarConfig={{
                show: true,
                text: "Challenge",
                backgroundColor: "#E5E5E5"
              }}
            />
          </Grid>
          <Grid xs={12} item>
            <div
              style={{
                background: "#E5E5E5",
                height: "calc(100vh - 87px)",
                width: "100%",
                alignItems: "center",
                justifyContent: "center",
                display: "flex"
              }}
            >
              <Typography
                style={{
                  fontFamily: theme.fontFamily.Roboto,
                  fontStyle: "normal",
                  fontWeight: 500,
                  fontSize: "24px",
                  lineHeight: "28px",
                  color: "#000000",
                  marginTop: "7px"
                }}
              >
                {error}
              </Typography>
            </div>
          </Grid>
        </Grid>
      ) : (
        <>
          {isDesktop && <ViewChallengeDetailWebContainer />}
          {isMobile && !isDesktop && <ViewChallengeDetailMobileContainer />}
        </>
      )}
    </SocketContextProvider>
  );
}

export default ViewChallengeDetail;
