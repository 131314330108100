import API from "../utils/API";

import {
  ADD_PARTICIPANTS_IN_CHALLENGE,
  CREATE_CHALLENGE,
  GET_ALL_CHALLENGES,
  GET_CHALLENGE_BY_ID,
  JOIN_CHALLENGS,
  IS_JOINED,
  SHOW_MESSAGE,
  CREATE_CHALLENGE_DATA,
  GET_CHALLENGE_STATS,
  COMPLETE_CHALLENGE_STATUS,
  ADD_ID,
  GET_RECENT_CHALLENGES,
  LOADER
} from "./types";

import { getSessionDetails } from './Session'

export const addParticipants = (participant) => async (dispatch) => {
  dispatch({
    type: ADD_PARTICIPANTS_IN_CHALLENGE,
    payload: participant,
  });
};

// create new challenge
export const createChallenge = (token, data) => async (dispatch) => {
  dispatch({
    type: LOADER,
    payload: true,
  });
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  const body = JSON.stringify({ ...data });
  try {
    const res = await API.post(`/api/v1/challenge/`, body, config);
    dispatch({
      type: CREATE_CHALLENGE,
      payload: res.data.data,
    });
    dispatch({
      type: ADD_ID,
      payload: { id: res?.data?.data?.id, type: res?.data?.data?.type, imageUrl: res?.data?.data?.imageUrl },
    });

    dispatch({
      type: CREATE_CHALLENGE_DATA,
      payload: {},
    });

    dispatch({
      type: ADD_PARTICIPANTS_IN_CHALLENGE,
      payload: [],
    });

    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        title: `success`,
        message: res.data.message,
        severity: "success",
      },
    });
    dispatch({
      type: LOADER,
      payload: false,
    });

    return dispatch(getAllChallenges(token));

  } catch (error) {
    console.log(error);
    dispatch({
      type: LOADER,
      payload: false,
    });
    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        title: `Failed`,
        message:
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : `create challenge failed`,
        severity: "error",
      },
    });
  }
};
// update challenge
export const updateChallenge = (token, data) => async (dispatch) => {
  dispatch({
    type: LOADER,
    payload: true,
  });
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  const body = JSON.stringify({ ...data });
  try {
    const res = await API.patch(`/api/v1/challenge/update-challenge`, body, config);

   /*  dispatch({
      type:  GET_CHALLENGE_BY_ID,
      payload: res.data.data,
    }); */
    dispatch({
      type: CREATE_CHALLENGE,
      payload: res.data.data,
    });
    dispatch({
      type: ADD_ID,
      payload: { id: res?.data?.data?.id, type: res?.data?.data?.type, imageUrl: res?.data?.data?.imageUrl },
    });

   /*  dispatch({
      type: CREATE_CHALLENGE_DATA,
      payload: {},
    }); */

   /*  dispatch({
      type: ADD_PARTICIPANTS_IN_CHALLENGE,
      payload: [],
    }); */

    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        title: `success`,
        message: res.data.message,
        severity: "success",
      },
    });
    dispatch({
      type: LOADER,
      payload: false,
    });

    return dispatch(getAllChallenges(token));

  } catch (error) {
    console.log(error);
    dispatch({
      type: LOADER,
      payload: false,
    });
    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        title: `Failed`,
        message:
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : `create challenge failed`,
        severity: "error",
      },
    });
  }
};

//get all challenges
export const getAllChallenges = (token) => async (dispatch) => {
  dispatch({
    type: LOADER,
    payload: true,
  });
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const res = await API.get(`/api/v1/challenge/get-all-challenges`, config);
    dispatch({
      type: GET_ALL_CHALLENGES,
      payload: res.data.data,
    });
    dispatch({
      type: LOADER,
      payload: false,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: LOADER,
      payload: false,
    });
  }
};

//get recent challenges
export const getRecentChallenges = ({ token,page,limit,categoryId }) => async (dispatch) => {
  dispatch({
    type: LOADER,
    payload: true,
  });
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    params:{
      page:page || undefined,
      limit: limit || undefined,
      categoryId: categoryId || '',
    }
  };

  try {
    const res = await API.get(`/api/v1/challenge/get-recent-challenges`, config);
    dispatch({
      type: GET_RECENT_CHALLENGES,
      payload: res.data.data,
    });
    dispatch({
      type: LOADER,
      payload: false,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: LOADER,
      payload: false,
    });
  }
};

//get challenge detail

export const getChallengeDetail = ({ token, challengeId }) => async (
  dispatch
) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const res = await API.get(
      `/api/v1/challenge/get-challenge-details/${challengeId}`,
      config
    );
    const invitationsRes = await API.get(
      `/api/v1/challenge/get-challenge-invitations/${challengeId}`,
      config
    );

    const datesRes = await API.get(
      `/api/v1/challenge/get-challenge-dates/${challengeId}`,
      config
    );



    let detailParticipants = invitationsRes?.data?.data?.invitations?.map(participant => {
      return {
        ...participant?.invitationReceivers,
        status: 'sent',
        invited: true
      }
    });
    dispatch(addParticipants(detailParticipants))

    const participantRes = await dispatch(getChallengeParticipants({token, challengeId}));


    dispatch({
      type: GET_CHALLENGE_BY_ID,
      payload: {...res.data.data, challengeParticipants: participantRes?.challengeParticipants, invitations: invitationsRes?.data?.data?.invitations, challengeDates: datesRes?.data?.data?.challengeDates},
    });

    return { status: 'success' }

  } catch (error) {
    console.log(error);
    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        title: `Failed`,
        message:
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : `failed`,
        severity: "error"
      }
    });
    return { status: 'fail', message: error?.response?.data?.message ? error?.response?.data?.message : 'something went wrong' }
  }
};
//get challenge participants

export const getChallengeParticipants = ({ token, challengeId }) => async (
  dispatch
) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const res = await API.get(
      `/api/v1/challenge/get-challenge-participants/${challengeId}`,
      config
    );

    return res.data.data

  } catch (error) {
    console.log(error);

  }
};

//get create challenge data

export const createChallengeData = (data) => async (dispatch) => {
  dispatch({
    type: CREATE_CHALLENGE_DATA,
    payload: data,
  });
};


export const getChallengesStats = ({ token }) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const res = await API.get(`/api/v1/challenge/get-challenges-stats`, config);
    dispatch({
      type: GET_CHALLENGE_STATS,
      payload: res.data.data,
    });
  } catch (error) {
    console.log(error);
  }
};

// complete challenge

export const challengeCompleteStatus = ({ token, challengeId }) => async (
  dispatch
) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  const body = JSON.stringify({ challengeId });
  try {
    const res = await API.post(
      `/api/v1/challenge/complete-challenge`,
      body,
      config
    );
    dispatch({
      type: COMPLETE_CHALLENGE_STATUS,
      payload: res.data.data,
    });
  } catch (error) {
    console.log(error);
  }
};

// challenge  active deactivate status

export const challengeActiveInActive = ({ token, challengeId, status }) => async (
  dispatch
) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  const body = JSON.stringify({ challengeId, status });
  try {
    const res = await API.patch(
      `/api/v1/challenge/change-status`,
      body,
      config
    );
    dispatch(getChallengeDetail({ token, challengeId }))
    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        title: `success`,
        message: res.data.message,
        severity: "success",
      },
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        title: `Failed`,
        message:
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : `change status failed`,
        severity: "error",
      },
    });
  }
};

//delete challenge

export const deleteChallenge = ({ token, id }) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const res = await API.delete(
      `/api/v1/challenge/delete-challenge/${id}`,
      config
    );

    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        title: `success`,
        message: res.data.message,
        severity: "success",
      },
    });
    return dispatch(getAllChallenges(token));
  } catch (error) {
    console.log(error);
    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        title: `Failed`,
        message:
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : `failed`,
        severity: "error",
      },
    });
  }
};

export const joinChallenges = ({ token, data }) => async (dispatch) => {
  // set header
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const body = JSON.stringify({ ...data });

    const res = await API.post(
      `/api/v1/challenge/join-sessionOrChallenge`,
      body,
      config
    );

    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        title: `success`,
        message: res.data.message,
        severity: "success",
      },
    });
    if (data?.type === 'Challenge')
      dispatch(getChallengeDetail({ token, challengeId: data?.challengeOrSessionId }));

    return { success: true };
  } catch (error) {

    if (error?.response?.data?.data?.type && error?.response?.data?.data?.type.toLowerCase() === 'paid') {
      return error?.response?.data?.data
    } else {
      dispatch({
        type: SHOW_MESSAGE,
        payload: {
          title: `Failed`,
          message:
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : ` failed`,
          severity: "error",
        },
      });
    }

  }
}



// add more friends in challenge
export const addFriendsInChallenge = ({ token, data, userid }) => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      'Authorization': `Bearer ${token}`
    },
  }
  const body = JSON.stringify({ ...data })
  try {
    const res = await API.post(`/api/v1/challenge/invite-users`, body, config);
    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        title: `success`,
        message: res.data.message,
        severity: "success"
      }
    });

    if (data?.type === 'Session') {
      return dispatch(getSessionDetails({ token, id: data?.challengeOrSessionId }))

    }
    if (data?.type === 'Challenge') {
      return dispatch(getChallengeDetail({ token, challengeId: data?.challengeOrSessionId }))
    }


  } catch (error) {
    console.log(error);
    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        title: `Failed`,
        message:
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : `invite friend failed`,
        severity: "error",
      },
    });
  }
}
