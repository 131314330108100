import React, { useEffect, useState } from "react";
import { useTheme } from "@material-ui/core/styles";
import {
  useMediaQuery,
  Grid,
  Stepper,
  Step,
  StepLabel,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import AppBar from "../../components/AppBar";
import PropTypes from "prop-types";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Check from "@material-ui/icons/Check";
import InviteAndShare from "../../containers/Common/CoursesContainer/InviteAndShare";
import DescriptionFormContainer from "../../containers/Common/CoursesContainer/DescriptionFormContainer";
import EpisodesAddContainer from "../../containers/Common/CoursesContainer/EpisodesAddContainer";
import PlanContainer from "../../containers/Common/CoursesContainer/PlanContainer";
import StepConnector from "@material-ui/core/StepConnector";
import { useHistory, useParams } from "react-router-dom";
import CourseUpload from "../../containers/Common/CoursesContainer/CourseUpload";
import {
  getAllCategoriesList,
  getAllPlans,
  addCourse,
  updateCourse,
  getCourseDetails,
} from "../../actions/courses";
import { useDispatch, useSelector } from "react-redux";
import AddLinkAccountDialog from "../../components/Common/AddLinkAccountDialog";
import CompleteYourProfile from "../../components/Common/CompleteYourProfile";
import { useIntl } from "react-intl";
const QontoConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  active: {
    "& $line": {
      borderColor: "#C4C4C4",
    },
    "& $alternativeLabel": {
      color: "#FBAE02",
    },
  },
  completed: {
    "& $line": {
      borderColor: "#C4C4C4",
    },
  },
  line: {
    borderColor: "#eaeaf0",
    borderTopWidth: 3,
    borderRadius: 1,
  },
})(StepConnector);

const useQontoStepIconStyles = makeStyles({
  root: {
    color: "#eaeaf0",
    display: "flex",
    height: 22,
    alignItems: "center",
  },
  active: {
    color: "#FBAE02",
    "& > div": {
      width: 28,
      height: 28,
      backgroundColor: "#FBAE02",
    },
  },
  circle: {
    width: 14,
    height: 14,
    border: `4px solid currentColor`,
    boxSizing: "border-box",
    borderRadius: "50%",
  },
  completed: {
    backgroundColor: "#3C4A6B",
    color: "#fff",
    borderRadius: "50%",
    zIndex: 1,
    fontSize: 18,
  },
  activeTypography: {
    color: "#FBAE02",
    cursor: "pointer",
  },

  completedTypography: {
    color: "#3C4A6B",
    cursor: "pointer",
  },

  inProgressTypography: {
    color: "#C4C4C4",
    cursor: "pointer",
  },
  link: {
    color: "#FBAE02",
    textDecoration: "none",
    "&:hover": {
      color: "#FBAE02",
      textDecoration: "none",
    },
  },
});

function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? (
        <Check className={classes.completed} />
      ) : (
        <div className={classes.circle} />
      )}
    </div>
  );
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
};

const AddCourse = () => {
  const intl = useIntl();
  const theme = useTheme();
  const classes = useQontoStepIconStyles();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  let coursesReducerState = useSelector((state) => state.courses);
  const siteConfig = useSelector((state) => state.setting?.siteConfig);
  let history = useHistory();
  let { id } = useParams();
  const [activeStep, setActiveStep] = React.useState(null);

  const [courseData, setCourseData] = useState();
  const [loader, setLoader] = useState(false);
  const steps = [
    intl.formatMessage({ id: "description" }),
    intl.formatMessage({ id: "episode" }),
    intl.formatMessage({ id: "package" }),
    "Preview",
    "Invite & Share",
  ];
  let authReducerState = useSelector((state) => state.auth);

  let dispatch = useDispatch();
  const handleNextWithoutUpdate = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleNext = (dataCourse) => {
    switch (activeStep) {
      case 0:
        dataCourse.courseData.courseId
          ? dispatch(
              updateCourse({ token: authReducerState.token, ...dataCourse })
            )
          : dispatch(
              addCourse({ token: authReducerState.token, ...dataCourse })
            );

        // setActiveStep((prevActiveStep) => prevActiveStep + 1);

        break;
      case 1:
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        break;
      case 2:
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        break;
      case 3:
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        break;
      case 4:
        history.goBack();
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (
      id &&
      coursesReducerState &&
      coursesReducerState?.courseData &&
      coursesReducerState?.courseData?.userId !== authReducerState?.user?.id
    ) {
      dispatch({ type: "UNSET_COURSE" });
    } else if (coursesReducerState.courseData) {
      setCourseData((preState) => coursesReducerState.courseData);
    }
    if (activeStep === null) {
      setActiveStep(0);
    }
  }, [coursesReducerState]);

  const handleBack = (dataCourse) => {
    if (activeStep > 0) {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const goPreviousStep = (e, ind) => {
    e.preventDefault();
    if (ind < activeStep) {
      setActiveStep(ind);
    }
  };

  const getCourseData = async () => {
    setLoader(true);
    const courseId = id.split("-");
    const res = await dispatch(
      getCourseDetails({
        courseId: courseId[courseId.length - 1],
        token: authReducerState.token,
      })
    );
    if (res) {
      setLoader(false);
    }
  };

  useEffect(() => {
    dispatch(getAllCategoriesList({ token: authReducerState.token }));
    dispatch(getAllPlans({ token: authReducerState.token }));
    if (id) {
      getCourseData();
    }
    return () => {
      dispatch({ type: "UNSET_COURSE" });
    };
  }, []);

  useEffect(() => {
    if (coursesReducerState.add_success) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      dispatch({ type: "ADD_SUCCESSFUL", payload: false });
    }
  }, [coursesReducerState.add_success]);

  return (
    <>
      {siteConfig?.mode === "production" ? (
        <AddLinkAccountDialog type="course" />
      ) : (
        <CompleteYourProfile />
      )}
      <Grid container style={{ marginBottom: isMobile && "60px" }}>
        {isMobile && !isDesktop && (
          <AppBar
            appBarConfig={{
              show: true,
              text:
                activeStep === 0
                  ? id
                    ? intl.formatMessage({ id: "editCourse" })
                    : intl.formatMessage({ id: "newCourse" })
                  : activeStep === 1
                  ? intl.formatMessage({ id: "episodes" })
                  : activeStep === 2
                  ? intl.formatMessage({ id: "packages" })
                  : activeStep === 3
                  ? intl.formatMessage({ id: "uploadPreview" })
                  : "Invite & share",
              close: true,
              action: "/profile",
              backAction: activeStep === 0 ? false : handleBack,
            }}
          />
        )}

        {!id ? (
          <Grid
            style={{ paddingLeft: isMobile && !isDesktop ? "0px" : "2rem" }}
            item
            xs={12}
            md={10}
            lg={10}
          >
            {isDesktop && (
              <AppBar
                appBarConfig={{
                  show: true,
                  text: id
                    ? intl.formatMessage({ id: "editCourse" })
                    : intl.formatMessage({ id: "newCourse" }),
                  backAction: activeStep === 0 ? false : handleBack,
                }}
              />
            )}
            <Grid item lg={10}>
              {isDesktop && (
                <Stepper
                  alternativeLabel
                  activeStep={activeStep}
                  connector={<QontoConnector />}
                >
                  {steps.map((label, index) => (
                    <Step key={label}>
                      <StepLabel StepIconComponent={QontoStepIcon}>
                        <Typography
                          className={
                            activeStep === index
                              ? classes.activeTypography
                              : activeStep > index
                              ? classes.completedTypography
                              : classes.inProgressTypography
                          }
                          onClick={(e) => goPreviousStep(e, index)}
                        >
                          {" "}
                          {label}
                        </Typography>
                      </StepLabel>
                    </Step>
                  ))}
                </Stepper>
              )}
            </Grid>
            {activeStep === 0 && (
              <DescriptionFormContainer
                courseData={courseData}
                handleNext={handleNext}
                handleNextWithoutUpdate={handleNextWithoutUpdate}
              />
            )}
            {activeStep === 1 && (
              <EpisodesAddContainer
                courseData={courseData}
                handleNext={handleNext}
              />
            )}
            {activeStep === 2 && (
              <PlanContainer courseData={courseData} handleNext={handleNext} />
            )}
            {activeStep === 4 && (
              <InviteAndShare
                invite={true}
                share={true}
                course={courseData}
                handleNext={handleNext}
              />
            )}
            {activeStep === 3 && (
              <CourseUpload handleNext={handleNext} course={courseData} />
            )}
          </Grid>
        ) : (
          <Grid
            style={{ paddingLeft: isMobile && !isDesktop ? "0px" : "2rem" }}
            item
            xs={12}
            md={11}
            lg={11}
          >
            {isDesktop && (
              <AppBar
                appBarConfig={{
                  show: true,
                  text: id
                    ? intl.formatMessage({ id: "editCourse" })
                    : intl.formatMessage({ id: "newCourse" }),
                  backAction: activeStep === 0 ? false : handleBack,
                }}
              />
            )}
            {courseData ? (
              <>
                <Grid item lg={11}>
                  {isDesktop && (
                    <Stepper
                      alternativeLabel
                      activeStep={activeStep}
                      connector={<QontoConnector />}
                    >
                      {steps.map((label, index) => (
                        <Step key={label}>
                          <StepLabel StepIconComponent={QontoStepIcon}>
                            <Typography
                              className={
                                activeStep === index
                                  ? classes.activeTypography
                                  : activeStep > index
                                  ? classes.completedTypography
                                  : classes.inProgressTypography
                              }
                              onClick={(e) => goPreviousStep(e, index)}
                            >
                              {" "}
                              {label}
                            </Typography>
                          </StepLabel>
                        </Step>
                      ))}
                    </Stepper>
                  )}
                </Grid>
                <Grid item xs={12} style={{ paddingLeft: !isMobile && "20px" }}>
                  {activeStep === 0 && (
                    <DescriptionFormContainer
                      courseData={courseData}
                      handleNext={handleNext}
                      handleNextWithoutUpdate={handleNextWithoutUpdate}
                    />
                  )}
                  {activeStep === 1 && (
                    <EpisodesAddContainer
                      courseData={courseData}
                      handleNext={handleNext}
                    />
                  )}
                  {activeStep === 2 && (
                    <PlanContainer
                      courseData={courseData}
                      handleNext={handleNext}
                    />
                  )}
                  {activeStep === 4 && (
                    <InviteAndShare
                      invite={true}
                      share={true}
                      course={courseData}
                      handleNext={handleNext}
                    />
                  )}
                  {activeStep === 3 && (
                    <CourseUpload handleNext={handleNext} course={courseData} />
                  )}
                </Grid>
              </>
            ) : loader ? (
              <Grid container justify="center">
                <CircularProgress size={30} style={{ color: "#FBAE02" }} />
              </Grid>
            ) : (
              <Grid container justify="center">
                <Typography color="textSecondary">
                  {intl.formatMessage({ id: "noCourseFound" })}
                </Typography>
              </Grid>
            )}
          </Grid>
        )}
      </Grid>
    </>
  );
};
export default AddCourse;
