import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  useMediaQuery,
  TextField,
  Button,
  IconButton
} from "@material-ui/core";
import { Share } from "@material-ui/icons";
import { makeStyles, useTheme } from "@material-ui/styles";
import { useIntl } from "react-intl";
import { useLocation } from "react-router-dom";
import Fb from ".././../../assets/Icons/fb.svg";
import Gmail from ".././../../assets/Icons/gmail.svg";
import Whatsapp from ".././../../assets/Icons/whatsapp.svg";
import LinkedIn from ".././../../assets/Icons/linkedin1.svg";
import Twitter from ".././../../assets/Icons/twitter1.svg";
import PinterestIcon from ".././../../assets/Icons/PinterestIcon.svg";
import { Helmet } from "react-helmet";

import Telegram from ".././../../assets/Icons/telegram1.svg";

import copy from "copy-to-clipboard";
import {
  EmailShareButton,
  FacebookShareButton,
  WhatsappShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  PinterestShareButton
} from "react-share";

function ShareToSocialMedia(props) {
  console.log(props);

  const intl = useIntl();
  const classes = useStyles();
  const location = useLocation();
  const [copySuccess, setCopySuccess] = useState("");

  function getImage(url) {
    return (
      <div style={{ height: "6em", width: "6em" }}>
        <img src={url} alt="" style={{ height: "4.5em", width: "4.5em" }} />
      </div>
    );
  }
  function getImage1(url) {
    return (
      <div style={{ height: "6em", width: "6em" }}>
        <img src={url} alt="" style={{ height: "3.5em", width: "3.5em" }} />
      </div>
    );
  }
  function getImage2(url) {
    return (
      <div style={{ height: "6em", width: "6em" }}>
        <img src={url} alt="" style={{ height: "5em", width: "5em" }} />
      </div>
    );
  }
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const copyToClipboard = () => {
    copy(props?.url);
    setCopySuccess("Link copied");
  };
  useEffect(() => {
    nativeShare();
  }, []);

  const isIos = () => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return /iphone|ipad|ipod/.test(userAgent);
  };

  const nativeShare = () => {
    navigator.share &&
      !isIos() &&
      navigator
        .share({
          url: props?.url
        })
        .then(() => console.log("Successful share"))
        .catch(error => console.log("Error sharing", error));
  };

  return (
    <>
      {navigator.share && !isIos() ? (
        <Grid container>
          <Grid item xs={12}>
            <Typography className={classes.header}>{props?.header}</Typography>
            <Grid container justify="center">
              <Button
                style={{
                  backgroundColor: "#FAAA02",
                  color: "#fff",
                  marginLeft: "20px"
                }}
                onClick={nativeShare}
              >
                Social share
                <Share
                  size={isMobile ? 30 : 40}
                  style={{ marginLeft: ".25rem" }}
                />
              </Button>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <>
          <Grid container>
            <Grid item xs={12}>
              <Helmet>
                <meta
                  property="og:title"
                  content="European Travel Destinations"
                />
                <meta
                  property="og:description"
                  content="Offering tour packages for individuals or groups."
                />
                <meta
                  property="og:image"
                  content={`${window.location.origin}/${
                    props?.imageUrl
                      ? props?.imageUrl
                      : location?.state?.imageUrl
                  }`}
                />
                <meta property="og:url" content={props?.url} />
                <meta
                  property="twitter:image"
                  alt="image"
                  content={`${window.location.origin}/${
                    props?.imageUrl
                      ? props?.imageUrl
                      : location?.state?.imageUrl
                  }`}
                />
                <meta name="twitter:card" content="summary_large_image"></meta>
              </Helmet>
            </Grid>
            <Typography className={classes.header}>{props?.header}</Typography>
          </Grid>
          <Grid container justify="space-between">
            <Grid
              container
              item
              xs={12}
              
              style={{ textAlign: isMobile ? "center" : "center" }}
            >
              <Grid item xs={4} md={4} lg={4} sm={4} xl={4}>
                <FacebookShareButton
                  hashtags={"props?.tags"}
                  style={{ outline: "none" }}
                  url={props?.url}
                  description="Hey there,  Check this out, I think you will love it:"
                  quote="Hey there,  Check this out, I think you will love it:"
                >
                  {getImage(Fb)}
                </FacebookShareButton>
              </Grid>

              <Grid item xs={4} md={4} lg={4} sm={4} xl={4}>
                <a
                  href={`https://api.whatsapp.com/send?text=${
                    props?.title
                      ? props?.title + ":\n " + props?.url
                      : props?.url
                  }`}
                  target="_blank"
                >
                  <WhatsappShareButton style={{ outline: "none" }}>
                    {getImage(Whatsapp)}
                  </WhatsappShareButton>
                </a>
              </Grid>
              <Grid item xs={4} md={4} lg={4} sm={4} xl={4}>
                <EmailShareButton
                  style={{ outline: "none" }}
                  body={
                    "Hey there,  Check this out, I think you will love it:\n"
                  }
                  subject="Invitation"
                  separator={" "}
                  url={props?.url}
                >
                  {getImage(Gmail)}
                </EmailShareButton>
              </Grid>
              <Grid item xs={4} md={4} lg={4} sm={4} xl={4}>
                <TelegramShareButton
                  style={{ outline: "none" }}
                  url={props?.url}
                  title="Hey there,  Check this out, I think you will love it:"
                >
                  {getImage1(Telegram)}
                </TelegramShareButton>
              </Grid>

              <Grid item xs={4} md={4} lg={4} sm={4} xl={4}>
                <LinkedinShareButton
                  style={{ outline: "none" }}
                  title={props?.title}
                  url={props?.url}
                  summary={`Hey there,  Check this out, I think you will love it:
                  ${props?.sumaryText}`}
                  source={"http:/omid.live.com"}
                  mini={true}
                  title="Hey there,  Check this out, I think you will love it:"
                >
                  {getImage1(LinkedIn)}
                </LinkedinShareButton>
              </Grid>
              <Grid item xs={4} md={4} lg={4} sm={4} xl={4}>
                <TwitterShareButton
                  style={{ outline: "none" }}
                  hashtags={props?.tags}
                  subject={props?.sumaryText}
                  body={"Hey there,  Check this out, I think you will love it:"}
                  url={props?.url}
                  title="Hey there,  Check this out, I think you will love it:"
                >
                  {getImage1(Twitter)}
                </TwitterShareButton>
              </Grid>
              <Grid item xs={4} md={4} lg={4} sm={4} xl={4}>
                <PinterestShareButton
                  style={{ outline: "none" }}
                  url={props?.url}
                  media={`${window.location.origin}/${
                    props?.imageUrl
                      ? props?.imageUrl
                      : location?.state?.imageUrl
                  }`}
                  description="Hey there,  Check this out, I think you will love it:\n"
                  title="Hey there,  Check this out, I think you will love it:\n"
                >
                  {getImage2(PinterestIcon)}
                </PinterestShareButton>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
      <Grid item xs={12} style={{ margin: "15px" }}>
        <TextField
          classes={{
            root: classes.rootTextField
          }}
          disabled={true}
          type="Search"
          variant="outlined"
          value={props?.url}
          style={{ width: "100%" }}
          placeholder={intl.formatMessage({ id: "search" })}
          name="search"
          InputProps={{
            endAdornment: (
              <Button
                onClick={() => copyToClipboard()}
                style={{
                  cursor: "pointer",
                  background: "#FAAA02",
                  borderRadius: "0px 39px 39px 0px",
                  marginRight: "-10px",
                  height: "58px",
                  width: "100px",
                  color: "#fff"
                }}
              >
                Copy
              </Button>
            )
          }}
        />

        <Typography className={classes.linkTextStyle}>{copySuccess}</Typography>
      </Grid>
    </>
  );
}

export default ShareToSocialMedia;

const useStyles = makeStyles(theme => ({
  appBar: {
    color: "#000",
    flexDirection: "row",
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    right: "unset",
    position: "relative",
    marginLeft: "15px",
    boxShadow: "none"
  },
  appBarMobile: {
    background: "#FFFFFF",
    color: "#000",
    flexDirection: "row",
    padding: theme.spacing(1.5),
    justifyContent: "space-between",
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    right: "unset",
    position: "sticky"
  },
  appBarText: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: "900",
    fontSize: "20px",
    lineHeight: "23px"
  },
  appBarTextMobile: {
    fontFamily: theme.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: "900",
    fontSize: "26px",
    lineHeight: "35px"
  },
  backButton: {
    background: "transparent",
    color: "#000",
    padding: "0px"
  },
  linkTextStyle: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "16px",
    color: "#8B8B8B",
    display: "flex",
    justifyContent: "center",
    height: "40px"
  },
  header: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "20px",
    lineHeight: "23px",
    color: "#C2C2C2",
    marginTop: "20px",
    marginBottom: "25px",
    marginLeft: "20px"
  },
  rootTextField: {
    "& .MuiInputBase-root": {
      backgroundColor: "#E5E5E5",
      fontFamily: theme.fontFamily.Roboto,
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "18px",
      lineHeight: "21px",
      padding: "10px",
      marginTop: "35px",
      height: "58px",
      width: "100%",
      borderRadius: "36px"
    },
    "&.MuiBadge-colorPrimary": {
      backgroundColor: "#E5E5E5"
    },
    "&.MuiInput-underline:before": {
      border: `0px solid #E5E5E5`
    },
    "& .MuiInput-underline:after": {
      border: `0px solid #E5E5E5`
    },
    "&.MuiInput-underline:hover:not(.Mui-disabled)::before": {
      border: `0px solid #E5E5E5`
    }
  }
}));
