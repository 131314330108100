// types
import {
  GET_FRIENDS_SESSION,
  ADD_SESSION_LOCAL,
  SET_ALL_SESSIONS,
  SELECTED_SESSION,
  DELETE_SESSION,
  SESSION_LOADER,
  GET_SESSION_DETAIL,
  JOIN_SESSION,
  CLEAN_SESSION,
  GET_ALL_CREATED_JOINED_SESSIONS,
  NEW_CREATED_SESSION,
  GET_RECENT_SESSIONS,
  SESSION_STATS,
  SESSION_PARTICIPANTS
} from "../actions/types";

// initial state
const initialState = {
  currentSession: {},
  sessionsList: [],
  allCreatedAndJoinedSessionsList: [],
  friendSessionList: [],
  selectedSession: {},
  showLoader: false,
  joined: false,
  isAdded: false,
  newCreatedSession:{},
  recentSessions:[],
  sessionStats:{}
};

// handle actions
export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case CLEAN_SESSION:
      return {
        ...state,
        selectedSession: {},
        joined: false,
        isAdded: false,
        showLoader: false,
      };
    case ADD_SESSION_LOCAL:
      return {
        ...state,
        currentSession: payload,
      };
    case SESSION_STATS:
      return {
        ...state,
        sessionStats: payload,
      };
    case GET_RECENT_SESSIONS:
      return {
        ...state,
        recentSessions: payload,
      };
    case NEW_CREATED_SESSION:
      return {
        ...state,
        newCreatedSession: payload,
      };
    case DELETE_SESSION: {
      let session_list = [...state.sessionsList];
      let ind = session_list.findIndex((n) => n.id === action.payload);
      session_list.splice(ind, 1);

      return {
        ...state,
        sessionsList: [...session_list],
      };
    }
    case SET_ALL_SESSIONS:
      return {
        ...state,
        sessionsList: payload,
      };
    case GET_ALL_CREATED_JOINED_SESSIONS:
      return {
        ...state,
        allCreatedAndJoinedSessionsList: payload,
      };
    case GET_FRIENDS_SESSION:
      return {
        ...state,
        friendSessionList: payload,
      };
    case SELECTED_SESSION: {
      return {
        ...state,
        selectedSession: payload.data,
      };
    }
    case GET_SESSION_DETAIL: {
      return {
        ...state,
        selectedSession: payload,
      };
    }
    case SESSION_PARTICIPANTS: {
      return {
        ...state,
        selectedSession: {...state.selectedSession, sessionParticipants: payload},
      };
    }
    case SESSION_LOADER: {
      return {
        ...state,
        showLoader: payload.showLoader,
        isAdded: payload.isAdded,
      };
    }
    case JOIN_SESSION: {
      return {
        ...state,
        joined: payload,
      };
    }
    default:
      return state;
  }
}
