import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Popper from '@material-ui/core/Popper';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import Add from '@material-ui/icons/Add';
import Icon from '@material-ui/core/Icon';
import FiberManualRecord from '@material-ui/icons/FiberManualRecord';
import CheckCircle from '@material-ui/icons/CheckCircle';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Close from '@material-ui/icons/Close';
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Button, Card, CardContent, Divider, Grid, TextField } from '@material-ui/core';
import FileCard from './FileCard'
import moment from 'moment';

function ScheduleA(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [open, setOpen] = React.useState(false);
    const [placement, setPlacement] = React.useState();
    const [publishNow, setPublishNow] = React.useState('');
    const [timeZone, setTimeZone] = React.useState('Asia/Karachi');
    const [date, setDate] = React.useState(moment().format('YYYY-MM-DD'))
    const [time, setTime] = React.useState('10:00')
    const classes = useStyles();
    const timeZones = moment.tz.names().filter(tz => tz.match(/^(((Africa|America|Antarctica|Asia|Australia|Europe|Arctic|Atlantic|Indian|Pacific)\/.+)|(UTC))$/))

    const handleClick = (newPlacement) => (event) => {
        setAnchorEl(event.currentTarget);
        setOpen((prev) => placement !== newPlacement || !prev);
        setPlacement(newPlacement);
    };


    const handlePublishType = (event, publishType) => {
        event.preventDefault()
        setPublishNow(publishType);
        
    };

 

    const changeHandler = (e, value) => {
        if (e.target.name === 'date') {
            setDate(e.target.value)
        } else if (e.target.name === 'time') {
            setTime(e.target.value)
        } else {
            setTimeZone(value)
        }
    }

    const handleCross = () => {
        setPublishNow(true)
        setDate(moment().format('YYYY-MM-DD'))
        setTime('10:00')
        setTimeZone('Asia/Karachi')
        setOpen(false)

    }

    const publishTypeHandler = () => {
        handleCross()
        return props?.publishType(publishNow, date, time, timeZone)
    }

    return (
        <div className={classes.root}>
            <Popper open={open} anchorEl={anchorEl} placement={'right-start'} transition>
                <Card style={{ maxWidth: '300px' }}>

                    <CardContent>
                        <Grid container >
                            <Grid item xs={12} container justify='flex-end'>
                                <Grid item xs={2} md={1}>
                                    <IconButton className={classes.closeIcon} onClick={handleCross}><Close /></IconButton>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} container style={{ height: '50px' }}>

                                <Grid item xs={10} className={classes.centerHorizontal}>
                                    <Typography className={publishNow !== 'open' ? classes.checkedHeading : classes.uncheckedHeading}>
                                        Open
                                </Typography>
                                </Grid>
                                <Grid item xs={2} className={classes.center}>
                                    {
                                        publishNow !== 'open' ? < FiberManualRecord onClick={(e) => handlePublishType(e, 'open')} className={classes.unCheckedIcon} /> : <CheckCircle className={classes.checkedIcon} />
                                    }
                                </Grid>
                            </Grid>

                            <Grid item xs={12}>
                                <Divider />
                            </Grid>

                            <Grid item xs={12} container style={{ height: '50px' }}>
                                <Grid item xs={10} className={classes.centerHorizontal}>
                                    <Typography className={publishNow !== 'schedule' ? classes.checkedHeading : classes.uncheckedHeading}>
                                        Submission
                                </Typography>
                                </Grid>
                                <Grid item xs={2} className={classes.center}>
                                    {
                                        publishNow !== 'schedule' ? < FiberManualRecord onClick={(e) => handlePublishType(e, 'schedule')} className={classes.unCheckedIcon} /> : <CheckCircle className={classes.checkedIcon} />
                                    }
                                </Grid>
                            </Grid>

                            <Grid item xs={12}>
                                <Typography className={classes.checkedHeading} style={{ fontSize: '14px' }}>
                                    Select submission date
                                </Typography>
                            </Grid>


                            <Grid item xs={12} >
                                <TextField
                                    id="date"
                                    variant='outlined'
                                    type="date"
                                    value={date}
                                    format='DD-MM-YYYY'
                                    name='date'
                                    InputProps={{ inputProps: { min: moment().format("YYYY-MM-DD"), } }}
                                    disabled={publishNow !== 'schedule'}
                                    onChange={changeHandler}
                                    classes={{ root: classes.dateAndTime }}
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12} >
                                <TextField
                                    id="time"
                                    variant='outlined'
                                    type="time"
                                    value={time}
                                    name='time'
                                    disabled={publishNow !== 'schedule'}
                                    onChange={changeHandler}
                                    defaultValue="07:30"
                                    classes={{ root: classes.dateAndTime }}
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}

                                />


                            </Grid>
                            <Grid item xs={12}>
                                <Autocomplete
                                    id="combo-box-demo"
                                    options={timeZones}
                                    fullWidth
                                    disabled={publishNow !== 'schedule'}
                                    onChange={changeHandler}
                                    name='timeZone'
                                    value={timeZone}
                                    getOptionLabel={(zone) => `(UTC ${moment.tz(zone).format('Z')})  ${zone}`}
                                    renderInput={(params) => <TextField classes={{ root: classes.dateAndTime }} {...params} placeholder="Select time zone" variant="outlined" />}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Button className={classes.publishButton} disabled={(publishNow === 'schedule' && (!date || !time || !timeZone)) || (!publishNow)} onClick={publishTypeHandler}>Set
                                </Button>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>

            </Popper>


            <Button className={classes.publishButton} onClick={handleClick('right-start')} style={{ background: '#F1F1F1', color: '#FBAE02' }} endIcon={<Icon><ExpandMore /></Icon>}>Submission</Button>


        </div>
    );
}


export default function AddAssignment(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [open, setOpen] = React.useState(false);
    const [assignmentLoader, setAssignmentLoader] = React.useState(false);
    const [publishType, setPublishType] = React.useState('');
    const [fileName, setFileName] = React.useState('');
    const [date, setDate] = React.useState('');
    const [time, setTime] = React.useState('');
    const [file, setFile] = React.useState('');
    const [placement, setPlacement] = React.useState();
    const [timeZone, setTimeZone] = React.useState('');
    const [error, setError] = React.useState('');

    const classes = useStyles();

    const handleClick = (newPlacement) => (event) => {
        setAnchorEl(event.currentTarget);
        setOpen((prev) => placement !== newPlacement || !prev);
        setPlacement(newPlacement);
    };

    const handleSelectFile = (event) => {
        let localFile = event.target.files && event.target.files[0]
        setError('')
        setFile(localFile);
        setFileName(localFile.name.match(/[^\.]+/)[0]);
    };

    const clickCrossHandler = () => {
        setFile('');
        setFileName('');
        setTimeZone('')
        setDate('')
        setTime('')
        setPublishType('')
    };

    const closeDialog = () => {
        setFile('');
        setFileName('');
        setTimeZone('')
        setDate('')
        setTime('')
        setPublishType('')
        setOpen(false)
    };

    const addAssignmentHandler = () => {
        if (!file) {
            return setError('assignment file is required!')
        } else if (!fileName) {
            return setError('file name is required!')
        } else if (!publishType) {
            return setError('set submission!')
        } else if (!date && publishType === 'schedule') {
            return setError('due date is required!')
        } else if (!time && publishType === 'schedule') {
            return setError('due time is required!')
        } else if (!timeZone && publishType === 'schedule') {
            return setError('timezone is required!')
        } else {
            const fileLocal = file;
            const fileNameLocal = fileName;
            let fileType = /^.+\.([^.]+)$/.exec(fileLocal?.name)[1] || ''

            return props.handleAddAssignment({ file: fileLocal, sectionId: props?.section?.id, size: fileLocal.size, type: fileType, name: fileNameLocal, date: date, timeZone: timeZone, scheduleType: publishType === 'open' ? 'published' : 'scheduled', 
            time,
        
        })
        }

    };


    const publishTypeHandler = (type, date, time, timeZone) => {
        setPublishType(type);
        setDate(date);
        setTime(time);
        setTimeZone(timeZone);
    }

    useEffect(() => {
        if (!assignmentLoader && props?.loader) {
            setAssignmentLoader(true)
        } else if (assignmentLoader && !props?.loader) {
            setAssignmentLoader(false)
            closeDialog()
        }
    }, [props?.loader])

    return (
        <div className={classes.root}>
            <Popper open={open} anchorEl={anchorEl} placement={'right-start'} transition>
                <Card style={{ maxWidth: '300px' }}>
                    <CardContent>
                        <Grid container justify='flex-end'>
                            <Grid item xs={2} md={1}>
                                <IconButton className={classes.closeIcon} onClick={closeDialog}><Close /></IconButton>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography className={classes.heading}>
                                    Upload Assignment
                                </Typography>
                            </Grid>

                            <Grid item xs={12} >
                                <TextField
                                    id="datetime-local"
                                    type="text"
                                    placeholder='File Name'
                                    className={classes.textField}
                                    variant='outlined'
                                    fullWidth
                                    classes={{ root: classes.dateAndTime }}
                                    value={fileName}
                                    onChange={(e) => { setError(''); setFileName(e.target.value) }}
                                />
                            </Grid>

                            {file ? <FileCard file={file} type={/^.+\.([^.]+)$/.exec(file?.name)[1] || ''} fileName={fileName} clickCrossHandler={clickCrossHandler} /> : <Grid item xs={12} className={classes.browsButtonContainer}>

                                <Button className={classes.browsButton}
                                    variant="contained"
                                    component="label"
                                >
                                    Upload File
                                       <input
                                        type="file"
                                        hidden
                                        onChange={handleSelectFile}
                                        accept=".docx, .doc"
                                    />
                                </Button>
                            </Grid>}




                            {/* <Grid item xs={12} >
                                <TextField
                                    id="datetime-local"
                                    type="datetime-local"
                                    value={date}
                                    className={classes.textField}
                                    variant='outlined'
                                    fullWidth
                                    classes={{ root: classes.dateAndTime }}
                                    onChange={(e) => { setError(''); setDate(e.target.value) }}
                                    InputLabelProps={{
                                        shrink: true,
                                      }}
                                    
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Autocomplete
                                    id="combo-box-demo"
                                    options={timeZones}
                                    fullWidth

                                    onChange={(e, value) => {
                                        setError('')
                                        setTimeZone(value)
                                    }}
                                    value={timeZone || null}
                                    getOptionLabel={(zone) => `(UTC ${moment.tz(zone).format('Z')})  ${zone}`}
                                    renderInput={(params) => <TextField classes={{ root: classes.dateAndTime }} {...params} placeholder="Select time zone" variant="outlined" />}
                                />
                            </Grid> */}

                            <Grid item xs={12} container >
                                <Grid item xs={12}>
                                    <ScheduleA publishType={publishTypeHandler} />
                                </Grid>
                                <Grid item xs={12} >
                                    {publishType === 'schedule' && <Typography className={classes.scheduleText}>Submission scheduled on {moment(date).format('DD MMM')} at {time}</Typography>}
                                </Grid> 

                                <Grid item xs={12}>
                                    {assignmentLoader ? <Button className={classes.publishButton}> <CircularProgress size={20} style={{ color: '#fff' }} /> </Button> : <Button className={classes.publishButton} onClick={addAssignmentHandler}>{'Add'}
                                    </Button>}
                                    {/* <Button className={classes.publishButton} onClick={addAssignmentHandler}>{props?.loader ? <CircularProgress size={12} style={{ color: '#fff' }} /> : 'Add'}
                                    </Button> */}
                                </Grid>

                                <Grid item xs={12}>
                                    <Typography className={classes.error}>
                                        {error}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>

            </Popper>



            <IconButton
                onClick={handleClick('right-start')}
                className={classes.button}>
                <Add />
            </IconButton>

        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        display: 'inline'

    },
    typography: {
        padding: theme.spacing(2),
    },
    button: {
        background: '#FBAE02 !important',
        boxShadow: '0px 2px 2px rgba(216, 118, 0, 0.18)',
        color: "#FFF",
        height: "40px",
        width: '40px',
    },
    heading: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '20px',
        lineHeight: '100%',
        color: ' #666666',
        paddingBottom: '30px'
    },
    closeIcon: {
        backgroundColor: "#F1F1F4",
        color: '#E54D40',
        height: '25px',
        width: '25px',
        '&:hover': {
            backgroundColor: "#F1F1F4",
            color: '#E54D40',
        }

    },
    browsButtonContainer: {
        background: '#F1F1F1',
        borderRadius: '11px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '80px',
        minWidth: '120px'
    },
    browsButton: {
        color: '#fff',
        background: '#FBAE02',
        borderRadius: '20px',
        height: '30px',
        padding: '0px 50px',
        minWidth: '30px',
        '&:hover': {
            color: '#fff',
            background: '#FBAE02',
        }
    },
    publishButton: {
        color: '#fff',
        background: '#FBAE02',
        borderRadius: '11px',
        width: '100%',
        padding: '12px 0px',
        marginTop: '30px',
        '&:hover': {
            color: '#fff',
            background: '#FBAE02',
        }
    },
    profile_image: {
        "& input[type='file']": {
            display: 'none',
        }
    },
    dateAndTime: {
        marginTop: '10px',
        marginBottom: '10px',
        color: '#FBAE02',
        border: '1px solid #E5E5E5 !important',
        borderRadius: '11px',
        '& label.Mui-focused': {
            color: 'white',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#FBAE02',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#666666',
            },
            '&:hover fieldset': {
                borderColor: '#666666',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#FBAE02',
            },
        },
    },
    checkedHeading: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '25px',
        color: '#C4C4C4',
    },
    uncheckedHeading: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '25px',
        color: '#666666',
    },
    checkedIcon: {
        color: '#2A4A67',
        justifyContent: 'center',
        cursor: 'pointer'

    },
    unCheckedIcon: {
        color: '#D7D7D7',
        fontSize: '30px',
        justifyContent: 'center',
        cursor: 'pointer'
    },
    center: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    centerHorizontal: {
        display: 'flex',

        alignItems: 'center'
    },
    error: {
        color: '#E54D40',
        fontSize: '12px',
    },
    scheduleText: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '16px',
        lineHeight: '22px',
        color: '#666666',
        paddingTop: '10px'
    }


}));