import  { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Grid from "@material-ui/core/Grid";
import { Tooltip, Chip } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { useIntl } from "react-intl";
import CreateIcon from "@material-ui/icons/Create";
import DeleteIcon from "@material-ui/icons/Delete";
import { useSelector, useDispatch } from "react-redux";
import { Modal, Button } from "@material-ui/core";
import { deleteSession } from "../../../../actions/Session";
import { useHistory } from "react-router-dom";
import Truncate from "react-truncate";
import moment from "moment";

const useStyles = makeStyles(theme => ({
  paper: {
    position: "absolute",
    height: 330,
    maxWidth: 315,
    minWidth: 300,
    justifyContent: "center",
    backgroundColor: theme.palette.background.paper,
    outline: "none"
  },

  modelBodyText: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "17px",
    lineHeight: "27px",
    textAlign: "center",
    letterSpacing: "0.02em",
    color: "#192247",
    maxWidth: 310,
    overflow: "auto"
  },

  modelBtn: {
    height: "73px",
    width: "50%",
    fontFamily: theme.fontFamily.Roboto,
    cursor: "pointer",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "21px",
    color: theme.colors.white
  },

  modalBtnGrid: {
    padding: "0px",
    alignSelf: "flex-end",
    color: "#fff",
    cursor: "pointer",
    background: `linear-gradient(#09A9BB 1%,#09A9BB 100%)`,
    height: "73px",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    display: "flex"
  },
  msgBody: {
    maxWidth: 315,
    minWidth: 300,
    minHeight: "240px",
    alignItems: "center",
    display: "flex"
  },
  root: {
    width: '230px',
    cursor: "pointer",
    maxHeight: "260px",
    borderRadius: "11px",
    boxShadow: " 0px 4px 4px rgba(0, 0, 0, 0.05)"
  },
  media: {
    height: 0,
    paddingTop: "56.25%",
    position: "relative",
    BorderRadiusTopRight: "11px",
    BorderRadiusTopLeft: "11px",
    background: "#C4C4C4",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover"
  },
  title: {
    fontFamily: theme.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: "900",
    fontSize: "14px",
    lineHeight: "19px",
    textAlign: "center",
    color: "#000000",
    margin: "auto",
    paddingBottom: "0.35em"
  },
  subTitle: {
    fontFamily: theme.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: "900",
    fontSize: "10px",
    lineHeight: "14px",
    textAlign: "center",
    color: "#C2C2C2"
  },
  nameBold: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "18px",
    lineHeight: "21px",
    letterSpacing: " 0.05em",
    color: "#3C4A6B",
    marginLeft: "20px",
    marginTop: "10px"
  }
}));

function SessionCard(props) {
  const classes = useStyles();
  const history = useHistory();
  const intl = useIntl();
  const uId = useSelector(state => state.auth.user.id);
  let dispatch = useDispatch();
  const authReducerState = useSelector(state => state.auth);
  const [deliverStatus, setDeliverStatus] = useState('');

  const [modalStyle] = useState(getModalStyle);
  const [openModel, setOpenModel] = useState(false);
  function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
      borderColor: "#fff",
      borderRadius: "4px",
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top - 4}%, -${left}%)`
    };
  }
  const handleCloseModal = e => {
    e.stopPropagation();
    setOpenModel(false);
  };
  const handleOpenModal = (e) => {
    e.stopPropagation();

    setOpenModel(true);
  };
  const confirmDelete = (e) => {
    handleCloseModal(e);
    dispatch(
      deleteSession({
        token: authReducerState?.token,
        id: props.session.id,
      })
    );
    e.stopPropagation();
  };
  const modalBody = (
    <Grid
      container
      direction="row"
      spacing={3}
      style={modalStyle}
      className={classes.paper}
    >
      <Grid item xs={12} className={classes.msgBody}>
        <Typography className={classes.modelBodyText}>
          {` ${intl.formatMessage({ id: "confirmDelete" })}  ${
            props.session.name
          } ?`}
        </Typography>
      </Grid>
      <Grid container>
        <Grid item xs={6} className={classes.modalBtnGrid}>
          <Button
            onClick={(e) => handleCloseModal(e)}
            className={classes.modelBtn}
          >
            {intl.formatMessage({ id: "cancle" })}
          </Button>
        </Grid>
        <Grid item xs={6} className={classes.modalBtnGrid}>
          <Button
            onClick={(e) => confirmDelete(e)}
            className={classes.modelBtn}
          >
            {intl.formatMessage({ id: "delete" })}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
  const goDetailsPage = (e, id) => {
    e.stopPropagation();
    history.push({ pathname: `/more/session/details/${id}` });
  };
  const goEditPage = (e, id) => {
    e.stopPropagation();
    history.push({ pathname: `/more/session/edit/${id}` });
  };

  useEffect(() => {
    if (
      props?.session?.status === "Active" &&
      props?.session?.sessionDates?.length > 0
    ) {
      if (
        moment().isBefore(
          moment(props?.session?.sessionDates[0]?.dateTime, "x").local()
        )
      ) {
        setDeliverStatus("Scheduled");
      } else if (
        moment().isAfter(
          moment(props?.session?.sessionDates[0]?.endingDateTime, "x").local()
        )
      ) {
        setDeliverStatus("Delivered");
      } else {
        setDeliverStatus("Live");
      }
    } else {
      setDeliverStatus(props?.session?.status);
    }
  }, []);

  return (
    <Card
      className={classes.root}
      onClick={(e) => goDetailsPage(e, props?.session?.id)}
    >
      <Modal
        disableEnforceFocus={true}
        open={openModel}
        onClose={(e) => handleCloseModal(e)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {modalBody}
      </Modal>
      {/* <CardActionArea> */}

      <CardMedia
        className={classes.media}
        image={props?.image}
        style={{ backgroundColor: props?.image && "#fff" }}
      >
        <Grid
          style={{
            position: "absolute",
            top: -3,
            zIndex: 1,
            height: "21px",
          }}
          container
          justify="space-between"
        >
          <Grid item>
            {
              <Chip
                label={deliverStatus}
                style={{
                  color: "#FFF",
                  height: "20px",
                  background: `${
                    deliverStatus === "Live"
                      ? "#39a746"
                      : deliverStatus === "Scheduled"
                      ? "#39a746"
                      : "#fbae02"
                  }`,
                  zIndex: 1,
                }}
              />
            }
          </Grid>

          {uId === props?.session?.userId &&
            moment().isBefore(
              moment(props?.session?.sessionDates[0]?.dateTime, "x").local()
            ) &&
            props.session &&
            props.session.type &&
            (props.session.status === "Active" ||
              props.session.status === "Pending") &&
            props?.edit !== false && (
              <Grid
                item
                style={{
                  zIndex: 1,
                  paddingTop: "2px",
                }}
              >
                <Tooltip title="Delete Session">
                  <DeleteIcon
                    onClick={(e) => handleOpenModal(e)}
                    style={{
                      fontSize: "25px",
                      cursor: "pointer",
                      color: "#F28705",
                    }}
                  />
                </Tooltip>

                <Tooltip title="Edit Session">
                  <CreateIcon
                    onClick={(e) => goEditPage(e, props?.session?.id)}
                    style={{
                      fontSize: "25px",
                      cursor: "pointer",
                      color: "#F28705",
                    }}
                  />
                </Tooltip>
              </Grid>
            )}
        </Grid>
        {props?.category && (
          <Grid container direction="row">
            <Grid item xs={5} md={6} />
            <Grid item xs={7} md={6} style={{ textAlign: "right" }}>
              {" "}
              <ul
                className={classes.subTitle}
                style={{ color: "#FFF", marginTop: "13.50em" }}
              >
                <li style={{ paddingRight: "20px" }}>{props?.category}</li>
              </ul>
            </Grid>
          </Grid>
        )}
      </CardMedia>
      <Tooltip title="Session Details">
        <CardContent style={{ cursor: "pointer", padding: "7px 0px" }}>
          <Typography
            gutterBottom
            className={props?.courseNameBold ? classes.nameBold : classes.title}
          >
            <Truncate lines={1} ellipsis={<span>...</span>}>
              {`    ${props?.name}   `}
            </Truncate>
          </Typography>
          <Grid container justify="space-between"></Grid>
        </CardContent>
      </Tooltip>
    </Card>
  );
}

export default SessionCard;
