import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Guide1 from "../../../assets/guideMobile1.svg";
import Guide2 from "../../../assets/guideMobile2.svg";
import Guide3 from "../../../assets/guideMobile3.svg";
import MobileStepper from "@material-ui/core/MobileStepper";
import { Button, Paper, Card, CardContent, CardMedia } from "@material-ui/core";

import { useHistory } from "react-router-dom";
export default function GuideMobile() {
  const classes = useStyles();

  const history = useHistory();
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  return (
    <Grid container>
      <Grid item container xs={12}>
        {activeStep === 0 ? (
          <CardMedia
            component="img"
            src={Guide1}
            className={classes.activeImage}
          />
        ) : activeStep === 1 ? (
          <CardMedia
            component="img"
            src={Guide2}
            className={classes.activeImage}
          />
        ) : (
          <CardMedia
            component="img"
            src={Guide3}
            className={classes.activeImage}
          />
        )}
      </Grid>

      <Grid item container xs={12} style={{ textAlign: "center" }}>
        <Grid
          container
          style={{ position: "absolute", bottom: 0 }}
          justify="center"
        >
          {activeStep === 0 ? (
            <Grid xs={12}>
              <Paper
                style={{ borderRadius: "0px", height: "100%" }}
                elevation={0}
              >
                <Card
                  style={{ height: "100%" }}
                  classes={{ root: classes.cardRoot }}
                  elevation={0}
                >
                  <CardContent
                    style={{
                      textAlign: "center",
                      position: "relative",
                      bottom: "0px"
                    }}
                  >
                    <Typography
                      className={activeStep === 0 && classes.cardTitle}
                      variant="h5"
                      component="h2"
                    >
                      Self-Practice at Home
                    </Typography>
                    <Typography
                      className={activeStep === 0 && classes.cardBody}
                      component="p"
                    >
                      Attending instructor-led live & pre-recorded classes
                      outside of your home is an excellent way to teach yourself
                      with self empowerment practices
                    </Typography>
                  </CardContent>
                </Card>
              </Paper>
            </Grid>
          ) : activeStep === 1 ? (
            <Grid xs={12}>
              <Paper
                style={{ borderRadius: "0px", height: "100%" }}
                elevation={0}
              >
                <Card elevation={0}>
                  <CardContent
                    style={{
                      textAlign: "center",
                      position: "relative",
                      bottom: "0px"
                    }}
                  >
                    <Typography
                      className={activeStep === 1 && classes.cardTitle}
                      variant="h5"
                      component="h2"
                    >
                      Group Challenges
                    </Typography>
                    <Typography
                      className={activeStep === 1 && classes.cardBody}
                      component="p"
                    >
                      Group Challenges help empower you to learn how to help
                      yourself in a friendly, encouraging and sharing community
                    </Typography>
                  </CardContent>
                </Card>
              </Paper>
            </Grid>
          ) : (
            <Grid xs={12}>
              <Paper
                style={{ borderRadius: "0px", height: "100%" }}
                elevation={0}
              >
                <Card elevation={0}>
                  <CardContent
                    style={{
                      textAlign: "center",
                      position: "relative",
                      bottom: "0px"
                    }}
                  >
                    <Typography
                      className={activeStep === 2 && classes.cardTitle}
                      variant="h5"
                      component="h2"
                    >
                      Top Courses
                    </Typography>
                    <Typography
                      className={activeStep === 2 && classes.cardBody}
                      component="p"
                    >
                      Multiple categories and amazing courses on breathing,
                      meditation, yoga and tantra
                    </Typography>
                  </CardContent>
                </Card>
              </Paper>
            </Grid>
          )}
          <Grid style={{ marginTop: "2rem" }} container justify="center">
            <MobileStepper
              variant="dots"
              steps={3}
              position="relative"
              activeStep={activeStep}
              style={{ background: "transparent" }}
            />
          </Grid>
          <Grid container justify="center">
            <Grid item xs={12}>
              <Grid container justify="space-between">
                <Button
                  className={classes.bottomButton}
                  onClick={() =>
                    activeStep === 0 ? history.push("/dashboard") : handleBack()
                  }
                >
                  {activeStep === 0 ? "Skip" : "Previous"}
                </Button>
                <Button
                  className={classes.bottomButton}
                  onClick={() =>
                    activeStep >= 2 ? history.push("/dashboard") : handleNext()
                  }
                >
                  Next
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/* <Grid item xs={10} /> */}
    </Grid>
  );
}

const useStyles = makeStyles(theme => ({
  bottomLeft: {
    width: "50px",
    height: "50px",
    borderRadius: "100%",
    float: "left",
    marginLeft: "50px",
    marginBottom: "50px"
  },
  cardRoot: {
    overflow: "unset",
    height: "100%"
  },

  activeImage: {
    height: "50vh",
    width: "100%",
    objectFit: "fill"
  },

  topRight: {
    width: "100px",
    height: "100px",
    top: "-77px"
  },
  cardTitle: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "32px",
    lineHeight: "37px",
    color: "#151348"
  },
  bottomButton: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "24px",
    lineHeight: "151.69%",
    /* or 36px */

    textAlign: "center",
    letterSpacing: "0.045em",

    color: "#151348"
  },
  cardBody: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "22px",
    lineHeight: "33px",
    /* or 33px */

    textAlign: "center",

    color: "#151348"
  }
}));
