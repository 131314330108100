import React, { useEffect, useState } from "react";
import {
  Grid,
  TextField,
  IconButton,
  Button,
  InputAdornment,
  useMediaQuery,
  CircularProgress
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import API from '../../../utils/API'
import AppBar from "../../../components/AppBar/index";
import { updateSetting } from "../../../actions/setting";
import { useDispatch, useSelector } from "react-redux";
import { useTheme } from "@material-ui/core/styles";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import MobileAppbar from "../../../components/More/settings/AppBar";
import { useIntl } from "react-intl";
import { useForm, Controller } from "react-hook-form";
import axios from 'axios'

function ChangePassword(props) {
  let dispatch = useDispatch();
  const classes = useStyles();
  let authReducerState = useSelector((state) => state.auth);
  const [values, setValues] = useState({
    password: "",
    showOldPassword: false,
    showNewPassword: false,
    showConfirmPassword: false
  });
  const [localLoader, setLocalLoader] = useState(false);
  const intl = useIntl();
  const { register, errors, handleSubmit, control, reset, watch } = useForm();

  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));


  const onSubmit = async data => {

    try {
      setLocalLoader(true)
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authReducerState?.token}`
        }
      };
      const body = JSON.stringify({ ...data });

      const res = await API.patch('/api/v1/user/changePassword', body, config);
      reset()
      setLocalLoader(false)
      dispatch({
        type: 'SHOW_MESSAGE',
        payload: {
          title: `success`,
          message: res.data.message,
          severity: "success",
        },
      });
    } catch (error) {
      console.log('error', error);
      setLocalLoader(false)
      dispatch({
        type: 'SHOW_MESSAGE',
        payload: {
          title: `Failed`,
          message: error?.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : `failed`,
          severity: "error",
        },
      });
    }
  };



  return (
    <Grid container  >
      <Grid xs={12} >
        {isMobile && !isDesktop ? (
          <MobileAppbar
            body={intl.formatMessage({ id: "changePassword" })}
            onClick={props.handleBack}
            backAction={"Settings"}
            backgroundColor="#87AC26"
          />
        ) : (
          <AppBar
            appBarConfig={{
              show: true,
              text: intl.formatMessage({ id: "changePassword" }),
              backAction: props.handleBack,
            }}
          />
        )}
      </Grid>

      <Grid xs={12} sm={12} md={8} lg={6} style={{ padding: '30px 30px 90px 30px' }}>
        <form onSubmit={handleSubmit(onSubmit)}>

          <TextField
            classes={{ root: classes.root }}
            type={values.showOldPassword ? "text" : "password"}
            label={intl.formatMessage({ id: "password" })}
            inputRef={register({
              required: intl.formatMessage({ id: "requiredPassword" }),
              minLength: {
                value: 5,
                message: intl.formatMessage({ id: "errorPassword" })
              }
            })}
            name="oldPassword"
            error={!!errors.oldPassword}
            helperText={errors.oldPassword && errors.oldPassword.message}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setValues({ ...values, showOldPassword: !values.showOldPassword })} edge="end">
                    {values.showOldPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />


          <TextField
            classes={{ root: classes.root }}
            type={values.showNewPassword ? "text" : "password"}
            label={intl.formatMessage({ id: "newPassword" })}
            inputRef={register({
              required: intl.formatMessage({ id: "requiredNewPassword" }),
              minLength: {
                value: 5,
                message: intl.formatMessage({ id: "errorPassword" })
              }
            })}
            name="newPassword"
            error={!!errors.newPassword}
            helperText={errors.newPassword && errors.newPassword.message}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setValues({ ...values, showNewPassword: !values.showNewPassword })} edge="end">
                    {values.showNewPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />

          <TextField
            classes={{ root: classes.root }}
            type={values.showConfirmPassword ? "text" : "password"}
            label={intl.formatMessage({ id: "confirmPassword" })}
            inputRef={register({
              required: intl.formatMessage({ id: "requiredConfirmPassword" }),

              validate: (value) => value === watch('newPassword') || "Passwords don't match."
            })}
            name="confirmNewPassword"
            error={!!errors.confirmNewPassword}
            helperText={errors.confirmNewPassword && errors.confirmNewPassword.message}

            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setValues({ ...values, showConfirmPassword: !values.showConfirmPassword })} edge="end">
                    {values.showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />




          <Grid container>
            <Grid item xs={12}>
              <Button type='submit' disabled={localLoader} className={classes.btn}>{localLoader ? <CircularProgress size={20} style={{ color: "#09a9bb" }} /> : intl.formatMessage({ id: "changePassword" })}</Button>


            </Grid>
          </Grid>
        </form>

      </Grid>
    </Grid>
  );
}

export default ChangePassword;

const useStyles = makeStyles((theme) => ({
  logoImage: {
    marginLeft: "10px",
    marginTop: "10px",
    height: "75px",
    width: "75px",
  },
  title: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "22px",
    lineHeight: "26px",
    color: "#000000",
  },
  fullHeight: {
    height: "100%",
    width: "70%",
    display: "flex",
  },
  body: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "18px",
    lineHeight: "26px",
    letterSpacing: "0.035em",
    color: "#9E9393",
  },
  subTitle: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "16px",
    color: "#8B8B8B",
  },
  header: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "22px",
    lineHeight: "26px",
    color: "#000000",
  },
  selectedBtn: {
    background: "linear-gradient(#87AC26 1%,#87AC26 100%)",
    borderRadius: "11px",
    color: "#FFFFFF",
    padding: "10px",
    height: "50px",
    width: "140px",
  },
  unSelectBtn: {
    borderColor: "#87AC26",
    borderRadius: "11px",
    color: "#87AC26",
    padding: "10px",
    height: "50px",
    width: "140px",
  },
  btn: {
    background: "linear-gradient(210.1deg, #15AEBF 13.46%, #14D9D9 76.64%);",
    boxShadow: "0px 3px 6px rgba(18, 166, 166, 0.25)",
    width: "100%",
    height: "50px",
    marginTop: '20px',
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "28px",
    letterSpacing: "0.09em",
    color: "#FFFFFF",
    textTransform: "none"
  },
  root: {
    width: '100%',
    marginBottom: "5%",
    "& .MuiInputBase-root": {
      color: "#a9a9a9",
      borderBottom: "1px solid #a9a9a9",
    },
    "& .MuiFormLabel-root": {
      color: "#a9a9a9",
    },
    "& .MuiFormLabel-root.Mui-focused": {
      color: "#a9a9a9",
    },
    "& .MuiInputBase-input": {
      color: "#000000",
    },
    "& .MuiInput-underline": {
      "&::before": {
        borderBottom: "1px solid #a9a9a9",
      },
      "&::after": {
        borderBottom: "1px solid #a9a9a9",
      },
    },

    "& .MuiInput-underline:hover:not(.Mui-disabled)": {
      "&::before": {
        borderBottom: "1px solid #a9a9a9",
      },
      "&::after": {
        borderBottom: "1px solid #a9a9a9",
      },
    },
  },
  messageRoot: {
    marginTop: "5%",
    "& .MuiInputBase-root": {
      color: "#a9a9a9",
      border: "1px solid #a9a9a9",
    },
    "& .MuiFormLabel-root": {
      color: "#a9a9a9",
    },
    "& .MuiFormLabel-root.Mui-focused": {
      color: "#a9a9a9 !important",
    },
    "& .MuiInputBase-input": {
      color: "#000000",
    },
  },
}));
