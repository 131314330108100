import React, { useState, useEffect } from "react";
import {
  Grid,
  TextField,
  Box,
  useMediaQuery,
  Button,
  Typography,
  CircularProgress
} from "@material-ui/core";
import { Search as SearchIcon } from "@material-ui/icons";
import { useSelector, useDispatch } from "react-redux";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/styles";
import FriendInvitationCard from "./FriendInvitationCard";
import { searchUsers } from "../../../../actions/userRelations";
import { invitePeopleForRetreat, getRetreatInvitedUsers } from "../../../../actions/retreatCourse";

function InviteFriends(props) {
  const intl = useIntl();
  const dispatch = useDispatch();
  const theme = useTheme();
  const classes = useStyles();
  const [friendsArr, setFriendsArr] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoader] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const loader = useSelector(state => state?.loader?.loader);
  const challengeParticipantsReducer = useSelector(
    state => state?.challenges?.challengeParticipants
  );
  const authReducer = useSelector(state => state?.auth);
  const searchedFriends = useSelector(
    state => state?.userRelations?.searchUsers
  );
  const invitedUsers = useSelector(
    state => state?.retreatCourse?.retreatInvitedUsers
  );

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box style={{ padding: "10px 0px 0px 0px" }}>
            <>{children}</>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
  };


  useEffect(() => {

    setFriendsArr([...searchedFriends]);

  }, [searchedFriends]);

  useEffect(() => {
    dispatch(getRetreatInvitedUsers({ token: authReducer?.token, trainingCourseId: props?.course?.id }))
    return () => {
      dispatch({
        type: 'SEARCH_USERS',
        payload: [],
      });
    }
  }, [])

  const invitationHandler = (status, participant) => {
    if (status === "sent") {
      let filterFriends = friendsArr?.map(friend => {
        if (friend?.id === participant?.id) {
          let localFriend = {
            ...friend,
            status: "sent"
          };

          return localFriend;
        }
        return friend;
      });

      setFriendsArr([...filterFriends]);
    }
    if (status === "cancel") {
      let filterFriends = friendsArr?.map(friend => {
        if (friend?.id === participant?.id) {
          return {
            ...friend,
            status: null
          };
        }
        return friend;
      });



      setFriendsArr([...filterFriends]);
    }
  };

  const handleAddParticipants = async () => {
    setLoader(true);
    const response = await dispatch(
      invitePeopleForRetreat({
        data: {
          courseId: props.course?.id,
          userIds: friendsArr.map(item => item?.status === 'sent' && item.id).filter(Boolean)
        },
        token: authReducer?.token
      })
    );
    setLoader(false);
    if (response?.status === 'success') {
      props.setOpenInvite(false);
    }
  };

  const inviteAgainHandler = async (id) => {
    await dispatch(
      invitePeopleForRetreat({
        data: {
          courseId: props.course?.id,
          userIds: [id]
        },
        token: authReducer?.token
      })
    );

  };



  const handleSearch = () => {
    dispatch(searchUsers({ token: authReducer?.token, searchTerm }));
  };

  return (
    <Grid container>

      <Grid item xs={12}>
        <Typography className={classes.header}>Invite Friends</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography style={{ color: '#09A9BB' }}>Search for specific people click on SEARCH {<SearchIcon />} to populate all available users</Typography>
      </Grid>
      <Grid item xs={12}>
        <TextField
          className={classes.homeSearchField}
          type="Search"
          style={{ width: "100%" }}
          placeholder="Search..."
          onChange={e => setSearchTerm(e.target.value)}
          name="search"
          onKeyPress={e => {
            (e.which === 13 || e.keyCode === 13) && handleSearch();
          }}
          InputProps={{
            endAdornment: (
              <SearchIcon
                onClick={handleSearch}
                style={{ cursor: "pointer" }}
              />
            )
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Grid container>
          {loader ? (
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <CircularProgress style={{ color: "#F28705" }} size={20} />
            </Grid>
          ) : friendsArr.length > 0 ? (
            friendsArr?.map(friend => {
              if (invitedUsers?.includes(friend?.id)) {
                return (
                  <Grid item xs={12} key={friend?.id}>
                    <FriendInvitationCard
                      inviteAgainHandler={inviteAgainHandler}
                      invitation={invitationHandler}
                      friend={{ ...friend, status: 'invited' }}
                    />
                  </Grid>
                );
              } else {
                return (
                  <Grid item xs={12} key={friend?.id}>
                    <FriendInvitationCard
                      invitation={invitationHandler}
                      friend={friend}
                    />
                  </Grid>
                );
              }

            })
          ) : (
            <Grid item xs={12}>
              <Typography className={classes.noRecord}>
                No friend found
              </Typography>
            </Grid>
          )}
          <Grid item xs={12} md={12} className={classes.top}>
            <Button
              style={{ borderRadius: isMobile && "30px" }}
              className={classes.completeBtn}
              disabled={!friendsArr.find(item => item?.status === 'sent') || loading}
              onClick={handleAddParticipants}
            >
              {loading ? (
                <CircularProgress size={15} style={{ color: "#fff" }} />
              ) : (
                "Send Invite"
              )}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default InviteFriends;

const useStyles = makeStyles(theme => ({
  appBar: {
    backgroundColor: "#fbfbfb",
    boxShadow: "none"

  },
  tabLabel: {
    color: "#DFDFDF",
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "21px",
    minWidth: "20%",
    textTransform: "none",
    padding: 0
  },
  tabSelected: {
    color: "#09A9BB",
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "21px"
  },

  indicatorStyling: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "#09A9BB",
    height: "3px",
    "& > span": {
      maxWidth: 20,
      width: "100%",
      backgroundColor: "#09A9BB"
    }

  },
  indicatorStylingMobile: {
    backgroundColor: "#09A9BB",
    height: "3px",
    borderRadius: "11px"
  },
  tabsContainer: {
    display: "flex",
    justifyContent: "space-between"
  },
  noRecord: {
    color: "#DFDFDF",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "19px",
    textAlign: "center",
    marginTop: "30px",
    marginBottom: "30px"
  },
  completeBtn: {
    width: "100%",
    height: "3.0em",
    background: "linear-gradient(180deg, #FFC000 -13.24%, #F28705 100%)",
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "20px",
    lineHeight: "23px",
    color: "#FFFFFF",
    marginBottom: "30px",
    borderRadius: "11px",
    textTransform: "none",
    marginTop: "30px"
  },
  header: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "20px",
    lineHeight: "18px",
    color: "#3C4A6B",

  },
  homeSearchField: {
    "& .MuiInputBase-root": {
      backgroundColor: '#dce8f2',
      color: '#A9A9A9',
      fontFamily: theme.fontFamily.Roboto,
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "18px",
      lineHeight: "21px",
      padding: "10px",
      minHeight: "64px",
      alignSelf: "center",
      marginTop: "12px",
      marginBottom: "20px",
      height: "58px",
      width: "100%",
      borderRadius: "10px"
    },
    "& .MuiBadge-colorPrimary": {
      backgroundColor: "#F9A702;"
    },

    "& .MuiInput-underline:before": {
      border: "0px solid #FBF1E0"
    },
    "& .MuiInput-underline::after": {
      border: "0px solid #FBF1E0"
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled)::before": {
      border: "0px solid #FBF1E0"
    }
  }
}));
