import React from "react";
import { Grid } from "@material-ui/core";
import AllNotifications from "../../../components/Notifications/AllNotificationsCard";
function Notifications() {
  return (
    <Grid container>
      <Grid
        item
        sm={5}
        md={5}
        lg={4}
        style={{ backgroundColor: "#F3F3F3", minHeight: "100vh" }}
      >
        <AllNotifications />
      </Grid>
      <Grid item sm={7} md={7} lg={8} style={{ padding: "55px 0px 0px 20px" }}>
      </Grid>
    </Grid>
  );
}

export default Notifications;
