import React, { useEffect, useState } from 'react';
import { Button, Grid, Switch, CircularProgress, Typography, useMediaQuery, Dialog, DialogContent, DialogTitle, FormControlLabel } from '@material-ui/core';
import { InsertInvitation, CancelRounded, Done } from '@material-ui/icons';
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import clsx from 'clsx';
import ShareIcon from '../../../assets/Icons/RetreatShareIcon.svg'
import { useSelector, useDispatch } from 'react-redux'
import InviteFriends from "../../../containers/Common/RetreatContainer/CoursePreview/InviteFriends";
import SocialMediaShare from "../../../components/More/Common/SocialMediaShare";
import MentorCard from './MentorCard'
import CourseEpisodeCard from "./CourseEpisodeCard";
import {
    changeEpisodeStatus,
    courseActiveInActive,
    changeSectionStatus
} from "../../../actions/trainingCourses";
import { useIntl } from "react-intl";

const CustomSwitch = withStyles({
    switchBase: {
        "&$checked": {
            color: "#29cc10"
        },
        "&$checked + $track": {
            backgroundColor: "#29cc10"
        }
    },
    checked: {},
    track: {}
})(Switch);


function PlayTrainingVideo(props) {
    const classes = useStyles();
    const theme = useTheme()
    const intl = useIntl();
    const dispatch = useDispatch()
    const [loader, setLoader] = useState(false);
    const [openInvite, setOpenInvite] = useState(false);
    const [selectedSection, setSelectedSection] = useState({});
    const [open, setOpen] = useState(false);
    const user = useSelector(state => state?.auth?.user);
    const auth = useSelector(state => state?.auth);
    const course = useSelector(state => state?.trainingCourse?.courseData);
    const coursesReducerState = useSelector(state => state?.trainingCourse);
    const lesson = useSelector(state => state?.trainingCourse?.lesson);
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
    const handleSetSelectedSection = (section) => {
        setSelectedSection(section);
    }


    const handleChangeStatus = async episode => {
        if (episode.status === "Active") {
            const res = await dispatch(
                changeEpisodeStatus({
                    episodeId: episode.id,
                    status: "InActive",
                    token: auth.token,
                    showMessage: true,
                    courseId: course?.id
                })
            );
            if (res.id) {
                const sectionLocal = selectedSection?.lessons.map(item => item.id === res.id ? { ...item, status: 'InActive' } : item);

                setSelectedSection({ ...selectedSection, lessons: sectionLocal })

            }
        } else {

            const res = await dispatch(
                changeEpisodeStatus({
                    episodeId: episode.id,
                    status: "Active",
                    token: auth.token,
                    showMessage: true,
                    courseId: course?.id

                })
            );
            if (res.id) {

                const sectionLocal = selectedSection?.lessons.map(item => item.id === res.id ? { ...item, status: 'Active' } : item);
                setSelectedSection({ ...selectedSection, lessons: sectionLocal })

            }
        }
    };

    const isIos = () => {
        const userAgent = window.navigator.userAgent.toLowerCase();
        return /iphone|ipad|ipod/.test(userAgent);
    };

    const changeCourseStatus = async () => {
        if (course) {
            if (course?.status === "Active") {
                props.handleNext();
            }
         
            else {
                setLoader(true);
                const { status } = await dispatch(
                    courseActiveInActive({
                        courseId: course.id,
                        status: "Active",
                        token: auth.token
                    })
                );
                setLoader(false);
                if (status) {
                    props.handleNext();
                }
            }
        }
    };


    const handleSectionChangeStatus = async () => {
        const activeSec = course.sections?.filter(item => item.status === "Active")?.length
        if (selectedSection.status === "Active") {
            const res = await dispatch(
                changeSectionStatus({
                    sectionId: selectedSection.id,
                    trainingCourseId: course?.id,
                    status: "InActive",
                    token: auth.token,
                    showMessage: true
                })
            );
            if (res?.status) {
                if (activeSec <= 1) {
                    dispatch({
                        type: 'IN_ACTIVE_LAST_SECTION',
                        payload: 'InComplete'
                    })
                }

                setSelectedSection({ ...selectedSection, status: 'InActive' })
            }
        } else {
            const res = await dispatch(
                changeSectionStatus({
                    sectionId: selectedSection.id,
                    trainingCourseId: course?.id,
                    status: "Active",
                    token: auth.token,
                    showMessage: true
                })
            );
            if (res?.status) {
                setSelectedSection({ ...selectedSection, status: 'Active' })
            }
        }
    };

    useEffect(() => {
        if (course && course?.sections?.length > 0) {
            setSelectedSection(course?.sections[0]);
        }
    }, [course])

    useEffect(() => {
        if (coursesReducerState?.courseData?.sections?.length > 0) {
            const index = coursesReducerState?.courseData?.sections?.findIndex(item => item?.id === selectedSection?.id);
            index > -1 && setSelectedSection(coursesReducerState?.courseData?.sections[index])

        }


    }, [coursesReducerState?.courseData?.sections])



    return (
        <Grid container>

            <Grid item xs={12}>
                <div className={classes.playerWrapper}>
                    <img src={course?.image} height="100%"
                        width="100%"
                        className={classes.reactPlayer} />
                </div>
            </Grid>

            <Grid item xs={12} container className={classes.attendanceContainer} style={{ borderBottom: '2px solid #E2E2E2', padding: isMobile && '0px 5px' }}>
                {/* <Grid item xs={4} md={2} container >
                    <Grid item className={classes.center} >
                        <Favorite style={{ paddingRight: '8px', color: '#E54D40', cursor: 'pointer' }} />
                    </Grid>
                    <Grid item className={classes.center}>
                        <Typography>Favorite</Typography>
                    </Grid>
                </Grid> */}
                {course?.isPrivate ? <Grid item xs={4} md={2} container onClick={() => coursesReducerState?.courseData?.status === 'Active' && setOpenInvite(true)} style={{ paddingRight: '3px', cursor: 'pointer' }}>

                    <Grid item className={classes.center} >
                        <img src={ShareIcon} style={{ paddingTop: '5px', paddingRight: '3px' }} />
                    </Grid>
                    <Grid item className={classes.center}>
                        <Typography > Invite Friends</Typography>
                    </Grid>
                </Grid> : <Grid item xs={6} sm={5} md={4} lg={3}  container>
                    <Grid item xs={6}  container onClick={() => coursesReducerState?.courseData?.status === 'Active' && setOpenInvite(true)} style={{ paddingRight: '3px', cursor: 'pointer' }}>

                        <Grid item className={classes.center} >
                            <InsertInvitation style={{color:'#C6C6C6'}}/>
                        </Grid>
                        <Grid item className={classes.center}>
                            <Typography > Invite </Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={6}  container onClick={() => coursesReducerState?.courseData?.status === 'Active' && setOpen(true)} style={{ paddingRight: '3px', cursor: 'pointer' }}>

                        <Grid item className={classes.center} >
                            <img src={ShareIcon} style={{ paddingTop: '5px', paddingRight: '3px' }} />
                        </Grid>
                        <Grid item className={classes.center}>
                            <Typography > Share</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                }

                {/* <Grid item xs={4} md={8} className={classes.center} style={{ justifyContent: 'flex-end' }}>
                    <Button className={classes.finishLessonButton}>Join Now</Button>
                    {isMobile && user?.id !== course?.user?.id && <JoinTrainingCourseComponent />}
                </Grid> */}

            </Grid>

            <Grid item xs={12} container className={classes.mentorDetailContainer} style={{ borderBottom: '2px solid #E2E2E2', marginBottom: '10px' }}>
                <Grid item xs={12} container className={clsx(classes.mentorContainer, classes.scroll)} spacing={2}>
                    {course?.mentors?.map(mentor => <Grid item key={mentor?.id} ><MentorCard mentor={mentor} /></Grid>)}
                </Grid>

                <Grid item xs={12} >
                    <Typography className={classes.mentorDetailHeading}>DETAIL</Typography>
                </Grid>

                <Grid item xs={12} className={classes.mentorContainer} >
                    {/* <Typography className={classes.mentorDetailContent}>{course?.description}</Typography> */}
                    {course?.description ? <Typography className={classes.mentorDetailContent} dangerouslySetInnerHTML={{ __html: unescape(course?.description) }} ></Typography> :
                        <Typography className={classes.mentorDetailContent} style={{ textAlign: 'center' }}>No description found</Typography>}
                </Grid>

            </Grid>

            <Grid item xs={12} md={8} lg={9} container spacing={1} style={{ padding: isMobile && '0px 10px' }}>
                {course?.sections?.map(section => <Grid key={section.id} item>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => selectedSection?.id !== section.id && handleSetSelectedSection(section)}
                        className={selectedSection?.id === section.id ? classes.sessionNumber : section?.status === "Incomplete" ? classes.sessionNumberInProgress : section?.status === "Active" ? classes.sessionActive : ""}
                        endIcon={section?.status === "Active" ? <Done /> : <></>}
                    >
                        {section.name}
                    </Button>
                </Grid>)}


            </Grid>

            <Grid item xs={12} md={4} lg={3}>
                <FormControlLabel
                    style={{ marginRight: "0px" }}
                    control={
                        <CustomSwitch
                            onChange={handleSectionChangeStatus}
                            checked={selectedSection?.status === "Active" ? true : false}
                        />
                    }
                    label={
                        <span className={classes.startTimeTxt}>
                            {selectedSection?.status}
                        </span>
                    }
                />
            </Grid>

            <Grid item xs={12} md={12} lg={12} container spacing={isMobile ? 0 : 1} style={{ padding: isMobile && '0px 10px' }}>
                {
                    selectedSection?.lessons?.length > 0 &&
                    selectedSection?.lessons?.map(episode => (
                        <Grid
                            key={episode.id}
                            item
                            xs={12}
                            md={6}
                            style={{ marginBottom: "10px" }}
                        >
                            <CourseEpisodeCard
                                textColor="#2A4A67"
                                videoIconBgColor="#2A4A67"
                                videoIconColor="#FFF"
                                title={episode.name}
                                image={episode.thumbnail}
                                isFree={episode?.isFree}
                                status={episode?.status}
                                episode={episode}
                                courseBy={
                                    auth &&
                                    auth.user &&
                                    auth.user.name
                                }
                                handleChangeStatus={() => handleChangeStatus(episode)}
                                duration={episode.videoFile ? episode.videoFile.duration : ""}
                                type=""
                            />
                        </Grid>
                    ))}
            </Grid>


            <Grid item container xs={12}>
                {course.sections?.filter(item => item.status === "Active")
                    ?.length === 0 && (
                        <Grid item xs={10} container justify="center" lg={12}>
                            <Typography component="p" variant="caption" style={{ color: 'red' }}>
                                {" "}
                  You can not activate your course unless you have one active
                  section{" "}
                            </Typography>
                        </Grid>
                    )}
                <Grid
                    style={{
                        padding: isMobile && !isDesktop ? "10px 10px" : "0px"
                    }}
                    item
                    xs={12}
                    md={12}
                    lg={6}
                >
                    <Button
                        style={{
                            borderRadius: isMobile && !isDesktop ? "50px" : "11px",

                        }}
                        disabled={
                            course?.sections?.filter(item => item?.status === "Active")
                                .length === 0
                        }
                        onClick={changeCourseStatus}
                        className={classes.btnStyle}
                    >
                        {loader ? (
                            <CircularProgress style={{ color: "#fff" }} size={20} />
                        ) : course?.status === "Active" ? (
                            intl.formatMessage({ id: "publish" })
                        ) : (
                            "Activate"
                        )}
                    </Button>
                </Grid>
            </Grid>

            <Dialog open={open} onClose={() => setOpen(false)}>
                <DialogContent style={{ padding: isIos() && "0px" }}>
                    <SocialMediaShare
                        url={`${window.location.origin}/share/${course?.retreatType}/${course?.slug}`}
                        imageUrl={course?.image}
                        sumaryText={"Here is new retreat"}
                        title={"Hey there,  Check this out, I think you will love it"}
                        tags={
                           course?.courseTags
                                ? course?.courseTags.map(item => item.name)
                                : []
                        }
                        header={intl.formatMessage({ id: "retreatShareText" })}
                    />
                </DialogContent>
            </Dialog>


            {openInvite && (
                <Dialog open={openInvite} onClose={() => setOpenInvite(false)}>
                    <DialogTitle>
                        <CancelRounded
                            className={classes.cancelBtn}
                            onClick={() => setOpenInvite(false)}
                        />
                    </DialogTitle>
                    <DialogContent>
                        <InviteFriends
                            setOpenInvite={setOpenInvite}
                            course={course}
                        />
                    </DialogContent>
                </Dialog>
            )
            }

        </Grid >
    );
}

export default PlayTrainingVideo;

const useStyles = makeStyles(theme => ({
    container: {
        height: '100vh',
        background: '#FBFBFB',
        padding: '30px 15px 50px 15px'
    },
    card: {
        width: '100%',
        background: '#FFFFFF',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.05)',
        borderRadius: '10px',
    },
    playerWrapper: {
        position: "relative",
        paddingTop: "56.25%",
        marginTop: '15px'
    },
    reactPlayer: {
        position: "absolute",
        top: 0,
        left: 0
    },
    cancelBtn: {
        color: "#D0CFCF",
        fontSize: "30px",
        cursor: "pointer",
        float: "right"
    },
    preLoadBody: {
        display: 'flex',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#a9a9a9',
        width: '100%'
    },
    attendanceContainer: {
        minHeight: '80px',
        alignItems: 'center',

    },
    noOfStudent: {
        height: '32px',
        minWidth: '32px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '5px'
    },
    attendanceContent: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '16px',
        lineHeight: '100%',
        color: '#353B35',
    },
    availableHeading: {
        fontFamily: 'Roboto',
        fontStyle: 'italic',
        fontWeight: 500,
        fontSize: '20px',
        lineHeight: '100%',
        color: ' #8F8F8F',
        alignItems: 'center',
        display: 'flex',
        height: '80px',
        justifyContent: 'flex-end'
    },
    availableDate: {
        fontFamily: 'Roboto',
        fontStyle: 'italic',
        fontWeight: 500,
        fontSize: '20px',
        lineHeight: '100%',
        color: '#353B35',
        paddingLeft: '7px'
    },
    live: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '20px',
        lineHeight: '100%',
        alignItems: 'center',
        display: 'flex',
        height: '80px',
        justifyContent: 'flex-end',
        color: '#353B35',
    },
    center: {
        display: 'flex',
        alignItems: 'center'
    },
    finishLessonButton: {
        background: '#09A9BB',
        color: '#FFF',
        textTransform: 'none',
        '&:hover': {
            background: '#09A9BB',
        }
    },
    mentorContainer: {
        minHeight: '100px',
        display: 'flex',
        alignItems: 'center',
    },
    mentorDetailContainer: {
        padding: '0px 25px'
    },
    mentorDetailHeading: {
        fontFamily: 'Reef',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '16px',
        paddingTop: '10px',
        color: '#353B35',
    },
    mentorDetailContent: {
        fontFamily: 'Reef',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '16px',
        lineHeight: '25px',
        color: '#434343'
    },
    liveIcon: {
        paddingRight: '10px'
    },
    scroll: {

        paddingTop: '7px',
        flexWrap: "unset",
        overflowX: "auto",
        "&::-webkit-scrollbar": {
            display: "none",
        },
    },
    ratio: {
        position: "relative",
        width: "100%",
        height: 0,

        paddingTop: `56.25%`,
    },
    sessionNumber: {
        background: "#2A4A67",
        marginBottom: '13px',
        color: "#fff",
        "&:hover": {
            background: "#2A4A67",
            color: "#fff"
        }
    },
    sessionCompleted: {
        background: "#52b202",
        marginBottom: '13px',
        color: "#fff",
        "&:hover": {
            background: "#52b202",
            color: "#fff"
        }
    },
    sessionNumberInProgress: {
        background: "#FBAE02",
        marginBottom: '13px',
        color: "#fff",
        "&:hover": {
            background: "#FBAE02",
            color: "#fff"
        }
    },
    btnStyle: {
        width: "100%",
        height: "2.750em",
        background:
            "linear-gradient(180deg, #FFC000 -13.24%, #F28705 100%)",
        fontFamily: theme.fontFamily.Roboto,
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "20px",
        lineHeight: "23px",
        color: "#FFFFFF",
        marginBottom: "30px",
        marginTop: '15px'
    },
    startTimeTxt: {
        fontFamily: theme.fontFamily.Roboto,
        fontStyle: "normal",
        fontWeight: "normal",

        fontSize: "24px",
        lineHeight: "10px",
        color: "#ff9100"
    },

}));