import React, { useEffect } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import {
  List,
  Avatar,
  Badge,
  ListItemText,
  Grid,
  Tooltip
} from "@material-ui/core";
import RelateIcon from "../../assets/Icons/RelateIcon.png";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import { useDispatch } from "react-redux";
import { useHistory, NavLink, Link } from "react-router-dom";

import { getUnseenNotificationsCount } from "../../actions/notifications";
import homeIcon from "../../assets/Icons/sidebarIcons/homeIcon.svg";
import homeIconSelected from "../../assets/Icons/sidebarIcons/homeIconSelected.svg";
import searchIcon from "../../assets/Icons/sidebarIcons/searchIcon.svg";
import searchIconSelected from "../../assets/Icons/sidebarIcons/searchIconSelected.svg";
import teacherIcon from "../../assets/Icons/BottomNavigationIcons/instructorIconSelected.svg";
import teacherIconSelected from "../../assets/Icons/sidebarIcons/teacherIconSelected.svg";
import bellIcon from "../../assets/Icons/bellIconNotifications.svg";
import bellIconSelected from "../../assets/Icons/sidebarIcons/bellIconSelected.svg";
import moreIcon from "../../assets/Icons/sidebarIcons/moreIcon.svg";
import moreIconSelected from "../../assets/Icons/sidebarIcons/moreIconSelected.svg";
import heartIcon from "../../assets/Icons/sidebarIcons/heartIcon.png";
import {
  HomeOutlined,
  SearchOutlined,
  HorizontalSplitOutlined,
  Notifications,
  ExitToApp,
  Favorite,
  Group

} from "@material-ui/icons";

import MenuItem from "@material-ui/core/MenuItem";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";

import "./Sidebar.scss";
const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    width: "100%"
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `100%`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginRight: 36
  },
  hide: {
    display: "none"
  },
  drawer: {
    flexWrap: "unset",
    overflowX: "auto",
    "&::-webkit-scrollbar": {
      display: "none"
    },
    width: "100%",
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxShadow: `3px 0px 5px rgba(0, 0, 0, 0.05)`,
    borderRadius: `11px`
  },
  drawerOpen: {
    flexWrap: "unset",
    overflowX: "auto",
    "&::-webkit-scrollbar": {
      display: "none"
    },

    width: "100%",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerClose: {
    flexWrap: "unset",
    overflowX: "auto",
    "&::-webkit-scrollbar": {
      display: "none"
    },
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: "hidden",
    [theme.breakpoints.up("sm")]: {
    }
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3)
  },
  navItems: {
    color: theme.colors.sideBar,
    justifyContent: "center",
    alignItems: "center",
    width: "120px",
    height: "80px"
  },
  navLink: {
    flexGrow: 1,
    height: "100%"
  },
  selectedNavItems: {
    backgroundColor: theme.colors.sideBar,
    color: "#FFFFFF",
    justifyContent: "center",
    alignItems: "center",
    width: "120px",
    height: "80px"
  },
  navActive: {
    paddingTop: "1rem",
    paddingBottom: "1rem",
    paddingLeft: "1rem",
    color: "red"
  },
  list: {
    height: "72vh",
    marginTop: "10%",
    marginBottom: "20%",
    display: "flex",
    flexDirection: "column"
  },
  logo: {
    alignSelf: "center",
    marginTop: "10px"
  },
  logoImage: {
    marginTop: "10px",
    height: "50px",
    width: "110px"
  },
  profileItem: {
    alignSelf: "flex-end",
    display: "flex",
    justifyContent: "center"
  },
  profileIcon: {
    paddingTop: "1rem",
    paddingBottom: "1rem",
    color: theme.colors.sideBar,
    justifyContent: "center"
  },
  badge: {
    background: "#09A9BB !important"
  }
}));

export default function Sidebar() {
  const classes = useStyles();
  let history = useHistory();
  let dispatch = useDispatch();
  const intl = useIntl();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [currentTab, setCurrentTab] = React.useState(null);

  const user = useSelector(state => state?.user?.user);
  const auth = useSelector(state => state?.auth);
  const noOfUnseenNotifications = useSelector(
    state => state?.notifications?.noOfUnseenNotifications
  );

  const handleSelect = tabText => {
    setCurrentTab(tabText);
  };

  useEffect(() => {
    dispatch(getUnseenNotificationsCount({ token: auth?.token }));
  }, []);

  const handleClose = () => {
    setAnchorEl(null);
  };


  return (
    <>
      <Drawer
        width="100%"
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: false,
          [classes.drawerClose]: !false
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: false,
            [classes.drawerClose]: !false
          })
        }}
        className="sidebar"
        style={{
          maxHeight: "100vh",

          overflowY: "hidden"
        }}
      >
        <Link to={"/dashboard"} className={classes.logo}>
          <img src={RelateIcon} alt="logo" className={classes.logoImage} />
        </Link>

        <List className={classes.list}>
          {[
            {
              text: intl.formatMessage({ id: "dashboard" }),
              to: "/dashboard",
              icon: history?.location?.pathname?.includes("/dashboard") ? <img src={homeIconSelected} style={{ width: '20px' }} /> : <img src={homeIcon} style={{ width: '20px' }} />
            },
            {
              text: intl.formatMessage({ id: "explore" }),
              to: "/explore",
              icon: history?.location?.pathname?.includes("/explore") ? <img src={searchIconSelected} style={{ width: '20px' }} /> : <img src={searchIcon} style={{ width: '20px' }} />
            },
            {
              text: intl.formatMessage({ id: "profile" }),
              to: "/profile",
              icon: (
                <Avatar src={user?.pictureUrl}>
                  {user?.name?.charAt(0).toUpperCase()}
                </Avatar>
              )
            },

            {
              text: intl.formatMessage({ id: "teachers" }),
              to: "/view-all-teachers",
              icon: history?.location?.pathname?.includes("/view-all-teachers") ? <img src={teacherIconSelected} style={{ width: '23px' }} /> : <img src={teacherIcon} style={{ width: '23px' }} />
            },
            {
              text: intl.formatMessage({ id: "favorite" }),
              to: "/favorite",
              icon: history?.location?.pathname?.includes("/favorite") ? <Favorite style={{ color: '#fff', width: '25px' }} /> : <img src={heartIcon} style={{ width: '20px' }} />
            },
            {
              text: intl.formatMessage({ id: "notifications" }),
              to: "/notification",
              icon: history?.location?.pathname?.includes("/notification") ? <img src={bellIconSelected} style={{ width: '17px' }} /> : <img src={bellIcon} style={{ width: '17px' }} />
            },
            {
              text: intl.formatMessage({ id: "menu" }),
              to: "/more",
              icon: history?.location?.pathname?.includes("/more") ? <img src={moreIconSelected} style={{ width: '20px' }} /> : <img src={moreIcon} style={{ width: '20px' }} />
            }
          ].map((item, index) => {
            if (item?.text === "Notifications") {
              return (
                <NavLink
                  style={{ textDecoration: "none" }}
                  key={index}
                  to={item.to}
                  className={classes.navLink}
                >
                  <ListItem
                    className={
                      history?.location?.pathname?.includes(item?.to)
                        ? classes.selectedNavItems
                        : classes.navItems
                    }
                    button
                    onClick={() => handleSelect(item.to)}
                    key={item.text}
                  >
                    <ListItemIcon
                      className={
                        history?.location?.pathname?.includes(item?.to)
                          ? classes.selectedNavItems
                          : classes.navItems
                      }
                    >
                      <Grid container justify="center">
                        <Grid container justify="center">
                          <Tooltip title={item.text} placement="top">
                            {noOfUnseenNotifications === 0 ? (
                              item.icon
                            ) : (
                              <Badge
                                color="primary"
                                classes={{ colorPrimary: classes.badge }}
                                badgeContent={noOfUnseenNotifications || 0}
                              >
                                {item.icon}
                              </Badge>
                            )}
                          </Tooltip>
                        </Grid>
                        {history?.location?.pathname?.includes(item?.to) && (
                          <Grid
                            container
                            justify="center"
                            style={{ textDecoration: "noner" }}
                          >
                            {item.text}
                          </Grid>
                        )}
                      </Grid>
                    </ListItemIcon>
                  </ListItem>
                </NavLink>
              );
            } else {
              return (
                <NavLink
                  style={{ textDecoration: "none" }}
                  key={index}
                  to={item.to}
                  className={classes.navLink}
                >
                  <ListItem
                    className={
                      history?.location?.pathname?.includes(item?.to)
                        ? classes.selectedNavItems
                        : classes.navItems
                    }
                    button
                    onClick={() => handleSelect(item.to)}
                    key={item.text}
                  >
                    <ListItemIcon
                      className={
                        history?.location?.pathname?.includes(item?.to)
                          ? classes.selectedNavItems
                          : classes.navItems
                      }
                    >
                      <Grid container justify="center">
                        <Grid container justify="center">
                          <Tooltip title={item.text} placement="top">
                            {item.icon}
                          </Tooltip>
                        </Grid>
                        {history?.location?.pathname?.includes(item?.to) && (
                          <Grid container justify="center">
                            {item.text}
                          </Grid>
                        )}
                      </Grid>
                    </ListItemIcon>
                  </ListItem>
                </NavLink>
              );
            }
          })}
          <NavLink
            style={{ textDecoration: "none" }}
            key={50}
            to={''}
            className={classes.navLink}
          >
            <ListItem
              className={
                history?.location?.pathname?.includes("/logout")
                  ? classes.selectedNavItems
                  : classes.navItems
              }
              button
              onClick={() => {
                handleClose();
                dispatch({ type: "LOG_OUT" });
                localStorage.setItem("userDataLocal", null);
                process.env.REACT_APP_LANDING_URL ? (window.location.href = process.env.REACT_APP_LANDING_URL) : history.push("/auth")
              }}
              key={intl.formatMessage({ id: "logout" })}
            >
              <ListItemIcon
                className={
                  history?.location?.pathname?.includes("/logout")
                    ? classes.selectedNavItems
                    : classes.navItems
                }>
                <Grid container justify="center">
                  <Grid container justify="center">
                    <Tooltip title="Logout" placement="top">
                      <ExitToApp style={{ color: '#3AC3C8' }} />
                    </Tooltip>
                  </Grid>
                  {history?.location?.pathname?.includes("/logout") && (
                    <Grid container justify="center">
                      {intl.formatMessage({ id: "logout" })}
                    </Grid>
                  )}
                </Grid>
              </ListItemIcon>
            </ListItem>
          </NavLink>

        </List>
      </Drawer>
    </>
  );
}
