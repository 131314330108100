import React, { useState } from 'react'
import { Grid, Card, Badge, Avatar, Typography, LinearProgress, Dialog, CircularProgress, Box, useMediaQuery, Button } from '@material-ui/core';
import { makeStyles, withStyles, useTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const BorderLinearProgress = withStyles(theme => ({
    root: {
        height: 5,
        borderRadius: 5
    },
    colorPrimary: {
        backgroundColor:
            theme.palette.grey[theme.palette.type === "light" ? 200 : 700]
    },
    bar: {
        borderRadius: 5,
        backgroundColor: "#F9A502"
    }
}))(LinearProgress);

function CircularProgressWithLabel(props) {
    const theme = useTheme()
    const classes = useStyles();
    let isMobile = useMediaQuery(theme.breakpoints.up("sm")) ? false : true;



    return (
        <Box position="relative" display="inline-flex">
            <CircularProgress variant="static" {...props} size={130} classes={{ circle: classes.circle }} />
            <Box
                top={0}
                left={0}
                bottom={0}
                right={0}
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                <Typography variant="caption" component="div" style={{ color: '#F9A502', fontSize: '26px' }}>{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}

CircularProgressWithLabel.propTypes = {

    value: PropTypes.number.isRequired,
};

function ChallengeParticipantCard(props) {
    const classes = useStyles();
    const [openDialog, setOpenDialog] = useState(false)

    const handleOpenDialog = () => {
        setOpenDialog(!openDialog)
    }


    return (
        <>
            <Card className={classes.container} onClick={handleOpenDialog} >
                <Grid container >

                    <Grid item xs={12} className={classes.imageContainer}>
                        <Badge classes={{ colorSecondary: classes.color }} color="secondary" overlap="circle" variant="dot">

                            <Avatar src={props?.participant?.pictureUrl} style={{
                                height: '56px',
                                width: '56px'
                            }} />
                        </Badge>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography className={classes.username}>@{props?.participant?.username ? props?.participant?.username : props?.participant?.name}</Typography>
                    </Grid>
                    <Grid item xs={12} container justify={'center'}>
                        <Grid item xs={4}  >
                            <BorderLinearProgress
                                variant="determinate"
                                value={props?.participant?.challengeCompletedStatus || 0}
                                style={{ marginTop: "10px" }}
                            />
                        </Grid>
                    </Grid>
                </Grid>

            </Card>

            <Dialog classes={{ paper: classes.paper }} onClose={handleOpenDialog} aria-labelledby="simple-dialog-title" open={openDialog} >
                <Card className={classes.dialogContainer} >
                    <Grid container>
                        <Grid item xs={12} style={{ position: "relative", top: "-58px", }} className={classes.contentContainer}>
                            <Badge classes={{ colorSecondary: classes.color }} color="secondary" overlap="circle" variant="dot">
                                <Avatar src={props?.participant?.pictureUrl} style={{
                                    height: '85px',
                                    width: '85px',

                                }} />
                            </Badge>


                        </Grid>

                        <Grid item xs={12} className={classes.contentContainer}>
                            <Typography className={classes.dialogUserName}>@{props?.participant?.username ? props?.participant?.username : props?.participant?.name}</Typography>
                        </Grid>

                        <Grid item xs={12} style={{ textAlign: 'center', marginTop: '-20px' }}>
                            <CircularProgressWithLabel value={props?.participant?.challengeCompletedStatus || 0} />
                        </Grid>
                        <Grid item xs={12} container className={classes.contentContainer}>
                            <Grid item xs={8}>
                                <Typography className={classes.dialogProgressTxt}>Out of {Math.round(props?.participant?.challengeCompletedStatus) || 0}% of personal contribution in goal</Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} container className={classes.contentContainer}>

                            <Button className={classes.btn} onClick={handleOpenDialog}>
                                OKAY
                            </Button>

                        </Grid>

                    </Grid>
                </Card>
            </Dialog>

        </>
    )
}

export default ChallengeParticipantCard

const useStyles = makeStyles(theme => ({
    container: {
        height: '113px',
        width: '100%',
        cursor: 'pointer'
    },
    imageContainer: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '10px'

    },
    paper: {
        margin: "32px",
        position: "relative",
        overflowY: "unset"
    },
    username: {
        fontFamily: theme.fontFamily.Roboto,
        fontStyle: 'italic',
        fontSize: '11px',
        fontWeight: 'bold',
        lineHeight: '13px',
        textAlign: 'center',
        color: '#3C4A6B',
        marginTop: '8px'

    },
    dialogContainer: {
        height: '340px',
        width: '315px',
        display: 'flex',
        overflow: "unset"

    },
    contentContainer: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '5px'
    },
    dialogUserName: {
        fontFamily: theme.fontFamily.Roboto,
        fontStyle: 'italic',
        fontSize: '18px',
        fontWeight: 'bold',
        lineHeight: '21px',
        textAlign: 'center',
        color: '#3C4A6B',
        marginTop: '-58px'

    },
    circle: {
        color: '#F9A502',
    },
    dialogProgressTxt: {
        fontFamily: theme.fontFamily.Roboto,
        fontStyle: 'normal',
        fontSize: '16px',
        fontWeight: 500,
        lineHeight: '21px',
        textAlign: 'center',
        letterSpacing: '0.04em',
        color: '#435E76',
    },
    btn: {
        fontFamily: theme.fontFamily.Roboto,
        fontStyle: 'normal',
        fontSize: '24px',
        fontWeight: 500,
        lineHeight: '28px',
        color: '#FFFFFF',
        backgroundColor: '#F9A502 !important',
        width: '100%',
        height: '65px',
        marginTop: '16px',
        borderRadius: 'none'
    },
    color: {
        backgroundColor: '#5BE4E4'
    }
}))