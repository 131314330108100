import React, { useEffect, useState } from 'react';
import { Tab, Tabs, Box, Grid, TextField, useMediaQuery } from '@material-ui/core'
import { makeStyles, withStyles, useTheme } from "@material-ui/core/styles";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";
import MentorCard from '../ChatAndMentor/mentorCard'
import Participants from '../CourseProgessAndParticipants/Participants'
import Content from '../CourseProgessAndParticipants/Content'
import PrivateChat from '../ChatAndMentor/privateChat';
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

const StyledTabs = withStyles({
    indicator: {
        display: "flex",
        justifyContent: "center",
        backgroundColor: "#09A9BB",
        "& > span": {
            maxWidth: 30,
            width: "100%",
            backgroundColor: "#09A9BB"
        }
    }
})(props => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

const StyledTab = withStyles(theme => ({
    root: {
        minWidth: "60px !important",
        textTransform: "none",
        color: "#080808e3",
        fontWeight: theme.typography.fontWeightRegular,
        fontSize: theme.typography.pxToRem(15),
        marginRight: theme.spacing(1),

        fontFamily: theme.fontFamily.Roboto,
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "16px",
        lineHeight: "14px",
        color: " #FFF",
        "&:focus": {
            opacity: 1
        }
    },
    selected: {
        color: "#09A9BB"
    }
}))(props => <Tab disableRipple {...props} />);
function TabPanel(props) {
    const theme = useTheme()
    const { children, value, index, ...other } = props;
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={index === 4 ? 0 : isMobile ? 1 : 3}>
                    <>{children}</>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
};

function ContentSection(props) {
    const classes = useStyles();
    const history = useHistory();
    const intl = useIntl();
    const course = useSelector(state => state?.retreatCourse?.trainingCourseDetails);
    const [value, setValue] = useState(0);
    const [message, setMessage] = useState('');

    const user = useSelector(state => state?.auth?.user);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    return (
        <Grid container>
            <Grid item xs={12}>
                <StyledTabs
                    value={value}
                    onChange={handleChange}
                    aria-label="styled tabs example"
                    variant="scrollable"
                    scrollButtons="auto"
                    style={{ borderBottom: '1px solid #e8e8e8' }}
                >
                    <StyledTab
                        label={intl.formatMessage({ id: "contents" })}
                    />
                    <StyledTab
                        label={intl.formatMessage({ id: "participants" })}
                    />
                    <StyledTab
                        label={intl.formatMessage({ id: "privateChat" })}
                    />
                    <StyledTab
                        label={intl.formatMessage({ id: "mentors" })}
                    />
                </StyledTabs>
            </Grid>

            <Grid item xs={12}>
                <TabPanel value={value} index={0}  >
                    <Content switch={props?.switch} />
                </TabPanel>

                <TabPanel value={value} index={1}  >
                    <Participants />
                </TabPanel>

                <TabPanel value={value} index={2}  >
                    {(course?.isUserTaking || course?.user?.id === user?.id || course?.isMentor) ? <PrivateChat /> : 'Will be available after join'}


                </TabPanel>

                <TabPanel value={value} index={3}  >

                    {course?.mentors?.map(mentor => <Grid xs={12} item key={mentor?.id} onClick={() => window.open(`${window.location.origin}/view-profile/${mentor?.id}`)}>
                        <MentorCard mentor={mentor} />
                    </Grid>)}
                </TabPanel>


            </Grid>

        </Grid>
    );
}

export default ContentSection;

const useStyles = makeStyles(theme => ({
    container: {
        height: '100vh',
        background: '#FBFBFB',
        padding: '30px 15px 50px 15px'
    },

    card: {
        width: '100%',
        background: '#FFFFFF',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.05)',
        borderRadius: '10px',
    },
    messageInputRoot: {
        marginTop: "15px",
        color: '#78849E',
        width: '100%',
        background: '#EBEBEB',
        '& focused': {
            border: '1px solid #E3E3E3 !important',
        }

    },
    focused: {
        border: '1px solid #E3E3E3 !important',
    },
    iconStyle: {
        color: '#3D3D3D'
    },
    attachmentContainer: {
        background: '#EBEBEB',
        display: 'flex',
        alignItems: 'center',
        height: '40px'
    }

}));