import React from "react";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import SessionInviteMobile from "../../../components/More/Common/SessionInvitations";
import MoreWebContainer from "../../../containers/Web/More";

function SessionInvite(props) {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      {isDesktop && <MoreWebContainer />}
      {isMobile && !isDesktop && <SessionInviteMobile />}
    </>
  );
}

export default SessionInvite;
