// types
import {
  USER_LOADED,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  AUTH_LOADER,
  SET_ROLE,
  LOG_OUT,

} from "../actions/types";

// initial state
const initialState = {
  isAuthenticated: JSON.parse(localStorage.getItem("userDataLocal"))?.token
    ? true
    : null,
  user: JSON.parse(localStorage.getItem("userDataLocal"))?.user
    ? JSON.parse(localStorage.getItem("userDataLocal"))?.user
    : {},
  loader: false,
  token: JSON.parse(localStorage.getItem("userDataLocal"))?.token
    ? JSON.parse(localStorage.getItem("userDataLocal"))?.token
    : null,
  role: null
};

// handle actions
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case USER_LOADED:
      return {
        ...state,
        isAuthenticated: true,
        user: payload
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        user: payload.user,
        token: payload.token,
        loader: false
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        isAuthenticated: false,
        user: null,
        token: null,
        loader: false
      };
    case AUTH_LOADER:
      return {
        ...state,
        loader: payload
      };
    case SET_ROLE:
      return {
        ...state,
        role: payload
      };
    case LOG_OUT:
      return {
        isAuthenticated: false,
        user: null,
        loader: false,
        token: null,
        role: null
      };

    default:
      return state;
  }
}
