import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Box, Typography } from '@material-ui/core'

function RepeatChallengeCard(props) {
    const classes = useStyles();

    return (
        <div className={classes.root} style={{backgroundColor: props?.selected === true ? '#FBAE02' : '#EFEFEF'}}>
            <Typography className={classes.text} style={{color: props?.selected === true ? '#FFFFFF' : '#363636'}}>{props?.text}</Typography>
        </div>
    )
}

export default RepeatChallengeCard


const useStyles = makeStyles(theme => ({
    root: {
        height: '80px',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems:'center',
        borderRadius:'6px',
        boxShadow:' 0px 4px 4px rgba(0, 0, 0, 0.05)',
        cursor:'pointer'
    },
    text: {

        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '18px',
        lineHeight: '21px',
    }
}))