import { useState } from 'react'
import { Grid, Typography } from '@material-ui/core'
import { ArrowBack } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'
import { addParticipants } from '../../../../actions/challenges'
import CreateChallenge from '../../../../components/More/CreateChallenge'
import { useDispatch } from 'react-redux'

function Challenges(props) {
    const classes = useStyles();
    const intl = useIntl();
    const history = useHistory();
    const dispatch = useDispatch();
    const [friendAddState, setFriendAddState] = useState(false)

    const addFriend = (state) => {
        setFriendAddState(state)
    }
    const goBackHandler = () => {
        if (friendAddState) {
            setFriendAddState(false)
        } else {
            dispatch(addParticipants({}))
            history.replace('/more', { courseInfo: {} })
        }

    }

    return (
        <Grid container style={{ backgroundColor: '#fbfbfb' }}>
            <Grid item xs={1} />
            <Grid item container xs={11} className={classes.challengesContainer}>
                <Grid item sm={1}>
                    <ArrowBack style={{ cursor: 'pointer' }} onClick={goBackHandler} />
                </Grid>
                <Grid item sm={4}>
                    <Typography className={classes.BackTxt}>{intl.formatMessage({ id: "Back" })}</Typography>
                </Grid>
                <Grid item sm={7} />
                <Grid item xs={12} className={classes.content}>
                    <CreateChallenge addFriend={addFriend} addFriendState={friendAddState} />
                </Grid>
            </Grid>


        </Grid>
    )
}

export default Challenges

const useStyles = makeStyles(theme => ({
    challengesContainer: {
        marginTop: '40px',
        backgroundColor: '#fbfbfb',
    },
    content: {
        marginTop: '50px',

    },
    BackTxt: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '20px',
        lineHeight: '23px',
        color: '#3C4A6B',
    }
}));