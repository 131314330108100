import { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import {
  Grid,
  Button,
  useMediaQuery
} from "@material-ui/core";
import InviteFriends from "./CourseUpload/InviteFriends";
import SocialMediaShare from "../../../components/More/Common/SocialMediaShare.js";
import { useTheme } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";

function InviteAndShare(props) {
  const theme = useTheme();
  const intl = useIntl();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));


  const [open, setOpen] = useState(false);
  const [openInvite, setOpenInvite] = useState(false);

  return (
    <Grid
      container
      style={{
        paddingLeft: isMobile && !isDesktop ? "0px" : "2rem",
        padding: isMobile && !isDesktop ? "10px 20px" : "0px"
      }}
    >
      {props.share && (
        <Grid container direction="row" style={{ marginTop: "2.375em" }}>
          <Grid item xs={12} md={props.buttonText ? 12 : 6}>
            <SocialMediaShare
              url={`${window.location.origin}/share/course/${props.course?.slug}`}
              imageUrl={props?.course?.image}
              sumaryText={"Here is new course"}
              title={"Hey there,  Check this out, I think you will love it"}
              tags={
                props.course?.courseTags
                  ? props.course?.courseTags.map(item => item.name)
                  : []
              }
              header={intl.formatMessage({ id: "courseShareText" })}
            />
          </Grid>
        </Grid>
      )}
      {props.invite && (
        <Grid container direction="row" style={{ marginTop: "2.375em" }}>
          <Grid item xs={12} md={props.buttonText ? 12 : 6}>
            <InviteFriends
              setOpenInvite={setOpenInvite}
              course={props.course}
            />
          </Grid>
        </Grid>
      )}
      {!props.buttonText && (
        <Grid
          style={{
            paddingLeft: isMobile && !isDesktop ? "0" : "2rem",
            padding: isMobile && !isDesktop ? "10px" : "0px"
          }}
          item
          xs={12}
          sm={12}
          md={12}
          lg={6}
        >
          <Button
            style={{
              width: "100%",
              height: "2.750em",
              background:
                "linear-gradient(180deg, #FFC000 -13.24%, #F28705 100%)",
              borderRadius: isMobile && !isDesktop ? "50px" : "11px",
              fontFamily: theme.fontFamily.Roboto,
              fontStyle: "normal",
              fontWeight: 500,
              fontSize: "20px",
              lineHeight: "23px",
              color: "#FFFFFF",
              marginBottom: "30px"
            }}
            onClick={props.handleNext}
          >
            {intl.formatMessage({ id: "finish" })}
          </Button>
        </Grid>
      )}

    </Grid>
  );
}

export default InviteAndShare;

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    minHeight: "16.850em",
    borderRadius: "10px",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat"

  },
  media: {
    width: "100%",
    height: "100%",
    background:
      "linear-gradient(360deg, rgba(21, 57, 89, 0.8) 0%, rgba(123, 146, 166, 0.8) 100%)",
    borderRadius: "11px",
    transform: "rotate(360deg)",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat"
  },
  courseTitle: {
    fontFamily: theme.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: 900,
    fontSize: "34px",
    color: "#FFFFFF",
    padding: "10% 0 0 0"
  },
  courseDescription: {
    fontFamily: theme.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: 900,
    fontSize: "20px",
    lineHeight: "27px",
    letterSpacing: "0.015em",
    color: "#FFFFFF",
    padding: "20px 0px"
  },
  startNowBtn: {
    height: "2.463em",
    width: "85%",
    background: "linear-gradient(360deg, #15AEBF 0%, #A6D6FF 100%)",
    borderRadius: "10px",
    transform: "rotate(360deg)",
    fontFamily: theme.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "20px",
    lineHeight: "10px",
    color: "#FFFFFF",
    marginTop: "10%",
    marginLeft: "10%",
    marginBottom: "3%",
    position: "static"
  },
  epText: {
    fontFamily: theme.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "21px",
    color: "#09A9BB",
    textShadow: "0px 2px 4px rgba(5, 85, 94, 0.31)"
  },
  readMore: {
    fontSize: "12px",
    cursor: "pointer",
    color: "#C4C4C4",
    "&:hover": {
      textDecoration: "underline",
      color: "#84CDF0"
    }
  }

}));
