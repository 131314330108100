// User Auth
export const USER_LOADED = "USER_LOADED";
export const LOGIN = "LOGIN";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const AUTH_ERROR = "AUTH_ERROR";
export const AUTH_LOADER = "AUTH_LOADER";
export const SET_ROLE = "SET_ROLE";
export const LOG_OUT = "LOG_OUT";
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const UPLOADED_IMAGE = "UPLOADED_IMAGE";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const RESET_PASSWORD = "RESET_PASSWORD";
//message
export const SHOW_MESSAGE = "SHOW_MESSAGE";
export const SHOW_MESSAGE_DIALOG = "SHOW_MESSAGE_DIALOG";
export const HIDE_MESSAGE_DIALOG = "HIDE_MESSAGE_DIALOG";
export const HIDE_MESSAGE = "HIDE_MESSAGE";

//courses
export const SET_CATEGORIES = "SET_CATEGORIES";
export const SET_PLANS = "SET_PLANS";
export const ADD_SUCCESSFUL = "ADD_SUCCESSFUL";
export const SET_COURSE = "SET_COURSE";
export const UNSET_COURSE = "UNSET_COURSE";
export const SET_EPISODES = "SET_EPISODES";
export const REMOVE_EPISODES = "REMOVE_EPISODES";
export const COURSE_LOADER_TRUE = "COURSE_LOADER_TRUE";
export const COURSE_LOADER_FALSE = "COURSE_LOADER_FALSE";
export const COURSE_DETAIL = "COURSE_DETAIL";
export const COURSE_REVIEWS = "COURSE_REVIEWS";
export const ADD_COURSE_REVIEW = "ADD_COURSE_REVIEW";
export const ADD_COURSE_RATING = "ADD_COURSE_RATING";
export const GET_NEW_COURSES = "GET_NEW_COURSES";
export const GET_RECENT_COURSES = "GET_RECENT_COURSES";
export const FAVORITE_COURSE = "FAVORITE_COURSE";
export const GET_USER_UPLOADED_COURSES = "GET_USER_UPLOADED_COURSES";
export const GET_USER_ENROLLED_COURSES = "GET_USER_ENROLLED_COURSES";
export const GET_QUOTE = "GET_QUOTE";
export const GET_COURSES_GROUP_BY_CATEGORY = "GET_COURSES_GROUP_BY_CATEGORY";
export const SEARCH_NEW_COURSES = "SEARCH_NEW_COURSES";
export const EPISODE_DETAIL = "EPISODE_DETAIL";
export const JOIN_FREE_COURSE = "JOIN_FREE_COURSE";
export const GET_COURSES_BY_CATEGORY = "GET_COURSES_BY_CATEGORY";
export const COURSE_STATS_DETAIL = "COURSE_STATS_DETAIL";
export const COURSE_LOADER = "COURSE_LOADER";
export const PREVIEW_VIDEO_PROGRESS = "PREVIEW_VIDEO_PROGRESS";

//user Relations
export const GET_USER_FRIENDS = "GET_USER_FRIENDS";
export const GET_PENDING_REQUESTS = "GET_PENDING_REQUESTS";
export const APPROVE_REQUESTS = "APPROVE_REQUESTS";
export const REJECT_REQUESTS = "REJECT_REQUESTS";
export const SEARCH_FRIENDS = "SEARCH_FRIENDS";
export const SEND_REQUEST = "SEND_REQUEST";
export const FOLLOW_USER = "FOLLOW_USER";
export const SEARCH_USERS = "SEARCH_USERS";
export const GET_USER_FOLLOWERS = "GET_USER_FOLLOWERS";
export const GET_USER_FOLLOWINGS = "GET_USER_FOLLOWINGS";

//chats
export const GET_USER_CHAT = "GET_USER_CHAT";
export const GET_CHAT_MESSAGES = "GET_CHAT_MESSAGES";
export const SEND_MESSAGE = "SEND_MESSAGE";
export const SELECTED_CHAT_USER = "SELECTED_CHAT_USER";
export const UPLOADED_CHAT_MEDIA = "UPLOADED_CHAT_MEDIA";
export const SELECTED_ROOM_ID = "SELECTED_ROOM_ID";
export const GROUP_CHAT_MESSAGES = "GROUP_CHAT_MESSAGES";
export const NEW_MESSAGE = "NEW_MESSAGE";
export const UPLOADED_CHAT_MEDIA_PROGRESS = "UPLOADED_CHAT_MEDIA_PROGRESS";
export const CHAT_LOADER = "CHAT_LOADER";
export const CHAT_MESSAGES_LOADER = "CHAT_MESSAGES_LOADER";

//categories
export const LATEST_CATEGORIES = "GET_LATEST_CATEGORIES";
export const SET_CATEGORIES_LIST = "SET_CATEGORIES_LIST";

//setting

export const GET_SETTING = "GET_SETTING";
export const SET_SETTING = "SET_SETTING";
export const UPDATE_SETTING = "UPDATE_SETTING";
export const SETTING_LOADER = "SETTING_LOADER";
export const GET_LANGUAGES = "GET_LANGUAGES";
export const SET_LANGUAGES = "SET_LANGUAGES";

export const SET_SUGGSETION = "SET_SUGGESTION";
export const UPDATE_USERNAME = "UPDATE_USERNAME";

//user
export const SET_CURRENT_LOGGED_IN_USER = "SET_CURRENT_LOGGED_IN_USER";
export const GET_USER_BY_ID = "GET_USER_BY_ID";

//session
export const SET_SESSION = "SET_SESSION";
export const SET_ALL_SESSIONS = "SET_ALL_SESSIONS";
export const GET_ALL_SESSIONS = "GET_ALL_SESSIONS";
export const ADD_SESSION_LOCAL = "ADD_SESSION_LOCAL";
export const GET_FRIENDS_SESSION = "GET_FRIENDS_SESSION";
export const SELECTED_SESSION = "SELECTED_SESSION";
export const UPDATE_SESSION = "UPDATE_SESSION";
export const DELETE_SESSION = "DELETE_SESSION";
export const GET_SESSION_DETAIL = "GET_SESSION_DETAIL";
export const SESSION_LOADER = "SESSION_LOADER";
export const JOIN_SESSION = "JOIN_SESSION";
export const CLEAN_SESSION = "CLEAN_SESSION";
export const GET_ALL_CREATED_JOINED_SESSIONS =
  "GET_ALL_CREATED_JOINED_SESSIONS";
export const NEW_CREATED_SESSION = "NEW_CREATED_SESSION";
export const GET_RECENT_SESSIONS = "GET_RECENT_SESSIONS";
export const SESSION_STATS = "SESSION_STATS";
export const SESSION_PARTICIPANTS = "SESSION_PARTICIPANTS";

//challenges
export const ADD_PARTICIPANTS_IN_CHALLENGE = "ADD_PARTICIPANTS_IN_CHALLENGE";
export const CREATE_CHALLENGE = "CREATE_CHALLENGE";
export const GET_ALL_CHALLENGES = "GET_ALL_CHALLENGES";
export const GET_CHALLENGE_BY_ID = "GET_CHALLENGE_BY_ID";
export const JOIN_CHALLENGS = "JOIN_CHALLENGS";
export const IS_JOINED = "IS_JOINED";
export const ADD_ID = "ADD_ID";
export const GET_ALL_INVITATIONS = "GET_ALL_INVITATIONS";
export const APPROVE_INVITATION = "APPROVE_INVITATION";
export const REJECT_INVITATION = "REJECT_INVITATION";
export const CREATE_CHALLENGE_DATA = "CREATE_CHALLENGE_DATA";
export const GET_CHALLENGE_STATS = "GET_CHALLENGE_STATS";
export const GET_RECENT_CHALLENGES = "GET_RECENT_CHALLENGES";
export const COMPLETE_CHALLENGE_STATUS = "COMPLETE_CHALLENGE_STATUS";

//Teachers
export const GET_NEW_TEACHERS = "GET_NEW_TEACHERS";
export const TEACHER_LOADER = "TEACHER_LOADER";

//stripe
export const SET_ALL_CARDS = "SET_ALL_CARDS";
export const SET_STRIPE_LOADER = "SET_STRIPE_LOADER";
export const SET_STRIPE_SUCCESS = "SET_STRIPE_SUCCESS";
export const SET_EXTERNAL_ACCOUNT = "SET_EXTERNAL_ACCOUNT";
//Notifications
export const GET_NOTIFICATIONS = "GET_NOTIFICATIONS";
export const GET_UNSEEN_NOTIFICATIONS_COUNT = "GET_UNSEEN_NOTIFICATIONS_COUNT";

//Loader
export const LOADER = "LOADER";

//Config
export const SET_SITE_SETTINGS = "SET_SITE_SETTINGS";
//SEARCH
export const SEARCH = "SEARCH";

export const TRAINING_ADD_SUCCESSFUL = "TRAINING_ADD_SUCCESSFUL";
export const SET_TRAINING_COURSE = "SET_TRAINING_COURSE";
export const TRAINING_COURSE_LOADER_TRUE = "TRAINING_COURSE_LOADER_TRUE";
export const TRAINING_COURSE_LOADER_FALSE = "TRAINING_COURSE_LOADER_FALSE";

export const SET_LESSONS = "SET_LESSONS";
export const REMOVE_LESSON = "REMOVE_LESSON";
// export const TRAINING_COURSE_DETAIL = "TRAINING_COURSE_DETAIL";
export const TRAINING_COURSE_REVIEWS = "TRAINING_COURSE_REVIEWS";
export const ADD_TRAINING_COURSE_REVIEW = "ADD_TRAINING_COURSE_REVIEW";
export const ADD_TRAINING_COURSE_RATING = "ADD_TRAINING_COURSE_RATING";
export const GET_NEW_TRAINING_COURSES = "GET_NEW_TRAINING_COURSES";
export const GET_RECENT_TRAINING_COURSES = "GET_RECENT_TRAINING_COURSES";
export const FAVORITE_TRAINING_COURSE = "FAVORITE_TRAINING_COURSE";
export const GET_USER_UPLOADED_TRAINING_COURSES =
  "GET_USER_UPLOADED_TRAINING_COURSES";
export const GET_USER_ENROLLED_TRAINING_COURSES =
  "GET_USER_ENROLLED_TRAINING_COURSES";
export const SET_SECTIONS = "SET_SECTIONS";
export const SET_MENTOR = "SET_MENTOR";
export const REMOVE_SECTION = "REMOVE_SECTION";
export const LESSON_DETAIL = "LESSON_DETAIL";
export const ACCEPT_OR_REJECT_STUDENT = "ACCEPT_OR_REJECT_STUDENT";
export const TRAINING_COURSE_STATS_DETAIL = "TRAINING_COURSE_STATS_DETAIL";
export const TRAINING_COURSE_LOADER = "TRAINING_COURSE_LOADER";
export const TRAINING_PREVIEW_VIDEO_PROGRESS =
  "TRAINING_PREVIEW_VIDEO_PROGRESS";
export const UNSET_TRAINING_COURSE = "UNSET_TRAINING_COURSE";
//Training course detail
export const TRAINING_COURSE_DETAIL = "TRAINING_COURSE_DETAIL";
export const TRAINING_COURSE_REQUESTS = "TRAINING_COURSE_REQUESTS";
export const TRAINING_COURSE_PARTICIPANTS = "TRAINING_COURSE_PARTICIPANTS";
export const GET_ALL_TRAINING_COURSE = "GET_ALL_TRAINING_COURSE";
export const TO_BE_PLAY_TRAINING_COURSE_EPISODE = "TO_BE_PLAY_TRAINING_COURSE_EPISODE";
export const COURSE_FAVORITE = "COURSE_FAVORITE";
export const TRAINING_COURSE_CONTENT = "TRAINING_COURSE_CONTENT";
export const TRAINING_COURSE_REQUESTS_STATUS = "TRAINING_COURSE_REQUESTS_STATUS";
export const TRAINING_COURSE_SECTION_SUPPORTED_FILES = "TRAINING_COURSE_SECTION_SUPPORTED_FILES";
export const TRAINING_COURSE_COMMENTS = "TRAINING_COURSE_COMMENTS";
export const ADD_COURSE_COMMENT = "ADD_COURSE_COMMENT";
export const SET_TRAINING_COURSE_SECTIONS = "SET_TRAINING_COURSE_SECTIONS";
export const ADD_UPDATE_COURSE_PLAN = "ADD_UPDATE_COURSE_PLAN";
export const TRAINING_COURSE_ANNOUNCEMENTS = "TRAINING_COURSE_ANNOUNCEMENTS";
export const ACTIVE_TRAINING_COURSE = "ACTIVE_TRAINING_COURSE";
export const GET_ENROLLED_RETREATS = "GET_ENROLLED_RETREATS";
export const GET_SECTION_ASSIGNMENTS = "GET_SECTION_ASSIGNMENTS";
export const STUDENT_SUBMITTED_SECTION_ASSIGNMENTS_FILES = "STUDENT_SUBMITTED_SECTION_ASSIGNMENTS_FILES";
export const STUDENT_ASSIGNMENT_APPROVE_DISAPPROVE = "STUDENT_ASSIGNMENT_APPROVE_DISAPPROVE";
export const GET_RETREAT_ASSIGNMENTS_FILES = "GET_RETREAT_ASSIGNMENTS_FILES";
export const ADD_UPDATE_RETREAT_PLAN = "ADD_UPDATE_RETREAT_PLAN";
export const GET_RETREAT_INVITED_USERS = "GET_RETREAT_INVITED_USERS";
export const CHANGE_VIEW_STATUS_LESSON = "CHANGE_VIEW_STATUS_LESSON";
export const RETREAT_STATS_DETAIL = "RETREAT_STATS_DETAIL";


//Teacher trainings

export const GET_ENROLLED_TEACHER_TRAININGS = "GET_ENROLLED_TEACHER_TRAININGS";
export const GET_ALL_CREATED_TEACHER_TRAINING = "GET_ALL_CREATED_TEACHER_TRAINING";


//SUBSCRIPTION PLAN
export const GET_ALL_SUBSCRIPTIONS_PLAN = "GET_ALL_SUBSCRIPTIONS_PLAN";
export const SUBSCRIPTIONS_LOADER = "SUBSCRIPTIONS_LOADER";
export const GET_ALL_USER_JOINED_UNDER_SUBSCRIPTION = "GET_ALL_USER_JOINED_UNDER_SUBSCRIPTION";
