import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  CheckCircleOutlineRounded,
  Close,
  TrendingUp,
  CancelOutlined,
} from "@material-ui/icons";
import {
  Grid,
  Typography,
  CardContent,
  Card,
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  useMediaQuery,
  DialogActions,
  CircularProgress,
  Chip,
} from "@material-ui/core";
import subscriptionCardIcon1 from "../../../assets/img/subscriptionCardIcon1.svg";
import subscriptionCardIcon2 from "../../../assets/img/subscriptionCardIcon2.svg";
import subscriptionCardIcon3 from "../../../assets/img/subscriptionCardIcon3.svg";
import subscriptionCardIcon4 from "../../../assets/img/subscriptionCardIcon4.svg";
import { useDispatch, useSelector } from "react-redux";
import { cancelSubscription } from "../../../actions/SubscriptionPlan";
import { buySubscription } from "../../../actions/stripe";
import CustomerCardList from "../../../components/Common/CustomerCardList";
import DisplayJoined from "./DisplayJoinedUnderSubscriptionPopup";
import Truncate from "react-truncate";
import { useLocation, useHistory } from "react-router-dom";

let subscriptionPlanId = null;

export default function SubscriptionCard(props) {
  const theme = useTheme();
  const location = useLocation();
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();

  const [totalAvailableCourses, setTotalAvailableCourses] = useState();
  const [totalAvailableSessions, setTotalAvailableSessions] = useState();
  const [totalAvailableRetreats, setTotalAvailableRetreats] = useState();
  const [totalAvailableTeacherTrainings, setAvailableTeacherTrainings] =
    useState();

  const [open, setOpen] = useState(false);
  const [joinedInfo, setJoinedInfo] = useState(false);
  const [cancelModal, setCancelModal] = useState(false);
  const [modalType, setModalType] = useState("");
  const [loading, setLoading] = useState(false);
  let isMobile = useMediaQuery(theme.breakpoints.up("sm")) ? false : true;
  const auth = useSelector((state) => state?.auth);
  const user = useSelector((state) => state?.user?.user);
  const configs = useSelector((state) => state?.setting?.siteConfig);
  const allJoined = useSelector(
    (state) => state?.subscriptionPlan?.userUnderSubscriptionJoined
  );

  console.log(user, "user________________");

  let selectedInterval = "monthly";
  const { color, background, plan, ind, annual } = props;
  selectedInterval = annual ? "yearly" : "monthly";
  let purchased =
    user?.purchasedSubscription &&
    user?.purchasedSubscription[0]?.SubscriptionPlanId === plan?.id;
  let purchasedSubscriptionInfo =
    user?.purchasedSubscription && user?.purchasedSubscription[0];
  let imageIcon = [
    subscriptionCardIcon1,
    subscriptionCardIcon1,
    subscriptionCardIcon2,
    subscriptionCardIcon3,
    subscriptionCardIcon4,
  ];

  useEffect(() => {
    user?.id &&
      location?.state?.type === "subscription" &&
      plan?.id === location?.state?.id &&
      setOpen(true);
    subscriptionPlanId = plan?.id;

    setTotalAvailableCourses(
      parseInt(plan.availableCourses) + parseInt(plan.availableTeacherCourses)
    );
    setTotalAvailableSessions(
      parseInt(plan.availableSessions) + parseInt(plan.availableTeacherSessions)
    );
    setTotalAvailableRetreats(
      parseInt(plan.availableRetreats) + parseInt(plan.availableTeacherRetreats)
    );
    setAvailableTeacherTrainings(
      parseInt(plan.availableTeacherTrainings) +
        parseInt(plan.availableTeachersTeacherTrainings)
    );
  }, [user]);

  const handleSubscription = () => {
    const zeroJoined =
      allJoined?.omidCourses?.length > 0 ||
      allJoined?.teacherCourses?.length > 0 ||
      allJoined?.omidRetreats?.length > 0 ||
      allJoined?.teacherRetreats?.length > 0 ||
      allJoined?.omidSessions?.length > 0 ||
      allJoined?.teacherSessions?.length > 0 ||
      allJoined?.omidTeacherTrainings?.length > 0 ||
      allJoined?.teachersTeacherTrainings?.length > 0
        ? false
        : true;

    if (
      ((annual && purchasedSubscriptionInfo?.interval === "monthly") ||
        (!annual && purchasedSubscriptionInfo?.interval === "yearly")) &&
      purchasedSubscriptionInfo?.status === "active" &&
      purchasedSubscriptionInfo?.SubscriptionPlan?.name !== "Free"
    ) {
      setModalType("switch");
      setCancelModal(true);
    } else if (
      (user?.isExpiredSubscription ||
        purchasedSubscriptionInfo?.status === "cancelled") &&
      !zeroJoined
    ) {
      /*   setModalType('switch');
               setCancelModal(true); */
      setJoinedInfo(true);
    } else if (plan?.name === "Free") {
      setCancelModal(true);
    } else {
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setCancelModal(false);
    setModalType("");
  };

  const handleContinue = () => {
    if (plan?.name === "Free") {
      setJoinedInfo(false);
      setCancelModal(true);
    } else {
      setJoinedInfo(false);
      setOpen(true);
    }
  };

  const handleCloseJoinedInfoPopup = () => {
    setJoinedInfo(false);
  };

  const handleCancelSubscription = async () => {
    setLoading(true);
    if (modalType === "switch") {
      setLoading(false);
      setOpen(false);
      setCancelModal(false);
      setModalType("");
    } else {
      plan?.name === "Free"
        ? await dispatch(
            buySubscription({
              subscriptionPlanId: plan?.id,
              customerId: user?.stripeUser?.customerId,
              cardId: null,
              token: auth.token,
              interval: annual ? "yearly" : "monthly",
            })
          )
        : await dispatch(
            cancelSubscription({
              token: auth?.token,
              id: purchasedSubscriptionInfo?.id,
              subscriptionId: user?.stripeUser?.subscriptionId,
            })
          );
      setLoading(false);
      setOpen(false);
      setCancelModal(false);
      setModalType("");
    }
  };
  console.log(purchasedSubscriptionInfo, "purchasedSubscriptionInfo");

  return (
    <>
      {plan?.attractTag && ((plan?.name !== "Free" && annual) || !annual) && (
        <Grid item xs={12} style={{ position: "relative" }}>
          <Chip
            size="small"
            icon={<TrendingUp />}
            label={plan?.attractTag}
            color="secondary"
            classes={{ colorSecondary: classes.tagClr }}
            className={classes.tagStyle}
          />
        </Grid>
      )}
      <DisplayJoined
        handleClose={handleCloseJoinedInfoPopup}
        open={joinedInfo}
        plan={plan}
        continueActivation={handleContinue}
      />
      {(plan?.name !== "Free" && annual) || !annual ? (
        <Card className={classes.root}>
          <CardContent style={{ padding: 0 }}>
            <Grid
              item={12}
              className={clsx(classes.header, background)}
              container
            >
              {plan?.name !== "Free" && (
                <Grid item>
                  <IconButton className={classes.iconContainer}>
                    <img src={imageIcon[0]} />
                  </IconButton>
                </Grid>
              )}

              <Grid item>
                <Typography
                  className={classes.headerTxt}
                  style={{ fontSize: isMobile ? "20px" : "24px" }}
                >
                  {plan?.name}
                </Typography>
              </Grid>
            </Grid>

            <Grid container justify="center">
              {
                <Grid
                  item={12}
                  className={clsx(classes.priceContainer)}
                  container
                  spacing={1}
                >
                  <Grid item xs={12} container justify="center">
                    {plan?.name !== "Free" && (
                      <Grid item>
                        <Typography
                          className={classes.priceTxt}
                          style={{ fontSize: isMobile ? "30px" : "36px" }}
                        >
                          {configs?.currency}
                          {annual ? plan?.annualPrice : plan?.price}
                        </Typography>
                      </Grid>
                    )}
                    <Grid item>
                      <Typography
                        className={clsx(classes.discountPriceTxt, color)}
                        style={{ fontSize: isMobile ? "30px" : "36px" }}
                      >
                        {configs?.currency}
                        {annual
                          ? plan?.annualDiscountedPrice
                          : plan?.discounted}
                        <sub className={classes.limitTxt}>
                          {annual ? "ANNUAL" : "MONTH"}
                        </sub>
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={8}
                    className={clsx(
                      classes.priceDescriptionContainer,
                      background
                    )}
                  >
                    <Typography className={classes.priceDesTxt}>
                      {plan?.priceTagline}
                    </Typography>
                  </Grid>
                </Grid>
              }

              <Grid item xs={12} container className={classes.benefitContainer}>
                {plan?.isActiveOmidCoursesTitle === true ? (
                  <Grid item xs={12} container>
                    <Grid item xs={2}>
                      {totalAvailableCourses == "0" ? (
                        <CancelOutlined className={classes.crossIcon} />
                      ) : (
                        <CheckCircleOutlineRounded className={color} />
                      )}
                    </Grid>
                    <Grid item xs={10}>
                      <Truncate lines={1} ellipsis={<span>...</span>}>
                        {totalAvailableCourses + " " + plan?.omidCoursesTitle}
                      </Truncate>
                    </Grid>
                  </Grid>
                ) : null}

                {plan?.isActiveOmidSessionsTitle === true ? (
                  <Grid item xs={12} container>
                    <Grid item xs={2}>
                      {totalAvailableSessions == "0" ? (
                        <CancelOutlined className={classes.crossIcon} />
                      ) : (
                        <CheckCircleOutlineRounded className={color} />
                      )}
                    </Grid>
                    <Grid item xs={10}>
                      <Truncate lines={1} ellipsis={<span>...</span>}>
                        {totalAvailableSessions + " " + plan?.omidSessionsTitle}
                      </Truncate>
                    </Grid>
                  </Grid>
                ) : null}

                {plan?.isActiveRetreatDiscountTitle === true ? (
                  <Grid item xs={12} container>
                    <Grid item xs={2}>
                      {annual ? (
                        plan?.annualRetreatDiscount == "0" ? (
                          <CancelOutlined className={classes.crossIcon} />
                        ) : (
                          <CheckCircleOutlineRounded className={color} />
                        )
                      ) : plan?.monthlyRetreatDiscount == "0" ? (
                        <CancelOutlined className={classes.crossIcon} />
                      ) : (
                        <CheckCircleOutlineRounded className={color} />
                      )}
                    </Grid>
                    <Grid item xs={10}>
                      <Truncate lines={1} ellipsis={<span>...</span>}>
                        {annual
                          ? plan?.annualRetreatDiscount +
                            "% " +
                            plan?.retreatDiscountTitle
                          : plan?.monthlyRetreatDiscount +
                            "% " +
                            plan?.retreatDiscountTitle}
                      </Truncate>
                    </Grid>
                  </Grid>
                ) : null}

                {plan?.isActiveTeacherTrainingDiscountTitle === true ? (
                  <Grid item xs={12} container>
                    <Grid item xs={2}>
                      {annual ? (
                        plan?.annualTeacherTrainingDiscount == "0" ? (
                          <CancelOutlined className={classes.crossIcon} />
                        ) : (
                          <CheckCircleOutlineRounded className={color} />
                        )
                      ) : plan?.monthlyTeacherTrainingDiscount == "0" ? (
                        <CancelOutlined className={classes.crossIcon} />
                      ) : (
                        <CheckCircleOutlineRounded className={color} />
                      )}
                    </Grid>
                    <Grid item xs={10}>
                      <Truncate lines={2} ellipsis={<span>...</span>}>
                        {annual
                          ? plan?.annualTeacherTrainingDiscount +
                            "% " +
                            plan?.teacherTrainingDiscountTitle
                          : plan?.monthlyTeacherTrainingDiscount +
                            "% " +
                            plan?.teacherTrainingDiscountTitle}
                      </Truncate>
                    </Grid>
                  </Grid>
                ) : null}

                {plan?.isActiveCourseDiscountTitle === true ? (
                  <Grid item xs={12} container>
                    <Grid item xs={2}>
                      {plan?.omidDiscount == "0" ? (
                        <CancelOutlined className={classes.crossIcon} />
                      ) : (
                        <CheckCircleOutlineRounded className={color} />
                      )}
                    </Grid>
                    <Grid item xs={10}>
                      <Truncate lines={1} ellipsis={<span>...</span>}>
                        {`${plan?.omidDiscount}% ${plan?.courseDiscountTitle}`}
                      </Truncate>
                    </Grid>
                  </Grid>
                ) : null}

                {plan?.isActiveSessionDiscountTitle === true ? (
                  <Grid item xs={12} container>
                    <Grid item xs={2}>
                      {plan?.omidDiscount == "0" ? (
                        <CancelOutlined className={classes.crossIcon} />
                      ) : (
                        <CheckCircleOutlineRounded className={color} />
                      )}
                    </Grid>
                    <Grid item xs={10}>
                      <Truncate lines={1} ellipsis={<span>...</span>}>
                        {plan?.omidDiscount + "% " + plan?.sessionDiscountTitle}
                      </Truncate>
                    </Grid>
                  </Grid>
                ) : null}

                {/* {plan.isActiveSessionDiscountTitle == true ? (
                  <Grid item xs={10} container>
                    <Grid item xs={2}>
                      {totalAvailableTeacherTrainings == "0" ? (
                        <CancelOutlined className={classes.crossIcon} />
                      ) : (
                        <CheckCircleOutlineRounded className={color} />
                      )}
                    </Grid>
                    <Grid item xs={10}>
                      <Truncate lines={1} ellipsis={<span>...</span>}>
                        {plan?.omidDiscount + "% " + plan?.sessionDiscountTitle}
                      </Truncate>
                    </Grid>
                  </Grid>
                ) : null} */}
                {console.log(plan, "plan")}
                {plan?.noOfFreeCourses > 0 && (
                  <Grid item xs={12} container>
                    <Grid item xs={2}>
                      {
                        plan?.noOfFreeCourses > 0 && (
                          <CheckCircleOutlineRounded className={color} />
                          /*  <CancelOutlined className={classes.crossIcon} /> */
                        ) /* : (

                    ) */
                      }
                    </Grid>
                    <Grid item xs={10}>
                      <Truncate lines={1} ellipsis={<span>...</span>}>
                        {plan?.noOfFreeCourses + " " + plan?.freeCourseTitle}
                      </Truncate>
                    </Grid>
                  </Grid>
                )}

                {/* <Grid item xs={10} container >
                                <Grid item xs={2}>
                                    {plan?.availableTeachersTeacherTrainings === '0' ? <CancelOutlined className={classes.crossIcon} /> : <CheckCircleOutlineRounded className={color} />}
                                </Grid>
                                <Grid item xs={10}>
                                    <Truncate lines={1} ellipsis={<span>...</span>} >
                                        {plan?.availableTeachersTeacherTrainings + " " + plan?.teacherTeacherTrainingsTitle}
                                    </Truncate>
                                </Grid>
                            </Grid> */}
                <Grid item xs={12} container>
                  <Grid item xs={2}>
                    <CheckCircleOutlineRounded
                      className={color}
                      style={{ color: !plan?.extras && "#fff" }}
                    />
                  </Grid>
                  <Grid item xs={10}>
                    {plan?.extras && (
                      <Truncate lines={1} ellipsis={<span>...</span>}>
                        {plan?.extras}
                      </Truncate>
                    )}
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={10} className={classes.purchaseContainer}>
                <Button
                  onClick={handleSubscription}
                  className={clsx(classes.purchaseButton, background)}
                  disabled={
                    (false &&
                      purchased &&
                      ((annual &&
                        purchasedSubscriptionInfo?.interval === "yearly") ||
                        (!annual &&
                          purchasedSubscriptionInfo?.interval === "monthly")) &&
                      user?.isExpiredSubscription) ||
                    (plan?.discounted <
                      purchasedSubscriptionInfo?.SubscriptionPlan?.discounted &&
                      !annual &&
                      purchasedSubscriptionInfo?.interval === "monthly" &&
                      purchasedSubscriptionInfo?.status === "active" &&
                      !user?.isExpiredSubscription) ||
                    (plan?.annualDiscountedPrice <
                      purchasedSubscriptionInfo?.SubscriptionPlan
                        ?.annualDiscountedPrice &&
                      annual &&
                      purchasedSubscriptionInfo?.interval === "yearly" &&
                      purchasedSubscriptionInfo?.status === "active" &&
                      !user?.isExpiredSubscription) ||
                    (purchased &&
                      ((annual &&
                        purchasedSubscriptionInfo?.interval === "yearly") ||
                        (!annual &&
                          purchasedSubscriptionInfo?.interval === "monthly")) &&
                      purchasedSubscriptionInfo?.status === "active" &&
                      !user?.isExpiredSubscription)
                  }
                >
                  <Typography className={classes.btnTxt}>
                    {purchased &&
                    ((annual &&
                      purchasedSubscriptionInfo?.interval === "yearly") ||
                      (!annual &&
                        purchasedSubscriptionInfo?.interval === "monthly")) &&
                    purchasedSubscriptionInfo?.status === "active"
                      ? "Subscribed"
                      : purchasedSubscriptionInfo?.SubscriptionPlan?.name ===
                          plan?.name &&
                        // purchasedSubscriptionInfo.isRevertAble === "yes" &&
                        purchasedSubscriptionInfo.interval === selectedInterval
                      ? "Reactive now"
                      : plan?.name === "Free"
                      ? "Activate Now"
                      : "Purchase Now"}
                  </Typography>
                </Button>
              </Grid>
              <Grid
                item
                xs={10}
                style={{ height: 7, textAlign: "center", paddingBottom: 15 }}
              >
                {plan?.name !== "Free" &&
                  purchased &&
                  ((annual &&
                    purchasedSubscriptionInfo?.interval === "yearly") ||
                    (!annual &&
                      purchasedSubscriptionInfo?.interval === "monthly")) && (
                    <Button
                      className={classes.cancel}
                      onClick={() =>
                        purchasedSubscriptionInfo?.status === "active" &&
                        setCancelModal(true)
                      }
                    >
                      {purchasedSubscriptionInfo?.status === "cancelled"
                        ? "Cancelled"
                        : "Cancel"}
                    </Button>
                  )}
              </Grid>
              <Grid item xs={11} className={classes.purchaseContainer}>
                {plan?.subscriptionDetailText && (
                  <Truncate
                    lines={2}
                    style={{ color: "#1d00ff" }}
                    ellipsis={<span>...</span>}
                  >
                    <Typography className={classes.warningTxt}>
                      {"   " + plan?.subscriptionDetailText + "   "}
                    </Typography>
                  </Truncate>
                )}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      ) : (
        ""
      )}

      <CustomerCardList
        /*                 type={user?.stripeUser?.subscriptionId ?
                     purchasedSubscriptionInfo?.status === 'cancelled' && purchasedSubscriptionInfo?.isRevertAble === 'yes'?
                      "reactiveSubscription": 'upgradeSubscription' : 'buySubscription'   } */

        /*  paymentType={user?.stripeUser?.subscriptionId ? 'upgrade' : 'purchase'} */
        /* paymentType={user?.stripeUser?.subscriptionId ?'upgrade' : purchasedSubscriptionInfo?.status === 'cancelled'
               && purchasedSubscriptionInfo?.isRevertAble === 'yes'?  'reactive' : 'purchase'} */

        open={open}
        setOpen={setOpen}
        type={
          purchasedSubscriptionInfo?.status === "cancelled" &&
          purchasedSubscriptionInfo?.isRevertAble === "yes" &&
          purchasedSubscriptionInfo?.interval === selectedInterval &&
          purchasedSubscriptionInfo.SubscriptionPlanId === plan?.id
            ? "reactiveSubscription"
            : user?.stripeUser?.subscriptionId
            ? "upgradeSubscription"
            : "buySubscription"
        }
        paymentType={
          purchasedSubscriptionInfo?.status === "cancelled" &&
          purchasedSubscriptionInfo?.isRevertAble === "yes" &&
          purchasedSubscriptionInfo?.interval === selectedInterval &&
          purchasedSubscriptionInfo.SubscriptionPlanId === plan?.id
            ? "reactive"
            : user?.stripeUser?.subscriptionId
            ? "upgrade"
            : "purchase"
        }
        planId={plan?.id}
        price={
          user?.stripeUser?.subscriptionId &&
          purchasedSubscriptionInfo?.interval === "monthly"
            ? plan?.discounted -
              purchasedSubscriptionInfo?.SubscriptionPlan?.discounted
            : user?.stripeUser?.subscriptionId &&
              purchasedSubscriptionInfo?.interval === "yearly"
            ? plan?.annualDiscountedPrice -
              purchasedSubscriptionInfo?.SubscriptionPlan?.annualDiscountedPrice
            : annual
            ? plan?.annualDiscountedPrice
            : plan?.discounted
        }
        customerId={user?.stripeUser?.customerId}
        interval={annual ? "yearly" : "monthly"}
        newPriceId={annual ? plan?.annualPriceId : plan?.stripePriceId}
        subscriptionId={user?.stripeUser?.subscriptionId}
        existingPlanId={purchasedSubscriptionInfo?.id}
        id={purchasedSubscriptionInfo?.id}
      />

      <Dialog open={cancelModal} onClose={handleClose}>
        <DialogTitle>
          <Grid container justify="space-between">
            <Grid item xs={10}>
              <Typography component="h4" variant="h5">
                {plan?.name === "Free"
                  ? "Activate Subscription"
                  : modalType === "switch"
                  ? "Read carefully"
                  : "Cancel Subscription"}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Button onClick={handleClose}>
                <Close />
              </Button>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Typography>
            {modalType === "switch"
              ? "To switch from Monthly to Yearly or vice versa you need to cancel existing subscription."
              : `Are you sure you wants to ${
                  plan?.name === "Free" ? "activate free" : "cancel this"
                } subscription.`}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCancelSubscription}
            className={classes.modelBtn}
            disabled={loading}
          >
            {loading ? (
              <CircularProgress size={15} />
            ) : modalType === "switch" ? (
              "Got it"
            ) : (
              "Confirm"
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 15,
    width: 300,
    maxWidth: "75vw",
  },
  header: {
    height: 75,
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  headerTxt: {
    color: "#FFFFFF",
    textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "bold",
    /*  fontSize: "24px", */
    lineHeight: "115%",
  },
  iconContainer: {
    background: "#FFf !important",
    marginRight: 10,
    height: 30,
    width: 30,
  },
  tagClr: {
    background: "#1cb22e",
  },
  tagStyle: {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: "100%",
  },
  priceContainer: {
    height: 100,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderBottom: "1px solid #D6D6D6",
    padding: 5,
  },
  freeTxt: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "48px",
    lineHeight: "115%",
    color: "#797979",
  },
  priceTxt: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 600,
    /*  fontSize: "36px", */
    lineHeight: "115%",
    color: "#797979",
    marginRight: 3,
    textDecoration: "line-through",
  },
  priceDesTxt: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 14,
    lineHeight: "115%",
    color: "#fff",
  },
  limitTxt: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "10px",
    lineHeight: "115%",
    color: "#C4C4C4",
    marginTop: 3,
  },
  discountPriceTxt: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "36px",
    lineHeight: "115%",
    marginLeft: 3,
  },
  priceDescriptionContainer: {
    height: 30,
    width: "70%",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    borderRadius: 5,
  },
  benefitsTxt: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: 19,
    color: "#393939",
  },
  benefitContainer: {
    marginTop: "5px",
    minHeight: "auto",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    paddingLeft: "1px",
    display: "flex",
    paddingTop: 5,
  },
  btnTx: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "18px",
    color: "#fff",
  },
  purchaseContainer: {
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    height: "50px",
  },
  purchaseButton: {
    width: "100%",
    height: "40px",
    borderRadius: "66px",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "18px",
    lineHeight: 21,
    color: "#fff",
    textTransform: "none",
  },
  warningTxt: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "16px",
    marginTop: 0,
    color: "#1d00ff !important",
    width: "100%",
    textAlign: "center",
  },

  modelBtn: {
    fontFamily: theme.fontFamily.Roboto,
    cursor: "pointer",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "21px",
    textTransform: "none",
    color: theme.colors.white,
    background: `linear-gradient(#09A9BB 1%,#09A9BB 100%)`,
  },
  cancel: {
    color: "#FF0000",
    textAlign: "center",
    cursor: "pointer",
  },
  crossIcon: {
    color: "#FF0000",
  },
}));
