// types
import {
    GET_ALL_SUBSCRIPTIONS_PLAN,
    SUBSCRIPTIONS_LOADER,
    GET_ALL_USER_JOINED_UNDER_SUBSCRIPTION
  } from "../actions/types";
  
  // initial state
  const initialState = {
    allSubscriptions: [],
    loader: false,
    userUnderSubscriptionJoined: {}
    
  };
  
  // handle actions
  export default function(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
      case GET_ALL_SUBSCRIPTIONS_PLAN:
        return {
          ...state,
          allSubscriptions: payload
        };
      case SUBSCRIPTIONS_LOADER:
           return {
          ...state,
          loader: payload
        };
      case GET_ALL_USER_JOINED_UNDER_SUBSCRIPTION:
           return {
          ...state,
          userUnderSubscriptionJoined: payload
        };
    
      default:
        return state;
    }
  }
  