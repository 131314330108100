import { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  CircularProgress,
  useMediaQuery,
  Button,
} from "@material-ui/core";
import { makeStyles } from "@material-ui//styles";
import { getNewCourses } from "../../actions/courses";
import { getNewRetreats } from "../../actions/retreatCourse";
import { useTheme, withStyles } from "@material-ui/core/styles";
import { getRecentChallenges } from "../../actions/challenges";
import { getRecentSessions } from "../../actions/Session";
import { getNewTeachers } from "../../actions/teacher";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CourseCard from "../../components/Common/CourseCard";
import SessionCard from "../../components/Common/ExploreSessionCard";
import UserCard from "../../components/Common/UserCard";
import moment from "moment";
import SubscribeNowBtn from "../../components/Common/SubscribeNowBtn";

function AllCoursesSessionChallengesInstructor(props) {
  const classes = useStyles();
  const theme = useTheme();
  let dispatch = useDispatch();
  const history = useHistory();
  const [retreatsLoader, setRetreatsLoader] = useState(false);
  const [teacherTrainingLoader, setTeacherTrainingLoader] = useState(false);
  const [latestRetreats, setLatestRetreats] = useState([]);
  const [latestTeacherTrainings, setLatestTeacherTrainings] = useState([]);
  const loader = useSelector((state) => state.loader?.loader);
  const teacherLoader = useSelector((state) => state.teacher?.teacherLoader);
  const authReducerState = useSelector((state) => state.auth);
  const latestCourses = useSelector((state) => state?.courses?.latestCourses);
  const recentChallenges = useSelector(
    (state) => state?.challenges?.recentChallenges
  );
  const recentSessions = useSelector((state) => state?.session?.recentSessions);
  const newTeachers = useSelector((state) => state?.teacher?.newTeachers);
  let reducerUser = useSelector((state) => state.user?.user);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));

  const getFormattedTime = (value) => {
    let hours =
      parseInt((value * 15) / 60) > 12
        ? parseInt((value * 15) / 60) - 12
        : parseInt((value * 15) / 60);
    let min = ((value * 15) / 60 - parseInt((value * 15) / 60)) * 60;
    let ampm = parseInt((value * 15) / 60) > 12 ? "PM" : "AM";
    if (hours === 0) {
      hours = 12;
    }

    return `${hours}: ${min} ${ampm}`;
  };

  const getData = async () => {
    setRetreatsLoader(true);
    setTeacherTrainingLoader(true);

    dispatch(
      getNewCourses({
        token: authReducerState?.token,
        page: 1,
        limit: 12,
        categoryId:
          props?.selectedCategory !== "my" ? props?.selectedCategory : "",
      })
    );
    dispatch(
      getRecentChallenges({
        token: authReducerState?.token,
        page: 1,
        limit: 12,
        categoryId:
          props?.selectedCategory !== "my" ? props?.selectedCategory : "",
      })
    );
    dispatch(
      getRecentSessions({
        token: authReducerState?.token,
        page: 1,
        limit: 12,
      })
    );
    dispatch(
      getNewTeachers({
        token: authReducerState?.token,
        page: 1,
        limit: 12,
        categoryId:
          props?.selectedCategory !== "my" ? props?.selectedCategory : "",
      })
    );

    const res = await dispatch(
      getNewRetreats({
        token: authReducerState?.token,
        page: 1,
        limit: 12,
        categoryId:
          props?.selectedCategory !== "my" ? props?.selectedCategory : "",
        retreatType: "retreat",
      })
    );
    if (res) {
      setLatestRetreats(res);
      setRetreatsLoader(false);
    } else {
      setRetreatsLoader(false);
    }

    const teacherTrainingRes = await dispatch(
      getNewRetreats({
        token: authReducerState?.token,
        page: 1,
        limit: 12,
        categoryId:
          props?.selectedCategory !== "my" ? props?.selectedCategory : "",
        retreatType: "teacherTraining",
      })
    );
    if (teacherTrainingRes) {
      setLatestTeacherTrainings(teacherTrainingRes);
      setTeacherTrainingLoader(false);
    } else {
      setTeacherTrainingLoader(false);
    }
  };
  useEffect(() => {
    getData();
  }, [props?.selectedCategory]);

  return (
    <Grid container>
      {(latestCourses?.latestCoursesList?.length > 0 ||
        authReducerState?.user?.role !== "Regular") && (
        <Grid container style={{ marginBottom: "20px" }}>
          <Grid item xs={6}>
            <Typography
              style={{ float: "left" }}
              className={classes.heading}
              classes={{ root: classes.heading }}
            >
              New Courses
            </Typography>
          </Grid>

          {isMobile && (
            <Grid
              item
              xs={3}
              sm={3}
              md={3}
              style={{
                marginLeft: "0px",
                marginRight: "0px",
                marginBottom: "5px",
              }}
            >
              {/*   <SubscribeNowBtn noMargin={true} /> */}
              {/*<Button*/}
              {/*  className={classes.subscribeBtn}*/}
              {/*  // style={{color: "blue", fontWeight : '700'}}*/}
              {/*  //  disabled={!courseDetail?.id }*/}

              {/*  onClick={() => history.push("/more/subscription-plan")}*/}
              {/*>*/}
              {/*  <Typography>*/}
              {/*    {reducerUser?.purchasedSubscription?.SubscriptionPlan*/}
              {/*      ?.name === "free" &&*/}
              {/*    reducerUser?.purchasedSubscription.length === 0*/}
              {/*      ? "SUBSCRIBE"*/}
              {/*      : "UPGRADE"}*/}
              {/*  </Typography>*/}
              {/*</Button>*/}
            </Grid>
          )}

          <Grid item xs={isMobile ? 3 : 6}>
            <Link to={`/view-all-courses`} className={classes.viewAll2}>
              view all
            </Link>
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            container
            className={classes.hideScrollBar}
            spacing={2}
          >
            {loader ? (
              <Grid container justify="center">
                <CircularProgress
                  color="primary"
                  style={{ color: "#09A9BB" }}
                  size={20}
                />
              </Grid>
            ) : latestCourses &&
              latestCourses.latestCoursesList &&
              latestCourses.latestCoursesList.length === 0 ? (
              <Grid item xs={12}>
                <Typography className={classes.noContent}>
                  No Record found
                </Typography>
              </Grid>
            ) : (
              latestCourses?.latestCoursesList?.map((course, ind) => {
                return (
                  <Grid item key={ind}>
                    <Link
                      to={`/view-course/${course?.slug}`}
                      style={{ textDecoration: "none" }}
                    >
                      <CourseCard
                        image={course?.image}
                        name={course?.name}
                        category={course?.category?.name}
                        totalDuration={course?.totalDuration}
                        plan={course?.plan}
                        price={course?.price}
                      />
                    </Link>
                  </Grid>
                );
              })
            )}
          </Grid>
        </Grid>
      )}
      {(recentChallenges?.length > 0 ||
        authReducerState?.user?.role !== "Regular") && (
        <Grid container style={{ marginBottom: "20px" }}>
          <Grid item xs={8}>
            <Typography
              className={classes.heading}
              classes={{ root: classes.heading }}
            >
              New Challenges
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Link to={`/view-all-challenges`} className={classes.viewAll2}>
              view all
            </Link>
          </Grid>

          <Grid
            item
            xs={12}
            container
            className={classes.hideScrollBar}
            spacing={2}
          >
            {loader ? (
              <Grid container justify="center">
                <CircularProgress
                  color="primary"
                  style={{ color: "#09A9BB" }}
                  size={20}
                />
              </Grid>
            ) : recentChallenges && recentChallenges?.length === 0 ? (
              <Grid item xs={12}>
                <Typography className={classes.noContent}>
                  No Record found
                </Typography>
              </Grid>
            ) : (
              recentChallenges?.map((challenge, ind) => {
                return (
                  <Grid item key={ind}>
                    <Link
                      to={`/challenge/details/${challenge?.id}`}
                      style={{ textDecoration: "none" }}
                    >
                      <CourseCard
                        image={challenge?.imageUrl}
                        category={challenge?.course?.category?.name}
                        friends={
                          challenge?.participantsCount > 1
                            ? `${challenge?.participantsCount} friends`
                            : challenge?.participantsCount === 1
                            ? `${challenge?.participantsCount - 1} friend`
                            : "0 friend"
                        }
                        plan={challenge?.plan}
                        price={challenge?.price}
                        name={challenge?.title}
                        totalDuration={Math.round(challenge?.duration / 60)}
                        time={moment(challenge?.exerciseTime, "LTS").format(
                          "LT"
                        )}
                      />
                    </Link>
                  </Grid>
                );
              })
            )}
          </Grid>
        </Grid>
      )}
      {(recentSessions?.length > 0 ||
        authReducerState?.user?.role !== "Regular") && (
        <Grid container style={{ marginBottom: "20px" }}>
          <Grid item xs={8}>
            <Typography
              className={classes.heading}
              classes={{ root: classes.heading }}
            >
              New Sessions
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Link to={`/view-all-sessions`} className={classes.viewAll2}>
              view all
            </Link>
          </Grid>
          <Grid
            item
            xs={12}
            container
            className={classes.hideScrollBar}
            spacing={2}
          >
            {loader ? (
              <Grid container justify="center">
                <CircularProgress
                  color="primary"
                  style={{ color: "#09A9BB" }}
                  size={20}
                />
              </Grid>
            ) : recentSessions?.length === 0 ? (
              <Grid item xs={12}>
                <Typography className={classes.noContent}>
                  No Record found
                </Typography>
              </Grid>
            ) : (
              recentSessions?.map((session, ind) => {
                return (
                  <Grid item key={ind}>
                    <Link
                      to={`/more/session/details/${session?.id}`}
                      style={{ textDecoration: "none" }}
                    >
                      <SessionCard
                        image={session?.imageUrl}
                        friends={
                          session?.sessionParticipants?.length > 1
                            ? `${session?.sessionParticipants?.length} friends`
                            : session?.sessionParticipants?.length === 1
                            ? `${session?.sessionParticipants?.length} friend`
                            : "0 friend"
                        }
                        name={session?.name}
                        session={session}
                        time={getFormattedTime(session?.startTime)}
                      />
                    </Link>
                  </Grid>
                );
              })
            )}
          </Grid>
        </Grid>
      )}

      {(latestRetreats?.length > 0 ||
        authReducerState?.user?.role !== "Regular") && (
        <Grid container style={{ marginBottom: "20px" }}>
          <Grid item xs={8}>
            <Typography
              className={classes.heading}
              classes={{ root: classes.heading }}
            >
              New Retreats
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Link to={`/view-all-retreats`} className={classes.viewAll2}>
              view all
            </Link>
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            container
            className={classes.hideScrollBar}
            spacing={2}
          >
            {retreatsLoader ? (
              <Grid container justify="center">
                <CircularProgress
                  color="primary"
                  style={{ color: "#09A9BB" }}
                  size={20}
                />
              </Grid>
            ) : latestRetreats && latestRetreats?.length === 0 ? (
              <Grid item xs={12}>
                <Typography className={classes.noContent}>
                  No Record found
                </Typography>
              </Grid>
            ) : (
              latestRetreats?.map((retreat, ind) => {
                return (
                  <Grid item key={ind}>
                    <Link
                      to={`/retreat-details/${retreat?.id}`}
                      style={{ textDecoration: "none" }}
                    >
                      <CourseCard
                        image={retreat?.image}
                        name={retreat?.name}
                        category={retreat?.category?.name}
                        totalDuration={retreat?.totalDuration}
                      />
                    </Link>
                  </Grid>
                );
              })
            )}
          </Grid>
        </Grid>
      )}

      {(latestTeacherTrainings?.length > 0 ||
        authReducerState?.user?.role !== "Regular") && (
        <Grid container style={{ marginBottom: "20px" }}>
          <Grid item xs={8}>
            <Typography
              className={classes.heading}
              classes={{ root: classes.heading }}
            >
              New Teacher Trainings
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Link
              to={`/view-all-teacher-trainings`}
              className={classes.viewAll2}
            >
              view all
            </Link>
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            container
            className={classes.hideScrollBar}
            spacing={2}
          >
            {teacherTrainingLoader ? (
              <Grid container justify="center">
                <CircularProgress
                  color="primary"
                  style={{ color: "#09A9BB" }}
                  size={20}
                />
              </Grid>
            ) : latestTeacherTrainings &&
              latestTeacherTrainings?.length === 0 ? (
              <Grid item xs={12}>
                <Typography className={classes.noContent}>
                  No Record found
                </Typography>
              </Grid>
            ) : (
              latestTeacherTrainings?.map((retreat, ind) => {
                return (
                  <Grid item key={ind}>
                    <Link
                      to={`/teacher-training-details/${retreat?.id}`}
                      style={{ textDecoration: "none" }}
                    >
                      <CourseCard
                        image={retreat?.image}
                        name={retreat?.name}
                        category={retreat?.category?.name}
                        totalDuration={retreat?.totalDuration}
                      />
                    </Link>
                  </Grid>
                );
              })
            )}
          </Grid>
        </Grid>
      )}

      {(newTeachers?.length > 0 ||
        authReducerState?.user?.role !== "Regular") && (
        <Grid container>
          <Grid item xs={8}>
            <Typography
              className={classes.heading}
              classes={{ root: classes.heading }}
            >
              New Teachers
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Link to={`/view-all-teachers`} className={classes.viewAll2}>
              view all
            </Link>
          </Grid>
          <Grid
            item
            xs={12}
            container
            className={classes.hideScrollBar}
            spacing={2}
          >
            {teacherLoader ? (
              <Grid container justify="center">
                <CircularProgress
                  color="primary"
                  style={{ color: "#09A9BB" }}
                  size={20}
                />
              </Grid>
            ) : newTeachers && newTeachers?.length === 0 ? (
              <Grid item xs={12}>
                <Typography className={classes.noContent}>
                  No Record found
                </Typography>
              </Grid>
            ) : (
              newTeachers?.map((teacher, ind) => {
                return (
                  <Grid item xs={3} key={ind} style={{ minWidth: "250px" }}>
                    <Link
                      to={`/view-profile/${teacher?.id}`}
                      style={{ textDecoration: "none" }}
                    >
                      <UserCard
                        image={teacher?.pictureUrl}
                        title={teacher?.title}
                        name={teacher?.name}
                        description={teacher?.title}
                      />
                    </Link>
                  </Grid>
                );
              })
            )}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}

export default AllCoursesSessionChallengesInstructor;

const useStyles = makeStyles((theme) => ({
  heading: {
    fontFamily: theme.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: 900,
    fontSize: "20px",
    lineHeight: "27px",
    color: "#000000",
    marginBottom: "15px",
  },
  subscribeBtn: {
    height: "2.463em",
    width: "30%",
    background: "#4DB051",
    borderRadius: "10px",
    transform: "rotate(360deg)",
    fontFamily: theme.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "10px",
    color: "#FFFFFF",
  },

  viewAll2: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "16px",
    color: "#B4B4B4",
    textDecoration: "none",
    display: "flex",
    paddingRight: "10px",
    justifyContent: "flex-end",
    paddingTop: "5px",
  },
  hideScrollBar: {
    flexWrap: "unset",
    overflowX: "auto",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
}));
