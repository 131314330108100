import React, { useEffect } from "react";
import { useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import InboxMobileContainer from "../../../containers/Mobile/More/Chat/Inbox";
import More from "../../../containers/Web/More";
import { useSelector, useDispatch } from "react-redux";
import socketIOClient from "socket.io-client";
import { getUserChats } from "../../../actions/chat";

let socket = null;

const Inbox = () => {
  const theme = useTheme();
  let dispatch = useDispatch();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const authReducer = useSelector(state => state?.auth);
  const chatsReducer = useSelector(state => state?.chat?.chats);
  const selectedRoomId = useSelector(state => state?.chat?.selectedRoomId);

  useEffect(() => {
    dispatch(getUserChats(authReducer?.token, true));
  }, []);


  return (
    <>
      {isDesktop && <More />}
      {isMobile && !isDesktop && <InboxMobileContainer />}
    </>
  );
};

export default Inbox;
