import React, { useState, useEffect, useRef, useContext } from 'react'
import { Grid, Avatar, Typography, Card, CardContent, CardActions, IconButton, CardHeader, OutlinedInput, useMediaQuery, CircularProgress, Divider } from '@material-ui/core'
import { AttachFile } from '@material-ui/icons'
import { makeStyles, useTheme } from '@material-ui/styles'
import { useHistory, useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import Send from '@material-ui/icons/Send';
import Close from '@material-ui/icons/Close';
import ReceiveMessageCard from "./ReceiveMessageCard";
import SendMessageCard from "./SendMessageCard";
import { SocketContext } from '../../Context/socketContext'
import {
    getGroupMessages,
    sendChatMedia,
} from "../../actions/chat";
import ChatParticipant from './chatParticipantCard'


function GroupChat(props) {
    const classes = useStyles();
    const theme = useTheme();
    const params = useParams();
    const dispatch = useDispatch();
    const history = useHistory();
    const scrollAble = useRef(null);
    const authReducer = useSelector(state => state.auth);
    const chatMessages = useSelector(state => state?.chat?.groupChatMessages);
    const mediaUploadProgress = useSelector(state => state?.chat?.mediaUploadProgress);
    const chatLoader = useSelector(state => state?.chat?.chatLoader);
    const challengeDetail = useSelector(state => state?.challenges?.challengeDetail)


    const [message, setMessage] = useState("");
    const [messages, setMessages] = useState([]);
    const [socketIo] = useContext(SocketContext);

    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));


    useEffect(() => {
        scrollAble.current.scrollIntoView();
    });

    useEffect(() => {
        if (challengeDetail?.type === 'Group') {
            dispatch(getGroupMessages({ token: authReducer?.token, roomId: `challenge_${params?.id}`, load: true }));
            socketIo && socketIo.emit("subscribe", `challenge_${params?.id}`);
        }

        return () => {
            socketIo && socketIo.emit("unsubscribe", `challenge_${params?.id}`);
        }
    }, [challengeDetail])

    useEffect(() => {
        if (chatMessages.length > 0) {
            setMessages(chatMessages);
        }

        return () => {
            dispatch({
                type: 'GROUP_CHAT_MESSAGES',
                payload: []
            });
        }
    }, [chatMessages?.length])


    const sendMessage = () => {
        if (message.trim().length === 0) {
            setMessage('')
        } else {
            const data = {
                roomId: `challenge_${params?.id}`,
                message: {
                    message,
                    messageType: "Text",
                    read: false,
                    senderId: authReducer?.user?.id,
                    groupType: "Challenge"
                }
            };
            socketIo && socketIo.emit("message", data);
            dispatch(getGroupMessages({ token: authReducer?.token, roomId: `challenge_${params?.id}` }));
            setMessage("");

        }
    };


    const sendMultimediaMessage = async (e) => {
        const formData = new FormData();
        formData.append("chatMedia", e.target.files[0]);
        const resUrl = await dispatch(
            sendChatMedia({
                token: authReducer?.token,
                chatMedia: formData,
                groupType: 'Challenge',
                roomId: `challenge_${params?.id}`,

            })
        );
        if (resUrl) {
            const data = {
                roomId: `challenge_${params?.id}`,
                message: {
                    message: resUrl?.chatMediaUrl,
                    messageType: "Image",
                    read: false,
                    groupType: 'Challenge',
                    senderId: authReducer?.user?.id,

                }
            };
            socketIo && socketIo.emit("message", data);
            dispatch(getGroupMessages({ token: authReducer?.token, roomId: `challenge_${params?.id}` }));
            setMessage("");
        }
    };


    return (

        <Grid container >
            <Card style={{ height: '100vh', width: '100%' }}>

                {isMobile && <CardHeader
                    className={classes.header}
                    avatar={
                        <Avatar src={challengeDetail?.imageUrl} aria-label="recipe" className={classes.avatar}>
                            {challengeDetail?.name && challengeDetail?.title[0]}
                        </Avatar>
                    }

                    title={challengeDetail?.title}
                    action={
                        <IconButton className={classes.headerAction}>
                            <Close onClick={() => history.push(`/challenge/details/${challengeDetail?.id}`)} />
                        </IconButton>
                    }
                />}
                <CardContent className={classes.messageBody}>
                    <Grid container style={{ marginBottom: '30px', paddingTop: isMobile ? '170px' : '100px' }}>
                        <Grid item xs={12} container className={classes.participantContainer} spacing={2}>
                            {
                                challengeDetail?.challengeParticipants?.map(participant => {
                                    if (participant?.id !== authReducer?.user?.id) {
                                        return <Grid item xs={3} key={participant?.id} >
                                            <ChatParticipant participant={participant} background={'#FBFAF9'} />
                                        </Grid>
                                    }
                                })

                            }
                        </Grid>
                        {challengeDetail?.challengeParticipants?.length > 0 && <Grid item xs={12} style={{ paddingBottom: '10px' }}>
                            <Divider />
                        </Grid>}
                        {chatLoader ? <Grid item xs={12} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
                            <CircularProgress size={20} style={{ color: '#09A9BB' }} />
                        </Grid> : messages?.length > 0 ? messages?.map(message => {
                            if (message?.sender?.id !== authReducer?.user?.id) {
                                return <Grid item container xs={12}>
                                    <Grid item xs={10} lg={10} style={{ marginBottom: "10px" }}>
                                        {
                                            message?.messageType === "Text" ? <ReceiveMessageCard message={message} /> : <div
                                                className={classes.mediaStyle}
                                                style={{
                                                    backgroundImage: `url(${message?.message})`,
                                                    marginRight: "15px",
                                                    marginLeft: "0px"
                                                }}
                                            ></div>
                                        }
                                    </Grid>
                                    <Grid item lg={2} xs={2} />
                                </Grid>
                            } else {
                                return <Grid item container xs={12}>
                                    <Grid item lg={2} xs={2} />
                                    <Grid item xs={10} lg={10} style={{ marginBottom: "10px" }}>
                                        {
                                            message?.messageType === "Text" ? <SendMessageCard message={message} /> : <div
                                                className={classes.mediaStyle}
                                                style={{
                                                    backgroundImage: `url(${message?.message})`,
                                                    marginRight: "15px",
                                                    marginLeft: "0px"
                                                }}
                                            ></div>
                                        }
                                    </Grid>
                                </Grid>
                            }
                        }) : (
                            <Grid item xs={12}>
                                <Typography className={classes.noContent}>
                                    No message yet
                                </Typography>
                            </Grid>
                        )
                        }
                        {mediaUploadProgress > 0 && mediaUploadProgress < 100 && (
                            <>
                                <Grid item lg={2} xs={2} />
                                <Grid item xs={10} style={{ marginBottom: "10px" }}>
                                    <div
                                        className={classes.mediaStyle}
                                        style={{
                                            background: `#c2c2c2`,
                                            marginRight: "15px",
                                            marginLeft: "0px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center"
                                        }}
                                    >
                                        <CircularProgress
                                            variant="static"
                                            value={mediaUploadProgress}
                                        />
                                    </div>
                                </Grid>
                            </>
                        )}
                        <Grid item xs={12}>
                            <div ref={scrollAble} style={{ height: '5px' }}></div>
                        </Grid>
                    </Grid>
                </CardContent>

                <CardActions style={{ bottom: 3, position: 'sticky', background: '#FBFAF9', zIndex: 200, padding: isMobile ? '0px 8px 0px 8px' : '0px 10px 0px 10px' }}>
                    <Grid container >
                        <Grid xs={9} md={10} lg={10} xl={11} >
                            <OutlinedInput
                                multiline
                                rowsMax={2}
                                fullWidth
                                placeholder={'Type here...'}
                                classes={{ root: classes.messageInputRoot, focused: classes.focused }}
                                id="outlined-adornment-weight"
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                                onKeyPress={e => {
                                    (e.which === 13 || e.keyCode === 13) && message && sendMessage();
                                }}
                                endAdornment={<>
                                    <label className={classes.inputFile}>
                                        <AttachFile className={classes.iconStyle} />
                                        <input
                                            className={classes.attachFile}
                                            type="file"
                                            accept="image/*"
                                            onChange={sendMultimediaMessage}
                                        />
                                    </label>

                                </>}
                                aria-describedby="outlined-weight-helper-text"
                                inputProps={{
                                    'aria-label': 'weight',
                                }}
                                labelWidth={0}
                            />

                        </Grid>
                        <Grid xs={3} md={2} lg={2} xl={1} style={{ textAlign: 'right' }}>
                            <IconButton onClick={message && sendMessage} style={{ background: '#09A9BB', height: '60px', width: '60px' }}>
                                <Send style={{ color: '#FFF' }} />
                            </IconButton>

                        </Grid>
                    </Grid>

                </CardActions>
            </Card>
        </Grid>
    )
}

export default GroupChat

const useStyles = makeStyles(theme => ({
    header: {
        position: 'fixed', width: '100%', top: 0, padding: '0px 0px 0px 8px', display: 'flex', alignItems: 'center', minHeight: '70px', background: '#FFF', zIndex: 200, wordBreak: 'break-all'
    },
    headerAction: {
        margin: '0px 8px 0px 0px', display: 'flex', alignItems: 'center', minHeight: '85px'
    },
    photo: {
        width: '66px',
        height: '66px',
        textAlign: 'center',
        marginLeft: '5px'
    },
    userName: {
        fontFamily: theme.fontFamily.Roboto,
        fontStyle: "normal",
        fontWeight: 'bold',
        fontSize: "16px",
        lineHeight: "137.19%",
        color: '#656565',
    },
    messageInput: {
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "green"
        },
        "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "red"
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "purple"
        }
    },
    inputFile: {
        '& input[type="file"]': {
            display: 'none'
        }
    },
    iconStyle: {
        color: '#717171'
    },
    messageInputRoot: {
        color: '#78849E',
        width: '100%',
        borderRadius: '25px',
        background: '#E3E3E3',
        border: '1px solid #E3E3E3 !important',

    },
    focused: {

        border: '1px solid #E3E3E3 !important',

    },
    messageBody: {
        background: '#FBFAF9',
        height: '100%', overflowY: 'auto',
        "&::-webkit-scrollbar": {
            width: "0px",
            background: "transparent"
        }
    },
    mediaStyle: {
        height: "270px",
        width: "100%",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        marginTop: "10px",
        borderRadius: "9px",
        backgroundPosition: "center",
        marginLeft: "15px"
    },
    noContent: {
        color: "#7d7b79",
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "16px",
        lineHeight: "19px",
        padding: "20px 10px 10px 17px",
        textAlign: "center"
    },
    participantContainer: {
        flexWrap: "unset",
        overflowX: "auto",
        "&::-webkit-scrollbar": {
            display: "none",
        },

    }
}))