

import React from 'react';
import { CardContent, Grid, Card, Avatar, Typography } from '@material-ui/core'
import { makeStyles } from "@material-ui/core/styles";
import moment from 'moment'

function LiveChatCard(props) {
    const classes = useStyles();
    const { message } = props

    return (
        <Grid container spacing={2} style={{ marginBottom: '20px' }}>
            <Grid item xs={2} sm={2} md={2} lg={2} xl={1}>
                <Avatar src={message?.sender?.pictureUrl}>{message && message?.sender?.name && message?.sender?.name[0]}</Avatar>
            </Grid>
            <Grid item xs={10} sm={10} md={10} lg={10} xl={11}>
                
                        <Grid container>
                            <Grid container item xs={9}>
                                <Grid item ><Typography className={classes.mentorName}>{message?.sender?.name}</Typography></Grid>
                            </Grid>
                            <Grid item xs={3}><Typography className={classes.time}>{moment(message?.createdAt).format('LT')}</Typography></Grid>
                            {message?.messageType !== 'Text' ? <Grid item xs={12} >
                                <div
                                    className={classes.mediaStyle}
                                    style={{
                                        backgroundImage: `url(${message?.message})`,

                                    }}
                                ></div>
                            </Grid> : <Grid item xs={12}><Typography className={classes.content}>
                                {message?.message}
                            </Typography></Grid>}
                        </Grid>
                   
            </Grid>

        </Grid>
    );
}

export default LiveChatCard;

const useStyles = makeStyles(theme => ({
    cardContainer: {
        background: '#FAF9F9',
        width: '100%',
        boxShadow: 'none'
    },

    mentorName: {
        fontFamily: theme.fontFamily.Roboto,
        fontStyle: "normal",
        fontWeight: 'bold',
        fontSize: "16px",
        lineHeight: "25px",
        color: "#383838"
    },
    time: {
        fontFamily: theme.fontFamily.Roboto,
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "12px",
        lineHeight: "14px",
        color: "#868686",
        textAlign: "right"
    },
    content: {
        fontFamily: theme.fontFamily.Roboto,
        fontStyle: "normal",
        fontWeight: 900,
        fontSize: "16px",
        lineHeight: "25px",
        color: "#888888"
    },
    mediaStyle: {
        height: "270px",
        width: "100%",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        marginTop: "10px",
        borderRadius: "9px",
        backgroundPosition: "center",
    },

}));