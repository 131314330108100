import { useEffect } from "react";
import {
  Grid,
  Typography,
  CircularProgress,
  useMediaQuery,
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import CourseCard from "../../../components/Common/CourseCard";
import TeacherCard from "../../../components/Common/UserCard";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { useIntl } from "react-intl";
import { makeStyles } from "@material-ui/styles";

const ViewAllCourses = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const intl = useIntl();
  const dispatch = useDispatch();

  let auth = useSelector((state) => state.auth);
  const searchData = useSelector((state) => state?.courses?.searchData);
  const loader = useSelector((state) => state?.loader?.loader);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    return () => {
      dispatch({
        type: "SEARCH",
        payload: [],
      });
    };
  }, []);

  return (
    <Grid container>
      <Grid
        item
        container
        xs={12}
        style={{ padding: isMobile ? "0px 0px 80px 0px" : "0px 0px 30px 0px" }}
      >
        {loader ? (
          <Grid
            item
            xs={12}
            className={classes.center}
            style={{ minHeight: "65vh" }}
          >
            <CircularProgress
              color="primary"
              style={{ color: "#09A9BB" }}
              size={30}
            />
          </Grid>
        ) : searchData?.length === 0 ? (
          <Grid
            item
            xs={12}
            className={classes.center}
            style={{ minHeight: "65vh" }}
          >
            <Typography className={classes.noContent}>
              No Record found
            </Typography>
          </Grid>
        ) : (
          <Grid
            item
            xs={12}
            container
            style={{ minHeight: "65vh" }}
            spacing={2}
          >
            {searchData?.map((item, ind) => {
              if (props?.searchSpecificIn === "Course") {
                return (
                  <Grid
                    item
                    xs={12}
                    md={4}
                    lg={3}
                    key={ind}
                    style={{ paddingBottom: "15px" }}
                  >
                    <Link
                      to={`/view-course/${item?.slug}`}
                      style={{ textDecoration: "none" }}
                    >
                      {console.log("item", item)}
                      <CourseCard
                        image={item?.image}
                        name={item?.name}
                        totalDuration={item?.totalDuration}
                        width="100%"
                        plan={item?.plan}
                        price={item?.price}
                      />
                    </Link>
                  </Grid>
                );
              } else if (props?.searchSpecificIn === "Retreat") {
                return (
                  <Grid
                    item
                    xs={12}
                    md={4}
                    lg={3}
                    key={ind}
                    style={{ paddingBottom: "15px" }}
                  >
                    <Link
                      to={`/retreat-details/${item?.id}`}
                      style={{ textDecoration: "none" }}
                    >
                      <CourseCard
                        image={item?.image}
                        name={item?.name}
                        totalDuration={item?.totalDuration}
                        width="100%"
                        plan={item?.plan}
                      />
                    </Link>
                  </Grid>
                );
              } else if (props?.searchSpecificIn === "TeacherTraining") {
                return (
                  <Grid
                    item
                    xs={12}
                    md={4}
                    lg={3}
                    key={ind}
                    style={{ paddingBottom: "15px" }}
                  >
                    <Link
                      to={`/teacher-training-details/${item?.id}`}
                      style={{ textDecoration: "none" }}
                    >
                      <CourseCard
                        image={item?.image}
                        name={item?.name}
                        totalDuration={item?.totalDuration}
                        width="100%"
                        plan={item?.plan}
                      />
                    </Link>
                  </Grid>
                );
              } else if (props?.searchSpecificIn === "Challenge") {
                return (
                  <Grid
                    item
                    xs={12}
                    md={4}
                    lg={3}
                    key={ind}
                    style={{ paddingBottom: "15px" }}
                  >
                    <Link
                      to={`/challenge/details/${item?.id}`}
                      style={{ textDecoration: "none" }}
                    >
                      <CourseCard
                        image={item?.imageUrl}
                        name={item?.title}
                        width="100%"
                        plan={item?.plan}
                      />
                    </Link>
                  </Grid>
                );
              } else if (props?.searchSpecificIn === "Session") {
                return (
                  <Grid
                    item
                    xs={12}
                    md={4}
                    lg={3}
                    key={ind}
                    style={{ paddingBottom: "15px" }}
                  >
                    <Link
                      to={`/more/session/details/${item?.id}`}
                      style={{ textDecoration: "none" }}
                    >
                      <CourseCard
                        image={item?.imageUrl}
                        name={item?.name}
                        width="100%"
                        plan={item?.plan}
                      />
                    </Link>
                  </Grid>
                );
              } else if (props?.searchSpecificIn === "Instructor") {
                return (
                  <Grid
                    item
                    xs={12}
                    md={4}
                    lg={3}
                    key={ind}
                    style={{ paddingBottom: "15px" }}
                  >
                    <Link
                      to={`/view-profile/${item?.id}`}
                      style={{ textDecoration: "none" }}
                    >
                      <TeacherCard
                        image={item?.pictureUrl}
                        title={item?.title}
                        name={item?.name}
                        description={item?.title}
                      />
                    </Link>
                  </Grid>
                );
              }
            })}
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};
export default ViewAllCourses;

const useStyles = makeStyles((theme) => ({
  center: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  noContent: {
    color: "#7d7b79",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "19px",

    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));
