import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import masterCardIcon from "../../../assets/Icons/masterCardIcon.svg";
import visaCardIcon from "../../../assets/Icons/visaCardIcon.svg";
import paypalCardIcon from "../../../assets/Icons/PaypalCardIcon.svg";
import { useTheme } from "@material-ui/core/styles";
import {
  Grid,
  Card,
  CardContent,
  Button,
  Typography,
  useMediaQuery,
  Avatar,
  IconButton
} from "@material-ui/core";
import { useLocation } from "react-router-dom";

import { useIntl } from "react-intl";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { Delete } from "@material-ui/icons";

function Payment(props) {
  const theme = useTheme();
  const intl = useIntl();
  const classes = useStyles();

  return (
    <Card
      onClick={() => props?.isClickable && props?.handleClick()}
      className={classes.cardContainer}
      style={{ cursor: props?.isClickable && "pointer"}}
    >
      <Grid container className={classes.container} justify="space-between">
        <Grid item>
          <img
            alt=""
            style={{ width: "50px", height: "50px", borderRadius: "5px" }}
            src={props?.brand === "Visa" ? visaCardIcon : masterCardIcon}
          />
          {/* <Avatar src={img} className={classes.image} /> */}
        </Grid>
        <Grid item xs={7}>
          <Typography className={classes.username}>
            XXXX- {props?.number}
          </Typography>
          {props?.year && (
            <Typography className={classes.name}>
              {"Expiring in"} {props?.year}
            </Typography>
          )}
        </Grid>
        <Grid item xs={2}>
          {props?.isClickable ? (
            <CheckCircleIcon
              style={{
                fontSize: "32px",
                color: props?.isSelected ? "#85EBFF" : "",
                backgroundColor: "#fff"
              }}
            />
          ) : (
            <IconButton onClick={props.handleDelete}>
              <Delete
                style={{
                  color: "#09A9BB"
                }}
              />
            </IconButton>
          )}
        </Grid>
      </Grid>
    </Card>
  );
}

export default Payment;

const useStyles = makeStyles(theme => ({
  cardContainer: {
    borderRadius: "11px",
    backgroundColor: "#FFFFFF",
    boxShadow: " 0px 4px 4px rgba(0, 0, 0, 0.05)",
    paddingLeft: "10px",
    paddingRight: "10px"
  },
  container: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    backgroundColor: "#FFFFFF",
    height: "6.5rem",
    transform: "rotate(360deg)"
  },

  username: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "15px",
    lineHeight: "18px",
    color: "#3C4A6B",
    marginLeft: "5px",
    color: " #192247"
  },
  name: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "8px",
    lineHeight: "9px",
    color: "#3C4A6B",
    marginTop: "5px",
    marginLeft: "5px",
    color: "#F94C5E"
  },
  btn: {
    borderRadius: "26px",
    height: "64px",
    width: "64px",
    color: "#FFF",
    background: "#fbae02",
    "&:hover": {
      background: "#fbae02"
    }
  },

  btnStyle: {
    width: "100%",
    height: "2.750em",
    background: "linear-gradient(180deg, #FFC000 -13.24%, #F28705 100%)",
    borderRadius: "11px",
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "20px",
    lineHeight: "23px",
    color: "#FFFFFF",
    marginBottom: "30px"
  },
  heading: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "24px",
    lineHeight: "28px",
    color: "#3D3D3D",
    textAlign: "center",
    marginTop: "60px"
  },
  paymentTypeCard: {
    height: "6.500em",
    width: "9.438em",
    marginRight: "0px",
    background: "#FFFFFF"
  }
}));
