import { combineReducers } from "redux";
import auth from "./auth";
import message from "./message";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import courses from "./courses";
import setting from "./setting";
import publicUser from "./publicUser";
import user from "./user";
import session from "./session";
import userRelations from "./userRelations";
import chat from "./chat";
import challenges from "./challenges";
import teacher from "./teacher";
import stripe from "./stripe";
import loader from "./Loader";
import notifications from "./notifications";
import trainingCourse from "./trainingCourses";
import retreatCourse from "./retreatCourseReducers";
import subscriptionPlan from './subscriptionPlan'

export default persistReducer(
  {
    key: "rrsb", // key is required
    storage, // storage is now required
    whitelist: [""]
  },
  combineReducers({
    auth,
    session,
    message,
    courses,
    userRelations,
    subscriptionPlan,
    user,
    chat,
    setting,
    publicUser,
    challenges,
    stripe,
    notifications,
    teacher,
    loader,
    trainingCourse,
    retreatCourse
  })
);
