import React, { useEffect, useState } from "react";
import { useMediaQuery, CircularProgress, Typography, Grid } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import PlayRetreatWebContainer from "../../containers/Web/PlayRetreat";
import PlayRetreatMobileContainer from "../../containers/Mobile/PlayRetreat";
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import { getTrainingCourseDetailById, getTrainingCourseRequests, getTrainingCourseContent, getTrainingCourseParticipants } from '../../actions/retreatCourse'
import TopBar from "../../components/AppBar";

const PlayRetreat = () => {
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams();
  const [loader, setLoader] = useState(true)
  const [error, setError] = useState(null)
  const auth = useSelector(state => state?.auth)
  const user = useSelector(state => state?.auth?.user)
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const getData = async () => {
    if (params.id) {
      const retreatId = params?.id.split("-");
      const id = retreatId[retreatId.length - 1]


      setLoader(true);
      const contentRes = await dispatch(getTrainingCourseContent({ token: auth?.token, trainingCourseId: id }));
      const res = await dispatch(getTrainingCourseDetailById({ token: auth?.token, courseId: id }));
      await dispatch(getTrainingCourseParticipants({ courseId: id, token: auth?.token }));
      if (res?.id) {
        if (user?.id === res?.user?.id) {
          const requestsRes = await dispatch(getTrainingCourseRequests({ token: auth?.token, trainingCourseId: id }));
          if (requestsRes) {
            setLoader(false);
          }
        }
        setLoader(false);
      } else if (res?.status === 'fail') {
        setError(res.message)
        setLoader(false);
      }
    }
  }

  useEffect(() => {
    getData();
    return () => {
      dispatch({
        type: 'TRAINING_COURSE_DETAIL',
        payload: {}
      })
      dispatch({
        type: 'TRAINING_COURSE_REQUESTS',
        payload: []
      })
      dispatch({
        type: 'TRAINING_COURSE_CONTENT',
        payload: []
      })
      dispatch({
        type: 'TRAINING_COURSE_PARTICIPANTS',
        payload: []
      });
    }
  }, [])

  return (
    <>
      {
        loader ? <div style={{ height: '100vh', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><CircularProgress style={{ color: '#0EB2EF' }} /></div> : error ?
          <Grid container >
            <Grid item xs={11} style={{ display: 'flex', alignItems: 'center', height: '87px' }}>
              <TopBar
                appBarConfig={{ show: true, text: 'Back', backgroundColor: "#fff", backAction: () => history.goBack() }}

              />
            </Grid>
            <Grid xs={12} item>
              <div style={{ height: '100vh', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Typography >
                  {error}
                </Typography>
              </div></Grid></Grid> :
          (isMobile ? <PlayRetreatMobileContainer /> : <PlayRetreatWebContainer />)
      }

    </>
  );
};

export default PlayRetreat;
