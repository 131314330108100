import React, { useState, useEffect } from "react";
import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import MainMenuCard from "../../../components/More/mainMenuCard";
import {
  ArrowBack,
  Assignment,
  Group,
  Email,
  Settings,
  Payment,
  ExitToApp,
  PeopleAlt,
  CardMembership
} from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getAllSessions } from "../../../actions/Session";
import { useIntl } from "react-intl";

function ChatMainMenu(props) {
  const classes = useStyles();
  const intl = useIntl();
  const history = useHistory();
  const dispatch = useDispatch();
  const chatsReducer = useSelector(state => state?.chat?.chats);
  const [unreadMessages, setUnreadMessages] = useState(0);
  const sessionsList = useSelector(state => state.session.sessionsList);
  const authReducerState = useSelector(state => state.auth);
  const allPendingRequests = useSelector(
    state => state?.userRelations?.pendingRequests
  );
  useEffect(() => {
    dispatch(
      getAllSessions({ token: authReducerState && authReducerState.token })
    );
  }, []);

  const logOut = () => {
    dispatch({ type: "LOG_OUT" });
    localStorage.setItem("userDataLocal", null);
    process.env.REACT_APP_LANDING_URL ? (window.location.href = process.env.REACT_APP_LANDING_URL) : history.push("/auth")
  };

  useEffect(() => {
    let noOfUnReadMessages = chatsReducer
      ?.map(chat => {
        return chat?.unreadMessages?.length;
      })
      .reduce((prev, next) => prev + next, 0);
    setUnreadMessages(noOfUnReadMessages);
  }, [chatsReducer]);

  return (
    <Grid container>
      <Grid
        item
        container
        xs={12}
        style={{
          background: "#F1F1F4",
          minHeight: "100vh",
          marginBottom: "70px"
        }}
      >
        <Grid item xs={12} container>
          <div className={classes.header}>
            <Grid container>
              <Grid item xs={2} className={classes.headerTitle}>
                <ArrowBack onClick={() => history.goBack()} />
              </Grid>
              <Grid item xs={8}>
                <Typography className={classes.headerTitle}>More</Typography>
              </Grid>
            </Grid>
          </div>

          <div style={{ marginTop: "-35px" }}>
            <Grid container>
              <Grid item xs={12} style={{ margin: "0 15px 20px 15px" }}>
                <Link
                  to="/more/relate-with-friends"
                  style={{ textDecoration: "none" }}
                >
                  <MainMenuCard
                    IconContainerBackground="#fce7cd"
                    Icon={
                      <Group style={{ color: "#F28705", fontSize: "40px" }} />
                    }
                    title="Relate With friends"
                    detail={
                      sessionsList.length === 0
                        ? ""
                        : sessionsList.length > 1
                          ? `${sessionsList.length} sessions`
                          : `${sessionsList.length} session`
                    }
                  />
                </Link>
              </Grid>
              <Grid item xs={12} style={{ margin: "0 15px 20px 15px" }}>
                <Link to="/more/inbox" style={{ textDecoration: "none" }}>
                  <MainMenuCard
                    IconContainerBackground="#ceeef1"
                    Icon={
                      <Email style={{ color: "#09A9BB", fontSize: "40px" }} />
                    }
                    title="Inbox"
                    detail={
                      unreadMessages > 0 && unreadMessages < 10
                        ? `${unreadMessages} New message`
                        : unreadMessages > 10
                          ? `${unreadMessages} New messages`
                          : ""
                    }
                  />
                </Link>
              </Grid>
              <Grid item xs={12} style={{ margin: "0 15px 20px 15px" }}>
                <Link to="/more/connections" style={{ textDecoration: "none" }}>
                  <MainMenuCard
                    IconContainerBackground="#fce7cd"
                    Icon={
                      <PeopleAlt
                        style={{ color: "#F28705", fontSize: "40px" }}
                      />
                    }
                    title={intl.formatMessage({ id: "connections" })}
                    detail={
                      allPendingRequests?.length > 0 &&
                        allPendingRequests?.length < 10
                        ? `${allPendingRequests?.length} new connection pending`
                        : allPendingRequests?.length > 10
                          ? `${unreadMessages} new connections pending`
                          : ""
                    }
                  />
                </Link>
              </Grid>

              <Grid item xs={12} style={{ margin: "0 15px 20px 15px" }}>
                <Link
                  to="/more/challenges-stats"
                  style={{ textDecoration: "none" }}
                >
                  <MainMenuCard
                    IconContainerBackground="#fce7cd"
                    Icon={
                      <Assignment
                        style={{ color: "#F28705", fontSize: "40px" }}
                      />
                    }
                    title="Challenges & Stats"
                  />
                </Link>
              </Grid>
              <Grid
                item
                xs={12}
                style={{ margin: "0 15px 20px 15px" }}
              >
                <Link
                  to="/more/payment-methods"
                  style={{ textDecoration: "none" }}
                >
                  <MainMenuCard
                    IconContainerBackground="#f2d0cd"
                    Icon={
                      <Payment style={{ color: "#E54D40", fontSize: "40px" }} />
                    }
                    title="Payment Method"
                  />
                </Link>
              </Grid>

              <Grid item xs={12} style={{ padding: "0 15px 20px 15px" }}>
                <Link
                  to="/more/subscription"
                  style={{ textDecoration: "none" }}
                >
                  <MainMenuCard
                    IconContainerBackground="#ceeef1"
                    Icon={
                      <CardMembership
                        style={{
                          color: "#09A9BB",
                          fontSize: "40px"
                        }}
                      />
                    }
                    title="Subscriptions"
                  />
                </Link>

              </Grid>

              <Grid item xs={12} style={{ margin: "0 15px 20px 15px" }}>
                <Link to="/more/settings" style={{ textDecoration: "none" }}>
                  <MainMenuCard
                    IconContainerBackground="#d0d7de"
                    Icon={
                      <Settings
                        style={{ color: "#153959", fontSize: "40px" }}
                      />
                    }
                    title="Settings"
                  />
                </Link>
              </Grid>
              <Grid
                item
                xs={12}
                style={{ margin: "0 15px 20px 15px" }}
                onClick={logOut}
              >
                <MainMenuCard
                  IconContainerBackground="#f2d0cd"
                  Icon={
                    <ExitToApp style={{ color: "#E54D40", fontSize: "40px" }} />
                  }
                  title="Logout"
                />
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default ChatMainMenu;

const useStyles = makeStyles(theme => ({
  header: {
    background: "#09A9BB",
    borderRadius: "0 0 34px 34px",
    height: "110px",
    width: "100%",
    alignItems: "flex-start",
    display: "flex",
    justifyContent: "center"
  },
  headerTitle: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "bold",
    marginTop: "2rem",
    fontSize: "24px",
    lineHeight: "28px",
    color: "#FFF",
    textAlign: 'center'
  }
}));
