import React, { useState, useEffect, useContext } from "react";
import {
  Grid,
  Box,
  TextField,
  useMediaQuery,
  CircularProgress,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import CardWithThumbnail from "../../../components/More/settings/CardWithThumbnailItem";
import { Search } from "@material-ui/icons";
import LocaleContext from "../../../providers/Locale/Context";
import AppBar from "../../../components/AppBar/index";
import { useDispatch, useSelector } from "react-redux";
import { getAllLanguages, updateSetting } from "../../../actions/setting";
import { useTheme } from "@material-ui/core/styles";
import MobileAppbar from "../../../components/More/settings/AppBar";
import { useIntl } from "react-intl";
function Languages(props) {
  const classes = useStyles();
  let dispatch = useDispatch();
  const intl = useIntl();
  const { setLocale } = useContext(LocaleContext);
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  const [searchText, setSearchText] = useState("");
  const [currentIcon, setCurronIcon] = useState(false);
  let authReducerState = useSelector((state) => state.auth);
  let updateLanguage = useSelector((state) => state.message);

  const allLanguages = useSelector((state) => state?.setting?.allLanguages);

  const currentLanguage = useSelector(
    (state) => state?.setting?.settings?.language
  );

  useEffect(() => {
    if (updateLanguage.show === true && updateLanguage.severity === "success") {
      setTimeout(props.handleBack(null), 2000);
    }
  }, [updateLanguage]);
  useEffect(() => {
    if (currentLanguage && currentLanguage.id) {
      switch (currentLanguage.name) {
        case "English (USA)":
          setLocale("en");
          break;
        case "English (UK)":
          setLocale("en");
          break;
        case "Spanish":
          setLocale("es");
          break;
        case "Swedish":
          setLocale("sv");
          break;
        case "German":
          setLocale("de");
          break;
        default:
          break;
      }
    }
  }, [currentLanguage]);
  function getImage(props) {
    return (
      <div
        style={{
          height: "3.1em",
          width: "5em",
          marginTop: "10px",
          marginRight: "30px",
        }}
      >
        <img style={{ height: "2.3em", width: "4em" }} src={props} alt="" />
      </div>
    );
  }

  useEffect(() => {
    dispatch(getAllLanguages({ token: authReducerState.token }));
  }, []);

  const handleClick = (id) => {

    let obj = allLanguages.find((obj) => obj.id === id);
    dispatch(
      updateSetting({
        token: authReducerState.token,
        setting: { languageId: id, language: { ...obj } },
      })
    );
  };
  const searchFieldChangeHandler = (e) => {
    setSearchText(e.target.value);
  };

  return (
    <Grid container lg={8} md={8} sm={12} xs={12} xl={8}>
      {isMobile && !isDesktop ? (
        <MobileAppbar
          body={intl.formatMessage({ id: "selectLanguage" })}
          onClick={props.handleBack}
          backAction={intl.formatMessage({ id: "settings" })}
          backgroundColor="#FE7271"
        />
      ) : (
        <div style={{ padding: "12px" }}>
          <AppBar
            appBarConfig={{
              show: true,
              text: intl.formatMessage({ id: "Language" }),
              backAction: props.handleBack,
            }}
          />
        </div>
      )}

      <Grid item xs={10} mx={1} style={{ marginLeft: "25px" }}>
        <Grid
          item
          xs={12}
          sm={10}
          md={10}
          lg={10}
          style={{ marginLeft: "1rem" }}
        >
          <TextField
            classes={{
              root: classes.rootTextField,
            }}
            type="Search"
            variant="outlined"
            value={searchText}
            style={{ width: "100%" }}
            placeholder={intl.formatMessage({ id: "search" })}
            name="search"
            onChange={searchFieldChangeHandler}
            InputProps={{
              endAdornment: <Search style={{ cursor: "pointer" }} />,
            }}
          />
        </Grid>
      </Grid>
      {allLanguages.length === 0 ? (
        <Grid
          item
          xs={10}
          mx={1}
          style={{
            marginLeft: "2rem",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <CircularProgress style={{ color: "#FE7271" }} size={25} />
        </Grid>
      ) : (
        allLanguages
          .filter((language) =>
            language.name.toUpperCase().includes(searchText.toUpperCase())
          )
          .map((item) => {
            return (
              <Grid item xs={10} mx={1} style={{ marginLeft: "2rem" }}>
                <CardWithThumbnail
                  rootCard={classes.root}
                  title={item.name}
                  IconContainerBackground={classes.iconColor}
                  Icon={getImage(item.flag)}
                  handleClick={handleClick}
                  iconStyle={classes.iconStyle}
                  currentIcon={currentIcon}
                  handleChange={setCurronIcon}
                  titleStyle={classes.title}
                  showImage={true}
                  id={item.id}
                />
              </Grid>
            );
          })
      )}
    </Grid>
  );
}

export default Languages;

const useStyles = makeStyles((theme) => ({
  rootTextField: {
    "& .MuiInputBase-root": {
      backgroundColor: theme.colors.inputBoxBg,
      fontFamily: theme.fontFamily.Roboto,
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "18px",
      lineHeight: "21px",
      padding: "10px",
      marginTop: "35px",
      marginBottom: "45px",
      height: "58px",
      width: "100%",
      borderRadius: "10px",
    },
    "&.MuiBadge-colorPrimary": {
      backgroundColor: theme.colors.inputBoxBg,
    },
    "&.MuiInput-underline:before": {
      border: `0px solid ${theme.colors.inputBoxBg}`,
    },
    "& .MuiInput-underline:after": {
      border: `0px solid ${theme.colors.inputBoxBg}`,
    },
    "&.MuiInput-underline:hover:not(.Mui-disabled)::before": {
      border: `0px solid ${theme.colors.inputBoxBg}`,
    },
  },
  feedSearchField: {
    backgroundColor: "#dce8f2",
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: " 18px",
    lineHeight: " 21px",
    marginTop: "35px",
    width: "100%",
    borderRadius: "10px",
    border: `0px solid #dce8f2`,
  },
  iconStyle: {
    fontSize: 30,
    textAlign: "center",
  },
  iconColor: `theme.palette.primary.contrastText`,
  selectedIconStyle: {
    color: "red",
    fontSize: 30,
    width: "10px",
  },
  title: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "18px",
    lineHeight: "21px",
    color: "#3C4A6B",
  },
  body: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "21px",
  },
  root: {
    width: "100%",
    height: "5rem",
    borderRadius: "11px",
    transform: "rotate(360deg)",
  },
  container: {
    marginTop: "0px",
  },
  paper: {
    position: "absolute",
    height: 250,
    maxWidth: 315,
    minWidth: 300,
    justifyContent: "center",
  },
  modalDesc: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "24px",
    lineHeight: "28px",
    color: "#3C4A6B",
    textAlign: "center",
  },
  availabilityMsg: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "18px",
    textAlign: "center",
    lineHeight: "21px",
    color: "#87AC26",
    padding: "10px",
  },
  inputBox: {
    textAlign: "center",
    padding: "10px",
  },
}));
