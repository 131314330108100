import React, { useState, useEffect } from "react";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import CourseInstructorIntroCard from "../../../components/ViewCourse/CourseInstructorIntroCard/InstructorIntroCardMobileView";
import CourseReviews from "../../../components/ViewCourse/CourseReviews";
import PostReviewCard from "../../../components/ViewCourse/PostReviewCard";
import CourseViewEpisodesCard from "../../../components/ViewCourse/CourseViewEpisodesCard";
import { Helmet } from "react-helmet";
import {
  Grid,
  Card,
  Typography,
  Button,
  Tab,
  Tabs,
  Box,
  AppBar,
  Modal,
  Dialog,
  DialogContent,
  DialogActions,
  Tooltip,
  DialogTitle,
  DialogContentText,
  IconButton,
  Switch,
  FormControlLabel,
} from "@material-ui/core";
import {
  Close,
  Share,
  InsertInvitation,
  ArrowBack,
  Edit,
  HighlightOff,
  Error,
} from "@material-ui/icons";
import InviteAndShare from "../../Common/CoursesContainer/InviteAndShare";
import CustomerCardList from "../../../components/Common/CustomerCardList";
import { useDispatch, useSelector } from "react-redux";
import {
  getCourseDetailById,
  addFavoriteCourse,
  deleteFavoriteCourse,
  joinFreeCourse,
  courseActiveInActive,
  joinUnderSubscriptionCourse,
} from "../../../actions/courses";
import CourseStats from "../../../components/ViewCourse/CourseStats";
import { createChallengeData } from "../../../actions/challenges";
import { useHistory } from "react-router-dom";
import "./viewCourseMobile.scss";
import { useIntl } from "react-intl";
import Rating from "@material-ui/lab/Rating";
import CoursePreview from "../../../components/ViewCourse/CoursePreview";
import heartIconSelected from "../../../assets/Icons/favoriteHeartIconSelected.png";
import heartIcon from "../../../assets/Icons/BottomNavigationIcons/heartIcon.png";
import ExpireSubscriptionPopup from "../../../components/Common/ExpireSubscriptionPopup";
import CoursePlanCard from "../../../components/ViewCourse/CoursePlanCard";
import RecommendUpgrade from "../../../components/Common/recommentUpgradeSubscriptionPopup";
import { getPrice } from "../../../common/methods";

const CustomSwitch = withStyles({
  switchBase: {
    "&$checked": {
      color: "#29cc10",
    },
    "&$checked + $track": {
      backgroundColor: "#29cc10",
    },
  },
  checked: {},
  track: {},
})(Switch);

function ViewCourseDetailMobile(props) {
  const classes = useStyles();
  const intl = useIntl();

  let dispatch = useDispatch();
  let history = useHistory();
  const theme = useTheme();
  let courseDetail = useSelector((state) => state?.courses?.courseDetail);
  const [joinDialog, setJoinDialog] = useState(false);
  const [subscribeDialog, setSubscribeDialog] = useState(false);
  const [value, setValue] = React.useState(0);
  const [modalStyle] = useState(getModalStyle);
  const [openModel, setOpenModel] = useState(false);
  const [modalType, setModalType] = useState("");
  const [courseStatus, setCourseStatus] = useState(false);
  const [recommendUpgrade, setRecommendUpgrade] = useState(false);
  const [noEpisode, setNoEpisode] = useState(false);
  const [shareOpen, setShareOpen] = useState(false);
  const [openPaymentDialog, setOpenPaymentDialog] = useState(false);
  const [subscriptionExpire, setSubscriptionExpire] = useState(false);

  const [errors, setErrors] = useState(false);
  const [messages, setMessages] = useState("");

  let configurations = useSelector((state) => state?.setting?.siteConfig);
  const courseStats = useSelector((state) => state?.courses?.courseStats);
  let authReducerState = useSelector((state) => state.auth);
  let reducerUser = useSelector((state) => state?.user?.user);
  console.log(reducerUser, "reducerUser?.isExpiredSubscription");
  useEffect(() => {
    if (courseDetail?.id) {
      setCourseStatus(courseDetail?.status === "Active" ? true : false);
    }
  }, [courseDetail]);

  function getModalStyle() {
    const top = 50;
    const left = 50;
    return {
      borderColor: theme.colors.white,
      borderRadius: "4px",
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top - 4}%, -${left}%)`,
    };
  }

  const modalBody = (
    <Grid
      container
      direction="row"
      spacing={3}
      style={modalStyle}
      className={classes.paper}
    >
      <Grid item xs={12}>
        <HighlightOff
          onClick={() => handleCloseModal()}
          className={classes.closeIcon}
        />
      </Grid>
      <Grid item className={classes.msgBody}>
        <Typography className={classes.modelBodyText}>
          {modalType === "createChallenge"
            ? "Please join course first to create challenge."
            : modalType === "joinCourse"
            ? "You have not joined this course. Please join to get access of this course"
            : "This feature is not available right now you can start  free course or with 0 price only."}
        </Typography>
      </Grid>
      <Grid item className={classes.modalBtnGrid}>
        {modalType === "joinCourse" ? (
          <Button
            className={classes.modelBtn}
            disabled={!courseDetail?.id}
            onClick={() => {
              if (
                courseDetail?.plan?.name === "Donation" ||
                courseDetail?.plan?.name === "Free" ||
                courseDetail?.plan?.name === "Freemium" ||
                courseDetail?.plan?.name === "Subscription" ||
                courseDetail?.plan?.name === "Paid"
              ) {
                /*  {
                setJoinDialog(true);
              } else */ joinCourseHandler();
              }
            }}
          >
            <Typography>
              {/*  {courseDetail?.plan?.name === "Donation" ||
              courseDetail?.plan?.name === "Free"
              ? "Join Now Free" : courseDetail?.plan?.name === "Subscription" ? `Join Now`
                : `Join Now ${configurations?.currency} ${getPrice(courseDetail?.price
                  ? courseDetail?.price
                  : courseDetail?.plan?.price
                    ? courseDetail?.plan?.price
                    : 0,reducerUser?.purchasedSubscription)
                }`} */}
              {courseDetail?.plan?.name === "Donation" ||
              courseDetail?.plan?.name === "Free" ? (
                "Join for free"
              ) : courseDetail?.plan?.name === "Subscription" ? (
                `Subscribe`
              ) : (
                <>
                  {`Join Now For `}
                  {reducerUser?.purchasedSubscription?.length === 0 ? (
                    `${configurations?.currency}${
                      courseDetail?.price
                        ? courseDetail?.price
                        : courseDetail?.plan?.price
                        ? courseDetail?.plan?.price
                        : 0
                    }`
                  ) : (reducerUser?.purchasedSubscription[0]?.interval ===
                      "monthly" &&
                      reducerUser?.purchasedSubscription[0]?.SubscriptionPlan
                        ?.omidDiscount > 0) ||
                    (reducerUser?.purchasedSubscription[0]?.interval ===
                      "annual" &&
                      reducerUser?.purchasedSubscription[0]?.SubscriptionPlan
                        ?.annualOmidDiscount > 0) ? (
                    <strike>
                      {`${configurations?.currency}${
                        courseDetail?.price
                          ? courseDetail?.price
                          : courseDetail?.plan?.price
                          ? courseDetail?.plan?.price
                          : 0
                      }`}
                    </strike>
                  ) : (
                    <></>
                  )}
                  {reducerUser?.purchasedSubscription?.length > 0 && (
                    <span style={{ paddingLeft: "2px" }}>
                      {`(${configurations?.currency}${getPrice(
                        courseDetail?.price
                          ? courseDetail?.price
                          : courseDetail?.plan?.price
                          ? courseDetail?.plan?.price
                          : 0,
                        reducerUser?.purchasedSubscription
                      )})`}
                    </span>
                  )}
                </>
              )}
            </Typography>
          </Button>
        ) : (
          /*  <Button
            onClick={() => handleCloseModal()}
            className={classes.modelBtn}
          >
            {intl.formatMessage({ id: "okay" })}
          </Button> */

          <Button
            className={classes.modelBtn}
            disabled={!courseDetail?.id}
            onClick={() => {
              if (
                courseDetail?.plan?.name === "Donation" ||
                courseDetail?.plan?.name === "Free" ||
                courseDetail?.plan?.name === "Freemium" ||
                courseDetail?.plan?.name === "Subscription" ||
                courseDetail?.plan?.name === "Paid"
              ) {
                /*  {
              setJoinDialog(true);
            } else */ joinCourseHandler();
              }
            }}
          >
            <Typography>
              {/*  {courseDetail?.plan?.name === "Donation" ||
            courseDetail?.plan?.name === "Free"
            ? "Join Now Free" : courseDetail?.plan?.name === "Subscription" ? `Join Now`
              : `Join Now ${configurations?.currency} ${getPrice(courseDetail?.price
                ? courseDetail?.price
                : courseDetail?.plan?.price
                  ? courseDetail?.plan?.price
                  : 0,reducerUser?.purchasedSubscription)
              }`} */}
              {courseDetail?.plan?.name === "Donation" ||
              courseDetail?.plan?.name === "Free" ? (
                "Join for free"
              ) : courseDetail?.plan?.name === "Subscription" ? (
                `Subscribe`
              ) : (
                <>
                  {`Join Now For `}
                  {reducerUser?.purchasedSubscription?.length === 0 ? (
                    `${configurations?.currency}${
                      courseDetail?.price
                        ? courseDetail?.price
                        : courseDetail?.plan?.price
                        ? courseDetail?.plan?.price
                        : 0
                    }`
                  ) : (reducerUser?.purchasedSubscription[0]?.interval ===
                      "monthly" &&
                      reducerUser?.purchasedSubscription[0]?.SubscriptionPlan
                        ?.omidDiscount > 0) ||
                    (reducerUser?.purchasedSubscription[0]?.interval ===
                      "annual" &&
                      reducerUser?.purchasedSubscription[0]?.SubscriptionPlan
                        ?.annualOmidDiscount > 0) ? (
                    <strike>
                      {`${configurations?.currency}${
                        courseDetail?.price
                          ? courseDetail?.price
                          : courseDetail?.plan?.price
                          ? courseDetail?.plan?.price
                          : 0
                      }`}
                    </strike>
                  ) : (
                    <></>
                  )}
                  {reducerUser?.purchasedSubscription?.length > 0 && (
                    <span style={{ paddingLeft: "2px" }}>
                      {`(${configurations?.currency}${getPrice(
                        courseDetail?.price
                          ? courseDetail?.price
                          : courseDetail?.plan?.price
                          ? courseDetail?.plan?.price
                          : 0,
                        reducerUser?.purchasedSubscription
                      )})`}
                    </span>
                  )}
                </>
              )}
            </Typography>
          </Button>
        )}
      </Grid>
    </Grid>
  );

  const noEpisodeBody = (
    <Grid
      container
      direction="row"
      spacing={3}
      style={modalStyle}
      className={classes.paper}
    >
      <Grid item className={classes.msgBody}>
        <Typography className={classes.modelBodyText}>
          No Episode Found
        </Typography>
      </Grid>
      <Grid item className={classes.modalBtnGrid}>
        <Button
          onClick={() => setNoEpisode(false)}
          className={classes.modelBtn}
        >
          {intl.formatMessage({ id: "okay" })}
        </Button>
      </Grid>
    </Grid>
  );

  const handleCloseModal = () => {
    setOpenModel(false);
  };

  const joinCourseHandler = async () => {
    if (
      courseDetail?.plan?.name === "Donation" ||
      courseDetail?.plan?.name === "Free" ||
      courseDetail?.plan?.name === "Freemium"
    ) {
      const responseData = await dispatch(
        joinFreeCourse({
          courseId: courseDetail?.id,
          token: authReducerState?.token,
        })
      );

      if (responseData.success) {
        setOpenModel(false);
        setRecommendUpgrade(true);
        setShareOpen("Share");
      } else {
        setErrors(true);
        setMessages(responseData?.message);
      }
    } else if (courseDetail?.plan?.name === "Subscription") {
      const responseData = await dispatch(
        joinUnderSubscriptionCourse({
          courseId: courseDetail?.id,
          token: authReducerState?.token,
        })
      );

      if (responseData.success) {
        setOpenModel(false);
        setRecommendUpgrade(true);
        setShareOpen("Share");
      } else {
        if (
          reducerUser?.purchasedSubscription &&
          reducerUser?.purchasedSubscription.length < 1 &&
          courseDetail?.plan?.name === "Subscription"
        ) {
        } else {
          //  setErrors(true);
          // setMessages(responseData?.message);
          history.push("/more/subscription-plan");
        }
      }
    } else {
      setOpenModel(false);
      setOpenPaymentDialog(true);
    }
    if (
      reducerUser?.purchasedSubscription &&
      reducerUser?.purchasedSubscription.length < 1 &&
      courseDetail?.plan?.name === "Subscription"
    ) {
      // setSubscribeDialog(true);
      history.push("/more/subscription-plan");
    }
  };

  const handleCreateChallenge = () => {
    if (
      courseDetail?.isUserTaking ||
      courseDetail?.userId === authReducerState?.user?.id
    ) {
      dispatch(
        createChallengeData({
          courseInfo: { ...courseDetail },
          category: courseDetail?.category?.name,
        })
      );
      return history.push(`/more/create-challenge`);
    } else {
      setModalType("createChallenge");
      setOpenModel(true);
    }
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box pt={3}>
            <>{children}</>
          </Box>
        )}
      </div>
    );
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleCourseFavorite = async () => {
    console.log("courseDetail", courseDetail);
    if (courseDetail.isUserFan === true) {
      const res = await dispatch(
        deleteFavoriteCourse({
          courseId: courseDetail?.id,
          token: authReducerState.token,
        })
      );
      if (res && res === courseDetail) {
        dispatch(
          getCourseDetailById({
            courseId: courseDetail?.id,
            token: authReducerState.token,
          })
        );
      }
    } else if (courseDetail.isUserFan === false) {
      const res = await dispatch(
        addFavoriteCourse({
          courseId: courseDetail?.id,
          token: authReducerState.token,
        })
      );
      if (res && res === courseDetail) {
        dispatch(
          getCourseDetailById({
            courseId: courseDetail?.id,
            token: authReducerState.token,
          })
        );
      }
    }
  };

  const startEpisode = () => {
    if (
      (reducerUser?.isExpiredSubscription &&
        courseDetail?.plan?.name === "Subscription") ||
      (reducerUser?.purchasedSubscription?.length === 0 &&
        courseDetail?.plan?.name === "Subscription")
    ) {
      return setSubscriptionExpire(true);
      // history.push("/more/subscription-plan");
    } else if (courseDetail?.episodes?.length > 0) {
      if (!courseDetail?.isPaid && courseDetail?.plan?.name === "Freemium") {
        let inProgress = "";
        let notStarted = "";
        const freeEpisodes =
          courseDetail?.episodes?.length > 0 &&
          courseDetail?.episodes?.filter((item) => {
            inProgress =
              !inProgress &&
              item?.isFree &&
              item?.watchStatus === "Started" &&
              item?.multimediaUrl
                ? item?.id
                : "";
            notStarted =
              !notStarted &&
              item?.isFree &&
              item?.watchStatus === "Not Started" &&
              item?.multimediaUrl
                ? item?.id
                : "";
            return item?.isFree && item?.multimediaUrl;
          });

        if (inProgress) {
          return history.push(`/play-course/${courseDetail?.id}/${inProgress}`);
        } else if (notStarted) {
          return history.push(`/play-course/${courseDetail?.id}/${notStarted}`);
        } else if (freeEpisodes?.length > 0) {
          return history.push(
            `/play-course/${courseDetail?.id}/${freeEpisodes[0].id}`
          );
        } else if (
          courseDetail?.episodes?.length > 0 &&
          freeEpisodes?.length === 0
        ) {
          return setOpenPaymentDialog(true);
        }
      } else if (courseDetail?.isPaid) {
        const inProgressEpisode =
          courseDetail?.episodes?.length > 0 &&
          courseDetail?.episodes?.find(
            (item) => item?.watchStatus === "Started" && item?.multimediaUrl
          );
        const notStarted =
          courseDetail?.episodes?.length > 0 &&
          courseDetail?.episodes?.find(
            (item) => item?.watchStatus === "Not Started" && item?.multimediaUrl
          );
        const multimediaEpisode =
          courseDetail?.episodes?.length > 0 &&
          courseDetail?.episodes?.find((item) => item?.multimediaUrl);
        if (inProgressEpisode) {
          return history.push(
            `/play-course/${courseDetail?.id}/${inProgressEpisode?.id}`
          );
        } else if (notStarted) {
          return history.push(
            `/play-course/${courseDetail?.id}/${notStarted?.id}`
          );
        } else if (multimediaEpisode) {
          return history.push(
            `/play-course/${courseDetail?.id}/${multimediaEpisode?.id}`
          );
        }
      }
    } else {
      setNoEpisode(true);
    }
  };

  const hexToRGB = (hex, alpha) => {
    var r = parseInt(hex && hex.slice(1, 3), 16),
      g = parseInt(hex && hex.slice(3, 5), 16),
      b = parseInt(hex && hex.slice(5, 7), 16);

    if (alpha) {
      return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
    } else {
      return "rgb(" + r + ", " + g + ", " + b + ")";
    }
  };

  const courseViewHeroImageCard = (
    <Card className={classes.root}>
      <div
        className={classes.media}
        style={{
          backgroundImage: `url(${courseDetail?.image}),linear-gradient(360deg, rgba(21, 57, 89, 0.8) 0%, rgba(123, 146, 166, 0.8) 100%) `,
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "top",
        }}
      >
        <div
          style={{
            background: `linear-gradient(360deg, ${hexToRGB(
              courseDetail?.category?.color1,
              0.2
            )} 0%, ${hexToRGB(courseDetail?.category?.color2, 0.5)} 100%)`,
          }}
        >
          <Grid container>
            <Grid item xs={6} style={{ paddingLeft: "10px" }}>
              <ArrowBack
                style={{ color: "#FFF", marginTop: "22px" }}
                onClick={(e) => {
                  e.preventDefault();
                  history.push("/dashboard");
                }}
              />
            </Grid>
            <Grid item xs={6} style={{ padding: "10px 10px 0px 0px" }}>
              {authReducerState?.user?.id === courseDetail?.userId &&
                courseDetail?.plan && (
                  <Button
                    disableRipple={true}
                    className={classes.startNowBtn}
                    style={{
                      background: `linear-gradient(360deg, #4caf50 100%, ${hexToRGB(
                        courseDetail?.category?.color2,
                        1
                      )} 0%)`,
                      color: "#fff",
                      width: "100%",
                    }}
                  >
                    {courseDetail?.plan?.name}
                  </Button>
                )}
            </Grid>

            <Grid
              item
              xs={12}
              style={{
                paddingLeft: "10px",
                paddingRight: "10px",
                paddingBottom: "100px",
              }}
            >
              <Typography className={classes.courseTitle}>
                {/* <Truncate lines={1} ellipsis={"..."}>
                  {courseDetail?.name}

                </Truncate> */}
                {courseDetail?.name}
              </Typography>
            </Grid>
          </Grid>
        </div>
      </div>
    </Card>
  );

  const handleStatusChange = () => {
    dispatch(
      courseActiveInActive({
        token: authReducerState?.token,
        courseId: courseDetail?.id,
        status: courseStatus ? "InActive" : "Active",
      })
    );
    return;
  };

  const handleCloseShare = () => {
    setShareOpen(null);
  };
  const handleErrorsModelClose = () => {
    setMessages("");
    setErrors(false);
  };

  return (
    <>
      <Helmet>
        <meta name="title" content={courseDetail?.name} />
        <meta name="description" content={courseDetail?.remarks} />
        <meta
          property="og:image"
          content={`${window.location.origin}/${courseDetail?.image}`}
        />
        <meta property="og:url" content={`${window.location.href}`} />
        <meta property="og:type" content={`app`} />

        <meta
          property="twitter:image"
          alt="image"
          content={`${window.location.origin}/${courseDetail?.image}`}
        />
        <meta name="twitter:card" content={courseDetail?.remarks}></meta>
      </Helmet>
      <ExpireSubscriptionPopup
        subscriptionExpire={subscriptionExpire}
        setPopUp={() => setSubscriptionExpire(false)}
      />
      <RecommendUpgrade
        recommend={recommendUpgrade}
        setRecommendUpgrade={() => setRecommendUpgrade(false)}
      />
      {shareOpen && (
        <Dialog open={Boolean(shareOpen)} onClose={handleCloseShare}>
          <DialogTitle>
            <Grid container justify="space-between">
              <Grid item>
                <Typography component="h4" variant="h5">
                  {shareOpen}
                </Typography>
              </Grid>
              <Grid item>
                <Button onClick={handleCloseShare}>
                  <Close />
                </Button>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent style={{ padding: "0px" }}>
            <InviteAndShare
              course={courseDetail}
              buttonText="close"
              invite={shareOpen === "Invite"}
              share={shareOpen === "Share"}
              handleNext={() => setShareOpen(null)}
            />
          </DialogContent>
        </Dialog>
      )}
      <Modal
        disableEnforceFocus={true}
        open={openModel}
        onClose={handleCloseModal}
      >
        {modalBody}
      </Modal>
      <Modal
        disableEnforceFocus={true}
        open={noEpisode}
        onClose={() => setNoEpisode(false)}
      >
        {noEpisodeBody}
      </Modal>

      {courseViewHeroImageCard}
      <div
        style={{
          position: "relative",
          minHeight: "200px",
          width: "100%",
          marginTop: "-120px",
          background: "#FFF",
          borderRadius: "50px 50px 0px 0px",
          paddingTop: "40px",
        }}
      >
        <Grid
          item
          container
          xs={12}
          style={{
            //    paddingBottom: "100px",
            paddingLeft: "10px",
            paddingRight: "10px",
          }}
        >
          <Grid item xs={6}>
            {courseDetail?.user?.id === authReducerState?.user?.id ? (
              <Button
                className={classes.startNowBtn}
                style={{ background: "#39A746" }}
                onClick={() =>
                  history.push(`/edit-course/${courseDetail.slug}`)
                }
              >
                <Edit /> {intl.formatMessage({ id: "edit" })}
              </Button>
            ) : courseDetail?.isUserTaking ? (
              <Button
                className={classes.startNowBtn}
                style={{ background: "#39A746" }}
                onClick={startEpisode}
              >
                <Typography>
                  {intl.formatMessage({ id: "startNow" })}
                </Typography>
              </Button>
            ) : (
              <Button
                className={classes.startNowBtn}
                style={{ background: "#39A746" }}
                onClick={() => {
                  if (
                    courseDetail?.plan?.name === "Donation" ||
                    courseDetail?.plan?.name === "Free" ||
                    courseDetail?.plan?.name === "Freemium" ||
                    courseDetail?.plan?.name === "Subscription" ||
                    courseDetail?.plan?.name === "Paid"
                  ) {
                    /* {
                    setJoinDialog(true);
                  } else  */ joinCourseHandler();
                  }
                }}
              >
                <Typography>
                  {courseDetail?.plan?.name === "Donation" ||
                  courseDetail?.plan?.name === "Free" ? (
                    "Join For Free"
                  ) : courseDetail?.plan?.name === "Subscription" ? (
                    `Subscribe`
                  ) : (
                    <>
                      {`Join Now For `}
                      {reducerUser?.purchasedSubscription?.length === 0 ? (
                        `${configurations?.currency}${
                          courseDetail?.price
                            ? courseDetail?.price
                            : courseDetail?.plan?.price
                            ? courseDetail?.plan?.price
                            : 0
                        }`
                      ) : (reducerUser?.purchasedSubscription[0]?.interval ===
                          "monthly" &&
                          reducerUser?.purchasedSubscription[0]
                            ?.SubscriptionPlan?.omidDiscount > 0) ||
                        (reducerUser?.purchasedSubscription[0]?.interval ===
                          "annual" &&
                          reducerUser?.purchasedSubscription[0]
                            ?.SubscriptionPlan?.annualOmidDiscount > 0) ? (
                        <strike>
                          {`${configurations?.currency}${
                            courseDetail?.price
                              ? courseDetail?.price
                              : courseDetail?.plan?.price
                              ? courseDetail?.plan?.price
                              : 0
                          }`}
                        </strike>
                      ) : (
                        <></>
                      )}
                      {reducerUser?.purchasedSubscription?.length > 0 && (
                        <span style={{ paddingLeft: "2px" }}>
                          {`(${configurations?.currency}${getPrice(
                            courseDetail?.price
                              ? courseDetail?.price
                              : courseDetail?.plan?.price
                              ? courseDetail?.plan?.price
                              : 0,
                            reducerUser?.purchasedSubscription
                          )})`}
                        </span>
                      )}
                    </>
                  )}
                </Typography>
              </Button>
            )}

            {/* </Link> */}
          </Grid>

          <Grid item xs={6} style={{ textAlign: "right" }}>
            {!courseDetail?.isPrivate && (
              <Button
                className={classes.startNowBtn}
                style={{
                  background:
                    "linear-gradient(360deg,  #C54DC2 -13.24%, #C54DC2 100%)",
                }}
                onClick={handleCreateChallenge}
                disabled={courseDetail?.status !== "Active" ? true : false}
              >
                <Typography>
                  {intl.formatMessage({ id: "createAChallenge" })}
                </Typography>
              </Button>
            )}
          </Grid>
        </Grid>
        <Grid
          style={{
            padding: "1rem",
          }}
          container
          justify="space-between"
        >
          <Grid item xs={12} container>
            <Grid item xs={5}>
              {authReducerState?.user?.id === courseDetail?.userId
                ? (courseDetail?.status === "Active" ||
                    courseDetail?.status === "InActive") && (
                    <FormControlLabel
                      control={
                        <CustomSwitch
                          onChange={handleStatusChange}
                          checked={courseStatus}
                        />
                      }
                      label={
                        <span className={classes.startTimeTxt}>
                          {courseDetail?.status}
                        </span>
                      }
                    />
                  )
                : authReducerState?.user?.id !== courseDetail?.userId &&
                  courseDetail?.isUserTaking &&
                  (courseDetail?.plan?.name === "Donation" ||
                    (courseDetail?.plan?.name === "Freemium" &&
                      !courseDetail?.isPaid)) && (
                    <Tooltip
                      title={
                        courseDetail?.plan?.name === "Freemium"
                          ? `Pay $${
                              courseDetail?.price
                                ? courseDetail?.price
                                : courseDetail?.plan?.price
                                ? courseDetail?.plan?.price
                                : 0
                            } to access full course`
                          : "Donate to appreciate course creator"
                      }
                    >
                      <Button
                        onClick={() => setOpenPaymentDialog(true)}
                        style={{
                          background: `linear-gradient(360deg, #4caf50 100%, ${hexToRGB(
                            courseDetail?.category?.color2,
                            1
                          )} 0%)`,
                          color: "#fff",
                          cursor: "default",
                          width: "100%",
                          height: "2.463em",
                          borderRadius: "10px",
                          transform: "rotate(360deg)",
                          fontFamily: theme.fontFamily.Reef,
                          fontStyle: "normal",
                          fontWeight: 500,
                          fontSize: "20px",
                          lineHeight: "10px",
                        }}
                      >
                        {courseDetail?.plan?.name === "Donation"
                          ? intl.formatMessage({ id: "donation" })
                          : `${intl.formatMessage({ id: "pay" })} ${
                              configurations?.currency
                            }
                          ${
                            courseDetail?.price
                              ? courseDetail?.price
                              : courseDetail?.plan?.price
                              ? courseDetail?.plan?.price
                              : 0
                          }`}
                      </Button>
                    </Tooltip>
                  )}
            </Grid>

            <Grid container justify="flex-end">
              <Grid
                item
                xs={2}
                alignItems="center"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {authReducerState?.user?.id !== courseDetail?.userId && (
                  <>
                    {courseDetail?.isUserFan ? (
                      <img
                        src={heartIconSelected}
                        onClick={handleCourseFavorite}
                        className={classes.heartIconStyle}
                        style={{ marginTop: "10px" }}
                      />
                    ) : (
                      <img
                        src={heartIcon}
                        width="25px"
                        onClick={handleCourseFavorite}
                        className={classes.heartIconStyle}
                      />
                    )}
                  </>
                )}
              </Grid>
              <Grid item style={{ marginRight: "5px" }}>
                <Tooltip
                  title={
                    <span style={{ fontSize: "14px", letterSpacing: 0.3 }}>
                      Invite
                    </span>
                  }
                  placement="top"
                >
                  <IconButton
                    disabled={
                      !courseDetail ||
                      courseDetail?.status === "Incomplete" ||
                      courseDetail?.status === "Blocked" ||
                      courseDetail?.status === "InActive"
                    }
                    onClick={() => setShareOpen("Invite")}
                    className={classes.inviteAndShare}
                  >
                    <InsertInvitation />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item>
                {!courseDetail?.isPrivate && (
                  <Tooltip
                    title={
                      <span style={{ fontSize: "14px", letterSpacing: 0.3 }}>
                        Share
                      </span>
                    }
                    placement="top"
                  >
                    <IconButton
                      disabled={
                        !courseDetail ||
                        courseDetail?.status === "Incomplete" ||
                        courseDetail?.status === "Blocked" ||
                        courseDetail?.status === "InActive"
                      }
                      onClick={() => setShareOpen("Share")}
                      className={classes.inviteAndShare}
                    >
                      <Share />
                    </IconButton>
                  </Tooltip>
                )}
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} container style={{ paddingTop: "10px" }}>
            <Grid item xs={8}>
              <Rating
                name="simple-controlled"
                size={"small"}
                value={courseDetail?.averageRating}
                readOnly={true}
                precision={0.5}
                classes={{ sizeSmall: classes.ratingSize }}
              />
            </Grid>

            <Grid item xs={4}>
              <Typography
                className={classes.CourseImageCardText}
                style={{ fontSize: "18px" }}
              >
                {courseDetail?.reviews_ratings?.length > 0
                  ? courseDetail?.reviews_ratings?.length + " "
                  : ""}
                {courseDetail?.reviews_ratings?.length < 1
                  ? intl.formatMessage({ id: "noReview" })
                  : courseDetail?.reviews_ratings?.length === 1
                  ? intl.formatMessage({ id: "Review" })
                  : intl.formatMessage({ id: "Reviews" })}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} container style={{ paddingTop: "10px" }}>
            {courseDetail?.previewUrl && (
              <Grid container>
                <CoursePreview courseDetail={courseDetail} />
              </Grid>
            )}
          </Grid>
        </Grid>

        <Grid container justify="center">
          <Grid
            item
            xs={12}
            sm={8}
            md={9}
            direction="row"
            style={{ textAlign: "center" }}
          >
            <AppBar
              position="static"
              className="viewCourseTabs"
              style={{ background: "#FFF", boxShadow: "none" }}
            >
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="simple tabs example"
                style={{ margin: "auto", background: "#FFF" }}
                className="tabTitle"
              >
                <Tab label={intl.formatMessage({ id: "episodes" })} />
                <Tab label={intl.formatMessage({ id: "details" })} />

                <Tab label={intl.formatMessage({ id: "Reviews" })} />
                {authReducerState?.user?.id === courseDetail?.userId && (
                  <Tab label={intl.formatMessage({ id: "stats" })} />
                )}
              </Tabs>
            </AppBar>
          </Grid>
          {/* <Grid item xs={1} /> */}
          <Grid
            item
            xs={12}
            sm={9}
            md={4}
            style={{ marginTop: "-20px", marginBottom: "100px" }}
          >
            <TabPanel value={value} index={0}>
              <CourseViewEpisodesCard
                setOpen={
                  courseDetail?.isUserTaking
                    ? setOpenPaymentDialog
                    : () => {
                        setOpenModel(true);
                        setModalType("joinCourse");
                      }
                }
                episodes={courseDetail?.episodes}
              />
            </TabPanel>
            <TabPanel
              style={{ padding: "0px 15px 0px 15px" }}
              value={value}
              index={1}
            >
              <CourseInstructorIntroCard
                instructor={courseDetail?.user}
                tags={courseDetail?.tags}
                remarks={courseDetail?.remarks}
                category={courseDetail?.category}
              />
              <CoursePlanCard />
            </TabPanel>
            <TabPanel
              value={value}
              index={2}
              style={{ padding: "0px 15px 0px 15px" }}
            >
              {(courseDetail?.isUserTaking ||
                courseDetail?.user?.id === authReducerState?.user?.id) && (
                <PostReviewCard courseId={courseDetail?.id} />
              )}
              <CourseReviews courseReviews={courseDetail?.reviews_ratings} />
            </TabPanel>
            {authReducerState?.user?.id === courseDetail?.userId && (
              <TabPanel
                value={value}
                index={3}
                style={{ padding: "0px 15px 0px 15px" }}
              >
                <CourseStats />
              </TabPanel>
            )}
          </Grid>
        </Grid>
      </div>
      <CustomerCardList
        open={openPaymentDialog}
        setOpen={setOpenPaymentDialog}
        setRecommendUpgrade={setRecommendUpgrade}
        setShareOpen={setShareOpen}
        courseId={courseDetail?.id}
        plan={courseDetail?.plan}
        // price={courseDetail?.price}
        price={getPrice(
          courseDetail?.price,
          reducerUser?.purchasedSubscription,
          "course"
        )}
      />
      {/*  <Dialog open={joinDialog} onClose={() => setJoinDialog(false)}>
        <DialogContent>Are you sure you want to join course?</DialogContent>
        <DialogActions>
          <Button
            className={classes.proceedButton}
            onClick={() => {
              setJoinDialog(false);
              joinCourseHandler();
            }}
          >
            Yes
          </Button>
          <Button onClick={() => setJoinDialog(false)}>No</Button>
        </DialogActions>
      </Dialog> */}
      <Dialog open={subscribeDialog} onClose={() => setSubscribeDialog(false)}>
        <DialogContent>
          You currently do not have any active subscription. Please upgrade and
          give yourself the best present.
        </DialogContent>
        <DialogActions>
          <Button
            className={classes.subscribeButton}
            onClick={() => {
              setSubscribeDialog(false);
              history.push("/more/subscription-plan");
            }}
          >
            Subscribe
          </Button>
          <Button onClick={() => setSubscribeDialog(false)}>Close</Button>
        </DialogActions>
      </Dialog>

      {/*   <Dialog  open={errors} onClose={handleErrorsModelClose}>
        <Grid>
          <Grid xs={12} className={classes.messageBody}>
          <DialogContent>
          <Grid xs={12} style={{disply:"inline"}}>
            <Error size="small"  className={classes.error}/>
              {messages}
              </Grid>

        </DialogContent>
          </Grid>

          <Grid xs={12}>
          <DialogActions>
         <Button className={classes.subscribeButton}
            onClick={() => {
              setSubscribeDialog(false);
              history.push("/more/subscription-plan");
            }}
          >
            Subscribe
          </Button>
          <Button onClick={handleErrorsModelClose}>Close</Button>
        </DialogActions>
          </Grid>


        </Grid>

      </Dialog> */}
      <Dialog
        open={errors}
        onClose={handleErrorsModelClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {/*  <DialogTitle id="alert-dialog-title">
          {"Attention"}
        </DialogTitle> */}
        <DialogContent>
          <Grid container>
            <Grid lg={1} xs={1}>
              <Error className={classes.error} />
            </Grid>
            <Grid lg={11} xs={11} style={{ marginTop: "12px" }}>
              {" "}
              <span>{messages}</span>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            className={classes.subscribeButton}
            onClick={() => {
              setSubscribeDialog(false);
              history.push("/more/subscription-plan");
            }}
          >
            Subscribe
          </Button>
          <Button onClick={handleErrorsModelClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
export default ViewCourseDetailMobile;

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    minHeight: "22.875em",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    position: "relative",
  },
  error: {
    color: "#F90606",
    paddingTop: "15px",
    paddingRight: "15px",
  },
  messageBody: {
    justifyContent: "center",
    alignItems: "center",
    height: "100px",
    textAlign: "center",
  },
  proceedButton: {
    background: "#fbae02",
    color: "#fff",
    "&:hover": {
      background: "#fbae02",
      color: "#fff",
    },
    "&:focus": {
      background: "#fbae02",
      color: "#fff",
    },
  },
  media: {
    width: "100%",
    height: "100%",
    transform: "rotate(360deg)",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    paddingBottom: "100px",
  },
  courseTitle: {
    fontFamily: theme.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: 900,
    fontSize: "28px",
    color: "#FFFFFF",
    padding: "4% 0 4% 0",
  },
  subscribeButton: {
    background: "#088A2D",
    color: "#fff",
    "&:hover": {
      background: "#088A2D",
      color: "#fff",
    },
    "&:focus": {
      background: "#088A2D",
      color: "#fff",
    },
  },
  courseDescription: {
    fontFamily: theme.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: 900,
    fontSize: "18px",
    lineHeight: "24px",
    letterSpacing: "0.015em",
    color: "#FFFFFF",
  },
  startNowBtn: {
    height: "2.463em",
    width: "90%",
    background: "linear-gradient(360deg, #15AEBF 0%, #A6D6FF 100%)",
    borderRadius: "50px",
    transform: "rotate(360deg)",
    fontFamily: theme.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "20px",
    lineHeight: "10px",
    color: "#FFFFFF",
    //marginBottom: "15px",
  },
  inviteAndShare: {
    background: "linear-gradient(360deg, #15AEBF 0%, #A6D6FF 100%)",

    fontSize: "20px",
    lineHeight: "10px",
    color: "#FFFFFF",
  },
  CourseImageCardText: {
    fontFamily: theme.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: 900,
    fontSize: "16px",

    color: "#83CCF0",
  },
  paper: {
    position: "absolute",
    height: 330,
    maxWidth: 315,
    minWidth: 300,
    justifyContent: "center",
    backgroundColor: theme.palette.background.paper,
    outline: "none",
  },
  modelBodyText: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "17px",
    lineHeight: "27px",
    textAlign: "center",
    letterSpacing: "0.02em",
    color: "#192247",
  },
  modelBtn: {
    height: "73px",
    width: "100%",
    fontFamily: theme.fontFamily.Roboto,
    cursor: "pointer",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "21px",
    color: theme.colors.white,
  },

  modalBtnGrid: {
    padding: "0px",
    alignSelf: "flex-end",
    color: "#fff",
    cursor: "pointer",
    background: `linear-gradient(#09A9BB 1%,#09A9BB 100%)`,
    height: "73px",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
  },
  startTimeTxt: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: 500,

    fontSize: "20px",
    lineHeight: "10px",
    color: "#15AEBF",
  },
  msgBody: {
    minHeight: "200px",
    alignItems: "center",
    display: "flex",
  },
  readMore: {
    fontSize: "12px",
    cursor: "pointer",
    color: "#C4C4C4",
  },
  ratingSize: {
    fontSize: "20px",
  },
  heartIconStyle: {
    cursor: "pointer",
  },
  closeIcon: {
    color: "#a9a9a9",
    float: "right",
    cursor: "pointer",
  },
}));
