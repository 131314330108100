import React, { useEffect, useState } from "react";

import { useTheme, withStyles, makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  CircularProgress,
  Button,
  Typography,
  useMediaQuery,
  Dialog,
  DialogActions,
  DialogContent,
  Tabs,
  Tab,
  Box,
  AppBar,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Tooltip,
  Avatar,
  Chip,
  TextField,
} from "@material-ui/core";
import { useForm } from "react-hook-form";
import TopBar from "../../../components/AppBar";
import { Add, AccountBalance, ArrowRight } from "@material-ui/icons";
import { useLocation } from "react-router-dom";
import AddPaymentCardForm from "../../../components/Common/AddPaymentForm";
import "./payment.scss";
import { useIntl } from "react-intl";
import ShowCard from "../../../components/Common/PaymentCard/ShowCard";
import {
  getAllCustomerCards,
  deleteCustomerCard,
  linkExternalBankAccount,
  getAccountDetails,
  verifyStripeAccount,
  deleteLinkedAccount,
} from "../../../actions/stripe";
import { resendEmail } from "../../../actions/auth";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

const StyledTabs = withStyles({
  indicator: {
    display: "flex",
    justifyContent: "center",
    height: "5px",
    backgroundColor: "transparent",
    "& > span": {
      maxWidth: 40,
      width: "100%",
      backgroundColor: "#09A9BB",
    },
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

const StyledTab = withStyles((theme) => ({
  root: {
    minWidth: "60px !important",
    textTransform: "none",
    color: "#09A9BB",
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    justifyContent: "space-evenly",
    "&:focus": {
      opacity: 1,
    },
  },
}))((props) => <Tab disableRipple {...props} />);

function TabPanel(props) {
  const theme = useTheme();

  const { children, value, index, ...other } = props;
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          style={{
            padding: "0px 20px",
            paddingLeft: !isMobile ? "40px" : "20px",
          }}
        >
          <>{children}</>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function Payment(props) {
  const theme = useTheme();
  const intl = useIntl();
  const classes = useStyles();
  const dispatch = useDispatch();
  let location = useLocation();
  const authReducerState = useSelector((state) => state.auth);
  const userReducer = useSelector((state) => state.user);
  const customerCardList = useSelector(
    (state) => state?.stripe?.customerCardList
  );
  const bankAccountInfo = useSelector(
    (state) => state?.stripe?.bankAccountInfo
  );
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const { stripeLoader, stripeSuccess } = useSelector((state) => state.stripe);
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  const [selectedCard, setSelectedCard] = useState(false);
  const [formSubmitLoader, setFormSubmitLoader] = useState(false);
  const [clicked, setClicked] = React.useState(false);
  const [tabValue, setTabValue] = React.useState(
    new URLSearchParams(location.search).get("tab") === "linkedAccount" ? 1 : 0
  );
  const [accountType, setAccountType] = React.useState("card");
  const [country, setCountry] = React.useState(null);
  const [openDeleteAccount, setOpenDeleteAccount] = React.useState(false);
  const [openConfirmCountry, setOpenConfirmCountry] = React.useState(false);
  const [deleteAccountLoader, setDeleteAccountLoader] = React.useState(false);
  const [loader, setLoader] = React.useState(false);

  const { register, errors, handleSubmit } = useForm();

  useEffect(() => {
    dispatch(getAllCustomerCards({ token: authReducerState.token }));
    dispatch(getAccountDetails({ token: authReducerState.token }));
  }, []);
  useEffect(() => {
    if (stripeSuccess) {
      setSelectedCard(null);
      setFormSubmitLoader(false);
      dispatch({ type: "SET_STRIPE_SUCCESS", payload: false });
    }
  }, [stripeSuccess]);

  useEffect(() => {
    if (customerCardList && customerCardList?.length === 0) {
      setClicked(true);
    }
  }, [customerCardList]);

  const handleOpenAddPaymentCard = () => {
    setClicked(!clicked);
  };
  let paymentModule = (
    <>
      <Grid container style={{ textAlign: "center" }} direction="row">
        {customerCardList?.length > 0 ? (
          customerCardList.map((customerCard) => (
            <Grid key={customerCard.id} item xs={12} md={8} lg={6}>
              <ShowCard
                brand={customerCard.brand}
                number={customerCard.last4}
                year={customerCard.exp_year}
                handleDelete={() => {
                  setSelectedCard(customerCard);
                }}
              />
            </Grid>
          ))
        ) : (
          <Grid container>
            <Grid item xs={12} md={8} lg={6} xl={4}>
              {stripeLoader ? (
                <CircularProgress size={20} style={{ color: "#09A9BB" }} />
              ) : (
                <Typography
                  variant="caption"
                  style={{ color: "#FF0000", fontSize: 14 }}
                >
                  {
                    "You have not added any payment method, please add at least one card to proceed payments."
                  }
                </Typography>
              )}
            </Grid>
          </Grid>
        )}
      </Grid>
      <Grid
        container
        direction="row"
        style={{ marginTop: "4.188em", justifyContent: isMobile && "center" }}
      >
        <Grid item xs={12} md={8} lg={6}>
          {!clicked && (
            <Button
              style={{
                width: !isDesktop && isMobile ? "100%" : "100%",
              }}
              className={classes.addPaymentCardBtn}
              onClick={handleOpenAddPaymentCard}
            >
              <Add />
              {intl.formatMessage({ id: "addNewCard" })}
            </Button>
          )}
        </Grid>
        <Grid item xs={12} container>
          <Grid item xs={12} md={8} lg={6}>
            {clicked && (
              <AddPaymentCardForm onClick={handleOpenAddPaymentCard} />
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );

  const verifyAccount = () => {
    dispatch(
      verifyStripeAccount({
        token: authReducerState.token,
        country: country,
      })
    );
  };

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
    console.log(newValue);
  };

  const handleBankInfo = (e, value) => {
    e.preventDefault();
    setAccountType(value);
  };

  const onSubmit = (data) => {
    setFormSubmitLoader(true);
    setOpenConfirmCountry(false);
    console.log(data);
    dispatch(
      linkExternalBankAccount({
        bankData: {
          accountNumber: data.accountNumber,
          routingNumber: data.routingNumber,
        },
        token: authReducerState.token,
      })
    );
  };

  const confirmDelete = () => {
    setOpenDeleteAccount(true);
  };

  const confirmCountry = () => {
    setOpenConfirmCountry(true);
  };

  const deleteAccount = async () => {
    setDeleteAccountLoader(true);
    await dispatch(deleteLinkedAccount({ token: authReducerState.token }));
    setDeleteAccountLoader(false);
    setOpenDeleteAccount(false);
  };
  const handleResendEmail = async () => {
    setLoader(true);
    let res = await dispatch(resendEmail(authReducerState?.user?.email));
    setLoader(false);

    // resendEmail()
  };
  return (
    <>
      <Grid
        container
        direction="row"
        style={{ backgroundColor: isMobile && "#fbfaf9", minHeight: "100vh" }}
      >
        <Grid item xs={12}>
          <TopBar
            appBarConfig={{
              show: true,
              text: "Payment Method",
            }}
          />
        </Grid>
        {userReducer?.user?.emailConfirmed ? (
          <>
            {authReducerState?.user?.role &&
            authReducerState?.user?.role !== "Regular" ? (
              <>
                <Grid item xs={12}>
                  <AppBar position="static" className={classes.appBar}>
                    <StyledTabs
                      value={tabValue}
                      onChange={handleChange}
                      classes={{
                        indicator: isMobile && classes.indicatorStylingMobile,
                        flexContainer: classes.tabsContainer,
                      }}
                      style={{ margin: isMobile ? "0px" : "0px 0px 25px 25px" }}
                    >
                      <StyledTab
                        label={intl.formatMessage({ id: "customerCard" })}
                        classes={{
                          root: classes.tabLabel,
                          selected: classes.tabSelected,
                        }}
                      />
                      <StyledTab
                        label={intl.formatMessage({ id: "linkedAccount" })}
                        classes={{
                          root: classes.tabLabel,
                          selected: classes.tabSelected,
                        }}
                      />
                    </StyledTabs>
                  </AppBar>
                </Grid>
                <Grid item xs={12}>
                  <TabPanel
                    value={tabValue}
                    index={0}
                    style={{ minHeight: "100vh", paddingTop: "15px" }}
                  >
                    {paymentModule}
                  </TabPanel>
                  <TabPanel
                    value={tabValue}
                    index={1}
                    style={{ minHeight: "100vh", paddingTop: "15px" }}
                  >
                    <Grid container style={{ width: "100%" }}>
                      <Grid item xs={11} md={6}>
                        {!bankAccountInfo && (
                          <FormControl
                            style={{ width: "100%", marginBottom: "1rem" }}
                          >
                            <InputLabel id="demo-customized-select-label">
                              {intl.formatMessage({ id: "selectHomeCountry" })}
                            </InputLabel>
                            <Select
                              labelId="demo-customized-select-label"
                              id="demo-customized-select"
                              value={country}
                              onChange={(e) => setCountry(e.target.value)}
                            >
                              {[
                                { value: "AT", label: "Austria (AT)" },
                                { value: "AU", label: "Australia (AU)" },
                                { value: "BE", label: "Belgium (BE)" },
                                { value: "BG", label: "Bulgaria (BG)" },
                                { value: "CA", label: "Canada (CA)" },
                                { value: "CH", label: "Switzerland (CH)" },
                                { value: "CY", label: "Cyprus (CY)" },
                                { value: "CZ", label: "Czech Republic (CZ)" },
                                { value: "DE", label: "Germany (DE)" },
                                { value: "DK", label: "Denmark (DK)" },
                                { value: "EE", label: "Estonia (EE)" },
                                { value: "ES", label: "Spain (ES)" },
                                { value: "FI", label: "Finland (FI)" },
                                { value: "FR", label: "France (FR)" },
                                { value: "GB", label: "United Kingdom (GB)" },
                                { value: "GR", label: "Greece (GR)" },
                                { value: "HK", label: "Hong Kong (HK)" },
                                { value: "HU", label: "Hungary (HU)" },
                                { value: "IE", label: "Ireland (IE)" },
                                { value: "IT", label: "Italy (IT)" },
                                { value: "JP", label: "Japan (JP)" },
                                { value: "LT", label: "Lithuania (LT)" },
                                { value: "LU", label: "Luxembourg (LU)" },
                                { value: "LV", label: "Latvia (LV)" },
                                { value: "MT", label: "Malta (MT)" },
                                { value: "MX", label: "Mexico (MX)" },
                                { value: "NL", label: "Netherlands (NL)" },
                                { value: "NO", label: "Norway (NO)" },
                                { value: "NZ", label: "New Zealand (NZ)" },
                                { value: "PL", label: "Poland (PL)" },
                                { value: "PT", label: "Portugal (PT)" },
                                { value: "RO", label: "Romania (RO)" },
                                { value: "SE", label: "Sweden (SE)" },
                                { value: "SG", label: "Singapore (SG)" },
                                { value: "SI", label: "Slovenia (SI)" },
                                { value: "SK", label: "Slovakia (SK)" },
                                { value: "US", label: "United States (US)" },
                              ].map((item) => (
                                <MenuItem value={item.value}>
                                  {item.label}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        )}
                      </Grid>
                      {!country && !bankAccountInfo ? (
                        <Grid item xs={12}>
                          <p style={{ fontSize: "12px" }}>
                            {intl.formatMessage({ id: "selectHomeCountry" })}
                          </p>
                        </Grid>
                      ) : (
                        !bankAccountInfo && (
                          <Grid item xs={12}>
                            <p style={{ fontSize: "12px", color: "#f44336" }}>
                              Carefully select your country. Because it can not
                              be changed later.
                            </p>
                          </Grid>
                        )
                      )}
                    </Grid>
                    {!bankAccountInfo ? (
                      <Grid container>
                        <Tooltip
                          title={
                            !country && !bankAccountInfo
                              ? "Please Select a country"
                              : ""
                          }
                        >
                          <Grid item sm={11} md={6}>
                            <Button
                              style={{}}
                              className={classes.addPaymentCardBtn}
                              disabled={
                                stripeLoader || (!bankAccountInfo && !country)
                              }
                              onClick={confirmCountry}
                            >
                              {stripeLoader ? (
                                <CircularProgress
                                  size={20}
                                  style={{ color: "#09A9BB" }}
                                />
                              ) : (
                                intl.formatMessage({ id: "linkAccount" })
                              )}
                            </Button>
                          </Grid>
                        </Tooltip>
                        <Grid container>
                          <Typography
                            style={{ color: "#f44336" }}
                            component="caption"
                            variant="caption"
                          >
                            No Bank account is linked. Click on link Account.
                            After clicking it will redirect to stripe form
                          </Typography>
                        </Grid>
                      </Grid>
                    ) : (
                      ""
                    )}
                    {bankAccountInfo && (
                      <Grid container style={{ marginBottom: "1rem" }}>
                        <Grid container>
                          <Typography
                            style={{
                              color: "rgb(135, 142, 156)",
                              marginBottom: "1rem",
                            }}
                            component="caption"
                            variant="caption"
                          >
                            After clicking Update Button it will redirect to
                            stripe form
                          </Typography>
                        </Grid>
                        {bankAccountInfo?.requirements?.currently_due.length ||
                        !bankAccountInfo.payouts_enabled ? (
                          <Grid container>
                            <Typography
                              style={{ color: "#f44336" }}
                              component="p"
                              variant="caption"
                            >
                              Either no bank information is provided or some
                              data is still missing. Click on update to update
                              your data or fill out bank information for to link
                              your account
                            </Typography>
                          </Grid>
                        ) : (
                          ""
                        )}
                        <Grid
                          item
                          xs={11}
                          md={8}
                          container
                          justify="space-between"
                        >
                          <Typography
                            style={{
                              fontSize: "18px",
                              color: "#000",
                              marginLeft: "8px",
                            }}
                          >
                            {bankAccountInfo.individual?.first_name}{" "}
                            {bankAccountInfo.individual?.last_name}
                          </Typography>
                          <Grid
                            item
                            xs={6}
                            md={8}
                            container
                            justify={"space-between"}
                          >
                            <Chip
                              label={
                                bankAccountInfo?.requirements?.currently_due
                                  .length
                                  ? "Restricted"
                                  : bankAccountInfo?.requirements
                                      ?.pending_verification.length
                                  ? "Pending"
                                  : "Completed"
                              }
                              style={{
                                background: bankAccountInfo?.requirements
                                  ?.currently_due.length
                                  ? "#f44336"
                                  : bankAccountInfo?.requirements
                                      ?.pending_verification.length
                                  ? "#ff9100"
                                  : "#52b202",
                                color: "#fff",
                              }}
                            />
                            <Button
                              disabled={stripeLoader}
                              color="inherit"
                              disableElevation
                              style={{ color: "#09A9BB" }}
                              onClick={verifyAccount}
                            >
                              {stripeLoader ? (
                                <CircularProgress
                                  size={10}
                                  style={{ color: "#09A9BB" }}
                                />
                              ) : (
                                "Update"
                              )}
                            </Button>
                            <Grid item justify="flex-end">
                              <Button
                                disabled={stripeLoader}
                                color="inherit"
                                disableElevation
                                style={{ color: "#f44336" }}
                                onClick={confirmDelete}
                              >
                                Delete
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                        {bankAccountInfo?.external_accounts?.data.map(
                          (bank) => (
                            <Grid item xs={12}>
                              <div
                                style={{ display: "flex", marginTop: "20px" }}
                              >
                                <Avatar
                                  variant="square"
                                  style={{
                                    width: "60px",
                                    height: "60px",
                                    marginLeft: "10px",
                                    borderRadius: "8px",
                                    backgroundColor: "#09A9BB",
                                  }}
                                >
                                  <AccountBalance
                                    style={{
                                      color: "#FFF",
                                      height: "40px",
                                      width: "40px",
                                    }}
                                  />
                                </Avatar>

                                <div>
                                  <Typography
                                    style={{
                                      fontSize: "18px",
                                      color: "#DFDFDF",
                                      marginLeft: "8px",
                                    }}
                                  >
                                    {bank?.bank_name}{" "}
                                    <Chip
                                      label={bank?.currency?.toUpperCase()}
                                      style={{
                                        background: "#E3E8EE",
                                        color: "#878E9C",
                                        height: "20px",
                                        marginLeft: "8px",
                                      }}
                                    />
                                  </Typography>

                                  <Typography
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <ArrowRight />
                                    {bank?.routing_number}{" "}
                                    <ArrowRight
                                      style={{ marginLeft: "10px" }}
                                    />
                                    <span className={classes.dot} />
                                    <span className={classes.dot} />
                                    <span className={classes.dot} />
                                    <span
                                      className={classes.dot}
                                      style={{ marginRight: "10px" }}
                                    />
                                    {bank?.last4}
                                  </Typography>
                                </div>
                              </div>
                            </Grid>
                          )
                        )}
                      </Grid>
                    )}
                    {bankAccountInfo?.requirements?.currently_due?.length &&
                    bankAccountInfo?.requirements?.currently_due.includes(
                      "external_account"
                    ) ? (
                      <Grid container>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
                          <form onSubmit={handleSubmit(onSubmit)}>
                            <Grid container spacing={1}>
                              <Grid item xs={12}>
                                <TextField
                                  className={classes.inputField}
                                  label={
                                    bankAccountInfo.country === "US"
                                      ? intl.formatMessage({
                                          id: "accountNumber",
                                        })
                                      : intl.formatMessage({
                                          id: "ibanNumber",
                                        })
                                  }
                                  placeholder={
                                    bankAccountInfo.country === "US"
                                      ? intl.formatMessage({
                                          id: "accountNumber",
                                        })
                                      : intl.formatMessage({
                                          id: "ibanNumber",
                                        })
                                  }
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  inputRef={register({
                                    required: intl.formatMessage({
                                      id: "requiredAccountNumber",
                                    }),
                                  })}
                                  type="text"
                                  name="accountNumber"
                                  error={!!errors.accountNumber}
                                  helperText={
                                    errors.accountNumber &&
                                    errors.accountNumber.message
                                  }
                                />
                              </Grid>

                              {bankAccountInfo.country === "US" && (
                                <Grid item xs={12}>
                                  <TextField
                                    className={classes.inputField}
                                    label={intl.formatMessage({
                                      id: "routingNumber",
                                    })}
                                    placeholder={intl.formatMessage({
                                      id: "routingNumber",
                                    })}
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    inputRef={register({
                                      required: intl.formatMessage({
                                        id: "requiredRoutingNumber",
                                      }),
                                    })}
                                    type="text"
                                    name="routingNumber"
                                    error={!!errors.routingNumber}
                                    helperText={
                                      errors.routingNumber &&
                                      errors.routingNumber.message
                                    }
                                  />
                                </Grid>
                              )}
                              <Grid
                                item
                                container
                                xs={12}
                                direction="row"
                                justify="center"
                                style={{
                                  textAlign: "center",
                                  marginTop: "40px",
                                }}
                              >
                                <Grid item xs={6}>
                                  <Button
                                    className={classes.cardBtn}
                                    onClick={props.onClick}
                                  >
                                    {intl.formatMessage({ id: "cancel" })}
                                  </Button>
                                </Grid>
                                <Grid item xs={6}>
                                  <Button
                                    type="submit"
                                    disabled={stripeLoader}
                                    onClick={() => handleSubmit()}
                                    className={classes.cardBtn}
                                    style={{
                                      background: "#09A9BB",
                                      color: "#FFF",
                                    }}
                                  >
                                    {stripeLoader && formSubmitLoader ? (
                                      <CircularProgress
                                        size={15}
                                        style={{ color: "#fff" }}
                                      />
                                    ) : (
                                      intl.formatMessage({ id: "add" })
                                    )}
                                  </Button>
                                </Grid>
                              </Grid>
                            </Grid>
                          </form>
                        </Grid>
                      </Grid>
                    ) : (
                      ""
                    )}
                  </TabPanel>
                </Grid>
              </>
            ) : (
              <Grid
                item
                xs={12}
                style={{
                  padding: isMobile
                    ? "0px 15px 35px 15px"
                    : "0px 25px 25px 25px",
                }}
              >
                {paymentModule}
              </Grid>
            )}
          </>
        ) : (
          <>
            <Grid container style={{ marginTop: "10px", maxHeight: "150px" }}>
              <Grid
                item
                xs={12}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  maxHeight: "20px",
                }}
              >
                <p style={{ color: "red", padding: "0px 20px" }}>
                  Your Email is not verified. Please verify your Email.
                </p>
              </Grid>
              <Grid
                item
                xs={12}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Button
                  disableRipple={true}
                  disabled={loader}
                  className={classes.customButton}
                  onClick={() => handleResendEmail()}
                  style={{ width: "130px", display: "block" }}
                >
                  {loader ? (
                    <CircularProgress style={{ color: "#fff" }} size={20} />
                  ) : (
                    "Resend Email"
                  )}
                </Button>
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
      <Dialog open={Boolean(selectedCard)}>
        <DialogContent>
          <Typography>
            {intl.formatMessage({ id: "areYouSure" })} XXXX-
            {selectedCard?.last4}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              dispatch(
                deleteCustomerCard({
                  params: {
                    cardId: selectedCard.id,
                    customerId: selectedCard.customer,
                  },
                  token: authReducerState.token,
                })
              );
            }}
            style={{
              color: "#fbae02",
            }}
          >
            {stripeLoader ? (
              <CircularProgress size={15} style={{ color: "#fbae02" }} />
            ) : (
              intl.formatMessage({ id: "yes" })
            )}
          </Button>
          <Button onClick={() => setSelectedCard(null)}>
            {intl.formatMessage({ id: "no" })}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openConfirmCountry}>
        <DialogContent>
          <Typography>
            Are you sure you want to select {country}. Country can not be
            changed afterwards. Please check your country again and then
            proceed.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={verifyAccount}
            style={{
              color: "#fbae02",
            }}
          >
            {stripeLoader ? (
              <CircularProgress size={15} style={{ color: "#fbae02" }} />
            ) : (
              intl.formatMessage({ id: "yes" })
            )}
          </Button>
          <Button onClick={() => setOpenConfirmCountry(false)}>
            {intl.formatMessage({ id: "no" })}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openDeleteAccount}>
        <DialogContent>
          <Typography>
            Are you sure you want to delete the linked account?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={deleteAccount}
            style={{
              color: "#fbae02",
            }}
          >
            {deleteAccountLoader ? (
              <CircularProgress size={15} style={{ color: "#fbae02" }} />
            ) : (
              intl.formatMessage({ id: "yes" })
            )}
          </Button>
          <Button onClick={() => setOpenDeleteAccount(false)}>
            {intl.formatMessage({ id: "no" })}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default Payment;

const useStyles = makeStyles((theme) => ({
  customButton: {
    background: `linear-gradient(360deg, #09A9BB 100%, #09A9BB 0%)`,
    height: "2.463em",
    width: "120px",
    borderRadius: "10px",
    transform: "rotate(360deg)",
    fontFamily: theme.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "10px",
    color: "#FFFFFF",
  },
  cardContainer: {
    borderRadius: "11px",
    backgroundColor: "#FFFFFF",
    boxShadow: " 0px 4px 4px rgba(0, 0, 0, 0.05)",
  },
  container: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    backgroundColor: "#FFFFFF",
    height: "6.5rem",
    transform: "rotate(360deg)",
  },
  image: {
    height: "70px",
    width: "70px",
  },
  username: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "15px",
    lineHeight: "18px",
    color: "#3C4A6B",
    marginLeft: "5px",
    color: " #192247",
  },
  name: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "8px",
    lineHeight: "9px",
    color: "#3C4A6B",
    marginTop: "5px",
    marginLeft: "5px",
    color: "#F94C5E",
  },
  btn: {
    borderRadius: "26px",
    height: "64px",
    width: "64px",
    color: "#FFF",
    background: "#fbae02",
    "&:hover": {
      background: "#fbae02",
    },
  },

  btnStyle: {
    maxWidth: "18.813em",
    width: "100%",
    height: "2.750em",
    background: "linear-gradient(180deg, #FFC000 -13.24%, #F28705 100%)",
    borderRadius: "11px",
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "20px",
    lineHeight: "23px",
    color: "#FFFFFF",
    marginBottom: "30px",
  },
  heading: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "24px",
    lineHeight: "28px",
    color: "#3D3D3D",
    textAlign: "center",
    marginTop: "60px",
  },
  paymentTypeCard: {
    height: "6.500em",
    width: "9.438em",
    marginRight: "0px",
    background: "#FFFFFF",
  },
  addPaymentCardBtn: {
    height: "4.375em",
    width: "18.813em",
    borderRadius: "9px",
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "19px",
    lineHeight: "22px",
    color: "#0F0F0F",
    border: "3px solid #09A9BB",
    marginBottom: "40px",
  },
  appBar: {
    backgroundColor: "#FFF",
    width: "100%",
    boxShadow: "none",
    display: "flex",
    justifyContent: "space-between",
  },
  tabLabel: {
    color: "#DFDFDF",
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "19px",
    minWidth: "20%",
    textTransform: "none",
  },
  tabSelected: {
    color: "#09A9BB",
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "19px",
  },
  tag: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "16px",
    backgroundColor: "#F28705 !important",
    color: "#FFF !important",
    width: "100%",
    height: "49px",
    borderRadius: "82px",
    border: "none",
    marginBottom: "20px",
  },
  selectedTag: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "16px",
    backgroundColor: "#FFF",
    color: "#F28705",
    width: "100%",
    height: "49px",
    borderRadius: "82px",
    border: "1px solid #BBBB",
    marginBottom: "20px",
  },
  inputField: {
    width: "100%",
    marginTop: "15px",
    "& .MuiInputLabel-formControl": {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "16px",
      lineHeight: "16px",
      color: "#09A9BB",
    },
  },
  inputLabel: {
    color: "#F28705",
  },
  cardBtn: {
    width: "9.250em",
    height: "3.063em",
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "16px",
    borderRadius: "8px",
  },

  dot: {
    height: "5px",
    width: "5px",
    backgroundColor: "#000000",
    borderRadius: "50%",
    display: "inline-block",
    marginLeft: "2px",
  },
}));
