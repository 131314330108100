import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Truncate from "react-truncate";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Grid from "@material-ui/core/Grid";
import Chip from "@material-ui/core/Chip";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { useIntl } from "react-intl";
import { Tooltip } from "@material-ui/core";
import { Close } from "@material-ui/icons";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    borderRadius: "11px",
    boxShadow: " 0px 4px 4px rgba(0, 0, 0, 0.05)"
  },
  media: {
    height: "0",
    position: "relative",
    paddingTop: "100%",
    width: "100%",
    BorderRadiusTopRight: "11px",
    BorderRadiusTopLeft: "11px",
    background: "#C4C4C4",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain"
  },
  title: {
    fontFamily: theme.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: "900",
    fontSize: "14px",
    lineHeight: "19px",
    textAlign: "center",
    color: "#000000"
  },
  subTitle: {
    fontFamily: theme.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: "900",
    fontSize: "10px",
    lineHeight: "14px",
    textAlign: "center",
    color: "#C2C2C2"
  },
  nameBold: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "18px",
    lineHeight: "14px",
    color: "#3C4A6B",
    marginTop: "10px"
  },
  closeIcon: {
    backgroundColor: "#F1F1F4",
    color: "#E54D40",
    height: "25px",
    width: "25px",
    margin:'5px 5px 0px 0px',
    "&:hover": {
      backgroundColor: "#F1F1F4",
      color: "#E54D40"
    }
  },
  iconContainer: {
    position: "absolute",
    top: '0px',
    left: 0,
    right: 0
  },
  active: {
    background: "#52b202",
    color: "#fff",
    height:'25px'
  },
  pending: {
    background: "#ff9100",
    color: "#fff",
    height:'25px'

  },
  rejected: {
    background: "red",
    color: "#fff",
    height:'25px'

  },
}));

function MentorCard(props) {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <Card className={classes.root}>
      <CardMedia className={classes.media} image={props?.image}>
        {props?.id !== props?.creatorId ? <Grid container className={classes.iconContainer} justify='space-between'>
          
          <Grid item >
            <Chip label={props?.status === 'accepted' ? 'Accepted' : props?.status === 'rejected' ? 'Rejected' : 'Pending'} className={props?.status === 'accepted' ? classes.active : props?.status === 'rejected' ? classes.rejected : classes.pending} />
          </Grid>
          <Grid item onClick={() => props?.removeMentor(props?.mentor)}>
            <IconButton className={classes.closeIcon}>
              <Close />
            </IconButton>
          </Grid>
        </Grid> : 
        <Grid container className={classes.iconContainer} justify='space-between'>
          
        <Grid item >
          <Chip label={'Creator'} className={classes.active} />
        </Grid>
        
      </Grid>
        }
      </CardMedia>
      <CardContent style={{ padding: "7px 0px" }}>
        <Tooltip title={props?.name}>
          <Typography
            gutterBottom
            style={{ width: "100%" }}
            className={props?.courseNameBold ? classes.nameBold : classes.title}
          >
            <Truncate lines={1} ellipsis={<span>...</span>}>
              {`    ${props?.name}    `}
            </Truncate>
          </Typography>
        </Tooltip>
      </CardContent>
    </Card>
  );
}

export default MentorCard;
